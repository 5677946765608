import {
  CREATE_PAYMENT_URL_SUCCESS,
  CREATE_PAYMENT_URL_FAILED,
  CREATE_TOKENIZATION_SUCCESS,
  CREATE_TOKENIZATION_FAILED,
} from './constants';

const initialState = {
  paymentURL: {},
};

const paymentHubReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_PAYMENT_URL_SUCCESS: {
      return {
        ...state,
        paymentURL: action.payload,
      };
    }
    case CREATE_PAYMENT_URL_FAILED: {
      return {
        ...state,
        paymentURL: {},
      };
    }

    case CREATE_TOKENIZATION_SUCCESS: {
      return {
        ...state,
        tokenization: action.payload,
      };
    }
    case CREATE_TOKENIZATION_FAILED: {
      return {
        ...state,
        tokenization: {},
      };
    }
    default:
      return state;
  }
};

export default paymentHubReducer;
