/* eslint-disable no-param-reassign */
import { addErrors, delErrors } from "utils/ErrorsGenerator";
import * as TYPE from "./constants";

const reducers = {
  [TYPE.UPLOAD_AVATAR.success]: (draft) => {
    draft.errors = delErrors(draft.errors, TYPE.UPLOAD_AVATAR.failure);
  },
  [TYPE.UPLOAD_AVATAR.failure]: (draft, { payload }) => {
    draft.errors = addErrors(draft.errors, {
      type: TYPE.UPLOAD_AVATAR.failure,
      msg: payload,
    });
  },
  [TYPE.UPDATE_USER_INFO.success]: (draft) => {
    draft.errors = delErrors(draft.errors, TYPE.UPDATE_USER_INFO.failure);
  },
  [TYPE.UPDATE_USER_INFO.failure]: (draft, { payload }) => {
    draft.errors = addErrors(draft.errors, {
      type: TYPE.UPDATE_USER_INFO.failure,
      msg: payload,
    });
  },
  [TYPE.GET_UTOP_INFOMATION.success]: (draft, { payload }) => {
    draft.errors = delErrors(draft.errors, TYPE.GET_UTOP_INFOMATION.failure);
    draft.utopInfomation = payload;
  },
  [TYPE.GET_UTOP_INFOMATION.failure]: (draft, { payload }) => {
    draft.errors = addErrors(draft.errors, {
      type: TYPE.GET_UTOP_INFOMATION.failure,
      msg: payload,
    });
  },
};

export default reducers;
