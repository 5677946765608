import {
  GET_PRODUCTS_REQUEST,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_FAILED,
  GET_TOPPINGS_REQUEST,
  GET_TOPPINGS_SUCCESS,
  GET_TOPPINGS_FAILED,
  GET_CATEGORIES_REQUEST,
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_FAILED,
  CHECK_PRODUCT_OUT_OF_STOCK_REQUEST,
  CHECK_PRODUCT_OUT_OF_STOCK_FAILED,
  CHECK_PRODUCT_OUT_OF_STOCK_SUCCESS,
  UPDATE_PRODUCT_CART_OUT_OF_STOCK,
  UPDATE_PRODUCT_CART,
  DELETE_PRODUCT_CART,
  ADD_PRODUCT_CART,
  CLEAR_PRODUCT_CART,
  SET_DATA_STORE,
  RESET_PRODUCT_CART,
  CREATE_ORDER_FNB_REQUEST,
  CREATE_ORDER_FNB_FAILED,
  CREATE_ORDER_FNB_SUCCESS,
  SET_INDEX_PRODUCT_CART,
  SET_LIST_COUPON_SUGGEST_FOR_STORE,
  UPDATE_LIST_COUPON_SUGGEST_FOR_STORE,
  SAVE_CURRENT_STORE_ID,
  UPDATE_LIST_PRODUCT_CART,
  SET_CREATE_NEW_LIST_DELIVERY,
  CLEAR_PRODUCT_OF_STORE,
} from './constants';

// get Products
export const getProductsHandle = (merchantId, onSuccess) => ({
  type: GET_PRODUCTS_REQUEST,
  merchantId,
  onSuccess,
});
export const setCreateNewListDelivery = payload => ({
  type: SET_CREATE_NEW_LIST_DELIVERY,
  payload,
});
export const getProductsSuccess = (balance, dataOrigin) => ({
  type: GET_PRODUCTS_SUCCESS,
  balance,
  dataOrigin,
});

export const getProductsFailed = error => ({
  type: GET_PRODUCTS_FAILED,
  error,
});

// get Toppings
export const getToppingsHandle = (merchantId, onSuccess) => ({
  type: GET_TOPPINGS_REQUEST,
  merchantId,
  onSuccess,
});

export const getToppingsSuccess = balance => ({
  type: GET_TOPPINGS_SUCCESS,
  balance,
});

export const getToppingsFailed = error => ({
  type: GET_TOPPINGS_FAILED,
  error,
});

// get Categories
export const getCategoriesHandle = (merchantId, onSuccess) => ({
  type: GET_CATEGORIES_REQUEST,
  merchantId,
  onSuccess,
});

export const getCategoriesSuccess = balance => ({
  type: GET_CATEGORIES_SUCCESS,
  balance,
});

export const getCategoriesFailed = error => ({
  type: GET_CATEGORIES_FAILED,
  error,
});

// checkProductOutOfStock
export const checkProductOutOfStockHandle = (
  storeId,
  merchantId,
  onSuccess,
  onFailed,
) => ({
  type: CHECK_PRODUCT_OUT_OF_STOCK_REQUEST,
  storeId,
  merchantId,
  onSuccess,
  onFailed,
});

export const checkProductOutOfStockSuccess = balance => ({
  type: CHECK_PRODUCT_OUT_OF_STOCK_SUCCESS,
  balance,
});

export const checkProductOutOfStockFailed = error => ({
  type: CHECK_PRODUCT_OUT_OF_STOCK_FAILED,
  error,
});

export const addProductCart = payload => ({
  type: ADD_PRODUCT_CART,
  payload,
});

export const updateProductCartOutOfStock = payload => ({
  type: UPDATE_PRODUCT_CART_OUT_OF_STOCK,
  payload,
});

export const updateProductCart = payload => ({
  type: UPDATE_PRODUCT_CART,
  payload,
});

export const deleteProductCart = payload => ({
  type: DELETE_PRODUCT_CART,
  payload,
});

export const clearProductCart = () => ({
  type: CLEAR_PRODUCT_CART,
});

export const setDataStore = payload => ({
  type: SET_DATA_STORE,
  payload,
});

export const resetProductCart = payload => ({
  type: RESET_PRODUCT_CART,
  payload,
});

export const createOrderFnbHandle = (payload = {}, onSuccess, onFailed) => ({
  type: CREATE_ORDER_FNB_REQUEST,
  payload,
  onSuccess,
  onFailed,
});

export const createOrderFnbSuccess = (payload = {}) => ({
  type: CREATE_ORDER_FNB_SUCCESS,
  payload,
});

export const createOrderFnbFailed = (payload = {}) => ({
  type: CREATE_ORDER_FNB_FAILED,
  payload,
});

export const setIndexProductCart = () => ({
  type: SET_INDEX_PRODUCT_CART,
});

export const setListCouponSuggestForStore = (payload = {}) => ({
  type: SET_LIST_COUPON_SUGGEST_FOR_STORE,
  payload,
});

export const updateListCouponSuggestForStore = (payload = '') => ({
  type: UPDATE_LIST_COUPON_SUGGEST_FOR_STORE,
  payload,
});

export const saveCurrentStoreId = (payload = '') => ({
  type: SAVE_CURRENT_STORE_ID,
  payload,
});

export const updateListProductCart = payload => ({
  type: UPDATE_LIST_PRODUCT_CART,
  payload,
});

export const clearProductOfStore = () => ({
  type: CLEAR_PRODUCT_OF_STORE,
});
