export const PAGE_SIZE = 10;

export const SELECTED_LOCATION_MODE = {
  CITY: 'SELECTED_LOCATION_MODE/CITY',
  GPS: 'SELECTED_LOCATION_MODE/GPS',
};

export const CLIENT_PAYMENT_METHOD_VOUCHER = ['utop', 'momo', 'payoo', 'cod'];
export const CLIENT_PAYMENT_METHOD = [
  'utop',
  'momo',
  'payoo',
  'vnpay',
  'alepay',
  'cod',
  'foxpay',
];

export const ENVIRONMENT = {QA: 'ENVIRONMENT/QA', PRO: 'ENVIRONMENT/PRO'};

export const EXPIRED_TIME_SCHEME_DATA = 2592000000;

export const DELTA_LIMIT_DISTANCE = 1000;
