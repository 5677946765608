import { END_POINTS } from '@shared/constants/apiUrl'
import { APIUtils } from 'utils'

export async function getProductMarketBySearchApi({
  valueSearch,
  skipCount,
  storeId,
  merchantId,
  categoryId,
}) {
  let requestData = `?search=${valueSearch}&skip=${skipCount}&take=20&storeId=${storeId}&merchantId=${merchantId}`
  if (categoryId) {
    requestData += `&categoryId=${categoryId}`
  }
  return APIUtils.get(END_POINTS.SEARCH_PRODUCT_MARKET + requestData)
}

export async function getProductMarketDetailApi(productId) {
  let requestData = '/' + productId
  return APIUtils.get(END_POINTS.GET_PRODUCT_MARKET + requestData)
}

export function getListProductApi({
  skip,
  top,
  categoryId,
  merchantId,
  storeId,
  fnbType,
}) {
  let requestData = `?storeId=${storeId}&merchantId=${merchantId}&fnbTypeId=${fnbType}&skip=${skip}&take=${top}`
  requestData += categoryId ? `&categoryId=${categoryId}` : ''

  let url = categoryId
    ? END_POINTS.GET_PRODUCT_MARKET
    : END_POINTS.GET_LIST_PRODUCT_FNB

  return APIUtils.get(url + requestData)
}

export function getListCategoryApi({ id, merchantId }) {
  return APIUtils.get(
    END_POINTS.GET_LIST_CATEGORY + `?merchantId=${merchantId}&fnbTypeId=${id}`,
  )
}

export function getMarketInfoApi({ storeId }) {
  const url = END_POINTS.URL_MARKET_GET_MARKET_INFO + storeId
  return APIUtils.get(url)
}

export function getListFnbTypeApi({ merchantId }) {
  const url =
    END_POINTS.URL_MARKET_GET_LIST_FNB_TYPE + '?merchantId=' + merchantId
  return APIUtils.get(url)
}

export function getListCategoryFnbTypeApi({ categoryId, merchantId }) {
  const url =
    END_POINTS.URL_MARKET_GET_LIST_CATEGORY_FNB_TYPE +
    '?categoryId=' +
    categoryId +
    '&merchantId=' +
    merchantId
  return APIUtils.get(url)
}

export function getListMediaHomeApi({ storeId }) {
  const url = END_POINTS.URL_MARKET_GET_LIST_MEDIA_HOME + storeId + '/Store'
  return APIUtils.get(url)
}
export function getListMediaSummaryApi({ storeId, skipCount = 0, take = 10 }) {
  let requestData = `${storeId}/Store?skip=${skipCount}&take=${take}&itemQuantity=10`
  const url = END_POINTS.URL_MARKET_GET_LIST_MEDIA_SUMMARY + requestData
  return APIUtils.get(url)
}
export function getAllCategoryApi({ storeId }) {
  let requestData = `${storeId}/Store?skip=0&take=100&itemQuantity=0`
  const url = END_POINTS.URL_MARKET_GET_LIST_MEDIA_SUMMARY + requestData
  return APIUtils.get(url)
}
export function getListMediaCategoryApi({
  categoryId,
  storeId,
  skipCount = 0,
  take = 10,
}) {
  let requestData = `${categoryId}/Category?storeId=${storeId}&skip=${skipCount}&take=${take}`
  const url = END_POINTS.URL_MARKET_GET_LIST_MEDIA_HOME + requestData
  return APIUtils.get(url)
}
export function getListFnbBestSellerApi(payload) {
  let requestData = `?storeId=${payload.storeId}&merchantId=${payload.merchantId}&skip=0&take=6`
  const url = END_POINTS.URL_MARKET_GET_LIST_FNB_BESTSELLER + requestData
  return APIUtils.get(url)
}
export function getListSuggestProductApi(payload) {
  const url =
    END_POINTS.URL_MARKET_GET_LIST_SUGGEST_PRODUCT +
    '?storeId=' +
    payload.storeId
  return APIUtils.get(url)
}
export function getListStoreMerchantApi(payload) {
  const url =
    END_POINTS.URL_MARKET_GET_LIST_STORE_MERCHANT +
    `?merchantId=${payload.merchantId}&skip=${payload.skip}&take=${payload.take}`
  return APIUtils.get(url)
}
export function getStoreDetailMechantApi(payload) {
  const url =
    END_POINTS.URL_MARKET_GET_STORE_DETAIL_MERCHANT +
    `${payload.id}?merchantId=${payload.merchantId}`
  return APIUtils.get(url)
}

export async function searchStallsRequestApi(payload) {
  const requestData = `?merchantId=${payload.merchantId}&skip=${payload.skip}&take=${payload.take}&name=${payload.valueSearch}`
  const url = END_POINTS.URL_MARKET_GET_LIST_STORE_MERCHANT + requestData
  return APIUtils.get(url)
}

export async function getShortLinkToShareApi(payload) {
  return await APIUtils.post(END_POINTS.SHORT_LINK, {
    body: payload,
  })
}
