import { addErrors, delErrors } from "utils/ErrorsGenerator";
import * as TYPE from "./constants";

const reducers = {
  // * GET_VOUCHER_INFO
  [TYPE.GET_VOUCHER_INFO.request]: (draft) => {
    return {
      ...draft,
      isLoading: {
        ...draft.isLoading,
        GET_VOUCHER_INFO: true,
      },
    };
  },
  [TYPE.GET_VOUCHER_INFO.success]: (draft, { payload }) => {
    return {
      ...draft,
      voucherInfo: payload, // ***
      errors: delErrors(draft.errors, TYPE.GET_VOUCHER_INFO.failure),
      isLoading: {
        ...draft.isLoading,
        GET_VOUCHER_INFO: false,
      },
    };
  },
  [TYPE.GET_VOUCHER_INFO.failure]: (draft, { payload }) => {
    return {
      ...draft,
      errors: addErrors(draft.errors, {
        type: TYPE.GET_VOUCHER_INFO.failure,
        msg: payload,
      }),
      isLoading: {
        ...draft.isLoading,
        GET_VOUCHER_INFO: false,
      },
    };
  },
  // * -- GET_SUGGESTED_COUPON_LIST -- * //

  // * GET_SUGGESTED_COUPON_LIST
  [TYPE.GET_SUGGESTED_COUPON_LIST.request]: (draft) => {
    return {
      ...draft,
      isLoading: {
        ...draft.isLoading,
        GET_SUGGESTED_COUPON_LIST: true,
      },
    };
  },
  [TYPE.GET_SUGGESTED_COUPON_LIST.success]: (draft, { payload }) => {
    return {
      ...draft,
      suggestedCouponList: payload, // ***
      errors: delErrors(draft.errors, TYPE.GET_SUGGESTED_COUPON_LIST.failure),
      isLoading: {
        ...draft.isLoading,
        GET_SUGGESTED_COUPON_LIST: false,
      },
    };
  },
  [TYPE.GET_SUGGESTED_COUPON_LIST.failure]: (draft, { payload }) => {
    return {
      ...draft,
      errors: addErrors(draft.errors, {
        type: TYPE.GET_SUGGESTED_COUPON_LIST.failure,
        msg: payload,
      }),
      isLoading: {
        ...draft.isLoading,
        GET_SUGGESTED_COUPON_LIST: false,
      },
    };
  },
  // * -- GET_SUGGESTED_COUPON_LIST -- * //

  // * GET_SHORT_LINK_TO_SHARE
  [TYPE.GET_SHORT_LINK_TO_SHARE.request]: (draft) => {
    return {
      ...draft,
      isLoading: {
        ...draft.isLoading,
        GET_SHORT_LINK_TO_SHARE: true,
      },
    };
  },
  [TYPE.GET_SHORT_LINK_TO_SHARE.success]: (draft, { payload }) => {
    return {
      ...draft,
      shortLinkToShare: payload.url, // ***
      errors: delErrors(draft.errors, TYPE.GET_SHORT_LINK_TO_SHARE.failure),
      isLoading: {
        ...draft.isLoading,
        GET_SHORT_LINK_TO_SHARE: false,
      },
    };
  },
  [TYPE.GET_SHORT_LINK_TO_SHARE.failure]: (draft, { payload }) => {
    return {
      ...draft,
      shortLinkToShare: undefined, // ***
      errors: addErrors(draft.errors, {
        type: TYPE.GET_SHORT_LINK_TO_SHARE.failure,
        msg: payload,
      }),
      isLoading: {
        ...draft.isLoading,
        GET_SHORT_LINK_TO_SHARE: false,
      },
    };
  },
  // * -- GET_SHORT_LINK_TO_SHARE -- * //

  // * SAVE_SUGGESTED_COUPON * //
  [TYPE.SAVE_SUGGESTED_COUPON.request]: (draft) => {
    return {
      ...draft,
      isLoading: {
        ...draft.isLoading,
        SAVE_SUGGESTED_COUPON: true,
      },
    };
  },
  [TYPE.SAVE_SUGGESTED_COUPON.success]: (draft, { payload }) => {
    const codeOfTheSavedCoupon = payload.code;
    const updatedSuggestedCouponList = draft.suggestedCouponList.map((coupon) =>
      coupon.code === codeOfTheSavedCoupon
        ? {
            ...coupon,
            isAllocated: true,
          }
        : coupon
    );

    return {
      ...draft,
      suggestedCouponList: updatedSuggestedCouponList, // ***
      errors: delErrors(draft.errors, TYPE.SAVE_SUGGESTED_COUPON.failure),
      isLoading: {
        ...draft.isLoading,
        SAVE_SUGGESTED_COUPON: false,
      },
    };
  },
  [TYPE.SAVE_SUGGESTED_COUPON.failure]: (draft, { payload }) => {
    return {
      ...draft,
      errors: addErrors(draft.errors, {
        type: TYPE.SAVE_SUGGESTED_COUPON.failure,
        msg: payload,
      }),
      isLoading: {
        ...draft.isLoading,
        SAVE_SUGGESTED_COUPON: false,
      },
    };
  },
  // * -- SAVE_SUGGESTED_COUPON -- * //

  // * CHECK_STOCK_OF_PRODUCT * //
  [TYPE.CHECK_STOCK_OF_PRODUCT.request]: (draft) => {
    return {
      ...draft,
      isLoading: {
        ...draft.isLoading,
        CHECK_STOCK_OF_PRODUCT: true,
      },
    };
  },
  [TYPE.CHECK_STOCK_OF_PRODUCT.success]: (draft, { payload }) => {
    const { isAvailable, productCode } = payload;

    let updatedOutOfStockProductCodes = draft.outOfStockProductCodes;

    if (!isAvailable) {
      updatedOutOfStockProductCodes = [
        ...updatedOutOfStockProductCodes,
        productCode,
      ];
    }

    return {
      ...draft,
      errors: delErrors(draft.errors, TYPE.CHECK_STOCK_OF_PRODUCT.failure),
      outOfStockProductCodes: updatedOutOfStockProductCodes, // *
      isLoading: {
        ...draft.isLoading,
        CHECK_STOCK_OF_PRODUCT: false,
      },
    };
  },
  [TYPE.CHECK_STOCK_OF_PRODUCT.failure]: (draft, { payload }) => {
    return {
      ...draft,
      errors: addErrors(draft.errors, {
        type: TYPE.CHECK_STOCK_OF_PRODUCT.failure,
        msg: payload,
      }),
      isLoading: {
        ...draft.isLoading,
        CHECK_STOCK_OF_PRODUCT: false,
      },
    };
  },
  // * -- CHECK_STOCK_OF_PRODUCT -- * //

  // ---------
  // [TYPE.GET_DETAIL_VOUCHER_MORE.success]: (draft, { payload }) => {
  //   return {
  //     ...draft,
  //     errors: delErrors(draft.errors, TYPE.GET_DETAIL_VOUCHER_MORE.failure),
  //     morevoucher: payload,
  //   };
  // },
  // [TYPE.GET_DETAIL_VOUCHER_MORE.failure]: (draft, { payload }) => {
  //   draft.errors = addErrors(draft.errors, {
  //     type: TYPE.GET_DETAIL_VOUCHER_MORE.failure,
  //     msg: payload,
  //   });
  // },
};

export default reducers;
