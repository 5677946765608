export const sentVoucher = undefined;
export const foundReceiver = undefined;
export const shortLinkToShare = "";
export const isLoading = {
  GET_USER_BY_PHONE: false,
  POST_SEND_VOUCHER: false,
};
export const errorMessages = {
  GET_USER_BY_PHONE: "",
};
