import request from "services/request";
import { encodeQueryData } from "utils/stringFormat";
import * as TYPE from "../store/constants";

const api = {
  [TYPE.GET_ORDER_DETAIL.request]: async (params) => {
    const query = encodeQueryData({
      orderCode: params.orderCode,
    });
    return request(`Order/GetOrderDetailByUser${query}`, "GET");
  },

  [TYPE.GET_PAYMENT_METHODS.request]: async () => {
    return request(`cms/core/paymentmethods`, "GET");
  },
};

export default api;
