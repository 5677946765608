import { genActionTypes } from "utils/actionGenerator";

export const TYPE = "TransactionHistory";

export const ACTION_KEYS = {
  LIST_ORDER_HISTORY: "LIST_ORDER_HISTORY",
  SET_LOADING_BY_FILTER_ORDER_HISTORY: "SET_LOADING_BY_FILTER_ORDER_HISTORY",
};

export const LIST_ORDER_HISTORY = genActionTypes(
  `${TYPE}_${ACTION_KEYS.LIST_ORDER_HISTORY}`
);

export const SET_LOADING_BY_FILTER_ORDER_HISTORY = `${TYPE}_${ACTION_KEYS.SET_LOADING_BY_FILTER_ORDER_HISTORY}`;

export const CLEAR_ORDER_HISTORY = `${TYPE}_CLEAR_ORDER_HISTORY`;
