import React, { useMemo } from "react";
import classNames from "classnames";
import Skeleton from "react-loading-skeleton";

import {
  LocationSolidYellow8x10,
  VoucherSolidYellow,
  LocationNearBy,
  StoreSolidYellow,
  ArrowRightBlack4x7,
  StarFilled8x8,
  StarHalf8x8,
  StarEmpty8x8,
  ListIcon,
} from "assets/svg";
import LogoUtop from "assets/img/LogoUtop.jpg";
import FeedPlaceHolder from "assets/img/feed-img-default--border-radius-0.jpg";

import { FEED_TYPES, VOUCHER_TYPES, CURRENCY_TYPE } from "constants/types";

import { toVND, toUTop, priceRange } from "utils/currencyFormat";

import { IconList } from "views/common/component";

import "./FeedCard.scss";

export function FeedCard(props) {
  const {
    feedType,
    type,
    images,
    distance,
    merchantType,
    logoUrl = LogoUtop,
    title,
    avgRate,
    avgPrice,
    currentOpeningData,
    // tags,
    promotionTags,
    description,
    // storeCount = 0,
    bestSellingVouchers,
    nearestStoreName,
    // priceToShow,
    handleShowListStoreOfMerchant,
    currencies,
    prices,
  } = props;

  const openingTimes = useMemo(() => {
    const isStoreOpenTemp =
      currentOpeningData &&
      (currentOpeningData.rangeTime === "in" ||
        currentOpeningData.rangeTime === "all");
    return isStoreOpenTemp;
  }, [currentOpeningData]);

  return (
    <div className="feed-card feed-card--clickable">
      <div className={classNames("feed-card__info")}>
        <object data={logoUrl} type="image/png" className="logo">
          <img src={LogoUtop} className="logo" />
        </object>

        <div className="minW-0">
          <div className="title text-on-1-line">{title}</div>

          {nearestStoreName && (
            <div className="--d-flex _mt-2px">
              <i className="nearest-store__icon">
                <LocationNearBy />
              </i>
              <span className="nearest-store__name text-on-1-line">
                {nearestStoreName}
              </span>
            </div>
          )}

          <div className="statistic">
            {Number(avgRate) > 0 && (
              <div className="statistic__avg-rate">
                <IconList
                  selectedNum={Number(avgRate)}
                  iconFull={<StarFilled8x8 />}
                  iconHalf={<StarHalf8x8 />}
                  iconEmpty={<StarEmpty8x8 />}
                  containerClassName="statistic__avg-rate__stars"
                  iconClassName="statistic__avg-rate__star"
                />
                <span>{Number(avgRate).toFixed(1)}</span>
              </div>
            )}

            {Number(avgRate) > 0 && Number(avgPrice) > 0 && (
              <div className="statistic__divider" />
            )}

            {Number(avgPrice) > 0 && (
              <div className="statistic__avg-price">
                <span>{"Trung bình: "}</span>
                <span className="statistic__avg-price__price">
                  {toVND(avgPrice)}/người
                </span>
              </div>
            )}
          </div>

          {[
            FEED_TYPES.FNB.key,
            FEED_TYPES.STORE.key,
            FEED_TYPES.MARKET.key,
          ].includes(String(feedType).toLowerCase()) &&
            !openingTimes && (
              <div className="opening-time-info">
                <span className="opening-time-info__status opening-time-info__status--closed">
                  Đã đóng cửa
                </span>

                {currentOpeningData &&
                  !openingTimes &&
                  currentOpeningData?.openTime?.from &&
                  currentOpeningData?.openTime?.to &&
                  currentOpeningData?.timePoint && (
                    <>
                      <span className="opening-time-info__divider" />

                      <span className="opening-time-info__comeback">
                        Quay lại lúc{" "}
                        <span className="opening-time-info__comeback__time">
                          {" "}
                          {currentOpeningData?.openTime?.from}{" "}
                          {currentOpeningData?.timePoint}
                        </span>
                      </span>
                    </>
                  )}
              </div>
            )}

          {promotionTags.length > 0 && (
            <div className="promotions hide-x-scrollbar">
              {promotionTags.map((promotionTag, idx) => (
                <span
                  className="promotion text-on-1-line"
                  key={`${promotionTag}__${1 + idx}`}
                >
                  {promotionTag}
                </span>
              ))}
            </div>
          )}

          {description && (
            <div className="feed-card__description">
              <i className="feed-card__description__icon">
                <ListIcon />
              </i>
              <span className="feed-card__description__content only-2-lines">
                {description}
              </span>
            </div>
          )}
        </div>
      </div>

      <div className="feed-card__hot-vouchers-container">
        {bestSellingVouchers?.map((voucher, idx) => (
          <div
            className="feed-card__hot-voucher"
            key={`${voucher.name}__${idx + 1}`}
          >
            {(voucher.price?.selling ||
              voucher.price?.list ||
              voucher.name) && (
              <i className="feed-card__hot-voucher__icon">
                <VoucherSolidYellow />
              </i>
            )}

            {Math.round(voucher.price?.selling / 1000) > 0 && (
              <span className="feed-card__hot-voucher__price--current">
                {voucher?.currencies &&
                voucher?.currencies?.name.toLowerCase() ===
                  CURRENCY_TYPE.UTOP.name
                  ? toUTop(
                      voucher.price?.selling,
                      voucher?.currencies?.exchangeRate
                    )
                  : `${Math.round(voucher.price?.selling / 1000).toLocaleString(
                      "vi"
                    )}k`}
              </span>
            )}

            {Math.round(voucher.price?.list / 1000) > 0 && (
              <span className="feed-card__hot-voucher__price--old strike-low">
                {voucher?.currencies &&
                voucher?.currencies?.name.toLowerCase() ===
                  CURRENCY_TYPE.UTOP.name
                  ? toUTop(
                      voucher.price?.list,
                      voucher?.currencies?.exchangeRate
                    )
                  : `${Math.round(voucher.price?.list / 1000).toLocaleString(
                      "vi"
                    )}k`}
              </span>
            )}

            {voucher.name && (
              <span className="feed-card__hot-voucher__name text-1-line">
                {voucher.name}
              </span>
            )}
          </div>
        ))}
      </div>

      <div
        className={classNames(
          "feed-card__gallery",
          [
            FEED_TYPES.FNB.key,
            FEED_TYPES.STORE.key,
            FEED_TYPES.MARKET.key,
          ].includes(String(feedType).toLowerCase()) &&
            !openingTimes &&
            "feed-card__gallery--darken"
        )}
      >
        <div className="feed-card__gallery--spotlight">
          <object data={images?.[0] || FeedPlaceHolder} type="image/png">
            <img src={FeedPlaceHolder} alt="default feed img" />
          </object>
          {type !== VOUCHER_TYPES.GEN_VOUCHER_WITH_AMOUNT &&
            prices &&
            prices.length > 0 &&
            priceRange(prices, "percent") > 0 && (
              <div className="feed-card-percent">
                -{priceRange(prices, "percent")}%
              </div>
            )}

          <div className="feed-card__gallery--spotlight__tags-container">
            {distance && (
              <div className="__distance-tag">
                <LocationSolidYellow8x10 />
                <span>{distance}</span>
              </div>
            )}

            {merchantType && (
              <div className="__merchant-tag text-on-1-line">
                {merchantType}
              </div>
            )}
          </div>
        </div>

        <div className="feed-card__gallery--sub-top">
          <object
            className="img-item--top"
            data={images?.[1] || FeedPlaceHolder}
            type="image/png"
          >
            <img src={FeedPlaceHolder} alt="default feed img" />
          </object>
        </div>

        <div className="feed-card__gallery--sub-bottom">
          <object
            className="img-item--bottom"
            data={images?.[2] || FeedPlaceHolder}
            type="image/png"
          >
            <img src={FeedPlaceHolder} alt="default feed img" />
          </object>
        </div>
      </div>

      {handleShowListStoreOfMerchant && (
        <div>
          <button
            className="feed-card__btn-show-all-store"
            type="button"
            onClick={handleShowListStoreOfMerchant}
          >
            <StoreSolidYellow />
            <span className="feed-card__btn-show-all-store__text">
              Xem tất cả cửa hàng
            </span>
            <i className="feed-card__btn-show-all-store__arrow">
              <ArrowRightBlack4x7 />
            </i>
          </button>
        </div>
      )}

      {/* {String(type).toLowerCase() !==
        String(VOUCHER_TYPES.GEN_VOUCHER_WITH_AMOUNT).toLowerCase() &&
        Number(priceToShow?.selling) > 0 && (
          <div className="feed-card__prices">
            <span className="feed-card__prices--selling">
              {currencies &&
              currencies?.name.toLowerCase() === CURRENCY_TYPE.UTOP.name
                ? toUTop(priceToShow.selling, currencies?.exchangeRate)
                : toVND(priceToShow.selling)}
            </span>
            {Number(priceToShow.list) > 0 && (
              <span className="feed-card__prices--list strike-low">
                {currencies &&
                currencies?.name.toLowerCase() === CURRENCY_TYPE.UTOP.name
                  ? toUTop(priceToShow.list, currencies?.exchangeRate)
                  : toVND(priceToShow.list)}
              </span>
            )}
          </div>
        )} */}
      {String(type).toLowerCase() !==
        String(VOUCHER_TYPES.GEN_VOUCHER_WITH_AMOUNT).toLowerCase() &&
        prices &&
        prices.length > 0 && (
          <div className="feed-card__prices text-1-line">
            <span className="feed-card__prices--selling">
              {currencies &&
              currencies?.name.toLowerCase() === CURRENCY_TYPE.UTOP.name
                ? `${toUTop(
                    priceRange(prices).selling?.minValue,
                    currencies?.exchangeRate
                  )}`
                : `${toVND(priceRange(prices).selling?.minValue)}`}
            </span>
            {priceRange(prices).selling?.maxValue && (
              <span className="dash-price"> - </span>
            )}
            {priceRange(prices).selling?.maxValue && (
              <span className="feed-card__prices--selling">
                {currencies &&
                currencies?.name.toLowerCase() === CURRENCY_TYPE.UTOP.name
                  ? toUTop(
                      priceRange(prices).selling?.maxValue,
                      currencies?.exchangeRate
                    )
                  : toVND(priceRange(prices).selling?.maxValue)}
              </span>
            )}
            {priceRange(prices)?.list?.minValue ? (
              <span>
                <span className="feed-card__prices--list strike-low">
                  {currencies &&
                  currencies?.name.toLowerCase() === CURRENCY_TYPE.UTOP.name
                    ? `${toUTop(
                        priceRange(prices).list?.minValue,
                        currencies?.exchangeRate
                      )}`
                    : `${toVND(priceRange(prices).list?.minValue)}`}
                </span>
                {priceRange(prices).list?.maxValue && (
                  <span className="dash-price"> -</span>
                )}
                {priceRange(prices).list?.maxValue && (
                  <span className="feed-card__prices--list strike-low">
                    {currencies &&
                    currencies?.name.toLowerCase() === CURRENCY_TYPE.UTOP.name
                      ? toUTop(
                          priceRange(prices).list?.maxValue,
                          currencies?.exchangeRate
                        )
                      : toVND(priceRange(prices).list?.maxValue)}
                  </span>
                )}
              </span>
            ) : (
              ""
            )}
          </div>
        )}
    </div>
  );
}

export function FeedCardSkeleton() {
  return (
    <div className="feed-card">
      <div className={classNames("feed-card__info")}>
        <Skeleton circle className="logo" />

        <div className="minW-0 --w-100">
          <div className="title text-on-1-line">
            <Skeleton width="80%" />
          </div>

          <div className="--d-flex _mt-2px -w-100">
            <Skeleton width="17ch" className="nearest-store__name" />
          </div>
        </div>
      </div>

      <div className="feed-card__gallery">
        <div className="feed-card__gallery--spotlight">
          <img src={FeedPlaceHolder} alt="default feed img" />
        </div>

        <div className="feed-card__gallery--sub-top">
          <img src={FeedPlaceHolder} alt="default feed img" />
        </div>

        <div className="feed-card__gallery--sub-bottom">
          <img src={FeedPlaceHolder} alt="default feed img" />
        </div>
      </div>
    </div>
  );
}

/**
 * Component of Feed Card that has a DividerTicket tag
 * Currently not being used
 */
// export function FeedCardStore(props) {
//   const {
//     coverImgUrl = FeedPlaceHolder,
//     distance,
//     merchantType,
//     logoUrl = LogoUtop,
//     title,
//     avgRate,
//     avgPrice,
//     currentOpeningData,
//     tags,
//     promotionTags,
//     description,
//     storeCount = 0,
//     bestSellingVouchers,
//     handleShowListStoreOfMerchant,
//   } = props;

//   return (
//     <div className="feed-card feed-card--clickable">
//       <div className="feed-card__top">
//         <div className="feed-card__top__cover">
//           <object
//             data={coverImgUrl}
//             type="image/png"
//             className={classNames(
//               "feed-card__top__cover__cover-img",
//               currentOpeningData?.status !== 1 && "filter-brightness-05"
//             )}
//           >
//             <img
//               src={FeedPlaceHolder}
//               className="feed-card__top__cover__cover-img"
//             />
//           </object>

//           <div className="feed-card__top__cover__tags-container">
//             {Number(distance) > 0 && (
//               <div className="feed-card__top__cover__distance-tag">
//                 <LocationIcon />
//                 <span>{toDistance(distance)}</span>
//               </div>
//             )}

//             {merchantType && (
//               <div className="feed-card__top__cover__merchant-tag text-on-1-line">
//                 {merchantType}
//               </div>
//             )}
//           </div>

//           {Number(storeCount) > 1 && (
//             <button
//               type="button"
//               className="_btn btn-show-list-stores"
//               onClick={handleShowListStoreOfMerchant}
//             >
//               <span>Xem tất cả {storeCount} cửa hàng</span>
//               <ArrowRightIcon />
//             </button>
//           )}
//         </div>

//         <div
//           className={classNames(
//             "feed-card__top__info",
//             !description && "_pb-12px"
//           )}
//         >
//           <object data={logoUrl} type="image/png" className="logo">
//             <img src={LogoUtop} className="logo" />
//           </object>

//           <div className="minW-0">
//             <div className="title text-on-1-line">{title}</div>

//             <div className="statistic">
//               {avgRate >= 0.5 && (
//                 <div className="statistic__avg-rate">
//                   <IconList
//                     selectedNum={Number(avgRate)}
//                     containerClassName="statistic__avg-rate__stars"
//                   />
//                   <span>{Number(avgRate).toFixed(1)}</span>
//                 </div>
//               )}

//               {avgRate >= 0.5 && avgPrice > 0 && (
//                 <div className="statistic__divider" />
//               )}

//               {avgPrice > 0 && (
//                 <div className="statistic__avg-price">
//                   <span>Trung bình: </span>
//                   <span className="statistic__avg-price__price">
//                     {toVND(avgPrice)}/người
//                   </span>
//                 </div>
//               )}
//             </div>

//             {currentOpeningData?.status !== 1 && (
//               <div className="opening-time-info">
//                 <span className="opening-time-info__status opening-time-info__status--closed">
//                   Đã đóng cửa
//                 </span>

//                 {currentOpeningData?.status !== 1 &&
//                   currentOpeningData?.comebackTime && (
//                     <>
//                       <span className="opening-time-info__divider" />

//                       <span className="opening-time-info__comeback">
//                         Quay lại lúc{" "}
//                         <span className="opening-time-info__comeback__time">
//                           {currentOpeningData.comebackTime}
//                         </span>
//                       </span>
//                     </>
//                   )}
//               </div>
//             )}

//             {tags.length > 0 && (
//               <div className="tags">
//                 {tags.map((tag, idx) => (
//                   <span
//                     className="tag text-on-1-line"
//                     key={`${tag}__${1 + idx}`}
//                   >
//                     {tag}
//                   </span>
//                 ))}
//               </div>
//             )}

//             {promotionTags.length > 0 && (
//               <div className="promotions hide-x-scrollbar">
//                 {promotionTags.map((promotionTag, idx) => (
//                   <span
//                     className="promotion text-on-1-line"
//                     key={`${promotionTag}__${1 + idx}`}
//                   >
//                     {promotionTag}
//                   </span>
//                 ))}

//                 {/* <span className="store-promotion--more">+2</span> */}
//               </div>
//             )}
//           </div>
//         </div>
//       </div>

//       {description && (
//         <>
//           <DividerTicket />

//           <div className="feed-card__bottom">
//             <div className="feed-card__bottom__description">
//               <i className="feed-card__bottom__description__icon">
//                 <ListIcon />
//               </i>
//               <span className="feed-card__bottom__description__content text-on-1-line">
//                 {description}
//               </span>
//             </div>

//             {bestSellingVouchers.length > 0 && (
//               <VoucherListHoriz listVoucher={bestSellingVouchers} />
//             )}
//           </div>
//         </>
//       )}
//     </div>
//   );
// }

// export function FeedCardVoucher(props) {
//   const {
//     coverImgUrl = FeedPlaceHolder,
//     merchantType,
//     distance,
//     logoUrl = LogoUtop,
//     title,
//     // avgRate, // ẩn rating cho voucher
//     nearestStoreName,
//     tags,
//     promotionTags,
//     description,
//     priceToShow,
//   } = props;

//   return (
//     <div className="feed-card feed-card--clickable">
//       <div className="feed-card__top">
//         <div className="feed-card__top__cover">
//           <object
//             data={coverImgUrl}
//             type="image/png"
//             className="feed-card__top__cover__cover-img"
//           >
//             <img
//               src={FeedPlaceHolder}
//               className="feed-card__top__cover__cover-img"
//             />
//           </object>

//           <div className="feed-card__top__cover__tags-container">
//             {Number(distance) > 0 && (
//               <div className="feed-card__top__cover__distance-tag">
//                 <LocationIcon />
//                 <span>{toDistance(distance)}</span>
//               </div>
//             )}

//             {merchantType && (
//               <div className="feed-card__top__cover__merchant-tag text-on-1-line">
//                 {merchantType}
//               </div>
//             )}
//           </div>
//         </div>

//         <div
//           className={classNames(
//             "feed-card__top__info",
//             !Number(priceToShow?.selling) > 0 && "_pb-12px"
//           )}
//         >
//           <object data={logoUrl} type="image/png" className="logo">
//             <img src={LogoUtop} className="logo" />
//           </object>

//           <div className="minW-0">
//             <div className="title text-on-1-line">{title}</div>

//             {nearestStoreName && (
//               <div className="--d-flex _mt-4px">
//                 <i className="nearest-store__icon">
//                   <LocationNearBy />
//                 </i>
//                 <span className="nearest-store__name text-on-1-line">
//                   {nearestStoreName}
//                 </span>
//               </div>
//             )}

//             {/* <div className="statistic">
//               {avgRate >= 0.5 && (
//                 <div className="statistic__avg-rate">
//                   <IconList
//                     selectedNum={Number(avgRate)}
//                     containerClassName="statistic__avg-rate__stars"
//                   />
//                   <span>{Number(avgRate).toFixed(1)}</span>
//                 </div>
//               )}
//             </div> */}

//             {tags.length > 0 && (
//               <div className="tags">
//                 {tags.map((tag, idx) => (
//                   <span
//                     className="tag text-on-1-line"
//                     key={`${tag}__${1 + idx}`}
//                   >
//                     {tag}
//                   </span>
//                 ))}
//               </div>
//             )}

//             {promotionTags.length > 0 && (
//               <div className="promotions hide-x-scrollbar">
//                 {promotionTags.map((promotionTag, idx) => (
//                   <span
//                     className="promotion text-on-1-line"
//                     key={`${promotionTag}__${1 + idx}`}
//                   >
//                     {promotionTag}
//                   </span>
//                 ))}
//               </div>
//             )}

//             {description && (
//               <div className="feed-card__bottom__description _mt-8px">
//                 <i className="feed-card__bottom__description__icon">
//                   <VoucherSolidYellow />
//                 </i>
//                 <span className="feed-card__bottom__description__content text-on-1-line">
//                   {description}
//                 </span>
//               </div>
//             )}
//           </div>
//         </div>
//       </div>

//       {Number(priceToShow?.selling) > 0 && (
//         <>
//           <DividerTicket />

//           <div className="feed-card__bottom">
//             <div className="feed-card__bottom__prices">
//               <span className="feed-card__bottom__prices--selling">
//                 {toVND(priceToShow.selling)}
//               </span>
//               {Number(priceToShow.list) > 0 && (
//                 <span className="feed-card__bottom__prices--list strike-low">
//                   {toVND(priceToShow.list)}
//                 </span>
//               )}
//             </div>
//           </div>
//         </>
//       )}
//     </div>
//   );
// }

// export const FeedCardSkeleton = () => {
//   return (
//     <div className="feed-card">
//       <div className="feed-card__top">
//         <div className="feed-card__top__cover">
//           <img
//             src={FeedPlaceHolder}
//             className="feed-card__top__cover__cover-img"
//           />
//         </div>

//         <div className="feed-card__top__info">
//           <Skeleton circle className="logo" />

//           <div className="--w-100">
//             <div className="title">
//               <Skeleton width="90%" />
//             </div>
//             <div className="nearest-store-name">
//               <Skeleton width="70%" />
//             </div>

//             <div className="tags">
//               <Skeleton width="10ch" />
//               <Skeleton width="8ch" />
//             </div>

//             <div className="promotions hide-x-scrollbar">
//               <Skeleton width="8ch" />
//               <Skeleton width="5ch" />
//             </div>
//           </div>
//         </div>
//       </div>

//       <DividerTicket />

//       <div className="feed-card__bottom">
//         <Skeleton width="100%" />
//       </div>
//     </div>
//   );
// };

// const VoucherListHoriz = ({ listVoucher }) => {
//   return (
//     <div className="voucher-tickets hide-x-scrollbar">
//       {/* {listVoucher?.map((voucher) => (
//         <VoucherTicket
//           key={voucher.id}
//           name={voucher.name}
//           price={voucher.price?.selling}
//           listPrice={voucher.price?.list}
//         />
//       ))} */}
//     </div>
//   );
// };

// const VoucherTicket = ({ name, price, listPrice }) => {
//   return (
//     <div className="voucher-ticket">
//       <div className="voucher-ticket__name text-1-line">{name}</div>

//       <div className="voucher-ticket__prices">
//         {Number(price) > 0 && (
//           <span className="voucher-ticket__prices__curr-price">
//             {toVND(price)}
//           </span>
//         )}
//         {Number(listPrice) > 0 && (
//           <span className="voucher-ticket__prices__old-price strike-low">
//             {toVND(listPrice)}
//           </span>
//         )}
//       </div>
//     </div>
//   );
// };
