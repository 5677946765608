/* eslint-disable import/extensions */
/* eslint-disable no-unused-vars */
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import SVG, { ArrowBackWhiteIcon, CouponSolidYellowIcon } from "assets/svg";
import { getShortLinkToShare } from "@shared/redux/market/marketHome/actions";

import { IconButton } from "views/common/component";
import Popup from "views/common/component/Popup";
import InfiniteScroll from "react-infinite-scroll-component";
import AlertAction from "views/common/component/AlertAction";
import { toast } from "react-toastify";
import { toastOptions } from "utils/toast";
import StoreInfo from "./components/StoreInfo";

import StoreHeader from "./components/StoreHeader";
import OrderMenu from "../MarketHomeView/components/OrderMenu";

import CategoryList from "../MarketHomeView/components/CategoryList";
import FoodItem from "../MarketHomeView/components/FoodItem";

import "./StoreDetailMarket.scss";
// eslint-disable-next-line import/order
import { NavigationServices, AlertMsg } from "utils";
// eslint-disable-next-line import/order
import CartBottomBar from "../MarketHomeView/components/CartBottomBar";
import QuantityMenu from "../MarketHomeView/components/QuantityMenu";
import EditMenu from "../MarketHomeView/components/EditMenu";
import { imgEmptyProduct } from "./icons";

const tabsListOfStoreView = [
  {
    code: "store-header",
    name: "Thông tin",
  },
  {
    code: "store__head_main-product",
    name: "Sản phẩm",
  },
];

const StoreDetailMarket = (props) => {
  const { storeId, merchantId } = props;
  const dispatch = useDispatch();
  const [showQuantityMenu, setShowQuantityMenu] = useState(false);
  const [itemsToUpdateQuantity, setItemsToUpdateQuantity] = useState([]);
  const [itemInCartToEdit, setItemInCartToEdit] = useState();
  const [showEditMenu, setShowEditMenu] = useState(false);

  const history = useHistory();

  const isScrollDown = useRef();
  // const loadingRef = useRef();

  // popup states
  const [showOrderMenu, setShowOrderMenu] = useState(false);
  const [chosenProduct, setChosenProduct] = useState();

  // handle show modal

  // * call API onMount
  // INITIALIZATION
  useLayoutEffect(() => {
    window.scrollTo({ top: 0 });

    // set default scroll direction to DOWN
    isScrollDown.current = true;
  }, []);

  // * handle scroll to show or hide store header
  useEffect(() => {
    const storeHeader = document.getElementById("store-header");
    const storeSlider = document.getElementById("store__head_main-product");

    let lastScrollY = 0;

    const handleScrollPage = () => {
      // get the scroll direction info
      isScrollDown.current = lastScrollY < window.scrollY;

      lastScrollY = window.scrollY; // store current scrollY value

      // stuff to handle store header display
      // if (isSearching) {
      //   storeHeader.style.display = "block";
      //   storeHeader.style.opacity = 1;
      //   return;
      // }
      if (props.loadingPager) {
        storeHeader.style.opacity =
          window.scrollY /
          (storeSlider.offsetHeight -
            storeHeader.offsetHeight -
            40 -
            15) /* 40px = khoảng trồi lên của store info card | 15px = khoảng trồi lên của merchantClass */;

        // set display sau khi update opacity để tránh hiện tượng chớp từ mờ -> rõ khi scroll xuống
        if (window.scrollY < 10) {
          storeHeader.style.display = "none";
        } else {
          storeHeader.style.display = "block";
        }
      }
    };

    window.addEventListener("scroll", handleScrollPage);

    return () => {
      window.removeEventListener("scroll", handleScrollPage);
    };
  }, [props.loadingPager]);

  // * NAVIGATE
  const goBack = () => {
    if (history.location.state === undefined) {
      history.push(
        `/markethome/?storeId=${props?.storeInfo?.id}&merchantId=${props?.storeInfo?.merchantId}`
      );
      return;
    }
    history.goBack();
  };

  const goToSearch = () => {
    NavigationServices.navigate("marketsearch", {
      storeInfo: { ...props.storeInfo, storeId: props.storeInfo.id },
      dataStoreMerchant: props.dataStoreMerchant,
      isCloseStore: !(
        props.openingTimes.rangeTime === "in" ||
        props.openingTimes.rangeTime === "all"
      ),
    });
  };

  const parseJsonToArray = (masterData, categories) => {
    const arrMaster = [];
    if (Object.keys(masterData)?.length > 0 && categories?.length > 0) {
      for (let i = 0; i < categories.length; i++) {
        const elmCategory = categories[i];
        Object.keys(masterData).forEach((element) => {
          if (elmCategory.categoryId === element) {
            if (masterData[element]?.data?.length > 0) {
              arrMaster.push({
                ...masterData[element],
                name: elmCategory?.categoryName?.vi,
                categoryId: elmCategory.categoryId,
                index: i,
              });
            }
          }
        });
        if (elmCategory.categoryId === props?.currentSectionRef.current) {
          return arrMaster;
        }
      }
    }
    return arrMaster;
  };

  const calDiscount = (listPrice, price) => {
    let percentDiscount = 0;
    if (!!listPrice && !!price) {
      percentDiscount = 100 - Math.round((price / listPrice) * 100);
      if (percentDiscount === 100) {
        percentDiscount -= 1;
      }
    }
    return percentDiscount;
  };

  const renderListProduct = useCallback(
    (masterData, categories) => {
      const array = parseJsonToArray(masterData, categories);
      return (
        <div>
          <InfiniteScroll
            dataLength={array.length}
            next={props.onLoadMore}
            hasMore={!props.isEndListAll}
            // loader={<div class="loader" />}
            endMessage={
              <p style={{ textAlign: "center" }}>
                {array.length > 0 ? "Đã hết sản phẩm" : ""}
              </p>
            }
          >
            {array.map((item) => (
              <div className="product-list_wrapper-detail-store">
                <div className="product-list_header">
                  <div className="label text-1-line">{item?.name}</div>
                </div>
                <div className="product-section-store">
                  {item?.data.length > 0 && (
                    <div className="best-seller__container product-section-child">
                      <div className="products__container">
                        {item?.data.map((product) => (
                          <FoodItem
                            key={product.id}
                            id={product.id}
                            code={product?.code}
                            // type={FOOD_ITEM_TYPES.DETAILS} // must have
                            title={product?.name?.vi}
                            imgSrc={product?.image}
                            description={product?.description?.default?.vi}
                            shortDescription={product?.description?.short?.vi}
                            originalPrice={product?.price?.list}
                            currentPrice={product?.price?.default}
                            category={product?.category}
                            hideStore
                            isStoreClosed={props.isCloseStore}
                            isSelling={product.isSelling}
                            isOutOfStock={
                              product.isSelling === 0 || product.isOutOfStock
                            }
                            // navigateToSmartOrderMenu={navigateToSmartOrderMenu}
                            handleOpenMenuOrder={() =>
                              handleOpenMenuOrder(product)
                            }
                            dataCart={props.dataCart}
                            data={product}
                            saleTagUrl={product?.saleTagUrl}
                            percentDiscount={calDiscount(
                              product?.price?.list,
                              product?.price?.default
                            )}
                          />
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </InfiniteScroll>
          {array.length === 0 && (
            <div className="empty-product">
              <img src={imgEmptyProduct} />
              <p>Chưa có sản phẩm nào!</p>
            </div>
          )}
        </div>
      );
    },
    [props.dataCart]
  );

  const handleCloseMenuOrder = () => {
    setShowOrderMenu(false);
    setTimeout(() => {
      setChosenProduct(undefined);
    }, 500);
  };

  const handleOpenMenuOrder = (product, type) => () => {
    if (
      props.dataCart &&
      props.dataCart.length > 0 &&
      props.dataCart.filter((f) => f.dataInfoProduct?.productId === product?.id)
        .length > 0 &&
      !type
    ) {
      setChosenProduct(
        props.dataCart.filter(
          (f) => f.dataInfoProduct?.productId === product?.id
        )
      );
      setShowQuantityMenu(true);
    } else {
      setChosenProduct((prevChosenProduct) => {
        if (prevChosenProduct?.id === product.id) {
          return prevChosenProduct;
        }
        const found = product;

        return found;
      });
      setShowOrderMenu(true);
    }
  };

  const handleCloseQuantityMenu = () => {
    setShowQuantityMenu(false);

    // set time out để tránh hiện tượng giật cục khi đóng popup
    // chú thích ở hàm handleCloseMenuOrder
    setTimeout(() => {
      setItemsToUpdateQuantity([]);
    }, 500);
  };

  const handleOpenEditMenu = (itemData) => () => {
    // setShowQuantityMenu(false);

    // bên trong hàm này có set lại itemsToUpdateQuantity về []
    // tránh được case:
    // đang mở QuantityMenu -> set quantity về 0 -> ấn nút "Thay đổi" để vào EditMenu -> tắt EditMenu -> mở lại QuantityMenu thì còn các items cũ (state cũ là 0)
    handleCloseQuantityMenu();

    const itemToEdit = props?.dataCart.find(
      (item) => item.dataInfoProduct?.index === itemData?.dataInfoProduct?.index
    );

    setItemInCartToEdit({
      ...itemToEdit,
    });
    setShowEditMenu(true);
  };

  const handleCloseEditMenu = () => {
    if (showQuantityMenu) {
      setShowQuantityMenu(false);
    }
    setShowEditMenu(false);

    // set time out để tránh hiện tượng giật cục khi đóng popup
    // chú thích ở hàm handleCloseMenuOrder
    setTimeout(() => {
      setItemInCartToEdit(undefined); // must have if not want to get bug
    }, 400);
  };

  const handleShareStore = useCallback(
    async (dataStore, url) => {
      if (navigator.share) {
        try {
          await navigator.share({
            title: "Chia sẻ liên kết của cửa hàng",
            text: `Bạn được giới thiệu trải nghiệm ${props?.dataStoreMerchant?.name?.vi} tại chợ ${props.storeInfo?.name} trên ứng dụng Utop. Nhấn link bên dưới để đăng ký và nhận ưu đãi\n${url}`,
          });
        } catch (err) {
          console.error(err);
        }
      } else {
        toast.error(`Không thể chia sẻ trên trình duyệt này`, toastOptions);
      }
    },
    [props.storeInfo?.name, props?.dataStoreMerchant]
  );

  const handleGetShortLinkToShare = (dataStore) => {
    const payload = {};
    payload.callbackUrl = window.location.href;
    payload.imageUrl = "";
    dispatch(
      getShortLinkToShare(
        { ...payload },
        (res) => {
          handleShareStore(dataStore, res?.data?.url);
        },
        (err) => {
          console.log("errShare", err);
        }
      )
    );
  };

  return (
    <div className="store-detail-market-page">
      <div className="buttons-bar--fixed-top">
        <IconButton onClick={goBack} className="button-back--top-left">
          <i>
            <ArrowBackWhiteIcon />
          </i>
        </IconButton>
      </div>
      <div className="buttons-bar--fixed-top-right">
        <button
          type="button"
          className="button__cover-img--circle button__effect _ml-12px"
          onClick={goToSearch}
        >
          <img src={SVG.LoupeWhite} alt="loupe icon" width={13} height={13} />
        </button>
      </div>
      <div
        id="store-header"
        className="store-header__wrapper"
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          zIndex: 3,
          opacity: 0,
        }}
      >
        <>
          <StoreHeader
            idSearchInput="store-detail__store-header__search-input"
            storeName={props.dataStoreMerchant?.name?.vi}
            handleChangeSearchStatus={() => {}}
            goBack={goBack}
            onCommitInputSearch={() => {}}
            goToSearch={goToSearch}
          />
          <CategoryList categories={tabsListOfStoreView} />
        </>
      </div>

      <div>
        <StoreInfo
          id={props?.dataStoreMerchant?.id}
          isLoading={props?.loadingPager?.["all"]?.isLoading || false}
          images={props.dataStoreMerchant?.images}
          name={props?.dataStoreMerchant?.name?.vi}
          phoneNumber={props?.dataStoreMerchant?.phone}
          description={props?.dataStoreMerchant?.description}
          // selector
          handleShareStore={handleGetShortLinkToShare}
          //   handleOpenPopupSuggestedCouponList={
          //     handleOpenPopupSuggestedCouponList
          //   }
          //   handleSaveCoupon={handleSaveCoupon}
          //   handleGotoDetailCoupon={handleGotoDetailCoupon}
        />
      </div>
      <div id="store__head_main-product" className="content">
        {renderListProduct(props?.data, props?.categories)}
      </div>
      <CartBottomBar
        // to="/order"
        eventHandle={() =>
          NavigationServices.navigate("CartMarket", {
            isFromMarket: true,
            storeInfo: props.storeInfo,
          })
        }
        totalPrice={
          props.dataCart.length > 0
            ? props.dataCart
                .map((i) => i.dataInfoProduct.totalPrice)
                .reduce((a, b) => a + b)
            : 0
        }
        itemsCount={
          props.dataCart.length > 0
            ? props.dataCart
                .map((i) => i.dataInfoProduct.quantity)
                .reduce((a, b) => a + b)
            : 0
        }
        className="store-detail-page__cart-bottom-bar"
        text="Xem giỏ hàng"
        disabled={props.dataCart.length > 0 && !props.isCloseStore ? 0 : 1}
        // todo: check thêm khi ấn nút này, có thể bị phá khi mở devtool
      />

      <Popup
        isOpen={showOrderMenu}
        handleClose={handleCloseMenuOrder}
        // showButtonClose={false}
      >
        {chosenProduct && (
          <OrderMenu
            showOrderMenu={showOrderMenu}
            storeId={storeId}
            // merchantId={storeInfo?.data?.merchant?.iv?.[0]}
            id={chosenProduct?.id}
            // code={chosenProduct?.data?.code?.iv}
            imageUrl={chosenProduct?.image}
            imageUrls={chosenProduct?.imageUrls}
            name={chosenProduct?.name?.vi}
            price={chosenProduct?.price?.default}
            listPrice={chosenProduct?.price?.list}
            description={chosenProduct?.description?.default?.vi}
            attributes={chosenProduct.attributes}
            sizes={chosenProduct?.sizes}
            toppings={chosenProduct?.toppings}
            rawData={chosenProduct}
            // functions
            handleClose={handleCloseMenuOrder}
            updateCart={props.updateCart}
            isStoreClosed={props.isCloseStore}
            itemCart={
              props?.dataCart.length > 0
                ? props?.dataCart[props?.dataCart.length - 1].dataInfoProduct
                    .index
                : 0
            }
            // handleTempSaveInfoOfItemToAdd={handleTempSaveInfoOfItemToAdd}
            // handleToggleModal={handleToggleModal}
            saleTagUrl={chosenProduct?.saleTagUrl}
            percentDiscount={calDiscount(
              chosenProduct?.price?.list,
              chosenProduct?.price?.default
            )}
          />
        )}
      </Popup>
      <Popup
        isOpen={showQuantityMenu}
        handleClose={handleCloseQuantityMenu}
        // showButtonClose={false}
      >
        {showQuantityMenu && chosenProduct && (
          <QuantityMenu
            // productId={itemsToUpdateQuantity?.[0].productId}
            items={chosenProduct}
            // * handle popups
            handleOpenMenuOrder={handleOpenMenuOrder}
            handleOpenEditMenu={handleOpenEditMenu}
            handleCloseQuantityMenu={handleCloseQuantityMenu}
            // * handle actions
            handleUpdateQuantityOfItem={props.updateProductCart}
            handleRemoveItemInCart={props.deleteProductCart}
          />
        )}
      </Popup>
      <Popup
        isOpen={showEditMenu}
        handleClose={handleCloseEditMenu}
        // showButtonClose={false}
      >
        {itemInCartToEdit && (
          <EditMenu
            showEditMenu={showEditMenu}
            itemId={itemInCartToEdit?.dataInfoProduct?.productId}
            chosenAttributes={itemInCartToEdit?.dataInfoProduct?.attributes}
            chosenToppings={itemInCartToEdit?.dataInfoProduct?.toppings}
            chosenSize={itemInCartToEdit?.dataInfoProduct?.sizeOption}
            chosenQuantity={itemInCartToEdit?.dataInfoProduct?.quantity}
            itemPrice={itemInCartToEdit?.data?.price?.default}
            // originalData
            itemData={itemInCartToEdit}
            id={itemInCartToEdit?.data?.id}
            code={itemInCartToEdit?.data?.code}
            imageUrl={itemInCartToEdit?.data?.image}
            imageUrls={itemInCartToEdit?.data?.imageUrls}
            name={itemInCartToEdit?.data?.name?.vi}
            price={itemInCartToEdit?.data?.price?.default}
            listPrice={itemInCartToEdit?.data?.price?.list}
            description={itemInCartToEdit?.data?.description?.default}
            sizes={itemInCartToEdit?.data?.sizes}
            attributes={itemInCartToEdit?.data?.attributes}
            toppings={itemInCartToEdit?.data?.toppings}
            handleClose={handleCloseEditMenu}
            handleCloseQuantityMenu={handleCloseQuantityMenu}
            handleUpdateItem={props.updateCart}
            updateCart={props.updateProductCart}
          />
        )}
      </Popup>
      <AlertAction
        isOpen={props?.dataAlertAction?.isShow}
        content={props?.dataAlertAction?.content}
        title={props?.dataAlertAction?.title}
        titleAccept={props?.dataAlertAction?.titleAccept}
        titleCancel={props?.dataAlertAction?.titleCancel}
        handleClose={props?.setDataAlertAction}
        handleAccept={props?.handleAlertAction}
        dataItem={props?.dataAlertAction?.dataItem}
      />
    </div>
  );
};

export default StoreDetailMarket;
