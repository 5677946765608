import {
  GET_LIST_CITY_REQUEST,
  GET_LIST_CITY_SUCCESS,
  GET_LIST_CITY_FAILED,
  GET_CITY_BY_LOCATION_REQUEST,
  GET_CITY_BY_LOCATION_SUCCESS,
  GET_CITY_BY_LOCATION_FAILED,
} from './constants';

export const getListCityHandle = () => ({
  type: GET_LIST_CITY_REQUEST,
});

export const getListCitySuccess = cities => ({
  type: GET_LIST_CITY_SUCCESS,
  cities,
});

export const getListCityFailed = error => ({
  type: GET_LIST_CITY_FAILED,
  error,
});

export const getCityByLocationHandle = (location, onSuccess, onError) => ({
  type: GET_CITY_BY_LOCATION_REQUEST,
  location,
  onSuccess,
  onError,
});

export const getCityByLocationSuccess = city => ({
  type: GET_CITY_BY_LOCATION_SUCCESS,
  city,
});

export const getCityByLocationFailed = error => ({
  type: GET_CITY_BY_LOCATION_FAILED,
  error,
});
