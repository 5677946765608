import React, { useCallback, useLayoutEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useToggleBodyScroll } from "hooks";

import classNames from "classnames";

import { PageHeader, Post, ReplyInputBox } from "..";

import "./DetailRatingPost.scss";

function DetailRatingPost(props) {
  const {
    show,
    handleClose,
    ratingPost,
    handleUnlikeCmt,
    handleLikeCmt,
    handleOpenUserLikedList,
    handleLikeSubCmt,
    handleUnLikeSubCmt,
    handleSeeAllRating,
    handleSubmitReply,
  } = props;

  const { userInfo } = useSelector((state) => state.root);
  const [focusOnReplyBox, setFocusOnReplyBox] = useState(true);
  useToggleBodyScroll({ shouldFreeze: show });

  useLayoutEffect(() => {
    if (show) {
      document
        .getElementById("detail-rating-post__post-wrapper")
        ?.scrollIntoView({
          behavior: "smooth",
          inline: "nearest",
          block: "start",
        });

      setFocusOnReplyBox(true);
    }
  }, [show, setFocusOnReplyBox]);

  const handleClickToRepliesButton = useCallback(() => {
    setFocusOnReplyBox(true);
  }, [setFocusOnReplyBox]);

  return (
    <div
      className={classNames(
        "detail-rating-post__container",
        show && "detail-rating-post__container--show",
        !show && "detail-rating-post__container--hide"
      )}
      id="detail-rating-post__container"
    >
      <PageHeader
        title={`Đánh giá của ${ratingPost?.creatorName}`}
        onNavigate={handleClose}
      />

      {/* 
      // * content-container with overflow: overflow; để scrollIntoView ko bị khuất 1 phần (do header fixed)
      // * nếu không có div này bọc thì sẽ lấy theo container bên ngoài và có PageHeader => sẽ bị che
       */}
      <div className="detail-rating-post__content-container">
        {ratingPost && (
          <div
            className="detail-rating-post__post-wrapper"
            id="detail-rating-post__post-wrapper"
          >
            <Post
              idElement={`post_${ratingPost.id}`}
              rating={ratingPost}
              handleUnlikeCmt={handleUnlikeCmt}
              handleLikeCmt={handleLikeCmt}
              handleOpenUserLikedList={handleOpenUserLikedList}
              handleLikeSubCmt={handleLikeSubCmt}
              handleUnLikeSubCmt={handleUnLikeSubCmt}
              handleSeeAllRating={handleSeeAllRating}
              handleClickToRepliesButton={handleClickToRepliesButton}
            />
          </div>
        )}
      </div>

      {ratingPost && (
        <div className="reply-input-box--fixed">
          <ReplyInputBox
            idElement={`reply-box_${ratingPost.id}`}
            idRatingPost={ratingPost?.id}
            handleSubmitReply={handleSubmitReply}
            currentUserAvatar={userInfo?.photoURL}
            showSendButton
            isFocus={focusOnReplyBox}
            handleOutFocus={() => {
              setFocusOnReplyBox(false);
            }}
            isOnDetailRatingPost
          />
        </div>
      )}
    </div>
  );
}

export default DetailRatingPost;
