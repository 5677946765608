import types from './types';

export const getOrderComment = payload => ({
  type: types.ORDER_GET_COMMENT,
  payload,
});

export const createOrderComment = payload => ({
  type: types.ORDER_POST_COMMENT,
  payload,
});

export const updateComment = payload => ({
  type: types.ORDER_UPDATE_COMMENT,
  payload,
});

export const deleteComment = payload => ({
  type: types.ORDER_DELETE_COMMENT,
  payload,
});

export const likeRating = payload => ({
  type: types.LIKE_STORE_RATING,
  payload,
});

export const likeReply = payload => ({
  type: types.LIKE_STORE_REPLY,
  payload,
});

export const getRatingSum = payload => ({
  type: types.GET_RATING_SUM,
  payload,
});

export const getListUserLike = payload => ({
  type: types.GET_LIST_USER_LIKE,
  payload,
});

export const getListReasonReport = payload => ({
  type: types.GET_LIST_REASON_REPORT,
  payload,
});

export const sendReportRating = payload => ({
  type: types.SEND_REPORT_RATING,
  payload,
});

export const getOrderNotRating = payload => ({
  type: types.GET_ORDER_NOT_RATING,
  payload,
});
