import APIUtils from 'utils/apiUtils';
import {END_POINTS} from '@shared/constants/apiUrl';

export function getStoreDetailApi({payload}) {
  return APIUtils.get(END_POINTS.GET_INFO_STORE_URL + payload, {
    method: 'GET',
  });
}
export function getStoreDetailFromStoreCodeApi({payload}) {
  return APIUtils.get(END_POINTS.GET_INFOSTORE_BY_STORE_CODE + payload, {
    method: 'GET',
  });
}
export function getMerchantInfoApi({payload}) {
  return APIUtils.get(END_POINTS.GET_MERCHANT_INFO_URL + payload, {
    method: 'GET',
  });
}

export function getListVoucherByStoreApi(payload) {
  return APIUtils.get(
    END_POINTS.CMS_GET_VOUCHER_BY_STORE_URL +
      '?storeId=' +
      payload.storeId +
      '&merchantId=' +
      payload.merchantId,
    {
      method: 'GET',
    },
  );
}

export function getListCouponSuggestApi(payload) {
  let merchantId = payload.merchantId ? payload.merchantId : '';
  let storeId = payload.storeId ? payload.storeId : '';
  let feature = payload.feature ? payload.feature : '';

  let apiLinks = END_POINTS.GET_SUGGEST_COUPON + '?MerchantId=' + merchantId;
  if (storeId !== '') {
    apiLinks += '&StoreId=' + storeId;
  }
  if (feature !== '') {
    apiLinks += '&Feature=' + feature;
  }
  return APIUtils.get(apiLinks, {});
}

export function getListBestSellerApi(payload) {
  const apiLinks = END_POINTS.GET_DATA_FNB_URL + payload + '/productsbytags';

  return APIUtils.get(apiLinks, {
    method: 'GET',
  });
}
