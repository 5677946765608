/* eslint-disable no-param-reassign */
import { addErrors, delErrors } from "utils/ErrorsGenerator";
import * as TYPE from "./constants";

const reducers = {
  [TYPE.GET_VOUCHER_INFO.success]: (draft, { payload }) => {
    return {
      ...draft,
      errors: delErrors(draft.errors, TYPE.GET_VOUCHER_INFO.failure),
      voucherInfo: payload,
    };
  },
  [TYPE.GET_VOUCHER_INFO.failure]: (draft, { payload }) => {
    draft.errors = addErrors(draft.errors, {
      type: TYPE.GET_VOUCHER_INFO.failure,
      msg: payload,
    });
  },

  [TYPE.GET_SUGGESTED_COUPON_LIST.success]: (draft, { payload }) => {
    return {
      ...draft,
      errors: delErrors(draft.errors, TYPE.GET_SUGGESTED_COUPON_LIST.failure),
      suggestedCouponList: payload,
    };
  },
  [TYPE.GET_SUGGESTED_COUPON_LIST.failure]: (draft, { payload }) => {
    draft.errors = addErrors(draft.errors, {
      type: TYPE.GET_SUGGESTED_COUPON_LIST.failure,
      msg: payload,
    });
  },

  // * CALC_COUPON_DISCOUNT_V2
  [TYPE.CALC_COUPON_DISCOUNT_V2.request]: (draft) => {
    draft.isLoading.CALC_COUPON_DISCOUNT_V2 = true;
    draft.voucherCodeOfAppliedCoupon = undefined;
  },
  [TYPE.CALC_COUPON_DISCOUNT_V2.success]: (draft, { payload }) => {
    draft.errors = delErrors(
      draft.errors,
      TYPE.CALC_COUPON_DISCOUNT_V2.failure
    );
    draft.discountAmountFromAppliedCoupon = payload.discountAmount;
    draft.voucherCodeOfAppliedCoupon = payload.voucherCode;
    draft.isLoading.CALC_COUPON_DISCOUNT_V2 = false;
  },
  [TYPE.CALC_COUPON_DISCOUNT_V2.failure]: (draft, { payload }) => {
    draft.errors = addErrors(draft.errors, {
      type: TYPE.CALC_COUPON_DISCOUNT_V2.failure,
      msg: payload,
    });
    draft.discountAmountFromAppliedCoupon = 0;
    draft.voucherCodeOfAppliedCoupon = undefined;
    draft.isLoading.CALC_COUPON_DISCOUNT_V2 = false;
  },

  // * CREATE_ORDER_VOUCHER
  [TYPE.CREATE_ORDER_VOUCHER.request]: (draft) => {
    draft.isLoading.CREATE_ORDER_VOUCHER = true;
  },
  [TYPE.CREATE_ORDER_VOUCHER.success]: (draft, { payload }) => {
    draft.errors = delErrors(draft.errors, TYPE.CREATE_ORDER_VOUCHER.failure);
    draft.createdOrder = payload;
    draft.isLoading.CREATE_ORDER_VOUCHER = false;
  },
  [TYPE.CREATE_ORDER_VOUCHER.failure]: (draft, { payload }) => {
    draft.errors = addErrors(draft.errors, {
      type: TYPE.CREATE_ORDER_VOUCHER.failure,
      msg: payload,
    });

    draft.isLoading.CREATE_ORDER_VOUCHER = false;
  },
  [TYPE.CLEAR_ORDER_VOUCHER_INFO]: (draft) => {
    return {
      ...draft,
      createdOrder: undefined,
    };
  },
};

export default reducers;
