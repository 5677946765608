/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
//   import SVG from "assets/svg";
// import { getOpeningTimeInfoOfToday } from "utils/getOpeningInfo";

// import {
//   calcTotalPriceAtStore,
//   calcTotalQuantity,
// } from "utils/calcFnBItemPrice";

// import { LoadingOverlay } from "views/common/component";
import Popup from "views/common/component/Popup";

import AlertAction from "views/common/component/AlertAction";
import { NavigationServices } from "utils";
import OrderMenu from "../MarketHomeView/components/OrderMenu";
import StoreHeader from "../StoreDetailMarket/components/StoreHeader";

import "./MarketProduct.scss";
import CartBottomBar from "../MarketHomeView/components/CartBottomBar";
import TabListProduct from "./components/TabListProcudt";
import QuantityMenu from "../MarketHomeView/components/QuantityMenu";
import EditMenu from "../MarketHomeView/components/EditMenu";

const MarketProductView = (props) => {
  const [chosenProduct, setChosenProduct] = useState();
  const [itemInCartToEdit, setItemInCartToEdit] = useState();

  // for store header
  const isScrollDown = useRef();

  // popups states
  const [showOrderMenu, setShowOrderMenu] = useState(false);
  const [showQuantityMenu, setShowQuantityMenu] = useState(false);
  const [showEditMenu, setShowEditMenu] = useState(false);

  // INITIALIZATION
  useEffect(() => {
    window.scrollTo({ top: 0 });

    // set default scroll direction to DOWN
    isScrollDown.current = true;
  }, []);

  const handleOpenMenuOrder = (product, type) => () => {
    if (
      props.dataCart &&
      props.dataCart.length > 0 &&
      props.dataCart.filter((f) => f.dataInfoProduct?.productId === product?.id)
        .length > 0 &&
      !type
    ) {
      setChosenProduct(
        props.dataCart.filter(
          (f) => f.dataInfoProduct?.productId === product?.id
        )
      );
      setShowQuantityMenu(true);
    } else {
      setChosenProduct((prevChosenProduct) => {
        if (prevChosenProduct?.id === product.id) {
          return prevChosenProduct;
        }
        const found = product;

        return found;
      });
      setShowOrderMenu(true);
    }
  };

  const handleCloseMenuOrder = () => {
    setShowOrderMenu(false);

    setTimeout(() => {
      setChosenProduct(undefined);
    }, 500);
  };

  const handleCloseEditMenu = () => {
    if (showQuantityMenu) {
      setShowQuantityMenu(false);
    }
    setShowEditMenu(false);

    // set time out để tránh hiện tượng giật cục khi đóng popup
    setTimeout(() => {
      setItemInCartToEdit(undefined); // must have if not want to get bug
    }, 400);
  };

  // NAVIGATE
  const goBack = () => {
    NavigationServices.goBack();
  };

  const goToSearch = () => {
    NavigationServices.navigate("marketsearch", {
      storeInfo: props?.storeInfo,
      isCloseStore: props?.isCloseStore,
    });
  };

  const handleCloseQuantityMenu = () => {
    setShowQuantityMenu(false);

    // set time out để tránh hiện tượng giật cục khi đóng popup
    // chú thích ở hàm handleCloseMenuOrder
  };

  const handleOpenEditMenu = (itemData) => () => {
    // bên trong hàm này có set lại itemsToUpdateQuantity về []
    // tránh được case:
    // đang mở QuantityMenu -> set quantity về 0 -> ấn nút "Thay đổi" để vào EditMenu -> tắt EditMenu -> mở lại QuantityMenu thì còn các items cũ (state cũ là 0)
    handleCloseQuantityMenu();

    const itemToEdit = props?.dataCart.find(
      (item) => item.dataInfoProduct?.index === itemData?.dataInfoProduct?.index
    );

    setItemInCartToEdit({
      ...itemToEdit,
    });
    setShowEditMenu(true);
  };

  const calDiscount = (listPrice, price) => {
    let percentDiscount = 0;
    if (!!listPrice && !!price) {
      percentDiscount = 100 - Math.round((price / listPrice) * 100);
      if (percentDiscount === 100) {
        percentDiscount -= 1;
      }
    }
    return percentDiscount;
  };

  return (
    <div className="store-detail-page position-relative">
      {/* button share & search on cover images */}
      <div className="store-header__wrapper">
        <StoreHeader
          idSearchInput="store-detail__store-header__search-input"
          storeName={props.name || "Danh sách sản phẩm"}
          handleChangeSearchStatus={() => {}}
          goBack={goBack}
          onCommitInputSearch={() => {}}
          goToSearch={goToSearch}
        />
      </div>
      <div className="product-list_content">
        <TabListProduct
          categories={props.categories}
          data={props.data}
          currentSectionRef={props.currentSectionRef}
          onPageSelected={props.onPageSelected}
          onLoadMore={props.onLoadMore}
          isLoading={props?.loadingPager}
          isEndListAll={props?.isEndListAll}
          loadingPagerRef={props?.loadingPagerRef}
          handleOpenMenuOrder={(product) => handleOpenMenuOrder(product)}
          dataCart={props.dataCart}
          isCloseStore={props.isCloseStore}
          goBack={goBack}
          goToSearch={goToSearch}
          valueLoadMore={props.valueLoadMore}
          setValueLoadMore={props.setValueLoadMore}
        />
      </div>
      <div style={{ height: 0 }} />

      {/* buffer height for fixed bottom bar */}
      <div style={{ height: 20 }} />

      <CartBottomBar
        // to="/cartmarket"
        eventHandle={props.onSubmit}
        totalPrice={
          props.dataCart.length > 0
            ? props.dataCart
                .map((i) => i.dataInfoProduct.totalPrice)
                .reduce((a, b) => a + b)
            : 0
        }
        itemsCount={
          props.dataCart.length > 0
            ? props.dataCart
                .map((i) => i.dataInfoProduct.quantity)
                .reduce((a, b) => a + b)
            : 0
        }
        className="store-detail-page__cart-bottom-bar"
        text="Xem giỏ hàng"
        disabled={props.dataCart.length > 0 && !props.isCloseStore ? 0 : 1}
        onSubmit={props.onSubmit}
        // todo: check thêm khi ấn nút này, có thể bị phá khi mở devtool
      />

      <Popup
        isOpen={showOrderMenu}
        handleClose={handleCloseMenuOrder}
        // showButtonClose={false}
      >
        {chosenProduct && (
          <OrderMenu
            showOrderMenu={showOrderMenu}
            itemDetail={chosenProduct}
            storeId={props?.storeInfo?.id}
            // merchantId={storeInfo?.data?.merchant?.iv?.[0]}
            id={chosenProduct?.id}
            // code={chosenProduct?.data?.code?.iv}
            imageUrl={chosenProduct?.image}
            imageUrls={chosenProduct?.imageUrls}
            name={chosenProduct?.name?.vi}
            price={chosenProduct?.price?.default}
            listPrice={chosenProduct?.price?.list}
            description={chosenProduct?.description?.default?.vi}
            attributes={chosenProduct.attributes}
            sizes={chosenProduct?.sizes}
            toppings={chosenProduct?.toppings}
            rawData={chosenProduct}
            // functions
            handleClose={handleCloseMenuOrder}
            updateCart={props.updateCart}
            isStoreClosed={props.isCloseStore}
            itemCart={
              props?.dataCart.length > 0
                ? props?.dataCart[props?.dataCart.length - 1].dataInfoProduct
                    .index
                : 0
            }
            // handleTempSaveInfoOfItemToAdd={handleTempSaveInfoOfItemToAdd}
            // handleToggleModal={handleToggleModal}
            saleTagUrl={chosenProduct?.saleTagUrl}
            percentDiscount={calDiscount(
              chosenProduct?.price?.list,
              chosenProduct?.price?.default
            )}
          />
        )}
      </Popup>
      <Popup
        isOpen={showQuantityMenu}
        handleClose={handleCloseQuantityMenu}
        // showButtonClose={false}
      >
        {showQuantityMenu && chosenProduct && (
          <QuantityMenu
            // productId={itemsToUpdateQuantity?.[0].productId}
            items={chosenProduct}
            // * handle popups
            handleOpenMenuOrder={handleOpenMenuOrder}
            handleOpenEditMenu={handleOpenEditMenu}
            handleCloseQuantityMenu={handleCloseQuantityMenu}
            // * handle actions
            handleUpdateQuantityOfItem={props.updateProductCart}
            handleRemoveItemInCart={props.deleteProductCart}
          />
        )}
      </Popup>
      <Popup
        isOpen={showEditMenu}
        handleClose={handleCloseEditMenu}
        // showButtonClose={false}
      >
        {itemInCartToEdit && (
          <EditMenu
            showEditMenu={showEditMenu}
            itemId={itemInCartToEdit?.dataInfoProduct?.productId}
            chosenAttributes={itemInCartToEdit?.dataInfoProduct?.attributes}
            chosenToppings={itemInCartToEdit?.dataInfoProduct?.toppings}
            chosenSize={itemInCartToEdit?.dataInfoProduct?.sizeOption}
            chosenQuantity={itemInCartToEdit?.dataInfoProduct?.quantity}
            itemPrice={itemInCartToEdit?.data?.price?.default}
            // originalData
            itemData={itemInCartToEdit}
            id={itemInCartToEdit?.data?.id}
            code={itemInCartToEdit?.data?.code}
            imageUrl={itemInCartToEdit?.data?.image}
            imageUrls={itemInCartToEdit?.data?.imageUrls}
            name={itemInCartToEdit?.data?.name?.vi}
            price={itemInCartToEdit?.data?.price?.default}
            listPrice={itemInCartToEdit?.data?.price?.list}
            description={itemInCartToEdit?.data?.description?.default}
            sizes={itemInCartToEdit?.data?.sizes}
            attributes={itemInCartToEdit?.data?.attributes}
            toppings={itemInCartToEdit?.data?.toppings}
            handleClose={handleCloseEditMenu}
            handleCloseQuantityMenu={handleCloseQuantityMenu}
            handleUpdateItem={props.updateCart}
            updateCart={props.updateProductCart}
          />
        )}
      </Popup>

      <AlertAction
        isOpen={props?.dataAlertAction?.isShow}
        content={props?.dataAlertAction?.content}
        title={props?.dataAlertAction?.title}
        titleAccept={props?.dataAlertAction?.titleAccept}
        titleCancel={props?.dataAlertAction?.titleCancel}
        handleClose={props?.setDataAlertAction}
        handleAccept={props?.handleAlertAction}
        dataItem={props?.dataAlertAction?.dataItem}
      />
    </div>
  );
};

export default MarketProductView;
