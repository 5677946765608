import {
  MARK_USED_VOUCHER_REQUEST,
  MARK_USED_VOUCHER_SUCCESS,
  MARK_USED_VOUCHER_FAILED,
} from './constants';

export const markUsedVoucherRequest = payload => ({
  type: MARK_USED_VOUCHER_REQUEST,
  payload,
});

export const markUsedVoucherSuccess = payload => ({
  type: MARK_USED_VOUCHER_SUCCESS,
  payload,
});

export const markUsedVoucherFailed = payload => ({
  type: MARK_USED_VOUCHER_FAILED,
  payload,
});
