/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
/* eslint-disable array-callback-return */
import SVG, {
  ArrowRightYellowIcon,
  CellPhoneIcon,
  ClockWithBackgroundIcon,
  CompassIcon,
  CouponSolidYellowIcon,
  LocationMarkerWithBackgroundIcon,
  RibbonIcon,
  ShareSolidYellowIcon,
  // ExclamationIcon,
  // ReceiveFoodOutlineIcon,
} from "assets/svg";
import classNames from "classnames";
import React, {
  useCallback,
  useMemo,
  useRef,
  useState,
  useEffect,
} from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import Skeleton from "react-loading-skeleton";
import { useScrollHorizontallyWithButtons } from "hooks";
import { MAP_MODES } from "constants/types";
import IMAGES from "assets/img";
// import { getOpeningTimeInfoOfToday } from "utils/getOpeningInfo";
import { getDistanceFromLatLonInKm } from "helpers/getDistanceFromLatLonInKm";
import { toVND } from "utils/currencyFormat";
import { IconList } from "views/common/component";
import { CustomSlider, IconButton } from "../../../../../common/component";
import "video-react/dist/video-react.css";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import { Pagination, EffectCube, Autoplay } from "swiper";
import "swiper/swiper.min.css";
import "swiper/swiper-bundle.min.css";
import "./StoreInfo.scss";
import { icons } from "assets/icons/index";
import { NavigationServices } from "utils";
import BannerMarket from "assets/img/BannerMarket.jpg";
import ReactPlayer from "react-player";

const OFFSET_TO_SCROLL_PER_CLICK = 300;

function StoreInfo(props) {
  const {
    idPivot, // id của element sẽ navigate tới khi chọn tab trên tab navigator
    isLoading,
    logoUrl,
    images = [],
    merchantImages = [],
    merchantClass,
    name,
    avgRate,
    avgPrice,
    openingTimes,
    // deliveryOptions,
    address,
    phoneNumber,
    location,
    tags,
    // * selector
    suggestedCouponList,
    // * actions
    handleShareStore,
    handleSaveCoupon,
    handleOpenPopupSuggestedCouponList,
    handleGotoDetailCoupon,
  } = props;
  const video_story_height = window.innerHeight * 0.85;
  const video_width = window.innerWidth;
  const [errorImage, setErrorImage] = useState(false);
  const [listImage, setListImage] = useState([]);
  const history = useHistory();
  const tagsContainerRef = useRef();
  const {
    arrowButtonsState,
    scrollHorizontal,
    handleTagsContainerScroll,
    BTN_STATE,
  } = useScrollHorizontallyWithButtons({
    containerRef: tagsContainerRef,
    items: tags,
  });
  const [swiperReviewStoryIndex, setSwiperReviewStoryIndex] = useState(0);
  const swiperReviewStoryRef = useRef(null);

  useEffect(() => {
    if (swiperReviewStoryRef) {
      if (
        listImage.length > 0 &&
        listImage[swiperReviewStoryIndex % listImage.length]?.type === 1
      ) {
        swiperReviewStoryRef.current?.swiper?.autoplay?.stop();
      } else {
        swiperReviewStoryRef.current?.swiper?.autoplay?.start();
      }
    }
  }, [swiperReviewStoryIndex]);
  const currentOpeningData = useMemo(() => {
    const isStoreOpenTemp =
      openingTimes &&
      (openingTimes.rangeTime === "in" || openingTimes.rangeTime === "all");
    return isStoreOpenTemp;
  }, [openingTimes]);

  const distance = useMemo(() => {
    const chosenLocationAtHomePage = JSON.parse(
      localStorage.getItem("myLocation")
    );

    if (
      chosenLocationAtHomePage?.lat &&
      chosenLocationAtHomePage?.long &&
      location?.latitude &&
      location?.longitude
    ) {
      return Number(
        getDistanceFromLatLonInKm(
          chosenLocationAtHomePage.lat,
          chosenLocationAtHomePage.long,
          location.latitude,
          location.longitude
        )
      ).toFixed(1);
    }

    return null;
  }, [location?.latitude, location?.longitude]);
  const goToAllImages = () => {
    NavigationServices.navigate(`/marketimages?storeId=${props.id}`);
  };
  const getOS = () => {
    const { userAgent } = window.navigator;
    const platform =
      window.navigator?.userAgentData?.platform ?? window.navigator.platform;
    const macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"];
    const windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"];
    const iosPlatforms = ["iPhone", "iPad", "iPod"];
    let os = null;
    console.log("platform", platform);
    console.log("userAgent", userAgent);
    if (macosPlatforms.indexOf(platform) !== -1) {
      os = "Mac OS";
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = "iOS";
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = "Windows";
    } else if (/Android/.test(userAgent)) {
      os = "Android";
    } else if (!os && /Linux/.test(platform)) {
      os = "Linux";
    }

    return os;
  };
  // * Open direction mode
  const handleOpenDirectionMode = useCallback(() => {
    let stateToSend = {
      mode: MAP_MODES.DIRECTION,
      storeLocation: { lat: location?.latitude, lng: location?.longitude },
      storeAddress: address,
    };
    const pinnedLocationAtHomePage = JSON.parse(
      localStorage.getItem("myLocation")
    );

    if (pinnedLocationAtHomePage?.lat && pinnedLocationAtHomePage?.long) {
      stateToSend = {
        ...stateToSend,
        customDeviceLocation: {
          lat: pinnedLocationAtHomePage?.lat,
          lng: pinnedLocationAtHomePage?.long,
        },
      };
    }

    history.push("/mapdirection", stateToSend);
  }, [location?.latitude, location?.longitude, address]);
  const onNextStory = () => {
    swiperReviewStoryRef.current?.swiper?.slideNext();
  };
  useEffect(() => {
    const items = [];

    if (Array.isArray(images) && images.length > 0) {
      items.push(...images);
    }

    if (Array.isArray(merchantImages) && merchantImages.length > 0) {
      items.push(...merchantImages);
    }
    setListImage(items);
  }, [images, merchantImages]);
  // *** RENDER FUNCTIONS
  const renderSlider = () => {
    const formatItems = (arr) => {
      return arr.map((i) => ({
        imageUrl: i.content,
        type: i.type,
      }));
    };

    return (
      <div style={{ height: 350 }}>
        {listImage.length > 0 ? (
          <Swiper
            activeIndex={0}
            ref={swiperReviewStoryRef}
            slidesPerView={1}
            spaceBetween={0}
            pagination={{
              clickable: true,
            }}
            className="banner-swiper"
            onSlideChange={(swiper) => {
              setSwiperReviewStoryIndex(swiper.activeIndex);
            }}
            autoplay={{
              delay: 1500,
              disableOnInteraction: false,
            }}
            // modules={[Autoplay]}
          >
            {listImage?.length > 0 &&
              listImage?.map((item, index) => {
                return (
                  <div style={{ height: 350, maxWidth: 800 }} key={index + 1}>
                    <SwiperSlide key={index + 1}>
                      {item.type === 1 ? (
                        index === swiperReviewStoryIndex % listImage.length ? (
                          <div style={{ height: 350 }}>
                            <ReactPlayer
                              url={item.content}
                              height="350px"
                              width="100%"
                              playing={true}
                              controls={false}
                              playsinline={true}
                              style={{ objectFit: "cover", width: "100%" }}
                              onEnded={(e) => {
                                onNextStory();
                              }}
                              muted={getOS() === "iOS"}
                            />
                          </div>
                        ) : (
                          <img className="item-image" src={item.thumbnail} />
                        )
                      ) : (
                        <div className="custom-slider">
                          <div
                            className={classNames(
                              "custom-slider__img__container"
                            )}
                          >
                            <img
                              src={item.content}
                              className={classNames("custom-slider__img--main")}
                            />

                            {/* blurred background */}
                            <img
                              src={item.content}
                              className="custom-slider__img--background--blur"
                            />
                          </div>
                        </div>
                      )}
                    </SwiperSlide>
                  </div>
                );
              })}
          </Swiper>
        ) : (
          <div className="custom-slider">
            <div className="custom-slider__img__container">
              <img
                src={BannerMarket}
                className={classNames("custom-slider__img--main")}
              />

              <img
                src={BannerMarket}
                className="custom-slider__img--background--blur"
              />
            </div>
          </div>
        )}
        <div className="custom-pagination">
          {listImage.length > 0 &&
            listImage.map((item, index) => {
              return (
                <div
                  className={
                    index === swiperReviewStoryIndex % listImage.length
                      ? "item-pagination item-pagination-active"
                      : "item-pagination item-pagination-inactive"
                  }
                  key={index}
                />
              );
            })}
        </div>
      </div>
    );
  };

  const renderCouponSection = useCallback(() => {
    if (!suggestedCouponList || !suggestedCouponList?.length > 0) {
      return <></>;
    }

    const firstCoupon = suggestedCouponList[0];

    return (
      <div className="store-info-card__row pl-16px ml-n-12px suggested-coupons__container">
        <div className="store-info-card__row__left-side">
          <i>
            <CouponSolidYellowIcon />
          </i>
        </div>

        <div
          className="store-info-card__row__content suggested-coupon__info"
          onClick={handleGotoDetailCoupon(firstCoupon)}
        >
          {firstCoupon && (
            <div className="text-on-1-line">{firstCoupon.name}</div>
          )}

          {firstCoupon.endDateTime && (
            <span className="coupon-date">
              Hết hạn:{" "}
              <span>
                {moment
                  .utc(firstCoupon.endDateTime)
                  .local()
                  .format("DD/MM/YYYY")}
              </span>
            </span>
          )}
        </div>

        {suggestedCouponList.length === 1 ? (
          <button
            type="button"
            className="store-info-card__row__right-side button__effect button-get-coupon"
            disabled={suggestedCouponList?.[0]?.isAllocated}
            onClick={() => handleSaveCoupon(suggestedCouponList?.[0])}
          >
            Lấy mã
          </button>
        ) : (
          <button
            type="button"
            className="store-info-card__row__right-side button-see-more"
            onClick={handleOpenPopupSuggestedCouponList}
          >
            Xem thêm
            <i>
              <ArrowRightYellowIcon />
            </i>
          </button>
        )}
      </div>
    );
  }, [suggestedCouponList]);

  const checkErrorImage = () => {
    setErrorImage(true);
  };

  const renderSkeleton = () => {
    return (
      <div className="store__head__container">
        <div className="store-banner">
          <Skeleton width="100%" height="100vw" style={{ maxHeight: 500 }} />
        </div>
        <div className="store-info-card">
          <div className="store-info-card__content-wrapper">
            <div className="store-info-card__top--over-top">
              <Skeleton width="16ch" height={30} />
            </div>

            <div className="store-info-card__body">
              <div className="name">
                <Skeleton width="75%" height={32} />
              </div>

              <div>
                <Skeleton width="12ch" height={18} />
              </div>

              <div>
                <Skeleton width="40%" height={24} />
              </div>

              <div style={{ marginTop: 12 }}>
                <Skeleton width="100%" height={46} />
              </div>

              <div style={{ marginTop: 12 }}>
                <Skeleton width="100%" height={32} />
              </div>
            </div>
          </div>

          <div className="store-info-card__tags hide-x-scrollbar">
            <Skeleton width="15ch" height="32px" />
            <Skeleton width="10ch" height="32px" />
            <Skeleton width="17ch" height="32px" />
          </div>
        </div>
      </div>
    );
  };

  if (isLoading) {
    return renderSkeleton();
  }

  return (
    <div className="store__head__container">
      <div
        id="store-detail-info-slider" // for control opacity when scroll page (handle in /storedetail)
        className="store-banner"
      >
        {renderSlider()}
      </div>

      <div id={idPivot} className="store-info-card">
        <div className="store-info-card__content-wrapper">
          {!!merchantClass && (
            <div className="store-info-card__top--over-top">
              <div className="merchant-class">
                <i>
                  <RibbonIcon />
                </i>
                <span>{merchantClass}</span>
              </div>

              {/* // ! tạm ẩn */}
              {/* <IconButton className="button-info">
                <ExclamationIcon />
              </IconButton> */}
            </div>
          )}

          <div className="store-info-card__body">
            <div className="name-button__wrapper">
              {logoUrl ? (
                !errorImage ? (
                  <img
                    className="logo-store"
                    src={logoUrl}
                    onError={checkErrorImage}
                  />
                ) : (
                  <img
                    className="logo-store"
                    src={IMAGES.LogoUtop2}
                    onError={checkErrorImage}
                  />
                )
              ) : (
                <img
                  className="logo-store"
                  src={IMAGES.LogoUtop2}
                  onError={checkErrorImage}
                />
              )}
              <div className="name">
                <div className="only-2-lines" style={{ maxHeight: "50px" }}>
                  {name?.toUpperCase()}
                </div>
                {avgRate !== null && avgRate !== 0 && (
                  <div className="avgRate">
                    <IconList
                      selectedNum={avgRate}
                      containerClassName="rating-star__container"
                    />
                    <div className="avgRate-text">
                      {avgRate && avgRate % 1 === 0 ? `${avgRate}.0` : avgRate}
                    </div>
                  </div>
                )}
              </div>
              <IconButton className="button-share" onClick={handleShareStore}>
                <ShareSolidYellowIcon />
              </IconButton>
            </div>

            {/* {avgRate && (
                  <IconList
                    selectedNum={Number(avgRate)}
                    containerClassName="rating-star__container"
                  />
                )} */}

            {!!avgPrice && (
              <div className="avg-price">
                <span>Trung bình: </span>
                <span className="avg-price__price">
                  {toVND(avgPrice)}/người
                </span>
              </div>
            )}

            <div className="store-info-card__row opening-times__container">
              <div className="store-info-card__row__left-side">
                <i>
                  <ClockWithBackgroundIcon />
                </i>
              </div>

              <span className="store-info-card__row__content opening-times__time">
                <span
                  className={classNames(
                    "opening-status",
                    currentOpeningData
                      ? "opening-status--opening"
                      : "opening-status--closed"
                  )}
                >
                  {`Đang ${currentOpeningData ? "mở" : "đóng"} cửa`}
                </span>

                {openingTimes && currentOpeningData && (
                  <>
                    {openingTimes.openTime?.from &&
                      openingTimes.openTime?.to && (
                        <div className="vertical-separator" />
                      )}
                    {openingTimes.openTime?.from && openingTimes.openTime?.to && (
                      <span className="current-opening-time-range">
                        {openingTimes.openTime?.from} -{" "}
                        {openingTimes.openTime?.to}
                      </span>
                    )}
                  </>
                )}

                {openingTimes && !currentOpeningData && (
                  <>
                    <div className="vertical-separator" />
                    <span>
                      {"Quay lại vào "}
                      <span className="comeback-time">
                        {props.openingTimes?.openNextTime}{" "}
                        {props.openingTimes?.timePoint}
                      </span>
                    </span>
                  </>
                )}
              </span>
            </div>

            {!!address && (
              <div className="store-info-card__row address">
                <div className="store-info-card__row__left-side">
                  <i>
                    <LocationMarkerWithBackgroundIcon />
                  </i>
                </div>

                <div className="store-info-card__row__content">
                  <div className="only-2-lines">{address}</div>

                  {distance !== null && (
                    <span className="distance">{`(${Number(
                      distance
                    ).toLocaleString("vi")} km)`}</span>
                  )}
                </div>

                <div className="store-info-card__row__right-side address__buttons">
                  <IconButton
                    onClick={handleOpenDirectionMode}
                    disabled={
                      !location?.latitude || !location?.longitude || !address
                    }
                  >
                    <CompassIcon />
                  </IconButton>

                  {!!phoneNumber && (
                    <a href={`tel:${phoneNumber}`}>
                      <IconButton>
                        <CellPhoneIcon />
                      </IconButton>
                    </a>
                  )}
                </div>
              </div>
            )}

            {/* coupon */}
            {renderCouponSection()}

            {/* //! tạm thời ẩn, dùng trong tương lai */}
            {/* <div className="divider-line" /> */}

            {/* Làm món ngay sau 30p */}
            {/* <div className="store-info-card__row pl-16px ml-n-12px ">
                  <div className="store-info-card__row__left-side">
                    <i>
                      <ReceiveFoodOutlineIcon />
                    </i>
                  </div>

                  <span className="store-info-card__row__content msg-text">Làm món ngay sau 30 phút</span>

                  <button type="button" className="store-info-card__row__right-side button-see-more">
                    Xem thêm
                    <i>
                      <ArrowRightYellowIcon />
                    </i>
                  </button>
                </div> */}
          </div>
        </div>

        {/* tags */}

        {tags?.length > 0 && (
          <div className="store-info-card__conatiner-scroll">
            {[BTN_STATE.SHOW_BUTTON_PREV, BTN_STATE.SHOW_BOTH_BUTTONS].includes(
              arrowButtonsState
            ) && (
              <img
                id="example"
                src={SVG.arrowPrev}
                alt="arrow prev"
                className="btn-prev-scroll"
                onClick={() => scrollHorizontal(-OFFSET_TO_SCROLL_PER_CLICK)}
              />
            )}
            <ul
              id="test"
              className="store-info-card__tags hide-x-scrollbar"
              ref={tagsContainerRef}
              onScroll={handleTagsContainerScroll}
            >
              {tags.map((tag, ind) => (
                <li className="tag only-2-lines" key={ind + 1}>
                  {tag}
                </li>
              ))}
            </ul>
            {[BTN_STATE.SHOW_BUTTON_NEXT, BTN_STATE.SHOW_BOTH_BUTTONS].includes(
              arrowButtonsState
            ) && (
              <img
                src={SVG.arrowNext}
                alt="arrow next"
                className="btn-next-scroll"
                onClick={() => scrollHorizontal(+OFFSET_TO_SCROLL_PER_CLICK)}
              />
            )}
          </div>
        )}
      </div>
      {props.showSeeMore && (
        <div
          className="see-more-image"
          onClick={() => {
            goToAllImages();
          }}
        >
          <img src={icons.iconAlbum} className="icon-album" /> Xem thêm
        </div>
      )}
    </div>
  );
}

export default StoreInfo;
