import { LOGOUT_REQUEST } from "@shared/redux/account/constants";
import {
  GET_HISTORY_POINT_REQUEST,
  GET_HISTORY_POINT_SUCCESS,
  GET_HISTORY_POINT_FAILED,
  GET_DETAIL_HISTORY_POINT_REQUEST,
  GET_DETAIL_HISTORY_POINT_SUCCESS,
  GET_DETAIL_HISTORY_POINT_FAILED,
} from "./constants";

const initialState = {
  listHistoryPoint: [],
  detailHistoryPoint: {},
  messageError: "",
  skip: 0,
  total: 0,
};

const historyPointReducer = (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case GET_HISTORY_POINT_REQUEST: {
      return {
        ...state,
        messageError: "",
      };
    }

    case GET_HISTORY_POINT_SUCCESS: {
      let { listHistoryPoint } = state;

      return {
        ...state,
        listHistoryPoint:
          payload.skip !== 0
            ? [...listHistoryPoint, ...payload.items]
            : payload.items,
        skip: payload.skip,
        total: payload.total,
      };
    }

    case GET_HISTORY_POINT_FAILED: {
      return {
        ...state,
        messageError: payload,
      };
    }
    case LOGOUT_REQUEST: {
      return {
        ...initialState,
      };
    }

    case GET_DETAIL_HISTORY_POINT_REQUEST: {
      return {
        ...state,
        messageError: "",
      };
    }

    case GET_DETAIL_HISTORY_POINT_SUCCESS: {
      return {
        ...state,
        detailHistoryPoint: payload,
      };
    }

    case GET_DETAIL_HISTORY_POINT_FAILED: {
      return {
        ...state,
        messageError: payload,
      };
    }
    default:
      return state;
  }
};

export default historyPointReducer;
