export const GET_PRODUCTS_REQUEST = 'smartMenu/GET_PRODUCTS_REQUEST';

export const GET_PRODUCTS_SUCCESS = 'smartMenu/GET_PRODUCTS_SUCCESS';

export const GET_PRODUCTS_FAILED = 'smartMenu/GET_PRODUCTS_FAILED';

export const GET_TOPPINGS_REQUEST = 'smartMenu/GET_TOPPINGS_REQUEST';

export const GET_TOPPINGS_SUCCESS = 'smartMenu/GET_TOPPINGS_SUCCESS';

export const GET_TOPPINGS_FAILED = 'smartMenu/GET_TOPPINGS_FAILED';

export const GET_CATEGORIES_REQUEST = 'smartMenu/GET_CATEGORIES_REQUEST';

export const GET_CATEGORIES_SUCCESS = 'smartMenu/GET_CATEGORIES_SUCCESS';

export const GET_CATEGORIES_FAILED = 'smartMenu/GET_CATEGORIES_FAILED';

export const CHECK_PRODUCT_OUT_OF_STOCK_REQUEST =
  'smartMenu/CHECK_PRODUCT_OUT_OF_STOCK_REQUEST';

export const CHECK_PRODUCT_OUT_OF_STOCK_FAILED =
  'smartMenu/CHECK_PRODUCT_OUT_OF_STOCK_FAILED';

export const CHECK_PRODUCT_OUT_OF_STOCK_SUCCESS =
  'smartMenu/CHECK_PRODUCT_OUT_OF_STOCK_SUCCESS';

export const ADD_PRODUCT_CART = 'smartMenu/ADD_PRODUCT_CART';
export const UPDATE_PRODUCT_CART = 'smartMenu/UPDATE_PRODUCT_CART';

export const UPDATE_PRODUCT_CART_OUT_OF_STOCK =
  'smartMenu/UPDATE_PRODUCT_CART_OUT_OF_STOCK';

export const DELETE_PRODUCT_CART = 'smartMenu/DELETE_PRODUCT_CART';

export const CLEAR_PRODUCT_CART = 'smartMenu/CLEAR_PRODUCT_CART';

export const SET_DATA_STORE = 'smartMenu/SET_DATA_STORE';

export const RESET_PRODUCT_CART = 'smartMenu/RESET_PRODUCT_CART';

export const CREATE_ORDER_FNB_REQUEST = 'smartMenu/CREATE_ORDER_FNB_REQUEST';
export const CREATE_ORDER_FNB_FAILED = 'smartMenu/CREATE_ORDER_FNB_FAILED';
export const CREATE_ORDER_FNB_SUCCESS = 'smartMenu/CREATE_ORDER_FNB_SUCCESS';

export const SET_INDEX_PRODUCT_CART = 'smartMenu/SET_INDEX_PRODUCT_CART';
export const SET_LIST_COUPON_SUGGEST_FOR_STORE =
  'smartMenu/SET_LIST_COUPON_SUGGEST_FOR_STORE';
export const UPDATE_LIST_COUPON_SUGGEST_FOR_STORE =
  'smartMenu/UPDATE_LIST_COUPON_SUGGEST_FOR_STORE';
export const SAVE_CURRENT_STORE_ID = 'smartMenu/SAVE_CURRENT_STORE_ID';
export const UPDATE_LIST_PRODUCT_CART = 'smartMenu/UPDATE_LIST_PRODUCT_CART';
export const SET_CREATE_NEW_LIST_DELIVERY =
  'smartMenu/SET_CREATE_NEW_LIST_DELIVERY';

export const CLEAR_PRODUCT_OF_STORE = 'smartMenu/CLEAR_PRODUCT_OF_STORE';
