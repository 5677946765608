import moment from 'moment';
import {
  SET_IS_CLEAR_DATA,
  SET_BUDGET_SELECTED,
  UPDATE_AVAILABLE_AMOUNT_BUDGET_SELECTED,
  GET_LIST_BUDGET_SUCCESS,
  GET_HISTORY_BUDGET_SUCCESS,
  GET_HISTORY_BUDGET_REQUEST,
  GET_HISTORY_BUDGET_FAILED,
  GET_TRANSACTION_HISTORY_UB_FAILED,
  GET_TRANSACTION_HISTORY_UB_SUCCESS,
  UPDATE_TRANSACTION_HISTORY_UB,
  CLEAR_BUDGET,
  UPDATE_BUDGET_HISTORY,
  GET_LIST_BUDGET_FAILED,
  SET_LIST_ACCOUNT_BUDGET,
} from './constants';

const initialState = {
  isClearData: false,
  budgetSelected: {},
  listBudget: [],
  listHistoryBudget: {},
  listTransactionHistoryUB: {},
  listAccountBudget: [],
};

const mergeMasterData = (dataOld, dataNew, isLoadNew) => {
  let masterData = dataOld;
  if (isLoadNew) {
    masterData = {};
  }
  for (var i = 0; i < dataNew.length; i++) {
    var groupName = moment.utc(dataNew[i].date).local().format('DD/MM/YYYY');
    if (!masterData[groupName]) {
      masterData[groupName] = [];
    }
    masterData[groupName].push(dataNew[i]);
  }
  return masterData;
};

const pushData = (dataOld, dataNew) => {
  let isExistAccount = false;
  let masterData = [...dataOld];
  if (dataNew) {
    if (masterData.length > 0) {
      masterData.forEach(item => {
        if (item.account === dataNew.account && item.orgId === dataNew.orgId) {
          isExistAccount = true;
        }
      });
    }

    if (!isExistAccount) {
      masterData.push(dataNew);
    }
  }

  return masterData;
};

const budgetReducer = (state = initialState, action) => {
  const {payload, type} = action;

  switch (action.type) {
    case SET_IS_CLEAR_DATA: {
      return {
        ...state,
        ...action,
      };
    }

    case SET_BUDGET_SELECTED: {
      return {
        ...state,
        ...action,
      };
    }

    case UPDATE_AVAILABLE_AMOUNT_BUDGET_SELECTED: {
      return {
        ...state,
        budgetSelected: {
          ...state.budgetSelected,
          availableAmount: payload,
        },
      };
    }

    case GET_LIST_BUDGET_SUCCESS: {
      return {
        ...state,
        ...action,
      };
    }

    case GET_LIST_BUDGET_FAILED: {
      return {
        ...state,
        budgetSelected: {},
      };
    }

    case GET_HISTORY_BUDGET_REQUEST: {
      return {
        ...state,
        listHistoryBudget: {
          ...state.listHistoryBudget,
          isLoadNew:
            typeof action.payload.isLoadNew !== 'undefined'
              ? action.payload.isLoadNew
              : true,
        },
      };
    }

    case GET_HISTORY_BUDGET_FAILED: {
      return {
        ...state,
        listHistoryBudget: {
          ...state.listHistoryBudget,
          isLoadNew: false,
          data: [],
        },
      };
    }

    case GET_HISTORY_BUDGET_SUCCESS: {
      return {
        ...state,
        listHistoryBudget: {
          ...state.listHistoryBudget,
          skip: action.listHistoryBudget ? action.listHistoryBudget.skip : 0,
          isLoadNew: false,
          data: mergeMasterData(
            state.listHistoryBudget.data,
            action.listHistoryBudget.data,
            action.listHistoryBudget.isLoadNew,
          ),
        },
      };
    }

    case GET_TRANSACTION_HISTORY_UB_FAILED: {
      return {
        ...state,
        listTransactionHistoryUB: {
          ...state.listTransactionHistoryUB,
          isLoadNew: false,
          data: [],
        },
      };
    }

    case GET_TRANSACTION_HISTORY_UB_SUCCESS: {
      return {
        ...state,
        listTransactionHistoryUB: {
          ...state.listTransactionHistoryUB,
          skip: action.listTransactionHistoryUB
            ? action.listTransactionHistoryUB.skip
            : 0,
          isLoadNew: false,
          data: mergeMasterData(
            state.listTransactionHistoryUB.data,
            action.listTransactionHistoryUB.data,
            action.listTransactionHistoryUB.isLoadNew,
          ),
        },
      };
    }

    case UPDATE_TRANSACTION_HISTORY_UB: {
      return {
        ...state,
        listTransactionHistoryUB: {},
      };
    }

    case UPDATE_BUDGET_HISTORY: {
      return {
        ...state,
        listHistoryBudget: {},
      };
    }

    case CLEAR_BUDGET: {
      return {
        ...state,
        ...initialState,
      };
    }

    case SET_LIST_ACCOUNT_BUDGET: {
      return {
        ...state,
        listAccountBudget: pushData(
          state.listAccountBudget,
          action.listAccountBudget,
        ),
      };
    }

    default:
      return state;
  }
};

export default budgetReducer;
