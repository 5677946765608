import { genActionTypes } from "utils/actionGenerator";

export const TYPE = "SendVoucher";

export const POST_SEND_VOUCHER = genActionTypes(`${TYPE}_POST_SEND_VOUCHER`);
export const GET_USER_BY_PHONE = genActionTypes(`${TYPE}_GET_USER_BY_PHONE`);
export const GET_SHORT_LINK_TO_SHARE = genActionTypes(
  `${TYPE}_GET_SHORT_LINK_TO_SHARE`
);
export const RESET_SEND_VOUCHER = `${TYPE}_RESET_SEND_VOUCHER`;
