import React, { useState, useCallback } from "react";
import moment from "moment";
import Skeleton from "react-loading-skeleton";
import classNames from "classnames";

import { ArrowRightBlackIcon } from "assets/svg";
// import Popup from "views/common/component/Popup";
import SwitchButton from "views/common/component/SwitchButton";

import { toVND } from "utils/currencyFormat";

import "./LoyaltyPointSection.scss";
import { Dock } from "views/common/component";

function LoyaltyPointSection(props) {
  const {
    isLoading,
    pointList,
    handleCommitLoyaltyPointToUse,
    loyaltyPointToUse,
    willUseLoyaltyPoint,
    handleToggleWillUseLoyaltyPoint,
    pointToUse,
  } = props;
  const [isOpenList, setIsOpenList] = useState(false);

  const handleOpenLoyaltyPointList = useCallback(() => {
    if (pointList.length > 0) {
      setIsOpenList(true);
    }
  }, [pointList]);

  const handleCloseLoyaltyPointList = useCallback(() => {
    setIsOpenList(false);
  }, []);

  const handleSelectPoint = useCallback(
    (point) => () => {
      handleCommitLoyaltyPointToUse(point);
      handleCloseLoyaltyPointList();
    },
    [handleCommitLoyaltyPointToUse]
  );

  // *** RENDER SKELETON
  if (isLoading) {
    return (
      <>
        <div className="loyalty-point">
          <div className="loyalty-point__top">
            <Skeleton width="7ch" className="loyalty-point__top__label" />

            <div className="loyalty-point__top__content">
              <Skeleton
                width="10ch"
                className="loyalty-point__top__content__discount-amount"
              />
            </div>
          </div>

          <div className="loyalty-point__body">
            <div className="loyalty-point__body__left">
              <div className="loyalty-point__body__left__point">
                <Skeleton width="10ch" />
              </div>
              <div className="loyalty-point__body__left__point-name text-on-1-line">
                <Skeleton width="30ch" />
              </div>
            </div>
          </div>
        </div>

        <div className="divider-line" />
      </>
    );
  }

  if (!loyaltyPointToUse) {
    return <></>;
  }

  return (
    <>
      <div className="loyalty-point">
        <div className="loyalty-point__top">
          <span className="loyalty-point__top__label">Sử dụng</span>

          <div className="loyalty-point__top__content">
            <span className="loyalty-point__top__content__discount-amount">
              {`[${toVND(
                pointToUse * loyaltyPointToUse.exchangingToVndRate,
                true
              )}]`}
            </span>
            <SwitchButton
              isChecked={willUseLoyaltyPoint}
              onToggle={handleToggleWillUseLoyaltyPoint}
            />
          </div>
        </div>

        <div
          className={classNames(
            "loyalty-point__body",
            pointList.length > 0 && "button__effect"
          )}
          onClick={handleOpenLoyaltyPointList}
        >
          <div className="loyalty-point__body__left">
            {typeof pointToUse === "number" && (
              <div className="loyalty-point__body__left__point">
                {`${Number(pointToUse).toLocaleString("vi")} điểm`}
              </div>
            )}
            <div className="loyalty-point__body__left__point-name text-on-1-line">
              {loyaltyPointToUse.pointName}
            </div>
          </div>

          <i>
            <ArrowRightBlackIcon />
          </i>
        </div>
      </div>

      <div className="divider-line" />

      <div className="loyalty-point-popup__container">
        <Dock
          show={isOpenList}
          handleClose={handleCloseLoyaltyPointList}
          showBtnClose
        >
          <div className="loyalty-point-popup__title text-1-line">
            Chọn điểm tích lũy
          </div>
          <div className="loyalty-point-list">
            {pointList.map((point) => (
              <div key={point.pointId} className="button__effect">
                <LoyaltyPointRow
                  pointName={point.pointName}
                  remainingPoint={point.remainingPoint}
                  exchangingToVndRate={point.exchangingToVndRate}
                  nearlyExpired={point.nearlyExpired}
                  isSelected={loyaltyPointToUse?.pointId === point.pointId}
                  onClick={handleSelectPoint(point)}
                />
              </div>
            ))}
          </div>
        </Dock>
      </div>
    </>
  );
}

export default LoyaltyPointSection;

const LoyaltyPointRow = (props) => {
  const {
    pointName,
    remainingPoint,
    exchangingToVndRate,
    nearlyExpired,
    isSelected = false,
    onClick,
    className,
  } = props;

  return (
    <div
      className={classNames("loyalty-point-list__row", className)}
      onClick={onClick}
    >
      <i
        className="loyalty-point-list__row__icon"
        status={isSelected ? "1" : "0"}
      />
      <div className="loyalty-point-list__row__content">
        <div className="loyalty-point-list__row__name only-2-lines">
          <span>{pointName}</span>

          {typeof remainingPoint === "number" && (
            <span className="remaining-point">{` ${Number(
              remainingPoint
            ).toLocaleString("vi")} điểm`}</span>
          )}
        </div>

        {typeof exchangingToVndRate === "number" && (
          <div className="loyalty-point-list__row__unit text-on-1-line">{`(1 điểm = ${Number(
            exchangingToVndRate
          ).toLocaleString("vi")} đ)`}</div>
        )}

        {typeof nearlyExpired?.amount === "number" &&
          nearlyExpired?.expirationDate && (
            <div className="loyalty-point-list__row__end-date only-2-lines">
              {`${Number(nearlyExpired.amount).toLocaleString(
                "vi"
              )} điểm sẽ hết hạn vào ${moment(
                nearlyExpired?.expirationDate
              ).format("DD/MM/YYYY")}`}
            </div>
          )}
      </div>
    </div>
  );
};
