import { genActionTypes } from "utils/actionGenerator";

export const TYPE = "VoucherView";

export const GET_VOUCHER_INFO = genActionTypes(`${TYPE}_GET_VOUCHER_INFO`);
export const GET_SUGGESTED_COUPON_LIST = genActionTypes(
  `${TYPE}_GET_SUGGESTED_COUPON_LIST`
);
export const SAVE_SUGGESTED_COUPON = genActionTypes(
  `${TYPE}_SAVE_SUGGESTED_COUPON`
);
export const GET_SHORT_LINK_TO_SHARE = genActionTypes(
  `${TYPE}_GET_SHORT_LINK_TO_SHARE`
);
export const CHECK_STOCK_OF_PRODUCT = genActionTypes(
  `${TYPE}_CHECK_STOCK_OF_PRODUCT`
);

// export const GET_DETAIL_VOUCHER_MORE = genActionTypes(
//   `${TYPE}_GET_DETAIL_VOUCHER_MORE`
// );
