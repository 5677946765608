export const GET_UTOP_POINT_REQUEST = 'account/GET_UTOP_POINT_REQUEST';

export const GET_UTOP_POINT_SUCCESS = 'account/GET_UTOP_POINT_SUCCESS';

export const GET_UTOP_POINT_FAILED = 'account/GET_UTOP_POINT_FAILED';

export const GET_USER_INFO_REQUEST = 'account/GET_USER_INFO_REQUEST';

export const GET_USER_INFO_SUCCESS = 'account/GET_USER_INFO_SUCCESS';

export const GET_USER_INFO_FAILED = 'account/GET_USER_INFO_FAILED';

export const UPDATE_ACCOUNT_INFO_REQUEST =
  'account/UPDATE_ACCOUNT_INFO_REQUEST';

export const UPDATE_ACCOUNT_INFO = 'account/UPDATE_ACCOUNT_INFO';

export const UPDATE_ACCOUNT_INFO_FAILED = 'account/UPDATE_ACCOUNT_INFO_FAILED';

export const LOGOUT_REQUEST = 'account/LOGOUT_REQUEST';

export const UPLOAD_AVATAR_REQUEST = 'account/UPLOAD_AVATAR_REQUEST';

export const UPLOAD_AVATAR_SUCCESS = 'account/UPLOAD_AVATAR_SUCCESS';

export const UPLOAD_AVATAR_FAILURE = 'account/UPLOAD_AVATAR_FAILURE';

export const UPDATE_USER_PROFILE_REQUEST =
  'account/UPDATE_USER_PROFILE_REQUEST';

export const UPDATE_USER_PROFILE_SUCCESS =
  'account/UPDATE_USER_PROFILE_SUCCESS';

export const UPDATE_USER_PROFILE_FAILURE =
  'account/UPDATE_USER_PROFILE_FAILURE';

export const CHANGE_PIN_CODE_REQUEST = 'account/CHANGE_PIN_CODE_REQUEST';
export const CHANGE_PIN_CODE_SUCCESS = 'account/CHANGE_PIN_CODE_SUCCESS';
export const CHANGE_PIN_CODE_FAILED = 'account/CHANGE_PIN_CODE_REQUEST';

export const VERIFY_PIN_CODE_REQUEST = 'account/VERIFY_PIN_CODE_REQUEST';
export const VERIFY_PIN_CODE_SUCCESS = 'account/VERIFY_PIN_CODE_SUCCESS';
export const VERIFY_PIN_CODE_FAILED = 'account/VERIFY_PIN_CODE_FAILED';

//default userInfo
export const initUserInfo = {
  name: '',
  dayOfBirth: '',
  phone: '',
  email: '',
  isGuest: false,
  isPhoneLogin: false,
  isFacebookLogin: false,
  isAppleIdLogin: false,
  isLinkFacebook: false,
  isLinkAppleId: false,
  isEnableTranferUtop: false,
  memberCode: '',
  avatar: '',
  photoURL: '',
  id: '',
  sessionId: '',
  tokenGameAAA: '',
  dataToken: {
    items: [],
  },
  listCard: {
    items: [],
  },
  clientPaymentMethod: [
    // "utop",
    // "momo",
    // "payoo"
  ],
  segment: [],
  serverPaymentMethod: [
    // "utop",
    // "momo",
    // "payoo"
  ],
  userAccessToken: '',
  tokenAmount: 0,
  byPassIntro: false,
  byPassIntroGuideCamera: false,
  byPassLogin: false,
  isLogout: false,
  isLogin: false,
  isWipeData: false,
  phoneTemp: '',
  currentMerchantLogin: {
    merchantId: '',
    MerchantSessionId: '',
  },
  beforePhoneRechargeCard: '',
  dataVoucherCode: undefined,
  merchantListPayByToken: {
    items: [],
  },
  merchantList: {
    available: [
      /*
			{
				"merchantId": 2,
				"merchantName": "F.Studio",
				"orgName": "frt",
				"address": "0",
				"taxId": "0",
				"loyaltyProgramName": "0",
				"walletAddress": "d5bccde691ae788dcfb0b2d5cd0061fb8a176111bc9255c6cf0967bcef795f32",
				"logo": "https://ucarecdn.com/608fc9d8-5610-405e-bb93-285ec01b0d9a/FStudiologo.png",
				"email": "0",
				"activeNewRateCash": 1000,
				"dappIP": "http://13.230.35.104:4000",
				"authenIP": "http://52.195.20.18",
				"status": "A",
				"isAdmin": 0
			}
			}*/
    ],
    connected: [
      /* The same structure as available */
    ],
    utop: [
      /* The same structure as available */
      // {
      // 	"merchantId": 1,
      // 	"merchantName": "Utop",
      // 	"orgName": "utop",
      // 	"address": "0",
      // 	"taxId": "0",
      // 	"loyaltyProgramName": "0",
      // 	"walletAddress": "3b2aa360675c434fa2126d1dde449adbadfef3069f94ad57f94e95ab53921b45",
      // 	"logo": "https://ucarecdn.com/a4edb51f-8285-4c64-aa6c-7ef81799f3c0/181116_UTOP_Badge03.png",
      // 	"email": "utop.vn",
      // 	"activeNewRateCash": 0,
      // 	"dappIP": "http://52.77.252.48:4000",
      // 	"authenIP": "https://akcd04.azurewebsites.net",
      // 	"status": "A",
      // 	"isAdmin": 1
      // }
    ],
    cartFood: {},
  },
};
