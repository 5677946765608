/* eslint global-require: 0 */
const SVG = {
  StarRating: require("./star-rating.svg").default,
  StarHalf: require("./star-half.svg").default,
  StarRatingActive: require("./star-rating-active.svg").default,
  Tick: require("./tick.svg").default,
  LocationSolid: require("./location-solid.svg").default,
  OrderFoodIllustration: require("./order-food.svg").default,
  WaitForConfirmationIllustration: require("./wait-for-confirmation.svg")
    .default,
  WaitForDeliveryIllustration: require("./wait-for-delivery.svg").default,
  ArrowRight: require("./arrow-right-white.svg").default,
  ArrowLeft: require("./arrow-left-white.svg").default,
  LoupeWhite: require("./loupe-white.svg").default,
  LoupeBlack: require("./loupe-black.svg").default,
  TickWhite: require("./tick-white.svg").default,
  AngleTopRightOrange: require("./angle-top-right-orange.svg").default,
  LogoUtop: require("./logo-utop-43x20.svg").default,
  LogoTPBank: require("./logo-tpbank.svg").default,
  LogoMomo: require("./logo-momo.svg").default,
  LogoCreditCard: require("./logo-credit-card.svg").default,
  LogoVisa: require("./logo-visa.svg").default,
  LogoMasterCard: require("./logo-master-card.svg").default,
  LogoVNPAY: require("./logo-vnpay.svg").default,
  LogoCash: require("./logo-cash.svg").default,
  LogoAlepay: require("./alepay.png").default,
  LogoJcb: require("./jcb.png").default,
  LogoPayoo: require("./payoo.png").default,
  LogoOtherCard: require("./other_code.png").default,
  Headset: require("./icon-headset.svg").default,
  PlusDashedBorder: require("./icon-plus-dashed-border.svg").default,
  ArrowDown: require("./arrow-down.svg").default,
  ArrowRightBlack: require("./arrow-right-black.svg").default, // todo: replace then clear this line
  ArrowRightCarousel: require("./arrow-right-carousel.svg").default,
  ArrowLeftCarousel: require("./arrow-left-carousel.svg").default,
  MoreCircleYellow: require("./more-circle-yellow.svg").default,
  HomeSolidIcon: require("./home-icon.svg").default,
  BuildingSolidIcon: require("./building-icon.svg").default,
  Bookmark: require("./bookmark.svg").default,
  Clock: require("./clock.svg").default,
  SelectIndicator: require("./select-indicator.svg").default,
  SelectIndicatorActive: require("./select-indicator-active.svg").default,
  ArrowBackWhite: require("./arrow-back-white.svg").default, // todo: consider replace all old style
  Coupon: require("./coupon.svg").default, // todo: clean up
  ArrowRightOrange: require("./arrow-right-orange.svg").default,
  BtnRemove: require("./btn-remove.svg").default, // todo: cleanup
  BannerStore: require("./Banner_store.svg").default,
  PlusSolidYellow: require("./plus-solid-yellow.svg").default,
  Pencil: require("./pencil.svg").default,
  GarbageCan: require("./garbage-can.svg").default,
  MinusWhite: require("./minus-white.svg").default,
  PlusWhite: require("./plus-white.svg").default,
  LocationSolidYellow15x20: require("./location-solid-yellow-15x20.svg")
    .default,
  LocationSolidRed24x24: require("./location-solid-red-24x24.svg").default,
  LocationCircleBlue16x16: require("./location-circle-blue-16x16.svg").default,
  BtnPanToCurrentLocation: require("./btn-pan-to-current-location.svg").default,
  LocationMarkerStore36x36: require("./location-marker-store-36x36.svg")
    .default,
  BtnYourLocation: require("./btn-your-location.svg").default,
  BtnStoreLocation: require("./btn-store-location.svg").default,
  BtnOpenDirectionMode: require("./btn-open-direction-mode.svg").default,
  ListPhoneBook: require("./listphonebook.svg").default,
  BtnClearText: require("./btn-clear-text.svg").default,

  IconMobifone: require("./iconmobifone.svg").default,
  IconMobile: require("./iconmobile.svg").default,
  IconVietnamMobile: require("./iconvietnamobile.svg").default,
  IconViettel: require("./iconviettel.svg").default,
  IconVinaphone: require("./iconvinaphone.svg").default,
  RemovePhone: require("./removephone.svg").default,
  IconSearchHome: require("./iconsearchhome.svg").default,
  CartIcon: require("./cart.svg").default,
  IconMap: require("./iconmapyellow.svg").default,
  IconHotTag: require("./vouchercouponmore.svg").default,

  // New star icon

  // Heart icon
  HeartFull: require("./heart-full.svg").default,
  HeartEmty: require("./heart-empty.svg").default,
  HeartFullSm: require("./heart-full-sub-cmt.svg").default,
  ArrowRightYellow: require("./arrow-right.svg").default,

  // star disable - active icon
  DisableStar: require("./disable-star.svg").default,
  IconRatingNoUser: require("./rate-talk.svg").default,

  // No avatar
  UserNoAvatar: require("./avatar-fb.svg").default,

  // FPT icon
  fptIconFooter: require("./Logo-FPT.svg").default,
  // footer Icon:
  YoutubeYellowIcon: require("./youtube-yellow-icon.svg").default,
  LinkedInYellowIcon: require("./linkedin-yellow-icon.svg").default,
  FacebookYellowIcon: require("./facebook-yellow-icon.svg").default,
  InstagramYellowIcon: require("./insta-yellow-icon.svg").default,
  FptLogo: require("./FPT-logo.svg").default,
  // qr utop
  QRUtopLogo: require("./qr-utop-logo.svg").default,
  ArrowDropdown: require("./dropdown-ava.svg").default,
  LoctaionSearchMobi: require("./location-icon-mobi.svg").default,
  // arrow scroll storeInfo
  arrowPrev: require("./arrow-prev-scroll.svg").default,
  arrowNext: require("./arrow-next-scroll.svg").default,
  // gift icon
  giftVoucher: require("./gift-voucher.svg").default,
  footerSendVoucher: require("./footer-img.svg").default,

  // receiver on active flag
  flagActive: require("./top-active-receiver.svg").default,
  // icon close input
  iCloseInput: require("./icon-close.svg").default,
  // icon person
  iconPerson: require("./icon-person.svg").default,
};

export { ReactComponent as GiftVoucher } from "./gift-voucher.svg";

export { ReactComponent as CellPhoneIcon } from "./cell-phone.svg";
export { ReactComponent as CompassIcon } from "./path.svg";
export { ReactComponent as IconRatingNoUser } from "./rate-talk.svg";
export { ReactComponent as CartRating } from "./cart-rating.svg";
export { ReactComponent as ArrowToRight } from "./arrow-to-right.svg";
export { ReactComponent as CopiedIcon } from "./copy-icon.svg";
export { ReactComponent as PhoneMobi } from "./phone-mobi.svg";
export { ReactComponent as ContactIcon } from "./danh-ba-dien-thoai.svg";

export { ReactComponent as EmailMobi } from "./email-mobi.svg";
export { ReactComponent as LocationSearchMobi } from "./location-icon-mobi.svg";
export { ReactComponent as ArrowDropdown } from "./dropdown-ava.svg";

export { ReactComponent as StarFullSm } from "./full-star-sm.svg";
export { ReactComponent as StarHalfSm } from "./half-star-sm.svg";
export { ReactComponent as StarEmptySm } from "./empty-star-sm.svg";

export { ReactComponent as IconOptionRating } from "./option-rating.svg";
export { ReactComponent as IconVerifyUser } from "./verify-tick-user.svg";
export { ReactComponent as IconDisableStar } from "./disable-star.svg";
export { ReactComponent as IconReply } from "./reply-rating.svg";

export { ReactComponent as IconMapGroup } from "./iconmapyellow.svg";
export { ReactComponent as IconHotTag } from "./vouchercouponmore.svg";

export { ReactComponent as ForwardYellowIcon } from "./forward-bold-yellow.svg";
export { ReactComponent as ForwardBlackIcon } from "./forward-black.svg";
export { ReactComponent as MoreActionVertIcon } from "./more-action-vertical.svg";

export { ReactComponent as HomeSolidBlackIcon } from "./home-icon.svg";
export { ReactComponent as BuildingSolidBlackIcon } from "./building-icon.svg";
export { ReactComponent as PlaceSolidBlackIcon } from "./place-solid-black.svg";

export { ReactComponent as HomeSolidYellowIcon } from "./home-solid-yelow.svg";
export { ReactComponent as BuildingSolidYellowIcon } from "./building-solid-yellow.svg";
export { ReactComponent as PlaceSolidYellowIcon } from "./place-solid-yellow.svg";

export { ReactComponent as FlagSolidOrangeIcon } from "./flag-solid-orange.svg";
export { ReactComponent as PlusSolid20x20 } from "./plus-solid-20x20.svg";
export { ReactComponent as LocationSolidRed24x24 } from "./location-solid-red-24x24.svg";
export { ReactComponent as LoupeBlackLongStick } from "./loupe-black-long-stick-15x15.svg";
export { ReactComponent as GPSIcon } from "./gps.svg";
export { ReactComponent as LocationSolidRed16x20 } from "./location-solid-red-16x20.svg";
export { ReactComponent as SignSoldOut } from "./sign-sold-out.svg";

export { ReactComponent as MinusWhiteIcon } from "./minus-white.svg";
export { ReactComponent as PlusWhiteIcon } from "./plus-white.svg";
export { ReactComponent as ListIcon } from "./list.svg";
export { ReactComponent as GoBackProvince } from "./gobackprovince.svg";
export { ReactComponent as IllustrationSearchNotFound } from "./illustration-search-not-found.svg";
export { ReactComponent as IllustrationHistorySearch } from "./illustration-history-search.svg";
export { ReactComponent as YoutubeYellowIcon } from "./youtube-yellow-icon.svg";
export { ReactComponent as LinkedInYellowIcon } from "./linkedin-yellow-icon.svg";
export { ReactComponent as FacebookYellowIcon } from "./facebook-yellow-icon.svg";
export { ReactComponent as InstagramYellowIcon } from "./insta-yellow-icon.svg";

export { ReactComponent as BillYellowIcon } from "./bill-yellow-icon.svg";
export { ReactComponent as CloseIcon } from "./close.svg";

// store info
export { ReactComponent as ReceiveFoodAtStore } from "./receive-food-at-store.svg";
export { ReactComponent as DeliveryOptionBike } from "./delivery-option-bike.svg";
export { ReactComponent as RibbonIcon } from "./ribbon.svg";
export { ReactComponent as ShareSolidYellowIcon } from "./share-solid-yellow.svg";
export { ReactComponent as ExclamationIcon } from "./exclamation.svg";
export { ReactComponent as ClockWithBackgroundIcon } from "./clock-with-background.svg";
export { ReactComponent as LocationMarkerWithBackgroundIcon } from "./location-marker-with-background.svg";
export { ReactComponent as CouponSolidYellowIcon } from "./coupon-solid-yellow.svg";
export { ReactComponent as ArrowRightYellowIcon } from "./arrow-right-orange.svg";
export { ReactComponent as ReceiveFoodOutlineIcon } from "./receive-food-outline.svg";
export { ReactComponent as ArrowBackWhiteIcon } from "./arrow-back-white.svg";
export { ReactComponent as CouponIcon } from "./coupon.svg";
export { ReactComponent as ScooterOutlinedIcon } from "./scooter-outlined.svg";

// rating feature
export { ReactComponent as PaperPlaneSolidYellowIcon } from "./paper-plane-solid-yellow.svg";

// voucher view
export { ReactComponent as ArrowDownIcon } from "./arrow-down.svg";
export { ReactComponent as ArrowRightWhiteIcon } from "./arrow-right-white.svg";
export { ReactComponent as ArrowLeftWhiteIcon } from "./arrow-left-white.svg";
export { ReactComponent as ShareWhiteIcon } from "./share-white.svg";

// voucher cart
export { ReactComponent as CouponOutlinedIcon } from "./coupon-outlined.svg";

export { ReactComponent as BtnRemoveIcon } from "./btn-remove.svg";

// order
export { ReactComponent as ArrowRightBlackIcon } from "./arrow-right-black.svg";

// transactionresult
export { ReactComponent as PaymentSuccessIllustration } from "./payment-success.svg";
export { ReactComponent as PaymentFailureIllustration } from "./payment-failure.svg";

// icon
export { ReactComponent as CloseWhiteYellowIcon } from "./close--white--yellow.svg";
export { ReactComponent as LocationSolidYellow8x10 } from "./location-solid-yellow-8x10.svg";
export { ReactComponent as ArrowRightWhite4x8 } from "./arrow-right-white-4x8.svg";
export { ReactComponent as VoucherSolidYellow } from "./voucher-solid-yellow.svg";
export { ReactComponent as LocationNearBy } from "./location-near-by.svg";
export { ReactComponent as StoreSolidYellow } from "./store-solid-yellow.svg";
export { ReactComponent as ArrowRightBlack4x7 } from "./arrow-right-black--4x7.svg";
export { ReactComponent as CloseGray16x16 } from "./close-gray-16x16.svg";
export { ReactComponent as StarFilled8x8 } from "./star-filled-8x8.svg";
export { ReactComponent as StarHalf8x8 } from "./star-half-8x8.svg";
export { ReactComponent as StarEmpty8x8 } from "./star-empty-8x8.svg";
export { ReactComponent as InfoCircle14x14 } from "./more-info.svg";
export { ReactComponent as TickGreenInCircle } from "./tick-green-in-circle.svg";
export { ReactComponent as UserSolidYellow12x12 } from "./icon-person.svg";

// Illustration
export { ReactComponent as IStoreGray } from "./illustration-store-gray.svg";
export { ReactComponent as SignUpPhoneUtop } from "./sign-up__voucher.svg";
export { ReactComponent as BackgroundSearch } from "./background-Search.svg";

export default SVG;
