import { call, put, takeLatest, fork, takeEvery } from "redux-saga/effects";
import actions from "./actions";
import * as TYPE from "./constants";
import api from "../api/api";

const sagas = {
  *[TYPE.GET_RATING_LIST.request](action) {
    try {
      const data = yield call(
        api[TYPE.GET_RATING_LIST.request],
        action.payload
      );
      if (data.status === 200) {
        yield put(actions.GET_RATING_LIST.success(data.data));
      } else {
        yield put(actions.GET_RATING_LIST.failure(data?.data));
      }
    } catch (error) {
      yield put(actions.GET_RATING_LIST.failure(error?.data));
    }
  },

  *[TYPE.GET_MERCHANT_INFO.request](action) {
    try {
      const data = yield call(
        api[TYPE.GET_MERCHANT_INFO.request],
        action.payload
      );

      if (data.status === 200) {
        yield put(actions.GET_MERCHANT_INFO.success(data.data));
      } else {
        yield put(actions.GET_MERCHANT_INFO.failure(data?.data));
      }
    } catch (error) {
      yield put(actions.GET_MERCHANT_INFO.failure(error?.data));
    }
  },

  *[TYPE.GET_STORE_INFO.request](action) {
    try {
      const data = yield call(api[TYPE.GET_STORE_INFO.request], action.payload);

      if (data.status === 200) {
        yield put(actions.GET_STORE_INFO.success(data.data));
      } else {
        yield put(actions.GET_STORE_INFO.failure(data?.data));
      }
    } catch (error) {
      yield put(actions.GET_STORE_INFO.failure(error?.data));
    }
  },

  *[TYPE.GET_STORE_INFO_V2.request](action) {
    try {
      const data = yield call(
        api[TYPE.GET_STORE_INFO_V2.request],
        action.payload
      );

      if (data.status === 200) {
        yield put(actions.GET_STORE_INFO_V2.success(data.data));
      } else {
        yield put(actions.GET_STORE_INFO_V2.failure(data?.data));
      }
    } catch (error) {
      yield put(actions.GET_STORE_INFO_V2.failure(error?.data));
    }
  },

  *[TYPE.GET_BEST_SELLER_LIST.request](action) {
    try {
      const data = yield call(
        api[TYPE.GET_BEST_SELLER_LIST.request],
        action.payload
      );

      if (data.status === 200) {
        yield put(actions.GET_BEST_SELLER_LIST.success(data.data));
      } else {
        yield put(actions.GET_BEST_SELLER_LIST.failure(data?.data));
      }
    } catch (error) {
      yield put(actions.GET_BEST_SELLER_LIST.failure(error?.data));
    }
  },

  *[TYPE.GET_SUGGESTED_COUPON_LIST.request](action) {
    try {
      const response = yield call(
        api[TYPE.GET_SUGGESTED_COUPON_LIST.request],
        action.payload
      );

      if (response.status === 200) {
        yield put(actions.GET_SUGGESTED_COUPON_LIST.success(response.data));
      } else {
        yield put(actions.GET_SUGGESTED_COUPON_LIST.failure(response?.data));
      }
    } catch (error) {
      yield put(actions.GET_SUGGESTED_COUPON_LIST.failure(error?.data));
    }
  },

  *[TYPE.SAVE_SUGGESTED_COUPON.request](action) {
    try {
      const response = yield call(
        api[TYPE.SAVE_SUGGESTED_COUPON.request],
        action.payload
      );

      if (response.status === 200) {
        yield put(
          actions.SAVE_SUGGESTED_COUPON.success(
            // response.data
            action.payload
          )
        );
      } else {
        yield put(actions.SAVE_SUGGESTED_COUPON.failure(response?.data));
      }
    } catch (error) {
      yield put(actions.SAVE_SUGGESTED_COUPON.failure(error?.data));
    }
  },

  *[TYPE.GET_SHORT_LINK_TO_SHARE.request](action) {
    const { url, callbackFunction } = action.payload;

    try {
      const response = yield call(api[TYPE.GET_SHORT_LINK_TO_SHARE.request], {
        url,
      });

      if (response.status === 200) {
        yield put(actions.GET_SHORT_LINK_TO_SHARE.success(response.data));
        yield call(callbackFunction, response.data.url);
      } else {
        yield put(actions.GET_SHORT_LINK_TO_SHARE.failure(response?.data));
      }
    } catch (error) {
      yield put(actions.GET_SHORT_LINK_TO_SHARE.failure(error?.data));
    }
  },

  *[TYPE.GET_USER_LIKED_LIST_OF_RATING_POST.request](action) {
    const {
      take,
      needToClearExistedList = false, // only be pass to here if click filter (by rate or toggle haveComment)
    } = action.payload;

    try {
      const response = yield call(
        api[TYPE.GET_USER_LIKED_LIST_OF_RATING_POST.request],
        action.payload
      );

      if (response.status === 200) {
        yield put(
          actions.GET_USER_LIKED_LIST_OF_RATING_POST.success({
            list: [...response.data],
            hasMore: response.data?.length === take,
            needToClearExistedList,
          })
        );
      } else {
        yield put(
          actions.GET_USER_LIKED_LIST_OF_RATING_POST.failure(response?.data)
        );
      }
    } catch (error) {
      yield put(
        actions.GET_USER_LIKED_LIST_OF_RATING_POST.failure(error?.data)
      );
    }
  },

  *[TYPE.GET_USER_LIKED_LIST_OF_COMMENT.request](action) {
    const {
      take,
      needToClearExistedList = false, // only be pass to here if click filter (by rate or toggle haveComment)
    } = action.payload;

    try {
      const response = yield call(
        api[TYPE.GET_USER_LIKED_LIST_OF_COMMENT.request],
        action.payload
      );

      if (response.status === 200) {
        yield put(
          actions.GET_USER_LIKED_LIST_OF_COMMENT.success({
            list: [...response.data],
            hasMore: response.data?.length === take,
            needToClearExistedList,
          })
        );
      } else {
        yield put(
          actions.GET_USER_LIKED_LIST_OF_COMMENT.failure(response?.data)
        );
      }
    } catch (error) {
      yield put(actions.GET_USER_LIKED_LIST_OF_COMMENT.failure(error?.data));
    }
  },

  *[TYPE.POST_LIKE_CMT.request](action) {
    try {
      const data = yield call(api[TYPE.POST_LIKE_CMT.request], action.payload);
      if (data.status === 200) {
        yield put(actions.POST_LIKE_CMT.success(action.payload));
      } else {
        yield put(actions.POST_LIKE_CMT.failure(data?.data));
      }
    } catch (error) {
      yield put(actions.POST_LIKE_CMT.failure(error?.data));
    }
  },

  *[TYPE.POST_UNLIKE_CMT.request](action) {
    try {
      const data = yield call(
        api[TYPE.POST_UNLIKE_CMT.request],
        action.payload
      );
      if (data.status === 200) {
        yield put(actions.POST_UNLIKE_CMT.success(action.payload));
      } else {
        yield put(actions.POST_UNLIKE_CMT.failure(data?.data));
      }
    } catch (error) {
      yield put(actions.POST_UNLIKE_CMT.failure(error?.data));
    }
  },
  *[TYPE.POST_LIKE_SUB_CMT.request](action) {
    try {
      const data = yield call(
        api[TYPE.POST_LIKE_SUB_CMT.request],
        action.payload
      );
      if (data.status === 200) {
        yield put(actions.POST_LIKE_SUB_CMT.success(action.payload));
      } else {
        yield put(actions.POST_LIKE_SUB_CMT.failure(data?.data));
      }
    } catch (error) {
      yield put(actions.POST_LIKE_SUB_CMT.failure(error?.data));
    }
  },

  *[TYPE.POST_UNLIKE_SUB_CMT.request](action) {
    try {
      const data = yield call(
        api[TYPE.POST_UNLIKE_SUB_CMT.request],
        action.payload
      );
      if (data.status === 200) {
        yield put(actions.POST_UNLIKE_SUB_CMT.success(action.payload));
      } else {
        yield put(actions.POST_UNLIKE_SUB_CMT.failure(data?.data));
      }
    } catch (error) {
      yield put(actions.POST_UNLIKE_SUB_CMT.failure(error?.data));
    }
  },
  *[TYPE.POST_REPLY_CMT.request](action) {
    try {
      const data = yield call(api[TYPE.POST_REPLY_CMT.request], action.payload);
      if (data.status === 200) {
        yield put(actions.POST_REPLY_CMT.success(data.data));
      } else {
        yield put(actions.POST_REPLY_CMT.failure(data?.data));
      }
    } catch (error) {
      yield put(actions.POST_REPLY_CMT.failure(error?.data));
    }
  },
  *[TYPE.GET_NOT_ALREADY_RATED_LIST.request](action) {
    try {
      const data = yield call(
        api[TYPE.GET_NOT_ALREADY_RATED_LIST.request],
        action.payload
      );
      if (data.status === 200) {
        yield put(actions.GET_NOT_ALREADY_RATED_LIST.success(data.data));
      } else {
        yield put(actions.GET_NOT_ALREADY_RATED_LIST.failure(data?.data));
      }
    } catch (error) {
      yield put(actions.GET_NOT_ALREADY_RATED_LIST.failure(error?.data));
    }
  },
  *[TYPE.GET_LIST_DEAL.request](action) {
    const { storeId, merchantId, Latitude, Longitude, Skip, Take } =
      action.payload;
    try {
      const response = yield call(api[TYPE.GET_LIST_DEAL.request], {
        storeId,
        merchantId,
        Latitude,
        Longitude,
        Skip,
        Take,
      });

      if (response.status === 200) {
        yield put(
          actions.GET_LIST_DEAL.success({
            items: response.data || [],
            skip: Skip,
            take: Take,
          })
        );
      } else {
        yield put(actions.GET_LIST_DEAL.failure(response?.data));
      }
    } catch (error) {
      yield put(actions.GET_LIST_DEAL.failure(error?.data));
    }
  },
};

function* watchedSaga() {
  yield takeLatest(
    TYPE.GET_RATING_LIST.request,
    sagas[TYPE.GET_RATING_LIST.request]
  );
  yield takeLatest(
    TYPE.GET_MERCHANT_INFO.request,
    sagas[TYPE.GET_MERCHANT_INFO.request]
  );
  yield takeLatest(
    TYPE.GET_STORE_INFO.request,
    sagas[TYPE.GET_STORE_INFO.request]
  );
  yield takeLatest(
    TYPE.GET_STORE_INFO_V2.request,
    sagas[TYPE.GET_STORE_INFO_V2.request]
  );
  yield takeLatest(
    TYPE.GET_BEST_SELLER_LIST.request,
    sagas[TYPE.GET_BEST_SELLER_LIST.request]
  );
  yield takeLatest(
    TYPE.GET_SUGGESTED_COUPON_LIST.request,
    sagas[TYPE.GET_SUGGESTED_COUPON_LIST.request]
  );
  yield takeEvery(
    TYPE.SAVE_SUGGESTED_COUPON.request,
    sagas[TYPE.SAVE_SUGGESTED_COUPON.request]
  );
  yield takeLatest(
    TYPE.GET_SHORT_LINK_TO_SHARE.request,
    sagas[TYPE.GET_SHORT_LINK_TO_SHARE.request]
  );
  yield takeLatest(
    TYPE.GET_USER_LIKED_LIST_OF_RATING_POST.request,
    sagas[TYPE.GET_USER_LIKED_LIST_OF_RATING_POST.request]
  );
  yield takeLatest(
    TYPE.GET_USER_LIKED_LIST_OF_COMMENT.request,
    sagas[TYPE.GET_USER_LIKED_LIST_OF_COMMENT.request]
  );
  yield takeLatest(
    TYPE.POST_LIKE_CMT.request,
    sagas[TYPE.POST_LIKE_CMT.request]
  );
  yield takeLatest(
    TYPE.POST_UNLIKE_CMT.request,
    sagas[TYPE.POST_UNLIKE_CMT.request]
  );
  yield takeLatest(
    TYPE.POST_LIKE_SUB_CMT.request,
    sagas[TYPE.POST_LIKE_SUB_CMT.request]
  );
  yield takeLatest(
    TYPE.POST_UNLIKE_SUB_CMT.request,
    sagas[TYPE.POST_UNLIKE_SUB_CMT.request]
  );
  yield takeLatest(
    TYPE.POST_REPLY_CMT.request,
    sagas[TYPE.POST_REPLY_CMT.request]
  );
  // yield takeLatest(
  //   TYPE.GET_FILTER_BY_STAR_RATE.request,
  //   sagas[TYPE.GET_FILTER_BY_STAR_RATE.request]
  // );
  yield takeLatest(
    TYPE.GET_NOT_ALREADY_RATED_LIST.request,
    sagas[TYPE.GET_NOT_ALREADY_RATED_LIST.request]
  );
  yield takeLatest(
    TYPE.GET_LIST_DEAL.request,
    sagas[TYPE.GET_LIST_DEAL.request]
  );
}

export default function* rootChild() {
  yield fork(watchedSaga);
}
