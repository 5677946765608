import {
  LIST_FAVORITE_HASHTAG_SUCCESS,
  LIST_FAVORITE_HASHTAG_REQUEST,
  LIST_FAVORITE_HASHTAG_FAILED,
} from './types';


export const listFavoriteHashtagRequest = payload => ({
  type: LIST_FAVORITE_HASHTAG_REQUEST,
  payload,
});

export const listFavoriteHashtagSuccess = payload => ({
  type: LIST_FAVORITE_HASHTAG_SUCCESS,
  payload,
});

export const listFavoriteHashtagFailure = payload => ({
  type: LIST_FAVORITE_HASHTAG_FAILED,
  payload,
});