import {
  GET_MOBILE_CARD_LIST_SUCCESS,
  GET_MOBILE_CARD_LIST_REQUEST,
  GET_MOBILE_CARD_LIST_FAILED,
  GET_BUY_CARD_LIST_SUCCESS,
  GET_BUY_CARD_LIST_REQUEST,
  GET_BUY_CARD_LIST_FAILED,
  CREATE_ORDER_SUCCESS,
  CREATE_ORDER_REQUEST,
  CREATE_ORDER_FAILED,
  PAYMENT_SUCCESS,
  PAYMENT_REQUEST,
  PAYMENT_FAILED,
} from './constants';

const initialState = {
  mobileCardList: [],
  buyCardList: [],
  payment: {}
};

const mobileCard = function (state = initialState, action) {
  switch (action.type) {
    // mobile card
    case GET_MOBILE_CARD_LIST_SUCCESS: {
      return {
        ...state,
        mobileCardList: action.mobileCard.data,
      };
    }

    case GET_MOBILE_CARD_LIST_REQUEST: {
      return {
        ...state,
        mobileCardList: {
          ...state.mobileCardList,
        },
      };
    }

    case GET_MOBILE_CARD_LIST_FAILED: {
      return {
        ...state,
        mobileCardList: {
          ...state.mobileCardList,
        },
      };
    }

    //buy card
    case GET_BUY_CARD_LIST_SUCCESS: {
      return {
        ...state,
        buyCardList: action.buyCard.data,
      };
    }

    case GET_BUY_CARD_LIST_REQUEST: {
      return {
        ...state,
        buyCardList: {
          ...state.buyCardList,
        },
      };
    }

    case GET_BUY_CARD_LIST_FAILED: {
      return {
        ...state,
        buyCardList: {
          ...state.buyCardList,
        },
      };
    }

    //create order
    case CREATE_ORDER_SUCCESS: {
      return {
        ...state,
        createOrder: action.createOrder,
      };
    }

    case CREATE_ORDER_REQUEST: {
      return {
        ...state,
        createOrder: {
          ...state.createOrder,
        },
      };
    }

    case CREATE_ORDER_FAILED: {
      return {
        ...state,
        createOrder: {
          ...state.createOrder,
        },
      };
    }

    //payment
    case PAYMENT_SUCCESS: {
      return {
        ...state,
        payment: action.payment,
      };
    }

    case PAYMENT_REQUEST: {
      return {
        ...state,
        payment: {
          ...state.payment,
        },
      };
    }

    case PAYMENT_FAILED: {
      return {
        ...state,
        payment: {
          ...state.payment,
        },
      };
    }

    default:
      return state;
  }
};

export default mobileCard;
