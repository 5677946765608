/* eslint-disable no-param-reassign */
import { addErrors, delErrors } from "utils/ErrorsGenerator";
import * as TYPE from "./constants";

const reducers = {
  // * GET_ORDER_DETAIL
  [TYPE.GET_ORDER_DETAIL.request]: (draft) => {
    draft.orderDetail = null;
    draft.isLoading.GET_ORDER_DETAIL = true;
  },
  [TYPE.GET_ORDER_DETAIL.success]: (draft, { payload }) => {
    draft.errors = delErrors(draft.errors, TYPE.GET_ORDER_DETAIL.failure);
    draft.orderDetail = payload;
    draft.isLoading.GET_ORDER_DETAIL = false;
  },
  [TYPE.GET_ORDER_DETAIL.failure]: (draft, { payload }) => {
    draft.errors = addErrors(draft.errors, {
      type: TYPE.GET_ORDER_DETAIL.failure,
      msg: payload,
    });
    draft.isLoading.GET_ORDER_DETAIL = false;
  },

  // * GET_PAYMENT_METHODS
  [TYPE.GET_PAYMENT_METHODS.request]: (draft) => {
    draft.isLoading.GET_PAYMENT_METHODS = true;
  },
  [TYPE.GET_PAYMENT_METHODS.success]: (draft, { payload }) => {
    draft.errors = delErrors(draft.errors, TYPE.GET_PAYMENT_METHODS.failure);
    draft.listTransactionMethods = payload; // ***
    draft.isLoading.GET_PAYMENT_METHODS = false;
  },
  [TYPE.GET_PAYMENT_METHODS.failure]: (draft, { payload }) => {
    draft.errors = addErrors(draft.errors, {
      type: TYPE.GET_PAYMENT_METHODS.failure,
      msg: payload,
    });
    draft.isLoading.GET_PAYMENT_METHODS = false;
  },
};

export default reducers;
