import {END_POINTS} from '@shared/constants/apiUrl';
import {APIUtils} from 'utils';

export function getTransactionDetailPaymentInfoNewsApi(payload) {
  return APIUtils.get(END_POINTS.GET_DETAIL_TRANSACTION + `?id=${payload}`);
}

export function getRepaymentTransactionLinkNewsApi(orderId) {
  return APIUtils.post(END_POINTS.GET_REPAYMENT_TRANSACTION_LINK_NEWS, {
    body: orderId,
  });
}

export const createTransactionInsuranceApi = async payload => {
  return await APIUtils.post(END_POINTS.CREATE_TRANSACTION_INSURANCE_URL, {
    body: payload,
    headers: {
      merchantId: payload.merchantId,
    },
  });
};

export function getTransactionDetailConfirmInfoApi(payload) {
  return APIUtils.get(END_POINTS.GET_DETAIL_CONFIRM_INFO + `?id=${payload}`);
}

export function getRepaymentTransactionLinkApi(payload) {
  return APIUtils.post(END_POINTS.GET_REPAYMENT_TRANSACTION_LINK, {
    body: payload,
  });
}
