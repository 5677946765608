/* eslint global-require: 0 */
const IMAGES = {
  NoImagePlaceholder: require("./no-image-placeholder.png").default,
  LocationBubble: require("./LocationBubble.png").default,
  BgrStoreHeader: require("./bgr-store-header.png").default,
  FeedImagePlaceholder: require("./feed-image-placeholder.png").default,
  BannerStore: require("./Banner_store.png").default,
  BannerMarket: require("./BannerMarket.jpg").default,
  FoodPlaceholderX3: require("./foodPlaceHolder-x3.png").default,
  TickCmt: require("./tick-cmt.jpg").default,
  AvatarPlaceholder: require("./avatar-placeholder-unsex.jpg").default,
  IosBlack: require("./ios-black.jpg").default,
  AndroidColor: require("./android-color.jpg").default,
  QRdownApp: require("./QR-downApp.png").default,
  LogoUtop: require("./utop-logo.png").default,
  LogoUtop2: require("./LogoUtop.jpg").default,
  PClandingBg: require("./bg-pro-landing.png").default,
  MobiLandingBg: require("./mobile-bg-landing.png").default,
  MobiHailocBtn: require("./mobile-hailoc.png").default,
  CloseIconLanding: require("./close-icon.png").default,
  // landing page
  HaiLocBtn: require("./btn-HaiLoc.png").default,
  IconInfo: require("./information.png").default,
  IconScrollTop: require("./iconScrollToTop.png").default,
  IconDeliveryHome: require("./iconDeliveryHome.png").default,
  IconDeliveryShop: require("./iconDeliveryShop.png").default,
  IconTable: require("./iconTable.png").default,
  IconShippingInfo: require("./iconShippingInfo.png").default,
  IconSearch: require("./iconSearch.png").default,
  IconProductTypeDefault: require("./iconProductTypeDefault.png").default,
  waitForConfirmation: require("./waitForConfirmation.png").default,
};

export default IMAGES;
