import { callApi } from "helpers/callApi";
import { fork, put, select, takeLatest } from "redux-saga/effects";
import {
  GET_UTOP_POINT_TOTAL_REQUEST,
  GET_LIST_CAMPAIGN_REQUEST,
  GET_LIST_AFF_HISTORY_REQUEST,
  GET_AFF_DETAIL_REQUEST,
} from "./constants";
import {
  getUtopPointTotalSuccess,
  getUtopPointTotalFailed,
  getListCampaignSuccess,
  getListCampaignFailed,
  getAffDetailSuccess,
  getAffDetailFailed,
  getAffHistorySuccess,
  getAffHistoryAffDetailFailed,
} from "./actions";
import {
  getTotalPointApi,
  getListCompaignApi,
  getAffDetailApi,
  getAffHistoryApi,
} from "@shared/services/api/utopback";
import { getMemberCodeSelector } from "../account/selectors";
import { getListCampaignSelector } from "./selectors";

export function* getUtopPointTotalSaga(obj) {
  const { onSuccess, onFailed } = obj;
  try {
    const res = yield callApi(getTotalPointApi);
    let balance = res?.data?.totalAmount;
    yield put(getUtopPointTotalSuccess(balance));

    if (typeof onSuccess === "function") {
      onSuccess(res);
    }
  } catch (err) {
    //call back error
    if (typeof onFailed === "function") {
      onFailed(err);
    }
    const { status } = err || {};
    if (status >= 400) {
      Alert.alert("Utop", err?.data?.message);
    }
    yield put(getUtopPointTotalFailed(err));
  }
}

export function* getListCompaignSaga(obj) {
  const { onSuccess, onFailed } = obj;
  try {
    const res = yield callApi(getListCompaignApi);
    yield put(
      getListCampaignSuccess({
        listcampaign: res?.data?.items,
        total: res?.data?.total,
      })
    );
    if (typeof onSuccess === "function") {
      onSuccess();
    }
  } catch (err) {
    if (typeof onFailed === "function") {
      onFailed();
    }
    const { status } = err || {};
    if (status >= 400) {
      Alert.alert("Utop", err?.data?.message);
    }
    yield put(getAffDetailFailed(err));
  }
}

export function* getAffDetailSaga(obj) {
  const { payload, onSuccess, onFailed } = obj;
  try {
    const res = yield callApi(getAffDetailApi, {
      campaignId: payload.campaignId,
    });

    yield put(getAffDetailSuccess(res?.data?.items[0]));
    if (typeof onSuccess === "function") {
      onSuccess(res?.items);
    }
  } catch (err) {
    if (typeof onFailed === "function") {
      onFailed();
    }
    const { status } = err || {};
    if (status >= 400) {
      Alert.alert("Utop", err?.data?.message);
    }
    yield put(getListCampaignFailed(err));
  }
}
export function* getAffHistorySaga(obj) {
  const { payload, onSuccess, onFailed } = obj;
  let requestData = {};
  let response = {};
  const memberCode = yield select(getMemberCodeSelector);
  const listCampaign = yield select(getListCampaignSelector);
  try {
    if (payload.type === "pending") {
      requestData = {
        type: "pending",
        method: "GET",
        url: memberCode + "?status=0",
      };
    } else if (payload.type === "approved") {
      requestData = {
        type: "approved",
        method: "GET",
        url:
          memberCode +
          "?status=1&is_confirmed=0&limit=10&offset=" +
          payload.skipCountApproved,
        skip: payload.skipCountApproved,
      };
    } else if (payload.type === "received") {
      requestData = {
        type: "received",
        method: "POST",
        skip: payload.skipCountReceived,
        maxResult: payload.maxResult,
      };
    } else {
      requestData = {
        type: "cancel",
        method: "GET",
        url:
          memberCode + "?status=2&limit=10&offset=" + payload.skipCountCancel,
        skip: payload.skipCountCancel,
      };
    }
    let res = yield callApi(getAffHistoryApi, requestData);

    if (payload.type === "received") {
      res?.data?.transactions?.forEach((itemsb) =>
        listCampaign.forEach((itemsa) => {
          if (
            itemsb.extraInformation.merchant.toLowerCase() ===
            itemsa.data.campaignName.vi.toLowerCase()
          ) {
            itemsb.merchantLogo = itemsa.data.merchantLogo.iv;
          }
        })
      );
    } else {
      res?.data?.data?.forEach((itemsb) =>
        listCampaign.forEach((itemsa) => {
          if (
            itemsb.merchant.toLowerCase() ===
            itemsa.data.campaignName.vi.toLowerCase()
          ) {
            itemsb.merchantLogo = itemsa.data.merchantLogo.iv;
          }
        })
      );
    }
    if (payload.type === "pending") {
      let totalUtopPending = 0;
      res?.data?.data?.forEach((item) => {
        return (totalUtopPending =
          totalUtopPending + Math.floor(parseFloat(item.commission) / 1000));
      });
      response = {
        type: payload.type,
        data: res?.data?.data,
        totalUtopPending: totalUtopPending,
      };
    } else if (payload.type === "received") {
      response = {
        type: payload.type,
        data: res?.data?.transactions,
        skip: requestData.skip,
      };
    } else {
      response = {
        type: payload.type,
        data: res?.data?.data,
        skip: requestData.skip,
      };
    }

    yield put(getAffHistorySuccess(response));
    if (typeof onSuccess === "function") {
      onSuccess();
    }
  } catch (err) {
    if (typeof onFailed === "function") {
      onFailed();
    }
    const { status } = err || {};
    if (status >= 400) {
      Alert.alert("Utop", err?.data?.message);
    }
    yield put(getAffHistoryAffDetailFailed(err));
  }
}

function* watchUtopBack() {
  yield takeLatest(GET_UTOP_POINT_TOTAL_REQUEST, getUtopPointTotalSaga);
  yield takeLatest(GET_LIST_CAMPAIGN_REQUEST, getListCompaignSaga);
  yield takeLatest(GET_LIST_AFF_HISTORY_REQUEST, getAffHistorySaga);
  yield takeLatest(GET_AFF_DETAIL_REQUEST, getAffDetailSaga);
}

export default function* rootChild() {
  yield fork(watchUtopBack);
}
