import {END_POINTS} from '@shared/constants/apiUrl';

import {APIUtils} from 'utils';

export function getListImagesWithEditionIDApi(payload) {
  return APIUtils.get(
    END_POINTS.GET_LIST_IMAGES_WITH_EDITION_ID +
      `?skip=${payload.skip}&take=${payload.take}&editionId=${payload.editionId}`,
  );
}

export function getListNumberNewsCurrentApi(payload) {
  return APIUtils.get(
    END_POINTS.GET_LIST_IMAGES_NEWS_CURRENT +
      `?newspaperId=${payload.newsPagesId}&currentEditionId=${payload.currentEditionId}`,
  );
}

export function getSasTokenApi() {
  return APIUtils.get(END_POINTS.GET_SAS_TOKEN_NEWS + `/generate-sas-token`);
}
