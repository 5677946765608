import { genActions } from "utils/actionGenerator";
import * as TYPE from "./constants";

const actions = {
  GET_LIST_CARD_TOPUP: genActions(TYPE.GET_LIST_CARD_TOPUP),
  GET_LIST_BUY_CARD: genActions(TYPE.GET_LIST_BUY_CARD),
  CREATE_ODER_TOPUP: genActions(TYPE.CREATE_ODER_TOPUP),
};

export default actions;
