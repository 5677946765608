import types from './types';

const initialState = {
  listProductSearchMarket: null,
  listSearchHistory: [],
  productDetailMarket: {},
};

const mergeMasterData = (dataOld, dataNew, isLoadNew) => {
  let masterData = dataOld;
  if (isLoadNew) {
    masterData = {};
  }
  for (var i = 0; i < dataNew.length; i++) {
    var groupName = dataNew[i].category.id;
    if (!masterData[groupName]) {
      masterData[groupName] = [];
    }
    masterData[groupName].push(dataNew[i]);
  }
  return masterData;
};

const mergeDataOutOfStock = dataListSearchMarket => {
  let data = dataListSearchMarket.data || [];
  let dataOutOfStock = dataListSearchMarket.dataOutOfStock || [];

  let masterData = data.concat(dataOutOfStock);

  return masterData;
};

function uniq(a, key) {
  let seen = new Set();
  return a.filter(item => {
    let k = key(item).toLowerCase();
    return seen.has(k) ? false : seen.add(k);
  });
}

const cook = (dataOld, valueSearch) => {
  let listSearchHistory = dataOld;
  if (!!valueSearch) {
    listSearchHistory.unshift(valueSearch);
  }

  listSearchHistory = uniq(listSearchHistory, x => x);
  listSearchHistory = listSearchHistory.slice(0, 10);

  return listSearchHistory;
};

const marketReducer = (state = initialState, action) => {
  const {payload, type} = action;

  switch (type) {
    case types.SEARCH_MARKET_REQUEST: {
      return {
        ...state,
        listProductSearchMarket: {
          ...state.listProductSearchMarket,
          isLoadNew: !!payload.isLoadNew ? payload.isLoadNew : true,
          isEndSearchMarket: payload.skipCount === 0 ? false : state.listProductSearchMarket?.isEndSearchMarket
        },
      };
    }

    case types.SEARCH_MARKET_SUCCESS: {
      return {
        ...state,
        listProductSearchMarket: {
          ...state.listProductSearchMarket,
          skip: action.listProductSearchMarket
            ? action.listProductSearchMarket.skip
            : 0,
          isLoadNew: false,
          data: action.listProductSearchMarket.isLoadNew
            ? action.listProductSearchMarket.data
            : [
                ...state.listProductSearchMarket.data,
                ...action.listProductSearchMarket.data,
              ],
          dataOutOfStock: action.listProductSearchMarket.isLoadNew
            ? action.listProductSearchMarket.dataOutOfStock
            : [
                ...state.listProductSearchMarket.dataOutOfStock,
                ...action.listProductSearchMarket.dataOutOfStock,
              ],
          isEndSearchMarket: action.listProductSearchMarket.isLoadNew
            ? false
            : state.listProductSearchMarket.isEndSearchMarket,
        },
        listSearchHistory: cook(
          state.listSearchHistory,
          action.listProductSearchMarket.valueSearch,
        ),
      };
    }

    case types.SET_IS_END_SEARCH_MARKET: {
      return {
        ...state,
        listProductSearchMarket: {
          ...state.listProductSearchMarket,
          data: mergeDataOutOfStock(state.listProductSearchMarket),
          isEndSearchMarket: action.payload.isEndSearchMarket,
        },
        listSearchHistory: cook(
          state.listSearchHistory,
          action.payload.valueSearch,
        ),
      };
    }

    case types.SEARCH_MARKET_FAILED: {
      return {
        ...state,
        listProductSearchMarket: {
          ...state.listProductSearchMarket,
          isLoadNew: false,
          data: [],
        },
      };
    }

    case types.CLEAR_SEARCH_MARKET: {
      return {
        ...state,
        listProductSearchMarket: null,
      };
    }

    case types.CLEAR_SEARCH_HISTORY_MARKET: {
      return {
        ...state,
        listSearchHistory: [],
      };
    }

    case types.GET_PRODUCT_DETAIL_MARKET_SUCCESS: {
      return {
        ...state,
        productDetailMarket: action.productDetailMarket,
      };
    }

    case types.CLEAR_PRODUCT_DETAIL_MARKET: {
      return {
        ...state,
        productDetailMarket: [],
      };
    }

    default:
      return state;
  }
};

export default marketReducer;
