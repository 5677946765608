import { call, put, takeLatest, fork } from "redux-saga/effects";
import actions from "./actions";
import * as TYPE from "./constants";
import api from "../api/api";

const sagas = {
  *[TYPE.POST_SEND_VOUCHER.request](action) {
    const { voucherDataToSend, onSuccess } = action.payload;
    try {
      const response = yield call(
        api[TYPE.POST_SEND_VOUCHER.request],
        voucherDataToSend
      );

      if (response.status === 202) {
        yield put(actions.POST_SEND_VOUCHER.success(response?.data));
        onSuccess?.();
      } else {
        yield put(actions.POST_SEND_VOUCHER.failure(response?.data));
      }
    } catch (error) {
      yield put(actions.POST_SEND_VOUCHER.failure(error?.data));
    }
  },

  // * GET_USER_BY_PHONE
  *[TYPE.GET_USER_BY_PHONE.request](action) {
    try {
      const response = yield call(
        api[TYPE.GET_USER_BY_PHONE.request],
        action.payload
      );

      switch (response.status) {
        case 200: {
          yield put(actions.GET_USER_BY_PHONE.success(response?.data));
          break;
        }
        case 422: {
          yield put(actions.GET_USER_BY_PHONE.failure(response?.data));
          break;
        }
        default: {
          yield put(actions.GET_USER_BY_PHONE.failure(response?.data));
          break;
        }
      }
    } catch (error) {
      yield put(actions.GET_USER_BY_PHONE.failure(error?.data));
    }
  },

  // * GET_SHORT_LINK_TO_SHARE
  *[TYPE.GET_SHORT_LINK_TO_SHARE.request](action) {
    const { url, callbackFunction } = action.payload;

    try {
      const response = yield call(api[TYPE.GET_SHORT_LINK_TO_SHARE.request], {
        url,
      });

      if (response.status === 200) {
        yield put(actions.GET_SHORT_LINK_TO_SHARE.success(response.data));
        yield call(callbackFunction, response.data.url);
      } else {
        yield put(actions.GET_SHORT_LINK_TO_SHARE.failure(response?.data));
      }
    } catch (error) {
      yield put(actions.GET_SHORT_LINK_TO_SHARE.failure(error?.data));
    }
  },
};

function* watchedSaga() {
  yield takeLatest(
    TYPE.POST_SEND_VOUCHER.request,
    sagas[TYPE.POST_SEND_VOUCHER.request]
  );

  // * GET_USER_BY_PHONE
  yield takeLatest(
    TYPE.GET_USER_BY_PHONE.request,
    sagas[TYPE.GET_USER_BY_PHONE.request]
  );
  // * GET_SHORT_LINK_TO_SHARE
  yield takeLatest(
    TYPE.GET_SHORT_LINK_TO_SHARE.request,
    sagas[TYPE.GET_SHORT_LINK_TO_SHARE.request]
  );
}

export default function* rootChild() {
  yield fork(watchedSaga);
}
