import React from "react";
import classNames from "classnames";
import "./popup.scss";
import { CloseIcon } from "assets/svg";

const Popup = (props) => {
  const {
    isOpen,
    handleClose,
    showButtonClose = false,
    title,
    titleClassName,
    children,
  } = props;
  return (
    <>
      <div className={classNames("popup-content", isOpen && "show-popup")}>
        <div
          className="popup__top"
          // {...handlers}
        >
          <div className="drag-indicator" />
          {showButtonClose && (
            <button type="button" onClick={handleClose} className="btn-close">
              <CloseIcon />
            </button>
          )}
        </div>

        {title && (
          <div className={classNames("popup__title", titleClassName)}>
            {title}
          </div>
        )}

        <div className="popup__children hide-x-scrollbar">{children}</div>
      </div>

      <div
        onClick={() => {
          if (isOpen) {
            handleClose();
          }
        }}
        className={classNames(
          "popup__overlay",
          isOpen && "popup__overlay--show"
        )}
      />
    </>
  );
};

export default Popup;
