export const GET_VOUCHER_DETAIL_REQUEST =
  'voucherDetail/GET_VOUCHER_DETAIL_REQUEST';
export const GET_VOUCHER_DETAIL_SUCCESS =
  'voucherDetail/GET_VOUCHER_DETAIL_SUCCESS';
export const GET_VOUCHER_DETAIL_FAILED =
  'voucherDetail/GET_VOUCHER_DETAIL_FAILED';
export const CHECK_STOCK_VOUCHER_REQUEST =
  'voucherDetail/CHECK_STOCK_VOUCHER_REQUEST';
export const CHECK_STOCK_VOUCHER_SUCCESS =
  'voucherDetail/CHECK_STOCK_VOUCHER_SUCCESS';
export const CHECK_STOCK_VOUCHER_FAILED =
  'voucherDetail/CHECK_STOCK_VOUCHER_FAILED';
export const ADD_VOUCHER_CART = 'voucherDetail/ADD_VOUCHER_CART';
export const UPDATE_VOUCHER_CART = 'voucherDetail/UPDATE_VOUCHER_CART';
export const CHECK_DISCOUNT_COUPON_REQUEST =
  'voucherDetail/CHECK_DISCOUNT_COUPON_REQUEST';
export const CHECK_DISCOUNT_COUPON_SUCCESS =
  'voucherDetail/CHECK_DISCOUNT_COUPON_SUCCESS';
export const CHECK_DISCOUNT_COUPON_FAILED =
  'voucherDetail/CHECK_DISCOUNT_COUPON_FAILED';
export const CREATE_ORDER_REQUEST = 'voucherDetail/CREATE_ORDER_REQUEST';
export const CREATE_ORDER_SUCCESS = 'voucherDetail/CREATE_ORDER_SUCCESS';
export const CREATE_ORDER_FAILED = 'voucherDetail/CREATE_ORDER_FAILED';
export const REFUND_VOUCHER_REQUEST = 'voucherDetail/REFUND_VOUCHER_REQUEST';
export const REFUND_VOUCHER_SUCCESS = 'voucherDetail/REFUND_VOUCHER_SUCCESS';
export const REFUND_VOUCHER_FAILED = 'voucherDetail/REFUND_VOUCHER_FAILED';
