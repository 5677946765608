/* eslint-disable no-param-reassign */
import { addErrors, delErrors } from "utils/ErrorsGenerator";
import * as TYPE from "./constants";

const reducers = {
  [TYPE.VOUCHER_DETAIL_BY_USER.success]: (draft, { payload }) => {
    return {
      ...draft,
      errors: delErrors(draft.errors, TYPE.VOUCHER_DETAIL_BY_USER.failure),
      voucherDetailByUser: payload,
    };
  },

  [TYPE.VOUCHER_DETAIL_BY_USER.failure]: (draft, { payload }) => {
    draft.errors = addErrors(draft.errors, {
      type: TYPE.VOUCHER_DETAIL_BY_USER.failure,
      msg: payload,
    });
  },
};

export default reducers;
