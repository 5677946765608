import request, { requestWithoutToken } from "services/request";
import { encodeQueryData } from "utils/stringFormat";

import * as TYPE from "../store/constants";

const api = {
  [TYPE.GET_MERCHANT_INFO.request]: async (merchantId) => {
    return requestWithoutToken(`/cms/core/merchant/${merchantId}`, "GET");
  },
  [TYPE.GET_STORE_INFO.request]: async (storeId) => {
    return requestWithoutToken(`/cms/core/stores/${storeId}`, "GET");
  },
  [TYPE.GET_BEST_SELLER_LIST.request]: async (merchantId) => {
    return request(`/cms/core/fnb/${merchantId}/productsbytags`, "GET");
  },
  [TYPE.GET_PRODUCT_LIST.request]: async (merchantId) => {
    return requestWithoutToken(
      `/cms/core/v2/fnb/products?skip=0&take=200&merchantid=${merchantId}`,
      "GET"
    );
  },
  [TYPE.GET_RECENT_ORDERED_PRODUCT_CODE_LIST.request]: async (params) => {
    const query = encodeQueryData({
      storeid: params.storeId,
      merchantid: params.merchantId,
    });

    return request(`/Order/GetProductSuggestByUser${query}`, "GET");
  },
  [TYPE.GET_SUGGESTED_COUPON_LIST.request]: async (params) => {
    const query = encodeQueryData({
      StoreId: params.storeId,
      MerchantId: params.merchantId,
      Feature: "orderfnb",
    });

    if (params?.viewAsGuest) {
      return requestWithoutToken(`/coupon/GetSuggestCoupon${query}`, "GET");
    }

    return request(`/coupon/GetSuggestCoupon${query}`, "GET");
  },
  [TYPE.SAVE_SUGGESTED_COUPON.request]: async (code) => {
    const data = { code };

    return request(`/coupon/GetAvailableCouponsByCode`, "POST", data);
  },
  [TYPE.GET_OUT_OF_STOCK_PRODUCT_LIST.request]: async (params) => {
    const query = encodeQueryData({
      merchantId: params.merchantId,
      storeId: params.storeId,
    });
    return request(`order/GetOutOfStockByStore${query}`, "GET");
  },
  [TYPE.GET_SHORT_LINK_TO_SHARE.request]: async (params) => {
    const { url } = params;

    return requestWithoutToken(`/common/ShortUrl`, "POST", { url });
  },
  [TYPE.GET_STORE_INFO_V2.request]: async (storeId) => {
    return requestWithoutToken(`/cms/store/${storeId}`, "GET");
  },
};

export default api;
