import produce from 'immer';
import {
  POST_SUCCESS,
  POST_REQUEST,
  POST_FAILED,
  CLEAR_ERRORS,
  COUNT_LIST_VIDEO,
} from './types';

const initialState = {
  errors: null,
  listPost: [],
  isRefresh: false,
  skipCount: 0,
  countList: 0,
};

const homeReducer = produce((draft = initialState, action) => {
  const {payload} = action;
  const refresh = action?.payload?.isRefresh;
  switch (action.type) {
    case POST_REQUEST:
      return {
        ...draft,
        isRefresh: refresh,
      };

    case POST_SUCCESS:
      return {
        ...draft,
        isRefresh: false,
        errors: null,
        listPost: payload.result,
        skipCount: payload.skipCount,
      };

    case POST_FAILED:
      return {
        ...draft,
        isRefresh: false,
        errors: payload,
      };

    case COUNT_LIST_VIDEO:
      return {
        ...draft,
        countList: payload,
      };

    case CLEAR_ERRORS:
      return {...draft, errors: null};

    default:
      return draft;
  }
});

export default homeReducer;
