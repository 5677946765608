import { Slider } from "infinite-react-carousel";
import React from "react";
// import BannerStore from "assets/img/Banner_store.png";
import BannerMarket from "assets/img/BannerMarket.jpg";

import "./CustomSlider.scss";
import classNames from "classnames";

const defaultSettings = {
  autoplay: true,
  dots: true,
  arrows: false,
  duration: 100,
  autoplaySpeed: 4000,
};

const CustomSlider = (props) => {
  const {
    items = [],
    fallbackImage,
    settings = {},
    imgClassName,
    sliderClassName,
    imgWrapperClassName,
  } = props;

  if (!items || !items?.length > 0) {
    if (!fallbackImage) {
      return (
        <div className="custom-slider">
          <div className="custom-slider__img__container">
            <img
              src={BannerMarket}
              className={classNames("custom-slider__img--main", imgClassName)}
            />

            <img
              src={BannerMarket}
              className="custom-slider__img--background--blur"
            />
          </div>
        </div>
      );
    }

    return (
      <div>
        <img src={fallbackImage} alt="fallback" />
      </div>
    );
  }

  return (
    <div className="custom-slider">
      {items.length > 0 && (
        <Slider {...defaultSettings} {...settings} className={sliderClassName}>
          {items.map((item) => (
            <div
              key={item.imageUrl}
              className={classNames(
                "custom-slider__img__container",
                imgWrapperClassName
              )}
            >
              <img
                src={item.imageUrl}
                className={classNames("custom-slider__img--main", imgClassName)}
              />

              {/* blurred background */}
              <img
                src={item.imageUrl}
                className="custom-slider__img--background--blur"
              />
            </div>
          ))}
        </Slider>
      )}
    </div>
  );
};

export default React.memo(CustomSlider);
