import {LOGOUT_REQUEST} from '@shared/redux/account/constants';
import produce from 'immer';
import {
  HASHTAG_LIST_SUCCESS,
  HASHTAG_LIST_FAILED,
  HASHTAG_DETAIL_SUCCESS,
  HASHTAG_DETAIL_FAILED,
  HASHTAG_INFO_SUCCESS,
  HASHTAG_INFO_FAILED,
  FAVORITE_HASHTAG_SUCCESS,
  FAVORITE_HASHTAG_FAILED,
  DIS_FAVORITE_HASHTAG_SUCCESS,
  DIS_FAVORITE_HASHTAG_FAILED,
  HASHTAG_LIST_REQUEST,
  HASHTAG_DETAIL_REQUEST,
} from './types';

const initialState = {
  errors: null,
  listHashtag: {},
  hashtagDetail: [],
  hashtagInfo: {},
  favoriteHashtag: null,
  disFavoriteHashtag: null,
  isRefresh: false,
};

const hashtagReducer = produce((draft = initialState, action) => {
  const {payload} = action;
  const refresh = action?.payload?.isRefresh || false;
  switch (action.type) {
    // List hashtag
    case HASHTAG_LIST_REQUEST:
      return {
        ...draft,
        isRefresh: refresh,
      };
    case HASHTAG_LIST_SUCCESS:
      return {
        ...draft,
        errors: null,
        listHashtag: payload,
        isRefresh: false,
      };
    case HASHTAG_LIST_FAILED:
      return {
        ...draft,
        errors: payload,
        isRefresh: false,
      };
    // List video hashtag detail
    case HASHTAG_DETAIL_REQUEST:
      return {
        ...draft,
        isRefresh: refresh,
      };
    case HASHTAG_DETAIL_SUCCESS:
      return {
        ...draft,
        errors: null,
        hashtagDetail: payload,
        isRefresh: false,
      };
    case HASHTAG_DETAIL_FAILED:
      return {
        ...draft,
        errors: payload,
        isRefresh: false,
      };
    // Info hashtag detail
    case HASHTAG_INFO_SUCCESS:
      return {
        ...draft,
        errors: null,
        hashtagInfo: payload,
      };
    case HASHTAG_INFO_FAILED:
      return {
        ...draft,
        errors: payload,
      };
    // favorite hashtag
    case FAVORITE_HASHTAG_SUCCESS:
      return {
        ...draft,
        errors: null,
        favoriteHashtag: payload,
      };
    case FAVORITE_HASHTAG_FAILED:
      return {
        ...draft,
        errors: payload,
      };
    // dis favorite hashtag
    case DIS_FAVORITE_HASHTAG_SUCCESS:
      return {
        ...draft,
        errors: null,
        disFavoriteHashtag: payload,
      };
    case DIS_FAVORITE_HASHTAG_FAILED:
      return {
        ...draft,
        errors: payload,
      };
    case LOGOUT_REQUEST: {
      return {
        ...initialState,
      };
    }
    default:
      return draft;
  }
});

export default hashtagReducer;
