import AppConfigs from 'configs/env'
import Platform from 'helpers/platform'
const domain = AppConfigs.domain
const domainAFFAPI = AppConfigs.domainAFFAPI
const domainUtopAppAPI = AppConfigs.domainUtopAppAPI
const CMSStatic = AppConfigs.CMSStatic
const downtimeServer = AppConfigs.downtimeServer
const prefixApiVersion = AppConfigs.prefixApiVersion
const domainVideoVersion = AppConfigs.domainVideo + AppConfigs.versionVideo
const domainVideo = AppConfigs.domainVideo
const domainCommentVideo =
  AppConfigs.domainCommentVideo + AppConfigs.versionCommentVideo

const END_POINTS = {
  GIFT_REDEEM_TRANSACTION_URL: '/CreateRedeemTransaction', // this api have dynamic domain based on dappIP
  GET_MERCHANT_COIN_BALANCE_URL: '/GetMerchantCoinBalance', // this api have dynamic domain based on dappIP
  EXCHANGE_TO_TOKEN_URL: '/ExchangeToToken', // this api have dynamic domain based on dappIP

  // Api Utop Daily Game
  LOTTERY_EVENT_URL: domain + 'Event/Lottery',
  // Api First login screen
  UPDATE_MEMBER_INFO_URL: domain + 'Member/Update',
  // OneSignal API
  GET_ALL_NOTIFICATIONS_URL: domain + 'Member/GetAllNotification',
  // Api Menu Member info
  GET_MEMBER_INFO_URL: domain + 'Member/GetById',
  // Api CheckPhoneNumber
  CHECK_PHONE_NUMBER_URL: domain + 'Member/CheckPhoneNumber',
  // Api Gift Screen
  GET_GIFT_LIST_URL: domain + 'GiftRedeemTransaction/GetAll',
  GET_GIFT_LIST_BY_ID_URL: domain + 'GiftRedeemTransaction/GetById',
  UPDATE_REDEEM_TRANSACTION_URL:
    domain + 'GiftRedeemTransaction/UpdateRedeemTransaction',
  GET_GIFT_DETAIL_BY_ID_URL: domain + 'Gift/GetById',

  // Api Card Screen
  GET_USER_MAPPING_URL: domain + 'GetUserMapping',
  // RemoveUserMapping: domain + "RemoveUserMapping",

  //Api Meunu History
  GET_TOKEN_TRANSACTION_URL: '/GetTokenTransaction', // this api have dynamic domain based on dappIP
  CHANGE_PIN_CODE_URL: domain + 'PinCode/ChangePinCode',

  // Api QrPay Screen
  GET_ALL_MERCHANT_STORE_URL: domain + 'MerchantStore/GetAll',

  // Api New Domain
  Login: (authenIP, sessionId, callback, merchantId) => {
    return (
      authenIP +
      '/login?sessionid=' +
      sessionId +
      '&callback=' +
      callback +
      '&merchantid=' +
      merchantId
    )
  },

  // Api Voucher
  REDEEM_VOUCHER_URL: '/Voucher/RedeemVoucher',

  // Api Mapping merchant
  MAPPING_MERCHANT_URL: domain + 'merchantMemberMapping',

  // Api Check Down Time
  CHECK_DOWN_TIME_URL: downtimeServer,

  // Api getListInforIPay
  GET_LIST_INFOR_I_PAY_URL: domain + 'IPay/getListInforIPay',

  // Api FeedBack
  UPDATE_FEEDBACK_URL: domain + 'updateFeedback',
  GET_FEEDBACK_BY_TRANSACTION_URL: domain + 'getFeedbackByTransaction',

  // Api Share Gift
  SHARE_GIFT_BY_MEMBER_ID_URL: domain + 'ShareGiftbyMemberId',

  // Api Campain
  UPLOAD_IMAGE_URL: domain + 'UploadImage',
  CONFIRM_IMAGE_LIST_URL: domain + 'ConfirmImageList',

  // Api Aff
  GET_TOTAL_AMOUNT_BY_TRANSACTION_TYPE_URL:
    domainAFFAPI +
    'aff-transaction/api/Transaction/GetTotalAmountByTransactionType',
  GET_TRANSACTION_BY_USER_URL:
    domainAFFAPI + 'aff-transaction/api/Transaction/GetTransactionByUser',
  GET_LIST_CAMPAIGN_URL: domainAFFAPI + 'aff/cms/campaign',
  GET_CAMPAIGN_CONTENT_URL: domainAFFAPI + 'aff/cms/campaigncontent',
  GET_TRANSACTION_URL: domainAFFAPI + 'aff/',

  // Api UtopV2
  GET_SHORTCUTS_URL: domainAFFAPI + 'cms/core/v4/shortcuts',
  GET_BANNERS_URL: domainAFFAPI + 'cms/core/banners',
  GET_BANNERS_BY_LOCATION_URL: domainUtopAppAPI + 'component/banners',
  GET_PRODUCT_DETAILS_URL: domainAFFAPI + 'cms/core/product/',
  GET_UTOP_BALANCE_URL: domainAFFAPI + 'wallet/info',
  GET_MERCHANT_INFO_URL: domainAFFAPI + 'cms/core/merchant/',
  PAY_BY_TOKEN_V2_URL: domainAFFAPI + 'wallet/PayByToken',
  GET_DELIVERY_ADDRESS_BOOK_LIST_URL:
    domainAFFAPI + 'personal/getuseraddressbook',
  DELETE_DELIVERY_ADDRESS_BOOK_LIST_URL:
    domainAFFAPI + 'personal/deleteaddressofuseraddressbook',
  SET_DEFAULT_OF_USER_ADDRESS_BOOK_URL:
    domainAFFAPI + 'personal/setdefaultofuseraddressbook',
  CREATE_ORDER_URL: domainAFFAPI + 'Order/CreateOrder',
  CREATE_ORDER_FNB_HUB_URL: domainAFFAPI + 'Order/CreateOrderFNBHub',
  GET_LIST_ORDER_URL: domainAFFAPI + 'Order/GetListOrderByUser',
  GET_LIST_HISTORY_ORDER_URL: domainAFFAPI + 'Order/GetListOrderHistoryByUser',
  GET_ORDER_DETAIL_URL: domainAFFAPI + 'Order/GetOrderDetailByUser',
  CHECK_STOCK_URL: domainAFFAPI + 'Order/CheckStockOfProduct',
  CANCEL_ORDER_URL: domainAFFAPI + 'Order/CancelOrder',
  CANCEL_PENDING_ITEM_URL: domainAFFAPI + 'Order/CancelPendingItem',
  GET_PRODUCT_CHANGED_URL: domainAFFAPI + 'Order/Reorder',
  RECEIVE_ORDER_URL: domainAFFAPI + 'Order/ReceiveOrder',
  PAYMENT_URL: domainAFFAPI + 'wallet/Payment',
  PAYMENT_WITH_VERSION_URL:
    domainAFFAPI + `wallet/api/${prefixApiVersion}/Transaction/Pay`,
  GET_ALL_MERCHANT_EXCHANGE_URL: domainAFFAPI + 'cms/core/partners',
  MERCHANT_MEMBER_MAPPING_URL: domainAFFAPI + 'exchange/merchantMemberMapping',
  GET_LIST_CATEGORY_URL: domainAFFAPI + 'cms/core/category/',
  GET_DETAIL_BRAND_URL: domainAFFAPI + 'cms/core/brands/',
  GET_MY_VOUCHER_URL: domainAFFAPI + 'voucher/myvouchers',
  GET_MY_VOUCHER_BY_USER_URL: domainAFFAPI + 'voucher/GetVoucherDetailByUser',
  GET_VOUCHER_DETAIL_URL: domainAFFAPI + 'voucher/VoucherDetail',
  MARK_USED_URL: domainAFFAPI + 'voucher/markUsed',
  GET_LIST_MOBILE_CARD_URL: domainAFFAPI + 'cms/core/category/',
  GET_HISTORY_URL: domainAFFAPI + `wallet/api/${prefixApiVersion}/Transaction`,
  GET_TRANSACTION_BY_CODE:
    domainAFFAPI + `wallet/api/${prefixApiVersion}/Transaction/`,
  EXCHANGE_REDEEM_VOUCHER_URL: domainAFFAPI + 'exchange/Voucher/RedeemVoucher',
  GET_VOUCHER_URL: domainAFFAPI + 'exchange/Voucher/VoucherDetail',
  REQUEST_OTP_URL: domainAFFAPI + 'otp/RequestOTP',
  VERIFY_OTP_URL: domainAFFAPI + 'otp/VerifyOTP',
  REMOVE_USER_MAPPING_URL: domainAFFAPI + 'exchange/RemoveUserMapping',
  GET_LIST_GAME_URL: domainAFFAPI + 'cms/core/games',
  GET_PAYMENT_METHOD_URL: domainAFFAPI + 'cms/core/paymentmethods',
  SAVE_INFO_URL: domainAFFAPI + 'personal/saveinfo',
  GET_INFO_URL: domainAFFAPI + 'personal/getInfo',
  GET_PRODUCT_BASED_LOCATION_URL: domainAFFAPI + 'cms/core/newsfeedv2',
  GET_VIDEO_BASED_LOCATION_URL: domainAFFAPI + 'video/GetVideoPersonalize',
  SET_PIN_CODE_URL: domainAFFAPI + 'personal/SetPinCode',
  SKIP_PIN_CODE_URL: domainAFFAPI + 'personal/skipPinCode',
  CHECK_PHONE_NUMBER_TRANSFER_URL: domainAFFAPI + 'personal/GetUserByPhone',
  TRANSFER_UTOP_URL: domainAFFAPI + 'wallet/TransferUtop',
  GET_LIST_STORE_BY_ZONE_URL: domainAFFAPI + 'cms/core/v2/searchstore',
  GET_DATA_FNB_URL: domainAFFAPI + 'cms/core/fnb/',
  GET_ALL_NOTIFICATIONS_NEW_URL: domainAFFAPI + 'Notification/notifications',
  GET_DETAIL_COUPON_URL: domainAFFAPI + 'cms/core/coupons/coupondetail',
  CHECK_COUPON_DISCOUNT_URL: domainAFFAPI + 'coupon/CalculateCouponDiscountHub',
  CHECK_COUPON_DISCOUNT_ORDER_DETAIL_URL:
    domainAFFAPI + 'Coupon/CalculateCouponDiscountByOrderCode',
  GET_COUPON_CAMPAIGN_BY_COUPON_CODE_URL:
    domainAFFAPI + 'coupon/GetCouponCampaignByCouponCode',
  GET_AVAILABLE_COUPONS_BY_CODE_URL:
    domainAFFAPI + 'coupon/GetAvailableCouponsByCode',
  UTOP_SHARING_REFER_FRIENDS_URL: domainAFFAPI + '',
  CALCULATE_SHIPPING_URL: domainAFFAPI + 'shipping/estimate',
  GET_DEFAULT_ADDRESS_USER_URL:
    domainAFFAPI + 'personal/GetDefaultUserAddressBook',
  GET_INFO_STORE_URL: domainAFFAPI + 'cms/core/stores/',
  GET_CMS_STATIC_URL: CMSStatic + 'article/',
  USER_RATING_URL: domainAFFAPI + 'Order/rateOrder',
  SEARCH_FEED_URL: domainAFFAPI + 'cms/core/searchfeed',
  GET_OUT_OF_STOCK_BY_STORE_URL: domainAFFAPI + 'order/GetOutOfStockByStore',
  GET_CURRENT_LIST_CARD_URL: domainAFFAPI + 'paymentcard/GetCurrentListCard',
  CREATE_TOKENIZATION_URL: domainAFFAPI + 'paymentcard/CreateTokenization',
  CANCEL_TOKENIZATION_URL: domainAFFAPI + 'paymentcard/CancelTokenization',
  CREATE_PAYMENT_URL: domainAFFAPI + 'Order/CreatePaymentUrl',
  GET_INFOSTORE_BY_STORE_CODE: domainAFFAPI + 'cms/core/storescode/',

  // member card
  CHECK_STATUS_CARD_MEMBER: domainAFFAPI + 'canteen/card/',
  GET_INFO_TENANT: domainAFFAPI + 'canteen/Organization/',
  GET_LIST_CARD_MEMBER: domainAFFAPI + 'canteen/card/',
  LINK_CARD_MEMBER: domainAFFAPI + 'canteen/card',
  CANCEL_LINK_CARD_FOXPAY: domainAFFAPI + 'paymentcard/',
  CANCEL_LINK_CARD_MEMBER: domainAFFAPI + 'canteen/card/',
  UPDATE_INFO_CARD_MEMBER: domainAFFAPI + 'canteen/card/',

  // api CMS
  GET_INFO_STORE_DETAIL_URL: domainAFFAPI + 'cms/core/merchant/',
  CMS_GET_VOUCHER_BY_STORE_URL: domainAFFAPI + 'cms/core/vouchers/',
  CMS_GET_VOUCHER_DETAIL_URL: domainAFFAPI + 'cms/core/voucherbyid',
  CMS_GET_PRODUCT_DETAIL_V3_URL: domainAFFAPI + 'cms/core/products/',

  //api UtopV3
  CMS_GET_BANNERS_SHORTCUTS_MALL_URL: domainAFFAPI + 'cms/core/malls/',
  CMS_GET_LIST_FEED_URL: domainAFFAPI + 'cms/core/searchfeed',
  CREATE_ORDER_HUB_URL: domainAFFAPI + 'Order/CreateOrderHub',
  CREATE_ORDER_UTOP_HUB_URL: domainAFFAPI + 'Order/CreateOrderUtopHub',
  GET_PAYMENT_INFO_URL: domainUtopAppAPI + 'Partner/Payment',
  REFUND_VOUCHER_BY_TRANSACTION_CODE_URL:
    domainAFFAPI + 'voucher/RefundVoucherByTransactionCode',

  CHECK_CITY_URL: domainAFFAPI + 'cms/core/latlong2city',
  GIFT_GROUP_GET_GIFT_LIST_BY_ID_URL: domain + 'GiftGroup/GetGiftListById',
  GET_SUGGEST_COUPON: domainAFFAPI + 'Coupon/GetSuggestCoupon',
  ALLOWCATION_COUPON: domainAFFAPI + 'Coupon/AllocationCoupon',
  //dynamic pages
  GET_DYNAMIC_PAGE_URL: domainAFFAPI + 'cms/core/pages/',
  GET_MOBILE_CARD_LIST: domainAFFAPI + 'cms/core/category/',
  GET_PRODUCT_SUGGEST_BY_USER: domainAFFAPI + 'Order/GetProductSuggestByUser',
  AR_GEN_TOKEN: domainAFFAPI,
  DOMAIN_AR_API: AppConfigs.domainARAPI,

  //PINCODE
  UPDATE_PIN_CODE_URL: domainUtopAppAPI + 'Wallet/User/ChangePin',
  VERIFY_PIN_CODE_URL: domainUtopAppAPI + 'Wallet/User/VerifyPin',
  TRANSFER_URL: domainUtopAppAPI + 'Wallet/Transaction/Transfer',

  //Smart Order Menu
  GET_LIST_PRODUCT_BY_MERCHANT_ID:
    domainAFFAPI + 'cms/core/v2/fnb/products?skip=0&take=200',

  //user profile
  UPDATE_USER_PROFILE_URL: domainUtopAppAPI + 'personal/UpdateUserProfile',

  // upload file
  GET_TOKEN_UPLOAD_URL: domainUtopAppAPI + 'upload/api/Upload/GetToken',

  //Dynamic modal
  GET_DYNAMIC_MODAL_URL: domainUtopAppAPI + 'Campaign/Campaign',

  //Suggest banner
  GET_SUGGEST_BANNER_URL: domainUtopAppAPI + 'component/banners/suggest',
  // UTOP BUSINESS
  VERIFY_LINK_BUSINESS: domainAFFAPI + '/Business/Registration/Confirm',
  GET_LIST_STORE_UB: domainAFFAPI + 'Business/Store',
  GET_LIST_STORE_TYPE: domainAFFAPI + 'Business/StoreType/',
  UTOP_BUSINESS_STORE_DETAIL: domainAFFAPI + 'Business/Store/',
  GET_LIST_BUDGET: domainAFFAPI + 'Business/Budget',
  GET_BUDGET_INFO: domainAFFAPI + 'Business/Budget/',
  GET_HISTORY_TRANSACTION: domainAFFAPI + 'Business/Transaction',
  CONFIRM_ADVANCE_INFO: domainAFFAPI + 'Business/Budget/',
  REVOCATION_BUDGET_INFO: domainAFFAPI + 'Business/Budget/',
  GET_TRANSACTION_DETAIL: domainAFFAPI + 'Business/Transaction/',
  GET_DETAIL_VERIFY: domainAFFAPI + 'Business/Registration',
  GET_USER_BUSINESS: domainAFFAPI + 'Business/User/',
  CHECK_ACCOUNT_BUSINESS: domainAFFAPI + 'Business/User/Validate',
  GET_TRANSACTION_TRANSACTION_UB: domainAFFAPI + 'Business/Transaction',
  PAY_BY_TOKEN_TRANSACTION_V1: domainAFFAPI + 'wallet/PayByToken',
  PAY_BY_TOKEN_TRANSACTION_V2:
    domainAFFAPI + `wallet/${prefixApiVersion}/Transaction/PayByToken`,
  CHECK_UTOP_BUSINESS: domainAFFAPI + 'Business/Store/',
  GET_LIST_BUDGET_SOUCRE: domainAFFAPI + 'Business/User/Budget',
  GET_LIST_LEGAL: domainAFFAPI + 'Business/LegalEntity',
  CHECK_QR: domainAFFAPI + 'QRCode/Check',
  LOAD_DATA_QR: domainAFFAPI + 'QRCode/Scan',
  PAY_BY_UTOP_BUSINESS: domainAFFAPI + 'Business/Budget/',
  UTOP_BUSINESS_UPLOAD_IMAGE: domainAFFAPI + 'Business/Image/Receipt',
  GET_LIST_CITY: domainAFFAPI + 'Business/City',
  GET_LIST_DISTRICT: domainAFFAPI + 'Business/District',
  UTOP_BUSINESS_GET_LIST_BOOKING_REQUEST:
    domainAFFAPI + 'Business/Reservation/History',
  CREATE_RESERVATION: domainAFFAPI + 'Business/Reservation',
  UTOP_BUSINESS_BOOKING_REQUEST: domainAFFAPI + 'Business/Reservation/',
  // App version
  GET_SUPPORTED_VERSION_URL: domainUtopAppAPI + 'Version/Supported',

  //Short link
  SHORT_URL: domainUtopAppAPI + 'common/ShortUrl',
  SHORT_LINK: domainUtopAppAPI + 'common/shortlink',
  PERSONALIZE_SHORTCUT_URL: domainUtopAppAPI + 'component/shortcuts',

  //Utop Back
  URL_GET_UTOP_TOTAL:
    domainUtopAppAPI +
    'aff-transaction/api/Transaction/GetTotalAmountByTransactionType',
  URL_GET_LIST_CAMPAIN:
    domainUtopAppAPI + 'aff/cms/campaign?TransactionType=AccessTrade',
  URL_GET_AFF_DETAIL: domainUtopAppAPI + 'aff/cms/campaigncontent',
  URL_GET_AFF_HISTORY: domainUtopAppAPI + 'aff/',
  URL_GET_AFF_HISTORY_RECEIVE:
    domainUtopAppAPI + 'aff-transaction/api/Transaction/GetTransactionByUser',
  // Referral
  URL_GET_REFERRAL_PROCESSING: `${domainUtopAppAPI}utopreferral/Referral/Processing`,
  URL_GET_REFERRAL_REFERRED: `${domainUtopAppAPI}utopreferral/Referral/Referred`,
  URL_GET_REFERRAL_REWARD: `${domainUtopAppAPI}utopreferral/Referral/RewardItem`,
  //suggest discount shipping
  GET_SUGGEST_DISCOUNT_SHIPPING: domainAFFAPI + 'Shipping/SuggestDiscount',

  // market
  SEARCH_PRODUCT_MARKET: domainAFFAPI + 'CMS/ProductFnb/Search',
  GET_PRODUCT_MARKET: domainAFFAPI + 'CMS/ProductFnb',
  GET_LIST_PRODUCT_FNB: domainAFFAPI + 'CMS/ProductFnb/StoreView',
  GET_LIST_CATEGORY: domainAFFAPI + 'CMS/fnbcategory',
  URL_MARKET_GET_MARKET_INFO: domainAFFAPI + 'cms/store/',
  URL_MARKET_GET_LIST_FNB_TYPE: domainAFFAPI + 'cms/fnbType',
  URL_MARKET_GET_LIST_CATEGORY_FNB_TYPE: domainAFFAPI + 'cms/FnbType',
  URL_MARKET_GET_LIST_MEDIA_HOME: domainAFFAPI + 'cms/MediaAsset/',
  URL_MARKET_GET_LIST_MEDIA_SUMMARY: domainAFFAPI + 'cms/MediaAsset/Summary/',
  //order address food
  CREATE_DEFAULT_ADDRESS: domainAFFAPI + 'personal/CreateDefaultAddress',
  URL_MARKET_GET_LIST_FNB_BESTSELLER:
    domainAFFAPI + 'cms/productfnb/bestseller',
  URL_MARKET_GET_LIST_SUGGEST_PRODUCT: domainAFFAPI + 'Order/SuggestProduct',
  GET_ESTIMATE_DELIVERY_TIME: domainAFFAPI + 'shipping/deliveryTime',

  //LOV
  LOV_URL: domainUtopAppAPI + 'cms/core/lov/',
  URL_MARKET_GET_LIST_STORE_MERCHANT: domainAFFAPI + 'cms/CategoryFnb/Search',
  URL_MARKET_GET_STORE_DETAIL_MERCHANT: domainAFFAPI + 'cms/CategoryFnb/',
  //fnb rating
  GET_LIST_TAGS_BY_ORDER_ID: domainAFFAPI + 'Order/Category',
  LOGIN_URL: domain + '/api/v1/account/login',

  //Get package detail info
  GET_PACKAGE_DETAIL_NEWS: domain + '/NewspaperWeb/GetInfoDetail',

  //Get list edition
  GET_LIST_EDITION: domain + '/NewspaperWeb/GetEditionList',

  //Get newspaper
  GET_NEWSPAPER: domain + '/NewspaperWeb/GetNewspaper',

  //Get detail transaction
  GET_DETAIL_TRANSACTION: domain + `/OrderWeb`,

  //Create order
  CREATE_ORDER_NEWS: domain + '/OrderWeb',

  //Get status order detail
  GET_STATUS_ORDER_DETAIL_NEWS: domain + '/Transaction',

  // Cancel order
  PUSH_CANCEL_ORDER_NEWS: domain + '/OrderWeb/cancel',

  //Get repayment
  GET_REPAYMENT_TRANSACTION_LINK_NEWS: domain + '/OrderWeb/payment',

  //Get block of school info
  GET_BLOCK_SCHOOL: domain + '/School/block/',

  //Get list recommend with package
  GET_LIST_RECOMMEND_WITH_PACKAGE: domain + '/NewspaperWeb/GetRelation',

  //Get list images with id news
  GET_LIST_IMAGES_WITH_EDITION_ID: domain + '/NewspaperWeb/GetImagesByEdition',

  //Get list news with type
  GET_LIST_NEWS_WITH_TYPE: domain + '/NewspaperType',

  //Get list news data with type
  GET_LIST_NEWS_DATA_WITH_TYPE_BLOCK: domain + '/NewsPaperWeb/GetNewsPaper',

  //Get list number news of news current
  GET_LIST_IMAGES_NEWS_CURRENT: domain + '/NewspaperWeb/GetScrollEdition',

  //Get type edition renew
  GET_EDITION_RENEW: domain + '/NewspaperWeb/EditionRenew',

  // Get sas token
  GET_SAS_TOKEN_NEWS: domain + '/Media',
  LOGIN_URL: domain + '/api/v1/account/login',
  ADD_DEVICE_TOKEN_URL: domain + '/api/v1/add-device-token',
  GET_LIST_FACTORY_URL: domain + '/api/v1/factory',
  GET_LIST_CABINET_BY_FACTORY_URL: domain + '/api/v1/cabinet-by-factory',
  GET_LATEST_CABINET_INFO_URL: domain + '/api/v1/tracking-cabinet/latest',

  // Get List Insurance
  GET_LIST_INSURANCE_TYPE_URL: domain + 'InsuranceType',
  GET_LIST_INSURANCE_ITEM_WITH_TYPE_URL: domain + 'Program/App/',
  CREATE_TRANSACTION_INSURANCE_URL: domain + 'Order/app',
  // get insurance detail by id
  GET_INSURANCE_DETAIL_BY_ID: domain + 'Program',

  //Get Status Order Detail
  GET_STATUS_ORDER_DETAIL: domain + 'Transaction/app/',

  //Get detail confirm info
  GET_DETAIL_CONFIRM_INFO: domain + 'Order/app',
  PUSH_CANCEL_ORDER: domain + 'Order/app/cancel',

  // get user info
  GET_USER_INFO: domain + 'User/app/info',
  GET_REPAYMENT_TRANSACTION_LINK: domain + 'Order/app/payment',
  GetHashtagDetail: domainVideoVersion + '/hashtag/detail',
  FavoriteHashtag: domainVideoVersion + '/hashtag/Favorite',
  DisFavorHashtag: domainVideoVersion + '/hashtag/DisFavor',
  SearchHashTag: domainVideoVersion + '/hashtag/Search',

  //Keyword
  HotKey: domainVideoVersion + '/keyword/hot',
  GetReasonReport: domainVideoVersion + '/keyword/ReasonReport',
  HotHashTag: domainVideoVersion + '/keyword/hotHashtag',

  //Personal
  UserInfo: domainVideoVersion + '/Personal',
  BlockCreatorByCreatorId: domainVideoVersion + '/Personal/block',
  UnBlockCreatorByCreatorId: domainVideoVersion + '/Personal/UnBlock',
  GetListCreatorBlockedByCreatorToken:
    domainVideoVersion + '/Personal/ListCreatorBlocked',
  UnFollow: domainVideoVersion + '/Personal/UnFollow',
  Follow: domainVideoVersion + '/Personal/Follow',
  GetFavoriteHashtag: domainVideoVersion + '/Personal/FavoriteHashtag',
  GetUserInfo: domainVideoVersion + '/Personal',
  SearchPersonal: domainVideoVersion + '/Personal/Search',

  //Video
  GetDiscoveryVideos: domainVideoVersion + '/video/Discovery',
  FavoriteVideo: domainVideoVersion + '/video/Favorite',
  GetVideoPersonalize: domainVideoVersion + '/video',
  MyVideo: domainVideoVersion + '/video/MyVideo',
  GetVideosByCreatorId: domainVideoVersion + '/video/ByCreator',
  LikeVideo: domainVideoVersion + '/video/Like',
  UnLikeVideo: domainVideoVersion + '/video/UnLike',
  DisFavorVideo: domainVideoVersion + '/video/DisFavor',
  SearchVideo: domainVideoVersion + '/video/search',
  ListVideoStore: domainVideoVersion + '/video/bystore',
  UploadVideo: domainVideoVersion + '/video/Upload',
  ReportVideo: domainVideoVersion + '/video/Report',
  GetVideosByHashtag: domainVideoVersion + '/video/ByHashtag',
  RemoveVideo: domainVideoVersion + '/video/Remove',
  ReupVideo: domainVideoVersion + '/video/ReUp',
  GetVideoDetail: domainVideoVersion + '/video',
  HomepageUtopApp: domainVideoVersion + '/Video/HomePageUtopApp',
  //Video problem
  GetDetailVideoProblem: domainVideoVersion + '/videoProblem',
  //Comment
  Comment: domainCommentVideo + '/comment',
  CountComment: domainCommentVideo + '/comment/CommentInfo',
  ReportComment: domainCommentVideo + '/comment/ReportComment',
  LikeComment: domainCommentVideo + '/comment/LikeComment',
  UnLikeComment: domainCommentVideo + '/comment/UnLikeComment',

  // Store rating
  rating: AppConfigs.domainCommentVideo + AppConfigs.versionRating + '/rating',
  keyword: domainCommentVideo + '/keyword',

  HomePage: domainVideoVersion + '/Video/HomePage',

  GetBanner: domainVideo + 'GetBanner',

  UserBehavior: domainVideoVersion + '/Video/UserBehavior',

  SetPrivacyVideo: domainVideoVersion + '/Video/SetPrivacy',

  SetCommentableVideo: domainVideoVersion + '/Video/SetCommentable',
}

export { END_POINTS }
