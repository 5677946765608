import APIUtils from 'utils/apiUtils'
import { END_POINTS } from '@shared/constants/apiUrl'

export function getAddressBookListApi() {
  return APIUtils.get(END_POINTS.GET_DELIVERY_ADDRESS_BOOK_LIST_URL)
}

export function removeAddressApi(id) {
  var requestData = {
    id: id,
  }
  return APIUtils.post(END_POINTS.DELETE_DELIVERY_ADDRESS_BOOK_LIST_URL, {
    body: requestData,
  })
}

export function setDefaultAddressApi(id) {
  var requestData = {
    id: id,
  }
  return APIUtils.post(END_POINTS.SET_DEFAULT_OF_USER_ADDRESS_BOOK_URL, {
    body: requestData,
  })
}
