import { CURRENT_HANDLED_FEED_TYPES, FEED_TYPES } from "constants/types";
import { standardizeFeedImages } from "helpers/standardizeInput";
import { simplifyNumber } from "utils/numberFormat";
import { getOpenTime } from "@shared/utils";

const TYPES_USE_VOUCHER_CARD = [
  FEED_TYPES.VOUCHER.key,
  FEED_TYPES.MALL.key,
  FEED_TYPES.PRODUCT.key,
];
const TYPES_USE_STORE_CARD = [
  FEED_TYPES.STORE.key,
  FEED_TYPES.FNB.key,
  FEED_TYPES.MARKET.key,
];

const filterCurrentHandledFeedType = (feed) =>
  CURRENT_HANDLED_FEED_TYPES.includes(String(feed.feedType).toLowerCase());

export const parseListFeed = ({ listFeed, localeCode = "vi" }) => {
  if (!Array.isArray(listFeed)) return;

  return listFeed.filter(filterCurrentHandledFeedType).map((feed) => {
    const feedTypeAsLowerCase = String(feed.feedType).toLowerCase();
    const title =
      (TYPES_USE_VOUCHER_CARD.includes(feedTypeAsLowerCase) &&
        feed.title?.[localeCode]) ||
      (TYPES_USE_STORE_CARD.includes(feedTypeAsLowerCase) &&
        feed.nearestStore?.name?.[localeCode]) ||
      "";
    const coordinates =
      feed.location?.coordinates?.[0] && feed.location?.coordinates?.[1]
        ? {
            lat: feed.location.coordinates[1],
            lng: feed.location.coordinates[0],
          }
        : null;
    const tags =
      (TYPES_USE_VOUCHER_CARD.includes(feedTypeAsLowerCase) && feed.tags) ||
      (TYPES_USE_STORE_CARD.includes(feedTypeAsLowerCase) &&
        feed.nearestStore?.tags) ||
      [];

    const bestSellingVouchers =
      feed.nearestStore?.bestSellingVouchers?.map((voucher) => ({
        id: voucher.id,
        price: {
          selling: voucher.price?.selling,
          list: voucher.price?.list,
        },
        name: voucher.name?.[localeCode] || "",
        currencies:
          voucher.currencies && voucher.currencies.length > 0
            ? voucher.currencies[0]
            : null,
      })) || [];

    const priceToShow =
      feed.prices?.find((price) => price.isDefault === true) || null;

    return {
      id: feed.id,
      feedId: feed.feedId,
      feedType: feedTypeAsLowerCase,
      types: feed.types || [],
      merchantId: feed.merchantId,
      distance: simplifyNumber({
        dividend: feed.distance,
        divisor: 1000,
        digits: 2,
        endCharacter: "km",
      }),
      currencies:
        feed.currencies && feed.currencies.length > 0
          ? feed.currencies[0]
          : null,
      title,
      images: standardizeFeedImages(feed.images, 4),
      logoUrl: feed.logoUrl,
      promotionTags: feed.promotions || [],
      tags,
      avgPrice: Number(feed.nearestStore?.storeAveragePrice) || 0,
      avgRate: Number(feed.nearestStore?.rating) || 0,
      currentOpeningData: getOpenTime(feed.nearestStore?.openingTimes),
      storeCount: Number(feed.nearestStore?.storeCount) || 0,
      prices: feed.prices || [],
      priceToShow,
      nearestStoreName: feed.nearestStore?.name?.[localeCode] || "",
      description: feed.description?.[localeCode] || "",
      merchantType: feed.merchantMetadata?.merchantType || "",
      merchantName: feed.merchantMetadata?.name?.[localeCode] || "",
      mallType: feed.mallMetadata?.mallType || "",
      phone: feed.nearestStore?.phone || null,
      bestSellingVouchers,
      storeId: feed.nearestStore?.id || null,
      coordinates,
    };
  });
};
