import {
  MY_VIDEO_REQUEST,
  MY_VIDEO_SUCCESS,
  MY_VIDEO_FAILED,
  // Fetch user's info
  USER_INFO_REQUEST,
  USER_INFO_SUCCESS,
  USER_INFO_FAILED,
} from './types';

export const myVideoRequest = payload => ({
  type: MY_VIDEO_REQUEST,
  payload,
});

export const myVideoSuccess = payload => ({
  type: MY_VIDEO_SUCCESS,
  payload,
});

export const myVideoFailure = payload => ({
  type: MY_VIDEO_FAILED,
  payload,
});

export const userInfoRequest = payload => ({
  type: USER_INFO_REQUEST,
  payload,
});

export const userInfoSuccess = payload => ({
  type: USER_INFO_SUCCESS,
  payload,
});

export const userInfoFailure = payload => ({
  type: USER_INFO_FAILED,
  payload,
});
