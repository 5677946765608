export const voucherNotExp = {
  isInit: true,
  value: undefined,
};
export const voucherExp = {
  isInit: true,
  value: undefined,
};
export const isLoading = {
  GET_LIST_COUPON_MY_VOUCHER: false,
};
