import {store} from '@shared/redux/configureStore';
import {getListOrderRequest} from '@shared/redux/pendingOrderHome/actions';

const getlastRouteFNB = callBack => {
  return callBack(store.getState().historyRoute.lastRouteFNB);
};
const getStoreId = callBack => {
  return callBack(store.getState().smartMenu.currentStoreId);
};
const getStateCommonNotInit = callBack => {
  return callBack(store.getState().commonNotInit);
};
const getIsShowMessageErrorCoupon = callBack => {
  return callBack(store.getState().coupon.isShowMessageError);
};
const fetchDataPendingOrder = () => {
  getlastRouteFNB(function (route) {
    if (route === 'FNB') {
      getStoreId(function (storeId) {
        if (
          storeId &&
          typeof storeId !== 'undefined' &&
          storeId !== null &&
          storeId !== ''
        ) {
          store.dispatch(getListOrderRequest(storeId));
        }
      });
    } else {
      store.dispatch(getListOrderRequest());
    }
  });
};

const getAddressBookList = callBack => {
  if (store.getState().utopDeliveryAddressBook.addressBookList) {
    return callBack(store.getState().utopDeliveryAddressBook.addressBookList);
  }
  return callBack([]);
};

export {
  fetchDataPendingOrder,
  getAddressBookList,
  getlastRouteFNB,
  getStoreId,
  getIsShowMessageErrorCoupon,
  getStateCommonNotInit,
};
