import request from "services/request";
// import { encodeQueryData } from "utils/stringFormat";
import { APIUtils } from "utils";
import * as TYPE from "../store/constants";

const api = {
  [TYPE.GET_LIST_CANCEL_REASON.request]: () => {
    return APIUtils.get(
      `${process.env.REACT_APP_DOMAIN_UTOP_API}Order/fnb/cancelreason`
    );
  },
  [TYPE.CANCEL_FNB.request]: async (data) => {
    return request("Order/CancelOrder", "POST", data);
  },
};

export default api;
