/* eslint-disable no-param-reassign */
import { toast } from "react-toastify";
import { addErrors, delErrors } from "utils/ErrorsGenerator";
import MESSAGES from "constants/vi";

import * as TYPE from "./constants";

const MESSAGE_KEY = "Cart"; // key của object chứa message // ! tạm thời dùng với Cart -> cần tách riêng mục StoreView
const UNKNOWN = "unknown";
const toastOptions = {
  position: "top-center",
  autoClose: 7000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

const reducers = {
  // * GET_RATING_LIST
  [TYPE.GET_RATING_LIST.request]: (draft) => {
    return {
      ...draft,
      isLoading: {
        ...draft.isLoading,
        GET_RATING_LIST: true,
      },
    };
  },
  [TYPE.GET_RATING_LIST.success]: (draft, { payload }) => {
    let dataInfiniteAppend = [];
    if (Array.isArray(payload.ratings) && payload?.ratings?.length > 0) {
      dataInfiniteAppend = payload.ratings;
    }
    return {
      ...draft,
      ratingData: draft.ratingData.ratings
        ? {
            ...draft.ratingData,
            ratings: [...draft.ratingData.ratings, ...dataInfiniteAppend],
          }
        : payload,
      errors: delErrors(draft.errors, TYPE.GET_RATING_LIST.failure),
      isLoading: {
        ...draft.isLoading,
        GET_RATING_LIST: false,
      },
    };
  },

  [TYPE.GET_RATING_LIST.failure]: (draft, { payload }) => {
    return {
      ...draft,
      errors: addErrors(draft.errors, {
        type: TYPE.GET_RATING_LIST.failure,
        msg: payload,
      }),
      isLoading: {
        ...draft.isLoading,
        GET_RATING_LIST: false,
      },
    };
  },

  // * CLEAR_RATING_LIST

  [TYPE.GET_RATING_LIST.request]: (draft) => {
    return {
      ...draft,
      ratingData: {},
    };
  },

  // * GET_MERCHANT_INFO
  [TYPE.GET_MERCHANT_INFO.request]: (draft) => {
    return {
      ...draft,
      isLoading: {
        ...draft.isLoading,
        GET_MERCHANT_INFO: true,
      },
    };
  },
  [TYPE.GET_MERCHANT_INFO.success]: (draft, { payload }) => {
    return {
      ...draft,
      merchantInfo: payload,
      errors: delErrors(draft.errors, TYPE.GET_MERCHANT_INFO.failure),
      isLoading: {
        ...draft.isLoading,
        GET_MERCHANT_INFO: false,
      },
    };
  },
  [TYPE.GET_MERCHANT_INFO.failure]: (draft, { payload }) => {
    return {
      ...draft,
      errors: addErrors(draft.errors, {
        type: TYPE.GET_MERCHANT_INFO.failure,
        msg: payload,
      }),
      isLoading: {
        ...draft.isLoading,
        GET_MERCHANT_INFO: false,
      },
    };
  },

  // * GET_STORE_INFO
  [TYPE.GET_STORE_INFO.request]: (draft) => {
    return {
      ...draft,
      isLoading: {
        ...draft.isLoading,
        GET_STORE_INFO: true,
      },
    };
  },
  [TYPE.GET_STORE_INFO.success]: (draft, { payload }) => {
    return {
      ...draft,
      storeInfo: payload,
      errors: delErrors(draft.errors, TYPE.GET_STORE_INFO.failure),
      isLoading: {
        ...draft.isLoading,
        GET_STORE_INFO: false,
      },
    };
  },
  [TYPE.GET_STORE_INFO.failure]: (draft, { payload }) => {
    return {
      ...draft,
      errors: addErrors(draft.errors, {
        type: TYPE.GET_STORE_INFO.failure,
        msg: payload,
      }),
      isLoading: {
        ...draft.isLoading,
        GET_STORE_INFO: false,
      },
    };
  },

  // * GET_STORE_INFO_V2
  [TYPE.GET_STORE_INFO_V2.request]: (draft) => {
    return {
      ...draft,
      isLoading: {
        ...draft.isLoading,
        GET_STORE_INFO_V2: true,
      },
    };
  },
  [TYPE.GET_STORE_INFO_V2.success]: (draft, { payload }) => {
    return {
      ...draft,
      storeInfoV2: payload,
      errors: delErrors(draft.errors, TYPE.GET_STORE_INFO_V2.failure),
      isLoading: {
        ...draft.isLoading,
        GET_STORE_INFO_V2: false,
      },
    };
  },
  [TYPE.GET_STORE_INFO_V2.failure]: (draft, { payload }) => {
    return {
      ...draft,
      errors: addErrors(draft.errors, {
        type: TYPE.GET_STORE_INFO_V2.failure,
        msg: payload,
      }),
      isLoading: {
        ...draft.isLoading,
        GET_STORE_INFO_V2: false,
      },
    };
  },

  // *** SUGGESTED COUPON LIST
  [TYPE.GET_SUGGESTED_COUPON_LIST.request]: (draft) => {
    return {
      ...draft,
      isLoading: {
        ...draft.isLoading,
        GET_SUGGESTED_COUPON_LIST: true,
      },
    };
  },
  [TYPE.GET_SUGGESTED_COUPON_LIST.success]: (draft, { payload }) => {
    return {
      ...draft,
      errors: delErrors(draft.errors, TYPE.GET_SUGGESTED_COUPON_LIST.failure),
      suggestedCouponList: payload.suggestedCouponDetails,
      isLoading: {
        ...draft.isLoading,
        GET_SUGGESTED_COUPON_LIST: false,
      },
    };
  },
  [TYPE.GET_SUGGESTED_COUPON_LIST.failure]: (draft, { payload }) => {
    // draft.errors = addErrors(draft.errors, {
    //   type: TYPE.GET_SUGGESTED_COUPON_LIST.failure,
    //   msg: payload,
    // });

    return {
      ...draft,
      errors: addErrors(draft.errors, {
        type: TYPE.GET_SUGGESTED_COUPON_LIST.failure,
        msg: payload,
      }),
      isLoading: {
        ...draft.isLoading,
        GET_SUGGESTED_COUPON_LIST: false,
      },
    };
  },

  // *** GET_BEST_SELLER_LIST
  [TYPE.GET_BEST_SELLER_LIST.request]: (draft) => {
    draft.errors = delErrors(draft.errors, TYPE.GET_BEST_SELLER_LIST.failure);
    draft.isLoading.GET_BEST_SELLER_LIST = true;
  },
  [TYPE.GET_BEST_SELLER_LIST.success]: (draft, { payload }) => {
    draft.errors = delErrors(draft.errors, TYPE.GET_BEST_SELLER_LIST.failure);
    draft.bestSellerData = payload;
    draft.isLoading.GET_BEST_SELLER_LIST = false;
  },
  [TYPE.GET_BEST_SELLER_LIST.failure]: (draft, { payload }) => {
    draft.errors = addErrors(draft.errors, {
      type: TYPE.GET_BEST_SELLER_LIST.failure,
      msg: payload,
    });
    draft.isLoading.GET_BEST_SELLER_LIST = false;
  },

  // *** SAVE_SUGGESTED_COUPON
  [TYPE.SAVE_SUGGESTED_COUPON.request]: (draft) => {
    return {
      ...draft,
      isLoading: {
        ...draft.isLoading,
        SAVE_SUGGESTED_COUPON: true,
      },
    };
  },
  [TYPE.SAVE_SUGGESTED_COUPON.success]: (draft, { payload }) => {
    // modify suggested coupon list, mark the saved one to isAllocated=true
    const codeOfTheSavedCoupon = payload;
    const newSuggestedCouponList = draft.suggestedCouponList.map((coupon) =>
      coupon.code === codeOfTheSavedCoupon
        ? {
            ...coupon,
            isAllocated: true,
          }
        : coupon
    );

    toast.success("Lấy mã thành công!", {
      toastId: `${TYPE}_SAVE_SUGGESTED_COUPON_SUCCESS`,
      ...toastOptions,
    });

    return {
      ...draft,
      errors: delErrors(draft.errors, TYPE.SAVE_SUGGESTED_COUPON.failure),
      suggestedCouponList: newSuggestedCouponList,
      isLoading: {
        ...draft.isLoading,
        SAVE_SUGGESTED_COUPON: false,
      },
    };
  },
  [TYPE.SAVE_SUGGESTED_COUPON.failure]: (draft, { payload }) => {
    const errMsgToToast =
      MESSAGES?.[MESSAGE_KEY]?.error?.[payload?.message] ||
      MESSAGES?.[MESSAGE_KEY]?.error?.[UNKNOWN];

    toast.error(errMsgToToast, {
      toastId: `${TYPE}_SAVE_SUGGESTED_COUPON_FAILURE`,
      ...toastOptions,
    });

    return {
      ...draft,
      errors: addErrors(draft.errors, {
        type: TYPE.SAVE_SUGGESTED_COUPON.failure,
        msg: payload,
      }),
      isLoading: {
        ...draft.isLoading,
        SAVE_SUGGESTED_COUPON: false,
      },
    };
  },

  // *** GET_SHORT_LINK_TO_SHARE
  [TYPE.GET_SHORT_LINK_TO_SHARE.request]: (draft) => {
    return {
      ...draft,
      isLoading: {
        ...draft.isLoading,
        GET_SHORT_LINK_TO_SHARE: true,
      },
    };
  },
  [TYPE.GET_SHORT_LINK_TO_SHARE.success]: (draft, { payload }) => {
    return {
      ...draft,
      shortLinkToShare: payload.url,
      errors: delErrors(draft.errors, TYPE.GET_SHORT_LINK_TO_SHARE.failure),
      isLoading: {
        ...draft.isLoading,
        GET_SHORT_LINK_TO_SHARE: false,
      },
    };
  },
  [TYPE.GET_SHORT_LINK_TO_SHARE.failure]: (draft, { payload }) => {
    return {
      ...draft,
      errors: addErrors(draft.errors, {
        type: TYPE.GET_SHORT_LINK_TO_SHARE.failure,
        msg: payload,
      }),
      shortLinkToShare: undefined,
      isLoading: {
        ...draft.isLoading,
        GET_SHORT_LINK_TO_SHARE: false,
      },
    };
  },

  // * GET_USER_LIKED_LIST_OF_RATING_POST
  [TYPE.GET_USER_LIKED_LIST_OF_RATING_POST.request]: (draft) => {
    return {
      ...draft,
      isLoading: {
        ...draft.isLoading,
        GET_USER_LIKED_LIST_OF_RATING_POST: true,
      },
    };
  },
  [TYPE.GET_USER_LIKED_LIST_OF_RATING_POST.success]: (draft, { payload }) => {
    let dataToUpdate = [];

    if (
      Array.isArray(payload.list)
      // && payload.list.length > 0 // ! gây bug nếu lần fetch sau, response trả về mảng rỗng
    ) {
      if (payload.needToClearExistedList) {
        dataToUpdate = payload.list;
      } else {
        dataToUpdate = [...draft.userLikedData.list, ...payload.list];
      }
    }

    return {
      ...draft,
      userLikedData: {
        list: dataToUpdate,
        hasMore: payload?.hasMore,
      },
      errors: delErrors(
        draft.errors,
        TYPE.GET_USER_LIKED_LIST_OF_RATING_POST.failure
      ),
      isLoading: {
        ...draft.isLoading,
        GET_USER_LIKED_LIST_OF_RATING_POST: false,
      },
    };
  },
  [TYPE.GET_USER_LIKED_LIST_OF_RATING_POST.failure]: (draft, { payload }) => {
    return {
      ...draft,
      errors: addErrors(draft.errors, {
        type: TYPE.GET_USER_LIKED_LIST_OF_RATING_POST.failure,
        msg: payload,
      }),
      isLoading: {
        ...draft.isLoading,
        GET_USER_LIKED_LIST_OF_RATING_POST: false,
      },
    };
  },

  // * GET_USER_LIKED_LIST_OF_COMMENT
  [TYPE.GET_USER_LIKED_LIST_OF_COMMENT.request]: (draft) => {
    return {
      ...draft,
      isLoading: {
        ...draft.isLoading,
        GET_USER_LIKED_LIST_OF_COMMENT: true,
      },
    };
  },
  [TYPE.GET_USER_LIKED_LIST_OF_COMMENT.success]: (draft, { payload }) => {
    let dataToUpdate = [];

    if (
      Array.isArray(payload.list)
      // && payload.list.length > 0 // ! gây bug nếu lần fetch sau, response trả về mảng rỗng
    ) {
      if (payload.needToClearExistedList) {
        dataToUpdate = payload.list;
      } else {
        dataToUpdate = [...draft.userLikedData.list, ...payload.list];
      }
    }

    return {
      ...draft,
      userLikedData: {
        list: dataToUpdate,
        hasMore: payload?.hasMore,
      },
      errors: delErrors(
        draft.errors,
        TYPE.GET_USER_LIKED_LIST_OF_COMMENT.failure
      ),
      isLoading: {
        ...draft.isLoading,
        GET_USER_LIKED_LIST_OF_COMMENT: false,
      },
    };
  },
  [TYPE.GET_USER_LIKED_LIST_OF_COMMENT.failure]: (draft, { payload }) => {
    return {
      ...draft,
      errors: addErrors(draft.errors, {
        type: TYPE.GET_USER_LIKED_LIST_OF_COMMENT.failure,
        msg: payload,
      }),
      isLoading: {
        ...draft.isLoading,
        GET_USER_LIKED_LIST_OF_COMMENT: false,
      },
    };
  },

  // * POST_UNLIKE_CMT
  [TYPE.POST_UNLIKE_CMT.request]: (draft) => {
    return {
      ...draft,
      isLoading: {
        ...draft.isLoading,
        POST_UNLIKE_CMT: true,
      },
    };
  },
  [TYPE.POST_UNLIKE_CMT.success]: (draft, { payload }) => {
    const postId = payload;
    let updatedRatings = draft.ratingData.ratings;
    if (postId) {
      const oldPost = draft.ratingData?.ratings.find(
        (post) => post.id === postId
      );
      if (oldPost) {
        const newPost = {
          ...oldPost,
          isLike: false,
          like: (oldPost?.like || 0) - 1 >= 0 ? (oldPost?.like || 0) - 1 : 0,
        };
        updatedRatings = draft.ratingData?.ratings.map((item) =>
          item.id === postId ? newPost : item
        );
      }
    }
    return {
      ...draft,
      ratingData: { ...draft.ratingData, ratings: updatedRatings },
      errors: delErrors(draft.errors, TYPE.POST_UNLIKE_CMT.failure),
      isLoading: {
        ...draft.isLoading,
        POST_UNLIKE_CMT: false,
      },
    };
  },
  [TYPE.POST_UNLIKE_CMT.failure]: (draft, { payload }) => {
    return {
      ...draft,
      errors: addErrors(draft.errors, {
        type: TYPE.POST_UNLIKE_CMT.failure,
        msg: payload,
      }),
      isLoading: {
        ...draft.isLoading,
        POST_UNLIKE_CMT: false,
      },
    };
  },
  // * POST_LIKE_CMT
  [TYPE.POST_LIKE_CMT.request]: (draft) => {
    return {
      ...draft,
      isLoading: {
        ...draft.isLoading,
        POST_LIKE_CMT: true,
      },
    };
  },
  [TYPE.POST_LIKE_CMT.success]: (draft, { payload }) => {
    const postId = payload;
    let updatedRatings = draft.ratingData.ratings;
    if (postId) {
      const oldPost = draft.ratingData?.ratings.find(
        (post) => post.id === postId
      );
      if (oldPost) {
        const newPost = {
          ...oldPost,
          isLike: true,
          like: (oldPost?.like || 0) + 1,
        };
        updatedRatings = draft.ratingData?.ratings.map((item) =>
          item.id === postId ? newPost : item
        );
      }
    }
    return {
      ...draft,
      ratingData: { ...draft.ratingData, ratings: updatedRatings },
      errors: delErrors(draft.errors, TYPE.POST_LIKE_CMT.failure),
      isLoading: {
        ...draft.isLoading,
        POST_LIKE_CMT: false,
      },
    };
  },
  [TYPE.POST_LIKE_CMT.failure]: (draft, { payload }) => {
    return {
      ...draft,
      errors: addErrors(draft.errors, {
        type: TYPE.POST_LIKE_CMT.failure,
        msg: payload,
      }),
      isLoading: {
        ...draft.isLoading,
        POST_LIKE_CMT: false,
      },
    };
  },
  // * POST_UNLIKE_SUB_CMT
  [TYPE.POST_UNLIKE_SUB_CMT.request]: (draft) => {
    return {
      ...draft,
      isLoading: {
        ...draft.isLoading,
        POST_UNLIKE_CMT: true,
      },
    };
  },
  [TYPE.POST_UNLIKE_SUB_CMT.success]: (draft, { payload }) => {
    const { replyId: postReplyId, postId: parentCmtId } = payload;
    // {ratings[{post [ratingReplies [{isLike, like}]}]}
    const foundRatingPost = draft.ratingData?.ratings?.find(
      (ratingPost) => ratingPost.id === parentCmtId
    ); // replies - post

    const ratingReply = foundRatingPost.ratingReplies.find(
      (itemId) => itemId.id === postReplyId
    ); // reply

    const newReply = {
      ...ratingReply,
      isLike: false,
      like:
        (ratingReply?.like || 0) - 1 >= 0 ? (ratingReply?.like || 0) - 1 : 0,
    }; // reply -> isLike, like
    const updateRatingReply = foundRatingPost.ratingReplies.map((reply) =>
      reply.id === postReplyId ? newReply : reply
    );
    // connect to the reply post found
    const updatedRatingPost = {
      ...foundRatingPost,
      ratingReplies: updateRatingReply,
    };
    const updatedRatingPostsList = draft.ratingData.ratings.map((ratingPost) =>
      ratingPost.id === parentCmtId ? updatedRatingPost : ratingPost
    );

    return {
      ...draft,
      ratingData: {
        ...draft.ratingData,
        ratings: updatedRatingPostsList,
      },
      errors: delErrors(draft.errors, TYPE.POST_UNLIKE_CMT.failure),
      isLoading: {
        ...draft.isLoading,
        POST_UNLIKE_SUB_CMT: false,
      },
    };
  },
  [TYPE.POST_UNLIKE_SUB_CMT.failure]: (draft, { payload }) => {
    return {
      ...draft,
      errors: addErrors(draft.errors, {
        type: TYPE.POST_UNLIKE_CMT.failure,
        msg: payload,
      }),
      isLoading: {
        ...draft.isLoading,
        POST_UNLIKE_CMT: false,
      },
    };
  },
  // * POST_LIKE_SUB_CMT
  [TYPE.POST_LIKE_SUB_CMT.request]: (draft) => {
    return {
      ...draft,
      isLoading: {
        ...draft.isLoading,
        POST_LIKE_SUB_CMT: true,
      },
    };
  },
  [TYPE.POST_LIKE_SUB_CMT.success]: (draft, { payload }) => {
    const { replyId: postReplyId, postId: parentCmtId } = payload;
    // {ratings[{post [ratingReplies [{isLike, like}]}]}
    const foundRatingPost = draft.ratingData?.ratings?.find(
      (ratingPost) => ratingPost.id === parentCmtId
    ); // replies - post

    const ratingReply = foundRatingPost.ratingReplies.find(
      (itemId) => itemId.id === postReplyId
    ); // reply

    const newReply = {
      ...ratingReply,
      isLike: true,
      like: (ratingReply?.like || 0) + 1,
    }; // reply -> isLike, like

    const updateRatingReply = foundRatingPost.ratingReplies.map((reply) =>
      reply.id === postReplyId ? newReply : reply
    );
    // connect to the reply post found
    const updatedRatingPost = {
      ...foundRatingPost,
      ratingReplies: updateRatingReply,
    };
    const updatedRatingPostsList = draft.ratingData.ratings.map((ratingPost) =>
      ratingPost.id === parentCmtId ? updatedRatingPost : ratingPost
    );
    return {
      ...draft,
      ratingData: {
        ...draft.ratingData,
        ratings: updatedRatingPostsList,
      },
      errors: delErrors(draft.errors, TYPE.POST_UNLIKE_CMT.failure),
      isLoading: {
        ...draft.isLoading,
        POST_UNLIKE_SUB_CMT: false,
      },
    };
  },
  [TYPE.POST_LIKE_SUB_CMT.failure]: (draft, { payload }) => {
    return {
      ...draft,
      errors: addErrors(draft.errors, {
        type: TYPE.POST_LIKE_SUB_CMT.failure,
        msg: payload,
      }),
      isLoading: {
        ...draft.isLoading,
        POST_LIKE_SUB_CMT: false,
      },
    };
  },

  // *** POST_REPLY_CMT
  [TYPE.POST_REPLY_CMT.request]: (draft) => {
    return {
      ...draft,
      isLoading: {
        ...draft.isLoading,
        POST_REPLY_CMT: true,
      },
    };
  },
  [TYPE.POST_REPLY_CMT.success]: (draft, { payload }) => {
    const postReplyId = payload.sourceId;

    const foundRatingPost = draft.ratingData?.ratings?.find(
      (ratingPost) => ratingPost.id === postReplyId
    );
    const newReply = [...foundRatingPost.ratingReplies, payload];
    const replyNewPost = {
      ...foundRatingPost,
      ratingReplies: newReply,
    };
    const replyNewPostsList = draft.ratingData.ratings.map((ratingPost) =>
      ratingPost.id === postReplyId ? replyNewPost : ratingPost
    );
    return {
      ...draft,
      replyData: payload, // ? dư?
      ratingData: {
        ...draft.ratingData,
        ratings: replyNewPostsList,
      },
      errors: delErrors(draft.errors, TYPE.POST_REPLY_CMT.failure),
      isLoading: {
        ...draft.isLoading,
        POST_REPLY_CMT: false,
      },
    };
  },
  [TYPE.POST_REPLY_CMT.failure]: (draft, { payload }) => {
    if (payload?.error?.code === "ContainWordInBlackList") {
      const { blackListWords } = payload;
      const listWordToShow = blackListWords.join(", ");

      if (blackListWords) {
        toast.error(`Bình luận không hợp lệ: ${listWordToShow}`, {
          ...toastOptions,
          toastId: `Bình luận không hợp lệ: ${listWordToShow}`,
        });
      }
    }

    return {
      ...draft,
      errors: addErrors(draft.errors, {
        type: TYPE.POST_REPLY_CMT.failure,
        msg: payload,
      }),
      isLoading: {
        ...draft.isLoading,
        POST_REPLY_CMT: false,
      },
    };
  },

  // *** GET_NOT_ALREADY_RATED_LIST
  [TYPE.GET_NOT_ALREADY_RATED_LIST.request]: (draft) => {
    return {
      ...draft,
      isLoading: {
        ...draft.isLoading,
        GET_NOT_ALREADY_RATED_LIST: true,
      },
    };
  },
  [TYPE.GET_NOT_ALREADY_RATED_LIST.success]: (draft, { payload }) => {
    return {
      ...draft,
      notAlreadyRatedList: payload,
      errors: delErrors(draft.errors, TYPE.GET_NOT_ALREADY_RATED_LIST.failure),
      isLoading: {
        ...draft.isLoading,
        GET_NOT_ALREADY_RATED_LIST: false,
      },
    };
  },
  [TYPE.GET_NOT_ALREADY_RATED_LIST.failure]: (draft, { payload }) => {
    return {
      ...draft,
      errors: addErrors(draft.errors, {
        type: TYPE.GET_NOT_ALREADY_RATED_LIST.failure,
        msg: payload,
      }),
      isLoading: {
        ...draft.isLoading,
        POST_REPLY_CMT: false,
      },
    };
  },

  // *** GET_LIST_DEAL
  [TYPE.GET_LIST_DEAL.request]: (draft) => {
    return {
      ...draft,
      isLoading: {
        ...draft.isLoading,
        GET_LIST_DEAL: true,
      },
    };
  },
  [TYPE.GET_LIST_DEAL.success]: (draft, { payload }) => {
    return {
      ...draft,
      listDeal: payload,
      skip: payload.skip,
      take: payload.take,
      errors: delErrors(draft.errors, TYPE.GET_LIST_DEAL.failure),
      isLoading: {
        ...draft.isLoading,
        GET_LIST_DEAL: false,
      },
    };
  },
  [TYPE.GET_LIST_DEAL.failure]: (draft, { payload }) => {
    return {
      ...draft,
      errors: addErrors(draft.errors, {
        type: TYPE.GET_LIST_DEAL.failure,
        msg: payload,
      }),
      isLoading: {
        ...draft.isLoading,
        GET_LIST_DEAL: false,
      },
    };
  },
};

export default reducers;
