import { call, put, takeLatest, fork } from 'redux-saga/effects'
import { LIST_FAVORITE_HASHTAG_REQUEST } from './types'
import { END_POINTS } from '@shared/constants/apiUrl'
import {
  listFavoriteHashtagSuccess,
  listFavoriteHashtagFailure,
} from './actions'
import { APIUtils } from 'utils'

function listFavoriteHashtagApi({ skipCount, maxResult }) {
  const requestData = `?SkipCount=${skipCount}&MaxResult=${maxResult}`
  return APIUtils.get(END_POINTS.GetFavoriteHashtag + requestData)
}

export function* listFavoriteHashtag(obj) {
  const { onSuccess } = obj.payload
  try {
    const data = yield call(listFavoriteHashtagApi, obj.payload)

    if (Array.isArray(data)) {
      yield put(listFavoriteHashtagSuccess([...obj.payload.list, ...data]))
      if (typeof onSuccess === 'function') {
        onSuccess(data)
      }
    } else {
      yield put(listFavoriteHashtagSuccess([]))
      yield put(listFavoriteHashtagFailure(data))
    }
  } catch (err) {
    yield put(listFavoriteHashtagSuccess([]))
    yield put(listFavoriteHashtagFailure(err))
  }
}

function* watchListFavoriteHashtag() {
  yield takeLatest(LIST_FAVORITE_HASHTAG_REQUEST, listFavoriteHashtag)
}

export default function* rootChild() {
  yield fork(watchListFavoriteHashtag)
}
