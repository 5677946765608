import produce from 'immer';
import {
  CHANNEL_LIST_SUCCESS,
  CHANNEL_LIST_FAILED,
  CHANNEL_DETAIL_SUCCESS,
  CHANNEL_DETAIL_FAILED,
} from './types';

const initialState = {
  errors: null,
  listChannel: [],
  channelDetail: {},
};

const channelReducer = produce((draft = initialState, action) => {
  const {payload} = action;

  switch (action.type) {
    case CHANNEL_LIST_SUCCESS:
      return {
        ...draft,
        errors: null,
        listChannel: payload,
      };
    case CHANNEL_LIST_FAILED:
      return {
        ...draft,
        errors: payload,
      };

    case CHANNEL_DETAIL_SUCCESS:
      return {
        ...draft,
        errors: null,
        channelDetail: payload,
      };
    case CHANNEL_DETAIL_FAILED:
      return {
        ...draft,
        errors: payload,
      };
    default:
      return draft;
  }
});

export default channelReducer;
