import types from './types';

export const getProductMarketBySearchRequest = payload => ({
  type: types.SEARCH_MARKET_REQUEST,
  payload,
});

export const getProductMarketBySearchSuccess = listProductSearchMarket => ({
  type: types.SEARCH_MARKET_SUCCESS,
  listProductSearchMarket,
});

export const getProductMarketBySearchFailed = error => ({
  type: types.SEARCH_MARKET_FAILED,
  error,
});

export const getProductDetailMarketRequest = payload => ({
  type: types.GET_PRODUCT_DETAIL_MARKET_REQUEST,
  payload,
});

export const getProductDetailMarketSuccess = productDetailMarket => ({
  type: types.GET_PRODUCT_DETAIL_MARKET_SUCCESS,
  productDetailMarket,
});

export const getProductDetailMarketFailed = error => ({
  type: types.GET_PRODUCT_DETAIL_MARKET_FAILED,
  error,
});

export const clearSearchMarket = () => ({
  type: types.CLEAR_SEARCH_MARKET,
});

export const clearSearchHistoryMarket = () => ({
  type: types.CLEAR_SEARCH_HISTORY_MARKET,
});

export const clearProductDetailMarket = () => ({
  type: types.CLEAR_PRODUCT_DETAIL_MARKET,
});

export const setIsEndSearchMarket = payload => ({
  type: types.SET_IS_END_SEARCH_MARKET,
  payload
});
