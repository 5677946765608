const AppConfigs = {
  domainAFFAPI: process.env.REACT_APP_DOMAIN_UTOP_API,
  domainUtopAppAPI: process.env.REACT_APP_DOMAIN_UTOP_APP_API,
  apiVersion: process.env.REACT_APP_API_VERSION,
  subscriptionKey: process.env.REACT_APP_OCP_APIM_SUBSCRIPTION_KEY,
  domainCommentVideo: process.env.REACT_APP_DOMAIN_COMMENT_VIDEO,
  versionRating: process.env.REACT_APP_API_VERSION_V2,
  idFeatureCommentOrder: process.env.REACT_APP_ID_FEATURE_COMMENT_ORDER,
  versionCommentVideo: process.env.REACT_APP_VERSION_COMMENT_VIDEO,
  keyGoogleMap: process.env.REACT_APP_GOOGLE_API_KEY,
};

export default AppConfigs;
