import { put, takeLatest, fork, call, select } from "redux-saga/effects";
import { GET_LIST_PENDING_ORDER_REQUEST } from "./constants";
import { getListOrderFailed, getListOrderSuccess } from "./actions";
import { getListOrderApi } from "@shared/services/api/pendingOrderHome";
import { orderListParser } from "./parser";
import { callApi } from "helpers/callApi";

export function* getListOrderSaga(obj) {
  const { payload, onSuccess } = obj;
  try {
    const res = yield callApi(getListOrderApi, payload);
    yield put(
      getListOrderSuccess({
        data: orderListParser(res),
      })
    );
    if (typeof onSuccess === "function") {
      onSuccess(res);
    }
  } catch (err) {
    yield put(getListOrderFailed(err));
  }
}

function* watchProduct() {
  yield takeLatest(GET_LIST_PENDING_ORDER_REQUEST, getListOrderSaga);
}

export default function* rootChild() {
  yield fork(watchProduct);
}
