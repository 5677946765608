/* eslint-disable no-unused-expressions */
/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from "react";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";

import axios from "axios";
import classNames from "classnames";

import { CCollapse } from "@coreui/react";

import SVG from "assets/svg";
import { imagesIcon } from "img";

import { MAP_MODES } from "constants/types";
import { GOOGLEAPIS_MAP } from "constants/App";
import { isString } from "utils/stringFormat";

import DebounceInput from "views/common/component/DebounceInput";
import AddressOption from "./components/AddressOption";
import MapAddress from "../MapAddress";
import AddressItem from "../../../DeliveryAddress/components/AddressItem";
import CheckBoxButton from "../../../DeliveryAddress/components/CheckBoxButton";

import "./deliveryInfo.scss";

const TYPES = {
  ENTER_ADDRESS: "enter address",
  CHOOSE_VALID_ADDRESS: "choose address",
};

const getBriefAddress = (fullAddress) => {
  const splitted = fullAddress?.split(",");

  return splitted?.[0];
};

export default function DeliveryInfo(props) {
  const {
    receiverAddress,
    receiverNote,
    receiverPosition, // lat, lng
    handleCommitAddressNote,
    handleNavigateToSearchAddressPage,
    messageError,
    isFreeText,
    pickupAddress,
    // handleSelectPickupAddress,
  } = props;
  const [chosenAddressCode, setChosenAddressCode] = useState();
  const [deliveryPosition, setDeliveryPosition] = useState();
  const [isResultSearch, setResultSearch] = useState(false);

  const {
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
    placesService,
  } = usePlacesService({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    debounce: 300,
    options: {
      componentRestrictions: { country: "vn" },
    },
  });

  useEffect(() => {
    if (receiverPosition) {
      setDeliveryPosition(receiverPosition);
    }
  }, [receiverPosition]);

  // * từ receiverAddress, tìm lat long để show map
  useEffect(() => {
    if (!receiverPosition) {
      if (receiverAddress && isString(receiverAddress)) {
        axios
          .get(
            `${GOOGLEAPIS_MAP.domain}?address=${receiverAddress}&key=${GOOGLEAPIS_MAP.key}`
          )
          .then((response) => {
            if (
              response &&
              response.data &&
              response.data.results[0] &&
              response.data.results[0].geometry
            ) {
              setDeliveryPosition(response.data.results[0].geometry.location);
            }
          });
      }
    }
  }, [receiverPosition, receiverAddress]);

  const handleSelect = (item) => (e) => {
    e.preventDefault();
    setChosenAddressCode(item.code);
    props.setChosenAddressCode(item.code);

    props.setListDeliveryAddress(
      props.pickupAddress.map((ite) => ({
        ...ite,
        isChecked: item.code === ite.code,
      }))
    );
    props.defaultAddressUser.current = item.address;
    item.note !== null && item.note !== ""
      ? props.setValueAddressNote(item.note)
      : null;
    props.handleCalculateShipping(item.address);
    props.convertAddressToLatLong(item.address);
    // props.setListShipping(null);

    // const chosenAddress = pickupAddress.find(
    //   (pickupAddressData) => pickupAddressData.code === item.code
    // );
    // handleSelectPickupAddress(chosenAddress.address);
    // handleCommitAddressNote(chosenAddress?.note || "");
  };

  const handleToggle = (method) => (e) => {
    e.preventDefault();

    if (method === TYPES.ENTER_ADDRESS) {
      props.setValueAddressNote("");
      props.setSelectedFreeText(true);
      props.defaultAddressUser.current = props.valueAddressUser;
      if (!props.selectedFreeText) {
        props.handleCalculateShipping(props.valueAddressUser);
        props.convertAddressToLatLong(props.valueAddressUser);
        props.setListDeliveryAddress(
          props.listDeliveryAddress.map((ite) => ({
            ...ite,
            isChecked: false,
          }))
        );
      }
      setChosenAddressCode(null);
    }

    if (method === TYPES.CHOOSE_VALID_ADDRESS) {
      props.isDelete.current = false;
      props.setSelectedFreeText(false);
      if (props.selectedFreeText) {
        props.setListShipping(null);
      }
      props.setValueAddressNote("");
    }
  };

  const handleCommitSearchAddressText = (typedText) => {
    getPlacePredictions({ input: typedText });
  };

  const handleOnFocus = () => {
    setResultSearch(true);
  };

  const handleClickSearchResult = (address) => {
    props.defaultAddressUser.current = address;
    props.setValueAddressUser(address);
    props.handleCalculateShipping(address);
    props.convertAddressToLatLong(address);
    props.isSelectedAddress.current = true;
    setResultSearch(false);
  };

  return (
    <div className="delivery-info__wrapper">
      <div className="delivery-info__heading">Giao hàng</div>
      {messageError !== "" && (
        <div style={{ color: "red", marginBottom: "15px" }}>{messageError}</div>
      )}
      <div className="delivery-info-methods__wrapper">
        {/* enter your address */}
        {isFreeText && (
          <div className="delivery-info-method">
            <div
              className="delivery-info-method__collapse-button"
              onClick={handleToggle(TYPES.ENTER_ADDRESS)}
            >
              <img
                src={
                  props.selectedFreeText
                    ? SVG.SelectIndicatorActive
                    : SVG.SelectIndicator
                }
                alt=""
              />
              <div className="delivery-info-method__heading">
                Nhập địa chỉ của bạn
              </div>
            </div>

            <CCollapse show={props.selectedFreeText}>
              <div className="delivery-info-method__content-wrapper ">
                <div className="delivery-info-method--enter-your-address">
                  <div className="delivery-info-method--enter-your-address__title">
                    Địa chỉ của bạn
                    <span className="hight-light-marker">&#42;</span>
                  </div>

                  <div>
                    <div className="delivery-info-method--enter-your-address__address-input-box">
                      <DebounceInput
                        id="delivery-address-page__input"
                        placeholder="Nhập địa chỉ"
                        onCommitChange={handleCommitSearchAddressText}
                        defaultValue={receiverAddress}
                        handleOnFocus={handleOnFocus}
                        // handleOnBlur={handleOnBlur}
                        showClearButton
                      />
                      <div onClick={handleNavigateToSearchAddressPage}>
                        <img
                          className="contacts-book"
                          src={imagesIcon.addressIcon}
                          alt="contacts book"
                        />
                      </div>
                    </div>
                    {placePredictions.length > 0 &&
                      !isPlacePredictionsLoading &&
                      isResultSearch && (
                        <div className="search-results__list">
                          {placePredictions?.map((place, index) => (
                            <div
                              className={classNames(
                                index + 1 <= placePredictions.length &&
                                  "address-item-search"
                              )}
                            >
                              <AddressItem
                                key={place.place_id}
                                briefAddress={getBriefAddress(
                                  place.description
                                )}
                                address={place.description}
                                isSearchResult
                                placeId={place.place_id}
                                placesService={placesService}
                                handleClickSearchResult={
                                  handleClickSearchResult
                                }
                                // currentPosition={currentPosition}
                              />
                            </div>
                          ))}
                        </div>
                      )}
                  </div>

                  <div
                    className="default-address"
                    onClick={() => {
                      props?.setCheckedDefaultAddress((old) => !old);
                    }}
                  >
                    <CheckBoxButton
                      isChecked={props?.isCheckedDefaultAddress}
                      // handleClick={handleCheck}
                      style={{ marginRight: 14 }}
                    />
                    <div style={{ color: "black" }}>
                      Đặt làm địa chỉ mặc định
                    </div>
                  </div>

                  <MapAddress
                    mode={MAP_MODES.VIEW_SELECTED_DELIVERY_ADDRESS}
                    deliveryLocation={props.pickupLocation}
                    deliveryAddress={props.pickupAddress}
                    initCenter={deliveryPosition}
                    mapContainerStyle={{
                      height: 173,
                      width: "100%",
                    }}
                    storeAddress={props?.storeAddress}
                  />

                  <div className="delivery-info-method--enter-your-address__address-notes">
                    <span>Ghi chú địa chỉ</span>

                    <div className="delivery-info-method--enter-your-address__address-input-box">
                      <DebounceInput
                        defaultValue={receiverNote}
                        placeholder="Số Block, số tầng, ..."
                        onCommitChange={handleCommitAddressNote}
                        delayTime={50}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </CCollapse>
          </div>
        )}

        {/* choose one from valid addresses */}
        {props.isPickup && (
          <div className="delivery-info-method">
            <div
              className="delivery-info-method__collapse-button"
              onClick={handleToggle(TYPES.CHOOSE_VALID_ADDRESS)}
            >
              <img
                src={
                  !props.selectedFreeText
                    ? SVG.SelectIndicatorActive
                    : SVG.SelectIndicator
                }
                alt=""
              />
              <div className="delivery-info-method__heading">
                Chọn địa chỉ giao hàng khả dụng
              </div>
            </div>
            {pickupAddress?.length > 0 && (
              <CCollapse show={!props.selectedFreeText}>
                <div className="delivery-info-method__content-wrapper address-options__wrapper">
                  {pickupAddress?.map((item) => (
                    <AddressOption
                      key={item.code}
                      code={item.code}
                      name={item?.name}
                      address={item?.address}
                      note={item?.note}
                      isChosen={chosenAddressCode === item.code}
                      handleSelect={handleSelect}
                      item={item}
                    />
                  ))}
                </div>
              </CCollapse>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
