import {
  GET_VOUCHER_DETAIL_REQUEST,
  GET_VOUCHER_DETAIL_SUCCESS,
  GET_VOUCHER_DETAIL_FAILED,
  CHECK_STOCK_VOUCHER_REQUEST,
  CHECK_STOCK_VOUCHER_SUCCESS,
  CHECK_STOCK_VOUCHER_FAILED,
  ADD_VOUCHER_CART,
  UPDATE_VOUCHER_CART,
  CHECK_DISCOUNT_COUPON_REQUEST,
  CHECK_DISCOUNT_COUPON_SUCCESS,
  CHECK_DISCOUNT_COUPON_FAILED,
  CREATE_ORDER_REQUEST,
  CREATE_ORDER_SUCCESS,
  CREATE_ORDER_FAILED,
  REFUND_VOUCHER_REQUEST,
  REFUND_VOUCHER_SUCCESS,
  REFUND_VOUCHER_FAILED,
} from './constants';

export const getVoucherDetailHandle = (voucherId, onSuccess) => ({
  type: GET_VOUCHER_DETAIL_REQUEST,
  voucherId,
  onSuccess,
});

export const getVoucherDetailSuccess = (payload = {}) => ({
  type: GET_VOUCHER_DETAIL_SUCCESS,
  payload,
});

export const getVoucherDetailFailed = (payload = {}) => ({
  type: GET_VOUCHER_DETAIL_FAILED,
  payload,
});

export const checkStockVoucherHandle = (productCode, onSuccess, onError) => ({
  type: CHECK_STOCK_VOUCHER_REQUEST,
  productCode,
  onSuccess,
  onError,
});

export const checkStockVoucherSuccess = (payload = {}) => ({
  type: CHECK_STOCK_VOUCHER_SUCCESS,
  payload,
});

export const checkStockVoucherFailed = (payload = {}) => ({
  type: CHECK_STOCK_VOUCHER_FAILED,
  payload,
});

export const addVoucherCart = (payload = {}) => ({
  type: ADD_VOUCHER_CART,
  payload,
});

export const updateVoucherCart = (payload = {}) => ({
  type: UPDATE_VOUCHER_CART,
  payload,
});

export const checkDiscountCouponForVoucherHandle = (
  payload = {},
  onSuccess,
  onError,
) => ({
  type: CHECK_DISCOUNT_COUPON_REQUEST,
  payload,
  onSuccess,
  onError,
});

export const checkDiscountCouponForVoucherSuccess = (payload = {}) => ({
  type: CHECK_DISCOUNT_COUPON_SUCCESS,
  payload,
});

export const checkDiscountCouponForVoucherFailed = (payload = {}) => ({
  type: CHECK_DISCOUNT_COUPON_FAILED,
  payload,
});

export const createOrderHubHandle = (payload = {}, onSuccess, onError) => ({
  type: CREATE_ORDER_REQUEST,
  payload,
  onSuccess,
  onError,
});

export const createOrderHubSuccess = (payload = {}) => ({
  type: CREATE_ORDER_SUCCESS,
  payload,
});

export const createOrderHubFailed = (payload = {}) => ({
  type: CREATE_ORDER_FAILED,
  payload,
});

export const refundVoucherHandle = (payload = {}, onSuccess, onError) => ({
  type: REFUND_VOUCHER_REQUEST,
  payload,
  onSuccess,
  onError,
});

export const refundVoucherSuccess = (payload = {}) => ({
  type: REFUND_VOUCHER_SUCCESS,
  payload,
});

export const refundVoucherFailed = (payload = {}) => ({
  type: REFUND_VOUCHER_FAILED,
  payload,
});
