import {strings} from '@i18n';
import {PaginationData} from '@shared/models/paginationData';
import PaginationInfo from '@shared/models/paginationInfo';

export function parseProcessing(obj) {
  const {currentProcessCount = 0, totatReferralCount = 0} = obj || {};
  return {currentProcessCount, totalReferralCount: totatReferralCount};
}

export function parseListReward(data) {
  return data?.map(e => {
    const {Id, Name, ImageUrl, Type, RewardItemType} = e;
    const title = (function () {
      switch (Type) {
        case 1:
          return strings('Referral.refererReward');

        case 2:
          return strings('Referral.presenteeReward');
        default:
          return strings('Referral.firstReferralsReward');
      }
    })();

    return {
      id: Id,
      title: title,
      name: Name,
      image: ImageUrl,
      type: Type,
      rewardItemType: RewardItemType, // 1 Coupon, 2 Voucher, 3 Utop
    };
  });
}

export function parseListReferred(data, {page}) {
  const newData = data?.map(e => {
    const {userName, userAvatarUrl, phoneNumber, userId, status} = e;
    return {
      id: userId,
      name: userName || 'N/A',
      avatar: userAvatarUrl,
      phoneNumber: phoneNumber,
      isUsed: !!status,
    };
  });
  const totalPage = newData.length > 0 ? page + 1 : page;

  return new PaginationData({
    data: newData,
    pagination: new PaginationInfo({currentPage: page, totalPage: totalPage}),
  });
}
