import { addErrors, delErrors } from "utils/ErrorsGenerator";
import * as TYPE from "./constants";

const reducers = {
  // * POST_SEND_VOUCHER
  [TYPE.POST_SEND_VOUCHER.request]: (draft) => {
    return {
      ...draft,
      isLoading: {
        ...draft.isLoading,
        POST_SEND_VOUCHER: true,
      },
    };
  },
  [TYPE.POST_SEND_VOUCHER.success]: (draft) => {
    return {
      ...draft,
      sentVoucher: "success",
      errors: delErrors(draft.errors, TYPE.POST_SEND_VOUCHER.failure),
      isLoading: {
        ...draft.isLoading,
        POST_SEND_VOUCHER: false,
      },
    };
  },
  [TYPE.POST_SEND_VOUCHER.failure]: (draft, { payload }) => {
    return {
      ...draft,
      errors: addErrors(draft.errors, {
        type: TYPE.POST_SEND_VOUCHER.failure,
      }),
      isLoading: {
        ...draft.isLoading,
        POST_SEND_VOUCHER: false,
      },
      sentVoucher: payload?.message || undefined,
    };
  },

  // * GET_USER_BY_PHONE
  [TYPE.GET_USER_BY_PHONE.request]: (draft) => {
    return {
      ...draft,
      isLoading: {
        ...draft.isLoading,
        GET_USER_BY_PHONE: true,
      },
      errorMessages: {
        ...draft.errorMessages,
        GET_USER_BY_PHONE: "",
      },
    };
  },
  [TYPE.GET_USER_BY_PHONE.success]: (draft, { payload }) => {
    return {
      ...draft,
      errors: delErrors(draft.errors, TYPE.GET_USER_BY_PHONE.failure),
      isLoading: {
        ...draft.isLoading,
        GET_USER_BY_PHONE: false,
      },
      foundReceiver: payload,
      errorMessages: {
        ...draft.errorMessages,
        GET_USER_BY_PHONE: "",
      },
    };
  },
  [TYPE.GET_USER_BY_PHONE.failure]: (draft, { payload }) => {
    return {
      ...draft,
      errors: addErrors(draft.errors, {
        type: TYPE.GET_USER_BY_PHONE.failure,
      }),
      isLoading: {
        ...draft.isLoading,
        GET_USER_BY_PHONE: false,
      },
      foundReceiver: null,
      errorMessages: {
        ...draft.errorMessages,
        GET_USER_BY_PHONE: payload?.message,
      },
    };
  },
  // * GET_SHORT_LINK_TO_SHARE
  [TYPE.GET_SHORT_LINK_TO_SHARE.request]: (draft) => {
    return {
      ...draft,
      isLoading: {
        ...draft.isLoading,
        GET_SHORT_LINK_TO_SHARE: true,
      },
    };
  },
  [TYPE.GET_SHORT_LINK_TO_SHARE.success]: (draft, { payload }) => {
    return {
      ...draft,
      shortLinkToShare: payload.url, // ***
      errors: delErrors(draft.errors, TYPE.GET_SHORT_LINK_TO_SHARE.failure),
      isLoading: {
        ...draft.isLoading,
        GET_SHORT_LINK_TO_SHARE: false,
      },
    };
  },
  [TYPE.GET_SHORT_LINK_TO_SHARE.failure]: (draft, { payload }) => {
    return {
      ...draft,
      shortLinkToShare: undefined, // ***
      errors: addErrors(draft.errors, {
        type: TYPE.GET_SHORT_LINK_TO_SHARE.failure,
        msg: payload,
      }),
      isLoading: {
        ...draft.isLoading,
        GET_SHORT_LINK_TO_SHARE: false,
      },
    };
  },
  [TYPE.RESET_SEND_VOUCHER]: (draft) => {
    return {
      ...draft,
      sentVoucher: undefined,
      foundReceiver: undefined,
      shortLinkToShare: "",
      isLoading: {
        GET_USER_BY_PHONE: false,
        POST_SEND_VOUCHER: false,
      },
      errorMessages: {
        GET_USER_BY_PHONE: "",
      },
    };
  },
};

export default reducers;
