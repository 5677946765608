import { call, put, takeLatest, fork } from "redux-saga/effects";
import actions from "./actions";
import * as TYPE from "./constants";
import api from "../api/api";

const sagas = {
  *[TYPE.GET_LIST_CANCEL_REASON.request](action) {
    try {
      const data = yield call(
        api[TYPE.GET_LIST_CANCEL_REASON.request],
        action.payload
      );
      if (data.status === 200) {
        yield put(actions.GET_LIST_CANCEL_REASON.success(data.data));
      } else {
        yield put(actions.GET_LIST_CANCEL_REASON.failure(data?.data));
      }
    } catch (error) {
      yield put(actions.GET_LIST_CANCEL_REASON.failure(error?.data));
    }
  },
  *[TYPE.CANCEL_FNB.request](action) {
    const { orderCode, reasons, onSuccess } = action.payload;

    try {
      const data = yield call(api[TYPE.CANCEL_FNB.request], {
        orderCode,
        reasons,
      });
      if (data.status === 200) {
        yield put(actions.CANCEL_FNB.success(data.data));
        onSuccess?.();
      } else {
        yield put(actions.CANCEL_FNB.failure(data?.data));
      }
    } catch (error) {
      yield put(actions.CANCEL_FNB.failure(error?.data));
    }
  },
};

function* watchedSaga() {
  yield takeLatest(
    TYPE.GET_LIST_CANCEL_REASON.request,
    sagas[TYPE.GET_LIST_CANCEL_REASON.request]
  );
  yield takeLatest(TYPE.CANCEL_FNB.request, sagas[TYPE.CANCEL_FNB.request]);
}

export default function* rootChild() {
  yield fork(watchedSaga);
}
