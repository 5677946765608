import SVG from "assets/svg";
import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const DebounceInput = (props) => {
  const {
    id,
    type = "text",
    placeholder = "",
    defaultValue,
    onCommitChange,
    delayTime = 500,
    showClearButton = false,
    enableClearButton = true,
    handleOnFocus = () => {},
    handleOnBlur = () => {},
    maxLength = 100,
    className,
    style,
    inputStyle,
    focusInput = false,
    ...rest
  } = props;
  const [termValue, setTermValue] = useState("");
  const typingTimeoutRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    if (defaultValue === null) {
      setTermValue("");
    } else {
      setTermValue(defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    if (focusInput) {
      inputRef?.current?.focus();
    }
  }, [focusInput]);

  const handleChangeTermValue = (e) => {
    const typedValue = e.target.value;

    setTermValue(e.target.value);

    if (!onCommitChange) {
      return;
    }

    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }

    typingTimeoutRef.current = setTimeout(() => {
      onCommitChange(typedValue);
    }, delayTime);
  };

  const handleClearSearchText = () => {
    setTermValue("");
    onCommitChange("");
    const inputElement = document.getElementById(id);

    if (inputElement) {
      inputElement.focus();
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      // enter/next button on mobile
      e.preventDefault();
    }
  };

  return (
    <div
      className={classNames("w-100 d-flex", className)}
      style={style}
      {...rest}
    >
      <input
        ref={inputRef}
        id={id}
        type={type || "text"}
        placeholder={placeholder}
        value={termValue}
        onChange={handleChangeTermValue}
        className="w-100 _outline-none _border-none"
        style={inputStyle}
        onKeyPress={handleKeyPress}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        maxLength={maxLength}
      />

      {enableClearButton && (
        <button
          type="button"
          className="_border-none _outline-none button__effect"
          style={{
            background: "transparent",
            visibility: showClearButton && termValue ? "visible" : "hidden",
          }}
          onClick={handleClearSearchText}
        >
          <img
            src={SVG.BtnClearText}
            alt="clear text button"
            width={12.25}
            height={12.25}
          />
        </button>
      )}
    </div>
  );
};

DebounceInput.propTypes = {
  type: PropTypes.string,
  placeholder: PropTypes.string,
  delayTime: PropTypes.number,
  onCommitChange: PropTypes.func.isRequired,
  showClearButton: PropTypes.bool,
  enableClearButton: PropTypes.bool,
  focusInput: PropTypes.bool,
};

DebounceInput.defaultProps = {
  type: "text",
  placeholder: "",
  delayTime: 500,
  showClearButton: false,
  enableClearButton: false,
  focusInput: false,
};

export default DebounceInput;
