/* eslint-disable no-param-reassign */
import { addErrors, delErrors } from "utils/ErrorsGenerator";
import MESSAGES from "constants/vi";
import { toast } from "react-toastify";

import { convertWeight } from "utils/numberFormat";
import * as TYPE from "./constants";

const MESSAGE_KEY = "SetDeliveryInfo"; // key của object chứa message
const UNKNOWN = "unknown";
const toastOptions = {
  position: "top-center",
  autoClose: 7000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

const reducers = {
  [TYPE.GET_SHIPPING_INFO.request]: (draft) => {
    draft.isLoading.GET_SHIPPING_INFO = true;
  },
  [TYPE.GET_SHIPPING_INFO.success]: (draft, { payload }) => {
    draft.errors = delErrors(draft.errors, TYPE.GET_SHIPPING_INFO.failure);
    draft.shippingInfo = payload?.[0];
    draft.isLoading.GET_SHIPPING_INFO = false;
  },
  [TYPE.GET_SHIPPING_INFO.failure]: (draft, { payload }) => {
    draft.errors = addErrors(draft.errors, {
      type: TYPE.GET_SHIPPING_INFO.failure,
      msg: payload,
    });
    draft.isLoading.GET_SHIPPING_INFO = false;
    switch (payload?.message) {
      case "UnsatisfyingMinOrder": {
        if (payload?.errors?.minOrderValue) {
          toast.error(
            `Cửa hàng chỉ giao với giá trị đơn hàng từ ${Number(
              payload.errors.minOrderValue
            ).toLocaleString("vi")}đ.`,
            {
              toastId: `${TYPE}_GET_SHIPPING_INFO__UnsatisfyingMinOrder`,
              ...toastOptions,
            }
          );
          break;
        }

        toast.error(`Đơn hàng chưa đạt giá trị tối thiểu tại cửa hàng.`, {
          toastId: `${TYPE}_GET_SHIPPING_INFO__UnsatisfyingMinOrder_default`,
          ...toastOptions,
        });

        break;
      }
      case "UnsatisfyingDistance": {
        if (payload?.errors?.maxDistance) {
          toast.error(
            `Cửa hàng chỉ giao trong ${payload.errors.maxDistance}km.`,
            {
              toastId: `${TYPE}_GET_SHIPPING_INFO__UnsatisfyingDistance_maxDistance`,
              ...toastOptions,
            }
          );
          break;
        }

        if (payload?.errors?.distanceTo) {
          toast.error(
            `Cửa hàng chỉ giao trong ${payload.errors.distanceTo}km.`,
            {
              toastId: `${TYPE}_GET_SHIPPING_INFO__UnsatisfyingDistance_distanceTo`,
              ...toastOptions,
            }
          );
          break;
        }

        break;
      }
      case "ExceedWeightLimitation": {
        if (payload?.errors?.maxWeight) {
          toast.error(
            `Chỉ áp dụng cho đơn hàng có khối lương từ ${convertWeight(
              payload.errors.maxWeight
            )} trở xuống.`,
            {
              toastId: `${TYPE}_GET_SHIPPING_INFO__ExceedWeightLimitation_maxWeight`,
              ...toastOptions,
            }
          );
        } else {
          toast.error(MESSAGES?.[MESSAGE_KEY]?.error?.[UNKNOWN], toastOptions);
        }

        break;
      }
      default: {
        const errMsgToToast =
          MESSAGES?.[MESSAGE_KEY]?.error?.[payload?.message] ||
          // MESSAGES?.[MESSAGE_KEY]?.error?.[UNKNOWN];
          MESSAGES?.[MESSAGE_KEY]?.error?.["unknownShippingError"];

        toast.error(errMsgToToast, {
          toastId: `${TYPE}_GET_SHIPPING_INFO__unknownShippingError`,
          ...toastOptions,
        });
      }
    }
  },

  [TYPE.GET_SUGGESTED_DELIVERY_DISCOUNT_LIST.request]: (draft) => {
    draft.isLoading.GET_SUGGESTED_DELIVERY_DISCOUNT_LIST = true;
  },
  [TYPE.GET_SUGGESTED_DELIVERY_DISCOUNT_LIST.success]: (draft, { payload }) => {
    draft.errors = delErrors(
      draft.errors,
      TYPE.GET_SUGGESTED_DELIVERY_DISCOUNT_LIST.failure
    );
    draft.suggestedDeliveryDiscountList = payload;
    draft.isLoading.GET_SUGGESTED_DELIVERY_DISCOUNT_LIST = false;
  },
  [TYPE.GET_SUGGESTED_DELIVERY_DISCOUNT_LIST.failure]: (draft, { payload }) => {
    draft.errors = addErrors(draft.errors, {
      type: TYPE.GET_SUGGESTED_DELIVERY_DISCOUNT_LIST.failure,
      msg: payload,
    });
    draft.isLoading.GET_SUGGESTED_DELIVERY_DISCOUNT_LIST = false;
  },

  [TYPE.GET_STORE_INFO.request]: (draft) => {
    draft.isLoading.GET_STORE_INFO = true;
  },
  [TYPE.GET_STORE_INFO.success]: (draft, { payload }) => {
    draft.errors = delErrors(draft.errors, TYPE.GET_STORE_INFO.failure);
    draft.storeInfo = payload;
    draft.isLoading.GET_STORE_INFO = false;
  },
  [TYPE.GET_STORE_INFO.failure]: (draft, { payload }) => {
    draft.errors = addErrors(draft.errors, {
      type: TYPE.GET_STORE_INFO.failure,
      msg: payload,
    });
    draft.isLoading.GET_STORE_INFO = false;
  },
};

export default reducers;
