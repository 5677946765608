import { addErrors, delErrors } from "utils/ErrorsGenerator";
// import { getPathnameAndSearchFromURLString } from "utils/stringFormat";
import * as TYPE from "./constants";
// import { toast } from "react-toastify";

const reducers = {
  /* GET_LIST_CITY */
  [TYPE.GET_LIST_CITY.request]: (draft) => {
    return {
      ...draft,
      isLoading: {
        ...draft.isLoading,
        GET_LIST_CITY: true,
      },
    };
  },
  [TYPE.GET_LIST_CITY.success]: (draft, { payload }) => {
    return {
      ...draft,
      errors: delErrors(draft.errors, TYPE.GET_LIST_CITY.failure),
      cities: payload,
      isLoading: {
        ...draft.isLoading,
        GET_LIST_CITY: false,
      },
    };
  },

  [TYPE.GET_LIST_CITY.failure]: (draft, { payload }) => {
    return {
      ...draft,
      errors: addErrors(draft.errors, {
        type: TYPE.GET_LIST_CITY.failure,
        msg: payload,
      }),
      isLoading: {
        ...draft.isLoading,
        GET_LIST_CITY: false,
      },
    };
  },

  /* FILTER_VOUCHER_STORE */
  [TYPE.FILTER_VOUCHER_STORE.request]: (draft) => {
    return {
      ...draft,
      isLoading: {
        ...draft.isLoading,
        FILTER_VOUCHER_STORE: true,
      },
    };
  },
  [TYPE.FILTER_VOUCHER_STORE.success]: (draft, { payload }) => {
    return {
      ...draft,
      errors: delErrors(draft.errors, TYPE.FILTER_VOUCHER_STORE.failure),
      listVoucher: {
        value: payload?.value || [], // filter là thay toàn bộ list chứ không gắn thêm
        total: payload?.[`@odata.count`] || 0,
      },
      isLoading: {
        ...draft.isLoading,
        FILTER_VOUCHER_STORE: false,
      },
    };
  },
  [TYPE.FILTER_VOUCHER_STORE.failure]: (draft, { payload }) => {
    return {
      ...draft,
      errors: addErrors(draft.errors, {
        type: TYPE.FILTER_VOUCHER_STORE.failure,
        msg: payload,
      }),
      isLoading: {
        ...draft.isLoading,
        FILTER_VOUCHER_STORE: false,
      },
    };
  },

  // ! deprecated
  /* GET_LIST_VOUCHER */
  [TYPE.GET_LIST_VOUCHER.request]: (draft) => {
    return {
      ...draft,
      isLoading: {
        ...draft.isLoading,
        GET_LIST_VOUCHER: true,
      },
    };
  },
  [TYPE.GET_LIST_VOUCHER.success]: (draft, { payload }) => {
    const prevListValue = draft.listVoucher.value || [];
    const listFeedToAppend = payload.value;

    return {
      ...draft,
      errors: delErrors(draft.errors, TYPE.GET_LIST_VOUCHER.failure),
      listVoucher: {
        isInit: false,
        value: [...prevListValue, ...listFeedToAppend],
        total: payload.total,
      },
      isLoading: {
        ...draft.isLoading,
        GET_LIST_VOUCHER: false,
      },
    };
  },

  [TYPE.GET_LIST_VOUCHER.failure]: (draft, { payload }) => {
    return {
      ...draft,
      errors: addErrors(draft.errors, {
        type: TYPE.GET_LIST_VOUCHER.failure,
        msg: payload,
      }),
      isLoading: {
        ...draft.isLoading,
        GET_LIST_VOUCHER: false,
      },
    };
  },

  [TYPE.INIT_STATE_LIST_FEED.request]: (draft) => {
    return {
      ...draft,
      listFeed: {
        isInit: true, // for check first time to call api
        value: [],
        hasMore: false,
      },
    };
  },

  // *** GET_LIST_FEED
  [TYPE.GET_LIST_FEED.request]: (draft) => {
    return {
      ...draft,
      isLoading: {
        ...draft.isLoading,
        GET_LIST_FEED: true,
      },
    };
  },
  [TYPE.GET_LIST_FEED.success]: (draft, { payload }) => {
    return {
      ...draft,
      errors: delErrors(draft.errors, TYPE.GET_LIST_FEED.failure),
      listFeed: payload,
      isLoading: {
        ...draft.isLoading,
        GET_LIST_FEED: false,
      },
    };
  },

  [TYPE.GET_LIST_FEED.failure]: (draft, { payload }) => {
    return {
      ...draft,
      errors: addErrors(draft.errors, {
        type: TYPE.GET_LIST_FEED.failure,
        msg: payload,
      }),
      isLoading: {
        ...draft.isLoading,
        GET_LIST_FEED: false,
      },
    };
  },

  // *** GET_LIST_STORE_OF_MERCHANT
  [TYPE.GET_LIST_STORE_OF_MERCHANT.request]: (draft, { payload }) => {
    const { merchantId } = payload;

    let { listStore, hasMore } = draft.listStoreOfSelectedMerchant;

    // reset data if select a another merchant
    if (draft.listStoreOfSelectedMerchant.merchantId !== merchantId) {
      listStore = [];
      hasMore = true;
    }

    return {
      ...draft,
      listStoreOfSelectedMerchant: {
        merchantId,
        listStore,
        hasMore,
      },
      isLoading: {
        ...draft.isLoading,
        GET_LIST_STORE_OF_MERCHANT: true,
      },
    };
  },
  [TYPE.GET_LIST_STORE_OF_MERCHANT.success]: (draft, { payload }) => {
    return {
      ...draft,
      listStoreOfSelectedMerchant: payload,
      errors: delErrors(draft.errors, TYPE.GET_LIST_STORE_OF_MERCHANT.failure),
      isLoading: {
        ...draft.isLoading,
        GET_LIST_STORE_OF_MERCHANT: false,
      },
    };
  },

  [TYPE.GET_LIST_STORE_OF_MERCHANT.failure]: (draft, { payload }) => {
    return {
      ...draft,
      errors: addErrors(draft.errors, {
        type: TYPE.GET_LIST_STORE_OF_MERCHANT.failure,
        msg: payload,
      }),
      isLoading: {
        ...draft.isLoading,
        GET_LIST_STORE_OF_MERCHANT: false,
      },
    };
  },

  /* GET_SHORTCUTS */
  [TYPE.GET_SHORTCUTS.request]: (draft) => {
    return {
      ...draft,
      isLoading: {
        ...draft.isLoading,
        GET_SHORTCUTS: true,
      },
    };
  },
  [TYPE.GET_SHORTCUTS.success]: (draft, { payload }) => {
    const flattenShortcutsData = payload.items
      .map((shortcutData) => ({
        id: shortcutData.id,
        icon: shortcutData.data.iconUrl.iv,
        name: shortcutData.data.name.vi,
        actionUrl: shortcutData.data.url.iv,
        priority: shortcutData.data.priority.iv,
      }))
      .sort((first, second) => second.priority - first.priority);

    return {
      ...draft,
      errors: delErrors(draft.errors, TYPE.GET_SHORTCUTS.failure),
      listShortcuts: flattenShortcutsData,
      isLoading: {
        ...draft.isLoading,
        GET_SHORTCUTS: false,
      },
    };
  },
  [TYPE.GET_SHORTCUTS.failure]: (draft, { payload }) => {
    return {
      ...draft,
      errors: addErrors(draft.errors, {
        type: TYPE.GET_SHORTCUTS.failure,
        msg: payload,
      }),
      isLoading: {
        ...draft.isLoading,
        GET_SHORTCUTS: false,
      },
    };
  },

  /* GET_SHORTCUTS_PERSONALIZED */
  [TYPE.GET_SHORTCUTS_PERSONALIZED.request]: (draft) => {
    return {
      ...draft,
      isLoading: {
        ...draft.isLoading,
        GET_SHORTCUTS_PERSONALIZED: true,
      },
    };
  },
  [TYPE.GET_SHORTCUTS_PERSONALIZED.success]: (draft, { payload }) => {
    const standardizedListShortcuts = payload.map((shortcutData) => ({
      ...shortcutData,
      actionUrl: shortcutData.actionUrl,
      id: shortcutData.id,
    }));

    return {
      ...draft,
      errors: delErrors(draft.errors, TYPE.GET_SHORTCUTS_PERSONALIZED.failure),
      listShortcuts: standardizedListShortcuts,
      isLoading: {
        ...draft.isLoading,
        GET_SHORTCUTS_PERSONALIZED: false,
      },
    };
  },
  [TYPE.GET_SHORTCUTS_PERSONALIZED.failure]: (draft, { payload }) => {
    return {
      ...draft,
      errors: addErrors(draft.errors, {
        type: TYPE.GET_SHORTCUTS_PERSONALIZED.failure,
        msg: payload,
      }),
      isLoading: {
        ...draft.isLoading,
        GET_SHORTCUTS_PERSONALIZED: false,
      },
    };
  },
};

export default reducers;
