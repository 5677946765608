const prefix = 'search_home';

export const RECENT_REQUEST = prefix + '/RECENT_REQUEST';
export const RECENT_SUCCESS = prefix + '/RECENT_SUCCESS';
export const RECENT_FAILED = prefix + '/RECENT_FAILED';

export const SEARCH_REQUEST = prefix + '/SEARCH_REQUEST';
export const SEARCH_SUCCESS = prefix + '/SEARCH_SUCCESS';
export const SEARCH_FAILED = prefix + '/SEARCH_FAILED';

export const SEARCH_HASHTAG_REQUEST = prefix + '/SEARCH_HASHTAG_REQUEST';
export const SEARCH_HASHTAG_SUCCESS = prefix + '/SEARCH_HASHTAG_SUCCESS';
export const SEARCH_HASHTAG_FAILED = prefix + '/SEARCH_HASHTAG_FAILED';

export const HOTKEY_REQUEST = prefix + '/HOTKEY_REQUEST';
export const HOTKEY_SUCCESS = prefix + '/HOTKEY_SUCCESS';
export const HOTKEY_FAILED = prefix + '/HOTKEY_FAILED';

export const CLEAR_ERRORS = prefix + '/CLEAR_ERRORS';
export const CLEAR_LIST = prefix + '/CLEAR_LIST';
export const CLEAR_LIST_HASHTAG = prefix + '/CLEAR_LIST_HASHTAG';

export const SEARCH_PERSONAL_REQUEST = prefix + '/SEARCH_PERSONAL_REQUEST';
export const SEARCH_PERSONAL_SUCCESS = prefix + '/SEARCH_PERSONAL_SUCCESS';
export const SEARCH_PERSONAL_FAILED = prefix + '/SEARCH_PERSONAL_FAILED';
