import moment from 'moment';

const parseJsonToArray = masterData => {
  var arrMaster = [];
  if (typeof masterData !== 'undefined' && masterData !== null) {
    Object.keys(masterData).forEach((element, i) => {
      arrMaster.push(masterData[element]);
    });
  }

  return arrMaster;
};

const mergeMasterData = data => {
  let masterData = {};
  for (var i = 0; i < data.length; i++) {
    var groupName = moment.utc(data[i].createdAt).local().format('DD/MM/YYYY');
    if (!masterData[groupName]) {
      masterData[groupName] = [];
    }
    masterData[groupName].push(data[i]);
  }
  return parseJsonToArray(masterData);
};

export const listHistoryOrderParser = data => {
  console.log(data);
  if (data) {
    const newArray = data.data.map(item => {
      return {
        createdAt: item.createdAt,
        merchantId: item.merchantId,
        orderCode: item.orderCode,
        status: item.status,
        storeId: item.storeId,
        totalAmount: item.totalAmount,
        type: item.type,
        updatedAt: item.updatedAt,
        userId: item.userId,
        metadata: item.metadata,
      };
    });
    return newArray;
  }
  return [];
};
