import request from "services/request";
import { encodeQueryData } from "utils/stringFormat";

import * as TYPE from "../store/constants";

const api = {
  [TYPE.GET_LIST_COUPON.request]: async (params) => {
    const query = encodeQueryData({
      status: params.status,
      voucherType: params.voucherType,
      orderType: params.orderType,
    });

    return request(`/voucher/myvouchers${query}`, "GET");
  },

  [TYPE.SEARCH_COUPON_BY_COUPON_CODE.request]: async (params) => {
    const query = encodeQueryData({
      couponCode: params.couponCode,
    });

    return request(`/coupon/GetCouponCampaignByCouponCode${query}`, "GET");
  },
};

export default api;
