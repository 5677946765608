export const orderListParser = data => {
  console.log('ORDER LIST: ', data);
  if (data) {
    // const newArray = data.data.map(item => {
    //   return {
    //     createdAt: item.createdAt,
    //     expiredDay: item.expiredDay,
    //     imageUrl: item.imageUrl,
    //     logoUrl: item.logoUrl,
    //     couponCode: item.productCode,
    //     couponName: item.productName,
    //     couponId: item.refId,
    //     transactionCode: item.transactionCode,
    //     voucherCount: item.voucherCount,
    //     voucherType: item.voucherType
    //   };
    // });
    return data;
  }
  return [];
};

