import request, { requestWithoutToken } from "services/request";
import { encodeQueryData } from "utils/stringFormat";
import { APIUtils } from "utils";

import * as TYPE from "../store/constants";

const api = {
  [TYPE.GET_VOUCHER_INFO.request]: async (params) => {
    return APIUtils.get(
      `${process.env.REACT_APP_DOMAIN_UTOP_API}cms/Voucher/${params.id}`
    );
    // return requestWithoutToken(`/cms/core/vouchers/${params.id}`, "GET", null, {
    //   "content-type": "application/json",
    //   "Access-Control-Allow-Credentials": true,
    //   Authorization: "",
    //   "Ocp-Apim-Subscription-Key":
    //     process.env.REACT_APP_OCP_APIM_SUBSCRIPTION_KEY,
    //   "X-Client": "Web",
    // });
  },

  [TYPE.GET_SUGGESTED_COUPON_LIST.request]: async (params) => {
    const { viewAsGuest } = params;
    const query = encodeQueryData({
      MerchantId: params.MerchantId,
      Feature: params.Feature,
    });

    if (viewAsGuest) {
      return requestWithoutToken(`/Coupon/GetSuggestCoupon${query}`, "GET");
    }

    return request(`/Coupon/GetSuggestCoupon${query}`, "GET");
  },

  [TYPE.SAVE_SUGGESTED_COUPON.request]: async (payload) => {
    return request(`/coupon/GetAvailableCouponsByCode`, "POST", payload);
  },

  [TYPE.GET_SHORT_LINK_TO_SHARE.request]: async (params) => {
    const { url } = params;

    return requestWithoutToken(`/common/ShortUrl`, "POST", { url });
  },

  [TYPE.CHECK_STOCK_OF_PRODUCT.request]: async (params) => {
    const query = encodeQueryData({
      productCode: params.productCode,
    });

    return request(`/Order/CheckStockOfProduct${query}`, "GET");
  },

  // [TYPE.GET_DETAIL_VOUCHER_MORE.request]: async (params) => {
  //   const query = encodeQueryData({
  //     categoryId: params.categoryId,
  //     lat: params.lat,
  //     long: params.long,
  //   });
  //   // https://apiqa.utopapp.net/feed/voucher?merchantId=6a5b1732-941d-4016-b961-036bb75ec6fd&lat=10.768482&long=106.683842
  //   return requestWithoutToken(`/feed/voucher${query}`, "GET", null, {
  //     "content-type": "application/json",
  //     "Access-Control-Allow-Credentials": true,
  //     Authorization: "",
  //     "Ocp-Apim-Subscription-Key":
  //       process.env.REACT_APP_OCP_APIM_SUBSCRIPTION_KEY,
  //     "X-Client": "Web",
  //   });
  // },
};

export default api;
