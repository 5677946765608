import { FEED_TYPES } from "constants/types";
import { requestWithoutToken } from "services/request";
import { encodeQueryDataV2 } from "utils/stringFormat";

import * as TYPE from "../store/constants";

const api = {
  // [TYPE.SEARCH_LIST.request]: async (params) => {
  //   const query = encodeQueryData({
  //     searchtext: params.searchText,
  //     lat: params.lat,
  //     long: params.long,
  //     skip: params.skip,
  //   });
  //   return requestWithoutToken(`/feed/Search${query}`, "GET", null, {
  //     "content-type": "application/json",
  //     "Access-Control-Allow-Credentials": true,
  //     "Ocp-Apim-Subscription-Key":
  //       process.env.REACT_APP_OCP_APIM_SUBSCRIPTION_KEY,
  //     "X-Client": "Web",
  //   });
  // },

  [TYPE.GET_LIST_FEED.request]: async (params) => {
    const query = encodeQueryDataV2({
      searchText: params.searchText,
      // ! hard code loc lai ket qua vi chua handle Product & Mall
      feedTypes: [
        FEED_TYPES.STORE.BEKey, // .key: lowercase, .BEKey: capitalized
        FEED_TYPES.MARKET.BEKey,
        FEED_TYPES.VOUCHER.BEKey,
      ],
      latitude: params.lat,
      longitude: params.long,
      skip: params.skip,
      take: params.take,
      isGrouping: params.isGrouping,
      isFilterVisibleAtHomePage: params.isFilterVisibleAtHomePage,
    });

    return requestWithoutToken(`/home/Feed/Search${query}`, "GET");
  },

  // * GET_LIST_STORE_OF_MERCHANT
  [TYPE.GET_LIST_STORE_OF_MERCHANT.request]: async (params) => {
    const query = encodeQueryDataV2({
      latitude: params.latitude,
      longitude: params.longitude,
      merchantId: params.merchantId,
      feedTypes: params.feedTypes, // can be an Array when use encodeQueryDataV2
      skip: params.skip,
      take: params.take,
      isGrouping: params.isGrouping,
    });

    return requestWithoutToken(`/home/Feed/Search${query}`, "GET");
  },
};

export default api;
