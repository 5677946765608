import {
  RECENT_SUCCESS,
  RECENT_REQUEST,
  RECENT_FAILED,
  SEARCH_SUCCESS,
  SEARCH_REQUEST,
  SEARCH_FAILED,
  CLEAR_ERRORS,
  CLEAR_LIST,
  CLEAR_LIST_HASHTAG,
  SEARCH_HASHTAG_REQUEST,
  SEARCH_HASHTAG_SUCCESS,
  SEARCH_HASHTAG_FAILED,
  HOTKEY_REQUEST,
  HOTKEY_SUCCESS,
  HOTKEY_FAILED,
  SEARCH_PERSONAL_REQUEST,
  SEARCH_PERSONAL_SUCCESS,
  SEARCH_PERSONAL_FAILED,
} from './types';

export const recentRequest = payload => ({
  type: RECENT_REQUEST,
  payload,
});

export const recentSuccess = payload => ({
  type: RECENT_SUCCESS,
  payload,
});

export const recentFailure = payload => ({
  type: RECENT_FAILED,
  payload,
});

export const searchVideoRequest = payload => ({
  type: SEARCH_REQUEST,
  payload,
});

export const searchVideoSuccess = payload => ({
  type: SEARCH_SUCCESS,
  payload,
});

export const searchVideoFailure = payload => ({
  type: SEARCH_FAILED,
  payload,
});

export const searchHashTagRequest = payload => ({
  type: SEARCH_HASHTAG_REQUEST,
  payload,
});

export const searchHashTagSuccess = payload => ({
  type: SEARCH_HASHTAG_SUCCESS,
  payload,
});

export const searchHashTagFailure = payload => ({
  type: SEARCH_HASHTAG_FAILED,
  payload,
});

export const searchPersonalRequest = payload => ({
  type: SEARCH_PERSONAL_REQUEST,
  payload,
});

export const searchPersonalSuccess = payload => ({
  type: SEARCH_PERSONAL_SUCCESS,
  payload,
});

export const searchPersonalFailed = payload => ({
  type: SEARCH_PERSONAL_FAILED,
  payload,
});

export const HotKeyRequest = payload => ({
  type: HOTKEY_REQUEST,
  payload,
});

export const HotKeySuccess = payload => ({
  type: HOTKEY_SUCCESS,
  payload,
});

export const HotKeyFailure = payload => ({
  type: HOTKEY_FAILED,
  payload,
});

export const clearErrors = () => ({
  type: CLEAR_ERRORS,
});

export const clearList = () => ({
  type: CLEAR_LIST,
});
export const clearListHashTag = () => ({
  type: CLEAR_LIST_HASHTAG,
});
