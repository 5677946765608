import { LOGOUT_REQUEST } from "@shared/redux/account/constants";
import {
  GET_DETAIL_ORDER_FAILED,
  GET_DETAIL_ORDER_REQUEST,
  GET_DETAIL_ORDER_SUCCESS,
  GET_INFO_STORE_FAILED,
  GET_INFO_STORE_SUCCESS,
  GET_DISCOUNT_COUPON_FAILED,
  GET_DISCOUNT_COUPON_SUCCESS,
  GET_PAYMENT_METHOD_FAILED,
  GET_PAYMENT_METHOD_SUCCESS,
  CANCEL_ORDER_SUCCESS,
  CANCEL_ORDER_FAILED,
} from "./constants";

const initialState = {
  messageError: "",
  detailOrder: {},
  infoStore: {},
  discountCoupon: {},
  paymentMethod: {},
};

const repaymentVoucherReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case GET_DETAIL_ORDER_REQUEST: {
      return {
        ...state,
      };
    }

    case GET_DETAIL_ORDER_FAILED: {
      return {
        ...state,
      };
    }

    case GET_DETAIL_ORDER_SUCCESS: {
      return {
        ...state,
        detailOrder: action.payload.data.data,
      };
    }

    case GET_INFO_STORE_FAILED: {
      return {
        ...state,
      };
    }

    case GET_INFO_STORE_SUCCESS: {
      return {
        ...state,
        infoStore: action.payload.data.data,
      };
    }

    case GET_DISCOUNT_COUPON_SUCCESS: {
      return {
        ...state,
        discountCoupon: action.payload.data.data,
      };
    }

    case GET_DISCOUNT_COUPON_FAILED: {
      return {
        ...state,
      };
    }

    case GET_PAYMENT_METHOD_SUCCESS: {
      return {
        ...state,
        paymentMethod: action.payload.data.data,
      };
    }

    case GET_PAYMENT_METHOD_FAILED: {
      return {
        ...state,
      };
    }

    case CANCEL_ORDER_SUCCESS: {
      return {
        ...state,
      };
    }

    case CANCEL_ORDER_FAILED: {
      return {
        ...state,
        messageError: state.messageError,
      };
    }

    case LOGOUT_REQUEST: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
};

export default repaymentVoucherReducer;
