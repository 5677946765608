/* eslint-disable vars-on-top */
/* eslint-disable no-var */
/* eslint-disable radix */
/* eslint-disable no-void */
/* eslint-disable no-cond-assign */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-param-reassign */
/* eslint-disable array-callback-return */
/**
 *
 * @param {Number, String} value : the price need to convert
 * @returns The price formatted in locale with đ behind
 */

import _ from "lodash";

const formatNumber = (a, b, c, d) => {
  const e = isNaN((b = Math.abs(b))) ? 2 : b;
  b = void 0 === c ? "," : c;
  d = void 0 === d ? "," : d;
  c = a < 0 ? "-" : "";
  const g = `${parseInt((a = Math.abs(+a || 0).toFixed(e)))}`;
  var n = (n = g.length) > 3 ? n % 3 : 0;
  return (
    c +
    (n ? g.substr(0, n) + d : "") +
    g.substr(n).replace(/(\d{3})(?=\d)/g, `$1${d}`) +
    (e
      ? b +
        Math.abs(a - g)
          .toFixed(e)
          .slice(2)
      : "")
  );
};

export const toVND = (value, isNegative) => {
  if (!value || value === undefined) {
    return `0đ`;
  }
  const valueAsNumber = Number(value);

  if (isNegative && valueAsNumber !== 0) {
    return `-${Number(valueAsNumber.toFixed(0)).toLocaleString("vi")}đ`;
  }

  return `${Number(valueAsNumber.toFixed(0)).toLocaleString("vi")}đ`;
};

export const toUTop = (value, exchangeRate, isNegative) => {
  const point = value / exchangeRate;

  const valueAsNumber = Number(value);

  if (isNegative && valueAsNumber !== 0) {
    return `-${formatNumber(
      Number.parseFloat(point).toFixed(),
      0,
      ".",
      "."
    )} Utop`;
  }
  if (!value || value === undefined) {
    return `0 Utop`;
  }
  return `${formatNumber(
    Number.parseFloat(point).toFixed(),
    0,
    ".",
    "."
  )} Utop`;
};

export const priceRange = (arr, type) => {
  if (!arr || arr.length === 0) return;
  const maxValue = _.maxBy(arr, "selling");
  const minValue = _.minBy(arr, "selling");
  if (type === "percent") {
    const percents = arr.map((item) => {
      if (item.list) {
        return 100 - (item.selling * 100) / item.list;
      }
      return 0;
    });
    return Math.round(_.max(percents));
  }

  if (
    maxValue?.selling === minValue?.selling &&
    maxValue.selling === maxValue.list
  ) {
    const selling = {
      minValue: minValue?.selling,
    };
    return { selling };
  }

  if (maxValue?.selling === minValue?.selling && !minValue.list) {
    const selling = {
      minValue: minValue?.selling,
    };
    return { selling };
  }
  if (
    maxValue?.list &&
    maxValue?.selling !== maxValue?.list &&
    minValue?.list &&
    minValue?.selling !== minValue?.list &&
    minValue?.selling !== maxValue?.selling &&
    minValue?.list !== maxValue?.list
  ) {
    const selling = {
      minValue: minValue?.selling,
      maxValue: maxValue?.selling,
    };
    const list = {
      minValue: minValue?.list,
      maxValue: maxValue?.list,
    };
    return {
      selling,
      list,
    };
  }
  if (
    maxValue?.selling === minValue?.selling &&
    maxValue?.list === minValue?.list
  ) {
    const selling = {
      minValue: minValue?.selling,
    };
    const list = {
      minValue: minValue?.list,
    };
    return {
      selling,
      list,
    };
  }
  if (
    (maxValue.selling !== minValue.selling && !maxValue.list) ||
    !minValue.list
  ) {
    const selling = {
      maxValue: maxValue.selling,
      minValue: minValue.selling,
    };
    return {
      selling,
    };
  }
  const selling = {
    minValue: minValue?.selling,
  };
  const list = {
    minValue: minValue?.list,
  };
  return { selling, list };
};

export const priceRangeV2 = (arr, type) => {
  if (!arr || arr.length === 0) {
    const selling = {
      minValue: "",
    };
    const list = {
      minValue: "",
    };
    return { selling, list };
  }
  const maxValue = _.maxBy(arr, "price");
  const minValue = _.minBy(arr, "price");
  if (type === "percent") {
    const percents = arr.map((item) => {
      if (item.listPrice) {
        return 100 - (item.price * 100) / item.listPrice;
      }
      return 0;
    });
    return Math.round(_.max(percents));
  }

  if (
    maxValue?.price === minValue?.price &&
    maxValue?.price === maxValue?.listPrice
  ) {
    const selling = {
      minValue: minValue?.price,
    };
    return { selling };
  }

  if (maxValue?.price === minValue?.price && !minValue.listPrice) {
    const selling = {
      minValue: minValue?.price,
    };
    return { selling };
  }
  if (
    maxValue?.listPrice &&
    maxValue?.price !== maxValue?.listPrice &&
    minValue?.listPrice &&
    minValue?.price !== minValue?.listPrice &&
    minValue?.price !== maxValue?.price &&
    minValue?.listPrice !== maxValue?.listPrice
  ) {
    const selling = {
      minValue: minValue?.price,
      maxValue: maxValue?.price,
    };
    const list = {
      minValue: minValue?.listPrice,
      maxValue: maxValue?.listPrice,
    };
    return {
      selling,
      list,
    };
  }
  if (
    maxValue?.price === minValue?.price &&
    maxValue?.listPrice === minValue?.listPrice
  ) {
    const selling = {
      minValue: minValue?.price,
    };
    const list = {
      minValue: minValue?.listPrice,
    };
    return {
      selling,
      list,
    };
  }
  if (
    (maxValue.price !== minValue.price && !maxValue.listPrice) ||
    !minValue.list
  ) {
    const selling = {
      maxValue: maxValue.price,
      minValue: minValue.price,
    };
    return {
      selling,
    };
  }
  const selling = {
    minValue: minValue?.price,
  };
  const list = {
    minValue: minValue?.listPrice,
  };
  return { selling, list };
};

export const priceRangeV3 = (arr, type) => {
  if (!arr || arr.length === 0) {
    const selling = {
      minValue: "",
    };
    const list = {
      minValue: "",
    };
    return { selling, list };
  }
  const maxValue = _.maxBy(arr, "selling");
  const minValue = _.minBy(arr, "selling");
  if (type === "percent") {
    const percents = arr.map((item) => {
      if (item.list) {
        return 100 - (item.selling * 100) / item.list;
      }
      return 0;
    });
    return Math.round(_.max(percents));
  }

  if (
    maxValue?.selling === minValue?.selling &&
    maxValue?.selling === maxValue?.list
  ) {
    const selling = {
      minValue: minValue?.selling,
    };
    return { selling };
  }

  if (maxValue?.selling === minValue?.selling && !minValue.list) {
    const selling = {
      minValue: minValue?.selling,
    };
    return { selling };
  }
  if (
    maxValue?.list &&
    maxValue?.selling !== maxValue?.list &&
    minValue?.list &&
    minValue?.selling !== minValue?.list &&
    minValue?.selling !== maxValue?.selling &&
    minValue?.list !== maxValue?.list
  ) {
    const selling = {
      minValue: minValue?.selling,
      maxValue: maxValue?.selling,
    };
    const list = {
      minValue: minValue?.list,
      maxValue: maxValue?.list,
    };
    return {
      selling,
      list,
    };
  }
  if (
    maxValue?.selling === minValue?.selling &&
    maxValue?.list === minValue?.list
  ) {
    const selling = {
      minValue: minValue?.selling,
    };
    const list = {
      minValue: minValue?.list,
    };
    return {
      selling,
      list,
    };
  }
  if (
    (maxValue.selling !== minValue.selling && !maxValue.list) ||
    !minValue.list
  ) {
    const selling = {
      maxValue: maxValue.selling,
      minValue: minValue.selling,
    };
    return {
      selling,
    };
  }
  const selling = {
    minValue: minValue?.selling,
  };
  const list = {
    minValue: minValue?.list,
  };
  return { selling, list };
};
