import React from "react";
import IMAGES from "assets/img";
import classNames from "classnames";

import "./Delivery.scss";

const Delivery = (props) => {
  return (
    <div className="delivery-wrapper">
      <div className="title-list">Phương thức giao hàng</div>
      <div className="list-item">
        {props.listMethodDelivery &&
          // eslint-disable-next-line array-callback-return
          props.listMethodDelivery.map((item) => {
            if (
              item.code !== "table" &&
              item.code !== "takeaway" &&
              item.code !== "pickuptime"
            ) {
              return (
                <div
                  className={classNames(
                    "type-delivery",
                    item.isCheck && "type-delivery-select"
                  )}
                  onClick={() => {
                    props.selectMethodDelivery(item);
                    // props.selectMethodDelivery(false);
                  }}
                >
                  <div className="type-delivery_left">
                    <div className="icon-delivery">
                      <img
                        src={
                          item?.code === "home"
                            ? IMAGES.IconDeliveryHome
                            : IMAGES.IconDeliveryShop
                        }
                      />
                    </div>
                    <div className="text-delivery">
                      {item?.code === "home" ? "Giao về" : "Nhận tại quầy"}
                    </div>
                  </div>
                </div>
              );
            }
          })}
      </div>
    </div>
  );
};

export default Delivery;
