import {
  GET_SUGGEST_COUPON_REQUEST,
  GET_SUGGEST_COUPON_SUCCESS,
  GET_SUGGEST_COUPON_FAILED,
  ALLOWCATION_COUPON_REQUEST,
  ALLOWCATION_COUPON_SUCCESS,
  ALLOWCATION_COUPON_FAILED,
  CLEAR_ERRORS,
  GET_COUPON_DETAIL_REQUEST,
  GET_COUPON_DETAIL_SUCCESS,
  GET_COUPON_DETAIL_FAILED,
  CAL_COUPON_DISCOUNT_REQUEST,
  CAL_COUPON_DISCOUNT_SUCCESS,
  CAL_COUPON_DISCOUNT_FAILED,
  ADD_PRODUCT_REQUEST,
  DELETE_PRODUCT_REQUEST,
  SET_DESCRIPTION,
  SET_DELIVERY_ADDRESS,
  SET_VOUCHER,
  SET_DISCOUNT,
  SET_NUMBER_TABLE,
  UPDATE_VOUCHER,
  CLEAR_ALLOWCATE_COUPON,
  SET_TAKE_AWAY,
  SET_DELIVERY_ADDRESS_SELECTED,
  GET_SUGGEST_DISCOUNT_SHIPPING_FAILED,
  GET_SUGGEST_DISCOUNT_SHIPPING_REQUEST,
  GET_SUGGEST_DISCOUNT_SHIPPING_SUCCESS,
} from './constants';

export const setNumberTable = payload => ({
  type: SET_NUMBER_TABLE,
  payload,
});

export const setDiscount = payload => ({
  type: SET_DISCOUNT,
  payload,
});

export const setDescription = payload => ({
  type: SET_DESCRIPTION,
  payload,
});

export const setDeliveryAddress = payload => ({
  type: SET_DELIVERY_ADDRESS,
  payload,
});

export const setVoucher = payload => ({
  type: SET_VOUCHER,
  payload,
});

export const updateVoucher = payload => ({
  type: UPDATE_VOUCHER,
  payload,
});
export const deleteProductRequest = payload => ({
  type: DELETE_PRODUCT_REQUEST,
  payload,
});

export const addProductRequest = payload => ({
  type: ADD_PRODUCT_REQUEST,
  payload,
});

export const calCouponDiscountRequest = (payload, onSuccess, onFailed) => ({
  type: CAL_COUPON_DISCOUNT_REQUEST,
  payload,
  onSuccess,
  onFailed,
});

export const calCouponDiscountSuccess = payload => ({
  type: CAL_COUPON_DISCOUNT_SUCCESS,
  payload,
});

export const calCouponDiscountFailure = payload => ({
  type: CAL_COUPON_DISCOUNT_FAILED,
  payload,
});

export const getCouponDetailRequest = (payload, onSuccess, onFailed) => ({
  type: GET_COUPON_DETAIL_REQUEST,
  payload,
  onSuccess,
  onFailed,
});

export const getCouponDetailSuccess = payload => ({
  type: GET_COUPON_DETAIL_SUCCESS,
  payload,
});

export const getCouponDetailFailure = payload => ({
  type: GET_COUPON_DETAIL_FAILED,
  payload,
});

export const allowcationCouponRequest = (payload, onSuccess, onFailed) => ({
  type: ALLOWCATION_COUPON_REQUEST,
  payload,
  onSuccess,
  onFailed,
});

export const allowcationCouponSuccess = payload => ({
  type: ALLOWCATION_COUPON_SUCCESS,
  payload,
});

export const allowcationCouponFailure = payload => ({
  type: ALLOWCATION_COUPON_FAILED,
  payload,
});

export const getSuggestCouponRequest = payload => ({
  type: GET_SUGGEST_COUPON_REQUEST,
  payload,
});

export const getSuggestCouponSuccess = payload => ({
  type: GET_SUGGEST_COUPON_SUCCESS,
  payload,
});

export const getSuggestCouponFailure = payload => ({
  type: GET_SUGGEST_COUPON_FAILED,
  payload,
});

export const clearErrors = payload => ({
  type: CLEAR_ERRORS,
  payload,
});

export const clearAllowcateCoupon = payload => ({
  type: CLEAR_ALLOWCATE_COUPON,
  payload,
});

export const setTakeAway = payload => ({
  type: SET_TAKE_AWAY,
  payload,
});

export const setDeliveryAddressSelected = payload => ({
  type: SET_DELIVERY_ADDRESS_SELECTED,
  payload,
});

export const getSuggestDiscountShippingRequest = (
  payload,
  onSuccess,
  onFailed,
) => ({
  type: GET_SUGGEST_DISCOUNT_SHIPPING_REQUEST,
  payload,
  onSuccess,
  onFailed,
});

export const getSuggestDiscountShippingSuccess = payload => ({
  type: GET_SUGGEST_DISCOUNT_SHIPPING_SUCCESS,
  payload,
});

export const getSuggestDiscountShippingFailed = payload => ({
  type: GET_SUGGEST_DISCOUNT_SHIPPING_FAILED,
  payload,
});
