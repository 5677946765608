import { BlobServiceClient } from '@azure/storage-blob';

const containerName = "useravatar";

export const uploadFileToBlob = async (file, sasToken, url, name) => {
  if (!file) return [];

  // get BlobService = notice `?` is pulled out of sasToken - if created in Azure portal
  const blobService = new BlobServiceClient(`https://utopstimagegstqa.blob.core.windows.net/?${sasToken}`);

  // get Container - full public read access
  const containerClient = blobService.getContainerClient(containerName);

  // upload file
  await createBlobInContainer(containerClient, file, name);

  return `${url}/${name}.jpg`;
};

const createBlobInContainer = async (containerClient, file, name) => {

  // create blobClient for container
  const blobClient = containerClient.getBlockBlobClient(`${name}.jpg`);

  // set mimetype as determined from browser with file upload control
  const options = { blobHTTPHeaders: { blobContentType: file.type } };

  // upload file
  await blobClient.uploadBrowserData(file, options);
}