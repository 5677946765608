import {
  GET_DEFAULT_ADDRESS_USER_REQUEST,
  GET_DEFAULT_ADDRESS_USER_SUCCESS,
  GET_DEFAULT_ADDRESS_USER_FAILED,
  CALCULATE_SHIPPING_SUCCESS,
  SAVE_DEFAULT_ADDRESS_USER,
  CALCULATE_SHIPPING_FAILED,
} from './constants';

const initialState = {
  defaultAddressUser: {},
  messageError: '',
  listShipping: [],
};

const orderAddressFood = (state = initialState, action) => {
  const {payload, type} = action;

  switch (type) {
    case CALCULATE_SHIPPING_SUCCESS: {
      return {
        ...state,
        listShipping: payload,
      };
    }

    case CALCULATE_SHIPPING_FAILED: {
      return {
        ...state,
        listShipping: [],
      };
    }

    case GET_DEFAULT_ADDRESS_USER_REQUEST: {
      return {
        ...state,
        messageError: '',
      };
    }

    case GET_DEFAULT_ADDRESS_USER_SUCCESS: {
      return {
        ...state,
        defaultAddressUser: payload,
      };
    }

    case GET_DEFAULT_ADDRESS_USER_FAILED: {
      return {
        ...state,
        messageError: payload,
      };
    }

    case SAVE_DEFAULT_ADDRESS_USER: {
      return {
        ...state,
        defaultAddressUser: payload,
      };
    }

    default:
      return state;
  }
};

export default orderAddressFood;
