import {END_POINTS} from '@shared/constants/apiUrl';

import {APIUtils} from 'utils';

export function getListNewsBoughtTypeApi(payload) {
  return APIUtils.get(
    END_POINTS.GET_NEWSPAPER +
      `?skip=${payload.skip}&take=${payload.take}&isPurchased=${payload.isPurchased}`,
  );
}
export function getListNewsWithTypeApi(payload) {
  return APIUtils.get(
    END_POINTS.GET_LIST_NEWS_WITH_TYPE + `?Status=${payload}`,
  );
}

export function getListNewsDataWithTypeApi(payload) {
  return APIUtils.get(
    END_POINTS.GET_LIST_NEWS_DATA_WITH_TYPE_BLOCK +
      `?skip=${payload.skip}&take=${payload.take}&newspaperTypeId=${payload.newspaperTypeId}&isPurchased=${payload.isPurchased}`,
  );
}
