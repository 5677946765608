import {
  GET_SUPPORTED_VERSION_FAILED,
  GET_SUPPORTED_VERSION_REQUEST,
  GET_SUPPORTED_VERSION_SUCCESS,
  UNSUPPORTED_VERSION_FAILED,
  UPDATE_IS_FORCE_TO_UPDATE_FAILED,
  UPDATE_IS_FORCE_TO_UPDATE_REQUEST,
  UPDATE_IS_FORCE_TO_UPDATE_SUCCESS,
  UPDATE_IS_MENTION_TO_UPDATE_FAILED,
  UPDATE_IS_MENTION_TO_UPDATE_REQUEST,
  UPDATE_IS_MENTION_TO_UPDATE_SUCCESS,
} from './constants';

export const getSupportedVersionHandle = (onSuccess, onError) => ({
  type: GET_SUPPORTED_VERSION_REQUEST,
  onSuccess,
  onError,
});

export const getSupportedVersionSuccess = payload => ({
  type: GET_SUPPORTED_VERSION_SUCCESS,
  payload,
});

export const getSupportedVersionFailed = error => ({
  type: GET_SUPPORTED_VERSION_FAILED,
  error,
});

export const setIsMentionToUpdateHandle = payload => ({
  type: UPDATE_IS_MENTION_TO_UPDATE_REQUEST,
  payload,
});

export const setIsMentionToUpdateSuccess = payload => ({
  type: UPDATE_IS_MENTION_TO_UPDATE_SUCCESS,
  payload,
});

export const setIsMentionToUpdateFailed = error => ({
  type: UPDATE_IS_MENTION_TO_UPDATE_FAILED,
  error,
});

export const setIsForceToUpdateHandle = payload => ({
  type: UPDATE_IS_FORCE_TO_UPDATE_REQUEST,
  payload,
});

export const setIsForceToUpdateSuccess = payload => ({
  type: UPDATE_IS_FORCE_TO_UPDATE_SUCCESS,
  payload,
});

export const setIsForceToUpdateFailed = error => ({
  type: UPDATE_IS_FORCE_TO_UPDATE_FAILED,
  error,
});

export const unsupportedVersionFailure = error => ({
  type: UNSUPPORTED_VERSION_FAILED,
  error,
});
