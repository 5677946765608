import classNames from "classnames";
import React from "react";
import ReactModal from "react-modal";
import styles from "./Modal.module.scss";

function Modal(props) {
  const { show, onDismiss, children, className, overlayClassName } = props;

  return (
    <ReactModal
      isOpen={show}
      closeTimeoutMS={200}
      onRequestClose={onDismiss}
      className={classNames(styles.modal, className)}
      overlayClassName={classNames(styles.overlay, overlayClassName)}
    >
      {children}
    </ReactModal>
  );
}

export default Modal;
