import {APIUtils} from 'utils';
import {END_POINTS} from '@shared/constants/apiUrl';

export function getListCardApi() {
  return APIUtils.get(global.apiLinks.GetCurrentListCard, {method: 'GET'});
}
export function getListCardFoxpayApi() {
  return APIUtils.get(global.apiLinks.GetCurrentListCardFoxPay, {
    method: 'GET',
  });
}
export function getListCardMemberApi() {
  return APIUtils.get(END_POINTS.GET_LIST_CARD_MEMBER, {
    method: 'GET',
  });
}
export function cancelLinkCardApi(cardID) {
  var requestData = {
    id: cardID,
  };
  return APIUtils.post(END_POINTS.CANCEL_TOKENIZATION_URL, {
    body: requestData,
  });
}
export function cancelLinkCardFoxpayApi(cardID) {
  return APIUtils.delete(
    END_POINTS.CANCEL_LINK_CARD_FOXPAY + cardID + '/deletetokenfoxpay',
  );
}
export function createTokenizationApi() {
  var requestData = {};
  return APIUtils.post(END_POINTS.CREATE_TOKENIZATION_URL, {
    body: requestData,
  });
}
export function checkStatusCardMemberApi(cardID) {
  return APIUtils.get(
    END_POINTS.CHECK_STATUS_CARD_MEMBER + cardID + '/IsRegistered',
  );
}
export function getInfoTenantApi(tenantID) {
  return APIUtils.get(END_POINTS.GET_INFO_TENANT + tenantID);
}
export function linkCardMemberApi(payload) {
  return APIUtils.post(END_POINTS.LINK_CARD_MEMBER, {
    body: payload,
  });
}
export function cancelLinkCardMemberApi(cardID) {
  return APIUtils.delete(END_POINTS.CANCEL_LINK_CARD_MEMBER + cardID);
}
export function updateInfoCardMemberApi(payload) {
  return APIUtils.put(
    END_POINTS.UPDATE_INFO_CARD_MEMBER +
      payload.cardID +
      '/Title/' +
      payload.title,
  );
}
