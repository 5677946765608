import { call, put, takeLatest, fork } from "redux-saga/effects";
import * as TYPE from "./constants";
import api from "../api/api";
import actions from "./actions";

const sagas = {
  // * GET_ORDER_DETAIL
  *[TYPE.GET_ORDER_DETAIL.request](action) {
    try {
      const data = yield call(
        api[TYPE.GET_ORDER_DETAIL.request],
        action.payload
      );

      if (data.status === 200) {
        yield put(actions.GET_ORDER_DETAIL.success(data.data));
      } else {
        yield put(actions.GET_ORDER_DETAIL.failure(data?.data));
      }
    } catch (error) {
      yield put(actions.GET_ORDER_DETAIL.failure(error?.data));
    }
  },

  // * GET_PAYMENT_METHODS
  *[TYPE.GET_PAYMENT_METHODS.request](action) {
    try {
      const data = yield call(
        api[TYPE.GET_PAYMENT_METHODS.request],
        action.payload
      );
      if (data.status === 200) {
        yield put(actions.GET_PAYMENT_METHODS.success(data.data));
      } else {
        yield put(actions.GET_PAYMENT_METHODS.failure(data?.data));
      }
    } catch (error) {
      yield put(actions.GET_PAYMENT_METHODS.failure(error?.data));
    }
  },
};

function* watchedSaga() {
  // * GET_ORDER_DETAIL
  yield takeLatest(
    TYPE.GET_ORDER_DETAIL.request,
    sagas[TYPE.GET_ORDER_DETAIL.request]
  );

  // * GET_PAYMENT_METHODS
  yield takeLatest(
    TYPE.GET_PAYMENT_METHODS.request,
    sagas[TYPE.GET_PAYMENT_METHODS.request]
  );
}

export default function* rootChild() {
  yield fork(watchedSaga);
}
