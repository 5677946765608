import classNames from "classnames";
import React from "react";
import "./IconButton.scss";

function IconButton(props) {
  const {
    children,
    icon = "",
    onClick,
    disabled = false,
    className,
    style,
  } = props;

  const handleOnClick = (e) => {
    if (onClick && !disabled) {
      onClick(e);
    }
  };

  return (
    <div
      className={classNames("icon-button", className)}
      disabled={disabled}
      onClick={handleOnClick}
      style={style}
    >
      {children || icon}

      <div className="icon-button__overlay" disabled={disabled} />
    </div>
  );
}

export default IconButton;
