import { addProductCart } from '@shared/redux/smartMenu/actions'
import { deleteProductCart } from '@shared/redux/smartMenu/actions'
import { isEqual } from 'lodash'

const checkDataReOrder = (dataReOrder, listProductNew, onFlowCompleted) => {
  let totalItem = 0
  if (typeof dataReOrder !== 'undefined' && dataReOrder.length > 0) {
    for (let i = 0; i < dataReOrder.length; i++) {
      let listToppingTmp = []
      let isProductError = false
      let sizeName = null

      let attrReOrder = dataReOrder[i]?.extraInfo?.attributes || []
      let toppingReOrder = dataReOrder[i]?.extraInfo?.toppings || []
      let toppingProduct = []

      for (let j = 0; j < listProductNew.length; j++) {
        let isExistProduct = false
        if (listProductNew[j]?.data?.sizes?.iv?.length > 0) {
          for (let k = 0; k < listProductNew[j].data.sizes.iv.length; k++) {
            if (
              listProductNew[j].data.sizes.iv[k]?.code ===
              dataReOrder[i]?.productCode
            ) {
              sizeName = 'Size ' + listProductNew[j].data.sizes.iv[k].name
              let attrProduct = []
              if (listProductNew[j]?.data?.attributes?.iv !== null) {
                attrProduct = listProductNew[j].data.attributes.iv
              }
              if (attrReOrder.length > 0) {
                if (attrProduct.length > 0) {
                  attrReOrder.forEach((itemAttrReOrder) => {
                    let isExistAttribute = false
                    attrProduct.find((itemAttrProduct) => {
                      if (
                        itemAttrProduct.name.trim() ===
                        itemAttrReOrder.name.trim()
                      ) {
                        itemAttrProduct.value.find((valueAttr) => {
                          if (
                            valueAttr.trim() === itemAttrReOrder.value.trim()
                          ) {
                            isExistAttribute = true
                          }
                        })
                        if (
                          itemAttrProduct.name.trim() !==
                          itemAttrReOrder.name.trim()
                        ) {
                          isExistAttribute = false
                        }
                      }
                    })
                    if (!isExistAttribute) {
                      isProductError = true
                    }
                  })
                } else {
                  isProductError = true
                }
              }

              if (
                (attrReOrder.length > 0 && attrProduct.length === 0) ||
                isProductError
              ) {
                break
              }

              if (listProductNew[j]?.data?.toppings?.iv !== null) {
                toppingProduct = listProductNew[j].data.toppings.iv
              }
              if (toppingReOrder.length > 0) {
                if (toppingProduct.length > 0) {
                  toppingReOrder.forEach((itemToppingReOrder) => {
                    let isExistTopping = false
                    toppingProduct.forEach((itemTopping) => {
                      if (
                        itemTopping?.flatData?.code === itemToppingReOrder?.code
                      ) {
                        isExistTopping = true
                        itemTopping.flatData.id = itemTopping.id
                        listToppingTmp.push(itemTopping.flatData)
                      }
                    })
                    if (!isExistTopping) {
                      isProductError = true
                    }
                  })
                }
              }

              if (
                (toppingReOrder.length > 0 && toppingProduct.length === 0) ||
                isProductError
              ) {
                break
              }

              if (!listProductNew[j].isOutOfStock && !isProductError) {
                var totalPriceToppingProduct = 0
                if (listToppingTmp && listToppingTmp.length > 0) {
                  listToppingTmp.forEach((element) => {
                    if (element?.price) {
                      totalPriceToppingProduct += parseInt(element.price)
                    }
                  })
                }
                var priceProduct = 0
                let productTemp = listProductNew.find((item) => {
                  return item.data?.code.iv === dataReOrder[i]?.productCode
                })

                priceProduct = productTemp?.data?.price?.iv
                  ? parseInt(productTemp.data.price.iv)
                  : parseInt(listProductNew[j].data.price.iv)

                var priceSize = 0
                if (sizeName !== null) {
                  priceSize = parseInt(
                    listProductNew[j].data.sizes.iv[k].extraPrice,
                  )
                }
                var totalPriceTemp =
                  (totalPriceToppingProduct + priceProduct + priceSize) *
                  dataReOrder[i].quantity
                if (dataReOrder[i].totalAmount !== totalPriceTemp) {
                  break
                }

                totalItem++
                store.dispatch(
                  addProductCart({
                    data: listProductNew[j].data,
                    dataInfoProduct: {
                      attributes: dataReOrder[i]?.extraInfo?.attributes || [],
                      productCode: dataReOrder[i]?.productCode,
                      productId: listProductNew[j]?.id,
                      quantity: dataReOrder[i]?.quantity,
                      toppings: listToppingTmp,
                      totalPrice: totalPriceTemp,
                      sizeName: sizeName,
                    },
                  }),
                )
                isExistProduct = true
                break
              }
            }
          }
          if (isExistProduct) {
            break
          }
        } else {
          if (
            listProductNew[j]?.data?.code?.iv === dataReOrder[i]?.productCode &&
            !listProductNew[j]?.isOutOfStock
          ) {
            toppingProduct = listProductNew[j]?.data?.toppings?.iv || []

            if (toppingReOrder.length > 0) {
              if (toppingProduct.length > 0) {
                toppingReOrder.forEach((itemToppingReOrder) => {
                  let isExistTopping = false
                  toppingProduct.forEach((itemTopping) => {
                    if (
                      itemTopping?.flatData?.code === itemToppingReOrder?.code
                    ) {
                      isExistTopping = true
                      itemTopping.flatData.id = itemTopping.id
                      listToppingTmp.push(itemTopping.flatData)
                    }
                  })
                  if (!isExistTopping) {
                    isProductError = true
                  }
                })
              }
            }

            if (
              (toppingReOrder.length > 0 && toppingProduct.length === 0) ||
              isProductError
            ) {
              break
            }

            let attrProduct = []
            if (
              listProductNew[j].data.attributes &&
              listProductNew[j].data.attributes.iv !== null
            ) {
              attrProduct = listProductNew[j].data.attributes.iv
            }
            if (attrReOrder.length > 0) {
              if (attrProduct.length > 0) {
                attrReOrder.forEach((itemAttrReOrder) => {
                  let isExistAttribute = false
                  attrProduct.find((itemAttrProduct) => {
                    if (
                      itemAttrProduct.name.trim() ===
                      itemAttrReOrder.name.trim()
                    ) {
                      itemAttrProduct.value.find((valueAttr) => {
                        if (valueAttr.trim() === itemAttrReOrder.value.trim()) {
                          isExistAttribute = true
                        }
                      })
                      if (
                        itemAttrProduct.name.trim() !==
                        itemAttrReOrder.name.trim()
                      ) {
                        isExistAttribute = false
                      }
                    }
                  })
                  if (!isExistAttribute) {
                    isProductError = true
                  }
                })
              } else {
                isProductError = true
              }
            }

            if (
              (attrReOrder.length > 0 && attrProduct.length === 0) ||
              isProductError
            ) {
              break
            }

            var totalPriceToppingProduct = 0
            if (listToppingTmp && listToppingTmp.length > 0) {
              listToppingTmp.forEach((element) => {
                if (element?.price) {
                  totalPriceToppingProduct += parseInt(element.price)
                }
              })
            }
            var priceProduct = 0
            let productTemp = listProductNew.find((item) => {
              return item.data?.code.iv === dataReOrder[i]?.productCode
            })
            if (productTemp?.data?.price?.iv) {
              priceProduct = parseInt(productTemp.data.price.iv)
            }
            var totalPriceTemp =
              (totalPriceToppingProduct + priceProduct) *
              dataReOrder[i].quantity

            if (dataReOrder[i].totalAmount !== totalPriceTemp) {
              break
            }

            totalItem++
            store.dispatch(
              addProductCart({
                data: listProductNew[j].data,
                dataInfoProduct: {
                  attributes: dataReOrder[i]?.extraInfo?.attributes || [],
                  productCode: dataReOrder[i].productCode,
                  productId: listProductNew[j].id,
                  quantity: dataReOrder[i].quantity,
                  toppings: listToppingTmp,
                  totalPrice: totalPriceTemp,
                },
              }),
            )
            break
          }
        }
      }
    }
  }
  onFlowCompleted(totalItem)
}

const checkProductCarts = (dataCart, listProductNew, onFlowCompleted) => {
  let totalItem = 0
  let sizeName = null
  if (typeof dataCart !== 'undefined' && dataCart?.length > 0) {
    for (let i = 0; i < dataCart.length; i++) {
      let listToppingTmp = []
      let isProductError = false

      let attrReOrder = dataCart[i].dataInfoProduct.attributes || []
      let toppingReOrder = dataCart[i].dataInfoProduct.toppings || []
      let toppingProduct = []

      for (let j = 0; j < listProductNew.length; j++) {
        let isExistProduct = false
        if (listProductNew[j].data.sizes.iv.length > 0) {
          for (let k = 0; k < listProductNew[j].data.sizes.iv.length; k++) {
            if (
              listProductNew[j].data.sizes.iv[k]?.code ===
              dataCart[i].dataInfoProduct?.productCode
            ) {
              sizeName = 'Size ' + listProductNew[j].data.sizes.iv[k].name
              let attrProduct = []
              if (listProductNew[j]?.data?.attributes?.iv !== null) {
                attrProduct = listProductNew[j].data.attributes.iv
              }
              if (attrReOrder.length > 0) {
                if (attrProduct.length > 0) {
                  attrReOrder.forEach((itemAttrReOrder) => {
                    let isExistAttribute = false
                    attrProduct.find((itemAttrProduct) => {
                      if (
                        itemAttrProduct.name.trim() ===
                        itemAttrReOrder.name.trim()
                      ) {
                        itemAttrProduct.value.find((valueAttr) => {
                          if (
                            valueAttr.trim() === itemAttrReOrder.value.trim()
                          ) {
                            isExistAttribute = true
                          }
                        })
                        if (
                          itemAttrProduct.name.trim() !==
                          itemAttrReOrder.name.trim()
                        ) {
                          isExistAttribute = false
                        }
                      }
                    })
                    if (!isExistAttribute) {
                      isProductError = true
                    }
                  })
                } else {
                  isProductError = true
                }
              }

              if (listProductNew[j]?.data?.toppings?.iv !== null) {
                toppingProduct = listProductNew[j].data.toppings.iv
              }
              if (toppingReOrder.length > 0) {
                if (toppingProduct.length > 0) {
                  toppingReOrder.forEach((itemToppingReOrder) => {
                    let isExistTopping = false
                    toppingProduct.forEach((itemTopping) => {
                      if (
                        itemTopping.flatData?.code === itemToppingReOrder?.code
                      ) {
                        isExistTopping = true
                        itemTopping.flatData.id = itemTopping.id
                        listToppingTmp.push(itemTopping.flatData)
                      }
                    })
                    if (!isExistTopping) {
                      isProductError = true
                    }
                  })
                }
              }

              if (!listProductNew[j].isOutOfStock && !isProductError) {
                var totalPriceToppingProduct = 0
                if (listToppingTmp && listToppingTmp.length > 0) {
                  listToppingTmp.forEach((element) => {
                    if (element?.price) {
                      totalPriceToppingProduct += parseInt(element.price)
                    }
                  })
                }
                var priceProduct = 0
                let productTemp = listProductNew.find((item) => {
                  return (
                    item.data?.code?.iv ===
                    dataCart[i].dataInfoProduct?.productCode
                  )
                })

                priceProduct = productTemp?.data?.price?.iv
                  ? parseInt(productTemp.data.price.iv)
                  : parseInt(listProductNew[j].data.price.iv)

                var priceSize = 0
                if (sizeName !== null) {
                  priceSize = parseInt(
                    listProductNew[j].data.sizes.iv[k].extraPrice,
                  )
                }
                var totalPriceTemp =
                  (totalPriceToppingProduct + priceProduct + priceSize) *
                  dataCart[i].dataInfoProduct.quantity
                if (dataCart[i].dataInfoProduct.totalPrice !== totalPriceTemp) {
                  isProductError = true
                }

                if (!isProductError) {
                  totalItem++
                } else {
                  //clear product
                  store.dispatch(
                    deleteProductCart(
                      dataCart[i].dataInfoProduct.productCode +
                        dataCart[i].dataInfoProduct.index,
                    ),
                  )
                }
              }
              isExistProduct = true
            }
            if (isExistProduct) {
              break
            }
          }
        } else {
          if (
            listProductNew[j].data?.code?.iv ===
            dataCart[i].dataInfoProduct?.productCode
          ) {
            if (listProductNew[j].isOutOfStock) {
              isProductError = true
            }
            toppingProduct =
              listProductNew[j].data.toppings &&
              listProductNew[j].data.toppings.iv
                ? listProductNew[j].data.toppings.iv
                : []

            if (toppingReOrder.length > 0) {
              if (toppingProduct.length > 0) {
                toppingReOrder.forEach((itemToppingReOrder) => {
                  let isExistTopping = false
                  toppingProduct.forEach((itemTopping) => {
                    if (
                      itemTopping?.flatData?.code === itemToppingReOrder?.code
                    ) {
                      isExistTopping = true
                      itemTopping.flatData.id = itemTopping.id
                      listToppingTmp.push(itemTopping.flatData)
                    }
                  })
                  if (!isExistTopping) {
                    isProductError = true
                  }
                })
              }
            }

            let attrProduct = []
            if (
              listProductNew[j].data.attributes &&
              listProductNew[j].data.attributes.iv !== null
            ) {
              attrProduct = listProductNew[j].data.attributes.iv
            }
            if (attrReOrder.length > 0) {
              if (attrProduct.length > 0) {
                attrReOrder.forEach((itemAttrReOrder) => {
                  let isExistAttribute = false
                  attrProduct.find((itemAttrProduct) => {
                    if (
                      itemAttrProduct.name.trim() ===
                      itemAttrReOrder.name.trim()
                    ) {
                      itemAttrProduct.value.find((valueAttr) => {
                        if (valueAttr.trim() === itemAttrReOrder.value.trim()) {
                          isExistAttribute = true
                        }
                      })
                      if (
                        itemAttrProduct.name.trim() !==
                        itemAttrReOrder.name.trim()
                      ) {
                        isExistAttribute = false
                      }
                    }
                  })
                  if (!isExistAttribute) {
                    isProductError = true
                  }
                })
              } else {
                isProductError = true
              }
            }

            var totalPriceToppingProduct = 0
            if (listToppingTmp && listToppingTmp.length > 0) {
              listToppingTmp.forEach((element) => {
                if (element?.price) {
                  totalPriceToppingProduct += parseInt(element.price)
                }
              })
            }
            var priceProduct = 0
            let productTemp = listProductNew.find((item) => {
              return (
                item.data?.code.iv === dataCart[i].dataInfoProduct?.productCode
              )
            })
            if (productTemp?.data?.price?.iv) {
              priceProduct = parseInt(productTemp.data.price.iv)
            }
            var totalPriceTemp =
              (totalPriceToppingProduct + priceProduct) *
              dataCart[i].dataInfoProduct.quantity

            if (dataCart[i].dataInfoProduct.totalPrice !== totalPriceTemp) {
              isProductError = true
            }

            if (!isProductError) {
              totalItem++
            } else {
              //clear product
              store.dispatch(
                deleteProductCart(
                  dataCart[i].dataInfoProduct.productCode +
                    dataCart[i].dataInfoProduct.index,
                ),
              )
            }
            isExistProduct = true
          }
        }
        if (isExistProduct) {
          break
        }
      }
    }
  }
  onFlowCompleted(totalItem)
}

const updateProductCart = (listData, payload, callBack) => {
  if (listData.length === 0) {
    callBack('add', payload)
    return
  }
  let listProductTemp = listData
  for (let index = 0; index < listData.length; index++) {
    let element = listData[index]
    let elmDataInfoProductTemp = element.dataInfoProduct
    let payloadDataInfoProductTemp = payload.dataInfoProduct
    if (isEqual(element.data, payload.data)) {
      let indexElm = elmDataInfoProductTemp.index,
        quantityElm = elmDataInfoProductTemp.quantity,
        totalPriceElm = elmDataInfoProductTemp.totalPrice

      let indexPayload = payloadDataInfoProductTemp.index,
        quantityPayload = payloadDataInfoProductTemp.quantity,
        totalPricePayload = payloadDataInfoProductTemp.totalPrice

      elmDataInfoProductTemp.index = 0
      elmDataInfoProductTemp.quantity = 0
      elmDataInfoProductTemp.totalPrice = 0

      payloadDataInfoProductTemp.index = 0
      payloadDataInfoProductTemp.quantity = 0
      payloadDataInfoProductTemp.totalPrice = 0

      if (isEqual(elmDataInfoProductTemp, payloadDataInfoProductTemp)) {
        element.dataInfoProduct.index = indexElm
        element.dataInfoProduct.quantity = quantityElm + quantityPayload
        element.dataInfoProduct.totalPrice = totalPriceElm + totalPricePayload
        // update product
        listProductTemp[index] = element
        callBack('update', listProductTemp)
        break
      }
      element.dataInfoProduct.index = indexElm
      element.dataInfoProduct.quantity = quantityElm
      element.dataInfoProduct.totalPrice = totalPriceElm

      payload.dataInfoProduct.index = indexPayload
      payload.dataInfoProduct.quantity = quantityPayload
      payload.dataInfoProduct.totalPrice = totalPricePayload
    }
    if (listData.length - 1 === index) {
      // add new product
      callBack('add', payload)
      break
    }
  }
}

const checkRepeatProductDataCart = (dataCart) => {
  let masterData = []
  if (dataCart.length <= 1) {
    return dataCart
  }
  for (let i = 0; i < dataCart.length; i++) {
    let totalExist = 0
    const elm1 = dataCart[i]
    const elmdataInfoProduct1 = dataCart[i].dataInfoProduct
    for (let j = 0; j < dataCart.length; j++) {
      const elm2 = dataCart[j]
      const elmdataInfoProduct2 = dataCart[j].dataInfoProduct
      if (isEqual(elm1.data, elm2.data)) {
        if (
          isEqual(
            elmdataInfoProduct1.attributes,
            elmdataInfoProduct2.attributes,
          ) &&
          isEqual(elmdataInfoProduct1.toppings, elmdataInfoProduct2.toppings) &&
          elmdataInfoProduct1.productCode === elmdataInfoProduct2.productCode &&
          elmdataInfoProduct1.productId === elmdataInfoProduct2.productId &&
          elmdataInfoProduct1.sizeName === elmdataInfoProduct2.sizeName
        ) {
          totalExist += 1
          if (totalExist === 1) {
            masterData.push(elm1)
            // dataCart.splice(j, 1);
          } else {
            //update product
            masterData[masterData.length - 1].dataInfoProduct.quantity +=
              elmdataInfoProduct2.quantity
            masterData[masterData.length - 1].dataInfoProduct.totalPrice +=
              elmdataInfoProduct2.totalPrice
            dataCart.splice(j, 1)
          }
        }
      }
    }
  }
  return masterData
}

export {
  checkDataReOrder,
  checkProductCarts,
  updateProductCart,
  checkRepeatProductDataCart,
}
