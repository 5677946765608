import { call, put, takeLatest, fork } from "redux-saga/effects";
import { toast } from "react-toastify";
import MESSAGES from "constants/vi";
import api from "../api/api";
import * as TYPE from "./constants";
import actions from "./actions";

const MESSAGE_KEY = "PaymentHub"; // key của object chứa message
const UNKNOWN = "unknown";
const toastOptions = {
  position: "top-center",
  autoClose: 7000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

const showToastMessage = ({ keyword, customOptions = {} }) => {
  if (!keyword) return;

  const errMsgToToast =
    MESSAGES?.[MESSAGE_KEY]?.error?.[keyword] ||
    MESSAGES?.[MESSAGE_KEY]?.error?.[UNKNOWN];

  toast.error(errMsgToToast, {
    toastId: `${keyword}`,
    ...toastOptions,
    ...customOptions,
  });
};

const sagas = {
  // * GET_PAYMENT_METHODS
  *[TYPE.GET_PAYMENT_METHODS.request](action) {
    try {
      const data = yield call(
        api[TYPE.GET_PAYMENT_METHODS.request],
        action.payload
      );
      if (data.status === 200) {
        yield put(actions.GET_PAYMENT_METHODS.success(data.data));
      } else {
        yield put(actions.GET_PAYMENT_METHODS.failure(data?.data));
      }
    } catch (error) {
      showToastMessage({ keyword: error?.data?.message });
      yield put(actions.GET_PAYMENT_METHODS.failure(error?.data));
    }
  },

  // * GET_PAYMENT_CARDS
  *[TYPE.GET_PAYMENT_CARDS.request](action) {
    try {
      const data = yield call(
        api[TYPE.GET_PAYMENT_CARDS.request],
        action.payload
      );
      if (data.status === 200) {
        yield put(actions.GET_PAYMENT_CARDS.success(data.data));
      } else {
        yield put(actions.GET_PAYMENT_CARDS.failure(data?.data));
      }
    } catch (error) {
      showToastMessage({ keyword: error?.data?.message });
      yield put(actions.GET_PAYMENT_CARDS.failure(error?.data));
    }
  },

  // * GET_UTOP_WALLET_INFO
  *[TYPE.GET_UTOP_WALLET_INFO.request](action) {
    try {
      const data = yield call(
        api[TYPE.GET_UTOP_WALLET_INFO.request],
        action.payload
      );
      if (data.status === 200) {
        yield put(actions.GET_UTOP_WALLET_INFO.success(data.data));
      } else {
        yield put(actions.GET_UTOP_WALLET_INFO.failure(data?.data));
      }
    } catch (error) {
      showToastMessage({ keyword: error?.data?.message });
      yield put(actions.CREATE_PAYMENT.failure(error?.data));
      yield put(actions.GET_UTOP_WALLET_INFO.failure(error?.data));
    }
  },

  // * CREATE_PAYMENT
  *[TYPE.CREATE_PAYMENT.request](action) {
    const { onSuccess, orderCode, paymentMethod, goToOrderDetailPage } =
      action.payload;

    try {
      const response = yield call(api[TYPE.CREATE_PAYMENT.request], {
        orderCode,
        paymentMethod,
      });

      if (response.status === 200) {
        if (paymentMethod === "utop") {
          yield put(actions.TRANSACTION_PAY.request(response.data));
        }

        yield call(onSuccess); // fire event purchase to GA
        yield put(actions.CREATE_PAYMENT.success(response.data));
      } else {
        yield put(actions.CREATE_PAYMENT.failure(response.data));
      }
    } catch (error) {
      yield put(actions.CREATE_PAYMENT.failure(error?.data));

      if (error?.data?.message === "InvalidStatus") {
        toast.info(MESSAGES[MESSAGE_KEY].error.InvalidStatus, {
          ...toastOptions,
          onClick: goToOrderDetailPage,
          onClose: goToOrderDetailPage,
        });
        return;
      }

      showToastMessage({ keyword: error?.data?.message });
    }
  },

  // * TRANSACTION_PAY
  *[TYPE.TRANSACTION_PAY.request]({ payload }) {
    try {
      const { searchParams } = new URL(payload.url);

      if (!searchParams) {
        return;
      }

      const data = yield call(api[TYPE.TRANSACTION_PAY.request], {
        orderCode: searchParams.get("OrderCode"),
        // signature: searchParams.get("Signature"), // không dùng vì khi qua searchParams thì nó đã auto decode, không keep được những kí tự như dấu "+", dẫn tới sai data khi call api
        signature: payload.url.split("&Signature=")[1],
        timestamp: searchParams.get("TimeStick"),
        amount: searchParams.get("Amount"),
      });

      if (data.status === 200) {
        yield put(actions.TRANSACTION_PAY.success(data.data));
      } else {
        yield put(actions.TRANSACTION_PAY.failure(data?.data));
      }
    } catch (error) {
      showToastMessage({ keyword: error?.data?.message });
      yield put(actions.CREATE_PAYMENT.failure(error?.data));
      yield put(actions.TRANSACTION_PAY.failure(error?.data));
    }

    // const params = getJsonFromUrl(payload.url.split("?")[1]);
    // try {
    //   const data = yield call(api[TYPE.TRANSACTION_PAY.request], {
    //     orderCode: params.rderCode,
    //     signature: payload.url.split("&Signature=")[1],
    //     timestamp: params.TimeStick,
    //     amount: params.Amount,
    //   });
    //   if (data.status === 200) {
    //     yield put(actions.TRANSACTION_PAY.success(data.data));
    //   } else {
    //     yield put(actions.TRANSACTION_PAY.failure(data?.data));
    //   }
    // } catch (error) {
    //   yield put(actions.TRANSACTION_PAY.failure(error?.data));
    // }
  },

  // * GET_ORDER_DETAIL
  *[TYPE.GET_ORDER_DETAIL.request](action) {
    const { orderCode } = action.payload;

    try {
      const data = yield call(api[TYPE.GET_ORDER_DETAIL.request], {
        orderCode,
      });

      if (data.status === 200) {
        yield put(actions.GET_ORDER_DETAIL.success(data.data));
      } else {
        yield put(actions.GET_ORDER_DETAIL.failure(data?.data));
      }
    } catch (error) {
      showToastMessage({ keyword: error?.data?.message });
      yield put(actions.GET_ORDER_DETAIL.failure(error?.data));
    }
  },
};

function* watchedSaga() {
  // * GET_ORDER_DETAIL
  yield takeLatest(
    TYPE.GET_ORDER_DETAIL.request,
    sagas[TYPE.GET_ORDER_DETAIL.request]
  );

  // * GET_PAYMENT_METHODS
  yield takeLatest(
    TYPE.GET_PAYMENT_METHODS.request,
    sagas[TYPE.GET_PAYMENT_METHODS.request]
  );

  // * GET_PAYMENT_CARDS
  yield takeLatest(
    TYPE.GET_PAYMENT_CARDS.request,
    sagas[TYPE.GET_PAYMENT_CARDS.request]
  );

  // * GET_UTOP_WALLET_INFO
  yield takeLatest(
    TYPE.GET_UTOP_WALLET_INFO.request,
    sagas[TYPE.GET_UTOP_WALLET_INFO.request]
  );

  // * CREATE_PAYMENT
  yield takeLatest(
    TYPE.CREATE_PAYMENT.request,
    sagas[TYPE.CREATE_PAYMENT.request]
  );

  // * TRANSACTION_PAY
  yield takeLatest(
    TYPE.TRANSACTION_PAY.request,
    sagas[TYPE.TRANSACTION_PAY.request]
  );
}

export default function* rootChild() {
  yield fork(watchedSaga);
}
