import { genActionTypes } from "utils/actionGenerator";

export const TYPE = "ApplicableStores";

export const ACTION_KEYS = {
  GET_LIST_STORE_OF_MERCHANT: "GET_LIST_STORE_OF_MERCHANT",
  GET_LIST_FEED: "GET_LIST_FEED",
};

// * GET_LIST_FEED
export const GET_LIST_FEED = genActionTypes(
  `${TYPE}_${ACTION_KEYS.GET_LIST_FEED}`
);

// * GET_LIST_STORE_OF_MERCHANT
export const GET_LIST_STORE_OF_MERCHANT = genActionTypes(
  `${TYPE}_${ACTION_KEYS.GET_LIST_STORE_OF_MERCHANT}`
);
