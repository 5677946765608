export const getListCouponForVoucherSelector = state =>
  state.couponForVoucher.listCouponForVoucher.data;
export const getCouponDetailForVoucherSelector = state =>
  state.couponForVoucher.couponDetailForVoucher.data
    ? state.couponForVoucher.couponDetailForVoucher
    : {};

export const searchCouponDetailForVoucherSelector = state => {
  let arr = [];
  state.couponForVoucher.searchCouponDetailForVoucher.data
    ? arr.push(state.couponForVoucher.searchCouponDetailForVoucher.data)
    : [];
  return arr;
};
export const getAvailabelCouponSelector = state => {
  return state.couponForVoucher.availableCoupon
    ? state.couponForVoucher.availableCoupon.data
    : [];
};
export const getCouponSelectedSelector = state => {
  return state.couponForVoucher.dataCouponSelected
    ? state.couponForVoucher.dataCouponSelected
    : {};
};
