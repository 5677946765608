import Location from "../img/location.png";
// import Close from "../img/close.png";
import LocationDistance from "../img/locationDistance.png";
import ImgKFC from "../img/imgKFC.png";
import ImgPhucLong from "../img/imgPhucLong.png";
import KFC from "../img/kfc.png";
import LogoHot from "../img/logoHot.png";
import LogoSale from "../img/logoSale.png";
import PhucLong from "../img/phucLong.png";
import Star from "../img/star.png";
import StarActive from "../img/star-active.png";
import Chart from "../img/chart.png";
import Fire from "../img/fire.png";
import voucherImg from "../img/voucherImg.png";
import All from "../img/all.png";
import Megaphone from "../img/megaphone.png";
import Cocktail from "../img/cocktail.png";
import Chef from "../img/chef.png";
import Shopping from "../img/shopping.png";
import Coupon from "../img/coupon.png";
import Makeup from "../img/makeup.png";
import Video from "../img/video.png";
import Savings from "../img/savings.png";
import DetailVoucher from "../img/detailVoucher.png";
import Shape from "../img/shape.png";
import mapIconActive from "../img/mapColor.png";
import arrowRight from "../img/arrow-to-right.png";
import arrowLeft from "../img/arrow-to-left.png";
import arrowDown from "../img/arrow-to-down.png";
import searchIcon from "../img/searchIcon.png";
import closeFilterIcon from "../img/closeFilterIcon.png";
import chooseFood from "../img/order-food.png";
import waitForConfirmation from "../img/wait-for-confirmation.png";
import waitForReceive from "../img/wait-for-receive.png";
import foodPlaceholder from "../img/food-placeholder.png";
import foodItemSample from "../img/food-item-sample.png";
import cardImageResult1 from "../img/cardImageResult1.png";
import cardImageResult2 from "../img/cardImageResult2.png";
import cardImageResult3 from "../img/cardImageResult3.png";
import oval from "../img/oval.png";
import line from "../img/line.png";
import starIcon from "../img/starIcon.png";
import deliveryIcon from "../img/deliveryIcon.png";
import clockYellowIcon from "../img/clockYellowIcon.png";
import voucherIcon from "../img/voucherIcon.png";
import callIcon from "../img/callIcon.png";
import searchItemIcon from "../img/searchItemIcon.png";
import tickIcon from "../img/tickIcon.png";
import storeLogoIcon from "../img/storeLogoIcon.png";
import clockIcon from "../img/clockIcon.png";
import grayClockIcon from "../img/grayClockIcon.png";
import tick from "../img/tick.png";
import SearchNone from "../img/searchNone.png";
import XNone from "../img/xNone.png";
import PopularKeyWord from "../img/popularKeyWord.png";
import VcCrab from "../img/v-crab.png";
import activedistrict from "../img/active-district.svg";
import ovalIcon from "../img/ovalIcon.png";
import voucherYellowIcon from "../img/voucherYellowIcon.png";
import magnifyingGlass from "../img/magnifyingGlass.png";
import cardLogo from "../img/cardLogo.png";
import fptLogo from "../img/fptLogo.png";
import utopcard from "../img/utop-card.svg";
import fptcard from "../img/fpt-card.svg";
import visabank from "../img/visa-bank.svg";
import morebankcard from "../img/more-bank-card.svg";
import imgexpire from "../img/img-expire.svg";
import closeHome from "../img/close-home.svg";
import locationHome from "../img/location-home.svg";
import arrowmoredetail from "../img/arrow-more-detail.svg";
import arrowmorehome from "../img/arrow-more-home.svg";
import arrowToRight from "../img/arrowToRight.png";
import locationstore from "../img/location-store.svg";
import phonestore from "../img/phone-store.svg";
import liststorelocation from "../img/liststore-apply-location.svg";
import voucher from "../img/voucher.svg";
import voucherBg from "../img/voucherBg.png";

import Imgthanks from "../img/imgthanks.png";
import Backgroundthanks from "../img/backgroundthanks.png";

const Images = {
  Imgthanks,
  Backgroundthanks,
  Location,
  // Close,
  LocationDistance,
  ImgKFC,
  ImgPhucLong,
  KFC,
  LogoHot,
  LogoSale,
  PhucLong,
  Star,
  StarActive,
  Chart,
  Fire,
  voucherImg,
  All,
  Megaphone,
  Cocktail,
  Chef,
  Shopping,
  Coupon,
  Makeup,
  Video,
  Savings,
  DetailVoucher,
  Shape,
  mapIconActive,
  arrowRight,
  arrowLeft,
  arrowDown,
  searchIcon,
  closeFilterIcon,
  cardImageResult1,
  cardImageResult2,
  cardImageResult3,
  oval,
  line,
  starIcon,
  deliveryIcon,
  clockYellowIcon,
  voucherIcon,
  callIcon,
  searchItemIcon,
  chooseFood,
  waitForConfirmation,
  waitForReceive,
  foodPlaceholder,
  foodItemSample,
  SearchNone,
  XNone,
  PopularKeyWord,
  VcCrab,
  activedistrict,
  tickIcon,
  storeLogoIcon,
  clockIcon,
  grayClockIcon,
  tick,
  ovalIcon,
  voucherYellowIcon,
  magnifyingGlass,
  cardLogo,
  fptLogo,
  utopcard,
  fptcard,
  visabank,
  morebankcard,
  imgexpire,
  closeHome,
  locationHome,
  arrowmoredetail,
  arrowmorehome,
  arrowToRight,
  locationstore,
  phonestore,
  liststorelocation,
  voucher,
  voucherBg,
};

export default Images;
