import { put, takeLatest, fork, call, select } from "redux-saga/effects";
import {
  REMOVE_ADDRESS_REQUEST,
  GET_LIST_ADDRESS_BOOK_REQUEST,
  SET_DEFAULT_ADDRESS_REQUEST,
} from "./constants";
import {
  getAddressBookListFailed,
  removeAddressFailed,
  setDefaultAddressFailed,
  setDefaultAddressSuccess,
  getAddressBookListSuccess,
  removeAddressSuccess,
} from "./actions";
import {
  getAddressBookListApi,
  removeAddressApi,
  setDefaultAddressApi,
} from "@shared/services/api/addressBook";
import { callApi } from "helpers/callApi";

export function* getAddressBooksListSaga(obj) {
  const { onSuccess, onError } = obj;
  try {
    const res = yield callApi(getAddressBookListApi);
    yield put(getAddressBookListSuccess(res));
    if (typeof onSuccess === "function") {
      onSuccess(res);
    }
  } catch (err) {
    if (typeof onError === "function") {
      onError(err);
    }
    yield put(getAddressBookListFailed(err));
  }
}

export function* removeAddressSaga(obj) {
  const { payload, onSuccess, onError } = obj;
  try {
    const res = yield callApi(removeAddressApi, payload);
    yield put(removeAddressSuccess(res));
    if (typeof onSuccess === "function") {
      onSuccess(res);
    }
  } catch (err) {
    if (typeof onError === "function") {
      onError(err);
    }
    yield put(removeAddressFailed(err));
  }
}

export function* setDefaultAddressSaga(obj) {
  const { payload, onSuccess, onError } = obj;
  try {
    const res = yield callApi(setDefaultAddressApi, payload);
    yield put(setDefaultAddressSuccess(res));
    if (typeof onSuccess === "function") {
      onSuccess(res);
    }
  } catch (err) {
    if (typeof onError === "function") {
      onError(err);
    }
    yield put(setDefaultAddressFailed(err));
  }
}

function* watchProduct() {
  yield takeLatest(GET_LIST_ADDRESS_BOOK_REQUEST, getAddressBooksListSaga);
  yield takeLatest(REMOVE_ADDRESS_REQUEST, removeAddressSaga);
  yield takeLatest(SET_DEFAULT_ADDRESS_REQUEST, setDefaultAddressSaga);
}

export default function* rootChild() {
  yield fork(watchProduct);
}
