import React from "react";
import SVG from "assets/svg";
import arrowBack from "assets/icons/arrow-back.svg";

import "./storeHeader.scss";

function StoreHeader(props) {
  const { storeName, goBack, goToSearch } = props;

  // const { categoriesList } = useSelector((state) => state[TYPE]);

  return (
    <>
      <div className="store-header_store_detail">
        <button
          type="button"
          onClick={goBack}
          className="button__effect flex-shrink-0 _border-none _outline-none"
          style={{ background: "transparent" }}
        >
          <img src={arrowBack} alt="arrow-back" width={8} height={15} />
        </button>

        <div className="text-truncate px-2 _fw-600 _fs-16px color-chinese_black name-text">
          {storeName.toUpperCase()}
        </div>
        <div
          className="search-button"
          onClick={goToSearch}
          style={{ width: "25px", height: "25px" }}
        >
          <img
            src={SVG.IconSearchHome}
            alt="loupe black icon"
            width={14}
            height={14}
          />
        </div>
      </div>
    </>
  );
}

export default StoreHeader;
