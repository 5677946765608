import {
  MY_VOUCHER_CODE_REQUEST,
  MY_VOUCHER_CODE_SUCCESS,
  MY_VOUCHER_CODE_FAILED,
} from './constants';

export const myVoucherCodeRequest = (payload, onSuccess) => ({
  type: MY_VOUCHER_CODE_REQUEST,
  payload,
  onSuccess,
});

export const myVoucherCodeSuccess = payload => ({
  type: MY_VOUCHER_CODE_SUCCESS,
  payload,
});

export const myVoucherCodeFailed = payload => ({
  type: MY_VOUCHER_CODE_FAILED,
  payload,
});
