import { callApi } from "helpers/callApi";
import { fork, put, takeEvery } from "redux-saga/effects";
import {
  getListImagesWithEditionIDApi,
  getSasTokenApi,
} from "@shared/services/api/listImagesNews";
import { getListImagesTypeFailure, getListImagesTypeSuccess } from "./actions";
import { GET_LIST_IMAGES_NEWS } from "./constants";

export function* getListImagesNewsSaga(action) {
  const { onSuccess, skip, editionId, take, onFailed } = action;
  try {
    const res = yield callApi(getListImagesWithEditionIDApi, {
      skip,
      take,
      editionId,
    });
    const sasToken = yield callApi(getSasTokenApi);
    const listImageNews = res?.data?.images?.map((element) => {
      return { ...element, sasToken: sasToken?.data?.access_token || "" };
    });
    onSuccess?.({ ...res?.data, images: listImageNews });
    yield put(getListImagesTypeSuccess());
  } catch (error) {
    onFailed?.();
    yield put(getListImagesTypeFailure(error));
  }
}

function* watchListImagesNews() {
  yield takeEvery(GET_LIST_IMAGES_NEWS.HANDLER, getListImagesNewsSaga);
}

export default function* rootChild() {
  yield fork(watchListImagesNews);
}
