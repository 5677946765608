import {
  GET_STATUS_ORDER_DETAIL,
  PUSH_CANCEL_ORDER,
  GET_STATUS_ORDER_DETAIL_NEWS,
  PUSH_CANCEL_ORDER_NEWS,
  CREATE_ORDER_NEWSPAPER_NEWS,
} from './constants';

export const getStatusOrderDetailHandle = (orderId, onSuccess, onFailed) => ({
  type: GET_STATUS_ORDER_DETAIL.HANDLER,
  orderId,
  onSuccess,
  onFailed,
});

export const getStatusOrderDetailSuccess = onSuccess => ({
  type: GET_STATUS_ORDER_DETAIL.SUCCESS,
  onSuccess,
});

export const getStatusOrderDetailFailed = onFailed => ({
  type: GET_STATUS_ORDER_DETAIL.FAILURE,
  onFailed,
});

export const getStatusOrderDetailNewsHandle = (
  orderId,
  onSuccess,
  onFailed,
) => ({
  type: GET_STATUS_ORDER_DETAIL_NEWS.HANDLER,
  orderId,
  onSuccess,
  onFailed,
});

export const getStatusOrderDetailNewsSuccess = onSuccess => ({
  type: GET_STATUS_ORDER_DETAIL_NEWS.SUCCESS,
  onSuccess,
});

export const getStatusOrderDetailNewsFailed = onFailed => ({
  type: GET_STATUS_ORDER_DETAIL_NEWS.FAILURE,
  onFailed,
});

export const pushCancelOrderNewsHandle = (orderId, onSuccess, onFailed) => ({
  type: PUSH_CANCEL_ORDER_NEWS.HANDLER,
  orderId,
  onSuccess,
  onFailed,
});

export const pushCancelOrderNewsSuccess = onSuccess => ({
  type: PUSH_CANCEL_ORDER_NEWS.SUCCESS,
  onSuccess,
});

export const pushCancelOrderNewsFailed = onFailed => ({
  type: PUSH_CANCEL_ORDER_NEWS.FAILURE,
  onFailed,
});

export const pushCancelOrderHandle = (orderId, onSuccess, onFailed) => ({
  type: PUSH_CANCEL_ORDER.HANDLER,
  orderId,
  onSuccess,
  onFailed,
});

export const pushCancelOrderSuccess = onSuccess => ({
  type: PUSH_CANCEL_ORDER.SUCCESS,
  onSuccess,
});
export const pushCancelOrderFailed = onFailed => ({
  type: PUSH_CANCEL_ORDER.FAILURE,
  onFailed,
});

export const createOrderNewspaperNewsHandle = (
  newspaperInfo,
  onSuccess,
  onFailed,
) => ({
  type: CREATE_ORDER_NEWSPAPER_NEWS.HANDLER,
  newspaperInfo,
  onSuccess,
  onFailed,
});

export const createOrderNewspaperNewsSuccess = onSuccess => ({
  type: CREATE_ORDER_NEWSPAPER_NEWS.SUCCESS,
  onSuccess,
});

export const createOrderNewspaperNewsFailed = onFailed => ({
  type: CREATE_ORDER_NEWSPAPER_NEWS.FAILURE,
  onFailed,
});
