import { useEffect, useRef, useState } from "react";

const SHOW_BUTTON_PREV = -1;
const SHOW_BUTTON_NEXT = 1;
const SHOW_BOTH_BUTTONS = 2;
const SHOW_NO_BUTTON = 0;
const TIME_TO_WAIT_SCROLL_FINISH = 100;
const TIME_TO_WAIT_FOR_DRAW_UI_ON_MOUNT = 1000;

const useScrollHorizontallyWithButtons = (props) => {
  const { containerRef, items } = props;
  const scrollTimeoutRef = useRef();
  const [arrowButtonsState, setArrowButtonsState] = useState(SHOW_NO_BUTTON);

  const handleArrowButtonsAppearance = (tagsContainerElement) => {
    if (!tagsContainerElement) return;

    if (!isAbleToScroll(tagsContainerElement)) {
      setArrowButtonsState(SHOW_NO_BUTTON);
      return;
    }

    if (isAtLeftEdge(tagsContainerElement)) {
      setArrowButtonsState(SHOW_BUTTON_NEXT);
      return;
    }

    if (isAtRightEdge(tagsContainerElement)) {
      setArrowButtonsState(SHOW_BUTTON_PREV);
      return;
    }

    setArrowButtonsState(SHOW_BOTH_BUTTONS);
  };

  // scrollHorizontal to plus a shift which is a param to slide a distance = shift
  const scrollHorizontal = (shift) => {
    const tagsContainerElement = containerRef?.current;

    if (!tagsContainerElement) return;

    if (willAtLeftEdge(tagsContainerElement, shift)) {
      setArrowButtonsState(SHOW_BUTTON_NEXT);
    }

    if (willAtRightEdge(tagsContainerElement, shift)) {
      setArrowButtonsState(SHOW_BUTTON_PREV);
    }

    tagsContainerElement.scrollLeft += shift;
  };

  const handleTagsContainerScroll = () => {
    if (scrollTimeoutRef.current) {
      clearTimeout(scrollTimeoutRef.current);
    }

    scrollTimeoutRef.current = setTimeout(() => {
      handleArrowButtonsAppearance(containerRef?.current);
    }, TIME_TO_WAIT_SCROLL_FINISH);
  };

  useEffect(() => {
    if (!items?.length > 0) return;

    // wait for react draw UI done
    const decideToShowArrowButtons = setTimeout(() => {
      handleArrowButtonsAppearance(containerRef?.current);
    }, TIME_TO_WAIT_FOR_DRAW_UI_ON_MOUNT);

    return () => {
      clearTimeout(decideToShowArrowButtons);
    };
  }, [items]);

  return {
    arrowButtonsState,
    scrollHorizontal,
    handleTagsContainerScroll,
    BTN_STATE: {
      SHOW_BOTH_BUTTONS,
      SHOW_NO_BUTTON,
      SHOW_BUTTON_NEXT,
      SHOW_BUTTON_PREV,
    },
  };
};

const isAtLeftEdge = (ele) => {
  return ele.scrollLeft === 0;
};
const willAtLeftEdge = (ele, shiftWillMove) => {
  return ele.scrollLeft + shiftWillMove <= 0;
};
const isAtRightEdge = (ele) => {
  return ele.scrollLeft + ele.offsetWidth === ele.scrollWidth;
};
const willAtRightEdge = (ele, shiftWillMove) => {
  return ele.scrollLeft + shiftWillMove + ele.offsetWidth >= ele.scrollWidth;
};
const isAbleToScroll = (ele) => {
  return ele.scrollWidth > ele.offsetWidth;
};

export default useScrollHorizontallyWithButtons;
