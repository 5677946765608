import React, { Suspense, useEffect } from "react";
import { CART_KEYS_MAP } from "constants/types";
import { Switch, useLocation } from "react-router-dom";
import { RouteConfig } from "utils/importDynamicModules";
// import Loading from "views/common/component/Loading";

// routes config
import routes from "routes";
import { LoadingScreen } from "views/common/component";

const TheContent = () => {
  const location = useLocation();

  useEffect(() => {
    if (
      location.pathname === "/" ||
      location.pathname === "/userinfo" ||
      location.pathname === "/search" ||
      location.pathname === "/myvoucher"
    ) {
      localStorage.removeItem("idvoucher");
      localStorage.removeItem("transactionPayment");
      localStorage.removeItem("topupCart");
      localStorage.removeItem("OrderVoucher");
      localStorage.removeItem("paymentCard");
      localStorage.removeItem("BuyTopup");
      localStorage.removeItem("OrderTopup");
      localStorage.removeItem("ratingStar");
    }

    if (
      !["/voucherview", "/vouchercart", "/coupondetail", "/promotion"].includes(
        location.pathname
      )
    ) {
      localStorage.removeItem(CART_KEYS_MAP.VOUCHER_CART.LOCAL_STORAGE_KEY);
    }
  }, [location.pathname]);

  useEffect(() => {
    const getFbRoot = document.getElementById("fb-root");
    if (getFbRoot) {
      if (location.pathname === "/") {
        getFbRoot.style.display = "block";
      } else {
        getFbRoot.style.display = "none";
      }
    }
  }, [location.pathname]);

  return (
    <main className="c-main">
      <Suspense fallback={<LoadingScreen />}>
        {/* <ScrollToTop /> */}
        <Switch>{RouteConfig(routes)}</Switch>
      </Suspense>
    </main>
  );
};

export default React.memo(TheContent);

// const ScrollToTop = () => {
//   const location = useLocation();

//   useLayoutEffect(() => {
//     window.scrollTo({
//       top: 0,
//     });
//   }, [location.pathname]);

//   return null;
// };
