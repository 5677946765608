import React from "react";

import InfiniteScroll from "react-infinite-scroll-component";

import SearchNotFound from "../SearchNotFound";
import FoodItem from "../../../MarketHomeView/components/FoodItem";

import "./searchResult.scss";

const SearchResult = (props) => {
  const calDiscount = (listPrice, price) => {
    let percentDiscount = 0;
    if (!!listPrice && !!price) {
      percentDiscount = 100 - Math.round((price / listPrice) * 100);
      if (percentDiscount === 100) {
        percentDiscount -= 1;
      }
    }
    return percentDiscount;
  };

  return (
    <div className="search-page-search">
      {!props.isShowLoading && props?.dataProducts?.length > 0 && (
        <InfiniteScroll
          dataLength={props.dataProducts.length}
          next={props.loadMore}
          hasMore={!props.isEndSearchMarket}
          // loader={<div class="loader" />}
          endMessage={<p style={{ textAlign: "center" }}>Đã hết sản phẩm</p>}
        >
          <div className="product-section">
            {props?.dataProducts?.length > 0 && (
              <div className="best-seller__container product-section-child">
                <div className="products__container">
                  {props?.dataProducts.map((product) => (
                    <FoodItem
                      key={product.id}
                      id={product.id}
                      code={product?.code}
                      // type={FOOD_ITEM_TYPES.DETAILS} // must have
                      title={product?.name?.vi}
                      imgSrc={product?.image}
                      description={product?.description?.default?.vi}
                      shortDescription={product?.description?.short?.vi}
                      originalPrice={product?.price?.list}
                      currentPrice={product?.price?.default}
                      category={product?.category}
                      dataCart={props.dataCart}
                      data={product}
                      isSelling={product.isSelling}
                      isStoreClosed={props?.isCloseStore}
                      isOutOfStock={product.isOutOfStock}
                      // navigateToSmartOrderMenu={navigateToSmartOrderMenu}
                      handleOpenMenuOrder={() =>
                        props.handleOpenMenuOrder(product)
                      }
                      saleTagUrl={product?.saleTagUrl}
                      percentDiscount={calDiscount(
                        product?.price?.list,
                        product?.price?.default
                      )}
                    />
                  ))}
                </div>
              </div>
            )}
          </div>
        </InfiniteScroll>
      )}
      {props.isShowLoading && (
        <div className="loading-wrapper">
          <div className="loader" />
        </div>
      )}

      {!props.isShowLoading &&
        props.dataProducts?.length === 0 &&
        props.dataProducts?.length >= 0 && (
          <>
            <SearchNotFound />
          </>
        )}
    </div>
  );
};

export default SearchResult;
