import {
  createActionGetUserInfoSuccess,
  createActionGetUserInfoFailure,
} from "./actions";
import { GET_USER_INFO_INSURANCE } from "./constants";
import { call, fork, put, takeLatest } from "redux-saga/effects";

import { getUserInfoApi } from "@shared/services/api/userInfo";

function* getUserInfo(action) {
  const { onSuccess, onError } = action || {};
  try {
    const repData = yield call(getUserInfoApi);
    onSuccess?.(repData.data);
    yield put(createActionGetUserInfoSuccess(repData));
  } catch (error) {
    onError?.();
    yield put(createActionGetUserInfoFailure(error));
  }
}

function* watchGetUserInfo() {
  yield takeLatest(GET_USER_INFO_INSURANCE.HANDLER, getUserInfo);
}

export default function* rootChild() {
  yield fork(watchGetUserInfo);
}
