import React, { useCallback } from "react";
import { NavigationServices, AlertMsg } from "utils";
import TheHeaderHome from "../MarketSearch/components/TheHeaderSearch/TheHeaderHome";
// eslint-disable-next-line import/extensions
import SearchResult from "./components/SearchResult/SearchResult";
import CartBottomBar from "../MarketHomeView/components/CartBottomBar";
import "./SearchStoreMarket.scss";

const SearchStoreMarketView = (props) => {
  const goBack = () => {
    NavigationServices.goBack();
  };

  const handleShareStoreList = useCallback(
    async (dataStore) => {
      if (navigator.share) {
        try {
          await navigator.share({
            title: "Chia sẻ liên kết của cửa hàng",
            text: `Bạn được giới thiệu trải nghiệm ${dataStore?.name?.vi} tại chợ ${props.storeInfo?.name} trên ứng dụng Utop. Nhấn link bên dưới để đăng ký và nhận ưu đãi`,
          });
        } catch (err) {
          console.error(err);
        }
      } else {
        AlertMsg(
          "basic",
          "Không thể chia sẻ trên browser máy tính"
          // backClicked
        );
        console.error("navigator.share is not available on this browser");
      }
    },
    [props.storeInfo?.name]
  );

  return (
    <div className="market-search-wrapper">
      <TheHeaderHome
        onSearchPage
        goBack={goBack}
        _onSearchProduct={props._onSearchProduct}
        _onClearSearchtext={props._onClearSearchtext}
        listSearchHistory={props.listSearchHistory}
        txtSearch={props.txtSearch}
        placeholder="Tìm cửa hàng..."
        clearSearchHistory={props.clearSearchHistory}
      />
      <SearchResult
        dataStalls={props.dataStalls}
        loadMore={props.onLoadMore}
        isShowLoading={props.isShowLoading}
        storeInfo={props.storeInfo}
        isCloseStore={props.isCloseStore}
        handleShareStore={handleShareStoreList}
        gotoProduct={props.gotoProduct}
      />
      <div className="cart-bottom">
        <CartBottomBar
          // to="/order"
          eventHandle={() =>
            NavigationServices.navigate("CartMarket", {
              isFromMarket: true,
              storeInfo: props.storeInfo,
            })
          }
          totalPrice={
            props.dataCart.length > 0
              ? props.dataCart
                  .map((i) => i.dataInfoProduct.totalPrice)
                  .reduce((a, b) => a + b)
              : 0
          }
          itemsCount={
            props.dataCart.length > 0
              ? props.dataCart
                  .map((i) => i.dataInfoProduct.quantity)
                  .reduce((a, b) => a + b)
              : 0
          }
          className="store-detail-page__cart-bottom-bar"
          text="Xem giỏ hàng"
          disabled={props.dataCart.length > 0 && !props.isCloseStore ? 0 : 1}
          // todo: check thêm khi ấn nút này, có thể bị phá khi mở devtool
        />
      </div>
    </div>
  );
};

export default SearchStoreMarketView;
