import {createSelector} from 'reselect';

export const select = state => state.orderAddressFood;
export const getDefaultAddressUser = createSelector(
  select,
  state => state.defaultAddressUser,
);
export const getListShipping = createSelector(select, state => {
  if (state.listShipping.length === 1) {
    let listShippingTemp = state.listShipping;
    listShippingTemp[0].isChecked = true;
    return listShippingTemp;
  }
  return state.listShipping;
});
