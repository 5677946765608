export const GET_STORE_INFO_BY_ID_REQUEST =
  'store/GET_STORE_INFO_BY_ID_REQUEST';
export const GET_STORE_INFO_BY_ID_SUCCESS =
  'store/GET_STORE_INFO_BY_ID_SUCCESS';
export const GET_STORE_INFO_BY_ID_FAILED = 'store/GET_STORE_INFO_BY_ID_FAILED';

export const GET_PRODUCT_SUGGEST_BY_USER_REQUEST =
  'store/GET_PRODUCT_SUGGEST_BY_USER_REQUEST';
export const GET_PRODUCT_SUGGEST_BY_USER_SUCCESS =
  'store/GET_PRODUCT_SUGGEST_BY_USER_SUCCESS';
export const GET_PRODUCT_SUGGEST_BY_USER_FAILED =
  'store/GET_PRODUCT_SUGGEST_BY_USER_FAILED';
