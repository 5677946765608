import { genActionTypes } from "utils/actionGenerator";

export const TYPE = "Voucher";

export const SET_LOADING = `${TYPE}_SET_LOADING`;
export const GET_LIST_CITY = genActionTypes(`${TYPE}_GET_LIST_CITY`);

export const GET_LIST_VOUCHER = genActionTypes(`${TYPE}_GET_LIST_VOUCHER`); // ! deprecated

export const GET_LIST_FEED = genActionTypes(`${TYPE}_GET_LIST_FEED`);
export const GET_LIST_FEED_KEY = "GET_LIST_FEED";

export const GET_LIST_STORE_OF_MERCHANT = genActionTypes(
  `${TYPE}_GET_LIST_STORE_OF_MERCHANT`
);
// export const GET_LIST_STORE_OF_MERCHANT_KEY = "GET_LIST_STORE_OF_MERCHANT"; // dont need to cache

export const FILTER_VOUCHER_STORE = genActionTypes(
  `${TYPE}_FILTER_VOUCHER_STORE`
);
export const GET_SHORTCUTS = genActionTypes(`${TYPE}_GET_SHORTCUTS`);
export const GET_SHORTCUTS_PERSONALIZED = genActionTypes(
  `${TYPE}_GET_SHORTCUTS_PERSONALIZED`
);
export const INIT_STATE_LIST_FEED = genActionTypes(
  `${TYPE}_INIT_STATE_LIST_FEED`
);
