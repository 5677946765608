import { POST_REQUEST } from './types'
import { countListVideo, postFailure, postSuccess } from './actions'
import { call, put, takeEvery, fork } from 'redux-saga/effects'
import { END_POINTS } from '@shared/constants/apiUrl'
import { APIUtils } from 'utils'

async function postApi({ skipCount, maxResult, location }) {
  const requestData = `?skip=${skipCount}&take=${maxResult}&lat=${location.selectedLocation.latitude}&long=${location.selectedLocation.longitude}`
  return APIUtils.get(END_POINTS.GetVideoPersonalize + requestData)
}

/**
 * Dispatch action success or failure
 * @param {*} obj params
 */
export function* post(obj) {
  try {
    const data = yield call(postApi, obj.payload)
    if (data.httpStatus === 200) {
      const result = [...obj.payload.list, ...data.value]
      yield put(postSuccess({ result, skipCount: obj.payload.skipCount }))
      yield put(countListVideo(data['@odata.count']))
    } else {
      yield put(postFailure(data))
    }
  } catch (err) {
    yield put(postFailure(err))
  }
}

/**
 * Catch action login request
 */
function* watchLogin() {
  yield takeEvery(POST_REQUEST, post)
}

export default function* rootChild() {
  yield fork(watchLogin)
}
