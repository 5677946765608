import React from "react";
import StoreItem from "../../../MarketHomeView/components/StoreList/components/StoreItem/StoreItem";
import "./StoreList.scss";
// eslint-disable-next-line import/order
import { ArrowRightYellowIcon } from "assets/svg";

const StoreList = ({
  goToSeeAll,
  hideTitle,
  gotoProduct,
  dataListStore,
  handleShareStore,
}) => {
  const SeeMore = () => {
    return (
      <div
        className="store-view__rating__header__text-header__right"
        onClick={goToSeeAll}
      >
        <span>Xem thêm</span>
        {/* <img src={SVG.ArrowRightYellow} alt="arrow" /> */}
        <ArrowRightYellowIcon />
      </div>
    );
  };
  return (
    <div className="store-list-wrapper">
      {!hideTitle && (
        <div className="store-wrapper_header">
          <div className="label">Danh sách cửa hàng</div>
          {SeeMore()}
        </div>
      )}
      <div className="store-content">
        {dataListStore.map((item) => (
          <StoreItem
            gotoProduct={() => gotoProduct(item)}
            dataStore={item}
            handleShareStore={handleShareStore}
          />
        ))}
      </div>
    </div>
  );
};

export default StoreList;
