/* eslint-disable no-param-reassign */
import React, { useState } from "react";
import {
  //   checkGuestPopup,
  //   getlastRouteFNB,
  NavigationServices,
  // AlertMsg,
  //   ReactUtils,
} from "utils";
import {
  getListCouponForVoucherHandle,
  getCouponDetailForVoucherHandle,
  searchCouponDetailForVoucherHandle,
  dataCouponSelected,
  getAvailableCouponHandle,
} from "@shared/redux/couponForVoucher/actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  getListCouponForVoucherSelector,
  getCouponDetailForVoucherSelector,
  searchCouponDetailForVoucherSelector,
  getAvailabelCouponSelector,
} from "@shared/redux/couponForVoucher/selectors";

import { getLoadingSelector } from "@shared/redux/loading/selector";
import {
  GET_LIST_COUPON_FOR_VOUCHER_REQUEST,
  GET_COUPON_DETAIL_FOR_VOUCHER_REQUEST,
  SEARCH_COUPON_DETAIL_FOR_VOUCHER_REQUEST,
} from "@shared/redux/couponForVoucher/constants";
import { allowcationCouponSuccess } from "@shared/redux/cart/actions";
import { updateListCouponSuggest } from "@shared/redux/common/actions";
import { updateListCouponSuggestForStore } from "@shared/redux/smartMenu/actions";
import { setShowMessageError } from "@shared/redux/coupon/actions";
import PromotionV2View from "./PromotionV2.view";

const PromotionV2 = (props) => {
  const [isSearch, setIsSearch] = useState(false);
  //   const [isGuest, setIsGuest] = useState(true);
  const [isShowLoading, setIsShowLoading] = useState(false);
  const [alertAction, setAlertAction] = useState({});
  const params = NavigationServices.getParams(props);

  React.useEffect(() => {
    props.getListCouponForVoucherHandle(params?.orderType);

    // const handleBackPress = () => {
    //   //   goBack();
    //   return true;
    // };

    // ReactUtils.getStorage("utopInfo", function (asynData) {
    //   if (typeof asynData.isGuest !== "undefined") {
    //     setIsGuest(asynData.isGuest);
    //     // checkGuestPopup();
    //   }
    // });

    // Back event
    // const backHandler = BackHandler.addEventListener(
    //   "hardwareBackPress",
    //   handleBackPress
    // );

    // return () => backHandler.remove();
  }, []);

  // useEffect(() => {
  //   renderHeaderScreen();
  // }, []);

  const onMessage = (message) => {
    switch (message.action) {
      case "detailCoupon":
        global.isFromMyVoucher = false;
        // eslint-disable-next-line no-param-reassign
        message.data.isFlow = params?.orderType;
        message.data.isSearchCoupon = message.data.isSearch;
        message.data.code = message.data.couponCode;

        if (message.data.isSearch) {
          NavigationServices.navigate(
            `/coupondetailv2?id=${message.data.couponId}`,
            {
              mode: "APPLY",
              callbackUrl: "/CartMarket", // todo: remove hard code
              isAllocated: !!message.data?.transactionCode, // always true (this page need authen to access, and call api by token of user)
              prevPage: `${window.location.pathname}${window.location.search}`,
              expiredDay: message.data?.expiredDay,
            }
          );
        } else {
          setIsShowLoading(true);
          props.getCouponDetailForVoucherHandle(
            {
              couponCode: message.data.couponCode,
              transactionCode: message.data.transactionCode,
            },
            (res) => {
              if (res.data.length > 0) {
                // res.data[0].couponId = message.data.couponId;
                // res.data[0].expiredDay = message.data.expiredDay;
                // res.data[0].couponName = message.data.couponName;
                // res.data[0].isSearchCoupon = message.data.isSearch;

                message.data.voucherCode = res.data[0].voucherCode;
                // save data coupon selected
                // props.dataCouponSelected(res.data[0]);
                NavigationServices.navigate(
                  `/coupondetailv2?id=${message.data.couponId}`,
                  {
                    mode: "APPLY",
                    callbackUrl: "/CartMarket", // todo: remove hard code
                    isAllocated: !!message.data?.transactionCode, // always true (this page need authen to access, and call api by token of user)
                    prevPage: `${window.location.pathname}${window.location.search}`,
                    expiredDay: message.data?.expiredDay,
                  }
                );

                setIsShowLoading(false);
              } else {
                // error message
                setIsShowLoading(false);
                setAlertAction({
                  isShow: true,
                  title: "Utop",
                  content: "Bạn đã hết lượt sử dụng coupon.",
                  titleAccept: "OK",
                  dataItem: "basic",
                });
              }
            },
            (err) => {
              // error message
              setIsShowLoading(false);
              try {
                const serverErrorMsg = err;
                setAlertAction({
                  isShow: true,
                  title: "Utop",
                  content: serverErrorMsg,
                  titleAccept: "OK",
                  dataItem: "basic",
                });
                // eslint-disable-next-line no-empty
              } catch (error) {}
            }
          );
        }
        break;

      case "searchCoupon":
        setIsSearch(true);
        // message.data = ReactUtils.replaceAll(message.data, " ", "");
        props.searchCouponDetailForVoucherHandle(
          { data: { couponCode: message.data } },
          () => {},
          () => {}
        );
        break;

      case "clearSearch":
        setIsSearch(false);
        break;

      case "useNow":
        setIsShowLoading(true);
        if (message.data.isSearch) {
          props.getAvailableCouponHandle(
            message.data.couponCode,
            (res) => {
              if (res.data.coupons.length > 0) {
                if (message.data.couponName === undefined) {
                  message.data.couponName = message.data.name;
                }

                message.data.transactionCode =
                  res.data.coupons[0].transactionCode;
                // getlastRouteFNB(function (route) {
                //   if (route === "FNB") {
                //     props.updateListCouponSuggestForStore(message.data);
                //   } else {
                //     props.updateListCouponSuggest(message.data);
                //   }
                // });

                console.log("params123", params);

                message.data.voucherCode = res.data.coupons[0].voucherCode;
                if (params?.orderType === "OrderFNB") {
                  props.setShowMessageError(true);
                  props.allowcationCouponSuccess(message.data);
                  NavigationServices.navigate("Cart");
                } else {
                  props.dataCouponSelected(message.data);
                  NavigationServices.navigate("ConfirmPayment");
                }
              } else {
                setAlertAction({
                  isShow: true,
                  title: "Utop",
                  content: "Bạn đã hết lượt sử dụng coupon.",
                  titleAccept: "OK",
                  dataItem: "basic",
                });
              }
              setIsShowLoading(false);
            },
            (err) => {
              // error message
              setIsShowLoading(false);
              switch (err.data.message) {
                case "DuplicateTransaction":
                  setAlertAction({
                    isShow: true,
                    title: "Utop",
                    content: "Hệ thống đang xứ lý, vui lòng thử lại sau.",
                    titleAccept: "OK",
                    dataItem: "basic",
                  });
                  break;

                case "OverMaxSet":
                  setAlertAction({
                    isShow: true,
                    title: "Utop",
                    content: "Số lượng coupon của chương trình đã hết.",
                    titleAccept: "OK",
                    dataItem: "basic",
                  });
                  break;

                case "OverMaxSetPerUser":
                  setAlertAction({
                    isShow: true,
                    title: "Utop",
                    content: "Bạn đã hết lượt nhận coupon.",
                    titleAccept: "OK",
                    dataItem: "basic",
                  });
                  break;

                case "NotFoundCouponCampaign":
                  setAlertAction({
                    isShow: true,
                    title: "Utop",
                    content:
                      "Mã ưu đãi không hợp lệ, vui lòng kiểm tra lại hoặc thử mã khác.",
                    titleAccept: "OK",
                    dataItem: "basic",
                  });
                  break;

                case "InvalidCouponCampaginPeriod":
                  setAlertAction({
                    isShow: true,
                    title: "Utop",
                    content: "Đã hết thời gian nhận coupon.",
                    titleAccept: "OK",
                    dataItem: "basic",
                  });
                  break;

                default:
                  break;
              }
            }
          );
        } else {
          props.getCouponDetailForVoucherHandle(
            {
              couponCode: message.data.couponCode,
              transactionCode: message.data.transactionCode,
            },
            (res) => {
              if (res.data.length > 0) {
                message.data.voucherCode = res.data[0].voucherCode;
                props.dataCouponSelected(message.data);
                // NavigationServices.navigate("ConfirmPayment");
              } else {
                setAlertAction({
                  isShow: true,
                  title: "Utop",
                  content: "Bạn đã hết lượt sử dụng coupon.",
                  titleAccept: "OK",
                  dataItem: "basic",
                });
              }
              setIsShowLoading(false);
              if (res.data.length > 0) {
                message.data.voucherCode = res.data[0].voucherCode;
                if (message.data.couponName === undefined) {
                  message.data.couponName = message.data.name;
                }
                // message.data.couponName = message.data.name;
                if (params?.orderType === "OrderFNB") {
                  props.setShowMessageError(true);
                  props.allowcationCouponSuccess(message.data);
                  NavigationServices.goBack();
                } else {
                  props.dataCouponSelected(message.data);
                  NavigationServices.navigate("ConfirmPayment");
                }
              } else {
                // ReactUtils.alertMsg("basic", "Bạn đã hết lượt sử dụng coupon.");
              }
            },
            (err) => {
              setIsShowLoading(false);
              try {
                const serverErrorMsg = err;
                console.log(serverErrorMsg);
                // ReactUtils.alertMsg("basic", serverErrorMsg);
                // eslint-disable-next-line no-empty
              } catch (error) {}
            }
          );
        }
        break;

      case "refresh":
        props.getListCouponForVoucherHandle(params?.orderType);
        break;

      default:
        break;
    }
  };

  const onAcceptAlert = (value) => {
    // eslint-disable-next-line default-case
    switch (value) {
      case "address":
        setAlertAction({});
        break;
      default:
        setAlertAction({});
    }
  };

  return (
    <PromotionV2View
      isLoadingSearch={props.isLoadingSearch}
      isLoadingList={props.isLoadingList}
      isSearch={isSearch}
      dataSearch={props.searchCouponDetailForVoucher}
      data={props.listCouponForVoucher}
      onMessage={(value) => onMessage(value)}
      isShowLoading={isShowLoading}
      dataAlertAction={alertAction}
      setDataAlertAction={() => setAlertAction({})}
      handleAlertAction={onAcceptAlert}
    />
  );
};
const mapStateToProps = (state) => {
  return {
    listCouponForVoucher: getListCouponForVoucherSelector(state),
    couponDetailForVoucher: getCouponDetailForVoucherSelector(state),
    searchCouponDetailForVoucher: searchCouponDetailForVoucherSelector(state),
    availabelCoupon: getAvailabelCouponSelector(state),
    isLoadingList: getLoadingSelector(state, [
      GET_LIST_COUPON_FOR_VOUCHER_REQUEST,
    ]),
    isLoadingDetail: getLoadingSelector(state, [
      GET_COUPON_DETAIL_FOR_VOUCHER_REQUEST,
    ]),
    isLoadingSearch: getLoadingSelector(state, [
      SEARCH_COUPON_DETAIL_FOR_VOUCHER_REQUEST,
    ]),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getListCouponForVoucherHandle,
      getCouponDetailForVoucherHandle,
      searchCouponDetailForVoucherHandle,
      dataCouponSelected,
      getAvailableCouponHandle,
      allowcationCouponSuccess,
      updateListCouponSuggestForStore,
      updateListCouponSuggest,
      setShowMessageError,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(PromotionV2);
