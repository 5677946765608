import {
    CHANNEL_LIST_REQUEST,
    CHANNEL_LIST_SUCCESS,
    CHANNEL_LIST_FAILED,

    CHANNEL_DETAIL_REQUEST,
    CHANNEL_DETAIL_SUCCESS,
    CHANNEL_DETAIL_FAILED,

} from './types';

export const channelListRequest = payload => ({
    type: CHANNEL_LIST_REQUEST,
    payload,
});

export const channelListSuccess = payload => ({
    type: CHANNEL_LIST_SUCCESS,
    payload,
});

export const channelListFailure = payload => ({
    type: CHANNEL_LIST_FAILED,
    payload,
});

export const channelDetailRequest = payload => ({
    type: CHANNEL_DETAIL_REQUEST,
    payload,
});

export const channelDetailSuccess = payload => ({
    type: CHANNEL_DETAIL_SUCCESS,
    payload,
});


export const channelDetailFailure = payload => ({
    type: CHANNEL_DETAIL_FAILED,
    payload,
});


