export default {
  common: {
    alertTitle: "Notification",
    alertConnectServerError:
      "An error occurred while connecting to the server.",
    alertNotEnoughUtop: "You don't have enough Utop points.\nCurrent balance: ",
    lableUtop: "Utop",
    labelNotEnoughUtop: "You don't have enough Utop points.",
    labelCurrentUtop: "Current balance: ",
    labelPlusUtop: "Topup now",
    labelClose: "Close",
    labelSuccessTrade: "Payment Successful",
    lavelFailTrade: "Transaction failed!",
    alertTitleContactStore: "Contact store",
    alertCall: "Call",
    alertTitleCancel: "Cancel",
    alertCloseStore:
      "The store is currently closed. Would you like to pay again?",
    errorMessage: "Something went wrong. Please try again!",
    somethingWentWrong: "Something went wrong",
    lostConnection:
      "Something went wrong. Please check your internet connection.",
    grant: "Agree",
    cancel: "Cancel",
    labelContactDriver: "Contact driver",
    hundred: "thousand",
    million: "million",
    billon: "billion",
    emptyData: "No data.",
    continueText: "Continue",
    unitPrice: "VND",
    vietnamDong: "VND",
    reLoginText: "Login",
    NoText: "No",
    clear: "Clear",
    apply: "Apply",
    accept: "Agree",
    use: "Use",
    takePicture: "Take a picture",
    dateTime: {
      today: "Today",
      tomorrow: "Tomorrow",
      sunday: "Sunday",
      monday: "Monday",
      tuesday: "Tuesday",
      wednesday: "Wednesday",
      thursday: "Thursday",
      friday: "Friday",
      saturday: "Saturday",
    },
    success: "Successful",
    under: "Under",
    above: "Over",
    btnCancel: "Cancel",
    yes: "Yes",
    retry: "Retry",
    needPermissionToTakeLocationAlertTitle:
      "Utop doesn't have location permissions",
    needPermissionToTakeLocationAlertText:
      "Please share your location for Utop to suggest suitable stores and utilities. Your location will not be shared without your permission.",
    viewMore: "View more",
    shareDescription: "Utop - Multi featured application for convenient life.",
    emptyListStore:
      "List Store is being updated shortly.\nPlease come back later.",
    all: "All",
    closeStatus: "Closed",
    opening: "Opening",
    backAt: "Opens at",
    store: "Stores",
    connectionInterrupted: "Network connection is interrupted",
    endList: "End of list",
    textLoadingMiniApp: "Xong ngay thôi, chờ Utop nhé!",
    viewAllStore: "View all Stores",
    later: "Later",
    grantAccess: "Allow Access",
  },
  home: {
    listCity: "List of provinces",
    turnOnPermissionLocation: "Turn on location settings",
    isNotLoginTitle: "You are not logged in!",
    isNotLoginContent: "Please register/login to get all features",
    yourUtopPoint: "Your Utop points",
    searchbarPlaceholder: "Type to search…",
    membership: "Membership",
    scanQR: "Scan QR",
    phoneRechargeCard: "Top-up Card",
    selectCurrentLocation: "Select current location",
    selectCity: "Select Province / City",
    hotKeyword: "Hot keywords",
    cityNotSupportDescription:
      "Utop is not currently serviced in your province, please select neighboring provinces:",
    pleaseSelectCityDescription:
      "Please select your location for Utop to recommend suitable services and promotions: ",
    loadScore: "Top-up points",
    supportOSToUseAppleId: "Your OS does not support to login!",
    discoverMediaTitle: "Discover",
    errorListStoreByMerchant:
      "The store listing has not been displayed.\n Please try again later!",
  },
  searchScreen: {
    recentSearch: "Recent Search",
    clear: "Clear",
    noData: "No Data",
  },
  voucherPayment: {},
  loginrequest: {
    title: "You are not logged in!",
    content: "Please login to use all\n features of Utop.",
    ok: "OK",
    labelYes: "Yes",
    labelNo: "No",
  },
  MyVoucher: {
    Main: {
      TitleBar: "My Vouchers",
      tabEffect: "Valid",
      tabExpired: "Invalid",
      ViewVoucher: "View",
      shareVoucher: "Give friends",
    },
    VoucherCode: {
      viewDetail: "View more",
      voucherSerial: "Partner code",
      copyBarcode: "COPY BARCODE NUMBER",
      voucherCode: "Voucher code",
      seriCode: "Serial number",
      COPY: "COPY",
      useNow: "Top-up",
      markUse: "Mark use",
      Copied: "Copied",
      alert: {
        confirm: "Confirm",
        cancel: "Cancel",
        notSupportIOS: "This feature only support from IOS 11.0 or later",
        bodyConfirm: "Please confirm you have used this gift.",
      },
      useNowScan: "Use",
      voucherEmpty: "Voucher is not available, please check again!",
    },
    CouponDetail: {
      rulesAndInstructions: "Rule and user manual",
      goBack: "Back",
      useVoucher: "Use",
    },
    VoucherDetail: {
      usageAndNotes: "Usage and notes",
      descriptionNotes:
        "Present valid E-voucher code at checkout to check and confirm.",
      locationOfUse: "Useable location",
      otherInformation: "Other information",
      otherContent:
        "Default, this voucher is selected with an expiration date recommending.",
      amountVoucherCanRefund: "voucher can refund.",
      ShareMessage:
        " is available on the Utop app. Click the link below to register and receive promotions!\n",
      labelNotExistProduct: "Product not available.",
      labelAgree: "AGREE",
      labelBuyProduct: "Buy product",
      labelOutOfStock: "Out of stock",
      labelQuantity: "Quantity",
      labelEnterPrice: "Enter amount (unit: Thousand VND)",
      labelConditionsOfUse: "Terms of use",
      labelStoreApply: "Applicable store",
      priceOption: "Price Options",
      viewMore: "View more",
      takenCoupon: "Claimed",
      takeCoupon: "Claim",
    },
    Expired: "Expiring",
    Used: "Used",
    errorMessage: "Something went wrong. Please try again",
    VoucherCannotMarkUsed: "Voucher was mark used. Please try again!",
    VoucherIsRefunded: "Voucher was refunded. Please try again!",
    VoucherIsUsed: "Voucher was used. Please try again!",
    labelConfirm: "Confirm",
    labelCouponCodeNotAvailable:
      "Coupon is not available, please use another coupon.",
  },
  countStep: {
    overview: "TỔNG QUAN",
    totalStep: "Tổng số bước đi",
    totalStepRedeem: "Số bước đã quy đổi",
    stepToday: "Số bước đi của bạn trong hôm nay:",
    step: "bước",
    moreInfo: "Thông tin thêm",
    today: "HÔM NAY",
    highest: "THÀNH TÍCH CAO NHẤT",
    weekly: "Theo dõi hàng tuần",
    monday: "T2",
    tuesday: "T3",
    wed: "T4",
    thu: "T5",
    fri: "T6",
    sat: "T7",
    sun: "CN",
    today2: "Hôm nay",
    date: "Ngày",
    congratulation: "CHÚC MỪNG BẠN",
    stepDes: "Là tổng số bước chân bạn đã đóng góp cho cộng đồng",
    moneyDes: "Là số tiền cộng đồng đã chung tay góp sức",
    highestStep: "Là số bước chân nhiều nhất bạn đi được trong 1 ngày",
    share: "CHIA SẺ NGAY",
    stepTodayAll: "Tổng số bước đi của cộng đồng:",
    redeem: "Quy đổi",
    strDescOne: "Mỗi khi đạt được mốc chỉ định, Utop sẽ tài trợ thêm",
    strDescTwo: "trên tổng số tiền đã ủng hộ",
    totalStepDesc: "Là tổng số bước chân cộng đồng đã chung tay góp sức",
    strShare: "Chia sẻ",
    stepHighestForOneDay: "Là số bước chân nhiều nhất bạn đi được trong 1 ngày",
  },
  listVoucher: {
    viewText: "View",
    expiredDateText: "Expired Date",
  },
  myCouponDetail: {
    expiredDateText: "Expired Date",
  },
  notificationList: {
    emptyText: "No notifications.",
  },
  video: {
    time: {
      m: "1 minute",
      h: "1 hour",
      d: "1 day",
      M: "1 month",
      y: "1 year",
    },
    uploadVideo: {
      errorVideo: "Video was error, please choose another video",
      send: "Send",
      delete: "Delete",
      update: "Update",
      reasonReject: "Please see detailed feedback on your video from Utop:",
      reasonRemove: "Please see detailed feedback on your video from Utop:",
      descriptionVideo: "Describe your video",
      agreeWithPolicy: "I agree with the policies to use this feature",
      shareVideo: "Share video",
      postNewVideo: "Upload new video",
      videoReject: "Your video has been refused",
      videoRemove: "Your video has been deleted",
      detail: "Detail",
      cancelVideo: "Video is not saved, are you sure to exit this screen?",
      msgErrorSelectVideo: "Please select only 1 video on your device.",
      requestSelectVideo: "Please select at least 1 video to continue",
      agree: "Agree",
      uploadSuccess: "Your Video is ready",
      reupSuccess: "You have successfully submitted your review",
      waitingReview:
        "Please see or edit your video at My Channel Please watch or edit your videos\n at My Channel now!",
      waitingReview2: "Please wait\nadmin ",
      no: "Cancel",
      yes: "Confirm",
      notSave: "Video upload failed",
      sureExit:
        "Your video is not share\nAre you sure exit this screen without posting a video? ",
      edit: "Edit",
      public: "Public",
      onlyMe: "Only me",
      whoCanWatch: "Who can see this video?",
      allowComment: "Allow Comment",
      view: "Views",
      inform: "Notifications",
      private: "Private",
      selectFromGallery: "Select videos from your gallery",
      recordVideo: "Record video",
      errorSelectVideo: "Please choose video under 60s",
      editThumbnail: "Change picture",
    },
    videoDetail: {
      count_view: "Views",
      all: "All",
      follow: "Follow",
      place_holder_search: "Type to search...",
      place_holder_cmt: "Input your comment here...",
      full_text: "View more",
      truncate_text: "reduce",
      ago: "ago",
      year: "year",
      month: "month",
      day: "day",
      hour: "hour",
      minute: "minute",
      sec: "second",
      msgErrorVideo: "Video is not available. Please try again",
      ok: "Confirm",
      errorReport: "System error",
      block: "Block",
      unblock: "Unblock",
      fail: "fail",
      confirm: "Confirm",
      cancel: "CANCEL",
      cancel2: "Cancel",
      titleReport: "Report",
      contentReport: "You will not see video or contact to each others",
      placeHolderReport: "Type other reason...",
      retry: "No data\nPress here to reload",
      pressRetry: "Press here to reload",
      back: "Back",
      contentBlock:
        "You will not find their account \nand video on Utop. Utop \nwill not notify them that you \nhave blocked them.",
      contentUnblock:
        "Now you can find their account \nand video on Utop. \nUtop will not notify them \nthat you have unblocked them.",
      titlePopupReport: "Thank you for your report!",
      contentPopupReport:
        "Your feedback helps us system \nknow when there is \nsomething wrong",
      titlePopupBlock: "You have blocked",
      contentPopupBlock: "You can unblock whenever \nyou want",
      share: "Share",
      message: "Message",
      other: "Other",
      delete: "Delete",
      private: "Private",
      edit: "Edit",
      report: "Report",
      option: "Options",
      sureDelete: "Are you sure delete this video?",
      download: "Download",
      downloading: "Downloading...",
      saved: "Save",
      duplicateDownload: "Please try again after the download is complete ",
      agree: "Agree",
      errorDownload: "Connection line error",
      retryDownload: "Retry",
      noOneBlocked: "You haven't blocked anyone yet.",
      blockAccounts: "Blocked accounts",
    },
    home: {
      policy: "Feature usage policy",
      viewCount: "Views",
      loginToUse: "Login to use this feature!",
      follower: "Follower",
      focus: "Focus",
      hashtag: "Tendency",
      myVideo: "My video",
      requestCamera:
        "Please allow Utop to access the camera to use this feature.",
      requestAudio:
        "Please allow Utop to access the Audio to use this feature.",
      refuse: "refuse",
      requestVideo:
        "Please allow Utop to access the video to use this feature.",
      inputSearch: "Enter keyword to search",
    },
    search: {
      account: "Account",
      hashtag: "Hashtag",
      hotKeyword: "Hot keyword",
      inputSearch: "Enter keyword",
      empty: "No data",
    },
    profile: {
      myVideo: "My Video",
      shareYourVideo: "Share your video",
      following: "Following",
      follower: "Followers",
      block: "Block",
      listFollower: "Follower",
      listFollowing: "Following",
      removeFollow: "Delete",
      follow: "Follow",
      unFollow: "Un Follow",
      myVideoTab: "My Video", // Của tôi, yêu thích, Hastag tab ở màn hình MyVideo trong video
      favoriteVideoTab: "Favorite",
      hashtagTab: "Hashtag",
    },
    userDetail: {
      follow: "Follow",
      unFollow: "Un follow",
      blocked: "You are blocking this user.",
    },
    hashtagDetail: {
      description: "Description",
      view: "view",
      unFavorite: "Un favorite",
      favorite: " Add to favorite",
      title: "Trending",
    },
    comment: {
      comment: "Comment",
      exit: "Exit",
      addComment: "Add more comment...",
      hide: "Hide",
      delete: "Delete",
      report: "Report",
      copy: "Copy",
      cancel: "Cancel",
      noComment: "This video has turned off comment function",
      firstComment: "Be the first to comment!",
      reply: "Reply",
      viewReply: "View reply",
      view: "View",
      reply2: "reply",
      sending: "Sending",
      viewMore: "View more",
      contentBlock: "Comment is invalid. Please try again.",
      errorComment: "An error has occurred. Please try again",
      following: "Following",
      thanks: "Thanks for let us know!",
      yourFeedback: "Your contributions helps us know if something went wrong.",
    },
    hashtagList: {
      empty: "No data",
    },
  },
  Utop: {
    YourBalance: "Current balance",
    Loaded: "Top-up",
    InviteFriends: "Invite your friends",
    SeeAll: "View all",
    Utop: "Utop",
    React: {
      TitleAlert: "Utop",
      AlertMsg: "Do you want to exit the application ?",
      BtnAlert1: "CANCEL",
      BtnAlert2: "AGREE",
      dialogTitle: "Select sharing methods",
      ShareTitle: "Share link is created",
      ShareMessage:
        "You receive an invitation to download the Utop app for a chance to exchange gifts from CGV, Phuc Long, … Click here at http://onelink.to/mgxnyk",
    },
  },
  UtopExchange: {
    RedemptionPoints: "Redemption points",
    Points: "Points",
    Receive: "Receive",
    Utop: "Utop",
    Brand: "Brand",
    Text1: "F.Money",
    Ratio: "Ratio",
    Text2: "F.Money = 1 Utop",
    Change: "Exchange",
    Text3: "Not connect to the brand",
    More: "More",
    Text4: "1 Utop is equivalent to 1,000 VND",
    Confirm: "Confirm",
    Guide: "Guideline",
    Text5: "Select brand",
    Text6: "Add another brand",
    Step1: "Step 1: Click “Add” and choose the brand you want to connect.",
    Step2: "Step 2: Confirm and complete top up Utop points",
    React: {
      TitleBar: "Top up",
      alertMsg: "No new brand",
    },
  },
  UtopExchangeConfirm: {
    Brand: "Brand",
    Details: "Details",
    Score: "Exchange points",
    Ratio: "Ratio",
    NumberUtopReceive: "Received Utop points",
    Perform: "Perform",
    React: {
      TitleBar: "Confirm",
    },
  },
  UtopExchangeSelectBrand: {
    alertMsg: "This brand has been added",
    React: {
      TitleBar: "Add brand",
    },
  },
  UtopExchangeSuccess: {
    SuccessfulTransaction: "Transaction successful",
    Text1: "You have performed a successful transaction",
    Text2: "Utop into account",
    React: {},
  },
  UtopQrPaySuccess: {
    Partner: "Merchant",
    SuccessfulTransactions: "Transaction successful",
    PointUtop: "Utop points",
    Details: "Details",
    UtopUsed: "Utop points used",
    Brand: "Brand",
    TradingCode: "Transaction code",
    MainScreen: "Home",
    TradingTime: "Time",
    Branch: "Branch",
    MakeDealAt: "Pay at",
    React: {},
    usedUtop: "Utop was used",
    headerTitle: "Transaction information",
  },
  UTopQR: {
    CurrencyUnit: "VND",
    UtopQrPay: {
      Title: "Enter Utop points",
      Partner: "Merchant",
      Brand: "Brand",
      UtopPay: "Utop payment",
      Utop: "Utop",
      note1: "Equivalent to",
      PaymentRecently: "Recently used",
      Pay: "Payment",
      Total: "Amount",
      HintInputTotal: "Enter amount to pay",
      HintInputUtop: "Enter the number of Utop to pay",
      UtopPoint: "Utop Point",
      Surplus: "Balance",
      NotEnoughMoney: "Balance not enough",
      BudgetSource: "Budget source",
      BudgetCompany: "Company budget",
      From: "Allocator",
      BillImage: "Invoice image",
      ClickToTakeImage: "Click to take a picture of the invoice",
      BillingInformation: "Invoice information",
      TaxCode: "Tax Code",
      Address: "Address",
      NumberGuest: "Number of guests",
      Note: "Notes",
      Guide: "Guideline",
      NotePlaceholder: "Type to add notes",
      Step1: "Step 1: Enter Utop points you want to use",
      Step2: 'Step 2: Click "Pay" to confirm',
      React: {
        TitleBar: "Input Utop points",
        alertMsg1: "Not enough points to pay",
        alertMsg2: "Utop points to pay must be greater than 0",
        alertMsg3: "Please enter the correct number",
        alertMsg4:
          "You cannot pay with this method because there is no budget available.",
        alertMsg5:
          "You cannot pay with this method because you don’t have any VAT invoice information yet.",
      },
      JsonError: "Invalid transaction information.",
      InvalidQrCode: "Invalid QR code.",
      InvalidRestaurant: "Invalid restaurant information.",
      NotYutangRestaurant:
        "Utop currently does not support payment at Yutang restaurant.",
      NotGoldenGateRestaurant:
        "Utop currently does not support payment at Golden Gate partners restaurant.",
      TransactionHadClosed: "The transaction was completed successfully.",
      TransactionNotReadyToPayment:
        "Transaction failed on the current QR code, please rescan the latest payment QR code!",
      TransactionHadConfirmed:
        "Transaction is being processed, please wait a moment!",
      WrongAccountTransaction:
        "The invoice is being processed by another account!",
      WrongGetAccountInfo: "Customer information not found, please try again!",
      InvalidMemberShip: "Member account is not valid to make payment.",
      MessageDefaul: "The QR Code is invalid. Please try again",
      InvalidEmployee:
        "Member account is not valid to make payment at Golden Gate partner.",
      MessageDefaulServer: "Something went wrong, please try again!.",
      TutorialUtopStep1: "Step 1",
      TutorialUtopContent1: "Enter the amount to pay",
      TutorialUtopStep2: "Step 2",
      TutorialUtopContent2: "Choose 1 of 2 payment methods",
      TutorialUtopStep3: "Step 3",
      TutorialUtopContent3: 'Click "Checkout" to confirm',
      TutorialUBStep1: "Step 1",
      TutorialUBContent1: "Enter the amount to pay",
      TutorialUBStep2: "Step 2",
      TutorialUBContent2: "Take a photo of the receipt to continue",
      NotEnoughBudget: "The selected budget balance is not enough",
    },
    UtopQrPayByPoint: {
      TutorialStep1: "Step 1",
      TutorialContent1: "Enter the amount to pay",
      TutorialStep2: "Step 2",
      TutorialContent2: 'Click "Checkout" to confirm',
      Total: "Utop point to pay",
    },
    UtopQrPayComfirm: {
      Title: "Payment Confirmation",
      Brand: "Brand",
      Details: "Details",
      Content: "Content",
      PayAt: "Pay at",
      UtopUsed: "Utop used",
      Perform: "Perform",
      ErrorOccurredWhenPayment: "Something went wrong, please try again!.",
      SuccessPayment:
        'Transaction is successful, please go to "History" to check again.',
      ErrorWhenPayment: "Something went wrong, please try again!.",
    },
    UtopQrPaySuccess: {
      Partner: "Merchant",
      SuccessfulTransactions: "Transaction successful",
      PointUtop: "Utop points",
      Details: "Details",
      UtopUsed: "Utop points used",
      Brand: "Brand",
      TradingCode: "Transaction code",
      MainScreen: "Home",
      TradingTime: "Time",
      Branch: "Branch",
      React: {},
    },
    UtopQrPayResult: {
      Method: "Payment method",
      PayAt: "Pay at",
      UtopPoint: "Utop Point",
      TransactionPending: "TRANSACTION IN PROCESSING",
      TransactionFailed: "PAYMENT FAILED",
      TransactionSuccess: "Payment successful",
      TransactionInfo: "Transaction info",
      TotalPrice: "Total Amount",
    },
  },
  Menu: {
    Personal: "Personal",
    History: "History",
    PersonalInformation: "Personal information",
    InviteFriends: "Invite your friends",
    Information: "Information",
    FAQ: "Frequently asked question",
    Terms: "Terms of use",
    Policy: "Privacy Policy",
    Support: "Support",
    LogOut: "Logout",
    Version: "Version",
    React: {
      TitleBar: "Category",
      TitleAlert: "Utop",
      LabelExit: "Do you want to exit the application?",
      LabelLogout: "Logout of the app?",
      BtnCancel: "CANCEL",
      BtnAgree: "AGREE",
      dialogTitle: "Select sharing methods",
      ShareTitle: "Share link is created",
      ShareMessage:
        "My friend, let's install the Utop application and receive many incentives. Click the link below to register!\n",
      ErrorLogout: "There was an error while logging out.\nPlease try again.",
    },
    thereIsNewRelease: "Version available",
    new: "New",
    update: "Update",
  },
  MenuChangePinCode: {
    OldPassword: "Your old password",
    NewPassword: "Your new password",
    EnterNewPassword: "Verify your new password",
    Btn: "Change Password",
    React: {
      TitleBar: "Change Password",
      TitleAlert: "Utop",
      Alert1: "Change password successfully",
      BtnAlert: "OK",
      errorMsg1: "Your new and current password must be different",
      errorMsg2: "Your new and verify password must be the same",
    },
  },
  MenuFaq: {
    Question1: "What is Utop?",
    Answer1:
      "Utop is a points accumulation application that allows you to accumulate, aggregate points as Utop points and redeem at hundreds of different stores on the merchant system of Utop in Vietnam.",
    Question2: "What is Utop points?",
    Answer2:
      "Utop points are points built on Blockchain - Akachain platform, and used by all stores in the Utop system.",
    Question3: "How can I download Utop app?",
    Answer3:
      "Currently, Utop is available on the App Store, you can go to search for Utop and start downloading.",
    Question4: "Where can I use Utop?",
    Answer4: "You can use Utop at all partners in the Utop system.",
    Question5:
      "Where can I see a list of places where I can earn points and redeem gifts?",
    Answer5: "You can view the list in the app or visit the website",
    Answer5_1: "www.utop.vn",
    Question6: "Does Utop have a card?",
    Answer6: "Currently, Utop is not integrate card",
    Question7: "Do I need to top up my money into Utop?",
    Answer7:
      "You do not need to top up because this is an app to accumulate and redeem membership points. Currently, we don't support this service.",
    Question8: "How can I contact Utop?",
    Answer8: "Currently, you can contact us on F.Studio's hotline:",
    Answer8_1: "1800 6601(ext:4)",
    Answer8_2: "or visit",
    Answer8_3: "Utop Fanpage",
    Answer8_4: "to contact us.",
    Question9: "How can I contact if I want to become Utop merchant?",
    Answer9:
      "If you need to contact us regarding cooperate, you can contact us via email",
    Answer9_1: "sales@utop.vn",
    Question10: "What is Blockchain?",
    Answer10:
      "In short and easy to understand, Blockchain is a technology that allows you to store data and make your transactions secure. Utop has applied this technology to increase the security and safety of transactions for customers.",
    Question11: "How do I register for an account?",
    Answer11:
      "After downloading the application, go to register and enter your phone number, the system will send a verification code. After entering your code, personal information and password, you have completed the account registration section.",
    Question12: "How should I get started?",
    Answer12:
      "After creating your account, you can start by logging in to your F.Studio loyalty account and getting started.",
    Question13: "How can I earn points?",
    Answer13:
      "Please go to the Utop app, click on “Card”, then select the brand that you want to earn points, a barcode/QR code will appear for the cashier to scan.",
    Question14: "How do I get Utop points?",
    Answer14:
      "To earn Utop points, simply go to “Points” on the application, then click on “Top up” and select a partner to redeem points.",
    Question15: "Why should I convert to Utop points? Is it required?",
    Answer15:
      "Utop points are used to redeem gifts at all stores. Therefore, the redemption Utop points is required.",
    Question16:
      "Can I use points if I am not converting points to Utop points?",
    Answer16:
      "At this moment, to use for many different services, you should convert to Utop points.",
    Question17: "How can I convert F.Studio membership points to Utop points?",
    Answer17:
      "To earn Utop points, simply go to “Points” on the application, then click on “Top up” and select F.Studio to redeem points.",
    Question18: "How do I redeem a voucher from a merchant?",
    Answer18:
      "Go to “Gifts” of the application, then choose a voucher of merchants in the category, choose “Redeem” to get a voucher. You need to notice the number of Utop points required below each voucher.",
    Question19: "How do I use my gifts/vouchers I have redeemed?",
    Answer19:
      "Go to “Gifts” and choose the store you want to redeem, then show the cashier to scan your code/QR code or enter a voucher code to confirm your transaction is successful.",
    Question20: "Can I use Utop if I forget to bring my phone?",
    Answer20:
      "If you forgot to bring your phone, you can accumulate points by giving your phone number to the cashier, but to earn and redeem points you still need your phone.",
    Question21: "Where can I see promotions?",
    Answer21:
      "You can see promotions right on the notification screen of the Utop app.",
    Question22:
      "I went to the redemption place but the cashier said “they knew nothing about the campaign”. What should I do?",
    Answer22:
      'If the redemption place is in our system but the cashier says "they knew nothing about the campaign", then please contact us on F.Studio\'s hotline',
    Answer22_1: "1800 6601(ext:4)",
    Question23: "What should I do when my gift code is invalid or error?",
    Answer23:
      "In case of your gift code is errored or invalid, please check the expiry date and maybe it's because of your gift code has used before. If there is not any cases above, you can contact the cashier at the counter or please contact us on F.Studio's hotline.",
    Answer23_1: "1800 6601(ext:4)",
    React: {
      TitleBar: "Frequently asked questions",
    },
  },
  MenuPolicy: {
    Policy1:
      "Utop application is used for exchange F.Studio points to Utop points. Customers can use Utop point to exchange goods/services at redeeming partners.",
    Policy2:
      "Utop points redeemed from F.Studio points will be accumulated and valid until the end of 31/5/2019.",
    Policy3: "Utop points cannot be exchanged or refunded for cash.",
    Policy4:
      "The use points rate corresponding to Vietnam dong (VND) is as follows: 1 point = 1000 VND.",
    Policy5:
      "The conversion rate is set at 1000 Fstudio points = 1 Utop point. The odd parts that cannot be changed are remain in F.Studio",
    Policy6:
      "Customers can change PIN code, personal information or “UPDATE INFORMATION” section on the app.",
    Policy7:
      "Utop uses customer personal information only for internal communications purposes.",
    Policy8:
      "After finishing the registration, customers can use the application to earn and redeem points immediately.",
    Policy9:
      "Each customer is only allowed to own 1 member account. This account is exclusively for registered customers and cannot be shared with family, friends or others for any purpose.",
    Policy10:
      "If you have any question, please contact The Card/Account Support Center at Hotline (028) 3622 0802",
    Policy11:
      "Utop has the right to deactivate a customer's account without notice if we determine that the customer has breached these Terms of Use, or is using the account counterfeitly. In this case, all accumulated points in the account will be forfeited.",
    Policy12:
      "Please keep your account secure. Utop will not take any responsibility if your account is the cause of the dispute.",
    React: {
      TitleBar: "Terms of use",
    },
  },
  MenuTerm: {
    Text1:
      "When using the Utop points system, customers need to provide us your personal information for us to manage on the system, which means customer information must be correct. We need our customers to understand that we will guarantee and responsible for your information on our system.",
    Text2:
      "This policy describes every information we collect, why we must collect it and how we use it and disclose it on our application.",
    Text3:
      "The content of this policy is very important, we hope you will take a little time to read and fully understand it. This policy may be changed from time to time, we recommend that you review this page for the latest information.",
    Text4: "Collection information",
    Text5:
      "When Utop is a function of a membership system, it handles all transactions of all members of the system. To identify one member to other members, we need to collect your different personal information from customers. Besides personal information, we also collect details about your engagement and the level of engagement you achieved.",
    Text6: "Below is a quick overview of the types of information we collect. ",
    Text7: "• User account information: name, phone number and email address.",
    Text8:
      "• Account information of the loyalty customer program: member code, password, current balance and points.",
    Text9:
      "• Transaction information: show detail transactions that use Utop. Example: transaction history, redeem transaction from partners, transaction minus points, ...",
    Text10: "Use information",
    Text11:
      "We will use the customer information provided by the purposes of supporting, maintaining, developing and improving services we provide. We use your information for the following purposes:",
    Text12:
      "• Communicate with customers and inform customers in an easy-to-understand way about the loyalty program.",
    Text13: "• Handling transactions that you required through our system.",
    Text14:
      "• Show transaction information, especially the history of points made by the customer.",
    Text15: "• Improve the quality and performance of the website.",
    Text16: "• Manage and administer our systems.",
    Text17: "• Prevent and combat counterfeiting practices.",
    Text18: "• Meet the specified security requirements.",
    Text19: "Public information",
    Text20: "We may disclose information we collect in the following purposes:",
    Text21:
      "• Used in our loyalty program, handling all requests that you have sent us.",
    Text22:
      "• To a third party, website or other services that you can connect through our system.",
    Text23:
      "• To meet the requirements about the information secure policies that we consider necessary for customers.",
    Text24: "Integrate with third parties",
    Text25:
      "Utop application includes links to other websites, especially social media sites such as: Facebook, Google, Twitter and several other media systems. Social and media features may collect information from your personal site. The websites you are visiting from our website have a cookie set to function. When you access these websites, please note that your information will be managed by their privacy policy. We also encourage you to read and understand the privacy policy they have outlined.",
    Text26: "Caption for “Cookies”",
    Text27:
      "Cookies are small pieces of text that store information on web browsers for archival purposes. We use ID version cookies and also version cookies on the Utop web site. For our website, it is necessary for us to use session cookies to store member information, especially membership number and member name. We also use ID version cookies to facilitate your authentication and allow you to access our resource servers once we have verified that you have the right to do so. Both the Utop version ID and the Utop web version cookie will expire immediately when you close your browser.",
    Text28: "Electronic mail (Email)",
    Text29:
      "Utop uses your correct email address and notifications will be sent by your email in the following processes:",
    Text30: "• Register as a new member of the system.",
    Text31: "• Update your personal information.",
    Text32: "• Sending your questions on our help screen.",
    Text33:
      "• Receive information about promotions and deals from brands in the network",
    Text34: "Implement security regulations",
    Text35:
      "The information transmitted to and from our website / application with your device is using modern standard encryption over SSL protocol. We committed our website using two-level authentication: authenticate our own Web service and use the basic authentication process \"Spring Security. It's important for your to note that no protocol operating over the Internet is 100% secure, so we cannot guarantee absolute security for the transmission of your information.",
    Text36: "Data stored",
    Text37:
      "We will keep your information while your account is active or until we assume it is necessary for maintain our services functional. If for some reason you want to stop using our services and cancel your account so that we can no longer use the information you provided us, please do not hesitate to contact us via the member support information provided on the website (you can access this screen by logging into the system and going to the help screen).",
    Text38: "Contact to us",
    Text39:
      "If you want to request access to Utop or delete your information or have any other questions, regarding our handling of policy information as well as our practices, please contact us (you can access this information by logging into the system and going to the help screen).",
    React: {
      TitleBar: "Privacy Policy",
    },
  },
  UserProfile: {
    Information: "Information",
    Email: "Email",
    EmailPlaceholder: "Enter email",
    EmailFaill: "Email is invalid",
    Password: "Password",
    ChangePassword: "Change password",
    NameUser: "Your name",
    PhoneNumber: "Phone Number",
    PhoneNumberPlaceholder: "Enter phone number",
    btn1: "CANCEL",
    btn2: "SAVE",
    btn3: "AGREE",
    SelectLang: "Select language",
    En: "English",
    Vn: "Vietnamese",
    React: {
      TitleBar: "Personal Information",
      messageUpdateSuccess: "Update successful",
      messageUpdateFail: "Update failed",
    },
    changeAvatarText: "Change your avatar",
    userProfileTitleText: "Personal information",
    popupChangeAvatarOptionsTitleText: "Change your avatar",
    popupChangeAvatarOptionPickImageFromGallery: "Choose from gallery",
    popupChangeAvatarOptionTakeAPicture: "Capture",
    needPermissionToTakeImageAlertTitle:
      "Utop does not have permission to access your Camera",
    needPermissionToTakeImageAlertText:
      "Please allow Utop to access the camera to scan QR code for payments, record videos, take avatars. Your photos and videos will not be shared without your permission.",
    needPermissionToPickImageAlertTitle:
      "Utop does not have permission to access images",
    needPermissionToPickImageAlertText:
      "Please allow Utop to access the image folder/video for purpose of uploading avatars/videos. Your photos and videos will not be shared without your permission.",
    needPickAtLeastOneImageToContinueText:
      "You need select at least 1 image to continue",
    confirmAlertToSaveProfileText:
      "Your information has not been saved, Do you want to save your information?",
    requestTakeImageText: "You must choose at least 1 picture to continue",
    cameraUnavailableText:
      "Photo capture is not currently available on your device",
    pickImageUnavailableText:
      "Select photo from the gallery is not currently available on your device",
    saveInfoButton: "Save",
    connectWithFacebook: "Connect Facebook",
    connectWithAppleID: "Connect Apple ID",
    loginMethod: "Login method",
    manageAccountTitle: "Manage card/account",
    InputPhoneNumber: "Enter your phone number",
    InputEmail: "Enter your email",
    ManagerCard: "Manage card/account",
    ChangePin: "Change PIN",
    Authentication: "Fingerprint/FaceId authentication",
    MethodLogin: "Login method",
    ConnectAppleID: "Connect Apple ID",
    ConnectFacebook: "Connect Facebook",
    SaveInfo: "Save",
    AuthenticationFaceID: "FaceId authentication",
    AuthenticationFingerprint: "Fingerprint authentication",
    DeviceNotSupport: "Device does not support biometrics",
    UpdateSuccess: "Update successful.",
    ErrorUpdate: "Something went wrong, please try again!.",
    ErrorEmail: "Email is not correct!",
    UnLinkAccountFB:
      "Do you want to unlink your Facebook account with your Utop account?",
    LinkAccountAppleSuccess: "You have successfully linked your Apple account",
    LinkAccountAppleFail: "There was an error while linking to the AppleId.",
    UnLinkAccount: "You have successfully removed the account %{title}.",
    UnLinkAccountError: "Something went wrong, please try again!.",
    confirmUnlinkFacebook:
      "Do you want to unlink your Facebook account with your Utop account?",
    confirmUnlinkApple:
      "Do you want to unlink your Apple account with your Utop account?",
    successLinkToApple: "You have successfully linked your Apple account",
    successLinkToFacebook: "You have successfully linked your Facebook account",
    somethingWrongWhenLinkToAppleId:
      "Something went wrong while linking to the AppleId",
    somethingWrongWhenLinkToFacebook:
      "Something went wrong while linking to the AppleId.",
    facebookAccountBeLinkedWithAnotherAccount:
      "This Facebook account is already linked to another user account.",
    successUnlinkTitle: "You have removed the account link",
    success: "Success.",
    somethingWentWrongWhenUnlink: "Something went wrong while linking account",
    tryAgain: ", please try again.",
    somethingWhenWrongWhenUpdate: "Something went wrong, please try again!.",
    successUpdateProfile: "Update successful.",
    TextPermission:
      "FaceID/fingerprint setting has been disabled. Please enable this feature again in your settings.",
    TextPermissionTouchID:
      "Your fingerprint/faceID settings are temporarily locked.",
    UpdatePinSuccess: "You have successfully changed your PIN Code.",
    CreatePinSuccess: "You have created a PIN Code successfully.",
    ErrorLogin:
      "There was an error while logging out.\nPlease try again later.",
    CreatePINCode: "Generate PIN Code",
    TextAskPermissionAndroid:
      "Use fingerprint to confirm payment quickly and conveniently.",
    TextLater: "LATER",
    TurnOn: "TURN ON",
    TextIgnore: "SKIP",
    TextSetting: "OPEN SETTINGS",
    UsingPrintFinger: "Use fingerprint for authentication",
  },
  GiftDetail: {
    Gift: "Gift",
    Change: "Redeem",
    ExpiryDate: "Expiry date",
    Utop: "Utop",
  },
  GiftDetailComfirm: {
    Brand: "Brand",
    Details: "Information details",
    NumberUtopUsed: "Utop number used",
    Program: "Campaign",
    Perform: "Perform",
    React: {
      TitleBar: "Redemption confirmation",
    },
    voucherRedeemConfirm: "Voucher redemption confirmation",
  },
  GiftRedeemSuccess: {
    label1: "Successful redemption",
    label2: "You have successfully executed the transaction",
    label3: "Utop for campaign",
    label4: "Information details",
    label5: "Current Utop points",
    label6: "Utop used",
    label7: "Brand",
    label8: "Transaction code",
    label9: "Redeem now",
    label10: "Main screen",
  },
  GiftDetailList: {
    GiftEmpty: "Your gifts are empty",
  },
  Intro: {
    label1: "Combine accumulated points",
    label2: "Redeem valuable gifts",
    label3:
      "Utop is a type of electronic point accumulation wallet, you can freely accumulate your points with any sales unit but also accumulate points.",
    label4: "Unlimit gifts redemption unlimit promotions",
    label5:
      "Hunt for super attractive promotions up to 60% only for Utop members.",
    label6: "Fast point accumulation - free to shopping",
    label7:
      "No worry about losing cards, Utop application helps you accumulate points quickly, check your rewards immediately.",
    label8: "Ready to accumulate points, anytime and anywhere.",
    label9:
      "With Utop, an electronic card will replace the other physical card you are using. Saving time, convenient transaction.",
    label10: "Skip",
    label11: "Next",
    label12: "Complete",
  },
  login: {
    note: "Enter your phone number to continue",
    placeholder: "Phone Number",
    label3: "I agree with Utop",
    termAndCondition: "Terms of use",
    label1: "By using Utop, you agree to",
    loginBySocial: "Login with",
    label4: "Terms of Use and Privacy Policy",
    continueButton: "Continue",
    IncorrectNumber: "Your phone number is not valid",
    titleBar: "Login/Register",
    and: "and",
    privacy: "Privacy Policy",
    label2: "of Utop",
    React: {
      TitleBar: "Login/Register",
    },
    IncorrectEmail: "Email is not valid",
    cannotUpdateEmail: "Email could not be updated, please try again.",
    cannotUpdateName: "Fullname could not be updated, please try again.",
    OtherLogin: " Or login with",
  },
  Login: {
    label1: "Enter your phone number to continue",
    label2: "Phone Number",
    label3: "I agree with Utop",
    label4: "Terms of Use and Privacy Policy",
    label5: "Continue",
    IncorrectNumber: "Your phone number is not valid",
    React: {
      TitleBar: "Login/Register",
    },
    IncorrectEmail: "Email is not valid",
    cannotUpdateEmail: "Email could not be updated, please try again.",
    cannotUpdateName: "Fullname could not be updated, please try again.",
    OtherLogin: " Or login with",
    signInWithAppleLabel: "Login with Apple",
    signInWithFacebookLabel: "Login with Facebook",
  },
  LoginPolicy: {
    label1:
      "Utop application is used for exchange F.Studio points to Utop points. Customers can use Utop point to exchange goods/services at redeeming partners.",
    label2:
      "Utop points redeemed from F.Studio points will be accumulated and valid until the end of 31/5/2019.",
    label3: "Utop points cannot be exchanged or refunded for cash.",
    label4:
      "The use points rate corresponding to Vietnam dong (VND) is as follows: 1 point = 1000 VND.",
    label5:
      "The conversion rate is set at 1000 Fstudio points = 1 Utop point. The odd parts that cannot be changed are remain in F.Studio",
    label6:
      "Customers can change PIN code, personal information or “UPDATE INFORMATION” section on the app.",
    label7:
      "Utop uses customer personal information only for internal communications purposes.",
    label8:
      "After finishing the registration, customers can use the application to earn and redeem points immediately.",
    label9:
      "Each customer is only allowed to own 1 member account. This account is exclusively for registered customers and cannot be shared with family, friends or others for any purpose.",
    label10:
      "If you have any question, please contact The Card/Account Support Center at Hotline (028) 3622 0802",
    label11:
      "Utop has the right to deactivate a customer's account without notice if we determine that the customer has breached these Terms of Use, or is using the account counterfeitly. In this case, all accumulated points in the account will be forfeited.",
    label12:
      "Please keep your account secure. Utop will not take any responsibility if your account is the cause of the dispute.",
    React: {
      TitleBar: "Terms of use",
    },
  },
  VerifyOTP: {
    label1: "A verification code has been sent to",
    label2: "Support",
    label3: "Continue",
    React: {
      TitleBar: "Verify phone number",
    },
    otpExpired: "OTP expires in",
  },
  CreatePinCode: {
    label1: "Create a password with 6 numbers",
    label2: "for the next login",
    label3: "Enter password",
    label4: "Verify password",
    label5: "Continue",
    React: {
      TitleBar: "Create password",
    },
    Authentication: "Fingerprint / face authentication",
    TextContent: " Ensure the safety for all of your transactions",
    TextCreatePin: "Create PIN Code",
    Continue: "Accept and continue",
    SkipNext: "Ignore PIN Code and continue",
    InputPin: "Enter PIN Code",
    ConfirmPin: "Confirm PIN Code",
  },
  VerifyPinCode: {
    label1: "Hello",
    label2: "Please enter password for",
    label3: "Forgot password",
    label4: "Support",
    label5: "Login",
    React: {
      TitleBar: "Enter password",
    },
    OTPError: "Your OTP is invalid or expired",
    ErrorConnect: "There was an error while linking to ",
    LinkAnotherAccount: "This phone number has been linked to another account.",
    ConfirmNumberPhone: "Verify phone number",
    INPutOTP: "Please enter the OTP was sent via SMS to the phone number ",
    ResendOTP: "Resend OTP",
    Continue: "Continue",
    Complete: "Complete",
    SupportText: "Hello, can Utop help you?",
    ResendOTPText: "I have not received an OTP. Call me",
    ChangeNumberPhone: "I want to change my phone number",
    CallCenter: "Call support 1900 636 997",
    SupportOnline: "Online support",
    QuestOTP: "Didn't receive an OTP?",
    UtopCall: "Utop will call you to read the OTP authentication code.",
    Close: "Close",
    Confirm: "Agree",
    WaitCall: "Please wait a moment Utop will call you",
  },
  ForgetPin: {
    label1: "The verification code will be send to this phone number",
    label2: "Phone number",
    label3: "Enter verification code",
    React: {
      TitleBar: "Forgot password",
    },
  },
  ChangePinCode: {
    ForgotPin: "Forgot PIN code",
    InputPin: "Enter PIN",
    ChooseMethodSupport: "Select supporting method",
    SupportOnline: "Online support",
    Cancel: "Cancel",
    Call: "Call",
    TextChangePinCode: "Change PIN code",
    TextCreatePinCode: "Create PIN code",
    InputOldPin: "Enter old PIN",
    InputNewPin: "Enter new PIN",
    ConfirmNewPin: "Confirm new PIN",
    Continue: "Continue",
    Confirm: "Confirm",
    TitleNotify: "Utop",
    ErrorText: "Please enter PIN code",
    TextConfirm: "Invalid PIN Code",
    SupportText:
      "You have entered the wrong PIN code more than 5 times. \nPlease contact the call center for assistance",
    InputAgain: "Something went wrong, please try again",
    Fingerprint: "Fingerprint authentication",
    FaceID: "FaceID authentication",
    NotSupport: "Non-biometric equipment not support",
    NotTurnOnFace: "The device has not turned on authentication",
    NotFullInfo: "You have not entered full information",
    ChangePinSuccess: "Change pin success",
    CountError: "You entered the wrong PIN. You have %{count} re-entered",
    ErrorPinCode: "An error occurred. Please try again later",
    ErrorLengthPin: "Your PIN CODE must be at least 6 characters!",
    ErrorConfirmPin: "Your confirmation PIN CODE is incorrect!",
    ContactCallCenter: "Please contact the hotline",
    ToSupports: "for support",
    CallNow: "Call now",
    NewPinSameOldPin: "The new PIN CODE cannot be the same as your current PIN",
    Close: "Close",
    OK: "Agree",
  },
  PaymentPlus: {
    BuyPoint: "Buy point",
    TitleNotify: "Utop",
    ContinuePay: "Continue pay",
    SumMoney: "Total",
    InputAmountUtop: "Enter the amount of Utop to pay",
    ChooseMethod: "Select support method",
    Receiver: "Receiver",
    TextLocked:
      "Your account is temporarily locked switching points function! Please contact 1900636997 for assistance.",
    ErrorPin:
      "You entered the wrong PIN CODE. You have %{remainingTime} re-entered.",
    ConfirmTranfer: "Confirm transaction",
    StatusError: "Invalid membership status",
    OverPoint:
      "The number of Utop points you requested to transfer exceeded the specified number of the day, the remaining Utop points allowed to be transferred are: %{remainingTime} Utop",
    CannotTransferPoint:
      "Unable to transfer to %{numberPhone}. Please contact 1900636997 for assistance",
    NotEnoughPoint: "You do not have enough Utop points to transfer",
    ErrorText: "An error occurred while processing. Please try again.",
    Continue: "Continue",
    ForgotPin: "Forgot PIN CODE",
    AmountUtopPoint: "Please Enter the amount of Utop to pay!",
    RemindText: "Please enter the correct number",
    TitleConfirmPoint: "Confirm payment",
    Confirm: "Confirm",
    SumMoneyPay: "Total Payment",
    UtopPointNeedBuy: "Utop point need to buy",
    OK: "OK",
    Cancel: "Cancel",
    ErrorConnectServer: "An error occurred while connecting to the server.",
    ExchangeUtop: "Exchange Utop",
    ChangePoint: "Change point",
    ChangeOfMerchant: "Exchange affiliates points to Utop points",
    BuyPointByCard:
      "Buy Utop points with MoMo or ATM/ VISA/ MASTER/ JCB or at \nConvenience Store",
    TextChange: "Transfer Utop points to friends and relatives",
    ForwardPoint: "Forward point",
    HI: "Hi",
    TransactionSuccess: "Transfer Utop points successfully!",
    TransactionCode: "Transaction code",
    Message: "Message",
    InputMessage: "Input message",
    UtopTransfer: "Transfered Utop points",
    BackHome: "Back home",
    TextSupport: "Please check or call support \ncall center of Utop 19001000.",
    BackTransfer: "Back to transfer",
    TitleError: "An error occurred!",
    TransferSuccess: "Transfer successfully",
    TransferFail: "Transfer unsuccessfully",
    Equivalent: "Equivalent",
    InputNumberPhoneReceiver: "Receiver phone number",
    InputNumberPhone: "Phone number",
    RecentRecipients: "Recent recipients",
    ExChangePoint: "Transfer points",
    AmountUtopNeedBuy: "Number of Utops to transfer",
    ConfirmPinCode: "Confirm PIN CODE",
    InputPinCode: "Enter PIN CODE",
    ConfirmAuth: "FaceID/Fingerprint authentication",
    SkipNext: "Skip PIN CODE and continue",
    ConfirmSkipNext: "Are you sure you don’t need to create a PIN CODE?",
    Title: "Generate PIN CODE",
    MissInfo: "Please enter full information",
    ErrorConfirmPin: "Confirmation PIN CODE does not match",
    RemindInputPin: "Please enter PIN CODE",
    StatusMemberError: "Member status is invalid",
    InputPINCodeConfirm: "Enter PIN CODE to confirm",
    MaxPoint: "Up to 1000 Utops can only be transferred per transaction",
    TitleText: "Secure all your transactions",
  },
  FirstLogin: {
    title: "Fill your personal information",
    label1: "Fill your personal information,",
    label2: "to recover your password",
    label3: "Your name",
    placeholderFullName: "Enter your first and last name",
    label4: "Email address",
    placeholderEmail: "Enter your email account",
    labelPhoneNumber: "Phone number",
    placeholderPhoneNumber: "Enter your phone number",
    continueButton: "Continue",
    label5: "Complete",
    React: {
      TitleBar: "Personal information",
    },
  },
  Card: {
    label1: "Click to create new card",
    label2: "There is no card",
    React: {
      TitleBar: "My cards",
    },
  },
  Notifications: {
    React: {
      TitleBar: "Notifications",
    },
  },
  Gift: {
    label1: "Your gifts",
    label2: "Gifts used",
    label3: "Expiry date",
    label4: "Your gifts is empty",
    React: {
      TitleBar: "Gifts",
    },
  },
  GiftDetailBarCode: {
    label1: "Please use this code when making transaction",
    label2: "PIN Code",
    label3: "View details",
    label4: "Used",
  },
  ReactUtils: {
    ExpiredSession:
      "Your login session has expired. Do you want to login again?",
    ErrorNetwork:
      "An error occurred while connecting to the server. Please check your network connection and try again. ",
    SupportMethod: "Select the support methods",
    Cancel: "Cancel",
    NumberPhoneSupport: "Call 1900636997",
    SupportOnline: "Online support",
    UserNotFound: "This user has not used Utop.",
    CanNotChangePoint: "Cannot transfer points to yourself",
  },
  MobileCard: {
    titleHeader: "Top-up card",
    labelMobileCardTab: "Prepaid recharge",
    labelBuyCardTab: "Buy card",
    labelTotalUtop: "Total Utop: ",
    lablePaymentButton: "Pay",
    lableOptionPrice: "Price options",
    lablePhoneNumber: "Phone number",
    validPhoneText: "Phone number is invalid",
    changeTect: "CHANGE",
    enterPhoneText: "Enter phone number",
    selectNetwork: "Select home network",
  },
  ProductConfirm: {
    titleHeader: "Pay",
    labelNumberPhone: "Phone number",
    labelNetwork: "Home network",
    labelPrices: "Price",
    labelTotalUtop: "Number of points Utop pays",
    labelContinuePayment: "Continue payment",
  },
  StoreDetail: {
    labelExpiredDate: "Expired date: ",
    labelSaleProduct: "coupon",
    labelSuggestCouponButton1: "Got",
    labelSuggestCouponButton2: "Get Code",
    labelMedium1: "Average:",
    labelMedium2: "/person",
    labelOpenStore: "Opening",
    labelCloseStore: "Closing",
    labelComeBackTime: "Come back at ",
    labelBookButton: "ORDER",
    labelUseAtShop: "ORDER",
    labelDelivery: "DELIVERY",
    labelBestSeller: "Best seller",
    alertSuccessCoupon:
      'Get coupon code successfully, please enter "My voucher" to see detail',
    labelVoucherList: "Deal/Voucher",
    errorDuplicateTransaction:
      "The system is processing, please try again later.",
    errorOverMaxSet: "The number of coupons of the program is over.",
    errorOverMaxSetPerUser: "You are out of turn to get coupon.",
    errorNotFoundCouponCampaign:
      "Invalid coupon code, please check or try another code.",
    errorInvalidCouponCampaginPeriod: "Coupon receiving time has expired.",
    viewMore: "View more",
    listVideo: "Video list",
    errorStore: "The store is currently closed. Please come back later.",
    labelRequireLogin: "Please login to fully use features of Utop.",
    no: "No",
    yes: "Yes",
    internalServerError: "System error, please try again later.(",
    ShareMessage1: "You are introduced to the experience",
    ShareMessage2:
      " on the Utop app. Click the link below to register and receive special offers.\n",
    takenCoupon: "Claimed",
    takeCoupon: "Claim",
    ratingOptionEdit: "Edit",
    ratingOptionDelete: "Delete",
    ratingOptionCopy: "Copy",
    ratingOptionReport: "Report",
    seeMore: "View more",
  },
  OrderStatus: {
    titleHeader: "Order Information",
    labelDiscount: "Discount",
    shippingMethodNameShop: "Pick up at store",
    shippingMethodNameHome: "Delivery",
    labelProcessing: "(Order processing)",
    labelAwaitConfirm: "(Wait for confirmation)",
    labelOrderFinished: "Your order has been completed",
    labelAwaitConfirmUser: "(Waiting for customer confirm)",
    labelOutStock: "Out of stock",
    labelOrderCancel: "Your order has been cancelled",
    labelOutStockProduct: "Out of stock",
    labelReOrder: "Reorder",
    labelOrderCancel1: "Cancel your order",
    labelConfirm: "Confirm",
    labelRePayment: "Repayment",
    labelRating: "Ratings & Reviews",
    labelOrdered: "Ordered",
    labelCanceled: "Cancelled",
    labelOutStock2: "Out of stock",
    labelAvailable: "Ready to deliver",
    labelAvailableHome: "Delivering",
    labelConfirmed: "Check-in",
    labelConfirmedHome: "Confirmed",
    labelDelivered: "Delivered",
    labelOrderDetail: "Order Details",
    labelOrderCode: "Order Code",
    labelOrderTime: "Time Order",
    labelShippingMethod: "Delivery Methods",
    labelAddress: "Address",
    labelReceiver: "Receiver",
    labelOrderNote: "Notes",
    labelPaymentMethod: "Payment Methods",
    labelSttOrder: "Order no",
    labelTotalOrder: "Subtotal",
    labelTotalOrder1: "part",
    labelShippingFee: "Shipping Fee",
    labelTotalPrice: "Total Payment",
    labelPayment: "PAID",
    labelNotPayment: "UNPAID",
    Rating: {
      labelRating: "Thank you for shopping at Utop",
      contentRating:
        "Your review will be a suggestion for other customers to refer to and a motivation to help Utop improve. Let Utop know how you feel!",
    },
    Notification: {
      labelSetting: "Notification settings",
      contentSetting:
        "Please turn on notifications to receive order information",
    },
    contentRemindRating: "You cannot evaluate service quality after 30 days!",
    labelConfirmCancelOrder: "Do you want to cancel this order?",
    labelConfirmCancelPendingItem: "Do you want to continue this order?",
    labelSuspenOperation: "The store is currently closed.",
    labelCancel: "CANCEL",
    labelAgree: "AGREE",
    modalQRCode: {
      orderCode: "Order code",
    },
    labelShippingDiscount: "Shipping Discount",
    labelDiscountCode: "Promo code",
    labelSearchDriver: "Searching for a driver...",
    labelViewDetailMap: "View details",
    labelInprocessDelivery: "Delivering",
    labelSuccessDelivery: "Delivered",
    labelDriverReceiveOrder: "Driver receives your order",
    labelDeliveryFail:
      "Delivery failed. Your order is being processed by the store",
    labelUnpaid: "Unpaid",
    labelPaymentBefore:
      "Please pay in 30 minutes. Otherwise your order will not be allowed to pay.",
    labelOrderWillCancel: ". Otherwise your order will be cancelled.",
    labelShippingFeeExpected: "Estimated Delivery Fee",
    labelOk: "OK",
    labelInvalidUserName: "Recipient name cannot be empty",
    labelInvalidPhoneNumber: "Invalid phone number",
    labelReceivedOrder: "Received",
    labelDeliveryTime: "Estimated Delivery Time",
    labelHintReOrder: "Re-Order",
    labelHintContent: "The order has expired. Please re-order!",
    ratingStore: "Store Ratings & Reviews",
  },
  UtopDeliveryAddressBook: {
    labelAddressDefault: "Default address",
    labelChooseAddress: "Selected delivery address",
    labelEmptyAddress: "The delivery address is empty",
    screenName: "Delivery Address",
  },
  SmartOrderMenu: {
    createNewCart: "Create new cart?",
    labelAddNewCart:
      "Adding this product will remove the current products in your cart. Do you want to continue?",
    outStockProduct: "Out of stock",
    openStore: "Opening",
    closeStore: "Closing",
    availableShip: "Shipping available",
    onlyReceiveAtTheStore: "Receive at store only",
    threeStepOrder: "Three step order",
    selectOrder: "Select order",
    checkinAtStore: "Check-in at store",
    label1: "Enjoy",
    labelLoadingData: "Loading data...",
    labelNotFoundResult: "No results",
    viewCart: "View cart",
    InformChangeCart:
      "Some of the products you ordered have been changed or no longer served!",
    call: "Call",
    cancel: "Cancel",
    modalOrderList: {
      labelNoOption: "No options",
      labelChange: "Change",
      labelAddOption: "Add option",
      labelClose: "Close",
      labelDelete: "Delete",
    },
    labelWaitConfirm: "Waiting for approval",
    labelWaitReceived: "Waiting for receiving",
    labelRecentOrder: "Recently Order",
    labelShop: "ORDER",
    labelHome: "DELIVERY",
    someProductHasChangedOrStopService:
      "Some of the products you ordered have been changed or no longer served!",
    notLoginYet: "Login required!",
    loginToUseAllFuncOfUtop: "Please login to fully use features of Utop.",
    no: "No",
    yes: "Yes",
    systemIsHandling: "The system is processing, please try again later.",
    outOfCoupon: "The number of coupons of the program is over.",
    outOfTurnToGetCoupon: "You've run out of turn to get coupons.",
    couponCodeIsNotValid:
      "Invalid coupon code, please check or try another code.",
    outOfTimeToGetCoupon: "Coupon receiving time has expired.",
    systemError: "System error, please try again later.",
    createNewCartQuestion: "Create new cart?",
    createNewCartQuestionDescription:
      "Adding this product will remove the current products in your cart. Do you want to continue?",
    close: "Close",
    continue: "Continue",
    contactToShop: "Contact to shop",
    labelTheStoreIsUpdating: "The store is updating.",
    labelYes: "AGREE",
    ShareMessage: "",
    viewMore: "View more",
    SupportOrderModal: {
      guide: {
        title1: "This is the order form and",
        title2: "go to the counter",
        title3: "Please note the steps below:",
      },
      section1: {
        title1: "Choose a store, order and pay",
        title2: "to save time queuing",
      },
      section2: {
        title1: "After completing the order, move",
        title2: "go to the store to check-in",
      },
      section3: {
        title1: "Confirm orders placed on Utop",
        title2: "at the cashier to be served",
      },
    },
  },
  SmartOrderItemModal: {
    label1: "Please select size",
    label2: "Please select ",
    label3: "Options ",
    label4: "(select 1)",
    label5: "Cart update",
    label6: "Add to cart",
    label7: "Select size: ",
    label8: "Optional extras: ",
    label9: " (not required to select)",
    label10: "Amount",
  },
  Cart: {
    errorNotFoundCouponCampaign:
      "Promotion code is invalid, please check again or try another code.",
    takeaway: "takeaway",
    table: "Get food at the table",
    shop: "Pick up at the counter",
    home: "Delivery",
    PointUtop: "Utop point",
    labelErrorInvalidTypeOfCoupon:
      "Coupon is not valid, please check again or try another code.",
    labelErrorCouponIsUsed:
      "Coupon has been used. Please choose another coupon.",
    labelErrorCouponExpired:
      "Coupon has expired. Please choose another coupon.",
    labelErrorLessThanMinValueRequired:
      "The order value is not enough to use the coupon. Please check your order again or choose another coupon.",
    labelErrorOverMaxSet:
      "The number of coupon of the program has expired. Please choose another coupon.",
    labelErrorOverMaxSetPerUser: "You have run out of coupons.",
    labelErrorInvalidPaymentMethod:
      "Coupon in use does not apply to the payment method you choose. Please check your order again or choose another coupon.",
    labelErrorInvalidStoreOfCoupon:
      "Coupon does not apply to this store. Please choose another coupon.",
    labelErrorInvalidOrderType:
      "The applicable product type is not suitable. Please check your order again or choose another coupon.",
    labelErrorNotFoundProduct:
      "The order has a product that has been discontinued, please remove it from the cart.",
    internalServerError: "Something went wrong. Please try again!",
    labelConfirm: "Confirm",
    labelNo: "No",
    labelYes: "Yes",
    labelSelectOtherCoupon: "Do you want to choose another coupon?",
    labelEmptyCart: "Your cart has no products.",
    labelEmptyMethodDelivery: "Please choose a delivery method.",
    labelEmptyNumberTable: "Please enter the table number.",
    labelInvalidCoupon:
      "Coupon is not valid! Do you want to continue this order?",
    labelChange: "Change",
    labelOrderMore: "Do you want more dishes?",
    labelTotal: "Subtotal (",
    label1: "part)",
    labelPaymentPrice: "Amount",
    labelSelectCoupon: "Add Coupon",
    labelInputNote: "Would you like to add a note to your order?",
    labelSelectMethodDelivery: "Choose a delivery method",
    labelTakeAway: "Take away",
    labelInputNumberTable: "Enter the table number is required",
    labelPickTime: "Pick up time",
    labelOrder: "Continue to pay",
    label3: "Come get it now",
    label4: "Timer ",
    label5: "Delivery Method",
    labelAddNote: "Add notes",
    labelCancel: "Cancel",
    labelDone: "Done",
    labelInputNumberTablePlaceholder: "Enter number of table",
    labelInputNotePlaceholder: "Notes",
    labelDelete: "Delete",
    labelSelectAddress: "Select shipping address",
    labelNumberTable: "Number of tables",
    labelPickupTime: "Timer",
    screenName: "Cart",
    labelPayment: "Subtotal ",
    labelPayment1: " part",
    labelShippingFee: "Shipping Fee",
    labelTotalPayment: "Total Payment",
    labelInvalidAddress:
      "Invalid address. Do you want to choose another address?",
    labelClose: "Close",
    deliveryAtTable: "Delivery at table: ",
    shippingDiscount: "Shipping discount",
    labelBuyMore: "Buy more",
    labelToDiscount: " for discount",
    labelDiscount: "Decrease ",
    labelShippingFeeFrom: " delivery fee for order from ",
    labelOrderChange: "The order has been updated. \n Please check again.",
    timePickup: "Pick up time",
    today: "Today",
    pickupImmediately: "Get it now",
    bookTime: "Booking",
    outOfCouponUsingTurn: "Coupon has expired",
    isOutOfStock: "Out of stock",
    labelEstimateDelivery: "Expected Delivery",
    labelMon: "Mon",
    labelTue: "Tue",
    labelWed: "Wed",
    labelThu: "Thu",
    labelFri: "Fri",
    labelSat: "Sat",
    labelSun: "Sun",
    labelToday: "Today",
    labelAtComma: ", at ",
    labelAt: " at ",
    labelAdvice: "Advice on choosing goods via video call",
    labelManager: "The Market Managerâ€˜ll call u when preparing your order",
    Guide: {
      on: "on",
      label:
        "this function, the Market Admin will call you when choosing products",
    },
  },
  MapDirection: {
    showDirectionText: "Directions",
    openWithGoogleMapText: "Open with Google Map",
    openWithAppleMapText: "Open with Apple Map",
    headerTitleText: "Guide to location",
    descriptionTitleShopText: "Store position",
    descriptionTitleUserText: "My position",
    openGoogleMapButtonText: "Open maps",
    downloadGoogleMapButtonText: "Download now",
    cancelGoogleMapButtonText: "Cancel",
    utopWantToOpenGoogleMapText: "Utop wants to open Google Map",
    utopGoogleMapNotAvailableText: "Please install Google Map",
    unknownUserAddress: "Unknown address",
    alertCanNotGetAddressFromLatLong: "Cannot determine your current address",
    descriptionDirection:
      "The distance shown is calculated based on the straight \n line. Actual travel distance may different.",
  },
  ListStore: {
    titleText: "List of stores",
  },
  preLogin: {
    loginTitle: "Login",
    viewAsGuestTitle: "View as guest",
  },
  barCodeScan: {
    description: "Move the camera to scan the QR code placed at the cashier.",
    requestPermissionCamera: "Please allow Camera access",
    invalidQRCodde:
      "The QR Code is not in the correct format. Please try again",
    agree: "Agree",
    cancel: "Cancel",
  },
  menuProfile: {
    personalText: "Personal",
    personalInformationText: "Personal Information",
    historyPointUtopText: "Utop point history",
    inviteFriendText: "Invite friend",
    informationTitle: "Information",
    commonQuestionText: "FAQ",
    policyText: "Privacy Policy",
    termAndConditionText: "Terms of Use",
    supportText: "Support",
    multiLanguageText: "Language",
    loginText: "Login",
    logoutText: "Logout",
    languageBottomSheetTitle: "Language",
    vietnameseLanguage: "Vietnamese",
    englishLanguage: "English",
    japaneseLanguage: "Japanese",
    currentVersion: "Current version",
  },
  MenuHistoryOrder: {
    screenName: "Transaction History",
    pickDay: "Pick date",
    labelStatusNew: "Unpaid",
    labelStatusPayment: "Order Successfully",
    labelStatusWait: "Waiting for approval",
    labelStatusConfirmed: "Preparing",
    labelStatusAvailable: "Ready",
    labelStatusDelivered: "Completed",
    labelStatusCancel: "Cancelled",
    labelEmptyData: "No data",
    labelOrderCode: "OrderID:",
    labelOrderStatus: "Order status",
  },
  ConfirmOrderFnB: {
    screenName: "Confirm Order",
    receiver: "Receiver",
    paymentMethod: "Payment method",
    note: "Notes",
    address: "Address",
    labelTotal: "Subtotal ",
    labelTotal1: " part",
    shippingFee: "Shipping Fee",
    applyDiscount: "Apply discount code",
    totalPrice: "Payment amount",
    paymentContinue: "Continue to pay",
    buyTakeOut: "Buy and take away",
    deliveryAtTable: "Delivery at the table: ",
    labelError: "Something went wrong. Please try again!",
    someProductsOutofStock:
      "Some of the products in the order have been discontinued",
    contactSupport:
      "Your account is temporarily blocked from using Coupon. Please contact Hotline for support",
    couponIsUsed: "Coupon code already used!",
    couponExpired: "Coupon code has expired!",
    textOk: "OK",
    buyTakeOut1: "[Buy and take away] ",
  },
  paymentSelect: {
    helloText: "Hello",
    changePointText: "Redeem point",
    changePointNote: "Redeem point of partner for Utop point",
    buyPointText: "Buy point",
    buyPointNote:
      "Buy Utop point by MoMo or ATM/ VISA/ MASTER/ JCB or at convenience store ",
    transferPointText: "Point transfer",
    transferPointNote: "Point transfer Utop for friends and family",
  },
  myVoucherDetail: {
    expiredDateText: "Expired",
    showCodeText: "Show product code",
    defaultSelectVoucherNote:
      "By default, the voucher is selected with an expiration date approaching.",
    otherInformationText: "Other information",
    usingAddressText: "Usable locations",
    showValidVoucherToPaymentNote:
      "Present valid E-voucher code at checkout to check and confirm.",
    usageAndNote: "Usage and Notes",
  },
  myVoucherCode: {
    expiredDateText: "Expired",
    usedText: "Used",
    merchantCodeText: "Merchant code",
    copyBarcodeNumberText: "COPY BARCODE NUMBER",
    voucherCodeText: "Voucher code",
    copyText: "COPY",
    markUsedText: "Mark used",
    loadNowText: "Top-up",
  },
  SuggestCouponFnB: {
    currentGoodWill: "Offers available",
    takeCoupon: "Claim",
    takenCoupon: "Claimed",
  },
  RefundVoucher: {
    titleHeader: "Refund Voucher",
    errMsg: {
      NotFoundVoucher: "Voucher not found.\n Please try again!",
      VoucherCannotMarkRefunded: "Something went wrong. Please try again!",
      InvalidCount:
        "The number of refund vouchers is not correct.\n Please try again!",
      errorMessage: "Something went wrong. Please try again!",
    },
    React: {
      txtAmountVoucher: "Amount",
      txtAmountUtopRefunded: "Utop point will be refunded",
      txtUtop: "Utop",
      txtConfirm: "Confirm",
    },
    PopupConfirm: {
      txtConfirmRefund: "Are you sure you want to refund",
      txtVoucher: "this",
      txtConfirmRefund2: "Voucher?",
      btnAccept: "Agree",
      btnCancel: "Cancel",
    },
    PopupResult: {
      txtRefundSuccess: "Successful transaction",
      txtRefundFail: "Failed to transaction",
      txtSuccess: "You are refunded",
      txtSuccess2: " from voucher",
      txtUtop: "Utop",
      txtDone: "Done",
      txtClose: "Close",
    },
    labelExpiredDate: "Expired: ",
  },
  UtopCardManager: {
    titleHeader: "Manage card/account",
    cardBank: "Bank card",
    cardMember: "Employee / member card",
    addCardbank: "Add card / account",
    addCardMember: "Add employee/member card",
    date: "day",
  },
  UtopCardConfirm: {
    titleHeader: "Card link",
    txtConfirm: "Confirm",
    txtDetail: "Press “Confirm” to link the employee card with the app",
    txtDetail2: "*The payment amount is directly through the application",
    txtHotline1: "Please contact hotline ",
    txtHotline2: " for assistance.",
    txtDetail4: "Go to section",
    txtDetail5: "Manage card/account",
    txtDetail6: "to view details.",
    txtLimit: "*Level",
    limitUtop: "Utop point",
    txtEquivalent: "equivalent",
    txtMoney: ".000d/day.",
    txtError: "Something went wrong",
    txtCancel: "Cancel",
    txtWatchNow: "View",
    txtLinkedWithCurrentUser: "The card has been linked to this account",
    txtQrExpired: "The QR code has expired",
    txtQrExpiredDetail:
      "Do you want to scan another QR Code to make card association with Utop app?",
    contactToShop: "Support",
    txtCallNow: "Call",
  },
  UtopCardDetail: {
    titleHeader: "Detail card / account",
    bankName: "Bank name",
    userName: "Account name",
    cardNumber: "Card Number",
    cardExpire: "Expiration date",
    cancelLink1: "Unlink card",
    cancelLink2: "Unlink",
    confirmCancelLink: "Are you sure you want to unlink this card?",
    confirmLabel: "Confirm",
    cardName: "Name card",
    IDCard: "Id card",
    cardBalance: "Remaining limit",
    cardLimit: "Availability limit",
    exchangeUtop: "(1 Utop = 1,000 VND)",
    detailDeleteCard:
      "*You will no longer be able to use this card to pay for Utop's services.",
    deletacardSucces: "Unlinked card successfully",
    deletacardSuccesDetail: "Your card has been successfully unlinked.",
    deletacardFaild: "Unlinking card failed",
    cardFPT: "FPT employee card",
    pointUtopDay: "Utop point/day",
    renameCard: "Rename card",
    updateInfoSuccess: "Update successful",
    updateInfoDetailSuccess:
      "Congratulations, you have successfully renamed the tag.",
    updateInfoFaild: "Update failed",
    updateInfoDetailFaild: "Something went wrong. Please try again!",
  },
  UtopCard: {
    LinkCardSuccess: {
      title: "Card link successful",
      txtDetail: "Your card has been successfully linked.",
      txtDone: "Done",
    },
    LinkCardFaild: {
      title: "Card link failed",
      txtDetail: "Something went wrong. Please try again!",
      txtClose: "Close",
    },
  },
  DynamicPage: {
    UserNotMatchCampaign:
      "Sorry, your account does not meet the program requirements",
    UserHasReceivedGift: "Sorry, you've run out of giveaways.",
    DuplicateTransaction: "Sorry, you've run out of giveaways.",
    OverMaxSetPerUser: "Sorry, you've run out of giveaways.",
    OverMaxSet: "Sorry, the number of gifts has reached the limit. ",
    NotFoundCouponCampaign:
      "Sorry, your account does not meet the program requirements",
    InvalidCouponCampaginPeriod:
      "Sorry, the time to receive gifts has expired.",
    currentGoodWill: "Offers available",
  },
  ConfirmPayment: {
    screenName: "Cart",
    labelErrorCouponIsUsed: "Coupon already used!",
    labelErrorCouponExpired: "Coupon has expired!",
    labelErrorMinOrder: "Order value is not enough to use coupon!",
    labelErrorNotApplyStore: "Coupon does not apply to this store!",
    labelErrorProductTypeMismatch:
      "The applicable product type does not match. Please check again!",
    labelErrorSomeProductsStopSelling:
      "Some of the products in the order have been discontinued!",
    labelErrorAccountBlock:
      "Your account has been temporarily blocked from using the coupon code. Please contact Hotline for support!",
    labelErrorServer: "Something went wrong. Please try again!",
    labelErrorSystemProcessing:
      "The system is processing, please try again later.",
    labelErrorQuantityCoupon:
      "The number of promotional codes of the program has expired.",
    labelErrorInvalidCouponCampaginPeriod: "Promo code has expired.",
    labelErrorExpireUseCoupon: "You have expired using the promo code.",
    labelErrorOutOfStockProduct: "This product is out of stock.",
    labelTotal: "Subtotal (",
    labelTotal1: " )",
    labelApplyCouponCode: "Apply coupon",
    labelTotalPricePayment: "Total Payment",
    labelChange: "Change",
    labelPaymentContinue: "Continue to pay",
    labelSelectCoupon: "Choose voucher code or input code",
    labelUpdateCart: "Cart update",
  },
  OrderAddressFood: {
    screenName: "Delivery Address",
    labelErrorShipping:
      "Your order cannot be delivered because the delivery address is too far or the total order amount is not satisfied.",
    labelEnterYourName: "Please enter your name!",
    labelEnterYourPhone: "Please enter your phone number!",
    labelInValidPhoneNumber: "The phone number you entered is not valid!",
    labelEnterAddress: "Please enter your delivery address!",
    labelEnterUnitShipping: "Please choose a delivery unit!",
    labelFree: "Free",
    labelEnterName: "Enter customer name",
    labelEnterPhoneNumber: "Enter customer phone number",
    labelPaymentContinue: "Confirm",
    labelSelectMethodShipping: "Choose a delivery method",
    labelWarningLeaveScreen:
      "The information you just entered will be lost when exiting this screen.",
    labelYes: "Agree",
    labelCancel: "Cancel",
    labelInfoUser: "Customer information",
    labelShipping: "Delivery",
    labelEnterYourAddress: "Enter your address",
    labelYourAddress: " Your address",
    labelNoteAddress: "Address notes",
    labelPlaceholderNoteAddress: "Block number, number of floors...",
    labelSelectAddress: "Choose a delivery address",
    labelYourSelected: "You are choosing",
    labelShop: "Pick up at the counter",
    labelHome: "Delivery",
    labelPleaseSelectAddress: "Please select the address.",
    labelTotalShippingFee: "Total Shipping Fee",
    labelAddressDelivery: "Shipping address",
    labelConfirm: "Confirm",
    labelErrorDistanceChange:
      "Your order cannot be delivered because the address is too far. Do you want to choose another address?",
    labelErrorMinOrder: "The store only deliver with the order value from ",
    labelErrorDistance: "The store only deliver within ",
    labelErrorMinOrderDistance: " with the order value from ",
    labelErrorWeight: "Only applicable for order weight ",
    labelErrorWeightEnd: " and below.",
    labelErrorPartner:
      "Your order was not shipped because it is not eligible for delivery.",
    labelErrorConfig: "The store does not set a shipping fee.",
    labelGettingDistanceFail:
      "The system does not determine the delivery distance, please try again.",
    labelStoreIsNotFound: "The store is temporarily closed, please try again.",
    labelInvalidUserAddress: "The delivery address could not be determined.",
    labelInvalidStoreAddress: "The store address could not be determined.",
    labelUnitMoney: "đ",
    labelUnitDistance: "km",
    labelUnitWeight: "kg",
  },
  PaymentHub: {
    screenName: "Payment Method",
    labelSelectPaymentMethod: "Please choose a payment method!",
    labelOverAmountLimit:
      "The amount to be paid exceeds the allowed limit, please choose another payment method.",
    labelInvalidStatus: "Orders are being processed. Please check again",
    labelErrorServer: "Something went wrong. Please try again!!",
    labelSupport: "Contact ",
    labelSupport1: " for assistance",
    phoneNumberSupport: "1900636997",
    labelLinkCreditCart: "Linked Master/Visa/JCB card",
    labelAddCreditCart: "Add card / account",
    labelSelectDifferentPaymentMethod: "Choose another payment method",
    labelNotEnoughUtop: "You don't have enough Utop points!",
    labelCurrentOverBalance: "Current balance: ",
    labelPaymentPrice: "Payment amount",
    labelPayment: "Payment",
    cancel: "Cancel",
  },
  SuggestCouponV2: {
    labelExpireDate: "EXP: ",
    labelSelect: "Select",
    labelNonSelect: "Cancel",
  },
  PaymentBuyPoint: {
    screenName: "Buy Points",
    labelServerError: "Something went wrong. Please try again!.",
    labelInputPoint: "Please enter the number of Utop to buy!",
    labelInputNumber: "Please enter the correct number",
    labelInputBuyPoint: "Enter the number of Utops to buy",
    labelTotalPrice: "Total price",
    labelPaymentContinue: "Payment continue",
  },
  RePaymentVoucher: {
    labelNotPayment: "Unpaid",
    labelCancelOrder: "Cancel order",
    labelPayment: "Payment",
    labelCompleted: "Complete",
    labelViewVoucher: "View Voucher",
    labelOrderSuccess: "Order Success",
    labelCanceled: "Cancelled",
    titleHeaderOrderCode: "OrderID: ",
    labelTotal: "Subtotal (%{sum})",
    labelApplyCouponCode: "Apply Coupon",
    labelTotal2: "Total",
    labelOrderDetail: "Order Detail",
    labelTransactionCode: "Trading Code",
    labelOrderTime: "Time Order",
    labelPaymentMethod: "Payment Method",
    labelOrderStatus: "Order Status",
    labelSupport:
      "Orders are being processed. Please wait a moment or contact the support hotline",
    YouWantCantOrder: "Do you want to cancel this order?",
    No: "NO",
    Yes: "AGREE",
    labelPaid: "PAID",
  },
  UtopScan: {
    UpdateVersionApp: "Please update the app to scan this QR code.",
    PermisionCameara: "Please allow Camera access",
    DetailCamera: "Move the camera to scan the QR code located at the cashier.",
    Accept: "Agree",
    QRFailed: "The QR Code is invalid. Please try again",
  },
  UB: {
    CurrencyUnit: "VND",
    MemberShip: {
      title: "Membership Card",
      created: "MEMBER SINCE",
      budgetManage: "Budget\nmanagement",
      transactionHistory: "Transaction\nhistory",
      reservationManage: "Booking\nmanagement",
      inputToSearch: "Enter to search…",
      notFoundStore: "Sorry, we couldn't find any restaurants",
      isInactiveAccount: "Your Utop Business account expired!",
      Filter: {
        titleSearch: "SEARCH FILTER",
        titleCity: "CITY",
        titleDistrict: "DISTRICTS",
        back: "Back",
        searchProvince: "Find a province or city",
        searchDistrict: "Find a district",
        area: "Area",
        province: "Province, City",
        district: "District, District",
        price: "Price",
        storeType: "Restaurant type",
        apply: "Apply",
        reset: "Reset",
        pickCity: "Select province/city",
        pickDistrict: "Select a district",
        all: "All",
        note: "Filter shows only available areas",
        notFoundData: "No suitable data found",
      },
    },
    BudgetManage: {
      title: "Budget Management",
      emptyTransactions: "There are currently no transactions",
      sourceBudget: "Budget source",
      allocator: "Allocator",
      expiryDate: "Refund date",
      totalAmount: "Amount",
      availableAmount: "Remaining",
      payByQR: "QR Payment",
      advancePayment: "Advance grant",
      recentTransactions: "RECENT TRANSACTIONS",
      err: {
        emptyBudget: "You don't have any budget to perform this function!",
        canNotReallocate: "Current budget is not allowed to make advance.",
        notEnoughBalance:
          "Current budget balance is not eligible for advance grant.",
        expiredBudget:
          "The current budget has expired. The advance grant cannot be made.",
      },
      SourceBudget: {
        title: "Budget source",
        allocateBy: "Allocator",
        availableAmount: "Balance",
        expiryDate: "Refund Date",
      },
    },
    AdvancePayment: {
      title: "Advance grant",
      fromBudget: "FROM BUDGET",
      availableAmount: "Balance",
      to: "TO",
      email: "Email",
      inputEmail: "Enter email",
      amount: "Amount",
      inputAmount: "Enter advance amount",
      expiryDate: "Refund date",
      content: "Content",
      inputContent: "Enter content",
      advancePaymentBudget: "Budget Advance Grant",
      reallocatable: "Forward advance",
      explain:
        "Allow the recipient of this advance to have the right to forward it to other members.",
      continue: "Continue",
      err: {
        cantAdvancePayment: "The advance cannot be granted to this member.",
        amountRequired: "Minimum amount is 1,000VND",
        notEnoughBudgetAmount: "Insufficient budget balance.",
        contentEmpty: "Please enter contents",
      },
      SourceBudget: {
        title: "Budget source",
        sourceBudget: "Budget source",
        availableAmount: "Balance",
        expiryDate: "Refund Date",
      },
      notEnoughBalance:
        "The balance of the newly used budget does not satisfy the conditions for further advances. Please choose another budget source!",
    },
    ConfirmBudget: {
      title: "Advance confirmation",
      fromBudget: "FROM BUDGET",
      availableAmount: "Balance",
      to: "TO",
      email: "Email",
      receiveName: "Recipient name",
      amount: "Amount",
      expiryDate: "Refund date",
      reallocatable: "Forward advance",
      yes: "Yes",
      no: "No",
      content: "Content",
      confirm: "Confirm",
      err: {
        InvalidExpiryDate: "Invalid refund date.",
        InvalidNewBudgetHolder: "Incorrect advance payment account.",
        InvalidSourceBudget: "The budget is not available.",
        InputValidationFail: "Invalid information.",
        UnallocatableSourceBudget:
          "Advances cannot be granted with this budget.",
        InvalidReceiverAccount: "Advances cannot be granted with this budget.",
        InvalidAmount:
          "The amount of the advance cannot be greater than the amount of the selected budget.",
        InvalidAllocatingBudget: "The current budget is not available.",
        AlreadyOwner:
          "The account receiving the advance is on the list of owners of the selected budget.",
        defaultConfirmBudfet: "Something went wrong. Please try again!",
      },
    },
    AdvanceSuccess: {
      title: "Advance Information",
      advanceSuccess: "ADVANCE GRANT SUCCESSFUL",
      transactionCode: "Transaction Code",
      fromBudget: "From budget",
      receiveName: "Recipient",
      amount: "Amount",
      expiredDate: "Valid until",
      reallocatable: "Forward advance",
      yes: "Yes",
      no: "No",
      content: "Content",
      close: "Close",
      continueAdvance: "Next Advance",
    },
    AdvanceDetail: {
      title: "Advance details",
      emptyTransactions: "There are currently no transactions",
      revoke: "Revoking advance",
      receiveName: "Recipient",
      amountAdvance: "Advance amount",
      availableAmount: "Remaining",
      content: "Content",
      transactionsHistory: "TRANSACTION HISTORY",
      confirmRevoke:
        "Are you sure, you want to recover the advance budget for ",
      alert: {
        confirm: "Confirm",
        cancel: "Cancel",
      },
      err: {
        InvalidReceiver: "Refund receiving account does not exist!",
        InvalidBudget: "Refund budget not available.",
        InvalidRevokingBudget: "Invalid refund budget!",
        defaultConfirmRevoke:
          "Something went wrong during the budget refund process. Please try again!",
      },
    },
    UtopBusinessHistory: {
      title: "Transaction history",
      emptyTransactions: "There are currently no transactions",
      listPaymentMethodHistory: {
        all: "All",
        payment: "Payment",
        budget: "Budget revenue and expenditure",
      },
    },
    UtopBusinessBooking: {
      title: "Reservations",
      bookingInfo: "RESERVATION INFORMATION",
      numberOfGuest: "Number of guests",
      inputNumber: "Enter quantity of customers",
      bookingDate: "Reservation\nDate",
      pickDate: "Select a reservation date",
      pickTime: "Select a reservation time",
      bookingTime: "Arrival time",
      note: "Notes",
      inputNote: "Enter notes",
      continue: "Continue",
      PickupTime: {
        bookingTime: "Arrival time",
        confirm: "Confirm",
      },
      err: {
        requiredPickDate: "Please select a reservation date!",
        storeClose:
          "Sorry, the restaurant is not open at the time you have selected!",
      },
      emptyTransactions: "You don't have any booking information yet",
    },
    Transactions: {
      payAt: "Pay at",
      cancelBy: "Refund from ",
      receiveBudgetBy: "Receive budget advance grant from",
      allocateBudgetFor: "Advance budget for ",
      receiveRevokedBudgetBy: "Receive budget refund from ",
      revokeBudgetFor: "Refund the budget for ",
      waitConfirmPayment: "Waiting for payment confirmation at ",
      paymentStatement: "Utop Business statement payment period ",
      paymentFailedAt: "Payment failed at",
    },
    UtopBusinessStoreView: {
      StoreImage: "Restaurant's pictures",
      SeeMore: "View More",
      Peole: "person",
      Intro: "Introduce",
      Service: "Service",
      ErrorDetail: "Something went wrong. Please try again!",
    },
    UtopBusinessStoreImage: { Title: "Restaurant picture" },
    UtopBusinessBookingManager: {
      Title: "Manage reservation information",
      Booked: "Reserved",
      Confirm: "Confirm",
      Cancel: "Cancelled",
      Time: "Time",
      NumberCustomer: "Number of guests",
      Completed: "Completed",
    },
    UtopBusinessBookingDetail: {
      Title: "Reservation information",
      Booked: "RESERVED",
      Confirm: "Confirmed",
      Cancel: "Cancelled",
      CancelQuestion: "Do you want to cancel your reservation",
      NumberCustomer: "Number of guests",
      DateBooking: "Reservation date",
      TimeBooking: "Arrival time",
      Note: "Notes",
      Contact: "CONTACT INFORMATION",
      Name: "Customer Name",
      PhoneNumber: "Phone number",
      CancelAction: "Cancel booking",
      Accept: "Confirm",
      FailedHandle: "An error occurred while processing. Please try again.",
      CancelSuccess: "You have successfully cancelled your reservation.",
      Close: "Close",
    },
    TransactionDetail: {
      Title: "Transaction details",
      BudgetAdvance: "Budget advance grant",
      Receiver: "Recipient",
      BuggetSoucre: "From the budget",
      ReceiveBudgetAdvance: "Receive a budget advance grant",
      Sender: "Allocator",
      RefundTheBudget: "Budget Revocation",
      ReceiveRefundTheBudget: "Receive a budget revocation",
      BuggetReceive: "To budget",
      ReceiveRefundTheBudgetExpired: "Receive revocation of expired budget",
      RefundTheBudgetExpired: "Revocation expired budget",
      RevokeAdvanceBudget: " recovery of budget advance grant",
      Pay: "Payment",
      RefundPay: "Refund Payment",
      PaymentOfStatement: "Payment statement",
      TransactionSuccess: "Transaction Successful",
      TransactionFailed: "Transaction Failed",
      TransactionWaiting: "Waiting for payment confirmation",
      TransactionHash: "Transaction code",
      Time: "Time",
      MoreInfo: "More info",
      Description: "Content",
      PayAt: "Pay at",
      AttenderCount: "Number of guests",
      PaymentInvoice: "Payment invoice",
      SeeImage: "View image",
      LegalEntity: "Invoice information",
      Note: "Notes",
      DateRefund: "Revocation date",
      Reallocatable: "Forward advance",
      Note2: "Note",
      ContentNote2:
        "Transaction is waiting for confirmation, payment amount will be refunded in case of payment failure.",
      Yes: "Yes",
      No: "No",
      PaymentFailedAt: "Payment failed at",
      RefundPayDescription:
        "Utop makes a request to refund the transaction at: ",
    },
    VerifyBusiness: {
      TitleHeader: "Utop Business Link Confirmation",
      Title:
        "Confirm linking existing Utop account with Utop Business utilities",
      RegisteredBy: "Registered by",
      MemberName: "Member Name",
      PhoneNumber: "Phone number",
      ChangeAccount: "Change Account",
      Confirm: "Confirm",
      Message1: "Your account is already a Utop Business member of ",
      Message2a: "Your Utop Business account belongs to ",
      Message2b: " has expired.",
      Message3: "Your Utop Business authentication link has expired!",
      MessageDefault:
        "Your Utop Business account has expired or an error has occurred.",
      MessageError: "An error occurred. Please try again.",
      Logout: "Sign out of the app?",
      LogoutError: "There was an error logging out.\nPlease try again later.",
      Cancel: "CANCEL",
      Accept: "AGREE",
      Close: "Close",
    },
    ConfirmBooking: {
      title: "Booking Confirmation",
      bookingInfo: "BOOKING INFORMATION",
      numberOfGuest: "Customer number",
      bookingDate: "Booking date",
      bookingTime: "Time",
      note: "Note",
      contactInfo: "CONTACT INFO",
      guestName: "Customer name",
      numberPhone: "Phone number",
      careful: "NOTE",
      storeInfo: "**The restaurant will reserve your spot for 30 minutes.",
      confirm: "Confirm",
      popup: {
        title: "Booking received",
        detail:
          "Utop will make book at the restaurant and notify you as soon as possible.",
      },
      err: {
        InvalidUser: "User account does not exist!",
        InvalidStore:
          "The restaurant does not exist or does not support Utop Business payments.",
        InvalidReservationDate: "Invalid booking time!",
        InvalidOpenTime:
          "The booking time has been outside the restaurant's opening hours!",
        InputValidationFail: "Invalid booking information.",
        defaultConfirmBooking: "Something went wrong. Please try again!",
      },
    },
  },
  FnBRating: {
    labelExistRating: "Your order has been evaluated.",
    labelConfirm: "Confirm",
    labelRatingTitle:
      "Your rating and review helps to improve the quality of products in the store",
    labelCommentPlaceholder:
      "Share your experience and help other customers make purchasing decisions.",
    labelRatingThanks: "Thank you for the rating and review!",
    labelLikeCount: "likes",
    labelLike: "Like",
    labelBuyer: "Buyer",
    labelClose: "Close",
    labelRatingError: `Something went wrong in the rating.
    Please check again.`,
    labelRatingErrorTitle: "Evaluation failed",
    labelEmpltyComment: "No rating and reviews!",
    labelAllRating: "All Ratings & Reviews",
    writeReply: "Write a Reply",
    listLike: "Likes",
    invalidComment: "Your comment may not valid. Please try again!",
    sendCommentFail: "Comment submission failed!",
    copied: "Copied",
    labelRateTitle: "Overall",
    selectReason: "Please select a reason for reporting feedback",
    placeholderOtherReason: "Please state another reason",
    otherReason: "Other Violation",
    titleReportReason: "Report Feedback",
    deleteRating: "Delete rating",
    deleteReply: "Delete response",
    confirmDeleteRating: "Are you sure you want to delete this review?",
    confirmDeleteReply: "Are you sure you want to delete this response?",
    thankReport: "Thank you for letting us know!",
    yourFeedback:
      "Your comment help our system know when there is inappropriate content.",
    done: "Done",
    sendReport: "Send report",
    seePreReply: "View previous response...",
    unknownError: "Unable to connect to server!",
    save: "Save",
    SelectReason: "Please select a reason to report!",
    reportError: "Report failed, please check again!",
    ratingImagesTitle: "Image",
    commentuggest: "Be the first to share\nyour opinion about the store.",
    commentCartText:
      "Temporarily no reviews, purchase an orders now\n and reviews the store",
    commentsText:
      "Share your experience to help other users find a better experience.",
    rate: "Rate",
    uploadRatingImage: "Post Image",
    selectImageFrom: "Select image from",
    ratingWithComment: "Only see reviews with comments",
    ratingOf: "Rating of",
  },
  requireVersionPopup: {
    description:
      "The current version is not supported. Please \n update to higher version continue using!",
    descriptionOptionUpdate:
      "Please update the app to use this \n new feature!",
  },
  ConfirmInfoVoucher: {
    screenName: "Confirm Information",
    labelPartner: "Partner",
    labelInfoDetail: "Details",
    labelVoucherName: "Voucher name",
    labelPrice: "Price",
    labelButton: "Confirm",
    msgErrorIsUsed:
      "Voucher has already been used.\n Please use another Voucher.",
    msgErrorNotExist: "Voucher not found. \n Please try again.",
    msgErrorIsRefunded:
      "Voucher has been refunded. \n Please use another Voucher.",
    msgErrorIsExpired: "Voucher has expired.\n Please use another Voucher.",
    msgErrorInvalidAmount:
      "Voucher value is not correct. \n Please contact hotline 1900 636 997 for support.",
    msgErrorServer: "Something went wrong. Please try again!.",
    msgErrorValidQR: "Invalid QR code!",
    msgErrorUnallowedMerchant:
      "You cannot use the Voucher at this store. \n Please use another Voucher.",
    labelTransactionFailed: "Transaction Failed",
    labelClose: "Close",
  },
  VoucherResult: {
    screenName: "Transaction information",
    useVoucherSuccess: "Use voucher successfully!",
    labelPrice: "Price",
    labelUseAt: "Use at",
    labelVoucherName: "Voucher Name",
    labelTime: "Time",
    labelGoHome: "Back the homepage",
  },
  utopBack: {
    titleScreen: "UtopBACK",
    utopCashBack: "Utop has returned",
    subTitleHeader:
      "Use the Utop app to purchase an order from \npartners to get Utop points back",
    noData: "No data",
    howCashBack: "How to get Utop back?",
    utopCash: "Utop back",
    utopWaitApprove: "Utop is waiting for approval",
    waiting: "Waiting",
    approve: "Approved",
    cashBack: "Refunded",
    cancel: "Cancelled",
    BuyNow: "Buy now",
    amountUtopRefunded: "Utop back Amount",
    amountUtopWillRefunded: "The amount Utop will be backed",
    valueOrder: "Order value",
    TradeDate: "Trading date",
    orderCode: "Order ID",
    order: "Order",
    textUtopBack: "UtopBack",
  },
  MapOrderStatus: {
    screenName: "Order Tracking",
    labelSupport: "Need support?",
    labelSupportOrder: "Order Support",
    labelContactHotline: "Please contact hotline",
    labelToSupport: " to \nsupported.",
    labelClose: "Close",
    labelCallNow: "Call Now",
  },
  Referral: {
    refererReward: "Referer Gift",
    firstReferralsReward: "First Referral Gift",
    presenteeReward: "Gift for the Referee",
    validInvitation: "Valid invited",
    invitationSentList: "Invitation sent list",
    people: "people",
    instructYourFriends:
      "Instruct your friends to do so that you both have gifts",
    listGift: "GIFT LIST",
    hasTrans: "Transaction made",
    noTrans: "No transaction yet",
    Modal: {
      guide:
        "Instruct your friends to do the STEPS for both of you to have gifts.",
      step1: {
        title: "Step 1",
        desc: "Click the link and download the Utop app",
      },
      step2: {
        title: "Step 2",
        desc: "Enter registered phone number",
      },
      step3: {
        title: "Step 3",
        desc: "Enter OTP to confirm phone number",
      },
      step4: {
        title: "Step 4",
        desc: "Generate Password and Login",
      },
      step5: {
        title: "Step 5",
        desc: "Purchase",
      },
      completed: {
        title: "Done",
        desc: "Receive gift from Utop",
      },
    },
    Home: {
      title: "Refer a friend",
      copyDesc: "Copied to Clipboard",
      inviteFriends: "Invite your friends to download Utop now!",
      inviteFriendsDesc:
        "Send this link to 5 friends to be able to install and receive gifts from Utop.",
    },
    ListInvited: {
      title: "Invitation sent list",
    },
  },
  CouponForVoucher: {
    screenTitle: "Coupons",
    placeholderSearchbar: "Enter coupon code",
    noCouponFound: "Coupon code not found",
    expiredDate: "Expired",
    useNow: "Use",
    errorDuplicateTransaction:
      "The system is processing, please try again later.",
    errorOverMaxSet: "The number of coupons of the program is over.",
    errorOverMaxSetPerUser: "you are out of turn to get coupon.",
    errorNotFoundCouponCampaign:
      "Invalid coupon code, please check or try another code.",
    errorInvalidCouponCampaginPeriod: "Coupon receiving time has expired.",
    outOfCouponUsingTurn: "Coupon has expired.",
  },
  changePointPartner: {
    screenName: "Redeem Points",
    partnerText: "Partner",
    connectWithPartnerText: "Partner connection",
    exchangePointText: "Exchange",
    directionText: "Guide",
    exchangeText: "Change",
    receiveText: "Receive",
    noteExchange:
      "The amount advanced to top up Utop will be deducted from the monthly salary period",
    changePartner: "Change",
    exchangeAll: "Change it all",
    balanceText: "Balance",
    noteExchange1: "The redemption amount cannot exceed the available balance.",
    noteExchange2: "*   (1.000 VND = 1 Utop)",
    continueText: "Continue",
    pressText: "PRESS",
    toConnectPartnerText: "to add partners",
    knowText: "Understood",
    inputText: "PRESS",
    exchangePointNote: "amount you want to convert",
    invalidExchangePoint: "Invalid amount",
    addOtherPartnerText: "Choose another partner",
  },
  confirmExchangePoint: {
    exchangeText: "Change",
    receiveText: "Receive",
    partnerText: "Partner",
    screenName: "Confirm Information",
    detailInformation: "Details",
    noteExchange: "(1.000 VND = 1 Utop)",
    confirmText: "Confirm",
    infoPartnerInvalidText: "Invalid Partner information",
  },
  loginPartner: {
    screenName: "Connect",
  },
  changePointSelectionPartner: {
    screenName: "Add Partner",
    infoPartnerInvalidText: "Invalid partner information",
  },
  exchangePointSuccess: {
    screenName: "Transaction Information",
    exchangePointSuccessText: "Successful transaction",
    description:
      "The corresponding amount will be collected in the next month's salary period.",
    exchangeText: "Change",
    receiveText: "Receive",
    partnerText: "Partner",
    transactionCodeText: "Transaction code",
    transactionTimeText: "Transaction time",
    goHomeButtonLabel: "Back the homepage",
  },
  SuggestCoupon: {
    canApply: "Apply coupon",
    expired: "Expired",
    apply: "Apply",
  },
  ContactList: {
    alert: "Notification",
    neverAskAgainPermission:
      "You have denied permission to read contacts for this app, please reopen permission in app settings.",
    skip: "Skip",
    confirm: "Agree",
    pickReceiver: "Choose a recipient",
    placeholderSearch: "Type to search",
    noSearchResultFor: "No search results for",
    needPermissionToSendMoney:
      "Give access to contacts to help you quickly select friends to top up",
    grant: "Allow access",
    chooseReceiver: "Chọn người nhận",
    notFound: "Không có kết quả tìm kiếm cho",
    label1:
      "Please allow Utop to access your contacts to help you easily give gifts (Top-up phone cards, Give vouchers...) to your friends.",
    acceptPermission: "Cho phép truy cập",
    refusePermission:
      "Bạn đã từ chối cấp quyền đọc danh bạ cho ứng dụng này, vui lòng mở lại quyền trong phần cài đặt của ứng dụng.",
    searchbarPlaceholder: "Nhập để tìm kiếm",
  },
  MenuHistoryPoint: {
    screenTitle: "Utop Points History",
    noData: "No history.",
    CashBack: "Giving Utop from Partner",
    FromPoint: "Change points from Partner",
    TopUpUserFrom: "Top-up Utop from",
    TopUpUser: "Top-up Utop",
    TransferUtopSend: "Transfer Utop points",
    TransferUtopReceive: "Receive Utop points",
    PayByToken: "Pay Utop at the counter",
    Gift: "Payment orders",
    RedeemGiftCard: "Top-up Utop from Giftcard",
    Cancel: "Transaction is refunded Utop",
    refundUtopFrom: "Refund Utop from",
    refundUtop: "Refund Utop",
    receivedFrom: "Received from",
    sendTo: "Send to",
    purchaseUtopAt: "Pay Utop at",
    purchaseUtop: "Pay Utop",
    Title: "Transaction details",
    TradingCode: "Transaction code",
    transactionTime: "Transaction time",
    Message: "Message",
    Partner: "Partner",
    Store: "Store",
    PaymentMethod: "Payment method",
    UtopPoint: "Utop points",
    Detail: "Detail",
    CanceledBill: "Order canceled",
    Sender: "Sender",
    Receiver: "Receiver",
    BillCode: "Code order",
    BillType: "Order type",
  },
  PaymentResult: {
    justSuccessfullyPaid: "You have successfully paid for your order",
    justSuccessfullyOrdered: "You have successfully placed an order",
    transaction: "Transaction",
    transactionFailed: "failed.",
    goToVoucher: "Please go to “My Voucher” to check and use the product",
    pleaseCheckOrContactToUtop:
      "Please check again or call Utop's support call center",
    checkingOrderStatus: "Check order status",
    viewInVoucher: "Go to the My Voucher",
    continuePayment: "Continue trading",
    goToHome: "Back the homepage",
    rePay: "Pay back",
    labelCheckOrder: "Kiểm tra đơn hàng",
  },
  OrderList: {
    youHave: "You have",
    ordersNeedToHandle: "orders to be processed",
    order: "order",
    orderSuccessfully: "Order Success",
    orderWaiting: "Waiting for your confirmation",
    orderConfirmed: "Check-in/Confirmed",
    orderAvailable: "Come pick up",
  },
  MapPickupAddress: {
    screenName: "Choose the delivery addresses",
    addressPickup: "Delivery address",
  },
  ListDealVoucher: {
    title: "List of Deals/Vouchers",
  },
  OrderPartnerDetail: {
    screenName: "Order Information",
    transactionCodeLabel: "Transaction code",
    orderDateLabel: "Time Order",
    descriptionLabel: "Description",
    amountLabel: "Amount",
    paymentMethodLabel: "Payment Methods",
    orderStatusLabel: "Order Status",
    detailButtonLabel: "View detail",
    deliveredStatusLabel: "Completed",
    newStatusLabel: "Unpaid",
    cancelStatusLabel: "Cancelled",
  },
  PaymentWebview: {
    screenName: "Payment",
  },
  UtopTLIP: {
    common: {
      justFrom: "From",
      pullToRefresh: "Pull to refresh",
      paymentSuccess: "PAYMENT SUCCESS",
      paymentFail: "PAYMENT FAILED",
      total: "Total",
      paymentMethods: "Payment method",
      utopMethod: "Utop point",
      packageName: "Package name",
      option: "Option",
      period: "Period",
      transactionCode: "Trading code",
      time: "Time",
      contactContentHotline:
        "** If you have any questions, please contact the hotline",
      phoneNumberUtop: "1900636997",
      phoneNumberMSIG: "02439369188",
      contentOrContactMSIG: " or hotline MSIG VN",
      contactContentHotlineUtop:
        "** If you have any questions, please contact the Utop hotline",
      year: "year",
      pointUtop: "Utop point",
      Utop: "Utop",
      unitVND: "VND",
      timeTransaction: "Time order",
      orderStatus: "Order status",
      cancelOrder: "Cancel order",
      payment: "Payment",
      amountOfMoney: "Total Amount",
      emptyData: "No data",
      customerInfo: "Customer Information",
      workplace: "Work place",
      fullName: "Full name",
      employeeCode: "Employee code",
      phoneNumber: "Phone number",
      email: "Email",
      dateBirth: "Date of birth",
      gender: "Gender",
      nationality: "Nationality",
      peopleIDOrPassport: "ID/Citizenship card/Passport",
      dateRange: "Date range",
      placeOfIssue: "Place of issue",
      entryVisaNumber: "Entry visa number",
      addressOfRegisteredPlaceOfResidenceAbroad:
        "Address of registered place of residence abroad",
      addressOfRegisteredResidenceInVietnam:
        "Address of registered residence in Vietnam",
      permanentAddress: "Permanent address",
      addressContact: "Address",
      job: "Job",
      position: "Position",
      unpaid: "Un paid",
      finish: "Completed",
      cancelled: "Cancelled",
      male: "Male",
      females: "Female",
      error: "Something went wrong. Please try again!",
      buyMore: "Buy more",
      done: "Done",
      notInfo: "Information cannot be left blank",
      phoneNumberNotWork: "Invalid phone number",
      emailIisIncorrect: "Invalid Email",
      nationalityIsIncorrect: "Invalid nationality",
      peopleIDOrPassportIsIncorrect: "Invalid ID/Passport",
      cancel: "Cancel",
      agree: "Agree",
      cancelOrderTransaction: "Do you want to cancel this order?",
      companyIsIncorrect: "Invalid company",
      fullNameIsIncorrect: "Invalid full name",
      jobPositionIsIncorrect: "Invalid position",
      jobIsIncorrect: "Invalid job",
      addressContactIsIncorrect: "Invalid address",
      permanentAddressIsIncorrect: "Invalid permanent address",
      placeOfIssueIsIncorrect: "Invalid place of issue",
      employeeCodeIsIncorrect: "Invalid  employee code",
      invalidEffectiveDate: "Your order has expired",
      effectiveStartDate: "Effective date",
      effectiveEndDate: "Expiration date",
      dateText: "DAY",
      monthText: "MONTH",
      yearText: "YEAR",
      selectDateText: "Select date",
      detailPeopleIdOrPassport: "ID/Citizenship card/Passport",
      enterYourCompanyName: "Enter your company name...",
      enterYourFullName: "Enter your full name...",
      enterYourEmployeeId: "Enter your employee code...",
      enterYourPhoneNumber: "Enter your phone number...",
      enterYourEmail: "Enter your email...",
      placeholderBornDate: "01/01/1991",
      enterYourNational: "Enter your nationality...",
      enterYourDetailPeopleIdOrPassport:
        "Enter ID/Citizenship card/Passport...",
      enterYourPlaceProvided: "Enter your place of grant...",
      enterYourPlace: "Enter your permanent address...",
      enterYourAddressContact: "Enter your address...",
      enterYourJob: "Enter your job...",
      enterYourJobPosition: "Enter your position...",
    },
    listInsuranceScreen: {
      listInsuranceScreenTitle: "Insurance",
    },
    insuranceDetail: {
      buttonConfirmTitle: "Buy",
      sumText: "Total",
      periodTitle: "Period",
      effectiveStartDate: "Effective date",
      effectiveEndDate: "Expiration date",
      buttonSelectTitle: "Select",
      buttonCancelTitle: "Cancel",
    },
    transactionInfoScreen: {
      transactionInfoScreenTitle: "Transaction Information",
      titleButtonPaymentAgain: "Pay back",
    },
    orderDetailScreen: {
      orderCode: "OrderID:",
      labelOrderDetail: "Transaction Detail",
      paid: "PAID",
    },
    confirmInfoScreen: {
      screenTitle: "Confirm information",
      partner: "Partner",
      detailInfo: "Detail",
      buttonConfirm: "Confirm",
      effectiveStartDate: "Effective date",
      effectiveEndDate: "Expiration date",
    },
    confirmInsuranceProduct: {
      screenTitle: "Warning",
      rejectButtonTitle: "Cancel",
      submitButtonTitle: "Confirm",
      confirmText: "I have read and understand the above note",
      numericalOrder: "No",
      listOfDiseases: "List of diseases",
    },
    getInfoCustomer: {
      screenTitle: "Enter Information",
      headingText: "Customer information",
      vietnameseInitValue: "Vietnamese",
      buttonNextTitle: "Continue",
    },
  },
  SearchStore: {
    storeNot: "Store not",
    running: "Active",
    openAt: "Open at",
    opening: "Open",
    storeIsClosing: "The store is closed",
  },
  RecommendStore: {
    screenTitle: "Recommend Restaurant",
    storeName: "Store name",
    storeAddress: "Address",
    addressTypingPlaceholder: "Enter address...",
    contactPerson: "Contact",
    contactPersonPlaceholder: "Enter contact name...",
    phoneNumber: "Phone number",
    PhoneNumberPlaceholder: "Enter phone number...",
    storeDescription: "Description of the store",
    storeDescriptionPlaceholder: "Enter store description...",
    sendRequest: "Submit a proposal",
  },
  GPSProvider: {
    title: "Permission to access location is very important!",
    description:
      "One-time operation for Utop to easily suggest delicious restaurants around you. Utop does not track and all your information is confidential",
    buttonConfirm: "I have read and understood the above content",
  },
  FnBPayment: {
    notValidCoupon:
      "The Coupon code you are using is not valid, so it will not be applied in this invoice!. Do you want to continue paying!",
    back: "Back",
    someProductsOutofStock:
      "Some of the products in the order have been discontinued",
    contactSupport:
      "Your account is temporarily blocked from using Coupon. Please contact Hotline for support",
    couponIsUsed: "Coupon code already used!",
    couponExpired: "Coupon code has expired!",
  },
  MallCustome: {
    searchbarPlaceholder: "Type to search…",
  },
  MarketProduct: {
    labelEmptyProduct: "No products",
    labelOk: "OK",
    labelAll: "All",
    labelEndList: "Out of product",
    labelOutOfHours: "Out of hours",
    labelOutOfStock: "Out of stock",
    labelRequireLogin: "Please login to view store reviews",
    labelLogin: "Login",
  },
  Market: {
    errorStore: "Connection interrupted, please try again.",
    imagesTitle: "Tổng hợp hình ảnh",
  },
  MarketSearch: {
    recentSearch: "Recent Search",
    clearSearch: "Clear",
    outOfProduct: "Out of product",
    notFoundProduct: "No matching items found.",
    categoryImage: "Danh mục hình ảnh",
  },
  OrderListModal: {
    labelConfirm: "Confirm delivery",
  },
  OrderConfirmedModal: {
    labelCancel: "Cancel",
    labelAgree: "Agree",
    labelOrderConfirm: "Confirm order",
    labelReceivedOrder: "You have received an order from ",
  },
  MenuHistory: {
    Month: "Month",
    Reward: "Utop reward from the brand",
    FromPoint: "Redeem Utop from",
    PayByToken: "Utop payment at the counter",
    VoucherGift: "Redeem voucher from the brand",
    Utop: "Utop",
    EmptyHistory: "You don't have any transaction history",
    React: {
      TitleBar: "Transaction history",
    },
    orderTypeDefaulDescriptionTopup: "Mua thẻ cào",
    orderTypeDefaulDescriptionBuycard: "Nạp tiền điện thoại",
    defaultOrderFnBDescription: "Payment for The Store's Order",
    defaultOrderGiftDescription: "Payment Voucher",
    defaultPartnerDescription: "Payment for The Partner's Order",
    labelEndList: "Đã hết danh sách",
  },
  UtopSupportOrder: {
    Title: "ĝây là hình thức đặt món và ",
    TitleHome: "giao hàng tận nơi",
    TitleShop: "đến quầy nhận hàng",
    TitleEnd: ", bạn hãy lưu ý các bước bên dưới nha:",
    DescriptionShop1A: "Chờn cửa hàng, đặt món và thanh toán",
    DescriptionShop1B: " để tiết kiệm thời gian xếp hàng",
    DescriptionShop2A: "Sau khi hoàn tất đơn hàng, di chuyển",
    DescriptionShop2B: " đến cửa hàng để check-in",
    DescriptionShop3A: "Xác nhận đơn hàng đã đặt trên Utop",
    DescriptionShop3B: " tại quầy thu ngân để được phục vụ",
    DescriptionHome2A: "Sau khi hoàn tất đơn hàng",
    DescriptionHome2B: " đợi cửa hàng xác nhận và giao hàng",
    DescriptionHome3A: "ĝợi tài xế gời nhận món",
    DescriptionHome3B: " và đánh giá cửa hàng",
  },
  ListApplicableForCoupon: {
    screenName: "Apply Coupon",
    endList: "End of listh",
    closed: "Closed",
    comeBackAt: "Opens at ",
    emptyStore:
      "The list of applicable coupons is being updated. Please come back later!",
  },
  ChooseVoucher: {
    title: "Choose Voucher",
    errorNotSelected: "Please select at least 1 Voucher",
    continue: "Continue",
  },
  GiveFriend: {
    title: "Give friends",
    emptyPhoneNumber: "Phone number cannot be left blank",
    errorShareVoucher: "Voucher has been used or expried. Please try again!",
    errorMessage: "An unexpected error has occurred. Please try again later!",
    sendingFailed: "Sending failed",
    successfullySent: "Successfully Sent",
    receiverInformation: "Receiver's Information",
    phoneNumber: "Phone Number",
    recentRecipients: "Recent Recipients",
    message: "Message",
    placeHolderMessage: "Enter a message to send to you...",
    vouchers: "Voucher",
    txtConfirm: "Confirm",
    emptyName: "The name hasn't been updated yet",
    PopupSuccess: {
      textA: "received the Vouchers:",
    },
    txtClose: "Close",
    Alert: {
      confirmSend: "Do you want to send Voucher to your friends?",
      agree: "Agree",
      cancel: "Cancel",
    },
    shareContent: {
      wantsToGiveYou: " wants to give you ",
      downApp: " download the app to receive the gift.",
      me: "Me",
      voucher: "Voucher",
    },
  },
};
