const LOADED_DATA_SESSION_STORAGE_KEY_PREFIX = "loadedData__";

// eslint-disable-next-line no-unused-vars
const genKey = ({ pathname, search }) => {
  return `${LOADED_DATA_SESSION_STORAGE_KEY_PREFIX}${pathname}`;
};

const saveLoadedData = ({ pathname, search, actionName, data }) => {
  const sessionStorageKey = genKey({ pathname, search });
  const loadedData = JSON.parse(sessionStorage.getItem(sessionStorageKey));

  let newStoreData;

  if (loadedData?.search !== search) {
    newStoreData = {
      [actionName]: data,
    };
  } else {
    newStoreData = {
      ...loadedData.storeData, // clone
      [actionName]: data,
    };
  }

  const dataToCache = {
    path: pathname,
    search: encodeURI(search),
    storeData: newStoreData,
  };

  sessionStorage.setItem(sessionStorageKey, JSON.stringify(dataToCache));
};

const restoreLoadedData = ({ pathname, search }) => {
  const sessionStorageKey = genKey({ pathname, search });
  const loadedData = JSON.parse(sessionStorage.getItem(sessionStorageKey));

  // if (loadedData?.search !== search) return null; // ? có xảy ra không?

  return loadedData?.storeData;
};

const removeLoadedData = ({ pathname }) => {
  sessionStorage.removeItem(
    `${LOADED_DATA_SESSION_STORAGE_KEY_PREFIX}${pathname}`
  );
};

export const storeScrollPos = ({ pathname, search }) => {
  const sessionStorageKey = genKey({ pathname, search });
  const loadedData = JSON.parse(sessionStorage.getItem(sessionStorageKey));

  sessionStorage.setItem(
    sessionStorageKey,
    JSON.stringify({
      ...loadedData,
      windowScrollY: window.scrollY,
    })
  );
};

export const getPrevScrollY = ({ pathname, search }) => {
  const sessionStorageKey = genKey({ pathname, search });
  const loadedData = JSON.parse(sessionStorage.getItem(sessionStorageKey));

  if (typeof loadedData?.windowScrollY !== "number") return 0;

  return loadedData.windowScrollY;
};

const SessionStorageUtils = {
  saveLoadedData,
  restoreLoadedData,
  removeLoadedData,
  storeScrollPos,
  getPrevScrollY,
};

export default SessionStorageUtils;
