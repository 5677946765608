import {
  GET_LIST_PENDING_ORDER_SUCCESS,
  CLEAR_LIST_PENDING_ORDER,
} from './constants';
import moment from 'moment';

const initialState = {
  orderList: [],
};

const orderList = function (state = initialState, action) {
  switch (action.type) {
    case GET_LIST_PENDING_ORDER_SUCCESS: {
      return {
        ...state,
        orderList: action.orderList.data,
      };
    }

    case CLEAR_LIST_PENDING_ORDER: {
      return {
        ...state,
        orderList: [],
      };
    }

    default:
      return state;
  }
};

export default orderList;
