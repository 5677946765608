import React, { useState } from "react";
import "./InputTable.scss";

const InputTable = (props) => {
  const [valueInput, setValueInput] = useState("");

  const handleDone = () => {
    props.setNumberTable(valueInput);
    props.setVisibleMethodDeliveryTable(false);
  };
  return (
    <div className="input-wrapper">
      <div className="header-input-table">
        <div
          className="color-ucla_gold _fw-500"
          onClick={() => {
            props.setNumberTable("");
            setValueInput("");
            props.setVisibleMethodDeliveryTable(false);
          }}
        >
          Huỷ
        </div>
        <div className="title-input-table">Nhập số bàn</div>
        <div className="color-ucla_gold _fw-600" onClick={() => handleDone()}>
          Xong
        </div>
      </div>
      <div className="input-table-content">
        <input
          // id={id}
          type="text"
          placeholder="Nhập số bàn"
          value={valueInput}
          onChange={(e) => setValueInput(e.target.value)}
          className="input-table _outline-none _border-none"
        />
      </div>
    </div>
  );
};

export default InputTable;
