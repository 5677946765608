import request, { requestWithoutToken } from "services/request";
import { encodeQueryData } from "utils/stringFormat";

import * as TYPE from "../store/constants";

const api = {
  [TYPE.GET_MERCHANT_INFO.request]: async (merchantId) => {
    return requestWithoutToken(`/cms/core/merchant/${merchantId}`, "GET");
  },
  [TYPE.GET_STORE_INFO.request]: async (storeId) => {
    return requestWithoutToken(`/cms/core/stores/${storeId}`, "GET");
  },
  [TYPE.GET_STORE_INFO_V2.request]: async (storeId) => {
    return requestWithoutToken(`/cms/store/${storeId}`, "GET");
  },
  [TYPE.GET_BEST_SELLER_LIST.request]: async (merchantId) => {
    return requestWithoutToken(
      `/cms/core/fnb/${merchantId}/productsbytags`,
      "GET"
    );
  },
  [TYPE.GET_SHORT_LINK_TO_SHARE.request]: async (params) => {
    const { url } = params;

    return requestWithoutToken(`/common/ShortUrl`, "POST", { url });
  },
  [TYPE.GET_SUGGESTED_COUPON_LIST.request]: async (params) => {
    const query = encodeQueryData({
      StoreId: params.storeId,
      MerchantId: params.merchantId,
      Feature: "orderfnb",
    });

    if (params?.viewAsGuest) {
      return requestWithoutToken(`/coupon/GetSuggestCoupon${query}`, "GET");
    }

    return request(`/coupon/GetSuggestCoupon${query}`, "GET");
  },
  [TYPE.SAVE_SUGGESTED_COUPON.request]: async (code) => {
    const data = { code };

    return request(`/coupon/GetAvailableCouponsByCode`, "POST", data);
  },
  [TYPE.GET_RATING_LIST.request]: async (params) => {
    const query = encodeQueryData({
      refId: params.refId,
      featureId: params.featureId,
      haveComment: params.haveComment,
      sortingType: params.sortingType,
      take: params.take,
      skip: params.skip,
    });
    return request(
      `/comment/api/v${process.env.REACT_APP_COMMENT_API_VERSION}/rating${query}`,
      "GET"
    );
  },
  [TYPE.GET_USER_LIKED_LIST_OF_RATING_POST.request]: async ({
    id,
    skip,
    take,
  }) => {
    const query = encodeQueryData({
      ratingId: id,
      skip,
      take,
    });
    return request(
      `/comment/api/v${process.env.REACT_APP_COMMENT_API_VERSION}/Rating/CreatorLike${query}`,
      "GET"
    );
  },
  [TYPE.GET_USER_LIKED_LIST_OF_COMMENT.request]: async ({ id, skip, take }) => {
    const query = encodeQueryData({
      commentId: id,
      skip,
      take,
    });
    return request(
      `/comment/api/v${process.env.REACT_APP_COMMENT_API_VERSION}/Comment/CreatorLike${query}`,
      "GET"
    );
  },
  [TYPE.POST_LIKE_CMT.request]: async (commentId) => {
    return request(
      `/comment/api/v${process.env.REACT_APP_COMMENT_API_VERSION}/rating/${commentId}/Like`,
      "POST"
    );
  },
  [TYPE.POST_UNLIKE_CMT.request]: async (commentId) => {
    return request(
      `/comment/api/v${process.env.REACT_APP_COMMENT_API_VERSION}/rating/${commentId}/UnLike`,
      "POST"
    );
  },
  [TYPE.POST_LIKE_SUB_CMT.request]: async ({ replyId }) => {
    const data = { commentId: replyId };
    return request(
      `/comment/api/v${process.env.REACT_APP_COMMENT_API_VERSION}/comment/LikeComment`,
      "POST",
      data
    );
  },
  [TYPE.POST_UNLIKE_SUB_CMT.request]: async ({ replyId }) => {
    const data = { commentId: replyId };
    return request(
      `/comment/api/v${process.env.REACT_APP_COMMENT_API_VERSION}/comment/UnLikeComment`,
      "POST",
      data
    );
  },

  [TYPE.POST_REPLY_CMT.request]: async (body) => {
    return request(
      `/comment/api/v${process.env.REACT_APP_COMMENT_API_VERSION}/comment`,
      "POST",
      body
    );
  },
  [TYPE.GET_NOT_ALREADY_RATED_LIST.request]: async (id) => {
    const query = encodeQueryData({
      storeId: id,
    });
    return request(`/Order/SuggestRating${query}`, "GET");
  },
  [TYPE.GET_LIST_DEAL.request]: async (params) => {
    const query = encodeQueryData({
      storeId: params.storeId,
      merchantId: params.merchantId,
      Latitude: params.Latitude,
      Longitude: params.Longitude,
      Skip: params.Skip,
      Take: params.Take,
    });
    return requestWithoutToken(`/home/feed/voucher${query}`, "GET");
  },
};

export default api;
