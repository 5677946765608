import { MinusWhiteIcon, PlusWhiteIcon } from "assets/svg";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import styles from "./InputNumberV2.module.scss";

const InputNumberV2 = (props) => {
  const {
    initialValue = 1,
    minValue = 1,
    maxValue = 99,
    onCommitChange,
    className,
  } = props;

  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  // -- handle quantity -- //
  const handleIncrease = (e) => {
    e.stopPropagation();
    e.preventDefault();

    if (value >= maxValue) return;

    setValue(value + 1);
    onCommitChange(value + 1);
  };

  const handleDecrease = (e) => {
    e.stopPropagation();
    e.preventDefault();

    if (value <= minValue) return;

    setValue(value - 1);
    onCommitChange(value - 1);
  };
  // -- handle quantity -- //

  // handle quantity - by keyboard - only commit number
  const handleChange = (e) => {
    if (!e.target.value) {
      setValue(minValue);
      onCommitChange(minValue);
      return;
    }

    const parsedValue = Number(e.target.value) || minValue;

    if (parsedValue < minValue) {
      return;
    }

    if (parsedValue > maxValue) {
      setValue(maxValue);
      onCommitChange(maxValue);
      return;
    }

    setValue(parsedValue);
    onCommitChange(parsedValue);
  };

  return (
    <span className={styles["common-input-number"]}>
      <button
        className={classNames(
          styles["btn-change-number"],
          value <= minValue && styles["btn-change-number--disabled"]
        )}
        type="button"
        onClick={handleDecrease}
      >
        <i>
          <MinusWhiteIcon />
        </i>
      </button>
      <input
        type="number"
        value={value}
        min={minValue}
        max={maxValue}
        inputMode="numeric"
        pattern="[0-9]*"
        readOnly
        aria-readonly
        onChange={handleChange}
        className={className}
      />
      <button
        className={classNames(
          styles["btn-change-number"],
          value >= maxValue && styles["btn-change-number--disabled"]
        )}
        type="button"
        onClick={handleIncrease}
      >
        <i>
          <PlusWhiteIcon />
        </i>
      </button>
    </span>
  );
};

export default InputNumberV2;
