import {
  SET_IS_CLEAR_DATA,
  GET_LIST_BUDGET_REQUEST,
  GET_LIST_BUDGET_SUCCESS,
  GET_LIST_BUDGET_FAILED,
  SET_BUDGET_SELECTED,
  UPDATE_AVAILABLE_AMOUNT_BUDGET_SELECTED,
  GET_BUDGET_INFO_REQUEST,
  GET_BUDGET_INFO_SUCCESS,
  GET_BUDGET_INFO_FAILED,
  GET_HISTORY_BUDGET_REQUEST,
  GET_HISTORY_BUDGET_SUCCESS,
  GET_HISTORY_BUDGET_FAILED,
  CONFIRM_ADVANCE_REQUEST,
  CONFIRM_ADVANCE_SUCCESS,
  CONFIRM_ADVANCE_FAILED,
  GET_TRANSACTION_HISTORY_UB_REQUEST,
  GET_TRANSACTION_HISTORY_UB_SUCCESS,
  GET_TRANSACTION_HISTORY_UB_FAILED,
  REVOCATION_BUDGET_REQUEST,
  REVOCATION_BUDGET_SUCCESS,
  REVOCATION_BUDGET_FAILED,
  UPDATE_TRANSACTION_HISTORY_UB,
  CLEAR_BUDGET,
  UPDATE_BUDGET_HISTORY,
  SET_LIST_ACCOUNT_BUDGET,
} from './constants';

export const setIsClearData = isClearData => ({
  type: SET_IS_CLEAR_DATA,
  isClearData,
});

export const setBudgetSelected = budgetSelected => ({
  type: SET_BUDGET_SELECTED,
  budgetSelected,
});

export const updateAvailableAmountBudgetSelected = payload => ({
  type: UPDATE_AVAILABLE_AMOUNT_BUDGET_SELECTED,
  payload,
});

export const getListBudgetRequest = (payload, onSuccess, onError) => ({
  type: GET_LIST_BUDGET_REQUEST,
  payload,
  onSuccess,
  onError,
});

export const getListBudgetSuccess = listBudget => ({
  type: GET_LIST_BUDGET_SUCCESS,
  listBudget,
});

export const getListBudgetFailed = error => ({
  type: GET_LIST_BUDGET_FAILED,
  error,
});

export const getBudgetInfoRequest = (payload, onSuccess, onError) => ({
  type: GET_BUDGET_INFO_REQUEST,
  payload,
  onSuccess,
  onError,
});

export const getBudgetInfoSuccess = payload => ({
  type: GET_BUDGET_INFO_SUCCESS,
  payload,
});

export const getBudgetInfoFailed = error => ({
  type: GET_BUDGET_INFO_FAILED,
  error,
});

export const getHistoryBudgetRequest = (payload, onSuccess, onError) => ({
  type: GET_HISTORY_BUDGET_REQUEST,
  payload,
  onSuccess,
  onError,
});

export const getHistoryBudgetSuccess = listHistoryBudget => ({
  type: GET_HISTORY_BUDGET_SUCCESS,
  listHistoryBudget,
});

export const getHistoryBudgetFailed = error => ({
  type: GET_HISTORY_BUDGET_FAILED,
  error,
});

export const getHistoryTransactionUBRequest = (
  payload,
  onSuccess,
  onError,
) => ({
  type: GET_TRANSACTION_HISTORY_UB_REQUEST,
  payload,
  onSuccess,
  onError,
});

export const getHistoryTransactionUBSuccess = listTransactionHistoryUB => ({
  type: GET_TRANSACTION_HISTORY_UB_SUCCESS,
  listTransactionHistoryUB,
});

export const getHistoryTransactionUBFailed = error => ({
  type: GET_TRANSACTION_HISTORY_UB_FAILED,
  error,
});

export const confirmAdvanceRequest = (payload, onSuccess, onError) => ({
  type: CONFIRM_ADVANCE_REQUEST,
  payload,
  onSuccess,
  onError,
});

export const confirmAdvanceSuccess = payload => ({
  type: CONFIRM_ADVANCE_SUCCESS,
  payload,
});

export const confirmAdvanceFailed = error => ({
  type: CONFIRM_ADVANCE_FAILED,
  error,
});

export const revocationBudgetRequest = (payload, onSuccess, onError) => ({
  type: REVOCATION_BUDGET_REQUEST,
  payload,
  onSuccess,
  onError,
});

export const revocationBudgetSuccess = payload => ({
  type: REVOCATION_BUDGET_SUCCESS,
  payload,
});

export const revocationBudgetFailed = error => ({
  type: REVOCATION_BUDGET_FAILED,
  error,
});

export const updateTransactionHistoryUB = error => ({
  type: UPDATE_TRANSACTION_HISTORY_UB,
  error,
});

export const clearBudget = () => ({
  type: CLEAR_BUDGET,
});

export const updateBudgetHistory = () => ({
  type: UPDATE_BUDGET_HISTORY,
});

export const setListAccountBudget = listAccountBudget => ({
  type: SET_LIST_ACCOUNT_BUDGET,
  listAccountBudget,
});
