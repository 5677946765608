import { actionTypes } from "@shared/redux/actionTypes";

export const GET_STATUS_ORDER_DETAIL = actionTypes(
  "order/GET_STATUS_ORDER_DETAIL"
);

export const GET_STATUS_ORDER_DETAIL_NEWS = actionTypes(
  "order/GET_STATUS_ORDER_DETAIL_NEWS"
);

export const PUSH_CANCEL_ORDER_NEWS = actionTypes(
  "order/PUSH_CANCEL_ORDER_NEWS"
);

export const PUSH_CANCEL_ORDER = actionTypes("order/PUSH_CANCEL_ORDER");

export const CREATE_ORDER_NEWSPAPER_NEWS = actionTypes(
  "order/CREATE_ORDER_NEWSPAPER_NEWS"
);
