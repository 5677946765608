import {
  GET_SUPPORTED_VERSION_FAILED,
  GET_SUPPORTED_VERSION_SUCCESS,
  UPDATE_IS_FORCE_TO_UPDATE_REQUEST,
  UPDATE_IS_MENTION_TO_UPDATE_SUCCESS,
} from './constants';

const initialState = {
  supportedVersion: [],
  latestVersion: '',
  isMentionToUpdate: false,
  isForceToUpdate: false,
};

const appVersionReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SUPPORTED_VERSION_SUCCESS: {
      return {
        ...state,
        supportedVersion: action.payload?.supportedVersion,
        latestVersion: action.payload?.latestVersion,
      };
    }
    case GET_SUPPORTED_VERSION_FAILED: {
      return {...initialState};
    }
    case UPDATE_IS_MENTION_TO_UPDATE_SUCCESS: {
      return {
        ...state,
        isMentionToUpdate: action.payload,
      };
    }
    case UPDATE_IS_FORCE_TO_UPDATE_REQUEST: {
      return {
        ...state,
        isForceToUpdate: action.payload,
      };
    }
    default:
      return state;
  }
};

export default appVersionReducer;
