export const GET_LIST_PARTNER_EXCHANGE_POINT_REQUEST =
  'point/GET_LIST_PARTNER_EXCHANGE_POINT_REQUEST';

export const GET_LIST_PARTNER_EXCHANGE_POINT_SUCCESS =
  'point/GET_LIST_PARTNER_EXCHANGE_POINT_SUCCESS';

export const GET_LIST_PARTNER_EXCHANGE_POINT_FAILED =
  'point/GET_LIST_PARTNER_EXCHANGE_POINT_FAILED';

export const MAPPING_MEMBER_WITH_PARTNER_REQUEST =
  'point/MAPPING_MEMBER_WITH_PARTNER_REQUEST';

export const MAPPING_MEMBER_WITH_PARTNER_SUCCESS =
  'point/MAPPING_MEMBER_WITH_PARTNER_SUCCESS';

export const MAPPING_MEMBER_WITH_PARTNER_FAILED =
  'point/MAPPING_MEMBER_WITH_PARTNER_FAILED';

export const REMOVE_MAPPING_MEMBER_WITH_PARTNER_REQUEST =
  'point/REMOVE_MAPPING_MEMBER_WITH_PARTNER_REQUEST';

export const REMOVE_MAPPING_MEMBER_WITH_PARTNER_SUCCESS =
  'point/REMOVE_MAPPING_MEMBER_WITH_PARTNER_SUCCESS';

export const REMOVE_MAPPING_MEMBER_WITH_PARTNER_FAILED =
  'point/REMOVE_MAPPING_MEMBER_WITH_PARTNER_FAILED';

export const GET_PARTNER_INFO_REQUEST = 'point/GET_PARTNER_INFO_REQUEST';

export const GET_PARTNER_INFO_SUCCESS = 'point/GET_PARTNER_INFO_SUCCESS';

export const GET_PARTNER_INFO_FAILED = 'point/GET_PARTNER_INFO_FAILED';

export const EXCHANGE_POINT_PARTNER_REQUEST =
  'point/EXCHANGE_POINT_PARTNER_REQUEST';

export const EXCHANGE_POINT_PARTNER_SUCCESS =
  'point/EXCHANGE_POINT_PARTNER_SUCCESS';

export const EXCHANGE_POINT_PARTNER_FAILED =
  'point/EXCHANGE_POINT_PARTNER_FAILED';

export const CLEAR_PARTNER_INFO = 'point/CLEAR_PARTNER_INFO';
