import {} from './actions'
import {
  FNB_STORE_RATING,
  FNB_STORE_UPDATE_RATING,
  GET_LIST_TAGS_BY_ORDER_ID_REQUEST,
} from './types'
import { END_POINTS } from '@shared/constants/apiUrl'
import { call, fork, select, takeEvery } from 'redux-saga/effects'
import { APIUtils, LocalStorage } from 'utils'
import moment from 'moment'

async function ratingApi(body) {
  return APIUtils.post(END_POINTS.rating, { body: body })
}

export function* storeRating(obj) {
  const { onSuccess, onError, RefId } = obj.payload
  try {
    const result = yield call(ratingApi, obj.payload)
    if (result.status >= 200 && result.status < 300) {
      if (typeof onSuccess === 'function') {
        const account = yield select((state) => state.account)
        console.log('currentUserInfo: ', account)
        yield call(
          LocalStorage.current.setItem,
          `IS_RATING_TODAY_${account.memberCode}_${RefId}`,
          `${moment().format('DD/MM/yyyy')}`,
        )
        onSuccess(result)
      }
    } else {
      if (typeof onError === 'function') {
        onError(result)
      }
    }
  } catch (err) {
    if (typeof onError === 'function') {
      onError(err)
    }
  }
}

async function updateRatingApi(body) {
  return APIUtils.put(END_POINTS.rating, { body: body })
}

export function* updateRating(obj) {
  const { onSuccess, onError } = obj.payload
  try {
    const result = yield call(updateRatingApi, obj.payload)
    if (result.status >= 200 && result.status < 300) {
      if (typeof onSuccess === 'function') {
        onSuccess(result)
      }
    } else {
      if (typeof onError === 'function') {
        onError(result)
      }
    }
  } catch (err) {
    if (typeof onError === 'function') {
      onError(err)
    }
  }
}

async function getListTagsByOrderIdApi(orderId) {
  return APIUtils.get(
    END_POINTS.GET_LIST_TAGS_BY_ORDER_ID + `?orderCode=${orderId}`,
  )
}

export function* getListTagsByOrderIdSaga(obj) {
  const { onSuccess, onError, payload } = obj
  try {
    const result = yield call(getListTagsByOrderIdApi, payload)
    if (result.status >= 200 && result.status < 300) {
      if (typeof onSuccess === 'function') {
        onSuccess(result)
      }
    } else {
      if (typeof onError === 'function') {
        onError(result)
      }
    }
  } catch (err) {
    if (typeof onError === 'function') {
      onError(err)
    }
  }
}

function* watchStoreRating() {
  yield takeEvery(FNB_STORE_RATING, storeRating)
  yield takeEvery(FNB_STORE_UPDATE_RATING, updateRating)
  yield takeEvery(GET_LIST_TAGS_BY_ORDER_ID_REQUEST, getListTagsByOrderIdSaga)
}

export default function* rootChild() {
  yield fork(watchStoreRating)
}
