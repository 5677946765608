const prefix = 'videoList_UtopVideo';

// Fetch hashtag list
export const VIDEO_LIST_REQUEST = prefix + '/VIDEO_LIST_REQUEST';
export const VIDEO_LIST_SUCCESS = prefix + '/VIDEO_LIST_SUCCESS';
export const VIDEO_LIST_FAILED = prefix + '/VIDEO_LIST_FAILED';
export const LIKE_VIDEO_REQUEST = prefix + '/LIKE_VIDEO_REQUEST';
export const LIKE_VIDEO_SUCCESS = prefix + '/LIKE_VIDEO_SUCCESS';
export const LIKE_VIDEO_FAILED = prefix + '/LIKE_VIDEO_FAILED';
export const DISLIKE_VIDEO_REQUEST = prefix + '/DISLIKE_VIDEO_REQUEST';
export const DISLIKE_VIDEO_SUCCESS = prefix + '/DISLIKE_VIDEO_SUCCESS';
export const DISLIKE_VIDEO_FAILED = prefix + '/DISLIKE_VIDEO_FAILED';
export const FOLLOW_VIDEO_REQUEST = prefix + '/FOLLOW_VIDEO_REQUEST';
export const FOLLOW_VIDEO_SUCCESS = prefix + '/FOLLOW_VIDEO_SUCCESS';
export const FOLLOW_VIDEO_FAILED = prefix + '/FOLLOW_VIDEO_FAILED';
export const UNFOLLOW_VIDEO_REQUEST = prefix + '/UNFOLLOW_VIDEO_REQUEST';
export const UNFOLLOW_VIDEO_SUCCESS = prefix + '/UNFOLLOW_VIDEO_SUCCESS';
export const UNFOLLOW_VIDEO_FAILED = prefix + '/UNFOLLOW_VIDEO_FAILED';
export const FAVOURITE_VIDEO_REQUEST = prefix + '/FAVOURITE_VIDEO_REQUEST';
export const FAVOURITE_VIDEO_SUCCESS = prefix + '/FAVOURITE_VIDEO_SUCCESS';
export const FAVOURITE_VIDEO_FAILED = prefix + '/FAVOURITE_VIDEO_FAILED';
export const DISFAVOURITE_VIDEO_REQUEST =
  prefix + '/DISFAVOURITE_VIDEO_REQUEST';
export const DISFAVOURITE_VIDEO_SUCCESS =
  prefix + '/DISFAVOURITE_VIDEO_SUCCESS';
export const DISFAVOURITE_VIDEO_FAILED = prefix + '/DISFAVOURITE_VIDEO_FAILED';

export const CURRENT_VIDEO = prefix + '/CURRENT_VIDEO';
export const CLEAR_ERRORS = prefix + '/CLEAR_ERRORS';
export const REPORT_VIDEO_REQUEST = prefix + '/REPORT_VIDEO_REQUEST';
export const REPORT_VIDEO_SUCCESS = prefix + '/REPORT_VIDEO_SUCCESS';
export const REPORT_VIDEO_FAILED = prefix + '/REPORT_VIDEO_FAILED';
export const GET_REASON_REPORT_REQUEST = prefix + '/GET_REASON_REPORT_REQUEST';
export const GET_REASON_REPORT_SUCCESS = prefix + '/GET_REASON_REPORT_SUCCESS';
export const GET_REASON_REPORT_FAILED = prefix + '/GET_REASON_REPORT_FAILED';
export const BLOCK_USER_REQUEST = prefix + '/BLOCK_USER_REQUEST';
export const BLOCK_USER_SUCCESS = prefix + '/BLOCK_USER_SUCCESS';
export const BLOCK_USER_FAILED = prefix + '/BLOCK_USER_FAILED';
export const RESET_BLOCK_USER = prefix + '/RESET_BLOCK_USER';
export const RESET_REPORT_VIDEO = prefix + '/RESET_REPORT_VIDEO';

// comment
export const LIST_COMMENT_REQUEST = prefix + '/LIST_COMMENT_REQUEST';
export const LIST_COMMENT_SUCCESS = prefix + '/LIST_COMMENT_SUCCESS';
export const LIST_COMMENT_FAILED = prefix + '/LIST_COMMENT_FAILED';

export const SEND_COMMENT_REQUEST = prefix + '/SEND_COMMENT_REQUEST';
export const SEND_COMMENT_SUCCESS = prefix + '/SEND_COMMENT_SUCCESS';
export const SEND_COMMENT_FAILED = prefix + '/SEND_COMMENT_FAILED';

export const DELETE_COMMENT_REQUEST = prefix + '/DELETE_COMMENT_REQUEST';
export const DELETE_COMMENT_SUCCESS = prefix + '/DELETE_COMMENT_SUCCESS';
export const DELETE_COMMENT_FAILED = prefix + '/DELETE_COMMENT_FAILED';

export const GET_COUNT_COMMENT_REQUEST = prefix + '/GET_COUNT_COMMENT_REQUEST';
export const GET_COUNT_COMMENT_SUCCESS = prefix + '/GET_COUNT_COMMENT_SUCCESS';
export const GET_COUNT_COMMENT_FAILED = prefix + '/GET_COUNT_COMMENT_FAILED';

export const REPORT_COMMENT_REQUEST = prefix + '/REPORT_COMMENT_REQUEST';
export const REPORT_COMMENT_SUCCESS = prefix + '/REPORT_COMMENT_SUCCESS';
export const REPORT_COMMENT_FAILED = prefix + '/REPORT_COMMENT_FAILED';

export const LIKE_COMMENT_REQUEST = prefix + '/LIKE_COMMENT_REQUEST';
export const LIKE_COMMENT_SUCCESS = prefix + '/LIKE_COMMENT_SUCCESS';
export const LIKE_COMMENT_FAILED = prefix + '/LIKE_COMMENT_FAILED';

export const UNLIKE_COMMENT_REQUEST = prefix + '/UNLIKE_COMMENT_REQUEST';
export const UNLIKE_COMMENT_SUCCESS = prefix + '/UNLIKE_COMMENT_SUCCESS';
export const UNLIKE_COMMENT_FAILED = prefix + '/UNLIKE_COMMENT_FAILED';

export const DOWNLOAD_VIDEO_REQUEST = prefix + '/DOWNLOAD_VIDEO_REQUEST';
export const DOWNLOAD_VIDEO_SUCCESS = prefix + '/DOWNLOAD_VIDEO_SUCCESS';
export const DOWNLOAD_VIDEO_FAILED = prefix + '/DOWNLOAD_VIDEO_FAILED';

export const SAVE_VIDEO_DETAIL = prefix + '/SAVE_VIDEO_DETAIL';
export const DELETE_VIDEO_DETAIL = prefix + '/DELETE_VIDEO_DETAIL';

export const SAVE_USER_DETAIL = prefix + '/SAVE_USER_DETAIL';
export const DELETE_USER_DETAIL = prefix + '/DELETE_USER_DETAIL';
