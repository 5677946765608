import {
  GET_LIST_VIDEO_BY_LOCATION_FAILED,
  GET_LIST_VIDEO_BY_LOCATION_REQUEST,
  GET_LIST_VIDEO_BY_LOCATION_SUCCESS,
  GET_LIST_VIDEO_DYNAMIC_FAILED,
  GET_LIST_VIDEO_DYNAMIC_REQUEST,
  GET_LIST_VIDEO_DYNAMIC_SUCCESS,
} from './constants';

export const getListVideoDynamicHandle = (payload = {}, onSuccess) => ({
  type: GET_LIST_VIDEO_DYNAMIC_REQUEST,
  onSuccess,
  payload,
});

export const getListVideoDynamicSuccess = videos => ({
  type: GET_LIST_VIDEO_DYNAMIC_SUCCESS,
  videos,
});

export const getListVideoDynamicFailed = payload => ({
  type: GET_LIST_VIDEO_DYNAMIC_FAILED,
  payload,
});

export const getListVideoByLocationHandle = (payload = {}) => ({
  type: GET_LIST_VIDEO_BY_LOCATION_REQUEST,
  payload,
});

export const getListVideoByLocationSuccess = videos => ({
  type: GET_LIST_VIDEO_BY_LOCATION_SUCCESS,
  videos,
});

export const getListVideoByLocationFailed = payload => ({
  type: GET_LIST_VIDEO_BY_LOCATION_FAILED,
  payload,
});
