const prefix = 'MARKET';

const types = {
  SEARCH_MARKET_REQUEST: prefix + '/SEARCH_MARKET_REQUEST',
  SEARCH_MARKET_SUCCESS: prefix + '/SEARCH_MARKET_SUCCESS',
  SEARCH_MARKET_FAILED: prefix + '/SEARCH_MARKET_FAILED',
  SET_IS_END_SEARCH_MARKET: prefix + '/SET_IS_END_SEARCH_MARKET',
  GET_PRODUCT_DETAIL_MARKET_REQUEST: prefix + '/GET_PRODUCT_DETAIL_MARKET_REQUEST',
  GET_PRODUCT_DETAIL_MARKET_SUCCESS: prefix + '/GET_PRODUCT_DETAIL_MARKET_SUCCESS',
  GET_PRODUCT_DETAIL_MARKET_FAILED: prefix + '/GET_PRODUCT_DETAIL_MARKET_FAILED',
  CLEAR_SEARCH_MARKET: prefix + '/CLEAR_SEARCH_MARKET',
  CLEAR_SEARCH_HISTORY_MARKET: prefix + '/CLEAR_SEARCH_HISTORY_MARKET',
  CLEAR_PRODUCT_DETAIL_MARKET: prefix + '/CLEAR_PRODUCT_DETAIL_MARKET',
};

export default types;
