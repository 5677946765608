import {GET_PRODUCT_SUGGEST_BY_USER_SUCCESS} from './constants';
const initialState = {
  listSuggestProductByUser: [],
};

const storeReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_PRODUCT_SUGGEST_BY_USER_SUCCESS:
      return {
        ...state,
        listSuggestProductByUser: action?.balance || [],
      };
    default:
      return state;
  }
};

export default storeReducer;
