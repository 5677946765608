import React from "react";
import { imagesIcon } from "img";
import PropTypes from "prop-types";
import { CImg, CModal, CModalFooter, CModalHeader } from "@coreui/react";
import "./PopupRating.scss";

const dataRating = [
  {
    id: 1,
    stardisable: imagesIcon.stardisable,
    staractive: imagesIcon.staractive,
  },
  {
    id: 2,
    stardisable: imagesIcon.stardisable,
    staractive: imagesIcon.staractive,
  },
  {
    id: 3,
    stardisable: imagesIcon.stardisable,
    staractive: imagesIcon.staractive,
  },
  {
    id: 4,
    stardisable: imagesIcon.stardisable,
    staractive: imagesIcon.staractive,
  },
  {
    id: 5,
    stardisable: imagesIcon.stardisable,
    staractive: imagesIcon.staractive,
  },
];
const PopupRating = ({
  title,
  titleContent,
  showModal,
  setShowModal,
  handleRatingStar,
  starActive,
}) => {
  const handleOnClick = (star) => {
    handleRatingStar(star);
  };
  return (
    <>
      <CModal
        title="Modal title"
        color="warning"
        show={showModal}
        onClose={() => setShowModal(false)}
        style={{ marginTop: "22vh" }}
        scrollable={false}
      >
        <CModalHeader style={{ padding: 0 }}>
          <CImg
            style={{ width: "100%", height: "100%" }}
            className="status-refund"
            src={imagesIcon.ratestore}
          />
        </CModalHeader>

        <CModalFooter
          className="action-mark-refund"
          style={{ justifyContent: "center" }}
        >
          <div className="rate-store__wrapper-content">
            <div className="rate-store__title">{title}</div>
            <div className="rate-store__content">{titleContent}</div>
            <div className="d-flex">
              {dataRating.map((star) => (
                <div key={star.id} onClick={() => handleOnClick(star)}>
                  {star.id <= starActive ? (
                    <CImg
                      style={{ width: 32, height: 32, margin: 8 }}
                      src={star.staractive}
                    />
                  ) : (
                    <CImg
                      style={{ width: 32, height: 32, margin: 8 }}
                      src={star.stardisable}
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
        </CModalFooter>
      </CModal>
    </>
  );
};
PopupRating.defaultProps = {
  title: "Đánh giá cửa hàng",
  titleContent:
    "Cảm ơn bạn đã sử dụng dịch vụ, hãy cho chúng tôi lắng nghe ý kiến đánh giá của bạn nhé!",
};
PopupRating.propTypes = {
  title: PropTypes.string,
  titleContent: PropTypes.string,
};

export default PopupRating;
