import React from "react";
import Modal from "../Modal";
import styles from "./Alert.module.scss";

function Alert(props) {
  const {
    show,
    onDismiss,
    header,
    content,
    textBtnClose,
    headerComponent,
    contentComponent,
    footerComponent,
  } = props;

  return (
    <Modal show={show} onDismiss={onDismiss} className={styles["alert"]}>
      {headerComponent || (
        <div className={styles["alert__header"]}>{header || "UTOP"}</div>
      )}

      {contentComponent || (
        <div className={styles["alert__content"]}>{content}</div>
      )}

      {footerComponent || (
        <div className={styles["alert__footer"]}>
          <button onClick={onDismiss} type="button">
            {textBtnClose}
          </button>
        </div>
      )}
    </Modal>
  );
}

export default Alert;
