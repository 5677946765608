import { genActionTypes } from "utils/actionGenerator";
// import { TYPE as ROOT } from "views/common/store/constants";

export const TYPE = "RatingPage";

export const SET_LOADING = `${TYPE}_SET_LOADING`;
export const GET_RATING_LIST = genActionTypes(`${TYPE}_GET_RATING_LIST`);
export const GET_MERCHANT_INFO = genActionTypes(`${TYPE}_GET_MERCHANT_INFO`);
export const GET_STORE_INFO = genActionTypes(`${TYPE}_GET_STORE_INFO`);
export const GET_BEST_SELLER_LIST = genActionTypes(
  `${TYPE}_GET_BEST_SELLER_LIST`
);
export const GET_PRODUCT_LIST = genActionTypes(`${TYPE}_GET_PRODUCT_LIST`);
export const GET_RECENT_ORDERED_PRODUCT_CODE_LIST = genActionTypes(
  `${TYPE}_GET_RECENT_ORDERED_PRODUCT_CODE_LIST`
);
export const GET_SUGGESTED_COUPON_LIST = genActionTypes(
  `${TYPE}_GET_SUGGESTED_COUPON_LIST`
);

export const SAVE_SUGGESTED_COUPON = genActionTypes(
  `${TYPE}_SAVE_SUGGESTED_COUPON`
);
export const GET_OUT_OF_STOCK_PRODUCT_LIST = genActionTypes(
  `${TYPE}_GET_OUT_OF_STOCK_PRODUCT_LIST`
);
export const GET_SHORT_LINK_TO_SHARE = genActionTypes(
  `${TYPE}_GET_SHORT_LINK_TO_SHARE`
);
export const GET_LIKE_COMMENT_LIST = genActionTypes(
  `${TYPE}_GET_LIKE_COMMENT_LIST`
);
export const POST_LIKE_CMT = genActionTypes(`${TYPE}_POST_LIKE_CMT`);
export const POST_UNLIKE_CMT = genActionTypes(`${TYPE}_POST_UNLIKE_CMT`);

export const GET_STORE_INFO_V2 = genActionTypes(`${TYPE}_GET_STORE_INFO_V2`);

export const POST_LIKE_SUB_CMT = genActionTypes(`${TYPE}_POST_LIKE_SUB_CMT`);
export const POST_UNLIKE_SUB_CMT = genActionTypes(
  `${TYPE}_POST_UNLIKE_SUB_CMT`
);
export const POST_REPLY_CMT = genActionTypes(`${TYPE}_POST_REPLY_CMT`);

export const GET_FILTER_BY_STAR_RATE = genActionTypes(
  `${TYPE}_GET_FILTER_BY_STAR_RATE`
);
export const GET_USER_LIKED_LIST_OF_RATING_POST = genActionTypes(
  `${TYPE}_GET_USER_LIKED_LIST_OF_RATING_POST`
);
export const GET_USER_LIKED_LIST_OF_COMMENT = genActionTypes(
  `${TYPE}_GET_USER_LIKED_LIST_OF_COMMENT`
);
export const GET_SUMMARY_RATING = genActionTypes(`${TYPE}_GET_SUMMARY_RATING`);
export const CLEAR_RATING_DATA = genActionTypes(`${TYPE}_CLEAR_RATING_DATA`);
