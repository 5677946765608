import { actionTypes } from "@shared/redux/actionTypes";

export const CHECK_NUMBER_TRANSFER = "payment/CHECK_NUMBER_TRANSFER";
export const UPDATE_LIST_RECEVER = "payment/UPDATE_LIST_RECEVER";
export const CLEAR_DATA_REQUEST = "payment/CLEAR_DATA_REQUEST";
export const GET_UTOP_BALANCE = "payment/GET_UTOP_BALANCE";
export const TRANSFER_UTOP = "payment/TRANSFER_UTOP";
export const SET_PIN_CODE = "payment/SET_PIN_CODE";
export const CREATE_ORDER = "payment/CREATE_ORDER";
export const CREATE_ORDER_UTOP_HUB = "payment/CREATE_ORDER_UTOP_HUB";

export const GET_PAYMENT_INFO = actionTypes("payment/GET_PAYMENT_INFO");
