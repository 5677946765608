import { call, put, takeLatest, fork } from "redux-saga/effects";
import actions from "./actions";
import * as TYPE from "./constants";
import api from "../api/api";

const sagas = {
  *[TYPE.GET_LIST_COUPON.request](action) {
    try {
      const data = yield call(
        api[TYPE.GET_LIST_COUPON.request],
        action.payload
      );

      if (data.status === 200) {
        yield put(actions.GET_LIST_COUPON.success(data.data));
      } else {
        yield put(actions.GET_LIST_COUPON.failure(data?.data));
      }
    } catch (error) {
      yield put(actions.GET_LIST_COUPON.failure(error?.data));
    }
  },

  *[TYPE.SEARCH_COUPON_BY_COUPON_CODE.request](action) {
    try {
      const response = yield call(
        api[TYPE.SEARCH_COUPON_BY_COUPON_CODE.request],
        action.payload
      );

      if (response.status === 200) {
        yield put(actions.SEARCH_COUPON_BY_COUPON_CODE.success(response.data));
      } else {
        yield put(actions.SEARCH_COUPON_BY_COUPON_CODE.failure(response?.data));
      }
    } catch (error) {
      yield put(actions.SEARCH_COUPON_BY_COUPON_CODE.failure(error?.data));
    }
  },
};

function* watchedSaga() {
  yield takeLatest(
    TYPE.GET_LIST_COUPON.request,
    sagas[TYPE.GET_LIST_COUPON.request]
  );
  yield takeLatest(
    TYPE.SEARCH_COUPON_BY_COUPON_CODE.request,
    sagas[TYPE.SEARCH_COUPON_BY_COUPON_CODE.request]
  );
}

export default function* rootChild() {
  yield fork(watchedSaga);
}
