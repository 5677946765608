import { LoadingOverlay } from "views/common/component";

const LoadingScreen = () => {
  return (
    <div
      style={{
        height: "100%",
        minHeight: "100vh",
        position: "relative",
      }}
    >
      <LoadingOverlay isLoading />
    </div>
  );
};

export default LoadingScreen;
