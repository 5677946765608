/* eslint-disable no-param-reassign */
/* eslint-disable no-case-declarations */
/* eslint-disable no-shadow */
import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
// import { Alert, BackHandler } from "react-native";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  calCouponDiscountRequest,
  getCouponDetailRequest,
  clearErrors,
  setDeliveryAddress,
  setVoucher,
  setDescription,
  setNumberTable,
  clearAllowcateCoupon,
  allowcationCouponSuccess,
  setTakeAway,
  setDeliveryAddressSelected,
  getSuggestDiscountShippingRequest,
} from "@shared/redux/cart/actions";
import { setShowMessageError } from "@shared/redux/coupon/actions";
import { getListCouponSuggestHandle } from "@shared/redux/storeDetail/actions";
import {
  clearProductCart,
  deleteProductCart,
  updateProductCart,
  createOrderFnbHandle,
  updateProductCartOutOfStock,
  setListCouponSuggestForStore,
} from "@shared/redux/smartMenu/actions";
import { GET_COUPON_DETAIL_REQUEST } from "@shared/redux/cart/constants";
import { GET_AVAILABLE_COUPON_REQUEST } from "@shared/redux/couponForVoucher/constants";
import {
  getMessageError,
  getAllowcationCoupon,
  getDeliveryAddress,
  getVoucher,
  getDescription,
  getNumberTable,
  getTakeAway,
  getDeliveryAddressSelected,
} from "@shared/redux/cart/selectors";
import {
  getListProduct,
  getDataStore,
  getListCouponSuggest,
} from "@shared/redux/smartMenu/selectors";
import { getLoadingSelector } from "@shared/redux/loading/selector";
import {
  //   ReactUtils,
  getIsShowMessageErrorCoupon,
  checkObjKeys,
  //   insightTrackEvent,
  numberWithCommas,
} from "utils";
import { getHistoryRoute } from "utils/getHistoryRoute";
import {
  getAvailableCouponHandle,
  getCouponDetailForVoucherHandle,
} from "@shared/redux/couponForVoucher/actions";
// import { strings } from "@i18n";
import {
  getDefaultAddressUserRequest,
  calculateShippingRequest,
} from "@shared/redux/orderAddressFood/actions";
import { CREATE_ORDER_FNB_REQUEST } from "@shared/redux/smartMenu/constants";
import {
  CALCULATE_SHIPPING_REQUEST,
  GET_DEFAULT_ADDRESS_USER_REQUEST,
} from "@shared/redux/orderAddressFood/constants";
// import { isEqual } from "lodash";
import NavigationServices from "utils/navigationServices";
import { isEqual } from "lodash";
import methodDelivery from "../../../../constants/methodDelivery";
import {
  createListMethodDelivery,
  createMethodDelivery,
  couponErrorMessage,
  getTotalPrice,
  getTotalItem,
  getNameVoucher,
  getCodeVoucher,
  getMethodDelivery,
  // checkTableDelivery,
  shippingDiscount,
} from "./utils";
import CartMarketView from "./CartMarket.view";

const CartMarket = (props) => {
  const [merchantId, setMerchantId] = useState("");
  const [isVisibleNote, setVisibleNote] = useState(false);
  const [itemDetailSelected, setItemDetailSelected] = useState(null);
  const [deliveryAddressOptions, setDeliveryAddressOptions] = useState(null);
  const [isVisibleAddProduct, setVisibleAddProduct] = useState(false);
  const [isVisibleMethodDelivery, setVisibleMethodDelivery] = useState(false);
  const [isVisibleMethodDeliveryTable, setVisibleMethodDeliveryTable] =
    useState(false);
  const [discount, setDiscount] = useState(0);
  const [isErrorCoupon, setIsErrorCoupon] = useState(false);
  const [messageError, setMessageError] = useState("");
  const [dataTimer, setDataTimer] = useState("");
  const [alertAction, setAlertAction] = useState({});
  //   const scrollViewRef = useRef();
  const [shippingInfo, setShippingInfo] = useState({});
  const [methodDeliveryMaster, setMethodDeliveryMaster] = useState({});
  const [deliveryInfo, setDeliveryInfo] = useState({});
  const deliveryInfoRef = useRef();
  const isShowMessageError = useRef(true);
  const redirectToUserAddress = useRef(false);
  const deliveryInfoTemp = useRef();
  const listProductRef = useRef();
  const showMsgOutOfStock = useRef(true);
  const isShowMsgOutOfStock = useRef(false);
  const [isVisibleShippingDiscount, setVisibleShippingDiscount] =
    useState(false);
  //   const handleBackPress = () => {
  //     NavigationServices.goBack();
  //     return true;
  //   };

  const { oldDeliveryAddress, isFromMarket, storeInfo } =
    NavigationServices.getParams(props);

  useEffect(() => {
    if (props?.listMethodDelivery) {
      const jsonDelivery = getMethodDelivery(props.listMethodDelivery);
      if (Object.keys(jsonDelivery)?.length > 0) {
        setMethodDeliveryMaster(jsonDelivery);
      } else {
        setMethodDeliveryMaster(props?.listMethodDelivery[0]);
      }
    }
  }, [props?.listMethodDelivery]);

  useEffect(() => {
    props.setNumberTable("");
  }, []);

  useEffect(() => {
    deliveryInfoTemp.current = deliveryInfo;
  }, [deliveryInfo]);

  useEffect(() => {
    handleDeliveryMethod();
  }, [props?.listMethodDelivery]);

  useEffect(() => {
    handleDeliveryMethod();
  }, [oldDeliveryAddress]);

  useEffect(() => {
    // const unsubscribeFocus = props.navigation.addListener("focus", async () => {
    //   //   BackHandler.addEventListener("hardwareBackPress", handleBackPress);
    // });
    setDiscount(0);
    // setStoreId(props.dataStore.storeId);
    setMerchantId(props.dataStore.merchantId);
    setDeliveryAddressOptions(props.dataStore.deliveryAddressOptions);
    if (
      !!props.listMethodDelivery &&
      props.listMethodDelivery.length > 0 &&
      props.dataStore.deliveryOptions
    ) {
      for (let i = 0; i < props.dataStore.deliveryOptions.length; i++) {
        const temp = props.dataStore.deliveryOptions[i].toLowerCase();
        // eslint-disable-next-line no-param-reassign
        props.dataStore.deliveryOptions[i] = temp;
        let checkExit = false;
        for (let j = 0; j < props.listMethodDelivery.length; j++) {
          if (
            props.dataStore.deliveryOptions[i]?.toLowerCase() ===
            props.listMethodDelivery[j]?.code?.toLowerCase()
          ) {
            checkExit = true;
          }
        }
        if (!checkExit) {
          props.listMethodDelivery.push(
            createMethodDelivery(
              props.dataStore.deliveryOptions[i],
              // eslint-disable-next-line no-undef
              methodDelivery
            )
          );
        }
      }
      props.listMethodDelivery.filter((item) =>
        props.dataStore.deliveryOptions.includes(item.code)
      );
      props.setDeliveryAddress(props.listMethodDelivery);
    } else {
      props.setDeliveryAddress(
        createListMethodDelivery(
          props.dataStore.deliveryOptions
          // eslint-disable-next-line no-undef
          // methodDelivery
        )
      );
    }

    props.getListCouponSuggestHandle(
      {
        feature: "orderfnb",
        storeId: props.dataStore.storeId,
        merchantId: props.dataStore.merchantId,
        isFromMarket,
      },
      (listCouponSuggest) => {
        if (
          listCouponSuggest.status === 200 &&
          listCouponSuggest.data.suggestedCouponDetails
        ) {
          props.setListCouponSuggestForStore(
            listCouponSuggest.data.suggestedCouponDetails
          );
        }
      }
    );

    return () => {
      // unsubscribeFocus();
    };
  }, []);

  useEffect(() => {
    if (props.voucher !== null) {
      if (
        getCodeVoucher(props.voucher) !== "" &&
        props.listProduct.length > 0
      ) {
        handleCouponDiscount(props.voucher);
      } else {
        props.setVoucher(null);
        props.setShowMessageError(true);
      }
    }
  }, [props.route]);

  useEffect(() => {
    getHistoryRoute(function () {
      if (isShowMsgOutOfStock.current) {
        if (
          props?.listProduct?.length > 0 &&
          props?.listProduct.filter((elm) => {
            return elm.isOutOfStock;
          })?.length > 0
        ) {
          showMsgOutOfStock.current = false;
        } else {
          showMsgOutOfStock.current = true;
        }
      }
      if (!isEqual(listProductRef.current, props.listProduct)) {
        if (props.voucher !== null && props.listProduct.length > 0) {
          handleCouponDiscount(props.voucher);
        }
        const jsonDelivery = getMethodDelivery(props.listMethodDelivery);
        if (Object.keys(jsonDelivery)?.length > 0) {
          if (jsonDelivery?.code === "home") {
            if (
              props.listProduct?.length > 0 &&
              checkObjKeys(deliveryInfo, "address")
            ) {
              calculateShippingWithAddress(deliveryInfo);
            }
          }
        }
        if (props.listProduct.length === 0) {
          // if (historyRoute.currentRouteName === "Cart") {
          //   if (isFromMarket) {
          //     NavigationServices.goBack();
          //     return;
          //   }
          //   switch (historyRoute.previousRouteName) {
          //     case "SmartOrderMenu":
          //       NavigationServices.navigate("SmartOrderMenu");
          //       break;
          //     case "ConfirmOrderFnB":
          //       NavigationServices.navigate("SmartOrderMenu");
          //       break;
          //     case "OrderAddressFood":
          //       NavigationServices.navigate("SmartOrderMenu");
          //       break;
          //     case "CouponForVoucher":
          //       NavigationServices.navigate("SmartOrderMenu");
          //       break;
          //     case "CouponDetailForVoucher":
          //       NavigationServices.navigate("SmartOrderMenu");
          //       break;
          //     default:
          //       NavigationServices.goBack();
          //       break;
          //   }
          // }
        }
      }
      listProductRef.current = props.listProduct;
    });
  }, [props.listProduct, props.voucher]);

  useLayoutEffect(() => {
    if (
      props.allowcationCoupon &&
      JSON.stringify(props.allowcationCoupon) !== "{}" &&
      props.allowcationCoupon.voucherCode
    ) {
      handleCouponDiscount(props.allowcationCoupon);
      if (props.allowcationCoupon.isAllocated) {
        props.getListCouponSuggestHandle(
          {
            feature: "orderfnb",
            storeId: props.dataStore.storeId,
            merchantId: props.dataStore.merchantId,
            isFromMarket,
          },
          (listCouponSuggest) => {
            if (
              listCouponSuggest.status === 200 &&
              listCouponSuggest.data.suggestedCouponDetails
            ) {
              props.setListCouponSuggestForStore(
                listCouponSuggest.data.suggestedCouponDetails
              );
            }
          }
        );
      }
    }
  }, [props.allowcationCoupon]);

  useEffect(() => {
    if (
      props.listMethodDelivery &&
      props.listMethodDelivery?.length === 1 &&
      props.listMethodDelivery[0].isCheck === false
    ) {
      selectMethodDelivery(props.listMethodDelivery[0]);
    }

    if (
      !!props.listMethodDelivery &&
      props.listMethodDelivery.length > 0 &&
      props.listMethodDelivery !== undefined &&
      props.dataStore.deliveryOptions !== undefined
    ) {
      for (let i = 0; i < props.dataStore.deliveryOptions.length; i++) {
        const temp = props.dataStore.deliveryOptions[i].toLowerCase();
        // eslint-disable-next-line no-param-reassign
        props.dataStore.deliveryOptions[i] = temp;
        let checkExit = false;
        for (let j = 0; j < props.listMethodDelivery.length; j++) {
          if (
            props.dataStore.deliveryOptions[i]?.toLowerCase() ===
            props.listMethodDelivery[j]?.code?.toLowerCase()
          ) {
            checkExit = true;
          }
        }
        if (!checkExit) {
          props.listMethodDelivery.push(
            createMethodDelivery(
              props.dataStore.deliveryOptions[i],
              // eslint-disable-next-line no-undef
              methodDelivery
            )
          );
        }
      }
      for (let i = 0; i < props.listMethodDelivery.length; i++) {
        let checkExit = false;
        for (let j = 0; j < props.dataStore.deliveryOptions.length; j++) {
          if (
            props.listMethodDelivery[i]?.code?.toLowerCase() ===
            props.dataStore.deliveryOptions[j].toLowerCase()
          ) {
            checkExit = true;
          }
        }
        if (!checkExit) {
          props.listMethodDelivery.splice(i, 1);
        }
      }
      props.setDeliveryAddress(props.listMethodDelivery);
    } else if (props.dataStore.deliveryOptions !== undefined) {
      props.setDeliveryAddress(
        createListMethodDelivery(
          props.dataStore.deliveryOptions,
          // eslint-disable-next-line no-undef
          methodDelivery
        )
      );
    }
  }, [props.listMethodDelivery, props.dataStore.deliveryOptions]);

  const handleDeliveryMethod = () => {
    if (props?.listMethodDelivery) {
      const jsonDelivery = getMethodDelivery(props.listMethodDelivery);
      if (Object.keys(jsonDelivery)?.length > 0) {
        if (jsonDelivery?.code === "home") {
          if (checkObjKeys(oldDeliveryAddress, "address")) {
            props.setDeliveryAddressSelected(oldDeliveryAddress || null);
            setDeliveryInfo(oldDeliveryAddress);
            // if (!props.route?.params?.oldDeliveryAddress?.noReload) {
            calculateShippingWithAddress(oldDeliveryAddress);
            // } else {
            //   setShippingInfo(
            //     props.route?.params?.oldDeliveryAddress?.shippingInfo,
            //   );
            //   props.route.params.oldDeliveryAddress.noReload = false;
            // }
          }
          if (!checkObjKeys(oldDeliveryAddress, "address")) {
            if (props?.deliveryAddressSelected?.address) {
              calculateShippingWithAddress(props?.deliveryAddressSelected);
              setDeliveryInfo(props?.deliveryAddressSelected);
            } else {
              let personalInfo = {};
              if (
                checkObjKeys(
                  { oldDeliveryAddress, isFromMarket, storeInfo },
                  "oldDeliveryAddress.isNotAddress",
                  true
                )
              ) {
                personalInfo = { error: "notDefaultAddress" };
                setDeliveryInfo(personalInfo);
                deliveryInfoTemp.current = personalInfo;
                if (redirectToUserAddress.current) {
                  handleNavigateSelectMethodDelivery();
                }
                redirectToUserAddress.current = true;
              } else {
                // ReactUtils.getStorage("utopInfo", (asynData) => {
                //   props.getDefaultAddressUserRequest({
                //     onSuccess: (respone) => {
                //       if (respone?.address) {
                //         personalInfo = {
                //           phoneNumber:
                //             respone.phoneNumber.replace("+84", "0") ||
                //             asynData.phone.replace("+84", "0"),
                //           address: respone?.address || "",
                //           userName: respone?.name || asynData?.name,
                //           deliveryOption: "",
                //           note: respone?.note,
                //         };
                //         calculateShippingWithAddress(personalInfo);
                //         setDeliveryInfo(personalInfo);
                //       } else {
                //         personalInfo = { error: "notDefaultAddress" };
                //         setDeliveryInfo(personalInfo);
                //         deliveryInfoTemp.current = personalInfo;
                //         if (redirectToUserAddress.current) {
                //           handleNavigateSelectMethodDelivery();
                //         }
                //         redirectToUserAddress.current = true;
                //       }
                //     },
                //     onFailed: () => {},
                //   });
                // });
                props.getDefaultAddressUserRequest({
                  onSuccess: (respone) => {
                    if (respone?.address) {
                      personalInfo = {
                        phoneNumber:
                          respone.phoneNumber.replace("+84", "0") || 0,
                        address: respone?.address || "",
                        userName: respone?.name || "",
                        deliveryOption: "",
                        note: respone?.note,
                      };
                      calculateShippingWithAddress(personalInfo);
                      setDeliveryInfo(personalInfo);
                    } else {
                      personalInfo = { error: "notDefaultAddress" };
                      setDeliveryInfo(personalInfo);
                      deliveryInfoTemp.current = personalInfo;
                      if (redirectToUserAddress.current) {
                        handleNavigateSelectMethodDelivery();
                      }
                      redirectToUserAddress.current = true;
                    }
                  },
                  onFailed: () => {},
                });
              }
            }
            // get default address user
          }
        } else {
          setDeliveryInfo({
            storeName: props?.dataStore?.storeName,
            arrayMethod: props?.listMethodDelivery,
          });
          setShippingInfo({});
        }
      }
    }
  };

  const calculateShippingWithAddress = (
    personalInfo,
    showMessage,
    onComplete
  ) => {
    let requestData = {};
    const products = [];

    if (personalInfo?.address !== "") {
      // eslint-disable-next-line array-callback-return
      props.listProduct.map((product) => {
        const { productCode, quantity, attributes, toppings } =
          product.dataInfoProduct;
        const tmpToppings = [];

        toppings.forEach((topping) => {
          const itemTopping = {
            code: topping.code,
            quantity: 1,
          };
          tmpToppings.push(itemTopping);
        });

        const item = {
          productCode,
          quantity,
          attributes,
          toppings: tmpToppings,
        };
        products.push(item);
      });

      requestData = {
        description: props?.description,
        merchantId: props?.dataStore?.merchantId,
        storeId: props?.dataStore?.storeId,
        products,
      };

      requestData.deliveryInfo = {
        phoneNumber: personalInfo?.phoneNumber || "",
        address: personalInfo?.address,
        userName: personalInfo?.userName || "",
        deliveryOption: "",
        note: "",
      };

      props.calculateShippingRequest({
        requestData,
        onSuccess: (respone) => {
          if (respone?.length > 0) {
            if (checkObjKeys(respone, "[0].value[0]")) {
              if (typeof onComplete === "function") {
                onComplete(respone);
              }
              props.getSuggestDiscountShippingRequest(
                {
                  storeId: props?.dataStore?.storeId,
                  merchantId: props?.dataStore?.merchantId,
                  code: checkObjKeys(respone, "[0].code", true),
                },
                (res) => {
                  setShippingInfo({
                    ...checkObjKeys(respone, "[0].value[0]", true),
                    code: checkObjKeys(respone, "[0].code", true),
                    shippingDiscountRange: shippingDiscount(
                      getTotalPrice(props.listProduct),
                      res
                    ),
                    listSuggestShippingDiscount: res,
                  });
                },
                () => {
                  setShippingInfo({
                    ...checkObjKeys(respone, "[0].value[0]", true),
                    code: checkObjKeys(respone, "[0].code", true),
                  });
                }
              );
            }
          } else {
            setShippingInfo({
              error:
                "Đơn hàng của bạn không thể giao do địa chỉ giao quá xa hoặc tổng tiền đơn hàng không thoả.",
            });
            if (showMessage && isShowMessageError.current) {
              isShowMessageError.current = false;
              setAlertAction({
                isShow: true,
                title: "Utop",
                content:
                  "Đơn hàng của bạn không thể giao do địa chỉ giao quá xa hoặc tổng tiền đơn hàng không thoả.",
                titleAccept: "Thay đổi",
                titleCancel: "Đóng",
                dataItem: {
                  key: "address",
                },
              });
              // Alert.alert(
              //   "Utop",
              //   strings("OrderAddressFood.labelErrorShipping"),
              //   [
              //     {
              //       text: strings("Cart.labelClose"),
              //       onPress: () => {
              //         isShowMessageError.current = true;
              //       },
              //       style: "cancel",
              //     },
              //     {
              //       text: strings("Cart.labelChange"),
              //       onPress: () => {
              //         isShowMessageError.current = true;
              //         handleNavigateSelectMethodDelivery();
              //       },
              //     },
              //   ],
              //   { cancelable: false }
              // );
            }
          }
        },
        onFailed: (error) => {
          if (typeof onComplete === "function") {
            onComplete("error");
          }
          let message = "";
          switch (error?.response?.data?.message) {
            case "NotFoundProduct":
              // eslint-disable-next-line no-case-declarations
              const productCodeErrors = error?.response?.data?.errors || [];
              if (productCodeErrors.length > 0) {
                const cartTemp = props?.listProduct;
                productCodeErrors.forEach((productCodeError) => {
                  cartTemp.forEach((item) => {
                    if (
                      item?.dataInfoProduct?.productCode === productCodeError
                    ) {
                      // eslint-disable-next-line no-param-reassign
                      item.isOutOfStock = true;
                      props.updateProductCartOutOfStock(item);
                      return;
                    }

                    if (checkObjKeys(item, "data.sizes.iv", true)?.length > 0) {
                      item?.data?.sizes?.iv.forEach((productCodeSize) => {
                        if (
                          productCodeSize === item?.dataInfoProduct?.productCode
                        ) {
                          // eslint-disable-next-line no-param-reassign
                          item.isOutOfStock = true;
                          props.updateProductCartOutOfStock(item);
                        }
                      });
                    }
                  });
                });
              }
              setShippingInfo({
                error:
                  "Đơn hàng đang có sản phẩm ngừng bán, vui lòng xoá khỏi giỏ hàng.",
              });
              if (showMessage && isShowMessageError.current) {
                isShowMessageError.current = false;
                setAlertAction({
                  isShow: true,
                  title: "Utop",
                  content: "Vài sản phẩm trong đơn hàng đã ngừng bán",
                  titleAccept: "OK",
                  dataItem: {
                    key: "basic",
                  },
                });
                // Alert.alert(
                //   "Utop",
                //   strings("ConfirmOrderFnB.someProductsOutofStock"),
                //   [
                //     {
                //       text: strings("ConfirmOrderFnB.textOk"),
                //       onPress: () => {
                //         isShowMessageError.current = true;
                //       },
                //       style: "cancel",
                //     },
                //   ],
                //   { cancelable: false }
                // );
              }

              break;

            case "UnsatisfyingMinOrder":
              message = `${`Cửa hàng chỉ giao với giá trị đơn hàng từ ${numberWithCommas(
                error?.response?.data?.errors?.minOrderValue
              )}đ`}.`;
              setShippingInfo({
                error: message,
              });

              if (showMessage && isShowMessageError.current) {
                isShowMessageError.current = false;
                setAlertAction({
                  isShow: true,
                  title: "Utop",
                  content: message,
                  titleAccept: "OK",
                  dataItem: {
                    key: "basic",
                  },
                });
                // Alert.alert(
                //   "Utop",
                //   message,
                //   [
                //     {
                //       text: strings("OrderAddressFood.labelConfirm"),
                //       onPress: () => {
                //         isShowMessageError.current = true;
                //       },
                //       style: "cancel",
                //     },
                //   ],
                //   {
                //     cancelable: false,
                //   }
                // );
              }
              break;

            case "UnsatisfyingDistance":
              if (error?.response?.data?.errors?.minOrderValue > 0) {
                message = `${
                  `Cửa hàng chỉ giao trong ${numberWithCommas(
                    error?.response?.data?.errors?.maxDistance
                  )}km` +
                  ` với giá trị đơn hàng từ ${numberWithCommas(
                    error?.response?.data?.errors?.minOrderValue
                  )}đ`
                }.`;
              } else {
                message = `${`Cửa hàng chỉ giao trong ${numberWithCommas(
                  error?.response?.data?.errors?.maxDistance
                )}km`}.`;
              }

              setShippingInfo({
                error: message,
              });

              if (showMessage && isShowMessageError.current) {
                isShowMessageError.current = false;
                setAlertAction({
                  isShow: true,
                  title: "Utop",
                  content: message,
                  titleAccept: "OK",
                  dataItem: {
                    key: "basic",
                  },
                });
                // Alert.alert(
                //   "Utop",
                //   message,
                //   [
                //     {
                //       text: strings("OrderAddressFood.labelConfirm"),
                //       onPress: () => {
                //         isShowMessageError.current = true;
                //       },
                //       style: "cancel",
                //     },
                //   ],
                //   {
                //     cancelable: false,
                //   }
                // );
              }
              break;
            case "ExceedWeightLimitation":
              // eslint-disable-next-line no-case-declarations
              let maxWeight = (
                error?.response?.data?.errors?.maxWeight / 1000
              ).toFixed(2);
              try {
                const maxWeightTemp = maxWeight.split(".");
                if (maxWeightTemp?.[1] === "00") {
                  maxWeight = parseInt(maxWeight, 10);
                }
                // eslint-disable-next-line no-empty
              } catch (error) {}
              maxWeight = numberWithCommas(maxWeight);
              message =
                `Chỉ áp dụng cho đơn hàng có khối lượng từ ${maxWeight}kg` +
                ` trở xuống.`;

              setShippingInfo({
                error: message,
              });

              if (showMessage && isShowMessageError.current) {
                isShowMessageError.current = false;
                setAlertAction({
                  isShow: true,
                  title: "Utop",
                  content: message,
                  titleAccept: "OK",
                  dataItem: {
                    key: "basic",
                  },
                });
                // Alert.alert(
                //   "Utop",
                //   message,
                //   [
                //     {
                //       text: strings("OrderAddressFood.labelConfirm"),
                //       onPress: () => {
                //         isShowMessageError.current = true;
                //       },
                //       style: "cancel",
                //     },
                //   ],
                //   {
                //     cancelable: false,
                //   }
                // );
              }
              break;

            case "AhaMovePartnerUnallowed":
              message =
                "Đơn hàng của bạn không thể giao do không thỏa điều kiện giao hàng.";
              setShippingInfo({
                error: message,
              });
              if (showMessage && isShowMessageError.current) {
                isShowMessageError.current = false;
                setAlertAction({
                  isShow: true,
                  title: "Utop",
                  content: message,
                  titleAccept: "OK",
                  dataItem: {
                    key: "basic",
                  },
                });
                // Alert.alert(
                //   "Utop",
                //   message,
                //   [
                //     {
                //       text: strings("OrderAddressFood.labelConfirm"),
                //       onPress: () => {
                //         isShowMessageError.current = true;
                //       },
                //       style: "cancel",
                //     },
                //   ],
                //   {
                //     cancelable: false,
                //   }
                // );
              }
              break;

            case "ShippingConfigIsNotFound":
              message = "Cửa hàng chưa cài đặt phí vận chuyển.";
              setShippingInfo({
                error: message,
              });
              if (showMessage && isShowMessageError.current) {
                isShowMessageError.current = false;
                setAlertAction({
                  isShow: true,
                  title: "Utop",
                  content: message,
                  titleAccept: "OK",
                  dataItem: {
                    key: "basic",
                  },
                });
                // Alert.alert(
                //   "Utop",
                //   message,
                //   [
                //     {
                //       text: strings("OrderAddressFood.labelConfirm"),
                //       onPress: () => {
                //         isShowMessageError.current = true;
                //       },
                //       style: "cancel",
                //     },
                //   ],
                //   {
                //     cancelable: false,
                //   }
                // );
              }

              break;

            case "GettingDistanceFail":
              message =
                "Hệ thống chưa xác định được khoảng cách giao hàng, vui lòng thử lại sau.";
              setShippingInfo({
                error: message,
              });
              if (showMessage && isShowMessageError.current) {
                isShowMessageError.current = false;
                setAlertAction({
                  isShow: true,
                  title: "Utop",
                  content: message,
                  titleAccept: "OK",
                  dataItem: {
                    key: "basic",
                  },
                });
                // Alert.alert(
                //   "Utop",
                //   message,
                //   [
                //     {
                //       text: strings("OrderAddressFood.labelConfirm"),
                //       onPress: () => {
                //         isShowMessageError.current = true;
                //       },
                //       style: "cancel",
                //     },
                //   ],
                //   {
                //     cancelable: false,
                //   }
                // );
              }
              break;

            case "StoreIsNotFound":
              message =
                "Cửa hàng đang tạm ngưng hoạt động, vui lòng thử lại sau.";
              setShippingInfo({
                error: message,
              });
              if (showMessage && isShowMessageError.current) {
                isShowMessageError.current = false;
                setAlertAction({
                  isShow: true,
                  title: "Utop",
                  content: message,
                  titleAccept: "OK",
                  dataItem: {
                    key: "basic",
                  },
                });
                // Alert.alert(
                //   "Utop",
                //   message,
                //   [
                //     {
                //       text: strings("OrderAddressFood.labelConfirm"),
                //       onPress: () => {
                //         isShowMessageError.current = true;
                //       },
                //       style: "cancel",
                //     },
                //   ],
                //   {
                //     cancelable: false,
                //   }
                // );
              }
              break;
            case "InvalidUserAddress":
              message = "Không xác định được địa chỉ giao hàng.";
              setShippingInfo({
                error: message,
              });
              if (showMessage && isShowMessageError.current) {
                isShowMessageError.current = false;
                setAlertAction({
                  isShow: true,
                  title: "Utop",
                  content: message,
                  titleAccept: "OK",
                  dataItem: {
                    key: "basic",
                  },
                });
                // Alert.alert(
                //   "Utop",
                //   message,
                //   [
                //     {
                //       text: strings("OrderAddressFood.labelConfirm"),
                //       onPress: () => {
                //         isShowMessageError.current = true;
                //       },
                //       style: "cancel",
                //     },
                //   ],
                //   {
                //     cancelable: false,
                //   }
                // );
              }
              break;
            case "InvalidStoreAddress":
              message = "Không xác định được địa chỉ cửa hàng.";
              setShippingInfo({
                error: message,
              });
              if (showMessage && isShowMessageError.current) {
                isShowMessageError.current = false;
                setAlertAction({
                  isShow: true,
                  title: "Utop",
                  content: message,
                  titleAccept: "OK",
                  dataItem: {
                    key: "basic",
                  },
                });
                // Alert.alert(
                //   "Utop",
                //   message,
                //   [
                //     {
                //       text: strings("OrderAddressFood.labelConfirm"),
                //       onPress: () => {
                //         isShowMessageError.current = true;
                //       },
                //       style: "cancel",
                //     },
                //   ],
                //   {
                //     cancelable: false,
                //   }
                // );
              }
              break;

            default:
              setShippingInfo({
                error:
                  "Đơn hàng của bạn không thể giao do địa chỉ giao quá xa hoặc tổng tiền đơn hàng không thoả.",
              });
              if (showMessage && isShowMessageError.current) {
                isShowMessageError.current = false;
                setAlertAction({
                  isShow: true,
                  title: "Utop",
                  content:
                    "Địa chỉ không hợp lệ. Bạn có muốn chọn địa chỉ khác?",
                  titleAccept: "Đồng ý",
                  titleCancel: "Đóng",
                  dataItem: {
                    key: "address",
                  },
                });
                // Alert.alert(
                //   "Utop",
                //   strings("Cart.labelInvalidAddress"),
                //   [
                //     {
                //       text: strings("Cart.labelNo"),
                //       onPress: () => {
                //         isShowMessageError.current = true;
                //       },
                //       style: "cancel",
                //     },
                //     {
                //       text: strings("Cart.labelYes"),
                //       onPress: () => {
                //         isShowMessageError.current = true;
                //         handleNavigateSelectMethodDelivery();
                //       },
                //     },
                //   ],
                //   {
                //     cancelable: false,
                //   }
                // );
              }
              break;
          }
        },
      });
    }
  };

  const clearCoupon = () => {
    setDiscount(0);
    props.setVoucher(null);
    props.setShowMessageError(true);
    props.clearAllowcateCoupon();
    setMessageError("");
    setIsErrorCoupon(false);
  };

  const saveMessageError = (error) => {
    setIsErrorCoupon(true);
    setMessageError(error.message);
    setDiscount(0);

    // if (typeof notShowMessage === 'undefined' || !notShowMessage) {
    showMessageErrorCoupon(error.message);
    // }
  };

  const showMessageErrorCoupon = (message) => {
    getIsShowMessageErrorCoupon((isShowMessage) => {
      if (!isShowMessage) return;
      if (message === "OverMaxSet") {
        props?.setVoucher(null);
        props.setShowMessageError(false);
        setIsErrorCoupon(false);
      }
      const errorMessage = couponErrorMessage(message);
      setAlertAction({
        isShow: true,
        title: "Utop",
        content: errorMessage,
        titleAccept: "OK",
        dataItem: {
          key: "basic",
        },
      });
      // Alert.alert(
      //   "Utop",
      //   errorMessage,
      //   [
      //     {
      //       text: strings("Cart.labelConfirm"),
      //       onPress: () => {
      //         props.setShowMessageError(false);
      //       },
      //     },
      //   ],
      //   { cancelable: false }
      // );
    });
  };

  const checkErrorCodeForFood = (message) => {
    if (typeof message === "string") {
      console.log("message", message);
      switch (message) {
        case "InvalidTypeOfCoupon":
          setAlertAction({
            isShow: true,
            title: "Utop",
            content:
              "Mã ưu đãi không hợp lệ, vui lòng kiểm tra lại hoặc thử mã khác.",
            titleAccept: "Xác nhận",
            // titleCancel: "Đóng",
            dataItem: {
              key: "coupon",
            },
          });
          //   Alert.alert(
          //     "Utop",
          //     strings("Cart.labelErrorInvalidTypeOfCoupon"),
          //     [
          //       {
          //         text: strings("Cart.labelConfirm"),
          //         onPress: () => {
          //           // clear discount & coupon
          //           clearCoupon();
          //         },
          //       },
          //     ],
          //     { cancelable: false }
          //   );
          break;

        case "NotFoundCoupon":
          setAlertAction({
            isShow: true,
            title: "Utop",
            content:
              "Mã ưu đãi không hợp lệ, vui lòng kiểm tra lại hoặc thử mã khác.",
            titleAccept: "Xác nhận",
            // titleCancel: "Không",
            dataItem: {
              key: "coupon",
            },
          });
          //   Alert.alert(
          //     "Utop",
          //     strings("StoreDetail.errorNotFoundCouponCampaign"),
          //     [
          //       {
          //         text: strings("Cart.labelConfirm"),
          //         onPress: () => {
          //           // clear discount & coupon
          //           clearCoupon();
          //         },
          //       },
          //     ],
          //     { cancelable: false }
          //   );
          break;

        case "CouponIsUsed":
          setAlertAction({
            isShow: true,
            title: "Utop",
            content: "Mã Coupon đã được sử dụng. Vui lòng chọn mã ưu đãi khác.",
            titleAccept: "Xác nhận",
            titleCancel: "Không",
            dataItem: {
              key: "couponNavigation",
            },
          });
          //   Alert.alert(
          //     "Utop",
          //     strings("Cart.labelErrorCouponIsUsed"),
          //     [
          //       {
          //         text: strings("Cart.labelNo"),
          //         onPress: () => {},
          //         style: "cancel",
          //       },
          //       {
          //         text: strings("Cart.labelConfirm"),
          //         onPress: () => {
          //           // clear discount & coupon
          //           clearCoupon();
          //           NavigationServices.navigate("CouponForVoucher", {
          //             isFlow: "FnB",
          //           });
          //         },
          //       },
          //     ],
          //     { cancelable: false }
          //   );
          break;

        case "CouponExpired":
          setAlertAction({
            isShow: true,
            title: "Utop",
            content: "Mã Coupon đã hết hạn. Vui lòng chọn mã ưu đãi khác.",
            titleAccept: "Xác nhận",
            titleCancel: "Không",
            dataItem: {
              key: "couponNavigation",
            },
          });
          //   Alert.alert(
          //     "Utop",
          //     strings("Cart.labelErrorCouponExpired"),
          //     [
          //       {
          //         text: strings("Cart.labelNo"),
          //         onPress: () => {},
          //         style: "cancel",
          //       },
          //       {
          //         text: strings("Cart.labelYes"),
          //         onPress: () => {
          //           // clear discount & coupon
          //           clearCoupon();
          //           NavigationServices.navigate("CouponForVoucher", {
          //             isFlow: "FnB",
          //           });
          //         },
          //       },
          //     ],
          //     { cancelable: false }
          //   );
          break;

        case "LessThanMinValueRequired":
          setAlertAction({
            isShow: true,
            title: "Utop",
            content:
              "Giá trị đơn hàng không đủ để sử dụng mã ưu đãi. Vui lòng kiểm tra lại đơn hàng hoặc chọn mã ưu đãi khác.",
            titleAccept: "Có",
            titleCancel: "Không",
            dataItem: {
              key: "couponNavigation",
            },
          });
          //   Alert.alert(
          //     "Utop",
          //     strings("Cart.labelErrorLessThanMinValueRequired"),
          //     [
          //       {
          //         text: strings("Cart.labelNo"),
          //         onPress: () => {},
          //         style: "cancel",
          //       },
          //       {
          //         text: strings("Cart.labelYes"),
          //         onPress: () => {
          //           NavigationServices.navigate("CouponForVoucher", {
          //             isFlow: "FnB",
          //           });
          //         },
          //       },
          //     ],
          //     { cancelable: false }
          //   );
          break;

        case "OverMaxSet":
          setAlertAction({
            isShow: true,
            title: "Utop",
            content:
              "Số lượng mã ưu đãi của chương trình đã hết. Vui lòng chọn mã ưu đãi khác.",
            titleAccept: "Có",
            titleCancel: "Không",
            dataItem: {
              key: "couponNavigation",
            },
          });
          //   Alert.alert(
          //     "Utop",
          //     strings("Cart.labelErrorOverMaxSet"),
          //     [
          //       {
          //         text: strings("Cart.labelNo"),
          //         onPress: () => {},
          //         style: "cancel",
          //       },
          //       {
          //         text: strings("Cart.labelYes"),
          //         onPress: () => {
          //           // clear discount & coupon
          //           clearCoupon();

          //           NavigationServices.navigate("CouponForVoucher", {
          //             isFlow: "FnB",
          //           });
          //         },
          //       },
          //     ],
          //     { cancelable: false }
          //   );
          break;

        case "OverMaxSetPerUser":
          setAlertAction({
            isShow: true,
            title: "Utop",
            content: "Bạn đã hết lượt nhận mã ưu đãi.",
            titleAccept: "Có",
            titleCancel: "Không",
            dataItem: {
              key: "couponNavigation",
            },
          });
          //   Alert.alert(
          //     "Utop",
          //     strings("Cart.labelErrorOverMaxSetPerUser"),
          //     [
          //       {
          //         text: strings("Cart.labelNo"),
          //         onPress: () => {},
          //         style: "cancel",
          //       },
          //       {
          //         text: strings("Cart.labelYes"),
          //         onPress: () => {
          //           // clear discount & coupon
          //           clearCoupon();
          //           NavigationServices.navigate("CouponForVoucher", {
          //             isFlow: "FnB",
          //           });
          //         },
          //       },
          //     ],
          //     { cancelable: false }
          //   );
          break;

        case "InvalidPaymentMethod":
          setAlertAction({
            isShow: true,
            title: "Utop",
            content:
              "Mã ưu đãi đang sử dụng không áp dụng cho phương thức thanh toán bạn chọn. Vui lòng kiểm tra lại đơn hàng hoặc chọn mã ưu đãi khác.",
            titleAccept: "Có",
            titleCancel: "Không",
            dataItem: {
              key: "couponNavigation",
            },
          });
          //   Alert.alert(
          //     "Utop",
          //     strings("Cart.labelErrorInvalidPaymentMethod"),
          //     [
          //       {
          //         text: strings("Cart.labelNo"),
          //         onPress: () => {},
          //         style: "cancel",
          //       },
          //       {
          //         text: strings("Cart.labelYes"),
          //         onPress: () => {
          //           NavigationServices.navigate("CouponForVoucher", {
          //             isFlow: "FnB",
          //           });
          //         },
          //       },
          //     ],
          //     { cancelable: false }
          //   );
          break;

        case "NotFoundCouponCampaign":
          setAlertAction({
            isShow: true,
            title: "Utop",
            content:
              "Mã ưu đãi không hợp lệ, vui lòng kiểm tra lại hoặc thử mã khác.",
            titleAccept: "Có",
            titleCancel: "Không",
            dataItem: {
              key: "coupon",
            },
          });
          //   Alert.alert(
          //     "Utop",
          //     strings("Cart.labelErrorInvalidTypeOfCoupon"),
          //     [
          //       {
          //         text: strings("Cart.labelConfirm"),
          //         onPress: () => {
          //           // clear discount & coupon
          //           clearCoupon();
          //         },
          //       },
          //     ],
          //     { cancelable: false }
          //   );
          break;

        case "InvalidStoreOfCoupon":
          setAlertAction({
            isShow: true,
            title: "Utop",
            content:
              "Mã ưu đãi không áp dụng cho cửa hàng này. Vui lòng chọn mã ưu đãi khác.",
            titleAccept: "Có",
            titleCancel: "Không",
            dataItem: {
              key: "couponNavigation",
            },
          });
          //   Alert.alert(
          //     "Utop",
          //     strings("Cart.labelErrorInvalidStoreOfCoupon"),
          //     [
          //       {
          //         text: strings("Cart.labelNo"),
          //         onPress: () => {},
          //         style: "cancel",
          //       },
          //       {
          //         text: strings("Cart.labelYes"),
          //         onPress: () => {
          //           // clear discount & coupon
          //           clearCoupon();

          //           NavigationServices.navigate("CouponForVoucher", {
          //             isFlow: "FnB",
          //           });
          //         },
          //       },
          //     ],
          //     { cancelable: false }
          //   );
          break;

        case "InvalidOrderType":
          setAlertAction({
            isShow: true,
            title: "Utop",
            content:
              "Loại sản phẩm áp dụng không phù hợp. Vui lòng kiểm tra lại đơn hàng hoặc chọn mã ưu đãi khác.",
            titleAccept: "Có",
            titleCancel: "Không",
            dataItem: {
              key: "couponNavigation",
            },
          });
          //   Alert.alert(
          //     "Utop",
          //     strings("Cart.labelErrorInvalidOrderType"),
          //     [
          //       {
          //         text: strings("Cart.labelNo"),
          //         onPress: () => {},
          //         style: "cancel",
          //       },
          //       {
          //         text: strings("Cart.labelYes"),
          //         onPress: () => {
          //           // clear discount & coupon
          //           clearCoupon();

          //           NavigationServices.navigate("CouponForVoucher", {
          //             isFlow: "FnB",
          //           });
          //         },
          //       },
          //     ],
          //     { cancelable: false }
          //   );
          break;

        case "NotFoundProduct":
          setAlertAction({
            isShow: true,
            title: "Utop",
            content:
              "Đơn hàng đang có sản phẩm ngừng bán, vui lòng xoá khỏi giỏ hàng.",
            titleAccept: "Xác nhận",
            // titleCancel: "Xác nhận",
            dataItem: {
              key: "coupon",
            },
          });
          //   Alert.alert(
          //     "Utop",
          //     strings("Cart.labelErrorNotFoundProduct"),
          //     [
          //       {
          //         text: strings("Cart.labelConfirm"),
          //         onPress: () => {},
          //       },
          //     ],
          //     { cancelable: false }
          //   );
          break;

        default:
          setAlertAction({
            isShow: true,
            title: "Utop",
            content: message,
            titleAccept: "OK",
            // titleCancel: "Xác nhận",
            dataItem: {
              key: "basic",
            },
          });
          //   Alert.alert(
          //     "Utop",
          //     message,
          //     [
          //       {
          //         text: strings("Cart.labelConfirm"),
          //         onPress: () => {
          //           // clear discount & coupon
          //           clearCoupon();
          //         },
          //         style: "cancel",
          //       },
          //     ],
          //     { cancelable: false }
          //   );
          break;
      }
    }
  };

  const handleCouponDiscount = (voucherItem) => {
    props.setVoucher(voucherItem);
    checkCouponDiscount(
      props.listProduct,
      {
        description: "",
        storeId: props.dataStore.storeId,
        merchantId: props.dataStore.merchantId,
        voucherCode: getCodeVoucher(voucherItem),
        paymentMethodName: "Điểm Utop",
        methodDelivery: getMethodDelivery(props.listMethodDelivery)?.code || "",
        deliveryMethodName:
          getMethodDelivery(props.listMethodDelivery)?.name || "",
      },
      (respone) => {
        setDiscount(respone.discountAmount);
        setIsErrorCoupon(false);
      },
      (error) => {
        if (error?.response?.data?.code === "NETWORK_REQUEST_FAILED") {
          clearCoupon();
        } else if (error?.response?.data?.message === "NotFoundProduct") {
          setIsErrorCoupon(true);
          const productCodeErrors = error?.response?.data?.errors || [];
          let masterMessage = "";
          if (productCodeErrors.length > 0) {
            const cartTemp = props?.listProduct;
            productCodeErrors.forEach((productCodeError) => {
              cartTemp.forEach((item) => {
                if (item?.dataInfoProduct?.productCode === productCodeError) {
                  // eslint-disable-next-line no-param-reassign
                  item.isOutOfStock = true;
                  props.updateProductCartOutOfStock(item);
                  return;
                }

                if (checkObjKeys(item, "data.sizes.iv", true)?.length > 0) {
                  item?.data?.sizes?.iv.forEach((productCodeSize) => {
                    if (
                      productCodeSize === item?.dataInfoProduct?.productCode
                    ) {
                      // eslint-disable-next-line no-param-reassign
                      item.isOutOfStock = true;
                      props.updateProductCartOutOfStock(item);
                    }
                  });
                }
              });
            });
          }
          masterMessage = "Vài sản phẩm trong đơn hàng đã ngừng bán";
          setMessageError("NotFoundProduct");
          if (isShowMessageError.current && showMsgOutOfStock.current) {
            isShowMsgOutOfStock.current = true;
            isShowMessageError.current = false;
            // show message
            setAlertAction({
              isShow: true,
              title: "Utop",
              content: masterMessage,
              titleAccept: "OK",
              // titleCancel: "Xác nhận",
              dataItem: {
                key: "basic",
              },
            });
            // Alert.alert(
            //   "Utop",
            //   masterMessage,
            //   [
            //     {
            //       text: strings("ConfirmOrderFnB.textOk"),
            //       onPress: () => {
            //         isShowMessageError.current = true;
            //       },
            //       style: "cancel",
            //     },
            //   ],
            //   { cancelable: false }
            // );
          }
        } else {
          if (
            error?.response?.data?.message ===
            `Exception of type 'Utop.ShopClient.Exceptions.FriendlyException' was thrown.`
          ) {
            return;
          }
          saveMessageError(error?.response?.data);
        }

        // eslint-disable-next-line no-unused-expressions
        JSON.stringify(props.voucher) !== JSON.stringify(voucherItem) ||
        error?.response?.data?.message === "NotFoundProduct"
          ? props.setShowMessageError(true)
          : props.setShowMessageError(false);
      }
    );
  };

  const selectMethodDelivery = (item) => {
    props.setDeliveryAddress(
      props.listMethodDelivery.map((ele) => ({
        ...ele,
        isCheck: ele.code === item.code,
      }))
    );
    setVisibleMethodDelivery(false);
  };

  const goToVoucher = () => {
    if (isErrorCoupon) {
      checkErrorCodeForFood(messageError);
    } else if (getNameVoucher(props.voucher) && discount > 0) {
      setAlertAction({
        isShow: true,
        title: "Utop",
        content: "Bạn có muốn chọn mã ưu đãi khác?",
        titleAccept: "Có",
        titleCancel: "Xác nhận",
        dataItem: {
          key: "couponNavigation",
        },
      });
      //   Alert.alert(
      //     "Utop",
      //     strings("Cart.labelSelectOtherCoupon"),
      //     [
      //       {
      //         text: strings("Cart.labelNo"),
      //         onPress: () => {},
      //         style: "cancel",
      //       },
      //       {
      //         text: strings("Cart.labelYes"),
      //         onPress: () => {
      //           //   ReactUtils.trackingEventClicks("FnB", "AddVoucherClicks");
      //           props.navigation.navigate("CouponForVoucher", { isFlow: "FnB" });
      //         },
      //       },
      //     ],
      //     { cancelable: false }
      //   );
    } else {
      //   ReactUtils.trackingEventClicks("FnB", "AddVoucherClicks");
      NavigationServices.navigate("/promotion", { isFlow: "FnB" });
    }
  };

  const checkAllowcationCoupon = (item) => {
    // eslint-disable-next-line no-unused-expressions
    JSON.stringify(props.voucher) !== JSON.stringify(item)
      ? props.setShowMessageError(true)
      : props.setShowMessageError(false);
    props.setVoucher(item);
    if (!item?.isAllocated) {
      props.getAvailableCouponHandle(
        item?.code,
        (res) => {
          // eslint-disable-next-line no-unused-expressions
          props.allowcationCouponSuccess({ ...item, ...res.data.coupons[0] });
          setIsErrorCoupon(false);
        },
        (err) => {
          saveMessageError(err?.response?.data);
        }
      );
    } else {
      props.getCouponDetailRequest(
        { item },
        () => {},
        () => {
          setAlertAction({
            isShow: true,
            title: "Utop",
            content: "Bạn đã hết lượt sử dụng coupon.",
            titleAccept: "OK",
            // titleCancel: "Xác nhận",
            dataItem: {
              key: "basic",
            },
          });
          props.setVoucher(null);
          props.setShowMessageError(true);
          setIsErrorCoupon(false);
          setDiscount(0);
        }
      );
    }
  };
  const checkCouponDiscount = (
    dataRequest,
    dataOrderItem,
    onFlowComplete,
    onFlowFailed
  ) => {
    const products = [];

    // eslint-disable-next-line array-callback-return
    dataRequest.map((product) => {
      const { productCode, quantity, attributes, toppings } =
        product.dataInfoProduct;
      const tmpToppings = [];

      toppings.forEach((topping) => {
        const itemTopping = {
          code: topping.code,
          quantity: 1,
        };
        tmpToppings.push(itemTopping);
      });

      const item = {
        productCode,
        quantity,
        attributes,
        toppings: tmpToppings,
      };
      products.push(item);
    });

    let requestData = {};

    requestData = {
      description: dataOrderItem.description,
      merchantId: dataOrderItem.merchantId,
      storeId: dataOrderItem.storeId,
      products,
      coupons: [dataOrderItem.voucherCode],
    };

    // eslint-disable-next-line no-undef
    if (dataOrderItem.methodDelivery === methodDelivery.home) {
      let noteAddress = "";
      if (dataOrderItem?.deliveryInfo?.noteAddress !== "") {
        noteAddress = "";
      }
      const deliveryInfoTemp = {
        phoneNumber: "",
        address: "",
        userName: "",
        deliveryOption: "",
        note: noteAddress,
      };
      requestData.deliveryInfo = deliveryInfoTemp;
    }

    props.calCouponDiscountRequest({
      requestData,
      onSuccess: (respone) => {
        if (respone.status === 200) {
          onFlowComplete(respone.data);
        }
      },
      onFailed: (error) => {
        onFlowFailed(error);
      },
    });
  };

  const setModalItem = (isShow) => {
    setVisibleAddProduct(isShow);
  };

  const handleConfirmOrder = (
    willUseLoyaltyPoint,
    loyaltyPointToUse,
    pointToUse
  ) => {
    switch (true) {
      case getMethodDelivery(props.listMethodDelivery)?.code === "home" &&
        Object.keys(shippingInfo)?.length === 0:
        setAlertAction({
          isShow: true,
          title: "Utop",
          content: "Vui lòng chọn địa chỉ.",
          titleAccept: "Xác nhận",
          dataItem: {
            key: "address",
          },
        });
        // Alert.alert(
        //   "Utop",
        //   strings("OrderAddressFood.labelPleaseSelectAddress"),
        //   [
        //     {
        //       text: strings("OrderAddressFood.labelPaymentContinue"),
        //       onPress: () => {
        //         handleNavigateSelectMethodDelivery();
        //       },
        //       style: "cancel",
        //     },
        //   ],
        //   { cancelable: false }
        // );
        break;

      case getMethodDelivery(props.listMethodDelivery)?.code === "home" &&
        shippingInfo?.error === "InvalidAddress":
        setAlertAction({
          isShow: true,
          title: "Utop",
          content: "Địa chỉ không hợp lệ. Bạn có muốn chọn địa chỉ khác?'",
          titleCancel: "Không",
          titleAccept: "Có",
          dataItem: {
            key: "address",
          },
        });
        // Alert.alert(
        //   "Utop",
        //   strings("Cart.labelInvalidAddress"),
        //   [
        //     {
        //       text: strings("Cart.labelNo"),
        //       onPress: () => {},
        //       style: "cancel",
        //     },
        //     {
        //       text: strings("Cart.labelYes"),
        //       onPress: () => {
        //         handleNavigateSelectMethodDelivery();
        //       },
        //     },
        //   ],
        //   { cancelable: false }
        // );
        break;

      case getMethodDelivery(props.listMethodDelivery)?.code ===
        // eslint-disable-next-line no-undef
        methodDelivery.shop &&
        getMethodDelivery(props.listMethodDelivery)?.isCheck === true &&
        methodDeliveryMaster?.code === "table" &&
        props.valueNumberTable.trim() === "":
        setAlertAction({
          isShow: true,
          title: "Utop",
          content: "Vui lòng nhập số bàn.",
          titleAccept: "Xác nhận",
          dataItem: {
            key: "table",
          },
        });
        // Alert.alert(
        //   "Utop",
        //   strings("Cart.labelEmptyNumberTable"),
        //   [
        //     {
        //       text: strings("Cart.labelConfirm"),
        //       onPress: () => {
        //         scrollViewRef.current?.scrollToEnd({ animated: true });
        //         setVisibleMethodDeliveryTable(true);
        //       },
        //       style: "cancel",
        //     },
        //   ],
        //   { cancelable: false }
        // );
        break;

      case JSON.stringify(getMethodDelivery(props.listMethodDelivery)) === "{}":
        setAlertAction({
          isShow: true,
          title: "Utop",
          content: "Vui lòng chọn phương thức giao hàng.",
          titleAccept: "Xác nhận",
          dataItem: {
            key: "address",
          },
        });
        // Alert.alert(
        //   "Utop",
        //   strings("Cart.labelEmptyMethodDelivery"),
        //   [
        //     {
        //       text: strings("Cart.labelConfirm"),
        //       onPress: () => {
        //         scrollViewRef.current?.scrollToEnd({ animated: true });
        //         setVisibleMethodDelivery(true);
        //       },
        //       style: "cancel",
        //     },
        //   ],
        //   { cancelable: false }
        // );
        break;

      case props.listProduct.length === 0:
        setAlertAction({
          isShow: true,
          title: "Utop",
          content: "Giỏ hàng không có sản phẩm.",
          titleCancel: "Xác nhận",
          dataItem: {},
        });
        // Alert.alert(
        //   "Utop",
        //   strings("Cart.labelEmptyCart"),
        //   [
        //     {
        //       text: strings("Cart.labelConfirm"),
        //       onPress: () => {},
        //       style: "cancel",
        //     },
        //   ],
        //   { cancelable: false }
        // );
        break;

      case getCodeVoucher(props.voucher) && isErrorCoupon:
        setAlertAction({
          isShow: true,
          title: "Utop",
          content:
            "Mã ưu đãi không hợp lệ! Bạn có muốn tiếp tục đơn hàng này không?",
          titleCancel: "Không",
          titleAccept: "Có",
          dataItem: {
            key: "ordered",
            data: {
              willUseLoyaltyPoint,
              loyaltyPointToUse,
              pointToUse,
            },
          },
        });
        // Alert.alert(
        //   "Utop",
        //   strings("Cart.labelInvalidCoupon"),
        //   [
        //     {
        //       text: strings("Cart.labelYes"),
        //       onPress: () => {
        //         createOrder();
        //       },
        //       style: "cancel",
        //     },
        //     {
        //       text: strings("Cart.labelNo"),
        //       onPress: () => {},
        //       style: "cancel",
        //     },
        //   ],
        //   { cancelable: false }
        // );
        break;

      default:
        createOrder(willUseLoyaltyPoint, loyaltyPointToUse, pointToUse);
        break;
    }
  };

  const handleNavigateSelectMethodDelivery = () => {
    const infoOrder = {
      deliveryMethodName:
        getMethodDelivery(props.listMethodDelivery)?.name || "",
      description: props.description,
      methodDelivery: getMethodDelivery(props.listMethodDelivery)?.code || "",
      totalItem: getTotalItem(props.listProduct),
      totalPrice: getTotalPrice(props.listProduct),
      storeId: props?.dataStore?.storeId,
      merchantId,
      isTakeaway: props.takeAway,
      dataTimer,
      valueNumberTable: props.valueNumberTable.trim().toUpperCase(),
    };
    let isPickup = false;
    let isFreeText = false;

    const dataCreateOrderTemp = {};
    dataCreateOrderTemp.dataRequest = props.listProduct;
    dataCreateOrderTemp.dataOrderItem = infoOrder;
    if (deliveryAddressOptions?.length > 0) {
      deliveryAddressOptions.forEach((element) => {
        switch (element) {
          case "Pickup":
            isPickup = true;
            break;
          case "FreeText":
            isFreeText = true;
            break;

          default:
            break;
        }
      });
    }

    dataCreateOrderTemp.dataOrderItem.isPickup = isPickup;
    dataCreateOrderTemp.dataOrderItem.isFreeText = isFreeText;
    NavigationServices.push("/OrderAddressFood", {
      dataOrder: dataCreateOrderTemp,
      deliveryInfo: deliveryInfoTemp.current,
    });
  };

  const createOrder = (willUseLoyaltyPoint, loyaltyPointToUse, pointToUse) => {
    const jsonDelivery = getMethodDelivery(props.listMethodDelivery);
    if (jsonDelivery?.code === "home") {
      calculateShippingWithAddress(deliveryInfo, true, (data) => {
        if (data === "error") {
          return;
        }
        // shipping current diff shipping previous
        if (
          shippingInfo?.shippingFee !==
          checkObjKeys(data, "[0].value[0].shippingFee", true)
        ) {
          //   ReactUtils.alertMsg("basic", strings("Cart.labelOrderChange"));
          return;
        }
        createOrderFnb(willUseLoyaltyPoint, loyaltyPointToUse, pointToUse);
      });
    } else {
      createOrderFnb(willUseLoyaltyPoint, loyaltyPointToUse, pointToUse);
    }
  };

  const createOrderFnb = (
    willUseLoyaltyPoint,
    loyaltyPointToUse,
    pointToUse
  ) => {
    const products = [];
    props?.listProduct.forEach((itemProduct) => {
      let attributes = [];
      const toppings = [];
      let quantity = 0;
      const productCode = itemProduct?.dataInfoProduct?.productCode;
      const productId = itemProduct?.dataInfoProduct?.productId;
      attributes = itemProduct?.dataInfoProduct?.attributes;
      quantity = itemProduct?.dataInfoProduct?.quantity;

      // eslint-disable-next-line no-unused-expressions
      itemProduct?.dataInfoProduct?.toppings?.length > 0 &&
        itemProduct?.dataInfoProduct?.toppings.forEach((itemTopping) => {
          const itemToppingTmp = {
            code: itemTopping?.code,
            id: itemTopping?.id,
            quantity: 1,
            price: itemTopping?.price,
            name: itemTopping?.name,
          };
          toppings.push(itemToppingTmp);
        });
      const item = {
        productCode,
        productId,
        quantity,
        attributes,
        toppings,
      };
      products.push(item);
    });
    let requestData = {};

    const dataDescription =
      (props.takeAway ? "[Mua mang về] " : "") +
      (props?.valueNumberTable !== ""
        ? `[${"Giao tại bàn: "}${props?.valueNumberTable}] `
        : "") +
      (props?.description !== "" ? props?.description : "");
    requestData = {
      description: dataDescription,
      dataTimer,
      merchantId,
      storeId: props?.dataStore?.storeId,
      products,
      deliveryInfo: {
        phoneNumber: deliveryInfo?.phoneNumber || "",
        address: deliveryInfo?.address || "",
        userName: deliveryInfo?.userName || "",
        deliveryOption: getMethodDelivery(props.listMethodDelivery)?.code || "",
        note: deliveryInfo?.note || "",
        shippingInfo: {
          code: shippingInfo?.code || "",
          shippingCode: shippingInfo?.shippingCode || "",
        },
      },
    };

    if (discount > 0) {
      requestData.coupons = [getCodeVoucher(props.voucher)];
    }

    if (willUseLoyaltyPoint && loyaltyPointToUse && pointToUse) {
      requestData.pointInfo = {
        pointId: loyaltyPointToUse.pointId,
        pointName: loyaltyPointToUse.pointName,
        amount: pointToUse * loyaltyPointToUse.exchangingToVndRate,
        exchangeRate: loyaltyPointToUse.exchangingToVndRate,
      };
    }

    props.createOrderFnbHandle(
      requestData,
      (res) => {
        if (res.status === 200) {
          const dataOrder = {
            orderCode: res.data.orderCode,
            paymentMethods: res.data.paymentMethods,
            totalPrice: discount
              ? getTotalPrice(props.listProduct) - discount
              : getTotalPrice(props.listProduct),
            orderType: "OrderFnB",
          };
          if (checkObjKeys(shippingInfo, "shippingFee", true) > 0) {
            dataOrder.totalPrice += shippingInfo.shippingFee;
          }
          props.setDeliveryAddress([]);
          // props.navigation.push("UtopPaymentHub", {
          //   dataOrderFNB: dataOrder,
          // });
          NavigationServices.navigate(
            `/transactionmethods/${res.data.orderCode}`
          );
          props.clearProductCart();
          props.setNumberTable("");
          const dataTrackingAzure = {
            OrderCode: dataOrder?.orderCode || 0,
            GrossAmount: getTotalPrice(props.listProduct) || 0,
            NetAmount: dataOrder?.totalPrice || 0,
            ShippingFee: shippingInfo?.shippingFee || 0,
            Discount: discount || 0,
            OrderType: dataOrder?.orderType || "",
            MerchantId: props?.dataStore?.merchantId || "",
            StoreId: props?.dataStore?.storeId || "",
            StoreName:
              props?.dataStore?.storeName || props?.dataStore?.name || "",
          };
          console.log("Button_PlaceOrder", dataTrackingAzure, dataOrder);
          //   insightTrackEvent("Button_PlaceOrder", dataTrackingAzure);
        }
      },
      (error) => {
        const msgError =
          error?.response?.data?.message ||
          "Đã có lỗi xảy ra. Vui lòng thử lại sau!";
        const productCodeErrors = error?.response.data?.errors || [];
        let masterMessage = "";
        if (error.response.status === 422) {
          switch (msgError) {
            case "NotFoundProduct":
              masterMessage = "Vài sản phẩm trong đơn hàng đã ngừng bán";
              setAlertAction({
                isShow: true,
                title: "Utop",
                content: masterMessage,
                titleAccept: "OK",
                dataItem: {
                  key: "order",
                  data: productCodeErrors,
                },
              });
              break;

            case "CanNotApplyCoupon":
              masterMessage =
                "Tài khoản của bạn bị tạm khóa chức năng sử dụng Coupon. Vui lòng liên hệ Hotline để được hỗ trợ";
              break;

            case "CouponIsUsed":
              masterMessage = "Mã Coupon đã được sử dụng!";
              break;

            case "CouponExpired":
              masterMessage = "Mã Coupon đã hết hạn!";
              break;

            default:
              masterMessage = msgError;
              break;
          }

          // show message
          //   Alert.alert(
          //     "Utop",
          //     masterMessage,
          //     [
          //       {
          //         text: strings("ConfirmOrderFnB.textOk"),
          //         onPress: () => {
          //           if (productCodeErrors.length > 0) {
          //             const cartTemp = props?.listProduct;
          //             productCodeErrors.forEach((productCodeError) => {
          //               cartTemp.forEach((item) => {
          //                 if (
          //                   item?.dataInfoProduct?.productCode ===
          //                   productCodeError
          //                 ) {
          //                   // eslint-disable-next-line no-param-reassign
          //                   item.isOutOfStock = true;
          //                   props.updateProductCartOutOfStock(item);
          //                   return;
          //                 }

          //                 if (
          //                   checkObjKeys(item, "data.sizes.iv", true)?.length > 0
          //                 ) {
          //                   item?.data?.sizes?.iv.forEach((productCodeSize) => {
          //                     if (
          //                       productCodeSize ===
          //                       item?.dataInfoProduct?.productCode
          //                     ) {
          //                       // eslint-disable-next-line no-param-reassign
          //                       item.isOutOfStock = true;
          //                       props.updateProductCartOutOfStock(item);
          //                     }
          //                   });
          //                 }
          //               });
          //             });
          //           }
          //         },
          //         style: "cancel",
          //       },
          //     ],
          //     { cancelable: false }
          //   );
        }
      }
    );
  };

  const navigateToDeliveryInfoPage = () => {
    NavigationServices.navigate("/setdeliveryinfo", {
      prevPage: "cartmarket",
      initialReceiverInfo: {
        name: "",
        phoneNumber: "",
        address: "",
        note: "",
      },
      // cartData: cart,
      // defaultDeliveryInfo,
    });
    // history.replace("/setdeliveryinfo", {
    //   cartData: cart,
    //   // defaultDeliveryInfo,
    // });
  };

  const onAcceptAlert = (value) => {
    // eslint-disable-next-line default-case
    switch (value.key) {
      case "address":
        isShowMessageError.current = true;
        navigateToDeliveryInfoPage();
        setAlertAction({});
        break;
      case "coupon":
        clearCoupon();
        setAlertAction({});
        break;
      case "couponNavigation":
        NavigationServices.navigate("/promotion", {
          isFlow: "FnB",
        });
        setAlertAction({});
        break;
      case "basic":
        setAlertAction({});
        break;
      case "table":
        setVisibleMethodDeliveryTable(true);
        setAlertAction({});
        break;
      case "order":
        const productCodeErrors = value.data;
        if (productCodeErrors.length > 0) {
          const cartTemp = props?.listProduct;
          productCodeErrors.forEach((productCodeError) => {
            cartTemp.forEach((item) => {
              if (item?.dataInfoProduct?.productCode === productCodeError) {
                item.isOutOfStock = true;
                props.updateProductCartOutOfStock(item);
                setAlertAction({});
                return;
              }

              if (checkObjKeys(item, "data.sizes.iv", true)?.length > 0) {
                item?.data?.sizes?.iv.forEach((productCodeSize) => {
                  if (productCodeSize === item?.dataInfoProduct?.productCode) {
                    item.isOutOfStock = true;
                    props.updateProductCartOutOfStock(item);
                    setAlertAction({});
                  }
                });
              }
            });
          });
        }
        break;
      case "ordered":
        const { data } = value;
        setAlertAction({});
        createOrder(
          data.willUseLoyaltyPoint,
          data.loyaltyPointToUse,
          data.pointToUse
        );
    }
  };

  return (
    <CartMarketView
      isLoading={props.isLoading}
      listProduct={props.listProduct}
      clearProductCart={props.clearProductCart}
      voucher={props.voucher}
      listSuggestCoupon={props.listSuggestCoupon}
      description={props.description}
      valueNumberTable={props.valueNumberTable}
      updateProductCart={props.updateProductCart}
      setDescription={props.setDescription}
      listMethodDelivery={props.listMethodDelivery}
      setNumberTable={props.setNumberTable}
      setShowMessageError={props.setShowMessageError}
      setVoucher={props.setVoucher}
      getAvailableCouponHandle={props.getAvailableCouponHandle}
      setDeliveryAddress={props.setDeliveryAddress}
      clearAllowcateCoupon={props.clearAllowcateCoupon}
      deleteProductCart={props.deleteProductCart}
      merchantId={merchantId}
      checkAllowcationCoupon={checkAllowcationCoupon}
      handleConfirmOrder={handleConfirmOrder}
      goToVoucher={goToVoucher}
      clearCoupon={clearCoupon}
      messageError={messageError}
      discount={discount}
      isErrorCoupon={isErrorCoupon}
      isVisibleMethodDelivery={isVisibleMethodDelivery}
      isVisibleMethodDeliveryTable={isVisibleMethodDeliveryTable}
      setVisibleMethodDeliveryTable={setVisibleMethodDeliveryTable}
      selectMethodDelivery={selectMethodDelivery}
      setVisibleMethodDelivery={setVisibleMethodDelivery}
      setVisibleNote={setVisibleNote}
      setModalItem={setModalItem}
      isVisibleNote={isVisibleNote}
      isVisibleAddProduct={isVisibleAddProduct}
      setVisibleAddProduct={setVisibleAddProduct}
      setItemDetailSelected={setItemDetailSelected}
      itemDetailSelected={itemDetailSelected}
      storeId={props?.dataStore?.storeId}
      isTakeaway={props.takeAway}
      dataTimer={dataTimer}
      setDataTimer={setDataTimer}
      deliveryMethod={methodDeliveryMaster}
      deliveryInfo={deliveryInfo}
      setTakeAway={props.setTakeAway}
      shippingInfo={shippingInfo}
      handleNavigateSelectMethodDelivery={handleNavigateSelectMethodDelivery}
      deliveryInfoRef={deliveryInfoRef}
      setVisibleShippingDiscount={setVisibleShippingDiscount}
      isVisibleShippingDiscount={isVisibleShippingDiscount}
      isFromMarket={isFromMarket}
      storeInfo={storeInfo}
      dataAlertAction={alertAction}
      setDataAlertAction={() => setAlertAction({})}
      handleAlertAction={onAcceptAlert}
    />
  );
};

const mapStateToProps = (state) => ({
  isLoading: getLoadingSelector(state, [
    GET_COUPON_DETAIL_REQUEST,
    GET_AVAILABLE_COUPON_REQUEST,
    CREATE_ORDER_FNB_REQUEST,
    CALCULATE_SHIPPING_REQUEST,
    GET_DEFAULT_ADDRESS_USER_REQUEST,
  ]),
  listSuggestCoupon: getListCouponSuggest(state),
  listProduct: getListProduct(state),
  dataStore: getDataStore(state),
  allowcationCoupon: getAllowcationCoupon(state),
  description: getDescription(state),
  voucher: getVoucher(state),
  listMethodDelivery: getDeliveryAddress(state),
  messageError: getMessageError(state),
  valueNumberTable: getNumberTable(state),
  takeAway: getTakeAway(state),
  deliveryAddressSelected: getDeliveryAddressSelected(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clearErrors,
      deleteProductCart,
      updateProductCart,
      getListCouponSuggestHandle,
      calCouponDiscountRequest,
      getCouponDetailRequest,
      setDeliveryAddress,
      setVoucher,
      setDescription,
      setNumberTable,
      clearAllowcateCoupon,
      getCouponDetailForVoucherHandle,
      getAvailableCouponHandle,
      allowcationCouponSuccess,
      setShowMessageError,
      getDefaultAddressUserRequest,
      calculateShippingRequest,
      setTakeAway,
      createOrderFnbHandle,
      setDeliveryAddressSelected,
      updateProductCartOutOfStock,
      getSuggestDiscountShippingRequest,
      setListCouponSuggestForStore,
      clearProductCart,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CartMarket);
