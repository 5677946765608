import {GET_LIST_SCHOOL} from './constants';

export const getListBlockOfSchoolTypeHandel = (
  schoolId,
  onSuccess,
  onFailed,
) => ({
  type: GET_LIST_SCHOOL.HANDLER,
  schoolId,
  onSuccess,
  onFailed,
});

export const getListBlockOfSchoolTypeSuccess = payload => ({
  type: GET_LIST_SCHOOL.SUCCESS,
  payload,
});

export const getListBlockOfSchoolTypeFailed = error => ({
  type: GET_LIST_SCHOOL.FAILURE,
  error,
});
