export const storeInfoParser = storeInfo => {
  if (!storeInfo) {
    return {};
  }
  return {
    merchantId: storeInfo?.data?.merchant?.iv[0],
    storeId: storeInfo.id,
    storeName: storeInfo?.data?.name?.vi,
    storeImage:
      global.staticUrl +
      'merchants/' +
      storeInfo?.data?.merchant?.iv[0] +
      '/logo.png',
    storeAddress: storeInfo?.data?.address?.iv || '',
    isAllowCod: storeInfo?.data?.allowCOD?.iv,
    deliveryOptions: storeInfo?.data?.deliveryOptions?.iv,
    openingTimes: storeInfo?.data?.openingTimes,
    phoneStore:
      storeInfo.data && storeInfo.data.phone ? storeInfo.data.phone.iv : '',
    pickupAddress: storeInfo.data.pickupAddress
      ? storeInfo.data.pickupAddress.iv
      : [],
    deliveryAddressOptions:
      storeInfo.data && storeInfo.data.deliveryAddressOptions
        ? storeInfo.data.deliveryAddressOptions.iv
        : [],
    tags:
      storeInfo.data && storeInfo.data.tags && storeInfo.data.tags.iv
        ? storeInfo.data.tags.iv
        : [],
    location: storeInfo?.data?.location?.iv || {},
  };
};

export const listSuggestProductByUserParser = (data, merchantId) => {
  if (!data) {
    return [];
  }
  data.data.merchantId = merchantId;
  return data.data;
};
