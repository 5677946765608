import { call, put, takeLatest, fork, select } from "redux-saga/effects";
import actions from "./actions";
import * as TYPE from "./constants";
import api from "../api/api";

const sagas = {
  *[TYPE.LIST_ORDER_HISTORY.request](action) {
    const { SkipCount, MaxResult } = action.payload;
    const needToRefresh = SkipCount === 0;

    try {
      const response = yield call(
        api[TYPE.LIST_ORDER_HISTORY.request],
        action.payload
      );

      if (response.status === 200) {
        const isInit = false;
        const itemsFromResponse = response.data?.items || []; // items got from response
        const prevCurrentCount = yield select(getCurrentCount) || 0;
        const prevItems = yield select(getListOrder) || [];
        const newCurrentCount = needToRefresh
          ? itemsFromResponse.length
          : prevCurrentCount + itemsFromResponse.length;
        const newItems = needToRefresh
          ? itemsFromResponse
          : [...prevItems, ...itemsFromResponse];

        yield put(
          actions.LIST_ORDER_HISTORY.success({
            listOrderHistoryItem: newItems,
            hasMore: itemsFromResponse.length === MaxResult,
            currentCount: newCurrentCount,
            isInit,
          })
        );
      } else {
        yield put(actions.LIST_ORDER_HISTORY.failure(response?.data));
      }
    } catch (error) {
      yield put(actions.LIST_ORDER_HISTORY.failure(error?.data));
    }
  },
};

function* watchedSaga() {
  yield takeLatest(
    TYPE.LIST_ORDER_HISTORY.request,
    sagas[TYPE.LIST_ORDER_HISTORY.request]
  );
}

export default function* rootChild() {
  yield fork(watchedSaga);
}

// *** Selectors
const getListOrder = (state) =>
  state[TYPE.TYPE].listOrderHistory.listOrderHistoryItem;
const getCurrentCount = (state) =>
  state[TYPE.TYPE].listOrderHistory.currentCount;
