import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import classNames from "classnames";
import { PaperPlaneSolidYellowIcon } from "assets/svg";
import IMAGES from "assets/img";
import { convertHtmlToText } from "helpers/string";
import { IconButton } from "../..";
import "./ReplyInputBox.scss";

const MAX_NUMBER_OF_CHARACTERS = 1000;

const ReplyInputBox = (props) => {
  const {
    idElement,
    idRatingPost,
    handleSubmitReply = () => {},
    currentUserAvatar,
    showSendButton = false,
    handleOutFocus,
    isFocus = false,
    isOnDetailRatingPost = false,
  } = props;
  // const [value, setValue] = useState("");
  const replyInputBoxRef = useRef();
  const [hasContent, setHasContent] = useState(false);

  // const handleOnChange = (e) => {
  //   setValue(e.target.value);
  // };

  // const handleKeyDown = (e) => {
  //   if (e.key === "Enter") {
  //     handleSend();
  //   }
  // };

  // const handleSend = useCallback(() => {
  //   handleSubmitReply({ idRatingPost, content: value });
  //   setValue("");
  // }, [handleSubmitReply, idRatingPost, value]);
  const handleSend = useCallback(() => {
    const text = convertHtmlToText(replyInputBoxRef.current.innerHTML);

    if (text.length > 0) {
      handleSubmitReply({
        idRatingPost,
        content: text,
      });

      // todo: check success mới xóa, đang làm tạm
      replyInputBoxRef.current.innerHTML = "";
    }
  }, [handleSubmitReply, idRatingPost]);

  const handleOnInput = (e) => {
    if (e.target.innerHTML?.length > 0) {
      if (!hasContent) {
        setHasContent(true);
      }
    } else if (hasContent) {
      setHasContent(false);
    }
  };

  useEffect(() => {
    if (replyInputBoxRef.current && isFocus) {
      replyInputBoxRef.current.focus();
    }
  }, [isFocus]);

  // fix bug không bung bàn phím trên IOS
  useLayoutEffect(() => {
    if (isOnDetailRatingPost) {
      const replyElement = document.getElementById(idElement);

      if (replyElement) {
        replyElement.focus();
      }
    }
  }, [isOnDetailRatingPost]);

  const handleScrollIntoReplyBoxView = () => {
    if (!isOnDetailRatingPost) {
      const pageHeader = document.getElementById("common-page-header");
      if (!replyInputBoxRef.current || !pageHeader) {
        return;
      }
      window.scrollTo({
        behavior: "smooth",
        top:
          replyInputBoxRef.current.offsetTop -
          window.innerHeight /
            3 /* chừa vị trí cho bàn phím bật lên (expect bàn phím ko cao quá 2/3 màn hình) */ -
          pageHeader.offsetHeight -
          12 /* 12px of padding top of each post */,
      });
    }
  };

  const handleKeyDown = (event) => {
    function maxLimitForContentEditable(e, limit) {
      let allowedKeys = false;
      if (e.type === "keydown") {
        allowedKeys =
          e.which === 8 /* BACKSPACE */ ||
          e.which === 35 /* END */ ||
          e.which === 36 /* HOME */ ||
          e.which === 37 /* LEFT */ ||
          e.which === 38 /* UP */ ||
          e.which === 39 /* RIGHT */ ||
          e.which === 40 /* DOWN */ ||
          e.which === 46 /* DEL */ ||
          (e.ctrlKey === true && e.which === 65) /* CTRL + A */ ||
          (e.ctrlKey === true && e.which === 88) /* CTRL + X */ ||
          (e.ctrlKey === true && e.which === 67) /* CTRL + C */ ||
          (e.ctrlKey === true && e.which === 86) /* CTRL + V */ ||
          (e.ctrlKey === true && e.which === 90) /* CTRL + Z */;
      }

      // if (e.type === "paste") {
      //   setTimeout(function () {
      //     $(e.target).text(
      //       $(e.target).text().slice(0, limit)
      //     );
      //   });
      // }

      if (!allowedKeys && e.target.innerHTML?.length >= limit) {
        e.preventDefault();
      }
    }

    maxLimitForContentEditable(event, MAX_NUMBER_OF_CHARACTERS);
  };

  return (
    <div className="reply-box__container">
      <object
        className="reply-box__avatar"
        data={currentUserAvatar || IMAGES.AvatarPlaceholder}
        type="image/png"
      >
        <img
          src={IMAGES.AvatarPlaceholder}
          alt="reply-box__avatar"
          className="reply-box__avatar"
        />
      </object>

      <div className="reply-box__input-container">
        <span
          id={idElement}
          role="textbox"
          aria-multiline="true"
          className="span-content-editable"
          contentEditable
          ref={replyInputBoxRef}
          placeholder="Viết bình luận ..."
          onBlur={handleOutFocus}
          onFocus={handleScrollIntoReplyBoxView}
          onInput={handleOnInput}
          onKeyDown={handleKeyDown}
          tabIndex={0}
        />
      </div>

      {showSendButton && (
        <IconButton
          disabled={!hasContent}
          className={classNames("button-send", {
            "button-send--show": hasContent,
            "button-send--hide": !hasContent,
          })}
          onClick={handleSend}
        >
          <PaperPlaneSolidYellowIcon />
        </IconButton>
      )}

      {/*
      <div className="reply-box__input-container">
        <input
          id={idElement}
          onKeyDown={handleKeyDown}
          type="text"
          onChange={handleOnChange}
          placeholder="Viết phản hồi..."
          value={value}
          className="reply-box__input-container__input"
          ref={replyInputBoxRef}
          onBlur={handleOutFocus}
          onFocus={handleScrollIntoReplyBoxView}
          maxLength={1000}
        />
      </div> */}

      {/* {showSendButton && (
        <IconButton
          disabled={!value}
          className={classNames("button-send", {
            "button-send--show": value,
            "button-send--hide": !value,
          })}
          onClick={handleSend}
        >
          <PaperPlaneSolidYellowIcon />
        </IconButton>
      )} */}
    </div>
  );
};

export default React.memo(ReplyInputBox);
