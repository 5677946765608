/* eslint-disable no-param-reassign */
import { addErrors, delErrors } from "utils/ErrorsGenerator";
import { toast } from "react-toastify";

import MESSAGES from "constants/vi";
// import { ERROR_MESSAGES } from "constants/errorMessages";
import { convertWeight } from "utils/numberFormat";
import * as TYPE from "./constants";

const MESSAGE_KEY = "Cart"; // key của object chứa message
const UNKNOWN = "unknown";
const toastOptions = {
  position: "top-center",
  autoClose: 7000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

const reducers = {
  // * GET_USER_DEFAULT_ADDRESS_BOOK
  [TYPE.GET_USER_DEFAULT_ADDRESS_BOOK.request]: (draft) => {
    draft.isLoading.GET_USER_DEFAULT_ADDRESS_BOOK = true;
  },
  [TYPE.GET_USER_DEFAULT_ADDRESS_BOOK.success]: (draft, { payload }) => {
    draft.errors = delErrors(
      draft.errors,
      TYPE.GET_USER_DEFAULT_ADDRESS_BOOK.failure
    );

    draft.defaultDeliveryInfo = payload;
    draft.isLoading.GET_USER_DEFAULT_ADDRESS_BOOK = false;
  },
  [TYPE.GET_USER_DEFAULT_ADDRESS_BOOK.failure]: (draft, { payload }) => {
    // const errMsgToToast =
    //   MESSAGES?.[MESSAGE_KEY]?.error?.[payload?.message] ||
    //   MESSAGES?.[MESSAGE_KEY]?.error?.[UNKNOWN];

    // toast.error(errMsgToToast, toastOptions);

    draft.errors = addErrors(draft.errors, {
      type: TYPE.GET_USER_DEFAULT_ADDRESS_BOOK.failure,
      msg: payload,
    });
    draft.isLoading.GET_USER_DEFAULT_ADDRESS_BOOK = false;
  },

  // * GET_SUGGESTED_COUPON_LIST
  [TYPE.GET_SUGGESTED_COUPON_LIST.request]: (draft) => {
    draft.isLoading.GET_SUGGESTED_COUPON_LIST = true;
  },
  [TYPE.GET_SUGGESTED_COUPON_LIST.success]: (draft, { payload }) => {
    draft.errors = delErrors(
      draft.errors,
      TYPE.GET_SUGGESTED_COUPON_LIST.failure
    );
    draft.suggestedCouponList = payload.suggestedCouponDetails;
    draft.isLoading.GET_SUGGESTED_COUPON_LIST = false;
  },
  [TYPE.GET_SUGGESTED_COUPON_LIST.failure]: (draft, { payload }) => {
    const errMsgToToast =
      MESSAGES?.[MESSAGE_KEY]?.error?.[payload?.message] ||
      MESSAGES?.[MESSAGE_KEY]?.error?.[UNKNOWN];

    toast.error(errMsgToToast, {
      toastId: `${payload?.message}`,
      ...toastOptions,
    });

    draft.errors = addErrors(draft.errors, {
      type: TYPE.GET_SUGGESTED_COUPON_LIST.failure,
      msg: payload,
    });
    draft.isLoading.GET_SUGGESTED_COUPON_LIST = false;
  },

  [TYPE.CLEAR_ERROR_SHIPPING_INFO.request]: (draft) => {
    draft.errors = delErrors(draft.errors, TYPE.GET_SHIPPING_INFO.failure);
    draft.shippingInfo = undefined;
  },

  // * GET_SHIPPING_INFO
  [TYPE.GET_SHIPPING_INFO.request]: (draft) => {
    draft.isLoading.GET_SHIPPING_INFO = true;
  },
  [TYPE.GET_SHIPPING_INFO.success]: (draft, { payload }) => {
    draft.errors = delErrors(draft.errors, TYPE.GET_SHIPPING_INFO.failure);
    draft.shippingInfo = payload?.[0];
    draft.isLoading.GET_SHIPPING_INFO = false;
  },
  [TYPE.GET_SHIPPING_INFO.failure]: (draft, { payload }) => {
    switch (payload?.message) {
      case "UnsatisfyingMinOrder": {
        if (payload?.errors?.minOrderValue) {
          toast.error(
            `Cửa hàng chỉ giao với giá trị đơn hàng từ ${Number(
              payload.errors.minOrderValue
            ).toLocaleString("vi")}đ.`,
            {
              toastId: `${payload?.message}`,
              ...toastOptions,
            }
          );
          break;
        }

        toast.error(`Đơn hàng chưa đạt giá trị tối thiểu tại cửa hàng.`, {
          toastId: `${payload?.message}`,
          ...toastOptions,
        });

        break;
      }
      case "UnsatisfyingDistance": {
        if (payload?.errors?.maxDistance) {
          toast.error(
            `Cửa hàng chỉ giao trong ${payload.errors.maxDistance}km.`,
            {
              toastId: `${payload?.message}`,
              ...toastOptions,
            }
          );
          break;
        }

        if (payload?.errors?.distanceTo) {
          toast.error(
            `Cửa hàng chỉ giao trong ${payload.errors.distanceTo}km.`,
            {
              toastId: `${payload?.message}`,
              ...toastOptions,
            }
          );
          break;
        }

        break;
      }
      case "ExceedWeightLimitation": {
        if (payload?.errors?.maxWeight) {
          toast.error(
            `Chỉ áp dụng cho đơn hàng có khối lương từ ${convertWeight(
              payload.errors.maxWeight
            )} trở xuống.`,
            {
              // toastId: `${TYPE}_GET_SHIPPING_INFO__ExceedWeightLimitation_maxWeight`,
              toastId: `${payload?.message}`,
              ...toastOptions,
            }
          );
        }
        // else {
        //   toast.error(MESSAGES?.[MESSAGE_KEY]?.error?.[UNKNOWN], toastOptions);
        // }

        break;
      }
      default: {
        const errMsgToToast =
          MESSAGES?.[MESSAGE_KEY]?.error?.[payload?.message] ||
          // MESSAGES?.[MESSAGE_KEY]?.error?.[UNKNOWN];
          MESSAGES?.[MESSAGE_KEY]?.error?.["unknownShippingError"];

        toast.error(errMsgToToast, {
          toastId: `${payload?.message}`,
          ...toastOptions,
        });
      }
    }

    draft.errors = addErrors(draft.errors, {
      type: TYPE.GET_SHIPPING_INFO.failure,
      msg: payload,
    });
    draft.isLoading.GET_SHIPPING_INFO = false;
  },

  // * GET_SUGGESTED_DELIVERY_DISCOUNT_LIST
  [TYPE.GET_SUGGESTED_DELIVERY_DISCOUNT_LIST.request]: (draft) => {
    draft.isLoading.GET_SUGGESTED_DELIVERY_DISCOUNT_LIST = true;
  },
  [TYPE.GET_SUGGESTED_DELIVERY_DISCOUNT_LIST.success]: (draft, { payload }) => {
    draft.errors = delErrors(
      draft.errors,
      TYPE.GET_SUGGESTED_DELIVERY_DISCOUNT_LIST.failure
    );
    draft.suggestedDeliveryDiscountList = payload;
    draft.isLoading.GET_SUGGESTED_DELIVERY_DISCOUNT_LIST = false;

    //! hard code for demo
    // draft.suggestedDeliveryDiscountList = [
    //   {
    //     amountFrom: 50000,
    //     amountTo: 75000,
    //     discountFee: 10000,
    //   },
    //   {
    //     amountFrom: 75000,
    //     amountTo: 100000,
    //     discountFee: 20000,
    //   },
    //   {
    //     amountFrom: 100000,
    //     amountTo: 150000,
    //     discountFee: 30000,
    //   },
    // ];
  },
  [TYPE.GET_SUGGESTED_DELIVERY_DISCOUNT_LIST.failure]: (draft, { payload }) => {
    const errMsgToToast =
      MESSAGES?.[MESSAGE_KEY]?.error?.[payload?.message] ||
      MESSAGES?.[MESSAGE_KEY]?.error?.[UNKNOWN];

    toast.error(errMsgToToast, {
      toastId: `${payload?.message}`,
      ...toastOptions,
    });

    draft.errors = addErrors(draft.errors, {
      type: TYPE.GET_SUGGESTED_DELIVERY_DISCOUNT_LIST.failure,
      msg: payload,
    });
    draft.isLoading.GET_SUGGESTED_DELIVERY_DISCOUNT_LIST = false;
  },

  // * CALC_COUPON_DISCOUNT
  [TYPE.CALC_COUPON_DISCOUNT.request]: (draft) => {
    draft.isLoading.CALC_COUPON_DISCOUNT = true;
  },
  [TYPE.CALC_COUPON_DISCOUNT.success]: (draft, { payload }) => {
    draft.errors = delErrors(draft.errors, TYPE.CALC_COUPON_DISCOUNT.failure);
    draft.discountAmount = payload;
    draft.isLoading.CALC_COUPON_DISCOUNT = false;
  },
  [TYPE.CALC_COUPON_DISCOUNT.failure]: (draft, { payload }) => {
    const errMsgToToast =
      MESSAGES?.[MESSAGE_KEY]?.error?.[payload?.message] ||
      MESSAGES?.[MESSAGE_KEY]?.error?.[UNKNOWN];

    toast.error(errMsgToToast, {
      toastId: `${payload?.message}`,
      ...toastOptions,
    });

    draft.errors = addErrors(draft.errors, {
      type: TYPE.CALC_COUPON_DISCOUNT.failure,
      msg: payload,
    });
    draft.isLoading.CALC_COUPON_DISCOUNT = false;
  },

  // * GET_AVAILABLE_COUPONS_BY_CODE
  [TYPE.GET_AVAILABLE_COUPONS_BY_CODE.request]: (draft) => {
    draft.isLoading.GET_AVAILABLE_COUPONS_BY_CODE = true;
  },
  [TYPE.GET_AVAILABLE_COUPONS_BY_CODE.success]: (draft, { payload }) => {
    draft.errors = delErrors(
      draft.errors,
      TYPE.GET_AVAILABLE_COUPONS_BY_CODE.failure
    );
    draft.appliedCouponVoucherCode = payload;
    draft.isLoading.GET_AVAILABLE_COUPONS_BY_CODE = false;
    // ! comment because after success we need to wait CALC_COUPON_DISCOUNT
  },
  [TYPE.GET_AVAILABLE_COUPONS_BY_CODE.failure]: (draft, { payload }) => {
    const errMsgToToast =
      MESSAGES?.[MESSAGE_KEY]?.error?.[payload?.message] ||
      MESSAGES?.[MESSAGE_KEY]?.error?.[UNKNOWN];

    toast.error(errMsgToToast, {
      toastId: `${payload?.message}`,
      ...toastOptions,
    });

    draft.errors = addErrors(draft.errors, {
      type: TYPE.GET_AVAILABLE_COUPONS_BY_CODE.failure,
      msg: payload,
    });
    draft.isLoading.GET_AVAILABLE_COUPONS_BY_CODE = false;
  },

  // * GET_AVAILABLE_COUPONS_OF_ALLOCATED_COUPON_TICKET
  [TYPE.GET_AVAILABLE_COUPONS_OF_ALLOCATED_COUPON_TICKET.request]: (draft) => {
    draft.isLoading.GET_AVAILABLE_COUPONS_OF_ALLOCATED_COUPON_TICKET = true;
  },
  [TYPE.GET_AVAILABLE_COUPONS_OF_ALLOCATED_COUPON_TICKET.success]: (
    draft,
    { payload }
  ) => {
    draft.errors = delErrors(
      draft.errors,
      TYPE.GET_AVAILABLE_COUPONS_OF_ALLOCATED_COUPON_TICKET.failure
    );
    draft.appliedCouponVoucherCode = payload?.[0]?.voucherCode;
    draft.isLoading.GET_AVAILABLE_COUPONS_OF_ALLOCATED_COUPON_TICKET = false;
    // ! comment because after success we need to wait CALC_COUPON_DISCOUNT
  },
  [TYPE.GET_AVAILABLE_COUPONS_OF_ALLOCATED_COUPON_TICKET.failure]: (
    draft,
    { payload }
  ) => {
    const errMsgToToast =
      MESSAGES?.[MESSAGE_KEY]?.error?.[payload?.message] ||
      MESSAGES?.[MESSAGE_KEY]?.error?.[UNKNOWN];

    toast.error(errMsgToToast, {
      toastId: `${payload?.message}`,
      ...toastOptions,
    });

    draft.errors = addErrors(draft.errors, {
      type: TYPE.GET_AVAILABLE_COUPONS_OF_ALLOCATED_COUPON_TICKET.failure,
      msg: payload,
    });
    draft.isLoading.GET_AVAILABLE_COUPONS_OF_ALLOCATED_COUPON_TICKET = false;
  },

  // * CALC_COUPON_DISCOUNT_V2
  [TYPE.CALC_COUPON_DISCOUNT_V2.request]: (draft) => {
    draft.isLoading.CALC_COUPON_DISCOUNT_V2 = true;
  },
  [TYPE.CALC_COUPON_DISCOUNT_V2.success]: (draft, { payload }) => {
    draft.errors = delErrors(
      draft.errors,
      TYPE.CALC_COUPON_DISCOUNT_V2.failure
    );

    draft.discountAmount = payload.discountAmount;

    const FNB_CART_KEY = "FnBCart";
    const FnBCart = JSON.parse(localStorage.getItem(FNB_CART_KEY));

    if (FnBCart) {
      localStorage.setItem(
        FNB_CART_KEY,
        JSON.stringify({
          ...FnBCart,
          coupons: payload?.voucherCode ? [payload.voucherCode] : [],
        })
      );
    }

    draft.isLoading.CALC_COUPON_DISCOUNT_V2 = false;
  },
  [TYPE.CALC_COUPON_DISCOUNT_V2.failure]: (draft, { payload }) => {
    const errMsgToToast =
      MESSAGES?.[MESSAGE_KEY]?.error?.[payload?.message] ||
      MESSAGES?.[MESSAGE_KEY]?.error?.[UNKNOWN];

    toast.error(errMsgToToast, {
      toastId: `${payload?.message}`,
      ...toastOptions,
    });

    draft.errors = addErrors(draft.errors, {
      type: TYPE.CALC_COUPON_DISCOUNT_V2.failure,
      msg: payload,
    });
    draft.discountAmount = 0;

    const FNB_CART_KEY = "FnBCart";
    const FnBCart = JSON.parse(localStorage.getItem(FNB_CART_KEY));

    localStorage.setItem(
      FNB_CART_KEY,
      JSON.stringify({
        ...FnBCart,
        coupons: [],
      })
    );

    draft.isLoading.CALC_COUPON_DISCOUNT_V2 = false;
  },

  // * CREATE_ORDER
  [TYPE.CREATE_ORDER.request]: (draft) => {
    draft.isLoading.CREATE_ORDER = true;
    draft.orderInfo = undefined;
  },
  [TYPE.CREATE_ORDER.success]: (draft, { payload }) => {
    draft.errors = delErrors(draft.errors, TYPE.CREATE_ORDER.failure);
    draft.orderInfo = payload;
    draft.isLoading.CREATE_ORDER = false;
  },
  [TYPE.CREATE_ORDER.failure]: (draft, { payload }) => {
    const errMsgToToast =
      MESSAGES?.[MESSAGE_KEY]?.error?.[payload?.message] ||
      MESSAGES?.[MESSAGE_KEY]?.error?.[UNKNOWN];

    toast.error(errMsgToToast, {
      toastId: `${payload?.message}`,
      ...toastOptions,
    });

    draft.errors = addErrors(draft.errors, {
      type: TYPE.CREATE_ORDER.failure,
      msg: payload,
    });
    draft.orderInfo = undefined;

    // toast.error(ERROR_MESSAGES?.[`${payload.message}`], toastOptions);
    draft.isLoading.CREATE_ORDER = false;
  },

  // * GET_OUT_OF_STOCK_PRODUCT_LIST
  [TYPE.GET_OUT_OF_STOCK_PRODUCT_LIST.request]: (draft) => {
    draft.isLoading.GET_OUT_OF_STOCK_PRODUCT_LIST = true;
  },
  [TYPE.GET_OUT_OF_STOCK_PRODUCT_LIST.success]: (draft, { payload }) => {
    draft.errors = delErrors(
      draft.errors,
      TYPE.GET_OUT_OF_STOCK_PRODUCT_LIST.failure
    );

    const outOfStockProductCodes = payload.map((item) => item.productCode);

    draft.outOfStockProductList = outOfStockProductCodes;
    draft.isLoading.GET_OUT_OF_STOCK_PRODUCT_LIST = false;
  },
  [TYPE.GET_OUT_OF_STOCK_PRODUCT_LIST.failure]: (draft, { payload }) => {
    const errMsgToToast =
      MESSAGES?.[MESSAGE_KEY]?.error?.[payload?.message] ||
      MESSAGES?.[MESSAGE_KEY]?.error?.[UNKNOWN];

    toast.error(errMsgToToast, {
      toastId: `${payload?.message}`,
      ...toastOptions,
    });

    draft.errors = addErrors(draft.errors, {
      type: TYPE.GET_OUT_OF_STOCK_PRODUCT_LIST.failure,
      msg: payload,
    });
    draft.isLoading.GET_OUT_OF_STOCK_PRODUCT_LIST = false;
  },

  // * GET_PRODUCT_LIST
  [TYPE.GET_PRODUCT_LIST.request]: (draft) => {
    draft.isLoading.GET_PRODUCT_LIST = true;
  },
  [TYPE.GET_PRODUCT_LIST.success]: (draft, { payload }) => {
    return {
      ...draft,
      errors: delErrors(draft.errors, TYPE.GET_PRODUCT_LIST.failure),
      productList: payload,
      isLoading: {
        ...draft.isLoading,
        GET_PRODUCT_LIST: false,
      },
    };
  },
  [TYPE.GET_PRODUCT_LIST.failure]: (draft, { payload }) => {
    const errMsgToToast =
      MESSAGES?.[MESSAGE_KEY]?.error?.[payload?.message] ||
      MESSAGES?.[MESSAGE_KEY]?.error?.[UNKNOWN];

    toast.error(errMsgToToast, {
      // toastId: `${TYPE}_GET_PRODUCT_LIST`,
      toastId: `${payload?.message}`,
      ...toastOptions,
    });

    return {
      ...draft,
      productList: [],
      categoriesList: [],
      errors: addErrors(draft.errors, {
        type: TYPE.GET_PRODUCT_LIST.failure,
        msg: payload,
      }),
      isLoading: {
        ...draft.isLoading,
        GET_PRODUCT_LIST: false,
      },
    };
  },

  // * GET_STORE_INFO
  [TYPE.GET_STORE_INFO.request]: (draft) => {
    return {
      ...draft,
      isLoading: {
        ...draft.isLoading,
        GET_STORE_INFO: true,
      },
    };
  },
  [TYPE.GET_STORE_INFO.success]: (draft, { payload }) => {
    return {
      ...draft,
      storeInfo: payload,
      errors: delErrors(draft.errors, TYPE.GET_STORE_INFO.failure),
      isLoading: {
        ...draft.isLoading,
        GET_STORE_INFO: false,
      },
    };
  },
  [TYPE.GET_STORE_INFO.failure]: (draft, { payload }) => {
    return {
      ...draft,
      errors: addErrors(draft.errors, {
        type: TYPE.GET_STORE_INFO.failure,
        msg: payload,
      }),
      isLoading: {
        ...draft.isLoading,
        GET_STORE_INFO: false,
      },
    };
  },

  // * GET_LOYALTY_POINT_LIST
  [TYPE.GET_LOYALTY_POINT_LIST.request]: (draft) => {
    return {
      ...draft,
      isLoading: {
        ...draft.isLoading,
        GET_LOYALTY_POINT_LIST: true,
      },
    };
  },
  [TYPE.GET_LOYALTY_POINT_LIST.success]: (draft, { payload }) => {
    return {
      ...draft,
      loyaltyPointList: payload,
      errors: delErrors(draft.errors, TYPE.GET_LOYALTY_POINT_LIST.failure),
      isLoading: {
        ...draft.isLoading,
        GET_LOYALTY_POINT_LIST: false,
      },
    };
  },
  [TYPE.GET_LOYALTY_POINT_LIST.failure]: (draft, { payload }) => {
    return {
      ...draft,
      errors: addErrors(draft.errors, {
        type: TYPE.GET_LOYALTY_POINT_LIST.failure,
        msg: payload,
      }),
      isLoading: {
        ...draft.isLoading,
        GET_LOYALTY_POINT_LIST: false,
      },
    };
  },
  [TYPE.CLEAR_ORDER_INFO]: (draft) => {
    return {
      ...draft,
      orderInfo: undefined,
    };
  },
};

export default reducers;
