import request, { requestWithoutToken, defaultHeaders } from "services/request";
import { encodeQueryData } from "utils/stringFormat";

import * as TYPE from "../store/constants";

const api = {
  [TYPE.GET_USER_DEFAULT_ADDRESS_BOOK.request]: async () => {
    return request(`/personal/GetDefaultUserAddressBook`, "GET");
  },

  [TYPE.GET_SUGGESTED_COUPON_LIST.request]: async (params) => {
    const query = encodeQueryData({
      StoreId: params.storeId,
      MerchantId: params.merchantId,
      Feature: "orderfnb",
    });

    if (params?.viewAsGuest) {
      return requestWithoutToken(`/coupon/GetSuggestCoupon${query}`, "GET");
    }

    return request(`/coupon/GetSuggestCoupon${query}`, "GET");
  },

  [TYPE.GET_SHIPPING_INFO.request]: async (bodyData) => {
    return request(`/shipping/Estimate`, "POST", bodyData);
  },

  [TYPE.GET_SUGGESTED_DELIVERY_DISCOUNT_LIST.request]: async (params) => {
    const query = encodeQueryData({
      merchantId: params.merchantId,
      storeId: params.storeId,
      code: params.code,
    });

    return request(`/Shipping/SuggestDiscount${query}`, "GET");
  },

  [TYPE.CALC_COUPON_DISCOUNT.request]: async (bodyData) => {
    return request(`/coupon/CalculateCouponDiscountHub`, "POST", bodyData);
  },

  [TYPE.GET_AVAILABLE_COUPONS_BY_CODE.request]: async (code) => {
    return request(`/coupon/GetAvailableCouponsByCode`, "POST", { code });
  },

  [TYPE.GET_AVAILABLE_COUPONS_OF_ALLOCATED_COUPON_TICKET.request]: async (
    params
  ) => {
    const query = encodeQueryData({
      productcode: params.couponProductCode,
      status: "Redeem",
      transactioncode: params.couponTransactionCode,
    });
    return request(`/voucher/VoucherDetail${query}`, "GET");
  },

  [TYPE.CREATE_ORDER.request]: async (bodyData) => {
    return request(`/Order/OrderFnbHub/UtopWeb`, "POST", bodyData);
  },

  [TYPE.GET_OUT_OF_STOCK_PRODUCT_LIST.request]: async (params) => {
    const query = encodeQueryData({
      merchantId: params.merchantId,
      storeId: params.storeId,
    });
    return request(`order/GetOutOfStockByStore${query}`, "GET");
  },

  [TYPE.GET_PRODUCT_LIST.request]: async (merchantId) => {
    return requestWithoutToken(
      `/cms/core/v2/fnb/products?skip=0&take=200&merchantid=${merchantId}`,
      "GET"
    );
  },

  [TYPE.GET_STORE_INFO.request]: async (storeId) => {
    return requestWithoutToken(`/cms/core/stores/${storeId}`, "GET");
  },

  [TYPE.GET_LOYALTY_POINT_LIST.request]: async (params) => {
    const query = encodeQueryData({
      merchantId: params.merchantId,
      storeId: params.storeId,
    });
    return request(`/Loyalty/Users/Point${query}`, "GET", null, {
      ...defaultHeaders,
      "Ocp-Apim-Subscription-Key":
        process.env.REACT_APP_OCP_APIM_SUBSCRIPTION_KEY_FOR_LOYALTY_POINT,
    });
  },
};

export default api;
