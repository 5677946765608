// import actions from "./actions";
// import * as TYPE from "./constants";
// import { call, put, takeLatest, fork, takeEvery } from "redux-saga/effects";
// import api from "../api/api";

// const sagas = {
//   // *[TYPE.CREATOR.request](action) {
//   //   try {
//   //     const data = yield call(api[TYPE.CREATOR.request], action.payload);
//   //     if (data.status === 200) {
//   //       yield put(actions.CREATOR.success(data.data));
//   //     } else {
//   //       yield put(actions.CREATOR.failure(data?.data));
//   //     }
//   //   } catch (error) {
//   //     yield put(actions.CREATOR.failure(error?.data));
//   //   }
//   // },
// };

// function* watchedSaga() {
//   // yield takeLatest(TYPE.CREATOR.request, sagas[TYPE.CREATOR.request]);
// }

export default function* rootChild() {
  // yield fork(watchedSaga);
}
