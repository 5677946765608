import * as _ from 'lodash';

export const sortArrayWithElementId = (arrayInput, elementId) => {
  const copyArrayInput = [...arrayInput];
  copyArrayInput.sort((a, b) => {
    return a[elementId] - b[elementId];
  });
  return copyArrayInput;
};

export const convertAppVersionToNumber = versionApp => {
  const stringFormat = versionApp.split('.').join('');
  const slashIndex = stringFormat.indexOf('-');
  const truncateSlash = stringFormat.slice(
    0,
    slashIndex !== -1 ? slashIndex : stringFormat?.length,
  );
  return parseInt(truncateSlash);
};

export const convertVersionToString = versionApp => {
  const slashIndex = versionApp.indexOf('-');
  const truncateSlash = versionApp.slice(
    0,
    slashIndex !== -1 ? slashIndex : versionApp?.length,
  );
  return truncateSlash;
};

export const compareTwoVersionApp = (
  latestVersion = '',
  currentAppVersion = '',
) => {
  let isLastestVersionGreaterThanCurrenVersion = false;
  const latestVersionArr = latestVersion.split('.');
  const currentAppVersionArr = currentAppVersion.split('.');
  currentAppVersionArr?.every((element, index) => {
    if (parseInt(element) < parseInt(latestVersionArr[index])) {
      isLastestVersionGreaterThanCurrenVersion = true;
      return false;
    }
    return true;
  });
  return isLastestVersionGreaterThanCurrenVersion;
};
