const AlertMsg = (type, content, callback) => {
  let message = content;
  if (typeof content === "object") {
    try {
      message = JSON.parse(content.bodyString);
    } catch (error) {
      message = content.bodyString;
    }
  }
  if (message !== "notShowMessage") {
    if (type === "basic") {
      window.alert(message);
    } else if (type === "action") {
      if (window.confirm(message)) {
        callback();
      }
    }
  }
};
export default AlertMsg;
