export const getUtopPointTotalSelector = state => state.utopback.utopPointTotal;

export const getListCampaignSelector = state => state.utopback.listCampaign;

export const getAffDetailSelector = state => state.utopback.affDetail;

export const getListApprovedSelector = state =>
  state.utopback.listAffHistory.listApproved;
export const getListReceivedSelector = state =>
  state.utopback.listAffHistory.listReceived;
export const getListCancelSelector = state =>
  state.utopback.listAffHistory.listCancel;
export const getListPendingSelector = state =>
  state.utopback.listAffHistory.listPending;
export const getTotalUtopPendingSelector = state =>
  state.utopback.listAffHistory.totalUtopPending;
export const getSkipCountApprovedSelector = state =>
  state.utopback.listAffHistory.skipCountApproved;
export const getSkipCountCancelSelector = state =>
  state.utopback.listAffHistory.skipCountCancel;
export const getSkipCountReceivedSelector = state =>
  state.utopback.listAffHistory.skipCountReceived;
