/* eslint-disable react/no-array-index-key */
/* eslint-disable import/order */
/* eslint-disable no-unused-vars */
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { useHistory, useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import moment from "moment";
import SVG, {
  ArrowBackWhiteIcon,
  ArrowRightYellowIcon,
  CartRating,
} from "assets/svg";
import IMAGES from "assets/img";
import { getShortLinkToShare } from "@shared/redux/market/marketHome/actions";
import InfiniteScroll from "react-infinite-scroll-component";
import DetailRatingPost from "views/common/component/DetailRatingPost/DetailRatingPost";

import { toast } from "react-toastify";
import { toastOptions } from "utils/toast";

import {
  FOOD_ITEM_TYPES,
  COUPON_DETAIL_PAGE_MODES,
  COMMENT_TYPES_OF_RATING_FEATURE,
} from "constants/types";

import { IconButton, Post, Dock, LoadingOverlay } from "views/common/component";
import StoreRatingView from "views/common/component/StoreComment/Comment.container";
import Popup from "views/common/component/Popup";
import CouponItem from "./components/CouponItem";
import StoreInfo from "./components/StoreInfo";
import AlertAction from "views/common/component/AlertAction";
import { useScrollHorizontallyWithButtons } from "hooks";

import FirstRater from "./components/Rating/components/FirstRater";
import StoreHeader from "./components/StoreHeader";
import OrderMenu from "./components/OrderMenu";

import FoodItem, { FoodItemSkeleton } from "./components/FoodItem";
import CategoryList from "./components/CategoryList";
import CategoryFilter from "./components/CategoryFilter";

import "./MarketHomeView.scss";
import StoreList from "./components/StoreList";
import { NavigationServices } from "utils";
import CartBottomBar from "./components/CartBottomBar";
import QuantityMenu from "./components/QuantityMenu";
import EditMenu from "./components/EditMenu";
import { TYPE } from "../../StoreView/store/constants";
import actions from "../../StoreView/store/actions";
import RatingStoreView from "../../StoreView/components/Rating/components/RatingStoreView/RatingStoreView";

const tabsListOfStoreView = [
  {
    code: "store__head__main-content",
    name: "Thông tin",
  },
  {
    code: "store__head__list-product",
    name: "Danh sách sản phẩm",
  },
  {
    code: "store__head__list-store",
    name: "Danh sách cửa hàng",
  },
  { code: "store-view__rating", name: "Đánh giá" },
];

const STORE_VIEW_PRODUCT_SECTION = "Đã mua gần đây";
const STORE_VIEW_PRODUCT_LIST = "Danh sách sản phẩm";

const OFFSET_TO_SCROLL_PER_CLICK = 300;

const MarketHome = (props) => {
  const { storeId } = props;
  const tagsContainerRef = useRef();
  const tagsContainerProductRef = useRef();

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const isScrollDown = useRef();
  // const loadingRef = useRef();

  const { userInfo } = useSelector((state) => state.root);
  const {
    ratingData,
    storeInfoV2,
    isLoading,
    userLikedData,
    notAlreadyRatedList,
  } = useSelector((state) => state[TYPE]);

  // popup states
  const [showSuggestedCouponList, setShowSuggestedCouponList] = useState(false);
  const [showOrderMenu, setShowOrderMenu] = useState(false);
  const [showQuantityMenu, setShowQuantityMenu] = useState(false);
  const [showEditMenu, setShowEditMenu] = useState(false);
  const [itemInCartToEdit, setItemInCartToEdit] = useState();

  const [openModalCreateNewCart, setOpenModalCreateNewCart] = useState(false);
  const [chosenProduct, setChosenProduct] = useState();
  const [isDetailRatting, setDetailRatting] = useState(false);
  const [starActive, setStarActive] = useState(0);
  const [likedListParams, setLikedListParams] = useState({
    skip: 0,
    take: 10,
  });
  const [showUserLikedList, setShowUserLikedList] = useState(false);
  const [chosenRatingPost, setChosenRatingPost] = useState();

  const itemToCreateNewCart = useRef(); // item lưu cho trường hợp ghi đè giỏ hàng

  const {
    arrowButtonsState,
    scrollHorizontal,
    handleTagsContainerScroll,
    BTN_STATE,
  } = useScrollHorizontallyWithButtons({
    containerRef: tagsContainerRef,
    items: props.dataProductRecent,
  });

  const {
    arrowButtonsState: arrowButtonsStateProduct,
    scrollHorizontal: scrollHorizontalProduct,
    handleTagsContainerScroll: handleTagsContainerScrollProduct,
    BTN_STATE: BTN_STATE_PRODUCT,
  } = useScrollHorizontallyWithButtons({
    containerRef: tagsContainerProductRef,
    items: props.dataProduct,
  });

  // handle show modal

  useEffect(() => {
    if (userInfo) {
      dispatch(
        actions.GET_RATING_LIST.request({
          refId: storeId,
          featureId: process.env.REACT_APP_FEATURE_ID,
          haveComment: true, // rule
          sortingType: 0,
          take: 2,
          skip: 0,
        })
      );
    }
    return () => {
      dispatch(actions.CLEAR_RATING_LIST.request());
    };
  }, [userInfo]);

  useEffect(() => {
    if (ratingData?.ratings && chosenRatingPost?.id) {
      setChosenRatingPost(
        ratingData.ratings.find((post) => post.id === chosenRatingPost?.id)
      );
    }
  }, [ratingData?.ratings]);

  useEffect(() => {
    if (storeId) {
      dispatch(actions.GET_NOT_ALREADY_RATED_LIST.request(storeId));
    }
  }, [storeId]);

  const filterCategory = (item) => {
    if (props.openingTimes) {
      NavigationServices.navigate("/marketproduct", {
        id: item?.id,
        merchantId: props?.merchantId,
        name: item.name?.vi,
        storeId: props?.storeId,
        storeInfo: props?.storeInfo,
        isCloseStore: !(
          props.openingTimes.rangeTime === "in" ||
          props.openingTimes.rangeTime === "all"
        ),
      });
    }
  };

  const goToAllStore = useCallback(() => {
    if (props.openingTimes) {
      NavigationServices.navigate("/liststoremarket", {
        storeInfo: props.storeInfo,
        isCloseStore: !(
          props.openingTimes.rangeTime === "in" ||
          props.openingTimes.rangeTime === "all"
        ),
      });
    }
  }, [props.storeInfo, props.openingTimes]);

  const gotoProductStore = (item) => {
    if (props.openingTimes) {
      NavigationServices.navigate(
        `/StoreDetailMarket?storeId=${props?.storeInfo?.id}&merchantId=${props?.storeInfo?.merchantId}&storeMerchantId=${item.id}`,
        {
          storeId: props?.storeInfo?.id,
          merchantId: props?.storeInfo?.merchantId,
          storeMerchantId: item.id,
          isCloseStore: !(
            props.openingTimes.rangeTime === "in" ||
            props.openingTimes.rangeTime === "all"
          ),
        }
      );
    }
  };

  // Render check UI to suggest user Rate or not rate
  // const handleCheckRatedOfUser = () => {
  //   if (userInfo === null) {
  //     return <FirstRater href="/login" />;
  //   }

  //   const dataAlreadyRate = JSON.parse(localStorage.getItem("storeViewRated"));
  //   let dateFormatted;

  //   if (dataAlreadyRate) {
  //     // check if this user?
  //     if (userInfo?.userId === dataAlreadyRate?.userId) {
  //       if (storeId === dataAlreadyRate.storeId) {
  //         dateFormatted = moment(dataAlreadyRate?.ratedAtDate).format("DD");
  //       }
  //     }

  //     // check if this storeId
  //   }
  //   return (
  //     <StoreRatingView
  //       storeInfo={props?.storeInfo}
  //       // avgRate={data?.storeDetail?.avgRate?.iv}
  //       storeId={props?.storeInfo?.id}
  //       // openRatingListLike={openRatingListLike}
  //       // openCommentListLike={openCommentListLike}
  //       isMarket
  //       openDetailRatting={() => setDetailRatting(true)}
  //       closeDetailRatting={() => setDetailRatting(false)}
  //       setStarRating={props?.setStarRating}
  //     />
  //   );
  // };

  const handleSeeAllRating = useCallback(() => {
    const defaultHaveComment = ratingData?.ratings?.length > 0;
    history.push(
      `/rating?storeId=${storeId}&merchantId=${props?.merchantId}&defaultHaveComment=${defaultHaveComment}`
    );
  }, [storeId, props?.merchantId, ratingData?.ratings]);

  const handleRatingStar = () => {
    setStarActive(0);
    localStorage.setItem(
      "ratingStar",
      JSON.stringify({
        rating: starActive,
      })
    );

    history.push("/ratingstore", {
      sourceId: notAlreadyRatedList[0],
      RefId: storeId,
      featureId: process.env.REACT_APP_FEATURE_ID,
      rating: 0,
      name: storeInfoV2?.name?.vi,
      merchant: props.merchantId,
    });
  };

  const handleUnlikeCmt = useCallback(
    (id) => () => {
      dispatch(actions.POST_UNLIKE_CMT.request(id));
    },
    []
  );

  const handleLikeCmt = useCallback(
    (id) => () => {
      dispatch(actions.POST_LIKE_CMT.request(id));
    },
    []
  );

  const handleOpenUserLikedList =
    ({ id, type, skip = 0 }) =>
    () => {
      setLikedListParams({ id, type, skip });
      handleFetchLikedList({
        id,
        type,
        skip: 0,
        needToClearExistedList: true,
      })();
      setShowUserLikedList(true);
    };

  const handleFetchLikedList = useCallback(
    ({ id, type, skip, take = 10, needToClearExistedList = false }) =>
      () => {
        if (
          !id ||
          ![
            COMMENT_TYPES_OF_RATING_FEATURE.RATING_POST,
            COMMENT_TYPES_OF_RATING_FEATURE.COMMENT,
          ].includes(type)
        ) {
          return;
        }

        const params = {
          id,
          skip,
          take,
          needToClearExistedList,
        };

        setLikedListParams({ id, type, skip });

        if (type === COMMENT_TYPES_OF_RATING_FEATURE.RATING_POST) {
          dispatch(actions.GET_USER_LIKED_LIST_OF_RATING_POST.request(params));
        }

        if (type === COMMENT_TYPES_OF_RATING_FEATURE.COMMENT) {
          dispatch(actions.GET_USER_LIKED_LIST_OF_COMMENT.request(params));
        }
      },
    []
  );

  const handleLikeSubCmt = (replyId, postId) => () => {
    dispatch(actions.POST_LIKE_SUB_CMT.request({ replyId, postId }));
  };

  const handleUnLikeSubCmt = (replyId, postId) => () => {
    dispatch(actions.POST_UNLIKE_SUB_CMT.request({ replyId, postId }));
  };

  const handleShowDetailRatingPost = useCallback(
    (ratingPost) => () => {
      if (ratingPost) {
        setChosenRatingPost(ratingPost);
      }
    },
    [ratingData]
  );

  const handleCloseUserLikedList = () => {
    setShowUserLikedList(false);
  };

  const handleCloseDetailRatingPost = useCallback(() => {
    setChosenRatingPost(null);
  }, []);

  const handleSubmitReply = useCallback(({ idRatingPost, content }) => {
    const body = {
      featureId: process.env.REACT_APP_FEATURE_ID,
      sourceId: idRatingPost,
      content,
    };

    dispatch(actions.POST_REPLY_CMT.request(body));
  }, []);

  const SeeMore = useCallback(() => {
    return (
      <>
        <div
          style={{
            borderTop: "1px solid rgb(222, 222, 222)",
            marginLeft: -12,
            marginRight: -12,
            marginTop: 16,
          }}
        />
        <div className="rating-content__see-more" onClick={handleSeeAllRating}>
          <p>Xem thêm</p>
          <img src={SVG.ArrowRightYellow} alt="arrow" />
        </div>
      </>
    );
  }, [handleSeeAllRating]);

  const handleCheckRatedOfUser = () => {
    if (userInfo === null) {
      return <FirstRater href="/login" />;
    }

    const dataAlreadyRate = JSON.parse(localStorage.getItem("storeViewRated"));
    const dateToday = moment(Date.now()).format("DD");
    let dateFormatted;

    if (dataAlreadyRate) {
      // check if this user?
      if (userInfo?.userId === dataAlreadyRate?.userId) {
        if (storeId === dataAlreadyRate.storeId) {
          dateFormatted = moment(dataAlreadyRate?.ratedAtDate).format("DD");
        }
      }

      // check if this storeId
    }

    const header = (
      <RatingStoreView
        ratingData={ratingData}
        handleSeeAllRating={handleSeeAllRating}
      />
    );
    let body;

    // * Tạm chưa có lời bình, mua ngay đơn hàng và đánh giá cửa hàng. Icon Giỏ hàng
    if (
      (ratingData?.ratings?.length === 0 &&
        notAlreadyRatedList?.length === 0) ||
      (ratingData?.ratings?.length === 0 &&
        notAlreadyRatedList?.length > 0 &&
        dateFormatted === dateToday) // đã có rating trong ngày
    ) {
      body = (
        <FirstRater
          title="Tạm chưa có lời bình, mua ngay đơn hàng và đánh giá cửa hàng."
          iconFirstRater={<CartRating />}
          hasButton={false}
          titleRate=""
          addMoreClassName="add-margin-top"
        />
      );
    } else if (
      ratingData?.ratings?.length === 0 &&
      notAlreadyRatedList?.length > 0 &&
      dateFormatted !== dateToday // chưa rating trong ngày
    ) {
      // Hãy là người đầu tiên chia sẻ cảm nhận của bạn về cửa hàng. Icon comment
      // console.log(
      //   "Hãy là người đầu tiên chia sẻ cảm nhận của bạn về cửa hàng. Icon comment"
      // );
      body = (
        <FirstRater
          title="Hãy là người đầu tiên chia sẻ cảm nhận của bạn về cửa hàng."
          buttonContent="Đánh giá"
          href=""
          titleRate=""
          onClick={handleRatingStar}
          addMoreClassName="add-margin-top"
        />
      );
    } else if (
      ratingData?.ratings?.length > 0 &&
      notAlreadyRatedList?.length > 0 &&
      dateFormatted !== dateToday // chưa rating trong ngày
    ) {
      // * Chia sẻ cảm nhận bạn để người dùng khác có trải nghiệm tốt hơn. Icon Comment
      // console.log(
      //   "Chia sẻ cảm nhận bạn để người dùng khác có trải nghiệm tốt hơn. Icon Comment"
      // );
      body = (
        <FirstRater
          title="Chia sẻ cảm nhận của bạn giúp người dùng khác tìm được trải nghiệm tốt hơn."
          buttonContent="Đánh giá"
          titleRate=""
          href=""
          onClick={handleRatingStar}
          addMoreClassName="add-margin-top"
        />
      );
    }
    return (
      <>
        {header}

        {body}

        {ratingData.ratings?.length > 0 &&
          ratingData.ratings.map((ratingPost) => (
            <div
              key={ratingPost.id}
              style={{ marginLeft: "-8px", marginRight: "-8px" }}
            >
              <div className="rating-content__line-break"> </div>
              <Post
                rating={ratingPost}
                handleUnlikeCmt={handleUnlikeCmt}
                handleLikeCmt={handleLikeCmt}
                handleOpenUserLikedList={handleOpenUserLikedList}
                handleLikeSubCmt={handleLikeSubCmt}
                handleUnLikeSubCmt={handleUnLikeSubCmt}
                handleSeeAllRating={handleSeeAllRating}
                handleClickToRepliesButton={handleShowDetailRatingPost(
                  ratingPost
                )}
              />
            </div>
          ))}

        {ratingData?.ratings?.length >= 2 && <SeeMore />}
      </>
    );
  };

  // * call API onMount
  // INITIALIZATION
  useLayoutEffect(() => {
    window.scrollTo({ top: 0 });

    // set default scroll direction to DOWN
    isScrollDown.current = true;
  }, []);

  // * handle scroll to show or hide store header
  useEffect(() => {
    const storeHeader = document.getElementById("store__head__main-content");
    const storeSlider = document.getElementById("store-detail-info-slider");

    let lastScrollY = 0;
    if (!props.isLoading) {
      const handleScrollPage = () => {
        // get the scroll direction info
        isScrollDown.current = lastScrollY < window.scrollY;

        lastScrollY = window.scrollY; // store current scrollY value

        // stuff to handle store header display
        // if (isSearching) {
        //   storeHeader.style.display = "block";
        //   storeHeader.style.opacity = 1;
        //   return;
        // }

        storeHeader.style.opacity =
          window.scrollY /
          (storeSlider.offsetHeight -
            storeHeader.offsetHeight -
            40 -
            15) /* 40px = khoảng trồi lên của store info card | 15px = khoảng trồi lên của merchantClass */;

        // set display sau khi update opacity để tránh hiện tượng chớp từ mờ -> rõ khi scroll xuống
        if (window.scrollY < 10) {
          storeHeader.style.display = "none";
        } else {
          storeHeader.style.display = "block";
        }
      };

      window.addEventListener("scroll", handleScrollPage);

      return () => {
        window.removeEventListener("scroll", handleScrollPage);
      };
    }
  }, [props.isLoading]);

  // * NAVIGATE
  const goBack = () => {
    if (
      history.location.state === undefined ||
      history?.location?.state?.prevPathname === "/StoreDetailMarket"
    ) {
      history.push("/");
      return;
    }
    history.goBack();
  };

  //* SHARE
  const handleShareStore = useCallback(
    async (url) => {
      if (navigator.share) {
        try {
          await navigator.share({
            title: "Chia sẻ liên kết của cửa hàng",
            text: `Bạn được giới thiệu trải nghiệm ${props.storeInfo?.name} trên ứng dụng Utop. Nhấn link bên dưới để đăng ký và nhận ưu đãi.\n${url}`,
          });
        } catch (err) {
          console.error(err);
        }
      } else {
        toast.error(`Không thể chia sẻ trên trình duyệt này`, toastOptions);
      }
    },
    [props.storeInfo?.name]
  );

  const handleShareStoreList = useCallback(
    async (dataStore, url) => {
      if (navigator.share) {
        try {
          await navigator.share({
            title: "Chia sẻ liên kết của cửa hàng",
            text: `Bạn được giới thiệu trải nghiệm ${dataStore?.name?.vi} tại chợ ${props.storeInfo?.name} trên ứng dụng Utop. Nhấn link bên dưới để đăng ký và nhận ưu đãi.\n${url}`,
          });
        } catch (err) {
          console.error(err);
        }
      } else {
        toast.error(`Không thể chia sẻ trên trình duyệt này`, toastOptions);
      }
    },
    [props.storeInfo?.name]
  );

  const handleGetShortLinkToShare = (type, dataStore) => {
    const payload = {};
    if (type === "market") {
      payload.callbackUrl = window.location.href;
      payload.imageUrl = props?.storeInfo?.logoUrl;
    } else {
      const arrayImages =
        dataStore?.images.length > 0
          ? dataStore?.images.filter((f) => f.isFeatured)
          : [];
      const imagesMerchant =
        arrayImages.length > 0 ? arrayImages[0].imageUrl : "";
      payload.callbackUrl = `${window.location.origin}/StoreDetailMarket?storeId=${props?.storeId}&merchantId=${props?.merchantId}&storeMerchantId=${dataStore.id}`;
      payload.imageUrl = imagesMerchant;
    }
    dispatch(
      getShortLinkToShare(
        { ...payload },
        (res) => {
          if (type === "market") {
            handleShareStore(res?.data?.url);
          } else {
            handleShareStoreList(dataStore, res?.data?.url);
          }
        },
        (err) => {
          console.log("errShare", err);
        }
      )
    );
  };

  // * GO TO DETAIL COUPON PAGE
  const handleGotoDetailCoupon = useCallback(
    (coupon) => () => {
      const paramsCoupon = {
        mode: COUPON_DETAIL_PAGE_MODES.ALLOCATE,
        productcode: coupon.couponCode,
        transactioncode: coupon.couponId,
        voucherId: coupon.couponId,
        productName: coupon.name,
        imageUrl: coupon.imageUrl,
        type: "GetCouponByUserId",
        status: coupon.isAllocated,
        appliedCoupon: {
          code: coupon.code,
          transactionCode: coupon.couponId,
          name: coupon.name,
        },
      };

      history.push(`/coupondetail?id=${coupon.couponId}`, {
        mode: COUPON_DETAIL_PAGE_MODES.ALLOCATE,
        callbackUrl: `${window.location.pathname}${window.location.search}`,
        prevPage: `${window.location.pathname}${window.location.search}`,
        transactionCode: coupon.transactionCode,
        isAllocated: coupon.isAllocated,
      });
    },
    []
  );

  // * POPUP TOGGLE FUNCTIONS
  const handleOpenPopupSuggestedCouponList = () => {
    setShowSuggestedCouponList(true);
  };
  const handleClosePopupSuggestedCouponList = () => {
    setShowSuggestedCouponList(false);
  };

  const handleOpenMenuOrder = (product, type) => () => {
    if (
      props.dataCart &&
      props.dataCart.length > 0 &&
      props.dataCart.filter((f) => f.dataInfoProduct?.productId === product?.id)
        .length > 0 &&
      !type
    ) {
      setChosenProduct(
        props.dataCart.filter(
          (f) => f.dataInfoProduct?.productId === product?.id
        )
      );
      setShowQuantityMenu(true);
    } else {
      setChosenProduct((prevChosenProduct) => {
        if (prevChosenProduct?.id === product.id) {
          return prevChosenProduct;
        }
        const found = product;

        return found;
      });
      setShowOrderMenu(true);
    }
  };

  const handleCloseMenuOrder = () => {
    setShowOrderMenu(false);
    setTimeout(() => {
      setChosenProduct(undefined);
    }, 500);
  };

  const handleCloseEditMenu = () => {
    if (showQuantityMenu) {
      setShowQuantityMenu(false);
    }
    setShowEditMenu(false);

    // set time out để tránh hiện tượng giật cục khi đóng popup
    // chú thích ở hàm handleCloseMenuOrder
    setTimeout(() => {
      setItemInCartToEdit(undefined); // must have if not want to get bug
    }, 400);
  };

  const handleCloseQuantityMenu = () => {
    setShowQuantityMenu(false);
  };

  const handleOpenEditMenu = (itemData) => () => {
    // setShowQuantityMenu(false);

    // bên trong hàm này có set lại itemsToUpdateQuantity về []
    // tránh được case:
    // đang mở QuantityMenu -> set quantity về 0 -> ấn nút "Thay đổi" để vào EditMenu -> tắt EditMenu -> mở lại QuantityMenu thì còn các items cũ (state cũ là 0)
    handleCloseQuantityMenu();

    const itemToEdit = props?.dataCart.find(
      (item) => item.dataInfoProduct?.index === itemData?.dataInfoProduct?.index
    );

    // const originalDataOfItemToEdit = genOriginalDataOfProductToStoreInCart(
    //   productList?.find((product) => product.id === itemToEdit.productId)
    // );

    setItemInCartToEdit({
      ...itemToEdit,
    });
    setShowEditMenu(true);
  };

  const handleTempSaveInfoOfItemToAdd = (itemData) => {
    itemToCreateNewCart.current = itemData;
  };

  const handleToggleModal = (state) => {
    setOpenModalCreateNewCart(state);
  };

  const calDiscount = (listPrice, price) => {
    let percentDiscount = 0;
    if (!!listPrice && !!price) {
      percentDiscount = 100 - Math.round((price / listPrice) * 100);
      if (percentDiscount === 100) {
        percentDiscount -= 1;
      }
    }
    return percentDiscount;
  };

  // *** RENDER UI
  const renderHighlightSection = useCallback(
    (data) => {
      if (props.isLoading) {
        return (
          <div className="product-section">
            <div className="best-seller__container product-section-child">
              <div className="label">
                <Skeleton width="10ch" />
              </div>

              <div className="products__container">
                <FoodItemSkeleton />
                <FoodItemSkeleton />
              </div>
            </div>

            <div className="action-buttons__container action-buttons__container--skeleton product-section-child">
              <Skeleton />
              <Skeleton />
            </div>
          </div>
        );
      }

      // create UI action button(s)

      let bestSellerList = [];

      // create UI best seller section
      if (data.length) {
        // rule: take 2 latest product theo lastUpdated
        bestSellerList = [...data].sort(
          (first, second) =>
            new Date(second.lastModified) - new Date(first.lastModified)
        );
      }

      return (
        <div>
          <div className="product-list_header">
            <div className="label">{STORE_VIEW_PRODUCT_SECTION}</div>
          </div>
          <div className="product-section-highlight">
            {bestSellerList.length > 0 && (
              <div className="best-seller__container product-section-child">
                <div
                  className="products__container"
                  ref={tagsContainerRef}
                  onScroll={handleTagsContainerScroll}
                >
                  {bestSellerList.map((product, index) => (
                    <div className="product-item" key={index}>
                      <FoodItem
                        key={product.id}
                        id={product.id}
                        code={product?.code}
                        type={FOOD_ITEM_TYPES.DETAILS} // must have
                        title={product?.name?.vi}
                        imgSrc={product?.image}
                        saleTagUrl={product?.saleTagUrl}
                        description={product?.description?.default?.vi}
                        shortDescription={product?.description?.short?.vi}
                        originalPrice={product?.price?.list}
                        currentPrice={product?.price?.default}
                        category={product?.category}
                        dataCart={props.dataCart}
                        data={product}
                        isStoreClosed={
                          !(
                            props.openingTimes.rangeTime === "in" ||
                            props.openingTimes.rangeTime === "all"
                          )
                        }
                        isSelling={product.isSelling}
                        isOutOfStock={product.isOutOfStock}
                        // navigateToSmartOrderMenu={navigateToSmartOrderMenu}
                        handleOpenMenuOrder={() => handleOpenMenuOrder(product)}
                        percentDiscount={calDiscount(
                          product?.price?.list,
                          product?.price?.default
                        )}
                      />
                    </div>
                  ))}
                  {[
                    BTN_STATE.SHOW_BUTTON_PREV,
                    BTN_STATE.SHOW_BOTH_BUTTONS,
                  ].includes(arrowButtonsState) && (
                    <img
                      // id="example"
                      src={SVG.arrowPrev}
                      alt="arrow prev"
                      className="btn-prev-scroll-home"
                      onClick={() =>
                        scrollHorizontal(-OFFSET_TO_SCROLL_PER_CLICK)
                      }
                    />
                  )}
                  {[
                    BTN_STATE.SHOW_BUTTON_NEXT,
                    BTN_STATE.SHOW_BOTH_BUTTONS,
                  ].includes(arrowButtonsState) && (
                    <img
                      src={SVG.arrowNext}
                      alt="arrow next"
                      className="btn-next-scroll-home"
                      onClick={() =>
                        scrollHorizontal(+OFFSET_TO_SCROLL_PER_CLICK)
                      }
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      );
    },
    [props.isLoading, props.dataCart, BTN_STATE, arrowButtonsState]
  );

  const SeeMoreProduct = useCallback(() => {
    const gotoProductAll = () => {
      if (props.openingTimes) {
        NavigationServices.navigate("/marketproduct", {
          type: "all",
          merchantId: props.storeInfo?.merchantId,
          name: "",
          storeId: props?.storeInfo?.id,
          storeInfo: props?.storeInfo,
          isCloseStore: !(
            props.openingTimes.rangeTime === "in" ||
            props.openingTimes.rangeTime === "all"
          ),
        });
      }
    };
    return (
      <div
        className="store-view__rating__header__text-header__right"
        onClick={gotoProductAll}
      >
        <span>Xem thêm</span>
        {/* <img src={SVG.ArrowRightYellow} alt="arrow" /> */}
        <ArrowRightYellowIcon />
      </div>
    );
  }, [props.storeInfo, props.openingTimes]);

  const renderProductList = useCallback(
    (data) => {
      if (props.isLoading) {
        return (
          <div className="product-section">
            <div className="best-seller__container product-section-child">
              <div className="label">
                <Skeleton width="10ch" />
              </div>

              <div className="products__container">
                <FoodItemSkeleton />
                <FoodItemSkeleton />
              </div>
            </div>

            <div className="action-buttons__container action-buttons__container--skeleton product-section-child">
              <Skeleton />
              <Skeleton />
            </div>
          </div>
        );
      }

      let bestSellerList = [];

      // create UI best seller section
      if (data.length) {
        // rule: take 2 latest product theo lastUpdated
        bestSellerList = [...data]
          .filter((f) => f.id)
          .sort(
            (first, second) =>
              new Date(second.lastModified) - new Date(first.lastModified)
          );
      }
      return (
        <div>
          <div className="product-list_header">
            <div className="label">{STORE_VIEW_PRODUCT_LIST}</div>
            {SeeMoreProduct()}
          </div>
          <div className="product-section-highlight">
            {bestSellerList.length > 0 && (
              <div className="best-seller__container product-section-child">
                <div
                  className="products__container"
                  ref={tagsContainerProductRef}
                  onScroll={handleTagsContainerScrollProduct}
                >
                  {bestSellerList.map((product, index) => (
                    <div className="product-item" key={index}>
                      <FoodItem
                        id={product.id}
                        code={product?.code}
                        type={FOOD_ITEM_TYPES.DETAILS} // must have
                        title={product?.name?.vi}
                        imgSrc={product?.image}
                        saleTagUrl={product?.saleTagUrl}
                        description={product?.description?.default?.vi}
                        shortDescription={product?.description?.short?.vi}
                        originalPrice={product?.price?.list}
                        currentPrice={product?.price?.default}
                        category={product?.category}
                        dataCart={props.dataCart}
                        data={product}
                        isSelling={product.isSelling}
                        isStoreClosed={
                          !(
                            props.openingTimes.rangeTime === "in" ||
                            props.openingTimes.rangeTime === "all"
                          )
                        }
                        isOutOfStock={product.isOutOfStock}
                        // navigateToSmartOrderMenu={navigateToSmartOrderMenu}
                        handleOpenMenuOrder={() => handleOpenMenuOrder(product)}
                        percentDiscount={calDiscount(
                          product?.price?.list,
                          product?.price?.default
                        )}
                      />
                    </div>
                  ))}
                  {[
                    BTN_STATE_PRODUCT.SHOW_BUTTON_PREV,
                    BTN_STATE_PRODUCT.SHOW_BOTH_BUTTONS,
                  ].includes(arrowButtonsStateProduct) && (
                    <img
                      // id="example"
                      src={SVG.arrowPrev}
                      alt="arrow prev"
                      className="btn-prev-scroll-home"
                      onClick={() =>
                        scrollHorizontalProduct(-OFFSET_TO_SCROLL_PER_CLICK)
                      }
                    />
                  )}
                  {[
                    BTN_STATE_PRODUCT.SHOW_BUTTON_NEXT,
                    BTN_STATE_PRODUCT.SHOW_BOTH_BUTTONS,
                  ].includes(arrowButtonsStateProduct) && (
                    <img
                      src={SVG.arrowNext}
                      alt="arrow next"
                      className="btn-next-scroll-home"
                      onClick={() =>
                        scrollHorizontalProduct(+OFFSET_TO_SCROLL_PER_CLICK)
                      }
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      );
    },
    [
      props.isLoading,
      props.dataCart,
      props.storeInfo,
      arrowButtonsStateProduct,
      BTN_STATE_PRODUCT,
    ]
  );

  const goToSearch = () => {
    if (props.openingTimes)
      NavigationServices.navigate("/marketsearch", {
        storeInfo: { ...props.storeInfo, storeId: props.storeInfo.id },
        dataStoreMerchant: props.dataStoreMerchant,
        isCloseStore: !(
          props.openingTimes.rangeTime === "in" ||
          props.openingTimes.rangeTime === "all"
        ),
      });
  };

  const renderLoaderOfUserLiked = useCallback(() => {
    if (
      !(
        (isLoading.GET_USER_LIKED_LIST_OF_RATING_POST ||
          isLoading.GET_USER_LIKED_LIST_OF_COMMENT) &&
        likedListParams.skip === 0
      )
    ) {
      return (
        <div className="people-liked-list">
          <div className="row-info">
            <Skeleton className="row-info__avatar" circle />
            <span className="row-info__name">
              <Skeleton width="23ch" />
            </span>
          </div>

          <div className="row-info">
            <Skeleton className="row-info__avatar" circle />
            <span className="row-info__name">
              <Skeleton width="23ch" />
            </span>
          </div>
        </div>
      );
    }
  }, [
    isLoading.GET_USER_LIKED_LIST_OF_RATING_POST,
    isLoading.GET_USER_LIKED_LIST_OF_COMMENT,
    likedListParams.skip === 0,
  ]);

  return (
    <div className="market-home-view-page">
      <div className="buttons-bar--fixed-top">
        <IconButton onClick={goBack} className="button-back--top-left">
          <i>
            <ArrowBackWhiteIcon />
          </i>
        </IconButton>
      </div>

      {!props.isLoading && (
        <div className="buttons-bar--fixed-top-right">
          <button
            type="button"
            className="button__cover-img--circle button__effect _ml-12px"
            onClick={goToSearch}
          >
            <img src={SVG.LoupeWhite} alt="loupe icon" width={13} height={13} />
          </button>
        </div>
      )}

      <div
        id="store__head__main-content"
        className="store-header__wrapper"
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          zIndex: chosenRatingPost ? 0 : 20,
          opacity: 0,
        }}
      >
        {!props.isLoading && (
          <>
            <StoreHeader
              idSearchInput="store-detail__store-header__search-input"
              storeName={props.storeInfo?.name}
              isSearching={false} // ! hard code, vì store view hiện tại ko có search
              goBack={goBack}
              goToSearch={goToSearch}
            />
            <CategoryList categories={tabsListOfStoreView} />
          </>
        )}
      </div>

      <div>
        <StoreInfo
          showSeeMore={props.showSeeMore}
          id={props?.storeInfo?.id}
          isLoading={props.isLoading}
          images={props.dataBanner}
          logoUrl={props.storeInfo?.logoUrl}
          merchantImages={props.storeInfo?.imagesMerchant}
          merchantClass={props.storeInfo?.merchantClass}
          name={props?.storeInfo?.name}
          avgRate={props.storeInfo?.avgRate ? props.storeInfo?.avgRate : 0}
          // avgPrice={storeInfo?.data?.avgPrice?.iv}
          openingTimes={props?.openingTimes}
          address={props?.storeInfo?.address}
          phoneNumber={props?.storeInfo?.phone}
          deliveryOptions={props?.storeInfo?.deliveryOptions}
          location={props?.storeInfo?.location}
          tags={props?.storeInfo?.tags}
          // selector
          suggestedCouponList={props?.listCouponSuggest}
          handleShareStore={() => handleGetShortLinkToShare("market")}
          handleOpenPopupSuggestedCouponList={
            handleOpenPopupSuggestedCouponList
          }
          handleSaveCoupon={props.allocateCoupon}
          handleGotoDetailCoupon={handleGotoDetailCoupon}
        />
      </div>
      {!props.isLoading &&
        props.listItemCategory &&
        props.listItemCategory.length > 0 && (
          <CategoryFilter
            gotoProduct={filterCategory}
            dataItemCategory={props.listItemCategory}
          />
        )}
      <div className="content">
        {props.dataProductRecent && props.dataProductRecent.length > 0
          ? renderHighlightSection(props.dataProductRecent)
          : null}
        {props.dataProduct && props.dataProduct.length > 0 && (
          <div id="store__head__list-product">
            {renderProductList(props.dataProduct)}
          </div>
        )}
        {props.dataListStore &&
          props.dataListStore.length > 0 &&
          !props.isLoading && (
            <div id="store__head__list-store">
              <StoreList
                goToSeeAll={goToAllStore}
                gotoProduct={gotoProductStore}
                dataListStore={props.dataListStore}
                handleShareStore={(dataStore) =>
                  handleGetShortLinkToShare("store", dataStore)
                }
              />
            </div>
          )}
        {/* <div id="store-view__rating" className="store-view__rating-layout">
          <div className="rating-content">{handleCheckRatedOfUser()}</div>
        </div> */}
        <div id="store-view__rating" className="store-view__rating-layout">
          {isLoading.GET_RATING_LIST ? (
            <Skeleton />
          ) : (
            <div className="rating-content">{handleCheckRatedOfUser()}</div>
          )}
        </div>
      </div>
      {!isDetailRatting && !chosenRatingPost && (
        <CartBottomBar
          // to="/order"
          eventHandle={() =>
            NavigationServices.navigate("/CartMarket", {
              isFromMarket: true,
              storeInfo: props.storeInfo,
            })
          }
          totalPrice={
            props?.dataCart.length > 0
              ? props?.dataCart
                  .map((i) => i.dataInfoProduct.totalPrice)
                  .reduce((a, b) => a + b)
              : 0
          }
          itemsCount={
            props?.dataCart.length > 0
              ? props?.dataCart
                  .map((i) => i.dataInfoProduct.quantity)
                  .reduce((a, b) => a + b)
              : 0
          }
          className="store-detail-page__cart-bottom-bar"
          text="Xem giỏ hàng"
          disabled={
            props.dataCart.length > 0 &&
            !(
              props.openingTimes.rangeTime === "in" ||
              props.openingTimes.rangeTime === "all"
            )
              ? 1
              : 0
          }
          // todo: check thêm khi ấn nút này, có thể bị phá khi mở devtool
        />
      )}

      {props.listCouponSuggest?.length > 0 && (
        <Popup
          isOpen={showSuggestedCouponList}
          title={
            <div className="text-center">
              <span className="color-ucla_gold _fs-15px _fw-600">
                Ưu đãi hiện có
              </span>
            </div>
          }
          handleClose={handleClosePopupSuggestedCouponList}
        >
          <div className="suggested-coupons">
            <div className="suggested-coupons__list">
              {props.listCouponSuggest?.map((coupon, index) => (
                <CouponItem
                  coupon={coupon}
                  key={index}
                  couponId={coupon?.couponId}
                  couponCode={coupon?.couponCode}
                  code={coupon?.code}
                  endDateTime={coupon?.endDateTime}
                  name={coupon?.name}
                  isAllocated={coupon?.isAllocated}
                  handleSave={props.allocateCoupon}
                  handleOnClick={handleGotoDetailCoupon(coupon)}
                />
              ))}
            </div>
          </div>
        </Popup>
      )}

      <Dock
        show={showUserLikedList}
        showBtnClose
        handleClose={handleCloseUserLikedList}
      >
        <LoadingOverlay
          isLoading={
            (isLoading.GET_USER_LIKED_LIST_OF_RATING_POST ||
              isLoading.GET_USER_LIKED_LIST_OF_COMMENT) &&
            likedListParams.skip === 0
          }
        />

        <div
          className="color-ucla_gold _fs-15px _fw-600 text-center text-1-line"
          style={{
            padding: "16px 40px 8px",
            lineHeight: "18px",
          }}
        >
          <span className="">Danh sách lượt thích</span>
        </div>

        <div
          className="people-liked-list__container"
          id="people-liked-list-popup"
        >
          <InfiniteScroll
            dataLength={userLikedData.list.length}
            next={handleFetchLikedList({
              id: likedListParams.id,
              type: likedListParams.type,
              take: likedListParams.take,
              skip: userLikedData.list.length,
            })}
            hasMore={userLikedData.hasMore}
            loader={renderLoaderOfUserLiked()}
            scrollableTarget="people-liked-list-popup"
          >
            <div className="people-liked-list">
              {!(
                (isLoading.GET_USER_LIKED_LIST_OF_RATING_POST ||
                  isLoading.GET_USER_LIKED_LIST_OF_COMMENT) &&
                likedListParams.skip === 0
              ) &&
                userLikedData.list.map((userLiked) => (
                  <div className="row-info" key={userLiked.id}>
                    <object
                      className="row-info__avatar"
                      data={userLiked.avatarUrl || IMAGES.AvatarPlaceholder}
                      type="image/png"
                    >
                      <img
                        className="row-info__avatar"
                        src={IMAGES.AvatarPlaceholder}
                        alt="ava"
                      />
                    </object>
                    <span className="row-info__name">{userLiked.name}</span>
                  </div>
                ))}
            </div>
          </InfiniteScroll>
        </div>
      </Dock>

      <DetailRatingPost
        show={!!chosenRatingPost}
        handleClose={handleCloseDetailRatingPost}
        ratingPost={chosenRatingPost}
        handleUnlikeCmt={handleUnlikeCmt}
        handleLikeCmt={handleLikeCmt}
        handleOpenUserLikedList={handleOpenUserLikedList}
        handleLikeSubCmt={handleLikeSubCmt}
        handleUnLikeSubCmt={handleUnLikeSubCmt}
        handleSeeAllRating={handleSeeAllRating}
        handleSubmitReply={handleSubmitReply}
      />

      <Popup
        isOpen={showOrderMenu}
        handleClose={handleCloseMenuOrder}
        // showButtonClose={false}
      >
        {chosenProduct && (
          <OrderMenu
            showOrderMenu={showOrderMenu}
            storeId={storeId}
            // merchantId={storeInfo?.data?.merchant?.iv?.[0]}
            id={chosenProduct?.id}
            // code={chosenProduct?.data?.code?.iv}
            imageUrl={chosenProduct?.image}
            imageUrls={chosenProduct?.imageUrls}
            name={chosenProduct?.name?.vi}
            price={chosenProduct?.price?.default}
            listPrice={chosenProduct?.price?.list}
            description={chosenProduct?.description?.default?.vi}
            attributes={chosenProduct.attributes}
            sizes={chosenProduct?.sizes}
            toppings={chosenProduct?.toppings}
            rawData={chosenProduct}
            // functions
            handleClose={handleCloseMenuOrder}
            updateCart={props.updateCart}
            handleTempSaveInfoOfItemToAdd={handleTempSaveInfoOfItemToAdd}
            handleToggleModal={handleToggleModal}
            isStoreClosed={
              !(
                props.openingTimes.rangeTime === "in" ||
                props.openingTimes.rangeTime === "all"
              )
            }
            itemCart={
              props?.dataCart.length > 0
                ? props?.dataCart[props?.dataCart.length - 1].dataInfoProduct
                    .index
                : 0
            }
            saleTagUrl={chosenProduct?.saleTagUrl}
            percentDiscount={calDiscount(
              chosenProduct?.price?.list,
              chosenProduct?.price?.default
            )}
          />
        )}
      </Popup>
      <Popup
        isOpen={showQuantityMenu}
        handleClose={handleCloseQuantityMenu}
        // showButtonClose={false}
      >
        {showQuantityMenu && chosenProduct && (
          <QuantityMenu
            // productId={itemsToUpdateQuantity?.[0].productId}
            items={chosenProduct}
            // * handle popups
            handleOpenMenuOrder={handleOpenMenuOrder}
            handleOpenEditMenu={handleOpenEditMenu}
            handleCloseQuantityMenu={handleCloseQuantityMenu}
            // * handle actions
            handleUpdateQuantityOfItem={props.updateProductCart}
            handleRemoveItemInCart={props.deleteProductCart}
          />
        )}
      </Popup>
      <Popup
        isOpen={showEditMenu}
        handleClose={handleCloseEditMenu}
        // showButtonClose={false}
      >
        {itemInCartToEdit && (
          <EditMenu
            showEditMenu={showEditMenu}
            itemId={itemInCartToEdit?.dataInfoProduct?.productId}
            chosenAttributes={itemInCartToEdit?.dataInfoProduct?.attributes}
            chosenToppings={itemInCartToEdit?.dataInfoProduct?.toppings}
            chosenSize={itemInCartToEdit?.dataInfoProduct?.sizeOption}
            chosenQuantity={itemInCartToEdit?.dataInfoProduct?.quantity}
            itemPrice={itemInCartToEdit?.data?.price?.default}
            // originalData

            itemData={itemInCartToEdit}
            id={itemInCartToEdit?.data?.id}
            code={itemInCartToEdit?.data?.code}
            imageUrl={itemInCartToEdit?.data?.image}
            imageUrls={itemInCartToEdit?.data?.imageUrls}
            name={itemInCartToEdit?.data?.name?.vi}
            price={itemInCartToEdit?.data?.price?.default}
            listPrice={itemInCartToEdit?.data?.price?.list}
            description={itemInCartToEdit?.data?.description?.default}
            sizes={itemInCartToEdit?.data?.sizes}
            attributes={itemInCartToEdit?.data?.attributes}
            toppings={itemInCartToEdit?.data?.toppings}
            handleClose={handleCloseEditMenu}
            handleCloseQuantityMenu={handleCloseQuantityMenu}
            handleUpdateItem={props.updateCart}
            updateCart={props.updateProductCart}
          />
        )}
      </Popup>

      <AlertAction
        isOpen={props?.dataAlertAction?.isShow}
        content={props?.dataAlertAction?.content}
        title={props?.dataAlertAction?.title}
        titleAccept={props?.dataAlertAction?.titleAccept}
        titleCancel={props?.dataAlertAction?.titleCancel}
        handleClose={props.handleClose}
        handleAccept={props?.handleAlertAction}
        dataItem={props?.dataAlertAction?.dataItem}
      />
    </div>
  );
};

export default MarketHome;
