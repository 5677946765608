import { APIUtils } from 'utils';
import { END_POINTS } from '@shared/constants/apiUrl';

export function getTotalPointApi() {
  return APIUtils.get(END_POINTS.URL_GET_UTOP_TOTAL);
}

export function getListCompaignApi() {
  return APIUtils.get(END_POINTS.URL_GET_LIST_CAMPAIN);
}

export function getAffDetailApi(params) {
  return APIUtils.get(
    END_POINTS.URL_GET_AFF_DETAIL + '?campaignid=' + params.campaignId,
  );
}

export function getAffHistoryApi(payload) {
  if (payload.method === 'POST') {
    return APIUtils.post(END_POINTS.URL_GET_AFF_HISTORY_RECEIVE, {
      body: {
        skipCount: payload.skip,
        maxResult: payload.maxResult,
      },
    });
  } else {
    return APIUtils.get(END_POINTS.URL_GET_AFF_HISTORY + payload.url);
  }
}
