/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import IMAGES from "assets/img";
import classNames from "classnames";
import React, { useMemo, useState } from "react";
import "./categoryFilter.scss";
// import classNames from "classnames";

const CategoryFilter = ({ gotoProduct, dataItemCategory }) => {
  const [errorImage, setErrorImage] = useState([]);
  const dataFormat = useMemo(() => {
    if (window.innerWidth > 175) {
      if (dataItemCategory.length < 8 && dataItemCategory.length > 4) {
        const arr1 = dataItemCategory.slice(0, 4);
        const arr2 = dataItemCategory.slice(
          arr1.length,
          dataItemCategory.length
        );
        return [arr1, arr2];
      }
      if (dataItemCategory.length >= 8) {
        const arr1 = dataItemCategory.slice(
          0,
          Math.ceil(dataItemCategory.length / 2)
        );
        const arr2 = dataItemCategory.slice(
          arr1.length,
          dataItemCategory.length
        );
        return [arr1, arr2];
      }
      const arr1 = dataItemCategory;
      return [arr1];
    }

    const arr1 = dataItemCategory;
    return [arr1];
  }, [dataItemCategory]);

  const checkErrorImage = (item) => {
    const arr = [...errorImage];
    arr.push(item.id);
    setErrorImage(arr);
  };

  const percentWidth = () => {
    if (dataItemCategory.length > 8) {
      return "22%";
    }
    return "25%";
  };

  return (
    <div className="category-wrapper">
      {dataItemCategory
        ? dataFormat.map((arr, index) => (
            <div
              key={index + 1}
              className={classNames(
                "category-row",
                dataFormat.length >= 2 && index === 0 && "category-padding"
              )}
            >
              {arr.map((item, index1) => {
                return (
                  <div
                    key={index1 + 1}
                    className="option"
                    style={{
                      maxWidth: percentWidth(),
                      flex: `1 0 ${percentWidth()}`,
                    }}
                    onClick={() => gotoProduct(item)}
                  >
                    {" "}
                    <img
                      className="kaka"
                      src={
                        errorImage.filter((f) => f === item.id).length > 0
                          ? IMAGES.IconProductTypeDefault
                          : item?.logoUrl || IMAGES.IconProductTypeDefault
                      }
                      // alt="Loading"
                      onError={() => checkErrorImage(item)}
                    />
                    <h3>{item?.name?.vi}</h3>
                  </div>
                );
              })}
            </div>
          ))
        : null}
    </div>
  );
};

export default React.memo(CategoryFilter);
