import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import "./categoryList.scss";

const CategoryList = (props) => {
  const { categories, style } = props;

  const listRef = useRef([]);
  const [selectedCategory, setSelectedCategory] = useState();
  const [clicked, setClicked] = useState(false);
  const clickTimeoutRef = useRef(null);

  const trackingScrollRef = useRef(); // debounce cho hàm selectCategory, không call selectCategory liên tục dẫn đến setState liên tục làm lag app, -> đặt quãng nghỉ khoảng 100ms

  // set default value (for visualization purpose)
  useEffect(() => {
    setSelectedCategory(categories[0]?.code);
  }, [categories]);

  useEffect(() => {
    if (clicked) {
      // if the last time is a click, not trackScrolling so we break here
      return () => {
        window.removeEventListener("scroll", trackScrolling);

        if (trackingScrollRef.current) {
          clearTimeout(trackingScrollRef.current);
        }
      };
    }

    const innerHeight = window.innerHeight - 73; // vùng user nhìn thấy trên màn hình (trừ bottom bar)

    function isScrolledIntoView(element) {
      return element?.offsetTop <= window.scrollY + innerHeight;
    }

    const invertedList = [...categories].reverse();

    const trackScrolling = () => {
      // eslint-disable-next-line no-restricted-syntax
      for (const category of invertedList) {
        const cateSection = document.getElementById(category.code);
        if (isScrolledIntoView(cateSection)) {
          if (trackingScrollRef.current) {
            clearTimeout(trackingScrollRef.current);
          }

          trackingScrollRef.current = setTimeout(() => {
            selectCategory(category.code);
          }, [100]);
          break;
        }
      }
    };

    window.addEventListener("scroll", trackScrolling);

    return () => window.removeEventListener("scroll", trackScrolling);
  }, [categories, clicked]);

  // scroll horizontally to active category
  const scrollToRef = (rect) => {
    const itemLength = rect.right - rect.left;
    const innerWidth = window.innerWidth - 16;
    const bufferSpace = (innerWidth - itemLength) / 2;
    const xMovement = rect.left - bufferSpace;
    const categoriesBar = document.getElementById("category-container");

    categoriesBar.scrollBy({
      left: xMovement,
      behavior: "smooth",
    });
  };

  /**
   * set the chosen category and scroll the active category to center of the bar
   */
  const selectCategory = (categoryCode) => {
    const rect = listRef.current?.[categoryCode]?.getBoundingClientRect();

    if (rect) {
      scrollToRef(rect);
    }

    setSelectedCategory(categoryCode); // set State
  };

  const scrollIntoCategorySection = (categoryCode) => {
    // const topOffset = document.getElementById(categoryCode).offsetTop;
    const element = document.getElementById(categoryCode);
    const storeHeader = document.getElementById("store__head__main-content");
    const specialCaseOffset =
      categoryCode === "store__head__main-content" ? 15 : 0;

    window.scrollTo({
      behavior: "smooth",
      top:
        element.offsetTop -
        storeHeader.offsetHeight -
        specialCaseOffset -
        8 /* margin top of each section */,
    });

    if (clickTimeoutRef.current) {
      clearTimeout(clickTimeoutRef.current);
    }

    clickTimeoutRef.current = setTimeout(() => {
      setClicked(false);
    }, 1000);
  };

  return (
    <div className="categories__container">
      <div
        id="category-container"
        className="horizontally-scrolled-items hide-x-scrollbar"
        style={style}
      >
        {categories.map((category) => (
          <div
            key={category.code}
            ref={(el) => {
              listRef.current[category.code] = el;
            }}
            onClick={() => {
              setClicked(true); // mark as clicked
              selectCategory(category.code);
              scrollIntoCategorySection(category.code);
            }}
            className="item-wrapper"
          >
            <div
              className={classNames(
                "item button__effect",
                category.code === selectedCategory && "item--active"
              )}
            >
              <span className="text-truncate">{category.name}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CategoryList;
