import React from "react";
// import { toVND } from "utils/currencyFormat";
import SVG from "assets/svg";
// import Skeleton from "react-loading-skeleton";
// import Popup from "views/common/component/Popup";
import classNames from "classnames";
import { shippingDiscount } from "../../utils";

import "./shippingInfo.scss";

function ShippingInfo(props) {
  return (
    <>
      <div className="list-delivery-discount hide-x-scrollbar">
        {props?.shippingDiscountList.map((item, index) => (
          <div key={`from${item?.amountFrom}_to${item?.amountTo}`}>
            <div className="row">
              <div
                className={classNames(
                  "_fw-400 _fs-15px col-11 only-2-lines",
                  shippingDiscount(
                    props?.totalPrice,
                    props?.shippingDiscountList
                  )?.currentIndex === index && "_fw-600"
                )}
              >
                {`Giảm ${Number(item.discountFee / 1000).toLocaleString(
                  "vi"
                )}K phí giao hàng cho đơn từ ${Number(
                  item.amountFrom / 1000
                ).toLocaleString("vi")}K`}
              </div>
              <div className="col-1 p-0" style={{ alignSelf: "center" }}>
                {shippingDiscount(
                  props?.totalPrice,
                  props?.shippingDiscountList
                )?.currentIndex === index && (
                  <img className="" src={SVG.Tick} alt="tick" />
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default ShippingInfo;
