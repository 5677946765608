import {
  SET_DEFAULT_ADDRESS_SUCCESS,
  GET_LIST_ADDRESS_BOOK_SUCCESS,
  REMOVE_ADDRESS_SUCCESS,
} from './constants';

const initialState = {
  addressBookList: [],
  removeAdderss: {},
  defaultAddress: {},
};

const addressBook = function (state = initialState, action) {
  switch (action.type) {
    case GET_LIST_ADDRESS_BOOK_SUCCESS: {
      return {
        ...state,
        addressBookList: action.addressBook.data,
      };
    }

    case REMOVE_ADDRESS_SUCCESS: {
      return {
        ...state,
        removeAddress: action.addressBook.data,
      };
    }

    case SET_DEFAULT_ADDRESS_SUCCESS: {
      return {
        ...state,
        defaultAddress: action.addressBook.data,
      };
    }

    default:
      return state;
  }
};

export default addressBook;
