import {callApi} from 'helpers/callApi';
import {fork, put, takeLatest, takeEvery} from 'redux-saga/effects';
import {
  getDetailInfoPackageApi,
  getListRecommendWithPackageApi,
} from '@shared/services/api/packageDetail';
import {
  getListRecommendWIthPackageFailed,
  getListRecommendWIthPackageSuccess,
  getPackageInfoFailed,
  getPackageInfoSuccess,
} from './actions';
import {GET_LIST_RECOMMEND_WITH_PACKAGE, GET_PACKAGE_INFO} from './constants';

export function* getPackageInfoSaga(action) {
  const {payload, onSuccess, onFailed} = action;
  try {
    const res = yield callApi(getDetailInfoPackageApi, payload);
    yield put(getPackageInfoSuccess());
    onSuccess?.(res?.data);
  } catch (err) {
    onFailed?.();
    yield put(getPackageInfoFailed(err));
  }
}
export function* getListRecommendWithPackageSaga(action) {
  const {skip, take, merchantId, newspaperId, onSuccess, onFailed} = action;
  try {
    const res = yield callApi(getListRecommendWithPackageApi, {
      skip,
      take,
      newspaperId,
      merchantId,
    });
    yield put(getListRecommendWIthPackageSuccess());
    onSuccess?.(res?.data);
  } catch (err) {
    onFailed?.();
    yield put(getListRecommendWIthPackageFailed(err));
  }
}

function* watchGetPackageInfo() {
  yield takeLatest(GET_PACKAGE_INFO.HANDLER, getPackageInfoSaga);
  yield takeLatest(
    GET_LIST_RECOMMEND_WITH_PACKAGE.HANDLER,
    getListRecommendWithPackageSaga,
  );
}

export default function* rootChild() {
  yield fork(watchGetPackageInfo);
}
