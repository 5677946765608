import {MARK_USED_VOUCHER_SUCCESS, MARK_USED_VOUCHER_FAILED} from './constants';

const initialState = {
  markUsed: null,
  errors: null,
};

const markUsedVoucherReducer = (state = initialState, action) => {
  const {payload} = action;

  switch (action.type) {
    case MARK_USED_VOUCHER_SUCCESS:
      return {
        ...state,
        markUsed: payload,
      };

    case MARK_USED_VOUCHER_FAILED:
      return {
        ...state,
        errors: payload,
      };

    default:
      return state;
  }
};

export default markUsedVoucherReducer;
