const root = 'marketProduct';
export const GET_LIST_PRODUCT_BY_CATEGORY_REQUEST =
  root + '/GET_LIST_PRODUCT_BY_CATEGORY_REQUEST';
export const GET_LIST_PRODUCT_BY_CATEGORY_SUCCESS =
  root + '/GET_LIST_PRODUCT_BY_CATEGORY_SUCCESS';
export const GET_LIST_PRODUCT_BY_CATEGORY_FAILED =
  root + '/GET_LIST_PRODUCT_BY_CATEGORY_FAILED';

export const GET_LIST_CATEGORY_REQUEST = root + '/GET_LIST_CATEGORY_REQUEST';
export const GET_LIST_CATEGORY_SUCCESS = root + '/GET_LIST_CATEGORY_SUCCESS';
export const GET_LIST_CATEGORY_FAILED = root + '/GET_LIST_CATEGORY_FAILED';
