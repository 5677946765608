import { genActions } from "utils/actionGenerator";
import * as TYPE from "./constants";

const actions = {
  GET_LIST_CITY: genActions(TYPE.GET_LIST_CITY),
  // GET_SEARCH_LIST: genActions(TYPE.GET_SEARCH_LIST),
  GET_LIST_VOUCHER: genActions(TYPE.GET_LIST_VOUCHER), // ! deprecated
  GET_LIST_FEED: genActions(TYPE.GET_LIST_FEED),
  GET_LIST_STORE_OF_MERCHANT: genActions(TYPE.GET_LIST_STORE_OF_MERCHANT),
  FILTER_VOUCHER_STORE: genActions(TYPE.FILTER_VOUCHER_STORE),
  GET_SHORTCUTS: genActions(TYPE.GET_SHORTCUTS),
  GET_SHORTCUTS_PERSONALIZED: genActions(TYPE.GET_SHORTCUTS_PERSONALIZED),
  INIT_STATE_LIST_FEED: genActions(TYPE.INIT_STATE_LIST_FEED),
};

export default actions;
