import { createSelector } from 'reselect'

export const select = (state) => state.smartMenu

export const getListProduct = createSelector(
  select,
  (state) => state.listProduct,
)

export const getDataStore = createSelector(select, (state) => {
  return state.dataStore
})

// export const getDataStore = createSelector(select, state => state.smartMenu ?
//   state.smartMenu.dataStore : [],
// );

export const getCart = (state) => state.smartMenu.listProduct

export const getListCouponSuggest = (state) => state.smartMenu.listCouponSuggest

export const listProductsByMerchantSelector = createSelector(
  select,
  (state) => state.listProductsByMerchant,
)

export const listOutOfStockProductsSelector = createSelector(
  select,
  (state) => state.listOutOfStockProducts,
)

export const listProductOriginSelector = createSelector(
  select,
  (state) => state.listProductsByMerchantOrigin,
)
export const checkCreateNewDelivery = (state) =>
  state.smartMenu.checkCreateNewDelivery
