import { combineReducers } from 'redux'
import newsfeed from '../newsfeed/reducer'
import loading from '../loading/reducer'
import gps from '../gps/reducer'
import Notification from '../notification/reducer'
import HistoryPoint from '../menuHistoryPoint/reducer'
import repaymentVoucher from '../repaymentVoucher/reducer'
import common from '../common/reducer'
import account from '../account/reducer'
import login from '../login/reducer'
import store from '../store/reducer'
import address from '../address/reducer'
import historyOrder from '../historyOrder/reducer'
import orderAddressFood from '../orderAddressFood/reducer'

import focusReducer from '../focus/reducers'
import bannerReducer from '../banner/reducers'
import searchReducer from '../search/reducers'
import channelReducer from '../channel/reducers'
import hashtagReducer from '../hashtag/reducers'
import videoListReducer from '../videoList/reducer'
import profileReducer from '../profile/reducers'
import listFavoriteReducer from '../listFavorite/reducers'
import userInfoReducer from '../userInfoReducer/reducer'
import uploadVideoReducer from '../uploadVideo/reducers'
import myVideoReducer from '../myVideo/reducers'
import listFavoriteHashtagReducer from '../listFavoriteHashtag/reducers'

import { persistReducer } from 'redux-persist'
import LocalStorage from 'utils/localStorage'
import listSearchStoreReducer from '../searchStore/listSearchStore/reducer'
import listSearchHistoryReducer from '../searchStore/listSearchHistory/reducer'
import myVoucherReducer from '../myVoucher/myVoucherList/reducer'
import myVoucherDetailReducer from '../myVoucher/myVoucherDetail/reducer'
import markUsedVoucherReducer from '../myVoucher/markUsedVoucher/reducer'
import couponForVoucher from '../couponForVoucher/reducer'
import voucher from '../voucher/reducer'
import cart from '../cart/reducer'
import error from '../error/reducer'
import pendingOrderHome from '../pendingOrderHome/reducer'
import storeDetail from '../storeDetail/reducer'
import smartMenu from '../smartMenu/reducer'
import orderStatus from '../OrderStatus/reducer'
import utopDeliveryAddressBook from '../utopDeliveryAddressBook/reducer'
import videoDynamic from '../video/reducer'
import payment from '../payment/reducer'
import navigation from '../navigation/reducer'

import mobileCard from '../mobileCard/reducer'
import scheme from '../scheme/reducer'
import historyRoute from '../historyRoute/reducer'
import coupon from '../coupon/reducer'
import commonNotInit from '../commonNotInit/reducer'
import business from '../business/reducer'
import budget from '../budget/reducer'
import qrpay from '../qrpay/reducer'

import orderComment from '../orderComment/reducer'
import { createTransform } from 'redux-persist'
import appVersionReducer from '../appVersion/reducer'
import paymentHub from '../paymentHub/reducer'
import trackingReducer from '@shared/redux/tracking/reducer'
import utopback from '@shared/redux/utopback/reducer'
import referral from '../referral/reducer'
import point from '../point/reducer'
import businessBooking from '../businessBooking/reducer'
import inputFormGetCustomerInfo from '@shared/redux/customerInfo/reducer'
import marketProduct from '@shared/redux/market/marketProduct/reducer'
import market from '@shared/redux/market/marketHome/reducer'
import marketReducer from '@shared/redux/market/marketSearch/reducer'
import marketStore from '@shared/redux/market/marketStore/reducer'
import popupExpiredReducer from '../popupExpired/reducer'
import auth from '@shared/redux/auth/reducer'
import getPackageInfoReducer from '@shared/redux/packageInfo/reducer'
import listNewsBoughtReducer from '@shared/redux/listNews/reducer'
import Platform from 'helpers/platform'
import others from '@redux/others/reducers'
// import storage from 'redux-persist/lib/storage'

// console.log('importAllStore', storeWeb(), storeWeb().reducerMap)
// console.log('Platform.OS', Platform.OS, others)
// const CurrentLocalStorage = storage
const CurrentLocalStorage = LocalStorage.persist
const newsfeedTransform = createTransform((inboundState, key) => {
  if (key === 'listNewsfeedByLocation') {
    return { ...inboundState, data: inboundState.data.slice(0, 20) }
  }
  return inboundState
})

const newsfeedPersistConfig = {
  key: 'newsfeed',
  storage: CurrentLocalStorage,
  transforms: [newsfeedTransform],
}

const popupExpiredPersistConfig = {
  key: 'popupExpired',
  storage: CurrentLocalStorage,
}

const authPersistConfig = {
  key: 'auth',
  storage: CurrentLocalStorage,
}

const schemePersistConfig = {
  key: 'scheme',
  storage: CurrentLocalStorage,
  whitelist: ['additionalDataSchema', 'campaignDataSchema'],
}

const notificationTransform = createTransform((inboundState, key) => {
  if (key === 'listNotification') {
    return inboundState.slice(0, 50)
  }
  return inboundState
})

const HistoryPointTransform = createTransform((inboundState, key) => {
  if (key === 'listHistoryPoint' && typeof inboundState !== 'undefined') {
    return inboundState.slice(0, 20)
  }
  return inboundState
})

const commonPersistConfig = {
  key: 'common',
  storage: CurrentLocalStorage,
  whitelist: ['listBanner', 'listShortcut', 'googleMapApiKey'],
}

const cartPersistConfig = {
  key: 'cart',
  storage: CurrentLocalStorage,
  blacklist: ['listProduct', 'allowcationCoupon', 'messageError'],
}

const gpsPersistConfig = {
  key: 'gps',
  storage: CurrentLocalStorage,
}

const pointPersistConfig = {
  key: 'point',
  storage: CurrentLocalStorage,
}

const NotificationPersistConfig = {
  key: 'notification',
  storage: CurrentLocalStorage,
  transforms: [notificationTransform],
}

const HistoryPointPersistConfig = {
  key: 'historyPoint',
  storage: CurrentLocalStorage,
  transforms: [HistoryPointTransform],
}

const accountPersistConfig = {
  key: 'account',
  storage: CurrentLocalStorage,
}

const listSearchHistoryPersistConfig = {
  key: 'searchHistory',
  storage: CurrentLocalStorage,
}
const paymentPersistConfig = {
  key: 'payment',
  storage: CurrentLocalStorage,
}

const listMyVoucherPersistConfig = {
  key: 'myVoucher',
  storage: CurrentLocalStorage,
}

const addressBookListPersistConfig = {
  key: 'addressBook',
  storage: CurrentLocalStorage,
}

const pendingOrderPersistConfig = {
  key: 'pendingOrderHome',
  storage: CurrentLocalStorage,
}

const smartMenuPersistConfig = {
  key: 'smartMenu',
  storage: CurrentLocalStorage,
}

const listMobileCardPersistConfig = {
  key: 'mobileCard',
  storage: CurrentLocalStorage,
}

const commonNotInitPersistConfig = {
  key: 'commonNotInitPersistConfig',
  storage: CurrentLocalStorage,
}
const qrpayPersistConfig = {
  key: 'qrpayPersistConfig',
  storage: CurrentLocalStorage,
}

const businessPersistConfig = {
  key: 'businessPersistConfig',
  storage: CurrentLocalStorage,
  blacklist: ['filterStore'],
}

const budgetPersistConfig = {
  key: 'budgetPersistConfig',
  storage: CurrentLocalStorage,
  whitelist: ['listAccountBudget'],
}

const appVersionPersistConfig = {
  key: 'appVersion',
  storage: CurrentLocalStorage,
  whitelist: ['isMentionToUpdate', 'latestVersion'],
}
const businessBookingPersistConfig = {
  key: 'businessBookingPersistConfig',
  storage: CurrentLocalStorage,
  blacklist: ['filterStore'],
}

const inputFormGetCustomerInfoPersistConfig = {
  key: 'inputFormGetCustomerInfo',
  storage: CurrentLocalStorage,
  blacklist: ['data'],
}

const marketPersistConfig = {
  key: 'market',
  storage: CurrentLocalStorage,
  blacklist: ['listProductSearchMarket', 'productDetailMarket'],
}

const marketStorePersistConfig = {
  key: 'marketStore',
  storage: CurrentLocalStorage,
  blacklist: ['listProductSearchMarket', 'productDetailMarket'],
}

const rootReducer = combineReducers({
  newsfeed: persistReducer(newsfeedPersistConfig, newsfeed),
  loading,
  gps: persistReducer(gpsPersistConfig, gps),
  notification: persistReducer(NotificationPersistConfig, Notification),
  historyPoint: persistReducer(HistoryPointPersistConfig, HistoryPoint),
  cart: persistReducer(cartPersistConfig, cart),
  repaymentVoucher,
  common: persistReducer(commonPersistConfig, common),
  account: persistReducer(accountPersistConfig, account),
  payment: persistReducer(paymentPersistConfig, payment),
  listSearchStoreReducer,
  listSearchHistory: persistReducer(
    listSearchHistoryPersistConfig,
    listSearchHistoryReducer,
  ),
  store,
  address,
  historyOrder,
  myVoucher: persistReducer(listMyVoucherPersistConfig, myVoucherReducer),
  myVoucherDetailReducer,
  markUsedVoucherReducer,
  couponForVoucher,
  voucher,
  error,
  pendingOrderHome: persistReducer(pendingOrderPersistConfig, pendingOrderHome),
  storeDetail,
  orderAddressFood,
  smartMenu: persistReducer(smartMenuPersistConfig, smartMenu),
  orderStatus,
  utopDeliveryAddressBook: persistReducer(
    addressBookListPersistConfig,
    utopDeliveryAddressBook,
  ),
  login,
  videoDynamic,
  mobileCard: persistReducer(listMobileCardPersistConfig, mobileCard),
  scheme: persistReducer(schemePersistConfig, scheme),
  historyRoute,
  coupon,
  commonNotInit: persistReducer(commonNotInitPersistConfig, commonNotInit),
  paymentHub,
  business: persistReducer(businessPersistConfig, business),
  budget: persistReducer(budgetPersistConfig, budget),
  qrpay: persistReducer(qrpayPersistConfig, qrpay),
  orderComment,
  appVersion: persistReducer(appVersionPersistConfig, appVersionReducer),
  tracking: trackingReducer,
  navigation,
  utopback,
  referral,
  point: persistReducer(pointPersistConfig, point),
  businessBooking: persistReducer(
    businessBookingPersistConfig,
    businessBooking,
  ),
  inputFormGetCustomerInfo: persistReducer(
    inputFormGetCustomerInfoPersistConfig,
    inputFormGetCustomerInfo,
  ),
  marketReducer: persistReducer(marketPersistConfig, marketReducer),
  marketProduct,
  market,
  marketStore: persistReducer(marketStorePersistConfig, marketStore),
  popupExpired: persistReducer(popupExpiredPersistConfig, popupExpiredReducer),
  auth: persistReducer(authPersistConfig, auth),
  packageInfo: getPackageInfoReducer,
  listNewsBought: listNewsBoughtReducer,
  focusReducer,
  bannerReducer,
  searchReducer,
  channelReducer,
  hashtagReducer,
  videoListReducer,
  listFavoriteReducer,
  userInfoReducer,
  uploadVideoReducer,
  profileReducer,
  myVideoReducer,
  listFavoriteHashtagReducer,
  ...others,
  // ...storeWeb().reducerMap,
})

export default rootReducer
