import { put, takeLatest, fork, select } from 'redux-saga/effects'
import { LIST_SEARCH_STORE_REQUEST } from './constants'
import { listSearchStoreFailed, listSearchStoreSuccess } from './actions'
import { callApi } from 'helpers/callApi'
import {
  APIUtils,
  // checkLocationPermission,
} from 'utils'
import { getLatLongSelector } from '@shared/redux/gps/selectors'
import { getDistance } from '@shared/helpers/geolib'
import { getOpenTime } from '@shared/utils'

async function listSearchStoreAPI({ skip, searchtext, location }) {
  const params = {
    skip,
    searchtext,
    lat: location.selectedLocation.latitude,
    long: location.selectedLocation.longitude,
  }
  return APIUtils.get(global.apiLinks.searchFeed, { params })
}

export function* listSearchStoreSaga(obj) {
  const { onSuccess, onFailed } = obj.payload
  try {
    const data = yield callApi(listSearchStoreAPI, obj.payload)
    let location = yield select(getLatLongSelector)
    // let isAllowLocationPermission = yield checkLocationPermission();
    let temp = []
    if (data.status === 200) {
      data.data.value.map((item) => {
        item.availableTime = ''
        item.distance = ''

        item.images.iv.forEach((image) => {
          if (image.isFeatured) {
            item.isFeatured = true
          }
        })
        // var weekDay = '';
        item.isOpening = false
        item.openTime = getOpenTime(item.openingTimes)
        item.distance = ''

        if (item.location) {
          // if (isAllowLocationPermission) {
          if (location) {
            let distance = getDistance(
              {
                latitude: item.location.coordinates[1],
                longitude: item.location.coordinates[0],
              },
              {
                latitude:
                  obj.payload.location.currentLocation?.latitude ||
                  obj.payload.location?.selectedLocation.latitude,
                longitude:
                  obj.payload.location.currentLocation?.longitude ||
                  obj.payload.location?.selectedLocation.longitude,
              },
            )
            if (distance >= 1000) {
              item.distance =
                Math.round((distance / 1000 + Number.EPSILON) * 10) / 10
            } else {
              item.distance =
                Math.round((distance / 1000 + Number.EPSILON) * 100) / 100
            }
          }
          // }
        }
        item.img = []
        item.images.iv.forEach((element, index) => {
          if (element.isFeatured) {
            item.featuredImg = element.imageUrl
          } else {
            item.img.push(element.imageUrl)
          }
        })

        item.price = {}
        item.prices?.iv.forEach((element, index) => {
          if (element.default) {
            item.price.default = element.price
            item.price.listPrice = element.listPrice
          }
        })
        temp.push(item)
      })

      yield put(listSearchStoreSuccess([...obj.payload.list, ...temp]))
      if (typeof onSuccess === 'function') {
        onSuccess()
      }
    } else {
      if (typeof onFailed === 'function') {
        onFailed()
      }
      yield put(listSearchStoreFailed(data))
    }
  } catch (err) {
    if (typeof onFailed === 'function') {
      onFailed()
    }
    yield put(listSearchStoreFailed(err))
  }
}

function* watchListSearchStore() {
  yield takeLatest(LIST_SEARCH_STORE_REQUEST, listSearchStoreSaga)
}

export default function* rootChild() {
  yield fork(watchListSearchStore)
}
