export const GET_SUPPORTED_VERSION_REQUEST =
  'appVersion/GET_SUPPORTED_VERSION_REQUEST';

export const GET_SUPPORTED_VERSION_SUCCESS =
  'appVersion/GET_SUPPORTED_VERSION_SUCCESS';

export const GET_SUPPORTED_VERSION_FAILED =
  'appVersion/GET_SUPPORTED_VERSION_FAILED';

export const UPDATE_IS_MENTION_TO_UPDATE_REQUEST =
  'appVersion/UPDATE_IS_MENTION_TO_UPDATE_REQUEST';

export const UPDATE_IS_MENTION_TO_UPDATE_SUCCESS =
  'appVersion/UPDATE_IS_MENTION_TO_UPDATE_SUCCESS';

export const UPDATE_IS_MENTION_TO_UPDATE_FAILED =
  'appVersion/UPDATE_IS_MENTION_TO_UPDATE_HANDLE';

export const UPDATE_IS_FORCE_TO_UPDATE_REQUEST =
  'appVersion/UPDATE_IS_FORCE_TO_UPDATE_REQUEST';

export const UPDATE_IS_FORCE_TO_UPDATE_SUCCESS =
  'appVersion/UPDATE_IS_FORCE_TO_UPDATE_SUCCESS';

export const UPDATE_IS_FORCE_TO_UPDATE_FAILED =
  'appVersion/UPDATE_IS_FORCE_TO_UPDATE_FAILED';

export const UNSUPPORTED_VERSION_FAILED =
  'appVersion/UNSUPPORTED_VERSION_FAILED';
