import React from "react";
import { LoadingScreen } from "views/common/component";
import loadable from "@loadable/component";
// import Loading from "views/common/component/Loading";
import ListStoreMarket from "views/pages/Market/ListStoreMarket/ListStoreMarket.container";
import MarketHomeView from "views/pages/Market/MarketHomeView/MarketHomeView.container";
import MarketProductView from "views/pages/Market/MarketProduct/MarketProduct.container";
import StoreDetailMarket from "views/pages/Market/StoreDetailMarket/StoreDetailMarket.container";
import MarketSearch from "views/pages/Market/MarketSearch/MarketSearch.container";
import CartMarket from "views/pages/Market/CartMarket/CartMarket.container";
import SearchStoreMarket from "views/pages/Market/SearchStoreMarket/SearchStoreMarket.container";
import PromotionV2 from "views/pages/PromotionV2/PromotionV2.container";
import MarketImages from "views/pages/Market/MarketImages/MarketImages.container";
import OrderAddressFood from "views/pages/OrderAddressFood/OrderAddressFood.container";
import CouponDetailV3 from "views/pages/CouponDetail/CouponDetailV3";

const routes = [
  {
    path: "/login",
    name: "Đăng nhập",
    page: "Login",
    exact: true,
    authority: false,
  },

  // * --- start: voucher
  {
    path: "/voucherview",
    name: "Thông tin voucher",
    page: "VoucherView",
    exact: true,
    authority: false,
  },

  {
    path: "/vouchercart",
    name: "Giỏ hàng voucher",
    page: "VoucherCart",
    exact: true,
    authority: false,
  },

  {
    path: "/myvoucher",
    name: "Voucher của tôi", // todo: sửa tên
    page: "MyVoucher",
    exact: true,
    authority: true,
  },
  {
    path: "/myvoucherdetails",
    name: "Chi tiết voucher của tôi",
    page: "MyVoucherDetails",
    exact: true,
    authority: true,
  },
  {
    path: "/voucherdetail",
    name: "MyVoucherDetailMore", // todo: sửa tên
    page: "MyVoucherDetailMore",
    exact: true,
    authority: true,
  },
  {
    path: "/promotionvoucher",
    name: "PromotionVoucher", // todo: sửa tên
    page: "PromotionVoucher",
    exact: true,
    authority: true,
  },
  // {
  //   path: "/paymentstatus",
  //   name: "Thông tin giao dịch voucher",
  //   page: "PaymentStatus",
  //   exact: true,
  //   authority: true,
  // },
  // {
  //   path: "/allowmarkrefund",
  //   name: "AllowMarkRefund", // todo: sửa tên
  //   page: "AllowMarkRefund",
  //   exact: true,
  //   authority: true,
  // },
  // --- end: voucher

  // * --- start: FNB
  {
    path: "/order",
    name: "Giỏ hàng",
    page: "Order",
    exact: true,
    // authority: true,
    authority: false,
  },
  {
    path: "/promotion",
    name: "Mã ưu đãi",
    page: "Promotion",
    exact: true,
    authority: true,
  },
  {
    path: "/promotionv2",
    name: "Mã ưu đãi",
    page: PromotionV2,
    exact: true,
    authority: true,
  },
  {
    path: "/ratingstore",
    name: "Đánh giá",
    page: "RatingStore",
    exact: true,
    authority: true,
  },
  {
    path: "/storedetail",
    name: "Cửa hàng",
    page: "StoreDetail",
    exact: true,
    authority: false,
  },
  {
    path: "/storeview",
    name: "Thông tin cửa hàng",
    page: "StoreView",
    exact: true,
    authority: false,
  },
  {
    path: "/listdeal",
    name: "Danh sách Deal/Voucher",
    page: "ListDeal",
    exact: true,
    authority: false,
  },
  {
    path: "/rating",
    name: "Rating",
    page: "RatingPage",
    exact: true,
    authority: true,
  },
  // --- end: FNB

  // * --- start: home + other features
  {
    path: "/",
    name: "Utop - Ứng dụng đa tính năng cho cuộc sống tiện ích.",
    page: "Voucher",
    exact: true,
    authority: false,
  },
  {
    path: "/listfeed",
    name: "Danh sách cửa hàng", // todo: sửa tên
    page: "ListFeed",
    exact: true,
    authority: false,
  },
  {
    path: "/userinfo",
    name: "Thông tin tài khoản",
    page: "UserInfo",
    exact: true,
    authority: true,
  },

  {
    path: "/search",
    name: "Tìm kiếm",
    page: "Search",
    exact: true,
    authority: false,
  },
  {
    path: "/transactionhistory",
    name: "Danh sách đơn hàng",
    page: "TransactionHistory",
    exact: true,
    authority: true,
  },
  {
    path: "/deliveryinfomations",
    name: "Thông tin đơn hàng", // thông tin đơn hàng FNB
    page: "DeliveryInfomations",
    exact: true,
    authority: true,
  },
  {
    path: "/cancelorder",
    name: "Lý do huỷ đơn",
    page: "CancelOrder",
    exact: true,
    authority: true,
  },
  {
    path: "/deliverydetail",
    name: "Thông tin đơn hàng voucher", // thông tin đơn hàng voucher
    page: "DeliveryDetail",
    exact: true,
    authority: true,
  },

  // * map pages
  {
    path: "/deliveryaddress",
    name: "Địa chỉ",
    page: "DeliveryAddress",
    exact: true,
    authority: false,
    icon: "cilLocationPin",
  },
  {
    path: "/mapdirection",
    name: "Hướng dẫn đường đi",
    page: "MapDirection",
    exact: true,
    authority: false,
  },
  {
    path: "/setdeliveryinfo",
    name: "Thông tin nhận hàng",
    page: "SetDeliveryInfo",
    exact: true,
    authority: false,
  },
  {
    path: "/OrderAddressFood",
    name: "Địa chỉ nhận hàng",
    page: OrderAddressFood,
    exact: true,
    authority: false,
  },
  {
    path: "/markposition",
    name: "Chọn địa chỉ",
    page: "MarkPosition",
    exact: true,
    authority: false,
  },
  {
    path: "/savedaddress/:updateMode",
    name: "Địa chỉ",
    page: "SavedAddressPage",
    exact: true,
    authority: true,
  },
  // --- end: other features

  // * --- start: shared pages
  {
    path: "/coupondetail",
    name: "Chi tiết coupon",
    page: "CouponDetail",
    exact: true,
    authority: false,
  },
  {
    path: "/coupondetailv2",
    name: "Chi tiết coupon",
    page: CouponDetailV3,
    exact: true,
    authority: false,
  },
  {
    path: "/applicablestores",
    name: "Cửa hàng áp dụng",
    page: "ApplicableStores",
    exact: true,
    authority: true,
  },
  // {
  //   path: "/storeapplycoupons", // ? khong biet page nay dung de lam gi
  //   name: "ListStoreApplyCounpons", // todo: sửa tên
  //   page: "ListStoreApplyCounpons",
  //   exact: true,
  //   authority: true,
  // },
  {
    path: "/liststoreapply", // ? khong biet page nay dung de lam gi
    name: "ListStoreApply", // todo: sửa tên
    page: "ListStoreApply",
    exact: true,
    authority: true,
  },
  {
    path: "/transactionmethods/:orderCode",
    name: "Phương thức thanh toán",
    page: "TransactionMethods",
    exact: true,
    authority: true,
  },
  {
    path: "/transactionresult/:paymentMethodCode",
    name: "Thông tin giao dịch",
    page: "TransactionResult",
    exact: true,
    authority: true,
  },
  // --- end: shared pages

  // * --- start: topup + mobi card
  {
    path: "/mytelcocard", // Chi tiết voucher: hiện mã thẻ & số seri để nạp ngay
    name: "MyTelcoCard", // todo: sửa tên
    page: "MyTelcoCard",
    exact: true,
    authority: true,
  },
  // choose voucher to send
  {
    path: "/choosevoucher",
    name: "Chọn Voucher",
    page: "ChooseVoucher",
    exact: true,
    authority: true,
  },
  // sendvoucher to friend
  {
    path: "/sendvoucher",
    name: "Gửi tặng bạn bè",
    page: "SendVoucher",
    exact: true,
    authority: true,
  },
  // landing page
  {
    path: "/landingpage", // Chi tiết voucher: hiện mã thẻ & số seri để nạp ngay
    name: "Landing Page", // todo: sửa tên
    page: "LandingPage",
    exact: true,
    authority: true,
  },
  {
    path: "/marketproduct",
    name: "Danh sách sản phẩm",
    page: MarketProductView,
    exact: true,
    authority: false,
  },
  {
    path: "/storedetailmarket",
    name: "Thông tin cửa hàng",
    page: StoreDetailMarket,
    exact: true,
    authority: false,
  },
  {
    path: "/markethome",
    name: "Thông tin chợ",
    page: MarketHomeView,
    exact: true,
    authority: false,
  },
  {
    path: "/liststoremarket",
    name: "Danh sách cửa hàng",
    page: ListStoreMarket,
    exact: true,
    authority: false,
  },
  {
    path: "/marketsearch",
    name: "Tìm kiếm",
    page: MarketSearch,
    exact: true,
    authority: false,
  },
  {
    path: "/searchstoremarket",
    name: "Tìm kiếm cửa hàng",
    page: SearchStoreMarket,
    exact: true,
    authority: false,
  },
  {
    path: "/CartMarket",
    name: "Giỏ hàng",
    page: CartMarket,
    exact: true,
    authority: false,
  },
  {
    path: "/marketimages",
    name: "Giỏ hàng",
    page: MarketImages,
    exact: true,
    authority: false,
  },
  // {
  //   path: "/paymenttopup",
  //   name: "PaymentTopUp", // todo: sửa tên
  //   page: "PaymentTopUp",
  //   exact: true,
  //   authority: true,
  //   _tag: "CSidebarNavItem",
  //   icon: "cil-calendar",
  // },
  // {
  //   path: "/topupcard", // Trang "Nạp thẻ điện thoại", dùng để mua điểm hoặc mua thẻ cào
  //   name: "TopUpCard", // todo: sửa tên
  //   page: "TopUpCard",
  //   exact: true,
  //   authority: false,
  //   _tag: "CSidebarNavItem",
  //   icon: "cil-calendar",
  // },
  // {
  //   path: "/payment", // Thanh toán, thanh toán mua thẻ hoặc topup
  //   name: "Payment", // todo: sửa tên
  //   page: "Payment",
  //   exact: true,
  //   authority: false,
  //   _tag: "CSidebarNavItem",
  //   icon: "cil-calendar",
  // },
  // * --- end: topup + mobi card

  // * deleted
  // {
  //   path: "/register",
  //   name: "Đăng ký", // todo: sửa tên
  //   page: "Register", // ! xóa folder
  //   exact: true,
  //   authority: true,
  //   _tag: "CSidebarNavItem",
  //   icon: "cil-calendar",
  // },
  // {
  //   path: "/history",
  //   name: "Lịch sử", // todo: sửa tên
  //   page: "History", // ! xóa folder
  //   exact: true,
  //   authority: true,
  //   _tag: "CSidebarNavItem",
  //   icon: "cil-calendar",
  // },
  // {
  //   path: "/topup",
  //   name: "Nạp điểm", // todo: sửa tên
  //   page: "Topup", // ! xóa folder
  //   exact: true,
  //   authority: true,
  //   _tag: "CSidebarNavItem",
  //   icon: "cil-calendar",
  // },
  // {
  //   path: "/card",
  //   name: "Card", // todo: sửa tên
  //   page: "Card", // ! xóa folder
  //   exact: true,
  //   authority: true,
  //   _tag: "CSidebarNavItem",
  //   icon: "cil-calendar",
  // },
  // {
  //   path: "/cardqr",
  //   name: "CardQR", // todo: sửa tên
  //   page: "CardQR", // ! xóa folder
  //   exact: true,
  //   authority: true,
  //   _tag: "CSidebarNavItem",
  //   icon: "cil-calendar",
  // },
  // {
  //   path: "/cardswipe",
  //   name: "CardSwipe", // todo: sửa tên
  //   page: "CardSwipe", // ! xóa folder
  //   exact: true,
  //   authority: true,
  //   _tag: "CSidebarNavItem",
  //   icon: "cil-calendar",
  // },
  // {
  //   path: "/cardcomfirm",
  //   name: "CardComfirm", // todo: sửa tên
  //   page: "CardComfirm", // ! xóa folder
  //   exact: true,
  //   authority: true,
  //   _tag: "CSidebarNavItem",
  //   icon: "cil-calendar",
  // },
  // {
  //   path: "/cardsuccess",
  //   name: "CardSuccess", // todo: sửa tên
  //   page: "CardSuccess", // ! xóa folder
  //   exact: true,
  //   authority: true,
  //   _tag: "CSidebarNavItem",
  //   icon: "cil-calendar",
  // },
  // {
  //   path: "/topupsuccess",
  //   name: "TopupSuccess", // todo: sửa tên
  //   page: "TopupSuccess", // ! xóa folder
  //   exact: true,
  //   authority: true,
  //   _tag: "CSidebarNavItem",
  //   icon: "cil-calendar",
  // },
  // {
  //   path: "/checkbalance",
  //   name: "CheckBalance", // todo: sửa tên
  //   page: "CheckBalance", // ! xóa folder
  //   exact: true,
  //   authority: true,
  //   _tag: "CSidebarNavItem",
  //   icon: "cil-calendar",
  // },
  // {
  //   path: "/registersuccess",
  //   name: "RegisterSuccess", // todo: sửa tên
  //   page: "RegisterSuccess", // ! xóa folder
  //   exact: true,
  //   authority: true,
  //   _tag: "CSidebarNavItem",
  //   icon: "cil-calendar",
  // },
  // {
  //   path: "/discount",
  //   name: "Discount", // todo: sửa tên
  //   page: "Discount", // ! xóa folder
  //   exact: true,
  //   authority: true,
  //   _tag: "CSidebarNavItem",
  //   icon: "cil-calendar",
  // },
  // {
  //   path: "/drink",
  //   name: "Discount", // todo: sửa tên
  //   page: "Discount", // ! xóa folder
  //   exact: true,
  //   authority: true,
  //   _tag: "CSidebarNavItem",
  //   icon: "cil-calendar",
  // },
  // {
  //   path: "/bestvoucher",
  //   name: "Discount", // todo: sửa tên
  //   page: "Discount", // ! xóa folder
  //   exact: true,
  //   authority: true,
  //   _tag: "CSidebarNavItem",
  //   icon: "cil-calendar",
  // },
  // {
  //   path: "/restaurant",
  //   name: "Discount", // todo: sửa tên
  //   page: "Discount", // ! xóa folder
  //   exact: true,
  //   authority: true,
  //   _tag: "CSidebarNavItem",
  //   icon: "cil-calendar",
  // },
  // {
  //   path: "/editmyaddress",
  //   name: "EditMyAddress",
  //   page: "EditMyAddress", // ! xóa folder
  //   exact: true,
  //   authority: true,
  //   _tag: "CSidebarNavItem",
  //   icon: "cil-calendar",
  // },
  // {
  //   path: "/accountmanagement",
  //   name: "AccountManagement",
  //   page: "AccountManagement", // ! xóa folder
  //   exact: true,
  //   authority: true,
  //   _tag: "CSidebarNavItem",
  //   icon: "cil-calendar",
  // },
  // {
  //   path: "/employeecarddetail",
  //   name: "EmployeeCardDetail", // todo: sửa tên
  //   page: "EmployeeCardDetail", // ! xóa folder
  //   exact: true,
  //   authority: true,
  //   _tag: "CSidebarNavItem",
  //   icon: "cil-calendar",
  // },
  // {
  //   path: "/employeecardconnect",
  //   name: "EmployeeCardCanTeen", // todo: sửa tên
  //   page: "EmployeeCardCanTeen", // ! xóa folder
  //   exact: true,
  //   authority: true,
  //   _tag: "CSidebarNavItem",
  //   icon: "cil-calendar",
  // },
  // {
  //   path: "/addnewaddress",
  //   name: "Thêm địa chỉ",
  //   page: "AddNewAddress", // ! xóa folder
  //   exact: true,
  //   authority: true,
  //   _tag: "CSidebarNavItem",
  //   icon: "cil-calendar",
  // },
  // {
  //   path: "/selectaddress",
  //   name: "SelectAddress",
  //   page: "SelectAddress", // ! xóa folder
  //   exact: true,
  //   authority: true,
  //   _tag: "CSidebarNavItem",
  //   icon: "cil-calendar",
  // },

  // *** deleted - 2022-01-09
  // **
  // {
  //   path: "/couponbuycart", // todo: delete (replaced by /coupondetail?id=)
  //   name: "Chi tiết coupon",
  //   page: "CouponDetailBuyCart",
  //   exact: true,
  //   authority: false,
  // },
  // **
  // {
  //   path: "/detail",
  //   name: "Chi tiết voucher",
  //   page: "Detail",
  //   exact: true,
  //   authority: false,
  // },
  // **
  // {
  //   path: "/cartvoucher",
  //   name: "Giỏ hàng voucher",
  //   page: "CartVoucher",
  //   exact: true,
  //   authority: false,
  // },
  // **
  // {
  //   path: "/commonPages",
  //   name: "CommonPages", // todo: sửa tên
  //   page: "CommonPages",
  //   exact: true,
  //   authority: true,
  // },
  // **
  // {
  //   path: "/promotionvoucher",
  //   name: "PromotionVoucher", // todo: sửa tên
  //   page: "PromotionVoucher",
  //   exact: true,
  //   authority: true,
  // },
  // **
  // {
  //   path: "/transactionstatus", // todo: delete folder. it was replaced by /transactionresult/:paymentMethodCode
  //   name: "Thông tin giao dịch FnB",
  //   page: "TransactionStatus",
  //   exact: true,
  //   authority: true,
  // **
  // {
  //   path: "/storeapplycoupons", // ? khong biet page nay dung de lam gi
  //   name: "ListStoreApplyCounpons", // todo: sửa tên
  //   page: "ListStoreApplyCounpons",
  //   exact: true,
  //   authority: true,
  // },
  // **
  // {
  //   path: "/liststoreapply", // ? khong biet page nay dung de lam gi
  //   name: "ListStoreApply", // todo: sửa tên
  //   page: "ListStoreApply",
  //   exact: true,
  //   authority: true,
  // },

  // // ***
  // {
  //   path: "/test",
  //   name: "Testing",
  //   page: "Home",
  //   exact: true,
  //   authority: false,
  // },

  {
    component: loadable(() => import("./views/pages/Page404"), {
      cacheKey: () => "404 Not Found",
      fallback: <LoadingScreen />,
    }),
  },
];

export default routes;
