import { toast } from "react-toastify";
import { addErrors, delErrors } from "utils/ErrorsGenerator";
import { getCategories } from "utils/getCategories";
import MESSAGES from "constants/vi";

import * as TYPE from "./constants";

const MESSAGE_KEY = "Cart"; // key của object chứa message
const UNKNOWN = "unknown";
const toastOptions = {
  position: "top-center",
  autoClose: 7000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

const reducers = {
  // * GET_MERCHANT_INFO
  [TYPE.GET_MERCHANT_INFO.request]: (draft) => {
    return {
      ...draft,
      isLoading: {
        ...draft.isLoading,
        GET_MERCHANT_INFO: true,
      },
    };
  },
  [TYPE.GET_MERCHANT_INFO.success]: (draft, { payload }) => {
    return {
      ...draft,
      merchantInfo: payload,
      errors: delErrors(draft.errors, TYPE.GET_MERCHANT_INFO.failure),
      isLoading: {
        ...draft.isLoading,
        GET_MERCHANT_INFO: false,
      },
    };
  },
  [TYPE.GET_MERCHANT_INFO.failure]: (draft, { payload }) => {
    return {
      ...draft,
      errors: addErrors(draft.errors, {
        type: TYPE.GET_MERCHANT_INFO.failure,
        msg: payload,
      }),
      isLoading: {
        ...draft.isLoading,
        GET_MERCHANT_INFO: false,
      },
    };
  },

  // * GET_STORE_INFO
  [TYPE.GET_STORE_INFO.request]: (draft) => {
    return {
      ...draft,
      isLoading: {
        ...draft.isLoading,
        GET_STORE_INFO: true,
      },
    };
  },
  [TYPE.GET_STORE_INFO.success]: (draft, { payload }) => {
    return {
      ...draft,
      storeInfo: payload,
      errors: delErrors(draft.errors, TYPE.GET_STORE_INFO.failure),
      isLoading: {
        ...draft.isLoading,
        GET_STORE_INFO: false,
      },
    };
  },
  [TYPE.GET_STORE_INFO.failure]: (draft, { payload }) => {
    return {
      ...draft,
      errors: addErrors(draft.errors, {
        type: TYPE.GET_STORE_INFO.failure,
        msg: payload,
      }),
      isLoading: {
        ...draft.isLoading,
        GET_STORE_INFO: false,
      },
    };
  },

  [TYPE.GET_PRODUCT_LIST.request]: (draft) => {
    return {
      ...draft,
      isLoading: {
        ...draft.isLoading,
        GET_PRODUCT_LIST: true,
      },
    };
  },
  [TYPE.GET_PRODUCT_LIST.success]: (draft, { payload }) => {
    const categoriesList = getCategories(payload);

    let updatedCategoriesList = categoriesList;

    // nếu vì lí do nào đó mà call API lấy ds đặt gần đây trước khi có ds product này
    if (draft.recentOrderedProductCodeList?.length > 0) {
      // chắc chắn là có categoriesList, vì đã xử lý trong reducer GET_RECENT_ORDERED_PRODUCT_CODE_LIST.success
      const highestPriority = categoriesList?.[0]?.priority || 0;

      const cateRecent = {
        code: "category__recent-ordered-products", // id of the section
        name: "Món đặt gần đây", // todo: bỏ hard code
        priority: highestPriority + 1,
      };

      updatedCategoriesList = [cateRecent, ...categoriesList];
    }

    return {
      ...draft,
      errors: delErrors(draft.errors, TYPE.GET_PRODUCT_LIST.failure),
      productList: payload,
      categoriesList: updatedCategoriesList,
      isLoading: {
        ...draft.isLoading,
        GET_PRODUCT_LIST: false,
      },
    };
  },
  [TYPE.GET_PRODUCT_LIST.failure]: (draft, { payload }) => {
    return {
      ...draft,
      productList: [],
      categoriesList: [],
      errors: addErrors(draft.errors, {
        type: TYPE.GET_PRODUCT_LIST.failure,
        msg: payload,
      }),
      isLoading: {
        ...draft.isLoading,
        GET_PRODUCT_LIST: false,
      },
    };
  },

  [TYPE.GET_RECENT_ORDERED_PRODUCT_CODE_LIST.request]: (draft) => {
    return {
      ...draft,
      isLoading: {
        ...draft.isLoading,
        GET_RECENT_ORDERED_PRODUCT_CODE_LIST: true,
      },
    };
  },
  [TYPE.GET_RECENT_ORDERED_PRODUCT_CODE_LIST.success]: (draft, { payload }) => {
    const categoriesList = draft?.categoriesList || [];

    let updatedCategoriesList = categoriesList;

    if (Array.isArray(categoriesList) && payload?.length > 0) {
      const highestPriority = categoriesList?.[0]?.priority || 0;

      const cateRecent = {
        code: "category__recent-ordered-products", // id of the section
        name: "Món đặt gần đây", // todo: bỏ hard code
        priority: highestPriority + 1,
      };

      updatedCategoriesList = [cateRecent, ...categoriesList];
    }

    return {
      ...draft,
      errors: delErrors(
        draft.errors,
        TYPE.GET_RECENT_ORDERED_PRODUCT_CODE_LIST.failure
      ),
      recentOrderedProductCodeList: payload,
      categoriesList: updatedCategoriesList,
      isLoading: {
        ...draft.isLoading,
        GET_RECENT_ORDERED_PRODUCT_CODE_LIST: false,
      },
    };
  },
  [TYPE.GET_RECENT_ORDERED_PRODUCT_CODE_LIST.failure]: (draft, { payload }) => {
    return {
      ...draft,
      errors: addErrors(draft.errors, {
        type: TYPE.GET_RECENT_ORDERED_PRODUCT_CODE_LIST.failure,
        msg: payload,
      }),
      isLoading: {
        ...draft.isLoading,
        GET_RECENT_ORDERED_PRODUCT_CODE_LIST: false,
      },
    };
  },

  [TYPE.GET_SUGGESTED_COUPON_LIST.request]: (draft) => {
    return {
      ...draft,
      isLoading: {
        ...draft.isLoading,
        GET_SUGGESTED_COUPON_LIST: true,
      },
    };
  },
  [TYPE.GET_SUGGESTED_COUPON_LIST.success]: (draft, { payload }) => {
    return {
      ...draft,
      errors: delErrors(draft.errors, TYPE.GET_SUGGESTED_COUPON_LIST.failure),
      suggestedCouponList: payload.suggestedCouponDetails,
      isLoading: {
        ...draft.isLoading,
        GET_SUGGESTED_COUPON_LIST: false,
      },
    };
  },
  [TYPE.GET_SUGGESTED_COUPON_LIST.failure]: (draft, { payload }) => {
    // draft.errors = addErrors(draft.errors, {
    //   type: TYPE.GET_SUGGESTED_COUPON_LIST.failure,
    //   msg: payload,
    // });

    return {
      ...draft,
      errors: addErrors(draft.errors, {
        type: TYPE.GET_SUGGESTED_COUPON_LIST.failure,
        msg: payload,
      }),
      isLoading: {
        ...draft.isLoading,
        GET_SUGGESTED_COUPON_LIST: false,
      },
    };
  },

  [TYPE.GET_OUT_OF_STOCK_PRODUCT_LIST.request]: (draft) => {
    return {
      ...draft,
      isLoading: {
        ...draft.isLoading,
        GET_OUT_OF_STOCK_PRODUCT_LIST: true,
      },
    };
  },
  [TYPE.GET_OUT_OF_STOCK_PRODUCT_LIST.success]: (draft, { payload }) => {
    const outOfStockProductCodes = payload.map((item) => item.productCode);

    return {
      ...draft,
      errors: delErrors(
        draft.errors,
        TYPE.GET_OUT_OF_STOCK_PRODUCT_LIST.failure
      ),
      outOfStockProductList: outOfStockProductCodes,
      isLoading: {
        ...draft.isLoading,
        GET_OUT_OF_STOCK_PRODUCT_LIST: false,
      },
    };
  },
  [TYPE.GET_OUT_OF_STOCK_PRODUCT_LIST.failure]: (draft, { payload }) => {
    return {
      ...draft,
      errors: addErrors(draft.errors, {
        type: TYPE.GET_OUT_OF_STOCK_PRODUCT_LIST.failure,
        msg: payload,
      }),
      isLoading: {
        ...draft.isLoading,
        GET_OUT_OF_STOCK_PRODUCT_LIST: false,
      },
    };
  },

  [TYPE.SAVE_SUGGESTED_COUPON.request]: (draft) => {
    return {
      ...draft,
      isLoading: {
        ...draft.isLoading,
        SAVE_SUGGESTED_COUPON: true,
      },
    };
  },
  [TYPE.SAVE_SUGGESTED_COUPON.success]: (draft, { payload }) => {
    // modify suggested coupon list, mark the saved one to isAllocated=true
    const codeOfTheSavedCoupon = payload;
    const newSuggestedCouponList = draft.suggestedCouponList.map((coupon) =>
      coupon.code === codeOfTheSavedCoupon
        ? {
            ...coupon,
            isAllocated: true,
          }
        : coupon
    );

    toast.success("Lấy mã thành công!", {
      toastId: `${TYPE}_SAVE_SUGGESTED_COUPON_SUCCESS`,
      ...toastOptions,
    });

    return {
      ...draft,
      errors: delErrors(draft.errors, TYPE.SAVE_SUGGESTED_COUPON.failure),
      suggestedCouponList: newSuggestedCouponList,
      isLoading: {
        ...draft.isLoading,
        SAVE_SUGGESTED_COUPON: false,
      },
    };
  },
  [TYPE.SAVE_SUGGESTED_COUPON.failure]: (draft, { payload }) => {
    const errMsgToToast =
      MESSAGES?.[MESSAGE_KEY]?.error?.[payload?.message] ||
      MESSAGES?.[MESSAGE_KEY]?.error?.[UNKNOWN];

    toast.error(errMsgToToast, {
      toastId: `${TYPE}_SAVE_SUGGESTED_COUPON_FAILURE`,
      ...toastOptions,
    });

    return {
      ...draft,
      errors: addErrors(draft.errors, {
        type: TYPE.SAVE_SUGGESTED_COUPON.failure,
        msg: payload,
      }),
      isLoading: {
        ...draft.isLoading,
        SAVE_SUGGESTED_COUPON: false,
      },
    };
  },

  [TYPE.GET_SHORT_LINK_TO_SHARE.request]: (draft) => {
    return {
      ...draft,
      isLoading: {
        ...draft.isLoading,
        GET_SHORT_LINK_TO_SHARE: true,
      },
    };
  },
  [TYPE.GET_SHORT_LINK_TO_SHARE.success]: (draft, { payload }) => {
    return {
      ...draft,
      shortLinkToShare: payload.url,
      errors: delErrors(draft.errors, TYPE.GET_SHORT_LINK_TO_SHARE.failure),

      isLoading: {
        ...draft.isLoading,
        GET_SHORT_LINK_TO_SHARE: false,
      },
    };
  },
  [TYPE.GET_SHORT_LINK_TO_SHARE.failure]: (draft, { payload }) => {
    return {
      ...draft,
      errors: addErrors(draft.errors, {
        type: TYPE.GET_SHORT_LINK_TO_SHARE.failure,
        msg: payload,
      }),
      shortLinkToShare: undefined,
      isLoading: {
        ...draft.isLoading,
        GET_SHORT_LINK_TO_SHARE: false,
      },
    };
  },
  // * GET_STORE_INFO_V2
  [TYPE.GET_STORE_INFO_V2.request]: (draft) => {
    return {
      ...draft,
      isLoading: {
        ...draft.isLoading,
        GET_STORE_INFO_V2: true,
      },
    };
  },
  [TYPE.GET_STORE_INFO_V2.success]: (draft, { payload }) => {
    return {
      ...draft,
      storeInfoV2: payload,
      errors: delErrors(draft.errors, TYPE.GET_STORE_INFO_V2.failure),
      isLoading: {
        ...draft.isLoading,
        GET_STORE_INFO_V2: false,
      },
    };
  },
  [TYPE.GET_STORE_INFO_V2.failure]: (draft, { payload }) => {
    return {
      ...draft,
      errors: addErrors(draft.errors, {
        type: TYPE.GET_STORE_INFO_V2.failure,
        msg: payload,
      }),
      isLoading: {
        ...draft.isLoading,
        GET_STORE_INFO_V2: false,
      },
    };
  },
};

export default reducers;
