import { LOGOUT_REQUEST } from "@shared/redux/account/constants";
import {
  MY_VOUCHER_REQUEST,
  MY_VOUCHER_SUCCESS,
  MY_VOUCHER_FAILED,
  MY_VOUCHER_USED_REQUEST,
  MY_VOUCHER_USED_SUCCESS,
  MY_VOUCHER_USED_FAILED,
} from "./constants";

const initialState = {
  listVoucher: [],
  listUsedVoucher: [],
  isRefreshVoucher: false,
  isRefreshVoucherUsed: false,
  errors: null,
};

const listVoucherReducer = (state = initialState, action) => {
  const { payload } = action;
  const refresh = action?.payload?.isRefresh || false;

  switch (action.type) {
    case MY_VOUCHER_REQUEST:
      return {
        ...state,
        isRefreshVoucher: refresh,
      };

    case MY_VOUCHER_SUCCESS:
      return {
        ...state,
        isRefreshVoucher: false,
        listVoucher: payload,
      };

    case MY_VOUCHER_FAILED:
      return {
        ...state,
        isRefreshVoucher: false,
        errors: payload,
      };
    case MY_VOUCHER_USED_REQUEST:
      return {
        ...state,
        isRefreshVoucherUsed: refresh,
      };

    case MY_VOUCHER_USED_SUCCESS:
      return {
        ...state,
        isRefreshVoucherUsed: false,
        listUsedVoucher: payload,
      };

    case MY_VOUCHER_USED_FAILED:
      return {
        ...state,
        isRefreshVoucherUsed: false,
        errors: payload,
      };

    case LOGOUT_REQUEST: {
      return {
        ...initialState,
      };
    }
    default:
      return state;
  }
};

export default listVoucherReducer;
