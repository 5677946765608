import { SAVE_PREVIOUS_CURRENT_ROUTE } from "@shared/redux/historyRoute/constants";
import {
  GET_LIST_BANNER_SUCCESS,
  GET_LIST_BANNER_REQUEST,
  GET_LIST_BANNER_FAILED,
  GET_LIST_BANNER_BY_LOCATION_SUCCESS,
  GET_LIST_BANNER_BY_LOCATION_REQUEST,
  GET_LIST_BANNER_BY_LOCATION_FAILED,
  GET_LIST_BANNER_DYNAMIC_SUCCESS,
  GET_LIST_BANNER_DYNAMIC_REQUEST,
  ACTION_CAMPAIGN_PRESS_REQUEST,
  ACTION_CAMPAIGN_PRESS_SUCCESS,
  GET_DYNAMIC_MODAL_FAILED,
  GET_DYNAMIC_MODAL_REQUEST,
  GET_DYNAMIC_MODAL_SUCCESS,
  GET_DYNAMIC_PAGE_FAILED,
  GET_DYNAMIC_PAGE_REQUEST,
  GET_DYNAMIC_PAGE_SUCCESS,
  GET_LIST_BANNER_DYNAMIC_FAILED,
  GET_LIST_HOT_MERCHANT_FAILED,
  GET_LIST_HOT_MERCHANT_REQUEST,
  GET_LIST_HOT_MERCHANT_SUCCESS,
  GET_LIST_SHORTCUT_FAILED,
  GET_LIST_SHORTCUT_REQUEST,
  GET_LIST_SHORTCUT_SUCCESS,
  SAVE_DEVICE_INFO_FAILED,
  SAVE_DEVICE_INFO_REQUEST,
  SAVE_DEVICE_INFO_SUCCESS,
  SET_LIST_COUPON_SUGGEST,
  TOGGLE_REQUEST_LOGIN,
  UPDATE_LIST_COUPON_SUGGEST,
  ACTION_CAMPAIGN_PRESS_FAILED,
  GET_SUGGEST_BANNER_REQUEST,
  GET_SUGGEST_BANNER_SUCCESS,
  GET_SUGGEST_BANNER_FAILED,
  SHORT_URL_REQUEST,
  GET_PERSONALIZE_SHORTCUT_REQUEST,
  GET_PERSONALIZE_SHORTCUT_SUCCESS,
  GET_PERSONALIZE_SHORTCUT_FAILED,
  UPDATE_GOOGLE_MAP_KEY_API,
  GET_LOV,
} from "./constants";

export const updateGoogleMapApiKey = (googleMapApiKey) => ({
  type: UPDATE_GOOGLE_MAP_KEY_API,
  googleMapApiKey,
});

export const toggleRequestLogin = (payload = {}) => ({
  type: TOGGLE_REQUEST_LOGIN,
  payload,
});

export const getListBannerHandle = (payload = {}) => ({
  type: GET_LIST_BANNER_REQUEST,
  payload,
});

export const getListBannerSuccess = (banners) => ({
  type: GET_LIST_BANNER_SUCCESS,
  banners,
});

export const getListBannerFailed = (error) => ({
  type: GET_LIST_BANNER_FAILED,
  error,
});

export const getListBannerByLocationHandle = (payload = {}) => ({
  type: GET_LIST_BANNER_BY_LOCATION_REQUEST,
  payload,
});

export const getListBannerByLocationSuccess = (banners) => ({
  type: GET_LIST_BANNER_BY_LOCATION_SUCCESS,
  banners,
});

export const getListBannerByLocationFailed = (error) => ({
  type: GET_LIST_BANNER_BY_LOCATION_FAILED,
  error,
});

export const getListBannerDynamicHandle = (payload = {}, onSuccess) => ({
  type: GET_LIST_BANNER_DYNAMIC_REQUEST,
  onSuccess,
  payload,
});

export const getListBannerDynamicSuccess = (banners) => ({
  type: GET_LIST_BANNER_DYNAMIC_SUCCESS,
  banners,
});

export const getListBannerDynamicFailed = (error) => ({
  type: GET_LIST_BANNER_DYNAMIC_FAILED,
  error,
});

export const getListShortcutHandle = (payload = {}) => ({
  type: GET_LIST_SHORTCUT_REQUEST,
  payload,
});

export const getListShortcutSuccess = (shortcuts) => ({
  type: GET_LIST_SHORTCUT_SUCCESS,
  shortcuts,
});

export const getListShortcutFailed = (error) => ({
  type: GET_LIST_SHORTCUT_FAILED,
  error,
});

export const saveDeviceInfoHandle = (deviceInfo = {}, onSuccess, onError) => ({
  type: SAVE_DEVICE_INFO_REQUEST,
  deviceInfo,
  onSuccess,
  onError,
});

export const saveDeviceInfoSuccess = (shortcuts) => ({
  type: SAVE_DEVICE_INFO_SUCCESS,
  shortcuts,
});

export const saveDeviceInfoFailed = (error) => ({
  type: SAVE_DEVICE_INFO_FAILED,
  error,
});

export const savePreviousCurrentRoute = (payload = {}) => ({
  type: SAVE_PREVIOUS_CURRENT_ROUTE,
  payload,
});

export const getDynamicPageHandle = (pageId, onSuccess) => ({
  type: GET_DYNAMIC_PAGE_REQUEST,
  onSuccess,
  pageId,
});

export const getDynamicPageSuccess = (dynamicPage) => ({
  type: GET_DYNAMIC_PAGE_SUCCESS,
  dynamicPage,
});

export const getDynamicPageFailed = (error) => ({
  type: GET_DYNAMIC_PAGE_FAILED,
  error,
});

export const getListHotMerchantHandle = (onSuccess) => ({
  type: GET_LIST_HOT_MERCHANT_REQUEST,
  onSuccess,
});

export const getListHotMerchantSuccess = (listHotMerchant) => ({
  type: GET_LIST_HOT_MERCHANT_SUCCESS,
  listHotMerchant,
});

export const getListHotMerchantFailed = (error) => ({
  type: GET_LIST_HOT_MERCHANT_FAILED,
  error,
});

export const setListCouponSuggest = (payload = {}) => ({
  type: SET_LIST_COUPON_SUGGEST,
  payload,
});

export const updateListCouponSuggest = (payload = {}) => ({
  type: UPDATE_LIST_COUPON_SUGGEST,
  payload,
});

export const getDynamicModalHandle = (configPayload, onSuccess, onError) => ({
  type: GET_DYNAMIC_MODAL_REQUEST,
  configPayload,
  onSuccess,
  onError,
});

export const getDynamicModalSuccess = (dynamicModalConfig) => ({
  type: GET_DYNAMIC_MODAL_SUCCESS,
  dynamicModalConfig,
});

export const getDynamicModalFailed = (error) => ({
  type: GET_DYNAMIC_MODAL_FAILED,
  error,
});

export const actionCampaignPressHandle = (
  payload = {},
  onSuccess,
  onError
) => ({
  type: ACTION_CAMPAIGN_PRESS_REQUEST,
  payload,
  onSuccess,
  onError,
});

export const actionCampaignPressSuccess = (config) => ({
  type: ACTION_CAMPAIGN_PRESS_SUCCESS,
  config,
});

export const actionCampaignPressFailure = (error) => ({
  type: ACTION_CAMPAIGN_PRESS_FAILED,
  error,
});

export const getSuggestBannerHandle = (payload = {}, onSuccess, onError) => ({
  type: GET_SUGGEST_BANNER_REQUEST,
  payload,
  onSuccess,
  onError,
});

export const getSuggestBannerSuccess = (payload) => ({
  type: GET_SUGGEST_BANNER_SUCCESS,
  payload,
});

export const getSuggestBannerFailure = (error) => ({
  type: GET_SUGGEST_BANNER_FAILED,
  error,
});

export const shortUrlHandle = (payload, onSuccess, onError) => ({
  type: SHORT_URL_REQUEST,
  payload,
  onSuccess,
  onError,
});

export const getPersonalizeShortcutHandle = (payload = {}) => ({
  type: GET_PERSONALIZE_SHORTCUT_REQUEST,
  payload,
});

export const getPersonalizeShortcutSuccess = (payload) => ({
  type: GET_PERSONALIZE_SHORTCUT_SUCCESS,
  payload,
});

export const getPersonalizeShortcutFailure = (error) => ({
  type: GET_PERSONALIZE_SHORTCUT_FAILED,
  error,
});

export const getLovHandle = (payload = {}, onSuccess, onFailed) => ({
  type: GET_LOV.HANDLER,
  payload,
  onSuccess,
  onFailed,
});

export const getLovSuccess = (payload) => ({
  type: GET_LOV.SUCCESS,
  payload,
});

export const getLovFailure = (error) => ({
  type: GET_LOV.FAILURE,
  error,
});
