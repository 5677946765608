import React from "react";
import PropTypes from "prop-types";
import { StarEmptySm, StarFullSm, StarHalfSm } from "assets/svg";
import classNames from "classnames";
import "./IconList.scss";

function IconList({
  selectedNum,
  totalIcon,
  customId,
  iconFull,
  iconHalf,
  iconEmpty,
  iconClassName,
  containerClassName,
}) {
  return (
    <span className={classNames("icon-list-container", containerClassName)}>
      {[...Array(totalIcon)].map((_, i) => {
        if (i < selectedNum) {
          // check if current star should be half
          if (
            i + 0.5 === selectedNum ||
            (i + 0.5 < selectedNum && i + 1 > selectedNum)
          ) {
            return (
              <i key={`${customId}${i + 1}`} className={iconClassName}>
                {iconHalf}
              </i>
            );
          }
          // 4.1 4.2 4.3 4.4 -> 4
          // 4.5 4.6 4.7 4.8 4.9 -> 4.5
          // 5 -> 5
          // check if current star should be full
          if (i + 1 < selectedNum || i + 1 === selectedNum) {
            return (
              <i key={`${customId}${i + 1}`} className={iconClassName}>
                {iconFull}
              </i>
            );
          }
        }
        // else, current star should be empty
        return (
          <i key={`${customId}${i + 1}`} className={iconClassName}>
            {iconEmpty}
          </i>
        );
      })}
    </span>
  );
}

IconList.defaultProps = {
  selectedNum: 0,
  totalIcon: 5,
  customId: "",
  iconFull: <StarFullSm />,
  iconHalf: <StarHalfSm />,
  iconEmpty: <StarEmptySm />,
  iconClassName: "",
  containerClassName: "",
};
IconList.propTypes = {
  selectedNum: PropTypes.number,
  totalIcon: PropTypes.number,
  customId: PropTypes.string,
  iconFull: PropTypes.element,
  iconHalf: PropTypes.element,
  iconEmpty: PropTypes.element,
  iconClassName: PropTypes.string,
  containerClassName: PropTypes.string,
};

export default IconList;
