import {END_POINTS} from '@shared/constants/apiUrl';

import {APIUtils} from 'utils';

export function getEditionRenewApi(payload) {
  return APIUtils.get(
    END_POINTS.GET_EDITION_RENEW +
      `?EditionId=${payload.editionId}&NewspaperId=${payload.newspaperId}`,
  );
}
