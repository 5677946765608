import produce from 'immer';
import {
  GET_LIST_BLOCK_USER_FAILED,
  GET_LIST_BLOCK_USER_SUCCESS,
  UNBLOCK_USER_SUCCESS,
} from './types';

const initialState = {
  errors: null,
  hashtagDetail: {},
  listBlockUser: null,
};

const hashtagReducer = produce((draft = initialState, action) => {
  const {payload} = action;

  switch (action.type) {
    case GET_LIST_BLOCK_USER_SUCCESS:
      return {
        ...draft,
        listBlockUser: payload,
      };
    case GET_LIST_BLOCK_USER_FAILED:
      return {
        ...draft,
        listBlockUser: [],
      };
    case UNBLOCK_USER_SUCCESS:
      draft.listBlockUser = Object.values(draft.listBlockUser).filter(
        user => user.blockedCreatorId !== payload,
      );
      break;
    default:
      return draft;
  }
});

export default hashtagReducer;
