import { genActions } from "utils/actionGenerator";
import * as TYPE from "./constants";

const actions = {
  POST_SEND_VOUCHER: genActions(TYPE.POST_SEND_VOUCHER),
  GET_USER_BY_PHONE: genActions(TYPE.GET_USER_BY_PHONE),
  GET_SHORT_LINK_TO_SHARE: genActions(TYPE.GET_SHORT_LINK_TO_SHARE),
  RESET_SEND_VOUCHER: () => ({
    type: TYPE.RESET_SEND_VOUCHER,
  }),
};

export default actions;
