import {
  COUNT_REFERRAL,
  GET_REFERRAL_REFERRED,
  GET_REFERRAL_REWARD,
} from './constants';

export const countReferralHandle = payload => {
  return {
    type: COUNT_REFERRAL.REQUEST,
    payload,
  };
};

export const countReferralSuccess = payload => {
  return {
    type: COUNT_REFERRAL.SUCCESS,
    payload,
  };
};

export const countReferralFailed = error => {
  return {
    type: COUNT_REFERRAL.FAILED,
    error,
  };
};

// ReferralReward

export const getReferralRewardHandle = payload => {
  return {
    type: GET_REFERRAL_REWARD.REQUEST,
    payload,
  };
};

export const getReferralRewardSuccess = payload => {
  return {
    type: GET_REFERRAL_REWARD.SUCCESS,
    payload,
  };
};

export const getReferralRewardFailed = error => {
  return {
    type: GET_REFERRAL_REWARD.FAILED,
    error,
  };
};

// Referral referred

export const getListReferredHandle = payload => {
  return {
    type: GET_REFERRAL_REFERRED.REQUEST,
    payload,
  };
};

export const getListReferredSuccess = payload => {
  return {
    type: GET_REFERRAL_REFERRED.SUCCESS,
    payload,
  };
};

export const getListReferredFailed = error => {
  return {
    type: GET_REFERRAL_REFERRED.FAILED,
    error,
  };
};

export const clearListReferred = () => {
  return {
    type: GET_REFERRAL_REFERRED.CLEAR,
  };
};
