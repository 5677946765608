const prefix = 'profile';

// Fetch hashtag detail
export const HASHTAG_DETAIL_REQUEST = prefix + '/HASHTAG_DETAIL_REQUEST';
export const HASHTAG_DETAIL_SUCCESS = prefix + '/HASHTAG_DETAIL_SUCCESS';
export const HASHTAG_DETAIL_FAILED = prefix + '/HASHTAG_DETAIL_FAILED';

// Fetch my video
export const MY_VIDEO_REQUEST = prefix + '/MY_VIDEO_REQUEST';
export const MY_VIDEO_SUCCESS = prefix + '/MY_VIDEO_SUCCESS';
export const MY_VIDEO_FAILED = prefix + '/MY_VIDEO_FAILED';

// Fetch user's info
export const USER_INFO_REQUEST = prefix + '/USER_INFO_REQUEST';
export const USER_INFO_SUCCESS = prefix + '/USER_INFO_SUCCESS';
export const USER_INFO_FAILED = prefix + '/USER_INFO_FAILED';