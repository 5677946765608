import { genActionTypes } from "utils/actionGenerator";

export const TYPE = "root";

export const SET_LOADING = `${TYPE}_SET_LOADING`;
export const SET_OFFLINE = `${TYPE}_SET_OFFLINE`;
export const GET_USER_INFO = genActionTypes(`${TYPE}_GET_USER_INFO`);
export const SET_USER_INFO = `${TYPE}_SET_USER_INFO`;
export const SIGN_IN_WITH_CUSTOM_TOKEN = genActionTypes(
  `${TYPE}_SIGN_IN_WITH_CUSTOM_TOKEN`
);
export const UPLOAD_AVATAR = genActionTypes(`${TYPE}_UPLOAD_AVATAR`);
export const UPDATE_USER_INFO = genActionTypes(`${TYPE}_UPDATE_USER_INFO`);

export const CHANGE_LAYOUT = `${TYPE}_CHANGE_LAYOUT`;
export const SET_USER = `${TYPE}_SET_USER`;

export const SEARCH_LIST = genActionTypes(`${TYPE}_SEARCH_LIST`);
export const GET_SEARCH_SUGGESTION = genActionTypes(
  `${TYPE}_GET_SEARCH_SUGGESTION`
);

export const SET_SEARCHTERMS = `${TYPE}_SET_SEARCHTERMS`;
export const SHOW_ELEMENTS = `${TYPE}_SHOW_ELEMENTS`;
export const SAVE_FILTER_DATA = `${TYPE}_SAVE_FILTER_DATA`;
export const CLEAR_SEARCH_RESULT = `${TYPE}_CLEAR_SEARCH_RESULT`;
