import { addErrors, delErrors } from "utils/ErrorsGenerator";
import * as TYPE from "./constants";

const reducers = {
  [TYPE.LIST_ORDER_HISTORY.request]: (draft) => {
    return {
      ...draft,
      isLoading: {
        ...draft.isLoading,
        LIST_ORDER_HISTORY: true,
      },
    };
  },
  [TYPE.LIST_ORDER_HISTORY.success]: (draft, { payload }) => {
    return {
      ...draft,
      errors: delErrors(draft.errors, TYPE.LIST_ORDER_HISTORY.failure),
      listOrderHistory: payload,
      isLoading: {
        ...draft.isLoading,
        LIST_ORDER_HISTORY: false,
        FILTER_ORDER_HISTORY: false,
      },
    };
  },
  [TYPE.LIST_ORDER_HISTORY.failure]: (draft, { payload }) => {
    return {
      ...draft,
      errors: addErrors(draft.errors, {
        type: TYPE.LIST_ORDER_HISTORY.failure,
        msg: payload,
      }),
      isLoading: {
        ...draft.isLoading,
        LIST_ORDER_HISTORY: false,
        FILTER_ORDER_HISTORY: false,
      },
    };
  },

  [TYPE.SET_LOADING_BY_FILTER_ORDER_HISTORY]: (draft, { payload }) => {
    return {
      ...draft,
      isLoading: {
        ...draft.isLoading,
        FILTER_ORDER_HISTORY: payload,
      },
    };
  },

  [TYPE.CLEAR_ORDER_HISTORY]: (draft) => {
    return {
      ...draft,
      listOrderHistory: {
        isInit: true,
        hasMore: false,
        listOrderHistoryItem: [],
        currentCount: 0,
      },
    };
  },
};

export default reducers;
