/* eslint-disable consistent-return */
/* eslint-disable no-use-before-define */
import produce from "immer";
import { connectRouter, routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";

export const history = createBrowserHistory();

const store = () => {
  const { constants, reducers, sagas, states: initialState } = importAllStore();
  const { TYPE } = constants;

  return {
    id: TYPE, // Unique id of the module

    // Maps the Store key to the reducer
    reducerMap: {
      [TYPE]: produce(
        (state, { type, payload }) => {
          // always return a new object for the root state
          if (reducers[type]) {
            return reducers[type](state, { payload, type });
          }
        },
        { ...initialState }
      ),
      router: connectRouter(history),
    },

    middlewares: [routerMiddleware(history)],

    sagas: [...sagas],
    // Optional: Any actions to dispatch when the module is loaded
    initialActions: [],
    // Optional: Any actions to dispatch when the module is unloaded
    // finalActions: [],
    retained: true, // Specifies if the module is retained forever in the store
  };
};
const reducers = {};
const constants = []
const states = {}
const sagas = {}

export const importAllStore = () => {
  try {

    // Constant
    const constantsContext = require.context("../../../views", true, /.constants.js/);
    const constantsTemp = constantsContext.keys().map(constantsContext);

    constantsTemp.forEach((constElm,i) => {
      constants[i]={...constElm}
    }) 

      // Reducers
    const reducersContext = require.context("../../../views", true, /.reducers.js/);

    const reducersTemp = reducersContext.keys().map(reducersContext);
      
    reducersTemp.forEach((reducer,i) => {
      reducers[constants[i].TYPE]={...reducer.default}
    }) 

    // States
    const statesContext = require.context("../../../views", true, /.states.js/);

    const statesTemp = statesContext.keys().map(statesContext);
    statesTemp.forEach((state,i) => {
      states[constants[i].TYPE]={...state}
    }) 

    // Sagas
    const sagasContext = require.context("../../../views", true, /.sagas.js/);

    const sagasTemp = sagasContext.keys().map(sagasContext);
    sagasTemp.forEach((saga,i) => {
      sagas[constants[i].TYPE]=saga.default
    }) 

    return {
      constants,
      reducers,
      sagas,
      states,
    };
  } catch (error) {
    console.warn(error);
  }
};

export default store;
