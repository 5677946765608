export const GET_LIST_CITY_REQUEST = 'address/GET_LIST_CITY_REQUEST';

export const GET_LIST_CITY_SUCCESS = 'address/GET_LIST_CITY_SUCCESS';

export const GET_LIST_CITY_FAILED = 'address/GET_LIST_CITY_FAILED';

export const GET_CITY_BY_LOCATION_REQUEST =
  'address/GET_CITY_BY_LOCATION_REQUEST';

export const GET_CITY_BY_LOCATION_SUCCESS =
  'address/GET_CITY_BY_LOCATION_SUCCESS';

export const GET_CITY_BY_LOCATION_FAILED =
  'address/GET_CITY_BY_LOCATION_FAILED';
