export const GET_LIST_VIDEO_DYNAMIC_REQUEST =
  'video/GET_LIST_VIDEO_DYNAMIC_REQUEST';

export const GET_LIST_VIDEO_DYNAMIC_SUCCESS =
  'video/GET_LIST_VIDEO_DYNAMIC_SUCCESS';

export const GET_LIST_VIDEO_DYNAMIC_FAILED =
  'video/GET_LIST_VIDEO_DYNAMIC_FAILED';

export const GET_LIST_VIDEO_BY_LOCATION_REQUEST =
  'video/GET_LIST_VIDEO_BY_LOCATION_REQUEST';

export const GET_LIST_VIDEO_BY_LOCATION_SUCCESS =
  'video/GET_LIST_VIDEO_BY_LOCATION_SUCCESS';

export const GET_LIST_VIDEO_BY_LOCATION_FAILED =
  'video/GET_LIST_VIDEO_BY_LOCATION_FAILED';
