import { put, takeLatest, fork } from "redux-saga/effects";
import {
  CREATE_PAYMENT_URL_REQUEST,
  CREATE_TOKENIZATION_REQUEST,
} from "./constants";

import {
  createPaymentUrlSuccess,
  createPaymentUrlFailed,
  createTokenizationSuccess,
  createTokenizationFailed,
} from "./actions";

import {
  CreatePaymentUrlAPI,
  CreateTokenizationAPI,
} from "@shared/services/api/paymentHub";
import { callApi } from "helpers/callApi";

export function* createPaymentUrlSaga(obj) {
  const { payload = {}, onSuccess, onError } = obj;
  try {
    const res = yield callApi(CreatePaymentUrlAPI, payload);
    let masterData = res?.data;
    if (Object.keys(masterData).length > 0) {
      masterData = {
        ...masterData,
        paymentMethod: payload.paymentMethod,
        status: res.status,
      };
    }
    yield put(createPaymentUrlSuccess(masterData));
    if (typeof onSuccess === "function") {
      onSuccess(masterData);
    }
  } catch (err) {
    yield put(createPaymentUrlFailed(err));
    if (typeof onError === "function") {
      onError(err);
    }
  }
}

export function* createTokenizationSaga(obj) {
  const { payload = {}, onSuccess, onError } = obj;
  try {
    const res = yield callApi(CreateTokenizationAPI, payload);
    yield put(createTokenizationSuccess(res?.data || {}));
    if (typeof onSuccess === "function") {
      onSuccess(res?.data || {});
    }
  } catch (err) {
    yield put(createTokenizationFailed(err));
    if (typeof onError === "function") {
      onError(err);
    }
  }
}

function* watchUser() {
  yield takeLatest(CREATE_PAYMENT_URL_REQUEST, createPaymentUrlSaga);
  yield takeLatest(CREATE_TOKENIZATION_REQUEST, createTokenizationSaga);
}

export default function* rootChild() {
  yield fork(watchUser);
}
