export const preprocessingPathName = (path) => {
  let tempPath = path?.trim();
  if (tempPath === "/") {
    tempPath = "home";
  }
  if (tempPath[0] === "/") {
    tempPath = tempPath?.slice(1);
  }
  return tempPath;
};
