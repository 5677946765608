import { put, takeLatest, fork, call, select } from "redux-saga/effects";
import { callApi } from "helpers/callApi";
import { requestOTP, sendOTP, getUserInfo } from "@shared/services/api/login";
import { GET_OTP_REQUEST, SEND_OTP_REQUEST, GET_USER_INFO } from "./constants";
import {
  getOtpRequestSuccess,
  getOtpRequestFaild,
  sendOtpRequestSuccess,
  sendOtpRequestFaild,
  getUserInfoSuccess,
  getUserInfoFaild,
} from "./actions";
import { getUserInfoSelector } from "@shared/redux/account/selectors";

export function* getRequestOTPSaga(obj) {
  console.log("------------------------------getRequestOTPSaga");

  const { data, onSuccess, onError } = obj;
  try {
    const res = yield callApi(requestOTP, {
      data: data,
    });
    console.log("-----------------------------------res");
    console.log(res);
    yield put(getOtpRequestSuccess({ data: res.data }));
    if (typeof onSuccess === "function") {
      onSuccess(res);
    }
  } catch (err) {
    console.log("-----------err------------------------res");
    console.log(err);

    if (typeof onError === "function") {
      onError(err);
    }
    yield put(getOtpRequestFaild(err));
  }
}
export function* SendOTPSaga(obj) {
  // console.log('------------------------------SendOTPSaga');
  const { data, onSuccess, onError } = obj;
  try {
    const res = yield callApi(sendOTP, {
      data: data,
    });
    // console.log('-----------------------------------res');
    yield put(sendOtpRequestSuccess({ data: res.data }));
    if (typeof onSuccess === "function") {
      onSuccess(res);
    }
  } catch (err) {
    // console.log('-----------err------------------------res');
    if (typeof onError === "function") {
      onError(err);
    }
    yield put(sendOtpRequestFaild({ err: err }));
  }
}
export function* getUserInfoSaga(obj) {
  // console.log('------------------------------SendOTPSaga');
  const { data, onSuccess, onError } = obj;
  try {
    const res = yield callApi(getUserInfo, {});
    // console.log('-----------------------------------res');
    const currentUserInfo = yield select(getUserInfoSelector);
    yield put(
      getUserInfoSuccess({ userInfo: { ...currentUserInfo, ...res.data } })
    );
    if (typeof onSuccess === "function") {
      onSuccess(res);
    }
  } catch (err) {
    // console.log('-----------err------------------------res');
    if (typeof onError === "function") {
      onError(err);
    }
    yield put(getUserInfoFaild({ err: err }));
  }
}
function* watchAddress() {
  yield takeLatest(GET_OTP_REQUEST, getRequestOTPSaga);
  yield takeLatest(SEND_OTP_REQUEST, SendOTPSaga);
  yield takeLatest(GET_USER_INFO, getUserInfoSaga);
}

export default function* rootChild() {
  yield fork(watchAddress);
}
