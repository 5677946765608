import { call, put, takeEvery, fork } from "redux-saga/effects";
import actions from "./actions";
import * as TYPE from "./constants";
import api from "../api/api";

function uniqueid() {
  // always start with a letter (for DOM friendlyness)
  let idstr = String.fromCharCode(Math.floor(Math.random() * 25 + 65));
  do {
    // between numbers and characters (48 is 0 and 90 is Z (42-48 = 90)
    const ascicode = Math.floor(Math.random() * 42 + 48);
    if (ascicode < 58 || ascicode > 64) {
      // exclude all chars between : (58) and @ (64)
      idstr += String.fromCharCode(ascicode);
    }
  } while (idstr.length < 32);

  return idstr;
}

const sagas = {
  *[TYPE.GET_LIST_VOUCHER_TO_CHOOSE.request](action) {
    try {
      const response = yield call(
        api[TYPE.GET_LIST_VOUCHER_TO_CHOOSE.request],
        action.payload
      );
      if (response.status === 200) {
        const updatedData = response.data.map((item) => ({
          ...item,
          quantity: 1,
          check: false,
          voucherId: uniqueid(), // Loại voucher checkExist mua có cùng 1 transactionCode
          // cho nên không dùng làm id dc, tự gen ID để update data khi back lại, filter data cho checkMark
        }));
        const voucherNotExp = {
          isInit: false,
          value: updatedData,
        };

        yield put(actions.GET_LIST_VOUCHER_TO_CHOOSE.success(voucherNotExp));
      } else {
        yield put(actions.GET_LIST_VOUCHER_TO_CHOOSE.failure(response?.data));
      }
    } catch (error) {
      yield put(actions.GET_LIST_VOUCHER_TO_CHOOSE.failure(error?.data));
    }
  },
};

function* watchedSaga() {
  yield takeEvery(
    TYPE.GET_LIST_VOUCHER_TO_CHOOSE.request,
    sagas[TYPE.GET_LIST_VOUCHER_TO_CHOOSE.request]
  );
}

export default function* rootChild() {
  yield fork(watchedSaga);
}
