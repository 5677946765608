import { APIUtils } from 'utils'
import { END_POINTS } from '@shared/constants/apiUrl'

export const getListVideoDynamicApi = ({ url }) => {
  return APIUtils.get(url)
}

export const getListVideoByLocationApi = ({
  page = 1,
  pageSize = 20,
  location,
}) => {
  const requestData = `?skip=${(page - 1) * 20}&take=${pageSize}&lat=${
    location.latitude
  }&long=${location.longitude}`
  return APIUtils.get(END_POINTS.HomepageUtopApp + requestData)
}
