import React, { useState, useEffect, useMemo } from "react";
import SVG, { FlagSolidOrangeIcon } from "assets/svg";

import { getDistanceFromLatLonInKm } from "helpers/getDistanceFromLatLonInKm";
import classNames from "classnames";
import "./AddressItem.scss";

const AddressItem = ({
  recentUsed,
  // id,
  briefAddress,
  address,
  isDefault,

  // search results
  isSearchResult,
  placeId,
  placesService,
  handleClickSearchResult,

  currentPosition,
}) => {
  const [coordinates, setCoordinates] = useState();

  useEffect(() => {
    const handleGetPlaceDetails = async () => {
      if (!placesService) {
        return;
      }

      try {
        await placesService.getDetails({ placeId }, (placeDetails) => {
          if (placeDetails) {
            setCoordinates(placeDetails.geometry.location.toJSON());
          }
        });
      } catch (error) {
        // console.log(
        //   "🚀 ~ file: AddressItem.js ~ line 41 ~ handleGetPlaceDetails ~ error",
        //   error
        // );
      }
    };

    if (isSearchResult && placeId) {
      handleGetPlaceDetails();
    }

    return () => {
      setCoordinates(undefined);
    };
  }, [placeId, placesService]);

  const distance = useMemo(() => {
    if (
      coordinates?.lat &&
      coordinates?.lng &&
      currentPosition?.lat &&
      currentPosition?.long
    ) {
      const result = getDistanceFromLatLonInKm(
        currentPosition.lat,
        currentPosition.long,
        coordinates.lat,
        coordinates.lng
      );

      return result;
    }
  }, [
    coordinates?.lat,
    coordinates?.lng,
    currentPosition?.lat,
    currentPosition?.long,
  ]);

  const handleClick = () => {
    if (isSearchResult && handleClickSearchResult) {
      // if (address && coordinates) {
      //   handleClickSearchResult(address, coordinates);
      // }
      if (address) {
        handleClickSearchResult(address, coordinates);
      }
    }
  };

  return (
    <>
      <div
        className={classNames(
          "address-item__wrapper",
          isSearchResult && "button__effect"
          // !coordinates && "address-item__wrapper--disabled"
        )}
        onClick={handleClick}
      >
        {recentUsed && (
          <img
            className="address-item__recent-used-icon"
            src={SVG.Clock}
            alt="clock icon"
          />
        )}

        <div className="address-item__address">
          <div className="address-item__address--brief">{briefAddress}</div>

          <div className="_text-truncate">
            {distance && (
              <>
                <span>
                  {
                    Number(distance.toFixed(2)).toLocaleString("vi")
                    // todo: transfer to meter if < 1km
                  }
                  km
                </span>
                <span style={{ marginInline: 10 }}>&bull;</span>
              </>
            )}
            {address}
          </div>

          {isDefault && (
            <div className="default-address-sign">
              <FlagSolidOrangeIcon />
              <span>Địa chỉ mặc định</span>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default React.memo(AddressItem);
