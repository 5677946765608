/* eslint-disable no-unused-vars */
import SVG from "assets/svg";
import classNames from "classnames";
import React from "react";
import { useHistory } from "react-router-dom";
import { toVND } from "utils/currencyFormat";
import "./cartBottomBar.scss";

const CartBottomBar = (props) => {
  const {
    to,
    totalPrice = 0,
    itemsCount = 0,
    bgrContainerClassName,
    priceActiveClassName,
    style,
    className,
    text,
    disabled = false,
    onClickButton,
    onClickIcon,
    eventHandle,
  } = props;

  const history = useHistory();

  const handleClickButton = () => {
    if (onClickButton) {
      onClickButton();
      return;
    }
    if (eventHandle) {
      eventHandle();
      // return;
    }
    if (to) {
      history.push(to);
    }
  };

  const handleClickIcon = () => {
    if (onClickIcon) {
      onClickIcon();
      return;
    }
    if (to) {
      history.push(to);
    }
  };

  return (
    <div className="cart-bottom-bar__spacer">
      <div
        className={classNames(
          "cart-bottom-bar__container",
          bgrContainerClassName,
          className
        )}
        style={style}
      >
        <div className="cart-bottom-bar__left">
          <button
            type="button"
            className="cart-bottom-bar__icon-button button__effect"
            onClick={handleClickIcon}
            disabled={!(itemsCount > 0) || disabled}
          >
            <img src={SVG.CartIcon} alt="logo cart" />

            {/* quantity */}
            <span
              className={classNames(
                "cart-bottom-bar__quantity",
                itemsCount > 0 && "d-block"
              )}
            >
              {itemsCount <= 99 ? itemsCount : `+99`}
              {/* <span className="visually-hidden">{`item${
                itemsCount > 1 ? "s" : ""
              } in cart`}</span> */}
            </span>
          </button>

          {/* price */}
          <span
            className={classNames(
              "cart-bottom-bar__price",
              itemsCount > 0
                ? priceActiveClassName
                : "cart-bottom-bar__price--disabled"
            )}
          >
            {toVND(totalPrice)}
          </span>
        </div>

        {/* button */}
        <button
          type="button"
          // onClick={eventHandle}
          onClick={handleClickButton}
          className="cart-bottom-bar__button button__effect"
          disabled={!(itemsCount > 0) || disabled}
        >
          <span>{text}</span>
        </button>
      </div>
    </div>
  );
};

export default CartBottomBar;
