import { getBannerSuccess, getBannerFailure } from './actions'
import { GET_BANNER_REQUEST } from './types'
import { call, put, takeLatest, fork } from 'redux-saga/effects'
import { END_POINTS } from '@shared/constants/apiUrl'
import { APIUtils } from 'utils'

function getBannerApi() {
  return APIUtils.get(END_POINTS.GetBanner)
}

export function* getBanner(obj) {
  try {
    const data = yield call(getBannerApi, obj.payload)
    if (data.httpStatus === 200) {
      delete data['httpStatus']
      if (data.items.length > 0) {
        data.items = data.items.sort(function (a, b) {
          return (
            (typeof b.data.priority !== 'undefined' ? b.data.priority.iv : 0) -
            (typeof a.data.priority !== 'undefined' ? a.data.priority.iv : 0)
          )
        })
      }
      yield put(getBannerSuccess(data))
    } else {
      yield put(getBannerFailure(data))
    }
  } catch (err) {
    yield put(getBannerFailure(err))
  }
}

function* watchBanner() {
  yield takeLatest(GET_BANNER_REQUEST, getBanner)
}

export default function* rootChild() {
  yield fork(watchBanner)
}
