export const ratingData = {};
export const storeInfo = undefined;
export const storeInfoV2 = undefined;
export const merchantInfo = {};
export const bestSellerData = {};
export const suggestedCouponList = [];
export const shortLinkToShare = undefined;
export const userLikedData = {
  list: [],
  hasMore: true,
};
export const replyData = {};
export const filterByStar = {};
export const notAlreadyRatedList = [];
export const listDeal = undefined;

export const isLoading = {
  PAGE: false,
  GET_RATING_LIST: false,
  GET_MERCHANT_INFO: false,
  GET_STORE_INFO: true,
  GET_STORE_INFO_V2: true,
  GET_SUGGESTED_COUPON_LIST: false,
  GET_SHORT_LINK_TO_SHARE: false, // ~ page
  SAVE_SUGGESTED_COUPON: false,
  GET_USER_LIKED_LIST_OF_RATING_POST: false,
  GET_USER_LIKED_LIST_OF_COMMENT: false,
  GET_LIST_DEAL: false,
};
