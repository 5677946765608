import "react-app-polyfill/ie11"; // For IE 11 support
import "react-app-polyfill/stable";
import "core-js";
import "./polyfill";
import "./locales/index";
import { store } from "@shared/redux/configureStore";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import * as serviceWorker from "./serviceWorker";
import { history } from "./services/stores";
import App from "./App";

import { icons } from "./assets/icons";

React.icons = icons;

if (process.env.NODE_ENV === "production") {
  try {
    console.log = () => {};
    console.error = () => {};
    console.warn = () => {};
    console.debug = () => {};
    // eslint-disable-next-line no-empty
  } catch (error) {}
}

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
