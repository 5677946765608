import React, { useEffect, useLayoutEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useFreezeScroll } from "hooks";

import classNames from "classnames";

import { PageHeader, ReplyInputBox } from "../../..";

import "./DetailRatingPost.scss";
import ItemComment from "../ItemComment/ItemComment";

function DetailRatingPost(props) {
  const {
    show,
    handleClose,
    ratingPost,
    //   handleUnlikeCmt,
    handleLikeCmt,
    handleOpenUserLikedList,
    handleLikeSubCmt,
    //   handleUnLikeSubCmt,
    //   handleSeeAllRating,
    handleSubmitReply,
  } = props;

  const { userInfo } = useSelector((state) => state.root);
  const [focusOnReplyBox, setFocusOnReplyBox] = useState(true);
  const [freezeBodyScroll, releaseBodyScroll] = useFreezeScroll();

  useEffect(() => {
    if (show) {
      // document.body.style.overflow = "hidden";
      freezeBodyScroll();
    }
    // else {
    //   document.body.style.overflow = "auto";
    // }

    return () => {
      // document.body.style.overflow = "auto";
      releaseBodyScroll();
    };
  }, [show]);

  useLayoutEffect(() => {
    if (show) {
      document
        .getElementById("detail-rating-post__post-wrapper")
        ?.scrollIntoView({
          behavior: "smooth",
          inline: "nearest",
          block: "start",
        });

      setFocusOnReplyBox(true);
    }
  }, [show, setFocusOnReplyBox]);

  return (
    <div
      className={classNames(
        "detail-rating-post__container",
        show && "detail-rating-post__container--show",
        !show && "detail-rating-post__container--hide"
      )}
      id="detail-rating-post__container"
    >
      <PageHeader
        title={`Đánh giá của ${ratingPost?.creatorName}`}
        onNavigate={handleClose}
      />

      {/* 
        // * content-container with overflow: overflow; để scrollIntoView ko bị khuất 1 phần (do header fixed)
        // * nếu không có div này bọc thì sẽ lấy theo container bên ngoài và có PageHeader => sẽ bị che
         */}
      <div className="detail-rating-post__content-container">
        {ratingPost && (
          <div
            className="detail-rating-post__post-wrapper"
            id="detail-rating-post__post-wrapper"
          >
            <ItemComment
              rating={ratingPost}
              // handleUnlikeCmt={handleUnlikeCmt}
              handleLikeCmt={handleLikeCmt}
              handleOpenUserLikedList={handleOpenUserLikedList}
              handleLikeSubCmt={handleLikeSubCmt}
              // handleUnLikeSubCmt={handleUnLikeSubCmt}
              handleSeeAllRating={props.gotoViewAll}
              //   handleClickToRepliesButton={handleShowDetailRatingPost(item)}
            />
          </div>
        )}
      </div>

      {ratingPost && (
        <div className="reply-input-box--fixed">
          <ReplyInputBox
            idElement={`reply-box_${ratingPost.id}`}
            idRatingPost={ratingPost?.id}
            handleSubmitReply={handleSubmitReply}
            currentUserAvatar={userInfo?.photoURL}
            showSendButton
            isFocus={focusOnReplyBox}
            handleOutFocus={() => {
              setFocusOnReplyBox(false);
            }}
            isOnDetailRatingPost
          />
        </div>
      )}
    </div>
  );
}

export default DetailRatingPost;
