import {
  VERIFY_LINK_REQUEST,
  VERIFY_LINK_SUCCESS,
  VERIFY_LINK_FAILED,
  GET_USER_BUSINESS_REQUEST,
  GET_USER_BUSINESS_SUCCESS,
  GET_USER_BUSINESS_FAILED,
  CHECK_ACCOUNT_BUSINESS_REQUEST,
  CHECK_ACCOUNT_BUSINESS_SUCCESS,
  CHECK_ACCOUNT_BUSINESS_FAILED,
  GET_TRANSACTION_DETAIL_REQUEST,
  GET_TRANSACTION_DETAIL_SUCCESS,
  GET_TRANSACTION_DETAIL_FAILED,
  GET_ALL_TRANSACTION_HISTORY_UB_REQUEST,
  GET_ALL_TRANSACTION_HISTORY_UB_SUCCESS,
  GET_ALL_TRANSACTION_HISTORY_UB_FAILED,
  GET_LIST_STORE_UB_REQUEST,
  GET_LIST_STORE_UB_SUCCESS,
  GET_LIST_STORE_UB_FAILED,
  CLEAR_ACCOUNT_BUSINESS,
  UPDATE_ALL_TRANSACTION_HISTORY_UB,
  GET_DETAIL_VERIFY_REQUEST,
  GET_LIST_STORE_TYPE_REQUEST,
  GET_LIST_STORE_TYPE_SUCCESS,
  GET_LIST_STORE_TYPE_FAILED,
  UPDATE_CURRENT_LOCATION,
  SET_CURRENT_LOCATION,
  GET_LIST_CITY_REQUEST,
  GET_LIST_CITY_SUCCESS,
  GET_LIST_CITY_FAILED,
  GET_LIST_DISTRICT_REQUEST,
  GET_LIST_DISTRICT_SUCCESS,
  GET_LIST_DISTRICT_FAILED,
  UPDATE_SELECTED_LOCATION,
  STORE_DETAIL_REQUEST,
  CLEAR_LIST_SEARCH_STORE_UB,
  GET_LIST_SEARCH_STORE_UB_REQUEST,
  GET_LIST_SEARCH_STORE_UB_SUCCESS,
  GET_LIST_SEARCH_STORE_UB_FAILED,
  LIST_SEARCH_STORE_UB_HISTORY,
  STORE_IMAGES_REQUEST,
  CREATE_RESERVATION_REQUEST,
  CREATE_RESERVATION_SUCCESS,
  CREATE_RESERVATION_FAILED,
  UPDATE_FILTER_STORE,
  UPDATE_ACCOUNT_BUSINESS,
} from './constants';

export const verifyLinkBusinessRequest = (payload, onSuccess, onError) => ({
  type: VERIFY_LINK_REQUEST,
  payload,
  onSuccess,
  onError,
});

export const verifyLinkBusinessSuccess = payload => ({
  type: VERIFY_LINK_SUCCESS,
  payload,
});

export const verifyLinkBusinessFailed = error => ({
  type: VERIFY_LINK_FAILED,
  error,
});

export const getUserBusinessRequest = (payload, onSuccess, onError) => ({
  type: GET_USER_BUSINESS_REQUEST,
  payload,
  onSuccess,
  onError,
});

export const getUserBusinessSuccess = userBusinessInfo => ({
  type: GET_USER_BUSINESS_SUCCESS,
  userBusinessInfo,
});

export const getUserBusinessFailed = error => ({
  type: GET_USER_BUSINESS_FAILED,
  error,
});

export const checkAccountBusinessRequest = (payload, onSuccess, onError) => ({
  type: CHECK_ACCOUNT_BUSINESS_REQUEST,
  payload,
  onSuccess,
  onError,
});

export const checkAccountBusinessSuccess = userInfo => ({
  type: CHECK_ACCOUNT_BUSINESS_SUCCESS,
  userInfo,
});

export const checkAccountBusinessFailed = error => ({
  type: CHECK_ACCOUNT_BUSINESS_FAILED,
  error,
});

export const getTransactionDetailRequest = (payload, onSuccess, onError) => ({
  type: GET_TRANSACTION_DETAIL_REQUEST,
  payload,
  onSuccess,
  onError,
});

export const getTransactionDetailSuccess = payload => ({
  type: GET_TRANSACTION_DETAIL_SUCCESS,
  payload,
});

export const getTransactionDetailFailed = error => ({
  type: GET_TRANSACTION_DETAIL_FAILED,
  error,
});

export const getAllTransactionHistoryUBRequest = (
  payload,
  onSuccess,
  onError,
) => ({
  type: GET_ALL_TRANSACTION_HISTORY_UB_REQUEST,
  payload,
  onSuccess,
  onError,
});

export const getAllTransactionHistoryUBSuccess = listAllTransactionHistoryUB => ({
  type: GET_ALL_TRANSACTION_HISTORY_UB_SUCCESS,
  listAllTransactionHistoryUB,
});

export const getAllTransactionHistoryUBFailed = error => ({
  type: GET_ALL_TRANSACTION_HISTORY_UB_FAILED,
  error,
});

export const getListStoreUBRequest = (payload, onSuccess, onError) => ({
  type: GET_LIST_STORE_UB_REQUEST,
  payload,
  onSuccess,
  onError,
});

export const getListStoreUBSuccess = payload => ({
  type: GET_LIST_STORE_UB_SUCCESS,
  payload,
});

export const getListStoreUBFailed = error => ({
  type: GET_LIST_STORE_UB_FAILED,
  error,
});

export const getListSearchStoreUBRequest = (payload, onSuccess, onError) => ({
  type: GET_LIST_SEARCH_STORE_UB_REQUEST,
  payload,
  onSuccess,
  onError,
});

export const getListSearchStoreUBSuccess = payload => ({
  type: GET_LIST_SEARCH_STORE_UB_SUCCESS,
  payload,
});

export const getListSearchStoreUBFailed = error => ({
  type: GET_LIST_SEARCH_STORE_UB_FAILED,
  error,
});

export const clearAccountBusiness = () => ({
  type: CLEAR_ACCOUNT_BUSINESS,
});

export const updateTransactionHistoryBusiness = () => ({
  type: UPDATE_ALL_TRANSACTION_HISTORY_UB,
});
export const getDetailVerifyRequest = (payload, onSuccess, onError) => ({
  type: GET_DETAIL_VERIFY_REQUEST,
  payload,
  onSuccess,
  onError,
});

export const getListStoreTypeRequest = (payload, onSuccess, onError) => ({
  type: GET_LIST_STORE_TYPE_REQUEST,
  payload,
  onSuccess,
  onError,
});

export const getListStoreTypeSuccess = payload => ({
  type: GET_LIST_STORE_TYPE_SUCCESS,
  payload,
});

export const getListStoreTypeFailed = error => ({
  type: GET_LIST_STORE_TYPE_FAILED,
  error,
});

export const setCurrentLocation = currentLocation => ({
  type: SET_CURRENT_LOCATION,
  currentLocation,
});

export const updateSelectedLocation = selectedLocation => ({
  type: UPDATE_SELECTED_LOCATION,
  selectedLocation,
});

export const updateCurrentLocation = currentLocation => ({
  type: UPDATE_CURRENT_LOCATION,
  currentLocation,
});

export const getListCityRequest = (payload, onSuccess, onError) => ({
  type: GET_LIST_CITY_REQUEST,
  payload,
  onSuccess,
  onError,
});

export const getListCitySuccess = payload => ({
  type: GET_LIST_CITY_SUCCESS,
  payload,
});

export const getListCityFailed = error => ({
  type: GET_LIST_CITY_FAILED,
  error,
});

export const getListDistrictRequest = (payload, onSuccess, onError) => ({
  type: GET_LIST_DISTRICT_REQUEST,
  payload,
  onSuccess,
  onError,
});

export const storeDetailRequest = (payload, onSuccess, onError) => ({
  type: STORE_DETAIL_REQUEST,
  payload,
  onSuccess,
  onError,
});

export const getListDistrictSuccess = payload => ({
  type: GET_LIST_DISTRICT_SUCCESS,
  payload,
});

export const getListDistrictFailed = error => ({
  type: GET_LIST_DISTRICT_FAILED,
  error,
});

export const clearListSearchStoreUB = payload => ({
  type: CLEAR_LIST_SEARCH_STORE_UB,
  payload,
});

export const saveListSearchStoreUBHistory = payload => ({
  type: LIST_SEARCH_STORE_UB_HISTORY,
  payload,
});

export const storeImagesRequest = (payload, onSuccess, onError) => ({
  type: STORE_IMAGES_REQUEST,
  payload,
  onSuccess,
  onError,
});

export const createReservationRequest = (payload, onSuccess, onError) => ({
  type: CREATE_RESERVATION_REQUEST,
  payload,
  onSuccess,
  onError,
});

export const createReservationSuccess = payload => ({
  type: CREATE_RESERVATION_SUCCESS,
  payload,
});

export const createReservationFailed = error => ({
  type: CREATE_RESERVATION_FAILED,
  error,
});

export const updateFilterStore = payload => ({
  type: UPDATE_FILTER_STORE,
  payload,
});

export const updateAccountBusiness = isUtopBusiness => ({
  type: UPDATE_ACCOUNT_BUSINESS,
  isUtopBusiness,
});

