export const MY_VOUCHER_DETAIL_REQUEST = 'myVoucher/MY_VOUCHER_DETAIL_REQUEST';
export const MY_VOUCHER_DETAIL_SUCCESS = 'myVoucher/MY_VOUCHER_DETAIL_SUCCESS';
export const MY_VOUCHER_DETAIL_FAILED = 'myVoucher/MY_VOUCHER_DETAIL_FAILED';

export const MY_COUPON_DETAIL_REQUEST = 'myVoucher/MY_COUPON_DETAIL_REQUEST';
export const MY_COUPON_DETAIL_SUCCESS = 'myVoucher/MY_COUPON_DETAIL_SUCCESS';
export const MY_COUPON_DETAIL_FAILED = 'myVoucher/MY_COUPON_DETAIL_FAILED';

export const MY_VOUCHER_DETAIL_BY_USER_REQUEST =
  'myVoucher/MY_VOUCHER_DETAIL_BY_USER_REQUEST';
export const MY_VOUCHER_DETAIL_BY_USER_SUCCESS =
  'myVoucher/MY_VOUCHER_DETAIL_BY_USER_SUCCESS';
export const MY_VOUCHER_DETAIL_BY_USER_FAILED =
  'myVoucher/MY_VOUCHER_DETAIL_BY_USER_FAILED';
