export const listOrderHistory = {
  isInit: true,
  hasMore: false,
  listOrderHistoryItem: [],
  currentCount: 0,
};
export const isLoading = {
  LIST_ORDER_HISTORY: false,
  FILTER_ORDER_HISTORY: false,
};
