export const sidebarShow = "responsive";
export const asideShow = false;
export const darkMode = false;
export const user = null;
export const searchList = []; // *
export const localeCode = "vi";
export const isOffline = false;
export const displayElements = {
  isShowSuggest: false,
  isShowHistory: false,
};
export const userInfo = null;
export const chosenFilterData = {};

export const listFeed = {
  value: [],
  total: 0,
};
export const isLoading = { SEARCH_LIST: false };
export const isLoadingUser = true; // true de force cac api khac doi firebase load user xong

export const listSearchSuggestion = {
  value: [],
  total: 0,
};
