import {
  GET_DEFAULT_ADDRESS_USER_REQUEST,
  GET_DEFAULT_ADDRESS_USER_SUCCESS,
  GET_DEFAULT_ADDRESS_USER_FAILED,
  CALCULATE_SHIPPING_REQUEST,
  CALCULATE_SHIPPING_SUCCESS,
  CALCULATE_SHIPPING_FAILED,
  SAVE_DEFAULT_ADDRESS_USER,
  CREATE_DEFAULT_ADDRESS_REQUEST,
} from './constants';

export const calculateShippingRequest = payload => ({
  type: CALCULATE_SHIPPING_REQUEST,
  payload,
});

export const calculateShippingSuccess = payload => ({
  type: CALCULATE_SHIPPING_SUCCESS,
  payload,
});

export const calculateShippingFailure = payload => ({
  type: CALCULATE_SHIPPING_FAILED,
  payload,
});

export const getDefaultAddressUserRequest = payload => ({
  type: GET_DEFAULT_ADDRESS_USER_REQUEST,
  payload,
});

export const getDefaultAddressUserSuccess = payload => ({
  type: GET_DEFAULT_ADDRESS_USER_SUCCESS,
  payload,
});

export const getDefaultAddressUserFailure = payload => ({
  type: GET_DEFAULT_ADDRESS_USER_FAILED,
  payload,
});

export const saveDefaultAddressUser = payload => ({
  type: SAVE_DEFAULT_ADDRESS_USER,
  payload,
});

export const createDefaultAddressRequest = (payload, onSuccess, onFailed) => ({
  type: CREATE_DEFAULT_ADDRESS_REQUEST,
  payload,
  onSuccess,
  onFailed,
});
