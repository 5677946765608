import {
  GET_LIST_CARD_REQUEST,
  GET_LIST_CARD_FOXPAY_REQUEST,
  GET_LIST_CARD_MEMBER_REQUEST,
  CANCEL_LINK_CARD_REQUEST,
  CANCEL_LINK_CARD_FOXPAY_REQUEST,
  CREATETOKENIZATION_REQUEST,
  CHECK_STATUS_CARD_MEMBER_REQUEST,
  GET_INFO_TENANT_REQUEST,
  LINK_CARD_MEMBER_REQUEST,
  CANCEL_LINK_CARD_MEMBER_REQUEST,
  UPDATE_INFO_CARD_MEMBER_REQUEST,
} from "./constants";
import { getListCardFailure, getListCardSuccess } from "./actions";
import { put, takeLatest, fork } from "redux-saga/effects";
import {
  getListCardApi,
  getListCardFoxpayApi,
  cancelLinkCardApi,
  cancelLinkCardFoxpayApi,
  createTokenizationApi,
  checkStatusCardMemberApi,
  getInfoTenantApi,
  getListCardMemberApi,
  linkCardMemberApi,
  cancelLinkCardMemberApi,
  updateInfoCardMemberApi,
} from "@shared/services/api/card";
import { callApi } from "helpers/callApi";

export function* getListCardSaga(obj) {
  const { onSuccess, onError } = obj;
  try {
    const res = yield callApi(getListCardApi, {});
    yield put(getListCardSuccess(res));
    if (typeof onSuccess === "function") {
      onSuccess(res);
    } else {
      yield put(getListCardFailure(res));
      if (typeof onError === "function") {
        onError();
      }
    }
  } catch (err) {
    yield put(getListCardFailure(err));
    if (typeof onError === "function") {
      onError();
    }
  }
}

export function* getListCardFoxpaySaga(obj) {
  const { onSuccess, onError } = obj;
  try {
    const res = yield callApi(getListCardFoxpayApi, {});
    if (typeof onSuccess === "function") {
      onSuccess(res);
    } else {
      if (typeof onError === "function") {
        onError();
      }
    }
  } catch (err) {
    if (typeof onError === "function") {
      onError(err);
    }
  }
}
export function* getListCardMemberSaga(obj) {
  const { onSuccess, onError } = obj;
  try {
    const res = yield callApi(getListCardMemberApi, {});
    if (typeof onSuccess === "function") {
      onSuccess(res);
    } else {
      if (typeof onError === "function") {
        onError();
      }
    }
  } catch (err) {
    if (typeof onError === "function") {
      onError(err);
    }
  }
}
export function* cancelLinkCardSaga(obj) {
  const { cardID, onSuccess, onError } = obj;
  try {
    const res = yield callApi(cancelLinkCardApi, cardID);
    if (typeof onSuccess === "function") {
      onSuccess(res);
    } else {
      if (typeof onError === "function") {
        onError();
      }
    }
  } catch (err) {
    if (typeof onError === "function") {
      onError(err);
    }
  }
}
export function* cancelLinkCardFoxpaySaga(obj) {
  const { cardID, onSuccess, onError } = obj;
  try {
    const res = yield callApi(cancelLinkCardFoxpayApi, cardID);
    if (typeof onSuccess === "function") {
      onSuccess(res);
    } else {
      if (typeof onError === "function") {
        onError();
      }
    }
  } catch (err) {
    if (typeof onError === "function") {
      onError(err);
    }
  }
}
export function* createTokenizationSaga(obj) {
  const { onSuccess, onError } = obj;
  try {
    const res = yield callApi(createTokenizationApi, {});
    if (typeof onSuccess === "function") {
      onSuccess(res);
    } else {
      if (typeof onError === "function") {
        onError();
      }
    }
  } catch (err) {
    if (typeof onError === "function") {
      onError(err);
    }
  }
}

export function* checkStatusCardMemberSaga(obj) {
  const { cardID, onSuccess, onError } = obj;
  try {
    const res = yield callApi(checkStatusCardMemberApi, cardID);
    if (typeof onSuccess === "function") {
      onSuccess(res);
    } else {
      if (typeof onError === "function") {
        onError();
      }
    }
  } catch (err) {
    if (typeof onError === "function") {
      onError(err);
    }
  }
}

export function* getInfoTenantSaga(obj) {
  const { tenantID, onSuccess, onError } = obj;
  try {
    const res = yield callApi(getInfoTenantApi, tenantID);
    if (typeof onSuccess === "function") {
      onSuccess(res);
    } else {
      if (typeof onError === "function") {
        onError();
      }
    }
  } catch (err) {
    if (typeof onError === "function") {
      onError(err);
    }
  }
}
export function* linkCardMemberSaga(obj) {
  const { payload, onSuccess, onError } = obj;
  try {
    const res = yield callApi(linkCardMemberApi, payload);
    if (typeof onSuccess === "function") {
      onSuccess(res);
    } else {
      if (typeof onError === "function") {
        onError();
      }
    }
  } catch (err) {
    if (typeof onError === "function") {
      onError(err);
    }
  }
}
export function* cancelLinkCardMemberSaga(obj) {
  const { cardID, onSuccess, onError } = obj;
  try {
    const res = yield callApi(cancelLinkCardMemberApi, cardID);
    if (typeof onSuccess === "function") {
      onSuccess(res);
    } else {
      if (typeof onError === "function") {
        onError();
      }
    }
  } catch (err) {
    if (typeof onError === "function") {
      onError(err);
    }
  }
}
export function* updateInfoCardMemberSaga(obj) {
  const { payload, onSuccess, onError } = obj;
  try {
    const res = yield callApi(updateInfoCardMemberApi, payload);
    if (typeof onSuccess === "function") {
      onSuccess(res);
    } else {
      if (typeof onError === "function") {
        onError();
      }
    }
  } catch (err) {
    if (typeof onError === "function") {
      onError(err);
    }
  }
}

function* getListCardWatch() {
  yield takeLatest(GET_LIST_CARD_REQUEST, getListCardSaga);
  yield takeLatest(GET_LIST_CARD_FOXPAY_REQUEST, getListCardFoxpaySaga);
  yield takeLatest(GET_LIST_CARD_MEMBER_REQUEST, getListCardMemberSaga);
  yield takeLatest(CANCEL_LINK_CARD_REQUEST, cancelLinkCardSaga);
  yield takeLatest(CANCEL_LINK_CARD_FOXPAY_REQUEST, cancelLinkCardFoxpaySaga);
  yield takeLatest(CREATETOKENIZATION_REQUEST, createTokenizationSaga);
  yield takeLatest(CHECK_STATUS_CARD_MEMBER_REQUEST, checkStatusCardMemberSaga);
  yield takeLatest(GET_INFO_TENANT_REQUEST, getInfoTenantSaga);
  yield takeLatest(LINK_CARD_MEMBER_REQUEST, linkCardMemberSaga);
  yield takeLatest(CANCEL_LINK_CARD_MEMBER_REQUEST, cancelLinkCardMemberSaga);
  yield takeLatest(UPDATE_INFO_CARD_MEMBER_REQUEST, updateInfoCardMemberSaga);
}

export default function* rootChild() {
  yield fork(getListCardWatch);
}
