import { FEED_TYPES } from "constants/types";
import request, { requestWithoutToken } from "services/request";
import { encodeQueryData, encodeQueryDataV2 } from "utils/stringFormat";
import * as TYPE from "../store/constants";

const api = {
  // !deprecated
  [TYPE.GET_LIST_VOUCHER.request]: async (params) => {
    const query = encodeQueryData({
      lat: params.lat,
      long: params.long,
      skip: params.skip,
      take: params.take,
    });

    return requestWithoutToken(`/feed/news${query}`, "GET", null, {
      "content-type": "application/json",
      "Access-Control-Allow-Credentials": true,
      Authorization: "",
      "Ocp-Apim-Subscription-Key":
        process.env.REACT_APP_OCP_APIM_SUBSCRIPTION_KEY,
      "X-Client": "Web",
    });
  },

  // new home
  [TYPE.GET_LIST_FEED.request]: async (params) => {
    const query = encodeQueryDataV2({
      // ! hard code loc lai ket qua vi chua handle Product & Mall
      feedTypes: [
        FEED_TYPES.STORE.BEKey, // .key: lowercase, .BEKey: capitalized
        FEED_TYPES.MARKET.BEKey,
        FEED_TYPES.VOUCHER.BEKey,
      ],
      latitude: params.lat,
      longitude: params.long,
      skip: params.skip,
      take: params.take,
    });

    return requestWithoutToken(`/home/Feed${query}`, "GET");
  },

  // * GET_LIST_STORE_OF_MERCHANT
  [TYPE.GET_LIST_STORE_OF_MERCHANT.request]: async (params) => {
    const query = encodeQueryDataV2({
      merchantId: params.merchantId,
      isGrouping: false, // search store của 1 merchant nên ko group feed theo merchant
      feedTypes: params.feedTypes, // can be an Array when use encodeQueryDataV2
      latitude: params.latitude,
      longitude: params.longitude,
      skip: params.skip,
      take: params.take,
    });

    return requestWithoutToken(`/home/Feed/Search${query}`, "GET");
  },

  [TYPE.GET_LIST_CITY.request]: async () => {
    return requestWithoutToken(`/cms/core/getCities`, "GET");
  },

  [TYPE.FILTER_VOUCHER_STORE.request]: async (params) => {
    const query = encodeQueryData({
      lat: params.lat,
      long: params.long,
      skip: params.skip,
      take: params.take,
    });

    return requestWithoutToken(`/feed/news${query}`, "GET", null, {
      "content-type": "application/json",
      "Access-Control-Allow-Credentials": true,
      Authorization: "",
      "Ocp-Apim-Subscription-Key":
        process.env.REACT_APP_OCP_APIM_SUBSCRIPTION_KEY,
      "X-Client": "Web",
    });
  },

  [TYPE.GET_SHORTCUTS.request]: async () => {
    return requestWithoutToken(`/cms/core/v4/shortcuts`, "GET", null, {
      "content-type": "application/json",
      "Access-Control-Allow-Credentials": true,
      "Ocp-Apim-Subscription-Key":
        process.env.REACT_APP_OCP_APIM_SUBSCRIPTION_KEY,
      "Ocp-Apim-Trace": true,
      "X-Client": "Web",
    });
  },
  [TYPE.GET_SHORTCUTS_PERSONALIZED.request]: async () => {
    return request(`/component/shortcuts?type=Web`, "GET", null, {
      "content-type": "application/json",
      "Access-Control-Allow-Credentials": true,
      "Ocp-Apim-Subscription-Key":
        process.env.REACT_APP_OCP_APIM_SUBSCRIPTION_KEY,
      "X-Client": "Web",
    });
  },
};
export default api;
