import {
  GET_MOBILE_CARD_LIST_REQUEST,
  GET_MOBILE_CARD_LIST_SUCCESS,
  GET_MOBILE_CARD_LIST_FAILED,
  GET_BUY_CARD_LIST_REQUEST,
  GET_BUY_CARD_LIST_SUCCESS,
  GET_BUY_CARD_LIST_FAILED,
  CREATE_ORDER_REQUEST,
  CREATE_ORDER_SUCCESS,
  CREATE_ORDER_FAILED,
  PAYMENT_REQUEST,
  PAYMENT_SUCCESS,
  PAYMENT_FAILED,
} from './constants';

// list mobile card
export const getMobileCardListHandle = (payload, onSuccess, onFailed) => ({
  type: GET_MOBILE_CARD_LIST_REQUEST,
  payload,
  onSuccess,
  onFailed,
});

export const getMobileCardListSuccess = mobileCard => ({
  type: GET_MOBILE_CARD_LIST_SUCCESS,
  mobileCard,
});

export const getMobileCardListFailed = error => ({
  type: GET_MOBILE_CARD_LIST_FAILED,
  error,
});

//buy card
export const getBuyCardListHandle = (payload, onSuccess, onFailed) => ({
  type: GET_BUY_CARD_LIST_REQUEST,
  payload,
  onSuccess,
  onFailed,
});

export const getBuyCardListSuccess = buyCard => ({
  type: GET_BUY_CARD_LIST_SUCCESS,
  buyCard,
});

export const getBuyCardListFailed = error => ({
  type: GET_BUY_CARD_LIST_FAILED,
  error,
});

//create order
export const createOrderHandle = (payload, onSuccess, onFailed) => ({
  type: CREATE_ORDER_REQUEST,
  payload,
  onSuccess,
  onFailed,
});

export const createOrderSuccess = buyCard => ({
  type: CREATE_ORDER_SUCCESS,
  buyCard,
});

export const createOrderFailed = error => ({
  type: CREATE_ORDER_FAILED,
  error,
});

//payment
export const paymentHandle = (payload, onSuccess, onFailed) => ({
  type: PAYMENT_REQUEST,
  payload,
  onSuccess,
  onFailed,
});

export const paymentSuccess = payment => ({
  type: PAYMENT_SUCCESS,
  payment,
});

export const paymentFailed = error => ({
  type: PAYMENT_FAILED,
  error,
});
