import React from "react";
import "./DividerTicket.scss";

function DividerTicket(props) {
  const { backgroundColor, height } = props;

  return (
    <div
      className="__divider--ticket"
      style={{
        height,
        backgroundColor,
      }}
    >
      <div className="__divider--ticket__dashed-line" />
    </div>
  );
}

export default DividerTicket;
