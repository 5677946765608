// import { createStore } from "redux-dynamic-modules";
// import { getSagaExtension } from "redux-dynamic-modules-saga";
import  { history } from "views/common/store";

// const store = createStore(
//   { initialState: {}, extensions: [getSagaExtension()] },
//   root()
// );

export { history };
// export default store;
