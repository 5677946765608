import { callApi } from "helpers/callApi";
import { fork, put, takeEvery, takeLatest } from "redux-saga/effects";

import types from "./types";

import {
  getProductMarketBySearchSuccess,
  getProductMarketBySearchFailed,
  getProductDetailMarketFailed,
  getProductDetailMarketSuccess,
  getProductMarketBySearchRequest,
  setIsEndSearchMarket,
} from "./actions";
import {
  getProductMarketBySearchApi,
  getProductMarketDetailApi,
} from "@shared/services/api/market";

export function* getProductMarketBySearchSaga(obj) {
  const { payload } = obj;
  try {
    const res = yield callApi(getProductMarketBySearchApi, payload);
    let data = res?.data;
    let dataOutOfStock = [];
    let dataNormal = [];

    if (data.length > 0) {
      data.forEach((element) => {
        if (element.isSelling === 1 && element.isOutOfStock) {
          dataOutOfStock.push(element);
        } else {
          dataNormal.push(element);
        }
      });
      console.log(dataOutOfStock);
      console.log(dataNormal);

      yield put(
        getProductMarketBySearchSuccess({
          skip: payload.skipCount + 20 || 0,
          data: dataNormal,
          dataOutOfStock: dataOutOfStock,
          isLoadNew: payload.isLoadNew,
          valueSearch: payload.valueSearch,
        })
      );
      if (dataNormal.length === 0) {
        yield put(
          getProductMarketBySearchRequest({
            valueSearch: payload.valueSearch,
            skipCount: payload.skipCount + 20,
            isLoadNew: false,
            storeId: payload.storeId,
            merchantId: payload.merchantId,
            isEndSearchMarket: payload.isEndSearchMarket,
          })
        );
      } else {
        if (data.length < 20) {
          yield put(
            setIsEndSearchMarket({
              isEndSearchMarket: true,
              valueSearch: payload.valueSearch,
            })
          );
        }
      }
    } else {
      if (payload.isLoadNew) {
        yield put(
          getProductMarketBySearchSuccess({
            skip: payload.skipCount + 20 || 0,
            data: dataNormal,
            dataOutOfStock: dataOutOfStock,
            isLoadNew: payload.isLoadNew,
            valueSearch: payload.valueSearch,
          })
        );
      } else if (!payload.isEndSearchMarket) {
        yield put(
          setIsEndSearchMarket({
            isEndSearchMarket: true,
            valueSearch: payload.valueSearch,
          })
        );
      }
    }
  } catch (err) {
    yield put(getProductMarketBySearchFailed(err));
  }
}

export function* getProductMarketDetailSaga(obj) {
  const { payload } = obj;
  try {
    const res = yield callApi(getProductMarketDetailApi, payload);

    yield put(getProductDetailMarketSuccess(res?.data));
  } catch (err) {
    yield put(getProductDetailMarketFailed(err));
  }
}

function* watchCommon() {
  yield takeLatest(types.SEARCH_MARKET_REQUEST, getProductMarketBySearchSaga);
  yield takeLatest(
    types.GET_PRODUCT_DETAIL_MARKET_REQUEST,
    getProductMarketDetailSaga
  );
}

export default function* rootChild() {
  yield fork(watchCommon);
}
