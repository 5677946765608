import {END_POINTS} from '@shared/constants/apiUrl';
import {APIUtils} from 'utils';

export function getStatusOrderDetailApi(payload) {
  return APIUtils.get(
    END_POINTS.GET_STATUS_ORDER_DETAIL + `?orderId=${payload.orderId}`,
  );
}

export const pushCancelOrderApi = async payload => {
  return await APIUtils.post(END_POINTS.PUSH_CANCEL_ORDER, {
    body: payload,
  });
};


export function getStatusOrderDetailNewsApi(payload) {
  return APIUtils.get(
    END_POINTS.GET_STATUS_ORDER_DETAIL_NEWS + `/app?orderId=${payload.orderId}`,
  );
}

export const pushCancelOrderNewsApi = async payload => {
  return await APIUtils.post(END_POINTS.PUSH_CANCEL_ORDER_NEWS, {
    body: payload,
  });
};

export function createOrderNewsApi(payload) {
  return APIUtils.post(END_POINTS.CREATE_ORDER_NEWS, {
    body: payload,
  });
}
