/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState } from "react";

import InputNumber from "views/common/component/InputNumber";

import { toVND } from "utils/currencyFormat";
import { calcUnitPrice } from "utils/calcFnBItemPrice";
import classNames from "classnames";
import { icons } from "assets/icons";

import "./orderMenu.scss";

import { FNB_OPTION_TYPES, FOOD_ITEM_TYPES } from "constants/types";

import { CheckBoxOption } from "views/common/component/FnBOption";
import IMAGES from "assets/img";
import { Markup } from "interweave";
import { AlertMsg } from "utils";
import RadioOption from "../RadioOption";
import CustomCarousel from "../StoreDetailInfo/components/CustomCarousel";
// import { CouponSolidYellowIcon } from "assets/svg";

const INPUT_MAX_VALUE = 999;
const INPUT_MIN_VALUE = 1;

const OrderMenu = (props) => {
  const {
    storeId,
    id,
    code,
    imageUrl,
    imageUrls,
    name,
    price,
    listPrice,
    description,
    attributes,
    sizes,
    toppings,
    isStoreClosed,
    types = FOOD_ITEM_TYPES.DETAILS,

    rawData, // full data from API

    // functions
    handleClose,
    handleTempSaveInfoOfItemToAdd,
    handleToggleModal,
    percentDiscount,
    saleTagUrl,
  } = props;

  const [unitPrice, setUnitPrice] = useState(price);
  const [originPrice, setOriginPrice] = useState(price);
  const [quantity, setQuantity] = useState(1);
  const [chosenSizeOption, setChosenSizeOption] = useState(sizes?.[0]);
  const [chosenAttributeOptions, setChosenAttributeOptions] = useState([]);
  const [chosenToppingOptions, setChosenToppingOptions] = useState([]);

  // load default choices
  useEffect(() => {
    setQuantity(1);
    setChosenSizeOption(sizes?.[0]);
    setChosenAttributeOptions([]);
    setChosenToppingOptions([]);
  }, [sizes]);

  useEffect(() => {
    const newUnitPrice = calcUnitPrice(
      price,
      chosenSizeOption,
      chosenToppingOptions
    );

    setUnitPrice(newUnitPrice);
  }, [price, chosenSizeOption, chosenToppingOptions]);

  useEffect(() => {
    const newUnitPrice = calcUnitPrice(price, chosenSizeOption);

    setOriginPrice(newUnitPrice);
  }, [price, chosenSizeOption]);

  // handle quantity
  const handleIncreaseQuantity = () => {
    setQuantity((prevQuantity) => {
      if (prevQuantity < INPUT_MAX_VALUE) {
        return prevQuantity + 1;
      }

      // todo: show helper
      return prevQuantity;
    });
  };

  const handleDecreaseQuantity = () => {
    setQuantity((prevQuantity) => {
      if (prevQuantity > INPUT_MIN_VALUE) {
        return prevQuantity - 1;
      }

      // todo: show helper
      return prevQuantity;
    });
  };

  // handle quantity - by keyboard - only commit number
  const handleChangeQuantity = (e) => {
    const valueAsNumber = parseInt(e.target.value, 10);
    e.target.value = valueAsNumber;

    if (!valueAsNumber) {
      setQuantity(0);
      return;
    }

    if (valueAsNumber > INPUT_MAX_VALUE) {
      setQuantity(INPUT_MAX_VALUE);
      return;
    }

    setQuantity(valueAsNumber);
  };

  // handle option check
  const handleCheckOption = (type, data) => () => {
    if (type === FNB_OPTION_TYPES.SIZE) {
      setChosenSizeOption(data);
    }
    if (type === FNB_OPTION_TYPES.ATTRIBUTE) {
      setChosenAttributeOptions((prev) => {
        return [
          ...prev.filter(
            (attributeOption) => attributeOption.Name !== data.Name
          ),
          data,
        ];
      });
    }
    if (type === FNB_OPTION_TYPES.TOPPING) {
      setChosenToppingOptions((prev) => {
        // check if already exist => remove
        const toppingAlreadyChosen = prev?.find(
          (toppingOption) => toppingOption?.code === data?.code
        );

        if (toppingAlreadyChosen) {
          return prev?.filter(
            (toppingOption) => toppingOption?.code !== data?.code
          );
        }

        return [...prev, data];
      });
    }
  };

  const handleClickAddItemToCart = (e) => {
    e.preventDefault();

    // get the latest info of Cart on LocalStorage
    let productCode =
      typeof rawData !== "undefined" && rawData.code
        ? rawData?.code
        : rawData?.data?.code;
    let sizeName = null;
    if (typeof rawData !== "undefined" && rawData.sizes.length > 0) {
      let isCheckedSize = false;
      sizes.forEach((size) => {
        if (size.Code === chosenSizeOption.Code) {
          isCheckedSize = true;
          productCode = size.Code;
          sizeName = size.Name;
        }
      });
      if (!isCheckedSize) {
        // ReactUtils.alertMsg('basic', strings('SmartOrderItemModal.label1'));
        AlertMsg("basic", "Vui lòng chọn size");
        // setIsProcess(false);
        return;
      }
    }
    const listAttribute = [];
    if (chosenAttributeOptions && chosenAttributeOptions.length > 0) {
      for (let i = 0; i < chosenAttributeOptions.length; i++) {
        // if (chosenAttributeOptions[i].selectedAttribute === undefined) {
        //   console.log("listAttribute");
        //   AlertMsg("basic", `Vui lòng chọn ${attributes[i].Name}`);
        //   // setIsProcess(false);
        //   return;
        // }
        const item = {
          Name: chosenAttributeOptions[i].Name,
          Value: chosenAttributeOptions[i]?.Value,
        };
        listAttribute.push(item);
      }
    }

    const listTopping = [];
    chosenToppingOptions.forEach((item) => {
      listTopping.push(item);
    });
    const itemCart = {
      data: rawData,
      dataInfoProduct: {
        quantity,
        productCode,
        productId: rawData?.id,
        attributes: listAttribute,
        toppings: listTopping,
        totalPrice: unitPrice * quantity,
        index: props?.itemCart + 1 || 0,
        sizeName,
        sizeOption: chosenSizeOption,
      },
    };
    handleClose();
    setTimeout(() => {
      if (itemCart && typeof itemCart.data !== "undefined") {
        props.updateCart(itemCart);
        // props.setIsShowModal(false);
      }
    }, 200);
  };

  const validImages = useMemo(() => {
    if (!imageUrls) {
      return [];
    }

    const validItems = imageUrls.filter((item) => item.imageUrl !== null);

    return validItems;
  }, [imageUrls]);

  const renderText = useMemo(() => {
    if (!rawData.isSelling) {
      return "Ngoài giờ bán";
    }
    if (rawData.isOutOfStock) {
      return "Hết món";
    }
    return "Thêm vào giỏ";
  }, [rawData]);

  const renderDisabled = useMemo(() => {
    if (isStoreClosed) {
      return true;
    }
    if (rawData.isOutOfStock) {
      return true;
    }
    if (!rawData.isSelling) {
      return true;
    }
    return false;
  }, [isStoreClosed, rawData]);

  return (
    <div className="order-menu">
      <div className="wrapper px-3">
        <div className="product-image__container">
          {validImages.length > 0 ? (
            <CustomCarousel
              images={validImages}
              saleTagUrl={saleTagUrl}
              imgClassName="product-image"
            />
          ) : (
            <>
              <img
                className="product-image"
                src={imageUrl || IMAGES.FoodPlaceholderX3}
                alt=""
              />
              {!!saleTagUrl && (
                <img src={saleTagUrl} className="icon-saleTagUrl" />
              )}
            </>
          )}
        </div>
        {percentDiscount > 0 && (
          <>
            <img
              src={icons.iconFlag}
              style={{
                height: "32px",
                width: "30px",
                objectFit: "contain",
                position: "absolute",
                top: "19px",
                right: "65px",
                zIndex: 1,
              }}
            />
            <div
              className={classNames("text-truncate _fs-13px _fw-600")}
              style={{
                position: "absolute",
                top: "26px",
                right: "68px",
                fontSize: "10px",
                zIndex: 1,
              }}
            >
              <span className="color-black">-{percentDiscount}%</span>
            </div>
          </>
        )}

        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <div
            style={{
              background: "#FFB600",
              marginTop: "16px",
              marginLeft: "-16px",
              padding: "5px 16px 5px 16px",
              borderBottomRightRadius: "4px",
              borderTopRightRadius: "4px",
              fontWeight: "500",
            }}
          >
            {rawData?.category?.name?.vi}
          </div>
        </div>
        <p className="mb-0 mt-3 _fw-600 _fs-14px color-chinese_black">{name}</p>
        <div>
          <span className="color-orange _fs-15px _fw-600 mr-2">
            {toVND(Number(originPrice))}
          </span>
          {/* {listPrice && (
            <span className="color-spanish_gray _fs-13px _fw-400 text-decoration-line-through">
              {toVND(Number(listPrice))}
            </span>
          )} */}
        </div>
        <p
          className={classNames(
            "mb-0 mt-1",
            rawData.isOutOfStock && "color-danger",
            !rawData.isSelling && "color-danger"
          )}
        >
          <Markup
            content={
              !rawData.isSelling
                ? "Ngoài giờ bán"
                : rawData.isOutOfStock
                ? "Hết món"
                : types === FOOD_ITEM_TYPES.DETAILS && description
            }
          />
        </p>

        {/* Options */}
        <div className="" style={{ marginTop: 22 }}>
          {sizes?.length > 0 && (
            <div className="my-3">
              <p className="mb-2 _fw-600 _fs-14px">
                Tùy chọn size <span className="_fw-400 _fs-12px">(chọn 1)</span>
              </p>

              <div className="options-list px-2">
                {sizes.map((size) => (
                  <div key={size.Code}>
                    <RadioOption
                      type={FNB_OPTION_TYPES.SIZE}
                      code={size.Code}
                      inputId={size.Code}
                      groupName={`${code}_sizes`}
                      name={size.Name}
                      productPrice={price}
                      extraPrice={size.ExtraPrice}
                      checked={size?.Code === chosenSizeOption?.Code}
                      handleCheckOption={handleCheckOption}
                    />
                    <hr className="_my-12px" />
                  </div>
                ))}
              </div>
            </div>
          )}

          {attributes?.map((attribute) => (
            <div className="my-3" key={attribute.Name}>
              <p className="mb-2 _fw-600 _fs-14px">
                Tùy chọn {attribute.Name}{" "}
                <span className="_fw-400 _fs-12px">(chọn 1)</span>
              </p>

              <div className="options-list px-2">
                {attribute?.Value?.map((attributeValue) => (
                  <div key={attributeValue}>
                    <RadioOption
                      type={FNB_OPTION_TYPES.ATTRIBUTE}
                      code={`${code}_${attribute.Name}_${attributeValue}`}
                      inputId={`${attribute.Name}_${attributeValue}`}
                      groupName={`${attribute.Name}`}
                      name={attributeValue}
                      checked={chosenAttributeOptions?.find(
                        (chosenAttributeOption) =>
                          chosenAttributeOption.Name === attribute.Name &&
                          chosenAttributeOption.Value === attributeValue
                      )}
                      handleCheckOption={handleCheckOption}
                    />
                    <hr className="_my-12px" />
                  </div>
                ))}
              </div>
            </div>
          ))}

          {/* Toppings */}
          {toppings?.length > 0 && (
            <div className="my-3">
              <p className="mb-2 _fw-600 _fs-14px">
                Tùy chọn món thêm{" "}
                <span className="_fw-400 _fs-12px">(không bắt buộc chọn)</span>
              </p>

              <div className="options-list px-2">
                {toppings?.map((topping) => (
                  <div key={topping.id}>
                    <CheckBoxOption
                      type={FNB_OPTION_TYPES.TOPPING}
                      id={topping.id}
                      code={topping?.code}
                      name={topping?.name}
                      price={topping?.price?.default}
                      listPrice={topping.price?.list}
                      description={topping?.description}
                      shortDescription={topping?.shortDescription}
                      handleCheckOption={handleCheckOption}
                      checked={chosenToppingOptions?.find(
                        (chosenToppingOption) =>
                          chosenToppingOption?.code === topping?.code
                      )}
                    />
                    <hr className="_my-12px" />
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>

        {/* Quantity */}
        <div className="d-flex justify-content-between align-items-center _py-12px">
          <span className="color-chinese_black _fw-600 _fs-14px">Số lượng</span>
          <div>
            <InputNumber
              value={quantity}
              handleIncrease={handleIncreaseQuantity}
              handleDecrease={handleDecreaseQuantity}
              handleChange={handleChangeQuantity}
              maxValue={INPUT_MAX_VALUE}
              disabled={renderDisabled}
            />
          </div>
        </div>
      </div>

      {/* Bottom */}
      <div
        className="bgr-white _py-12px px-3
      d-flex align-items-center
      position-sticky bottom-0 menu-bottom"
      >
        <span className="color-orange _fw-700 _fs-15px mr-auto">
          {toVND(unitPrice * quantity)}
        </span>
        <button
          type="button"
          className={classNames(
            "w-50 _py-10px _border-none bgr-linear rounded-pill",
            (chosenAttributeOptions?.length < attributes?.length ||
              quantity === 0 ||
              renderDisabled) &&
              "bgr--disabled"
          )}
          onClick={handleClickAddItemToCart}
          disabled={
            chosenAttributeOptions?.length < attributes?.length ||
            quantity === 0 ||
            renderDisabled
          }
        >
          <span className="_fw-700 _fs-14px color-white">{renderText}</span>
        </button>
      </div>
    </div>
  );
};

export default OrderMenu;
