import { put, takeLatest, fork } from "redux-saga/effects";
import { GET_LIST_HISTORY_ORDER_REQUEST } from "./constants";
import {
  getListHistoryOrderSuccess,
  getListHistoryOrderFailed,
} from "./actions";
import { getListHistoryOrderApi } from "@shared/services/api/historyOrder";
import { listHistoryOrderParser } from "./parser";
import { callApi } from "helpers/callApi";

export function* getListHistoryOrderSaga(obj) {
  const { payload = {}, onSuccess } = obj;
  try {
    const res = yield callApi(getListHistoryOrderApi, payload);
    yield put(
      getListHistoryOrderSuccess({
        skip: payload.skipCount + 10 || 0,
        data: listHistoryOrderParser(res),
        isLoadNew: payload.isLoadNew,
      })
    );
    if (typeof onSuccess === "function") {
      onSuccess(res);
    }
  } catch (err) {
    yield put(getListHistoryOrderFailed(err));
  }
}

function* watchProduct() {
  yield takeLatest(GET_LIST_HISTORY_ORDER_REQUEST, getListHistoryOrderSaga);
}

export default function* rootChild() {
  yield fork(watchProduct);
}
