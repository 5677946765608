import APIUtils from 'utils/apiUtils';
import {END_POINTS} from '@shared/constants/apiUrl';

export function getAllNotificationsNew({skip, take, fromDate, toDate}) {
  let requestData = '?skip=' + skip + '&take=' + take;

  if (fromDate) {
    requestData = requestData + '&FromDate=' + fromDate + '&OrderBy=ASC';
  }

  if (toDate) {
    requestData = requestData + '&ToDate=' + toDate;
  }

  return APIUtils.get(END_POINTS.GET_ALL_NOTIFICATIONS_NEW_URL + requestData);
}
