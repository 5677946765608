import { actionTypes } from "@shared/redux/actionTypes";

export const CREATE_TRANSACTION_INSURANCE = actionTypes(
  "transaction/CREATE_TRANSACTION_INSURANCE"
);

export const GET_REPAYMENT_TRANSACTION_LINK_NEWS = actionTypes(
  "transactionNews/GET_REPAYMENT_TRANSACTION_LINK_NEWS"
);

export const GET_REPAYMENT_TRANSACTION_LINK = actionTypes(
  "transaction/GET_REPAYMENT_TRANSACTION_LINK"
);

export const GET_DETAIL_TRANSACTION_INFO_NEWS = actionTypes(
  "transactionNews/GET_DETAIL_TRANSACTION_INFO_NEWS"
);
