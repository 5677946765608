import Geocode from "react-geocode";

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_API_KEY);

const checkObjKeys = (json, objParams, isReturnValue) => {
  if (
    !json ||
    Object.keys(json).length <= 0 ||
    typeof json !== "object" ||
    objParams === null ||
    typeof objParams === "undefined"
  ) {
    return false;
  }
  let values = json;
  const arrObjKeys = objParams.split(".");
  let index = 0;
  for (let i = 0; i < arrObjKeys.length; i++) {
    try {
      if (arrObjKeys[i].includes("[")) {
        const objKeysTemp = arrObjKeys[i].substring(
          0,
          arrObjKeys[i].length - 1
        );
        const arrObjKeysTemp = objKeysTemp.split("[");
        for (let j = 0; j < arrObjKeysTemp.length; j++) {
          if (arrObjKeysTemp[j]?.toString()?.trim()?.length > 0) {
            values = values[arrObjKeysTemp[j]];
          }
        }
      } else {
        values = values[arrObjKeys[i]];
      }
      if (
        typeof values === "undefined" ||
        values === null ||
        values?.toString()?.trim()?.length === 0
      ) {
        break;
      }
      ++index;
    } catch (error) {
      return false;
    }
  }

  if (index === arrObjKeys.length) {
    if (typeof values === "boolean" || isReturnValue) {
      return values;
    }
    return true;
  }
  return false;
};

const addressToLatlng = async (address, onCompleted) => {
  Geocode.fromAddress(address)
    .then((json) => {
      onCompleted(json);
    })
    .catch((error) => console.warn(error));
};

export { checkObjKeys, addressToLatlng };
