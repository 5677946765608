import { call, put, takeLatest, fork } from "redux-saga/effects";
import { toast } from "react-toastify";
import MESSAGES from "constants/vi";
import actions from "./actions";
import * as TYPE from "./constants";
import api from "../api/api";

const MESSAGE_KEY = "ConfirmPayment"; // key của object chứa message
const UNKNOWN = "unknown";
const toastOptions = {
  position: "top-center",
  autoClose: 7000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

const showToastMessage = ({ keyword, customOptions = {} }) => {
  if (!keyword) return;

  const errMsgToToast =
    MESSAGES?.[MESSAGE_KEY]?.error?.[keyword] ||
    MESSAGES?.[MESSAGE_KEY]?.error?.[UNKNOWN];

  toast.error(errMsgToToast, {
    toastId: `${keyword}`,
    ...toastOptions,
    ...customOptions,
  });
};
const sagas = {
  // * GET_VOUCHER_INFO
  *[TYPE.GET_VOUCHER_INFO.request](action) {
    try {
      const data = yield call(
        api[TYPE.GET_VOUCHER_INFO.request],
        action.payload
      );
      if (data.status === 200) {
        yield put(actions.GET_VOUCHER_INFO.success(data?.data?.data));
      } else {
        yield put(actions.GET_VOUCHER_INFO.failure(data?.data));
      }
    } catch (error) {
      yield put(actions.GET_VOUCHER_INFO.failure(error?.data));
    }
  },

  *[TYPE.GET_SUGGESTED_COUPON_LIST.request](action) {
    const { feature, merchantId } = action.payload;

    try {
      const response = yield call(api[TYPE.GET_SUGGESTED_COUPON_LIST.request], {
        feature,
        merchantId,
      });

      if (response.status === 200) {
        yield put(
          actions.GET_SUGGESTED_COUPON_LIST.success(
            response.data.suggestedCouponDetails
          )
        );
      } else {
        yield put(actions.GET_SUGGESTED_COUPON_LIST.failure(response?.data));
      }
    } catch (error) {
      yield put(actions.GET_SUGGESTED_COUPON_LIST.failure(error?.data));
    }
  },

  // combine many api calls:
  // + CALC_COUPON_DISCOUNT
  // + GET_AVAILABLE_COUPONS_BY_CODE
  // + GET_AVAILABLE_COUPONS_OF_ALLOCATED_COUPON_TICKET
  *[TYPE.CALC_COUPON_DISCOUNT_V2.request](action) {
    const { couponProductCode, merchantId, products } = action.payload;
    let voucherCode; // dùng để tính discount amount khi áp dụng coupon
    // do API bên khách không rõ ràng giữa voucher và coupon
    // nên nó mang tên là voucherCode, đặt tên theo response cho dễ theo dõi

    try {
      // if (couponTransactionCode) {
      //   // nếu coupon này đã thuộc về user
      //   // thì call để lấy chi tiết 1 mã nhỏ (tương ứng với 1 lần sd) của coupon đó
      //   const response = yield call(
      //     api[TYPE.GET_AVAILABLE_COUPONS_OF_ALLOCATED_COUPON_TICKET.request],
      //     {
      //       productCode: couponProductCode,
      //       transactionCode: couponTransactionCode,
      //     }
      //   );

      //   if (response.status === 200) {
      //     voucherCode = response.data?.[0]?.voucherCode;
      //   } else {
      //     yield put(actions.CALC_COUPON_DISCOUNT_V2.failure(response?.data));
      //   }
      // } else {
      //   // nếu coupon được chọn chưa dược cấp cho user
      //   // cần lưu coupon trước để có transaction code
      //   const response = yield call(
      //     api[TYPE.GET_AVAILABLE_COUPONS_BY_CODE.request],
      //     couponProductCode
      //   );

      //   if (response.status === 200) {
      //     voucherCode = response.data?.coupons?.[0]?.voucherCode;
      //   } else {
      //     yield put(actions.CALC_COUPON_DISCOUNT_V2.failure(response?.data));
      //   }
      // }

      // do có trường hợp GET_AVAILABLE_COUPONS_OF_ALLOCATED_COUPON_TICKET trả ra mảng rỗng
      // -> lấy voucherCode bị null -> CALC_COUPON_DISCOUNT bị NotFoundCoupon  (do truyền coupon: [null])
      // do GET_AVAILABLE_COUPONS_BY_CODE đều call được dù coupon đó đã đc allocated hay chưa
      // -> luôn call GET_AVAILABLE_COUPONS_BY_CODE để bắt được lỗi tốt hơn
      const getAvailableCouponsByCodeResponse = yield call(
        api[TYPE.GET_AVAILABLE_COUPONS_BY_CODE.request],
        couponProductCode
      );

      if (getAvailableCouponsByCodeResponse.status === 200) {
        voucherCode =
          getAvailableCouponsByCodeResponse.data?.coupons?.[0]?.voucherCode;
      } else {
        yield put(
          actions.CALC_COUPON_DISCOUNT_V2.failure(
            getAvailableCouponsByCodeResponse?.data
          )
        );
      }

      // call API 046
      const response = yield call(api[TYPE.CALC_COUPON_DISCOUNT.request], {
        merchantId,
        products,
        coupons: [voucherCode],
        orderType: "OrderGift",
      });

      if (response.status === 200) {
        yield put(
          actions.CALC_COUPON_DISCOUNT_V2.success({
            voucherCode,
            discountAmount: response.data?.discountAmount,
          })
        );
      } else {
        yield put(actions.CALC_COUPON_DISCOUNT_V2.failure(response?.data));
      }
    } catch (error) {
      showToastMessage({ keyword: error?.data?.message });
      yield put(actions.CALC_COUPON_DISCOUNT_V2.failure(error?.data));
    }
  },
  // *** --

  // * CREATE_ORDER_VOUCHER
  *[TYPE.CREATE_ORDER_VOUCHER.request](action) {
    const { requestPayload, onSuccess } = action.payload;

    try {
      const response = yield call(
        api[TYPE.CREATE_ORDER_VOUCHER.request],
        requestPayload
      );

      if (response.status === 200) {
        onSuccess?.();
        yield put(actions.CREATE_ORDER_VOUCHER.success(response.data));
      } else {
        yield put(actions.CREATE_ORDER_VOUCHER.failure(response?.data));
      }
    } catch (error) {
      showToastMessage({ keyword: error?.data?.message });
      yield put(actions.CREATE_ORDER_VOUCHER.failure(error?.data));
    }
  },
};

function* watchedSaga() {
  // * GET_VOUCHER_INFO
  yield takeLatest(
    TYPE.GET_VOUCHER_INFO.request,
    sagas[TYPE.GET_VOUCHER_INFO.request]
  );

  // * GET_SUGGESTED_COUPON_LIST
  yield takeLatest(
    TYPE.GET_SUGGESTED_COUPON_LIST.request,
    sagas[TYPE.GET_SUGGESTED_COUPON_LIST.request]
  );

  // * CALC_COUPON_DISCOUNT_V2
  yield takeLatest(
    TYPE.CALC_COUPON_DISCOUNT_V2.request,
    sagas[TYPE.CALC_COUPON_DISCOUNT_V2.request]
  );

  // * CREATE_ORDER_VOUCHER
  yield takeLatest(
    TYPE.CREATE_ORDER_VOUCHER.request,
    sagas[TYPE.CREATE_ORDER_VOUCHER.request]
  );
}

export default function* rootChild() {
  yield fork(watchedSaga);
}
