/* eslint-disable react/no-array-index-key */
import React from "react";
import StoreItem from "./components/StoreItem/StoreItem";
import "./StoreList.scss";
// eslint-disable-next-line import/order
import { ArrowRightYellowIcon } from "assets/svg";

const StoreList = ({
  goToSeeAll,
  hideTitle,
  gotoProduct,
  dataListStore,
  handleShareStore,
}) => {
  const SeeMore = () => {
    return (
      <div
        className="store-view__rating__header__text-header__right"
        onClick={goToSeeAll}
      >
        <span>Xem thêm</span>
        {/* <img src={SVG.ArrowRightYellow} alt="arrow" /> */}
        <ArrowRightYellowIcon />
      </div>
    );
  };
  return (
    <div className="store-wrapper">
      {!hideTitle && (
        <div className="store-wrapper_header">
          <div className="label">Danh sách cửa hàng</div>
          {dataListStore.length >= 6 && SeeMore()}
        </div>
      )}
      <div className="store-content">
        {dataListStore.slice(0, 5).map((item, index) => (
          <StoreItem
            gotoProduct={() => gotoProduct(item)}
            dataStore={item}
            handleShareStore={handleShareStore}
            key={index + 1}
          />
        ))}
      </div>
      {dataListStore.length >= 6 && (
        <div className="button-see-more">{SeeMore()}</div>
      )}
    </div>
  );
};

export default StoreList;
