import {
  POST_SUCCESS,
  POST_REQUEST,
  POST_FAILED,
  CLEAR_ERRORS,
  COUNT_LIST_VIDEO,
} from './types';

export const postRequest = payload => ({
  type: POST_REQUEST,
  payload,
});

export const postSuccess = payload => ({
  type: POST_SUCCESS,
  payload,
});

export const postFailure = payload => ({
  type: POST_FAILED,
  payload,
});

export const clearErrors = () => ({
  type: CLEAR_ERRORS,
});

export const countListVideo = payload => ({
  type: COUNT_LIST_VIDEO,
  payload,
});
