import { genActions } from "utils/actionGenerator";
import * as TYPE from "./constants";

const actions = {
  GET_LIST_COUPON: genActions(TYPE.GET_LIST_COUPON),
  /**
   * GET_LIST_COUPON: {
   *  request: (payload) => ({ type: `${"Coupon_GET_LIST_COUPON"}_REQUEST`, payload})
   *  success: (payload) => ({ type: `${"Coupon_GET_LIST_COUPON"}_SUCCESS`, payload})
   *  failure: (payload) => ({ type: `${"Coupon_GET_LIST_COUPON"}_FAILURE`, payload})
   * }
   */
  SEARCH_COUPON_BY_COUPON_CODE: genActions(TYPE.SEARCH_COUPON_BY_COUPON_CODE),
};

export default actions;
