export const listDeal = {
  isInit: true,
  items: undefined,
  skip: undefined,
  take: undefined,
};

export const isLoading = {
  GET_LIST_DEAL: false,
};
