import {GET_PACKAGE_INFO, GET_LIST_RECOMMEND_WITH_PACKAGE} from './constants';

export const getPackageInfoHandle = (payload, onSuccess, onFailed) => ({
  type: GET_PACKAGE_INFO.HANDLER,
  payload,
  onSuccess,
  onFailed,
});

export const getPackageInfoSuccess = payload => ({
  type: GET_PACKAGE_INFO.SUCCESS,
  payload,
});

export const getPackageInfoFailed = error => ({
  type: GET_PACKAGE_INFO.FAILURE,
  error,
});

export const getListRecommendWIthPackageHandle = (
  skip,
  take,
  newspaperId,
  merchantId,
  onSuccess,
  onFailed,
) => ({
  type: GET_LIST_RECOMMEND_WITH_PACKAGE.HANDLER,
  skip,
  take,
  newspaperId,
  merchantId,
  onSuccess,
  onFailed,
});

export const getListRecommendWIthPackageSuccess = payload => ({
  type: GET_LIST_RECOMMEND_WITH_PACKAGE.SUCCESS,
  payload,
});

export const getListRecommendWIthPackageFailed = error => ({
  type: GET_LIST_RECOMMEND_WITH_PACKAGE.FAILURE,
  error,
});
