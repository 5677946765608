import {
  CHECK_NUMBER_TRANSFER,
  UPDATE_LIST_RECEVER,
  CLEAR_DATA_REQUEST,
  GET_UTOP_BALANCE,
  TRANSFER_UTOP,
  SET_PIN_CODE,
  CREATE_ORDER,
  CREATE_ORDER_UTOP_HUB,
  GET_PAYMENT_INFO,
} from './constants';

export const checkNumberHandle = (payload = {}, onSuccess, onError) => ({
  type: CHECK_NUMBER_TRANSFER,
  payload,
  onSuccess,
  onError,
});
export const setPinCodeHandle = (payload = {}, onSuccess, onError) => ({
  type: SET_PIN_CODE,
  payload,
  onSuccess,
  onError,
});
export const createOrderHandle = (payload = {}, onSuccess, onError) => ({
  type: CREATE_ORDER,
  payload,
  onSuccess,
  onError,
});
export const createOrderUtopHubHandle = (payload = {}, onSuccess, onError) => ({
  type: CREATE_ORDER_UTOP_HUB,
  payload,
  onSuccess,
  onError,
});
export const transferUtopHandle = (payload = {}, onSuccess, onError) => ({
  type: TRANSFER_UTOP,
  payload,
  onSuccess,
  onError,
});

export const getUtopBalanceHandle = (onSuccess, onError) => ({
  type: GET_UTOP_BALANCE,
  onSuccess,
  onError,
});
export const updateListRecever = listRecever => ({
  type: UPDATE_LIST_RECEVER,
  listRecever,
});

export const clearPaymentDataHandle = () => ({
  type: CLEAR_DATA_REQUEST,
});

export const getPaymentInfoHandle = (payload, onSuccess, onError) => ({
  type: GET_PAYMENT_INFO.HANDLER,
  payload,
  onSuccess,
  onError,
});
export const getPaymentInfoSuccess = paymentInfo => ({
  type: GET_PAYMENT_INFO.SUCCESS,
  paymentInfo,
});

export const getPaymentInfoFailure = error => ({
  type: GET_PAYMENT_INFO.FAILURE,
  error,
});
