import APIUtils from 'utils/apiUtils';
import {END_POINTS} from '@shared/constants/apiUrl';

export function apiDefaultAddressUser() {
  let requestData = '';

  return APIUtils.get(END_POINTS.GET_DEFAULT_ADDRESS_USER_URL + requestData);
}

export function apiCalculateShipping(requestData) {
  console.log('apiCalculateShipping -> requestData', requestData);
  return APIUtils.post(END_POINTS.CALCULATE_SHIPPING_URL, {
    body: requestData,
  });
}

export function createDefaultAddressApi(requestData) {
  return APIUtils.post(END_POINTS.CREATE_DEFAULT_ADDRESS, {
    body: requestData,
  });
}
