import React, { useState } from "react";
import Modal from "react-modal";
import { icons } from "assets/icons/index";
import IMAGES from "assets/img";
import "./ImageShow.scss";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "transparent",
    border: "none",
  },
  overlay: {
    backgroundColor: "#000",
    zIndex: 200,
  },
};

const ImageShow = ({ isShowStory, closeShowStory, image }) => {
  const [errorImage, setErrorImage] = useState(false);

  const checkErrorImage = () => {
    setErrorImage(true);
  };

  const checkImageUrl = (images) => {
    if (images !== undefined && images.length > 0) {
      const listAvatar = images.filter((item) => item.isFeatured === true);
      if (listAvatar.length > 0) {
        return listAvatar[0].imageUrl || "";
      }
      return "";
    }
    return "";
  };
  return (
    <Modal isOpen={isShowStory} style={customStyles}>
      <div>
        <div className="close-button" onClick={closeShowStory}>
          <img src={icons.iconClose} />
        </div>
        {errorImage ? (
          <img className="avatar-img-modal" src={IMAGES.LogoUtop2} alt="ava" />
        ) : (
          <img
            className="avatar-img-modal"
            src={checkImageUrl(image) || IMAGES.LogoUtop2}
            alt="ava"
            onError={checkErrorImage}
          />
        )}
      </div>
    </Modal>
  );
};

export default ImageShow;
