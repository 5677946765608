import { call, put, takeLatest, fork, takeEvery } from "redux-saga/effects";
import actions from "./actions";
import * as TYPE from "./constants";
import api from "../api/api";

const sagas = {
  *[TYPE.VOUCHER_DETAIL.request](action) {
    try {
      const data = yield call(api[TYPE.VOUCHER_DETAIL.request], action.payload);
      if (data.status === 200) {
        yield put(actions.VOUCHER_DETAIL.success(data.data));
      } else {
        yield put(actions.VOUCHER_DETAIL.failure(data?.data));
      }
    } catch (error) {
      yield put(actions.VOUCHER_DETAIL.failure(error?.data));
    }
  },

  *[TYPE.VOUCHER_INFOMATION.request](action) {
    try {
      const data = yield call(
        api[TYPE.VOUCHER_INFOMATION.request],
        action.payload
      );
      if (data.status === 200) {
        yield put(actions.VOUCHER_INFOMATION.success(data.data));
      } else {
        yield put(actions.VOUCHER_INFOMATION.failure(data?.data));
      }
    } catch (error) {
      yield put(actions.VOUCHER_INFOMATION.failure(error?.data));
    }
  },
  // *[TYPE.GET_VOUCHER_OUTSTAND.request](action) {
  //   try {
  //     const data = yield call(
  //       api[TYPE.GET_VOUCHER_OUTSTAND.request],
  //       action.payload
  //     );
  //     if (data.status === 200) {
  //       yield put(actions.GET_VOUCHER_OUTSTAND.success(data.data));
  //     } else {
  //       yield put(actions.GET_VOUCHER_OUTSTAND.failure(data?.data));
  //     }
  //   } catch (error) {
  //     yield put(actions.GET_VOUCHER_OUTSTAND.failure(error?.data));
  //   }
  // },

  *[TYPE.CLICK_BY_MARK_USED.request](action) {
    try {
      const data = yield call(
        api[TYPE.CLICK_BY_MARK_USED.request],
        action.payload
      );
      if (data.status === 200) {
        yield put(actions.CLICK_BY_MARK_USED.success(data.data));
      } else {
        yield put(actions.CLICK_BY_MARK_USED.failure(data?.data));
      }
    } catch (error) {
      yield put(actions.CLICK_BY_MARK_USED.failure(error?.data));
    }
  },
};

function* watchedSaga() {
  yield takeEvery(
    TYPE.VOUCHER_DETAIL.request,
    sagas[TYPE.VOUCHER_DETAIL.request]
  );
  yield takeEvery(
    TYPE.VOUCHER_INFOMATION.request,
    sagas[TYPE.VOUCHER_INFOMATION.request]
  );
  // yield takeLatest(
  //   TYPE.GET_VOUCHER_OUTSTAND.request,
  //   sagas[TYPE.GET_VOUCHER_OUTSTAND.request]
  // );
  yield takeLatest(
    TYPE.CLICK_BY_MARK_USED.request,
    sagas[TYPE.CLICK_BY_MARK_USED.request]
  );
}

export default function* rootChild() {
  yield fork(watchedSaga);
}
