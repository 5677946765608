import {
  GET_UTOP_POINT_TOTAL_REQUEST,
  GET_UTOP_POINT_TOTAL_SUCCESS,
  GET_UTOP_POINT_TOTAL_FAILED,
  GET_LIST_CAMPAIGN_REQUEST,
  GET_LIST_CAMPAIGN_SUCCESS,
  GET_LIST_CAMPAIGN_FAILED,
  GET_LIST_AFF_HISTORY_REQUEST,
  GET_LIST_AFF_HISTORY_SUCCESS,
  GET_LIST_AFF_HISTORY_FAILED,
  GET_AFF_DETAIL_REQUEST,
  GET_AFF_DETAIL_SUCCESS,
  GET_AFF_DETAIL_FAILED,
} from './constants';

export const getUtopPointTotalHandle = (onSuccess, onFailed) => ({
  type: GET_UTOP_POINT_TOTAL_REQUEST,
  onSuccess,
  onFailed,
});

export const getUtopPointTotalSuccess = balance => ({
  type: GET_UTOP_POINT_TOTAL_SUCCESS,
  balance,
});

export const getUtopPointTotalFailed = payload => ({
  type: GET_UTOP_POINT_TOTAL_FAILED,
  payload,
});

export const getListCampaignHandle = (onSuccess, onFailed) => ({
  type: GET_LIST_CAMPAIGN_REQUEST,
  onSuccess,
  onFailed,
});

export const getListCampaignSuccess = payload => {
  return {
    type: GET_LIST_CAMPAIGN_SUCCESS,
    listcampaign: payload.listcampaign,
    total: payload.total,
  };
};

export const getListCampaignFailed = payload => ({
  type: GET_LIST_CAMPAIGN_FAILED,
  payload,
});
export const getAffDetailHandle = (payload, onSuccess, onFailed) => ({
  type: GET_AFF_DETAIL_REQUEST,
  payload,
  onSuccess,
  onFailed,
});

export const getAffDetailSuccess = affdetail => ({
  type: GET_AFF_DETAIL_SUCCESS,
  affdetail,
});

export const getAffDetailFailed = payload => ({
  type: GET_AFF_DETAIL_FAILED,
  payload,
});

export const getAffHistoryHandle = (payload, onSuccess, onFailed) => ({
  type: GET_LIST_AFF_HISTORY_REQUEST,
  payload,
  onSuccess,
  onFailed,
});

export const getAffHistorySuccess = payload => ({
  type: GET_LIST_AFF_HISTORY_SUCCESS,
  payload,
});

export const getAffHistoryAffDetailFailed = payload => ({
  type: GET_LIST_AFF_HISTORY_FAILED,
  payload,
});
