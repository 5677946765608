import {
  GET_STORE_INFO_BY_ID_REQUEST,
  GET_STORE_INFO_BY_ID_SUCCESS,
  GET_STORE_INFO_BY_ID_FAILED,
  GET_PRODUCT_SUGGEST_BY_USER_REQUEST,
  GET_PRODUCT_SUGGEST_BY_USER_SUCCESS,
  GET_PRODUCT_SUGGEST_BY_USER_FAILED,
} from './constants';

export const getStoreInfoByIdHandle = (storeId, onSuccess, onFailed) => ({
  type: GET_STORE_INFO_BY_ID_REQUEST,
  storeId,
  onSuccess,
  onFailed,
});

export const getStoreInfoByIdSuccess = balance => ({
  type: GET_STORE_INFO_BY_ID_SUCCESS,
  balance,
});

export const getStoreInfoByIdFailed = error => ({
  type: GET_STORE_INFO_BY_ID_FAILED,
  error,
});

export const getProductSuggestByUserHandle = (
  storeId,
  merchantId,
  onSuccess,
) => ({
  type: GET_PRODUCT_SUGGEST_BY_USER_REQUEST,
  storeId,
  merchantId,
  onSuccess,
});

export const getProductSuggestByUserSuccess = balance => ({
  type: GET_PRODUCT_SUGGEST_BY_USER_SUCCESS,
  balance,
});

export const getProductSuggestByUserFailed = error => ({
  type: GET_PRODUCT_SUGGEST_BY_USER_FAILED,
  error,
});
