import {
  CLEAR_PARTNER_INFO,
  EXCHANGE_POINT_PARTNER_FAILED,
  EXCHANGE_POINT_PARTNER_REQUEST,
  EXCHANGE_POINT_PARTNER_SUCCESS,
  GET_LIST_PARTNER_EXCHANGE_POINT_FAILED,
  GET_LIST_PARTNER_EXCHANGE_POINT_REQUEST,
  GET_LIST_PARTNER_EXCHANGE_POINT_SUCCESS,
  GET_PARTNER_INFO_FAILED,
  GET_PARTNER_INFO_REQUEST,
  GET_PARTNER_INFO_SUCCESS,
  MAPPING_MEMBER_WITH_PARTNER_FAILED,
  MAPPING_MEMBER_WITH_PARTNER_REQUEST,
  MAPPING_MEMBER_WITH_PARTNER_SUCCESS,
  REMOVE_MAPPING_MEMBER_WITH_PARTNER_FAILED,
  REMOVE_MAPPING_MEMBER_WITH_PARTNER_REQUEST,
  REMOVE_MAPPING_MEMBER_WITH_PARTNER_SUCCESS,
} from './constants';

export const getListPartnerExchangePointHandle = (
  payload = {},
  onSuccess,
  onError,
) => ({
  type: GET_LIST_PARTNER_EXCHANGE_POINT_REQUEST,
  payload,
  onSuccess,
  onError,
});
export const getListPartnerExchangePointSuccess = (payload = {}) => ({
  type: GET_LIST_PARTNER_EXCHANGE_POINT_SUCCESS,
  payload,
});
export const getListPartnerExchangePointFailed = (payload = {}) => ({
  type: GET_LIST_PARTNER_EXCHANGE_POINT_FAILED,
  payload,
});

export const mappingMemberWithPartnerHandle = (
  payload = {},
  onSuccess,
  onError,
) => ({
  type: MAPPING_MEMBER_WITH_PARTNER_REQUEST,
  payload,
  onSuccess,
  onError,
});
export const mappingMemberWithPartnerSuccess = (payload = {}) => ({
  type: MAPPING_MEMBER_WITH_PARTNER_SUCCESS,
  payload,
});
export const mappingMemberWithPartnerFailed = (payload = {}) => ({
  type: MAPPING_MEMBER_WITH_PARTNER_FAILED,
  payload,
});

export const removeMappingMemberWithPartnerHandle = (
  payload = {},
  onSuccess,
  onError,
) => ({
  type: REMOVE_MAPPING_MEMBER_WITH_PARTNER_REQUEST,
  payload,
  onSuccess,
  onError,
});
export const removeMappingMemberWithPartnerSuccess = (payload = {}) => ({
  type: REMOVE_MAPPING_MEMBER_WITH_PARTNER_SUCCESS,
  payload,
});
export const removeMappingMemberWithPartnerFailed = (payload = {}) => ({
  type: REMOVE_MAPPING_MEMBER_WITH_PARTNER_FAILED,
  payload,
});

export const getPartnerInfoHandle = (url, config, onSuccess, onError) => ({
  type: GET_PARTNER_INFO_REQUEST,
  url,
  config,
  onSuccess,
  onError,
});
export const getPartnerInfoSuccess = (payload = {}) => ({
  type: GET_PARTNER_INFO_SUCCESS,
  payload,
});
export const getPartnerInfoFailed = (payload = {}) => ({
  type: GET_PARTNER_INFO_FAILED,
  payload,
});

export const exchangePointPartnerHandle = (
  url,
  config,
  onSuccess,
  onError,
) => ({
  type: EXCHANGE_POINT_PARTNER_REQUEST,
  url,
  config,
  onSuccess,
  onError,
});
export const exchangePointPartnerSuccess = (payload = {}) => ({
  type: EXCHANGE_POINT_PARTNER_SUCCESS,
  payload,
});
export const exchangePointPartnerFailed = (payload = {}) => ({
  type: EXCHANGE_POINT_PARTNER_FAILED,
  payload,
});

export const clearPartnerInfoHandle = () => ({
  type: CLEAR_PARTNER_INFO,
});
