const parseJsonToArray = (masterData, merchantId) => {
  var arrMaster = []
  if (typeof masterData !== 'undefined' && masterData !== null) {
    Object.keys(masterData).forEach((element, i) => {
      arrMaster.push(masterData[element])
    })
  }
  let masterDataTemp = arrMaster
    .sort(function (a, b) {
      return b.priority - a.priority
    })
    .map((elm, index) => {
      return elm
    })
  masterDataTemp.merchantId = merchantId
  return masterDataTemp
}

const mergeMasterData = (data, merchantId) => {
  if (data.length === 0) {
    return []
  }
  let masterData = {}
  for (var i = 0; i < data.length; i++) {
    if (data[i]?.data?.categories?.iv[0]?.flatData?.code) {
      var groupName = data[i].data.categories.iv[0].flatData.code
      if (!masterData[groupName]) {
        masterData[groupName] = []
        masterData[groupName].data = []
        masterData[groupName].code = data[i].data.categories.iv[0].flatData.code
        masterData[groupName].name = data[i].data.categories.iv[0].flatData.name
        masterData[groupName].priority =
          data[i].data.categories.iv[0].flatData.priority
      }
      masterData[groupName].data.push({
        ...data[i],
      })
    }
  }
  if (JSON.stringify(masterData) !== '{}') {
    return parseJsonToArray(masterData, merchantId)
  }
  masterData.merchantId = merchantId
  return masterData
}

export const listProductsByMerchantIdParser = (data, merchantId) => {
  if (!data) {
    return {}
  }
  return mergeMasterData(
    data?.data?.data?.queryProductfnbContentsWithTotal?.items || [],
    merchantId,
  )
}
