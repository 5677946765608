import { genActions } from "utils/actionGenerator";
import * as TYPE from "./constants";

const actions = {
  CREATE_PAYMENT: genActions(TYPE.CREATE_PAYMENT),
  TRANSACTION_PAY: genActions(TYPE.TRANSACTION_PAY),
  GET_LIST_COUPON_MY_VOUCHER: genActions(TYPE.GET_LIST_COUPON_MY_VOUCHER),

};

export default actions;
