import { genActions } from "utils/actionGenerator";
import * as TYPE from "./constants";

const actions = {
  // SET_LOADING: (payload) => ({ type: TYPE.SET_LOADING, payload }),
  // GET_LIST_FEED__FILTER_BY_ZONE: genActions(TYPE.GET_LIST_FEED__FILTER_BY_ZONE),
  // GET_LIST_FEED__OPEN_CAT: genActions(TYPE.GET_LIST_FEED__OPEN_CAT),
  // GET_LIST_FEED__OPEN_LIST_STORE: genActions(
  //   TYPE.GET_LIST_FEED__OPEN_LIST_STORE
  // ),

  GET_LIST_FEED: genActions(TYPE.GET_LIST_FEED),
  GET_LIST_STORE_OF_MERCHANT: genActions(TYPE.GET_LIST_STORE_OF_MERCHANT),
};

export default actions;
