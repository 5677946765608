import {
  MY_VOUCHER_REQUEST,
  MY_VOUCHER_SUCCESS,
  MY_VOUCHER_FAILED,
  MY_VOUCHER_USED_REQUEST,
  MY_VOUCHER_USED_SUCCESS,
  MY_VOUCHER_USED_FAILED,
} from './constants';

export const myVoucherRequest = (payload) => ({
  type: MY_VOUCHER_REQUEST,
  payload,
});

export const myVoucherSuccess = (payload) => ({
  type: MY_VOUCHER_SUCCESS,
  payload,
});

export const myVoucherFailed = (payload) => ({
  type: MY_VOUCHER_FAILED,
  payload,
});

export const myVoucherUsedRequest = (payload) => ({
  type: MY_VOUCHER_USED_REQUEST,
  payload,
});

export const myVoucherUsedSuccess = (payload) => ({
  type: MY_VOUCHER_USED_SUCCESS,
  payload,
});

export const myVoucherUsedFailed = (payload) => ({
  type: MY_VOUCHER_USED_FAILED,
  payload,
});
