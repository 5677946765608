import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  NavigationServices,
  // insightTrackEvent,
} from "utils";
import { checkGuestPopup, checkGuest } from "@shared/utils";
import {
  getDataStore,
  getListProduct,
} from "@shared/redux/smartMenu/selectors";
import {
  setDataStore,
  setIndexProductCart,
} from "@shared/redux/smartMenu/actions";
import { getListStoreMerchantRequest } from "@shared/redux/market/marketHome/actions";
import ListStoreMarketView from "./ListStoreMarket.view";

const ListStoreMarket = (props) => {
  const { storeInfo, isCloseStore } = NavigationServices.getParams(props);

  const [isEndSearchMarket, setIsEndSearchMarket] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [listStore, setListStore] = useState([]);
  const [skip, setSkip] = useState(0);
  const take = 20;

  useEffect(() => {
    getListStore(0);
  }, [props]);

  const getListStore = (skipTmp) => {
    const requestData = {
      merchantId: storeInfo.merchantId,
      skip: skipTmp,
      take,
    };
    props.getListStoreMerchantRequest(
      requestData,
      (res) => {
        console.log("resList", res);
        setIsLoading(false);
        if (res.data.length > 0) {
          if (res.data.length < take) {
            setIsEndSearchMarket(true);
          }
          if (listStore.length > 0) {
            const listStoreTmp = listStore.concat(res.data);
            setListStore([...listStoreTmp]);
          } else {
            setListStore(res.data);
          }
          if (res.data.length < take) {
            setIsEndSearchMarket(true);
          }
        } else {
          setIsEndSearchMarket(true);
        }
      },
      (err) => {
        if (skip > 0) {
          // eslint-disable-next-line no-shadow
          const skipTmp = skip - take;
          setSkip(skipTmp);
        }
        console.log("getListStoreMerchantRequest err", err);
      }
    );
  };

  const onLoadMore = () => {
    const skipTmp = skip + take;
    if (listStore.length > 0) {
      setSkip(skipTmp);
      getListStore(skipTmp);
    }
  };

  const _onSearch = () => {
    NavigationServices.navigate("SearchStoreMarket", {
      storeInfo,
      isCloseStore,
    });
  };

  const _onclickViewDetail = (item) => {
    NavigationServices.navigate(
      `/StoreDetailMarket?storeId=${storeInfo?.id}&merchantId=${storeInfo?.merchantId}&storeMerchantId=${item.id}`,
      {
        storeId: storeInfo?.id,
        merchantId: storeInfo?.merchantId,
        storeMerchantId: item.id,
        isCloseStore,
      }
    );
  };

  const onSubmit = () => {
    checkGuest(function (isGuest) {
      if (isGuest) {
        checkGuestPopup();
      } else {
        const storeDetail = props.route.params.storeInfo;
        storeDetail.storeId = storeDetail.id;
        props.setDataStore(storeDetail);
        props.setIndexProductCart();

        NavigationServices.push("Cart", {
          isFromMarket: true,
        });
      }
    });
  };

  return (
    <ListStoreMarketView
      loadMore={onLoadMore}
      dataCart={
        JSON.stringify(props?.getDataStore) !== "{}" &&
        storeInfo?.id === props?.getDataStore?.storeId
          ? props?.getDataCart
          : []
      }
      onSubmit={onSubmit}
      isEndSearchMarket={isEndSearchMarket}
      onSearch={_onSearch}
      onclickViewDetail={_onclickViewDetail}
      listStore={listStore}
      isCloseStore={isCloseStore}
      storeDetail={storeInfo}
      isLoading={isLoading}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    getDataCart: getListProduct(state),
    getDataStore: getDataStore(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setDataStore,
      setIndexProductCart,
      getListStoreMerchantRequest,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ListStoreMarket);
