import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import { GOOGLE_ANALYTICS_TRACKING_ID_FOR_LOCALHOST } from "configs/gaConfig";
import insightTracking from "utils/insightTracking";
import { preprocessingPathName } from "utils/common";

const usePageTracking = () => {
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    let GA4_TRACKING_ID = GOOGLE_ANALYTICS_TRACKING_ID_FOR_LOCALHOST; // use for local host

    if (!window.location.href.includes("localhost")) {
      GA4_TRACKING_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID; // QA
    }

    ReactGA.initialize(GA4_TRACKING_ID);
    setInitialized(true);
  }, []);

  useEffect(() => {
    if (initialized) {
      insightTracking.logEvent({
        action: preprocessingPathName(location.pathname),
        payload: { EventType: "PageView" },
      });
      ReactGA.send({
        hitType: "pageview",
        page: location.pathname + location.search,
      });
    }
  }, [initialized, location]);

  return [initialized, ReactGA];
};

export default usePageTracking;
