import { GET_NOTIFICATION_REQUEST } from "./constants";
import { getNotificationFailure, getNotificationSuccess } from "./actions";
import { put, takeLatest, fork } from "redux-saga/effects";
import { getAllNotificationsNew } from "@shared/services/api/notification";
import { callApi } from "helpers/callApi";

export function* getNotification(obj) {
  try {
    const respone = yield callApi(getAllNotificationsNew, {
      ...obj.payload,
    });

    if (respone.status === 200) {
      const { items, total } = respone.data;
      const { skip, isLoadMore } = obj.payload;

      yield put(
        getNotificationSuccess({
          items,
          total,
          skip,
          isLoadMore,
        })
      );
    }
  } catch (err) {
    yield put(getNotificationFailure(err));
  }
}

function* getNotificationWatch() {
  yield takeLatest(GET_NOTIFICATION_REQUEST, getNotification);
}

export default function* rootChild() {
  yield fork(getNotificationWatch);
}
