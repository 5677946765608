// import request from "services/request";

// import * as TYPE from "../store/constants";

const api = {
  // [TYPE.GET_USER_ADDRESS_BOOKS.request]: async () => {
  //   return request(`/personal/getuseraddressbook`, "GET");
  // },
  // [TYPE.DELETE_A_USER_ADDRESS_BOOK.request]: async () => {
  //   return request(`/personal/deleteaddressofuseraddressbook`, "POST");
  // },
};

export default api;
