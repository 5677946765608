/* eslint-disable prettier/prettier */
import {LOGOUT_REQUEST} from '@shared/redux/account/constants';
import produce from 'immer';
import {
  DELETE_USER_DETAIL,
  DELETE_VIDEO_DETAIL,
  DOWNLOAD_VIDEO_REQUEST,
  DOWNLOAD_VIDEO_SUCCESS,
  GET_REASON_REPORT_SUCCESS,
  SAVE_USER_DETAIL,
  SAVE_VIDEO_DETAIL,
} from './types';

const initialState = {
  getReasonReport: [],
  listComment: [],
  downloading: {},
  videoDetail: [],
  userDetail: [],
};

const videoListReducer = (draft = initialState, action) => {
  const {payload} = action;
  switch (action.type) {
    case GET_REASON_REPORT_SUCCESS:
      return {
        ...draft,
        getReasonReport: payload,
      };
    case DOWNLOAD_VIDEO_REQUEST:
      return {
        ...draft,
        downloading: payload,
      };
    case SAVE_VIDEO_DETAIL:
      let arr = draft.videoDetail;
      const index = arr.findIndex(item => item.id === payload.id);
      if (index === -1) {
        arr = arr.concat(payload);
      } else {
        arr[index] = payload;
      }
      return {
        ...draft,
        videoDetail: arr,
      };
    case SAVE_USER_DETAIL:
      let arrTmp = draft.videoDetail;
      const indexTmp = arrTmp.findIndex(
        item => item.creatorId === payload.creatorId,
      );
      if (indexTmp === -1) {
        arrTmp = arrTmp.concat(payload);
      } else {
        arrTmp[indexTmp] = payload;
      }
      return {
        ...draft,
        userDetail: arrTmp,
      };
    case DELETE_VIDEO_DETAIL:
      return {
        ...draft,
        videoDetail: [],
      };
    case DELETE_USER_DETAIL:
      return {
        ...draft,
        userDetail: [],
      };
    case LOGOUT_REQUEST: {
      return {
        ...initialState,
      };
    }
    default:
      return draft;
  }
};

export default videoListReducer;
