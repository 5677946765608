// Order List
export const GET_LIST_PENDING_ORDER_REQUEST =
  'orderList/GET_LIST_PENDING_ORDER_REQUEST';

export const GET_LIST_PENDING_ORDER_SUCCESS =
  'orderList/GET_LIST_PENDING_ORDER_SUCCESS';

export const GET_LIST_PENDING_ORDER_FAILED =
  'orderList/GET_LIST_PENDING_ORDER_FAILED';

export const CLEAR_LIST_PENDING_ORDER = 'orderList/CLEAR_LIST_PENDING_ORDER';
