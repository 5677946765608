/* eslint-disable prettier/prettier */
import {
  unblockUserFailure,
  unblockUserSuccess,
  getListBlockUserFailure,
  getListBlockUserSuccess,
} from './actions'
import { UNBLOCK_USER_REQUEST, GET_LIST_BLOCK_USER_REQUEST } from './types'
import { call, put, takeLatest, fork } from 'redux-saga/effects'
import { END_POINTS } from '@shared/constants/apiUrl'
import { APIUtils } from 'utils'

async function getListBlockUserApi() {
  return APIUtils.get(END_POINTS.GetListCreatorBlockedByCreatorToken)
}

export function* getListBlockUser(obj) {
  try {
    const data = yield call(getListBlockUserApi, obj.payload)
    console.log('function*getListBlockUser -> data', data)

    if (data.httpStatus === 200) {
      yield put(getListBlockUserSuccess(data))
    } else {
      yield put(getListBlockUserFailure())
    }
  } catch (err) {
    yield put(getListBlockUserFailure(err))
  }
}

async function unblockUserApi(id) {
  var requestData = {
    creatorId: id,
  }
  return APIUtils.post(END_POINTS.UnBlockCreatorByCreatorId, {
    body: requestData,
  })
}

export function* unblockUser(obj) {
  try {
    const data = yield call(unblockUserApi, obj.payload)

    if (data.httpStatus === 202) {
      yield put(unblockUserSuccess(obj.payload))
    } else {
      yield put(unblockUserFailure())
    }
  } catch (err) {
    yield put(unblockUserFailure(err))
  }
}

function* watchHashtag() {
  yield takeLatest(UNBLOCK_USER_REQUEST, unblockUser)
  yield takeLatest(GET_LIST_BLOCK_USER_REQUEST, getListBlockUser)
}

export default function* rootChild() {
  yield fork(watchHashtag)
}
