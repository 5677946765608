import {LOGOUT_REQUEST} from '@shared/redux/account/constants';
import {
  GET_LIST_VIDEO_SUCCESS,
  GET_USER_INFO_SUCCESS,
  FOLLOW_USER_SUCCESS,
  UN_FOLLOW_USER_SUCCESS,
  GET_LIST_VIDEO_FAILED,
} from './constants';

const initialState = {
  byId: {},
  listVideo: [],
  listVideoSearching: {},
  userInfo: {},
  isLoading: false,
  isSuccess: false,
  isError: false,
  message: '',
};

const unFollowed = state => {
  state.userInfo.follower--;
  state.userInfo.isFollowing = false;
  return {...state.userInfo};
};

const followed = state => {
  state.userInfo.follower++;
  state.userInfo.isFollowing = true;
  return {...state.userInfo};
};

const userInfoReducer = function (state = initialState, action) {
  const {payload} = action;
  switch (action.type) {
    case GET_LIST_VIDEO_SUCCESS:
      const {data, skipCount, maxResult} = action.payload;
      return {
        ...state,
        listVideo: data,
      };
    case GET_LIST_VIDEO_FAILED:
      return {
        ...state,
        isError: true,
      };
    case GET_USER_INFO_SUCCESS:
      return {
        ...state,
        userInfo: payload.data,
      };
    case FOLLOW_USER_SUCCESS:
      return {
        ...state,
        userInfo:
          payload.creatorId === state.userInfo.creatorId
            ? followed(state)
            : state.userInfo,
      };
    case UN_FOLLOW_USER_SUCCESS:
      return {
        ...state,
        userInfo:
          payload.creatorId === state.userInfo.creatorId
            ? unFollowed(state)
            : state.userInfo,
      };
    case LOGOUT_REQUEST: {
      return {
        ...initialState,
      };
    }
    default:
      return state;
  }
};

export default userInfoReducer;
