import { call, put, takeLatest, fork } from "redux-saga/effects";
import actions from "./actions";
import * as TYPE from "./constants";
import api from "../api/api";

const sagas = {
  *[TYPE.CREATE_PAYMENT_TOPUP.request](action) {
    try {
      const data = yield call(
        api[TYPE.CREATE_PAYMENT_TOPUP.request],
        action.payload
      );
      if (data.status === 200) {
        yield put(actions.CREATE_PAYMENT_TOPUP.success(data.data));
      } else {
        yield put(actions.CREATE_PAYMENT_TOPUP.failure(data?.data));
      }
    } catch (error) {
      yield put(actions.CREATE_PAYMENT_TOPUP.failure(error?.data));
    }
  },
};

function* watchedSaga() {
  yield takeLatest(TYPE.CREATE_PAYMENT_TOPUP.request, sagas[TYPE.CREATE_PAYMENT_TOPUP.request]);
}

export default function* rootChild() {
  yield fork(watchedSaga);
}
