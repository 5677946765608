/* eslint-disable no-undef */
/* eslint-disable no-param-reassign */
import { addErrors, delErrors } from "utils/ErrorsGenerator";
import * as TYPE from "./constants";

const reducers = {
  [TYPE.RATING_FOR_STORE.request]: (draft, { payload }) => {
    draft.rating = payload;
    draft.isRating = undefined;
  },
  [TYPE.RATING_FOR_STORE.success]: (draft, { payload }) => {
    draft.errors = delErrors(draft.errors, TYPE.RATING_FOR_STORE.failure);
    draft.rating = payload;
    draft.isRating = true;
  },
  [TYPE.RATING_FOR_STORE.failure]: (draft, { payload }) => {
    draft.errors = addErrors(draft.errors, {
      type: TYPE.RATING_FOR_STORE.failure,
      msg: payload,
      merchantErr: payload.blackListWords,
    });
    if (payload.blackListWords.length > 0) {
      draft.isRating = undefined;
    } else {
      draft.isRating = false;
    }
  },

  [TYPE.GET_MERCHANT_INFO.success]: (draft, { payload }) => {
    draft.errors = delErrors(draft.errors, TYPE.GET_MERCHANT_INFO.failure);
    draft.merchatInfo = payload;
  },
  [TYPE.GET_MERCHANT_INFO.failure]: (draft, { payload }) => {
    draft.errors = addErrors(draft.errors, {
      type: TYPE.GET_MERCHANT_INFO.failure,
      msg: payload,
    });
  },
};

export default reducers;
