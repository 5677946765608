import {
  MY_VOUCHER_DETAIL_SUCCESS,
  MY_VOUCHER_DETAIL_FAILED,
  MY_COUPON_DETAIL_SUCCESS,
  MY_COUPON_DETAIL_FAILED,
} from './constants';

const initialState = {
  voucherDetail: [],
  couponDetail: [],
  errors: null,
};

const myVoucherDetailReducer = (state = initialState, action) => {
  const {payload} = action;

  switch (action.type) {
    case MY_VOUCHER_DETAIL_SUCCESS:
      return {
        ...state,
        voucherDetail: payload,
      };

    case MY_VOUCHER_DETAIL_FAILED:
      return {
        ...state,
        errors: payload,
      };

    case MY_COUPON_DETAIL_SUCCESS:
      return {
        ...state,
        couponDetail: payload,
      };

    case MY_COUPON_DETAIL_FAILED:
      return {
        ...state,
        errors: payload,
      };
    default:
      return state;
  }
};

export default myVoucherDetailReducer;
