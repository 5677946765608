import {
  GET_LIST_HISTORY_ORDER_REQUEST,
  GET_LIST_HISTORY_ORDER_SUCCESS,
  GET_LIST_HISTORY_ORDER_FAILED,
} from './constants';
export const getListHistoryOrderHandle = payload => ({
  type: GET_LIST_HISTORY_ORDER_REQUEST,
  payload,
});

export const getListHistoryOrderSuccess = historyOrder => ({
  type: GET_LIST_HISTORY_ORDER_SUCCESS,
  historyOrder,
});

export const getListHistoryOrderFailed = error => ({
  type: GET_LIST_HISTORY_ORDER_FAILED,
  error,
});
