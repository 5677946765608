import request from "services/request";
import { encodeQueryData } from "utils/stringFormat";

import * as TYPE from "../store/constants";
// https://utop-apim-utopapp-qa.azure-api.net/voucher/markUsed
const api = {
  [TYPE.VOUCHER_DETAIL.request]: async (params) => {
    const query = encodeQueryData(params);
    return request(`voucher/VoucherDetail${query}`, "GET");
  },

  [TYPE.VOUCHER_INFOMATION.request]: async (params) => {
    return request(`cms/core/vouchers/${params.voucherId}`, "GET");
  },
  [TYPE.VOUCHER_PRODUCT_INFORMATION.request]: async (params) => {
    return request(`cms/core/products/${params.voucherId}`, "GET");
  },

  [TYPE.CLICK_BY_MARK_USED.request]: async (bodyData) => {
    return request(`/voucher/markUsed`, "POST", bodyData);
  },
};

export default api;
