import {
  GET_HISTORY_POINT_REQUEST,
  GET_HISTORY_POINT_SUCCESS,
  GET_HISTORY_POINT_FAILED,
  GET_DETAIL_HISTORY_POINT_REQUEST,
  GET_DETAIL_HISTORY_POINT_SUCCESS,
  GET_DETAIL_HISTORY_POINT_FAILED,
} from './constants';

export const getHistoryPointRequest = payload => ({
  type: GET_HISTORY_POINT_REQUEST,
  payload,
});

export const getHistoryPointSuccess = payload => ({
  type: GET_HISTORY_POINT_SUCCESS,
  payload,
});

export const getHistoryPointFailure = payload => ({
  type: GET_HISTORY_POINT_FAILED,
  payload,
});

export const getDetailHistoryPointRequest = (payload, onSuccess, onFailed) => ({
  type: GET_DETAIL_HISTORY_POINT_REQUEST,
  payload,
  onSuccess,
  onFailed,
});

export const getDetailHistoryPointSuccess = payload => ({
  type: GET_DETAIL_HISTORY_POINT_SUCCESS,
  payload,
});

export const getDetailHistoryPointFailure = payload => ({
  type: GET_DETAIL_HISTORY_POINT_FAILED,
  payload,
});
