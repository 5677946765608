import { genActions } from "utils/actionGenerator";
import * as TYPE from "./constants";

const actions = {
  GET_MERCHANT_INFO: genActions(TYPE.GET_MERCHANT_INFO),
  GET_STORE_INFO: genActions(TYPE.GET_STORE_INFO),
  GET_BEST_SELLER_LIST: genActions(TYPE.GET_BEST_SELLER_LIST),
  GET_PRODUCT_LIST: genActions(TYPE.GET_PRODUCT_LIST),
  GET_RECENT_ORDERED_PRODUCT_CODE_LIST: genActions(
    TYPE.GET_RECENT_ORDERED_PRODUCT_CODE_LIST
  ),
  GET_SUGGESTED_COUPON_LIST: genActions(TYPE.GET_SUGGESTED_COUPON_LIST),
  SAVE_SUGGESTED_COUPON: genActions(TYPE.SAVE_SUGGESTED_COUPON),
  GET_OUT_OF_STOCK_PRODUCT_LIST: genActions(TYPE.GET_OUT_OF_STOCK_PRODUCT_LIST),
  GET_SHORT_LINK_TO_SHARE: genActions(TYPE.GET_SHORT_LINK_TO_SHARE),
  GET_STORE_INFO_V2: genActions(TYPE.GET_STORE_INFO_V2),
};

export default actions;
