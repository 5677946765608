export const GET_STORE_DETAIL_REQUEST = 'storeDetail/GET_STORE_DETAIL_REQUEST';
export const GET_STORE_DETAIL_SUCCESS = 'storeDetail/GET_STORE_DETAIL_SUCCESS';
export const GET_STORE_DETAIL_FAILED = 'storeDetail/GET_STORE_DETAIL_FAILED';
export const GET_INFO_STORE_REQUEST = 'storeDetail/GET_INFO_STORE_REQUEST';
export const GET_INFO_STORE_SUCCESS = 'storeDetail/GET_INFO_STORE_SUCCESS';
export const GET_INFO_STORE_FAILED = 'storeDetail/GET_INFO_STORE_FAILED';
export const GET_STORE_DETAIL_FROM_STORE_CODE_REQUEST =
  'storeDetail/GET_STORE_DETAIL_FROM_STORE_CODE_REQUEST';

export const GET_LIST_VOUCHER_BY_STORE_REQUEST =
  'storeDetail/GET_LIST_VOUCHER_BY_STORE_REQUEST';
export const GET_LIST_VOUCHER_BY_STORE_SUCCESS =
  'storeDetail/GET_LIST_VOUCHER_BY_STORE_SUCCESS';
export const GET_LIST_VOUCHER_BY_STORE_FAILED =
  'storeDetail/GET_LIST_VOUCHER_BY_STORE_FAILED';

export const GET_MERCHANT_INFO_REQUEST =
  'storeDetail/GET_MERCHANT_INFO_REQUEST';
export const GET_MERCHANT_INFO_SUCCESS =
  'storeDetail/GET_MERCHANT_INFO_SUCCESS';
export const GET_MERCHANT_INFO_FAILED = 'storeDetail/GET_MERCHANT_INFO_FAILED';

export const GET_LIST_COUPON_SUGGEST_REQUEST =
  'storeDetail/GET_LIST_COUPON_SUGGEST_REQUEST';
export const GET_LIST_COUPON_SUGGEST_SUCCESS =
  'storeDetail/GET_LIST_COUPON_SUGGEST_SUCCESS';
export const GET_LIST_COUPON_SUGGEST_FAILED =
  'storeDetail/GET_LIST_COUPON_SUGGEST_FAILED';

export const GET_LIST_BEST_SELLER_REQUEST =
  'storeDetail/GET_LIST_BEST_SELLER_REQUEST';
export const GET_LIST_BEST_SELLER_SUCCESS =
  'storeDetail/GET_LIST_BEST_SELLER_SUCCESS';
export const GET_LIST_BEST_SELLER_FAILED =
  'storeDetail/GET_LIST_BEST_SELLER_FAILED';

export const UPDATE_LIST_SUGGEST_COUPON =
  'storeDetail/UPDATE_LIST_SUGGEST_COUPON';
export const GET_LIST_VIDEO_STORE_REQUEST =
  'storeDetail/GET_LIST_VIDEO_STORE_REQUEST';
export const GET_LIST_VIDEO_STORE_SUCCESS =
  'storeDetail/GET_LIST_VIDEO_STORE_SUCCESS';
export const GET_LIST_VIDEO_STORE_FAILED =
  'storeDetail/GET_LIST_VIDEO_STORE_FAILED';
export const SET_LIST_COUPON_SUGGEST_FOR_STORE =
  'storeDetail/SET_LIST_COUPON_SUGGEST_FOR_STORE';
export const UPDATE_LIST_COUPON_SUGGEST_FOR_STORE =
  'storeDetail/UPDATE_LIST_COUPON_SUGGEST_FOR_STORE';
