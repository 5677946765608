import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import branch from "branch-sdk";

const useBranchIO = () => {
  const [initialized, setInitialized] = useState(false);
  const history = useHistory();

  useEffect(() => {
    // let KEY_VALUE_BRANCH = "key_live_fd3AQaxPGpmzkGdfc12k8gbosAnn4tVD"; // testing account;
    // const KEY_VALUE_BRANCH = "key_test_bkGg2Sdo1CvwqvVgH3PC6gdjrBbR8e8l"; // testing main branch
    const KEY_VALUE_BRANCH = process.env.REACT_APP_BRANCHIO_KEY; // testing main branch

    branch.init(KEY_VALUE_BRANCH, null, function getData(err, data) {
      const actionUrl = data?.data_parsed?.action || "";

      const handleDirectUrl = () => {
        if (!actionUrl) return;

        try {
          const regEx = /(actions\/)(.*)(?=\/\?)/; // get the sub string between "utopakc://actions/"" and "/?""
          const res = actionUrl.match(regEx);
          const action = res?.[0];

          const url = new URL(actionUrl);

          if (action === "actions/openMerchant") {
            const merchantId = url.searchParams.get("merchantId");
            const storeId = url.searchParams.get("storeId");

            if (merchantId && storeId) {
              history.push(
                `/storedetail?storeId=${storeId}&merchantId=${merchantId}`
              );
            }
          }
        } catch (error) {
          console.warn(
            "🚀 ~ file: useBranchio.js ~ line 38 ~ handleDirectUrl ~ error",
            error
          );
          history.replace("/");
        }
      };

      handleDirectUrl(actionUrl);
    });

    setInitialized(true);
  }, []);

  return [initialized, branch];
};

export default useBranchIO;
