import { call, put, takeLatest, fork } from "redux-saga/effects";
import actions from "./actions";
import * as TYPE from "./constants";
import api from "../api/api";

const sagas = {
  *[TYPE.ORDER_HISTORY_DETAIL.request](action) {
    try {
      const data = yield call(
        api[TYPE.ORDER_HISTORY_DETAIL.request],
        action.payload
      );
      if (data.status === 200) {
        yield put(actions.ORDER_HISTORY_DETAIL.success(data.data));
      } else {
        yield put(actions.ORDER_HISTORY_DETAIL.failure(data?.data));
      }
    } catch (error) {
      yield put(actions.ORDER_HISTORY_DETAIL.failure(error?.data));
    }
  },
  *[TYPE.INFOMATION_STORE.request](action) {
    try {
      const data = yield call(
        api[TYPE.INFOMATION_STORE.request],
        action.payload
      );
      if (data.status === 200) {
        yield put(actions.INFOMATION_STORE.success(data.data));
      } else {
        yield put(actions.INFOMATION_STORE.failure(data?.data));
      }
    } catch (error) {
      yield put(actions.INFOMATION_STORE.failure(error?.data));
    }
  },
  *[TYPE.CANCEL_FNB.request](action) {
    try {
      const data = yield call(api[TYPE.CANCEL_FNB.request], action.payload);
      if (data.status === 200) {
        yield put(actions.CANCEL_FNB.success(data.data));
      } else {
        yield put(actions.CANCEL_FNB.failure(data?.data));
      }
    } catch (error) {
      yield put(actions.CANCEL_FNB.failure(error?.data));
    }
  },

  // * GET_PAYMENT_METHODS
  *[TYPE.GET_PAYMENT_METHODS.request](action) {
    try {
      const data = yield call(
        api[TYPE.GET_PAYMENT_METHODS.request],
        action.payload
      );
      if (data.status === 200) {
        yield put(actions.GET_PAYMENT_METHODS.success(data.data));
      } else {
        yield put(actions.GET_PAYMENT_METHODS.failure(data?.data));
      }
    } catch (error) {
      yield put(actions.GET_PAYMENT_METHODS.failure(error?.data));
    }
  },
};

function* watchedSaga() {
  yield takeLatest(
    TYPE.ORDER_HISTORY_DETAIL.request,
    sagas[TYPE.ORDER_HISTORY_DETAIL.request]
  );
  yield takeLatest(
    TYPE.INFOMATION_STORE.request,
    sagas[TYPE.INFOMATION_STORE.request]
  );
  yield takeLatest(TYPE.CANCEL_FNB.request, sagas[TYPE.CANCEL_FNB.request]);

  // * GET_PAYMENT_METHODS
  yield takeLatest(
    TYPE.GET_PAYMENT_METHODS.request,
    sagas[TYPE.GET_PAYMENT_METHODS.request]
  );
}

export default function* rootChild() {
  yield fork(watchedSaga);
}
