import { genActions, genActionTypes } from "utils/actionGenerator";

export const TYPE = "TransactionMethods";

export const GET_PAYMENT_METHODS = genActionTypes(
  `${TYPE}_LIST_TRANSACTION_METHODS`
);
export const GET_PAYMENT_CARDS = genActionTypes(
  `${TYPE}_LIST_CURRENT_TRANSACTION_METHODS`
);
export const CREATE_PAYMENT = genActionTypes(`${TYPE}_CREATE_PAYMENT`);
export const GET_UTOP_WALLET_INFO = genActionTypes(
  `${TYPE}_GET_UTOP_WALLET_INFO`
);
export const TRANSACTION_PAY = genActionTypes(`${TYPE}_TRANSACTION_PAY`);
export const GET_ORDER_DETAIL = genActionTypes(`${TYPE}_GET_ORDER_DETAIL`);
export const CLEAR_PAYMENT = genActions(`${TYPE}_CLEAR_PAYMENT`);
