import request from "services/request";
import { encodeQueryData } from "utils/stringFormat";

import * as TYPE from "../store/constants";

const api = {
  [TYPE.VOUCHER_DETAIL_BY_USER.request]: async (params) => {
    const query = encodeQueryData(params);
    return request(`voucher/GetVoucherDetailByUser${query}`, "GET");
  },
};

export default api;
