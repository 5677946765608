/* eslint-disable no-const-assign */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import SVG from "assets/svg";
import "./styles.scss";
import { toVND } from "utils/currencyFormat";
import InputNumber from "views/common/component/InputNumber";
import classNames from "classnames";

function QuantityMenu(props) {
  const {
    // productId,
    items,
    handleOpenMenuOrder,
    handleOpenEditMenu,
    handleCloseQuantityMenu,
    handleRemoveItemInCart,
    handleUpdateItemInCart,
    handleUpdateQuantityOfItem,
  } = props;

  const handleClickAddAnother = () => {
    handleCloseQuantityMenu();

    // ? review dùng setTimeout có issue gì không
    setTimeout(() => {
      handleOpenMenuOrder(items?.[0]?.data, "quantity")();
    }, 300);
  };

  return (
    <div>
      <div className="px-3">
        <div className="_fw-600 _fs-16px text-center only-2-lines">
          {items?.[0]?.data?.name.vi}
        </div>

        <div className="mt-2 _mb-10px">
          <button
            className="button__add-another d-flex align-items-center bgr-white mx-auto"
            onClick={handleClickAddAnother}
            type="button"
          >
            <img src={SVG.PlusSolidYellow} alt="icon PlusSolidYellow" />
            <span className="color-ucla_gold _fs-12px _fw-600 ml-1">
              Thêm tùy chọn khác
            </span>
          </button>
        </div>
      </div>

      <div className="list-items">
        {items?.map((item) => (
          <CartItem
            itemData={item}
            key={item?.dataInfoProduct?.productId}
            itemId={item?.dataInfoProduct?.productId}
            code={item?.dataInfoProduct?.productCode}
            index={item?.dataInfoProduct?.index}
            chosenSize={item?.dataInfoProduct?.sizeName}
            chosenAttributes={item?.dataInfoProduct?.attributes}
            chosenToppings={item?.dataInfoProduct?.toppings}
            chosenQuantity={item?.dataInfoProduct?.quantity}
            itemPrice={item?.dataInfoProduct?.totalPrice}
            handleRemoveItemInCart={handleRemoveItemInCart}
            handleOpenEditMenu={handleOpenEditMenu}
            handleUpdateItemInCart={handleUpdateItemInCart}
            handleUpdateQuantityOfItem={handleUpdateQuantityOfItem}
            handleEditItem={handleClickAddAnother}
            handleCloseQuantityMenu={handleCloseQuantityMenu}
          />
        ))}
      </div>
    </div>
  );
}

const INPUT_MAX_VALUE = 999;
const INPUT_MIN_VALUE = 1;

const CartItem = (props) => {
  const {
    itemData,
    itemId,
    chosenAttributes,
    chosenToppings,
    chosenSize,
    chosenQuantity,
    itemPrice,
    handleRemoveItemInCart,
    handleUpdateQuantityOfItem,
  } = props;

  const [quantity, setQuantity] = useState(chosenQuantity);
  const [totalPrice, setTotalPrice] = useState(itemPrice);
  const quantityRef = useRef();
  const hasClickedDeleteBtn = useRef();

  useEffect(() => {
    setQuantity(chosenQuantity);
    hasClickedDeleteBtn.current = false;
    quantityRef.current = chosenQuantity;

    return () => {
      // handle case when user clear the input box (quantity turn to 0) and close the quantity menu popup
      if (!hasClickedDeleteBtn.current) {
        if (quantityRef.current === 0) {
          handleRemoveItemInCart(itemId)();
        }
      }
    };
  }, []);

  useEffect(() => {
    let total = itemData?.data?.price?.default;
    if (itemData?.dataInfoProduct?.sizeOption) {
      total = itemData?.dataInfoProduct?.sizeOption?.ExtraPrice * 1 + total;
    }
    if (
      itemData?.dataInfoProduct?.toppings &&
      itemData?.dataInfoProduct?.toppings.length > 0
    ) {
      const sum = itemData?.dataInfoProduct?.toppings
        .map((m) => m.price)
        .reduce((partialSum, a) => partialSum + a, 0);
      total = sum + total;
    }
    setTotalPrice(total);
  }, [itemData, quantity]);

  const updateCart = (valueQuantity) => {
    if (valueQuantity > 0) {
      const itemCart = {
        data: itemData?.data,
        dataInfoProduct: {
          ...itemData?.dataInfoProduct,
          totalPrice: totalPrice * valueQuantity,
          quantity: valueQuantity,
        },
      };
      handleUpdateQuantityOfItem(itemCart);
      setTotalPrice(totalPrice * valueQuantity);
    }
  };

  // handle quantity
  const handleIncreaseQuantity = () => {
    setQuantity((prevQuantity) => {
      if (prevQuantity < INPUT_MAX_VALUE) {
        updateCart(prevQuantity + 1);
        return prevQuantity + 1;
      }

      // todo: show helper
      updateCart(prevQuantity);
      return prevQuantity;
    });
  };

  const handleDecreaseQuantity = () => {
    setQuantity((prevQuantity) => {
      if (prevQuantity > INPUT_MIN_VALUE) {
        updateCart(prevQuantity - 1);
        return prevQuantity - 1;
      }

      // todo: show helper
      updateCart(prevQuantity);
      return prevQuantity;
    });
  };

  // handle quantity - by keyboard - only commit number
  const handleChangeQuantity = (e) => {
    const valueAsNumber = parseInt(e.target.value, 10);
    e.target.value = valueAsNumber;

    if (!valueAsNumber) {
      setQuantity(0);
      return;
    }
    if (valueAsNumber > INPUT_MAX_VALUE) {
      setQuantity(INPUT_MAX_VALUE);
      return;
    }

    setQuantity(valueAsNumber);
  };

  const handleClickDelete = () => {
    hasClickedDeleteBtn.current = true;
    // handleRemoveItemInCart(itemId)();
    handleRemoveItemInCart(props.index);
    props.handleCloseQuantityMenu();
  };

  return (
    <div className="mx-3 _py-12px">
      <div className="d-flex justify-content-between ">
        <div>
          {chosenSize && (
            <div>
              <p className="mb-0 _fs-14px _fw-400" style={{ display: "flex" }}>
                <span>Size</span>
                <span className="_fw-500">{`: ${chosenSize}`}</span>
              </p>
            </div>
          )}

          {chosenAttributes &&
            chosenAttributes.length > 0 &&
            chosenAttributes.map((attribute) => (
              <div key={`${itemId}___${attribute.Name || attribute.name}`}>
                <p className="mb-0 _fs-14px _fw-400">
                  {attribute.Name || attribute.name}:{" "}
                  <span className="_fw-500">
                    {attribute.Value || attribute.value}
                  </span>
                </p>
              </div>
            ))}

          {chosenToppings?.length > 0 && (
            <div>
              <p className="mb-0 _fs-14px _fw-400">
                Topping:{" "}
                <span className="_fw-500">
                  {genTopingDescription(chosenToppings)}
                </span>
              </p>
            </div>
          )}

          {!chosenSize &&
            chosenAttributes.length === 0 &&
            chosenToppings.length === 0 && <div>Không có tùy chọn</div>}
        </div>

        <div className="text-end" style={{ minWidth: "10ch" }}>
          <span className="_fw-600 _fs-14px color-orange">
            {toVND(totalPrice * quantity)}
          </span>
        </div>
      </div>

      <div className="d-flex align-items-center justify-content-end mt-3">
        <button
          type="button"
          className="button_small shadow-none outlined"
          onClick={handleClickDelete}
        >
          <img src={SVG.GarbageCan} alt="" style={{ marginRight: 6 }} />
          <span className="color-chinese_black _fs-12px _fw-500">Xóa</span>
        </button>

        <button
          type="button"
          className={classNames(
            "button_small shadow-none outlined mx-2",
            quantity === 0 && "button_small--disabled"
          )}
          onClick={props.handleOpenEditMenu(itemData)}
          disabled={quantity === 0}
        >
          <img src={SVG.Pencil} alt="" style={{ marginRight: 5 }} />
          <span className="color-chinese_black _fs-12px _fw-500">Thay đổi</span>
        </button>

        <div style={{ marginLeft: 6 }}>
          <InputNumber
            value={quantity}
            handleIncrease={handleIncreaseQuantity}
            handleDecrease={handleDecreaseQuantity}
            handleChange={handleChangeQuantity}
            maxValue={INPUT_MAX_VALUE}
          />
        </div>
      </div>
    </div>
  );
};

const genTopingDescription = (chosenToppings) =>
  chosenToppings?.map((topping) => topping?.name).join(", ");

export default QuantityMenu;
