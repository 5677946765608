/* eslint-disable no-param-reassign */
import { addErrors, delErrors } from "utils/ErrorsGenerator";
import * as TYPE from "./constants";

const reducers = {
  [TYPE.GET_LIST_CARD_TOPUP.success]: (draft, { payload }) => {
    draft.errors = delErrors(draft.errors, TYPE.GET_LIST_CARD_TOPUP.failure);
    draft.cardLive = payload;
  },

  [TYPE.GET_LIST_CARD_TOPUP.failure]: (draft, { payload }) => {
    draft.errors = addErrors(draft.errors, {
      type: TYPE.GET_LIST_CARD_TOPUP.failure,
      msg: payload,
    });
  },

  [TYPE.GET_LIST_BUY_CARD.success]: (draft, { payload }) => {
    draft.errors = delErrors(draft.errors, TYPE.GET_LIST_BUY_CARD.failure);
    draft.buyCard = payload;
  },

  [TYPE.GET_LIST_BUY_CARD.failure]: (draft, { payload }) => {
    draft.errors = addErrors(draft.errors, {
      type: TYPE.GET_LIST_BUY_CARD.failure,
      msg: payload,
    });
  },

  [TYPE.CREATE_ODER_TOPUP.request]: (draft) => {
    draft.createOrder = false;
  },

  [TYPE.CREATE_ODER_TOPUP.success]: (draft, { payload }) => {
    draft.errors = delErrors(draft.errors, TYPE.CREATE_ODER_TOPUP.failure);
    draft.order = payload;

    localStorage.setItem("OrderTopup", JSON.stringify(payload));
    draft.createOrder = true;
  },

  [TYPE.CREATE_ODER_TOPUP.failure]: (draft, { payload }) => {
    draft.errors = addErrors(draft.errors, {
      type: TYPE.CREATE_ODER_TOPUP.failure,
      msg: payload,
    });
    draft.createOrder = false;
  },


};

export default reducers;
