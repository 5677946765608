export const GET_LIST_CARD_REQUEST = 'myCard/GET_LIST_CARD_REQUEST';
export const GET_LIST_CARD_SUCCESS = 'myCard/GET_LIST_CARD_SUCCESS';
export const GET_LIST_CARD_FAILED = 'myCard/GET_LIST_CARD_FAILED';
export const GET_LIST_CARD_FOXPAY_REQUEST =
  'myCard/GET_LIST_CARD_FOXPAY_REQUEST';
export const CANCEL_LINK_CARD_REQUEST = 'myCard/CANCEL_LINK_CARD_REQUEST';
export const CANCEL_LINK_CARD_FOXPAY_REQUEST =
  'myCard/CANCEL_LINK_CARD_FOXPAY_REQUEST';
export const CREATETOKENIZATION_REQUEST = 'myCard/CREATETOKENIZATION_REQUEST';
export const CHECK_STATUS_CARD_MEMBER_REQUEST =
  'myCard/CHECK_STATUS_CARD_MEMBER';
export const GET_INFO_TENANT_REQUEST = 'myCard/GET_INFO_TENANT';
export const LINK_CARD_MEMBER_REQUEST = 'myCard/LINK_CARD_MEMBER_REQUEST';
export const GET_LIST_CARD_MEMBER_REQUEST =
  'myCard/GET_LIST_CARD_MEMBER_REQUEST';
export const CANCEL_LINK_CARD_MEMBER_REQUEST =
  'myCard/CANCEL_LINK_CARD_MEMBER_REQUEST';
export const UPDATE_INFO_CARD_MEMBER_REQUEST =
  'myCard/UPDATE_INFO_CARD_MEMBER_REQUEST';
