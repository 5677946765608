/* eslint-disable react/no-array-index-key */
/* eslint-disable prefer-template */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState, createRef } from "react";
import "./MarketImages.scss";
import { NavigationServices } from "utils";
import { Player, ControlBar, PlayToggle, BigPlayButton } from "video-react";
import "video-react/dist/video-react.css";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import { Pagination, EffectCube } from "swiper";
import "swiper/swiper.min.css";
import "swiper/swiper-bundle.min.css";
import moment from "moment";
import { icons } from "assets/icons/index";
import Header from "./components/Header";
import Loading from "./components/Loading";
import Popup from "./components/Popup";

const MarketImagesView = (props) => {
  const [isShowStory, setIsShowStory] = useState(false);
  const [isImageViewer, setIsImageViewer] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [swiperReviewImageIndex, setSwiperReviewImageIndex] = useState(-1);
  const [swiperReviewStoryIndex, setSwiperReviewStoryIndex] = useState(-1);
  const [initSwiperReviewStoryIndex, setInitSwiperReviewStoryIndex] =
    useState(-1);
  const [initSwiperReviewImageIndex, setInitSwiperReviewImageIndex] =
    useState(-1);
  const videoRef = useRef(null);
  const swiperReviewImageRef = useRef(null);
  const swiperReviewStoryRef = useRef(null);
  const swiperReviewpaginationRef = useRef(null);
  const video_height = window.innerHeight * 0.78;
  const video_story_height = window.innerHeight * 0.85;
  const video_width = window.innerWidth;
  const [currentCategory, setCurrentCategory] = useState({});

  useEffect(() => {
    if (
      swiperReviewImageIndex > -1 &&
      swiperReviewpaginationRef.current !== undefined
    ) {
      swiperReviewpaginationRef.current?.swiper?.slideTo(
        swiperReviewImageIndex - 1
      );
    }
  }, [swiperReviewImageIndex]);
  useEffect(() => {
    if (isShowStory) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }
  }, [isShowStory]);
  const secondsToHms = (d) => {
    const result = Number(d);
    return moment(result).format("mm:ss");
  };
  const goBack = () => {
    NavigationServices.goBack();
  };
  const onNextStory = () => {
    swiperReviewStoryRef.current?.swiper?.slideNext();
  };
  const scrollRefs = useRef([]);
  const scrollSmoothHandler = (index) => {
    try {
      scrollRefs?.current[index]?.current?.scrollIntoView({
        behavior: "smooth",
      });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    scrollRefs.current = [...Array(props.albums.length).keys()].map(
      (_, i) => scrollRefs.current[i] ?? createRef()
    );
  }, [props.albums]);

  return (
    <div>
      {!props.isLoading ? (
        <div className="market-images">
          <div className="scroll-top" id="albums_0"></div>
          <div className="store-header__wrapper">
            <Header
              storeName={"Tổng hợp hình ảnh"}
              handleChangeSearchStatus={() => {}}
              goBack={goBack}
              onCommitInputSearch={() => {}}
              rightButton={() => {
                return <img src={icons.iconFilter} />;
              }}
              onRightButtonClick={() => {
                setShowFilter(true);
              }}
            />
          </div>
          {!!props.listHistory && props.listHistory?.length > 0 && (
            <div className="album list-story" id="albums_1">
              <div className="title-album">
                {props.dataStory.categoryName} ( {props.dataStory.count} )
              </div>

              <Swiper
                slidesPerView={3.8}
                spaceBetween={10}
                pagination={{
                  clickable: true,
                }}
                // modules={[Pagination]}
                className="swiper-story"
              >
                {props.listHistory?.length > 0 &&
                  props.listHistory?.map((item2, index) => {
                    return (
                      <div key={"listHistory" + index}>
                        <SwiperSlide>
                          <div
                            className="view-image"
                            onClick={() => {
                              props.loadMoreStory();
                              setInitSwiperReviewStoryIndex(index);
                              setSwiperReviewStoryIndex(index);
                              setIsShowStory(true);
                            }}
                          >
                            {item2.type === 1 ? (
                              <img
                                className="image-story"
                                src={item2.thumbnail}
                              />
                            ) : (
                              <img
                                className="image-story"
                                src={item2.content}
                              />
                            )}
                          </div>
                          {item2.type === 1 && (
                            <div
                              onClick={() => {
                                props.loadMoreStory();
                                setInitSwiperReviewStoryIndex(index);
                                setSwiperReviewStoryIndex(index);
                                setIsShowStory(true);
                              }}
                              className="item-video-story"
                            >
                              <div className="txt-duration-story">
                                {secondsToHms(item2.duration)}
                              </div>
                              <img
                                src={icons.iconPause}
                                className="icon-pause"
                              />
                            </div>
                          )}
                        </SwiperSlide>
                      </div>
                    );
                  })}
              </Swiper>
            </div>
          )}
          {props.albums?.map((itemParent, indexParent) => {
            if (itemParent.count > 0) {
              return (
                <div
                  key={"albums" + indexParent}
                  className="album"
                  ref={scrollRefs.current[indexParent]}
                >
                  <div className="title-album">
                    {itemParent.categoryName} ( {itemParent.count} )
                  </div>
                  <div className="list-image">
                    {itemParent.mediaAssets?.length > 0 &&
                      itemParent.mediaAssets
                        ?.slice(0, 8)
                        .map((item2, index) => {
                          return (
                            <div
                              key={"mediaAssets" + index}
                              className="view-image"
                              onClick={() => {
                                props.onClickImageCategory(itemParent);
                                setInitSwiperReviewImageIndex(index);
                                setIsImageViewer(true);
                                setSwiperReviewImageIndex(index);
                                // swiperReviewImageRef.current?.swiper?.slideTo(index);
                              }}
                            >
                              {item2.type === 1 ? (
                                <img
                                  className="image-category"
                                  src={item2.thumbnail}
                                />
                              ) : (
                                <img
                                  className="image-category"
                                  src={item2.content}
                                />
                              )}
                              {item2.type === 1 && index !== 7 && (
                                <div className="item-video-album">
                                  <div className="txt-duration-album">
                                    {secondsToHms(item2.duration)}
                                  </div>
                                  <img
                                    src={icons.iconPause}
                                    className="icon-pause"
                                  />
                                </div>
                              )}
                              {itemParent.count > 8 && index === 7 && (
                                <div className="see-more">Xem thêm</div>
                              )}
                            </div>
                          );
                        })}
                  </div>
                </div>
              );
            }
            return null;
          })}
          {isShowStory && (
            <div className="image-viewer-container">
              <div className="image-viewer-header">
                <div className="current-title"></div>
                <div
                  className="close-button"
                  onClick={() => {
                    setIsShowStory(false);
                  }}
                >
                  <img src={icons.iconClose} />
                </div>
              </div>
              <div className="story-viewer-content">
                <Swiper
                  initialSlide={initSwiperReviewStoryIndex}
                  ref={swiperReviewStoryRef}
                  slidesPerView={1}
                  spaceBetween={0}
                  pagination={{
                    clickable: true,
                  }}
                  className="mySwiper"
                  onSlideChange={(swiper) => {
                    setSwiperReviewStoryIndex(swiper.activeIndex);
                  }}
                  cubeEffect={{
                    shadow: true,
                    slideShadows: true,
                    shadowOffset: 20,
                    shadowScale: 0.94,
                  }}
                  // modules={[EffectCube]}
                >
                  {props.listHistory?.length > 0 &&
                    props.listHistory?.map((item, index) => {
                      return (
                        <div key={"listHistory" + index}>
                          <SwiperSlide>
                            {item.type === 1 ? (
                              index === swiperReviewStoryIndex ? (
                                <Player
                                  fluid={false}
                                  width={video_width}
                                  height={video_story_height}
                                  ref={videoRef}
                                  autoPlay
                                  poster={item.thumbnail}
                                  onEnded={(e) => {
                                    onNextStory();
                                  }}
                                >
                                  <ControlBar autoHide>
                                    <PlayToggle />
                                  </ControlBar>
                                  <BigPlayButton position="center" />
                                  <source src={item.content} />
                                </Player>
                              ) : (
                                <img
                                  className="item-image"
                                  src={item.thumbnail}
                                />
                              )
                            ) : (
                              <img className="item-image" src={item.content} />
                            )}
                          </SwiperSlide>
                        </div>
                      );
                    })}
                </Swiper>
              </div>
            </div>
          )}
          {isImageViewer && (
            <div className="image-viewer-container">
              <div className="image-viewer-header">
                <div className="current-title">
                  {props.currentCategoryViewer?.categoryName}
                </div>
                <div
                  className="close-button"
                  onClick={() => {
                    setIsImageViewer(false);
                  }}
                >
                  <img src={icons.iconClose} />
                </div>
              </div>
              <div className="image-viewer-content">
                <Swiper
                  initialSlide={initSwiperReviewImageIndex}
                  ref={swiperReviewImageRef}
                  slidesPerView={1}
                  spaceBetween={0}
                  pagination={{
                    clickable: true,
                  }}
                  // modules={[Pagination]}
                  className="mySwiper"
                  onSlideChange={(swiper) => {
                    console.log("swiper.activeIndex", swiper.activeIndex);
                    setSwiperReviewImageIndex(swiper.activeIndex);
                  }}
                >
                  {props.currentCategoryViewer?.mediaAssets?.length > 0 &&
                    props.currentCategoryViewer?.mediaAssets?.map(
                      (item, index) => {
                        return (
                          <div key={"currentCategoryViewer" + index}>
                            <SwiperSlide>
                              {item.type === 1 ? (
                                index === swiperReviewImageIndex ? (
                                  <Player
                                    fluid={false}
                                    width={video_width}
                                    height={video_height}
                                    ref={videoRef}
                                    autoPlay
                                    poster={item.thumbnail}
                                  >
                                    <ControlBar autoHide>
                                      <PlayToggle />
                                    </ControlBar>
                                    <source src={item.content} />
                                  </Player>
                                ) : (
                                  <img
                                    className="item-image"
                                    src={item.thumbnail}
                                  />
                                )
                              ) : (
                                <img
                                  className="item-image"
                                  src={item.content}
                                />
                              )}
                            </SwiperSlide>
                          </div>
                        );
                      }
                    )}
                </Swiper>
              </div>
              <div className="image-viewer-pagination">
                <Swiper
                  ref={swiperReviewpaginationRef}
                  slidesPerView={"auto"}
                  spaceBetween={5}
                  pagination={{
                    clickable: true,
                  }}
                  // modules={[Pagination]}
                  className="mySwiper"
                >
                  {props.currentCategoryViewer?.mediaAssets?.length > 0 &&
                    props.currentCategoryViewer?.mediaAssets?.map(
                      (item, index) => {
                        return (
                          <div key={"mediaAssets" + index}>
                            <SwiperSlide>
                              {item.type === 1 ? (
                                <img
                                  className="item-image-pagination"
                                  src={item.thumbnail}
                                />
                              ) : (
                                <img
                                  className="item-image-pagination"
                                  src={item.content}
                                />
                              )}
                              {index === swiperReviewImageIndex ? (
                                <div
                                  onClick={() => {
                                    setSwiperReviewImageIndex(index);
                                  }}
                                  className="active-image"
                                ></div>
                              ) : (
                                <div
                                  onClick={() => {
                                    setSwiperReviewImageIndex(index);
                                    swiperReviewImageRef.current?.swiper?.slideTo(
                                      index
                                    );
                                  }}
                                  className="inactive-image"
                                >
                                  {item.type === 1 && (
                                    <div className="txt-duration">
                                      {secondsToHms(item.duration)}
                                    </div>
                                  )}
                                  {item.type === 1 && (
                                    <img
                                      src={icons.iconPause}
                                      className="icon-pause"
                                    />
                                  )}
                                </div>
                              )}
                            </SwiperSlide>
                          </div>
                        );
                      }
                    )}
                </Swiper>
              </div>
            </div>
          )}
          <Popup
            isOpen={showFilter}
            handleClose={() => {
              setShowFilter(false);
            }}
          >
            <div className="title-filter">Danh mục hình ảnh</div>
            <div className="view-filter">
              {!!props.listAllCategory &&
                props.listAllCategory?.length > 0 &&
                props.listAllCategory.map((item, index) => {
                  return (
                    <div
                      key={"listAllCategory" + index}
                      className={
                        item.categoryId === currentCategory.categoryId
                          ? "filter-category-name-active"
                          : "filter-category-name"
                      }
                      onClick={() => {
                        setShowFilter(false);
                        if (index === 0) {
                          window.scrollTo({
                            top: 0,
                            behavior: "smooth",
                          });
                        } else scrollSmoothHandler(index);
                      }}
                    >
                      <div
                        className="txt-title-category"
                        onClick={() => {
                          setCurrentCategory(item);
                        }}
                        // href={"#albums_" + index}
                      >
                        {item.categoryName}
                      </div>
                    </div>
                  );
                })}
            </div>
          </Popup>
        </div>
      ) : (
        <div className="market-images">
          <div className="store-header__wrapper">
            <Header
              storeName={"Tổng hợp hình ảnh"}
              handleChangeSearchStatus={() => {}}
              goBack={goBack}
              onCommitInputSearch={() => {}}
              rightButton={() => {
                return <img src={icons.iconFilter} />;
              }}
              onRightButtonClick={() => {
                setShowFilter(true);
              }}
            />
          </div>
          <Loading />
        </div>
      )}
    </div>
  );
};
export default MarketImagesView;
