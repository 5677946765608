// address list
export const GET_LIST_ADDRESS_BOOK_REQUEST = 'addressBook/GET_LIST_ADDRESS_BOOK_REQUEST';

export const GET_LIST_ADDRESS_BOOK_SUCCESS = 'addressBook/GET_LIST_ADDRESS_BOOK_SUCCESS';

export const GET_LIST_ADDRESS_BOOK_FAILED = 'addressBook/GET_LIST_ADDRESS_BOOK_FAILED';

// remove address
export const REMOVE_ADDRESS_REQUEST = 'addressBook/REMOVE_ADDRESS_REQUEST';

export const REMOVE_ADDRESS_SUCCESS = 'addressBook/REMOVE_ADDRESS_SUCCESS';

export const REMOVE_ADDRESS_FAILED = 'addressBook/REMOVE_ADDRESS_FAILED';

// set default address
export const SET_DEFAULT_ADDRESS_REQUEST = 'addressBook/SET_DEFAULT_ADDRESS_REQUEST';

export const SET_DEFAULT_ADDRESS_SUCCESS = 'addressBook/SET_DEFAULT_ADDRESS_SUCCESS';

export const SET_DEFAULT_ADDRESS_FAILED = 'addressBook/SET_DEFAULT_ADDRESS_FAILED';
