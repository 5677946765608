import React, { useRef, useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import {
  getProductMarketBySearchRequest,
  clearSearchMarket,
  clearSearchHistoryMarket,
  getProductDetailMarketRequest,
  clearProductDetailMarket,
} from "@shared/redux/market/marketSearch/actions";
import {
  getIsEndSearchMarketSelector,
  getListProductOutOfStockSelector,
  getListProductSearchMarketSelector,
} from "@shared/redux/market/marketSearch/selectors";
import {
  NavigationServices,
  //   insightTrackEvent,
} from "utils";

import {
  checkGuest,
  checkGuestPopup,
  updateProductCart as updateProductsCart,
} from "@shared/utils";
import {
  getDataStore,
  getListProduct,
} from "@shared/redux/smartMenu/selectors";
import {
  addProductCart,
  setDataStore,
  resetProductCart,
  updateListProductCart,
  updateProductCart,
  deleteProductCart,
  setIndexProductCart,
} from "@shared/redux/smartMenu/actions";
// import { Alert } from "react-native";
// import { strings } from "@i18n";
import MarketSearchView from "./MarketSearch.view";

const MarketSearch = (props) => {
  const params = NavigationServices.getParams(props);

  const [data, setData] = useState(null);
  const [txtSearch, setTxtSearch] = useState("");
  const [storeInfo, setStoreInfo] = useState({});
  const [isShowLoading, setIsShowLoading] = useState(false);
  const [alertAction, setAlertAction] = useState({});
  const flatRef = useRef();

  const listProductSearchMarkettmp = useSelector(
    (store) => store.marketReducer.listProductSearchMarket
  );

  const listSearchHistory = useSelector(
    (store) => store.marketReducer.listSearchHistory
  );

  useEffect(() => {
    setStoreInfo(params?.storeInfo);
    return () => {
      props.clearSearchMarket();
    };
  }, [params?.storeInfo]);

  useEffect(() => {
    if (
      (JSON.stringify(props.getDataStore) !== "{}" &&
        storeInfo?.id === props.getDataStore.storeId) ||
      params?.dataReOrder
    ) {
      props.setIndexProductCart();
    }
  }, [props.getDataCart, props.getDataStore]);

  useEffect(() => {
    if (props.listProductSearchMarket) {
      setIsShowLoading(false);
      setData([...props.listProductSearchMarket]);
    }
  }, [props.listProductSearchMarket]);

  const _onSearchProduct = (valueSearch) => {
    // eslint-disable-next-line no-param-reassign
    valueSearch = valueSearch.trim();
    if (valueSearch.length > 0) {
      setIsShowLoading(true);

      if (flatRef.current) {
        flatRef.current.scrollToOffset({ animated: true, offset: 0 });
      }
      setTxtSearch(valueSearch);
      const storeId = params?.storeInfo?.id;
      //   const storeName =params?.storeInfo?.name;
      const merchantId = params?.storeInfo?.merchantId;
      //   const merchantName =params?.storeInfo?.merchantName;
      const categoryId = params?.dataStoreMerchant?.id;

      //   const dataTrackingAzure = {
      //     MerchantId: merchantId,
      //     MerchantName: merchantName,
      //     StoreId: storeId,
      //     StoreName: storeName,
      //     searchText: valueSearch,
      //   };
      //   insightTrackEvent("Search_Product", dataTrackingAzure);

      props.getProductMarketBySearchRequest({
        valueSearch,
        skipCount: 0,
        isLoadNew: true,
        storeId,
        merchantId,
        isEndSearchMarket: props.isEndSearchMarket,
        categoryId,
      });
    }
  };

  const onLoadMore = () => {
    if (
      props.isEndSearchMarket !== undefined &&
      !props.isEndSearchMarket &&
      !isShowLoading
    ) {
      console.log(listProductSearchMarkettmp);
      const storeId = params?.storeInfo?.id;
      const merchantId = params?.storeInfo?.merchantId;
      const categoryId = params?.dataStoreMerchant?.id;

      props.getProductMarketBySearchRequest({
        valueSearch: txtSearch,
        skipCount: listProductSearchMarkettmp?.skip,
        isLoadNew: false,
        storeId,
        merchantId,
        isEndSearchMarket: props.isEndSearchMarket,
        categoryId,
      });
    }
  };

  const _onClearSearchtext = () => {
    setTxtSearch("");
    setData(null);
    props.clearSearchMarket();
  };

  const clearSearchHistory = () => {
    props.clearSearchHistoryMarket();
    props.clearSearchMarket();
  };

  const updateCart = (itemCart) => {
    // const dataTrackingAzure = {
    //   MerchantId: storeInfo?.merchantId,
    //   MerchantName: storeInfo?.merchantName,
    //   StoreId: storeInfo?.id,
    //   StoreName: storeInfo?.name,
    //   Quantity: itemCart?.dataInfoProduct?.quantity,
    //   ProductCode: itemCart?.dataInfoProduct?.productCode,
    //   ProductId: itemCart?.dataInfoProduct?.productId,
    //   ProductName: itemCart?.data?.name?.vi,
    // };
    // insightTrackEvent("Add_To_Cart", dataTrackingAzure);
    if (
      props?.getDataCart?.length > 0 &&
      JSON.stringify(props.getDataStore) !== "{}" &&
      storeInfo?.id !== props.getDataStore.storeId
    ) {
      setAlertAction({
        isShow: true,
        title: "Tạo giỏ hàng mới?",
        content:
          "Thêm sản phẩm này sẽ khiến các sản phẩm hiện tại trong giỏ hàng của bạn bị xoá. Bạn có muốn tiếp tục?",
        titleAccept: "Tiếp tục",
        titleCancel: "Đóng",
        dataItem: { ...itemCart },
      });
      //   Alert.alert(
      //     strings("SmartOrderMenu.createNewCart"),
      //     strings("SmartOrderMenu.labelAddNewCart"),
      //     [
      //       {
      //         text: strings("common.labelClose"),
      //         style: "cancel",
      //       },
      //       {
      //         text: strings("Login.label5"),
      //         onPress: () => {
      //           storeInfo.storeId = storeInfo.id;
      //           props.setDataStore(storeInfo);
      //           props.resetProductCart({
      //             itemCart,
      //             isNotClearCoupon:
      //              params.isFrom === "ApplicableStore",
      //           });
      //         },
      //       },
      //     ],
      //     { cancelable: false }
      //   );
    } else {
      if (
        JSON.stringify(props.getDataStore) === "{}" ||
        storeInfo?.id !== props.getDataStore.storeId
      ) {
        storeInfo.storeId = storeInfo.id;
        props.setDataStore(storeInfo);
      }
      updateProductsCart(props.getDataCart, itemCart, (type, payload) => {
        switch (type) {
          case "add":
            props.addProductCart(payload);
            break;

          case "update":
            props.updateListProductCart(payload);
            break;

          default:
            break;
        }
      });
    }
  };

  const onSubmit = () => {
    checkGuest(function (isGuest) {
      if (isGuest) {
        checkGuestPopup();
      } else {
        storeInfo.storeId = storeInfo.id;
        props.setDataStore(storeInfo);
        props.setIndexProductCart();

        NavigationServices.push("Cart", { isFromMarket: true });
      }
    });
  };

  return (
    <MarketSearchView
      dataProducts={data}
      dataOutOfStock={props.dataOutOfStock}
      _onSearchProduct={_onSearchProduct}
      isLoading={props.isLoading}
      loadMore={onLoadMore}
      _onClearSearchtext={_onClearSearchtext}
      listSearchHistory={listSearchHistory}
      txtSearch={txtSearch}
      clearSearchHistory={clearSearchHistory}
      clearProductDetailMarket={props.clearProductDetailMarket}
      updateCart={updateCart}
      updateProductCart={props.updateProductCart}
      dataCart={
        JSON.stringify(props?.getDataStore) !== "{}" &&
        storeInfo?.id === props?.getDataStore?.storeId
          ? props?.getDataCart
          : params?.dataReOrder || []
      }
      onSubmit={onSubmit}
      placeholder="Nhập để tìm kiếm..."
      deleteProductCart={props.deleteProductCart}
      isShowLoading={isShowLoading}
      storeInfo={storeInfo}
      isEndSearchMarket={props.isEndSearchMarket}
      isCloseStore={params?.isCloseStore}
      flatRef={flatRef}
      dataStoreMerchant={params?.dataStoreMerchant?.id}
      dataAlertAction={alertAction}
      setDataAlertAction={() => setAlertAction({})}
      handleAlertAction={(itemCart) => {
        storeInfo.storeId = storeInfo.id;
        props.setDataStore(storeInfo);
        props.resetProductCart({
          itemCart,
          isNotClearCoupon: params?.isFrom === "ApplicableStore",
        });
        setAlertAction({});
      }}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    listProductSearchMarket: getListProductSearchMarketSelector(state),
    dataOutOfStock: getListProductOutOfStockSelector(state),
    getDataCart: getListProduct(state),
    getDataStore: getDataStore(state),
    isEndSearchMarket: getIsEndSearchMarketSelector(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getProductMarketBySearchRequest,
      clearSearchMarket,
      clearSearchHistoryMarket,
      getProductDetailMarketRequest,
      clearProductDetailMarket,
      updateProductCart,
      deleteProductCart,
      setDataStore,
      addProductCart,
      updateListProductCart,
      resetProductCart,
      setIndexProductCart,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(MarketSearch);
