import {
  GET_STORE_DETAIL_REQUEST,
  GET_STORE_DETAIL_SUCCESS,
  GET_STORE_DETAIL_FAILED,
  GET_LIST_VOUCHER_BY_STORE_REQUEST,
  GET_LIST_VOUCHER_BY_STORE_SUCCESS,
  GET_LIST_VOUCHER_BY_STORE_FAILED,
  GET_MERCHANT_INFO_REQUEST,
  GET_MERCHANT_INFO_SUCCESS,
  GET_MERCHANT_INFO_FAILED,
  GET_LIST_COUPON_SUGGEST_REQUEST,
  GET_LIST_COUPON_SUGGEST_SUCCESS,
  GET_LIST_COUPON_SUGGEST_FAILED,
  GET_LIST_BEST_SELLER_REQUEST,
  GET_LIST_BEST_SELLER_SUCCESS,
  GET_LIST_BEST_SELLER_FAILED,
  UPDATE_LIST_SUGGEST_COUPON,
  GET_LIST_VIDEO_STORE_REQUEST,
  GET_LIST_VIDEO_STORE_SUCCESS,
  GET_LIST_VIDEO_STORE_FAILED,
  GET_STORE_DETAIL_FROM_STORE_CODE_REQUEST,
  SET_LIST_COUPON_SUGGEST_FOR_STORE,
  UPDATE_LIST_COUPON_SUGGEST_FOR_STORE,
} from './constants';

export const getStoreDetailFromStoreCodeHandle = (
  payload = {},
  onSuccess,
  onFailed,
) => ({
  type: GET_STORE_DETAIL_FROM_STORE_CODE_REQUEST,
  payload,
  onSuccess,
  onFailed,
});
export const getStoreDetailHandle = (payload = {}, onSuccess, onFailed) => ({
  type: GET_STORE_DETAIL_REQUEST,
  payload,
  onSuccess,
  onFailed,
});

export const getStoreDetailSuccess = (payload = {}) => ({
  type: GET_STORE_DETAIL_SUCCESS,
  payload,
});

export const getStoreDetailFailed = (payload = {}) => ({
  type: GET_STORE_DETAIL_FAILED,
  payload,
});

export const getListVoucherByStoreHandle = (
  payload = {},
  onSuccess,
  onFailed,
) => ({
  type: GET_LIST_VOUCHER_BY_STORE_REQUEST,
  payload,
  onSuccess,
  onFailed,
});

export const getListVoucherByStoreSuccess = (payload = {}) => ({
  type: GET_LIST_VOUCHER_BY_STORE_SUCCESS,
  payload,
});

export const getListVoucherByStoreFailed = (payload = {}) => ({
  type: GET_LIST_VOUCHER_BY_STORE_FAILED,
  payload,
});

export const getMerchantInfoHandle = (payload = {}, onSuccess, onFailed) => ({
  type: GET_MERCHANT_INFO_REQUEST,
  payload,
  onSuccess,
  onFailed,
});

export const getMerchantInfoSuccess = (payload = {}) => ({
  type: GET_MERCHANT_INFO_SUCCESS,
  payload,
});

export const getMerchantInfoFailed = (payload = {}) => ({
  type: GET_MERCHANT_INFO_FAILED,
  payload,
});

export const getListCouponSuggestHandle = (
  payload = {},
  onSuccess,
  onFailed,
) => ({
  type: GET_LIST_COUPON_SUGGEST_REQUEST,
  payload,
  onSuccess,
  onFailed,
});

export const getListCouponSuggestSuccess = (payload = {}) => ({
  type: GET_LIST_COUPON_SUGGEST_SUCCESS,
  payload,
});

export const getListCouponSuggestFailed = (payload = {}) => ({
  type: GET_LIST_COUPON_SUGGEST_FAILED,
  payload,
});

export const getListBestSellerHandle = (payload = {}, onSuccess, onFailed) => ({
  type: GET_LIST_BEST_SELLER_REQUEST,
  payload,
  onSuccess,
  onFailed,
});

export const getListBestSellerSuccess = (payload = {}) => ({
  type: GET_LIST_BEST_SELLER_SUCCESS,
  payload,
});

export const getListBestSellerFailed = (payload = {}) => ({
  type: GET_LIST_BEST_SELLER_FAILED,
  payload,
});

export const updateListSuggestCoupon = (payload = '') => ({
  type: UPDATE_LIST_SUGGEST_COUPON,
  payload,
});

export const getListVideoStoreRequest = (payload = {}) => ({
  type: GET_LIST_VIDEO_STORE_REQUEST,
  payload,
});

export const getListVideoStoreSuccess = (payload = {}) => ({
  type: GET_LIST_VIDEO_STORE_SUCCESS,
  payload,
});

export const getListVideoStoreFailed = (payload = {}) => ({
  type: GET_LIST_VIDEO_STORE_FAILED,
  payload,
});
export const setListCouponSuggestForStore = (payload = {}) => ({
  type: SET_LIST_COUPON_SUGGEST_FOR_STORE,
  payload,
});

export const updateListCouponSuggestForStore = (payload = '') => ({
  type: UPDATE_LIST_COUPON_SUGGEST_FOR_STORE,
  payload,
});
