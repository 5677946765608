import { call, put, takeLatest, fork } from 'redux-saga/effects'
import { MY_VIDEO_REQUEST, USER_INFO_REQUEST } from './types'
import { END_POINTS } from '@shared/constants/apiUrl'
import {
  myVideoSuccess,
  myVideoFailure,
  userInfoSuccess,
  userInfoFailure,
} from './actions'
import { APIUtils } from 'utils'

function myVideoApi({ skipCount, maxResult }) {
  const requestData = `?SkipCount=${skipCount}&MaxResult=${maxResult}`
  return APIUtils.get(END_POINTS.MyVideo + requestData)
}

export function* myVideo(obj) {
  const { onSuccess } = obj.payload
  try {
    const data = yield call(myVideoApi, obj.payload)

    if (Array.isArray(data)) {
      yield put(myVideoSuccess([...obj.payload.list, ...data]))
      if (typeof onSuccess === 'function') {
        onSuccess(data)
      }
    } else {
      yield put(myVideoFailure(data))
    }
  } catch (err) {
    yield put(myVideoFailure(err))
  }
}

function userInfoApi() {
  const requestData = ``
  return new Promise((resolve, reject) => {
    reactUtils.apiRequest(
      null,
      apiLinks.UserInfo,
      'GET',
      requestData,
      (respone) => {
        resolve(respone)
      },
      (error) => {
        console.log(`%c error`, 'color: blue; font-weight: 600', error)
        reject(error)
      },
    )
  })
}

export function* userInfo(obj) {
  try {
    const data = yield call(userInfoApi, obj.payload)
    if (data.httpStatus === 200) {
      yield put(userInfoSuccess(data))
    } else {
      yield put(userInfoFailure(data))
    }
  } catch (err) {
    yield put(userInfoFailure(err))
  }
}

function* watchMyVideo() {
  yield takeLatest(MY_VIDEO_REQUEST, myVideo)
  yield takeLatest(USER_INFO_REQUEST, userInfo)
}

export default function* rootChild() {
  yield fork(watchMyVideo)
}
