import request from "services/request";
// import { encodeQueryData } from "utils/stringFormat";
import { APIUtils } from "utils";
import * as TYPE from "../store/constants";

const api = {
  [TYPE.ORDER_HISTORY_DETAIL.request]: async (data) => {
    return APIUtils.get(
      `${process.env.REACT_APP_DOMAIN_UTOP_API}Order/${data.OrderCode}`
    );
  },
  [TYPE.INFOMATION_STORE.request]: async (data) => {
    if (data.isShowDraft) {
      return APIUtils.get(
        `${process.env.REACT_APP_DOMAIN_UTOP_API}cms/store/${data.storeId}?unpublished=true`
      );
    }
    return APIUtils.get(
      `${process.env.REACT_APP_DOMAIN_UTOP_API}cms/store/${data.storeId}`
    );
  },
  [TYPE.CANCEL_FNB.request]: async (data) => {
    return request("Order/CancelOrder", "POST", data);
  },
  [TYPE.GET_PAYMENT_METHODS.request]: async () => {
    return request(`cms/core/paymentmethods`, "GET");
  },
};

export default api;
