import request from "services/request";

import * as TYPE from "../store/constants";

const api = {
  [TYPE.RATING_FOR_STORE.request]: async (params) => {
    return request(
      `comment/api/v${process.env.REACT_APP_COMMENT_API_VERSION}/rating`,
      "POST",
      params
    );
  },
  [TYPE.GET_MERCHANT_INFO.request]: async (data) => {
    return request(`/cms/core/merchant/${data.merchantId}`, "GET");
    // return request(`/cms/core/merchant/${merchantId.id}`, "GET", null, {
    //   "content-type": "application/json",
    //   "Access-Control-Allow-Credentials": true,
    //   Authorization: "",
    //   "Ocp-Apim-Subscription-Key": "20c23f8a59104b159ff0cac596ed7e12",
    // "Ocp-Apim-Subscription-Key":
    //   process.env.REACT_APP_OCP_APIM_SUBSCRIPTION_KEY,
    //   "X-Client": "Web",
    // });
  },
};

export default api;
