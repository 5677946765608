import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
import { useLocation } from "react-router";

import "./scss/style.scss";
import TheLayout from "views/layout/TheLayout";
import { LoadingScreen } from "views/common/component";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "react-toastify/dist/ReactToastify.css";

import rootActions from "views/common/store/actions/actions";

import { onAuthStateChanged } from "firebase/auth";
import { authFirebase } from "configs/firebase";

import usePageTracking from "hooks/usePageTracking";
import useBranchIO from "hooks/useBranchio";
import useScrollMemory from "hooks/useScrollMemory";
import routes from "./routes";

const App = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [isFetchingUserData, setIsFetchingUserData] = useState(true);

  usePageTracking();
  useBranchIO();
  useScrollMemory();

  useEffect(() => {
    function isOffline() {
      dispatch(rootActions.SET_OFFLINE(true));
    }
    function isOnlineBrowser() {
      dispatch(rootActions.SET_OFFLINE(false));
    }

    window.addEventListener("offline", isOffline);
    window.addEventListener("online", isOnlineBrowser);

    return () => {
      window.removeEventListener("offline", isOffline);
      window.removeEventListener("offline", isOnlineBrowser);
    };
  }, []);

  useEffect(() => {
    const unregisterAuthObserver = onAuthStateChanged(authFirebase, (user) => {
      if (user) {
        dispatch(rootActions.SET_USER_INFO(user));
      }
      setIsFetchingUserData(false);
    });
    return () => {
      unregisterAuthObserver();
    };
  }, []);

  useEffect(() => {
    if (location.pathname !== "/login") {
      localStorage.setItem(
        "historyState",
        JSON.stringify(location?.state || {})
      );
      localStorage.setItem(
        "historyUrl",
        `${location.pathname}${location.search}`
      );
    }
  }, [location.pathname]);

  // set page name mỗi khi chuyển trang
  useEffect(() => {
    const found = routes.find((route) => {
      const splittedRoutePath = route.path?.split("/:");

      // các path có dạng /path-path-path/:param-on-path
      if (splittedRoutePath?.length > 1) {
        return splittedRoutePath[0] === `/${location.pathname?.split("/")[1]}`;
      }

      return route.path === location.pathname;
    });

    if (found) {
      document.title = found.name;
    }
  }, [location.pathname]);

  return (
    <>
      {isFetchingUserData ? <LoadingScreen /> : <TheLayout />}
      <ToastContainer />
    </>
  );
};

export default App;
