import { toast } from "react-toastify";

export const toastOptions = {
  position: "top-center",
  autoClose: 7000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

const toastInstance = (actionType, message, customOptions) => {
  toast[actionType](message, {
    ...toastOptions,
    ...customOptions,
  });
};

export default toastInstance;
