import {
  RECENT_REQUEST,
  SEARCH_REQUEST,
  SEARCH_HASHTAG_REQUEST,
  HOTKEY_REQUEST,
  SEARCH_PERSONAL_REQUEST,
} from './types'
import {
  HotKeyFailure,
  HotKeySuccess,
  recentFailure,
  recentSuccess,
  searchHashTagFailure,
  searchHashTagSuccess,
  searchPersonalFailed,
  searchPersonalSuccess,
  searchVideoFailure,
  searchVideoSuccess,
} from './actions'
import { call, put, takeLatest, fork } from 'redux-saga/effects'
import { handleResponseVideoApi } from '@shared/utils/common'
import { END_POINTS } from '@shared/constants/apiUrl'
import { APIUtils, LocalStorage } from 'utils'

async function recentApi() {
  let async = await LocalStorage.current.getItem('searchHistory')

  return {
    data: JSON.parse(async) || [],
    status: 200,
  }
}

/**
 * Dispatch action success or failure
 * @param {*} obj params
 */
export function* recent(obj) {
  try {
    const data = yield call(recentApi, obj.payload)

    if (data.status === 200) {
      yield put(recentSuccess(data.data))
    } else {
      yield put(recentFailure(handleResponseVideoApi(data)))
    }
  } catch (err) {
    yield put(recentFailure(handleResponseVideoApi(err)))
  }
}

function searchVideoRequest({ skipCount, maxResult, searchText }) {
  let text = searchText
  if (text.indexOf('#') === 0) {
    text = text.substr(1)
  }
  text = encodeURIComponent(text)
  const params = {
    skipCount,
    maxResult,
    title: text,
  }
  return APIUtils.get(END_POINTS.SearchVideo, { params })
}
export function* searchVideos(obj) {
  try {
    const data = yield call(searchVideoRequest, obj.payload)
    if (data.status && data?.data?.length !== 0) {
      yield put(searchVideoSuccess([...obj.payload.searchVideos, ...data.data]))
    } else {
      yield put(searchVideoFailure(data.data))
    }
  } catch (err) {
    yield put(searchVideoFailure(err))
    console.log('searchVideoErr=======', err)
  }
}

function searchHashTagRequest({
  skipCount,
  maxResult,
  searchText,
  isHotSearch,
}) {
  let text = encodeURI(searchText)
  if (text.indexOf('#') === 0) {
    text = text.substr(1)
  }
  const requestData = `?Title=${text}&SkipCount=${skipCount}&MaxResult=${maxResult}`
  const params = {
    SkipCount: skipCount,
    MaxResult: maxResult,
    Title: text,
  }
  if (text !== 'undefined') {
    return APIUtils.get(END_POINTS.SearchHashTag, {
      params,
    })
  }
  return APIUtils.get(END_POINTS.HotHashTag, {
    params,
  })
}
export function* searchHashTag(obj) {
  try {
    const data = yield call(searchHashTagRequest, obj.payload)
    if (data.status && data?.data?.length !== 0) {
      delete data.httpStatus
      yield put(
        searchHashTagSuccess([...obj.payload.searchHashTags, ...data.data]),
      )
    } else {
      yield put(searchHashTagFailure(data.data))
    }
  } catch (err) {
    yield put(searchHashTagFailure(err))
  }
}

function searchPersonalRequest({ skipCount, maxResult, searchText }) {
  let text = encodeURI(searchText)
  if (text.indexOf('#') === 0) {
    text = text.substr(1)
  }
  const params = {
    SkipCount: skipCount,
    MaxResult: maxResult,
    name: text,
  }
  return APIUtils.get(END_POINTS.SearchPersonal, {
    params,
  })
}

export function* searchPersonal(obj) {
  try {
    const data = yield call(searchPersonalRequest, obj.payload)
    if (data.status && data?.data?.length !== 0) {
      yield put(
        searchPersonalSuccess([...obj.payload.searchPersonal, ...data.data]),
      )
    } else {
      yield put(searchPersonalFailed(data.data))
    }
  } catch (err) {
    yield put(searchHashTagFailure(err))
  }
}

function HotKeyRequest() {
  return APIUtils.get(END_POINTS.HotKey)
}
export function* hotKey(obj) {
  try {
    const data = yield call(HotKeyRequest, obj.payload)
    if (data) {
      delete data.httpStatus
      yield put(HotKeySuccess(data))
    } else {
      yield put(HotKeyFailure(data))
    }
  } catch (err) {
    yield put(HotKeyFailure(err))
  }
}
function* watchLogin() {
  yield takeLatest(RECENT_REQUEST, recent)
  yield takeLatest(SEARCH_REQUEST, searchVideos)
  yield takeLatest(SEARCH_HASHTAG_REQUEST, searchHashTag)
  yield takeLatest(SEARCH_PERSONAL_REQUEST, searchPersonal)
  yield takeLatest(HOTKEY_REQUEST, hotKey)
}

export default function* rootChild() {
  yield fork(watchLogin)
}
