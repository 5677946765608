function numberWithCommas(n) {
  const parts = n ? n.toString().split(".") : "0";
  return (
    parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".") +
    (parts[1] ? `,${parts[1]}` : "")
  );
}
function numberWithCommasSymbol(n, symbol) {
  const parts = n ? n.toString().split(".") : "0";
  return `${
    parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, symbol) +
    (parts[1] ? `,${parts[1]}` : "")
  }đ`;
}

export { numberWithCommas, numberWithCommasSymbol };
