import {PaginationData} from '@shared/models/paginationData';
import {
  COUNT_REFERRAL,
  GET_REFERRAL_REWARD,
  GET_REFERRAL_REFERRED,
} from './constants';

const initialState = {
  countReferral: {},
  listReward: [],
  listReferred: new PaginationData({}),
};

export default (state = initialState, action) => {
  switch (action.type) {
    case COUNT_REFERRAL.SUCCESS: {
      return {
        ...state,
        countReferral: action.payload,
      };
    }
    case COUNT_REFERRAL.CLEAR: {
      return initialState;
    }

    case GET_REFERRAL_REWARD.SUCCESS: {
      return {
        ...state,
        listReward: action.payload,
      };
    }

    case GET_REFERRAL_REFERRED.SUCCESS: {
      return {
        ...state,
        listReferred: action.payload,
      };
    }
    case GET_REFERRAL_REFERRED.CLEAR: {
      return {
        ...state,
        listReferred: initialState.listReferred,
      };
    }
    default:
      return state;
  }
};
