import { callApi } from "helpers/callApi";
import { fork, put, takeEvery, takeLatest } from "redux-saga/effects";
import {
  GET_LIST_BOOKING_REQUEST,
  GET_DETAIL_BOOKING_REQUEST,
  CANCEL_BOOKING_REQUEST,
} from "./constants";
import {
  getListBookingApi,
  getDetailBookingApi,
  cancelBookingApi,
} from "@shared/services/api/businessBooking";
import { getListBookingUtopBusinessSuccess } from "./actions";
export function* getListBookingSaga(obj) {
  const { payload = {}, onSuccess, onError } = obj;
  try {
    const res = yield callApi(getListBookingApi, payload);
    console.log("getListBookingUtopBusinessSuccess", res);
    yield put(
      getListBookingUtopBusinessSuccess({
        skip: payload.skipCount + 10 || 0,
        data: res.data,
        isLoadNew: payload.isLoadNew,
      })
    );
    onSuccess?.(res);
  } catch (err) {
    onError?.(err);
  }
}
export function* getDetailBookingSaga(obj) {
  const { payload = {}, onSuccess, onError } = obj;
  try {
    const res = yield callApi(getDetailBookingApi, payload);
    onSuccess?.(res);
  } catch (err) {
    onError?.(err);
  }
}
export function* cancelBookingSaga(obj) {
  const { payload = {}, onSuccess, onError } = obj;
  try {
    console.log("payload", payload);
    const res = yield callApi(cancelBookingApi, payload);
    onSuccess?.(res);
  } catch (err) {
    onError?.(err);
  }
}
function* watchCommon() {
  yield takeLatest(GET_LIST_BOOKING_REQUEST, getListBookingSaga);
  yield takeLatest(GET_DETAIL_BOOKING_REQUEST, getDetailBookingSaga);
  yield takeLatest(CANCEL_BOOKING_REQUEST, cancelBookingSaga);
}

export default function* rootChild() {
  yield fork(watchCommon);
}
