import { memo, useMemo } from "react";
import { Link, useHistory } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import Skeleton from "react-loading-skeleton";

import LogoUtop from "assets/img/LogoUtop.jpg";
import {
  CellPhoneIcon,
  CompassIcon,
  IStoreGray,
  ListIcon,
  StarFilled8x8,
  StarHalf8x8,
  StarEmpty8x8,
} from "assets/svg";
import { FEED_TYPES, MAP_MODES } from "constants/types";

import { IconList, IconButton } from "views/common/component";

import "./ListStoreOfMerchant.scss";

function ListStoreOfMerchant(props) {
  const history = useHistory();
  const {
    isLoading,
    listStoreOfSelectedMerchant,
    locationUser /* {lat, long} */,
    handleLoadMore,
  } = props;
  const { merchantId, listStore, hasMore } = listStoreOfSelectedMerchant;
  const firstStore = listStore[0];

  const handleClickBtnCompass =
    ({ lat, lng }) =>
    (e) => {
      e.stopPropagation();
      e.preventDefault();

      if (!lat || !lng || !locationUser?.lat || !locationUser?.long) return;

      history.push(`/mapdirection`, {
        mode: MAP_MODES.DIRECTION,
        storeLocation: {
          lat,
          lng,
        },
        customDeviceLocation: {
          lat: locationUser.lat,
          lng: locationUser.long,
        },
      });
    };

  const handleClickBtnPhone = (phoneNumber) => (e) => {
    e.stopPropagation();
    e.preventDefault();

    window.open(`tel:${phoneNumber}`, "_self");
  };

  if (isLoading && !listStore.length > 0) {
    return <ListStoreOfMerchantSkeleton />;
  }

  if (!isLoading && listStore.length === 0) {
    return <ListStoreOfMerchantEmpty />;
  }

  return (
    <div className="list-store-of-merchant">
      {firstStore && (
        <MerchantInfo
          description={firstStore.description}
          name={firstStore.merchantName}
          logoUrl={firstStore.logoUrl}
          avgRate={firstStore.avgRate}
          avgPrice={firstStore.avgPrice}
          tags={firstStore.tags}
          promotionTags={firstStore.promotionTags}
        />
      )}

      {listStore.length > 0 && (
        <div
          id="list-store-of-merchant__infinite-scroll-wrapper"
          className="list-store-of-merchant__infinite-scroll-wrapper"
        >
          <InfiniteScroll
            dataLength={listStore.length}
            next={handleLoadMore({
              merchantId,
              skip: listStore.length,
            })}
            hasMore={hasMore}
            loader={<StoreRowSkeleton />}
            className="items-with-border"
            scrollableTarget="list-store-of-merchant__infinite-scroll-wrapper"
          >
            {listStore.map((store) => {
              switch (String(store.types?.[0]).toLowerCase()) {
                case FEED_TYPES.FNB.key: {
                  return (
                    <div key={store.feedId}>
                      <Link
                        to={`/storedetail?storeId=${store.storeId}&merchantId=${store.merchantId}`}
                        key={store.feedId}
                        className="_reset-link"
                      >
                        <StoreRow
                          distance={store.distance}
                          name={store.title}
                          avgRate={store.avgRate}
                          currentOpeningData={store.currentOpeningData}
                          coordinates={store.coordinates}
                          phoneNumber={store.phone}
                          handleClickBtnCompass={handleClickBtnCompass}
                          handleClickBtnPhone={handleClickBtnPhone}
                        />
                      </Link>
                    </div>
                  );
                }

                default: {
                  return (
                    <div key={store.feedId}>
                      <Link
                        to={`/${
                          store.feedType === FEED_TYPES.MARKET.key
                            ? "markethome"
                            : "storeview"
                        }?storeId=${store.storeId}&merchantId=${
                          store.merchantId
                        }`}
                        key={store.feedId}
                        className="_reset-link"
                      >
                        <StoreRow
                          key={store.storeId}
                          distance={store.distance}
                          name={store.title}
                          avgRate={store.avgRate}
                          currentOpeningData={store.currentOpeningData}
                          coordinates={store.coordinates}
                          phoneNumber={store.phone}
                          handleClickBtnCompass={handleClickBtnCompass}
                          handleClickBtnPhone={handleClickBtnPhone}
                        />
                      </Link>
                    </div>
                  );
                }
              }
            })}
          </InfiniteScroll>
        </div>
      )}
    </div>
  );
}

export default memo(ListStoreOfMerchant);

const StoreRow = (props) => {
  const {
    name,
    distance,
    avgRate,
    phoneNumber,
    coordinates,
    currentOpeningData,
    handleClickBtnCompass,
    handleClickBtnPhone,
  } = props;

  const openingTimes = useMemo(() => {
    const isStoreOpenTemp =
      currentOpeningData &&
      (currentOpeningData.rangeTime === "in" ||
        currentOpeningData.rangeTime === "all");
    return isStoreOpenTemp;
  }, [currentOpeningData]);

  return (
    <div className="list-store-of-merchant__store-row">
      <div className="list-store-of-merchant__store-row__left minW-0">
        <div className="name only-2-lines">{name}</div>
        <div className="--d-flex --align-items-center">
          {distance && <span className="distance">{distance}</span>}
          {Number(avgRate) > 0 && (
            <div className="rating-info">
              <IconList
                selectedNum={Number(avgRate)}
                iconFull={<StarFilled8x8 />}
                iconHalf={<StarHalf8x8 />}
                iconEmpty={<StarEmpty8x8 />}
                containerClassName="rating-info__star-icons"
              />
              <span className="rating-info__text">
                {Number(avgRate).toFixed(1)}
              </span>
            </div>
          )}
        </div>

        <div className="opening-info">
          {openingTimes && (
            <span className="opening-info--open">Đang mở cửa</span>
          )}
          {!openingTimes && (
            <span className="opening-info--closed">Đã đóng cửa</span>
          )}

          {currentOpeningData.openNextTime && (
            <div className="opening-time-info__divider" />
          )}

          {currentOpeningData &&
            openingTimes &&
            currentOpeningData?.openTime?.from &&
            currentOpeningData?.openTime?.to && (
              <span>
                {" "}
                {currentOpeningData?.openTime?.from} -{" "}
                {currentOpeningData?.openTime?.to}
              </span>
            )}
          {!openingTimes && currentOpeningData?.openNextTime && (
            <span>{`Quay lại lúc ${currentOpeningData?.openNextTime} ${currentOpeningData?.timePoint}`}</span>
          )}
        </div>
      </div>

      <div className="list-store-of-merchant__store-row__right">
        {coordinates && (
          <IconButton
            onClick={handleClickBtnCompass?.({
              lat: coordinates.lat,
              lng: coordinates.lng,
            })}
          >
            <CompassIcon />
          </IconButton>
        )}

        {phoneNumber && (
          <IconButton onClick={handleClickBtnPhone(phoneNumber)}>
            <CellPhoneIcon />
          </IconButton>
        )}
      </div>
    </div>
  );
};

const MerchantInfo = (props) => {
  const { description, logoUrl, name, promotionTags } = props;

  return (
    <div className="list-store-of-merchant__merchant-info">
      <object data={logoUrl || LogoUtop} type="image/png" className="logo">
        <img src={LogoUtop} className="logo" />
      </object>

      <div className="minW-0">
        <div className="merchant-name text-1-line">{name}</div>

        {/* {tags.length > 0 && (
          <div className="tags">
            {tags.map((tag, idx) => (
              <span className="tag text-1-line" key={`${tag}__${1 + idx}`}>
                {tag}
              </span>
            ))}
          </div>
        )} */}

        {promotionTags.length > 0 && (
          <div className="promotion-tags hide-x-scrollbar">
            {promotionTags.map((promotionTag, idx) => (
              <span
                className="promotion-tag text-1-line"
                key={`${promotionTag}__${1 + idx}`}
              >
                {promotionTag}
              </span>
            ))}
          </div>
        )}

        {description && (
          <div className="short-description">
            <i className="short-description__icon">
              <ListIcon />
            </i>
            <span className="short-description__content only-2-lines">
              {description}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export const ListStoreOfMerchantSkeleton = () => {
  return (
    <div className="list-store-of-merchant">
      <MerchantInfoSkeleton />
      <div className="list-store-of-merchant__infinite-scroll-wrapper">
        <div className="items-with-border">
          <StoreRowSkeleton />
          <StoreRowSkeleton />
        </div>
      </div>
    </div>
  );
};

const StoreRowSkeleton = () => {
  return (
    <div className="list-store-of-merchant__store-row">
      <div className="list-store-of-merchant__store-row__left --w-100">
        <div className="name">
          <Skeleton width="80%" />
        </div>
        <div>
          <Skeleton width="20%" style={{ marginRight: 4 }} />
          <Skeleton width="30%" />
        </div>
        <div>
          <Skeleton width="40%" />
        </div>
      </div>
      <div className="--d-flex">
        <Skeleton circle width={32} height={32} style={{ marginRight: 8 }} />
        <Skeleton circle width={32} height={32} />
      </div>
    </div>
  );
};

export const MerchantInfoSkeleton = () => {
  return (
    <div className="list-store-of-merchant__merchant-info">
      <Skeleton circle className="logo" />
      <div className="--w-100">
        <div className="merchant-name text-1-line">
          <Skeleton width="90%" />
        </div>
        <div className="promotion-tags hide-x-scrollbar">
          <Skeleton width="10ch" />
          <Skeleton width="12ch" />
        </div>
        <div>
          <Skeleton width="45%" />
        </div>
      </div>
    </div>
  );
};

const ListStoreOfMerchantEmpty = () => {
  return (
    <div className="list-store-of-merchant--empty-screen">
      <IStoreGray />
      <div className="list-store-of-merchant--empty-screen__text">
        Danh sách cửa hàng đang được cập nhật. Bạn quay lại sau nhé!
      </div>
    </div>
  );
};
