import {
  GET_LIST_COUPON_FOR_VOUCHER_SUCCESS,
  GET_COUPON_DETAIL_FOR_VOUCHER_SUCCESS,
  SEARCH_COUPON_DETAIL_FOR_VOUCHER_SUCCESS,
  SEARCH_COUPON_DETAIL_FOR_VOUCHER_FAILED,
  DATA_COUPON_SELECTED,
  GET_AVAILABLE_COUPON_SUCCESS,
  CLEAR_DATA_COUPON_LIST,
} from './constants';

const initialState = {
  listCouponForVoucher: [],
  couponDetailForVoucher: [],
  searchCouponDetailForVoucher: {},
  dataCouponSelected: {},
  availableCoupon: [],
};

const couponForVoucher = function (state = initialState, action) {
  switch (action.type) {
    case GET_LIST_COUPON_FOR_VOUCHER_SUCCESS: {
      return {
        ...state,
        listCouponForVoucher: {
          ...state.listCouponForVoucher,
          data:
            state.listCouponForVoucher.length > 0
              ? state.listCouponForVoucher
              : action.couponForVoucher.data,
        },
      };
    }

    case GET_COUPON_DETAIL_FOR_VOUCHER_SUCCESS: {
      return {
        ...state,
        couponDetailForVoucher: {
          ...state.couponDetailForVoucher,
          data:
            action.couponDetailForVoucher.data.length > 0
              ? action.couponDetailForVoucher
              : {},
        },
      };
    }

    case SEARCH_COUPON_DETAIL_FOR_VOUCHER_SUCCESS: {
      return {
        ...state,
        searchCouponDetailForVoucher: {
          ...state.searchCouponDetailForVoucher,
          data: action.searchCouponDetailForVoucher.data
            ? action.searchCouponDetailForVoucher.data
            : {},
        },
      };
    }

    case SEARCH_COUPON_DETAIL_FOR_VOUCHER_FAILED: {
      return {
        ...state,
        searchCouponDetailForVoucher: {
          ...state.searchCouponDetailForVoucher,
          data: {noResult: true},
        },
      };
    }

    case DATA_COUPON_SELECTED: {
      return {
        ...state,
        dataCouponSelected: action.payload,
      };
    }

    case GET_AVAILABLE_COUPON_SUCCESS: {
      return {
        ...state,
        availableCoupon: {
          ...state.availableCoupon,
          data: action.getAvailableCoupon.data
            ? action.getAvailableCoupon.data
            : {},
        },
      };
    }

    case CLEAR_DATA_COUPON_LIST: {
      return {
        ...state,
        listCouponForVoucher: [],
        searchCouponDetailForVoucher: {},
      };
    }

    default:
      return state;
  }
};

export default couponForVoucher;
