import { APIUtils } from "utils";
import { END_POINTS } from "@shared/constants/apiUrl";
import AppConfigs from "configs/env";

export async function payByTokenV1API(payload) {
  console.log("payload", payload);
  return APIUtils.post(END_POINTS.PAY_BY_TOKEN_TRANSACTION_V1, {
    headers: {
      // 'Api-version': AppConfigs.transactionApiVersion,
      "X-RequestId": payload.RequestId,
    },
    body: payload.dataConfirm,
  });
}
export async function payByTokenV2API(payload) {
  console.log("payload", payload);
  return APIUtils.post(END_POINTS.PAY_BY_TOKEN_TRANSACTION_V2, {
    headers: {
      "Api-version": AppConfigs.transactionApiVersion,
      "X-RequestId": payload.RequestId,
    },
    body: payload.dataConfirm,
  });
}
export async function payByUtopBusinessAPI(payload) {
  return APIUtils.post(
    END_POINTS.PAY_BY_UTOP_BUSINESS + payload.budgetId + "/Order",
    {
      headers: {
        "X-BusinessId": payload?.userId || "",
      },
      body: payload.requestBody,
    }
  );
}
export async function checkUtopBusinessAPI(payload) {
  return APIUtils.get(
    END_POINTS.CHECK_UTOP_BUSINESS + payload.StoreCode + "/InfoForPayment",
    {
      headers: {
        "X-BusinessId": payload.userId || "",
      },
    }
  );
}
export async function getlistBudgetSourceAPI(payload) {
  console.log("getlistBudgetSourceAPI", payload);
  return APIUtils.get(END_POINTS.GET_LIST_BUDGET + "?budgetStatus=1", {
    headers: {
      "X-BusinessId": payload.userId || "",
    },
  });
}
export async function getlistLegalAPI(payload) {
  return APIUtils.get(END_POINTS.GET_LIST_LEGAL, {
    headers: {
      "X-BusinessId": payload?.userId || "",
    },
  });
}
export async function checkQRAPI(payload) {
  return APIUtils.post(END_POINTS.CHECK_QR, { body: payload });
}
export async function loadDataQRAPI(payload) {
  return APIUtils.post(END_POINTS.LOAD_DATA_QR, { body: payload });
}

export async function uploadImageAPI(payload) {
  return APIUtils.post(END_POINTS.UTOP_BUSINESS_UPLOAD_IMAGE, {
    headers: {
      "Ocp-Apim-Subscription-Key": AppConfigs.subscriptionKey,
    },
    body: payload.dataConfirm,
  });
}
