import { useCallback, useLayoutEffect, useRef } from "react";

const useBodyScrollControl = () => {
  const overflowRef = useRef();
  const positionRef = useRef();
  const topRef = useRef();

  const freezeBodyScroll = useCallback(() => {
    overflowRef.current = document.body.style.overflow;
    positionRef.current = document.body.style.position;
    document.body.style.top = `-${window.scrollY}px`;

    document.body.style.overflow = "hidden";
    document.body.style.position = "fixed";
  }, []);

  // Only use releaseBodyScroll on unMount
  const releaseBodyScroll = useCallback(() => {
    const scrollY = document.body.style.top;

    document.body.style.overflow = overflowRef.current;
    document.body.style.position = positionRef.current;
    document.body.style.top = topRef.current;
    window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
  }, []);

  useLayoutEffect(() => {
    overflowRef.current = document.body.style.overflow;
    positionRef.current = document.body.style.position;
    topRef.current = document.body.style.top;

    return () => {
      releaseBodyScroll();
      document.body.style.top = topRef.current;
    };
  }, []);

  return [freezeBodyScroll, releaseBodyScroll];
};

export default useBodyScrollControl;
