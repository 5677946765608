import { put, takeLatest, fork } from 'redux-saga/effects'
import {
  GET_LIST_CATEGORY_REQUEST,
  GET_LIST_PRODUCT_BY_CATEGORY_REQUEST,
} from './constants'
import {
  getListCategorySuccess,
  getListProductByCategorySuccess,
  getListCategoryFailed,
  getListProductByCategoryFailed,
} from './actions'
import {
  getListProductApi,
  getListCategoryApi,
} from '@shared/services/api/market'

import { callApi } from 'helpers/callApi'
import { listCategoryParser, listProductParser } from './parser'

export function* getListProductSaga(obj) {
  const { payload, onSuccess, onError } = obj
  try {
    const res = yield callApi(getListProductApi, payload)
    yield put(getListProductByCategorySuccess(res))
    if (typeof onSuccess === 'function') {
      // onSuccess({...listProductParser(res?.data)});
      onSuccess({ ...res })
    }
  } catch (err) {
    if (typeof onError === 'function') {
      onError({ ...err })
    }
    yield put(getListProductByCategoryFailed(err))
  }
}

export function* getListCategorySaga(obj) {
  const { payload, onSuccess, onFailed } = obj
  try {
    const res = yield callApi(getListCategoryApi, payload)
    yield put(getListCategorySuccess(res))
    if (typeof onSuccess === 'function') {
      onSuccess(listCategoryParser(res?.data, payload?.id))
    }
  } catch (err) {
    if (typeof onFailed === 'function') {
      onFailed(err)
    }
    yield put(getListCategoryFailed(err))
  }
}

function* watchProduct() {
  yield takeLatest(GET_LIST_PRODUCT_BY_CATEGORY_REQUEST, getListProductSaga)
  yield takeLatest(GET_LIST_CATEGORY_REQUEST, getListCategorySaga)
}

export default function* rootChild() {
  yield fork(watchProduct)
}
