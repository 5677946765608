import { ADD_VOUCHER_CART, UPDATE_VOUCHER_CART } from './constants';

const initialState = {
  voucherCart: {}
};

const voucher = (state = initialState, action) => {

  switch (action.type) {
    case ADD_VOUCHER_CART: {
      return {
        ...state,
        voucherCart: action.payload,
      };
    }

    case UPDATE_VOUCHER_CART: {
      return {
        ...state,
        voucherCart: {...state.voucherCart, ...state.voucherCart.quantity = action.payload.quantity, ...state.voucherCart.totalPrice = action.payload.totalPrice },
      };
    }
    default:
      return state;
  }
};

export default voucher;
