import {
  GET_LIST_MEDIA_SUMMARY_SUCCESS,
  CLEAR_LIST_MEDIA_SUMMARY,
  GET_LIST_MEDIA_CATEGORY_SUCCESS,
  CLEAR_LIST_MEDIA_CATEGORY,
  GET_LIST_STORY_SUCCESS,
} from './constants';
const initialState = {
  listCategory: {
    data: [],
    skip: 0,
  },
  listCurrentMediaCategory: {
    data: [],
    skip: 0,
  },
  listStory: {
    data: [],
    skip: 0,
  },
  dataStory: [],
  dataBanner: [],
};
const parseListCategoryInit = (skip, data, oldData) => {
  if (skip === 0) return data.slice(1);
  return [...oldData, ...data];
};
const market = (state = initialState, action) => {
  switch (action.type) {
    case GET_LIST_MEDIA_SUMMARY_SUCCESS: {
      console.log('actionaction', action);
      if (action.payload.skip === 0)
        return {
          ...state,
          listCategory: {
            data: parseListCategoryInit(
              action.payload.skip,
              action.payload.data,
              state.listCategory.data,
            ),
            skip: action.payload.skip,
          },
          dataStory: action.payload?.data[0],
          listStory: {
            data: action.payload?.data[0]?.mediaAssets,
            skip: 10,
          },
        };
      return {
        ...state,
        listCategory: {
          data: parseListCategoryInit(
            action.payload.skip,
            action.payload.data,
            state.listCategory.data,
          ),
          skip: action.payload.skip,
        },
      };
    }
    case GET_LIST_MEDIA_CATEGORY_SUCCESS: {
      if (!!action.payload.data && action.payload.data.length > 0) {
        return {
          ...state,
          listCurrentMediaCategory: {
            data:
              action.payload.skip <= 8
                ? action.payload.data
                : [
                    ...state.listCurrentMediaCategory.data,
                    ...action.payload.data,
                  ],
            skip: action.payload.skip + action.payload.take,
          },
        };
      }
      return state;
    }

    case GET_LIST_STORY_SUCCESS: {
      console.log('GET_LIST_STORY_SUCCESS', action);
      return {
        ...state,
        listStory: {
          data:
            action.payload.skip <= 8
              ? action.payload.data
              : [...state.listStory.data, ...action.payload.data],
          skip: action.payload.skip + action.payload.take,
        },
      };
    }

    case CLEAR_LIST_MEDIA_SUMMARY: {
      return {
        ...state,
        listCategory: {
          data: [],
          skip: 0,
        },
      };
    }
    case CLEAR_LIST_MEDIA_CATEGORY: {
      return {
        ...state,
        listCurrentMediaCategory: {
          data: [],
          skip: 0,
        },
      };
    }

    default:
      return state;
  }
};

export default market;
