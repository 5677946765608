import request from "services/request";

import * as TYPE from "../store/constants";

const api = {
  [TYPE.CREATE_PAYMENT_TOPUP.request]: async (rqData) => {
    return request(`wallet/api/v2.0/Transaction/Pay`, "POST", rqData, {
      "Api-version": process.env.REACT_APP_PAYMENT_API_VERSION,
    });
  },
};

export default api;
