export const FNB_CART_KEY = "FnBCart";
export const FNB_CART_TMP_KEY = "FnBCartTmp";
export const VOUCHER_CART_KEY = "VoucherCart";
export const MARKET_CART_KEY = "MarketCart";
export const SEND_VOUCHER__RECENT_RECEIVERS_KEY =
  "SendVoucher__Recent-Receivers";
export const PICKED_ADDRESS__TO__SET_DELIVERY_INFO =
  "pickedAddressInfo__to__setDeliveryInfo";
export const TAB_VOUCHER_STATUS = "previousBackListVoucher";
export const MY_VOUCHER_SCROLL_POSITION = "scrollPosition";
