import COMMON from "./common.json";
import VALIDATE from "./validate.json";
import ERROR from "./error.json";

const RESOURCE = {
  common: COMMON,
  validate: VALIDATE,
  error: ERROR,
};

export default RESOURCE;
