/* eslint-disable no-param-reassign */
import { toast } from "react-toastify";
import { addErrors, delErrors } from "utils/ErrorsGenerator";
import { toastOptions } from "utils/toast";
import * as TYPE from "./constants";

const reducers = {
  // * ORDER_HISTORY_DETAIL
  [TYPE.ORDER_HISTORY_DETAIL.request]: (draft) => {
    draft.deliveryDetail = null;
    draft.isLoading.ORDER_HISTORY_DETAIL = true;
  },
  [TYPE.ORDER_HISTORY_DETAIL.success]: (draft, { payload }) => {
    draft.errors = delErrors(draft.errors, TYPE.ORDER_HISTORY_DETAIL.failure);
    draft.deliveryDetail = payload;
    draft.isLoading.ORDER_HISTORY_DETAIL = false;
  },
  [TYPE.ORDER_HISTORY_DETAIL.failure]: (draft, { payload }) => {
    draft.errors = addErrors(draft.errors, {
      type: TYPE.ORDER_HISTORY_DETAIL.failure,
      msg: payload,
    });
    draft.isLoading.ORDER_HISTORY_DETAIL = false;
  },

  // * INFOMATION_STORE
  [TYPE.INFOMATION_STORE.request]: (draft) => {
    draft.storeDetail = null;
    draft.isLoading.INFOMATION_STORE = true;
  },
  [TYPE.INFOMATION_STORE.success]: (draft, { payload }) => {
    draft.errors = delErrors(draft.errors, TYPE.INFOMATION_STORE.failure);
    draft.storeDetail = payload;
    draft.isLoading.INFOMATION_STORE = false;
  },
  [TYPE.INFOMATION_STORE.failure]: (draft, { payload }) => {
    draft.errors = addErrors(draft.errors, {
      type: TYPE.INFOMATION_STORE.failure,
      msg: payload,
    });
    draft.isLoading.INFOMATION_STORE = false;
  },

  // * CANCEL_FNB
  [TYPE.CANCEL_FNB.request]: (draft) => {
    draft.isLoading.CANCEL_FNB = true;
  },
  [TYPE.CANCEL_FNB.success]: (draft) => {
    toast.success("Hủy đơn hàng thành công!", toastOptions);
    draft.errors = delErrors(draft.errors, TYPE.CANCEL_FNB.failure);
    draft.deliveryDetail = {
      ...draft.deliveryDetail,
      status: draft.deliveryDetail.status === "wait" ? "Cancel_wait" : "Cancel",
    };
    draft.isLoading.CANCEL_FNB = false;
  },
  [TYPE.CANCEL_FNB.failure]: (draft, { payload }) => {
    toast.error("Hủy đơn hàng thất bại!", toastOptions);
    draft.errors = addErrors(draft.errors, {
      type: TYPE.CANCEL_FNB.failure,
      msg: payload,
    });
    draft.isLoading.CANCEL_FNB = false;
  },

  // * GET_PAYMENT_METHODS
  [TYPE.GET_PAYMENT_METHODS.request]: (draft) => {
    draft.isLoading.GET_PAYMENT_METHODS = true;
  },
  [TYPE.GET_PAYMENT_METHODS.success]: (draft, { payload }) => {
    draft.errors = delErrors(draft.errors, TYPE.GET_PAYMENT_METHODS.failure);
    draft.listTransactionMethods = payload; // ***
    draft.isLoading.GET_PAYMENT_METHODS = false;
  },
  [TYPE.GET_PAYMENT_METHODS.failure]: (draft, { payload }) => {
    draft.errors = addErrors(draft.errors, {
      type: TYPE.GET_PAYMENT_METHODS.failure,
      msg: payload,
    });
    draft.isLoading.GET_PAYMENT_METHODS = false;
  },
};

export default reducers;
