import request, { requestWithoutToken } from "services/request";
import { encodeQueryData } from "utils/stringFormat";
import * as TYPE from "../store/constants";

const api = {
  [TYPE.GET_VOUCHER_INFO.request]: async (params) => {
    return requestWithoutToken(`/cms/core/vouchers/${params.id}`, "GET", null, {
      "content-type": "application/json",
      "Access-Control-Allow-Credentials": true,
      Authorization: "",
      "Ocp-Apim-Subscription-Key":
        process.env.REACT_APP_OCP_APIM_SUBSCRIPTION_KEY,
      "X-Client": "Web",
    });
  },

  // *** RELATE TO SUGGESTED COUPONS *** //
  [TYPE.GET_SUGGESTED_COUPON_LIST.request]: async (params) => {
    const { viewAsGuest } = params;
    const query = encodeQueryData({
      merchantId: params.merchantId,
      feature: params.feature,
    });

    if (viewAsGuest) {
      return requestWithoutToken(`/Coupon/GetSuggestCoupon${query}`, "GET");
    }

    return request(`/Coupon/GetSuggestCoupon${query}`, "GET");
  },

  // * khai báo để dùng trong saga|reducer tên "CALC_COUPON_DISCOUNT_V2", không có reducer & saga
  [TYPE.GET_AVAILABLE_COUPONS_BY_CODE.request]: async (code) => {
    return request(`/coupon/GetAvailableCouponsByCode`, "POST", { code });
  },

  // * khai báo để dùng trong saga|reducer tên "CALC_COUPON_DISCOUNT_V2", không có reducer & saga
  [TYPE.GET_AVAILABLE_COUPONS_OF_ALLOCATED_COUPON_TICKET.request]: async (
    params
  ) => {
    const query = encodeQueryData({
      productcode: params.productCode,
      transactioncode: params.transactionCode,
      status: "Redeem",
    });
    return request(`/voucher/VoucherDetail${query}`, "GET");
  },

  // * khai báo để dùng trong saga|reducer tên "CALC_COUPON_DISCOUNT_V2", không có reducer & saga
  [TYPE.CALC_COUPON_DISCOUNT.request]: async (requestPayload) => {
    // * sample requestPayload
    // {
    //   merchantId,
    //   orderType: "OrderGift",
    //   coupons: [voucherCart?.appliedCoupon?.couponCode],
    //   products: [
    //     {
    //       productCode: voucherCart?.priceOption?.productCode,
    //       quantity: voucherCart?.quantity,
    //     },
    //   ],
    // }

    return request(
      `/coupon/CalculateCouponDiscountHub`,
      "POST",
      requestPayload
    );
  },
  // *** -- RELATE TO SUGGESTED COUPONS -- *** //

  [TYPE.CREATE_ORDER_VOUCHER.request]: async (requestPayload) => {
    // * sample requestPayload
    // {
    //   quantity: 2,
    //   description: voucherCart?.voucherInfo?.name?.vi,
    //   productCode: voucherCart?.priceOption?.productCode,
    //   coupons: [ABCXYZ], // voucherCode when call /voucher/VoucherDetail?productcode=THOTEST1&status=Redeem&transactioncode=d1e3713b5eef43688ce4a594cdfb1adf
    //   merchantId: "asdc",
    // }
    return request(`Order/OrderGiftHub/UtopWeb`, "POST", requestPayload);
  },
};

export default api;
