export const storeInfo = undefined;
export const merchantInfo = {};
export const bestSellerList = {};
export const productList = [];
export const recentOrderedProductCodeList = [];
export const suggestedCouponList = [];
export const outOfStockProductList = [];
export const categoriesList = [];
export const shortLinkToShare = undefined;
export const storeInfoV2 = undefined;

export const isLoading = {
  PAGE: false,
  GET_MERCHANT_INFO: false,
  GET_STORE_INFO: true,
  GET_PRODUCT_LIST: false,
  GET_RECENT_ORDERED_PRODUCT_CODE_LIST: false,
  GET_SUGGESTED_COUPON_LIST: false,
  GET_OUT_OF_STOCK_PRODUCT_LIST: false,
  GET_SHORT_LINK_TO_SHARE: false, // ~ page
  SAVE_SUGGESTED_COUPON: false,
  GET_STORE_INFO_V2: true,
};
