/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-shadow */
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import PropTypes from "prop-types";
import { useLayoutEffect, useRef } from "react";
import ReactModal from "react-modal";
import { useSwipeable } from "react-swipeable";
import classes from "./BottomSheet.module.scss";

function BottomSheet(props) {
  const {
    show,
    handleClose,
    handleAfterClose,
    headerElement,
    footerElement,
    headerChildren,
    footerChildren,
    children,
    scrollContainerId,
    scrollContainerStyle = {},
    sheetStyle,
  } = props;

  const scrollRef = useRef();
  const contentRef = useRef();
  const swipeHandlers = useSwipeable({
    delta: 0, // min distance(px) before a swipe starts. *See Notes*
    preventDefaultTouchmoveEvent: false, // call e.preventDefault *See Details* | if true, cannot scroll inside by touch
    trackTouch: true, // track touch input
    trackMouse: true, // track mouse input
    rotationAngle: 0, // set a rotation angle

    // After any swipe   (SwipeEventData) => void
    onSwiped: () => {
      if (!contentRef.current) return;

      contentRef.current.style.transition = "";
      contentRef.current.style.transform = "";
    },

    // After DOWN swipe  (SwipeEventData) => void
    onSwipedDown: (eventData) => {
      if (
        eventData.deltaY <
        Math.min(50, (contentRef.current?.offsetHeight || 0) / 4)
      )
        return;

      handleClose();
    },

    // Start of swipe    (SwipeEventData) => void *see details*
    // onSwipeStart: () => {
    //   // ! Không set lại transition ở đây, vì sẽ gây mất transition khi nhấn nút set lại isOpen=false từ bên ngoài
    // },

    // During swiping    (SwipeEventData) => void
    onSwiping: (eventData) => {
      if (eventData.initial[1] === 0) return;
      if (!scrollRef.current) {
        console.log("onSwiping - scrollRef not found");
        return;
      }
      if (eventData.dir !== "Down") return;
      if (!contentRef.current) return;

      contentRef.current.style.transition = "none";
      contentRef.current.style.transform = `translateY(${eventData.deltaY}px)`;
    },

    // After a tap       ({ event }) => void
    // onTap: ({ event }) => {
    //   event.stopPropagation();
    // },
  });

  // const refPassthrough = (el) => {
  //   // call useSwipeable ref prop with el
  //   swipeHandlers.ref(el);

  //   // set myRef el so you can access it yourself
  //   contentRef.current = el;
  // };

  useLayoutEffect(() => {
    return () => {
      if (show) {
        enableBodyScroll(scrollRef.current);
      }
    };
  }, [show]);

  return (
    <ReactModal
      appElement={document.getElementById('root')}
      isOpen={show}
      closeTimeoutMS={300}
      onRequestClose={() => {
        handleClose();
      }}
      onAfterOpen={() => {
        disableBodyScroll(scrollRef.current);
      }}
      onAfterClose={() => {
        handleAfterClose?.();
      }}
      className={{
        base: classes.sheet,
        afterOpen: classes.sheetAfterOpen,
        beforeClose: classes.sheetBeforeClose,
      }}
      overlayClassName={{
        base: classes.overlay,
        afterOpen: classes.overlayAfterOpen,
        beforeClose: classes.overlayBeforeClose,
      }}
      style={{
        content: sheetStyle,
      }}
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
      shouldFocusAfterRender
      shouldReturnFocusAfterClose
      contentRef={(node) => {
        contentRef.current = node;
      }}
      contentElement={(props, children) => (
        <div
          {...props}
          // {...swipeHandlers}
          // ref={refPassthrough}
        >
          {children}
        </div>
      )}
    >
      <div {...swipeHandlers}>
        <div className={classes.dragIndicatorContainer}>
          <div className={classes.dragIndicator} />
        </div>

        {headerElement}
        {!headerElement && headerChildren && (
          <div className={classes.header}>{headerChildren}</div>
        )}
      </div>

      <div
        ref={scrollRef}
        id={scrollContainerId}
        className={classes.scrollContainer}
        style={scrollContainerStyle}
      >
        {children}
      </div>

      {footerElement}
      {!footerElement && footerChildren && (
        <div className={classes.footer}>{footerChildren}</div>
      )}
    </ReactModal>
  );
}

BottomSheet.defaultProps = {
  headerElement: undefined,
  footerElement: undefined,
  headerChildren: undefined,
  footerChildren: undefined,
  scrollContainerId: undefined,
  scrollContainerStyle: {},
};

BottomSheet.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleAfterClose: PropTypes.func,
  headerElement: PropTypes.node,
  footerElement: PropTypes.node,
  headerChildren: PropTypes.node,
  footerChildren: PropTypes.node,
  children: PropTypes.node.isRequired,
  scrollContainerId: PropTypes.string,
  scrollContainerStyle: PropTypes.object,
};

export default BottomSheet;
