import {
  createTransactionInsuranceApi,
  getRepaymentTransactionLinkApi,
} from "@shared/services/api/transaction";
import { callApi } from "helpers/callApi";
import {
  getTransactionDetailPaymentInfoNewsApi,
  getRepaymentTransactionLinkNewsApi,
} from "@shared/services/api/transaction";
import { call, fork, put, takeLeading, takeLatest } from "redux-saga/effects";
import {
  createActionTransactionInsuranceFailure,
  createActionTransactionInsuranceSuccess,
  getRepaymentTractionLinkFailure,
  getRepaymentTractionLinkSuccess,
  getDetailTransactionInfoNewsFailure,
  getDetailTransactionInfoNewsSuccess,
  getRepaymentTractionLinkNewsFailure,
  getRepaymentTractionLinkNewsSuccess,
} from "./actions";
import {
  CREATE_TRANSACTION_INSURANCE,
  GET_REPAYMENT_TRANSACTION_LINK,
  GET_DETAIL_TRANSACTION_INFO_NEWS,
  GET_REPAYMENT_TRANSACTION_LINK_NEWS,
} from "./constants";

export function* createTransactionInsurance(action) {
  const { transactionInsuranceInfo, onSuccess, onFailed } = action;
  try {
    const repData = yield call(
      createTransactionInsuranceApi,
      transactionInsuranceInfo
    );
    yield call(onSuccess, repData);
    yield put(createActionTransactionInsuranceSuccess(repData));
  } catch (error) {
    yield put(createActionTransactionInsuranceFailure(error));
    onFailed?.(error);
  }
}

export function* getRepaymentTransactionLink(action) {
  const { payload, onSuccess, onFailed } = action;
  try {
    const repData = yield call(getRepaymentTransactionLinkApi, payload);
    onSuccess?.(repData);
    yield put(getRepaymentTractionLinkSuccess(repData));
  } catch (error) {
    yield put(getRepaymentTractionLinkFailure(error));
    onFailed?.(error);
  }
}

export function* getDetailTransactionNews(action) {
  const { onSuccess, orderId, onFailed } = action;
  try {
    const res = yield callApi(getTransactionDetailPaymentInfoNewsApi, orderId);
    yield put(getDetailTransactionInfoNewsSuccess(res));
    onSuccess?.(res?.data);
  } catch (error) {
    onFailed?.();
    yield put(getDetailTransactionInfoNewsFailure(error));
  }
}
export function* getRepaymentTransactionNewsLink(action) {
  const { orderId, onSuccess, onFailed } = action;
  try {
    const repData = yield callApi(getRepaymentTransactionLinkNewsApi, orderId);
    onSuccess?.(repData);
    yield put(getRepaymentTractionLinkNewsSuccess(repData));
  } catch (error) {
    yield put(getRepaymentTractionLinkNewsFailure(error));
    onFailed?.(error);
  }
}

function* transactionSaga() {
  yield takeLatest(
    CREATE_TRANSACTION_INSURANCE.HANDLER,
    createTransactionInsurance
  );
  yield takeLatest(
    GET_REPAYMENT_TRANSACTION_LINK.HANDLER,
    getRepaymentTransactionLink
  );
  yield takeLatest(
    GET_REPAYMENT_TRANSACTION_LINK_NEWS.HANDLER,
    getRepaymentTransactionNewsLink
  );
  yield takeLeading(
    GET_DETAIL_TRANSACTION_INFO_NEWS.HANDLER,
    getDetailTransactionNews
  );
}

export default function* rootChild() {
  yield fork(transactionSaga);
}
