import { LOGOUT_REQUEST } from "@shared/redux/account/constants";
import produce from "immer";
import {
  RESET_NOTIFICATION,
  READ_NOTIFICATION,
  SET_NUMBER_NOTI,
  GET_NOTIFICATION_REQUEST,
  GET_NOTIFICATION_SUCCESS,
  GET_NOTIFICATION_FAILED,
} from "./constants";

const initialState = {
  numberNoti: 0,
  listNotification: [],
  messageError: "",
  skip: 0,
  total: "",
  firstNoti: {},
};

const notificationReducer = (state = initialState, action) => {
  const { payload, type, number } = action;

  switch (type) {
    case GET_NOTIFICATION_REQUEST: {
      return {
        ...state,
        messageError: "",
      };
    }
    case READ_NOTIFICATION:
      let tmpListNotification = state.listNotification.map((noti) => ({
        ...noti,
        isRead:
          payload === "All" ? true : noti.id === payload ? true : noti.isRead,
      }));

      return {
        ...state,
        listNotification: tmpListNotification,
        numberNoti: tmpListNotification.filter((noti) => noti.isRead === false)
          .length,
      };

    case GET_NOTIFICATION_SUCCESS: {
      let { listNotification } = state;
      let tmpItems = [];

      if (payload.isLoadMore || listNotification.length === 0) {
        payload.items.map((ele) => {
          tmpItems.push({ ...ele, isRead: true });
        });
        tmpItems = [...listNotification, ...tmpItems];
      } else {
        payload.items.map((ele) => {
          let index = listNotification.findIndex((ite) => ite.id === ele.id);

          if (index === -1) {
            tmpItems.push({ ...ele, isRead: false });
          } else {
            tmpItems.push({ ...ele, isRead: listNotification[index].isRead });
          }
        });
      }

      return {
        ...state,
        listNotification: tmpItems,
        skip: payload.skip,
        total: payload.total,
        numberNoti: tmpItems.filter((noti) => noti.isRead === false).length,
      };
    }

    case GET_NOTIFICATION_FAILED: {
      return {
        ...state,
        messageError: payload,
      };
    }

    case SET_NUMBER_NOTI: {
      return {
        ...state,
        numberNoti: state.numberNoti + payload,
      };
    }

    case RESET_NOTIFICATION: {
      return initialState;
    }

    case LOGOUT_REQUEST: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
};

export default notificationReducer;
