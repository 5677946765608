import { call, put, takeLatest, fork } from "redux-saga/effects";
import actions from "./actions";
import * as TYPE from "./constants";
import api from "../api/api";

const sagas = {
  *[TYPE.RATING_FOR_STORE.request](action) {
    try {
      const data = yield call(api[TYPE.RATING_FOR_STORE.request], action.payload);
      if (data.status === 200) {
        yield put(actions.RATING_FOR_STORE.success(data.data));
      } else {
        yield put(actions.RATING_FOR_STORE.failure(data?.data));
      }
    } catch (error) {
      yield put(actions.RATING_FOR_STORE.failure(error?.data));
    }
  },
  *[TYPE.GET_MERCHANT_INFO.request](action) {
    try {
      const data = yield call(api[TYPE.GET_MERCHANT_INFO.request], action.payload);
      if (data.status === 200) {
        yield put(actions.GET_MERCHANT_INFO.success(data.data));
      } else {
        yield put(actions.GET_MERCHANT_INFO.failure(data?.data));
      }
    } catch (error) {
      yield put(actions.GET_MERCHANT_INFO.failure(error?.data));
    }
  },
};

function* watchedSaga() {
  yield takeLatest(TYPE.RATING_FOR_STORE.request, sagas[TYPE.RATING_FOR_STORE.request]);
  yield takeLatest(TYPE.GET_MERCHANT_INFO.request, sagas[TYPE.GET_MERCHANT_INFO.request]);

}

export default function* rootChild() {
  yield fork(watchedSaga);
}
