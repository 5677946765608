import moment from 'moment';
import {
  GET_USER_BUSINESS_SUCCESS,
  GET_ALL_TRANSACTION_HISTORY_UB_SUCCESS,
  GET_USER_BUSINESS_FAILED,
  CLEAR_ACCOUNT_BUSINESS,
  UPDATE_ALL_TRANSACTION_HISTORY_UB,
  GET_LIST_STORE_TYPE_SUCCESS,
  SET_CURRENT_LOCATION,
  UPDATE_CURRENT_LOCATION,
  GET_LIST_CITY_SUCCESS,
  GET_LIST_DISTRICT_SUCCESS,
  UPDATE_SELECTED_LOCATION,
  GET_LIST_STORE_UB_SUCCESS,
  CLEAR_LIST_SEARCH_STORE_UB,
  GET_LIST_SEARCH_STORE_UB_SUCCESS,
  LIST_SEARCH_STORE_UB_HISTORY,
  UPDATE_FILTER_STORE,
  UPDATE_ACCOUNT_BUSINESS,
} from './constants';

const mergeMasterData = (dataOld, dataNew, isLoadNew) => {
  let masterData = dataOld;
  if (isLoadNew) {
    masterData = {};
  }
  for (var i = 0; i < dataNew.length; i++) {
    var groupName = moment.utc(dataNew[i].date).local().format('DD/MM/YYYY');
    if (!masterData[groupName]) {
      masterData[groupName] = [];
    }
    masterData[groupName].push(dataNew[i]);
  }
  return masterData;
};

const cookData = listDistrict => {
  if (listDistrict.length > 0) {
    if (listDistrict.length > 1) {
      listDistrict.unshift({ id: 0, name: 'Tất cả' });
    }
    listDistrict.forEach(element => {
      element.isSelected = false;
    });
    listDistrict[0].isSelected = true;
  }

  return listDistrict;
};

const initialState = {
  userBusinessInfo: [],
  listAllTransactionHistoryUB: {},
  isUtopBusiness: false,
  filterStore: {},
  listCity: {},
  listSearchStoreUB: [],
  isActiveAccount: true,
};

const budgetReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_BUSINESS_SUCCESS: {
      return {
        ...state,
        ...action,
        isActiveAccount: action.userBusinessInfo.length === 0 ? false : true,
      };
    }

    case UPDATE_ACCOUNT_BUSINESS: {
      return {
        ...state,
        ...action,
      };
    }

    case GET_USER_BUSINESS_FAILED: {
      return {
        ...state,
        ...action,
      };
    }

    case GET_ALL_TRANSACTION_HISTORY_UB_SUCCESS: {
      return {
        ...state,
        listAllTransactionHistoryUB: {
          ...state.listAllTransactionHistoryUB,
          skip: action.listAllTransactionHistoryUB
            ? action.listAllTransactionHistoryUB.skip
            : 0,
          isLoadNew: false,
          data: mergeMasterData(
            state.listAllTransactionHistoryUB.data,
            action.listAllTransactionHistoryUB.data,
            action.listAllTransactionHistoryUB.isLoadNew,
          ),
        },
      };
    }

    case UPDATE_ALL_TRANSACTION_HISTORY_UB: {
      return {
        ...state,
        listAllTransactionHistoryUB: {},
      };
    }

    case CLEAR_ACCOUNT_BUSINESS: {
      return {
        ...initialState,
      };
    }

    case GET_LIST_STORE_TYPE_SUCCESS: {
      return {
        ...state,
        listStoreType: action.payload.data,
      };
    }

    case SET_CURRENT_LOCATION: {
      return {
        ...state,
        filterStore: {
          ...state.filterStore,
          currentLocation: action.currentLocation.isGPSOn
            ? action.currentLocation
            : null,
          selectedLocation: action.currentLocation,
        },
      };
    }

    case UPDATE_SELECTED_LOCATION: {
      return {
        ...state,
        filterStore: {
          ...state.filterStore,
          selectedLocation: action.selectedLocation,
        },
      };
    }

    case UPDATE_FILTER_STORE: {
      return {
        ...state,
        filterStore: {
          ...state.filterStore,
          ...action.payload,
        },
      };
    }

    case UPDATE_CURRENT_LOCATION: {
      return {
        ...state,
        filterStore: {
          ...state.filterStore,
          currentLocation: action.currentLocation,
        },
      };
    }

    case GET_LIST_CITY_SUCCESS: {
      return {
        ...state,
        listCity: action.payload.data,
      };
    }

    case GET_LIST_DISTRICT_SUCCESS: {
      return {
        ...state,
        listDistrict: cookData(action.payload.data),
      };
    }

    case GET_LIST_STORE_UB_SUCCESS: {
      return {
        ...state,
        listStoreUB:
          action.payload.skip === 0
            ? action.payload.data
            : [...state.listStoreUB, ...action.payload.data],
      };
    }

    case GET_LIST_SEARCH_STORE_UB_SUCCESS: {
      return {
        ...state,
        listSearchStoreUB:
          action.payload.skip === 0
            ? action.payload.data
            : [...state.listSearchStoreUB, ...action.payload.data],
      };
    }

    case CLEAR_LIST_SEARCH_STORE_UB: {
      return {
        ...state,
        listSearchStoreUB: null,
      };
    }

    case LIST_SEARCH_STORE_UB_HISTORY:
      return {
        ...state,
        listSearchStoreUBHistory: action.payload,
      };

    default:
      return state;
  }
};

export default budgetReducer;
