import request, { requestWithoutToken } from "services/request";
import { encodeQueryData } from "utils/stringFormat";

import * as TYPE from "../store/constants";

const api = {
  [TYPE.GET_LIST_CARD_TOPUP.request]: async () => {
    const query = encodeQueryData({
      skip: 0,
      top: 10,
    });
    return requestWithoutToken(
      `cms/core/category/7f9c9725-1e53-4e27-af68-0fa0f0d328b6/${query}`,
      "GET"
    );
  },

  [TYPE.GET_LIST_BUY_CARD.request]: async () => {
    const query = encodeQueryData({
      skip: 0,
      top: 10,
    });
    return requestWithoutToken(
      `cms/core/category/59b13bea-3d40-4ce9-b58d-e86119703909/${query}`,
      "GET"
    );
  },

  [TYPE.CREATE_ODER_TOPUP.request]: async (bodyData) => {
    return request(`Order/OrderGift/UtopWeb`, "POST", bodyData);
  },
};

export default api;
