import APIUtils from 'utils/apiUtils';
import {END_POINTS} from '@shared/constants/apiUrl';
import AppConfigs from 'configs/env';
export function getMobileCardListApi(payload) {
  return APIUtils.get(END_POINTS.GET_MOBILE_CARD_LIST + payload);
}

export function createOrderApi(requestData) {
  return APIUtils.post(END_POINTS.CREATE_ORDER_URL, {
    body: requestData,
  });
}

export function paymentApi(requestData) {
  return APIUtils.post(END_POINTS.PAYMENT_WITH_VERSION_URL, {
    body: requestData,
    headers: {
      'Api-version': AppConfigs.transactionApiVersion,
    },
  });
}
