import { genActions } from "utils/actionGenerator";
import * as TYPE from "./constants";

const actions = {
  ORDER_HISTORY_DETAIL: genActions(TYPE.ORDER_HISTORY_DETAIL),
  CANCEL_VOUCHER: genActions(TYPE.CANCEL_VOUCHER),
  GET_PAYMENT_METHODS: genActions(TYPE.GET_PAYMENT_METHODS),
  GET_WHOLESALE_LIST: genActions(TYPE.GET_WHOLESALE_LIST)

};

export default actions;
