/* eslint-disable no-unused-vars */
class Alert {
  static alert(
    title,
    message = '',
    buttons = [
      {
        text: 'OK',
        onPress: () => console.log('OK Pressed'),
        style: 'cancel',
      },
    ],
    options = { cancelable: false },
  ) {
    // AlertRN.alert(title, message, buttons, options);
  }
}

export default Alert
