import { END_POINTS } from '@shared/constants/apiUrl'
import { APIUtils } from 'utils'
import { CryptoJS } from '@shared/utils/CryptoJS'
import md5 from 'md5'

export function getUTopCoinApi() {
  return APIUtils.get(END_POINTS.GET_UTOP_BALANCE_URL, { method: 'GET' })
}

export function getUserInfoApi() {
  return APIUtils.get(END_POINTS.GET_INFO_URL, { method: 'GET' })
}

export function updateUserProfileApi(payload) {
  return APIUtils.put(END_POINTS.UPDATE_USER_PROFILE_URL, { body: payload })
}
export async function verifyPinCodeApi({ PinCode }) {
  let requestData = { timestamp: Math.floor(new Date().getTime() / 1000) }
  if (PinCode !== '') {
    let pinCode = md5(PinCode).toString()
    let pinCodeSign = CryptoJS.HmacSHA256(
      JSON.stringify(requestData),
      pinCode,
    ).toString()
    var header = {
      'Content-Type': 'application/json',
    }
    header['X-PinCodeSign'] = pinCodeSign
  }

  return APIUtils.post(END_POINTS.VERIFY_PIN_CODE_URL, {
    headers: header,
    body: requestData,
  })
}

export async function changePinCodeApi({
  CurrentPinCode: PinCode,
  NewPinCode,
}) {
  let requestData = {
    timestamp: Math.floor(new Date().getTime() / 1000),
    newPin: NewPinCode,
  }
  if (PinCode !== '') {
    let pinCode = md5(PinCode).toString()
    let pinCodeSign = CryptoJS.HmacSHA256(
      JSON.stringify(requestData),
      pinCode,
    ).toString()
    var header = {
      'Content-Type': 'application/json',
    }
    header['X-PinCodeSign'] = pinCodeSign
  } else {
    let pinCode = md5('').toString()
    let pinCodeSign = CryptoJS.HmacSHA256(
      JSON.stringify(requestData),
      pinCode.toUpperCase(),
    ).toString()
    var header = {
      'Content-Type': 'application/json',
    }
    header['X-PinCodeSign'] = pinCodeSign
  }
  // requestData['newPin'] = NewPinCode;
  return APIUtils.post(END_POINTS.UPDATE_PIN_CODE_URL, {
    headers: header,
    body: requestData,
  })
}
