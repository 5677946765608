import { all, fork } from 'redux-saga/effects'
import newsfeedSagas from '../newsfeed/sagas'
import commonSagas from '../common/sagas'
import accountSagas from '../account/sagas'
import loginSagas from '../login/sagas'
import storeSagas from '../store/sagas'
import merchantSagas from '../merchant/sagas'
import smartMenuSagas from '../smartMenu/sagas'
import voucherSagas from '../voucher/sagas'
import addressSagas from '../address/sagas'
import historyOrder from '../historyOrder/sagas'
import focusSagas from '../focus/saga'
import searchSagas from '../search/saga'
import channelSagas from '../channel/saga'
import hashtagSagas from '../hashtag/saga'
import videoListSagas from '../myVideo/saga'
import profileSagas from '../profile/saga'
import bannerSagas from '../banner/saga'
import listVideoSagas from '../listFavorite/saga'
import userInfoSagas from '../userInfoReducer/saga'
import uploadVideoSagas from '../uploadVideo/saga'
import myVideoSagas from '../myVideo/saga'
import listFavoriteHashtagSagas from '../listFavoriteHashtag/saga'

import videoDynamicSagas from '../video/sagas'
import watchListVoucher from '../myVoucher/myVoucherList/sagas'
import watchMyVoucherDetail from '../myVoucher/myVoucherDetail/sagas'
import watchMarkUsedVoucher from '../myVoucher/markUsedVoucher/sagas'
import searchStoreSagas from '../searchStore/listSearchStore/sagas'
import couponForVoucher from '../couponForVoucher/sagas'
import notificationSagas from '../notification/sagas'
import menuHistoryPointSagas from '../menuHistoryPoint/sagas'
import repaymentVoucherSagas from '../repaymentVoucher/sagas'
import pendingOrderHome from '../pendingOrderHome/sagas'
import myCardSagas from '../myCard/sagas'
import cartSagas from '../cart/sagas'
import storeDetailSagas from '../storeDetail/sagas'
import orderStatus from '../OrderStatus/sagas'
import orderAddressFood from '../orderAddressFood/sagas'
import utopDeliveryAddressBook from '../utopDeliveryAddressBook/sagas'
import paymentSagas from '../payment/sagas'
import qrpay from '../qrpay/sagas'
import mobileCardSagas from '../mobileCard/sagas'
import myVoucherCodeSaga from '../myVoucher/myVoucherCode/sagas'
import paymentHubSaga from '../paymentHub/sagas'
import businessSagas from '../business/sagas'
import budgetSagas from '../budget/sagas'
import businessBookingSagas from '../businessBooking/sagas'
import orderCommentSaga from '../orderComment/saga'
import storeRatingSage from '../fnbRating/saga'
import utopbackSaga from '../utopback/sagas'
import referralSaga from '../referral/sagas'
import pointSaga from '../point/sagas'
import insuranceSagas from '../insurance/sagas'
import transactionSaga from '../transaction/sagas'
import orderStatusSaga from '@shared/redux/order/saga'
import userInfo from '../userInfo/sagas'
import marketSaga from '../market/marketHome/sagas'
import appVersionSaga from '../appVersion/sagas'
import marketSearchSaga from '@shared/redux/market/marketSearch/saga'
import marketProductSagas from '@shared/redux/market/marketProduct/sagas'
import authSagas from '../auth/sagas'
import packageNewsInfoSaga from '../packageInfo/sagas'
import listNumberNews from '../listNumberNews/sagas'
import listNews from '../listNews/sagas'
import blockSchool from '../school/sagas'
import listImagesNews from '../listImagesNews/sagas'
import listNumberNewsCurrent from '../listNumberNewsCurrent/sagas'
import editionRenew from '../editionRenew/sagas'
import marketStoreSaga from '@shared/redux/market/marketStore/saga'
import othersSagas from '@redux/others/sagas'

export default function* rootSaga() {
  yield all([
    fork(utopbackSaga),
    fork(pendingOrderHome),
    fork(storeDetailSagas),
    fork(newsfeedSagas),
    fork(commonSagas),
    fork(accountSagas),
    fork(searchStoreSagas),
    fork(storeSagas),
    fork(merchantSagas),
    fork(smartMenuSagas),
    fork(voucherSagas),
    fork(addressSagas),
    fork(historyOrder),
    fork(watchListVoucher),
    fork(watchMyVoucherDetail),
    fork(watchMarkUsedVoucher),
    fork(couponForVoucher),
    fork(notificationSagas),
    fork(menuHistoryPointSagas),
    fork(repaymentVoucherSagas),
    fork(myCardSagas),
    fork(cartSagas),
    fork(orderStatus),
    fork(orderAddressFood),
    fork(utopDeliveryAddressBook),
    fork(loginSagas),
    fork(videoDynamicSagas),
    fork(paymentSagas),
    fork(qrpay),
    fork(mobileCardSagas),
    fork(myVoucherCodeSaga),
    fork(paymentHubSaga),
    fork(businessSagas),
    fork(budgetSagas),
    fork(businessBookingSagas),
    fork(orderCommentSaga),
    fork(storeRatingSage),
    fork(appVersionSaga),
    fork(referralSaga),
    fork(pointSaga),
    fork(insuranceSagas),
    fork(transactionSaga),
    fork(orderStatusSaga),
    fork(userInfo),
    fork(marketProductSagas),
    fork(marketSaga),
    fork(marketSearchSaga),
    fork(authSagas),
    fork(packageNewsInfoSaga),
    fork(listNumberNews),
    fork(listNews),
    fork(blockSchool),
    fork(listImagesNews),
    fork(listNumberNewsCurrent),
    fork(editionRenew),
    fork(marketStoreSaga),
    fork(focusSagas),
    fork(channelSagas),
    fork(hashtagSagas),
    fork(searchSagas),
    fork(videoListSagas),
    fork(bannerSagas),
    fork(listVideoSagas),
    fork(userInfoSagas),
    fork(uploadVideoSagas),
    fork(myVideoSagas),
    fork(profileSagas),
    fork(listFavoriteHashtagSagas),
    ...othersSagas,
  ])
}
