import {
  GET_LIST_PRODUCT_BY_LOCATION_REQUEST,
  GET_LIST_PRODUCT_BY_LOCATION_SUCCESS,
  GET_LIST_PRODUCT_BY_LOCATION_FAILED,
  GET_LIST_NEWSFEED_DYNAMIC_REQUEST,
  GET_LIST_NEWSFEED_DYNAMIC_SUCCESS,
  GET_LIST_NEWSFEED_DYNAMIC_FAILED,
  GET_LIST_NEWSFEED_STORE_REQUEST,
  GET_LIST_NEWSFEED_STORE_SUCCESS,
  GET_LIST_NEWSFEED_STORE_FAILED,
} from './constants';

export const getListNewsfeedByLocationHandle = (payload, onSuccess) => ({
  type: GET_LIST_PRODUCT_BY_LOCATION_REQUEST,
  payload,
  onSuccess,
});

export const getListNewsfeedByLocationSuccess = products => ({
  type: GET_LIST_PRODUCT_BY_LOCATION_SUCCESS,
  products,
});

export const getListNewsfeedByLocationFailed = error => ({
  type: GET_LIST_PRODUCT_BY_LOCATION_FAILED,
  error,
});

export const getListNewsfeedDynamicHandle = (payload, onSuccess, onError) => ({
  type: GET_LIST_NEWSFEED_DYNAMIC_REQUEST,
  payload,
  onSuccess,
  onError,
});

export const getListNewsfeedDynamicSuccess = (pageId, products) => ({
  type: GET_LIST_NEWSFEED_DYNAMIC_SUCCESS,
  pageId,
  products,
});

export const getListNewsfeedDynamicFailed = error => ({
  type: GET_LIST_NEWSFEED_DYNAMIC_FAILED,
  error,
});

export const getListNewsfeedStoreHandle = (payload, onSuccess, onError) => ({
  type: GET_LIST_NEWSFEED_STORE_REQUEST,
  payload,
  onSuccess,
  onError,
});

export const getListNewsfeedStoreSuccess = (storeId, products) => ({
  type: GET_LIST_NEWSFEED_STORE_SUCCESS,
  storeId,
  products,
});

export const getListNewsfeedStoreFailed = error => ({
  type: GET_LIST_NEWSFEED_STORE_FAILED,
  error,
});
