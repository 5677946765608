const initialState = {
  listComment: [],
};

const orderComment = (draft = initialState, action) => {
  const {payload} = action;
  switch (action.type) {
    default:
      return draft;
  }
};

export default orderComment;
