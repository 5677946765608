import {END_POINTS} from '@shared/constants/apiUrl';
import {APIUtils} from 'utils';

export function getListPartnerExchangePointApi() {
  return APIUtils.get(END_POINTS.GET_ALL_MERCHANT_EXCHANGE_URL);
}

export function mappingMemberWithPartnerApi(payload) {
  return APIUtils.post(END_POINTS.MERCHANT_MEMBER_MAPPING_URL, {body: payload});
}

export function removeMappingMemberWithPartnerApi(payload) {
  return APIUtils.post(END_POINTS.REMOVE_USER_MAPPING_URL, {body: payload});
}

export function getPartnerInfoApi({url, config}) {
  return APIUtils.post(url, config);
}

export function exchangePointPartnerApi({url, config}) {
  return APIUtils.post(url, config);
}
