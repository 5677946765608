import {END_POINTS} from '@shared/constants/apiUrl';
import {APIUtils} from 'utils';

export function getProductsApi({merchantId}) {
  // const url = global.apiLinks.getDataFNB + merchantId + '/products';
  const url =
    END_POINTS.GET_LIST_PRODUCT_BY_MERCHANT_ID + '&merchantid=' + merchantId;
  return APIUtils.get(url);
}
export function getToppingsApi({merchantId}) {
  const url = global.apiLinks.getDataFNB + merchantId + '/toppings';
  return APIUtils.get(url);
}
export function getCategoriesApi({merchantId}) {
  const url = global.apiLinks.getDataFNB + merchantId + '/categories';
  return APIUtils.get(url);
}
export function checkProductOutOfStockApi({storeId, merchantId}) {
  const url =
    global.apiLinks.GetOutOfStockByStore +
    '?merchantId=' +
    merchantId +
    '&storeId=' +
    storeId;
  return APIUtils.get(url);
}
export function createOrderFnBApi(payload) {
  return APIUtils.post(END_POINTS.CREATE_ORDER_FNB_HUB_URL, {
    body: payload,
  });
}
