import { genActions } from "utils/actionGenerator";
import * as TYPE from "./constants";

const actions = {
  // SEARCH_LIST: genActions(TYPE.SEARCH_LIST), // ! deprecated
  GET_LIST_FEED: genActions(TYPE.GET_LIST_FEED),
  GET_LIST_STORE_OF_MERCHANT: genActions(TYPE.GET_LIST_STORE_OF_MERCHANT),
};

export default actions;
