import {
  GET_LIST_HISTORY_ORDER_SUCCESS,
  GET_LIST_HISTORY_ORDER_REQUEST,
  GET_LIST_HISTORY_ORDER_FAILED,
} from "./constants";
import moment from "moment";
import { LOGOUT_REQUEST } from "@shared/redux/account/constants";
import { GET_DETAIL_ORDER_SUCCESS } from "@shared/redux/repaymentVoucher/constants";

const initialState = {
  listHistoryOrder: [],
};

const updateListHistoryOrder = (listHistoryOrder = {}, order = {}) => {
  const listOfDate = Object.keys(listHistoryOrder);
  const tempListHistoryOrder = {};

  listOfDate?.forEach((dateKey) => {
    tempListHistoryOrder[dateKey] = listHistoryOrder[dateKey]?.map((obj) => {
      if (obj.orderCode !== order?.orderCode) {
        return obj;
      }
      return { ...order, totalAmount: obj.totalAmount };
    });
  });
  return tempListHistoryOrder;
};

const mergeMasterData = (dataOld, dataNew, isLoadNew) => {
  let masterData = dataOld;
  if (isLoadNew) {
    masterData = {};
  }
  for (var i = 0; i < dataNew.length; i++) {
    var groupName = moment
      .utc(dataNew[i].createdAt)
      .local()
      .format("DD/MM/YYYY");
    if (!masterData[groupName]) {
      masterData[groupName] = [];
    }
    masterData[groupName].push(dataNew[i]);
  }
  return masterData;
};

const historyOrder = function (state = initialState, action) {
  switch (action.type) {
    case GET_LIST_HISTORY_ORDER_SUCCESS: {
      return {
        ...state,
        listHistoryOrder: {
          ...state.listHistoryOrder,
          skip: action.historyOrder ? action.historyOrder.skip : 0,
          isLoadNew: false,
          data: mergeMasterData(
            state.listHistoryOrder.data,
            action.historyOrder.data,
            action.historyOrder.isLoadNew
          ),
        },
      };
    }

    case GET_LIST_HISTORY_ORDER_REQUEST: {
      return {
        ...state,
        listHistoryOrder: {
          ...state.listHistoryOrder,
          isLoadNew:
            typeof action.payload.isLoadNew !== "undefined"
              ? action.payload.isLoadNew
              : true,
        },
      };
    }

    case GET_LIST_HISTORY_ORDER_FAILED: {
      return {
        ...state,
        listHistoryOrder: {
          ...state.listHistoryOrder,
          isLoadNew: false,
          data: [],
        },
      };
    }
    case GET_DETAIL_ORDER_SUCCESS: {
      return {
        ...state,
        listHistoryOrder: {
          ...state.listHistoryOrder,
          data: updateListHistoryOrder(
            state.listHistoryOrder?.data,
            action.payload?.data?.data
          ),
        },
      };
    }
    case LOGOUT_REQUEST: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
};

export default historyOrder;
