import request, { requestWithoutToken } from "services/request";
import * as TYPE from "../store/constants";

const api = {
  [TYPE.POST_SEND_VOUCHER.request]: async (data) => {
    const voucherToSend = {
      phoneNumberReceive: data.phoneNum,
      vouchers: data.voucher,
      message: data.message,
    };
    return request(`/Voucher/ShareVoucher`, "POST", voucherToSend);
  },
  [TYPE.GET_USER_BY_PHONE.request]: async ({ phoneNumber }) => {
    return request(
      `/personal/GetUserByPhone?phoneNumber=${phoneNumber}`,
      "GET"
    );
  },
  [TYPE.GET_SHORT_LINK_TO_SHARE.request]: async (params) => {
    const { url } = params;
    return requestWithoutToken(`/common/ShortUrl`, "POST", { url });
  },
};

export default api;
