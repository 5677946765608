import request from "services/request";
import { encodeQueryData } from "utils/stringFormat";
import * as TYPE from "../store/constants";

const api = {
  [TYPE.LIST_ORDER_HISTORY.request]: async (data) => {
    const params = encodeQueryData(data);
    return request(`Order/History/Web${params}`, "GET");
  },
};

export default api;
