/* eslint-disable no-unused-vars */
import React, { useState, useCallback, useEffect } from "react";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";

import { NavigationServices } from "utils";
import InfiniteScroll from "react-infinite-scroll-component";
import { toast } from "react-toastify";
import { toastOptions } from "utils/toast";
import { getShortLinkToShare } from "@shared/redux/market/marketHome/actions";
import CartBottomBar from "../MarketHomeView/components/CartBottomBar";
import StoreHeader from "./components/StoreHeader";

import "./ListStoreMarket.scss";
import StoreList from "./components/StoreList/StoreList";
// eslint-disable-next-line import/order
import IMAGES from "assets/img";

const ListStoreMarket = (props) => {
  const [scrollTop, setScrollTop] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  // * call API get Ratings

  useEffect(() => {
    scrollToTop();
  }, []);

  // * NAVIGATE
  const goBack = () => {
    NavigationServices.goBack();
  };

  const goToProduct = () => {
    history.push("/storedetailmarket", {
      storeInfo: {},
    });
  };

  const handleShareStoreList = useCallback(
    async (dataStore, url) => {
      if (navigator.share) {
        try {
          await navigator.share({
            title: "Chia sẻ liên kết của cửa hàng",
            text: `Bạn được giới thiệu trải nghiệm ${dataStore?.name?.vi} tại chợ ${props.storeDetail?.name} trên ứng dụng Utop. Nhấn link bên dưới để đăng ký và nhận ưu đãi\n${url}`,
          });
        } catch (err) {
          console.error(err);
        }
      } else {
        toast.error(`Không thể chia sẻ trên trình duyệt này`, toastOptions);
      }
    },
    [props.storeInfo?.name]
  );

  const handleGetShortLinkToShare = (dataStore) => {
    const payload = {};
    payload.callbackUrl = `${window.location.origin}/StoreDetailMarket?storeId=${props?.storeDetail?.id}&merchantId=${props?.storeDetail?.merchantId}&storeMerchantId=${dataStore.id}`;
    payload.imageUrl = "";
    dispatch(
      getShortLinkToShare(
        { ...payload },
        (res) => {
          handleShareStoreList(dataStore, res?.data?.url);
        },
        (err) => {
          console.log("errShare", err);
        }
      )
    );
  };

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setScrollTop(true);
    } else if (scrolled <= 300) {
      setScrollTop(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };

  window.addEventListener("scroll", toggleVisible);

  const goToSearch = () => {
    NavigationServices.navigate("/searchstoremarket", {
      storeInfo: props.storeDetail,
      type: "searchStore",
      isCloseStore: props?.isCloseStore,
    });
  };

  return (
    <div className="list-store-view-page">
      <div className="store-header__wrapper">
        <StoreHeader
          idSearchInput="store-detail__store-header__search-input"
          storeName="abc"
          isSearching={false} // ! hard code, vì store view hiện tại ko có search
          handleChangeSearchStatus={() => {}}
          goBack={goBack}
          onCommitInputSearch={() => {}}
          goToSearch={goToSearch}
        />
      </div>
      <div className="content-list-store">
        <InfiniteScroll
          dataLength={props.listStore.length}
          next={props.loadMore}
          hasMore={!props.isEndSearchMarket}
          endMessage={
            <p style={{ textAlign: "center", fontSize: "12px" }}>
              Đã hết cửa hàng
            </p>
          }
        >
          <StoreList
            hideTitle
            gotoProduct={props.onclickViewDetail}
            dataListStore={props.listStore}
            handleShareStore={handleGetShortLinkToShare}
            isCloseStore={props.isCloseStore}
          />
        </InfiniteScroll>
      </div>
      <div
        className="button-scroll-top"
        style={{
          display: scrollTop ? "inline" : "none",
          justifyContent: "center",
          alignItems: "center",
        }}
        onClick={scrollToTop}
      >
        <img className="icon-scroll-top" src={IMAGES.IconScrollTop} alt="png" />
      </div>
      <CartBottomBar
        // to="/order"
        eventHandle={() =>
          NavigationServices.navigate("/CartMarket", {
            isFromMarket: true,
            storeInfo: props.storeInfo,
          })
        }
        totalPrice={
          props.dataCart.length > 0
            ? props.dataCart
                .map((i) => i.dataInfoProduct.totalPrice)
                .reduce((a, b) => a + b)
            : 0
        }
        itemsCount={
          props.dataCart.length > 0
            ? props.dataCart
                .map((i) => i.dataInfoProduct.quantity)
                .reduce((a, b) => a + b)
            : 0
        }
        className="store-detail-page__cart-bottom-bar"
        text="Xem giỏ hàng"
        disabled={props.dataCart.length > 0 && !props.isCloseStore ? 0 : 1}
        // todo: check thêm khi ấn nút này, có thể bị phá khi mở devtool
      />
    </div>
  );
};

export default ListStoreMarket;
