import classNames from "classnames";
import React from "react";
import "./addressOption.scss";

const AddressOption = ({
  isChosen = false,
  name,
  address,
  note,
  handleSelect,
  item,
}) => {
  return (
    <div
      onClick={handleSelect(item)}
      className={classNames(
        "address-option",
        isChosen && "address-option--active"
      )}
    >
      <div className="address-option__title">{name}</div>
      <div className="address-option__detail">{address}</div>
      <div className="address-option__description">{note || ""}</div>
    </div>
  );
};

export default AddressOption;
