import {SAVE_CAMPAIGN_ID} from './constants';

const initialState = {
  data: {},
};

const trackingReducer = (state = initialState, action) => {
  const {payload} = action;

  switch (action.type) {
    case SAVE_CAMPAIGN_ID:
      return {
        ...state,
        data: action?.payload?.data,
      };
    default:
      return state;
  }
};

export default trackingReducer;
