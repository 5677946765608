import request, { requestWithoutToken } from "services/request";
import { encodeQueryData } from "utils/stringFormat";

import * as TYPE from "../store/constants";

const api = {
  [TYPE.GET_COUPON_INFO.request]: async (params) => {
    const { viewAsGuest } = params;
    const query = encodeQueryData({
      couponid: params.couponId,
    });

    if (viewAsGuest) {
      return requestWithoutToken(
        `cms/core/coupons/coupondetail${query}`,
        "GET"
      );
    }
    return request(`cms/core/coupons/coupondetail${query}`, "GET");
  },

  [TYPE.SAVE_SUGGESTED_COUPON.request]: async (requestPayload) => {
    // requestPayload = { code };
    return request(`/coupon/GetAvailableCouponsByCode`, "POST", requestPayload);
  },
};

export default api;
