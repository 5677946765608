import {SET_SHOW_MESSAGE_ERROR} from './constants';

const initialState = {
  isShowMessageError: true,
};

const couponReducer = (state = initialState, action) => {
  const {payload, type} = action;

  switch (type) {
    case SET_SHOW_MESSAGE_ERROR: {
      return {
        ...state,
        isShowMessageError: payload,
      };
    }
    default:
      return state;
  }
};

export default couponReducer;
