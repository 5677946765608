import AppConfigs from 'configs/env';
import {END_POINTS} from '@shared/constants/apiUrl';
import {APIUtils} from 'utils';

export function getListBannerApi({userId, latitude, longitude}) {
  let url = '';
  if (!userId) {
    url = END_POINTS.GET_BANNERS_URL;
  } else {
    url =
      END_POINTS.GET_BANNERS_BY_LOCATION_URL +
      `?Type=0&Latitude=${latitude}&Longitude=${longitude}`;
  }

  return APIUtils.get(url);
}

export function getListHotMerchantApi() {
  const url =
    AppConfigs.domainAFFAPI + 'cms/core/v2.0/banners?type=merchantfeed';

  return APIUtils.get(url);
}

export function getListShortcutApi() {
  return APIUtils.get(END_POINTS.GET_SHORTCUTS_URL);
}

export function saveDeviceInfoApi({deviceInfo}) {
  return APIUtils.post(END_POINTS.SAVE_INFO_URL, {
    body: {deviceInfo},
  });
}

export function getDynamicPageApi({pageId}) {
  return APIUtils.get(END_POINTS.GET_DYNAMIC_PAGE_URL + `${pageId}`);
}

export function getDynamicModalConfigApi({code, lat, lng, ...rest}) {
  let params = {
    lat,
    lng,
  };
  if (code) {
    params = {
      ...params,
      code,
      ...rest,
    };
  }
  return APIUtils.get(END_POINTS.GET_DYNAMIC_MODAL_URL, {
    headers: {
      'Api-version': '1.0',
    },
    params,
  });
}

export function receiveCampaignApi({campaignCode}) {
  return APIUtils.post();
}

export function getSuggestBannerApi({userId, latitude, longitude}) {
  let url = '';
  if (!userId) {
    url = END_POINTS.GET_SUGGEST_BANNER_URL;
  } else {
    url =
      END_POINTS.GET_SUGGEST_BANNER_URL +
      `?Type=0&Latitude=${latitude}&Longitude=${longitude}`;
  }

  return APIUtils.get(url);
}

export function shortUrlApi(url) {
  return APIUtils.post(END_POINTS.SHORT_URL, {
    body: {url: url},
  });
}

export function getPersonalizeShortcutApi() {
  return APIUtils.get(END_POINTS.PERSONALIZE_SHORTCUT_URL);
}

export function getLovApi(payload) {
  return APIUtils.get(END_POINTS.LOV_URL + payload.module);
}
