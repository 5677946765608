import {
  GET_LIST_VIDEO_REQUEST,
  GET_LIST_VIDEO_SUCCESS,
  GET_LIST_VIDEO_FAILED,
  GET_USER_INFO_REQUEST,
  GET_USER_INFO_SUCCESS,
  GET_USER_INFO_FAILED,
  FOLLOW_USER_REQUEST,
  FOLLOW_USER_SUCCESS,
  FOLLOW_USER_FAILED,
  UN_FOLLOW_USER_REQUEST,
  UN_FOLLOW_USER_SUCCESS,
  UN_FOLLOW_USER_FAILED,
} from './constants';

export const getListVideoRequest = payload => ({
  type: GET_LIST_VIDEO_REQUEST,
  payload,
});

export const getListVideoSuccess = payload => ({
  type: GET_LIST_VIDEO_SUCCESS,
  payload,
});

export const getListVideoFailed = error => ({
  type: GET_LIST_VIDEO_FAILED,
  error,
});

export const getUserInfoRequest = payload => ({
  type: GET_USER_INFO_REQUEST,
  payload,
});

export const getUserInfoSuccess = payload => ({
  type: GET_USER_INFO_SUCCESS,
  payload,
});

export const getUserInfoFailed = error => ({
  type: GET_USER_INFO_FAILED,
  error,
});

export const followUserRequest = (payload, onSuccess, onFailed) => ({
  type: FOLLOW_USER_REQUEST,
  payload,
  onSuccess,
  onFailed,
});

export const followUserSuccess = payload => ({
  type: FOLLOW_USER_SUCCESS,
  payload,
});

export const followUserFailed = error => ({
  type: FOLLOW_USER_FAILED,
  error,
});

export const unFollowUserRequest = (payload, onSuccess, onFailed) => ({
  type: UN_FOLLOW_USER_REQUEST,
  payload,
  onSuccess,
  onFailed,
});

export const unFollowUserSuccess = payload => ({
  type: UN_FOLLOW_USER_SUCCESS,
  payload,
});

export const unFollowUserFailed = error => ({
  type: UN_FOLLOW_USER_FAILED,
  error,
});
