import {
  CANCEL_ORDER_FAILED,
  CANCEL_ORDER_REQUEST,
  CANCEL_ORDER_SUCCESS,
  CANCEL_PENDING_ITEM_FAILED,
  CANCEL_PENDING_ITEM_REQUEST,
  CANCEL_PENDING_ITEM_SUCCESS,
  GET_PRODUCT_CHANGED_FAILED,
  GET_PRODUCT_CHANGED_REQUEST,
  GET_PRODUCT_CHANGED_SUCCESS,
  SET_RECEIVED_ORDER_REQUEST,
  GET_ESTIMATE_DELIVERY_TIME_REQUEST,
  GET_ESTIMATE_DELIVERY_TIME_SUCCESS,
  GET_ESTIMATE_DELIVERY_TIME_FAILED,
  GET_CUSTOM_CONFIG_MAP_REQUEST,
} from './constants';

// cancel order
export const cancelOrderHandle = (payload = {}, onSuccess, onFailed) => ({
  type: CANCEL_ORDER_REQUEST,
  payload,
  onSuccess,
  onFailed,
});

export const cancelOrderSuccess = (payload = {}) => ({
  type: CANCEL_ORDER_SUCCESS,
  payload,
});

export const cancelOrderFailed = (payload = {}) => ({
  type: CANCEL_ORDER_FAILED,
  payload,
});

// cancel pending item
export const cancelPendingItemHandle = (payload = {}, onSuccess, onFailed) => ({
  type: CANCEL_PENDING_ITEM_REQUEST,
  payload,
  onSuccess,
  onFailed,
});

export const cancelPendingItemSuccess = (payload = {}) => ({
  type: CANCEL_PENDING_ITEM_SUCCESS,
  payload,
});

export const cancelPendingItemFailed = (payload = {}) => ({
  type: CANCEL_PENDING_ITEM_FAILED,
  payload,
});

export const getProductChangedHandle = (payload = {}, onSuccess, onFailed) => ({
  type: GET_PRODUCT_CHANGED_REQUEST,
  payload,
  onSuccess,
  onFailed,
});

export const getProductChangedSuccess = (payload = {}) => ({
  type: GET_PRODUCT_CHANGED_SUCCESS,
  payload,
});

export const getProductChangedFailed = (payload = {}) => ({
  type: GET_PRODUCT_CHANGED_FAILED,
  payload,
});

export const setReceivedOrderRequest = (payload, onSuccess, onFailed) => ({
  type: SET_RECEIVED_ORDER_REQUEST,
  payload,
  onSuccess,
  onFailed,
});

export const getEstimateDeliveryTime = (payload, onSuccess, onFailed) => ({
  type: GET_ESTIMATE_DELIVERY_TIME_REQUEST,
  payload,
  onSuccess,
  onFailed,
});

export const getEstimateDeliveryTimeSuccess = (payload = {}) => ({
  type: GET_ESTIMATE_DELIVERY_TIME_SUCCESS,
  payload,
});

export const getEstimateDeliveryTimeFailed = (payload = {}) => ({
  type: GET_ESTIMATE_DELIVERY_TIME_FAILED,
  payload,
});
export const getCustomConfigMapRequest = (payload, onSuccess, onFailed) => ({
  type: GET_CUSTOM_CONFIG_MAP_REQUEST,
  payload,
  onSuccess,
  onFailed,
});
