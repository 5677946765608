const parseJsonToArray = masterData => {
  // var arrMaster = [];
  // if (typeof masterData !== 'undefined' && masterData !== null) {
  //   Object.keys(masterData).forEach((element, i) => {
  //     arrMaster.push(masterData[element]);
  //   });
  // }
  return masterData;
};

export const getListProductSearchMarketSelector = state => {
  if (state.marketReducer.listProductSearchMarket?.data) {
    return parseJsonToArray(state.marketReducer.listProductSearchMarket.data);
  }
  return null;
};

export const getListProductOutOfStockSelector = state => {
  if (state.marketReducer.listProductSearchMarket?.dataOutOfStock) {
    return parseJsonToArray(state.marketReducer.listProductSearchMarket.dataOutOfStock);
  }
  return [];
};

export const getProductDetailMarketSelector = state => {
  if (!!state.marketReducer.productDetailMarket) {
    return state.marketReducer.productDetailMarket;
  }
  return {};
};

export const getIsEndSearchMarketSelector = state => {
  if (!!state.marketReducer.listProductSearchMarket) {
    return state.marketReducer.listProductSearchMarket.isEndSearchMarket;
  }
  return false;
};
