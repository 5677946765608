import { connectRouter } from "connected-react-router";
import { createBrowserHistory } from "history";
import { importAllStore } from "views/common/store";
import produce from "immer";

export const history = createBrowserHistory();
const { constants, reducers, states: initialState } = importAllStore();
const tempReducer = {};
constants.forEach((constElm) => {
  tempReducer[constElm.TYPE] = produce(
    (state, { type, payload }) => {
      // always return a new object for the root state
      if (reducers[constElm.TYPE][type]) {
        return reducers[constElm.TYPE][type](state, { payload, type });
      }
    },
    { ...initialState[constElm.TYPE] }
  );
});
export default {
  router: connectRouter(history),
  ...tempReducer,
};
