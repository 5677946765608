import { call, put, takeLatest, fork } from "redux-saga/effects";
import { getsignInWithCustomToken, getUserProfile } from "configs/firebase";
import { uploadFileToBlob } from "utils/imageUtils";
import moment from "moment";
import actions from "../actions/actions";
import * as TYPE from "../constants/constants";
import api from "../../api/api";

const sagas = {
  *[TYPE.GET_USER_INFO.request]() {
    try {
      const data = yield call(getUserProfile);
      if (data) {
        yield put(actions.GET_USER_INFO.success(data));
      } else {
        yield put(actions.GET_USER_INFO.failure(data));
      }
    } catch (error) {
      yield put(actions.GET_USER_INFO.failure(error));
    }
  },
  *[TYPE.SIGN_IN_WITH_CUSTOM_TOKEN.request]({ payload }) {
    try {
      const data = yield call(getsignInWithCustomToken, payload);
      if (data) {
        yield put(actions.SIGN_IN_WITH_CUSTOM_TOKEN.success(data));
      } else {
        yield put(actions.SIGN_IN_WITH_CUSTOM_TOKEN.failure(data));
      }
    } catch (error) {
      yield put(actions.SIGN_IN_WITH_CUSTOM_TOKEN.failure(error));
    }
  },
  *[TYPE.UPLOAD_AVATAR.request]({ payload }) {
    try {
      const data = yield call(api[TYPE.UPLOAD_AVATAR.request]);
      if (data && data.data && data.status === 200) {
        const { file, userId } = payload;
        const { sasToken, blobEndpoint } = data.data;
        const response = yield uploadFileToBlob(
          file,
          sasToken,
          blobEndpoint,
          userId
        );
        if (response) {
          const photoUrl = `${response}?time=${moment().unix()}`;
          yield put(actions.UPDATE_USER_INFO.request({ photoUrl }));
          yield put(actions.UPLOAD_AVATAR.success(photoUrl));
        } else {
          yield put(actions.UPLOAD_AVATAR.failure(data?.data));
        }
      } else {
        yield put(actions.UPLOAD_AVATAR.failure(data?.data));
      }
    } catch (error) {
      yield put(actions.UPLOAD_AVATAR.failure(error?.data));
    }
  },
  *[TYPE.UPDATE_USER_INFO.request](action) {
    try {
      const data = yield call(
        api[TYPE.UPDATE_USER_INFO.request],
        action.payload
      );
      if (data.status === 200) {
        yield put(actions.UPDATE_USER_INFO.success(action.payload));
      } else {
        yield put(actions.UPDATE_USER_INFO.failure(data?.data));
      }
    } catch (error) {
      yield put(actions.UPDATE_USER_INFO.failure(error?.data));
    }
  },
  *[TYPE.SEARCH_LIST.request](action) {
    try {
      const data = yield call(api[TYPE.SEARCH_LIST.request], action.payload);
      if (data.status === 200) {
        yield put(actions.SEARCH_LIST.success(data?.data));
      } else {
        yield put(actions.SEARCH_LIST.failure(data?.data));
      }
    } catch (error) {
      yield put(actions.SEARCH_LIST.failure(error?.data));
    }
  },

  *[TYPE.GET_SEARCH_SUGGESTION.request](action) {
    try {
      const data = yield call(
        api[TYPE.GET_SEARCH_SUGGESTION.request],
        action.payload
      );
      if (data.status === 200) {
        yield put(actions.GET_SEARCH_SUGGESTION.success(data?.data));
      } else {
        yield put(actions.GET_SEARCH_SUGGESTION.failure(data?.data));
      }
    } catch (error) {
      yield put(actions.GET_SEARCH_SUGGESTION.failure(error?.data));
    }
  },
};

function* watchedSaga() {
  yield takeLatest(
    TYPE.GET_USER_INFO.request,
    sagas[TYPE.GET_USER_INFO.request]
  );
  yield takeLatest(
    TYPE.SIGN_IN_WITH_CUSTOM_TOKEN.request,
    sagas[TYPE.SIGN_IN_WITH_CUSTOM_TOKEN.request]
  );
  yield takeLatest(
    TYPE.UPLOAD_AVATAR.request,
    sagas[TYPE.UPLOAD_AVATAR.request]
  );
  yield takeLatest(
    TYPE.UPDATE_USER_INFO.request,
    sagas[TYPE.UPDATE_USER_INFO.request]
  );
  yield takeLatest(TYPE.SEARCH_LIST.request, sagas[TYPE.SEARCH_LIST.request]);
  yield takeLatest(
    TYPE.GET_SEARCH_SUGGESTION.request,
    sagas[TYPE.GET_SEARCH_SUGGESTION.request]
  );
}

export default function* rootChild() {
  yield fork(watchedSaga);
}
