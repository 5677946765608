/* eslint-disable prefer-template */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import { Slider } from "infinite-react-carousel";
import React, { useState, useRef, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import { Player, ControlBar, PlayToggle } from "video-react";
import moment from "moment";
// import BannerStore from "assets/img/Banner_store.png";
import BannerMarket from "assets/img/BannerMarket.jpg";
import { icons } from "assets/icons/index";

import "./CustomSlider.scss";
import classNames from "classnames";

const defaultSettings = {
  autoplay: true,
  dots: true,
  arrows: false,
  duration: 100,
  autoplaySpeed: 4000,
};

const CustomSlider = (props) => {
  const {
    items = [],
    fallbackImage,
    settings = {},
    imgClassName,
    sliderClassName,
    imgWrapperClassName,
  } = props;

  const [errorImage, setErrorImage] = useState([]);
  const [isImageViewer, setIsImageViewer] = useState(false);
  const [initSwiperReviewImageIndex, setInitSwiperReviewImageIndex] =
    useState(-1);
  const [swiperReviewImageIndex, setSwiperReviewImageIndex] = useState(-1);
  const swiperReviewStoryRef = useRef(null);
  const video_width = window.innerWidth;
  const video_height = window.innerHeight * 0.78;
  const videoRef = useRef(null);
  const swiperReviewpaginationRef = useRef(null);
  const swiperReviewImageRef = useRef(null);

  useEffect(() => {
    if (
      swiperReviewImageIndex > -1 &&
      swiperReviewpaginationRef.current !== undefined
    ) {
      swiperReviewpaginationRef.current?.swiper?.slideTo(
        swiperReviewImageIndex - 1
      );
    }
  }, [swiperReviewImageIndex]);

  useEffect(() => {
    if (isImageViewer) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }
  }, [isImageViewer]);

  const secondsToHms = (d) => {
    const result = Number(d);
    return moment(result).format("mm:ss");
  };

  if (!items || !items?.length > 0) {
    if (!fallbackImage) {
      return (
        <div className="custom-slider">
          <div className="custom-slider__img__container">
            <img
              src={BannerMarket}
              className={classNames("custom-slider__img--main", imgClassName)}
            />
          </div>
        </div>
      );
    }

    return (
      <div>
        <img src={fallbackImage} alt="fallback" />
      </div>
    );
  }

  const checkErrorImage = (item) => {
    const arr = [...errorImage];
    arr.push(item.imageUrl);
    setErrorImage(arr);
  };

  const onNextStory = () => {
    swiperReviewStoryRef.current?.swiper?.slideNext();
  };

  return (
    <div className="custom-slider">
      {items.length > 0 && (
        <Slider {...defaultSettings} {...settings} className={sliderClassName}>
          {items.map((item, index) => (
            <div
              key={item.imageUrl}
              className={classNames(
                "custom-slider__img__container",
                imgWrapperClassName
              )}
              onClick={() => {
                setInitSwiperReviewImageIndex(index);
                setIsImageViewer(true);
                setSwiperReviewImageIndex(index);
              }}
            >
              <img
                src={
                  errorImage.filter((f) => f === item.imageUrl).length > 0
                    ? BannerMarket
                    : item.imageUrl
                }
                className={classNames("custom-slider__img--main", imgClassName)}
                onError={() => checkErrorImage(item)}
              />
            </div>
          ))}
        </Slider>
      )}
      {isImageViewer && (
        <div className="image-viewer-container">
          <div className="image-viewer-header">
            <div className="current-title">
              {props.currentCategoryViewer?.categoryName}
            </div>
            <div
              className="close-button"
              onClick={() => {
                setIsImageViewer(false);
              }}
            >
              <img src={icons.iconClose} />
            </div>
          </div>
          <div className="image-viewer-content">
            <Swiper
              initialSlide={initSwiperReviewImageIndex}
              ref={swiperReviewImageRef}
              slidesPerView={1}
              spaceBetween={0}
              pagination={{
                clickable: true,
              }}
              // modules={[Pagination]}
              className="mySwiper"
              onSlideChange={(swiper) => {
                console.log("swiper.activeIndex", swiper.activeIndex);
                setSwiperReviewImageIndex(swiper.activeIndex);
              }}
            >
              {items.length > 0 &&
                items?.map((item, index) => {
                  return (
                    <div>
                      <SwiperSlide>
                        {item.type === 1 ? (
                          index === swiperReviewImageIndex ? (
                            <Player
                              fluid={false}
                              width={video_width}
                              height={video_height}
                              ref={videoRef}
                              autoPlay
                              poster={item.imageUrl}
                            >
                              <ControlBar autoHide>
                                <PlayToggle />
                              </ControlBar>
                              <source src={item.content} />
                            </Player>
                          ) : (
                            <img className="item-image" src={item.imageUrl} />
                          )
                        ) : (
                          <img className="item-image" src={item.imageUrl} />
                        )}
                      </SwiperSlide>
                    </div>
                  );
                })}
            </Swiper>
          </div>
          <div className="image-viewer-pagination">
            <Swiper
              ref={swiperReviewpaginationRef}
              slidesPerView={"auto"}
              spaceBetween={5}
              pagination={{
                clickable: true,
              }}
              // modules={[Pagination]}
              className="mySwiper"
            >
              {items?.length > 0 &&
                items?.map((item, index) => {
                  return (
                    <div>
                      <SwiperSlide>
                        {item.type === 1 ? (
                          <img
                            className="item-image-pagination"
                            src={item.imageUrl}
                          />
                        ) : (
                          <img
                            className="item-image-pagination"
                            src={item.imageUrl}
                          />
                        )}
                        {index === swiperReviewImageIndex ? (
                          <div
                            onClick={() => {
                              setSwiperReviewImageIndex(index);
                            }}
                            className="active-image"
                          ></div>
                        ) : (
                          <div
                            onClick={() => {
                              setSwiperReviewImageIndex(index);
                              swiperReviewImageRef.current?.swiper?.slideTo(
                                index
                              );
                            }}
                            className="inactive-image"
                          >
                            {item.type === 1 && (
                              <div className="txt-duration">
                                {secondsToHms(item.duration)}
                              </div>
                            )}
                            {item.type === 1 && (
                              <img
                                src={icons.iconPause}
                                className="icon-pause"
                              />
                            )}
                          </div>
                        )}
                      </SwiperSlide>
                    </div>
                  );
                })}
            </Swiper>
          </div>
        </div>
      )}
    </div>
  );
};

export default React.memo(CustomSlider);
