import { genActionTypes } from "utils/actionGenerator";

export const TYPE = "DeliveryInfomations";

export const ORDER_HISTORY_DETAIL = genActionTypes(
  `${TYPE}_ORDER_HISTORY_DETAIL`
);
export const INFOMATION_STORE = genActionTypes(`${TYPE}_INFOMATION_STORE`);
export const CANCEL_FNB = genActionTypes(`${TYPE}_CANCEL_FNB`);
export const GET_PAYMENT_METHODS = genActionTypes(
  `${TYPE}_LIST_TRANSACTION_METHODS`
);
