import request from "services/request";
import { encodeQueryData } from "utils/stringFormat";
import * as TYPE from "../store/constants";

const api = {
  [TYPE.GET_ORDER_DETAIL.request]: async (params) => {
    const query = encodeQueryData({
      orderCode: params.orderCode,
    });
    return request(`Order/GetOrderDetailByUser${query}`, "GET");
  },

  [TYPE.GET_PAYMENT_METHODS.request]: async () => {
    return request(`cms/core/paymentmethods`, "GET");
  },

  [TYPE.GET_PAYMENT_CARDS.request]: async () => {
    return request(`paymentcard/GetCurrentListCard`, "GET");
  },

  [TYPE.GET_UTOP_WALLET_INFO.request]: async () => {
    return request(`wallet/user`, "GET");
  },

  [TYPE.CREATE_PAYMENT.request]: async (params) => {
    // return request(`Order/CreatePaymentUrl`, "POST", params);
    return request(`Order/PaymentUrl/UtopWeb`, "POST", params);
  },

  [TYPE.TRANSACTION_PAY.request]: async (params) => {
    return request(`wallet/api/v2.0/Transaction/Pay`, "POST", params, {
      "Api-version": process.env.REACT_APP_PAYMENT_API_VERSION,
    });
  },
};

export default api;
