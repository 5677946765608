import {put, takeLatest, fork, call} from 'redux-saga/effects';
import {APIUtils} from 'utils';
import {
  myVoucherFailed,
  myVoucherSuccess,
  myVoucherUsedFailed,
  myVoucherUsedSuccess,
} from './actions';
import {MY_VOUCHER_REQUEST, MY_VOUCHER_USED_REQUEST} from './constants';

async function myVoucherAPI({skip}, renewToken) {
  const header = {Authorization: 'Bearer ' + renewToken};
  const params = {
    skip: skip,
    status: 'Redeem',
  };
  return APIUtils.get(global.apiLinks.getMyVoucher, {params, headers: header});
}

export function* myVoucherSaga(obj) {
  const {onSuccess} = obj.payload;
  try {
    const renewToken = yield APIUtils.reauthenticate();
    const data = yield call(myVoucherAPI, obj.payload, renewToken);
    if (data.status === 200) {
      yield put(myVoucherSuccess(data.data));
      if (typeof onSuccess === 'function') {
        onSuccess();
      }
    } else {
      yield put(myVoucherFailed(data));
    }
  } catch (err) {
    yield put(myVoucherFailed(err));
  }
}

async function myVoucherUsedAPI({skip}, renewToken) {
  const header = {Authorization: 'Bearer ' + renewToken};
  const params = {
    skip: skip,
    status: 'Used',
  };
  return APIUtils.get(global.apiLinks.getMyVoucher, {params, headers: header});
}

export function* myVoucherUsedSaga(obj) {
  const {onSuccess} = obj.payload;
  try {
    const renewToken = yield APIUtils.reauthenticate();
    const data = yield call(myVoucherUsedAPI, obj.payload, renewToken);
    if (data.status === 200) {
      yield put(myVoucherUsedSuccess(data.data));
      if (typeof onSuccess === 'function') {
        onSuccess();
      }
    } else {
      yield put(myVoucherUsedFailed(data));
    }
  } catch (err) {
    yield put(myVoucherUsedFailed(err));
  }
}

function* watchListVoucher() {
  yield takeLatest(MY_VOUCHER_REQUEST, myVoucherSaga);
  yield takeLatest(MY_VOUCHER_USED_REQUEST, myVoucherUsedSaga);
}

export default function* rootChild() {
  yield fork(watchListVoucher);
}
