import produce from 'immer';
import {GET_BANNER_SUCCESS, GET_BANNER_FAILED} from './types';

const initialState = {
  errors: null,
  banner: null,
};

const bannerReducer = produce((draft = initialState, action) => {
  const {payload} = action;

  switch (action.type) {
    case GET_BANNER_SUCCESS:
      return {
        errors: null,
        banner: payload,
      };
    case GET_BANNER_FAILED:
      return {
        errors: payload,
      };
    default:
      return draft;
  }
});

export default bannerReducer;
