import { call, put, takeLatest, fork } from "redux-saga/effects";
import actions from "./actions";
import * as TYPE from "./constants";
import api from "../api/api";

const sagas = {
  *[TYPE.ORDER_HISTORY_DETAIL.request](action) {
    try {
      const data = yield call(
        api[TYPE.ORDER_HISTORY_DETAIL.request],
        action.payload
      );
      if (data.status === 200) {
        yield put(actions.ORDER_HISTORY_DETAIL.success(data.data));
      } else {
        yield put(actions.ORDER_HISTORY_DETAIL.failure(data?.data));
      }
    } catch (error) {
      yield put(actions.ORDER_HISTORY_DETAIL.failure(error?.data));
    }
  },
  *[TYPE.CANCEL_VOUCHER.request](action) {
    try {
      const data = yield call(api[TYPE.CANCEL_VOUCHER.request], action.payload);
      if (data.status === 200) {
        yield put(actions.CANCEL_VOUCHER.success(data.data));
      } else {
        yield put(actions.CANCEL_VOUCHER.failure(data?.data));
      }
    } catch (error) {
      yield put(actions.CANCEL_VOUCHER.failure(error?.data));
    }
  },

  // * GET_PAYMENT_METHODS
  *[TYPE.GET_PAYMENT_METHODS.request](action) {
    try {
      const data = yield call(
        api[TYPE.GET_PAYMENT_METHODS.request],
        action.payload
      );
      if (data.status === 200) {
        yield put(actions.GET_PAYMENT_METHODS.success(data.data));
      } else {
        yield put(actions.GET_PAYMENT_METHODS.failure(data?.data));
      }
    } catch (error) {
      yield put(actions.GET_PAYMENT_METHODS.failure(error?.data));
    }
  },
  *[TYPE.GET_WHOLESALE_LIST.request](action) {
    try {
      const response = yield call(
        api[TYPE.GET_WHOLESALE_LIST.request],
        action.payload
      );

      if (response.status === 200) {
        yield put(actions.GET_WHOLESALE_LIST.success(response.data));
      } else {
        yield put(actions.GET_WHOLESALE_LIST.failure(response?.data));
      }
    } catch (error) {
      yield put(actions.GET_WHOLESALE_LIST.failure(error?.data));
    }
  },
};

function* watchedSaga() {
  yield takeLatest(
    TYPE.ORDER_HISTORY_DETAIL.request,
    sagas[TYPE.ORDER_HISTORY_DETAIL.request]
  );
  yield takeLatest(
    TYPE.CANCEL_VOUCHER.request,
    sagas[TYPE.CANCEL_VOUCHER.request]
  );

  // * GET_PAYMENT_METHODS
  yield takeLatest(
    TYPE.GET_PAYMENT_METHODS.request,
    sagas[TYPE.GET_PAYMENT_METHODS.request]
  );
  yield takeLatest(
    TYPE.GET_WHOLESALE_LIST.request,
    sagas[TYPE.GET_WHOLESALE_LIST.request]
  );
}

export default function* rootChild() {
  yield fork(watchedSaga);
}
