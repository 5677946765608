import { addErrors, delErrors } from "utils/ErrorsGenerator";
import * as TYPE from "./constants";

const reducers = {
  // [TYPE.SET_LOADING]: (draft, { payload }) => {
  //   return {
  //     ...draft,
  //     isLoading: payload,
  //   };
  // },

  // [TYPE.GET_LIST_FEED__FILTER_BY_ZONE.success]: (draft, { payload }) => {
  //   return {
  //     ...draft,
  //     errors: delErrors(
  //       draft.errors,
  //       TYPE.GET_LIST_FEED__FILTER_BY_ZONE.failure
  //     ),
  //     listFeed: {
  //       value: [...draft.listFeed.value, ...payload.value],
  //       total: payload?.[`@odata.count`],
  //     },
  //   };
  // },
  // [TYPE.GET_LIST_FEED__FILTER_BY_ZONE.failure]: (draft, { payload }) => {
  //   return {
  //     ...draft,
  //     errors: addErrors(draft.errors, {
  //       type: TYPE.GET_LIST_FEED__FILTER_BY_ZONE.failure,
  //       msg: payload,
  //     }),
  //   };
  // },

  // [TYPE.GET_LIST_FEED__OPEN_LIST_STORE.success]: (draft, { payload }) => {
  //   return {
  //     ...draft,
  //     errors: delErrors(
  //       draft.errors,
  //       TYPE.GET_LIST_FEED__OPEN_LIST_STORE.failure
  //     ),
  //     listFeed: {
  //       value: [...draft.listFeed.value, ...payload.value],
  //       total: payload?.[`@odata.count`],
  //     },
  //   };
  // },
  // [TYPE.GET_LIST_FEED__OPEN_LIST_STORE.failure]: (draft, { payload }) => {
  //   return {
  //     ...draft,
  //     errors: addErrors(draft.errors, {
  //       type: TYPE.GET_LIST_FEED__OPEN_LIST_STORE.failure,
  //       msg: payload,
  //     }),
  //   };
  // },

  // //
  // [TYPE.GET_LIST_FEED__OPEN_CAT.success]: (draft, { payload }) => {
  //   return {
  //     ...draft,
  //     errors: delErrors(draft.errors, TYPE.GET_LIST_FEED__OPEN_CAT.failure),
  //     listFeed: {
  //       value: [...draft.listFeed.value, ...payload.value],
  //       total: payload?.[`@odata.count`],
  //     },
  //   };
  // },
  // [TYPE.GET_LIST_FEED__OPEN_CAT.failure]: (draft, { payload }) => {
  //   return {
  //     ...draft,
  //     errors: addErrors(draft.errors, {
  //       type: TYPE.GET_LIST_FEED__OPEN_CAT.failure,
  //       msg: payload,
  //     }),
  //   };
  // },

  // *** GET_LIST_FEED
  [TYPE.GET_LIST_FEED.request]: (draft) => {
    return {
      ...draft,
      isLoading: {
        ...draft.isLoading,
        GET_LIST_FEED: true,
      },
    };
  },
  [TYPE.GET_LIST_FEED.success]: (draft, { payload }) => {
    return {
      ...draft,
      errors: delErrors(draft.errors, TYPE.GET_LIST_FEED.failure),
      listFeed: payload,
      isLoading: {
        ...draft.isLoading,
        GET_LIST_FEED: false,
      },
    };
  },

  [TYPE.GET_LIST_FEED.failure]: (draft, { payload }) => {
    return {
      ...draft,
      errors: addErrors(draft.errors, {
        type: TYPE.GET_LIST_FEED.failure,
        msg: payload,
      }),
      isLoading: {
        ...draft.isLoading,
        GET_LIST_FEED: false,
      },
    };
  },

  // *** GET_LIST_STORE_OF_MERCHANT
  [TYPE.GET_LIST_STORE_OF_MERCHANT.request]: (draft, { payload }) => {
    const { merchantId } = payload;

    let { listStore, hasMore } = draft.listStoreOfSelectedMerchant;

    // reset data if select a another merchant
    if (draft.listStoreOfSelectedMerchant.merchantId !== merchantId) {
      listStore = [];
      hasMore = true;
    }

    return {
      ...draft,
      listStoreOfSelectedMerchant: {
        merchantId,
        listStore,
        hasMore,
      },
      isLoading: {
        ...draft.isLoading,
        GET_LIST_STORE_OF_MERCHANT: true,
      },
    };
  },
  [TYPE.GET_LIST_STORE_OF_MERCHANT.success]: (draft, { payload }) => {
    return {
      ...draft,
      listStoreOfSelectedMerchant: payload,
      errors: delErrors(draft.errors, TYPE.GET_LIST_STORE_OF_MERCHANT.failure),
      isLoading: {
        ...draft.isLoading,
        GET_LIST_STORE_OF_MERCHANT: false,
      },
    };
  },

  [TYPE.GET_LIST_STORE_OF_MERCHANT.failure]: (draft, { payload }) => {
    return {
      ...draft,
      errors: addErrors(draft.errors, {
        type: TYPE.GET_LIST_STORE_OF_MERCHANT.failure,
        msg: payload,
      }),
      isLoading: {
        ...draft.isLoading,
        GET_LIST_STORE_OF_MERCHANT: false,
      },
    };
  },
};

export default reducers;
