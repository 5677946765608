import { genActionTypes } from "utils/actionGenerator";

export const TYPE = "Order";

// export const SET_LOADING = `${TYPE}_SET_LOADING`;
export const GET_USER_DEFAULT_ADDRESS_BOOK = genActionTypes(
  `${TYPE}_GET_USER_DEFAULT_ADDRESS_BOOK`
);
export const GET_SUGGESTED_COUPON_LIST = genActionTypes(
  `${TYPE}_GET_SUGGESTED_COUPON_LIST`
);
export const GET_SHIPPING_INFO = genActionTypes(`${TYPE}_GET_SHIPPING_INFO`);
export const CLEAR_ERROR_SHIPPING_INFO = genActionTypes(
  `${TYPE}_CLEAR_ERROR_SHIPPING_INFO`
);
export const GET_SUGGESTED_DELIVERY_DISCOUNT_LIST = genActionTypes(
  `${TYPE}_GET_SUGGESTED_DELIVERY_DISCOUNT_LIST`
);
export const CALC_COUPON_DISCOUNT = genActionTypes(
  `${TYPE}_CALC_COUPON_DISCOUNT`
);
export const GET_AVAILABLE_COUPONS_BY_CODE = genActionTypes(
  `${TYPE}_GET_AVAILABLE_COUPONS_BY_CODE`
);
export const GET_AVAILABLE_COUPONS_OF_ALLOCATED_COUPON_TICKET = genActionTypes(
  `${TYPE}_GET_AVAILABLE_COUPONS_OF_ALLOCATED_COUPON_TICKET`
);
export const CALC_COUPON_DISCOUNT_V2 = genActionTypes(
  `${TYPE}_CALC_COUPON_DISCOUNT_V2`
);
export const CREATE_ORDER = genActionTypes(`${TYPE}_CREATE_ORDER`);
export const GET_PRODUCT_LIST = genActionTypes(`${TYPE}_GET_PRODUCT_LIST`);
export const GET_OUT_OF_STOCK_PRODUCT_LIST = genActionTypes(
  `${TYPE}_GET_OUT_OF_STOCK_PRODUCT_LIST`
);
export const GET_STORE_INFO = genActionTypes(`${TYPE}_GET_STORE_INFO`);
export const GET_LOYALTY_POINT_LIST = genActionTypes(
  `${TYPE}_GET_LOYALTY_POINT_LIST`
);
export const CLEAR_ORDER_INFO = `${TYPE}_CLEAR_ORDER_INFO`;
