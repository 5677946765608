import {
  GET_SUGGEST_COUPON_REQUEST,
  ALLOWCATION_COUPON_REQUEST,
  GET_COUPON_DETAIL_REQUEST,
  CAL_COUPON_DISCOUNT_REQUEST,
  GET_SUGGEST_DISCOUNT_SHIPPING_REQUEST,
} from './constants'
import {
  getSuggestCouponFailure,
  getSuggestCouponSuccess,
  allowcationCouponFailure,
  allowcationCouponSuccess,
  getCouponDetailFailure,
  getCouponDetailSuccess,
  calCouponDiscountFailure,
  calCouponDiscountSuccess,
  getSuggestDiscountShippingSuccess,
  getSuggestDiscountShippingFailed,
} from './actions'
import { put, takeLatest, fork } from 'redux-saga/effects'
import {
  getSuggestCouponApi,
  allowcationCouponApi,
  getCouponDetailApi,
  calCouponDiscountApi,
  getSuggestDiscountShippingApi,
} from '@shared/services/api/cart'
import { callApi } from 'helpers/callApi'

export function* calCouponDiscount(obj) {
  const { requestData = {}, onSuccess, onFailed } = obj.payload
  console.log('function*calCouponDiscount -> obj', obj.payload)

  try {
    const data = yield callApi(calCouponDiscountApi, requestData)

    if (data.status === 200) {
      yield put(calCouponDiscountSuccess(data.data))
      try {
        onSuccess(data)
      } catch (error) {}
    } else {
      yield put(calCouponDiscountFailure())
      try {
        onFailed(data?.message)
        yield put(allowcationCouponSuccess({}))
      } catch (error) {}
    }
  } catch (err) {
    yield put(calCouponDiscountFailure(err))
    yield put(allowcationCouponSuccess({}))
    try {
      onFailed(err)
    } catch (error) {}
  }
}

export function* getCouponDetail(obj) {
  const { item = {} } = obj.payload
  const { onFailed } = obj

  try {
    const respone = yield callApi(getCouponDetailApi, item)

    if (respone.status === 200) {
      if (respone.data.length > 0) {
        yield put(getCouponDetailSuccess())
        yield put(allowcationCouponSuccess({ ...item, ...respone.data[0] }))
      } else {
        // Alert.alert("Utop", "Bạn đã hết lượt sử dụng coupon.");
        yield put(getCouponDetailSuccess())
        try {
          if (typeof onFailed === 'function') {
            onFailed(respone)
          }
        } catch (error) {}
      }
    } else {
      yield put(getCouponDetailFailure(respone))
    }
  } catch (err) {
    yield put(getCouponDetailFailure(err))
  }
}

export function* allowcationCoupon(obj) {
  const { payload = {}, onSuccess, onFailed } = obj
  try {
    const respone = yield callApi(allowcationCouponApi, payload)

    if (respone.status === 200) {
      if (respone.data.coupons.length > 0) {
        yield put(
          allowcationCouponSuccess({ ...payload, ...respone.data.coupons[0] }),
        )
        if (typeof onSuccess === 'function') {
          onFailed(respone.data.coupons[0])
        }
      } else {
        yield put(allowcationCouponFailure(null))
      }
    }
  } catch (err) {
    if (typeof onFailed === 'function') {
      onFailed(err?.data)
    }
    yield put(allowcationCouponFailure(err?.data?.message))
    // checkErrorCodeFortFood(err?.data);
  }
}

export function* getSuggestCoupon(obj) {
  try {
    const respone = yield callApi(getSuggestCouponApi, obj.payload)

    if (respone.status === 200) {
      yield put(getSuggestCouponSuccess(respone.data.suggestedCouponDetails))
    }
  } catch (err) {
    yield put(getSuggestCouponFailure(err))
  }
}

export function* getSuggestDiscountShippingSaga(obj) {
  const { payload, onSuccess, onFailed } = obj
  try {
    const respone = yield callApi(getSuggestDiscountShippingApi, payload)
    if (respone.status === 200) {
      yield put(getSuggestDiscountShippingSuccess(respone?.data))
    }

    if (typeof onSuccess === 'function') {
      onSuccess(respone?.data)
    }
  } catch (err) {
    yield put(getSuggestDiscountShippingFailed(err))
    if (typeof onFailed === 'function') {
      onFailed(err)
    }
  }
}

function* getSuggestCouponWatch() {
  yield takeLatest(GET_SUGGEST_COUPON_REQUEST, getSuggestCoupon)
  yield takeLatest(ALLOWCATION_COUPON_REQUEST, allowcationCoupon)
  yield takeLatest(GET_COUPON_DETAIL_REQUEST, getCouponDetail)
  yield takeLatest(CAL_COUPON_DISCOUNT_REQUEST, calCouponDiscount)
  yield takeLatest(
    GET_SUGGEST_DISCOUNT_SHIPPING_REQUEST,
    getSuggestDiscountShippingSaga,
  )
}

export default function* rootChild() {
  yield fork(getSuggestCouponWatch)
}
