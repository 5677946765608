import { END_POINTS } from "@shared/constants/apiUrl";
import { APIUtils } from "utils";
import { store } from "@shared/redux/configureStore";

export async function getListBookingApi({
  skipCount,
  dataFilter,
  userId,
  type,
}) {
  let url =
    END_POINTS.UTOP_BUSINESS_GET_LIST_BOOKING_REQUEST +
    "?Skip=" +
    skipCount +
    "&Take=10";

  if (
    JSON.stringify(dataFilter) !== "{}" &&
    dataFilter !== null &&
    typeof dataFilter !== "undefined"
  ) {
    url =
      url + "&FromDate=" + dataFilter.fromDate + "&ToDate=" + dataFilter.toDate;
  }
  return APIUtils.get(url, {
    headers: {
      "X-BusinessId": userId || "",
    },
  });
}

export async function getDetailBookingApi(payload) {
  let url = END_POINTS.UTOP_BUSINESS_BOOKING_REQUEST + payload.id;
  return APIUtils.get(url, {
    headers: {
      "X-BusinessId": payload.userId || "",
    },
  });
}
export async function cancelBookingApi(payload) {
  let url = END_POINTS.UTOP_BUSINESS_BOOKING_REQUEST + payload.id + "/Cancel";
  return APIUtils.post(url, {
    headers: {
      "X-BusinessId": payload.userId || "",
    },
  });
}
