import { takeLatest, fork, put } from "redux-saga/effects";
import {
  EXCHANGE_POINT_PARTNER_REQUEST,
  GET_LIST_PARTNER_EXCHANGE_POINT_REQUEST,
  GET_PARTNER_INFO_REQUEST,
  MAPPING_MEMBER_WITH_PARTNER_REQUEST,
  REMOVE_MAPPING_MEMBER_WITH_PARTNER_REQUEST,
} from "./constants";

import { callApi } from "helpers/callApi";
import {
  exchangePointPartnerApi,
  getListPartnerExchangePointApi,
  getPartnerInfoApi,
  mappingMemberWithPartnerApi,
  removeMappingMemberWithPartnerApi,
} from "@shared/services/api/point";
import {
  exchangePointPartnerFailed,
  exchangePointPartnerSuccess,
  getListPartnerExchangePointFailed,
  getListPartnerExchangePointSuccess,
  getPartnerInfoFailed,
  getPartnerInfoSuccess,
  mappingMemberWithPartnerFailed,
  mappingMemberWithPartnerSuccess,
  removeMappingMemberWithPartnerFailed,
  removeMappingMemberWithPartnerSuccess,
} from "./actions";

export function* getListPartnerExchangePointSaga(obj) {
  const { payload = {}, onSuccess, onError } = obj;

  try {
    const res = yield callApi(getListPartnerExchangePointApi, payload);
    if (typeof onSuccess === "function") {
      onSuccess(res.data);
    }
    yield put(getListPartnerExchangePointSuccess(res.data));
  } catch (err) {
    if (typeof onError === "function") {
      onError(err);
    }
    yield put(getListPartnerExchangePointFailed(err));
  }
}

export function* exchangePointPartnerSaga(obj) {
  const { url = "", config, onSuccess, onError } = obj;

  try {
    const res = yield callApi(exchangePointPartnerApi, { url, config });
    if (res.data?.result === 200) {
      if (typeof onSuccess === "function") {
        onSuccess(res.data);
      }
      yield put(exchangePointPartnerSuccess(res.data));
    } else {
      if (typeof onError === "function") {
        onError(res.data);
      }
      yield put(exchangePointPartnerFailed(res.data));
    }
  } catch (err) {
    if (typeof onError === "function") {
      onError(err);
    }
    yield put(exchangePointPartnerFailed(err));
  }
}

export function* mappingMemberWithPartnerSaga(obj) {
  const { payload = {}, onSuccess, onError } = obj;

  try {
    const res = yield callApi(mappingMemberWithPartnerApi, payload);
    if (res?.data?.result === 200) {
      if (typeof onSuccess === "function") {
        onSuccess(res.data);
      }
      yield put(mappingMemberWithPartnerSuccess(payload.partnerInfo));
    } else {
      if (typeof onError === "function") {
        onError(res.data);
      }
      yield put(mappingMemberWithPartnerFailed(res.data));
      Alert.alert("Utop", res?.data?.message);
    }
  } catch (err) {
    if (typeof onError === "function") {
      onError(err);
    }
    yield put(mappingMemberWithPartnerFailed(err));
  }
}

export function* removeMappingMemberWithPartnerSaga(obj) {
  const { payload = {}, onSuccess, onError } = obj;

  try {
    const res = yield callApi(removeMappingMemberWithPartnerApi, payload);
    if (typeof onSuccess === "function") {
      onSuccess(res.data);
    }
    yield put(removeMappingMemberWithPartnerSuccess(payload.partnerInfo));
  } catch (err) {
    if (typeof onError === "function") {
      onError(err);
    }
    yield put(removeMappingMemberWithPartnerFailed(err));
  }
}

export function* getPartnerInfoSaga(obj) {
  const { url = "", config, onSuccess, onError } = obj;

  try {
    const res = yield callApi(getPartnerInfoApi, { url, config });
    if (res.data?.result === 200) {
      if (typeof onSuccess === "function") {
        onSuccess(res.data);
      }
      yield put(getPartnerInfoSuccess(res.data));
    } else {
      if (typeof onError === "function") {
        onError(res.data);
      }
      yield put(getPartnerInfoFailed(res.data));
    }
  } catch (err) {
    if (typeof onError === "function") {
      onError(err);
    }
    yield put(getPartnerInfoFailed(err));
  }
}

function* watchPoint() {
  yield takeLatest(
    GET_LIST_PARTNER_EXCHANGE_POINT_REQUEST,
    getListPartnerExchangePointSaga
  );
  yield takeLatest(
    MAPPING_MEMBER_WITH_PARTNER_REQUEST,
    mappingMemberWithPartnerSaga
  );
  yield takeLatest(
    REMOVE_MAPPING_MEMBER_WITH_PARTNER_REQUEST,
    removeMappingMemberWithPartnerSaga
  );
  yield takeLatest(GET_PARTNER_INFO_REQUEST, getPartnerInfoSaga);
  yield takeLatest(EXCHANGE_POINT_PARTNER_REQUEST, exchangePointPartnerSaga);
}

export default function* rootChild() {
  yield fork(watchPoint);
}
