import React, { useState, useEffect } from "react";
import "./StoreItem.scss";
import IMAGES from "assets/img";
import { CellPhoneIcon, ShareSolidYellowIcon } from "assets/svg";
import { convertHtmlToText } from "helpers/string";
import classNames from "classnames";
import { IconButton, ImageShow } from "../../../../../../../common/component";

const StoreItem = ({ gotoProduct, dataStore, handleShareStore, key }) => {
  const [errorImage, setErrorImage] = useState(false);
  const [isShowStory, setIsShowStory] = useState(false);

  useEffect(() => {
    if (isShowStory) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }
  }, [isShowStory]);
  const checkImageUrl = (images) => {
    if (images !== undefined && images.length > 0) {
      const listAvatar = images.filter((item) => item.isFeatured === true);
      if (listAvatar.length > 0) {
        return listAvatar[0].imageUrl || "";
      }
      return "";
    }
    return "";
  };

  const checkErrorImage = () => {
    setErrorImage(true);
  };

  return (
    <div className="content-store-item" key={key}>
      <div className="content-avatar">
        {errorImage ? (
          <div onClick={() => setIsShowStory(true)}>
            <img
              className="avatar-img"
              src={IMAGES.LogoUtop2}
              alt="ava"
              onError={checkErrorImage}
            />
          </div>
        ) : (
          <object
            className="avatar-img"
            data={checkImageUrl(dataStore.images) || IMAGES.LogoUtop2}
            type="image/png"
            onError={checkErrorImage}
            onClick={() => setIsShowStory(true)}
          >
            <img
              className="avatar-img"
              src={checkImageUrl(dataStore.images) || IMAGES.LogoUtop2}
              alt="ava"
              onError={checkErrorImage}
            />
          </object>
        )}
      </div>
      <div
        className="content-store-info"
        onClick={gotoProduct}
        style={{ cursor: "pointer" }}
      >
        <div>
          <div className="content-store-info_title">{dataStore?.name?.vi}</div>
          <p
            className={classNames("mb-2 _fs-12px _fw-50", "only-2-lines")}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: convertHtmlToText(dataStore?.description),
            }}
          />
        </div>
        <div className="content-store-info-contact">
          {dataStore?.phone && (
            <div
              className="content-store-info-contact_icon-right"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                window.location.href = `tel:${dataStore?.phone}`;
              }}
            >
              <IconButton>
                <CellPhoneIcon />
              </IconButton>
            </div>
          )}
          <div
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleShareStore(dataStore);
            }}
          >
            <IconButton className="button-share">
              <ShareSolidYellowIcon />
            </IconButton>
          </div>
        </div>
      </div>
      <ImageShow
        isShowStory={isShowStory}
        closeShowStory={() => setIsShowStory(false)}
        image={dataStore.images}
      />
    </div>
  );
};

export default StoreItem;
