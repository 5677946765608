import Skeleton from "react-loading-skeleton";

const renderSkeleton = () => {
  return (
    <div style={{ marginTop: 60 }}>
      <Skeleton width="50%" height="32px" />
      <div>
        <Skeleton width="25%" height="100px" />
        <Skeleton width="25%" height="100px" />
        <Skeleton width="25%" height="100px" />
        <Skeleton width="25%" height="100px" />
      </div>
      <div style={{ marginTop: 10 }}>
        <Skeleton width="25%" height="100px" />
        <Skeleton width="25%" height="100px" />
        <Skeleton width="25%" height="100px" />
        <Skeleton width="25%" height="100px" />
      </div>
      <Skeleton width="50%" height="32px" />
      <div>
        <Skeleton width="25%" height="100px" />
        <Skeleton width="25%" height="100px" />
        <Skeleton width="25%" height="100px" />
        <Skeleton width="25%" height="100px" />
      </div>
      <div style={{ marginTop: 10 }}>
        <Skeleton width="25%" height="100px" />
        <Skeleton width="25%" height="100px" />
        <Skeleton width="25%" height="100px" />
        <Skeleton width="25%" height="100px" />
      </div>
    </div>
  );
};
export default renderSkeleton;
