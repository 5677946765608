import {actionTypes} from '../actionTypes';

export const GET_LIST_BOUGHT_PACKAGE = actionTypes(
  'listNews/GET_LIST_BOUGHT_PACKAGE',
);
export const GET_LIST_RECOMMEND_LEVEL_TYPE_NEWS = actionTypes(
  'listNews/GET_LIST_RECOMMEND_LEVEL_TYPE_NEWS',
);
export const GET_LIST_DATA_RECOMMEND_WITH_LEVEL_TYPE_NEWS = actionTypes(
  'listNews/GET_LIST_DATA_RECOMMEND_WITH_LEVEL_TYPE_NEWS',
);

export const GET_LIST_NEWS_BOUGHT = actionTypes(
  'listNews/GET_LIST_NEWS_BOUGHT',
);
