import {APIUtils} from 'utils';
import md5 from 'md5';
import CryptoJS from '@shared/utils/CryptoJS';
import {END_POINTS} from '@shared/constants/apiUrl';

export function checkNumberTransferAPI(phoneNumber) {
  return APIUtils.get(
    global.apiLinks.CheckPhoneNumberTransfer + '?phoneNumber=' + phoneNumber,
    {method: 'GET'},
  );
}

export function getUtopBalanceAPI() {
  return APIUtils.get(global.apiLinks.getUtopBalance, {method: 'GET'});
}
export function transferUtopAPI(payload) {
  if (payload.pinCode !== '') {
    payload['timestamp'] = Math.floor(new Date().getTime() / 1000);
    var pinCode = md5(payload.pinCode).toString();
    var pinCodeSign = CryptoJS.HmacSHA256(
      JSON.stringify(payload),
      pinCode,
    ).toString();
    var header = {
      'Content-Type': 'application/json',
    };
    header['X-PinCodeSign'] = pinCodeSign;
  } else {
    let pinCode = md5('').toString();
    let pinCodeSign = CryptoJS.HmacSHA256(
      JSON.stringify(payload),
      pinCode,
    ).toString();
    var header = {
      'Content-Type': 'application/json',
    };
    header['X-PinCodeSign'] = pinCodeSign;
  }

  return APIUtils.post(END_POINTS.TRANSFER_URL, {
    headers: header,
    body: payload,
  });
}
export function setPinCodeAPI(payload) {
  return APIUtils.post(global.apiLinks.SetPinCode, {body: payload});
}
export function createOrderAPI(payload) {
  return APIUtils.post(global.apiLinks.CreateOrder, {body: payload});
}
export function createOrderUtopHubAPI(payload) {
  console.log('payload : ', payload);
  return APIUtils.post(END_POINTS.CREATE_ORDER_UTOP_HUB_URL, {body: payload});
}

export function getPaymentInfoApi({orderId, transactionId, partnerId}) {
  return APIUtils.get(END_POINTS.GET_PAYMENT_INFO_URL, {
    params: {orderId, transactionId, partnerId},
  });
}
