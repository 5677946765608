const prefix = 'hashtag_UtopVideo';

// Fetch hashtag list
export const HASHTAG_LIST_REQUEST = prefix + '/HASHTAG_LIST_REQUEST';
export const HASHTAG_LIST_SUCCESS = prefix + '/HASHTAG_LIST_SUCCESS';
export const HASHTAG_LIST_FAILED = prefix + '/HASHTAG_LIST_FAILED';

// Fetch hashtag detail
export const HASHTAG_DETAIL_REQUEST = prefix + '/HASHTAG_DETAIL_REQUEST';
export const HASHTAG_DETAIL_SUCCESS = prefix + '/HASHTAG_DETAIL_SUCCESS';
export const HASHTAG_DETAIL_FAILED = prefix + '/HASHTAG_DETAIL_FAILED';

// Fetch hashtag info
export const HASHTAG_INFO_REQUEST = prefix + '/HASHTAG_INFO_REQUEST';
export const HASHTAG_INFO_SUCCESS = prefix + '/HASHTAG_INFO_SUCCESS';
export const HASHTAG_INFO_FAILED = prefix + '/HASHTAG_INFO_FAILED';

// Favorite hashtag 
export const FAVORITE_HASHTAG_REQUEST = prefix + '/FAVORITE_HASHTAG_REQUEST';
export const FAVORITE_HASHTAG_SUCCESS = prefix + '/FAVORITE_HASHTAG_SUCCESS';
export const FAVORITE_HASHTAG_FAILED = prefix + '/FAVORITE_HASHTAG_FAILED';

// Dis Favorite hashtag 
export const DIS_FAVORITE_HASHTAG_REQUEST = prefix + '/DIS_FAVORITE_HASHTAG_REQUEST';
export const DIS_FAVORITE_HASHTAG_SUCCESS = prefix + '/DIS_FAVORITE_HASHTAG_SUCCESS';
export const DIS_FAVORITE_HASHTAG_FAILED = prefix + '/DIS_FAVORITE_HASHTAG_FAILED';