import {
  GET_HISTORY_POINT_REQUEST,
  GET_DETAIL_HISTORY_POINT_REQUEST,
} from "./constants";
import {
  getHistoryPointFailure,
  getHistoryPointSuccess,
  getDetailHistoryPointSuccess,
  getDetailHistoryPointFailure,
} from "./actions";
import { put, takeLatest, fork } from "redux-saga/effects";
import {
  getTransactionByUser,
  getTransactionByCode,
} from "@shared/services/api/menuHistoryPoint";
import { callApi } from "helpers/callApi";

export function* getHistoryPoint(obj) {
  try {
    const respone = yield callApi(getTransactionByUser, {
      ...obj.payload,
    });
    if (respone.status === 200) {
      const { skip } = obj.payload;

      yield put(
        getHistoryPointSuccess({
          items: respone.data,
          total: respone.data.length,
          skip,
        })
      );
    }
  } catch (err) {
    yield put(getHistoryPointFailure(err));
  }
}

export function* getDetailHistoryPoint(obj) {
  const { payload = {}, onSuccess, onFailed } = obj;
  try {
    const res = yield callApi(getTransactionByCode, payload);
    console.log("getDetailHistoryPointSuccess", res);
    yield put(getDetailHistoryPointSuccess(res.data));
    if (typeof onSuccess === "function") {
      onSuccess(res);
    }
  } catch (err) {
    console.log("getDetailHistoryPointFailure", err);
    yield put(getDetailHistoryPointFailure(err));
    onFailed(err);
  }
}

function* getHistoryPointWatch() {
  yield takeLatest(GET_HISTORY_POINT_REQUEST, getHistoryPoint);
  yield takeLatest(GET_DETAIL_HISTORY_POINT_REQUEST, getDetailHistoryPoint);
}

export default function* rootChild() {
  yield fork(getHistoryPointWatch);
}
