import {fork, put, takeEvery} from 'redux-saga/effects';
import {callApi} from 'helpers/callApi';
import {getListNumberNewspaperEditionApi} from '@shared/services/api/listNumberNewspaper';
import {
  getListNumberNewspaperTypeFailed,
  getListNumberNewspaperTypeSuccess,
} from './actions';
import {GET_LIST_NUMBER_NEWSPAPER} from './constants';

export function* getListNumberNewspaperOfPackage(action) {
  const {onSuccess, skip, take, newsPagesId, onFailed} = action;
  try {
    const res = yield callApi(getListNumberNewspaperEditionApi, {
      skip,
      newsPagesId,
      take,
    });
    yield put(getListNumberNewspaperTypeSuccess());
    onSuccess?.(res?.data);
  } catch (error) {
    onFailed?.();
    yield put(getListNumberNewspaperTypeFailed(error));
  }
}

function* watchListNumberNewspaperSaga() {
  yield takeEvery(
    GET_LIST_NUMBER_NEWSPAPER.HANDLER,
    getListNumberNewspaperOfPackage,
  );
}
export default function* rootChild() {
  yield fork(watchListNumberNewspaperSaga);
}
