import {
  GET_DETAIL_ORDER_FAILED,
  GET_DETAIL_ORDER_REQUEST,
  GET_DETAIL_ORDER_SUCCESS,
  GET_INFO_STORE_FAILED,
  GET_INFO_STORE_REQUEST,
  GET_INFO_STORE_SUCCESS,
  GET_DISCOUNT_COUPON_FAILED,
  GET_DISCOUNT_COUPON_REQUEST,
  GET_DISCOUNT_COUPON_SUCCESS,
  GET_PAYMENT_METHOD_FAILED,
  GET_PAYMENT_METHOD_REQUEST,
  GET_PAYMENT_METHOD_SUCCESS,
  CANCEL_ORDER_FAILED,
  CANCEL_ORDER_REQUEST,
  CANCEL_ORDER_SUCCESS,
  GET_LIST_SUMMARY_BY_USER_REQUEST,
  GET_LIST_SUMMARY_BY_USER_SUCCESS,
  GET_LIST_SUMMARY_BY_USER_FAILED,
} from './constants';

// get detail order
export const getDetailOrderRequest = (payload = {}, onSuccess, onFailed) => ({
  type: GET_DETAIL_ORDER_REQUEST,
  payload,
  onSuccess,
  onFailed,
});

export const getDetailOrderSuccess = payload => ({
  type: GET_DETAIL_ORDER_SUCCESS,
  payload,
});

export const getDetailOrderFailure = payload => ({
  type: GET_DETAIL_ORDER_FAILED,
  payload,
});

//get info store
export const getInfoStoreRequest = (payload = {}, onSuccess, onFailed) => ({
  type: GET_INFO_STORE_REQUEST,
  payload,
  onSuccess,
  onFailed,
});

export const getInfoStoreSuccess = payload => ({
  type: GET_INFO_STORE_SUCCESS,
  payload,
});

export const getInfoStoreFailure = payload => ({
  type: GET_INFO_STORE_FAILED,
  payload,
});

//get discount coupom
export const getDiscountCouponRequest = (
  payload = {},
  onSuccess,
  onFailed,
) => ({
  type: GET_DISCOUNT_COUPON_REQUEST,
  payload,
  onSuccess,
  onFailed,
});

export const getDiscountCouponSuccess = payload => ({
  type: GET_DISCOUNT_COUPON_SUCCESS,
  payload,
});

export const getDiscountCouponFailure = payload => ({
  type: GET_DISCOUNT_COUPON_FAILED,
  payload,
});

//get payment method
export const getPaymentMethodRequest = (onSuccess, onFailed) => ({
  type: GET_PAYMENT_METHOD_REQUEST,
  onSuccess,
  onFailed,
});

export const getPaymentMethodSuccess = payload => ({
  type: GET_PAYMENT_METHOD_SUCCESS,
  payload,
});

export const getPaymentMethodFailure = payload => ({
  type: GET_PAYMENT_METHOD_FAILED,
  payload,
});

//cancel order
export const cancelOrderRequest = (payload = {}, onSuccess, onFailed) => ({
  type: CANCEL_ORDER_REQUEST,
  payload,
  onSuccess,
  onFailed,
});

export const cancelOrderSuccess = payload => ({
  type: CANCEL_ORDER_SUCCESS,
  payload,
});

export const cancelOrderFailure = payload => ({
  type: CANCEL_ORDER_FAILED,
  payload,
});

export const getListSummaryByUserRequest = (onSuccess, onFailed) => ({
  type: GET_LIST_SUMMARY_BY_USER_REQUEST,
  onSuccess,
  onFailed,
});

export const getListSummaryByUserSuccess = payload => ({
  type: GET_LIST_SUMMARY_BY_USER_SUCCESS,
  payload,
});

export const getListSummaryByUserFailure = payload => ({
  type: GET_LIST_SUMMARY_BY_USER_FAILED,
  payload,
});
