import {fork, put, takeLatest} from 'redux-saga/effects';
import {LOGIN} from './constants';
import { loginSuccess} from './actions';


export function* loginSaga(obj) {
  const {payload, onSuccess, onError} = obj;

  onSuccess?.(payload);
  yield put(loginSuccess(payload));
  AsyncStorage.setItem('ACCESS_TOKEN', payload.accessToken);
}

function* watchUser() {
  yield takeLatest(LOGIN.HANDLER, loginSaga);
}

export default function* rootChild() {
  yield fork(watchUser);
}
