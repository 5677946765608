import { checkRepeatProductDataCart } from '@shared/utils/preprocessingData'
import {
  UPDATE_PRODUCT_CART_OUT_OF_STOCK,
  CLEAR_PRODUCT_CART,
  DELETE_PRODUCT_CART,
  ADD_PRODUCT_CART,
  RESET_PRODUCT_CART,
  SET_DATA_STORE,
  SET_INDEX_PRODUCT_CART,
  UPDATE_PRODUCT_CART,
  SET_LIST_COUPON_SUGGEST_FOR_STORE,
  UPDATE_LIST_COUPON_SUGGEST_FOR_STORE,
  SAVE_CURRENT_STORE_ID,
  GET_PRODUCTS_SUCCESS,
  CHECK_PRODUCT_OUT_OF_STOCK_SUCCESS,
  UPDATE_LIST_PRODUCT_CART,
  SET_CREATE_NEW_LIST_DELIVERY,
  CLEAR_PRODUCT_OF_STORE,
} from './constants'

const initialState = {
  listProduct: [],
  listProductsByMerchant: [],
  listOutOfStockProducts: [],
  listProductsByMerchantOrigin: [],
  dataStore: {},
  listCouponSuggest: [],
}

const initialStateListProducts = {
  listProductsByMerchant: [],
  listOutOfStockProducts: [],
  listProductsByMerchantOrigin: [],
}

const initialStateClearProduct = {
  listProduct: [],
  dataStore: {},
}

const smartMenuReducer = (state = initialState, action) => {
  const { payload } = action

  switch (action.type) {
    case ADD_PRODUCT_CART:
      return {
        ...state,
        listProduct: [...state.listProduct, payload],
      }

    case UPDATE_PRODUCT_CART_OUT_OF_STOCK:
      return {
        ...state,
        listProduct: state.listProduct.map((product, index) =>
          product.dataInfoProduct.productCode ===
          payload.dataInfoProduct.productCode
            ? payload
            : product,
        ),
      }

    case DELETE_PRODUCT_CART:
      return {
        ...state,
        listProduct: state.listProduct.filter(
          (product) => product.dataInfoProduct.index !== payload,
        ),
      }

    case RESET_PRODUCT_CART:
      return {
        ...state,
        listProduct: [payload.itemCart],
      }

    case CLEAR_PRODUCT_CART:
      return {
        ...state,
        ...initialStateClearProduct,
      }

    case SET_DATA_STORE:
      return {
        ...state,
        dataStore: payload,
      }

    case SET_INDEX_PRODUCT_CART:
      let listProductTmp = state.listProduct
      listProductTmp.forEach((item, i) => {
        item.dataInfoProduct.index = i
      })
      return {
        ...state,
        listProduct: listProductTmp,
      }

    case UPDATE_PRODUCT_CART:
      return {
        ...state,
        listProduct: checkRepeatProductDataCart(
          state.listProduct.map((item) => {
            if (
              item.dataInfoProduct.productId ===
              payload.dataInfoProduct.productId
            ) {
              if (
                item.dataInfoProduct.index === payload.dataInfoProduct.index
              ) {
                return payload
              } else {
                return item
              }
            } else {
              return item
            }
          }),
        ),
      }

    case SET_LIST_COUPON_SUGGEST_FOR_STORE:
      var storeId = payload.storeId || state?.listCouponSuggest?.storeId || '',
        merchantId =
          payload.merchantId || state?.listCouponSuggest?.merchantId || ''
      var arrMaster = payload
      arrMaster.storeId = storeId
      arrMaster.merchantId = merchantId
      return {
        ...state,
        listCouponSuggest: arrMaster,
      }

    case UPDATE_LIST_COUPON_SUGGEST_FOR_STORE:
      var storeId = state?.listCouponSuggest?.storeId || '',
        merchantId = state?.listCouponSuggest?.merchantId || ''
      var arrMaster =
        state?.listCouponSuggest && state.listCouponSuggest?.length > 0
          ? state.listCouponSuggest.map((coupon, index) => {
              if (coupon.couponId === payload.couponId) {
                coupon.isAllocated = true
                coupon.transactionCode = payload.transactionCode
              }
              return coupon
            })
          : [{ ...payload, transactionCode: payload.transactionCode }]
      arrMaster.storeId = storeId
      arrMaster.merchantId = merchantId
      return {
        ...state,
        listCouponSuggest: arrMaster,
      }

    case SAVE_CURRENT_STORE_ID:
      return {
        ...state,
        currentStoreId: payload,
      }
    case GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        listProductsByMerchant: action.balance,
        listProductsByMerchantOrigin: action.dataOrigin,
      }
    case CHECK_PRODUCT_OUT_OF_STOCK_SUCCESS:
      return {
        ...state,
        listOutOfStockProducts: action?.balance || [],
      }

    case UPDATE_LIST_PRODUCT_CART:
      return {
        ...state,
        listProduct: [].concat(payload),
      }

    case SET_CREATE_NEW_LIST_DELIVERY: {
      return {
        ...state,
        checkCreateNewDelivery: payload,
      }
    }
    case CLEAR_PRODUCT_OF_STORE: {
      return {
        ...state,
      }
    }
    default:
      return state
  }
}

export default smartMenuReducer
