import {END_POINTS} from '@shared/constants/apiUrl';
import {APIUtils} from 'utils';

export function getDetailInfoPackageApi(payload) {
  return APIUtils.get(END_POINTS.GET_PACKAGE_DETAIL_NEWS + `/${payload}`);
}

export function getListRecommendWithPackageApi(payload) {
  return APIUtils.get(
    END_POINTS.GET_LIST_RECOMMEND_WITH_PACKAGE +
      `?skip=${payload.skip}&take=${payload.take}&NewsPaperId=${payload.newspaperId}&MerchantId=${payload.merchantId}`,
  );
}
