import { LOGOUT_REQUEST } from '@shared/redux/account/constants'
import produce from 'immer'
import {
  RECENT_SUCCESS,
  RECENT_FAILED,
  SEARCH_SUCCESS,
  SEARCH_FAILED,
  CLEAR_ERRORS,
  CLEAR_LIST,
  SEARCH_HASHTAG_SUCCESS,
  SEARCH_HASHTAG_FAILED,
  CLEAR_LIST_HASHTAG,
  SEARCH_HASHTAG_REQUEST,
  SEARCH_REQUEST,
  HOTKEY_REQUEST,
  HOTKEY_SUCCESS,
  HOTKEY_FAILED,
  SEARCH_PERSONAL_REQUEST,
  SEARCH_PERSONAL_SUCCESS,
  SEARCH_PERSONAL_FAILED,
} from './types'

const initialState = {
  errors: null,
  listRecent: [],
  searchVideos: [],
  searchHashTags: [],
  searchPersonal: [],
  keywords: {},
  isRefresh: false,
}

const searchReducer = produce((draft = initialState, action) => {
  const { payload } = action
  const refresh = action?.payload?.isRefresh
  switch (action.type) {
    case RECENT_SUCCESS:
      return {
        ...draft,
        errors: null,
        listRecent: payload,
      }

    case RECENT_FAILED:
      return {
        ...draft,
        errors: payload,
      }

    case SEARCH_REQUEST:
      return {
        ...draft,
        isRefresh: refresh,
      }

    case SEARCH_SUCCESS:
      return {
        ...draft,
        errors: null,
        searchVideos: payload,
        isRefresh: false,
      }

    case SEARCH_FAILED:
      return {
        ...draft,
        errors: payload,
        isRefresh: false,
      }

    case SEARCH_HASHTAG_REQUEST:
      return {
        ...draft,
        isRefresh: refresh,
      }

    case SEARCH_HASHTAG_SUCCESS:
      return {
        ...draft,
        errors: null,
        searchHashTags: payload,
        isRefresh: false,
      }

    case SEARCH_HASHTAG_FAILED:
      return {
        ...draft,
        errors: payload,
        isRefresh: false,
      }

    case SEARCH_PERSONAL_REQUEST:
      return {
        ...draft,
        isRefresh: refresh,
      }

    case SEARCH_PERSONAL_SUCCESS:
      return {
        ...draft,
        errors: null,
        searchPersonal: payload,
        isRefresh: false,
      }

    case SEARCH_PERSONAL_FAILED:
      return {
        ...draft,
        errors: payload,
        isRefresh: false,
      }

    case HOTKEY_REQUEST:
      return {
        ...draft,
        isRefresh: refresh,
      }

    case HOTKEY_SUCCESS:
      return {
        ...draft,
        errors: null,
        keywords: payload,
        isRefresh: false,
      }

    case HOTKEY_FAILED:
      return {
        ...draft,
        errors: payload,
        isRefresh: false,
      }
    case CLEAR_ERRORS:
      return { ...draft, errors: null }

    case CLEAR_LIST:
      return { ...draft, searchVideos: [] }
    case CLEAR_LIST_HASHTAG:
      return { ...draft, searchHashTags: [] }
    case LOGOUT_REQUEST: {
      // TODO: Lưu ý
      // setStorageByKey('searchHistory', []);
      return {
        ...initialState,
      }
    }
    default:
      return draft
  }
})

export default searchReducer
