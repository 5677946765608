import {
  GET_LIST_PRODUCT_BY_CATEGORY_SUCCESS,
  GET_LIST_CATEGORY_SUCCESS,
} from './constants';

const initialState = {
  listProduct: [],
  listCategory: [],
};

const marketProduct = function (state = initialState, action) {
  switch (action.type) {
    // mobile card
    case GET_LIST_PRODUCT_BY_CATEGORY_SUCCESS: {
      return {
        ...state,
        listProduct: action.data,
      };
    }

    case GET_LIST_CATEGORY_SUCCESS: {
      return {
        ...state,
        listCategory: action.data,
      };
    }

    default:
      return state;
  }
};

export default marketProduct;
