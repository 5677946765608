/**
 *
 * @param {string} openTime e.g 07:30
 * @param {string} closeTime e.g 22h15
 * @returns
 */
export const isOpening = (openTime, closeTime) => {
  if (!openTime || !closeTime) {
    return 2; // không tìm thấy data giờ đóng/mở cửa của ngày hiện tại -> hiện tại đã qua ngày mở cửa
  }

  const openHour = Number(String(openTime).split(":")?.[0]);
  const openMinute = Number(String(openTime).split(":")?.[1]);
  const closeHour = Number(String(closeTime).split(":")?.[0]);
  const closeMinute = Number(String(closeTime).split(":")?.[1]);
  const now = new Date();
  const totalMinutesTillNow = now.getHours() * 60 + now.getMinutes();
  const totalMinutesTillOpenTime = openHour * 60 + openMinute;
  const totalMinutesTillCloseTime = closeHour * 60 + closeMinute;

  if (totalMinutesTillNow < totalMinutesTillOpenTime) {
    return 0; // 0: trước khi quán mở
  }

  if (
    totalMinutesTillNow >= totalMinutesTillOpenTime &&
    totalMinutesTillNow <= totalMinutesTillCloseTime
  ) {
    return 1; // 1: sau khi quán mở và trước khi quán đóng (đang mở cửa)
  }

  if (totalMinutesTillNow > totalMinutesTillCloseTime) {
    return 2; // 2: sau khi quán đóng cửa
  }
};

/**
 * [1,2,3,4,5,6,7] ~ ['Sunday', 'Monday', 'TuesDay', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
 * @returns A number presents for current week day by format of BE response
 */
const getCurrentWeekDay = () => {
  return new Date(Date.now()).getDay() + 1;
};

const VI_WEEKDAY_NAMES = [
  "",
  "Chủ Nhật",
  "Thứ Hai",
  "Thứ Ba",
  "Thứ Tư",
  "Thứ Năm",
  "Thứ Sáu",
  "Thứ Bảy",
];

/**
 *
 * @param opening 0:
 * @param currentOpeningData
 * @param nextOpeningData
 * @returns
 */
const genOpeningTimeMessage = ({
  opening,
  currentOpeningData,
  nextOpeningData,
  weekDayPropName,
  fromPropName,
  toPropName,
}) => {
  // trước
  if (opening === 0) {
    if (currentOpeningData) {
      return {
        comebackTime: `${currentOpeningData[fromPropName]} hôm nay`,
      };
    }
  }

  // đang mở
  if (opening === 1) {
    return {
      currentOpeningTimeRange: `${currentOpeningData[fromPropName]} - ${currentOpeningData[toPropName]}`,
    };
  }

  // sau
  if (opening === 2) {
    if (Number(nextOpeningData[weekDayPropName]) - getCurrentWeekDay() === 1) {
      return {
        comebackTime: `${nextOpeningData[fromPropName]} ngày mai`,
      };
    }

    return {
      comebackTime: `${nextOpeningData[fromPropName]} ${
        VI_WEEKDAY_NAMES[nextOpeningData[weekDayPropName]]
      }`,
    };
  }
};

/**
 *
 * @param {object} openingTimes An array contains opening times info in a week
 * @returns
 */
export const getOpeningTimeInfoOfToday = (
  openingTimes,
  weekDayPropName = "WeekDay",
  fromPropName = "From",
  toPropName = "To"
) => {
  if (!openingTimes) {
    return null;
  }

  if (!openingTimes?.length > 0) {
    // when store owner not set the openingTimes
    // ->  assume that the store is always open
    return {
      status: 1,
      data: null,
      msg: "No data, assume that the store is always open.",
    };
  }

  const sortedOpeningTimes = openingTimes
    .slice()
    .sort(
      (first, second) =>
        Number(first?.[weekDayPropName]) - Number(second?.[weekDayPropName])
    );

  const currentWeekDay = getCurrentWeekDay();
  let nextOpeningData;
  let lastOpeningData;
  let found;

  for (let i = 0; i < sortedOpeningTimes.length; i++) {
    lastOpeningData = sortedOpeningTimes[i];
    nextOpeningData = sortedOpeningTimes[(i + 1) % sortedOpeningTimes.length];

    if (Number(sortedOpeningTimes[i]?.[weekDayPropName]) === currentWeekDay) {
      found = sortedOpeningTimes[i];
      break;
    }

    if (Number(sortedOpeningTimes[i]?.[weekDayPropName]) > currentWeekDay) {
      // nếu chạy qua khỏi vị trí mong đợi
      // vị trí hiện tại (lastOpeningData) chính là vị trí tiếp theo cần tìm
      // vậy, next lúc này bước quá 1 đơn vị
      // => lùi lại 1 bước;

      nextOpeningData = lastOpeningData;
      lastOpeningData = sortedOpeningTimes[i - 1];
      break;
    }
  }

  const openingStatus = isOpening(found?.[fromPropName], found?.[toPropName]);
  const res = genOpeningTimeMessage({
    opening: openingStatus,
    currentOpeningData: found,
    nextOpeningData,
    weekDayPropName,
    fromPropName,
    toPropName,
  });

  if (found) {
    return {
      status: openingStatus,
      //   data: found, // found
      nextOpeningData, // nextOfFound
      currentOpeningTimeRange: res?.currentOpeningTimeRange,
      comebackTime: res?.comebackTime,
    };
  }

  return {
    status: openingStatus,
    nextOpeningData,
    currentOpeningTimeRange: res?.currentOpeningTimeRange,
    comebackTime: res?.comebackTime,
  };
};
