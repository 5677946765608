import React from "react";
import { useHistory } from "react-router-dom";
import { ORDER_TYPES } from "constants/types";

import classNames from "classnames";

// import { checkIfErrorExisted } from "utils/checkIfErrorExisted";
// import { checkIfCouponErrorsExisted } from "utils/checkIfErrorsExistedWithPageType";
// import { toVND } from "utils/currencyFormat";

import SVG from "assets/svg";

// import { CSpinner } from "@coreui/react";
// import { TYPE } from "../../store/constants";

import "./AppliedCoupon.scss";

function AppliedCoupon(props) {
  const { appliedCoupon, handleRemovePromo, isErrorCoupon } = props;
  const formatAmount = (amount) => {
    return `${amount?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}đ`;
  };

  const history = useHistory();

  // const { errors, discountAmount } = useSelector((state) => state[TYPE]);
  // const { CALC_COUPON_DISCOUNT_V2: isCalculating } = useSelector((state) => state[TYPE].isLoading);

  const handleClickAppliedCouponContainer = () => {
    if (!appliedCoupon?.name && !appliedCoupon?.couponName) {
      history.push("/promotionV2", {
        callbackUrl: `${window.location.pathname}${window.location.search}`,
        prevPage: `${window.location.pathname}${window.location.search}`,
        orderType: ORDER_TYPES.OrderFNB,
      });
    }
  };

  // const appliedCouponFailed = useMemo(() => {
  //   return checkIfCouponErrorsExisted(errors, TYPE);
  // }, [errors]);

  return (
    <div
      className="applied-coupon__container"
      onClick={handleClickAppliedCouponContainer}
    >
      <div
        className={classNames(
          "content-wrapper",
          isErrorCoupon ? "applied-coupon--failed" : "applied-coupon--succeeded"
        )}
      >
        {appliedCoupon?.name || appliedCoupon?.couponName ? (
          <>
            <p className="applied-coupon__name text-on-1-line">
              {appliedCoupon?.name || appliedCoupon?.couponName}
            </p>

            {/* {!appliedCouponFailed && (
              <span className="applied-coupon__discount-amount">
                {isCalculating ? <CSpinner component="span" size="sm" /> : `-${toVND(discountAmount)}`}
              </span>
            )} */}
            <div style={{ display: "flex" }}>
              {!isErrorCoupon && (
                <p className="applied-coupon__name text-on-1-line">{` -${formatAmount(
                  props.discount
                )}`}</p>
              )}
              <button
                type="button"
                className="btn-remove-coupon"
                onClick={handleRemovePromo}
                style={{ backgroundColor: "transparent" }}
              >
                <img src={SVG.BtnRemove} />
              </button>
            </div>
          </>
        ) : (
          <div className="button-suggest-add-coupon">
            <p className="btn-add-coupon">Thêm mã khuyến mãi</p>
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.0619 5.06247H6.93704V0.937647C6.93704 0.419967 6.51732 0 5.9994 0C5.48148 0 5.06175 0.419727 5.06175 0.937647V5.06247H0.937406C0.419727 5.06247 0 5.4822 0 6.00012C0 6.51804 0.419486 6.93753 0.937406 6.93753H5.06223V11.0624C5.06223 11.58 5.48196 12 5.99988 12C6.5178 12 6.93753 11.5803 6.93753 11.0624V6.93753H11.0624C11.5803 6.93753 12 6.5178 12 6.00012C12 5.48244 11.5798 5.06247 11.0619 5.06247Z"
                fill="#58CA30"
              />
            </svg>
          </div>
        )}
      </div>
    </div>
  );
}

export default AppliedCoupon;
