import { SELECTED_LOCATION_MODE } from '@shared/constants/common'
import {
  SET_CURRENT_LOCATION,
  SET_MARK_POSITION,
  SET_SELECTED_PROVINCE,
  UPDATE_CURRENT_LOCATION,
  CLEAR_MARK_POSITION,
} from './constants'

const initialState = {
  currentLocation: {},
  province: {},
  selectedLocation: {},
  selectedLocationMode: SELECTED_LOCATION_MODE.GPS,
  markPosition: {},
}

const gps = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_LOCATION: {
      return {
        ...state,
        province: {},
        currentLocation: action.currentLocation,
        selectedLocation: action.currentLocation,
        selectedLocationMode: action.currentLocation
          ? SELECTED_LOCATION_MODE.GPS
          : state.selectedLocationMode,
      }
    }
    case UPDATE_CURRENT_LOCATION: {
      return {
        ...state,
        currentLocation: action.currentLocation,
      }
    }
    case SET_SELECTED_PROVINCE: {
      return {
        ...state,
        province: action.province,
        selectedLocation: action.province,
        selectedLocationMode: action.province
          ? SELECTED_LOCATION_MODE.CITY
          : state.selectedLocationMode,
      }
    }
    case SET_MARK_POSITION: {
      return {
        ...state,
        markPosition: {
          pickupAddress: action.payload.pickupAddress,
          pickupLocation: action.payload.pickupLocation,
        },
      }
    }
    case CLEAR_MARK_POSITION: {
      return {
        ...state,
        markPosition: {},
      }
    }
    default:
      return state
  }
}

export default gps
