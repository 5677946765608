import {APIUtils} from 'utils';
import {END_POINTS} from '@shared/constants/apiUrl';

export function getStoreInfoByIdApi({storeId}) {
  const url = END_POINTS.GET_INFO_STORE_URL + storeId;
  return APIUtils.get(url);
}

export function getProductSuggestByUserApi({storeId, merchantId}) {
  const url =
    END_POINTS.GET_PRODUCT_SUGGEST_BY_USER +
    '?storeid=' +
    storeId +
    '&merchantid=' +
    merchantId;
  return APIUtils.get(url);
}
