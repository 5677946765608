import { call, put, takeLatest, fork, takeEvery } from "redux-saga/effects";
import actions from "./actions";
import * as TYPE from "./constants";
import api from "../api/api";

const sagas = {
  // * GET_VOUCHER_INFO
  *[TYPE.GET_VOUCHER_INFO.request](action) {
    try {
      const data = yield call(
        api[TYPE.GET_VOUCHER_INFO.request],
        action.payload
      );
      if (data.status === 200) {
        yield put(actions.GET_VOUCHER_INFO.success(data?.data));
      } else {
        yield put(actions.GET_VOUCHER_INFO.failure(data?.data));
      }
    } catch (error) {
      yield put(actions.GET_VOUCHER_INFO.failure(error?.data));
    }
  },

  // * GET_SUGGESTED_COUPON_LIST
  *[TYPE.GET_SUGGESTED_COUPON_LIST.request](action) {
    try {
      const data = yield call(
        api[TYPE.GET_SUGGESTED_COUPON_LIST.request],
        action.payload
      );

      if (data.status === 200) {
        yield put(
          actions.GET_SUGGESTED_COUPON_LIST.success(
            data.data.suggestedCouponDetails
          )
        );
      } else {
        yield put(actions.GET_SUGGESTED_COUPON_LIST.failure(data?.data));
      }
    } catch (error) {
      yield put(actions.GET_SUGGESTED_COUPON_LIST.failure(error?.data));
    }
  },

  // * SAVE_SUGGESTED_COUPON
  *[TYPE.SAVE_SUGGESTED_COUPON.request](action) {
    const { code, onSuccess, onFailure } = action.payload;

    try {
      const response = yield call(api[TYPE.SAVE_SUGGESTED_COUPON.request], {
        code,
      });
      if (response.status === 200) {
        onSuccess?.();
        yield put(actions.SAVE_SUGGESTED_COUPON.success(action.payload));
      } else {
        onFailure?.(response?.data?.message);
        yield put(actions.SAVE_SUGGESTED_COUPON.failure(response?.data));
      }
    } catch (error) {
      onFailure?.(error?.data?.message);
      yield put(actions.SAVE_SUGGESTED_COUPON.failure(error?.data));
    }
  },

  // * GET_SHORT_LINK_TO_SHARE
  *[TYPE.GET_SHORT_LINK_TO_SHARE.request](action) {
    const { url, callbackFunction } = action.payload;

    try {
      const response = yield call(api[TYPE.GET_SHORT_LINK_TO_SHARE.request], {
        url,
      });

      if (response.status === 200) {
        yield put(actions.GET_SHORT_LINK_TO_SHARE.success(response.data));
        yield call(callbackFunction, response.data.url);
      } else {
        yield put(actions.GET_SHORT_LINK_TO_SHARE.failure(response?.data));
      }
    } catch (error) {
      yield put(actions.GET_SHORT_LINK_TO_SHARE.failure(error?.data));
    }
  },

  // * CHECK_STOCK_OF_PRODUCT
  *[TYPE.CHECK_STOCK_OF_PRODUCT.request](action) {
    const { productCode } = action.payload;
    try {
      const response = yield call(api[TYPE.CHECK_STOCK_OF_PRODUCT.request], {
        productCode,
      });

      if (response.status === 200) {
        if (typeof response.data.isAvailable === "boolean") {
          yield put(
            actions.CHECK_STOCK_OF_PRODUCT.success({
              isAvailable: response.data.isAvailable,
              productCode,
            })
          );
        }
      } else {
        yield put(actions.CHECK_STOCK_OF_PRODUCT.failure(response?.data));
      }
    } catch (error) {
      yield put(actions.CHECK_STOCK_OF_PRODUCT.failure(error?.data));
    }
  },

  // ----
  // *[TYPE.GET_DETAIL_VOUCHER_MORE.request](action) {
  //   try {
  //     const data = yield call(
  //       api[TYPE.GET_DETAIL_VOUCHER_MORE.request],
  //       action.payload
  //     );
  //     if (data.status === 200) {
  //       yield put(actions.GET_DETAIL_VOUCHER_MORE.success(data.data.value));
  //     } else {
  //       yield put(actions.GET_DETAIL_VOUCHER_MORE.failure(data?.data));
  //     }
  //   } catch (error) {
  //     yield put(actions.GET_DETAIL_VOUCHER_MORE.failure(error?.data));
  //   }
  // },
};

function* watchedSaga() {
  // * GET_VOUCHER_INFO
  yield takeLatest(
    TYPE.GET_VOUCHER_INFO.request,
    sagas[TYPE.GET_VOUCHER_INFO.request]
  );

  // * GET_SUGGESTED_COUPON_LIST
  yield takeLatest(
    TYPE.GET_SUGGESTED_COUPON_LIST.request,
    sagas[TYPE.GET_SUGGESTED_COUPON_LIST.request]
  );

  // * SAVE_SUGGESTED_COUPON
  yield takeEvery(
    TYPE.SAVE_SUGGESTED_COUPON.request,
    sagas[TYPE.SAVE_SUGGESTED_COUPON.request]
  );

  // * GET_SHORT_LINK_TO_SHARE
  yield takeLatest(
    TYPE.GET_SHORT_LINK_TO_SHARE.request,
    sagas[TYPE.GET_SHORT_LINK_TO_SHARE.request]
  );

  // * CHECK_STOCK_OF_PRODUCT
  yield takeEvery(
    TYPE.CHECK_STOCK_OF_PRODUCT.request,
    sagas[TYPE.CHECK_STOCK_OF_PRODUCT.request]
  );

  // * GET_CHECK_VOUCHER
  // yield takeLatest(
  //   TYPE.GET_DETAIL_VOUCHER_MORE.request,
  //   sagas[TYPE.GET_DETAIL_VOUCHER_MORE.request]
  // );
}

export default function* rootChild() {
  yield fork(watchedSaga);
}
