import { callApi } from "helpers/callApi";
import { fork, put, takeEvery, takeLatest } from "redux-saga/effects";
import {
  GET_BUDGET_INFO_REQUEST,
  GET_LIST_BUDGET_REQUEST,
  GET_HISTORY_BUDGET_REQUEST,
  CONFIRM_ADVANCE_REQUEST,
  REVOCATION_BUDGET_REQUEST,
  GET_TRANSACTION_HISTORY_UB_REQUEST,
} from "./constants";
import {
  getListBudgetSuccess,
  getListBudgetFailed,
  getBudgetInfoSuccess,
  getBudgetInfoFailed,
  getHistoryBudgetSuccess,
  getHistoryBudgetFailed,
  confirmAdvanceSuccess,
  confirmAdvanceFailed,
  getHistoryTransactionUBSuccess,
  getHistoryTransactionUBFailed,
  revocationBudgetSuccess,
  revocationBudgetFailed,
} from "./actions";
import {
  getListBudgetApi,
  getBudgetInfoApi,
  getHistoryBudgetApi,
  confirmAdvanceApi,
  revocationBudgetApi,
} from "@shared/services/api/budget";

export function* getListBudgetSaga(obj) {
  const { payload, onSuccess, onFailed } = obj;
  try {
    const res = yield callApi(getListBudgetApi, payload);
    if (res?.data.length > 0) {
      res.data.forEach((item) => {
        if (item.status === 1) {
          item.isExpired = true;
        } else {
          item.isExpired = false;
        }
      });
      res.data = res.data.sort(function (a, b) {
        return b.isExpired - a.isExpired;
      });
    }
    yield put(getListBudgetSuccess(res?.data || []));
    if (typeof onSuccess === "function") {
      onSuccess(res);
    }
  } catch (err) {
    if (typeof onFailed === "function") {
      onFailed(err);
    }
    yield put(getListBudgetFailed(err));
  }
}

export function* getBudgetInfoSaga(obj) {
  const { payload, onSuccess, onFailed } = obj;
  try {
    const res = yield callApi(getBudgetInfoApi, payload);
    yield put(getBudgetInfoSuccess(res));
    if (typeof onSuccess === "function") {
      onSuccess(res);
    }
  } catch (err) {
    if (typeof onFailed === "function") {
      onFailed(err);
    }
    yield put(getBudgetInfoFailed(err));
  }
}

export function* getHistoryBudgetSaga(obj) {
  const { payload, onSuccess, onFailed } = obj;
  try {
    const res = yield callApi(getHistoryBudgetApi, payload);

    yield put(
      getHistoryBudgetSuccess({
        skip: payload.skipCount + 10 || 0,
        data: res.data,
        isLoadNew: payload.isLoadNew,
      })
    );

    if (typeof onSuccess === "function") {
      onSuccess(res);
    }
  } catch (err) {
    if (typeof onFailed === "function") {
      onFailed(err);
    }
    yield put(getHistoryBudgetFailed(err));
  }
}

export function* getHistoryTransactionUBSaga(obj) {
  const { payload, onSuccess, onFailed } = obj;
  try {
    const res = yield callApi(getHistoryBudgetApi, payload);
    yield put(
      getHistoryTransactionUBSuccess({
        skip: payload.skipCount + 10 || 0,
        data: res.data,
        isLoadNew: payload.isLoadNew,
      })
    );
    if (typeof onSuccess === "function") {
      onSuccess(res);
    }
  } catch (err) {
    if (typeof onFailed === "function") {
      onFailed(err);
    }
    yield put(getHistoryTransactionUBFailed(err));
  }
}

export function* confirmAdvanceSaga(obj) {
  const { payload, onSuccess, onError } = obj;
  try {
    const res = yield callApi(confirmAdvanceApi, payload);
    yield put(confirmAdvanceSuccess(res));
    if (typeof onSuccess === "function") {
      onSuccess(res);
    }
  } catch (err) {
    if (typeof onError === "function") {
      onError(err?.data);
    }
    yield put(confirmAdvanceFailed(err));
  }
}

export function* revocationBudgetSaga(obj) {
  const { payload, onSuccess, onError } = obj;
  try {
    const res = yield callApi(revocationBudgetApi, payload);
    yield put(revocationBudgetSuccess(res));
    if (typeof onSuccess === "function") {
      onSuccess(res);
    }
  } catch (err) {
    if (typeof onError === "function") {
      onError(err?.data);
    }
    yield put(revocationBudgetFailed(err));
  }
}

function* watchCommon() {
  yield takeLatest(GET_LIST_BUDGET_REQUEST, getListBudgetSaga);
  yield takeLatest(GET_BUDGET_INFO_REQUEST, getBudgetInfoSaga);
  yield takeLatest(GET_HISTORY_BUDGET_REQUEST, getHistoryBudgetSaga);
  yield takeLatest(
    GET_TRANSACTION_HISTORY_UB_REQUEST,
    getHistoryTransactionUBSaga
  );
  yield takeLatest(CONFIRM_ADVANCE_REQUEST, confirmAdvanceSaga);
  yield takeLatest(REVOCATION_BUDGET_REQUEST, revocationBudgetSaga);
}

export default function* rootChild() {
  yield fork(watchCommon);
}
