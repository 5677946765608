import {
  channelListSuccess,
  channelListFailure,
  channelDetailSuccess,
  channelDetailFailure,
} from './actions'
import { CHANNEL_LIST_REQUEST, CHANNEL_DETAIL_REQUEST } from './types'
import { call, put, takeLatest, fork } from 'redux-saga/effects'
import { handleResponseVideoApi } from '@shared/utils/common'

const data = [
  {
    title: 'Pao quan - Ngõ 62 Trần Thái Tông',
    view: 1000,
    image: 'https://www.gstatic.com/webp/gallery/1.png',
    list: [
      { image: 'https://www.gstatic.com/webp/gallery/1.png' },
      { image: 'https://www.gstatic.com/webp/gallery/2.png' },
      { image: 'https://www.gstatic.com/webp/gallery/3.png' },
      { image: 'https://www.gstatic.com/webp/gallery/4.png' },
      { image: 'https://www.gstatic.com/webp/gallery/5.png' },
      { image: 'https://www.gstatic.com/webp/gallery/1.png' },
      { image: 'https://www.gstatic.com/webp/gallery/2.png' },
      { image: 'https://www.gstatic.com/webp/gallery/1.png' },
      { image: 'https://www.gstatic.com/webp/gallery/2.png' },
      { image: 'https://www.gstatic.com/webp/gallery/3.png' },
      { image: 'https://www.gstatic.com/webp/gallery/4.png' },
    ],
  },
  {
    title: 'Chuỗi hải sản biển đông - dư vị biển khơi giữa phố thị Việt Nam',
    view: 10000,
    image: 'https://www.gstatic.com/webp/gallery/1.png',
    list: [
      { image: 'https://www.gstatic.com/webp/gallery/1.png' },
      { image: 'https://www.gstatic.com/webp/gallery/2.png' },
      { image: 'https://www.gstatic.com/webp/gallery/3.png' },
      { image: 'https://www.gstatic.com/webp/gallery/4.png' },
      { image: 'https://www.gstatic.com/webp/gallery/5.png' },
    ],
  },
  {
    title: 'Chuỗi phương nam',
    view: 10000,
    image: 'https://www.gstatic.com/webp/gallery/1.png',
    list: [
      { image: 'https://www.gstatic.com/webp/gallery/1.png' },
      { image: 'https://www.gstatic.com/webp/gallery/2.png' },
      { image: 'https://www.gstatic.com/webp/gallery/3.png' },
      { image: 'https://www.gstatic.com/webp/gallery/4.png' },
      { image: 'https://www.gstatic.com/webp/gallery/5.png' },
    ],
  },
  {
    title: 'Quán ăn ngon- Hội tụ tinh túy',
    view: 10000,
    image: 'https://www.gstatic.com/webp/gallery/1.png',
    list: [
      { image: 'https://www.gstatic.com/webp/gallery/1.png' },
      { image: 'https://www.gstatic.com/webp/gallery/2.png' },
      { image: 'https://www.gstatic.com/webp/gallery/3.png' },
      { image: 'https://www.gstatic.com/webp/gallery/4.png' },
      { image: 'https://www.gstatic.com/webp/gallery/5.png' },
      { image: 'https://www.gstatic.com/webp/gallery/1.png' },
      { image: 'https://www.gstatic.com/webp/gallery/2.png' },
    ],
  },
]

const detail = {
  title: 'Chuỗi hải sản biển đông - dư vị biển khơi giữa phố thị Việt Nam',
  view: 10000,
  image: 'https://www.gstatic.com/webp/gallery/1.png',
  des: 'Có mặt tại TPCHM Có mặt tại TPCHM Có mặt tại TPCHM Có mặt tại TPCHM Có mặt tại TPCHM Có mặt tại TPCHM Có mặt tại TPCHM Có mặt tại TPCHM Có mặt tại TPCHM Có mặt tại TPCHM',
  list: [
    { image: 'https://www.gstatic.com/webp/gallery/1.png' },
    { image: 'https://www.gstatic.com/webp/gallery/2.png' },
    { image: 'https://www.gstatic.com/webp/gallery/3.png' },
    { image: 'https://www.gstatic.com/webp/gallery/4.png' },
    { image: 'https://www.gstatic.com/webp/gallery/5.png' },
    { image: 'https://www.gstatic.com/webp/gallery/1.png' },
    { image: 'https://www.gstatic.com/webp/gallery/2.png' },
    { image: 'https://www.gstatic.com/webp/gallery/1.png' },
    { image: 'https://www.gstatic.com/webp/gallery/2.png' },
    { image: 'https://www.gstatic.com/webp/gallery/3.png' },
    { image: 'https://www.gstatic.com/webp/gallery/4.png' },
  ],
}

function channelListApi() {
  return {
    data: data,
    status: 200,
  }
}

function channelDetailApi() {
  return {
    data: detail,
    status: 200,
  }
}

/**
 * Dispatch action success or failure
 * @param {*} obj params
 */
export function* channelList(obj) {
  try {
    const data = yield call(channelListApi, obj.payload)

    if (data.status === 200) {
      yield put(channelListSuccess(data.data))
    } else {
      yield put(channelListFailure(handleResponseVideoApi(data)))
    }
  } catch (err) {
    yield put(channelListFailure(handleResponseVideoApi(err)))
  }
}

export function* channelDetail(obj) {
  try {
    const data = yield call(channelDetailApi, obj.payload)

    if (data.status === 200) {
      yield put(channelDetailSuccess(data.data))
    } else {
      yield put(channelDetailFailure(handleResponseVideoApi(data)))
    }
  } catch (err) {
    yield put(channelDetailFailure(handleResponseVideoApi(err)))
  }
}

function* watchChannel() {
  yield takeLatest(CHANNEL_LIST_REQUEST, channelList)
  yield takeLatest(CHANNEL_DETAIL_REQUEST, channelDetail)
}

export default function* rootChild() {
  yield fork(watchChannel)
}
