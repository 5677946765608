import { LOGOUT_REQUEST } from "@shared/redux/account/constants";
import { SAVE_PREVIOUS_CURRENT_ROUTE, SAVE_LAST_ROUTE_FNB } from "./constants";

const initialState = {};

const historyRoute = (state = initialState, action) => {
  const { payload } = action;

  switch (action.type) {
    case SAVE_PREVIOUS_CURRENT_ROUTE: {
      return {
        ...state,
        historyRoute: {
          previousRouteName: action.payload.previousRouteName,
          currentRouteName: action.payload.currentRouteName,
        },
      };
    }

    case SAVE_LAST_ROUTE_FNB: {
      return {
        ...state,
        lastRouteFNB: action.payload,
      };
    }

    default:
      return state;
  }
};

export default historyRoute;
