import { addErrors, delErrors } from "utils/ErrorsGenerator";
import * as TYPE from "./constants";

const reducers = {
  // [TYPE.SEARCH_LIST.request]: (draft, { payload }) => {
  //   if (payload?.refresh) {
  //     return {
  //       ...draft,
  //       errors: delErrors(draft.errors, TYPE.SEARCH_LIST.failure),
  //       listFeed: {
  //         value: [],
  //         total: 0,
  //       },
  //       isLoading: {
  //         ...draft.isLoading,
  //         SEARCH_LIST: true,
  //       },
  //     };
  //   }

  //   return {
  //     ...draft,
  //     errors: delErrors(draft.errors, TYPE.SEARCH_LIST.failure),
  //     isLoading: {
  //       ...draft.isLoading,
  //       SEARCH_LIST: true,
  //     },
  //   };
  // },
  // [TYPE.SEARCH_LIST.success]: (draft, { payload }) => {
  //   return {
  //     ...draft,
  //     errors: delErrors(draft.errors, TYPE.SEARCH_LIST.failure),
  //     listFeed: {
  //       value: [...draft.listFeed.value, ...payload.value],
  //       total: payload?.[`@odata.count`],
  //     },
  //     isLoading: {
  //       ...draft.isLoading,
  //       SEARCH_LIST: false,
  //     },
  //   };
  // },
  // [TYPE.SEARCH_LIST.failure]: (draft, { payload }) => {
  //   return {
  //     ...draft,
  //     errors: addErrors(draft.errors, {
  //       type: TYPE.SEARCH_LIST.failure,
  //       msg: payload,
  //     }),
  //     isLoading: {
  //       ...draft.isLoading,
  //       SEARCH_LIST: false,
  //     },
  //   };
  // },

  // *** GET_LIST_FEED
  [TYPE.GET_LIST_FEED.request]: (draft) => {
    return {
      ...draft,
      isLoading: {
        ...draft.isLoading,
        GET_LIST_FEED: true,
      },
    };
  },
  [TYPE.GET_LIST_FEED.success]: (draft, { payload }) => {
    return {
      ...draft,
      errors: delErrors(draft.errors, TYPE.GET_LIST_FEED.failure),
      listFeed: payload,
      isLoading: {
        ...draft.isLoading,
        GET_LIST_FEED: false,
      },
    };
  },

  [TYPE.GET_LIST_FEED.failure]: (draft, { payload }) => {
    return {
      ...draft,
      errors: addErrors(draft.errors, {
        type: TYPE.GET_LIST_FEED.failure,
        msg: payload,
      }),
      isLoading: {
        ...draft.isLoading,
        GET_LIST_FEED: false,
      },
    };
  },

  // *** GET_LIST_STORE_OF_MERCHANT
  [TYPE.GET_LIST_STORE_OF_MERCHANT.request]: (draft, { payload }) => {
    const { merchantId } = payload;

    let { listStore, hasMore } = draft.listStoreOfSelectedMerchant;

    // reset data if select a another merchant
    if (draft.listStoreOfSelectedMerchant.merchantId !== merchantId) {
      listStore = [];
      hasMore = true;
    }

    return {
      ...draft,
      listStoreOfSelectedMerchant: {
        merchantId,
        listStore,
        hasMore,
      },
      isLoading: {
        ...draft.isLoading,
        GET_LIST_STORE_OF_MERCHANT: true,
      },
    };
  },
  [TYPE.GET_LIST_STORE_OF_MERCHANT.success]: (draft, { payload }) => {
    return {
      ...draft,
      listStoreOfSelectedMerchant: payload,
      errors: delErrors(draft.errors, TYPE.GET_LIST_STORE_OF_MERCHANT.failure),
      isLoading: {
        ...draft.isLoading,
        GET_LIST_STORE_OF_MERCHANT: false,
      },
    };
  },

  [TYPE.GET_LIST_STORE_OF_MERCHANT.failure]: (draft, { payload }) => {
    return {
      ...draft,
      errors: addErrors(draft.errors, {
        type: TYPE.GET_LIST_STORE_OF_MERCHANT.failure,
        msg: payload,
      }),
      isLoading: {
        ...draft.isLoading,
        GET_LIST_STORE_OF_MERCHANT: false,
      },
    };
  },
};

export default reducers;
