import { END_POINTS } from "@shared/constants/apiUrl";
import { APIUtils } from "utils";
import { store } from "@shared/redux/configureStore";
import moment from "moment";

export async function getListBudgetApi(userId) {
  return APIUtils.get(END_POINTS.GET_LIST_BUDGET, {
    headers: {
      "X-BusinessId": userId || "",
    },
  });
}

export async function getBudgetInfoApi({ budgetId, userId }) {
  let url = END_POINTS.GET_BUDGET_INFO + budgetId;
  return APIUtils.get(url, {
    headers: {
      "X-BusinessId": userId || "",
    },
  });
}

export function getHistoryBudgetApi({ budgetId, skipCount, userId }) {
  let url =
    END_POINTS.GET_HISTORY_TRANSACTION +
    "?BudgetId=" +
    budgetId +
    "&Skip=" +
    skipCount +
    "&Take=10";
  return APIUtils.get(url, {
    headers: {
      "X-BusinessId": userId || "",
    },
  });
}

export function confirmAdvanceApi(requestBody) {
  let requestData = requestBody.confirmData;
  requestData.ExpiryDate = moment(requestData.ExpiryDateTmp)
    .subtract(1, "day")
    .format("YYYY-MM-DDTHH:mm:ss");
  let url =
    END_POINTS.CONFIRM_ADVANCE_INFO + requestData.budgetId + "/Allocate";
  return APIUtils.post(url, {
    body: requestData,
    headers: {
      "X-BusinessId": requestBody.userId || "",
    },
  });
}

export function revocationBudgetApi({ budgetId, userId }) {
  let url = END_POINTS.REVOCATION_BUDGET_INFO + budgetId + "/Revoke";
  return APIUtils.post(url, {
    body: {},
    headers: {
      "X-BusinessId": userId || "",
    },
  });
}
