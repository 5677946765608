import {
  SET_CURRENT_LOCATION,
  SET_SELECTED_PROVINCE,
  UPDATE_CURRENT_LOCATION,
  SET_MARK_POSITION,
  CLEAR_MARK_POSITION,
} from './constants'

export const setSelectedLocation = (currentLocation) => ({
  type: SET_CURRENT_LOCATION,
  currentLocation,
})

export const updateCurrentLocation = (currentLocation) => ({
  type: UPDATE_CURRENT_LOCATION,
  currentLocation,
})

export const setSelectedProvince = (province) => ({
  type: SET_SELECTED_PROVINCE,
  province,
})

export const setMarkPosition = (payload) => ({
  type: SET_MARK_POSITION,
  payload,
})

export const clearMarkPosition = () => ({
  type: CLEAR_MARK_POSITION,
})
