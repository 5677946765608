import {UPDATE_LIST_RECEVER, CLEAR_DATA_REQUEST} from './constants';

const initialState = {
  listRecever: [],
};

const paymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_LIST_RECEVER: {
      // console.log('props.UPDATE_LIST_RECEVER');
      return {
        ...state,
        ...action.listRecever,
      };
    }
    case CLEAR_DATA_REQUEST: {
      return {
        ...initialState,
      };
    }
    default:
      return state;
  }
};

export default paymentReducer;
