export const listFeed = {
  isInit: true, // for check first time to call api
  value: [],
  hasMore: false,
};

export const listStoreOfSelectedMerchant = {
  merchantId: null,
  listStore: [],
  hasMore: false,
};

export const listShortcuts = [];
export const isLoading = {
  GET_LIST_CITY: false,
  GET_SHORTCUTS: false,
  // GET_LIST_VOUCHER: false, // ! deprecated
  FILTER_VOUCHER_STORE: false,
  GET_LIST_FEED: false,
  GET_LIST_STORE_OF_MERCHANT: false,
};
