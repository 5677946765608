import {
  hashtagListSuccess,
  hashtagListFailure,
  hashtagDetailSuccess,
  hashtagDetailFailure,
  hashtagInfoSuccess,
  hashtagInfoFailure,
  favoriteHashtagSuccess,
  favoriteHashtagFailure,
  disFavoriteHashtagSuccess,
  disFavoriteHashtagFailure,
} from './actions'
import {
  HASHTAG_LIST_REQUEST,
  HASHTAG_DETAIL_REQUEST,
  HASHTAG_INFO_REQUEST,
  FAVORITE_HASHTAG_REQUEST,
  DIS_FAVORITE_HASHTAG_REQUEST,
} from './types'
import { call, put, takeLatest, takeEvery, fork } from 'redux-saga/effects'
import { END_POINTS } from '@shared/constants/apiUrl'
import { APIUtils } from 'utils'

function fetchHashtagList({ skipCount, maxResult }) {
  const requestData = `?SkipCount=${skipCount}&MaxResult=${maxResult}`
  return APIUtils.get(END_POINTS.GetDiscoveryVideos + requestData)
}

function fetchHashtagDetail({ skipCount, maxResult, hashtagTitle }) {
  const params = {
    skipCount,
    maxResult,
    hashtagTitle: encodeURIComponent(hashtagTitle.trim()),
  }
  return APIUtils.get(apiLinks.GetVideosByHashtag, { params })
}

function fetchHashtagInfo({ hashtagTitle }) {
  const params = {
    hashtagTitle: encodeURIComponent(hashtagTitle.trim()),
  }
  return APIUtils.get(apiLinks.GetHashtagDetail, { params })
}

export function* hashtagList(obj) {
  try {
    const { onSuccess } = obj.payload
    const data = yield call(fetchHashtagList, obj.payload)
    if (Array.isArray(data)) {
      // delete data['httpStatus'];
      yield put(hashtagListSuccess([...obj.payload.list, ...data]))
      if (typeof onSuccess === 'function') {
        onSuccess(data)
      }
    } else {
      yield put(hashtagListFailure(data))
    }
  } catch (err) {
    yield put(hashtagListFailure(err))
  }
}

export function* hashtagDetail(obj) {
  try {
    const data = yield call(fetchHashtagDetail, obj.payload)
    if (data.status === 200 && data?.data?.length !== 0) {
      // delete data['httpStatus'];
      yield put(hashtagDetailSuccess([...obj.payload.list, ...data.data]))
    } else {
      yield put(hashtagDetailFailure(data))
    }
  } catch (err) {
    yield put(hashtagDetailFailure(err))
  }
}

export function* hashtagInfo(obj) {
  try {
    const data = yield call(fetchHashtagInfo, obj.payload)
    if (data.status === 200) {
      // delete data['httpStatus'];
      yield put(hashtagInfoSuccess(data.data))
    } else {
      yield put(hashtagInfoFailure(data))
    }
  } catch (err) {
    yield put(hashtagInfoFailure(err))
  }
}

function fetchFavoriteHashtag({ hashtagTitle }) {
  const params = { hashtagTitle: encodeURIComponent(hashtagTitle.trim()) }
  return APIUtils.post(apiLinks.FavoriteHashtag, { body: params })
}

export function* favoriteHashtag(obj) {
  const { onSuccess } = obj.payload
  try {
    const data = yield call(fetchFavoriteHashtag, obj.payload)
    if (data.status === 200) {
      // delete data['httpStatus'];
      yield put(favoriteHashtagSuccess(data.data))
      if (typeof onSuccess === 'function') {
        onSuccess(data.data)
      }
    } else {
      yield put(favoriteHashtagFailure(data))
    }
  } catch (err) {
    yield put(favoriteHashtagFailure(err))
  }
}

function fetchDisFavoriteHashtag({ hashtagTitle }) {
  const params = { hashtagTitle: encodeURIComponent(hashtagTitle.trim()) }
  return APIUtils.post(apiLinks.DisFavorHashtag, { body: params })
}

export function* disFavoriteHashtag(obj) {
  const { onSuccess } = obj.payload
  try {
    const data = yield call(fetchDisFavoriteHashtag, obj.payload)
    if (data.status === 200) {
      // delete data['httpStatus'];
      yield put(disFavoriteHashtagSuccess(data.data))
      if (typeof onSuccess === 'function') {
        onSuccess()
      }
    } else {
      yield put(disFavoriteHashtagFailure(data))
    }
  } catch (err) {
    yield put(disFavoriteHashtagFailure(err))
  }
}

function* watchHashtag() {
  yield takeEvery(HASHTAG_LIST_REQUEST, hashtagList)
  yield takeEvery(HASHTAG_DETAIL_REQUEST, hashtagDetail)
  yield takeEvery(HASHTAG_INFO_REQUEST, hashtagInfo)
  yield takeLatest(FAVORITE_HASHTAG_REQUEST, favoriteHashtag)
  yield takeLatest(DIS_FAVORITE_HASHTAG_REQUEST, disFavoriteHashtag)
}

export default function* rootChild() {
  yield fork(watchHashtag)
}
