import {CANCEL_ORDER_SUCCESS, CANCEL_PENDING_ITEM_SUCCESS} from './constants';
const initialState = {
  messageError: '',
  cancelOrder: {},
  cancelPendingItem: {},
};
const orderStatus = (state = initialState, action) => {
  switch (action.type) {
    case CANCEL_ORDER_SUCCESS: {
      return {
        ...state,

        cancelOrder: action.payload.data.data,
      };
    }
    case CANCEL_PENDING_ITEM_SUCCESS: {
      return {
        ...state,
        cancelPendingItem: action.payload.data.data,
      };
    }
    default:
      return state;
  }
};

export default orderStatus;
