import React from "react";
import { toVND } from "utils/currencyFormat";
import { FNB_OPTION_TYPES } from "constants/types";

const RadioOption = (props) => {
  const {
    type = FNB_OPTION_TYPES.SIZE, // FNB_OPTION_TYPES.SIZE || FNB_OPTION_TYPES.ATTRIBUTE
    code, // = `${attribute.name} - ${attribute.value}` || = topping.flatData.code || = size.code
    name,
    productPrice,
    extraPrice,
    checked = false,
    inputId,
    groupName,
    handleCheckOption,
  } = props;

  const handleOnChange = () => {
    if (type === FNB_OPTION_TYPES.SIZE) {
      handleCheckOption(type, { code, extraPrice, name })();
    }
    if (type === FNB_OPTION_TYPES.ATTRIBUTE) {
      handleCheckOption(type, {
        name: groupName,
        value: name,
      })();
    }
  };

  return (
    <div className="d-flex align-items-center">
      <label
        className="form-check-label d-flex justify-content-between w-100"
        htmlFor={inputId}
      >
        <span className="_fs-14px _fw-400">{name}</span>
        {extraPrice && (
          <span className="_fw-600 _fs-14px">
            {toVND(Number(productPrice) + Number(extraPrice))}
          </span>
        )}
      </label>
      <div className="form-check mb-0 ml-3">
        <input
          className="form-check-input"
          type="radio"
          name={groupName}
          id={inputId}
          checked={checked}
          onChange={handleOnChange}
        />
      </div>
    </div>
  );
};

export default RadioOption;
