import request from "services/request";
import { encodeQueryData } from "utils/stringFormat";
import * as TYPE from "../store/constants";

const api = {
  [TYPE.ORDER_HISTORY_DETAIL.request]: async (data) => {
    const params = encodeQueryData(data);
    return request(`Order/GetOrderDetailByUser${params}`, "GET");
  },
  [TYPE.CANCEL_VOUCHER.request]: async (data) => {
    return request("Order/CancelOrder", "POST", data);
  },
  [TYPE.GET_PAYMENT_METHODS.request]: async () => {
    return request(`cms/core/paymentmethods`, "GET");
  },
  [TYPE.GET_WHOLESALE_LIST.request]: async () => {
    return request(`/cms/wholesale`, "GET", null);
  },
};

export default api;
