import moment from 'moment';

export const listCouponForVoucherParser = data => {
  console.log('LIST COUPON: ', data);
  if (data) {
    const newArray = data.data.map(item => {
      return {
        createdAt: item.createdAt,
        expiredDay: item.expiredDay,
        imageUrl: item.imageUrl,
        logoUrl: item.logoUrl,
        couponCode: item.productCode,
        couponName: item.productName,
        couponId: item.refId,
        transactionCode: item.transactionCode,
        voucherCount: item.voucherCount,
        voucherType: item.voucherType,
      };
    });
    return newArray;
  }
  return [];
};

export const couponDetailForVoucherParser = data => {
  console.log(data);
  if (data?.data?.length > 0) {
    return {
      createdAt: data.data[0].createdAt,
      expiredDay: data.data[0].expiredDay,
      imageUrl: data.data[0].imageUrl,
      logoUrl: data.data[0].logoUrl,
      productCode: data.data[0].productCode,
      productId: data.data[0].productId,
      productName: data.data[0].productName,
      transactionCode: data.data[0].transactionCode,
      voucherCount: data.data[0].voucherCount,
      voucherType: data.data[0].voucherType,
    };
  }
  return [];
};

export const searchCouponDetailForVoucherParser = data => {
  console.log(data);
  return {
    createdAt: data.data.createdAt,
    expiredDay: data.data.endDateTime,
    imageUrl: data.data.imageUrl,
    logoUrl: data.data.logoUrl,
    couponCode: data.data.code,
    couponId: data.data.refId,
    couponName: data.data.name,
    voucherCount: 0,
    // voucherType: data.data.voucherType,
  };
};

export const getAvailableCouponParser = data => {
  console.log(data);
  return data.data.coupons;
};
