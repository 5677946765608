import {
  GET_LIST_COUPON_FOR_VOUCHER_REQUEST,
  GET_LIST_COUPON_FOR_VOUCHER_SUCCESS,
  GET_LIST_COUPON_FOR_VOUCHER_FAILED,
  GET_COUPON_DETAIL_FOR_VOUCHER_REQUEST,
  GET_COUPON_DETAIL_FOR_VOUCHER_SUCCESS,
  GET_COUPON_DETAIL_FOR_VOUCHER_FAILED,
  SEARCH_COUPON_DETAIL_FOR_VOUCHER_REQUEST,
  SEARCH_COUPON_DETAIL_FOR_VOUCHER_SUCCESS,
  SEARCH_COUPON_DETAIL_FOR_VOUCHER_FAILED,
  GET_AVAILABLE_COUPON_REQUEST,
  GET_AVAILABLE_COUPON_SUCCESS,
  GET_AVAILABLE_COUPON_FAILED,
  DATA_COUPON_SELECTED,
  CLEAR_DATA_COUPON_LIST,
} from './constants';
//Action get list
export const getListCouponForVoucherHandle = (
  payload,
  onSuccess,
  onFailed,
) => ({
  type: GET_LIST_COUPON_FOR_VOUCHER_REQUEST,
  payload,
  onSuccess,
  onFailed,
});

export const getListCouponForVoucherSuccess = couponForVoucher => ({
  type: GET_LIST_COUPON_FOR_VOUCHER_SUCCESS,
  couponForVoucher,
});

export const getListCouponForVoucherFailed = error => ({
  type: GET_LIST_COUPON_FOR_VOUCHER_FAILED,
  error,
});

//Action get detail
export const getCouponDetailForVoucherHandle = (
  payload = {},
  onSuccess,
  onFailed,
) => ({
  type: GET_COUPON_DETAIL_FOR_VOUCHER_REQUEST,
  payload,
  onSuccess,
  onFailed,
});

export const getCouponDetailForVoucherSuccess = couponDetailForVoucher => ({
  type: GET_COUPON_DETAIL_FOR_VOUCHER_SUCCESS,
  couponDetailForVoucher,
});

export const getCouponDetailForVoucherFailed = error => ({
  type: GET_COUPON_DETAIL_FOR_VOUCHER_FAILED,
  error,
});

//Action search
export const searchCouponDetailForVoucherHandle = (
  payload = {},
  onSuccess,
  onFailed,
) => ({
  type: SEARCH_COUPON_DETAIL_FOR_VOUCHER_REQUEST,
  payload,
  onSuccess,
  onFailed,
});

export const searchCouponDetailForVoucherSuccess = searchCouponDetailForVoucher => ({
  type: SEARCH_COUPON_DETAIL_FOR_VOUCHER_SUCCESS,
  searchCouponDetailForVoucher,
});

export const searchCouponDetailForVoucherFailed = error => ({
  type: SEARCH_COUPON_DETAIL_FOR_VOUCHER_FAILED,
  error,
});

//Action get available coupon
export const getAvailableCouponHandle = (
  payload = {},
  onSuccess,
  onFailed,
) => ({
  type: GET_AVAILABLE_COUPON_REQUEST,
  payload,
  onSuccess,
  onFailed,
});

export const getAvailableCouponSuccess = getAvailableCoupon => ({
  type: GET_AVAILABLE_COUPON_SUCCESS,
  getAvailableCoupon,
});

export const getAvailableCouponFailed = error => ({
  type: GET_AVAILABLE_COUPON_FAILED,
  error,
});

//Save data coupon
export const dataCouponSelected = payload => ({
  type: DATA_COUPON_SELECTED,
  payload,
});

//Save data coupon
export const clearDataCouponList = () => ({
  type: CLEAR_DATA_COUPON_LIST,
});
