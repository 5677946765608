export const genProductDescription = (
  chosenSize,
  chosenAttributes,
  chosenToppings
) => {
  const size = chosenSize?.name && `Size ${chosenSize?.name}`;
  const toppings = chosenToppings?.map((topping) => topping?.name).join(", ");
  const attributes = chosenAttributes
    ?.map((attribute) => `${attribute.name} ${attribute.value}`)
    .join(", ");

  return [size, attributes, toppings].filter(Boolean).join(", ");
};

export const genProductMarketDescription = (
  chosenSize,
  chosenAttributes,
  chosenToppings
) => {
  const size = chosenSize;
  const toppings = chosenToppings?.map((topping) => topping?.name).join(", ");
  const attributes = chosenAttributes
    ?.map(
      (attribute) =>
        `${attribute.Name || attribute.name} ${
          attribute.Value || attribute.value
        }`
    )
    .join(", ");

  return [size, attributes, toppings].filter(Boolean).join(", ");
};
