/* eslint-disable no-unused-vars */
/* eslint-disable no-prototype-builtins */
import React, { useEffect } from "react";
import jwtDecode from "jwt-decode";
import { useDispatch } from "react-redux";
import loadable from "@loadable/component";
import { Route, Redirect } from "react-router-dom";
import { CFade } from "@coreui/react";

import Loading from "views/common/component/Loading";

// Route config
export const RouteConfig = (routes) => {
  return routes.map((route) => {
    if (route._children) {
      return RouteConfig(route._children);
    }

    return (
      <RouteWithSubRoutes
        key={`${route.path}__${route.name}__${route.exact}`}
        {...route}
      />
    );
  });
};

export const RouteWithSubRoutes = (route) => {
  if (route.authority) {
    return (
      <Route
        path={route.path}
        render={(props) => {
          // If has component
          if (route.hasOwnProperty("component")) {
            return <route.component router={props} />;
          }

          return <Page page={route.page} router={props} title={route.name} />;
        }}
      />
    );
  }

  return (
    <Route
      path={route.path}
      render={(props) => {
        // If has component
        if (route.hasOwnProperty("component")) {
          return <route.component router={props} />;
        }

        return <Page page={route.page} router={props} title={route.name} />;
      }}
    />
  );
};

// Loading Pages
export const Page = (props) => {
  const View =
    typeof props.page === "string"
      ? loadable(() => import(`../views/pages/${props.page}`))
      : props.page;

  return (
    <CFade style={{ height: "100%" }}>
      <View {...props} />
    </CFade>
  );
};
