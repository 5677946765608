import { numberWithCommas } from "utils";
import moment from "moment";

const { strings } = require("@i18n");

const arrDayOfWeek = [
  strings("Cart.labelMon"),
  strings("Cart.labelTue"),
  strings("Cart.labelWed"),
  strings("Cart.labelThu"),
  strings("Cart.labelFri"),
  strings("Cart.labelSat"),
  strings("Cart.labelSun"),
];

const createListMethodDelivery = (arr = [], methodDelivery) => {
  const listMethodDelivery = [];
  // eslint-disable-next-line array-callback-return
  arr.map((ite) => {
    let name = "";
    switch (ite.toLowerCase()) {
      case methodDelivery.takeaway:
        name = strings("Cart.takeaway");
        break;
      case methodDelivery.table:
        name = strings("Cart.table");
        break;
      case methodDelivery.shop:
        name = strings("Cart.shop");
        break;
      case methodDelivery.home:
        name = strings("Cart.home");
        break;
      default:
        name = strings("Cart.home");
        break;
    }
    listMethodDelivery.push({ code: ite.toLowerCase(), name, isCheck: false });
  });
  return listMethodDelivery;
};

const createMethodDelivery = (item, methodDelivery) => {
  let name = "";
  switch (item.toLowerCase()) {
    case methodDelivery.takeaway:
      name = strings("Cart.takeaway");
      break;
    case methodDelivery.table:
      name = strings("Cart.table");
      break;
    case methodDelivery.shop:
      name = strings("Cart.shop");
      break;
    case methodDelivery.home:
      name = strings("Cart.home");
      break;
    default:
      name = strings("Cart.home");
      break;
  }
  return { code: item.toLowerCase(), name, isCheck: false };
};

const couponErrorMessage = (message) => {
  let errorMessage = message;
  if (typeof message === "string") {
    switch (message) {
      case "InvalidTypeOfCoupon":
        errorMessage =
          "Mã ưu đãi không hợp lệ, vui lòng kiểm tra lại hoặc thử mã khác.";
        break;

      case "NotFoundCoupon":
        errorMessage =
          "Mã ưu đãi không hợp lệ, vui lòng kiểm tra lại hoặc thử mã khác.";
        break;

      case "CouponIsUsed":
        errorMessage =
          "Mã Coupon đã được sử dụng. Vui lòng chọn mã ưu đãi khác.";
        break;

      case "CouponExpired":
        errorMessage = "Mã Coupon đã hết hạn. Vui lòng chọn mã ưu đãi khác.";
        break;

      case "LessThanMinValueRequired":
        errorMessage =
          "Giá trị đơn hàng không đủ để sử dụng mã ưu đãi. Vui lòng kiểm tra lại đơn hàng hoặc chọn mã ưu đãi khác.";
        break;

      case "OverMaxSet":
        errorMessage =
          "Số lượng mã ưu đãi của chương trình đã hết. Vui lòng chọn mã ưu đãi khác.";
        break;

      case "OverMaxSetPerUser":
        errorMessage = "Bạn đã hết lượt nhận mã ưu đãi.";
        break;

      case "InvalidPaymentMethod":
        errorMessage =
          "Mã ưu đãi đang sử dụng không áp dụng cho phương thức thanh toán bạn chọn. Vui lòng kiểm tra lại đơn hàng hoặc chọn mã ưu đãi khác.";
        break;

      case "NotFoundCouponCampaign":
        errorMessage =
          "Mã ưu đãi không hợp lệ, vui lòng kiểm tra lại hoặc thử mã khác.";
        break;

      case "InvalidStoreOfCoupon":
        errorMessage =
          "Mã ưu đãi không áp dụng cho cửa hàng này. Vui lòng chọn mã ưu đãi khác.";
        break;

      case "InvalidOrderType":
        errorMessage =
          "Loại sản phẩm áp dụng không phù hợp. Vui lòng kiểm tra lại đơn hàng hoặc chọn mã ưu đãi khác.";

        break;

      case "NotFoundProduct":
        errorMessage =
          "Đơn hàng đang có sản phẩm ngừng bán, vui lòng xoá khỏi giỏ hàng.";
        break;

      default:
        break;
    }
  } else {
    errorMessage = "Lỗi hệ thống, vui lòng thử lại sau.";
  }
  return errorMessage;
};

const getTotalPrice = (listProduct) => {
  let tmpTotalPrice = 0;
  // eslint-disable-next-line array-callback-return
  listProduct.map((ite) => {
    tmpTotalPrice += ite.dataInfoProduct?.totalPrice || 0;
  });
  return tmpTotalPrice;
};

const getTotalItem = (listProduct) => {
  let tmpTotalItem = 0;
  // eslint-disable-next-line array-callback-return
  listProduct.map((ite) => {
    tmpTotalItem += ite.dataInfoProduct?.quantity || 0;
  });
  return tmpTotalItem;
};

const getNameVoucher = (data) =>
  data?.productName || data?.couponName || data?.name || data?.voucherCode;

const getCodeVoucher = (data) => data?.voucherCode || "";

const getMethodDelivery = (listMethodDelivery) => {
  if (!listMethodDelivery?.length) return {};
  return listMethodDelivery.filter((ele) => ele.isCheck === true)[0] || {};
};
const checkTableDelivery = (listMethodDelivery) => {
  let check = false;
  if (!listMethodDelivery?.length) return check;
  // eslint-disable-next-line array-callback-return
  listMethodDelivery.map((item) => {
    if (item?.code === "table") check = true;
  });
  return check;
};

const shippingDiscount = (totalPrice, listShippingDiscount) => {
  let nextRangeShippingDiscount = null;
  if (listShippingDiscount?.length > 0) {
    for (let i = 0; i < listShippingDiscount.length; i++) {
      const element = listShippingDiscount[i];
      if (totalPrice < element?.amountFrom) {
        nextRangeShippingDiscount = {
          extraPurchaseAmount: element?.amountFrom - totalPrice,
          shippingDiscount: element?.discountFee,
        };
        break;
      }
      if (element?.amountFrom <= totalPrice && totalPrice < element?.amountTo) {
        nextRangeShippingDiscount =
          i < listShippingDiscount.length - 1
            ? {
                extraPurchaseAmount:
                  listShippingDiscount[i + 1]?.amountFrom - totalPrice,
                shippingDiscount: listShippingDiscount[i + 1]?.discountFee,
                currentIndex: i,
              }
            : {
                currentIndex: i,
              };
        break;
      }
      if (
        i === listShippingDiscount?.length - 1 &&
        totalPrice > element?.amountTo
      ) {
        nextRangeShippingDiscount = {
          // extraPurchaseAmount:
          //   listShippingDiscount[i + 1]?.amountFrom - totalPrice,
          // shippingDiscount: listShippingDiscount[i + 1]?.discountFee,
          currentIndex: i,
        };
      }
    }
  }
  return nextRangeShippingDiscount;
};

const formatPrice = (price) => {
  // eslint-disable-next-line no-restricted-globals
  if (!isNaN(price) && parseInt(price, 10) >= 0) {
    // eslint-disable-next-line radix
    return `${numberWithCommas(parseInt(price) / 1000)}K`;
  }
  return 0;
};
const formatPhone = (phoneNumber) => {
  if (!phoneNumber) return "";
  if (phoneNumber?.indexOf("+84") === 0) {
    return phoneNumber.replace("+84", "0") || "";
  }
  if (phoneNumber?.indexOf("84") === 0) {
    return phoneNumber.replace("84", "0") || "";
  }
  return phoneNumber;
};

const convertDistance = (distance) => {
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(distance)) {
    return "";
  }
  const a = distance.toString().split(".");
  if (a?.length === 2 && a[1]?.length > 2) {
    return `${a[0]}.${a[1].substring(0, 2)}`;
  }
  return distance;
};

const numberWithCommasA = (n) => {
  const parts = n ? n.toString().split(".") : "0";
  return (
    parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".") +
    (parts[1] ? `,${parts[1]}` : "")
  );
};

const formatDistance = (distance) => {
  return numberWithCommasA(distance);
};

const formatNumber = (num) => {
  if (!num && typeof num !== "number") {
    // eslint-disable-next-line no-param-reassign
    num = "";
  }
  return num
    .toString()
    .split(/(?=(?:\d{3})+(?:\.|$))/g)
    .join(".");
};

const convertEstimateDeliveryTime = (deliveryTime) => {
  if (deliveryTime) {
    const formatDeliveryTime = moment(deliveryTime).utcOffset("+07:00");
    const isToDay = formatDeliveryTime.isSame(moment(), "day");
    const hourMinute = moment(formatDeliveryTime).format("HH:mm");
    const date = moment(formatDeliveryTime).format("DD/MM/YYYY");
    if (isToDay) {
      return (
        strings("Cart.labelToday") + strings("Cart.labelAtComma") + hourMinute
      );
    }
    const _a = formatDeliveryTime.isoWeekday();
    return `${arrDayOfWeek[_a - 1]}, ${date}${strings(
      "Cart.labelAt"
    )}${hourMinute}`;
  }
  return strings("Cart.labelAt");
};

export {
  createListMethodDelivery,
  createMethodDelivery,
  couponErrorMessage,
  getTotalPrice,
  getTotalItem,
  getNameVoucher,
  getCodeVoucher,
  getMethodDelivery,
  checkTableDelivery,
  shippingDiscount,
  formatPrice,
  formatPhone,
  convertDistance,
  formatDistance,
  formatNumber,
  convertEstimateDeliveryTime,
};
