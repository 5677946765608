import { useLayoutEffect } from "react";
import useBodyScrollControl from "./useBodyScrollControl";

const useToggleBodyScroll = (props) => {
  const { shouldFreeze } = props;
  const [freezeBodyScroll, releaseBodyScroll] = useBodyScrollControl();

  useLayoutEffect(() => {
    if (typeof shouldFreeze !== "boolean") return;

    if (shouldFreeze) {
      freezeBodyScroll();
    } else {
      releaseBodyScroll();
    }
  }, [shouldFreeze]);
};

export default useToggleBodyScroll;
