import {
  GET_DEFAULT_ADDRESS_USER_REQUEST,
  CALCULATE_SHIPPING_REQUEST,
  CREATE_DEFAULT_ADDRESS_REQUEST,
} from "./constants";
import {
  getDefaultAddressUserFailure,
  getDefaultAddressUserSuccess,
  calculateShippingFailure,
  calculateShippingSuccess,
} from "./actions";
import { put, takeLatest, fork } from "redux-saga/effects";
import {
  apiDefaultAddressUser,
  apiCalculateShipping,
  createDefaultAddressApi,
} from "@shared/services/api/orderAddressFood";
import { callApi } from "helpers/callApi";

export function* calculateShipping(obj) {
  const { requestData, onSuccess, onFailed } = obj.payload;

  try {
    const respone = yield callApi(apiCalculateShipping, requestData);
    console.log("function*calculateShipping -> respone", respone);
    yield put(calculateShippingSuccess(respone.data));
    if (typeof onSuccess === "function") {
      onSuccess(respone.data);
    }
  } catch (err) {
    yield put(calculateShippingFailure(err));
    if (typeof onFailed === "function") {
      onFailed(err);
    }
  }
}

export function* getDefaultAddressUser(obj) {
  const { onSuccess, onFailed } = obj.payload;

  try {
    const respone = yield callApi(apiDefaultAddressUser);

    if (respone.status === 200) {
      yield put(getDefaultAddressUserSuccess(respone.data));
      onSuccess(respone.data);
    } else {
      yield put(getDefaultAddressUserFailure());
      onFailed();
    }
  } catch (err) {
    yield put(getDefaultAddressUserFailure(err));
    onFailed();
  }
}

export function* createDefaultAddress(obj) {
  const { payload, onSuccess, onFailed } = obj;
  try {
    const respone = yield callApi(createDefaultAddressApi, payload);

    if (typeof onSuccess === "function") {
      onSuccess(respone?.data);
    }
  } catch (err) {
    if (typeof onFailed === "function") {
      onFailed(err);
    }
  }
}

function* getDefaultAddressUserWatch() {
  yield takeLatest(GET_DEFAULT_ADDRESS_USER_REQUEST, getDefaultAddressUser);
  yield takeLatest(CALCULATE_SHIPPING_REQUEST, calculateShipping);
  yield takeLatest(CREATE_DEFAULT_ADDRESS_REQUEST, createDefaultAddress);
}

export default function* rootChild() {
  yield fork(getDefaultAddressUserWatch);
}
