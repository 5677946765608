import { genActionTypes } from "utils/actionGenerator";

export const TYPE = "ChooseVoucher";

export const ACTION_KEYS = {
  GET_LIST_VOUCHER_TO_CHOOSE: "GET_LIST_VOUCHER_TO_CHOOSE",
};

export const GET_LIST_VOUCHER_TO_CHOOSE = genActionTypes(
  `${TYPE}_${ACTION_KEYS.GET_LIST_VOUCHER_TO_CHOOSE}`
);
export const SET_QUANTITY_VOUCHER = `${TYPE}_SET_QUANTITY_VOUCHER`;
export const SET_CHECK_MARK = `${TYPE}_SET_CHECK_MARK`;
export const SET_UNCHECK_MARK = `${TYPE}_SET_UNCHECK_MARK`;
export const CLEAR_LIST_VOUCHER_TO_CHOOSE = `${TYPE}_CLEAR_LIST_VOUCHER_TO_CHOOSE`;
