export default {
  common: {
    alertTitle: "Thông báo",
    alertConnectServerError:
      "Đã có lỗi xảy ra trong quá trình kết nối với máy chủ.",
    alertNotEnoughUtop: "Bạn không đủ điểm Utop.\nSố dư hiện tại: ",
    lableUtop: "Utop",
    labelNotEnoughUtop: "Bạn không đủ điểm Utop.",
    labelCurrentUtop: "Số dư hiện tại: ",
    labelPlusUtop: "Nạp ngay",
    labelClose: "Đóng",
    labelSuccessTrade: "Giao dịch thành công",
    lavelFailTrade: "Giao dịch thất bại",
    alertTitleContactStore: "Liên hệ cửa hàng",
    alertCall: "Gọi",
    alertTitleCancel: "Huỷ",
    alertCloseStore:
      "Cửa hàng hiện tại đang đóng cửa. Bạn có muốn thanh toán lại ?",
    errorMessage: "Đã có lỗi xảy ra. Vui lòng thử lại!",
    somethingWentWrong: "Đã có lỗi xảy ra",
    lostConnection: "Đã có lỗi xảy ra. Vui lòng kiểm tra kết nối mạng.",
    grant: "Cho phép",
    cancel: "Hủy bỏ",
    labelContactDriver: "Liên hệ tài xế",
    hundred: "nghìn",
    million: "triệu",
    billon: "tỉ",
    emptyData: "Không có dữ liệu.",
    continueText: "Tiếp tục",
    unitPrice: "đ",
    reLoginText: "Đăng nhập lại",
    NoText: "Không",
  },
  home: {
    listCity: "Danh sách tỉnh thành",
    turnOnPermissionLocation: "Bật cài đặt vị trí",
    isNotLoginTitle: "Bạn chưa đăng nhập!",
    isNotLoginContent:
      "Vui lòng đăng ký/đăng nhập để thực hiện đầy đủ tính năng",
    yourUtopPoint: "Điểm Utop của bạn",
    seachbarPlaceholder: "Nhập để tìm kiếm…",
    membership: "Membership",
    scanQR: "Quét QR",
    phoneRechargeCard: "Nạp thẻ ĐT",
    selectCurrentLocation: "Chọn vị trí hiện tại",
    selectCity: "Chọn Tỉnh / Thành phố",
    hotKeyword: "Từ khoá hot",
    cityNotSupportDescription:
      "Hiện Utop chưa phục vụ tại tỉnh thành của bạn, xem các tỉnh thành lân cận:",
    pleaseSelectCityDescription:
      "Vui lòng chọn địa điểm của bạn để Utop gợi ý các dịch vụ và khuyến mãi phù hợp:",
    loadScore: "Nạp điểm",
    supportOSToUseAppleId: "OS của bạn không hỗ trợ đăng nhập!",
    discoverMediaTitle: "Khám phá",
  },
  voucherPayment: {},
  loginrequest: {
    title: "Bạn chưa đăng nhập!",
    content:
      "Vui lòng đăng nhập để sử dụng đầy đủ\n các tính năng của Utop nhé.",
    ok: "OK",
    labelYes: "Có",
    labelNo: "Không",
  },
  MyVoucher: {
    Main: {
      TitleBar: "Voucher của tôi",
      tabEffect: "Còn hiệu lực",
      tabExpired: "Hết hiệu lực",
      ViewVoucher: "Xem",
    },
    VoucherCode: {
      viewDetail: "Xem chi tiết",
      voucherSerial: "Mã đối tác",
      copyBarcode: "COPY BARCODE NUMBER",
      voucherCode: "Mã thẻ",
      seriCode: "Số seri",
      COPY: "COPY",
      useNow: "Nạp ngay",
      markUse: "Đánh dấu đã dùng",
      Copied: "Copied",
      alert: {
        confirm: "Xác nhận",
        cancel: "Huỷ",
        notSupportIOS: "Tính năng này chỉ hỗ trợ từ phiên bản IOS 11.0 trở lên",
        bodyConfirm: "Vui lòng xác nhận bạn đã dùng món quà này.",
      },
    },

    VoucherDetail: {
      usageAndNotes: "Cách sử dụng và các lưu ý",
      descriptionNotes:
        "Xuất trình mã E-voucher hợp lệ khi thanh toán để kiểm tra và xác nhận.",
      locationOfUse: "Địa điểm sử dụng",
      otherInformation: "Thông tin khác",
      otherContent: "Mặc định chọn voucher sắp đến ngày hết hạn.",
      amountVoucherCanRefund: "voucher có thể hoàn lại.",
      ShareMessage:
        " đang có sẵn trên ứng dụng Utop. Nhấn link bên dưới để đăng ký và nhận khuyến mãi nhé!\n",
      labelNotExistProduct: "Sản phẩm không khả dụng.",
      labelAgree: "ĐỒNG Ý",
      labelBuyProduct: "Mua sản phẩm",
      labelOutOfStock: "Hết Hàng",
      labelQuantity: "Số lượng",
      labelEnterPrice: "Nhập số tiền (đơn vị: Ngàn VND)",
      labelConditionsOfUse: "Điều kiện sử dụng",
      labelStoreApply: "Ưu đãi áp dụng",
      priceOption: "Tuỳ chọn giá",
    },
    Expired: "Hết hạn",
    Used: "Đã dùng",
    errorMessage: "Đã có lỗi xảy ra. Vui lòng thử lại",
    VoucherCannotMarkUsed:
      "Voucher không được đánh dấu đã dùng. Vui lòng kiểm tra lại!",
    VoucherIsRefunded: "Voucher đã được hoàn lại. Vui lòng kiểm tra lại!",
    VoucherIsUsed: "Voucher đã được sử dụng. Vui lòng kiểm tra lại!",
  },
  video: {
    time: {
      m: "1 phút",
      h: "1 giờ",
      d: "1 ngày",
      M: "1 tháng",
      y: "1 năm",
    },
    uploadVideo: {
      errorVideo: "Video bị lỗi, vui lòng chọn video khác",
      send: "GỬI",
      delete: "XÓA",
      update: "Cập nhật",
      reasonReject: "Mời bạn xem phản hồi chi tiết về video của bạn từ Utop:",
      reasonRemove: "Mời bạn xem phản hồi chi tiết về video của bạn từ Utop:",
      descriptionVideo: "Mô tả video của bạn",
      agreeWithPolicy: "Tôi đồng ý với chính sách sử dụng tính năng",
      shareVideo: "Chia sẻ video",
      postNewVideo: "ĐĂNG VIDEO MỚI",
      videoReject: "Video của bạn đã bị từ chối",
      videoRemove: "Video của bạn đã bị xóa",
      detail: "Chi tiết",
      cancelVideo:
        "Video chưa được lưu, bạn có chắc muốn thoát khỏi màn hình này?",
      msgErrorSelectVideo:
        "Vui lòng chỉ chọn video đang được lưu trên thiết bị của bạn.",
      requestSelectVideo: "Bạn cần chọn 1 video để tiếp tục",
      agree: "Đồng ý",
      uploadSuccess: "Video của bạn đã sẵn sàng",
      reupSuccess: "Bạn đã gửi xét duyệt lại thành công",
      waitingReview:
        "Mời bạn xem hoặc chỉnh sửa video của mình\n tại mục My Channel ngay nhé!",
      waitingReview2: "Vui lòng chờ\nquản trị viện duyệt nhé",
      no: "Hủy",
      yes: "Xác nhận",
      notSave: "Đăng tải video chưa thành công",
      sureExit:
        "Bạn chưa chia sẻ video\nBạn có chắc chắn muốn thoát khỏi màn hình khi chưa đăng video? ",
      edit: "Chỉnh sửa",
      public: "Công khai",
      onlyMe: "Chỉ mình tôi",
      whoCanWatch: "Ai có thể xem video này?",
      allowComment: "Cho phép bình luận",
      view: "lượt xem",
      inform: "Thông báo",
      private: "Quyền riêng tư",
      selectFromGallery: "Chọn video từ thư viện của bạn",
      recordVideo: "Quay video",
      errorSelectVideo: "Xin vui lòng chọn video dưới 60 giây",
      editThumbnail: "Đổi ảnh",
    },
    videoDetail: {
      count_view: "Lượt xem",
      all: "Tất cả",
      follow: "Follow",
      place_holder_search: "Nhập để tìm kiếm...",
      place_holder_cmt: "Để lại bình luận của bạn...",
      full_text: "xem thêm",
      truncate_text: "rút gọn",
      ago: "trước",
      year: "năm",
      month: "tháng",
      day: "ngày",
      hour: "giờ",
      minute: "phút",
      sec: "giây",
      msgErrorVideo: "Video không khả dụng. Vui lòng thử lại",
      ok: "Xác nhận",
      errorReport: "Có lỗi hệ thống",
      block: "Chặn",
      fail: "thất bại",
      confirm: "XÁC NHẬN",
      cancel: "HUỶ BỎ",
      cancel2: "Huỷ",
      titleReport: "Báo cáo vi phạm",
      contentReport: "Bạn sẽ không nhìn thấy video hoặc liên lạc với nhau.",
      placeHolderReport: "Nhập lý do khác...",
      retry: "Không có dữ liệu\nNhấn để tải lại",
      pressRetry: "Nhấn để tải lại",
      back: "Quay lại",
      contentBlock:
        "Bạn sẽ không tìm thấy tài khoản \nvà video của họ trên Utop. Utop \nsẽ không thông báo cho họ là bạn \nđã chặn họ.",
      contentUnblock:
        "Giờ đây bạn sẽ tìm thấy tài khoản \nvà xem video của họ trên Utop. \nUtop sẽ không thông báo cho họ \nlà bạn đã bỏ chặn họ.",
      titlePopupReport: "Cảm ơn bạn đã cho chúng tôi biết!",
      contentPopupReport:
        "Ý kiến đóng góp của bạn giúp hệ \nthống chúng tôi biết khi có gì đó \nkhông ổn.",
      titlePopupBlock: "Bạn đã chặn",
      contentPopupBlock: "Bạn có thể bỏ chặn bất kì lúc nào \nbạn muốn.",
      share: "Chia sẻ lên",
      message: "Tin nhắn",
      other: "Khác",
      delete: "Xóa",
      private: "Quyền\nriêng tư",
      edit: "Chỉnh sửa",
      report: "Báo cáo",
      option: "Tuỳ chọn",
      sureDelete: "Bạn có chắc muốn xóa video này?",
      download: "Tải về",
      downloading: "Đang tải về...",
      saved: "Đã lưu về máy",
      duplicateDownload: "Vui lòng thử lại sau khi tải về hoàn tất",
      agree: "Đồng ý",
      errorDownload: "Lỗi đường truyền",
      retryDownload: "Thử lại",
    },
    home: {
      policy: "Chính sách sử dụng tính năng",
      viewCount: "Lượt xem",
      loginToUse: "Đăng nhập để sử dụng chức năng này!",
      follower: "Người theo dõi",
      focus: "Tiêu điểm",
      hashtag: "Xu hướng",
      myVideo: "Của tôi",
      requestCamera:
        "Vui lòng cho phép Utop truy cập máy ảnh để sử dụng tính năng này.",
      requestAudio:
        "Vui lòng cho phép Utop truy cập Audio để sử dụng tính năng này.",
      refuse: "Từ chối",
      requestVideo:
        "Vui lòng cho phép Utop truy cập thư viện video để sử dụng tính năng này.",
      inputSearch: "Nhập từ khoá cần tìm",
    },
    search: {
      account: "Tài khoản",
      hashtag: "Hashtag",
      hotKeyword: "Từ khóa hot",
      inputSearch: "Nhập từ khóa cần tìm",
      empty: "Không có dữ liệu",
    },
    profile: {
      myVideo: "Của tôi",
      shareYourVideo: "Chia sẻ video của bạn",
      following: "Đang theo dõi",
      follower: "Người theo dõi",
      block: "Chặn",
    },
    userDetail: {
      follow: "Theo dõi",
      unFollow: "Bỏ theo dõi",
      blocked: "Bạn đang chặn người dùng này.",
    },
    hashtagDetail: {
      description: "Mô tả",
      view: "lượt xem",
      unFavorite: "Bỏ yêu thích",
      favorite: " Thêm vào yêu thích",
    },
    comment: {
      comment: "Bình luận",
      exit: "Thoát",
      addComment: "Thêm bình luận...",
      hide: "Ẩn",
      delete: "Xóa",
      report: "Báo cáo",
      copy: "Sao chép",
      cancel: "Hủy",
      noComment: "Video này hiện đang tắt tính năng bình luận",
      firstComment: "Hãy là người bình luận đầu tiên!",
      reply: "Phản hồi",
      viewReply: "Xem phản hồi",
      view: "Xem",
      reply2: "phản hồi",
      sending: "Đang gửi",
      viewMore: "Xem thêm",
      contentBlock: "Bình luận không hợp lệ. Vui lòng thử lại.",
      errorComment: "Đã xảy ra lỗi. Vui lòng thử lại.",
    },
    hashtagList: {
      empty: "Không có dữ liệu",
    },
  },
  Utop: {
    YourBalance: "Số dư của bạn",
    Loaded: "Nạp",
    InviteFriends: "Mời bạn bè",
    SeeAll: "Xem tất cả",
    Utop: "Utop",
    React: {
      TitleAlert: "Utop",
      AlertMsg: "Bạn có muốn thoát khỏi ứng dụng ?",
      BtnAlert1: "HỦY",
      BtnAlert2: "ĐỒNG Ý",
      dialogTitle: "Chọn phương thức chia sẻ",
      ShareTitle: "Liên kết chia sẻ đã được tạo",
      ShareMessage:
        "Bạn nhận được lời mời tải app Utop để có cơ hội đổi quà CGV, Phúc Long,.... Bấm ngay tại http://onelink.to/mgxnyk",
    },
  },
  UtopExchange: {
    RedemptionPoints: "Điểm quy đổi",
    Points: "Điểm",
    Receive: "Nhận",
    Utop: "Utop",
    Brand: "Thương hiệu",
    Text1: "F.Money",
    Ratio: "Tỷ lệ",
    Text2: "F.Money = 1 Utop",
    Change: "Đổi",
    Text3: "Chưa kết nối thương hiệu",
    More: "Thêm",
    Text4: "1 Utop tương đương với 1.000 VND",
    Confirm: "Xác nhận",
    Guide: "Hướng dẫn",
    Text5: "Chọn thương hiệu",
    Text6: "Thêm thương hiệu khác",
    Step1: "Bước 1: Bấm vào “Thêm” và chọn thương hiệu bạn muốn kết nối.",
    Step2: "Bước 2: Xác nhận và hoàn tất nạp điểm Utop",
    React: {
      TitleBar: "Nạp",
      alertMsg: "Không có thương hiệu mới",
    },
  },
  UtopExchangeConfirm: {
    Brand: "Thương hiệu",
    Details: "Thông tin chi tiết",
    Score: "Số điểm đổi",
    Ratio: "Tỷ lệ",
    NumberUtopReceive: "Số Utop nhận được",
    Perform: "Thực hiện",
    React: {
      TitleBar: "Xác nhận",
    },
  },
  UtopExchangeSelectBrand: {
    alertMsg: "Thương hiệu này đã được thêm",
    React: {
      TitleBar: "Thêm thương hiệu",
    },
  },
  UtopExchangeSuccess: {
    SuccessfulTransaction: "Giao dịch thành công",
    Text1: "Bạn đã thực hiện thành công giao dịch nạp",
    Text2: "Utop vào tài khoản",
    React: {},
  },
  UtopQrPaySuccess: {
    Partner: "Đối tác",
    SuccessfulTransactions: "Giao dịch thành công",
    PointUtop: "điểm Utop",
    Details: "Thông tin chi tiết",
    UtopUsed: "Utop sử dụng",
    Brand: "Thương hiệu",
    TradingCode: "Mã giao dịch",
    MainScreen: "Về trang chủ",
    TradingTime: "Thời gian",
    Branch: "Chi nhánh",
    MakeDealAt: "Thanh toán tại",
    React: {},
    usedUtop: "Utop đã sử dụng",
    headerTitle: "Thông tin giao dịch",
  },
  UTopQR: {
    CurrencyUnit: "đ",
    UtopQrPay: {
      Title: "Nhập số điểm Utop",
      Partner: "Đối tác",
      Brand: "Thương hiệu",
      UtopPay: "Phương thức thanh toán",
      Utop: "Utop",
      note1: "Tương đương với",
      PaymentRecently: "Sử dụng gần đây",
      Pay: "Thanh toán",
      Total: "Số tiền",
      HintInputTotal: "Nhập số tiền cần thanh toán",
      HintInputUtop: "Nhập số Utop cần thanh toán",
      UtopPoint: "Điểm Utop",
      Surplus: "Số dư",
      NotEnoughMoney: "Số dư không đủ",
      BudgetSource: "Nguồn ngân sách",
      BudgetCompany: "Ngân sách công ty",
      From: "Người cấp",
      BillImage: "Hình ảnh hoá đơn",
      ClickToTakeImage: "Click để chụp ảnh hoá đơn",
      BillingInformation: "Thông tin xuất hoá đơn",
      TaxCode: "Mã số thuế",
      Address: "Địa chỉ",
      NumberGuest: "Số lượng khách",
      Note: "Ghi chú",
      Guide: "Hướng dẫn",
      NotePlaceholder: "Nhập để thêm ghi chú",
      Step1: "Bước 1: Nhập điểm Utop bạn muốn sử dụng",
      Step2: "Bước 2: Bấm vào “Thanh toán” để xác nhận",
      React: {
        TitleBar: "Nhập số điểm Utop",
        alertMsg1: "Không đủ điểm thanh toán",
        alertMsg2: "Số Utop thanh toán phải lớn hơn 0",
        alertMsg3: "Vui lòng nhập số đúng",
        alertMsg4:
          "Bạn không thể thanh toán bằng phương thức này vì chưa có nguồn ngân sách nào.",
        alertMsg5:
          "Bạn không thể thanh toán bằng phương thức này vì chưa có thông tin xuất hóa đơn nào.",
      },
      JsonError: "Thông tin giao dịch không hợp lệ.",
      InvalidQrCode: "Mã QR không hợp lệ.",
      InvalidRestaurant: "Thông tin nhà hàng không hợp lệ.",
      NotYutangRestaurant:
        "Utop hiện không hỗ trợ thanh toán tại hệ thống nhà hàng Yutang.",
      TransactionHadClosed: "Giao dịch đã được thực hiện thành công.",
      TransactionNotReadyToPayment:
        "Giao dịch thất bại trên mã QR hiện tại, vui lòng quét lại mã QR thanh toán mới nhất!",
      TransactionHadConfirmed:
        "Giao dịch đang được xử lý, vui lòng chờ trong giây lát!",
      WrongAccountTransaction: "Hóa đơn đang được xử lý bởi tài khoản khác!",
      WrongGetAccountInfo:
        "Không tìm thấy thông tin khách hàng, vui lòng thử lại!",
      InvalidMemberShip:
        "Tài khoản thành viên không hợp lệ để thực hiện thanh toán.",
      InvalidEmployee:
        "Tài khoản thành viên không hợp lệ để thực hiện thanh toán tại đối tác Golden Gate.",
      MessageDefaul: "Mã QR Code không đúng định dạng. Vui lòng thử lại",
      MessageDefaulServer: "Đã có lỗi xảy ra, vui lòng thử lại!",
      TutorialUtopStep1: "Bước 1",
      TutorialUtopContent1: " Nhập số tiền cần thanh toán",
      TutorialUtopStep2: "Bước 2",
      TutorialUtopContent2: "Chọn 1 trong 2 phương thức thanh toán",
      TutorialUtopStep3: "Bước 3",
      TutorialUtopContent3: 'Bấm vào "Thanh toán" để xác nhận',
      TutorialUBStep1: "Bước 1",
      TutorialUBContent1: "Nhập số tiền cần thanh toán",
      TutorialUBStep2: "Bước 2",
      TutorialUBContent2: "Chụp ảnh hoá đơn để tiếp tục",
    },
    UtopQrPayByPoint: {
      TutorialStep1: "Bước 1",
      TutorialContent1: "Nhập số Utop cần thanh toán",
      TutorialStep2: "Bước 2",
      TutorialContent2: 'Bấm vào "Thanh toán" để xác nhận',
      Total: "Số Utop thanh toán",
    },
    UtopQrPayComfirm: {
      Title: "Xác nhận thanh toán",
      Brand: "Thương hiệu",
      Details: "Thông tin chi tiết",
      Content: "Nội dung",
      PayAt: "Thanh toán tại ",
      UtopUsed: "Utop sử dụng",
      Perform: "Thực hiện",
    },
    UtopQrPaySuccess: {
      Partner: "Đối tác",
      SuccessfulTransactions: "Giao dịch thành công",
      PointUtop: "điểm Utop",
      Details: "Thông tin chi tiết",
      UtopUsed: "Utop sử dụng",
      Brand: "Thương hiệu",
      TradingCode: "Mã giao dịch",
      MainScreen: "Về trang chủ",
      TradingTime: "Thời gian",
      Branch: "Chi nhánh",
      React: {},
    },
    UtopQrPayResult: {
      Method: "Phương thức thanh toán",
      PayAt: "Thanh toán tại",
      UtopPoint: "Điểm Utop",
      TransactionPending: "GIAO DỊCH ĐANG XỬ LÝ",
      TransactionFailed: "THANH TOÁN THẤT BẠI",
      TransactionSuccess: "Thanh toán thành công",
      TransactionInfo: "Thông tin giao dịch",
      TotalPrice: "Tổng tiền",
    },
  },
  Menu: {
    Personal: "Cá nhân",
    History: "Lịch sử",
    PersonalInformation: "Thông tin cá nhân",
    InviteFriends: "Mời bạn bè",
    Information: "Thông tin",
    FAQ: "Câu hỏi thường gặp",
    Terms: "Điều khoản sử dụng",
    Policy: "Chính sách bảo mật",
    Support: "Hỗ trợ",
    LogOut: "Đăng xuất",
    Version: "Phiên bản hiện tại",
    React: {
      TitleBar: "Danh mục",
      TitleAlert: "Utop",
      LabelExit: "Bạn có muốn thoát khỏi ứng dụng ?",
      LabelLogout: "Đăng xuất khỏi ứng dụng?",
      BtnCancel: "HỦY",
      BtnAgree: "ĐỒNG Ý",
      dialogTitle: "Chọn phương thức chia sẻ",
      ShareTitle: "Liên kết chia sẻ đã được tạo",
      ShareMessage:
        "Bạn ơi, cùng mình cài ứng dụng Utop và nhận nhiều ưu đãi. Nhấn link bên dưới để đăng ký nhé!\n",
      ErrorLogout: "Có lỗi trong quá trình đăng xuất.\nVui lòng thử lại sau.",
    },
    thereIsNewRelease: "Đã có phiên bản",
    new: "Mới",
    update: "Cập nhật",
  },
  MenuChangePinCode: {
    OldPassword: "Mật khẩu cũ",
    NewPassword: "Mật khẩu mới",
    EnterNewPassword: "Nhập lại mật khẩu mới",
    Btn: "Đổi mật khẩu",
    React: {
      TitleBar: "Đổi mật khẩu",
      TitleAlert: "Utop",
      Alert1: "Thay đổi mật khẩu thành công",
      BtnAlert: "OK",
      errorMsg1: "Mật khẩu mới và mật khẩu hiện tại phải khác nhau",
      errorMsg2: "Mật khẩu mới và xác nhận mật khẩu phải trùng nhau",
    },
  },
  MenuFaq: {
    Question1: "Utop là gì?",
    Answer1:
      'Utop là ứng dụng tích điểm điện tử cho phép bạn tích lũy điểm, gộp tổng thành điểm "Utop" và đổi điểm tại hàng trăm cửa hàng khác nhau trong hệ thống đối tác của Utop tại Việt Nam.',
    Question2: "Điểm Utop là gì?",
    Answer2:
      "Điểm Utop là điểm được xây dựng trên nền tảng Blockchain - Akachain, và được sử dụng cho tất cả các cửa hàng trong mạng lưới Utop.",
    Question3: "Tôi có thể tải Utop như thế nào?",
    Answer3:
      "Hiện tại, Utop đã có trên App Store, bạn có thể vào tìm kiếm Utop và bắt đầu tải.",
    Question4: "Tôi có thể dùng Utop ở đâu?",
    Answer4:
      "Bạn có thể sử dụng Utop tại tất cả các đối tác trong hệ thống của Utop.",
    Question5:
      "Tôi xem danh sách các địa điểm có thể tích điểm và đổi quà ở đâu?",
    Answer5: "Bạn có thể xem danh sách trong app hoặc truy cập vào website",
    Answer5_1: "www.utop.vn",
    Question6: "Utop có thẻ không?",
    Answer6: "Hiện tại, Utop không tích hợp thẻ.",
    Question7: "Tôi có phải nạp tiền vào Utop không?",
    Answer7:
      "Bạn không cần nạp tiền vì đây là ứng dụng tích và đổi điểm thành viên. Hiện tại, chúng tôi chưa có dịch vụ này.",
    Question8: "Tôi có thể liên hệ Utop qua đâu?",
    Answer8: "Hiện tại, bạn có thể liên hệ số hotline của F.Studio: ",
    Answer8_1: "1800 6601(ext:4)",
    Answer8_2: "hoặc truy cập vào",
    Answer8_3: "Fanpage Utop",
    Answer8_4: "để liên hệ chúng tôi.",
    Question9: "Tôi muốn trở thành đối tác của Utop thì liên hệ như thế nào?",
    Answer9:
      "Nếu bạn cần liên hệ  liên quan tới việc hợp tác thì có thể liên hệ qua email",
    Answer9_1: "sales@utop.vn",
    Question10: "Blockchain là gì?",
    Answer10:
      "Nói ngắn gọn và dễ hiểu, Blockchain là công nghệ cho phép lưu trữ dữ liệu và thực hiện giao drequestTakeImageTextịch một cách an toàn. Utop đã áp dụng công nghệ này để tăng sự bảo mật cũng như sự an toàn trong giao dịch cho khách hàng.",
    Question11: "Tôi đăng ký tài khoản như thế nào?",
    Answer11:
      "Sau khi tải ứng dụng, bạn vào đăng ký và điền số điện thoại, hệ thống sẽ gửi cho bạn 1 mã xác thực. Sau khi điền mã, thông tin cá nhân và mật khẩu, bạn đã hoàn tất phần đăng ký tài khoản.",
    Question12: "Tôi nên bắt đầu như thế nào?",
    Answer12:
      "Sau khi tạo tài khoản, bạn có thể bắt đầu bằng cách đăng nhập tài khoản khách hàng thân thiết F.Studio và bắt đầu sử dụng.",
    Question13: "Tôi có thể tích điểm như thế nào?",
    Answer13:
      'Bạn vui lòng vào ứng dụng Utop, chọn vào "Thẻ", sau đó chọn vào thương hiệu bạn muốn tích điểm, sẽ hiện ra mã vạch/mã QR để đưa cho thu ngân quét.',
    Question14: "Làm sao để tôi có điểm Utop?",
    Answer14:
      'Để có điểm Utop, bạn chỉ cần vào phần "Điểm" trên ứng dụng, sau đó bấm vào phần "Nạp" và chọn đối tác liên kết đã tích điểm để đổi.',
    Question15:
      "Tại sao tôi phải chuyển đổi sang điểm Utop? Có bắt buộc không?",
    Answer15:
      "Điểm Utop được sử dụng để đổi quà tại tất cả các cửa hàng. Vì vậy, việc đổi điểm Utop là bắt buộc.",
    Question16: "Không chuyển điểm thành điểm Utop có sử dụng được không?",
    Answer16:
      "Ở thời điểm hiện tại, để sử dụng cho nhiều dịch vụ khác nhau, bạn nên đổi thành điểm Utop.",
    Question17: "Làm sao để đổi điểm thành viên của F.studio sang điểm Utop?",
    Answer17:
      'Để có điểm Utop, bạn chỉ cần vào phần "Điểm" trên ứng dụng, sau đó bấm vào phần "Nạp" và chọn đối tác liên kết là F.studio để đổi điểm.',
    Question18: "Làm sao để đổi voucher từ đối tác?",
    Answer18:
      'Bạn vào phần "Điểm" trên ứng dụng, sau đó chọn vào voucher của đối tác trong danh mục, chọn "Đổi" để lấy voucher. Bạn cần chú ý về số lượng điểm Utop cần đổi ở dưới mỗi voucher.',
    Question19: "Làm sao để sử dụng quà/voucher tôi đã đổi?",
    Answer19:
      'Bạn vào phần "Quà tặng" và chọn vào cửa hàng bạn muốn đổi, sau đó đưa cho thu ngân quét mã code/QR hoặc nhập mã voucher để xác nhận giao dịch thành công.',
    Question20: "Nếu tôi quên mang điện thoại thì có sử dụng Utop được không?",
    Answer20:
      "Nếu quên mang điện thoại thì bạn có thể tích điểm bằng cách đọc số điện thoại cho nhân viên thanh toán, nhưng gộp và đổi điểm thì vẫn cần điện thoại.",
    Question21: "Tôi có thể xem khuyến mãi ở đâu?",
    Answer21:
      "Bạn có thể xem khuyến mãi ngay trên phần thông báo trên ứng dụng Utop.",
    Question22:
      "Tôi có tới địa điểm đổi quà nhưng thu ngân bảo không biết gì về chương trình. Tôi phải làm gì?",
    Answer22:
      "Nếu địa điểm đổi quà nằm trong hệ thông của chúng tôi nhưng nhân viên thu ngân nói không hề biết gì về chương trình thì bạn vui lòng báo với chúng tôi qua số điện thoại hotline của F.Studio: ",
    Answer22_1: "1800 6601(ext:4)",
    Question23:
      "Tôi phải làm gì khi mã quà tặng của tôi không hợp lệ hoặc là bị lỗi?",
    Answer23:
      "Khi bạn gặp trường hợp mã quà bị lỗi hoặc không hợp lệ, vui lòng kiểm tra lại hạn sử dụng và có thể xảy ra trường hợp mã quà tặng của bạn đã được sử dụng trước đó. Nếu không phải trường hợp trên, bạn có thể liên hệ trực tiếp với thu ngân tại quầy hoặc vui lòng gọi vào số hotline của F.Studio: ",
    Answer23_1: "1800 6601(ext:4)",
    React: {
      TitleBar: "Câu hỏi thường gặp",
    },
  },
  MenuHistory: {
    Month: "Tháng",
    Reward: "Thưởng Utop từ thương hiệu",
    FromPoint: "Nạp Utop vào ví từ",
    PayByToken: "Thanh toán Utop tại quầy",
    VoucherGift: "Đổi voucher từ thương hiệu",
    Utop: "Utop",
    EmptyHistory: "Chưa có lịch sử",
    React: {
      TitleBar: "Lịch sử giao dịch",
    },
  },
  MenuPolicy: {
    Policy1:
      "Ứng dụng Utop dùng để đổi điểm của F.Studio với điểm Utop. Khách hàng có thể dùng điểm Utop quy đổi hàng hóa/dịch vụ tại các đối tác đổi điểm.",
    Policy2:
      "Số điểm Utop đổi từ điểm F.Studio được cộng dồn và có giá trị sử dụng đến hết ngày 31/5/2019",
    Policy3: "Điểm không được qui đổi hoặc hoàn lại thành tiền mặt.",
    Policy4:
      "Tỷ lệ sử dụng điểm tương ứng với Việt Nam đồng (VNĐ) như sau: 1 điểm = 1000 VNĐ.",
    Policy5:
      "Tỉ lệ quy đổi điểm được quy định là 1000 điểm Fstudio = 1 điểm Utop. Phần lẻ không đổi được vẫn được giữ lại tại F.Studio",
    Policy6:
      "Khách hàng có thể thay đổi mã PIN, thông tin cá nhân trên ứng dụng hoặc mục CẬP NHẬT THÔNG TIN trên ứng dụng.",
    Policy7:
      "Utop chỉ sử dụng thông tin cá nhân của khách hàng trong mục đích truyền thông nội bộ.",
    Policy8:
      "Sau khi đăng ký thì khách hàng có thể sử dụng ứng dụng để tích, đổi điểm ngay lập tức.",
    Policy9:
      "Mỗi khách hàng chỉ được sở hữu 1 tài khoản thành viên. Tài khoản này là dành riêng cho cá nhân khách hàng đã đăng ký và không thể chia sẻ với gia đình, bạn bè hoặc những người khác cho bất cứ mục đích nào.",
    Policy10:
      "Nếu khách hàng có bất kỳ thắc mắc xin vui lòng liên hệ với trung tâm Hỗ trợ thẻ/tài khoản theo số Hotline (028) 3622 0802",
    Policy11:
      "Utop có quyền vô hiệu hóa tài khoản của khách hàng mà không cần thông báo nếu xác định rằng khách hàng đã vi phạm Điều Khoản Sử Dụng này, hoặc sử dụng tài khoản dưới hành vi gian lận. Trong trường hợp này, thì tất cả các điểm tích lũy trong tài khoản sẽ bị hủy.",
    Policy12:
      "Vui lòng bảo mật tài khoản của quý khách hàng cẩn thận. Utop sẽ không chịu bất kỳ trách nhiệm nào nếu tài khoản của quý khách hàng là nguyên nhân gây ra tranh chấp.",
    React: {
      TitleBar: "Điều khoản sử dụng",
    },
  },
  MenuTerm: {
    Text1:
      "Khi sử dụng hệ thống điểm của Utop, khách hàng cần cung cấp cho chúng tôi thông tin cá nhân của mình để chúng tôi quản lý trên hệ thống, đồng nghĩa với việc thông tin của khách hàng cần phải chính xác. Chúng tôi muốn khách hàng hiểu rằng chúng tôi sẽ đảm bảo và chịu trách nhiệm về thông tin của quý khách trên hệ thống của chúng tôi.",
    Text2:
      "Chính sách này mô tả các thông tin mà chúng tôi thu thập đươc, lý do tại sao chúng tôi phải thu thập nó và làm thế nào để chúng tôi sử dụng và tiết lộ nó trên ứng dụng của chúng tôi.",
    Text3:
      "Nội dung của chính sách này rất quan trọng, chúng tôi hi vọng bạn sẽ dành ít thời gian ra đọc và hiểu nó một cách đầy đủ. Chính sách này có thể bị thay đổi theo thời gian, chúng tôi khuyên bạn nên xem lại trang này để có thể nắm được những thông tin mới nhất.",
    Text4: "Thu thập thông tin",
    Text5:
      "Khi Utop là một chức năng của hệ thống thành viên, nó xử lý tất cả các giao dịch của mỗi thành viên thuộc hệ thống. Để phân biệt một thành viên này và thành viên khác, chúng tôi cần phải thu thập thông tin cá nhân khác nhau từ khách hàng. Ngoài thông tin cá nhân này, chúng tôi cũng thu thập thông tin chi tiết về sự gắn bó của bạn và cấp độ gắn bó mà bạn đạt được.",
    Text6:
      "Bên dưới là cái nhìn tổng quát về các loại thông tin mà chúng tôi thu thập.",
    Text7:
      "•    Thông tin tài khoản người dùng: tên, số điện thoại và địa chỉ mail.",
    Text8:
      "•    Thông tin tài khoản chương trình khách hàng thân thiết: mã thành viên, mật khẩu, số dư và điểm.",
    Text9:
      "•    Thông tin giao dịch: hiển thị chi tiết các giao dịch có sử dụng Utop (Ví dụ: lịch sử giao dịch, giao dịch đổi điểm từ đối tác, giao dịch trừ điểm, …)",
    Text10: "Sử dụng thông tin",
    Text11:
      "Chúng tôi sẽ sử dụng thông tin của khách hàng cung cấp cho mục đích hỗ trợ, bảo trì, phát triển và cải thiện các dịch vụ chúng tôi cung cấp. Chúng tôi sử dụng thông tin của bạn cho các mục đích sau:",
    Text12:
      "•   Liên lạc với khách hàng và thông tin cho khách hàng một cách dễ hiểu về chương trình khách hàng thân thiết.",
    Text13:
      "•    Xử lý các giao dịch mà bạn đã yêu cầu thông qua hệ thống của chúng tôi.",
    Text14:
      "•    Hiển thị thông tin giao dịch, đặc biệt là lịch sử tích điểm của các giao dịch khách hàng đã thực hiện.",
    Text15: "• Cải thiện chất lượng cũng như hiệu suất của website.",
    Text16: "•   Quản lý và quản trị hệ thống của chúng tôi.",
    Text17: "•    Ngăn chặn cũng như chống lại các hành vi lừa đảo.",
    Text18: "•  Đáp ứng được các yêu cầu về bảo mật đã quy định.",
    Text19: "Công khai thông tin",
    Text20:
      "Chúng tôi có thể tiết lộ thông tin mà chúng tôi thu thập được trong các trường hợp sau đây:",
    Text21:
      "• Sử dụng trong chương trình khách hàng thân thiết, xử lý tất cả các yêu cầu mà bạn đã gửi cho chúng tôi.",
    Text22:
      "•    Để một bên thứ ba, website hoặc các dịch vụ khác mà bạn có thể kết nối thông qua hệ thống của chúng tôi.",
    Text23:
      "•   Để đáp ứng yêu cầu bảo mật về thông tin theo quy định mà chúng tôi cho là cần thiết với khách hàng.",
    Text24: "Liên kết với bên thứ ba",
    Text25:
      "Ứng dụng Utop bao gồm các liên kết đến các website khác, đặc biệt là các trang truyền thông, mạng xã hội chẳng hạn như: Facebook, Google, Twitter và một số hệ thống truyền thông khác. Các tính năng truyền thông cũng như mạng xã hội có thể thu thập thông tin từ trang của bạn. Các website mà bạn đang truy cập vào từ website của chúng tôi được thiết lập một cookie để hoạt động. Khi bạn truy cập vào các website này, hãy lưu ý rằng thông tin của bạn sẽ được điều chỉnh bởi chính sách riêng tư của họ. Chúng tôi cũng khuyến khích bạn đọc và hiểu chính sách riêng tư họ đã quy định.",
    Text26: "Chú thích “Cookie”",
    Text27:
      "Cookies là những mẩu nhỏ của văn bản lưu trữ thông tin trên các trình duyệt web cho mục đích lưu trữ hồ sơ. Chúng tôi sử dụng cookie phiên bản ID và cả cookie phiên bản trên web Utop. Đối với website của chúng tôi, nó là cần thiết để chúng tôi sử dụng cookie phiên để lưu thông tin thành viên, đặc biệt là mã số thành viên và tên thành viên. Chúng tôi cũng sử dụng cookie phiên bản ID để tạo thuận lợi cho quá trình xác thực của bạn và cho phép bạn truy cập vào máy chủ tài nguyên của chúng tôi khi chúng tôi đã xác minh rằng bạn có quyền làm như vậy. Cả hai phiên bản ID và cookie phiên bản web Utop sẽ hết hạn ngay lập tức khi bạn đóng trình duyệt của bạn.",
    Text28: "Thư điện tử (Email)",
    Text29:
      "Utop sử dụng địa chỉ mail chính xác của bạn và các thông báo sẽ đươc gửi qua mail của bạn trong các quy trình sau:",
    Text30: "• Đăng ký là thành viên mới của hệ thống.",
    Text31: "•    Cập nhập thông tin cá nhân của bạn",
    Text32: "• Gửi câu hỏi trên màn hình trợ giúp của chúng tôi.",
    Text33:
      "•  Nhận thông tin chương trình khuyến mãi, ưu đãi từ các nhãn hàng trong mạng lưới",
    Text34: "Thực hiện các quy định về an ninh",
    Text35:
      "Các thông tin được truyền đến và đi từ website/ứng dụng của chúng tôi với thiết bị của bạn đang sử dụng mã hóa theo tiêu chuẩn hiện đại qua giao thức SSL. Chúng tôi xác nhận website của chúng tôi sử dụng xác thực hai cấp: xác thực dịch vụ Web riêng của chúng tôi và sử dụng quy trình xác thực cơ bản “Spring Security”. Chúng tôi đã kích hoạt mã hóa TLS / SSL để bảo vệ dữ liệu nhạy cảm và các giao dịch theo hướng dẫn của “Spring Security”. Điều quan trọng bạn cần lưu ý rằng không có giao thức nào hoạt động thông qua Internet là an toàn 100%, vì vậy chúng tôi không thể đảm bảo an toàn tuyệt đối cho việc truyền tải thông tin của bạn.",
    Text36: "Lưu trữ dữ liệu",
    Text37:
      "Chúng tôi sẽ giữ thông tin của bạn khi tài khoản của bạn trong tình trang hoạt động hoặc cho đến khi chúng tôi cho là cần thiết nhằm duy trì cho các dịch vụ của chúng tôi có thể vận hành. Nếu vì một số lý do nào đó mà bạn muốn ngừng sử dụng dịch vụ của chúng tôi và hủy bỏ tài khoản của bạn để chúng tôi không còn có thể sử dụng các thông tin mà bạn cung cấp cho chúng tôi, xin đừng ngần ngại liên hệ với chúng tôi qua các thông tin hỗ trợ thành viên được cung cấp trên website (bạn có thể truy cập vào màn hình này bằng cách đăng nhập vào hệ thống và đi vào màn hình trợ giúp).",
    Text38: "Liên hệ với chúng tôi",
    Text39:
      "Nếu bạn muốn yêu cầu quyền truy cập vào Utop hoặc xóa thông tin của bạn hoặc có bất kỳ câu hỏi khác, các khiếu nại liên quan đến việc xử lý thông tin chính sách cũng như việc thực hành của chúng tôi, hãy liên hệ với chúng tôi (bạn có thể truy cập thông tin này bằng cách đăng nhập vào hệ thống và đi vào màn hình trợ giúp).",
    React: {
      TitleBar: "Chính sách bảo mật",
    },
  },
  UserProfile: {
    Information: "Thông tin",
    Email: "Email",
    EmailFaill: "Email không hợp lệ",
    Password: "Mật khẩu",
    ChangePassword: "Đổi mật khẩu",
    NameUser: "Họ và tên",
    btn1: "HỦY",
    btn2: "LƯU",
    btn3: "ĐỒNG Ý",
    SelectLang: "Chọn ngôn ngữ",
    En: "English",
    Vn: "Tiếng Việt",
    React: {
      TitleBar: "Thông tin cá nhân",
      messageUpdateSuccess: "Cập nhật thành công",
      messageUpdateFail: "Cập nhật thất bại",
    },
    changeAvatarText: "Đổi hình đại diện",
    userProfileTitleText: "Thông tin cá nhân",
    popupChangeAvatarOptionsTitleText: "Đổi hình đại diện",
    popupChangeAvatarOptionPickImageFromGallery: "Chọn ảnh từ thư viện",
    popupChangeAvatarOptionTakeAPicture: "Chụp ảnh",
    needPermissionToTakeImageAlertTitle: "Utop chưa có quyền truy cập Camera",
    needPermissionToTakeImageAlertText:
      "Vui lòng cho phép Utop truy cập máy ảnh để có thể quét mã QR thanh toán, quay video, chụp hình đại diện. Hình ảnh và video của bạn sẽ không bị chia sẻ nếu chưa được sự cho phép của bạn.",
    needPermissionToPickImageAlertTitle: "Utop chưa có quyền truy cập Hình ảnh",
    needPermissionToPickImageAlertText:
      "Vui lòng cho phép Utop truy cập thư mục hình ảnh/video với mục đích đăng tải hình đại diện/video. Hình ảnh/video của bạn sẽ không bị chia sẻ nếu chưa được sự cho phép của bạn.",
    needPickAtLeastOneImageToContinueText:
      "Bạn cần chọn ít nhất 1 hình ảnh để tiếp tục",
    confirmAlertToSaveProfileText:
      "Thông tin của bạn chưa được lưu, bạn có muốn lưu thông tin của bạn không ?",
    requestTakeImageText: "Bạn cần chụp 1 ảnh để tiếp tục",
    cameraUnavailableText:
      "Chức năng chụp ảnh hiện không khả dụng trên thiết bị của bạn",
    pickImageUnavailableText:
      "Chức năng chọn ảnh từ thư viện hiện không khả dụng trên thiết bị của bạn",
    PhoneNumber: "Số điện thoại",
    InputPhoneNumber: "Nhập số điện thoại",
    InputEmail: "Nhập email",
    ManagerCard: "Quản lý thẻ / Tài khoản",
    ChangePin: "Đổi mã PIN",
    Authentication: "Xác thực bằng vân tay / khuôn mặt",
    MethodLogin: "Phương thức đăng nhập",
    ConnectAppleID: "Kết nối Apple ID",
    ConnectFacebook: "Kết nối Facebook",
    SaveInfo: "Lưu thông tin",
    AuthenticationFaceID: "Xác thực bằng khuôn mặt",
    AuthenticationFingerprint: "Xác thực bằng vân tay",
    DeviceNotSupport: "Thiết bị không hỗ trợ trắc sinh học",
    UpdateSuccess: "Cập nhật thông tin thành công.",
    ErrorUpdate: "Có lỗi trong quá trình cập nhật, vui lòng thử lại lần sau.",
    ErrorEmail: "Email không đúng!!",
    LinkAccountAppleSuccess: "Bạn đã liên kết tài khoản Apple thành công",
    LinkAccountAppleFail: "Có lỗi trong quá trình liên kết với AppleId.",
    UnLinkAccount: "Bạn đã gỡ bỏ liên kết tài khoản %{title} thành công.",
    UnLinkAccountError:
      "Có lỗi trong quá trình gỡ bỏ liên kết tài khoản %{title} , vui lòng thử lại sau.",
    confirmUnlinkFacebook:
      "Bạn có muốn gỡ bỏ liên kết tài khoản Facebook với tài khoản Utop?",
    confirmUnlinkApple:
      "Bạn có muốn gỡ bỏ liên kết tài khoản Apple với tài khoản Utop?",
    successLinkToApple: "Bạn đã liên kết tài khoản Apple thành công",
    successLinkToFacebook: "Bạn đã liên kết tài khoản Facebook thành công",
    somethingWrongWhenLinkToAppleId:
      "Có lỗi trong quá trình liên kết với AppleId.",
    somethingWrongWhenLinkToFacebook:
      "Có lỗi trong quá trình liên kết với facebook.",
    facebookAccountBeLinkedWithAnotherAccount:
      "Tài khoản Facebook này đã được liên kết với một tài khoản người dùng khác.",
    successUnlinkTitle: "Bạn đã gỡ bỏ liên kết tài khoản ",
    success: " thành công.",
    somethingWentWrongWhenUnlink:
      "Có lỗi trong quá trình gỡ bỏ liên kết tài khoản",
    tryAgain: ", vui lòng thử lại sau.",
    somethingWhenWrongWhenUpdate:
      "Có lỗi trong quá trình cập nhật, vui lòng thử lại lần sau.",
    successUpdateProfile: "Cập nhật thông tin thành công.",
    TextPermission:
      "Thiết lập vân tay / khuôn mặt đã bị tắt. Vui lòng bật lại tính năng này trong cài đặt của bạn.",
    TextPermissionTouchID:
      "Thiết lập vân tay / khuôn mặt của bạn tạm thời bị khoá.",
    UpdatePinSuccess: "Bạn đã đổi mã PIN thành công.",
    CreatePinSuccess: "Bạn đã tạo mã PIN thành công.",
    ErrorLogin: "Có lỗi trong quá trình đăng xuất.\nVui lòng thử lại sau.",
    CreatePINCode: "Tạo mã PIN",
    TextAskPermissionAndroid:
      "Sử dụng vân tay để xác nhận thanh toán nhanh chóng và tiện lợi.",
    TextLater: "ĐỂ SAU",
    TurnOn: "BẬT",
    TextIgnore: "BỎ QUA",
    TextSetting: "CÀI ĐẶT",
  },
  GiftDetail: {
    Gift: "Quà tặng",
    Change: "Đổi",
    ExpiryDate: "Hạn sử dụng",
    Utop: "Utop",
  },
  GiftDetailComfirm: {
    Brand: "Thương hiệu",
    Details: "Thông tin chi tiết",
    NumberUtopUsed: "Số Utop sử dụng",
    Program: "Chương trình",
    Perform: "Thực hiện",
    React: {
      TitleBar: "Xác nhận đổi quà",
    },
  },
  GiftRedeemSuccess: {
    label1: "Đổi quà thành công",
    label2: "Bạn đã thực hiện thành công giao dịch đổi",
    label3: "Utop cho chương trình",
    label4: "Thông tin chi tiết",
    label5: "Số Utop đang có",
    label6: "Utop sử dụng",
    label7: "Thương hiệu",
    label8: "Mã giao dịch",
    label9: "Sử dụng ngay",
    label10: "Màn hình chính",
  },
  GiftDetailList: {
    GiftEmpty: "Chưa có quà tặng",
  },
  Intro: {
    label1: "Gộp điểm tích lũy",
    label2: "Đổi quà giá trị",
    label3:
      "Utop là một loại ví tích điểm điện tử, bạn có thể thoải mái tích lũy điểm số của bạn với bất cứ đơn vị bán hàng nào mà còn được gộp điểm số.",
    label4: "Đổi quà thả ga bao la khuyến mãi.",
    label5:
      "Săn khuyến mãi siêu hấp dẫn lên đến 60% chỉ áp dụng riêng cho thành viên Utop.",
    label6: "Tích điểm nhanh - thảnh thơi mua sắm",
    label7:
      "Không lo mất thẻ, Ứng dụng Utop giúp bạn tích điểm cực nhanh, kiểm tra điểm thưởng ngay tức thì.",
    label8: "Sẵn sàng tích điểm, mọi lúc mọi nơi.",
    label9:
      "Với Utop , một thẻ điện tử thay thế các thẻ nhựa bạn đang dùng. Tiết kiệm thời gian, giao dịch thuận tiện.",
    label10: "Bỏ qua",
    label11: "Tiếp theo",
    label12: "Hoàn thành",
  },
  Login: {
    label1: "Nhập số điện thoại để tiếp tục",
    label2: "Số điện thoại",
    label3: "Tôi đồng ý với Utop",
    label4: "Điều Khoản Sử Dụng và Chính Sách Riêng Tư",
    label5: "Tiếp tục",
    IncorrectNumber: "Số điện thoại bạn nhập không hợp lệ",
    React: {
      TitleBar: "Đăng nhập / Đăng ký",
    },
    IncorrectEmail: "Địa chỉ email không hợp lệ",
    cannotUpdateEmail: "Không thể cập nhật Email, vui lòng thử lại.",
    cannotUpdateName: "Không thể cập nhật Họ và tên, vui lòng thử lại.",
    OtherLogin: " Hoặc đăng nhập bằng",
    signInWithAppleLabel: "Đăng nhập bằng Apple",
    signInWithFacebookLabel: "Đăng nhập bằng Facebook",
  },
  LoginPolicy: {
    label1:
      "Ứng dụng Utop dùng để đổi điểm của F.Studio với điểm Utop. Khách hàng có thể dùng điểm Utop quy đổi hàng hóa/dịch vụ tại các đối tác đổi điểm.",
    label2:
      "Số điểm Utop đổi từ điểm F.Studio được cộng dồn và có giá trị sử dụng đến hết ngày 31/5/2019.",
    label3: "Điểm không được qui đổi hoặc hoàn lại thành tiền mặt.",
    label4:
      "Tỷ lệ sử dụng điểm tương ứng với Việt Nam đồng (VNĐ) như sau: 1 điểm = 1000 VNĐ.",
    label5:
      "Tỉ lệ quy đổi điểm được quy định là 1000 điểm Fstudio = 1 điểm Utop. Phần lẻ không đổi được vẫn được giữ lại tại F.Studio.",
    label6:
      "Khách hàng có thể thay đổi mã PIN, thông tin cá nhân trên ứng dụng hoặc mục CẬP NHẬT THÔNG TIN trên ứng dụng.",
    label7:
      "Utop chỉ sử dụng thông tin cá nhân của khách hàng trong mục đích truyền thông nội bộ.",
    label8:
      "Sau khi đăng ký thì khách hàng có thể sử dụng ứng dụng để tích, đổi điểm ngay lập tức.",
    label9:
      "Mỗi khách hàng chỉ được sở hữu 1 tài khoản thành viên. Tài khoản này là dành riêng cho cá nhân khách hàng đã đăng ký và không thể chia sẻ với gia đình, bạn bè hoặc những người khác cho bất cứ mục đích nào.",
    label10:
      "Nếu khách hàng có bất kỳ thắc mắc xin vui lòng liên hệ với trung tâm Hỗ trợ thẻ/tài khoản theo số Hotline (028) 3622 0802.",
    label11:
      "Utop có quyền vô hiệu hóa tài khoản của khách hàng mà không cần thông báo nếu xác định rằng khách hàng đã vi phạm Điều Khoản Sử Dụng này, hoặc sử dụng tài khoản dưới hành vi gian lận. Trong trường hợp này, thì tất cả các điểm tích lũy trong tài khoản sẽ bị hủy.",
    label12:
      "Vui lòng bảo mật tài khoản của quý khách hàng cẩn thận. Utop sẽ không chịu bất kỳ trách nhiệm nào nếu tài khoản của quý khách hàng là nguyên nhân gây ra tranh chấp.",
    React: {
      TitleBar: "Điều khoản sử dụng",
    },
  },
  VerifyOTP: {
    label1: "Một mã xác thực đã được gửi đến",
    label2: "Hỗ trợ",
    label3: "Tiếp tục",
    React: {
      TitleBar: "Xác thực số điện thoại",
    },
  },
  CreatePinCode: {
    label1: "Tạo một mật khẩu 6 số",
    label2: "cho lần đăng nhập tiếp theo",
    label3: "Nhập mật khẩu",
    label4: "Nhập lại mật khẩu",
    label5: "Tiếp tục",
    React: {
      TitleBar: "Tạo mật khẩu",
    },
    Authentication: "Xác thực bằng vân tay / khuôn mặt",
    TextContent: " Đảm bảo an toàn cho mọi giao dịch của bạn",
    TextCreatePin: "Tạo PIN Code",
    Continue: "Xác nhận và tiếp tục",
    SkipNext: "Bỏ qua PIN Code và tiếp tục",
    InputPin: "Nhập PIN Code",
    ConfirmPin: "Xác nhận PIN Code",
  },
  VerifyPinCode: {
    label1: "Xin chào",
    label2: "Vui lòng nhập mật khẩu cho",
    label3: "Quên mật khẩu",
    label4: "Hỗ trợ",
    label5: "Đăng nhập",
    React: {
      TitleBar: "Nhập mật khẩu",
    },
    OTPError: "Mã OTP không hợp lệ hoặc hết hạn",
    ErrorConnect: "Có lỗi trong quá trình liên kết với ",
    LinkAnotherAccount:
      "Số điện thoại này đã được liên kết với tài khoản khác.",
    ConfirmNumberPhone: "Xác thực số điện thoại",
    INPutOTP: "Vui lòng nhập mã OTP đã được gửi SMS đến số điện thoại ",
    ResendOTP: "Gửi lại mã OTP",
    Continue: "Tiếp tục",
    Complete: "Hoàn thành",
    SupportText: "Xin chào, Utop có thể giúp gì cho bạn không?",
    ResendOTPText: "Tôi chưa nhận được OTP. Gọi cho tôi",
    ChangeNumberPhone: "Tôi muốn đổi số điện thoại",
    CallCenter: "Gọi hỗ trợ 1900 636 997",
    SupportOnline: "Hỗ trợ trực tuyến",
    QuestOTP: "Bạn chưa nhận được mã OTP?",
    UtopCall: "Utop sẽ gọi điện cho bạn để đọc mã xác thực OTP.",
    Close: "Đóng",
    Confirm: "Đồng ý",
    WaitCall: "Xin đợi một chút Utop sẽ gọi bạn",
  },
  ForgetPin: {
    label1: "Số điện thoại sẽ nhận lại mã xác thực",
    label2: "Số điện thoại",
    label3: "Nhận mã xác thực",
    React: {
      TitleBar: "Quên mật khẩu",
    },
  },
  ChangePinCode: {
    ForgotPin: "Quên mã PIN",
    InputPin: "Nhập mã PIN",
    ChooseMethodSupport: "Chọn phương thức hỗ trợ",
    SupportOnline: "Hỗ trợ trực tuyến",
    Cancel: "Huỷ",
    Call: "Gọi ",
    TextChangePinCode: "Đổi mã PIN",
    TextCreatePinCode: "Tạo mã PIN",
    InputOldPin: "Nhập mã PIN cũ",
    InputNewPin: "Nhập mã PIN mới",
    ConfirmNewPin: "Xác nhận mã PIN mới",
    Continue: "Tiếp tục",
    Confirm: "Xác nhận",
    TitleNotify: "Utop",
    ErrorText: "Vui lòng nhập mã PIN",
    TextConfirm: "Mã xác nhận PIN Code không hợp lệ",
    SupportText:
      "Tài khoản của bạn đang bị tạm khoá chức năng cập nhật mã PIN! Vui lòng liên hệ 1900636997 để được trợ giúp.",
    InputAgain: "Xảy ra lỗi, vui lòng thử lại",
    Fingerprint: "Xác thực bằng vân tay",
    FaceID: "Xác thực bằng khuôn mặt",
    NotSupport: "Thiết bị không hỗ trợ trắc sinh học",
    NotTurnOnFace: "Thiết bị chưa bật xác thực",
    NotFullInfo: "Bạn chưa nhập đủ thông tin",
    ChangePinSuccess: "Đổi mã PIN thành công",
    CountError: "Bạn đã nhập sai mã PIN. Bạn còn %{count} lần thử lại",
    ErrorPinCode: "Đã có lỗi xảy ra. Vui lòng thử lại sau",
    ErrorLengthPin: "Mã PIN của bạn phải có ít nhất 6 ký tự!",
    ErrorConfirmPin: "Mã PIN xác nhận của bạn không chính xác!",
    ContactCallCenter: "Vui lòng liên hệ hotline ",
    ToSupports: " để được hỗ trợ",
    CallNow: "Gọi ngay",
    NewPinSameOldPin: "Mã PIN mới không được giống với mã PIN hiện tại của bạn",
    Close: "Đóng",
    OK: "Đồng ý",
  },
  PaymentPlus: {
    BuyPoint: "Mua điểm",
    TitleNotify: "Utop",
    ContinuePay: "Tiếp tục thanh toán",
    SumMoney: "Tổng tiền",
    InputAmountUtop: "Nhập số Utop cần mua",
    ChooseMethod: "Chọn phương thức hỗ trợ",
    Receiver: "Người nhận",
    TextLocked:
      "Tài khoản của bạn đang tạm bị khoá chức năng chuyển điểm! Vui lòng liên hệ 1900636997 để được trợ giúp.",
    ErrorPin: "Bạn đã nhập sai mã PIN. Bạn còn %{remainingTime} lần thử lại.",
    ConfirmTranfer: "Xác nhận giao dịch",
    StatusError: "Trạng thái thành viên không hợp lệ",
    OverPoint:
      "Số Utop bạn yêu cầu chuyển đã vượt số quy định trong ngày, số Utop còn lại được phép chuyển là: %{remainingTime} Utop",
    CannotTransferPoint:
      "Không thể chuyển điểm đến %{numberPhone}. Vui lòng liên hệ 1900636997 để được hỗ trợ",
    NotEnoughPoint: "Bạn không đủ Utop để chuyển điểm",
    ErrorText: "Đã xảy ra lỗi trong quá trình xử lý. Vui lòng thử lại.",
    Continue: "Tiếp tục",
    ForgotPin: "Quên mã PIN",
    AmountUtopPoint: "Vui lòng nhập số Utop cần mua!",
    RemindText: "Vui lòng nhập số đúng",
    TitleConfirmPoint: "Xác nhận thanh toán",
    Confirm: "Xác nhận",
    SumMoneyPay: "Tổng tiền thanh toán",
    UtopPointNeedBuy: "Số Utop cần mua",
    OK: "Đồng ý",
    Cancel: "Huỷ",
    ErrorConnectServer: "Đã có lỗi xảy ra trong quá trình kết nối với máy chủ.",
    ExchangeUtop: "Giao dịch Utop",
    ChangePoint: "Đổi điểm",
    ChangeOfMerchant: "Đổi điểm của đối tác liên kết sang điểm Utop",
    BuyPointByCard:
      "Mua điểm Utop bằng MoMo hoặc ATM/ VISA/ MASTER/ JCB hoặc tại \nCửa hàng tiện lợi",
    TextChange: "Chuyển điểm Utop cho bạn bè và người thân",
    ForwardPoint: "Chuyển điểm",
    HI: "Chào",
    TransactionSuccess: "Chuyển Utop thành công!",
    TransactionCode: "Mã giao dịch ",
    Message: "Lời nhắn",
    InputMessage: "Nhập lời nhắn ",
    UtopTransfer: "Số Utop đã chuyển",
    BackHome: "Về trang chủ",
    TextSupport:
      "Vui lòng kiểm tra hoặc gọi cho tổng \n đài hỗ trợ của Utop 19001000",
    BackTransfer: "Quay lại giao dịch",
    TitleError: "Có lỗi xảy ra!",
    TransferSuccess: "Giao dịch thành công",
    TransferFail: "Giao dich thất bại",
    Equivalent: "Tương đương",
    InputNumberPhoneReceiver: "Số điện thoại người nhận",
    InputNumberPhone: "Số điện thoại",
    RecentRecipients: "Người nhận gần đây",
    ExChangePoint: "Chuyển điểm",
    AmountUtopNeedBuy: "Số Utop cần chuyển",
    ConfirmPinCode: "Xác nhận mã PIN",
    InputPinCode: "Nhập mã PIN",
    ConfirmAuth: "Xác thực bằng vân tay / khuôn mặt",
    SkipNext: "Bỏ qua PIN Code và tiếp tục",
    ConfirmSkipNext: "Bạn có chắc chắn không tạo PIN Code?",
    Title: "Tạo mã PIN",
    MissInfo: "Vui lòng nhập đầy đủ thông tin",
    ErrorConfirmPin: "Mã PIN xác nhận không khớp",
    RemindInputPin: "Vui lòng nhập mã PIN",
    StatusMemberError: "Trạng thái thành viên không hợp lệ",
    InputPINCodeConfirm: "Nhập PIN Code để xác nhận",
    MaxPoint: "Chỉ có thể chuyển tối đa 1000 Utop cho mỗi giao dịch",
    TitleText: "Đảm bảo an toàn cho mọi giao dịch của bạn",
  },
  FirstLogin: {
    label1: "Điền thông tin cá nhân của bạn,",
    label2: "để có thể khôi phục lại mật khẩu",
    label3: "Họ và tên",
    label4: "Địa chỉ email",
    label5: "Hoàn Thành",
    React: {
      TitleBar: "Thông tin cá nhân",
    },
  },
  Card: {
    label1: "Bấm để tạo mới thẻ",
    label2: "Chưa có thẻ nào",
    React: {
      TitleBar: "Thẻ của tôi",
    },
  },
  Notifications: {
    React: {
      TitleBar: "Thông báo",
    },
  },
  Gift: {
    label1: "Quà của bạn",
    label2: "Quà đã dùng",
    label3: "Hạn sử dụng",
    label4: "Chưa có quà tặng",
    React: {
      TitleBar: "Quà tặng",
    },
  },
  GiftDetailBarCode: {
    label1: "Vui lòng dùng mã này khi giao dịch",
    label2: "Mã PIN",
    label3: "Xem chi tiết",
    label4: "Đã dùng",
  },
  ReactUtils: {
    ExpiredSession: "Phiên đăng nhập đã hết hạn. Bạn có muốn đăng nhập lại?",
    ErrorNetwork:
      "Đã có lỗi xảy ra trong quá trình kết nối với máy chủ. Vui lòng kiểm tra kết nối mạng và thử lại sau.",
    SupportMethod: "Chọn phương thức hỗ trợ",
    Cancel: "Huỷ",
    NumberPhoneSupport: "Gọi 1900636997",
    SupportOnline: "Hỗ trợ trực tuyến",
    UserNotFound: "Người dùng này chưa sử dụng Utop",
    CanNotChangePoint: "Không thể chuyển điểm cho chính mình",
  },
  MobileCard: {
    titleHeader: "Nạp thẻ điện thoại",
    labelMobileCardTab: "Nạp tiền trực tiếp",
    labelBuyCardTab: "Mua thẻ điện thoại",
    labelTotalUtop: "Tổng tiền: ",
    lablePaymentButton: "Thanh toán",
    lableOptionPrice: "Tuỳ chọn giá",
    lablePhoneNumber: "Số điện thoại cần nạp",
    validPhoneText: "* Số điện thoại không hợp lệ",
    changeTect: "THAY ĐỔI",
    enterPhoneText: "Nhập số điện thoại",
    selectNetwork: "Chọn nhà mạng",
  },
  ProductConfirm: {
    titleHeader: "Thanh toán",
    labelNumberPhone: "Số điện thoại",
    labelNetwork: "Nhà mạng",
    labelPrices: "Mệnh giá",
    labelTotalUtop: "Số điểm Utop chi trả",
    labelContinuePayment: "Tiếp tục thanh toán",
  },
  StoreDetail: {
    labelExpiredDate: "Hết hạn: ",
    labelSaleProduct: "Mã KM sản phẩm",
    labelSuggestCouponButton1: "Đã lấy",
    labelSuggestCouponButton2: "Lấy mã",
    labelMedium1: "Trung bình:",
    labelMedium2: "/người",
    labelOpenStore: "Đang mở cửa",
    labelCloseStore: "Đang đóng cửa",
    labelComeBackTime: "Quay lại vào lúc ",
    labelBookButton: "ĐẶT MÓN",
    labelUseAtShop: "ĐẶT MÓN",
    labelDelivery: "GIAO VỀ",
    labelBestSeller: "Nổi bật",
    alertSuccessCoupon:
      'Bạn đã lấy mã thành công, vào "Voucher của tôi" để xem chi tiết',
    labelVoucherList: "Deal/Voucher",
    errorDuplicateTransaction: "Hệ thống đang xứ lý, vui lòng thử lại sau.",
    errorOverMaxSet: "Số lượng coupon của chương trình đã hết.",
    errorOverMaxSetPerUser: "Bạn đã hết lượt nhận coupon.",
    errorNotFoundCouponCampaign:
      "Mã ưu đãi không hợp lệ, vui lòng kiểm tra lại hoặc thử mã khác.",
    errorInvalidCouponCampaginPeriod: "Đã hết thời gian nhận coupon.",
    viewMore: "Xem thêm",
    listVideo: "Danh sách video",
    errorStore:
      "Cửa hàng hiện tại đang tạm ngưng hoạt động. Vui lòng quay lại sau.",
    labelRequireLogin:
      "Vui lòng đăng nhập để sử dụng đầy đủ các tính năng của Utop nhé.",
    no: "Không",
    yes: "Có",
    internalServerError: "Lỗi hệ thống, vui lòng thử lại sau.(",
    ShareMessage1: "Bạn được giới thiệu trải nghiệm ",
    ShareMessage2:
      " trên ứng dụng Utop. Nhấn link bên dưới để đăng ký và nhận ưu đãi.\n",
    seeMore: "Xem thêm",
  },
  OrderStatus: {
    titleHeader: "Thông tin đơn hàng",
    shippingMethodNameShop: "Nhận hàng tại cửa hàng",
    shippingMethodNameHome: "Giao hàng tận nơi",
    labelProcessing: "(Đang thực hiện)",
    labelAwaitConfirm: "(Chờ xác nhận)",
    labelOrderFinished: "Đơn hàng đã hoàn thành",
    labelAwaitConfirmUser: "(Chờ khách xác nhận)",
    labelOutStock: "Hết món !",
    labelOrderCancel: "Đơn hàng đã huỷ",
    labelOutStockProduct: "Sản phẩm hết hàng",
    labelReOrder: "Đặt lại",
    labelOrderCancel1: "Huỷ đơn hàng",
    labelConfirm: "Xác nhận",
    labelRePayment: "Thanh toán lại",
    labelRating: "Đánh giá",
    labelOrdered: "Đã đặt",
    labelCanceled: "Đã huỷ",
    labelOutStock2: "Hết món",
    labelAvailable: "Sẵn sàng giao",
    labelAvailableHome: "Chờ giao hàng",
    labelConfirmed: "Check-in",
    labelConfirmedHome: "Đã xác nhận",
    labelDelivered: "Nhận hàng",
    labelOrderDetail: "Chi tiết đơn hàng",
    labelOrderCode: "Mã đơn hàng",
    labelOrderTime: "Thời gian đặt",
    labelShippingMethod: "Phương thức giao hàng",
    labelAddress: "Địa chỉ",
    labelReceiver: "Người nhận",
    labelOrderNote: "Ghi chú đơn hàng",
    labelPaymentMethod: "Phương thức thanh toán",
    labelSttOrder: "Số thứ tự đơn hàng",
    labelTotalOrder: "Tổng",
    labelTotalOrder1: "phần",
    labelShippingFee: "Phí giao hàng",
    labelTotalPrice: "Số tiền thanh toán",
    labelPayment: "ĐÃ THANH TOÁN",
    labelNotPayment: "CHƯA THANH TOÁN",
    Rating: {
      labelRating: "Đánh giá cửa hàng",
      contentRating:
        " Cảm ơn bạn đã sử dụng dịch vụ, hãy cho chúng tôi lắng nghe ý kiến đánh giá của bạn nhé!",
    },
    Notification: {
      labelSetting: "Cài đặt thông báo",
      contentSetting: "Vui lòng bật thông báo để nhận thông tin đơn hàng",
    },
    contentRemindRating: "Bạn không thể đánh giá chất lượng dịch vụ sau 72h!",
    labelConfirmCancelOrder: "Bạn có muốn huỷ đơn hàng này?",
    labelConfirmCancelPendingItem: "Bạn có muốn tiếp tục đơn hàng này?",
    labelSuspenOperation: "Cửa hàng tạm ngưng hoạt động.",
    labelCancel: "KHÔNG",
    labelAgree: "ĐỒNG Ý",
    modalQRCode: {
      orderCode: "Mã đơn hàng",
    },
    labelShippingDiscount: "Giảm phí giao hàng",
    labelDiscountCode: "Mã khuyến mãi",
    labelSearchDriver: "Đang tìm tài xế...",
    labelViewDetailMap: "Xem chi tiết",
    labelDiscount: "Giảm giá",
    labelInprocessDelivery: "Đang giao hàng",
    labelSuccessDelivery: "Đã giao hàng",
    labelDriverReceiveOrder: "Tài xế nhận đơn",
    labelDeliveryFail:
      "Giao hàng thất bại. Đơn hàng của bạn đang được cửa hàng xử lý",
    labelUnpaid: "Chưa thanh toán",
    labelPaymentBefore:
      "Vui lòng thanh toán trong 30 phút. Nếu không đơn hàng của bạn sẽ không được thanh toán lại.",
    labelOrderWillCancel: ". Nếu không đơn hàng của bạn sẽ bị hủy.",
  },
  UtopDeliveryAddressBook: {
    labelAddressDefault: "Địa chỉ mặc định",
    labelChooseAddress: "Chọn địa chỉ giao hàng",
    labelEmptyAddress: "Chưa có địa chỉ giao hàng",
    screenName: "Địa chỉ nhận hàng",
  },
  SmartOrderMenu: {
    createNewCart: "Tạo giỏ hàng mới?",
    labelAddNewCart:
      "Thêm sản phẩm này sẽ khiến các sản phẩm hiện tại trong giỏ hàng của bạn bị xoá. Bạn có muốn tiếp tục?",
    outStockProduct: "Hết món",
    openStore: " Đang mở cửa",
    closeStore: "Đang đóng cửa",
    availableShip: "Có giao hàng",
    onlyReceiveAtTheStore: "Chỉ nhận tại quán",
    threeStepOrder: "3 BƯỚC ĐẶT MÓN",
    selectOrder: "Chọn đặt món",
    checkinAtStore: "Check-in tại quán",
    label1: "Thưởng thức",
    labelLoadingData: "Đang tải dữ liệu ...",
    labelNotFoundResult: "Không tìm thấy kết quả phù hợp",
    viewCart: "Xem giỏ hàng",
    InformChangeCart:
      "Một vài sản phẩm bạn đặt đã bị thay đổi hoặc không còn phục vụ!",
    call: "Gọi",
    cancel: "Hủy",
    modalOrderList: {
      labelNoOption: "Không có tuỳ chọn",
      labelChange: "Thay đổi",
      labelAddOption: " Thêm tuỳ chọn khác",
      labelClose: "Đóng",
      labelDelete: "Xoá",
    },
    labelWaitConfirm: "Chờ xác nhận",
    labelWaitReceived: "Chờ nhận hàng",
    labelRecentOrder: "Món đặt gần đây",
    labelShop: "ĐẶT MÓN",
    labelHome: "GIAO VỀ",
    someProductHasChangedOrStopService:
      "Một vài sản phẩm bạn đặt đã bị thay đổi hoặc không còn phục vụ!",
    notLoginYet: "Bạn chưa đăng nhập!",
    loginToUseAllFuncOfUtop:
      "Vui lòng đăng nhập để sử dụng đầy đủ các tính năng của Utop nhé.",
    no: "Không",
    yes: "Có",
    systemIsHandling: "Hệ thống đang xứ lý, vui lòng thử lại sau.",
    outOfCoupon: "Số lượng coupon của chương trình đã hết.",
    outOfTurnToGetCoupon: "Bạn đã hết lượt nhận coupon.",
    couponCodeIsNotValid:
      "Mã ưu đãi không hợp lệ, vui lòng kiểm tra lại hoặc thử mã khác.",
    outOfTimeToGetCoupon: "Đã hết thời gian nhận coupon.",
    systemError: "Lỗi hệ thống, vui lòng thử lại sau.",
    createNewCartQuestion: "Tạo giỏ hàng mới?",
    createNewCartQuestionDescription:
      "Thêm sản phẩm này sẽ khiến các sản phẩm hiện tại trong giỏ hàng của bạn bị xoá. Bạn có muốn tiếp tục?",
    close: "Đóng",
    continue: "Tiếp tục",
    contactToShop: "Liên hệ cửa hàng",
    labelTheStoreIsUpdating: "Cửa hàng đang cập nhật món.",
    labelYes: "ĐỒNG Ý",
    ShareMessage: "",
  },
  SmartOrderItemModal: {
    label1: "Vui lòng chọn size",
    label2: "Vui lòng chọn ",
    label3: "Tuỳ chọn ",
    label4: "(chọn 1)",
    label5: "Cập nhật giỏ hàng",
    label6: "Thêm vào giỏ",
    label7: "Tuỳ chọn size ",
    label8: "Tuỳ chọn món thêm",
    label9: " (không bắt buộc chọn)",
    label10: "Số lượng",
  },
  // *** HungND73 them *** //
  // * Giỏ hàng FnB
  Cart: {
    error: {
      // * coupon
      CanNotApplyCoupon:
        "Tài khoản của bạn bị tạm khóa chức năng sử dụng Coupon. Vui lòng liên hệ Hotline để được hỗ trợ", // new, update at 2021/12/14 10h16
      NotFoundCouponCampaign:
        "Mã ưu đãi không hợp lệ, vui lòng kiểm tra lại hoặc thử mã khác.",
      // thêm trong quá trình làm, ko có ở bên app
      NotFoundCoupon:
        "Mã ưu đãi không hợp lệ, vui lòng kiểm tra lại hoặc thử mã khác.",
      InvalidTypeOfCoupon:
        "Mã ưu đãi không hợp lệ, vui lòng kiểm tra lại hoặc thử mã khác.",
      CouponIsUsed: "Mã Coupon đã được sử dụng. Vui lòng chọn mã ưu đãi khác.",
      CouponExpired: "Mã Coupon đã hết hạn. Vui lòng chọn mã ưu đãi khác.",
      LessThanMinValueRequired:
        "Giá trị đơn hàng không đủ để sử dụng mã ưu đãi. Vui lòng kiểm tra lại đơn hàng hoặc chọn mã ưu đãi khác.",
      OverMaxSet:
        "Số lượng mã ưu đãi của chương trình đã hết. Vui lòng chọn mã ưu đãi khác.",
      OverMaxSetPerUser: "Bạn đã hết lượt nhận mã ưu đãi.",
      InvalidStoreOfCoupon:
        "Mã ưu đãi không áp dụng cho cửa hàng này. Vui lòng chọn mã ưu đãi khác.",
      InvalidCoupon:
        "Mã ưu đãi không hợp lệ! Bạn có muốn tiếp tục đơn hàng này không?",
      InvalidPaymentMethod:
        "Mã ưu đãi đang sử dụng không áp dụng cho phương thức thanh toán bạn chọn. Vui lòng kiểm tra lại đơn hàng hoặc chọn mã ưu đãi khác.",

      // * product
      NotFoundProduct:
        "Đơn hàng đang có sản phẩm ngừng bán, vui lòng xoá khỏi giỏ hàng.",
      InvalidOrderType:
        "Loại sản phẩm áp dụng không phù hợp. Vui lòng kiểm tra lại đơn hàng hoặc chọn mã ưu đãi khác.",

      // * shipping
      ShippingConfigIsNotFound: "Cửa hàng chưa cài đặt phí vận chuyển.",
      // UnsatisfyingMinOrder: `Cửa hàng chỉ giao với giá trị đơn hàng từ ${minOrder}đ.`, // ! phải tự code ở lúc gặp err này
      // UnsatisfyingDistance: "Cửa hàng chỉ giao trong 'distanceTo'km.". ,
      // ExceedWeightLimitation: "Chỉ áp dụng cho đơn hàng có khối lương từ 'WeightTo'kg trở xuống.",
      AhaMovePartnerUnallowed:
        "Đơn hàng của bạn không thể giao do không thỏa điều kiện giao hàng.",
      GettingDistanceFail:
        "Hệ thống chưa xác định được khoảng cách giao hàng, vui lòng thử lại sau.",
      StoreIsNotFound:
        "Cửa hàng đang tạm ngưng hoạt động, vui lòng thử lại sau.",
      InvalidUserAddress: "Không xác định được địa chỉ giao hàng.",
      InvalidStoreAddress: "Không xác định được địa chỉ cửa hàng.",
      unknownShippingError:
        "Đơn hàng của bạn không thể giao do địa chỉ giao quá xa hoặc tổng tiền đơn hàng không thỏa.", // mặc định của api shipping

      // * create order
      "Số tiền thanh toán phải lớn hơn 1.000đ":
        "Số tiền thanh toán phải lớn hơn 1.000đ",

      // * server
      InternalServerError: "Lỗi hệ thống, vui lòng thử lại sau.",

      // * default
      unknown: "Có lỗi xảy ra. Vui lòng thử lại!",
    },

    labelInvalidAddress: "Địa chỉ không hợp lệ. Bạn có muốn chọn địa chỉ khác?",
    labelOrderChange: "Đơn hàng đã được cập nhật. \n Vui lòng kiểm tra lại.",
  },
  // màn /storedetail hiện tại của web
  StoreDetailWithOrderMenu: {
    error: {
      // * coupon
      outOfCoupon: "Số lượng coupon của chương trình đã hết.",
      outOfTurnToGetCoupon: "Bạn đã hết lượt nhận coupon.",
      couponCodeIsNotValid:
        "Mã ưu đãi không hợp lệ, vui lòng kiểm tra lại hoặc thử mã khác.",
      outOfTimeToGetCoupon: "Đã hết thời gian nhận coupon.",

      // lấy từ Cart
      NotFoundCouponCampaign:
        "Mã ưu đãi không hợp lệ, vui lòng kiểm tra lại hoặc thử mã khác.",
      // thêm trong quá trình làm, ko có ở bên app
      NotFoundCoupon:
        "Mã ưu đãi không hợp lệ, vui lòng kiểm tra lại hoặc thử mã khác.",
      InvalidTypeOfCoupon:
        "Mã ưu đãi không hợp lệ, vui lòng kiểm tra lại hoặc thử mã khác.",
      CouponIsUsed: "Mã Coupon đã được sử dụng. Vui lòng chọn mã ưu đãi khác.",
      CouponExpired: "Mã Coupon đã hết hạn. Vui lòng chọn mã ưu đãi khác.",
      LessThanMinValueRequired:
        "Giá trị đơn hàng không đủ để sử dụng mã ưu đãi. Vui lòng kiểm tra lại đơn hàng hoặc chọn mã ưu đãi khác.",
      OverMaxSet:
        "Số lượng mã ưu đãi của chương trình đã hết. Vui lòng chọn mã ưu đãi khác.",
      OverMaxSetPerUser: "Bạn đã hết lượt nhận mã ưu đãi.",
      InvalidStoreOfCoupon:
        "Mã ưu đãi không áp dụng cho cửa hàng này. Vui lòng chọn mã ưu đãi khác.",
      InvalidCoupon:
        "Mã ưu đãi không hợp lệ! Bạn có muốn tiếp tục đơn hàng này không?",
      InvalidPaymentMethod:
        "Mã ưu đãi đang sử dụng không áp dụng cho phương thức thanh toán bạn chọn. Vui lòng kiểm tra lại đơn hàng hoặc chọn mã ưu đãi khác.",

      // * server
      InternalServerError: "Lỗi hệ thống, vui lòng thử lại sau.",

      // * default
      unknown: "Có lỗi xảy ra. Vui lòng thử lại!",
    },
  },
  SetDeliveryInfo: {
    error: {
      // * shipping
      ShippingConfigIsNotFound: "Cửa hàng chưa cài đặt phí vận chuyển.",
      // UnsatisfyingMinOrder: `Cửa hàng chỉ giao với giá trị đơn hàng từ ${minOrder}đ.`, // ! phải tự code ở lúc gặp err này
      // UnsatisfyingDistance: "Cửa hàng chỉ giao trong 'distanceTo'km.". ,
      // ExceedWeightLimitation: "Chỉ áp dụng cho đơn hàng có khối lương từ 'WeightTo'kg trở xuống.",
      AhaMovePartnerUnallowed:
        "Đơn hàng của bạn không thể giao do không thỏa điều kiện giao hàng.",
      GettingDistanceFail:
        "Hệ thống chưa xác định được khoảng cách giao hàng, vui lòng thử lại sau.",
      StoreIsNotFound:
        "Cửa hàng đang tạm ngưng hoạt động, vui lòng thử lại sau.",
      InvalidUserAddress: "Không xác định được địa chỉ giao hàng.",
      InvalidStoreAddress: "Không xác định được địa chỉ cửa hàng.",
      unknownShippingError:
        "Đơn hàng của bạn không thể giao do địa chỉ giao quá xa hoặc tổng tiền đơn hàng không thỏa.", // mặc định của api shipping

      // * default
      unknown: "Có lỗi xảy ra. Vui lòng thử lại!",
    },
  },
  // CouponDetail: {
  //   rulesAndInstructions: "Thể lệ và hướng dẫn sử dụng",
  //   goBack: "Quay lại",
  // },
  CouponDetail: {
    error: {
      // * coupon
      outOfCoupon: "Số lượng coupon của chương trình đã hết.",
      outOfTurnToGetCoupon: "Bạn đã hết lượt nhận coupon.",
      couponCodeIsNotValid:
        "Mã ưu đãi không hợp lệ, vui lòng kiểm tra lại hoặc thử mã khác.",
      outOfTimeToGetCoupon: "Đã hết thời gian nhận coupon.",

      // lấy từ Cart
      NotFoundCouponCampaign:
        "Mã ưu đãi không hợp lệ, vui lòng kiểm tra lại hoặc thử mã khác.",
      // thêm trong quá trình làm, ko có ở bên app
      NotFoundCoupon:
        "Mã ưu đãi không hợp lệ, vui lòng kiểm tra lại hoặc thử mã khác.",
      InvalidTypeOfCoupon:
        "Mã ưu đãi không hợp lệ, vui lòng kiểm tra lại hoặc thử mã khác.",
      CouponIsUsed: "Mã Coupon đã được sử dụng. Vui lòng chọn mã ưu đãi khác.",
      CouponExpired: "Mã Coupon đã hết hạn. Vui lòng chọn mã ưu đãi khác.",
      LessThanMinValueRequired:
        "Giá trị đơn hàng không đủ để sử dụng mã ưu đãi. Vui lòng kiểm tra lại đơn hàng hoặc chọn mã ưu đãi khác.",
      OverMaxSet:
        "Số lượng mã ưu đãi của chương trình đã hết. Vui lòng chọn mã ưu đãi khác.",
      OverMaxSetPerUser: "Bạn đã hết lượt nhận mã ưu đãi.",
      InvalidStoreOfCoupon:
        "Mã ưu đãi không áp dụng cho cửa hàng này. Vui lòng chọn mã ưu đãi khác.",
      InvalidCoupon:
        "Mã ưu đãi không hợp lệ! Bạn có muốn tiếp tục đơn hàng này không?",
      InvalidPaymentMethod:
        "Mã ưu đãi đang sử dụng không áp dụng cho phương thức thanh toán bạn chọn. Vui lòng kiểm tra lại đơn hàng hoặc chọn mã ưu đãi khác.",

      // * server
      InternalServerError: "Lỗi hệ thống, vui lòng thử lại sau.",

      // * default
      unknown: "Có lỗi xảy ra. Vui lòng thử lại!",
    },
  },
  // -- sample (Utop APP)
  // PaymentHub: {
  //   screenName: "Hình thức thanh toán",
  //   labelSelectPaymentMethod: "Vui lòng chọn phương thức thanh toán!",
  //   labelOverAmountLimit:
  //     "Số tiền cần thanh toán vượt hạn mức cho phép, vui lòng chọn phương thức thanh toán khác.",
  //   labelInvalidStatus: "Đơn hàng đang xử lý. Vui lòng kiểm tra lại",
  //   labelErrorServer: "Đã có lỗi xảy ra. Vui lòng thử lại sau!",
  //   labelSupport: "Liên hệ ",
  //   labelSupport1: "  để được hỗ trợ",
  //   phoneNumberSupport: "1900636997",
  //   labelLinkCreditCart: "Thẻ Master/Visa/JCB đã liên kết",
  //   labelAddCreditCart: "Thêm thẻ / tài khoản",
  //   labelSelectDifferentPaymentMethod: "Chọn hình thức thanh toán khác",
  //   labelNotEnoughUtop: "Bạn không đủ điểm Utop!",
  //   labelCurrentOverBalance: "Số dư hiện tại: ",
  //   labelPaymentPrice: "Số tiền thanh toán",
  //   labelPayment: "Thanh toán",
  // },
  PaymentHub: {
    screenName: "Hình thức thanh toán",
    labelSelectPaymentMethod: "Vui lòng chọn phương thức thanh toán!",

    labelSupport: "Liên hệ ",
    labelSupport1: "  để được hỗ trợ",
    phoneNumberSupport: "1900636997",
    labelLinkCreditCart: "Thẻ Master/Visa/JCB đã liên kết",
    labelAddCreditCart: "Thêm thẻ / tài khoản",
    labelSelectDifferentPaymentMethod: "Chọn hình thức thanh toán khác",
    labelNotEnoughUtop: "Bạn không đủ điểm Utop!",
    labelCurrentOverBalance: "Số dư hiện tại: ",
    labelPaymentPrice: "Số tiền thanh toán",
    labelPayment: "Thanh toán",

    error: {
      OverAmountLimit:
        "Số tiền cần thanh toán vượt hạn mức cho phép, vui lòng chọn phương thức thanh toán khác.",
      InvalidStatus: "Đơn hàng đang xử lý. Vui lòng kiểm tra lại",
      ErrorServer: "Đã có lỗi xảy ra. Vui lòng thử lại sau!",

      // * default
      unknown: "Có lỗi xảy ra. Vui lòng thử lại!",
    },
  },

  // * Giỏ hàng voucher
  // ConfirmPayment: {
  //   screenName: "Giỏ hàng",
  //   labelErrorCouponIsUsed: "Mã ưu đãi đã được sử dụng!",
  //   labelErrorCouponExpired: "Mã ưu đãi đã hết hạn!",
  //   labelErrorMinOrder: "Giá trị đơn hàng không đủ để sử dụng mã ưu đãi!",
  //   labelErrorNotApplyStore: "Mã ưu đãi không áp dụng cho cửa hàng này!",
  //   labelErrorProductTypeMismatch:
  //     "Loại sản phẩm áp dụng không phù hợp. Vui lòng kiểm tra lại!",
  //   labelErrorSomeProductsStopSelling:
  //     "Vài sản phẩm trong đơn hàng đã ngừng bán!",
  //   labelErrorAccountBlock:
  //     "Tài khoản của bạn bị tạm khóa chức năng sử dụng mã ưu đãi. Vui lòng liên hệ Hotline để được hỗ trợ!",
  //   labelErrorServer: "Lỗi hệ thống, vui lòng thử lại sau!",
  //   labelErrorSystemProcessing: "Hệ thống đang xứ lý, vui lòng thử lại sau.",
  //   labelErrorQuantityCoupon: "Số lượng mã ưu đãi của chương trình đã hết.",
  //   labelErrorInvalidCouponCampaginPeriod: "Đã hết thời gian nhận mã ưu đãi.",
  //   labelErrorExpireUseCoupon: "Bạn đã hết lượt sử dụng mã ưu đãi.",
  //   labelErrorOutOfStockProduct: "Sản phẩm này đã hết hàng.",
  //   labelTotal: "Tổng (",
  //   labelTotal1: " cái)",
  //   labelApplyCouponCode: "Áp dụng mã khuyến mãi",
  //   labelTotalPricePayment: "Số tiền thanh toán",
  //   labelChange: "Thay đổi",
  //   labelPaymentContinue: "Tiếp tục thanh toán",
  //   labelSelectCoupon: "Chọn mã voucher hoặc nhập mã",
  // },
  // * Voucher Cart
  ConfirmPayment: {
    error: {
      // * coupon
      CanNotApplyCoupon:
        "Tài khoản của bạn bị tạm khóa chức năng sử dụng Coupon. Vui lòng liên hệ Hotline để được hỗ trợ", // new, update at 2021/12/14 10h16
      NotFoundCouponCampaign:
        "Mã ưu đãi không hợp lệ, vui lòng kiểm tra lại hoặc thử mã khác.",
      // thêm trong quá trình làm, ko có ở bên app
      NotFoundCoupon:
        "Mã ưu đãi không hợp lệ, vui lòng kiểm tra lại hoặc thử mã khác.",
      InvalidTypeOfCoupon:
        "Mã ưu đãi không hợp lệ, vui lòng kiểm tra lại hoặc thử mã khác.",
      LessThanMinValueRequired:
        "Giá trị đơn hàng không đủ để sử dụng mã ưu đãi. Vui lòng kiểm tra lại đơn hàng hoặc chọn mã ưu đãi khác.",
      OverMaxSet:
        "Số lượng mã ưu đãi của chương trình đã hết. Vui lòng chọn mã ưu đãi khác.",
      OverMaxSetPerUser: "Bạn đã hết lượt nhận mã ưu đãi.",
      InvalidStoreOfCoupon:
        "Mã ưu đãi không áp dụng cho cửa hàng này. Vui lòng chọn mã ưu đãi khác.",
      InvalidCoupon:
        "Mã ưu đãi không hợp lệ! Bạn có muốn tiếp tục đơn hàng này không?",
      InvalidPaymentMethod:
        "Mã ưu đãi đang sử dụng không áp dụng cho phương thức thanh toán bạn chọn. Vui lòng kiểm tra lại đơn hàng hoặc chọn mã ưu đãi khác.",
      CouponIsUsed: "Mã ưu đãi đã được sử dụng!",
      CouponExpired: "Mã ưu đãi đã hết hạn!",
      MinOrder: "Giá trị đơn hàng không đủ để sử dụng mã ưu đãi!",
      NotApplyStore: "Mã ưu đãi không áp dụng cho cửa hàng này!",
      ProductTypeMismatch:
        "Loại sản phẩm áp dụng không phù hợp. Vui lòng kiểm tra lại!",
      SomeProductsStopSelling: "Vài sản phẩm trong đơn hàng đã ngừng bán!",
      AccountBlock:
        "Tài khoản của bạn bị tạm khóa chức năng sử dụng mã ưu đãi. Vui lòng liên hệ Hotline để được hỗ trợ!",
      SystemProcessing: "Hệ thống đang xứ lý, vui lòng thử lại sau.",
      QuantityCoupon: "Số lượng mã ưu đãi của chương trình đã hết.",
      InvalidCouponCampaginPeriod: "Đã hết thời gian nhận mã ưu đãi.",
      ExpireUseCoupon: "Bạn đã hết lượt sử dụng mã ưu đãi.",
      OutOfStock: "Sản phẩm này đã hết hàng.",

      // * product
      NotFoundProduct:
        "Đơn hàng đang có sản phẩm ngừng bán, vui lòng xoá khỏi giỏ hàng.",
      InvalidOrderType:
        "Loại sản phẩm áp dụng không phù hợp. Vui lòng kiểm tra lại đơn hàng hoặc chọn mã ưu đãi khác.",

      // * create order
      "Số tiền thanh toán phải lớn hơn 1.000đ":
        "Số tiền thanh toán phải lớn hơn 1.000đ",

      // * server
      Server: "Lỗi hệ thống, vui lòng thử lại sau!",
      InternalServerError: "Lỗi hệ thống, vui lòng thử lại sau.",

      // * default
      unknown: "Có lỗi xảy ra. Vui lòng thử lại!",
    },

    screenName: "Giỏ hàng",

    labelTotal: "Tổng (",
    labelTotal1: " cái)",
    labelApplyCouponCode: "Áp dụng mã khuyến mãi",
    labelTotalPricePayment: "Số tiền thanh toán",
    labelChange: "Thay đổi",
    labelPaymentContinue: "Tiếp tục thanh toán",
    labelSelectCoupon: "Chọn mã voucher hoặc nhập mã",
  },
  // * -- end
  MapDirection: {
    showDirectionText: "Chỉ đường",
    openWithGoogleMapText: "Mở bằng Google Maps",
    openWithAppleMapText: "Mở bằng Apple Maps",
    headerTitleText: "Hướng dẫn đường đi",
    descriptionTitleShopText: "Vị trí cửa hàng",
    descriptionTitleUserText: "Vị trí của tôi",
    openGoogleMapButtonText: "Mở",
    downloadGoogleMapButtonText: "Tải ngay",
    cancelGoogleMapButtonText: "Huỷ",
    utopWantToOpenGoogleMapText: "Utop muốn mở Google Map",
    utopGoogleMapNotAvailableText: "Vui lòng cài đặt Google Map",
    unknownUserAddress: "Không xác định",
    alertCanNotGetAddressFromLatLong:
      "Không thể xác định địa chỉ hiện tại của bạn",
    descriptionDirection:
      "Khoảng cách thể hiện được tính dựa trên đường \n thẳng. Khoảng cách di chuyển thực tế có thể thay đổi.",
  },
  ListStore: {
    titleText: "Danh sách cửa hàng",
  },
  MenuHistoryOrder: {
    screenName: "Lịch sử giao dịch",
    pickDay: "Chọn ngày",
    labelStatusNew: "Chưa thanh toán",
    labelStatusPayment: "Đặt hàng thành công",
    labelStatusWait: "Chờ xác nhận",
    labelStatusConfirmed: "Đang chuẩn bị hàng",
    labelStatusAvailable: "Sẵn sàng để lấy hàng",
    labelStatusDelivered: "Hoàn thành",
    labelStatusCancel: "Đã hủy",
    labelEmptyData: "Không có dữ liệu",
    labelOrderCode: "Mã GD:",
    labelOrderStatus: "Trạng thái đơn hàng",
  },
  ConfirmOrderFnB: {
    screenName: "Xác nhận đơn hàng",
    receiver: "Người nhận",
    paymentMethod: "Phương thức giao hàng",
    note: "Ghi chú",
    address: "Địa chỉ",
    labelTotal: "Tổng (",
    labelTotal1: "phần",
    shippingFee: "Chi phí vận chuyển",
    applyDiscount: "Áp dụng mã giảm giá",
    totalPrice: "Số tiền thanh toán",
    paymentContinue: "Tiếp tục thanh toán",
    buyTakeOut: "Mua mang về",
    deliveryAtTable: "Giao tại bàn: ",
    labelError: "Đã có lỗi xảy ra. Vui lòng thử lại sau!",
    someProductsOutofStock: "Vài sản phẩm trong đơn hàng đã ngừng bán",
    contactSupport:
      "Tài khoản của bạn bị tạm khóa chức năng sử dụng Coupon. Vui lòng liên hệ Hotline để được hỗ trợ",
    couponIsUsed: "Mã Coupon đã được sử dụng!",
    couponExpired: "Mã Coupon đã hết hạn!",
    textOk: "OK",
    buyTakeOut1: "[Mua mang về] ",
  },
  SuggestCouponFnB: {
    currentGoodWill: "Ưu đãi hiện có",
  },
  RefundVoucher: {
    titleHeader: "Hoàn trả Voucher",
    errMsg: {
      NotFoundVoucher: "Không tìm thấy Voucher.\n Xin vui lòng thử lại!",
      VoucherCannotMarkRefunded: "Đã có lỗi xảy ra.\n Xin vui lòng thử lại!",
      InvalidCount: "Số lượng Voucher hoàn chưa đúng.\n Xin vui lòng thử lại!",
      errorMessage: "Đã có lỗi xảy ra.\n Xin vui lòng thử lại!",
    },
    React: {
      txtAmountVoucher: "Số lượng",
      txtAmountUtopRefunded: "Số Utop sẽ hoàn lại",
      txtUtop: "Utop",
      txtConfirm: "Xác nhận",
    },
    PopupConfirm: {
      txtConfirmRefund: "Bạn có chắc chắn muốn hoàn",
      txtVoucher: "Voucher",
      txtConfirmRefund2: "này?",
      btnAccept: "Đồng ý",
      btnCancel: "Huỷ bỏ",
    },
    PopupResult: {
      txtRefundSuccess: "Giao dịch thành công",
      txtRefundFail: "Giao dịch thất bại",
      txtSuccess: "Bạn được hoàn trả",
      txtSuccess2: "từ voucher",
      txtUtop: "Utop",
      txtDone: "Xong",
      txtClose: "Đóng",
    },
  },
  DynamicPage: {
    UserNotMatchCampaign:
      "Rất tiếc, tài khoản của bạn không thoả điều kiện chương trình",
    UserHasReceivedGift: "Rất tiếc, bạn đã hết lượt nhận quà.",
    DuplicateTransaction: "Rất tiếc, bạn đã hết lượt nhận quà.",
    OverMaxSetPerUser: "Rất tiếc, bạn đã hết lượt nhận quà.",
    OverMaxSet: "Rất tiếc, số lượng quà đã đạt mức giới hạn. ",
    NotFoundCouponCampaign:
      "Rất tiếc, tài khoản của bạn không thoả điều kiện chương trình",
    InvalidCouponCampaginPeriod: "Rất tiếc, đã hết thời gian nhận quà.",
    currentGoodWill: "Ưu đãi hiện có",
  },

  OrderAddressFood: {
    screenName: "Địa chỉ nhận hàng",
    labelErrorShipping:
      "Đơn hàng của bạn không thể giao do địa chỉ giao quá xa hoặc tổng tiền đơn hàng không thoả.",
    labelEnterYourName: "Vui lòng nhập tên của bạn!",
    labelEnterYourPhone: "Vui lòng nhập số điện thoại của bạn!",
    labelInValidPhoneNumber: "Số điện thoại bạn nhập không hợp lệ!",
    labelEnterAddress: "Vui lòng nhập địa chỉ nhận hàng!",
    labelEnterUnitShipping: "Vui lòng chọn đơn vị giao hàng!",
    labelFree: "Miễn phí",
    labelEnterName: "Nhập tên khách hàng",
    labelEnterPhoneNumber: "Nhập số điện thoại khách hàng",
    labelPaymentContinue: "Xác nhận",
    labelSelectMethodShipping: "Chọn hình thức giao hàng",
    labelWarningLeaveScreen:
      "Thông tin bạn vừa nhập sẽ bị mất khi thoát khỏi màn hình này.",
    labelYes: "Đồng ý",
    labelCancel: "Huỷ",
    labelInfoUser: "Thông tin khách hàng",
    labelShipping: "Giao hàng",
    labelEnterYourAddress: "Nhập địa chỉ của bạn",
    labelYourAddress: " Địa chỉ của bạn",
    labelNoteAddress: "Ghi chú địa chỉ",
    labelPlaceholderNoteAddress: "Số Block, số tầng...",
    labelSelectAddress: "Chọn địa chỉ nhận hàng",
    labelYourSelected: "Bạn đang chọn ",
    labelShop: "Nhận tại quầy",
    labelHome: "Giao về",
    labelPleaseSelectAddress: "Vui lòng chọn địa chỉ.",
    labelTotalShippingFee: "Tổng phí giao hàng",
  },

  SuggestCouponV2: {
    labelExpireDate: "HSD: ",
    labelSelect: "Chọn",
    labelNonSelect: "Huỷ",
  },
  PaymentBuyPoint: {
    screenName: "Mua điểm",
    labelServerError:
      "Đã xảy ra lỗi trong quá trình xử lý. Vui lòng thực hiện lại.",
    labelInputPoint: "Vui lòng nhập số Utop cần mua!",
    labelInputNumber: "Vui lòng nhập số đúng",
    labelInputBuyPoint: "Nhập số Utop cần mua",
    labelTotalPrice: "Tổng tiền",
    labelPaymentContinue: "Tiếp tục thanh toán",
  },
  RePaymentVoucher: {
    labelNotPayment: "Chưa thanh toán",
    labelCancelOrder: "Huỷ đơn",
    labelPayment: "Thanh toán",
    labelCompleted: "Hoàn thành",
    labelViewVoucher: "Xem Voucher",
    labelOrderSuccess: "Đặt hàng thành công",
    labelCanceled: "Đã hủy",
    titleHeaderOrderCode: "Mã đơn hàng: ",
    labelTotal: "Tổng ( %{sum} cái)",
    labelApplyCouponCode: "Áp dụng mã khuyến mãi",
    labelTotal2: "Tổng cộng",
    labelOrderDetail: "Chi tiết đơn hàng",
    labelTransactionCode: "Mã giao dịch",
    labelOrderTime: "Thời gian đặt hàng",
    labelPaymentMethod: "Phương thức thanh toán",
    labelOrderStatus: "Trạng thái đơn hàng",
    labelSupport:
      "Đơn hàng đang xử lý. Vui lòng chờ giây lát hoặc liên hệ hotline hỗ trợ",
    YouWantCantOrder: "Bạn có muốn huỷ đơn hàng này?",
    No: "KHÔNG",
    Yes: "ĐỒNG Ý",
  },
  UtopCardManager: {
    titleHeader: "Quản lý thẻ / tài khoản",
    cardBank: "Thẻ ngân hàng",
    cardMember: "Thẻ nhân viên / thành viên",
    addCardbank: "Thêm thẻ / tài khoản",
    addCardMember: "Thêm thẻ nhân viên / thành viên",
    date: "ngày",
  },
  UtopCardConfirm: {
    titleHeader: "Liên kết thẻ",
    txtConfirm: "Xác nhận",
    txtDetail: "Bấm “Xác nhận” để liên kết thẻ nhân viên với ứng dụng",
    txtDetail2: "*Số tiền thanh toán được trừ trực tiếp qua ứng dụng",
    txtHotline1: "Vui lòng liên hệ hotline ",
    txtHotline2: " để được hỗ trợ.",
    txtDetail4: "Đến mục",
    txtDetail5: "Quản lý thẻ / tài khoản",
    txtDetail6: "để xem chi tiết.",
    txtLimit: "*Hạn mức",
    limitUtop: "điểm Utop",
    txtEquivalent: "tương đương",
    txtMoney: ".000đ/ngày.",
    txtError: "Đã có lỗi xảy ra",
    txtCancel: "Huỷ bỏ",
    txtWatchNow: "Xem ngay",
    txtLinkedWithCurrentUser: "Thẻ đã được liên kết với tài khoản này",
    txtQrExpired: "Mã QR hết hiệu lực",
    txtQrExpiredDetail:
      "Bạn có muốn quét QR Code khác để thực hiện liên kết thẻ với ứng dụng Utop?",
    contactToShop: "Liên hệ hỗ trợ",
    txtCallNow: "Gọi ngay",
  },
  UtopCardDetail: {
    titleHeader: "Chi tiết thẻ / tài khoản",
    bankName: "Tên ngân hàng",
    userName: "Tên chủ thẻ",
    cardNumber: "Số thẻ",
    cardExpire: "Ngày hết hạn",
    cancelLink1: "Huỷ liên kết thẻ",
    cancelLink2: "Huỷ liên kết",
    confirmCancelLink: "Bạn có chắc chắn muốn huỷ liên kết với thẻ này không?",
    confirmLabel: "Xác nhận",
    cardName: "Tên thẻ",
    IDCard: "Mã thẻ",
    cardBalance: "Hạn mức còn lại",
    cardLimit: "Hạn mức khả dụng",
    exchangeUtop: "(1 Utop = 1.000 VNĐ)",
    detailDeleteCard:
      "*Bạn sẽ không thể dùng thẻ này để thanh toán cho các dịch vụ của Utop được nữa.",
    deletacardSucces: "Huỷ liên kết thẻ thành công",
    deletacardSuccesDetail: "Thẻ của bạn đã được huỷ liên kết thành công.",
    deletacardFaild: "Huỷ liên kết thẻ thất bại",
    cardFPT: "Thẻ nhân viên FPT",
    pointUtopDay: "điểm Utop/ngày",
    renameCard: "Đặt lại tên thẻ",
    updateInfoSuccess: "Cập nhật thành công",
    updateInfoDetailSuccess: "Chúc mừng, bạn đã đổi tên thẻ thành công.",
    updateInfoFaild: "Cập nhật thất bại",
    updateInfoDetailFaild: "Đã có lỗi xảy ra. Xin vui lòng thử lại.",
  },
  UtopCard: {
    LinkCardSuccess: {
      title: "Liên kết thẻ thành công",
      txtDetail: "Thẻ của bạn đã được liên kết thành công.",
      txtDone: "Xong",
    },
    LinkCardFaild: {
      title: "Liên kết thẻ thất bại",
      txtDetail: "Đã có lỗi xảy ra không mong muốn. Xin vui lòng thử lại.",
      txtClose: "Đóng",
    },
  },
  UtopScan: {
    UpdateVersionApp: "Vui lòng cập nhật ứng dụng để quét QR code này.",
    PermisionCameara: "Vui lòng cho phép quyền truy cập Camera",
    DetailCamera: "Di chuyển camera để quét mã QR đặt tại quầy thu ngân.",
    Accept: "ĐỒNG Ý",
    QRFailed: "Mã QR Code không đúng định dạng. Vui lòng thử lại",
  },
  UB: {
    CurrencyUnit: "đ",
    MemberShip: {
      title: "Thẻ thành viên",
      created: "THÀNH VIÊN TỪ",
      budgetManage: "Quản lý ngân sách",
      transactionHistory: "Lịch sử giao dịch",
      reservationManage: "Quản lý đặt chỗ",
      inputToSearch: "Nhập để tìm kiếm…",
      notFoundStore: "Rất tiếc, chúng tôi không tìm thấy nhà hàng nào",
      isInactiveAccount: "Tài khoản Utop Business của bạn đã hết hiệu lực!",
      Filter: {
        titleSearch: "BỘ LỌC TÌM KIẾM",
        titleCity: "TỈNH THÀNH",
        titleDistrict: "QUẬN HUYỆN",
        back: "Quay lại",
        searchProvince: "Tìm tỉnh, thành",
        searchDistrict: "Tìm quận, huyện",
        area: "Khu vực",
        province: "Tỉnh, Thành phố",
        district: "Quận, Huyện",
        price: "Giá",
        storeType: "Loại hình",
        apply: "Áp dụng",
        reset: "Thiết lập lại",
        pickCity: "Chọn tỉnh/thành",
        pickDistrict: "Chọn quận/huyện",
        all: "Tất cả",
        note: "Bộ lọc chỉ hiển thị những khu vực khả dụng",
        notFoundData: "Không tìm thấy dữ liệu phù hợp",
      },
    },
    BudgetManage: {
      title: "Quản lý ngân sách",
      emptyTransactions: "Hiện tại chưa có giao dịch nào",
      sourceBudget: "Nguồn ngân sách",
      allocator: "Cấp bởi",
      expiryDate: "Ngày hoàn ứng",
      totalAmount: "Số tiền",
      availableAmount: "Còn lại",
      payByQR: "Thanh toán QR",
      advancePayment: "Cấp tạm ứng",
      recentTransactions: "GIAO DỊCH GẦN ĐÂY",
      err: {
        emptyBudget:
          "Hiện bạn không có nguồn ngân sách nào để thực hiện chức năng này!",
        canNotReallocate:
          "Nguồn ngân sách hiện tại không được cho phép để thực hiện cấp tạm ứng.",
        notEnoughBalance:
          "Số dư ngân sách hiện tại không thỏa điều kiện để cấp tạm ứng.",
        expiredBudget:
          "Nguồn ngân sách hiện tại đã hết hiệu lực. Không thể thực hiện cấp tạm ứng.",
      },
      SourceBudget: {
        title: "Nguồn ngân sách",
        allocateBy: "Người cấp",
        availableAmount: "Số dư",
        expiryDate: "Ngày hoàn ứng",
      },
    },
    AdvancePayment: {
      title: "Cấp tạm ứng",
      fromBudget: "TỪ NGÂN SÁCH",
      availableAmount: "Số dư",
      to: "ĐẾN",
      email: "Email",
      inputEmail: "Nhập email",
      amount: "Số tiền",
      inputAmount: "Nhập số tiền tạm ứng",
      expiryDate: "Ngày hoàn ứng",
      content: "Nội dung",
      inputContent: "Nhập nội dung",
      advancePaymentBudget: "Cấp tạm ứng ngân sách",
      reallocatable: "Chuyển tiếp tạm ứng",
      explain:
        "Cho phép người nhận số tiền tạm ứng này có quyền chuyển tiếp cho các thành viên khác.",
      continue: "Tiếp tục",
      err: {
        cantAdvancePayment: "Không thể cấp tạm ứng cho thành viên này.",
        amountRequired: "Số tiền tối thiểu là 1.000đ",
        notEnoughBudgetAmount: "Số dư ngân sách không đủ.",
        contentEmpty: "Vui lòng nhập nội dung",
      },
      SourceBudget: {
        title: "Nguồn ngân sách",
        sourceBudget: "Nguồn ngân sách",
        availableAmount: "Số dư",
        expiryDate: "Ngày hoàn ứng",
      },
    },
    ConfirmBudget: {
      title: "Xác nhận tạm ứng",
      fromBudget: "TỪ NGÂN SÁCH",
      availableAmount: "Số dư",
      to: "ĐẾN",
      email: "Email",
      receiveName: "Tên người nhận",
      amount: "Số tiền",
      expiryDate: "Ngày hoàn ứng",
      reallocatable: "Chuyển tiếp tạm ứng",
      yes: "Có",
      no: "Không",
      content: "Nội dung",
      confirm: "Xác nhận",
      err: {
        InvalidExpiryDate: "Ngày hoàn ứng không hợp lệ.",
        InvalidNewBudgetHolder: "Tài khoản nhận tạm ứng không đúng.",
        InvalidSourceBudget: "Ngân sách không khả dụng.",
        InputValidationFail: "Thông tin cấp tạm ứng không hợp lệ.",
        UnallocatableSourceBudget: "Không thể cấp tạm ứng với ngân sách này.",
        InvalidReceiverAccount: "Tài khoản nhận tạm ứng không tồn tại.",
        InvalidAmount:
          "Số tiền tạm ứng không được lớn hơn số tiền của ngân sách đang chọn.",
        InvalidAllocatingBudget: "Ngân sách hiện tại không khả dụng.",
        AlreadyOwner:
          "Tài khoản nhận tạm ứng đang thuộc danh sách sở hữu ngân sách đang chọn.",
        defaultConfirmBudfet:
          "Đã có lỗi xảy ra trong quá trình cấp tạm ứng, vui lòng thử lại!",
      },
    },
    AdvanceSuccess: {
      title: "Thông tin tạm ứng",
      advanceSuccess: "CẤP TẠM ỨNG THÀNH CÔNG",
      transactionCode: "Mã giao dịch",
      fromBudget: "Từ ngân sách",
      receiveName: "Người nhận",
      amount: "Số tiền",
      expiredDate: "Có hiệu lực đến",
      reallocatable: "Chuyển tiếp tạm ứng",
      yes: "Có",
      no: "Không",
      content: "Nội dung",
      close: "Đóng",
      continueAdvance: "Tạm ứng tiếp",
    },
    AdvanceDetail: {
      title: "Chi tiết tạm ứng",
      emptyTransactions: "Hiện tại chưa có giao dịch nào",
      revoke: "Thu hồi tạm ứng",
      receiveName: "Người nhận",
      amountAdvance: "Số tiền tạm ứng",
      availableAmount: "Còn lại",
      content: "Nội dung",
      transactionsHistory: "LỊCH SỬ GIAO DỊCH",
      confirmRevoke: "Bạn có chắc muốn thu hồi ngân sách đã tạm ứng cho ",
      alert: {
        confirm: "Xác nhận",
        cancel: "Huỷ",
      },
      err: {
        InvalidReceiver: "Tài khoản nhận hoàn ứng không tồn tại!",
        InvalidBudget: "Ngân sách nhận hoàn ứng không khả dụng.",
        InvalidRevokingBudget: "Ngân sách hoàn ứng không hợp lệ!",
        defaultConfirmRevoke:
          "Đã có lỗi xảy ra trong quá trình hoàn ứng ngân sách, vui lòng thử lại!",
      },
    },
    UtopBusinessHistory: {
      title: "Lịch sử giao dịch",
      emptyTransactions: "Hiện tại chưa có giao dịch nào",
      listPaymentMethodHistory: {
        all: "Tất cả",
        payment: "Thanh toán",
        budget: "Thu chi ngân sách",
      },
    },
    UtopBusinessBooking: {
      title: "Đặt chỗ trước",
      bookingInfo: "THÔNG TIN ĐẶT CHỖ",
      numberOfGuest: "Số lượng khách",
      inputNumber: "Nhập số lượng khách",
      bookingDate: "Ngày đặt chỗ",
      pickDate: "Chọn ngày đặt",
      pickTime: "Chọn thời gian",
      bookingTime: "Thời gian đến",
      note: "Ghi chú",
      inputNote: "Nhập ghi chú",
      continue: "Tiếp tục",
      PickupTime: {
        bookingTime: "Thời gian đến",
        confirm: "Xác nhận",
      },
      err: {
        requiredPickDate: "Vui lòng chọn ngày đặt chỗ!",
        storeClose:
          "Rất tiếc, nhà hàng không hoạt động vào thời điểm mà bạn vừa chọn!",
      },
      emptyTransactions: "Bạn chưa có thông tin đặt chỗ nào",
    },
    Transactions: {
      payAt: "Thanh toán tại ",
      cancelBy: "Hoàn thanh toán từ ",
      receiveBudgetBy: "Nhận tạm ứng ngân sách từ ",
      allocateBudgetFor: "Cấp tạm ứng ngân sách cho ",
      receiveRevokedBudgetBy: "Nhận hoàn ứng ngân sách từ ",
      revokeBudgetFor: "Hoàn ứng ngân sách cho ",
      waitConfirmPayment: "Chờ xác nhận thanh toán tại ",
      paymentStatement: " thanh toán sao kê Utop Business kỳ ",
      paymentFailedAt: "Thanh toán không thành công tại ",
    },
    UtopBusinessStoreView: {
      StoreImage: "Hình ảnh nhà hàng",
      SeeMore: "Xem thêm",
      Peole: "khách",
      Intro: "Giới thiệu",
      Service: "Dịch vụ",
      ErrorDetail:
        "Đã có lỗi xảy ra khi tải thông tin nhà hàng, vui lòng thử lại sau!",
    },
    UtopBusinessStoreImage: { Title: "Hình ảnh nhà hàng" },
    UtopBusinessBookingManager: {
      Title: "Quản lý thông tin đặt chỗ",
      Booked: "Đã đặt",
      Confirm: "Xác nhận",
      Cancel: "Đã huỷ",
      Time: "Thời gian",
      NumberCustomer: "Số lượng khách",
      Completed: "Hoàn tất",
    },
    UtopBusinessBookingDetail: {
      Title: "Thông tin đặt chỗ",
      Booked: "Đã đặt",
      Confirm: "Xác nhận",
      Cancel: "Đã huỷ",
      Completed: "Hoàn tất",
      CancelQuestion: "Bạn có chắc muốn hủy yêu cầu đặt chỗ trước này?",
      NumberCustomer: "Số lượng khách",
      DateBooking: "Ngày đặt chỗ",
      TimeBooking: "Thời gian đến",
      Note: "Ghi chú",
      Contact: "THÔNG TIN LIÊN HỆ",
      Name: "Tên khách hàng",
      PhoneNumber: "Số điện thoại",
      CancelAction: "Huỷ đặt chỗ",
      Accept: "Xác nhận",
      FailedHandle: "Đã có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại.",
      CancelSuccess: "Bạn đã huỷ đặt bàn thành công.",
      Close: "Đóng",
    },
    TransactionDetail: {
      Title: "Chi tiết giao dịch",
      BudgetAdvance: "Cấp tạm ứng ngân sách",
      Receiver: "Người nhận",
      BuggetSoucre: "Từ ngân sách",
      ReceiveBudgetAdvance: "Nhận tạm ứng ngân sách",
      Sender: "Người gửi",
      RefundTheBudget: "Hoàn ứng ngân sách",
      ReceiveRefundTheBudget: "Nhận hoàn ứng ngân sách",
      BuggetReceive: "Đến ngân sách",
      ReceiveRefundTheBudgetExpired: "Nhận hoàn ứng ngân sách hết hiệu lực",
      RefundTheBudgetExpired: "Hoàn ứng ngân sách hết hiệu lực",
      RevokeAdvanceBudget: " thu hồi tạm ứng ngân sách",
      Pay: "Thanh toán",
      RefundPay: "Hoàn thanh toán",
      PaymentOfStatement: "Thanh toán sao kê",
      TransactionSuccess: "Giao dịch thành công",
      TransactionFailed: "Giao dịch không thành công",
      TransactionWaiting: "Chờ xác nhận thanh toán",
      TransactionHash: "Mã giao dịch",
      Time: "Thời gian",
      MoreInfo: "Thông tin thêm",
      Description: "Nội dung",
      PayAt: "Thanh toán tại",
      AttenderCount: "Số lượng khách",
      PaymentInvoice: "Hoá đơn thanh toán",
      SeeImage: "Xem hình",
      LegalEntity: "Thông tin xuất hoá đơn",
      Note: "Ghi chú",
      DateRefund: "Ngày hoàn ứng",
      Reallocatable: "Chuyển tiếp tạm ứng",
      Note2: "Lưu ý",
      ContentNote2:
        "Giao dịch đang đợi xác nhận, số tiền thanh toán sẽ được hoàn lại trong trường hợp thanh toán thất bại.",
      Yes: "Có",
      No: "Không",
      PaymentFailedAt: "Thanh toán không thành công tại ",
      RefundPayDescription:
        "Utop thực hiện yêu cầu hoàn thanh toán cho giao dịch tại: ",
    },
    VerifyBusiness: {
      TitleHeader: "Xác nhận liên kết Utop Business",
      Title:
        "Xác nhận liên kết tài khoản Utop hiện tại với các tiện ích Utop Business",
      RegisteredBy: "Đăng ký bởi",
      MemberName: "Tên thành viên",
      PhoneNumber: "Số điện thoại",
      ChangeAccount: "Thay đổi tài khoản",
      Confirm: "Xác nhận",
      Message1: "Tài khoản của bạn đã là thành viên Utop Business thuộc ",
      Message2a: "Tài khoản Utop Business thuộc ",
      Message2b: " của bạn đã hết hiệu lực.",
      Message3: "Đường dẫn xác thực Utop Business của bạn đã hết hiệu lực!",
      MessageDefaul:
        "Tài khoản Utop Business của bạn đã hết hiệu lực hoặc có lỗi xảy ra.",
      MessageError: "Đã có lỗi xảy ra. Vui lòng thử lại.",
      Logout: "Đăng xuất khỏi ứng dụng?",
      LogoutError: "Có lỗi trong quá trình đăng xuất.\nVui lòng thử lại sau.",
      Cancel: "HUỶ",
      Accept: "ĐỒNG Ý",
      Close: "Đóng",
    },
    ConfirmBooking: {
      title: "Xác nhận đặt chỗ",
      bookingInfo: "THÔNG TIN ĐẶT CHỖ",
      numberOfGuest: "Số lượng khách",
      bookingDate: "Ngày đặt chỗ",
      bookingTime: "Thời gian đến",
      note: "Ghi chú",
      contactInfo: "THÔNG TIN LIÊN HỆ",
      guestName: "Tên khách hàng",
      numberPhone: "Số điện thoại",
      careful: "LƯU Ý",
      storeInfo: "**Nhà hàng sẽ giữ chỗ cho bạn trong 30 phút.",
      confirm: "Xác nhận",
      popup: {
        title: "Đã nhận thông tin đặt chỗ",
        detail:
          "Utop sẽ tiến hành đặt chỗ tại nhà hàng và thông báo đến bạn trong thời gian sớm nhất.",
      },
      err: {
        InvalidUser: "Tài khoản người dùng không tồn tại!",
        InvalidStore:
          "Nhà hàng không tồn tại hoặc không hỗ trợ thanh toán Utop Business.",
        InvalidReservationDate: "Thời gian đặt chỗ không hợp lệ!",
        InvalidOpenTime:
          "Thời gian đặt chỗ đã nằm ngoài thời gian phục vụ của nhà hàng!",
        InputValidationFail: "Dữ liệu đặt chỗ không hợp lệ.",
        defaultConfirmBooking:
          "Đã có lỗi xảy ra trong quá trình đặt chỗ, vui lòng thử lại!",
      },
    },
  },
  FnBRating: {
    labelExistRating: "Đơn hàng đã được đánh giá.",
    labelConfirm: "Xác nhận",
    labelRatingTitle:
      "Đánh giá của bạn giúp cho chất lượng sản phẩm tại cửa hàng được cải thiện hơn",
    labelCommentPlaceholder:
      "Chia sẻ cảm nhận của bạn và giúp khách hàng khác đưa ra quyết định mua sản phẩm.",
    labelRatingThanks: "Cảm ơn bạn đã đánh giá!",
    labelLikeCount: "lượt thích",
    labelLike: "Thích",
    labelBuyer: "Người mua hàng",
    labelClose: "Đóng",
    labelRatingError: `Có gì đó không ổn trong phần đánh giá.
      Vui lòng kiểm tra lại.`,
    labelRatingErrorTitle: "Đánh giá thất bại",
    labelEmpltyComment: "Không có đánh giá nào!",
    labelAllRating: "Tất cả đánh giá",
    writeReply: "Viết phản hồi",
    listLike: "Danh sách lượt thích",
    invalidComment: "Bình luận không hợp lệ. Vui lòng thử lại!",
    sendCommentFail: "Gửi bình luận thất bại!",
    copied: "Đã sao chép",
    labelRateTitle: "đánh giá",
    selectReason: "Vui lòng chọn lí do báo cáo phản hồi",
    placeholderOtherReason: "Vui lòng nêu lí do khác",
    otherReason: "Vi phạm khác",
    titleReportReason: "Báo cáo phản hồi",
    deleteRating: "Xoá đánh giá",
    deleteReply: "Xoá phản hồi",
    confirmDeleteRating: "Bạn có chắc chắn muốn xóa đánh giá này?",
    confirmDeleteReply: "Bạn có chắc chắn muốn xóa phản hồi này?",
    thankReport: "Cảm ơn bạn đã cho chúng tôi biết!",
    yourFeedback:
      "Ý kiến đóng góp của bạn giúp hệ thống chúng tôi biết khi có nội dung không phù hợp.",
    done: "Xong",
    sendReport: "Gửi báo cáo",
    seePreReply: "Xem phản hồi trước...",
    unknownError: "Không thể kết nối với máy chủ!",
    save: "Lưu",
    SelectReason: "Vui lòng chọn lý do báo cáo!",
    reportError: "Gửi báo cáo bị lỗi, vui lòng kiểm tra lại!",
    ratingImagesTitle: "Hình ảnh",
    commentSuggest:
      "Hãy là người đầu tiên chia sẻ\ncảm nhận của bạn về cửa hàng.",
    commentCartText:
      "Tạm chưa có lời bình, mua ngay\nđơn hàng và đánh giá cửa hàng",
    commentsText:
      "Chia sẻ cảm nhận của bạn giúp người\ndùng khác tìm được trải nghiệm tốt hơn.",
    rate: "Đánh giá",
    uploadRatingImage: "Đăng hình ảnh",
    selectImageFrom: "Chọn hình ảnh từ",
    ratingWithComment: "Đánh giá có bình luận",
  },
  requireVersionPopup: {
    description:
      "Phiên bản hiện tại không được hỗ trợ. Vui \n lòng cập nhật để tiếp tục sử dụng!",
  },
  utopBack: {
    titleScreen: "UtopBACK",
    utopCashBack: "Utop đã hoàn lại",
    subTitleHeader:
      "Sử dụng ứng dụng Utop mua hàng từ \ncác đối tác để được hoàn lại điểm Utop",
    noData: "Không có dữ liệu",
    howCashBack: "Làm thế nào để được hoàn Utop?",
    utopCash: "Hoàn Utop",
    utopWaitApprove: "Utop đang chờ duyệt",
    waiting: "Chờ duyệt",
    approve: "Chờ hoàn",
    cashBack: "Đã hoàn",
    cancel: "Đã huỷ",
    BuyNow: "Mua ngay",
    amountUtopRefunded: "Số Utop đã hoàn lại",
    amountUtopWillRefunded: "Số Utop sẽ hoàn lại",
    valueOrder: "Giá trị đơn hàng",
    TradeDate: "Ngày giao dịch",
    orderCode: "Mã đơn hàng",
    order: "Đơn hàng",
    textUtopBack: "UtopBack",
  },
  MapOrderStatus: {
    screenName: "Theo dõi đơn hàng",
    labelSupport: "Bạn cần hỗ trợ?",
    labelSupportOrder: "Hỗ trợ đơn hàng",
    labelContactHotline: "Vui lòng liên hệ hotline ",
    labelToSupport: " để \nđược hỗ trợ.",
    labelClose: "Đóng",
    labelCallNow: "Gọi ngay",
  },
  Referral: {
    refererReward: "Quà cho người giới thiệu",
    firstReferralsReward: "Quà cho người giới thiệu lần đầu",
    presenteeReward: "Quà cho người Được giới thiệu",
    validInvitation: "Đã mời hợp lệ",
    invitationSentList: "Danh sách đã gửi lời mời",
    people: "người",
    instructYourFriends: "Hướng dẫn bạn bè thực hiện để cả 2 cùng có quà",
    listGift: "DANH SÁCH QUÀ TẶNG",
    hasTrans: "Đã thực hiện giao dịch",
    noTrans: "Chưa thực hiện giao dịch",
    Modal: {
      guide: "Hướng dẫn bạn bè thực hiện các BƯỚC để cả 2 cùng có quà.",
      step1: {
        title: "Bước 1",
        desc: "Nhấn vào liên kết và tải app Utop",
      },
      step2: {
        title: "Bước 2",
        desc: "Nhập số điện thoại đăng kí",
      },
      step3: {
        title: "Bước 3",
        desc: "Nhập mã OTP xác nhận số điện thoại",
      },
      step4: {
        title: "Bước 4",
        desc: "Tạo mật khẩu và đăng nhập",
      },
      step5: {
        title: "Bước 5",
        desc: "Mua hàng",
      },
      completed: {
        title: "Hoàn thành",
        desc: "Nhận quà từ Utop",
      },
    },
    Home: {
      title: "Giới thiệu bạn bè",
      copyDesc: "Đã sao chép vào Clipboard",
      inviteFriends: "Mời bạn bè tải Utop ngay thôi!",
      inviteFriendsDesc:
        "Gửi liên kết này cho 5 người bạn để có thể cài đặt và nhận quà từ Utop nhé.",
    },
    ListInvited: {
      title: "Danh sách đã gửi lời mời",
    },
  },
  changePointPartner: {
    screenName: "Đổi điểm",
    partnerText: "Đối tác",
    connectWithPartnerText: "Kết nối đối tác",
    exchangePointText: "Quy đổi",
    directionText: "Hướng dẫn",
    exchangeText: "Đổi",
    receiveText: "Nhận",
    noteExchange:
      "Số tiền được ứng để nạp Utop sẽ được trừ vào kì lương hàng tháng",
    changePartner: "Thay đổi",
    exchangeAll: "Đổi hết",
    balanceText: "Số dư",
    noteExchange1: "Số tiền quy đổi không được vượt quá số dư hiện có.",
    noteExchange2: "*   (1.000 đ = 1 Utop)",
    continueText: "Tiếp tục",
    pressText: "NHẤN",
    toConnectPartnerText: "để thêm đối tác",
    knowText: "Đã hiểu",
    inputText: "NHẬP",
    exchangePointNote: "số tiền muốn quy đổi",
    invalidExchangePoint: "Số tiền không hợp lệ",
    addOtherPartnerText: "Chọn đối tác khác",
  },
  confirmExchangePoint: {
    exchangeText: "Đổi",
    receiveText: "Nhận",
    partnerText: "Đối tác",
    screenName: "Xác nhận thông tin",
    detailInformation: "Thông tin chi tiết",
    noteExchange: "(1.000 đ = 1 Utop)",
    confirmText: "Xác nhận",
    infoPartnerInvalidText: "Thông tin đối tác không hợp lệ",
  },
  loginPartner: {
    screenName: "Kết nối",
  },
  changePointSelectionPartner: {
    screenName: "Thêm đối tác",
    infoPartnerInvalidText: "Thông tin đối tác không hợp lệ",
  },
  exchangePointSuccess: {
    screenName: "Thông tin giao dịch",
    exchangePointSuccessText: "Giao dịch thành công",
    description:
      "Số tiền tương ứng sẽ được truy thu vào kì lương tháng tiếp theo.",
    exchangeText: "Đổi",
    receiveText: "Nhận",
    partnerText: "Đối tác",
    transactionCodeText: "Mã giao dịch",
    transactionTimeText: "Thời gian",
    goHomeButtonLabel: "Về trang chủ",
  },
  OrderPartnerDetail: {
    screenName: "Thông tin đơn hàng",
    transactionCodeLabel: "Mã giao dịch",
    orderDateLabel: "Thời gian đặt hàng",
    descriptionLabel: "Mô tả",
    amountLabel: "Số tiền thanh toán",
    paymentMethodLabel: "Phương thức thanh toán",
    orderStatusLabel: "Trạng thái đơn hàng",
    detailButtonLabel: "Xem chi tiết",
    deliveredStatusLabel: "Hoàn thành",
    newStatusLabel: "Chưa thanh toán",
    cancelStatusLabel: "Đã hủy",
  },
  PaymentWebview: {
    screenName: "Thanh toán",
  },
  UtopTLIP: {
    common: {
      justFrom: "Chỉ từ",
      pullToRefresh: "Pull to refresh",
      paymentSuccess: "THANH TOÁN THÀNH CÔNG",
      paymentFail: "THANH TOÁN THẤT BẠI",
      total: "Tổng cộng",
      paymentMethods: "Phương thức thanh toán",
      utopMethod: "Điểm Utop",
      packageName: "Tên gói",
      option: "Tùy chọn",
      period: "Kỳ hạn",
      transactionCode: "Mã giao dịch",
      time: "Thời gian",
      contactContentHotline: "** Mọi thắc mắc xin vui lòng liên hệ hotline",
      phoneNumberUtop: "1900636997",
      phoneNumberMSIG: "02439369188",
      contentOrContactMSIG: " hoặc hotline MSIG VN",
      contactContentHotlineUtop:
        "** Mọi thắc mắc xin vui lòng liên hệ hotline Utop",
      year: "năm",
      pointUtop: "Điểm Utop",
      Utop: "Utop",
      unitVND: "đ",
      timeTransaction: "Thời gian giao dịch",
      orderStatus: "Trạng thái đơn hàng",
      cancelOrder: "Hủy đơn",
      payment: "Thanh toán",
      amountOfMoney: "Số tiền",
      emptyData: "Không có dữ liệu",
      customerInfo: "Thông tin khách hàng",
      workplace: "Nơi làm việc",
      fullName: "Họ tên",
      employeeCode: "Mã nhân viên",
      phoneNumber: "Số điện thoại",
      email: "Email",
      dateBirth: "Ngày sinh",
      gender: "Giới tính",
      nationality: "Quốc tịch",
      peopleIDOrPassport: "Số CMT/Hộ chiếu",
      dateRange: "Ngày cấp",
      placeOfIssue: "Nơi cấp",
      entryVisaNumber: "Số thị thực nhập cảnh",
      addressOfRegisteredPlaceOfResidenceAbroad:
        "Địa chỉ nơi đăng ký cư trú ở nước ngoài",
      addressOfRegisteredResidenceInVietnam:
        "Địa chỉ nơi đăng ký cư trú ở Việt Nam",
      permanentAddress: "Địa chỉ thường trú",
      addressContact: "Địa chỉ liên hệ",
      job: "Nghề nghiệp",
      position: "Chức vụ",
      unpaid: "Chưa thanh toán",
      finish: "Hoàn thành",
      cancelled: "Đã hủy",
      male: "Nam",
      females: "Nữ",
      error: "Đã có lỗi xảy ra",
      buyMore: "Mua thêm",
      done: "Done",
      notInfo: "Thông tin không được bỏ trống",
      phoneNumberNotWork: "Số điện thoại không hợp lệ",
      emailIisIncorrect: "Email không hợp lệ",
      nationalityIsIncorrect: "Quốc tịch không hợp lệ",
      peopleIDOrPassportIsIncorrect: "Số CMT/Hộ chiếu không hợp lệ",
      cancel: "Hủy",
      agree: "Đồng ý",
      cancelOrderTransaction: "Bạn có muốn hủy đơn hàng này không?",
      companyIsIncorrect: "Tên công ty không hợp lệ",
      fullNameIsIncorrect: "Họ và tên không hợp lệ",
      jobPositionIsIncorrect: "Chức vụ không hợp lệ",
      jobIsIncorrect: "Nghề nghiệp không hợp lệ",
      addressContactIsIncorrect: "Địa chỉ liên hệ không hợp lệ",
      permanentAddressIsIncorrect: "Địa chỉ thường trú không hợp lệ",
      placeOfIssueIsIncorrect: "Nơi cấp không hợp lệ",
      employeeCodeIsIncorrect: "Mã nhân vên không hợp lệ",
      invalidEffectiveDate: "Đơn hàng đã hết thời gian thanh toán",
      effectiveStartDate: "Ngày bắt đầu hiệu lực",
      effectiveEndDate: "Ngày hết hiệu lực",
      dateText: "NGÀY",
      monthText: "THÁNG",
      yearText: "NĂM",
      selectDateText: "Chọn ngày",
      detailPeopleIdOrPassport: "Số Chứng minh thư/CCCD/Hộ chiếu",
      enterYourCompanyName: "Nhập tên công ty của bạn...",
      enterYourFullName: "Nhập họ tên của bạn...",
      enterYourEmployeeId: "Nhập mã nhân viên của bạn...",
      enterYourPhoneNumber: "Nhập số điện thoại của bạn...",
      enterYourEmail: "Nhập email của bạn...",
      placeholderBornDate: "01/01/1991",
      enterYourNational: "Nhập quốc tịch của bạn...",
      enterYourDetailPeopleIdOrPassport:
        "Nhập số chứng minh thư/CCCD/Hộ chiếu...",
      enterYourPlaceProvided: "Nhập nơi cấp của bạn...",
      enterYourPlace: "Nhập địa chỉ thường trú của bạn...",
      enterYourAddressContact: "Nhập địa chỉ liên hệ của bạn...",
      enterYourJob: "Nhập nghề nghiệp của bạn...",
      enterYourJobPosition: "Nhập chức vụ của bạn...",
    },
    listInsuranceScreen: {
      listInsuranceScreenTitle: "Bảo hiểm",
    },
    insuranceDetail: {
      buttonConfirmTitle: "Mua sản phẩm",
      sumText: "Tổng cộng",
      periodTitle: "Kỳ hạn",
      effectiveStartDate: "Ngày bắt đầu hiệu lực",
      effectiveEndDate: "Ngày hết hiệu lực",
      buttonSelectTitle: "Chọn",
      buttonCancelTitle: "Hủy bỏ",
    },
    transactionInfoScreen: {
      transactionInfoScreenTitle: "Thông tin giao dịch",
      titleButtonPaymentAgain: "Thanh toán lại",
    },
    orderDetailScreen: {
      orderCode: "Mã đơn hàng:",
      labelOrderDetail: "Chi tiết giao dịch",
      paid: "ĐÃ THANH TOÁN",
    },
    confirmInfoScreen: {
      screenTitle: "Xác nhận thông tin",
      partner: "Đối tác",
      detailInfo: "Thông tin chi tiết",
      buttonConfirm: "Xác nhận",
      effectiveStartDate: "Ngày bắt đầu hiệu lực",
      effectiveEndDate: "Ngày hết hiệu lực",
    },
    confirmInsuranceProduct: {
      screenTitle: "Điểm lưu ý",
      rejectButtonTitle: "Huỷ bỏ",
      submitButtonTitle: "Xác nhận",
      confirmText: "Tôi đã đọc và hiểu nội dung lưu ý trên",
      numericalOrder: "STT",
      listOfDiseases: "Danh sách các bệnh",
    },
    getInfoCustomer: {
      screenTitle: "Nhập thông tin",
      headingText: "Thông tin khách hàng",
      vietnameseInitValue: "Người Việt Nam",
      buttonNextTitle: "Tiếp tục",
    },
  },
  ListDealVoucher: {
    title: "Danh sách Deal/Voucher",
  },
};
