import {GET_LIST_CITY_SUCCESS} from './constants';

const initialState = {
  cities: [],
};

const addressReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LIST_CITY_SUCCESS: {
      return {...state, cities: action.cities};
    }
    default:
      return state;
  }
};

export default addressReducer;
