/* eslint-disable no-async-promise-executor */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-param-reassign */
/* eslint-disable no-throw-literal */
/* eslint-disable no-empty */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-vars */
import AppConfigs from "configs/env";
import axios from "axios";

import { authFirebase } from "configs/firebase";

const REQUEST_TIMEOUT = 60000;

const buildHeaderWithAuthorization = async (config) => {
  const token = await APIUtils.reauthenticate();
  if (!config.headers) {
    config.headers = {};
  }
  // console.log(
  //   "axios.interceptors.request1111",
  //   config,
  //   config.headers.hasOwnProperty("Authorization")
  // );
  if (
    token &&
    config &&
    config.headers &&
    !config.headers.hasOwnProperty("Authorization")
  ) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  // Modify the url or config here
  return config;
};

const buildURLWithParams = (url, params = {}) => {
  let requestedURL = url;
  if (params) {
    const keys = Object.keys(params);

    if (Array.isArray(keys) && keys.length > 0) {
      requestedURL += "?";
      for (const property of keys) {
        const index = keys.indexOf(property);
        if (index > 0 && index < keys.length) {
          requestedURL += "&";
        }
        requestedURL += `${property}=${params[property]}`;
      }
    }
  }
  return requestedURL;
};

export default class APIUtils {
  static async reauthenticate() {
    if (authFirebase.currentUser) {
      const idToken = await authFirebase.currentUser.getIdToken(false);
      return idToken;
    }
    return "";
  }

  static get(url, config = { headers: {}, params: {} }) {
    // console.log("APIUtils>>>>>");
    return new Promise(async (resolve, reject) => {
      const headersDefault = {
        "Content-Type": "application/json",
        Accept: "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        "Api-version": AppConfigs.apiVersion,
        "Ocp-Apim-Subscription-Key":
          process.env.REACT_APP_OCP_APIM_SUBSCRIPTION_KEY,
        e_platform: "Web",
      };
      const { headers, params, ...restConfig } = config;
      const requestedURL = buildURLWithParams(url, params);

      // eslint-disable-next-line no-undef
      const controller = new AbortController();
      const { signal } = controller;

      const fetchConfig = await buildHeaderWithAuthorization({
        cache: "default",
        credentials: "include",
        headers: {
          ...headersDefault,
          ...config.headers,
        },
        ...restConfig,
        method: "GET",
        signal,
      });

      setTimeout(() => {
        controller.abort();
      }, REQUEST_TIMEOUT);
      // console.log(">>>>>request>>>>>", {
      //   url: requestedURL,
      //   config: fetchConfig,
      // });

      axios({ ...fetchConfig, url: requestedURL })
        .then(async (response) => {
          // console.log(">>>>>response>>>>>", { url, data: response.data });

          resolve(response);
        })
        .catch((error) => {
          // console.log(">>>>>error>>>>>", { url: requestedURL, error });
          reject(error);
        });
    });
  }

  static post(url, config = { headers: {} }) {
    return new Promise(async (resolve, reject) => {
      const headersDefault = {
        "Content-Type": "application/json",
        Accept: "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        "Api-version": AppConfigs.apiVersion,
        "Ocp-Apim-Subscription-Key": AppConfigs.subscriptionKey,
        e_platform: "mobile",
      };
      const { headers, body, params, ...restConfig } = config;

      // eslint-disable-next-line no-undef
      const controller = new AbortController();
      const { signal } = controller;

      const fetchConfig = await buildHeaderWithAuthorization({
        cache: "default",
        credentials: "include",
        headers: {
          ...headersDefault,
          ...config.headers,
        },
        ...restConfig,
        signal,
        method: "POST",
        data: config.body,
      });
      setTimeout(() => {
        controller.abort();
      }, REQUEST_TIMEOUT);
      // console.log(">>>>>request>>>>>", {
      //   url,
      //   config: fetchConfig,
      // });
      axios({ ...fetchConfig, url })
        .then(async (response) => {
          // console.log(">>>>>response>>>>>", { url, data: response.data });

          resolve(response);
        })
        .catch((error) => {
          // console.log(">>>>>error>>>>>", { url, ...error.response });
          reject(error);
        });
    });
  }

  static delete(url, config = { headers: {} }) {
    return new Promise(async (resolve, reject) => {
      const headersDefault = {
        "Content-Type": "application/json",
        Accept: "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        "Api-version": AppConfigs.apiVersion,
        "Ocp-Apim-Subscription-Key": AppConfigs.subscriptionKey,
        e_platform: "mobile",
      };
      const { headers, body, params, ...restConfig } = config;

      // eslint-disable-next-line no-undef
      const controller = new AbortController();
      const { signal } = controller;

      const fetchConfig = await buildHeaderWithAuthorization({
        cache: "default",
        credentials: "include",
        headers: {
          ...headersDefault,
          ...config.headers,
        },
        ...restConfig,
        signal,
        method: "DELETE",
        body: JSON.stringify(config.body),
      });
      setTimeout(() => {
        controller.abort();
      }, REQUEST_TIMEOUT);
      // console.log(">>>>>request>>>>>", {
      //   url,
      //   config: fetchConfig,
      // });
      axios({ ...fetchConfig, url })
        .then(async (response) => {
          // console.log(">>>>>response>>>>>", { url, data: response.data });

          resolve(response);
        })
        .catch((error) => {
          // console.log(">>>>>error>>>>>", { url, error });
          reject(error);
        });
    });
  }

  static put(url, config = { headers: {} }) {
    return new Promise(async (resolve, reject) => {
      const headersDefault = {
        "Content-Type": "application/json",
        Accept: "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        "Api-version": AppConfigs.apiVersion,
        "Ocp-Apim-Subscription-Key": AppConfigs.subscriptionKey,
        e_platform: "mobile",
      };
      const { headers, body, params, ...restConfig } = config;

      // eslint-disable-next-line no-undef
      const controller = new AbortController();
      const { signal } = controller;

      const fetchConfig = await buildHeaderWithAuthorization({
        cache: "default",
        credentials: "include",
        headers: {
          ...headersDefault,
          ...config.headers,
        },
        ...restConfig,
        signal,
        method: "PUT",
        data: config.body,
      });
      setTimeout(() => {
        controller.abort();
      }, REQUEST_TIMEOUT);
      // console.log(">>>>>request>>>>>", {
      //   url,
      //   config: fetchConfig,
      // });
      axios({ ...fetchConfig, url })
        .then(async (response) => {
          // console.log(">>>>>response>>>>>", { url, data: response.data });

          resolve(response);
        })
        .catch((error) => {
          // console.log(">>>>>error>>>>>", { url, error });
          reject(error);
        });
    });
  }
}
