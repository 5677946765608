import React from "react";
import classNames from "classnames";
import "./CheckBoxButton.scss";

const CheckBoxButton = ({
  isChecked = true,
  handleClick,
  className,
  style,
}) => {
  return (
    <div
      onClick={handleClick}
      className={classNames(
        "checkbox-button",
        isChecked && "checkbox-button--checked",
        className
      )}
      style={style}
    >
      <div className="rect">
        <div className="tick">{/*  */}</div>
      </div>
    </div>
  );
};

export default React.memo(CheckBoxButton);
