import { call, put, takeLatest, fork, select } from "redux-saga/effects";
import actions from "./actions";
import * as TYPE from "./constants";
import api from "../api/api";

const sagas = {
  *[TYPE.GET_LIST_DEAL.request](action) {
    const { storeId, merchantId, Latitude, Longitude, Skip, Take } =
      action.payload;
    try {
      const response = yield call(api[TYPE.GET_LIST_DEAL.request], {
        storeId,
        merchantId,
        Latitude,
        Longitude,
        Skip,
        Take,
      });

      if (response.status === 200) {
        const prevListDeal = yield select(getListDeal);
        let listDeal = {
          isInit: false,
          items: response.data,
          skip: Skip,
          take: Take,
        };
        let hasMore = true;

        // set flag for load more
        if (response.data?.length < Take) {
          hasMore = false;
        }

        // if already exists, append list items
        if (prevListDeal?.items?.length > 0) {
          listDeal = {
            ...listDeal,
            items: [...prevListDeal.items, ...listDeal.items],
          };
        }

        yield put(
          actions.GET_LIST_DEAL.success({
            ...listDeal,
            hasMore,
          })
        );
      } else {
        yield put(actions.GET_LIST_DEAL.failure(response?.data));
      }
    } catch (error) {
      yield put(actions.GET_LIST_DEAL.failure(error?.data));
    }
  },
};

function* watchedSaga() {
  yield takeLatest(
    TYPE.GET_LIST_DEAL.request,
    sagas[TYPE.GET_LIST_DEAL.request]
  );
}

export default function* rootChild() {
  yield fork(watchedSaga);
}

// *** Selectors
const getListDeal = (state) => state[TYPE.TYPE].listDeal;
