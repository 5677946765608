import { genActionTypes } from "utils/actionGenerator";

export const TYPE = "TopUpCard";

export const GET_LIST_CARD_TOPUP = genActionTypes(`${TYPE}_GET_LIST_CARD_TOPUP`);
export const GET_LIST_BUY_CARD = genActionTypes(`${TYPE}_GET_LIST_BUY_CARD`);
export const CREATE_ODER_TOPUP = genActionTypes(`${TYPE}_CREATE_ODER_TOPUP`);



