import { genActions } from "utils/actionGenerator";
import * as TYPE from "./constants";

const actions = {
  GET_RATING_LIST: genActions(TYPE.GET_RATING_LIST),
  GET_MERCHANT_INFO: genActions(TYPE.GET_MERCHANT_INFO),
  GET_STORE_INFO: genActions(TYPE.GET_STORE_INFO),
  GET_BEST_SELLER_LIST: genActions(TYPE.GET_BEST_SELLER_LIST),
  GET_SUGGESTED_COUPON_LIST: genActions(TYPE.GET_SUGGESTED_COUPON_LIST),
  SAVE_SUGGESTED_COUPON: genActions(TYPE.SAVE_SUGGESTED_COUPON),
  GET_SHORT_LINK_TO_SHARE: genActions(TYPE.GET_SHORT_LINK_TO_SHARE),
  GET_USER_LIKED_LIST_OF_RATING_POST: genActions(
    TYPE.GET_USER_LIKED_LIST_OF_RATING_POST
  ),

  GET_USER_LIKED_LIST_OF_COMMENT: genActions(
    TYPE.GET_USER_LIKED_LIST_OF_COMMENT
  ),
  POST_LIKE_CMT: genActions(TYPE.POST_LIKE_CMT),
  POST_UNLIKE_CMT: genActions(TYPE.POST_UNLIKE_CMT),
  GET_STORE_INFO_V2: genActions(TYPE.GET_STORE_INFO_V2),

  POST_LIKE_SUB_CMT: genActions(TYPE.POST_LIKE_SUB_CMT),
  POST_UNLIKE_SUB_CMT: genActions(TYPE.POST_UNLIKE_SUB_CMT),

  POST_REPLY_CMT: genActions(TYPE.POST_REPLY_CMT),

  GET_NOT_ALREADY_RATED_LIST: genActions(TYPE.GET_NOT_ALREADY_RATED_LIST),
  GET_LIST_DEAL: genActions(TYPE.GET_LIST_DEAL),
  CLEAR_RATING_LIST: genActions(TYPE.CLEAR_RATING_LIST),
};

export default actions;
