import React from "react";
import classNames from "classnames";
import moment from "moment";

import "./styles.scss";
import Skeleton from "react-loading-skeleton";

const CouponSearchResultItem = (props) => {
  const {
    transactionCode,
    name,
    code,
    couponCode,
    expiryDate,
    isApplied,
    handleToggleApplyPromo,
    couponData,
    handleOnClick,
    ...rest
  } = props;

  return (
    // promotion item wrapper
    <div {...rest}>
      <div
        className={classNames(
          "promotion-item d-flex",
          isApplied && "promotion-item-applied"
        )}
      >
        <div
          className="pr-2"
          style={{
            minWidth: 0,
            flexGrow: 1,
            borderRight: "1px dashed #dedede",
            cursor: "pointer",
          }}
          onClick={handleOnClick}
        >
          <div className="_fw-500 _fs-14px color-black text-truncate">
            {name || <Skeleton width="80%" />}
          </div>
          {/* <div className="_fw-700 _fs-12px color-ucla_gold">
            {couponCode || <Skeleton width={110} />}
          </div> */}
          <div
            className="_fw-500 _fs-10px color-granite_gray"
            style={{ color: !expiryDate && "transparent" }}
          >
            {expiryDate ? (
              `Hết hạn: ${moment(expiryDate || "").format("DD/MM/YYYY")}`
            ) : (
              <Skeleton width={70} />
            )}
          </div>
        </div>

        <div className="d-flex">
          {name ? (
            <button
              onClick={() => handleToggleApplyPromo(couponData)}
              className="button-apply _outline-none color-ucla_gold _fw-600 _fs-14px"
              type="button"
              style={{ flexShrink: 0 }}
            >
              {/* {isApplied ? "Hủy" : "Chọn"} */}
              Áp dụng
            </button>
          ) : (
            <Skeleton width={60} />
          )}
        </div>

        {/* serration ticket border */}
        <div className="dotted-right-border" />
        <div className="dotted-right-border dotted-right-border-cover" />
        <div className="redundant-dotted-cover" />
      </div>
    </div>
  );
};

export default React.memo(CouponSearchResultItem);
