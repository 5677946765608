export const listNewsfeedByLocationParser = data => {
  if (data) {
    const newArray = data.map(item => {
      return {
        id: item.id,
        storeId: item.storeId,
        distanceOrigin: item.distanceOrigin,
        feedType: item.feedType ? item.feedType : '',
        merchant: item.merchant,
        avgPrice: item.avgPrice ? item.avgPrice : 0,
        location: item.location?.coordinates ? item.location?.coordinates : [],
        images: item.images && item.images.iv.length > 0 ? item.images.iv : [],
        logoUrl: item.logoUrl,
        prices: item.prices && item.prices.iv.length > 0 ? item.prices.iv : [],
        shortDescription:
          item.shortDescription && item.shortDescription.vi
            ? item.shortDescription.vi
            : '',
        tags: item.tags && typeof item.tags === 'object' ? item.tags : [],
        name: item.name && item.name.vi ? item.name.vi : '',
        type: item.type && item.type.iv ? item.type.iv : [],
        distance:
          item.distance !== null && item.distance !== undefined
            ? item.distance
            : null,
        merchantType: item.merchantType ? item.merchantType : '',
        promotion: item.promotion ? item.promotion : '',
        avgRate: item?.avgRate,
        operationTypes: item?.operationTypes,
      };
    });
    return newArray;
  }
  return [];
};
