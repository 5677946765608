const prefix = 'orderAddressFood';

export const GET_DEFAULT_ADDRESS_USER_REQUEST =
  prefix + '/GET_DEFAULT_ADDRESS_USER_REQUEST';
export const GET_DEFAULT_ADDRESS_USER_SUCCESS =
  prefix + '/GET_DEFAULT_ADDRESS_USER_SUCCESS';
export const GET_DEFAULT_ADDRESS_USER_FAILED =
  prefix + '/GET_DEFAULT_ADDRESS_USER_FAILED';
export const SAVE_DEFAULT_ADDRESS_USER = prefix + '/SAVE_DEFAULT_ADDRESS_USER';
export const CALCULATE_SHIPPING_REQUEST =
  prefix + '/CALCULATE_SHIPPING_REQUEST';
export const CALCULATE_SHIPPING_SUCCESS =
  prefix + '/CALCULATE_SHIPPING_SUCCESS';
export const CALCULATE_SHIPPING_FAILED = prefix + '/CALCULATE_SHIPPING_FAILED';
export const CREATE_DEFAULT_ADDRESS_REQUEST =
  prefix + '/CREATE_DEFAULT_ADDRESS_REQUEST';
