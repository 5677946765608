import {
  GET_LIST_CARD_REQUEST,
  GET_LIST_CARD_SUCCESS,
  GET_LIST_CARD_FAILED,
  GET_LIST_CARD_FOXPAY_REQUEST,
  CANCEL_LINK_CARD_REQUEST,
  CANCEL_LINK_CARD_FOXPAY_REQUEST,
  CREATETOKENIZATION_REQUEST,
  CHECK_STATUS_CARD_MEMBER_REQUEST,
  GET_INFO_TENANT_REQUEST,
  LINK_CARD_MEMBER_REQUEST,
  GET_LIST_CARD_MEMBER_REQUEST,
  CANCEL_LINK_CARD_MEMBER_REQUEST,
  UPDATE_INFO_CARD_MEMBER_REQUEST,
} from './constants';

export const getListCardRequest = (onSuccess, onError) => ({
  type: GET_LIST_CARD_REQUEST,
  onSuccess,
  onError,
});

export const getListCardSuccess = payload => ({
  type: GET_LIST_CARD_SUCCESS,
  payload,
});

export const getListCardFailure = payload => ({
  type: GET_LIST_CARD_FAILED,
  payload,
});
export const getListCardFoxpayRequest = (onSuccess, onError) => ({
  type: GET_LIST_CARD_FOXPAY_REQUEST,
  onSuccess,
  onError,
});
export const cancelLinkCardRequest = (cardID, onSuccess, onError) => ({
  cardID,
  type: CANCEL_LINK_CARD_REQUEST,
  onSuccess,
  onError,
});

export const cancelLinkCardFoxpayRequest = (cardID, onSuccess, onError) => ({
  cardID,
  type: CANCEL_LINK_CARD_FOXPAY_REQUEST,
  onSuccess,
  onError,
});

export const createTokenization = (onSuccess, onError) => ({
  type: CREATETOKENIZATION_REQUEST,
  onSuccess,
  onError,
});
export const checkStatusCardMemberRequest = (cardID, onSuccess, onError) => ({
  cardID,
  type: CHECK_STATUS_CARD_MEMBER_REQUEST,
  onSuccess,
  onError,
});
export const getInfoTenantRequest = (tenantID, onSuccess, onError) => ({
  tenantID,
  type: GET_INFO_TENANT_REQUEST,
  onSuccess,
  onError,
});
export const linkCardMemberRequest = (payload, onSuccess, onError) => ({
  payload,
  type: LINK_CARD_MEMBER_REQUEST,
  onSuccess,
  onError,
});
export const getListCardMemberRequest = (onSuccess, onError) => ({
  type: GET_LIST_CARD_MEMBER_REQUEST,
  onSuccess,
  onError,
});

export const cancelLinkCardMemberRequest = (cardID, onSuccess, onError) => ({
  cardID,
  type: CANCEL_LINK_CARD_MEMBER_REQUEST,
  onSuccess,
  onError,
});
export const updateInfoCardMemberRequest = (payload, onSuccess, onError) => ({
  payload,
  type: UPDATE_INFO_CARD_MEMBER_REQUEST,
  onSuccess,
  onError,
});
