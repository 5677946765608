/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";

import SVG from "assets/svg";
import { imagesIcon } from "img";
// eslint-disable-next-line import/extensions
import SearchHistoryList from "../SearchHistoryList/SearchHistoryList";
// eslint-disable-next-line import/extensions

import "../../../../Home/_home.scss";

const TheInputSearch = (props) => {
  // state dùng để ẩn hoặc hiện danh sách gợi ý khi tìm kiếm từ khóa và danh sách lịch sử đã tìm kiếm trước đó

  const [searchHistoryList, setSearchHistoryList] = useState(true);
  const [placeholder, setPlaceholder] = useState(
    props.placeholder || "Nhập để tìm kiếm..."
  );
  const [searchtext, setSearchText] = useState("");

  // * handle body scroll & toggle appearance of banner (guide open app)

  useEffect(() => {
    setSearchText(props.searchtext);
  }, [props.searchtext]);

  const onChangeTxtSearch = (e) => {
    setSearchText(e.target.value);
    if (e === "") {
      // eslint-disable-next-line no-unused-expressions
      props.clearSearchtext && props.clearSearchtext();
    }
    // eslint-disable-next-line no-unused-expressions
    props.onChangeText && props.onChangeText(e.target.value);
  };

  const onSearchClicked = (e) => {
    if (e.keyCode === 13) {
      // eslint-disable-next-line no-unused-expressions
      props.onSearchClicked && props.onSearchClicked(searchtext);
      setSearchHistoryList(false);
    }
  };

  const onSearchHistory = (item) => {
    // eslint-disable-next-line no-unused-expressions
    props.onSearchClicked && props.onSearchClicked(item);
    setSearchText(item);
    setSearchHistoryList(false);
  };

  const clearTextClicked = () => {
    setSearchText("");
    // eslint-disable-next-line no-unused-expressions
    props.clearSearchtext();
    setSearchHistoryList(true);
  };

  const onSearchList = () => {
    setSearchHistoryList(true);
  };

  const handleShowWhenFocus = () => {
    setPlaceholder(props.placeholder || "Nhập để tìm kiếm...");
    // show list history search
  };

  const handleShowWhenBlur = () => {
    setPlaceholder(props.placeholder || "Nhập để tìm kiếm...");
  };

  return (
    <div className="searchMain">
      <div className="searchInput">
        <img
          className="iconSearchHome"
          src={SVG.IconSearchHome}
          alt="search loading"
        />

        <input
          size="90"
          placeholder={placeholder}
          type="text"
          id="search-box-input"
          aria-label="Text input with dropdown button"
          value={searchtext}
          onChange={onChangeTxtSearch}
          onClick={onSearchList}
          onFocus={handleShowWhenFocus}
          onBlur={handleShowWhenBlur}
          onKeyUp={onSearchClicked}
          autoComplete="off"
          autoFocus
        />
        <div
          className="searchButton"
          // onClick={handleSearchButton}
          style={{ display: "none" }}
        >
          <img
            className="searchBar"
            src={imagesIcon.searchBar}
            alt="search loading"
          />
          <img
            className="searchIcon"
            src={imagesIcon.searchIcon}
            alt="icon loading"
          />
        </div>
        {searchtext && (
          <img
            onClick={clearTextClicked}
            className={classNames(
              searchtext ? "input-close-btn" : "input-close-btn d-none"
            )}
            src={imagesIcon.inputCloseBtn}
            alt="input Close Button"
            style={{
              // paddingRight: user ? "" : "3.2rem",
              // position: "absolute",
              // right: "1rem",
              marginLeft: "0.5rem",
            }}
          />
        )}
      </div>
      <>
        {props.listSearchHistory && searchHistoryList && (
          <SearchHistoryList
            // handleShowSearchList={handleShowSearchList}
            searchHistoryList={props.listSearchHistory}
            setSearchHistoryList={setSearchHistoryList}
            onSearchHistory={onSearchHistory}
            clearHistorySearch={props.clearSearchHistory}
          />
        )}
      </>
    </div>
  );
};

export default TheInputSearch;
