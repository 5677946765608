import { callApi } from "helpers/callApi";
import { put, takeLatest, fork, call } from "redux-saga/effects";
import { APIUtils } from "utils";
import {
  myCouponDetailFailed,
  myCouponDetailSuccess,
  myVoucherDetailFailed,
  myVoucherDetailSuccess,
  getVoucherDetailByUserSuccess,
  getVoucherDetailByUserFailed,
} from "./actions";

import {
  MY_COUPON_DETAIL_REQUEST,
  MY_VOUCHER_DETAIL_REQUEST,
  MY_VOUCHER_DETAIL_BY_USER_REQUEST,
} from "./constants";

import { getVoucherDetailByUserApi } from "@shared/services/api/myVoucher";
async function myVoucherDetailAPI(url, renewToken) {
  const header = { Authorization: "Bearer " + renewToken };
  return APIUtils.get(url, {
    headers: header,
  });
}

export function* myVoucherDetailSaga(obj) {
  const { onSuccess } = obj.payload;
  try {
    const renewToken = yield APIUtils.reauthenticate();
    const data = yield call(myVoucherDetailAPI, obj.payload, renewToken);
    if (data.status === 200) {
      yield put(myVoucherDetailSuccess(data.data));
      if (typeof onSuccess === "function") {
        onSuccess();
      }
    } else {
      yield put(myVoucherDetailFailed(data));
    }
  } catch (err) {
    yield put(myVoucherDetailFailed(err));
  }
}

async function myCouponDetailAPI({ couponid }, renewToken) {
  const header = { Authorization: "Bearer " + renewToken };
  const params = {
    couponid,
  };
  return APIUtils.get(global.apiLinks.getDetailCoupon, {
    params,
    headers: header,
  });
}

export function* myCouponDetailSaga(obj) {
  const { onSuccess } = obj.payload;
  try {
    const renewToken = yield APIUtils.reauthenticate();
    const data = yield call(myCouponDetailAPI, obj.payload, renewToken);
    if (data.status === 200) {
      yield put(myCouponDetailSuccess(data.data));
      if (typeof onSuccess === "function") {
        onSuccess();
      }
    } else {
      yield put(myCouponDetailFailed(data));
    }
  } catch (err) {
    yield put(myCouponDetailFailed(err));
  }
}

export function* getVoucherDetailByUserSaga(obj) {
  const { payload = {}, onSuccess, onError } = obj;
  try {
    const res = yield callApi(getVoucherDetailByUserApi, payload);
    if (typeof onSuccess === "function") {
      onSuccess(res);
    }
    yield put(getVoucherDetailByUserSuccess(res));
  } catch (err) {
    yield put(getVoucherDetailByUserFailed(err));
    onError(err);
  }
}

function* watchMyVoucherDetail() {
  yield takeLatest(MY_VOUCHER_DETAIL_REQUEST, myVoucherDetailSaga);
  yield takeLatest(MY_COUPON_DETAIL_REQUEST, myCouponDetailSaga);
  yield takeLatest(
    MY_VOUCHER_DETAIL_BY_USER_REQUEST,
    getVoucherDetailByUserSaga
  );
}

export default function* rootChild() {
  yield fork(watchMyVoucherDetail);
}
