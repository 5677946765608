import { genActions } from "utils/actionGenerator";
import * as TYPE from "./constants";

const actions = {
  LIST_ORDER_HISTORY: genActions(TYPE.LIST_ORDER_HISTORY),
  SET_LOADING_BY_FILTER_ORDER_HISTORY: (payload) => ({
    type: TYPE.SET_LOADING_BY_FILTER_ORDER_HISTORY,
    payload,
  }),
  CLEAR_ORDER_HISTORY: () => ({
    type: TYPE.CLEAR_ORDER_HISTORY,
  }),
};

export default actions;
