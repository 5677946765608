import { genActions } from "utils/actionGenerator";
import * as TYPE from "./constants";

const actions = {
  GET_PAYMENT_METHODS: genActions(TYPE.GET_PAYMENT_METHODS),
  GET_PAYMENT_CARDS: genActions(TYPE.GET_PAYMENT_CARDS),
  CREATE_PAYMENT: genActions(TYPE.CREATE_PAYMENT),
  GET_UTOP_WALLET_INFO: genActions(TYPE.GET_UTOP_WALLET_INFO),
  TRANSACTION_PAY: genActions(TYPE.TRANSACTION_PAY),
  GET_ORDER_DETAIL: genActions(TYPE.GET_ORDER_DETAIL),
  CLEAR_PAYMENT: genActions(TYPE.CLEAR_PAYMENT),
};

export default actions;
