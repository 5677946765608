import { genActionTypes } from "utils/actionGenerator";

export const TYPE = "MyVoucherDetails";

export const SET_LOADING = `${TYPE}_SET_LOADING`;
export const VOUCHER_DETAIL = genActionTypes(`${TYPE}_VOUCHER_DETAIL`);
export const VOUCHER_INFOMATION = genActionTypes(`${TYPE}_VOUCHER_INFOMATION`);
export const CLICK_BY_MARK_USED = genActionTypes(`${TYPE}_CLICK_BY_MARK_USED`);
export const VOUCHER_PRODUCT_INFORMATION = genActionTypes(
  `${TYPE}_VOUCHER_PRODUCT_INFORMATION`
);
