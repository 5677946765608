import {
  LIST_SEARCH_STORE_REQUEST,
  LIST_SEARCH_STORE_SUCCESS,
  LIST_SEARCH_STORE_FAILED,
  CLEAR_LIST_SEARCH_STORE,
} from './constants';

export const listSearchStoreRequest = payload => ({
  type: LIST_SEARCH_STORE_REQUEST,
  payload,
});

export const listSearchStoreSuccess = payload => ({
  type: LIST_SEARCH_STORE_SUCCESS,
  payload,
});

export const listSearchStoreFailed = error => ({
  type: LIST_SEARCH_STORE_FAILED,
  error,
});

export const clearListSearchStore = payload => ({
  type: CLEAR_LIST_SEARCH_STORE,
  payload,
});
