/* eslint-disable no-unused-vars */
/* eslint-disable prefer-const */
import React, { useEffect, useMemo, useState } from "react";
import InputNumber from "views/common/component/InputNumber";
import { toVND } from "utils/currencyFormat";
import { calcUnitPrice } from "utils/calcFnBItemPrice";
import { AlertMsg } from "utils";

import { CheckBoxOption } from "views/common/component/FnBOption";
import { FNB_OPTION_TYPES } from "constants/types";
import classNames from "classnames";

import "./editMenu.scss";
import IMAGES from "assets/img";
import RadioOption from "../RadioOption";

import CustomCarousel from "../StoreDetailInfo/components/CustomCarousel";

const INPUT_MAX_VALUE = 999;
const INPUT_MIN_VALUE = 1;

const EditMenu = (props) => {
  const {
    // itemId,
    itemData,
    chosenAttributes,
    chosenToppings,
    chosenSize,
    chosenQuantity,
    // itemPrice,

    // * originalData
    // id,
    code,
    imageUrl,
    imageUrls,
    name,
    price,
    // listPrice,
    // description,
    attributes,
    sizes,
    toppings,

    // originalData, // ! thừa nhưng chưa kịp refactor

    // * events
    handleClose,
    // handleUpdateItem,
    // handleCloseQuantityMenu,
  } = props;
  // const history = useHistory();

  // const [totalPriceOfThisItem, setTotalPriceOfThisItem] = useState(0);
  const [unitPrice, setUnitPrice] = useState(price);
  const [originPrice, setOriginPrice] = useState(price);
  const [quantity, setQuantity] = useState(0);
  const [chosenSizeOption, setChosenSizeOption] = useState();
  const [chosenAttributeOptions, setChosenAttributeOptions] = useState();
  const [chosenToppingOptions, setChosenToppingOptions] = useState();

  const isAlreadyCheckAllValidAttributes = useMemo(() => {
    if (chosenAttributeOptions?.length < attributes?.length) {
      return false;
    }

    const check = chosenAttributeOptions?.filter((chosenAttributeOption) => {
      const originalAttribute = attributes?.find(
        (attribute) => attribute.Name === chosenAttributeOption.Name
      );

      return originalAttribute?.Value?.includes(chosenAttributeOption?.Value);
    });

    return check?.length === attributes?.length;
  }, [chosenAttributeOptions, attributes]);

  // reset on when show different item in cart
  useEffect(() => {
    if (chosenSize) {
      setChosenSizeOption(chosenSize);
    }

    if (chosenAttributes) {
      setChosenAttributeOptions(chosenAttributes);
    }

    if (chosenToppings) {
      setChosenToppingOptions(chosenToppings);
    }

    if (chosenQuantity) {
      setQuantity(chosenQuantity);
    }
  }, []);

  // calc total price
  useEffect(() => {
    const newUnitPrice = calcUnitPrice(
      price,
      chosenSizeOption,
      chosenToppingOptions
    );

    setUnitPrice(newUnitPrice);
  }, [price, chosenSizeOption, chosenToppingOptions]);

  useEffect(() => {
    const newUnitPrice = calcUnitPrice(price, chosenSizeOption);

    setOriginPrice(newUnitPrice);
  }, [price, chosenSizeOption]);

  // handle quantity
  const handleIncreaseQuantity = () => {
    setQuantity((prevQuantity) => {
      if (prevQuantity < INPUT_MAX_VALUE) {
        return prevQuantity + 1;
      }

      // todo: show helper
      return prevQuantity;
    });
  };

  const handleDecreaseQuantity = () => {
    setQuantity((prevQuantity) => {
      if (prevQuantity > INPUT_MIN_VALUE) {
        return prevQuantity - 1;
      }

      // todo: show helper
      return prevQuantity;
    });
  };

  // handle quantity - by keyboard - only commit number
  const handleChangeQuantity = (e) => {
    const valueAsNumber = parseInt(e.target.value, 10);
    e.target.value = valueAsNumber;

    if (!valueAsNumber) {
      setQuantity(0);
      return;
    }

    if (valueAsNumber > INPUT_MAX_VALUE) {
      setQuantity(INPUT_MAX_VALUE);
      return;
    }

    setQuantity(valueAsNumber);
  };

  // handle option check
  const handleCheckOption = (type, data) => () => {
    if (type === FNB_OPTION_TYPES.SIZE) {
      setChosenSizeOption(data);
    }
    if (type === FNB_OPTION_TYPES.ATTRIBUTE) {
      setChosenAttributeOptions((prev) => {
        return [
          ...prev.filter(
            (attributeOption) =>
              attributeOption.name !== data.name ||
              attributeOption.Name !== data.Name
          ),
          data,
        ];
      });
    }
    if (type === FNB_OPTION_TYPES.TOPPING) {
      setChosenToppingOptions((prev) => {
        // check if already exist => remove
        const toppingAlreadyChosen = prev?.find(
          (toppingOption) =>
            toppingOption?.code === data?.code ||
            toppingOption?.code === data?.Code ||
            toppingOption?.Code === data?.code
        );

        if (toppingAlreadyChosen) {
          return prev?.filter(
            (toppingOption) => toppingOption?.code !== data?.code
          );
        }

        return [...prev, data];
      });
    }
  };

  const updateCart = () => {
    let productCode =
      typeof itemData !== "undefined" && itemData.code
        ? itemData?.code
        : itemData?.data?.code;
    let sizeName = null;
    if (typeof itemData !== "undefined" && itemData.data.sizes.length > 0) {
      let isCheckedSize = false;
      sizes.forEach((size) => {
        if (size.Code === chosenSizeOption.Code) {
          isCheckedSize = true;
          productCode = size.Code;
          sizeName = size.Name;
        }
      });
      if (!isCheckedSize) {
        AlertMsg("basic", "Vui lòng chọn size");
        // ReactUtils.alertMsg('basic', strings('SmartOrderItemModal.label1'));
        // setIsProcess(false);
        return;
      }
      productCode = chosenSizeOption.Code;
    }
    const listAttribute = [];
    if (chosenAttributeOptions && chosenAttributeOptions.length > 0) {
      for (let i = 0; i < chosenAttributeOptions.length; i++) {
        // if (attributes[i].selectedAttribute === undefined) {
        //   // ReactUtils.alertMsg(
        //   //   'basic',
        //   //   strings('SmartOrderItemModal.label2') + attributes[i].Name,
        //   // );
        //   // setIsProcess(false);
        //   return;
        // }
        const item = {
          Name: chosenAttributeOptions[i].Name,
          Value: chosenAttributeOptions[i]?.Value,
        };
        listAttribute.push(item);
      }
    }

    const listTopping = [];
    chosenToppingOptions.forEach((item) => {
      listTopping.push(item);
    });
    const itemCart = {
      data: itemData?.data,
      dataInfoProduct: {
        quantity,
        productCode,
        productId: itemData?.data?.id,
        attributes: listAttribute,
        toppings: listTopping,
        totalPrice: unitPrice * quantity,
        index: props?.itemData?.dataInfoProduct?.index,
        sizeName,
        sizeOption: chosenSizeOption,
      },
    };
    handleClose();
    setTimeout(() => {
      if (itemCart && typeof itemCart.data !== "undefined") {
        props.updateCart(itemCart);
        // props.setIsShowModal(false);
        handleClose();
      }
    }, 200);
  };

  const validImages = useMemo(() => {
    if (!imageUrls) {
      return [];
    }

    const validItems = imageUrls.filter((item) => item.imageUrl !== null);

    return validItems;
  }, [imageUrls]);

  return (
    <div className="edit-menu">
      <div className="wrapper px-3">
        <div className="product-image__container">
          {validImages.length > 0 ? (
            <CustomCarousel images={validImages} imgClassName="product-image" />
          ) : (
            <object
              className="product-image"
              data={imageUrl || IMAGES.FoodPlaceholderX3}
              type="image/png"
            >
              <img
                className="product-image"
                src={IMAGES.FoodPlaceholderX3}
                alt=""
              />
            </object>
          )}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <div
            style={{
              background: "#FFB600",
              marginTop: "16px",
              marginLeft: "-16px",
              padding: "5px 16px 5px 16px",
              borderBottomRightRadius: "4px",
              borderTopRightRadius: "4px",
              fontWeight: "500",
            }}
          >
            {itemData?.data?.category?.name?.vi}
          </div>
        </div>
        <p className="mb-0 mt-3 _fw-600 _fs-14px color-chinese_black">{name}</p>
        <div>
          <span className="color-orange _fs-15px _fw-600 mr-2">
            {toVND(Number(originPrice))}
          </span>
          {/* {listPrice && (
            <span className="color-spanish_gray _fs-13px _fw-400 text-decoration-line-through">
              {toVND(Number(listPrice))}
            </span>
          )} */}
        </div>
        <p className="mb-0 mt-1">{/* <Markup content={description} /> */}</p>

        {/* Options */}
        <div className="" style={{ marginTop: 22 }}>
          {sizes?.length > 0 && (
            <div className="my-3">
              <p className="mb-2 _fw-600 _fs-14px">
                Tùy chọn size <span className="_fw-400 _fs-12px">(chọn 1)</span>
              </p>

              <div className="options-list px-2">
                {sizes.map((size) => (
                  <div key={size.Code}>
                    <RadioOption
                      type={FNB_OPTION_TYPES.SIZE}
                      code={size.Code}
                      inputId={size.Code}
                      groupName={`${code}_sizes`}
                      name={size.Name}
                      productPrice={price}
                      extraPrice={size.ExtraPrice}
                      checked={size?.Code === chosenSizeOption?.Code}
                      handleCheckOption={handleCheckOption}
                    />
                    <hr className="_my-12px" />
                  </div>
                ))}
              </div>
            </div>
          )}

          {attributes?.map((attribute) => (
            <div className="my-3" key={attribute.name}>
              <p className="mb-2 _fw-600 _fs-14px">
                Tùy chọn {attribute.Name}{" "}
                <span className="_fw-400 _fs-12px">(chọn 1)</span>
              </p>

              <div className="options-list px-2">
                {attribute?.Value?.map((attributeValue) => (
                  <div key={attributeValue}>
                    <RadioOption
                      type={FNB_OPTION_TYPES.ATTRIBUTE}
                      code={`${code}_${attribute.Name}_${attributeValue}`}
                      inputId={`${attribute.Name}_${attributeValue}`}
                      groupName={`${attribute.Name}`}
                      name={attributeValue}
                      checked={chosenAttributeOptions?.find(
                        (chosenAttributeOption) =>
                          chosenAttributeOption.Name === attribute.Name &&
                          chosenAttributeOption.Value === attributeValue
                      )}
                      handleCheckOption={handleCheckOption}
                    />
                    <hr className="_my-12px" />
                  </div>
                ))}
              </div>
            </div>
          ))}

          {/* Toppings */}
          {toppings?.length > 0 && (
            <div className="my-3">
              <p className="mb-2 _fw-600 _fs-14px">
                Tùy chọn món thêm{" "}
                <span className="_fw-400 _fs-12px">(không bắt buộc chọn)</span>
              </p>

              <div className="options-list px-2">
                {toppings?.map((topping) => (
                  <div key={topping.id}>
                    <CheckBoxOption
                      type={FNB_OPTION_TYPES.TOPPING}
                      id={topping?.id}
                      code={topping?.code}
                      name={topping?.name}
                      price={topping?.price?.default}
                      listPrice={topping?.price.list}
                      description={topping?.flatData?.description}
                      shortDescription={topping?.flatData?.shortDescription}
                      handleCheckOption={handleCheckOption}
                      checked={chosenToppingOptions?.find(
                        (chosenToppingOption) =>
                          chosenToppingOption?.code === topping?.code
                      )}
                    />
                    <hr className="_my-12px" />
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>

        {/* Quantity */}
        <div className="d-flex justify-content-between align-items-center _py-12px">
          <span className="color-chinese_black _fw-600 _fs-14px">Số lượng</span>
          <div>
            <InputNumber
              value={quantity}
              handleIncrease={handleIncreaseQuantity}
              handleDecrease={handleDecreaseQuantity}
              handleChange={handleChangeQuantity}
              maxValue={INPUT_MAX_VALUE}
            />
          </div>
        </div>
      </div>

      {/* Bottom */}
      <div
        className="bgr-white _py-12px px-3
      d-flex align-items-center
      position-sticky bottom-0
      menu-bottom
      "
      >
        <span className="color-orange _fw-700 _fs-15px mr-auto">
          {toVND(unitPrice * quantity)}
        </span>

        <button
          type="button"
          className={classNames(
            "w-50 _py-10px _border-none bgr-linear rounded-pill button__effect",
            (!isAlreadyCheckAllValidAttributes || !quantity > 0) &&
              "bgr--disabled"
          )}
          onClick={updateCart}
          disabled={!isAlreadyCheckAllValidAttributes || !quantity > 0}
        >
          <span className="_fw-700 _fs-14px color-white">
            Cập nhật giỏ hàng
          </span>
        </button>
      </div>
    </div>
  );
};

export default EditMenu;
