const covertImageToMediaType = (payload) => {
  if (payload !== undefined && payload.length > 0) {
    let newL = []
    payload.map((item) => {
      newL.push({
        content: item || '',
        type: 0,
      })
    })
    return newL
  }
  return []
}
export const storeInfoParser = (storeInfo) => {
  if (storeInfo !== {} && storeInfo !== undefined) {
    return {
      merchantId: storeInfo?.merchantId,
      merchantName: storeInfo?.merchantName,
      merchantInfo: [],
      merchantClass: storeInfo?.merchantClass || '',
      id: storeInfo.id,
      name: storeInfo?.name?.vi,
      logoUrl: storeInfo?.logoUrl,
      imagesMerchant: covertImageToMediaType(storeInfo?.merchantImages) || [],
      address: storeInfo?.address || '',
      isAllowCod: storeInfo?.allowCOD,
      deliveryOptions: storeInfo?.deliveryOptions || [],
      openingTimes: storeInfo?.openingTimes || [],
      phone: storeInfo.phone || '',
      pickupAddress: storeInfo?.pickupAddress,
      deliveryAddressOptions: storeInfo.deliveryAddressOptions || [],
      tags: storeInfo.tags || [],
      location: storeInfo?.location || {},
      avgRate: storeInfo?.avgRate || '',
      deliveryConfigs: storeInfo?.deliveryConfigs,
    }
  }
  return {}
}
