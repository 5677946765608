//List Coupon
export const GET_LIST_COUPON_FOR_VOUCHER_REQUEST =
  'couponForVoucher/GET_LIST_COUPON_FOR_VOUCHER_REQUEST';

export const GET_LIST_COUPON_FOR_VOUCHER_SUCCESS =
  'couponForVoucher/GET_LIST_COUPON_FOR_VOUCHER_SUCCESS';

export const GET_LIST_COUPON_FOR_VOUCHER_FAILED =
  'couponForVoucher/GET_LIST_COUPON_FOR_VOUCHER_FAILED';

// Detail Coupon
export const GET_COUPON_DETAIL_FOR_VOUCHER_REQUEST =
  'couponForVoucher/GET_COUPON_DETAIL_FOR_VOUCHER_REQUEST';

export const GET_COUPON_DETAIL_FOR_VOUCHER_SUCCESS =
  'couponForVoucher/GET_COUPON_DETAIL_FOR_VOUCHER_SUCCESS';

export const GET_COUPON_DETAIL_FOR_VOUCHER_FAILED =
  'couponForVoucher/GET_COUPON_DETAIL_FOR_VOUCHER_FAILED';

// Search Coupon
export const SEARCH_COUPON_DETAIL_FOR_VOUCHER_REQUEST =
  'couponForVoucher/SEARCH_COUPON_DETAIL_FOR_VOUCHER_REQUEST';

export const SEARCH_COUPON_DETAIL_FOR_VOUCHER_SUCCESS =
  'couponForVoucher/SEARCH_COUPON_DETAIL_FOR_VOUCHER_SUCCESS';

export const SEARCH_COUPON_DETAIL_FOR_VOUCHER_FAILED =
  'couponForVoucher/SEARCH_COUPON_DETAIL_FOR_VOUCHER_FAILED';

// Get Available Coupons ByCode
export const GET_AVAILABLE_COUPON_REQUEST =
  'couponForVoucher/GET_AVAILABLE_COUPON_REQUEST';

export const GET_AVAILABLE_COUPON_SUCCESS =
  'couponForVoucher/GET_AVAILABLE_COUPON_SUCCESS';

export const GET_AVAILABLE_COUPON_FAILED =
  'couponForVoucher/GET_AVAILABLE_COUPON_FAILED';

//save coupon
export const DATA_COUPON_SELECTED = 'couponForVoucher/DATA_COUPON_SELECTED';

//clear coupon
export const CLEAR_DATA_COUPON_LIST = 'couponForVoucher/CLEAR_DATA_COUPON_LIST';
