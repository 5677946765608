import produce from 'immer';
import {
  UPLOAD_VIDEO_SUCCESS,
  UPLOAD_VIDEO_FAILED,
  GET_VIDEO_PROBLEM_SUCCESS,
  GET_VIDEO_PROBLEM_FAILED,
  DELETE_VIDEO_SUCCESS,
  DELETE_VIDEO_FAILED,
} from './types';

const initialState = {
  errors: null,
  resultUpload: null,
  videoProblem: null,
  deleteVideo: null,
};

const uploadVideoReducer = produce((draft = initialState, action) => {
  const {payload} = action;
  switch (action.type) {
    case UPLOAD_VIDEO_SUCCESS:
      return {
        ...draft,
        errors: null,
        resultUpload: payload,
      };

    case UPLOAD_VIDEO_FAILED:
      return {
        ...draft,
        errors: payload,
      };

    case GET_VIDEO_PROBLEM_SUCCESS:
      return {
        ...draft,
        errors: null,
        videoProblem: payload,
      };

    case GET_VIDEO_PROBLEM_FAILED:
      return {
        ...draft,
        errors: payload,
      };

    case DELETE_VIDEO_SUCCESS:
      return {
        ...draft,
        errors: null,
        deleteVideo: payload,
      };

    case DELETE_VIDEO_FAILED:
      return {
        ...draft,
        errors: payload,
      };

    default:
      return draft;
  }
});

export default uploadVideoReducer;
