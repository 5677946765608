import { genActionTypes } from "utils/actionGenerator";

export const TYPE = "Promotion";

export const SET_LOADING = `${TYPE}_SET_LOADING`;
export const GET_LIST_COUPON = genActionTypes(`${TYPE}_GET_LIST_COUPON`);
// export const GET_LIST_COUPON_DEMO = {
//   request: `${TYPE}_GET_LIST_COUPON_REQUEST`,
//   success: `${TYPE}_GET_LIST_COUPON_SUCCESS`,
//   failure: `${TYPE}_GET_LIST_COUPON_FAILURE`,
// };
export const SEARCH_COUPON_BY_COUPON_CODE = genActionTypes(
  `${TYPE}_SEARCH_COUPON_BY_COUPON_CODE`
);
