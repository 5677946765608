import {
  DELETE_VIDEO_REQUEST,
  GET_VIDEO_PROBLEM_REQUEST,
  SET_ISCOMMENTABLE_REQUEST,
  SET_PRIVACY_REQUEST,
  UPLOAD_VIDEO_REQUEST,
} from './types'
import {
  uploadVideoSuccess,
  uploadVideoFailure,
  getVideoProblemSuccess,
  getVideoProblemFailed,
  deleteVideoSuccess,
  deleteVideoFailed,
} from './actions'
import { call, put, takeLatest, fork } from 'redux-saga/effects'
import { END_POINTS } from '@shared/constants/apiUrl'
import { APIUtils } from 'utils'

function uploadVideoRequest({
  title,
  refType,
  refCode,
  fileName,
  videoId,
  isCommentable,
  fileNameThumbnail,
  displayStatus,
}) {
  let url = END_POINTS.UploadVideo
  let requestData = {
    title,
    refType,
    refCode,
    fileName,
    isCommentable,
    fileNameThumbnail,
    displayStatus,
  }
  if (videoId) {
    url = END_POINTS.ReupVideo
    requestData = {
      title,
      refType,
      refCode,
      videoId,
      isCommentable,
      displayStatus,
      fileNameThumbnail,
    }
  }
  return APIUtils.put(url, { body: requestData })
}

export function* uploadVideo(obj) {
  const { onSuccess, onError } = obj.payload
  try {
    const data = yield call(uploadVideoRequest, obj.payload)

    if (data) {
      yield put(uploadVideoSuccess(data))
      if (typeof onSuccess === 'function') {
        onSuccess(data)
      }
    } else {
      yield put(uploadVideoFailure(data))
      if (typeof onError === 'function') {
        onError(data)
      }
    }
  } catch (err) {
    yield put(uploadVideoFailure(err))
    if (typeof onError === 'function') {
      onError(err)
    }
  }
}

function getVideoProblemRequest({ videoId }) {
  let params = {
    videoId,
  }
  return APIUtils.get(END_POINTS.GetDetailVideoProblem + `/${videoId}`, {
    params,
  })
}

export function* getVideoProblem(obj) {
  const { onSuccess, onError } = obj.payload
  try {
    const data = yield call(getVideoProblemRequest, obj.payload)
    if (data.status === 200) {
      yield put(getVideoProblemSuccess(data))
      if (typeof onSuccess === 'function') {
        onSuccess(data)
      }
    } else {
      yield put(getVideoProblemFailed(data))
      if (typeof onError === 'function') {
        onError()
      }
    }
  } catch (err) {
    yield put(getVideoProblemFailed(err))
    if (typeof onError === 'function') {
      onError()
    }
  }
}

function deleteVideoRequest({ videoId }) {
  let params = {
    videoId,
  }
  return APIUtils.delete(END_POINTS.RemoveVideo, { body: params })
}

export function* deleteVideo(obj) {
  const { onSuccess, onError } = obj.payload
  try {
    const data = yield call(deleteVideoRequest, obj.payload)
    if (data.status === 200) {
      yield put(deleteVideoSuccess(data))
      if (typeof onSuccess === 'function') {
        onSuccess()
      }
    } else {
      yield put(deleteVideoFailed(data))
      if (typeof onError === 'function') {
        onError()
      }
    }
  } catch (err) {
    yield put(deleteVideoFailed(err))
    if (typeof onError === 'function') {
      onError()
    }
  }
}

function setPrivacyRequest({ videoId, displayStatus }) {
  let params = {
    videoId,
    displayStatus,
  }
  return APIUtils.put(END_POINTS.SetPrivacyVideo, { body: params })
}

export function* setPrivacy(obj) {
  const { onSuccess, onError } = obj.payload
  try {
    const data = yield call(setPrivacyRequest, obj.payload)
    if (data.status === 200) {
      if (typeof onSuccess === 'function') {
        onSuccess()
      }
    } else {
      if (typeof onError === 'function') {
        onError()
      }
    }
  } catch (err) {
    if (typeof onError === 'function') {
      onError()
    }
  }
}

function setIsCommentableRequest({ videoId, isCommentable }) {
  let params = {
    videoId,
    isCommentable,
  }
  return APIUtils.put(END_POINTS.SetCommentableVideo, { body: params })
}

export function* setIsCommentable(obj) {
  const { onSuccess, onError } = obj.payload
  try {
    const data = yield call(setIsCommentableRequest, obj.payload)
    if (data.status === 200) {
      if (typeof onSuccess === 'function') {
        onSuccess()
      }
    } else {
      if (typeof onError === 'function') {
        onError()
      }
    }
  } catch (err) {
    if (typeof onError === 'function') {
      onError()
    }
  }
}

function* watchUploadVideo() {
  yield takeLatest(UPLOAD_VIDEO_REQUEST, uploadVideo)
  yield takeLatest(GET_VIDEO_PROBLEM_REQUEST, getVideoProblem)
  yield takeLatest(DELETE_VIDEO_REQUEST, deleteVideo)
  yield takeLatest(SET_PRIVACY_REQUEST, setPrivacy)
  yield takeLatest(SET_ISCOMMENTABLE_REQUEST, setIsCommentable)
}

export default function* rootChild() {
  yield fork(watchUploadVideo)
}
