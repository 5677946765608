import React from "react";
import classNames from "classnames";
// import { useSwipeable } from "react-swipeable";
import { CloseIcon } from "assets/svg";
import "./popup.scss";
import { useToggleBodyScroll } from "hooks";

/**
 * Popup luôn có prop @isOpen = false
 */

const Popup = (props) => {
  const {
    isOpen,
    handleClose,
    showButtonClose = false,
    title,
    titleClassName,
    children,
  } = props;

  // const [thisPopup, setThisPopup] = useState();
  useToggleBodyScroll({ shouldFreeze: isOpen });

  // const handleSwiping = useCallback(
  //   (eventData) => {
  //     if (eventData.dir === "Down") {
  //       if (thisPopup) {
  //         thisPopup.style.transform = `translateY(${eventData.deltaY}px)`;
  //       }
  //     }
  //   },
  //   [thisPopup]
  // );

  // const handleSwipedDown = (eventData) => {
  //   if (thisPopup) {
  //     thisPopup.style["transition-duration"] = "0.4s"; // default setup

  //     if (eventData.velocity < 0.3) {
  //       thisPopup.style.transform = `translateY(0%)`;
  //       return;
  //     }
  //     if (eventData.absY < thisPopup.offsetHeight / 3) {
  //       thisPopup.style.transform = `translateY(0%)`;
  //     } else {
  //       handleClose();
  //       thisPopup.style = "";
  //     }
  //   }
  // };

  // const handleSwipedStart = useCallback(() => {
  //   const found = document.getElementsByClassName("show-popup")[0];
  //   found.style["transition-duration"] = "unset"; // avoid delay transition when holding drag indicator
  //   if (!thisPopup) {
  //     setThisPopup(found);
  //   }
  // }, [thisPopup]);

  // const handlers = useSwipeable({
  //   onSwipeStart: handleSwipedStart,
  //   onSwiping: handleSwiping,
  //   onSwipedDown: handleSwipedDown,
  // });

  return (
    <>
      <div className={classNames("popup-content", isOpen && "show-popup")}>
        <div
          className="popup__top"
          // {...handlers}
        >
          <div className="drag-indicator" />
          {showButtonClose && (
            <button type="button" onClick={handleClose} className="btn-close">
              <CloseIcon />
            </button>
          )}
        </div>

        {title && (
          <div className={classNames("popup__title", titleClassName)}>
            {title}
          </div>
        )}

        <div className="popup__children hide-x-scrollbar">{children}</div>
      </div>

      <div
        onClick={() => {
          if (isOpen) {
            handleClose();
          }
        }}
        className={classNames(
          "popup__overlay",
          isOpen && "popup__overlay--show"
        )}
      />
    </>
  );
};

export default Popup;
