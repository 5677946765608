import { call, put, takeLatest, fork } from "redux-saga/effects";
import actions from "./actions";
import * as TYPE from "./constants";
import api from "../api/api";

const sagas = {
  *[TYPE.GET_SHIPPING_INFO.request](action) {
    try {
      const response = yield call(
        api[TYPE.GET_SHIPPING_INFO.request],
        action.payload
      );

      if (response.status === 200) {
        yield put(actions.GET_SHIPPING_INFO.success(response.data));
      } else {
        yield put(actions.GET_SHIPPING_INFO.failure(response?.data));
      }
    } catch (error) {
      yield put(actions.GET_SHIPPING_INFO.failure(error?.data));
    }
  },

  *[TYPE.GET_SUGGESTED_DELIVERY_DISCOUNT_LIST.request](action) {
    try {
      const response = yield call(
        api[TYPE.GET_SUGGESTED_DELIVERY_DISCOUNT_LIST.request],
        action.payload
      );

      if (response.status === 200) {
        yield put(
          actions.GET_SUGGESTED_DELIVERY_DISCOUNT_LIST.success(response.data)
        );
      } else {
        yield put(
          actions.GET_SUGGESTED_DELIVERY_DISCOUNT_LIST.failure(response?.data)
        );
      }
    } catch (error) {
      yield put(
        actions.GET_SUGGESTED_DELIVERY_DISCOUNT_LIST.failure(error?.data)
      );
    }
  },

  *[TYPE.GET_STORE_INFO.request](action) {
    try {
      const response = yield call(
        api[TYPE.GET_STORE_INFO.request],
        action.payload
      );

      if (response.status === 200) {
        yield put(actions.GET_STORE_INFO.success(response.data));
      } else {
        yield put(actions.GET_STORE_INFO.failure(response?.data));
      }
    } catch (error) {
      yield put(actions.GET_STORE_INFO.failure(error?.data));
    }
  },
};

function* watchedSaga() {
  yield takeLatest(
    TYPE.GET_SHIPPING_INFO.request,
    sagas[TYPE.GET_SHIPPING_INFO.request]
  );
  yield takeLatest(
    TYPE.GET_SUGGESTED_DELIVERY_DISCOUNT_LIST.request,
    sagas[TYPE.GET_SUGGESTED_DELIVERY_DISCOUNT_LIST.request]
  );
  yield takeLatest(
    TYPE.GET_STORE_INFO.request,
    sagas[TYPE.GET_STORE_INFO.request]
  );
}

export default function* rootChild() {
  yield fork(watchedSaga);
}
