import { call, put, takeLatest, fork } from "redux-saga/effects";
import actions from "./actions";
import * as TYPE from "./constants";
import api from "../api/api";

const sagas = {
  // * GET_COUPON_INFO
  *[TYPE.GET_COUPON_INFO.request](action) {
    const { couponId, viewAsGuest, showAlertOnDraft } = action.payload;

    try {
      const response = yield call(api[TYPE.GET_COUPON_INFO.request], {
        couponId,
        viewAsGuest,
      });

      const couponInfo = response.data?.data?.queryCouponsContents?.[0];

      if (response.status === 200) {
        yield put(actions.GET_COUPON_INFO.success(couponInfo));
      } else {
        yield put(actions.GET_COUPON_INFO.failure(response?.data));
      }

      if (!couponInfo) {
        showAlertOnDraft?.();
      }
    } catch (error) {
      yield put(actions.GET_COUPON_INFO.failure(error?.data));
    }
  },

  // * SAVE_SUGGESTED_COUPON
  *[TYPE.SAVE_SUGGESTED_COUPON.request](action) {
    const { onSuccessSaveCoupon, onFailureSaveCoupon, code } = action.payload;

    try {
      const response = yield call(api[TYPE.SAVE_SUGGESTED_COUPON.request], {
        code,
      });

      if (response.status === 200) {
        yield put(actions.SAVE_SUGGESTED_COUPON.success());
        yield call(onSuccessSaveCoupon);
      } else {
        yield put(actions.SAVE_SUGGESTED_COUPON.failure(response?.data));
        yield call(onFailureSaveCoupon);
      }
    } catch (error) {
      yield put(actions.SAVE_SUGGESTED_COUPON.failure(error?.data));
      yield call(onFailureSaveCoupon, error?.data?.message);
    }
  },
};

function* watchedSaga() {
  // * GET_COUPON_INFO
  yield takeLatest(
    TYPE.GET_COUPON_INFO.request,
    sagas[TYPE.GET_COUPON_INFO.request]
  );

  // * SAVE_SUGGESTED_COUPON
  yield takeLatest(
    TYPE.SAVE_SUGGESTED_COUPON.request,
    sagas[TYPE.SAVE_SUGGESTED_COUPON.request]
  );
}

export default function* rootChild() {
  yield fork(watchedSaga);
}
