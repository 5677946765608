import { genActions } from "utils/actionGenerator";
import * as TYPE from "./constants";

const actions = {
  GET_USER_ADDRESS_BOOKS: genActions(TYPE.GET_USER_ADDRESS_BOOKS),
  DELETE_AN_USER_ADDRESS_BOOK: genActions(TYPE.DELETE_AN_USER_ADDRESS_BOOK),
  SET_DEFAULT_TO_AN_USER_ADDRESS_BOOK: genActions(
    TYPE.SET_DEFAULT_TO_AN_USER_ADDRESS_BOOK
  ),
};

export default actions;
