import { genActions } from "utils/actionGenerator";
import * as TYPE from "./constants";

const actions = {
  SET_LOADING: (payload) => ({ type: TYPE.SET_LOADING, payload }),
  GET_SHIPPING_INFO: genActions(TYPE.GET_SHIPPING_INFO),
  GET_SUGGESTED_DELIVERY_DISCOUNT_LIST: genActions(
    TYPE.GET_SUGGESTED_DELIVERY_DISCOUNT_LIST
  ),
  GET_STORE_INFO: genActions(TYPE.GET_STORE_INFO),
};

export default actions;
