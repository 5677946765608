import {LOGOUT_REQUEST} from '@shared/redux/account/constants';
import produce from 'immer';
import {
  LIST_FAVORITE_HASHTAG_SUCCESS,
  LIST_FAVORITE_HASHTAG_REQUEST,
  LIST_FAVORITE_HASHTAG_FAILED,
} from './types';

const initialState = {
  errors: null,
  listFavoriteHashtag: [],
  isRefresh: false,
};

const listFavoriteHashtagReducer = produce((draft = initialState, action) => {
  const {payload} = action;
  const refresh = action?.payload?.isRefresh;

  switch (action.type) {
    case LIST_FAVORITE_HASHTAG_REQUEST:
      return {
        ...draft,
        isRefresh: refresh,
      };

    case LIST_FAVORITE_HASHTAG_SUCCESS:
      return {
        ...draft,
        isRefresh: false,
        errors: null,
        listFavoriteHashtag: payload,
      };

    case LIST_FAVORITE_HASHTAG_FAILED:
      return {
        ...draft,
        isRefresh: false,
        errors: payload,
      };
    case LOGOUT_REQUEST: {
      return {
        ...initialState,
      };
    }
    default:
      return draft;
  }
});

export default listFavoriteHashtagReducer;
