import {
    GET_BANNER_REQUEST,
    GET_BANNER_SUCCESS,
    GET_BANNER_FAILED,
} from './types'

export const getBannerRequest = payload => {
    return ({
        type: GET_BANNER_REQUEST,
        payload,
    });
}

export const getBannerSuccess = payload => ({
    type: GET_BANNER_SUCCESS,
    payload,
});

export const getBannerFailure = payload => ({
    type: GET_BANNER_FAILED,
    payload,
});


