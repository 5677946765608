import { LOGOUT_REQUEST } from "@shared/redux/account/constants";
import {
  LIST_SEARCH_STORE_REQUEST,
  LIST_SEARCH_STORE_SUCCESS,
  LIST_SEARCH_STORE_FAILED,
  CLEAR_LIST_SEARCH_STORE,
} from "./constants";

const initialState = {
  listSearchStore: null,
  isRefresh: false,
  error: null,
  searchText: "",
};

const listSearchStoreReducer = (state = initialState, action) => {
  const { payload } = action;
  const refresh = action?.payload?.isRefresh;

  switch (action.type) {
    case LIST_SEARCH_STORE_REQUEST:
      return {
        ...state,
        isRefresh: refresh,
      };

    case LIST_SEARCH_STORE_SUCCESS:
      return {
        ...state,
        isRefresh: false,
        errors: null,
        listSearchStore: payload,
        searchText: payload.searchText,
      };

    case LIST_SEARCH_STORE_FAILED:
      return {
        ...state,
        isRefresh: false,
        errors: payload,
      };
    case CLEAR_LIST_SEARCH_STORE:
      return {
        listSearchStore: null,
        isRefresh: false,
        errors: null,
      };

    case LOGOUT_REQUEST: {
      return {
        ...initialState,
      };
    }
    default:
      return state;
  }
};

export default listSearchStoreReducer;
