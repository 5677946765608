// import reactUtils from '../../helpers/ReactUtils';
// import {COUPON_URL} from '../../constants/api.js';
import { END_POINTS } from '@shared/constants/apiUrl'
import { APIUtils } from 'utils'

export function getUserInfoApi({ creatorId }) {
  const requestData = ``
  return APIUtils.get(END_POINTS.GetUserInfo + `/${creatorId}`)
}

export function getListVideoByCreator({ creatorId, skipCount, maxResult }) {
  const requestData = `?CreatorId=${creatorId}&SkipCount=${skipCount}&MaxResult=${maxResult}`
  return APIUtils.get(END_POINTS.GetVideosByCreatorId + requestData)
}

export function followUserApi({ creatorId }) {
  const requestData = { CreatorId: `${creatorId}` }
  return APIUtils.post(END_POINTS.Follow, { body: requestData })
}

export function unFollowUserApi({ creatorId }) {
  const requestData = { CreatorId: `${creatorId}` }
  return APIUtils.post(END_POINTS.UnFollow, { body: requestData })
}
