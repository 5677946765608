const prefix = 'channel_UtopVideo';

// Fetch channel list
export const CHANNEL_LIST_REQUEST = prefix + '/CHANNEL_LIST_REQUEST';
export const CHANNEL_LIST_SUCCESS = prefix + '/CHANNEL_LIST_SUCCESS';
export const CHANNEL_LIST_FAILED = prefix + '/CHANNEL_LIST_FAILED';

// Fetch channel detail
export const CHANNEL_DETAIL_REQUEST = prefix + '/CHANNEL_DETAIL_REQUEST';
export const CHANNEL_DETAIL_SUCCESS = prefix + '/CHANNEL_DETAIL_SUCCESS';
export const CHANNEL_DETAIL_FAILED = prefix + '/CHANNEL_DETAIL_FAILED';