export const GET_MARKET_INFO = 'market/GET_MARKET_INFO'
export const GET_LIST_FNB_TYPE = 'market/GET_LIST_FNB_TYPE'
export const GET_LIST_CATEGORY_FNB_TYPE = 'market/GET_LIST_CATEGORY_FNB_TYPE'
export const GET_LIST_MEDIA_HOME = 'market/GET_LIST_MEDIA_HOME'
export const GET_LIST_MEDIA_SUMMARY = 'market/GET_LIST_MEDIA_SUMMARY'
export const GET_LIST_MEDIA_SUMMARY_SUCCESS =
  'market/GET_LIST_MEDIA_SUMMARY_SUCCESS'
export const GET_ALL_CATEGORY = 'market/GET_ALL_CATEGORY'
export const GET_LIST_MEDIA_CATEGORY = 'market/GET_LIST_MEDIA_CATEGORY'
export const CLEAR_LIST_MEDIA_SUMMARY = 'market/CLEAR_LIST_MEDIA_SUMMARY'
export const GET_LIST_MEDIA_CATEGORY_SUCCESS =
  'market/GET_LIST_MEDIA_CATEGORY_SUCCESS'
export const CLEAR_LIST_MEDIA_CATEGORY = 'market/CLEAR_LIST_MEDIA_CATEGORY'
export const GET_LIST_STORY = 'market/GET_LIST_STORY'
export const GET_LIST_STORY_SUCCESS = 'market/GET_LIST_STORY_SUCCESS'
export const GET_LIST_FNB_BESTSELLER = 'market/GET_LIST_PRODUCT_BESTSELLER'
export const GET_LIST_SUGGEST_PRODUCT = 'market/GET_LIST_SUGGEST_PRODUCT'
export const GET_LIST_STORE_MERCHANT = 'market/GET_LIST_STORE_MERCHANT'
export const GET_STORE_DETAIL_MERCHANT = 'market/GET_STORE_DETAIL_MERCHANT'
export const GET_SHORT_LINK_TO_SHARE = 'market/GET_SHORT_LINK_TO_SHARE'
