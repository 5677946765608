export const calcTotalPriceAtStore = (itemsInCart) => {
  return (
    itemsInCart?.reduce(
      (total, item) => total + item.itemPrice * item.quantity,
      0
    ) || 0
  );
};

export const calcTotalQuantity = (items) => {
  return (
    items?.reduce((totalQuantity, item) => totalQuantity + item.quantity, 0) ||
    0
  );
};

export const calcUnitPrice = (
  originalPrice,
  chosenSizeOption,
  chosenToppingOptions
) => {
  const toppingsTotalPrice =
    chosenToppingOptions?.reduce((acc, curr) => acc + Number(curr.price), 0) ||
    0;

  const newUnitPrice =
    Number(originalPrice) +
    Number(chosenSizeOption?.extraPrice || chosenSizeOption?.ExtraPrice || 0) +
    toppingsTotalPrice;

  return newUnitPrice;
};
