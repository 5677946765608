import { callApi } from "helpers/callApi";
import { fork, put, takeLeading, takeLatest } from "redux-saga/effects";
import {
  getListNewsBoughtTypeApi,
  getListNewsDataWithTypeApi,
  getListNewsWithTypeApi,
} from "@shared/services/api/listNews";
import {
  getListBoughtPackageTypeFailure,
  getListBoughtPackageTypeSuccess,
  getListUnBoughtDataWithTypeFailed,
  getListUnBoughtDataWithTypeSuccess,
  getListUnBoughtWithTypeHandleFailed,
  getListUnBoughtWithTypeSuccess,
} from "./actions";
import {
  GET_LIST_BOUGHT_PACKAGE,
  GET_LIST_DATA_RECOMMEND_WITH_LEVEL_TYPE_NEWS,
  GET_LIST_RECOMMEND_LEVEL_TYPE_NEWS,
} from "./constants";

export function* getListNewspaperSaga(action) {
  const { onSuccess, skip, take, onFailed, isPurchased } = action;
  try {
    const res = yield callApi(getListNewsBoughtTypeApi, {
      skip,
      take,
      isPurchased,
    });
    yield put(getListBoughtPackageTypeSuccess(res?.data));
    onSuccess?.(res?.data);
  } catch (error) {
    onFailed?.();
    yield put(getListBoughtPackageTypeFailure(error));
  }
}

export function* getListNewspaperUnBoughtWithTypeSaga(action) {
  const { onSuccess, onFailed, status } = action;
  try {
    const res = yield callApi(getListNewsWithTypeApi, status);
    yield put(getListUnBoughtWithTypeSuccess());
    onSuccess?.(res?.data);
  } catch (error) {
    onFailed?.();
    yield put(getListUnBoughtWithTypeHandleFailed(error));
  }
}

export function* getListNewspaperUnBoughtDataWithTypeSaga(action) {
  const { skip, take, newspaperTypeId, isPurchased, onFailed, onSuccess } =
    action;
  try {
    const res = yield callApi(getListNewsDataWithTypeApi, {
      skip,
      take,
      newspaperTypeId,
      isPurchased,
    });
    yield put(getListUnBoughtDataWithTypeSuccess());
    onSuccess?.(res?.data);
  } catch (error) {
    onFailed?.();
    yield put(getListUnBoughtDataWithTypeFailed(error));
  }
}

function* watchListNews() {
  yield takeLatest(GET_LIST_BOUGHT_PACKAGE.HANDLER, getListNewspaperSaga);
  yield takeLeading(
    GET_LIST_RECOMMEND_LEVEL_TYPE_NEWS.HANDLER,
    getListNewspaperUnBoughtWithTypeSaga
  );
  yield takeLeading(
    GET_LIST_DATA_RECOMMEND_WITH_LEVEL_TYPE_NEWS.HANDLER,
    getListNewspaperUnBoughtDataWithTypeSaga
  );
}

export default function* rootChild() {
  yield fork(watchListNews);
}
