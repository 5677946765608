const prefix = 'profile';

export const UNBLOCK_USER_REQUEST = prefix + '/UNBLOCK_USER_REQUEST';
export const UNBLOCK_USER_SUCCESS = prefix + '/UNBLOCK_USER_SUCCESS';
export const UNBLOCK_USER_FAILED = prefix + '/UNBLOCK_USER_FAILED';
export const GET_LIST_BLOCK_USER_REQUEST =
  prefix + '/GET_LIST_BLOCK_USER_REQUEST';
export const GET_LIST_BLOCK_USER_SUCCESS =
  prefix + '/GET_LIST_BLOCK_USER_SUCCESS';
export const GET_LIST_BLOCK_USER_FAILED =
  prefix + '/GET_LIST_BLOCK_USER_FAILED';
