// import { addErrors, delErrors } from "utils/ErrorsGenerator";
// import * as TYPE from "./constants";

const reducers = {
  // [TYPE.CREATOR.success]: (draft, { payload }) => {
  //   draft.errors = delErrors(draft.errors, TYPE.CREATOR.failure);
  //   draft.creator = payload;
  // },
  // [TYPE.CREATOR.failure]: (draft, { payload }) => {
  //   draft.errors = addErrors(draft.errors, {
  //     type: TYPE.CREATOR.failure,
  //     msg: payload,
  //   });
  // },
  // [TYPE.GET_USER_ADDRESS_BOOKS.success]: (draft, { payload }) => {
  //   const formattedAddressBooks = payload?.slice(0, 2)?.map((addressBook) => ({
  //     ...addressBook,
  //     briefAddress: addressBook.address.split(",")[0],
  //   }));
  //   return {
  //     ...draft,
  //     errors: delErrors(draft.errors, TYPE.GET_USER_ADDRESS_BOOKS.failure),
  //     userAddressBooks: formattedAddressBooks,
  //   };
  // },
  // [TYPE.GET_USER_ADDRESS_BOOKS.failure]: (draft, { payload }) => {
  //   return {
  //     ...draft,
  //     errors: addErrors(draft.errors, {
  //       type: TYPE.GET_USER_ADDRESS_BOOKS.failure,
  //       msg: payload,
  //     }),
  //     userAddressBooks: undefined,
  //   };
  // },
  // [TYPE.DELETE_A_USER_ADDRESS_BOOK.success]: (draft, { payload }) => {
  //   const idAddressBookToDelete = payload;
  //   const userAddressBooksAfterDelete = draft.userAddressBooks.filter(
  //     (addressBook) => addressBook.id !== idAddressBookToDelete
  //   );
  //   return {
  //     ...draft,
  //     errors: delErrors(draft.errors, TYPE.DELETE_A_USER_ADDRESS_BOOK.failure),
  //     userAddressBooks: userAddressBooksAfterDelete,
  //   };
  // },
  // [TYPE.DELETE_A_USER_ADDRESS_BOOK.failure]: (draft, { payload }) => {
  //   return {
  //     ...draft,
  //     errors: addErrors(draft.errors, {
  //       type: TYPE.DELETE_A_USER_ADDRESS_BOOK.failure,
  //       msg: payload,
  //     }),
  //   };
  // },
};

export default reducers;
