import moment from 'moment';
import {
  GET_LIST_BOOKING_REQUEST_SUCCESS,
  CLEAR_LIST_BOOKING,
  IS_RELOAD_LIST_BOOKING,
} from './constants';

const initialState = {
  listBooking: {},
  isReload: false,
};

const businessBookingReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LIST_BOOKING_REQUEST_SUCCESS: {
      // let tmpItems = [...state.listBooking.data, ...action.listBooking.data];
      return {
        ...state,
        listBooking: {
          skip: action.listBooking ? action.listBooking.skip : 0,
          isLoadNew: false,
          data: action.listBooking.isLoadNew
            ? action.listBooking.data
            : [...state.listBooking.data, ...action.listBooking.data],
        },
      };
    }
    case IS_RELOAD_LIST_BOOKING: {
      return {...state, isReload: action.isReload};
    }
    case CLEAR_LIST_BOOKING: {
      return initialState;
    }
    default:
      return state;
  }
};

export default businessBookingReducer;
