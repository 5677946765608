/* eslint-disable no-nested-ternary */
/* eslint-disable no-empty */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-param-reassign */
/* eslint-disable no-case-declarations */
/* eslint-disable no-shadow */
/* eslint-disable array-callback-return */
/* eslint-disable no-undef */
/* eslint-disable dot-notation */
import React, { useState, useEffect, useRef } from "react";
// import { Alert, BackHandler } from "react-native";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import {
  getDefaultAddressUserRequest,
  calculateShippingRequest,
  createDefaultAddressRequest,
} from "@shared/redux/orderAddressFood/actions";
import {
  getDefaultAddressUser,
  getListShipping,
} from "@shared/redux/orderAddressFood/selectors";
import {
  getDataStore,
  getListProduct,
} from "@shared/redux/smartMenu/selectors";
import {
  getDescription,
  getDeliveryAddress,
} from "@shared/redux/cart/selectors";
import {
  // ReactUtils,
  NavigationServices,
  checkObjKeys,
  addressToLatlng,
  numberWithCommas,
} from "utils";
import { strings } from "@i18n";
import { getSuggestDiscountShippingRequest } from "@shared/redux/cart/actions";
import { updateProductCartOutOfStock } from "@shared/redux/smartMenu/actions";
import {
  getLatLongSelector,
  getMarkPosition,
} from "@shared/redux/gps/selectors";
import { getGoogleMapApiKeySelector } from "@shared/redux/common/selectors";
import { getEstimateDeliveryTime } from "@shared/redux/OrderStatus/actions";
import { clearMarkPosition } from "@shared/redux/gps/actions";
import validNumberPrefix from "./validNumberPrefix";
import {
  getTotalPrice,
  shippingDiscount,
  formatPhone,
} from "../Market/CartMarket/utils";
import OrderAddressFoodView from "./OrderAddressFood.view";

const OrderAddressFood = (props) => {
  const refTextView = useRef();
  const scrollViewRef = useRef();
  const isSelectedAddress = useRef(false);
  const refGooglePlacesAutocomplete = useRef();
  const [listDeliveryAddress, setListDeliveryAddress] = useState([]);
  const [listShipping, setListShipping] = useState(null);
  const defaultAddressUser = useRef({});
  const [valueAddressUser, setValueAddressUser] = useState(null);
  const [valueUserName, setValueUserName] = useState(null);
  const [valueNumberPhone, setValueNumberPhone] = useState(null);
  const [valueAddressNote, setValueAddressNote] = useState("");
  const [isPickup, setIsPickup] = useState(false);
  const [isFreeText, setIsFreeText] = useState(false);
  const [selectedFreeText, setSelectedFreeText] = useState(false);
  const [isShowLoading, setIsShowLoading] = useState(true);
  const [messageError, setMessageError] = useState("");
  const [isEnableButton, setIsEnableButton] = useState(false);
  const [positionInputAddress, setPositionInputAddress] = useState(0);
  const isDelete = useRef(false);
  const dataOrderFnB = useRef("");
  const deliveryInfo = useRef({});
  const isFreeTextRef = useRef(false);
  const GoogleApiKey = useSelector(getGoogleMapApiKeySelector);
  const listShippingRef = useRef([]);
  const [isVisibleShippingDiscount, setVisibleShippingDiscount] =
    useState(false);
  const [pickupLocation, setPickupLocation] = useState(null);
  const [isFocusInputName, setFocusInputName] = useState(false);
  const [isFocusInputPhone, setFocusInputPhone] = useState(false);
  const [isCheckedDefaultAddress, setCheckedDefaultAddress] = useState(false);
  const checkedDefaultAddressRef = useRef(false);
  const [deliveryTime, setDeliveryTime] = useState(null);

  const params = NavigationServices.getParams(props);

  console.log("paramsOrder", params);

  useEffect(() => {
    if (params?.dataOrder) {
      dataOrderFnB.current = params?.dataOrder;
    }
  }, [params]);

  useEffect(() => {
    checkedDefaultAddressRef.current = isCheckedDefaultAddress;
  }, [isCheckedDefaultAddress]);

  useEffect(() => {
    listShippingRef.current = listShipping;
  }, [listShipping]);

  useEffect(() => {
    deliveryInfo.current = {
      ...deliveryInfo,
      phoneNumber: valueNumberPhone,
      userName: valueUserName,
      note: valueAddressNote,
    };
  }, [valueNumberPhone, valueUserName, valueAddressNote]);

  useEffect(() => {
    if (
      params?.deliveryInfo &&
      Object.keys(params?.deliveryInfo)?.length > 0 &&
      !params?.deliveryInfo["error"]
    ) {
      const deliveryInfoTemp = params?.deliveryInfo;
      setValueUserName(deliveryInfoTemp?.userName || "");
      setValueNumberPhone(formatPhone(deliveryInfoTemp?.phoneNumber));
      setValueAddressNote(deliveryInfoTemp?.note || "");
      if (props.dataStore?.deliveryAddressOptions?.indexOf("FreeText") !== -1) {
        setValueAddressUser(deliveryInfoTemp?.address || "");
        defaultAddressUser.current = deliveryInfoTemp?.address || "";
        handleCalculateShipping(deliveryInfoTemp?.address || "");
        convertAddressToLatLong(deliveryInfoTemp?.address || "");
        isFreeTextRef.current = true;
      } else {
        setIsShowLoading(false);
      }
      setCheckedDefaultAddress(deliveryInfoTemp?.checked || false);
    } else {
      // ReactUtils.getStorage("utopInfo", (asynData) => {
      //   props.getDefaultAddressUserRequest({
      //     onSuccess: (respone) => {
      //       setIsShowLoading(false);
      //       setValueUserName(respone?.name || asynData?.name);
      //       setValueNumberPhone(
      //         respone?.phoneNumber
      //           ? formatPhone(respone?.phoneNumber)
      //           : formatPhone(asynData?.phone)
      //       );
      //       if (isFreeTextRef.current) {
      //         setValueAddressUser(respone?.address || "");
      //         defaultAddressUser.current = respone?.address || "";
      //         handleCalculateShipping(respone?.address || "");
      //         convertAddressToLatLong(respone?.address || "");
      //       }
      //       if (!respone?.address) {
      //         setCheckedDefaultAddress(true);
      //       } else {
      //         setCheckedDefaultAddress(false);
      //       }
      //     },
      //     onFailed: () => {
      //       setValueUserName(asynData?.name || "");
      //       setValueNumberPhone(
      //         asynData?.phone ? formatPhone(asynData?.phone) : ""
      //       );
      //       setIsShowLoading(false);
      //     },
      //   });
      // });
    }
  }, [params?.deliveryInfo, props.router.history.action, props?.markPosition]);

  useEffect(() => {
    const { pickupAddress, pickupLocation } = props?.markPosition;
    const deliveryInfoTemp = params?.deliveryInfo;
    if (pickupAddress && pickupLocation) {
      defaultAddressUser.current = pickupAddress;
      setValueAddressUser(pickupAddress);
      handleCalculateShipping(pickupAddress);
      setPickupLocation(pickupLocation);
      handleCalculateShipping(pickupAddress);
      convertAddressToLatLong(pickupAddress);
      setCheckedDefaultAddress(deliveryInfoTemp?.address === pickupAddress);
    }
  }, [props?.markPosition]);

  // const handleBackPress = () => {
  //   Alert.alert(
  //     "Utop",
  //     strings("OrderAddressFood.labelWarningLeaveScreen"),
  //     [
  //       {
  //         text: strings("OrderAddressFood.labelYes"),
  //         onPress: () => {
  //           NavigationServices.goBack();
  //         },
  //         style: "cancel",
  //       },
  //       {
  //         text: strings("OrderAddressFood.labelCancel"),
  //         style: "cancel",
  //       },
  //     ],
  //     { cancelable: false }
  //   );
  //   return true;
  // };

  useEffect(() => {
    // const unsubscribeFocus = props.navigation.addListener("focus", async () => {
    //   BackHandler.addEventListener("hardwareBackPress", handleBackPress);
    // });
    // const unsubscribeBlur = props.navigation.addListener("blur", () => {
    //   BackHandler.removeEventListener("hardwareBackPress", handleBackPress);
    // });
    if (props.dataStore?.deliveryAddressOptions?.indexOf("Pickup") !== -1) {
      setIsPickup(true);
      setIsFreeText(false);
      isFreeTextRef.current = false;
    }
    if (props.dataStore?.deliveryAddressOptions?.indexOf("FreeText") !== -1) {
      setSelectedFreeText(true);
      setIsFreeText(true);
      isFreeTextRef.current = true;
    }

    if (props.dataStore?.deliveryAddressOptions?.indexOf("Pickup") !== -1) {
      if (props.dataStore?.pickupAddress?.length > 0) {
        setListDeliveryAddress(
          props.dataStore?.pickupAddress.map((ite) => ({
            ...ite,
            isChecked: false,
          }))
        );
      }
    }
    // return () => {
    //   unsubscribeFocus();
    //   unsubscribeBlur();
    // };
  }, []);

  useEffect(() => {
    if (params?.orderAddressFood?.address) {
      setValueAddressUser(params?.orderAddressFood?.address);
      defaultAddressUser.current = params?.orderAddressFood?.address;
      handleCalculateShipping(params?.orderAddressFood?.address);
      convertAddressToLatLong(params?.orderAddressFood?.address);
      setValueUserName(params?.orderAddressFood?.name);
      setValueNumberPhone(formatPhone(params?.orderAddressFood?.phoneNumber));
      setValueAddressNote(params?.orderAddressFood?.note);
    }
  }, [params?.orderAddressFood]);

  useEffect(() => {
    if (!isFocusInputName && !isFocusInputPhone) {
      validDataSubmit();
    } else {
      return setIsEnableButton(false);
    }
  }, [
    valueUserName,
    valueNumberPhone,
    listShipping,
    isFocusInputName,
    isFocusInputPhone,
  ]);

  const validPhoneNumber = (phone) => {
    let isAllowNumberPrefix = false;
    if (phone?.length >= 10) {
      for (let i = 0; i < validNumberPrefix.length; i++) {
        if (formatPhone(phone).startsWith("0")) {
          isAllowNumberPrefix = true;
        }
      }
    }
    return isAllowNumberPrefix;
  };

  const validDataSubmit = () => {
    if (
      valueUserName?.trim() !== "" &&
      valueNumberPhone !== "" &&
      validPhoneNumber(valueNumberPhone) &&
      listShipping?.code
    ) {
      return setIsEnableButton(true);
    }
    return setIsEnableButton(false);
  };

  const convertAddressToLatLong = (address) => {
    if (address) {
      addressToLatlng(address, (json) => {
        const locationTemp = checkObjKeys(
          json,
          "results[0].geometry.location",
          true
        );
        if (Object.keys(locationTemp)?.length > 0) {
          setPickupLocation(locationTemp);
        }
      });
    }
  };

  const handleCalculateShipping = (address) => {
    let requestData = {};
    const products = [];

    if (address !== "") {
      setIsShowLoading(true);
      props.listProduct.map((product) => {
        const { productCode, quantity, attributes, toppings } =
          product.dataInfoProduct;
        const tmpToppings = [];

        toppings.forEach((topping) => {
          const itemTopping = {
            code: topping.code,
            quantity: 1,
          };
          tmpToppings.push(itemTopping);
        });

        const item = {
          productCode,
          quantity,
          attributes,
          toppings: tmpToppings,
        };
        products.push(item);
      });

      requestData = {
        description: props?.description,
        merchantId: props?.dataStore?.merchantId,
        storeId: props?.dataStore?.storeId,
        products,
      };

      if (
        props.deliveryAddress.filter((ite) => ite.isCheck)[0]?.code === "home"
      ) {
        const deliveryInfo = {
          phoneNumber: valueNumberPhone,
          address,
          userName: valueUserName,
          deliveryOption: "",
          note: valueAddressNote,
        };
        requestData.deliveryInfo = deliveryInfo;
      }

      props.calculateShippingRequest({
        requestData,
        onSuccess: (respone) => {
          if (respone?.length > 0) {
            props.getSuggestDiscountShippingRequest(
              {
                storeId: props?.dataStore?.storeId,
                merchantId: props?.dataStore?.merchantId,
                code: checkObjKeys(respone, "[0].code", true),
              },
              (res) => {
                setListShipping({
                  ...checkObjKeys(respone, "[0].value[0]", true),
                  code: checkObjKeys(respone, "[0].code", true),
                  shippingDiscountRange: shippingDiscount(
                    getTotalPrice(props?.listProduct),
                    res
                  ),
                  listSuggestShippingDiscount: res,
                });
                props.getEstimateDeliveryTime(
                  {
                    merchantId: props?.dataStore?.merchantId,
                    storeId: props?.dataStore?.storeId,
                    receiveAddress: defaultAddressUser.current,
                  },
                  (deliveryTimeRes) => {
                    if (deliveryTimeRes?.data?.result?.expectTime?.[0]) {
                      setDeliveryTime(
                        deliveryTimeRes?.data?.result?.expectTime?.[0]
                      );
                    }
                  },
                  (error) => {
                    console.log("getEstimateDeliveryTimeError >>>", error);
                  }
                );
              },
              () => {
                setListShipping({
                  ...checkObjKeys(respone, "[0].value[0]", true),
                  code: checkObjKeys(respone, "[0].code", true),
                });
              }
            );
            setMessageError("");
            setIsShowLoading(false);
          } else {
            setIsShowLoading(false);
            setListShipping(null);
            setMessageError(strings("OrderAddressFood.labelErrorShipping"));
          }
        },
        onFailed: (error) => {
          console.log("calculate shipping >>>", error);
          setIsShowLoading(false);
          setListShipping(null);
          let message = "";
          switch (error?.data?.message) {
            case "NotFoundProduct":
              const productCodeErrors = error?.data?.errors || [];
              if (productCodeErrors.length > 0) {
                const cartTemp = props?.listProduct;
                productCodeErrors.forEach((productCodeError) => {
                  cartTemp.forEach((item) => {
                    if (
                      item?.dataInfoProduct?.productCode === productCodeError
                    ) {
                      item.isOutOfStock = true;
                      props.updateProductCartOutOfStock(item);
                      return;
                    }

                    if (checkObjKeys(item, "data.sizes.iv", true)?.length > 0) {
                      item?.data?.sizes?.iv.forEach((productCodeSize) => {
                        if (
                          productCodeSize === item?.dataInfoProduct?.productCode
                        ) {
                          item.isOutOfStock = true;
                          props.updateProductCartOutOfStock(item);
                        }
                      });
                    }
                  });
                });
              }
              setMessageError(strings("Cart.labelErrorNotFoundProduct"));
              break;

            case "UnsatisfyingMinOrder":
              message = `${
                strings("OrderAddressFood.labelErrorMinOrder") +
                numberWithCommas(error?.data?.errors?.minOrderValue) +
                strings("OrderAddressFood.labelUnitMoney")
              }.`;
              setMessageError(message);
              break;

            case "UnsatisfyingDistance":
              if (error?.data?.errors?.minOrderValue > 0) {
                message = `${
                  strings("OrderAddressFood.labelErrorDistance") +
                  numberWithCommas(error?.data?.errors?.maxDistance) +
                  strings("OrderAddressFood.labelUnitDistance") +
                  strings("OrderAddressFood.labelErrorMinOrderDistance") +
                  numberWithCommas(error?.data?.errors?.minOrderValue) +
                  strings("OrderAddressFood.labelUnitMoney")
                }.`;
              } else {
                message = `${
                  strings("OrderAddressFood.labelErrorDistance") +
                  numberWithCommas(error?.data?.errors?.maxDistance) +
                  strings("OrderAddressFood.labelUnitDistance")
                }.`;
              }
              setMessageError(message);
              break;

            case "ExceedWeightLimitation":
              let maxWeight = (error?.data?.errors?.maxWeight / 1000).toFixed(
                2
              );
              try {
                const maxWeightTemp = maxWeight.split(".");
                if (maxWeightTemp?.[1] === "00") {
                  maxWeight = parseInt(maxWeight, 10);
                }
                // eslint-disable-next-line no-empty
              } catch (error) {}
              maxWeight = numberWithCommas(maxWeight);
              message =
                strings("OrderAddressFood.labelErrorWeight") +
                maxWeight +
                strings("OrderAddressFood.labelUnitWeight") +
                strings("OrderAddressFood.labelErrorWeightEnd");
              setMessageError(message);
              break;

            case "AhaMovePartnerUnallowed":
              setMessageError(strings("OrderAddressFood.labelErrorPartner"));
              break;

            case "ShippingConfigIsNotFound":
              setMessageError(strings("OrderAddressFood.labelErrorConfig"));
              break;

            case "GettingDistanceFail":
              setMessageError(
                strings("OrderAddressFood.labelGettingDistanceFail")
              );
              break;

            case "StoreIsNotFound":
              setMessageError(strings("OrderAddressFood.labelStoreIsNotFound"));

              break;
            case "InvalidUserAddress":
              setMessageError(
                strings("OrderAddressFood.labelInvalidUserAddress")
              );
              break;
            case "InvalidStoreAddress":
              setMessageError(
                strings("OrderAddressFood.labelInvalidStoreAddress")
              );
              break;

            default:
              setMessageError(strings("OrderAddressFood.labelErrorShipping"));
              break;
          }
        },
      });
    }
  };

  const handleConfirm = () => {
    if (!isEnableButton) {
      return;
    }
    const methodShipping = listShipping;

    let isPassed = true;

    if (valueUserName.trim() === "") {
      setMessageError(strings("OrderAddressFood.labelEnterYourName"));
      isPassed = false;
    } else if (valueNumberPhone.trim() === "") {
      setMessageError(strings("OrderAddressFood.labelEnterYourPhone"));
      isPassed = false;
    } else if (
      isNaN(parseInt(valueNumberPhone, 10)) ||
      valueNumberPhone.trim().length < 10
    ) {
      setMessageError(strings("OrderAddressFood.labelInValidPhoneNumber"));
      isPassed = false;
    } else if (defaultAddressUser.current.trim() === "") {
      setMessageError(strings("OrderAddressFood.labelEnterAddress"));
      isPassed = false;
    } else if (JSON.stringify(methodShipping) === "{}") {
      setMessageError(strings("OrderAddressFood.labelEnterUnitShipping"));
      isPassed = false;
    }

    if (isPassed) {
      if (checkedDefaultAddressRef.current && selectedFreeText) {
        // set default address user
        props?.createDefaultAddressRequest({
          address: defaultAddressUser.current,
          name: deliveryInfo.current?.userName,
          phoneNumber: deliveryInfo.current?.phoneNumber,
          note: deliveryInfo.current?.note,
        });
      }
      const addressDeliveryInfo = {
        ...deliveryInfo.current,
        address: defaultAddressUser.current,
        shippingInfo: {
          ...(checkObjKeys(methodShipping, ".value[0]")
            ? methodShipping.value[0]
            : methodShipping),
          code: methodShipping?.code,
        },
        // noReload: true,
      };
      NavigationServices.navigate("/CartMarket", {
        oldDeliveryAddress: {
          ...addressDeliveryInfo,
          checked: checkedDefaultAddressRef.current,
        },
      });
      props.clearMarkPosition();
    }
  };

  const onLayout = () => {
    try {
      refTextView.measure((fy) => {
        setPositionInputAddress(fy);
      });
    } catch (error) {}
  };

  console.log("defaultAddressUser", defaultAddressUser, valueAddressUser);

  return (
    <OrderAddressFoodView
      isShowLoading={isShowLoading}
      scrollViewRef={scrollViewRef}
      valueUserName={valueUserName}
      setValueUserName={setValueUserName}
      setIsEnableButton={setIsEnableButton}
      valueNumberPhone={valueNumberPhone}
      setValueNumberPhone={setValueNumberPhone}
      isFreeText={isFreeText}
      isPickup={isPickup}
      setValueAddressNote={setValueAddressNote}
      setSelectedFreeText={setSelectedFreeText}
      defaultAddressUser={defaultAddressUser}
      valueAddressUser={valueAddressUser}
      selectedFreeText={selectedFreeText}
      handleCalculateShipping={handleCalculateShipping}
      setListDeliveryAddress={setListDeliveryAddress}
      listDeliveryAddres={listDeliveryAddress}
      refTextView={refTextView}
      onLayout={onLayout}
      refGooglePlacesAutocomplete={refGooglePlacesAutocomplete}
      setValueAddressUser={setValueAddressUser}
      isSelectedAddress={isSelectedAddress}
      isDelete={isDelete}
      setListShipping={setListShipping}
      positionInputAddress={positionInputAddress}
      GoogleApiKey={GoogleApiKey}
      valueAddressNote={valueAddressNote}
      listDeliveryAddress={listDeliveryAddress}
      listShipping={listShipping}
      messageError={messageError}
      handleConfirm={handleConfirm}
      isEnableButton={isEnableButton}
      setVisibleShippingDiscount={setVisibleShippingDiscount}
      isVisibleShippingDiscount={isVisibleShippingDiscount}
      totalPrice={getTotalPrice(props?.listProduct)}
      pickupLocation={pickupLocation}
      gps={props.gps}
      convertAddressToLatLong={convertAddressToLatLong}
      validPhoneNumber={validPhoneNumber}
      setFocusInputName={setFocusInputName}
      isFocusInputName={isFocusInputName}
      setFocusInputPhone={setFocusInputPhone}
      isFocusInputPhone={isFocusInputPhone}
      onSelectLocationPress={() => {
        NavigationServices.navigate("MapPickupAddress", {
          markers: {
            pickupAddress: valueAddressUser,
            currentLocation: {
              latitude: props.gps?.currentLocation?.latitude
                ? props.gps?.currentLocation?.latitude
                : null,
              longitude: props.gps?.currentLocation?.longitude
                ? props.gps?.currentLocation?.longitude
                : null,
            },
            pickupLocation: {
              latitude: pickupLocation?.lat
                ? pickupLocation?.lat
                : props.gps?.currentLocation?.latitude
                ? props.gps?.currentLocation?.latitude
                : props.gps?.selectedLocation?.latitude,
              longitude: pickupLocation?.lng
                ? pickupLocation?.lng
                : props.gps?.currentLocation?.longitude
                ? props.gps?.currentLocation?.longitude
                : props.gps?.selectedLocation?.longitude,
            },
          },
          callback: (pickupAddress, pickupLocation) => {
            if (pickupAddress) {
              defaultAddressUser.current = pickupAddress;
              setValueAddressUser(pickupAddress);
              handleCalculateShipping(pickupAddress);
              setPickupLocation({
                lat: pickupLocation?.latitude,
                lng: pickupLocation?.longitude,
              });
            }
          },
        });
      }}
      setCheckedDefaultAddress={setCheckedDefaultAddress}
      isCheckedDefaultAddress={isCheckedDefaultAddress}
      deliveryTime={deliveryTime}
    />
  );
};

const mapStateToProps = (state) => ({
  dataStore: getDataStore(state),
  listProduct: getListProduct(state),
  listShipping: getListShipping(state),
  description: getDescription(state),
  deliveryAddress: getDeliveryAddress(state),
  defaultAddressUser: getDefaultAddressUser(state),
  gps: getLatLongSelector(state),
  markPosition: getMarkPosition(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getDefaultAddressUserRequest,
      calculateShippingRequest,
      getSuggestDiscountShippingRequest,
      updateProductCartOutOfStock,
      createDefaultAddressRequest,
      getEstimateDeliveryTime,
      clearMarkPosition,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(OrderAddressFood);
