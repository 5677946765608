/* eslint-disable max-len */
import {call, put, takeLatest, fork} from 'redux-saga/effects';
import {
  getUserInfoApi,
  unFollowUserApi,
  followUserApi,
  getListVideoByCreator,
} from './apiService';
import {
  getListVideoSuccess,
  getListVideoFailed,
  getUserInfoSuccess,
  getUserInfoFailed,
  followUserSuccess,
  followUserFailed,
  unFollowUserSuccess,
  unFollowUserFailed,
} from './actions';
import {
  GET_LIST_VIDEO_REQUEST,
  GET_USER_INFO_REQUEST,
  FOLLOW_USER_REQUEST,
  UN_FOLLOW_USER_REQUEST,
} from './constants';

function* getUserInfoRequest(obj) {
  const {onSuccess, onError} = obj.payload;
  try {
    const res = yield call(getUserInfoApi, obj.payload);
    if (res.httpStatus === 200) {
      yield put(getUserInfoSuccess({data: res}));
      if (typeof onSuccess === 'function') {
        onSuccess({data: res});
      }
    } else {
      onError?.(res);
      yield put(getUserInfoFailed());
    }
  } catch (err) {
    onError?.(err);
    yield put(getUserInfoFailed(err));
    // handleError(err);
  }
}

function* followUserRequest(obj) {
  const {creatorId, onSuccess} = obj.payload;
  try {
    const res = yield call(followUserApi, obj.payload);

    if (res.httpStatus === 200) {
      yield put(followUserSuccess({creatorId}));
      if (typeof onSuccess === 'function') {
        onSuccess({creatorId});
      }
    } else {
      yield put(followUserFailed());
    }
  } catch (err) {
    yield put(followUserFailed(err));
    // handleError(err);
  }
}

function* unFollowUserRequest(obj) {
  const {creatorId, onSuccess} = obj.payload;
  try {
    const res = yield call(unFollowUserApi, obj.payload);

    if (res.httpStatus === 200) {
      yield put(unFollowUserSuccess({creatorId}));
      if (typeof onSuccess === 'function') {
        onSuccess({creatorId});
      }
    } else {
      yield put(unFollowUserFailed());
    }
  } catch (err) {
    yield put(unFollowUserFailed(err));
    // handleError(err);
  }
}

function* getListVideoRequest(obj) {
  const {onSuccess} = obj.payload;
  try {
    const res = yield call(getListVideoByCreator, obj.payload);
    if (Array.isArray(res)) {
      yield put(
        getListVideoSuccess({
          data: res,
        }),
      );
      if (typeof onSuccess === 'function') {
        onSuccess({
          data: res,
        });
      }
    } else {
      yield put(getListVideoFailed());
    }
  } catch (err) {
    yield put(getListVideoFailed(err));
    // handleError(err);
  }
}

function* watchUserInfo() {
  yield takeLatest(GET_USER_INFO_REQUEST, getUserInfoRequest);
  yield takeLatest(GET_LIST_VIDEO_REQUEST, getListVideoRequest);
  yield takeLatest(FOLLOW_USER_REQUEST, followUserRequest);
  yield takeLatest(UN_FOLLOW_USER_REQUEST, unFollowUserRequest);
}

export default function* rootChild() {
  yield fork(watchUserInfo);
}
