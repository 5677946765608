import { initializeApp } from "firebase/app";
import {
  getAuth,
  signOut,
  signInWithCustomToken,
  onAuthStateChanged,
  // signInWithEmailAndPassword
} from "firebase/auth";

import config from "./apiConfig";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseUrl: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

initializeApp(firebaseConfig);

export const authFirebase = getAuth();

export function getUserProfile() {
  return new Promise((resolve, reject) => {
    onAuthStateChanged(authFirebase, (user) => {
      if (user) {
        resolve(user);
      } else {
        reject(new Error("Ops!"));
      }
    });
  });
}

export async function getsignInWithCustomToken({ customToken }) {
  return signInWithCustomToken(authFirebase, customToken)
    .then((userCredential) => {
      return userCredential.user;
    })
    .catch(() => {
      handleLogout(true);
    });
}

export function handleLogout(isLogin) {
  signOut(authFirebase);

  const loginForPartnerUrl = !window.location.href.includes("localhost")
    ? process.env.REACT_APP_AUTHORITY
    : process.env.REACT_APP_AUTHORITY_LOCAL;
  let url = `${loginForPartnerUrl}/logout.html?redirect=${config.baseURL}`;
  if (isLogin) url = `${url}/login`;
  // window.location.href = url;
  window.location.replace(url);
}

// export function getSendEmailVerification(email) {
//   return new Promise((resolve) => {
//     const passwordRandom = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5);
//     signInWithEmailAndPassword(authFirebase, email, passwordRandom)
//       .then(() => {
//         resolve(true);
//       })
//       .catch((error) => {
//         if (error.code === 'auth/wrong-password') {
//           resolve(true);
//         }
//         resolve(false);
//       });
//   })
// }
