import { call, put, takeLatest, fork, select } from "redux-saga/effects";
import { parseListFeed } from "helpers/parsers";
import {
  FEED_SEARCH_PARAMS,
  WEB_ACTION_URL_PARAMS_REGEX,
} from "constants/types";
import actions from "./actions";
import * as TYPE from "./constants";
import api from "../api/api";

const sagas = {
  // *[TYPE.GET_LIST_FEED__FILTER_BY_ZONE.request](action) {
  //   try {
  //     yield put(actions.SET_LOADING(true));

  //     const queryParams = action.payload;

  //     const response = yield call(
  //       api[TYPE.GET_LIST_FEED__FILTER_BY_ZONE.request],
  //       queryParams
  //     );

  //     if (response.status === 200) {
  //       yield put(actions.GET_LIST_FEED__FILTER_BY_ZONE.success(response.data));
  //     } else {
  //       yield put(
  //         actions.GET_LIST_FEED__FILTER_BY_ZONE.failure(response?.data)
  //       );
  //     }
  //     yield put(actions.SET_LOADING(false));
  //   } catch (error) {
  //     yield put(actions.GET_LIST_FEED__FILTER_BY_ZONE.failure(error?.data));
  //     yield put(actions.SET_LOADING(false));
  //   }
  // },
  // *[TYPE.GET_LIST_FEED__OPEN_LIST_STORE.request](action) {
  //   try {
  //     yield put(actions.SET_LOADING(true));

  //     const queryParams = action.payload;

  //     const response = yield call(
  //       api[TYPE.GET_LIST_FEED__OPEN_LIST_STORE.request],
  //       queryParams
  //     );

  //     if (response.status === 200) {
  //       yield put(
  //         actions.GET_LIST_FEED__OPEN_LIST_STORE.success(response.data)
  //       );
  //     } else {
  //       yield put(
  //         actions.GET_LIST_FEED__OPEN_LIST_STORE.failure(response?.data)
  //       );
  //     }
  //     yield put(actions.SET_LOADING(false));
  //   } catch (error) {
  //     yield put(actions.GET_LIST_FEED__OPEN_LIST_STORE.failure(error?.data));
  //     yield put(actions.SET_LOADING(false));
  //   }
  // },
  // *[TYPE.GET_LIST_FEED__OPEN_CAT.request](action) {
  //   try {
  //     yield put(actions.SET_LOADING(true));

  //     const queryParams = action.payload;

  //     const response = yield call(
  //       api[TYPE.GET_LIST_FEED__OPEN_CAT.request],
  //       queryParams
  //     );
  //     if (response.status === 200) {
  //       yield put(actions.GET_LIST_FEED__OPEN_CAT.success(response.data));
  //     } else {
  //       yield put(actions.GET_LIST_FEED__OPEN_CAT.failure(response?.data));
  //     }

  //     yield put(actions.SET_LOADING(false));
  //   } catch (error) {
  //     yield put(actions.GET_LIST_FEED__OPEN_CAT.failure(error?.data));
  //     yield put(actions.SET_LOADING(false));
  //   }
  // },

  // *** GET_LIST_FEED
  *[TYPE.GET_LIST_FEED.request](action) {
    const { take, localeCode = "vi", type } = action.payload;
    let { query } = action.payload;
    try {
      // nếu không có isGrouping trên url
      // - có merchantId -> isGrouping: false
      // - k có merchantId -> isGrouping: true
      if (!WEB_ACTION_URL_PARAMS_REGEX.isGrouping.test(query)) {
        query = `${query}&${FEED_SEARCH_PARAMS.isGrouping}=${!query.includes(
          FEED_SEARCH_PARAMS.merchantId
        )}`;
      }

      const response = yield call(api[TYPE.GET_LIST_FEED.request], {
        query,
      });

      if (response.status === 200) {
        const isInit = false;
        const nextFeeds =
          parseListFeed({ listFeed: response.data }, localeCode) || [];
        const hasMore = nextFeeds?.length === take;
        const prevListFeed = yield select(getListFeed);
        const newListFeed =
          type === "load"
            ? {
                value: [...prevListFeed.value, ...nextFeeds],
                hasMore,
                isInit,
              }
            : {
                value: [...nextFeeds],
                hasMore,
                isInit,
              };

        yield put(actions.GET_LIST_FEED.success(newListFeed));
      } else {
        yield put(actions.GET_LIST_FEED.failure(response?.data));
      }
    } catch (error) {
      yield put(actions.GET_LIST_FEED.failure(error?.data));
    }
  },

  // *** GET_LIST_STORE_OF_MERCHANT
  *[TYPE.GET_LIST_STORE_OF_MERCHANT.request](action) {
    const {
      latitude,
      longitude,
      feedTypes,
      merchantId,
      skip,
      take = 20,
      onSuccess,
    } = action.payload;

    try {
      const response = yield call(
        api[TYPE.GET_LIST_STORE_OF_MERCHANT.request],
        {
          merchantId,
          feedTypes,
          latitude,
          longitude,
          skip,
          take,
          isGrouping: false, // search store của 1 merchant nên ko group feed theo merchant
        }
      );

      if (response.status === 200) {
        const listStore =
          parseListFeed({ listFeed: response.data, localeCode: "vi" }) || [];
        const prevListStoreData = yield select(getListStoreOfSelectedMerchant);
        const shouldReplacePrevListStore =
          merchantId !== prevListStoreData.merchantId;
        const listStoreOfSelectedMerchant = {
          merchantId,
          listStore: shouldReplacePrevListStore
            ? listStore
            : [...prevListStoreData.listStore, ...listStore],
          hasMore: listStore.length === take,
        };

        yield put(
          actions.GET_LIST_STORE_OF_MERCHANT.success(
            listStoreOfSelectedMerchant
          )
        );

        if (onSuccess) {
          yield call(onSuccess);
        }
      } else {
        yield put(actions.GET_LIST_STORE_OF_MERCHANT.failure(response?.data));
      }
    } catch (error) {
      yield put(actions.GET_LIST_STORE_OF_MERCHANT.failure(error?.data));
    }
  },
};

function* watchedSaga() {
  // yield takeLatest(
  //   TYPE.GET_LIST_FEED__FILTER_BY_ZONE.request,
  //   sagas[TYPE.GET_LIST_FEED__FILTER_BY_ZONE.request]
  // );
  // yield takeLatest(
  //   TYPE.GET_LIST_FEED__OPEN_LIST_STORE.request,
  //   sagas[TYPE.GET_LIST_FEED__OPEN_LIST_STORE.request]
  // );
  // yield takeLatest(
  //   TYPE.GET_LIST_FEED__OPEN_CAT.request,
  //   sagas[TYPE.GET_LIST_FEED__OPEN_CAT.request]
  // );

  // * GET_LIST_FEED
  yield takeLatest(
    TYPE.GET_LIST_FEED.request,
    sagas[TYPE.GET_LIST_FEED.request]
  );

  // * GET_LIST_STORE_OF_MERCHANT
  yield takeLatest(
    TYPE.GET_LIST_STORE_OF_MERCHANT.request,
    sagas[TYPE.GET_LIST_STORE_OF_MERCHANT.request]
  );
}

export default function* rootChild() {
  yield fork(watchedSaga);
}

// *** SELECTORS
const getListFeed = (state) => state[TYPE.TYPE].listFeed;
const getListStoreOfSelectedMerchant = (state) =>
  state[TYPE.TYPE].listStoreOfSelectedMerchant;
