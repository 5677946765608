import { put, takeLatest, fork } from 'redux-saga/effects'
import {
  CANCEL_ORDER_REQUEST,
  CANCEL_PENDING_ITEM_REQUEST,
  GET_PRODUCT_CHANGED_REQUEST,
  SET_RECEIVED_ORDER_REQUEST,
  GET_ESTIMATE_DELIVERY_TIME_REQUEST,
  GET_CUSTOM_CONFIG_MAP_REQUEST,
} from './constants'
import {
  cancelOrderFailed,
  cancelOrderSuccess,
  cancelPendingItemFailed,
  cancelPendingItemSuccess,
  getEstimateDeliveryTimeFailed,
  getEstimateDeliveryTimeSuccess,
  getProductChangedFailed,
  getProductChangedSuccess,
} from './actions'
import {
  cancelOrderApi,
  cancelItemPendingApi,
  getProductChangedApi,
  setReceivedOrderApi,
  getEstimateDeliveryTimeApi,
  getCustomConfigMapApi,
} from '../../services/api/OrderStatus'
import { callApi } from 'helpers/callApi'

export function* cancelOrderSaga(obj) {
  const { payload = {}, onSuccess, onFailed } = obj
  try {
    const res = yield callApi(cancelOrderApi, payload)
    yield put(cancelOrderSuccess(res))
    if (typeof onSuccess === 'function') {
      onSuccess(res)
    }
  } catch (err) {
    if (typeof onFailed === 'function') {
      onFailed()
    }
    yield put(cancelOrderFailed(err))
  }
}

export function* cancelPendingItemSaga(obj) {
  const { payload = {}, onSuccess, onFailed } = obj
  try {
    const res = yield callApi(cancelItemPendingApi, payload)
    yield put(cancelPendingItemSuccess(res))
    if (typeof onSuccess === 'function') {
      onSuccess(res)
    }
  } catch (err) {
    if (typeof onFailed === 'function') {
      onFailed()
    }
    yield put(cancelPendingItemFailed(err))
  }
}

export function* getProductChangedSaga(obj) {
  const { payload = {}, onSuccess, onFailed } = obj
  try {
    const res = yield callApi(getProductChangedApi, payload)
    yield put(getProductChangedSuccess(res))
    if (typeof onSuccess === 'function') {
      onSuccess(res)
    }
  } catch (err) {
    if (typeof onFailed === 'function') {
      onFailed()
    }
    yield put(getProductChangedFailed(err))
  }
}

export function* setReceivedOrderSaga(obj) {
  const { payload, onSuccess, onFailed } = obj
  try {
    const res = yield callApi(setReceivedOrderApi, payload)
    if (typeof onSuccess === 'function') {
      onSuccess(res)
    }
  } catch (err) {
    if (typeof onFailed === 'function') {
      onFailed()
    }
  }
}

export function* getEstimateDeliveryTimeSaga(obj) {
  const { payload, onSuccess, onFailed } = obj
  try {
    const res = yield callApi(getEstimateDeliveryTimeApi, payload)
    yield put(getEstimateDeliveryTimeSuccess(res))
    if (typeof onSuccess === 'function') {
      onSuccess(res)
    }
  } catch (err) {
    yield put(getEstimateDeliveryTimeFailed(err))
    if (typeof onFailed === 'function') {
      onFailed()
    }
  }
}
export function* getCustomConfigMapSaga(obj) {
  const { payload, onSuccess, onFailed } = obj
  try {
    const res = yield callApi(getCustomConfigMapApi, payload)
    if (typeof onSuccess === 'function') {
      onSuccess(res)
    }
  } catch (err) {
    if (typeof onFailed === 'function') {
      onFailed()
    }
  }
}

function* watchCommon() {
  yield takeLatest(CANCEL_ORDER_REQUEST, cancelOrderSaga)
  yield takeLatest(CANCEL_PENDING_ITEM_REQUEST, cancelPendingItemSaga)
  yield takeLatest(GET_PRODUCT_CHANGED_REQUEST, getProductChangedSaga)
  yield takeLatest(SET_RECEIVED_ORDER_REQUEST, setReceivedOrderSaga)
  yield takeLatest(
    GET_ESTIMATE_DELIVERY_TIME_REQUEST,
    getEstimateDeliveryTimeSaga,
  )
  yield takeLatest(GET_CUSTOM_CONFIG_MAP_REQUEST, getCustomConfigMapSaga)
}

export default function* rootChild() {
  yield fork(watchCommon)
}
