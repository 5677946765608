import React from "react";
import Skeleton from "react-loading-skeleton";
import classNames from "classnames";
import { CloseGray16x16 } from "assets/svg";
import { useToggleBodyScroll } from "hooks";
import "./Dock.scss";

/**
 * A dock panel, nailed at bottom and show up when @show  and @children exist
 * if @children not exists, show a default skeleton
 */

const Dock = (props) => {
  const {
    show,
    handleClose,
    children,
    showBtnClose,
    btnCloseClassName,
    btnCloseStyle,
  } = props;

  useToggleBodyScroll({ shouldFreeze: show });

  const handleClickBackdrop = () => {
    if (show) {
      handleClose();
    }
  };

  return (
    <div className="dock-container">
      <div className={classNames("dock", show && "dock--show")}>
        {/* <div className="dock__top">
          <div className="dock__top__drag-indicator" />
        </div> */}

        <div className="dock__children">
          {showBtnClose && (
            <button
              type="button"
              className={`dock__btn-close _btn _btn--dark-mode ${
                btnCloseClassName || ""
              }`}
              onClick={handleClose}
              style={btnCloseStyle}
            >
              <CloseGray16x16 />
            </button>
          )}
          {children || <ContentSkeleton />}
        </div>
      </div>

      <div
        onClick={handleClickBackdrop}
        className={classNames("dock-backdrop", show && "dock-backdrop--show")}
      />
    </div>
  );
};

export default Dock;

const ContentSkeleton = () => {
  return (
    <div className="dock__children__skeleton-container--default">
      <Skeleton width="80%" />
      <Skeleton width="80%" />
      <Skeleton width="60%" />
    </div>
  );
};
