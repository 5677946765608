const root = 'mobileCard';
export const GET_MOBILE_CARD_LIST_REQUEST =
  root + '/GET_MOBILE_CARD_LIST_REQUEST';

export const GET_MOBILE_CARD_LIST_SUCCESS =
  root + '/GET_MOBILE_CARD_LIST_SUCCESS';

export const GET_MOBILE_CARD_LIST_FAILED =
  root + '/GET_MOBILE_CARD_LIST_FAILED';

export const GET_BUY_CARD_LIST_REQUEST = root + '/GET_BUY_CARD_LIST_REQUEST';

export const GET_BUY_CARD_LIST_SUCCESS = root + '/GET_BUY_CARD_LIST_SUCCESS';

export const GET_BUY_CARD_LIST_FAILED = root + '/GET_BUY_CARD_LIST_FAILED';

export const CREATE_ORDER_REQUEST = root + '/CREATE_ORDER_REQUEST';

export const CREATE_ORDER_SUCCESS = root + '/CREATE_ORDER_SUCCESS';

export const CREATE_ORDER_FAILED = root + '/CREATE_ORDER_FAILED';

export const PAYMENT_REQUEST = root + '/PAYMENT_REQUEST';

export const PAYMENT_SUCCESS = root + '/PAYMENT_SUCCESS';

export const PAYMENT_FAILED = root + '/PAYMENT_FAILED';
