/* eslint-disable no-param-reassign */
import { toast } from "react-toastify";
import { addErrors, delErrors } from "utils/ErrorsGenerator";
import { toastOptions } from "utils/toast";
import * as TYPE from "./constants";

const reducers = {
  [TYPE.VOUCHER_DETAIL.request]: (draft) => {
    return {
      ...draft,
      isLoading: {
        ...draft.isLoading,
        VOUCHER_DETAIL: true,
      },
    };
  },
  [TYPE.VOUCHER_DETAIL.success]: (draft, { payload }) => {
    return {
      ...draft,
      errors: delErrors(draft.errors, TYPE.VOUCHER_DETAIL.failure),
      voucherDetail: payload,
      isLoading: {
        ...draft.isLoading,
        VOUCHER_DETAIL: false,
      },
    };
  },
  [TYPE.VOUCHER_DETAIL.failure]: (draft, { payload }) => {
    draft.errors = addErrors(draft.errors, {
      type: TYPE.VOUCHER_DETAIL.failure,
      msg: payload,
      isLoading: {
        ...draft.isLoading,
        VOUCHER_DETAIL: true,
      },
    });
  },

  [TYPE.VOUCHER_INFOMATION.success]: (draft, { payload }) => {
    return {
      ...draft,
      errors: delErrors(draft.errors, TYPE.VOUCHER_INFOMATION.failure),
      voucherInfomation: payload,
    };
  },

  [TYPE.VOUCHER_INFOMATION.failure]: (draft, { payload }) => {
    draft.errors = addErrors(draft.errors, {
      type: TYPE.VOUCHER_INFOMATION.failure,
      msg: payload,
    });
  },

  // [TYPE.GET_VOUCHER_OUTSTAND.success]: (draft, { payload }) => {
  //   return {
  //     ...draft,
  //     errors: delErrors(draft.errors, TYPE.GET_VOUCHER_OUTSTAND.failure),
  //     morevoucher: payload,
  //   };
  // },
  // [TYPE.GET_VOUCHER_OUTSTAND.failure]: (draft, { payload }) => {
  //   draft.errors = addErrors(draft.errors, {
  //     type: TYPE.GET_VOUCHER_OUTSTAND.failure,
  //     msg: payload,
  //   });
  // },

  [TYPE.CLICK_BY_MARK_USED.request]: (draft) => {
    draft.isOrderCreated = false;
  },
  [TYPE.CLICK_BY_MARK_USED.success]: (draft) => {
    toast.success("Đánh dấu thành công!", toastOptions);
    draft.errors = delErrors(draft.errors, TYPE.CLICK_BY_MARK_USED.failure);
    draft.isOrderCreated = true;
  },
  [TYPE.CLICK_BY_MARK_USED.failure]: (draft, { payload }) => {
    draft.errors = addErrors(draft.errors, {
      type: TYPE.CLICK_BY_MARK_USED.failure,
      msg: payload,
    });
    draft.isOrderCreated = false;
  },
};

export default reducers;
