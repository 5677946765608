import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import SessionStorageUtils from "utils/sessionStorageUtils";

export default function useScrollMemory() {
  const history = useHistory();

  // remove caching data & scroll pos on refresh
  useEffect(() => {
    const handleBeforeUnload = () => {
      SessionStorageUtils.removeLoadedData({
        pathname: history.location.pathname,
      });
    };

    window.history.scrollRestoration = "manual"; // tắt tính năng tự động keep vị trí scroll của trình duyệt
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    const { push, replace } = history;

    // Override the history PUSH method to automatically set scroll state.
    history.push = (path, state = {}) => {
      SessionStorageUtils.storeScrollPos({
        pathname: history.location.pathname,
        search: history.location.search,
      });
      push(path, {
        ...state,
        prevPage: `${history.location.pathname}${history.location.search}`,
        prevPathname: history.location.pathname,
        prevSearch: history.location.search,
      });
    };
    // Override the history REPLACE method to automatically set scroll state.
    history.replace = (path, state = {}) => {
      SessionStorageUtils.storeScrollPos({
        pathname: history.location.pathname,
        search: history.location.search,
      });
      replace(path, {
        ...state,
        prevPage: `${history.location.pathname}${history.location.search}`,
        prevPathname: history.location.pathname,
        prevSearch: history.location.search,
      });
    };

    // Listen for location changes and set the scroll position accordingly.
    const unregister = history.listen((location, action) => {
      if (
        action === "POP" &&
        !exceptionPathnameList.includes(location.pathname)
      ) {
        const prevScrollY = SessionStorageUtils.getPrevScrollY({
          pathname: location.pathname,
          search: location.search,
        });

        setTimeout(() => {
          window.scrollTo({ top: prevScrollY, behavior: "auto" });
        }, 100);
      }
    });

    // Unregister listener when component unmounts.
    return () => {
      unregister();
    };
  }, [history]);
}

const exceptionPathnameList = [];
