import { put, takeLatest, fork, call, takeEvery } from "redux-saga/effects";
import {
  GET_LIST_VIDEO_BY_LOCATION_REQUEST,
  GET_LIST_VIDEO_DYNAMIC_REQUEST,
} from "./constants";
import {
  getListVideoByLocationFailed,
  getListVideoByLocationSuccess,
  getListVideoDynamicFailed,
  getListVideoDynamicSuccess,
} from "./actions";
import {
  getListVideoByLocationApi,
  getListVideoDynamicApi,
} from "@shared/services/api/video";

export function* getListVideoDynamicSaga(obj) {
  const { onSuccess } = obj;
  try {
    const data = yield call(getListVideoDynamicApi, obj.payload);
    onSuccess(data.data.value);
    yield put(getListVideoDynamicSuccess(data.data.value));
  } catch (err) {
    yield put(getListVideoDynamicFailed(err));
  }
}

export function* getListVideoByLocationSaga(obj) {
  try {
    const data = yield call(getListVideoByLocationApi, obj.payload);
    yield put(getListVideoByLocationSuccess(data.data));
  } catch (err) {
    yield put(getListVideoByLocationFailed(err));
  }
}

function* watchVideo() {
  yield takeEvery(GET_LIST_VIDEO_DYNAMIC_REQUEST, getListVideoDynamicSaga);
  yield takeLatest(
    GET_LIST_VIDEO_BY_LOCATION_REQUEST,
    getListVideoByLocationSaga
  );
}

export default function* rootChild() {
  yield fork(watchVideo);
}
