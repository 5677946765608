import { genActionTypes } from "utils/actionGenerator";

export const TYPE = "DeliveryDetail";

export const ORDER_HISTORY_DETAIL = genActionTypes(
  `${TYPE}_ORDER_HISTORY_DETAIL`
);
export const CANCEL_VOUCHER = genActionTypes(`${TYPE}_CANCEL_VOUCHER`);
export const GET_PAYMENT_METHODS = genActionTypes(
  `${TYPE}_LIST_TRANSACTION_METHODS`
);
export const GET_WHOLESALE_LIST = genActionTypes(
  `${TYPE}_GET_WHOLESALE_LIST}`
);
