/* eslint-disable no-unused-vars */
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { useHistory, useLocation } from "react-router-dom";
import { CART_KEYS_MAP, ORDER_TYPES } from "constants/types";
// import { IStoreGray } from "assets/svg";
import { CForm } from "@coreui/react";
import PageHeader from "views/common/component/PageHeader";
import DebounceInput from "views/common/component/DebounceInput";
import AlertAction from "views/common/component/AlertAction";
import { CouponItem, CouponSearchResultItem } from "./components";

import "./PromotionV2.scss";

/**
 * - location.state.orderType: *must have*
 * - location.state.callbackUrl
 * - location.state.prevPage
 * @returns
 */
const PromotionV2View = (props) => {
  const history = useHistory();
  const location = useLocation();
  const [searchText, setSearchText] = useState("");

  useLayoutEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  useEffect(() => {
    if (!ORDER_TYPES[location.state?.orderType]) {
      history.push("/");
    }
  }, []);

  // search logic
  const handleSearchTextChange = (couponCode) => {
    const message = { action: "searchCoupon" };
    setSearchText(couponCode);
    message.data = couponCode;

    if (couponCode?.length < 4) {
      return;
    }

    props.onMessage(message);
  };

  const onClickUseNow = (item) => {
    const message = {};
    message.action = "useNow";
    message.data = item;
    props.onMessage(message);
  };
  // promo logic

  const handleGotoDetail = (item) => () => {
    const message = {};
    message.action = "detailCoupon";
    message.data = item;
    props.onMessage(message);
  };

  /**
   * Back về trang trước
   */
  const handleGoBack = () => {
    if (location.state?.prevPage) {
      history.goBack();
      return;
    }

    history.push("/");
  };

  const renderCouponList = useCallback(() => {
    if (props.isLoadingList) {
      return (
        <>
          <CouponSearchResultItem handleToggleApplyPromo={() => {}} />
          <CouponSearchResultItem handleToggleApplyPromo={() => {}} />
        </>
      );
    }

    return (
      <>
        {!searchText?.length > 0 &&
          props.data &&
          props?.data.map((coupon) => (
            <CouponItem
              key={coupon.transactionCode}
              id={coupon.couponId}
              name={coupon?.couponName}
              code={coupon?.couponCode}
              couponCode={coupon.couponCode}
              expiryDate={coupon?.expiredDay}
              couponData={coupon}
              handleToggleApplyPromo={onClickUseNow}
              handleOnClick={handleGotoDetail(coupon)}
            />
          ))}
      </>
    );
  }, [props.isLoadingList, searchText, props.data]);

  const renderFoundCouponList = useCallback(() => {
    if (props.isLoadingSearch) {
      return <CouponSearchResultItem handleToggleApplyPromo={() => {}} />;
    }

    return (
      <>
        {searchText?.length > 0 && (
          <CouponSearchResultItem
            key={props?.dataSearch[0]?.couponId}
            id={props?.dataSearch[0]?.couponId}
            name={props?.dataSearch[0]?.couponName}
            code={props?.dataSearch[0]?.couponCode}
            couponCode={props?.dataSearch[0]?.couponCode}
            expiryDate={props?.dataSearch[0]?.expiredDay}
            couponData={props?.dataSearch[0]}
            handleToggleApplyPromo={onClickUseNow}
            handleOnClick={handleGotoDetail({
              ...props?.dataSearch[0],
              isSearch: props.isSearch,
            })}
          />
        )}
      </>
    );
  }, [searchText, props?.dataSearch, props.isLoadingSearch]);

  return (
    <>
      {!props.isLoadingList && (
        <div className="promotion-page">
          <div
            className="position-fixed top-0 start-0 end-0"
            style={{ zIndex: 1 }}
          >
            <PageHeader title="Mã ưu đãi" onNavigate={handleGoBack} />

            <div
              className="p-3 bgr-white promotion-page__search-box"
              style={{ zIndex: 1 }}
            >
              <CForm
                className="--d-flex --align-items-center px-3 py-1"
                style={{
                  backgroundColor: "#F3F3F3",
                  borderRadius: 7,
                  height: 40,
                }}
              >
                <span className="icon-loupe mr-2" />
                <DebounceInput
                  className="_fw-400 _fs-14px _outline-none _border-none"
                  type="text"
                  placeholder="Tìm kiếm mã khuyến mãi"
                  onCommitChange={handleSearchTextChange}
                  inputStyle={{ backgroundColor: "#F3F3F3" }}
                  delayTime={300}
                  showClearButton
                />
              </CForm>
            </div>
          </div>

          <div style={{ height: 72 + 56 }} />

          {/* promo list */}
          <div className="promo-list p-3">
            {!props.isLoadingSearch &&
            searchText?.length >= 4 &&
            props?.dataSearch[0]?.noResult ? (
              <div className="text-center">Không tìm thấy mã ưu đãi</div>
            ) : (
              renderFoundCouponList()
            )}

            {renderCouponList()}
          </div>
        </div>
      )}
      <AlertAction
        isOpen={props?.dataAlertAction?.isShow}
        content={props?.dataAlertAction?.content}
        title={props?.dataAlertAction?.title}
        titleAccept={props?.dataAlertAction?.titleAccept}
        titleCancel={props?.dataAlertAction?.titleCancel}
        handleClose={props?.setDataAlertAction}
        handleAccept={props?.handleAlertAction}
        dataItem={props?.dataAlertAction?.dataItem}
      />
    </>
  );
};

export default PromotionV2View;
