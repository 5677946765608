import {
    HASHTAG_LIST_REQUEST,
    HASHTAG_LIST_SUCCESS,
    HASHTAG_LIST_FAILED,
    HASHTAG_DETAIL_REQUEST,
    HASHTAG_DETAIL_SUCCESS,
    HASHTAG_DETAIL_FAILED,
    HASHTAG_INFO_REQUEST,
    HASHTAG_INFO_SUCCESS,
    HASHTAG_INFO_FAILED,
    FAVORITE_HASHTAG_REQUEST,
    FAVORITE_HASHTAG_SUCCESS,
    FAVORITE_HASHTAG_FAILED,
    DIS_FAVORITE_HASHTAG_REQUEST,
    DIS_FAVORITE_HASHTAG_SUCCESS,
    DIS_FAVORITE_HASHTAG_FAILED,

} from './types';

export const hashtagListRequest = payload => ({
    type: HASHTAG_LIST_REQUEST,
    payload,
});

export const hashtagListSuccess = payload => ({
    type: HASHTAG_LIST_SUCCESS,
    payload,
});

export const hashtagListFailure = payload => ({
    type: HASHTAG_LIST_FAILED,
    payload,
});

export const hashtagDetailRequest = payload => ({
    type: HASHTAG_DETAIL_REQUEST,
    payload,
});

export const hashtagDetailSuccess = payload => ({
    type: HASHTAG_DETAIL_SUCCESS,
    payload,
});


export const hashtagDetailFailure = payload => ({
    type: HASHTAG_DETAIL_FAILED,
    payload,
});

export const hashtagInfoRequest = payload => ({
    type: HASHTAG_INFO_REQUEST,
    payload,
});

export const hashtagInfoSuccess = payload => ({
    type: HASHTAG_INFO_SUCCESS,
    payload,
});

export const hashtagInfoFailure = payload => ({
    type: HASHTAG_INFO_FAILED,
    payload,
});

export const favoriteHashtagRequest = payload => ({
    type: FAVORITE_HASHTAG_REQUEST,
    payload,
});

export const favoriteHashtagSuccess = payload => ({
    type: FAVORITE_HASHTAG_SUCCESS,
    payload,
});

export const favoriteHashtagFailure = payload => ({
    type: FAVORITE_HASHTAG_FAILED,
    payload,
});

export const disDavoriteHashtagRequest = payload => ({
    type: DIS_FAVORITE_HASHTAG_REQUEST,
    payload,
});

export const disFavoriteHashtagSuccess = payload => ({
    type: DIS_FAVORITE_HASHTAG_SUCCESS,
    payload,
});

export const disFavoriteHashtagFailure = payload => ({
    type: DIS_FAVORITE_HASHTAG_FAILED,
    payload,
});




