/* eslint-disable no-param-reassign */
import { addErrors, delErrors } from "utils/ErrorsGenerator";
import * as TYPE from "./constants";

const reducers = {
  [TYPE.CREATE_PAYMENT_TOPUP.request]: (draft) => {
    draft.createPayment = [];
    draft.isPayment = null;
  },

  [TYPE.CREATE_PAYMENT_TOPUP.success]: (draft, { payload }) => {
    draft.errors = delErrors(draft.errors, TYPE.CREATE_PAYMENT_TOPUP.failure);
    draft.payment = payload;
    draft.isPayment = true;
    localStorage.setItem("TopupPayment", JSON.stringify(payload));
  },

  [TYPE.CREATE_PAYMENT_TOPUP.failure]: (draft, { payload }) => {
    draft.errors = addErrors(draft.errors, {
      type: TYPE.CREATE_PAYMENT_TOPUP.failure,
      msg: payload,
    });
    draft.isPayment = false;
  },
};

export default reducers;
