import { call, put, takeLatest, fork, takeEvery } from "redux-saga/effects";
import actions from "./actions";
import * as TYPE from "./constants";
import api from "../api/api";

const sagas = {
  *[TYPE.VOUCHER_DETAIL.request](action) {
    const { productcode, status, transactioncode, expiredDay } = action.payload;

    try {
      const response = yield call(api[TYPE.VOUCHER_DETAIL.request], {
        productcode,
        status,
        transactioncode,
      });
      if (response.status === 200) {
        if (!Array.isArray(response.data)) return;
        // handle for case 1 voucher, can contain many sub-vouchers (e.g type CheckExist & qtyToGen > 1)
        // these sub-vouchers have different expiredDay
        // output: only  show sub-voucher(s) that have the same expiredDay with the clicked voucher record on /myvoucher
        // filter theo expiredDate

        const filteredData = response?.data.filter(
          (subVoucher) => subVoucher?.expiredDay === expiredDay
        );
        if (filteredData.length > 0) {
          yield put(actions.VOUCHER_DETAIL.success(filteredData));
        }
        // handle thêm case không có expiredDay (thẻ cào) (cách trước chưa work, note Refrector sau)
        // QuyenVTB
        yield put(actions.VOUCHER_DETAIL.success(response?.data));
      } else {
        yield put(actions.VOUCHER_DETAIL.failure(response?.data));
      }
    } catch (error) {
      yield put(actions.VOUCHER_DETAIL.failure(error?.data));
    }
  },

  *[TYPE.VOUCHER_INFOMATION.request](action) {
    try {
      const data = yield call(
        api[TYPE.VOUCHER_INFOMATION.request],
        action.payload
      );
      if (data.status === 200) {
        yield put(actions.VOUCHER_INFOMATION.success(data.data));
      } else {
        yield put(actions.VOUCHER_INFOMATION.failure(data?.data));
      }
    } catch (error) {
      yield put(actions.VOUCHER_INFOMATION.failure(error?.data));
    }
  },
  *[TYPE.VOUCHER_PRODUCT_INFORMATION.request](action) {
    try {
      const data = yield call(
        api[TYPE.VOUCHER_PRODUCT_INFORMATION.request],
        action.payload
      );
      if (data.status === 200) {
        yield put(actions.VOUCHER_PRODUCT_INFORMATION.success(data.data));
      } else {
        yield put(actions.VOUCHER_PRODUCT_INFORMATION.failure(data?.data));
      }
    } catch (error) {
      yield put(actions.VOUCHER_PRODUCT_INFORMATION.failure(error?.data));
    }
  },

  *[TYPE.CLICK_BY_MARK_USED.request](action) {
    try {
      const data = yield call(
        api[TYPE.CLICK_BY_MARK_USED.request],
        action.payload
      );
      if (data.status === 202) {
        yield put(actions.CLICK_BY_MARK_USED.success(action.payload));
      } else {
        yield put(actions.CLICK_BY_MARK_USED.failure(data?.data));
      }
    } catch (error) {
      yield put(actions.CLICK_BY_MARK_USED.failure(error?.data));
    }
  },
};

function* watchedSaga() {
  yield takeEvery(
    TYPE.VOUCHER_DETAIL.request,
    sagas[TYPE.VOUCHER_DETAIL.request]
  );
  yield takeEvery(
    TYPE.VOUCHER_INFOMATION.request,
    sagas[TYPE.VOUCHER_INFOMATION.request]
  );
  yield takeEvery(
    TYPE.VOUCHER_PRODUCT_INFORMATION.request,
    sagas[TYPE.VOUCHER_PRODUCT_INFORMATION.request]
  );

  yield takeLatest(
    TYPE.CLICK_BY_MARK_USED.request,
    sagas[TYPE.CLICK_BY_MARK_USED.request]
  );
}

export default function* rootChild() {
  yield fork(watchedSaga);
}
