/* eslint-disable no-param-reassign */
import { toast } from "react-toastify";
import { addErrors, delErrors } from "utils/ErrorsGenerator";
import { toastOptions } from "utils/toast";
import * as TYPE from "./constants";

const reducers = {
  // * ORDER_HISTORY_DETAIL
  [TYPE.ORDER_HISTORY_DETAIL.request]: (draft) => {
    draft.detail = null;
    draft.isLoading.ORDER_HISTORY_DETAIL = true;
  },
  [TYPE.ORDER_HISTORY_DETAIL.success]: (draft, { payload }) => {
    draft.errors = delErrors(draft.errors, TYPE.ORDER_HISTORY_DETAIL.failure);
    draft.detail = payload;
    draft.isLoading.ORDER_HISTORY_DETAIL = false;
  },
  [TYPE.ORDER_HISTORY_DETAIL.failure]: (draft, { payload }) => {
    draft.errors = addErrors(draft.errors, {
      type: TYPE.ORDER_HISTORY_DETAIL.failure,
      msg: payload,
    });
    draft.isLoading.ORDER_HISTORY_DETAIL = false;
  },

  // * CANCEL_VOUCHER
  [TYPE.CANCEL_VOUCHER.request]: (draft) => {
    draft.isLoading.CANCEL_VOUCHER = true;
  },
  [TYPE.CANCEL_VOUCHER.success]: (draft) => {
    toast.success("Hủy đơn hàng thành công!", toastOptions);
    draft.errors = delErrors(draft.errors, TYPE.CANCEL_VOUCHER.failure);
    draft.detail = {
      ...draft.detail,
      status: "Cancel",
    };
    draft.isLoading.CANCEL_VOUCHER = false;
  },
  [TYPE.CANCEL_VOUCHER.failure]: (draft, { payload }) => {
    toast.error("Hủy đơn hàng thất bại!", toastOptions);
    draft.errors = addErrors(draft.errors, {
      type: TYPE.CANCEL_VOUCHER.failure,
      msg: payload,
    });
    draft.isLoading.CANCEL_VOUCHER = false;
  },

  // * GET_PAYMENT_METHODS
  [TYPE.GET_PAYMENT_METHODS.request]: (draft) => {
    draft.isLoading.GET_PAYMENT_METHODS = true;
  },
  [TYPE.GET_PAYMENT_METHODS.success]: (draft, { payload }) => {
    draft.errors = delErrors(draft.errors, TYPE.GET_PAYMENT_METHODS.failure);
    draft.listTransactionMethods = payload; // ***
    draft.isLoading.GET_PAYMENT_METHODS = false;
  },
  [TYPE.GET_PAYMENT_METHODS.failure]: (draft, { payload }) => {
    draft.errors = addErrors(draft.errors, {
      type: TYPE.GET_PAYMENT_METHODS.failure,
      msg: payload,
    });
    draft.isLoading.GET_PAYMENT_METHODS = false;
  },
  [TYPE.GET_WHOLESALE_LIST.success]: (draft, { payload }) => {
    draft.errors = delErrors(draft.errors, TYPE.GET_WHOLESALE_LIST.failure);
    draft.listWholeSale = payload; // ***
    draft.isLoading.GET_WHOLESALE_LIST = false;
  },
};

export default reducers;
