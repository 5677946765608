import { addErrors, delErrors } from "utils/ErrorsGenerator";
import * as TYPE from "./constants";

const reducers = {
  [TYPE.GET_LIST_COUPON.request]: (draft) => {
    return {
      ...draft,
      errors: delErrors(draft.errors, TYPE.GET_LIST_COUPON.failure),
      isLoading: {
        ...draft.isLoading,
        GET_LIST_COUPON: true,
      },
    };
  },
  [TYPE.GET_LIST_COUPON.success]: (draft, { payload }) => {
    return {
      ...draft,
      errors: delErrors(draft.errors, TYPE.GET_LIST_COUPON.failure),
      coupons: payload,
      isLoading: {
        ...draft.isLoading,
        GET_LIST_COUPON: false,
      },
    };
  },
  [TYPE.GET_LIST_COUPON.failure]: (draft, { payload }) => {
    return {
      ...draft,
      errors: addErrors(draft.errors, {
        type: TYPE.GET_LIST_COUPON.failure,
        msg: payload,
      }),
      isLoading: {
        ...draft.isLoading,
        GET_LIST_COUPON: false,
      },
    };
  },

  [TYPE.SEARCH_COUPON_BY_COUPON_CODE.request]: (draft) => {
    return {
      ...draft,
      foundCoupon: {},
      errors: delErrors(
        draft.errors,
        TYPE.SEARCH_COUPON_BY_COUPON_CODE.failure
      ),
      isLoading: {
        ...draft.isLoading,
        SEARCH_COUPON_BY_COUPON_CODE: true,
      },
    };
  },
  [TYPE.SEARCH_COUPON_BY_COUPON_CODE.success]: (draft, { payload }) => {
    return {
      ...draft,
      foundCoupon: payload,
      errors: delErrors(
        draft.errors,
        TYPE.SEARCH_COUPON_BY_COUPON_CODE.failure
      ),
      isLoading: {
        ...draft.isLoading,
        SEARCH_COUPON_BY_COUPON_CODE: false,
      },
    };
  },
  [TYPE.SEARCH_COUPON_BY_COUPON_CODE.failure]: (draft, { payload }) => {
    return {
      ...draft,
      foundCoupon: {},
      errors: addErrors(draft.errors, {
        type: TYPE.SEARCH_COUPON_BY_COUPON_CODE.failure,
        msg: payload.message,
      }),
      isLoading: {
        ...draft.isLoading,
        SEARCH_COUPON_BY_COUPON_CODE: false,
      },
    };
  },
};

export default reducers;
