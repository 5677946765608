import { LOGOUT_REQUEST } from "@shared/redux/account/constants";
import { LIST_SEARCH_HISTORY } from "./constants";

const initialState = {
  listSearchHistory: [],
};

const listSearchHistoryReducer = (state = initialState, action) => {
  const { payload } = action;

  switch (action.type) {
    case LIST_SEARCH_HISTORY:
      return {
        ...state,
        listSearchHistory: payload,
      };
    case LOGOUT_REQUEST: {
      return {
        ...initialState,
      };
    }
    default:
      return state;
  }
};

export default listSearchHistoryReducer;
