export const voucherParser = data => {
  if (data) {
    const newData = {
      voucherName: data.name && data.name.vi,
      voucherDescription:
        data.description && data.description.vi !== null
          ? data.description.vi
          : '',
      images: data.images && data.images.iv.length > 0 ? data.images.iv : [],
      brandName:
        data.brand &&
        data.brand.iv.length > 0 &&
        data.brand.iv[0].data.name.iv !== null
          ? data.brand.iv[0].data.name.iv
          : '',
      brandLogo:
        data.brand &&
        data.brand.iv.length > 0 &&
        data.brand.iv[0].data.logoUrl.iv !== null
          ? data.brand.iv[0].data.logoUrl.iv
          : '',
      brandDescription:
        data.brand &&
        data.brand.iv.length > 0 &&
        data.brand.iv[0].data.description &&
        data.brand.iv[0].data.description.vi !== null
          ? data.brand.iv[0].data.description.vi
          : '',
      termVoucher: data.term && data.term.vi !== null ? data.term.vi : '',
      dataPrice:
        data.prices && data.prices.iv.length > 0 && data.prices.iv !== null
          ? data.prices.iv
          : [],
      merchantId:
        data.merchant &&
        data.merchant.iv.length > 0 &&
        data.merchant.iv[0].id !== null
          ? data.merchant.iv[0].id
          : '',
      voucherType:
        data.type.iv && data.type.iv.length > 0 ? data.type.iv[0] : '',
      applyStore:
        data.locations && data.locations.iv !== null ? data.locations.iv : '',
      shortDescription:
        data.shortDescription && data.shortDescription.vi !== null
          ? data.shortDescription.vi
          : '',
      promotion:
        data.promotion && data.promotion.vi !== null ? data.promotion.vi : '',
      expiredDate:
        data.expiredDate && data.expiredDate.iv !== null
          ? data.expiredDate.iv
          : '',
      tags: data.tags && data.tags.iv !== null ? data.tags.iv : [],
    };
    return newData;
  }
  return [];
};
