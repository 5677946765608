/* eslint-disable eqeqeq */
import { GET_LIST_NEWS_BOUGHT } from "./constants";
import { LOGOUT_REQUEST } from "@shared/redux/account/constants";

const initialState = { lisBoughtNews: [] };
const listNewsBoughtReducer = (state = initialState, action) => {
  switch (action?.type) {
    case GET_LIST_NEWS_BOUGHT.ADD: {
      return { ...state, lisBoughtNews: action?.payload.value };
    }
    case LOGOUT_REQUEST: {
      return initialState;
    }
    default:
      return state;
  }
};

export default listNewsBoughtReducer;
