import {
  CREATE_TRANSACTION_INSURANCE,
  GET_REPAYMENT_TRANSACTION_LINK,
  GET_DETAIL_TRANSACTION_INFO_NEWS,
  GET_REPAYMENT_TRANSACTION_LINK_NEWS,
} from './constants';

export const createActionTransactionInsuranceHandle = (
  transactionInsuranceInfo,
  onSuccess,
  onFailed,
) => ({
  type: CREATE_TRANSACTION_INSURANCE.HANDLER,
  transactionInsuranceInfo,
  onSuccess,
  onFailed,
});

export const createActionTransactionInsuranceSuccess =
  transactionInsuranceInfo => ({
    type: CREATE_TRANSACTION_INSURANCE.SUCCESS,
    transactionInsuranceInfo,
  });

export const createActionTransactionInsuranceFailure = error => ({
  type: CREATE_TRANSACTION_INSURANCE.FAILURE,
  error,
});

export const getRepaymentTractionLinkHandle = (
  payload,
  onSuccess,
  onFailed,
) => ({
  type: GET_REPAYMENT_TRANSACTION_LINK.HANDLER,
  payload,
  onSuccess,
  onFailed,
});

export const getRepaymentTractionLinkSuccess = transactionInsuranceInfo => ({
  type: GET_REPAYMENT_TRANSACTION_LINK.SUCCESS,
  transactionInsuranceInfo,
});

export const getRepaymentTractionLinkFailure = error => ({
  type: GET_REPAYMENT_TRANSACTION_LINK.FAILURE,
  error,
});

export const getRepaymentTractionLinkNewsHandle = (
  orderId,
  onSuccess,
  onFailed,
) => ({
  type: GET_REPAYMENT_TRANSACTION_LINK_NEWS.HANDLER,
  orderId,
  onSuccess,
  onFailed,
});

export const getRepaymentTractionLinkNewsSuccess = transactionNewsInfo => ({
  type: GET_REPAYMENT_TRANSACTION_LINK_NEWS.SUCCESS,
  transactionNewsInfo,
});

export const getRepaymentTractionLinkNewsFailure = error => ({
  type: GET_REPAYMENT_TRANSACTION_LINK_NEWS.FAILURE,
  error,
});

export const getDetailTransactionInfoNewsHandle = (
  orderId,
  onSuccess,
  onFailed,
) => ({
  type: GET_DETAIL_TRANSACTION_INFO_NEWS.HANDLER,
  orderId,
  onSuccess,
  onFailed,
});

export const getDetailTransactionInfoNewsSuccess = onSuccess => ({
  type: GET_DETAIL_TRANSACTION_INFO_NEWS.SUCCESS,
  onSuccess,
});

export const getDetailTransactionInfoNewsFailure = onFailed => ({
  type: GET_DETAIL_TRANSACTION_INFO_NEWS.FAILURE,
  onFailed,
});
