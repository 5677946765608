import APIUtils from 'utils/apiUtils';

export function getListOrderApi(payload) {
  var requestData =
    '?Type=OrderFNB&Status=Wait&Status=Available&Maxresult=10000';
  if (typeof payload !== 'undefined') {
    var requestData =
      '?Type=OrderFNB&Status=Payment&Status=Confirmed&Status=Wait&Status=Available&Maxresult=10000&StoreId=' +
      payload;
  }
  console.log('requestData pending order: ', requestData);

  return APIUtils.get(global.apiLinks.GetListHistoryOrder + requestData);
}
