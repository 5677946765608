import {createSelector} from 'reselect';

export const select = state => state.cart;
export const getListSuggestCoupon = createSelector(
  select,
  state => state.listSuggestCoupon,
);
export const getListProduct = createSelector(
  select,
  state => state.listProduct,
);
export const getAllowcationCoupon = createSelector(
  select,
  state => state.allowcationCoupon,
);
export const getMessageError = createSelector(
  select,
  state => state.messageError,
);
export const getDescription = createSelector(
  select,
  state => state.description,
);
export const getDeliveryAddress = createSelector(
  select,
  state => state.deliveryAddress,
);
export const getVoucher = createSelector(select, state => state.voucher);

export const getDiscount = createSelector(select, state => state.discount);

export const getNumberTable = createSelector(
  select,
  state => state.valueNumberTable,
);
export const getTakeAway = createSelector(select, state => state.takeAway);
export const getDeliveryAddressSelected = createSelector(
  select,
  state => state.deliveryAddressSelected,
);
