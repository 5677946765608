import { takeLatest, fork, put } from 'redux-saga/effects'
import {
  GET_MARKET_INFO,
  GET_LIST_FNB_TYPE,
  GET_LIST_MEDIA_HOME,
  GET_LIST_MEDIA_SUMMARY,
  GET_ALL_CATEGORY,
  GET_LIST_MEDIA_CATEGORY,
  GET_LIST_STORY,
  GET_LIST_FNB_BESTSELLER,
  GET_LIST_SUGGEST_PRODUCT,
  GET_LIST_STORE_MERCHANT,
  GET_STORE_DETAIL_MERCHANT,
  GET_LIST_CATEGORY_FNB_TYPE,
  GET_SHORT_LINK_TO_SHARE,
} from './constants'
import {
  getMarketInfoApi,
  getListFnbTypeApi,
  getListMediaHomeApi,
  getListMediaSummaryApi,
  getAllCategoryApi,
  getListMediaCategoryApi,
  getListFnbBestSellerApi,
  getListSuggestProductApi,
  getListStoreMerchantApi,
  getStoreDetailMechantApi,
  getListCategoryFnbTypeApi,
  getShortLinkToShareApi,
} from '@shared/services/api/market'
import { callApi } from 'helpers/callApi'
import { storeInfoParser } from './parser'
import {
  getListMediaSummarySuccess,
  getListMediaCategorySuccess,
  getListStorySuccess,
} from './actions.js'
export function* getStoreInfoSaga(obj) {
  const { onSuccess, storeId, onFailed } = obj
  try {
    const res = yield callApi(getMarketInfoApi, { storeId })
    const result = storeInfoParser(res.data)
    onSuccess?.(result)
  } catch (err) {
    onFailed?.(err)
  }
}
export function* getListFnbTypeSaga(obj) {
  const { onSuccess, merchantId, onFailed } = obj
  try {
    const res = yield callApi(getListFnbTypeApi, { merchantId })
    onSuccess?.(res)
  } catch (err) {
    onFailed?.(err)
  }
}
export function* getListCategoryFnbTypeSaga(obj) {
  const { onSuccess, payload, onFailed } = obj
  try {
    const res = yield callApi(getListCategoryFnbTypeApi, payload)
    onSuccess?.(res)
  } catch (err) {
    onFailed?.(err)
  }
}

export function* getListMediaHomeSaga(obj) {
  const { onSuccess, storeId, onFailed } = obj
  try {
    const res = yield callApi(getListMediaHomeApi, { storeId })
    onSuccess?.(res)
  } catch (err) {
    onFailed?.(err)
  }
}
export function* getListMediaSummarySaga(obj) {
  const { onSuccess, onFailed, payload } = obj
  try {
    const res = yield callApi(getListMediaSummaryApi, payload)
    yield put(
      getListMediaSummarySuccess({
        skip: payload.skipCount,
        data: res.data,
        take: payload.take,
      }),
    )
    onSuccess?.(res)
  } catch (err) {
    onFailed?.(err)
  }
}
export function* getAllCategorySaga(obj) {
  const { onSuccess, onFailed, payload } = obj
  try {
    const res = yield callApi(getAllCategoryApi, payload)
    onSuccess?.(res)
  } catch (err) {
    onFailed?.(err)
  }
}
export function* getListMediaCategorySaga(obj) {
  const { onSuccess, onFailed, payload } = obj
  try {
    const res = yield callApi(getListMediaCategoryApi, payload)
    yield put(
      getListMediaCategorySuccess({
        skip: payload.skipCount,
        data: res.data,
        take: payload.take,
      }),
    )
    onSuccess?.(res)
  } catch (err) {
    onFailed?.(err)
  }
}
export function* getListStorySaga(obj) {
  const { onSuccess, onFailed, payload } = obj
  try {
    const res = yield callApi(getListMediaCategoryApi, payload)
    yield put(
      getListStorySuccess({
        skip: payload.skipCount,
        data: res.data,
        take: payload.take,
      }),
    )
    onSuccess?.(res)
  } catch (err) {
    onFailed?.(err)
  }
}
export function* getListFnbBestSellerSaga(obj) {
  const { onSuccess, onFailed, payload } = obj
  try {
    const res = yield callApi(getListFnbBestSellerApi, payload)
    onSuccess?.(res)
  } catch (err) {
    onFailed?.(err)
  }
}
export function* getListSuggestProductSaga(obj) {
  const { onSuccess, onFailed, payload } = obj
  try {
    const res = yield callApi(getListSuggestProductApi, payload)
    onSuccess?.(res)
  } catch (err) {
    onFailed?.(err)
  }
}
export function* getListStoreMerchantSaga(obj) {
  const { onSuccess, onFailed, payload } = obj
  try {
    const res = yield callApi(getListStoreMerchantApi, payload)
    onSuccess?.(res)
  } catch (err) {
    onFailed?.(err)
  }
}
export function* getStoreDetailMerchantSaga(obj) {
  const { onSuccess, onFailed, payload } = obj
  try {
    const res = yield callApi(getStoreDetailMechantApi, payload)
    onSuccess?.(res)
  } catch (err) {
    onFailed?.(err)
  }
}

export function* getShortLinkToShareSaga(obj) {
  const { onSuccess, onFailed, payload } = obj
  try {
    const res = yield callApi(getShortLinkToShareApi, payload)
    onSuccess?.(res)
  } catch (err) {
    onFailed?.(err)
  }
}

function* watchStore() {
  yield takeLatest(GET_MARKET_INFO, getStoreInfoSaga)
  yield takeLatest(GET_LIST_FNB_TYPE, getListFnbTypeSaga)
  yield takeLatest(GET_LIST_CATEGORY_FNB_TYPE, getListCategoryFnbTypeSaga)
  yield takeLatest(GET_LIST_MEDIA_HOME, getListMediaHomeSaga)
  yield takeLatest(GET_LIST_MEDIA_SUMMARY, getListMediaSummarySaga)
  yield takeLatest(GET_ALL_CATEGORY, getAllCategorySaga)
  yield takeLatest(GET_LIST_MEDIA_CATEGORY, getListMediaCategorySaga)
  yield takeLatest(GET_LIST_STORY, getListStorySaga)
  yield takeLatest(GET_LIST_FNB_BESTSELLER, getListFnbBestSellerSaga)
  yield takeLatest(GET_LIST_SUGGEST_PRODUCT, getListSuggestProductSaga)
  yield takeLatest(GET_LIST_STORE_MERCHANT, getListStoreMerchantSaga)
  yield takeLatest(GET_STORE_DETAIL_MERCHANT, getStoreDetailMerchantSaga)
  yield takeLatest(GET_SHORT_LINK_TO_SHARE, getShortLinkToShareSaga)
}

export default function* rootChild() {
  yield fork(watchStore)
}
