import {GET_LIST_NUMBER_NEWSPAPER} from './constants';

export const getListNumberNewspaperTypeHandle = (
  newsPagesId,
  skip,
  take,
  onSuccess,
  onFailed,
) => ({
  type: GET_LIST_NUMBER_NEWSPAPER.HANDLER,
  newsPagesId,
  skip,
  take,
  onSuccess,
  onFailed,
});

export const getListNumberNewspaperTypeSuccess = payload => ({
  type: GET_LIST_NUMBER_NEWSPAPER.SUCCESS,
  payload,
});

export const getListNumberNewspaperTypeFailed = error => ({
  type: GET_LIST_NUMBER_NEWSPAPER.FAILURE,
  error,
});
