const prefix = 'ORDER_COMMENT';

const types = {
  ORDER_GET_COMMENT: prefix + '/GET_COMMENT',
  ORDER_POST_COMMENT: prefix + '/POST_COMMENT',
  ORDER_UPDATE_COMMENT: prefix + '/PUT_COMMENT',
  ORDER_DELETE_COMMENT: prefix + '/DELETE_COMMENT',
  LIKE_STORE_RATING: prefix + '/LIKE_STORE_RATING',
  LIKE_STORE_REPLY: prefix + '/LIKE_STORE_REPLY',
  GET_RATING_SUM: prefix + '/GET_RATING_SUM',
  GET_LIST_USER_LIKE: prefix + '/GET_LIST_USER_LIKE',
  GET_LIST_REASON_REPORT: prefix + '/GET_LIST_REASON_REPORT',
  SEND_REPORT_RATING: prefix + '/SEND_REPORT_RATING',
  GET_ORDER_NOT_RATING: prefix + '/GET_ORDER_NOT_RATING',
};

export default types;
