export const listCategoryParser = (data, id) => {
  if (!data || data?.length === 0) {
    return [];
  }

  return data.map(elm => {
    return {
      categoryName: elm.name,
      categoryId: elm.id,
    };
  });
};

export const listProductParser = data => {
  const masterData = {
    data: [],
  };
  if (!data || data?.length === 0) {
    return masterData;
  }

  masterData.data = data.map(elm => {
    return {...elm, isOutOfStock: true};
  });
  return masterData;
};
