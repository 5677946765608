export const GET_LIST_PRODUCT_BY_LOCATION_REQUEST =
  'product/GET_LIST_PRODUCT_BY_LOCATION_REQUEST';

export const GET_LIST_PRODUCT_BY_LOCATION_SUCCESS =
  'product/GET_LIST_PRODUCT_BY_LOCATION_SUCCESS';

export const GET_LIST_PRODUCT_BY_LOCATION_FAILED =
  'product/GET_LIST_PRODUCT_BY_LOCATION_FAILED';

export const GET_LIST_NEWSFEED_DYNAMIC_REQUEST =
  'product/GET_LIST_NEWSFEED_DYNAMIC_REQUEST';

export const GET_LIST_NEWSFEED_DYNAMIC_SUCCESS =
  'product/GET_LIST_NEWSFEED_DYNAMIC_SUCCESS';

export const GET_LIST_NEWSFEED_DYNAMIC_FAILED =
  'product/GET_LIST_NEWSFEED_DYNAMIC_FAILED';

export const GET_LIST_NEWSFEED_STORE_REQUEST =
  'product/GET_LIST_NEWSFEED_STORE_REQUEST';

export const GET_LIST_NEWSFEED_STORE_SUCCESS =
  'product/GET_LIST_NEWSFEED_STORE_SUCCESS';

export const GET_LIST_NEWSFEED_STORE_FAILED =
  'product/GET_LIST_NEWSFEED_STORE_FAILED';
