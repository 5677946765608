import {
  INPUT_FORM_GET_CUSTOMER_INFO,
  REMOVE_CUSTOMER_INFO,
} from "./constants";
// TODO: Lưu ý
const initialState = {
  data: [],
};

const inputFormGetCustomerInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case INPUT_FORM_GET_CUSTOMER_INFO.HANDLER:
      const stateProcessed = { ...state };
      stateProcessed.data = [];
      stateProcessed.data.push(action.payload);

      for (const idInputField in stateProcessed) {
        stateProcessed[idInputField] = [
          ...new Set(stateProcessed[idInputField]),
        ];

        if (
          !!action.payload[idInputField] &&
          stateProcessed[idInputField]?.every(
            (v) => v !== action.payload[idInputField]
          )
        ) {
          if (stateProcessed[idInputField]?.length >= 20) {
            stateProcessed[idInputField].shift();
          }
          stateProcessed[idInputField]?.push(action.payload[idInputField]);
        }
      }
      return stateProcessed;
    case REMOVE_CUSTOMER_INFO.HANDLER:
      return { ...state, data: [] };
    default:
      return state;
  }
};

export default inputFormGetCustomerInfoReducer;
