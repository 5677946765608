/* eslint-disable eqeqeq */
import {POPUP_EXPIRED} from './constants';

const initialState = {
  statusNews: [],
};

const popupExpiredReducer = (state = initialState, action) => {
  switch (action?.type) {
    case POPUP_EXPIRED.GET: {
      return state;
    }
    case POPUP_EXPIRED.ADD: {
      return {
        ...state,
        statusNews: [...state?.statusNews, action?.payload],
      };
    }
    case POPUP_EXPIRED.EDIT: {
      return {
        statusNews: state?.statusNews.map(status =>
          status?.id == action?.payload?.id
            ? {
                ...status,
                status: action?.payload?.status,
                time: action?.payload?.time,
                isOpen: action?.payload?.isOpen,
              }
            : status,
        ),
      };
    }
    case POPUP_EXPIRED.REMOVE: {
      return {
        ...state,
        statusNews: state?.statusNews?.filter(
          status => status?.id != action?.payload?.id,
        ),
      };
    }
    default:
      return state;
  }
};

export default popupExpiredReducer;
