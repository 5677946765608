import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
} from "@coreui/react";
import { icons } from "assets/icons/index";
import IMAGES from "assets/img";
import { strings } from "@i18n";
import { useFreezeScroll } from "hooks";
import { clearMarkPosition } from "@shared/redux/gps/actions";

import Popup from "views/common/component/Popup";

import PageHeader from "views/common/component/PageHeader";
import ShippingInfo from "../Market/CartMarket/components/ShippingInfo";
import DeliveryInfo from "./components/DeliveryInfo";
import ReceiverInfo from "./components/ReeciverInfo";

import {
  formatPhone,
  formatNumber,
  formatDistance,
  convertDistance,
  convertEstimateDeliveryTime,
} from "../Market/CartMarket/utils";

import "./OrderAddressFood.scss";

const SetDeliveryInfo = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  // const pickedAddressInfo = location?.state?.pickedAddressInfo;

  const { userInfo } = useSelector((state) => state.root);

  const [releaseBodyScroll] = useFreezeScroll();

  const [
    showIgnoreEnteredDataConfirmationPopup,
    setShowIgnoreEnteredDataConfirmationPopup,
  ] = useState(false);

  const [hideShipping, setHideShipping] = useState(false);
  const [chosenAddressCode, setChosenAddressCode] = useState(null);

  useEffect(() => {
    if (!props?.valueUserName && !props?.valueNumberPhone) {
      props.setValueUserName(userInfo.displayName);
      props.setValueNumberPhone(formatPhone(userInfo.phoneNumber));
    }
  }, [userInfo]);

  const formatAmount = (amount) => {
    return `${amount?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}đ`;
  };

  const handleClickBtnBack = () => {
    setShowIgnoreEnteredDataConfirmationPopup(true);
  };

  const handleNavigateToSearchAddressPage = () => {
    history.push("/deliveryaddress");
  };

  const handleConfirmToIgnoreEnteredData = () => {
    setShowIgnoreEnteredDataConfirmationPopup(false);
    dispatch(clearMarkPosition());
    history.goBack();
  };

  const handleCancelIgnore = () => {
    releaseBodyScroll();
    setShowIgnoreEnteredDataConfirmationPopup(false);
  };

  const formatNumberD = (data) => {
    return `${formatNumber(data)}đ`;
  };

  const renderMethodDeliveryByProvider = () => {
    return (
      <div className="delivery-provider-wrapper">
        {props?.deliveryTime && (
          <div className="delivery-estimate-wrapper">
            <div className="delivery-estimate-content">
              <img
                className="icon-estimate"
                src={icons.iconScheduledDelivery}
              />
              <div className="text-estimate">{`${strings(
                "Cart.labelEstimateDelivery"
              )} ${convertEstimateDeliveryTime(props?.deliveryTime)}`}</div>
            </div>
          </div>
        )}
        <div className="shipping-content">
          <div />
          <div className="shipping-fee">
            <span className="shipping-fee_title">
              {props?.listShipping?.provider?.toLowerCase() === "ahamove"
                ? "Phí giao hàng dự kiến"
                : "Phí giao hàng"}
              {` (${formatDistance(
                convertDistance(props?.listShipping?.distance)
              )}km)`}
            </span>
            <span className="shipping-fee_title">
              {formatAmount(props?.listShipping?.originalFee)}
            </span>
          </div>
          {(props?.listShipping?.shippingDiscount > 0 ||
            props?.listShipping?.shippingDiscountRange !== null) && (
            <div className="shipping-fee-note">
              <div className="shipping-fee-note_title">
                <span className="shipping-fee-note_title-text">
                  Giảm phí giao hàng
                </span>
                {props?.listShipping?.listSuggestShippingDiscount?.length >
                  0 && (
                  <div
                    hitSlop={{ top: 20, bottom: 20, left: 20, right: 20 }}
                    onClick={() => props.setVisibleShippingDiscount(true)}
                  >
                    <img src={IMAGES.IconShippingInfo} />
                  </div>
                )}
              </div>
              <span className="shipping-fee-note_title-value-text">
                -
                {formatAmount(
                  props?.listShipping?.shippingDiscount >
                    props?.listShipping?.originalFee
                    ? props?.listShipping?.originalFee
                    : props?.listShipping?.shippingDiscount
                )}
              </span>
            </div>
          )}
          {props?.listShipping?.shippingDiscount <
            props?.listShipping?.originalFee &&
          props?.listShipping?.shippingDiscountRange?.extraPurchaseAmount ? (
            <div className="shipping-description">
              <span className="shipping-description-text">
                {`Mua thêm ${formatNumberD(
                  props?.listShipping?.shippingDiscountRange
                    ?.extraPurchaseAmount
                )}  để được giảm phí ${formatNumberD(
                  props?.listShipping?.shippingDiscountRange?.shippingDiscount
                )}`}
              </span>
            </div>
          ) : null}
          {(props?.listShipping?.shippingDiscount > 0 ||
            props?.listShipping?.shippingDiscountRange !== null) && (
            <div>
              <div className="shipping-dashed" />
              <div className="d-flex justify-content-between align-items-center mt-3 mb-1">
                <span className="_fw-600 _fs-14px color-black">
                  Tổng thanh toán
                </span>
                <span className="_fw-700 _fs-14px">
                  {formatAmount(
                    props?.listShipping?.originalFee -
                      props?.listShipping?.shippingDiscount
                  )}
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderListShipping = React.useMemo(() => {
    if (props?.listShipping === null || (hideShipping && chosenAddressCode)) {
      return;
    }
    const provider = props?.listShipping?.provider;

    return renderMethodDeliveryByProvider(provider);
  }, [props.listShipping, props?.deliveryTime, hideShipping]);

  return (
    <div className="set-delivery-info__wrapper">
      <PageHeader title="Địa chỉ nhận hàng" onNavigate={handleClickBtnBack} />

      {/* page content */}
      <div>
        <ReceiverInfo
          valueUserName={props?.valueUserName}
          valueNumberPhone={props?.valueNumberPhone}
          setValueUserName={props.setValueUserName}
          setValueNumberPhone={props.setValueNumberPhone}
          validPhoneNumber={props.validPhoneNumber}
        />

        <DeliveryInfo
          receiverAddress={props?.valueAddressUser}
          receiverNote={props?.valueAddressNote}
          handleCommitAddressNote={props.setValueAddressNote}
          handleNavigateToSearchAddressPage={handleNavigateToSearchAddressPage}
          deliveryAddressOptions={() => {}}
          pickupAddress={props?.listDeliveryAddress}
          messageError={props?.messageError}
          isFreeText={props?.isFreeText}
          gps={props.gps}
          GoogleApiKey={props.GoogleApiKey}
          pickupLocation={props.pickupLocation}
          isPickup={props.isPickup}
          hideShipping={setHideShipping}
          setChosenAddressCode={setChosenAddressCode}
          {...props}
        />
        {renderListShipping}
      </div>

      {/* bottom button */}
      <div className="button-bottom-screen">
        <button
          type="button"
          onClick={props.handleConfirm}
          className="button__effect"
          disabled={
            !props.isEnableButton ||
            !Number.isInteger(props?.valueNumberPhone * 1)
          }
        >
          Xác nhận
        </button>
      </div>

      {props?.listShipping?.listSuggestShippingDiscount && (
        <Popup
          isOpen={props.isVisibleShippingDiscount}
          handleClose={() => props.setVisibleShippingDiscount(false)}
          title={
            <div className="color-ucla_gold _fw-500 _fs-16px mb-2 text-center">
              Giảm phí giao hàng
            </div>
          }
        >
          <ShippingInfo
            shippingDiscountList={
              props?.listShipping?.listSuggestShippingDiscount
            }
            totalPrice={props?.listShipping?.originalFee}
          />
        </Popup>
      )}

      <CModal
        show={showIgnoreEnteredDataConfirmationPopup}
        onClose={handleCancelIgnore}
        centered

        // className="modal modal-dialog"
      >
        <CModalHeader closeButton className="border-0 _fw-700">
          Utop
        </CModalHeader>
        <CModalBody className="py-0">
          Thông tin bạn vừa nhập sẽ bị mất khi thoát khỏi màn hình này.
        </CModalBody>
        <CModalFooter className="border-0">
          <CButton variant="ghost" onClick={handleCancelIgnore}>
            Huỷ
          </CButton>
          <CButton variant="ghost" onClick={handleConfirmToIgnoreEnteredData}>
            Đồng ý
          </CButton>
        </CModalFooter>
      </CModal>
    </div>
  );
};

export default SetDeliveryInfo;
