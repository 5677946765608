const prefix = 'upload_video';

export const UPLOAD_VIDEO_REQUEST = prefix + '/UPLOAD_VIDEO_REQUEST';
export const UPLOAD_VIDEO_SUCCESS = prefix + '/UPLOAD_VIDEO_SUCCESS';
export const UPLOAD_VIDEO_FAILED = prefix + '/UPLOAD_VIDEO_FAILED';

export const GET_VIDEO_PROBLEM_REQUEST = prefix + '/GET_VIDEO_PROBLEM_REQUEST';
export const GET_VIDEO_PROBLEM_SUCCESS = prefix + '/GET_VIDEO_PROBLEM_SUCCESS';
export const GET_VIDEO_PROBLEM_FAILED = prefix + '/GET_VIDEO_PROBLEM_FAILED';

export const DELETE_VIDEO_REQUEST = prefix + '/DELETE_VIDEO_REQUEST';
export const DELETE_VIDEO_SUCCESS = prefix + '/DELETE_VIDEO_SUCCESS';
export const DELETE_VIDEO_FAILED = prefix + '/DELETE_VIDEO_FAILED';

export const SET_ISCOMMENTABLE_REQUEST = prefix + '/SET_ISCOMMENTABLE_REQUEST';
export const SET_ISCOMMENTABLE_SUCCESS = prefix + '/SET_ISCOMMENTABLE_SUCCESS';
export const SET_ISCOMMENTABLE_FAILED = prefix + '/SET_ISCOMMENTABLE_FAILED';

export const SET_PRIVACY_REQUEST = prefix + '/SET_PRIVACY_REQUEST';
export const SET_PRIVACY_SUCCESS = prefix + '/SET_PRIVACY_SUCCESS';
export const SET_PRIVACY_FAILED = prefix + '/SET_PRIVACY_FAILED';
