import { callApi } from "helpers/callApi";
import { fork, put, takeEvery, takeLatest } from "redux-saga/effects";
import {
  CHECK_ACCOUNT_BUSINESS_REQUEST,
  GET_USER_BUSINESS_REQUEST,
  VERIFY_LINK_REQUEST,
  GET_TRANSACTION_DETAIL_REQUEST,
  GET_ALL_TRANSACTION_HISTORY_UB_REQUEST,
  GET_LIST_STORE_UB_REQUEST,
  GET_DETAIL_VERIFY_REQUEST,
  GET_LIST_STORE_TYPE_REQUEST,
  GET_LIST_CITY_REQUEST,
  GET_LIST_DISTRICT_REQUEST,
  STORE_DETAIL_REQUEST,
  GET_LIST_SEARCH_STORE_UB_REQUEST,
  STORE_IMAGES_REQUEST,
  CREATE_RESERVATION_REQUEST,
} from "./constants";
import {
  verifyLinkBusinessSuccess,
  verifyLinkBusinessFailed,
  getUserBusinessSuccess,
  getUserBusinessFailed,
  checkAccountBusinessSuccess,
  checkAccountBusinessFailed,
  getTransactionDetailSuccess,
  getTransactionDetailFailed,
  getAllTransactionHistoryUBSuccess,
  getAllTransactionHistoryUBFailed,
  getListStoreUBSuccess,
  getListStoreUBFailed,
  getListStoreTypeSuccess,
  getListStoreTypeFailed,
  getListCitySuccess,
  getListCityFailed,
  getListDistrictSuccess,
  getListDistrictFailed,
  getListSearchStoreUBSuccess,
  getListSearchStoreUBFailed,
  createReservationSuccess,
  createReservationFailed,
} from "./actions";
import {
  verifyLinkBusinessApi,
  getUserBusinessApi,
  checkAccountBusinessApi,
  getTransactionDetailApi,
  getAllTransactionHistoryUBApi,
  getListStoreUBApi,
  getDetailVerifyApi,
  getListStoreTypeApi,
  getListCityApi,
  getListDistrictApi,
  storeDetailApi,
  storeImageslApi,
  createReservationApi,
} from "@shared/services/api/business";
import { listTransactionParser } from "./parser";

export function* verifyLinkBusinessSaga(obj) {
  const { payload = {}, onSuccess, onError } = obj;
  try {
    const res = yield callApi(verifyLinkBusinessApi, payload);
    yield put(verifyLinkBusinessSuccess(res));
    if (typeof onSuccess === "function") {
      onSuccess(res);
    }
  } catch (err) {
    const { status } = err || {};
    if (status >= 400) {
      onError(err);
    }
    yield put(verifyLinkBusinessFailed(err));
  }
}

export function* getUserBusinessSaga(obj) {
  const { payload = {}, onSuccess, onError } = obj;
  try {
    const res = yield callApi(getUserBusinessApi);
    yield put(getUserBusinessSuccess(res.data));
    if (typeof onSuccess === "function") {
      onSuccess(res);
    }
  } catch (err) {
    const { status } = err || {};
    if (status >= 400) {
      Alert.alert("Utop", err?.data?.message);
    }
    yield put(getUserBusinessFailed(err));
  }
}

export function* checkAccountBusinessSaga(obj) {
  const { payload = {}, onSuccess, onError } = obj;
  try {
    const res = yield callApi(checkAccountBusinessApi, payload);
    yield put(checkAccountBusinessSuccess(res));
    if (typeof onSuccess === "function") {
      onSuccess(res);
    }
  } catch (err) {
    const { status } = err || {};
    if (typeof onError === "function") {
      onError(err);
    }
    yield put(checkAccountBusinessFailed(err));
  }
}

export function* getTransactionDetailSaga(obj) {
  const { payload, onSuccess, onFailed } = obj;
  try {
    const res = yield callApi(getTransactionDetailApi, payload);
    yield put(getTransactionDetailSuccess(res));
    if (typeof onSuccess === "function") {
      onSuccess(res);
    }
  } catch (err) {
    if (typeof onFailed === "function") {
      onFailed(err);
    }
    yield put(getTransactionDetailFailed(err));
  }
}

export function* getAllTransactionHistoryUBSaga(obj) {
  const { payload, onSuccess, onFailed } = obj;
  try {
    const res = yield callApi(getAllTransactionHistoryUBApi, payload);
    yield put(
      getAllTransactionHistoryUBSuccess({
        skip: payload.skipCount + 10 || 0,
        data: res.data,
        isLoadNew: payload.isLoadNew,
      })
    );

    if (typeof onSuccess === "function") {
      onSuccess(res);
    }
  } catch (err) {
    if (typeof onFailed === "function") {
      onFailed(err);
    }
    yield put(getAllTransactionHistoryUBFailed(err));
  }
}

export function* getListStoreUBSaga(obj) {
  const { payload, onSuccess, onFailed } = obj;
  try {
    const res = yield callApi(getListStoreUBApi, payload);
    // yield put(getListStoreUBSuccess(res));
    let result = listTransactionParser(res.data);
    yield put(
      getListStoreUBSuccess({
        skip: payload.skipCount,
        data: result,
      })
    );

    if (typeof onSuccess === "function") {
      onSuccess(result);
    }
  } catch (err) {
    if (typeof onFailed === "function") {
      onFailed(err);
    }
    yield put(getListStoreUBFailed(err));
  }
}

export function* getListSearchStoreUBSaga(obj) {
  const { payload, onSuccess, onFailed } = obj;
  try {
    const res = yield callApi(getListStoreUBApi, payload);
    let result = listTransactionParser(res.data);
    yield put(
      getListSearchStoreUBSuccess({
        skip: payload.skipCount,
        data: result,
      })
    );

    if (typeof onSuccess === "function") {
      onSuccess(result);
    }
  } catch (err) {
    if (typeof onFailed === "function") {
      onFailed(err);
    }
    yield put(getListSearchStoreUBFailed(err));
  }
}

export function* getDetailVerifySaga(obj) {
  const { payload, onSuccess, onError } = obj;
  try {
    const res = yield callApi(getDetailVerifyApi, payload);
    onSuccess?.(res);
  } catch (err) {
    onError?.(err);
  }
}

export function* getListStoreTypeSaga(obj) {
  const { payload, onSuccess, onFailed } = obj;
  try {
    const res = yield callApi(getListStoreTypeApi, payload);
    if (res?.data.length > 0) {
      res.data.forEach((element) => {
        element.isSelected = false;
      });
    }

    yield put(getListStoreTypeSuccess(res));
    if (typeof onSuccess === "function") {
      onSuccess(res.data);
    }
  } catch (err) {
    if (typeof onFailed === "function") {
      onFailed(err);
    }
    yield put(getListStoreTypeFailed(err));
  }
}
export function* storeDetailSaga(obj) {
  const { payload, onSuccess, onError } = obj;
  try {
    const res = yield callApi(storeDetailApi, payload);
    onSuccess?.(res);
  } catch (err) {
    onError?.(err);
  }
}

export function* getListCitySaga(obj) {
  const { payload, onSuccess, onFailed } = obj;
  try {
    const res = yield callApi(getListCityApi, payload);
    yield put(getListCitySuccess(res));
    if (typeof onSuccess === "function") {
      onSuccess(res.data);
    }
  } catch (err) {
    if (typeof onFailed === "function") {
      onFailed(err);
    }
    yield put(getListCityFailed(err));
  }
}

export function* getListDistrictSaga(obj) {
  const { payload, onSuccess, onFailed } = obj;
  try {
    const res = yield callApi(getListDistrictApi, payload);
    yield put(getListDistrictSuccess(res));
    if (typeof onSuccess === "function") {
      onSuccess(res.data);
    }
  } catch (err) {
    if (typeof onFailed === "function") {
      onFailed(err);
    }
    yield put(getListDistrictFailed(err));
  }
}

export function* storeImagesSaga(obj) {
  const { payload, onSuccess, onError } = obj;
  try {
    const res = yield callApi(storeImageslApi, payload);
    onSuccess?.(res);
  } catch (err) {
    onError?.(err);

    yield put(getListStoreTypeFailed(err));
  }
}

export function* createReservationSaga(obj) {
  const { payload, onSuccess, onError } = obj;
  try {
    const res = yield callApi(createReservationApi, payload);
    yield put(createReservationSuccess(res));
    if (typeof onSuccess === "function") {
      onSuccess(res.data);
    }
  } catch (err) {
    if (typeof onError === "function") {
      onError(err.data);
    }
    yield put(createReservationFailed(err));
  }
}

function* watchCommon() {
  yield takeLatest(VERIFY_LINK_REQUEST, verifyLinkBusinessSaga);
  yield takeLatest(GET_USER_BUSINESS_REQUEST, getUserBusinessSaga);
  yield takeLatest(CHECK_ACCOUNT_BUSINESS_REQUEST, checkAccountBusinessSaga);
  yield takeLatest(GET_TRANSACTION_DETAIL_REQUEST, getTransactionDetailSaga);
  yield takeLatest(
    GET_ALL_TRANSACTION_HISTORY_UB_REQUEST,
    getAllTransactionHistoryUBSaga
  );
  yield takeLatest(GET_LIST_STORE_UB_REQUEST, getListStoreUBSaga);
  yield takeLatest(GET_LIST_SEARCH_STORE_UB_REQUEST, getListSearchStoreUBSaga);
  yield takeLatest(GET_DETAIL_VERIFY_REQUEST, getDetailVerifySaga);
  yield takeLatest(GET_LIST_STORE_TYPE_REQUEST, getListStoreTypeSaga);
  yield takeLatest(GET_LIST_CITY_REQUEST, getListCitySaga);
  yield takeLatest(GET_LIST_DISTRICT_REQUEST, getListDistrictSaga);
  yield takeLatest(STORE_DETAIL_REQUEST, storeDetailSaga);
  yield takeLatest(STORE_IMAGES_REQUEST, storeImagesSaga);
  yield takeLatest(CREATE_RESERVATION_REQUEST, createReservationSaga);
}

export default function* rootChild() {
  yield fork(watchCommon);
}
