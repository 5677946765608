import { LOGOUT_REQUEST } from "@shared/redux/account/constants";
import {
  GET_UTOP_POINT_TOTAL_SUCCESS,
  GET_LIST_CAMPAIGN_SUCCESS,
  GET_AFF_DETAIL_SUCCESS,
  GET_LIST_AFF_HISTORY_SUCCESS,
} from "./constants";

const initialState = {
  utopPointTotal: 0,
  listCampaign: [],
  listAffHistory: {
    listApproved: [],
    listReceived: [],
    listCancel: [],
    listPending: [],
    totalUtopPending: 0,
    skipCountApproved: 10,
    skipCountCancel: 10,
    skipCountReceived: 10,
  },
  affDetail: {},
  total: 0,
};

const utopbackReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_UTOP_POINT_TOTAL_SUCCESS: {
      return {
        ...state,
        utopPointTotal: action.balance,
      };
    }
    case GET_LIST_CAMPAIGN_SUCCESS: {
      return {
        ...state,
        listCampaign: action.listcampaign,
        total: action.total,
      };
    }
    case GET_AFF_DETAIL_SUCCESS: {
      return {
        ...state,
        affDetail: action.affdetail,
      };
    }
    case GET_LIST_AFF_HISTORY_SUCCESS: {
      if (action.payload.type === "pending") {
        return {
          ...state,
          listAffHistory: {
            ...state.listAffHistory,
            listPending: action.payload.data,
            totalUtopPending: action.payload.totalUtopPending,
          },
        };
      } else if (action.payload.type === "approved") {
        let newListApproved = [];
        if (action.payload.skip > 0) {
          newListApproved = [
            ...state.listAffHistory.listApproved,
            ...action.payload.data,
          ];
        } else {
          newListApproved = action.payload.data;
        }
        return {
          ...state,
          listAffHistory: {
            ...state.listAffHistory,
            listApproved: newListApproved,
            skipCountApproved: action.payload.skip,
          },
        };
      } else if (action.payload.type === "received") {
        let newListReceived = [];
        if (action.payload.skip > 0) {
          newListReceived = [
            ...state.listAffHistory.listReceived,
            ...action.payload.data,
          ];
        } else {
          newListReceived = action.payload.data;
        }
        return {
          ...state,
          listAffHistory: {
            ...state.listAffHistory,
            listReceived: newListReceived,
            skipCountReceived: action.payload.skip,
          },
        };
      } else {
        let newListCancel = [];
        if (action.payload.skip > 0) {
          newListCancel = [
            ...state.listAffHistory.listCancel,
            ...action.payload.data,
          ];
        } else {
          newListCancel = action.payload.data;
        }
        return {
          ...state,
          listAffHistory: {
            ...state.listAffHistory,
            listCancel: newListCancel,
            skipCountCancel: action.payload.skip,
          },
        };
      }
    }
    case LOGOUT_REQUEST: {
      return {
        ...state,
        listAffHistory: {
          listApproved: [],
          listReceived: [],
          listCancel: [],
          listPending: [],
          totalUtopPending: 0,
          skipCountApproved: 10,
          skipCountCancel: 10,
          skipCountReceived: 10,
        },
      };
    }
    default:
      return state;
  }
};

export default utopbackReducer;
