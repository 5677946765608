import {
  MY_VOUCHER_DETAIL_REQUEST,
  MY_VOUCHER_DETAIL_SUCCESS,
  MY_VOUCHER_DETAIL_FAILED,
  MY_COUPON_DETAIL_REQUEST,
  MY_COUPON_DETAIL_SUCCESS,
  MY_COUPON_DETAIL_FAILED,
  MY_VOUCHER_DETAIL_BY_USER_REQUEST,
  MY_VOUCHER_DETAIL_BY_USER_SUCCESS,
  MY_VOUCHER_DETAIL_BY_USER_FAILED,
} from './constants';

export const myVoucherDetailRequest = payload => ({
  type: MY_VOUCHER_DETAIL_REQUEST,
  payload,
});

export const myVoucherDetailSuccess = payload => ({
  type: MY_VOUCHER_DETAIL_SUCCESS,
  payload,
});

export const myVoucherDetailFailed = payload => ({
  type: MY_VOUCHER_DETAIL_FAILED,
  payload,
});

export const myCouponDetailRequest = payload => ({
  type: MY_COUPON_DETAIL_REQUEST,
  payload,
});

export const myCouponDetailSuccess = payload => ({
  type: MY_COUPON_DETAIL_SUCCESS,
  payload,
});

export const myCouponDetailFailed = payload => ({
  type: MY_COUPON_DETAIL_FAILED,
  payload,
});

export const getVoucherDetailByUserRequest = (
  payload = {},
  onSuccess,
  onError,
) => {
  return {
    type: MY_VOUCHER_DETAIL_BY_USER_REQUEST,
    payload,
    onSuccess,
    onError,
  };
};

export const getVoucherDetailByUserSuccess = payload => ({
  type: MY_VOUCHER_DETAIL_BY_USER_SUCCESS,
  payload,
});

export const getVoucherDetailByUserFailed = payload => ({
  type: MY_VOUCHER_DETAIL_BY_USER_FAILED,
  payload,
});
