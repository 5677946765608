import React from "react";
import moment from "moment";

const CouponItem = (props) => {
  const {
    // id,
    transactionCode,
    name,
    code,
    couponCode,
    expiryDate,
    // isApplied,
    handleToggleApplyPromo,
    // isAllocated,
    handleOnClick,
    couponData,
    ...rest
  } = props;

  return (
    // promotion item wrapper
    <div {...rest}>
      <div
        className="promotion-item d-flex"
        // isApplied && "promotion-item-applied"
      >
        <div
          className="pr-2"
          style={{
            minWidth: 0,
            flexGrow: 1,
            borderRight: "1px dashed #dedede",
            cursor: "pointer",
          }}
          onClick={handleOnClick}
        >
          <div className="_fw-500 _fs-14px color-black text-truncate">
            {name}
          </div>
          {/* !! utop demand */}
          {/* <div className="_fw-700 _fs-12px color-ucla_gold">{couponCode}</div> */}

          <div
            className="_fw-500 _fs-10px color-granite_gray"
            style={{ color: !expiryDate && "transparent" }}
          >
            {`Hết hạn: ${moment.utc(expiryDate).local().format("DD/MM/YYYY")}`}
          </div>
        </div>

        <div className="d-flex">
          <button
            onClick={() => handleToggleApplyPromo(couponData)}
            className="button-apply _outline-none"
            type="button"
          >
            <span className="color-ucla_gold _fw-600 _fs-12px">Áp dụng</span>
          </button>
        </div>

        {/* serration ticket border */}
        <div className="dotted-right-border" />
        <div className="dotted-right-border dotted-right-border-cover" />
        <div className="redundant-dotted-cover" />
      </div>
    </div>
  );
};

export default CouponItem;
