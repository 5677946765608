import APIUtils from 'utils/apiUtils'
import { END_POINTS } from '@shared/constants/apiUrl'

export function getVoucherDetailApi({ voucherId }) {
  const params = {
    id: voucherId,
  }
  return APIUtils.get(END_POINTS.CMS_GET_VOUCHER_DETAIL_URL, {
    method: 'GET',
    params,
  })
}

export function checkStockApi(productCode) {
  const params = {
    ProductCode: productCode,
  }
  return APIUtils.get(END_POINTS.CHECK_STOCK_URL, {
    method: 'GET',
    params,
  })
}

export function checkDiscountCouponForVoucherApi(payload) {
  const body = payload
  return APIUtils.post(END_POINTS.CHECK_COUPON_DISCOUNT_URL, {
    method: 'POST',
    body,
  })
}

export function createOrderHubApi(payload) {
  const body = payload
  return APIUtils.post(END_POINTS.CREATE_ORDER_HUB_URL, {
    method: 'POST',
    body,
  })
}

export function refundVoucherApi(payload) {
  const body = payload
  return APIUtils.post(END_POINTS.REFUND_VOUCHER_BY_TRANSACTION_CODE_URL, {
    method: 'POST',
    body,
  })
}
