import { LOGOUT_REQUEST } from "@shared/redux/account/constants";
import {
  CLEAR_PARTNER_INFO,
  MAPPING_MEMBER_WITH_PARTNER_SUCCESS,
  REMOVE_MAPPING_MEMBER_WITH_PARTNER_SUCCESS,
} from "./constants";

const initialState = {
  mappingPartnerInfo: {},
};

const point = (state = initialState, action) => {
  switch (action.type) {
    case MAPPING_MEMBER_WITH_PARTNER_SUCCESS: {
      return { ...state, mappingPartnerInfo: action.payload };
    }
    case REMOVE_MAPPING_MEMBER_WITH_PARTNER_SUCCESS: {
      return { ...state, mappingPartnerInfo: {} };
    }
    case CLEAR_PARTNER_INFO:
    case LOGOUT_REQUEST: {
      return {
        ...initialState,
      };
    }
    default:
      return state;
  }
};

export default point;
