import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import "./styles.scss";
import { CloseWhiteYellowIcon } from "assets/svg";
import arrowBack from "assets/icons/arrow-back.svg";

function PageHeader({
  title,
  href,
  onNavigate,
  hideBtnLeft,
  onClickBtnRight,
  hideBtnRight,
  isBtnRightRounded,
}) {
  const history = useHistory();

  const handleNavigate = () => {
    if (!onNavigate) {
      history.push(href);
      return;
    }
    onNavigate();
  };

  return (
    <div id="common-page-header" className="page-header-container">
      <div className="page-header">
        <div className="page-header__main">
          {!hideBtnLeft && (
            <div
              onClick={handleNavigate}
              className="page-header__main__btn-left"
            >
              <img src={arrowBack} alt="arrow-back" />
            </div>
          )}
          <h2>{title}</h2>
          {!hideBtnRight && onClickBtnRight && (
            <>
              {isBtnRightRounded ? (
                <div
                  className="page-header__main__btn-right"
                  onClick={onClickBtnRight}
                >
                  <i>
                    <CloseWhiteYellowIcon />
                  </i>
                </div>
              ) : (
                <div
                  onClick={onClickBtnRight}
                  className="page-header__main__btn-right"
                >
                  <span>Hủy</span>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

PageHeader.defaultProps = {
  title: "",
  href: "/",
  onNavigate: undefined,
  hideBtnLeft: false,
  hideBtnRight: true,
  isBtnRightRounded: false,
};

PageHeader.propTypes = {
  title: PropTypes.string,
  href: PropTypes.string,
  onNavigate: PropTypes.func,
  hideBtnLeft: PropTypes.bool,
  hideBtnRight: PropTypes.bool,
  isBtnRightRounded: PropTypes.bool,
};

export default PageHeader;
