import { call, put, fork, takeEvery } from "redux-saga/effects";
import actions from "./actions";
import * as TYPE from "./constants";
import api from "../api/api";

const sagas = {
  *[TYPE.GET_LIST_COUPON_MY_VOUCHER.request](action) {
    const { status } = action.payload;
    try {
      const data = yield call(
        api[TYPE.GET_LIST_COUPON_MY_VOUCHER.request],
        action.payload
      );
      if (data.status === 200) {
        yield put(
          actions.GET_LIST_COUPON_MY_VOUCHER.success({
            data: data.data,
            status,
          })
        );
      } else {
        yield put(actions.GET_LIST_COUPON_MY_VOUCHER.failure(data?.data));
      }
    } catch (error) {
      yield put(actions.GET_LIST_COUPON_MY_VOUCHER.failure(error?.data));
    }
  },
};

function* watchedSaga() {
  yield takeEvery(
    TYPE.GET_LIST_COUPON_MY_VOUCHER.request,
    sagas[TYPE.GET_LIST_COUPON_MY_VOUCHER.request]
  );
}

export default function* rootChild() {
  yield fork(watchedSaga);
}
