export const productList = undefined;
export const outOfStockProductList = undefined;
export const defaultDeliveryInfo = undefined;
export const suggestedCouponList = undefined;
export const shippingInfo = undefined;
export const suggestedDeliveryDiscountList = undefined;
export const discountAmount = 0;
export const appliedCouponVoucherCode = undefined; // after call coupon/GetAvailableCouponsByCode, take the voucherCode of coupons[0]
export const orderInfo = undefined;
export const storeInfo = {};
export const loyaltyPointList = [];

export const isLoading = {
  GET_USER_DEFAULT_ADDRESS_BOOK: false,

  GET_SUGGESTED_COUPON_LIST: false,
  GET_SHIPPING_INFO: false,
  GET_SUGGESTED_DELIVERY_DISCOUNT_LIST: false,

  CALC_COUPON_DISCOUNT: false,
  GET_AVAILABLE_COUPONS_BY_CODE: false,
  GET_AVAILABLE_COUPONS_OF_ALLOCATED_COUPON_TICKET: false,
  CALC_COUPON_DISCOUNT_V2: false,

  CREATE_ORDER: false,

  GET_PRODUCT_LIST: false,
  GET_OUT_OF_STOCK_PRODUCT_LIST: false,

  GET_STORE_INFO: false,
  GET_LOYALTY_POINT_LIST: false,
};
