import APIUtils from 'utils/apiUtils';
import {END_POINTS} from '@shared/constants/apiUrl';

export function getListCouponForVoucherApi(type) {
  var requestData = `?status=Redeem&voucherType=Coupon&orderType=${type}`;

  return APIUtils.get(END_POINTS.GET_MY_VOUCHER_URL + requestData);
}

export function getCouponDetailForVoucherApi(dataCoupon) {
  var requestData =
    '?productcode=' +
    dataCoupon.couponCode +
    '&status=Redeem&transactioncode=' +
    dataCoupon.transactionCode;

  return APIUtils.get(END_POINTS.GET_VOUCHER_DETAIL_URL + requestData);
}

export function searchCouponDetailForVoucherApi({data}) {
  var requestData = '?couponCode=' + data.couponCode;

  return APIUtils.get(
    END_POINTS.GET_COUPON_CAMPAIGN_BY_COUPON_CODE_URL + requestData,
  );
}

export function getAvailableCouponApi(couponCode) {
  var requestData = {
    code: couponCode,
  };

  return APIUtils.post(END_POINTS.GET_AVAILABLE_COUPONS_BY_CODE_URL, {
    body: requestData,
  });
}
