import request from "services/request";
import { encodeQueryData } from "utils/stringFormat";

import * as TYPE from "../store/constants";
// https://utop-apim-utopapp-qa.azure-api.net/voucher/markUsed
const api = {
  [TYPE.VOUCHER_DETAIL.request]: async (params) => {
    const query = encodeQueryData(params);
    return request(`voucher/VoucherDetail${query}`, "GET");
  },

  [TYPE.VOUCHER_INFOMATION.request]: async (params) => {
    return request(`cms/core/vouchers/${params.voucherId}`, "GET");
  },

  // [TYPE.GET_VOUCHER_OUTSTAND.request]: async (params) => {
  //   const query = encodeQueryData({
  //     categoryId: params.categoryId,
  //     lat: params.lat,
  //     long: params.long,
  //   });
  //   return request(`/feed/voucher/${query}`, "GET", null, {
  //     "content-type": "application/json",
  //     "Access-Control-Allow-Credentials": true,
  //     Authorization: "",
  //     "Ocp-Apim-Subscription-Key":
  //       process.env.REACT_APP_OCP_APIM_SUBSCRIPTION_KEY,
  //     "X-Client": "Web",
  //   });
  // },

  [TYPE.CLICK_BY_MARK_USED.request]: async (bodyData) => {
    return request(`/voucher/markUsed`, "POST", bodyData);
  },
};

export default api;
