import { callApi } from "helpers/callApi";
import { fork, put, takeLatest, takeLeading } from "redux-saga/effects";
import { getListNumberNewsCurrentApi } from "@shared/services/api/listImagesNews";
import {
  getListNumberNewsCurrentTypeSuccess,
  getListNumberNewsCurrentTypeFailure,
} from "./actions";
import { GET_LIST_IMAGES_NEWS_CURRENT } from "./constants";

export function* getListNumberNewsCurrentSaga(action) {
  const { onSuccess, newsPagesId, currentEditionId, onFailed } = action;
  try {
    const res = yield callApi(getListNumberNewsCurrentApi, {
      newsPagesId,
      currentEditionId,
    });
    yield put(getListNumberNewsCurrentTypeSuccess());
    onSuccess?.(res.data);
  } catch (error) {
    onFailed?.();
    yield put(getListNumberNewsCurrentTypeFailure(error));
  }
}

function* watchListNumberNewsCurrent() {
  yield takeLeading(
    GET_LIST_IMAGES_NEWS_CURRENT.HANDLER,
    getListNumberNewsCurrentSaga
  );
}

export default function* rootChild() {
  yield fork(watchListNumberNewsCurrent);
}
