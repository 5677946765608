const prefix = 'repaymentVoucher';
// GET DETAIL ORDER
export const GET_DETAIL_ORDER_REQUEST = prefix + '/GET_DETAIL_ORDER_REQUEST';
export const GET_DETAIL_ORDER_SUCCESS = prefix + '/GET_DETAIL_ORDER_SUCCESS';
export const GET_DETAIL_ORDER_FAILED = prefix + '/GET_DETAIL_ORDER_FAILED';

//GET INFO STORE
export const GET_INFO_STORE_REQUEST = prefix + '/GET_INFO_STORE_REQUEST';
export const GET_INFO_STORE_SUCCESS = prefix + '/GET_INFO_STORE_SUCCESS';
export const GET_INFO_STORE_FAILED = prefix + '/GET_INFO_STORE_FAILED';

//CHECK DISCOUNT COUPON
export const GET_DISCOUNT_COUPON_REQUEST =
  prefix + '/GET_DISCOUNT_COUPON_REQUEST';
export const GET_DISCOUNT_COUPON_SUCCESS =
  prefix + '/GET_DISCOUNT_COUPON_SUCCESS';
export const GET_DISCOUNT_COUPON_FAILED =
  prefix + '/GET_DISCOUNT_COUPON_FAILED';

//GET PAYMENT METHOD
export const GET_PAYMENT_METHOD_REQUEST =
  prefix + '/GET_PAYMENT_METHOD_REQUEST';
export const GET_PAYMENT_METHOD_SUCCESS =
  prefix + '/GET_PAYMENT_METHOD_SUCCESS';
export const GET_PAYMENT_METHOD_FAILED = prefix + '/GET_PAYMENT_METHOD_FAILED';

//CANCEL ORDER
export const CANCEL_ORDER_REQUEST = prefix + '/CANCEL_ORDER_REQUEST';
export const CANCEL_ORDER_SUCCESS = prefix + '/CANCEL_ORDER_SUCCESS';
export const CANCEL_ORDER_FAILED = prefix + '/CANCEL_ORDER_FAILED';

export const GET_LIST_SUMMARY_BY_USER_REQUEST =
  prefix + '/GET_LIST_SUMMARY_BY_USER_REQUEST';
export const GET_LIST_SUMMARY_BY_USER_SUCCESS =
  prefix + '/GET_LIST_SUMMARY_BY_USER_SUCCESS';
export const GET_LIST_SUMMARY_BY_USER_FAILED =
  prefix + '/GET_LIST_SUMMARY_BY_USER_FAILED';
