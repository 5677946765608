const prefix = 'historyPoint';
export const GET_HISTORY_POINT_REQUEST = prefix + '/GET_HISTORY_POINT_REQUEST';
export const GET_HISTORY_POINT_SUCCESS = prefix + '/GET_HISTORY_POINT_SUCCESS';
export const GET_HISTORY_POINT_FAILED = prefix + '/GET_HISTORY_POINT_FAILED';

export const GET_DETAIL_HISTORY_POINT_REQUEST =
  prefix + '/GET_DETAIL_HISTORY_POINT_REQUEST';
export const GET_DETAIL_HISTORY_POINT_SUCCESS =
  prefix + '/GET_DETAIL_HISTORY_POINT_SUCCESS';
export const GET_DETAIL_HISTORY_POINT_FAILED =
  prefix + '/GET_DETAIL_HISTORY_POINT_FAILED';
