import React from "react";
import classNames from "classnames";
import "./LoadingOverlay.scss";

function LoadingOverlay({ isLoading = true, style }) {
  return (
    <div
      className={classNames(
        "loading-overlay__container",
        !isLoading && "d-none"
      )}
      style={style}
    >
      <div className={classNames(isLoading && "loading-overlay__layer")} />

      {isLoading && (
        <div className="lds-ellipsis indicators--center-of-parent">
          <div />
          <div />
          <div />
          <div />
        </div>
      )}
    </div>
  );
}

export default LoadingOverlay;
