import request from "services/request";
import { encodeQueryData } from "utils/stringFormat";

import * as TYPE from "../store/constants";

const api = {
  [TYPE.GET_LIST_COUPON_MY_VOUCHER.request]: async (params) => {
    const query = encodeQueryData(params);
    return request(`/voucher/myvouchers${query}`, "GET");
  },
};
export default api;
