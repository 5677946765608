import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import { FALLBACK_LANGUAGE } from "configs/localesConfig";
import RESOURCE_VI from "./vi";
import RESOURCE_EN from "./en";

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    // debug: true,
    fallbackLng: FALLBACK_LANGUAGE,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      vi: RESOURCE_VI,
      en: RESOURCE_EN,
    },
    lng: localStorage.getItem("i18nextLng") || "vi-VN", // luu trong local storage de keep ngon ngu ma device da dung
    // ns: ["common"], // namespace
    // va: ["validate"],
    // keySeparator: false,

    react: {
      wait: true,
      //   useSuspense: false,
    },
  });

export function translate(text, options = {}) {
  return i18n.t(text, options);
}

export default i18n;
