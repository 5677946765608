import {
  UPLOAD_VIDEO_REQUEST,
  UPLOAD_VIDEO_SUCCESS,
  UPLOAD_VIDEO_FAILED,
  GET_VIDEO_PROBLEM_REQUEST,
  GET_VIDEO_PROBLEM_SUCCESS,
  GET_VIDEO_PROBLEM_FAILED,
  DELETE_VIDEO_REQUEST,
  DELETE_VIDEO_SUCCESS,
  DELETE_VIDEO_FAILED,
  SET_ISCOMMENTABLE_REQUEST,
  SET_ISCOMMENTABLE_SUCCESS,
  SET_ISCOMMENTABLE_FAILED,
  SET_PRIVACY_REQUEST,
  SET_PRIVACY_SUCCESS,
  SET_PRIVACY_FAILED,
} from './types';

export const uploadVideoRequest = payload => ({
  type: UPLOAD_VIDEO_REQUEST,
  payload,
});

export const uploadVideoSuccess = payload => ({
  type: UPLOAD_VIDEO_SUCCESS,
  payload,
});

export const uploadVideoFailure = payload => ({
  type: UPLOAD_VIDEO_FAILED,
  payload,
});

export const getVideoProblemRequest = payload => ({
  type: GET_VIDEO_PROBLEM_REQUEST,
  payload,
});

export const getVideoProblemSuccess = payload => ({
  type: GET_VIDEO_PROBLEM_SUCCESS,
  payload,
});

export const getVideoProblemFailed = payload => ({
  type: GET_VIDEO_PROBLEM_FAILED,
  payload,
});

export const deleteVideoRequest = payload => ({
  type: DELETE_VIDEO_REQUEST,
  payload,
});

export const deleteVideoSuccess = payload => ({
  type: DELETE_VIDEO_SUCCESS,
  payload,
});

export const deleteVideoFailed = payload => ({
  type: DELETE_VIDEO_FAILED,
  payload,
});

export const setIsCommentableRequest = payload => ({
  type: SET_ISCOMMENTABLE_REQUEST,
  payload,
});

export const setIsCommentableSuccess = payload => ({
  type: SET_ISCOMMENTABLE_SUCCESS,
  payload,
});

export const setIsCommentableFailed = payload => ({
  type: SET_ISCOMMENTABLE_FAILED,
  payload,
});

export const setPrivacyRequest = payload => ({
  type: SET_PRIVACY_REQUEST,
  payload,
});

export const setPrivacySuccess = payload => ({
  type: SET_PRIVACY_SUCCESS,
  payload,
});

export const setPrivacyFailed = payload => ({
  type: SET_PRIVACY_FAILED,
  payload,
});
