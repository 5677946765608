import React from "react";
import { IconList } from "views/common/component";
import { ArrowRightYellowIcon } from "assets/svg";
import "./RatingStoreView.scss";

const RatingStoreView = ({ ratingData, handleSeeAllRating }) => {
  if (!ratingData || !ratingData?.count > 0) {
    return (
      <div className="store-view__rating__header">
        <div className="store-view__rating__header__text-header">
          <div className="store-view__rating__header__text-header__left">
            <h1>Đánh giá</h1>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="store-view__rating__header">
      <div className="store-view__rating__header__text-header">
        <div className="store-view__rating__header__text-header__left">
          <h1>
            Đánh giá <span>({ratingData.count} đánh giá)</span>
          </h1>
        </div>
        <div
          className="store-view__rating__header__text-header__right"
          onClick={handleSeeAllRating}
        >
          <span>Xem thêm</span>
          {/* <img src={SVG.ArrowRightYellow} alt="arrow" /> */}
          <ArrowRightYellowIcon />
        </div>
      </div>
      <div className="store-view__rating__header__icon-star">
        <IconList
          selectedNum={Number(ratingData?.avgRate)}
          containerClassName="start-list"
        />
        <span style={{ marginLeft: "4px" }}>
          {parseFloat(ratingData?.avgRate).toFixed(1)}
        </span>
      </div>
    </div>
  );
};
export default RatingStoreView;
