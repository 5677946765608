import APIUtils from 'utils/apiUtils'
import { END_POINTS } from '@shared/constants/apiUrl'

export function cancelOrderApi(orderCode) {
  var requestData = { OrderCode: orderCode }
  return APIUtils.post(END_POINTS.CANCEL_ORDER_URL, {
    body: requestData,
  })
}

export function cancelItemPendingApi(orderCode) {
  var requestData = { OrderCode: orderCode }
  return APIUtils.post(END_POINTS.CANCEL_PENDING_ITEM_URL, {
    body: requestData,
  })
}

export function getProductChangedApi(orderCode) {
  const params = {
    orderCode: orderCode,
  }
  return APIUtils.get(END_POINTS.GET_PRODUCT_CHANGED_URL, {
    params,
  })
}

export function setReceivedOrderApi(orderCode) {
  return APIUtils.put(`${END_POINTS.SET_RECEIVED_ORDER}${orderCode}/Received`)
}

export function getEstimateDeliveryTimeApi({
  merchantId,
  storeId,
  receiveAddress,
}) {
  const params = {
    MerchantId: merchantId,
    StoreId: storeId,
    ReceiveAddress: receiveAddress,
  }
  return APIUtils.get(END_POINTS.GET_ESTIMATE_DELIVERY_TIME, {
    params,
  })
}
export function getCustomConfigMapApi(provider) {
  return APIUtils.get(
    END_POINTS.GET_CUSTOM_CONFIG_MAP_SHIPPING + `?provider=${provider}`,
  )
}
