import React, { useRef, useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import {
  searchStallsRequest,
  clearSearchStalls,
  clearSearchStallsHistory,
} from "@shared/redux/market/marketStore/actions";
import {
  NavigationServices,
  //   checkGuest,
  //   checkGuestPopup,
  //   insightTrackEvent,
} from "utils";
import {
  getListSearchStallsSelector,
  getSkipSearchStallsSelector,
} from "@shared/redux/market/marketStore/selectors";
import {
  getDataStore,
  getListProduct,
} from "@shared/redux/smartMenu/selectors";
import {
  setDataStore,
  setIndexProductCart,
} from "@shared/redux/smartMenu/actions";
import SearchStoreMarketView from "./SearchStoreMarket.view";

const PAGE_SIZE = 10;
const SearchStoreMarket = (props) => {
  const [data, setData] = useState(null);
  const [txtSearch, setTxtSearch] = useState("");
  const [storeInfo, setStoreInfo] = useState({});
  const [isShowLoading, setIsShowLoading] = useState(false);
  const flatRef = useRef();
  const [isEndSearchStalls, setIsEndSearchStalls] = useState(false);
  const [listSearchHistory, setListSearchHistory] = useState([]);
  const [isScrollTop, setIsScrollTop] = useState(false);
  const listSearchHistoryTemp = useSelector(
    (store) => store.marketStore.listSearchHistory
  );

  const params = NavigationServices.getParams(props);

  useEffect(() => {
    if (!!listSearchHistoryTemp && listSearchHistoryTemp.length > 0) {
      setListSearchHistory(listSearchHistoryTemp);
    } else {
      setListSearchHistory([]);
    }
  }, [listSearchHistoryTemp]);
  useEffect(() => {
    props.clearSearchStalls();
    setStoreInfo(params?.storeInfo);
    return () => {
      props.clearSearchStalls();
    };
  }, [params]);

  // useEffect(() => {
  //   if (
  //     (JSON.stringify(props.getDataStore) !== '{}' &&
  //       storeInfo?.id === props.getDataStore.storeId) ||
  //     props.route.params?.dataReOrder
  //   ) {
  //     props.setIndexProductCart();
  //   }
  // }, [props.getDataCart, props.getDataStore]);

  useEffect(() => {
    if (props.listStallsSelector) {
      setIsShowLoading(false);
      setData(props.listStallsSelector);
    }
  }, [props.listStallsSelector]);

  const _onSearchProduct = (valueSearch) => {
    // eslint-disable-next-line no-param-reassign
    valueSearch = valueSearch.trim();
    setIsScrollTop(false);
    if (valueSearch.length > 0) {
      setIsShowLoading(true);
      // if (!!flatRef.current) {
      //   flatRef.current.scrollToOffset({animated: true, offset: 0});
      // }
      setTxtSearch(valueSearch);
      const requestData = {
        merchantId: params.storeInfo.merchantId,
        storeId: params.storeInfo.storeId,
        skip: 0,
        take: PAGE_SIZE,
        valueSearch,
        isLoadNew: true,
      };
      props.searchStallsRequest(
        requestData,
        (res) => {
          if (res.data.length > 0) {
            setIsScrollTop(true);
            if (res.data.length < PAGE_SIZE) {
              setIsEndSearchStalls(true);
            } else {
              setIsEndSearchStalls(false);
            }
          } else {
            setIsEndSearchStalls(false);
          }
        },
        (err) => {
          console.log("getListStoreMerchantRequest err", err);
        }
      );
      //   const dataTrackingAzure = {
      //     MerchantId: props.route.params.storeInfo?.merchantId || "",
      //     MerchantName: props.route.params?.storeInfo?.merchantName || "",
      //     StoreId: props.route.params.storeInfo.storeId || "",
      //     StoreName: props.route.params.storeInfo?.name || "",
      //     searchText: valueSearch,
      //   };
      //   insightTrackEvent("Stalls_Search", dataTrackingAzure);
    }
  };

  const onLoadMore = () => {
    setIsScrollTop(false);
    if (isEndSearchStalls !== undefined && !isEndSearchStalls) {
      const requestData = {
        merchantId: params.storeInfo.merchantId,
        storeId: params.storeInfo.storeId,
        skip: props.skipSearchStallsSelector,
        take: PAGE_SIZE,
        valueSearch: txtSearch,
      };
      props.searchStallsRequest(
        requestData,
        (res) => {
          console.log("getListStoreMerchantRequest", res.data);
          if (res.data.length === 0 || res.data.length < PAGE_SIZE) {
            setIsEndSearchStalls(true);
          }
        },
        (err) => {
          console.log("getListStoreMerchantRequest err", err);
        }
      );
    }
  };

  const _onClearSearchtext = () => {
    setTxtSearch("");
    setData(null);
    props.clearSearchStalls();
  };

  const clearSearchHistory = () => {
    props.clearSearchStallsHistory();
    props.clearSearchStalls();
  };

  const _onclickViewDetail = (item) => {
    NavigationServices.navigate("StoreDetailMarket", {
      storeId: storeInfo?.id,
      merchantId: storeInfo?.merchantId,
      storeMerchantId: item.id,
      isCloseStore: params.isCloseStore,
    });
  };
  return (
    <SearchStoreMarketView
      dataStalls={data}
      _onSearchProduct={_onSearchProduct}
      isLoading={props.isLoading}
      loadMore={onLoadMore}
      _onClearSearchtext={_onClearSearchtext}
      listSearchHistory={listSearchHistory}
      txtSearch={txtSearch}
      clearSearchHistory={clearSearchHistory}
      isShowLoading={isShowLoading}
      storeInfo={storeInfo}
      flatRef={flatRef}
      dataCart={
        JSON.stringify(props?.getDataStore) !== "{}" &&
        storeInfo?.id === props?.getDataStore?.storeId
          ? props?.getDataCart
          : props?.route?.params?.dataReOrder || []
      }
      isEndSearchStalls={isEndSearchStalls}
      isScrollTop={isScrollTop}
      isCloseStore={params?.isCloseStore}
      gotoProduct={_onclickViewDetail}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    listStallsSelector: getListSearchStallsSelector(state),
    skipSearchStallsSelector: getSkipSearchStallsSelector(state),
    getDataCart: getListProduct(state),
    getDataStore: getDataStore(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      searchStallsRequest,
      clearSearchStalls,
      clearSearchStallsHistory,
      setDataStore,
      setIndexProductCart,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SearchStoreMarket);
