export const orderDetail = undefined;
export const listTransactionMethods = undefined; // lấy ds phương thức thanh toán đang được BE hỗ trợ
export const listPaymentCards = undefined; // lấy ds thẻ thanh toán của user
export const utopWalletInfo = undefined;
export const createPayment = undefined; // when pay by methods that's not utop wallet
export const paidTransactionInfo = undefined; // when call api to pay by utop wallet
export const isPaid = undefined; // when pay by utop wallet

export const isLoading = {
  GET_PAYMENT_METHODS: false,
  GET_PAYMENT_CARDS: false,
  CREATE_PAYMENT: false,
  GET_UTOP_WALLET_INFO: false,
  TRANSACTION_PAY: false,
  GET_ORDER_DETAIL: false,
};
