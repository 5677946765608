import { put, takeLatest, fork } from "redux-saga/effects";
import {
  PAY_BY_TOKEN_V1,
  PAY_BY_TOKEN_V2,
  PAY_BY_UTOP_BUSINESS,
  CHECK_UTOP_BUSINESS,
  GET_LIST_BUDGET_SOUCRE,
  CHECK_QR,
  GET_LIST_LEGAL,
  LOAD_DATA_QR,
  UPLOAD_IMAGE_UTOP_BUSINESS,
} from "./constants";
import {
  payByTokenV1API,
  payByTokenV2API,
  payByUtopBusinessAPI,
  checkUtopBusinessAPI,
  getlistBudgetSourceAPI,
  checkQRAPI,
  getlistLegalAPI,
  loadDataQRAPI,
  uploadImageAPI,
} from "@shared/services/api/qrpay";
import { callApi } from "helpers/callApi";
// import RNFetchBlob from "rn-fetch-blob";
import AppConfigs from "configs/env";
import { APIUtils } from "utils";

export function* payByTokenV1Saga(obj) {
  const { payload = {}, onSuccess, onError } = obj;
  try {
    const res = yield callApi(payByTokenV1API, payload);
    console.log(res);
    if (typeof onSuccess === "function") {
      onSuccess(res);
    }
  } catch (err) {
    console.log(err);
    if (typeof onError === "function") {
      onError(err);
    }
  }
}
export function* payByTokenV2Saga(obj) {
  const { payload = {}, onSuccess, onError } = obj;
  try {
    const res = yield callApi(payByTokenV2API, payload);
    console.log(res);
    if (typeof onSuccess === "function") {
      onSuccess(res);
    }
  } catch (err) {
    console.log(err);
    if (typeof onError === "function") {
      onError(err);
    }
  }
}
export function* payByUtopBusinessSaga(obj) {
  const { payload = {}, onSuccess, onError } = obj;
  try {
    const res = yield callApi(payByUtopBusinessAPI, payload);
    console.log(res);
    if (typeof onSuccess === "function") {
      onSuccess(res);
    }
  } catch (err) {
    console.log(err);

    if (typeof onError === "function") {
      onError(err);
    }
  }
}
export function* checkUtopBusinessSaga(obj) {
  const { payload = {}, onSuccess, onError } = obj;
  try {
    const res = yield callApi(checkUtopBusinessAPI, payload);
    console.log(res);
    if (typeof onSuccess === "function") {
      onSuccess(res);
    }
  } catch (err) {
    console.log(err);

    if (typeof onError === "function") {
      onError(err);
    }
  }
}
export function* getlistBudgetSourceSaga(obj) {
  const { payload = {}, onSuccess, onError } = obj;
  try {
    const res = yield callApi(getlistBudgetSourceAPI, payload);
    console.log(res);
    if (typeof onSuccess === "function") {
      onSuccess(res);
    }
  } catch (err) {
    console.log(err);

    if (typeof onError === "function") {
      onError(err);
    }
  }
}
export function* getlistLegalSaga(obj) {
  const { payload = {}, onSuccess, onError } = obj;
  try {
    const res = yield callApi(getlistLegalAPI, payload);
    console.log(res);
    if (typeof onSuccess === "function") {
      onSuccess(res);
    }
  } catch (err) {
    console.log(err);

    if (typeof onError === "function") {
      onError(err);
    }
  }
}
export function* checkQRSaga(obj) {
  const { payload = {}, onSuccess, onError } = obj;
  try {
    const res = yield callApi(checkQRAPI, payload);
    console.log(res);
    if (typeof onSuccess === "function") {
      onSuccess(res);
    }
  } catch (err) {
    console.log(err);

    if (typeof onError === "function") {
      onError(err);
    }
  }
}
export function* loadDataQRSaga(obj) {
  const { payload = {}, onSuccess, onError } = obj;
  try {
    const res = yield callApi(loadDataQRAPI, payload);
    console.log(res);
    if (typeof onSuccess === "function") {
      onSuccess(res);
    }
  } catch (err) {
    console.log(err);

    if (typeof onError === "function") {
      onError(err);
    }
  }
}
export function* uploadImageSaga(obj) {
  const { payload = {}, onSuccess, onError } = obj;
  try {
    // const res = yield callApi(uploadImageAPI, payload);
    console.log(
      "uploadImageSaga",
      payload.file.path,
      RNFetchBlob.wrap(payload.file.path)
    );
    let url = payload.file.path.replace("file://", "");
    console.log("RNFetchBlob", RNFetchBlob.wrap(decodeURIComponent(url)));
    const renewToken = yield APIUtils.reauthenticate();
    console.log(renewToken);
    // RNFetchBlob.fetch(
    //   "POST",
    //   "https://utop-apim-utopapp-qa.azure-api.net/Business/Image/Receipt",
    //   {
    //     Authorization: "Bearer " + renewToken,
    //     "Ocp-Apim-Subscription-Key": AppConfigs.subscriptionKey,
    //     "Content-type": "multipart/form-data",
    //   },
    //   [
    //     {
    //       name: "file",
    //       filename: new Date().getTime().toString() + ".jpg",
    //       data: RNFetchBlob.wrap(decodeURIComponent(url)),
    //     },
    //   ]
    // )
    //   .uploadProgress((written, total) => {
    //     console.log("uploaded", written / total);
    //   })
    //   .progress((received, total) => {
    //     console.log("progress", received / total);
    //   })
    //   .then((value) => {
    //     if (typeof onSuccess === "function") {
    //       onSuccess(value);
    //     }
    //   })
    //   .catch((error) => {
    //     if (typeof onError === "function") {
    //       onError(error);
    //     }
    //   });
  } catch (err) {
    console.log(err);
    if (typeof onError === "function") {
      onError(err);
    }
  }
}

function* watchUser() {
  yield takeLatest(PAY_BY_TOKEN_V1, payByTokenV1Saga);
  yield takeLatest(PAY_BY_TOKEN_V2, payByTokenV2Saga);
  yield takeLatest(PAY_BY_UTOP_BUSINESS, payByUtopBusinessSaga);
  yield takeLatest(CHECK_UTOP_BUSINESS, checkUtopBusinessSaga);
  yield takeLatest(GET_LIST_BUDGET_SOUCRE, getlistBudgetSourceSaga);
  yield takeLatest(GET_LIST_LEGAL, getlistLegalSaga);
  yield takeLatest(CHECK_QR, checkQRSaga);
  yield takeLatest(LOAD_DATA_QR, loadDataQRSaga);
  yield takeLatest(UPLOAD_IMAGE_UTOP_BUSINESS, uploadImageSaga);
}
export default function* rootChild() {
  yield fork(watchUser);
}
