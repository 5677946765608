import { call, put, takeLatest, fork, takeEvery } from "redux-saga/effects";
import actions from "./actions";
import * as TYPE from "./constants";
import api from "../api/api";

const sagas = {
  // *[TYPE.CREATOR.request](action) {
  //   try {
  //     const data = yield call(api[TYPE.CREATOR.request], action.payload);
  //     if (data.status === 200) {
  //       yield put(actions.CREATOR.success(data.data));
  //     } else {
  //       yield put(actions.CREATOR.failure(data?.data));
  //     }
  //   } catch (error) {
  //     yield put(actions.CREATOR.failure(error?.data));
  //   }
  // },

  *[TYPE.GET_MERCHANT_INFO.request](action) {
    try {
      const data = yield call(
        api[TYPE.GET_MERCHANT_INFO.request],
        action.payload
      );

      if (data.status === 200) {
        yield put(actions.GET_MERCHANT_INFO.success(data.data));
      } else {
        yield put(actions.GET_MERCHANT_INFO.failure(data?.data));
      }
    } catch (error) {
      yield put(actions.GET_MERCHANT_INFO.failure(error?.data));
    }
  },

  *[TYPE.GET_STORE_INFO.request](action) {
    try {
      const data = yield call(api[TYPE.GET_STORE_INFO.request], action.payload);

      if (data.status === 200) {
        yield put(actions.GET_STORE_INFO.success(data.data));
      } else {
        yield put(actions.GET_STORE_INFO.failure(data?.data));
      }
    } catch (error) {
      yield put(actions.GET_STORE_INFO.failure(error?.data));
    }
  },

  *[TYPE.GET_BEST_SELLER_LIST.request](action) {
    try {
      const data = yield call(
        api[TYPE.GET_BEST_SELLER_LIST.request],
        action.payload
      );

      if (data.status === 200) {
        yield put(actions.GET_BEST_SELLER_LIST.success(data.data));
      } else {
        yield put(actions.GET_BEST_SELLER_LIST.failure(data?.data));
      }
    } catch (error) {
      yield put(actions.GET_BEST_SELLER_LIST.failure(error?.data));
    }
  },

  *[TYPE.GET_PRODUCT_LIST.request](action) {
    try {
      const { merchantId, onSuccess } = action.payload;

      const response = yield call(
        api[TYPE.GET_PRODUCT_LIST.request],
        merchantId
      );

      if (response.status === 200) {
        onSuccess?.(
          response.data?.data?.queryProductfnbContentsWithTotal?.items
        );
        yield put(
          actions.GET_PRODUCT_LIST.success(
            response.data?.data?.queryProductfnbContentsWithTotal?.items
          )
        );
      } else {
        yield put(actions.GET_PRODUCT_LIST.failure(response?.data));
      }
    } catch (error) {
      yield put(actions.GET_PRODUCT_LIST.failure(error?.data));
    }
  },

  *[TYPE.GET_RECENT_ORDERED_PRODUCT_CODE_LIST.request](action) {
    try {
      const response = yield call(
        api[TYPE.GET_RECENT_ORDERED_PRODUCT_CODE_LIST.request],
        action.payload
      );

      if (response.status === 200) {
        yield put(
          actions.GET_RECENT_ORDERED_PRODUCT_CODE_LIST.success(response.data)
        );
      } else {
        yield put(
          actions.GET_RECENT_ORDERED_PRODUCT_CODE_LIST.failure(response?.data)
        );
      }
    } catch (error) {
      yield put(
        actions.GET_RECENT_ORDERED_PRODUCT_CODE_LIST.failure(error?.data)
      );
    }
  },

  *[TYPE.GET_SUGGESTED_COUPON_LIST.request](action) {
    try {
      const response = yield call(
        api[TYPE.GET_SUGGESTED_COUPON_LIST.request],
        action.payload
      );

      if (response.status === 200) {
        yield put(actions.GET_SUGGESTED_COUPON_LIST.success(response.data));
      } else {
        yield put(actions.GET_SUGGESTED_COUPON_LIST.failure(response?.data));
      }
    } catch (error) {
      yield put(actions.GET_SUGGESTED_COUPON_LIST.failure(error?.data));
    }
  },

  *[TYPE.SAVE_SUGGESTED_COUPON.request](action) {
    try {
      const response = yield call(
        api[TYPE.SAVE_SUGGESTED_COUPON.request],
        action.payload
      );

      if (response.status === 200) {
        yield put(
          actions.SAVE_SUGGESTED_COUPON.success(
            // response.data
            action.payload
          )
        );
      } else {
        yield put(actions.SAVE_SUGGESTED_COUPON.failure(response?.data));
      }
    } catch (error) {
      yield put(actions.SAVE_SUGGESTED_COUPON.failure(error?.data));
    }
  },

  *[TYPE.GET_OUT_OF_STOCK_PRODUCT_LIST.request](action) {
    try {
      const response = yield call(
        api[TYPE.GET_OUT_OF_STOCK_PRODUCT_LIST.request],
        action.payload
      );

      if (response.status === 200) {
        yield put(actions.GET_OUT_OF_STOCK_PRODUCT_LIST.success(response.data));
      } else {
        yield put(
          actions.GET_OUT_OF_STOCK_PRODUCT_LIST.failure(response?.data)
        );
      }
    } catch (error) {
      yield put(actions.GET_OUT_OF_STOCK_PRODUCT_LIST.failure(error?.data));
    }
  },

  *[TYPE.GET_SHORT_LINK_TO_SHARE.request](action) {
    const { url, callbackFunction } = action.payload;

    try {
      const response = yield call(api[TYPE.GET_SHORT_LINK_TO_SHARE.request], {
        url,
      });

      if (response.status === 200) {
        yield put(actions.GET_SHORT_LINK_TO_SHARE.success(response.data));
        yield call(callbackFunction, response.data.url);
      } else {
        yield put(actions.GET_SHORT_LINK_TO_SHARE.failure(response?.data));
      }
    } catch (error) {
      yield put(actions.GET_SHORT_LINK_TO_SHARE.failure(error?.data));
    }
  },
  *[TYPE.GET_STORE_INFO_V2.request](action) {
    try {
      const data = yield call(
        api[TYPE.GET_STORE_INFO_V2.request],
        action.payload
      );

      if (data.status === 200) {
        yield put(actions.GET_STORE_INFO_V2.success(data.data));
      } else {
        yield put(actions.GET_STORE_INFO_V2.failure(data?.data));
      }
    } catch (error) {
      yield put(actions.GET_STORE_INFO_V2.failure(error?.data));
    }
  },
};

function* watchedSaga() {
  yield takeLatest(
    TYPE.GET_MERCHANT_INFO.request,
    sagas[TYPE.GET_MERCHANT_INFO.request]
  );
  yield takeLatest(
    TYPE.GET_STORE_INFO.request,
    sagas[TYPE.GET_STORE_INFO.request]
  );
  yield takeLatest(
    TYPE.GET_BEST_SELLER_LIST.request,
    sagas[TYPE.GET_BEST_SELLER_LIST.request]
  );
  yield takeLatest(
    TYPE.GET_PRODUCT_LIST.request,
    sagas[TYPE.GET_PRODUCT_LIST.request]
  );
  yield takeLatest(
    TYPE.GET_RECENT_ORDERED_PRODUCT_CODE_LIST.request,
    sagas[TYPE.GET_RECENT_ORDERED_PRODUCT_CODE_LIST.request]
  );
  yield takeLatest(
    TYPE.GET_SUGGESTED_COUPON_LIST.request,
    sagas[TYPE.GET_SUGGESTED_COUPON_LIST.request]
  );
  yield takeEvery(
    TYPE.SAVE_SUGGESTED_COUPON.request,
    sagas[TYPE.SAVE_SUGGESTED_COUPON.request]
  );
  yield takeLatest(
    TYPE.GET_OUT_OF_STOCK_PRODUCT_LIST.request,
    sagas[TYPE.GET_OUT_OF_STOCK_PRODUCT_LIST.request]
  );
  yield takeLatest(
    TYPE.GET_SHORT_LINK_TO_SHARE.request,
    sagas[TYPE.GET_SHORT_LINK_TO_SHARE.request]
  );
  yield takeLatest(
    TYPE.GET_STORE_INFO_V2.request,
    sagas[TYPE.GET_STORE_INFO_V2.request]
  );
}

export default function* rootChild() {
  yield fork(watchedSaga);
}
