import { genActionTypes } from "utils/actionGenerator";

export const TYPE = "ListFeed";

export const ACTION_KEYS = {
  GET_LIST_STORE_OF_MERCHANT: "GET_LIST_STORE_OF_MERCHANT",
  GET_LIST_FEED: "GET_LIST_FEED",
};

// export const SET_LOADING = `${TYPE}_SET_LOADING`;
// export const GET_LIST_FEED__FILTER_BY_ZONE = genActionTypes(
//   `${TYPE}_GET_LIST_FEED__FILTER_BY_ZONE`
// );
// export const GET_LIST_FEED__OPEN_CAT = genActionTypes(
//   `${TYPE}_GET_LIST_FEED__OPEN_CAT`
// );
// export const GET_LIST_FEED__OPEN_LIST_STORE = genActionTypes(
//   `${TYPE}_GET_LIST_FEED__OPEN_LIST_STORE`
// );

// * GET_LIST_FEED
export const GET_LIST_FEED = genActionTypes(
  `${TYPE}_${ACTION_KEYS.GET_LIST_FEED}`
);

// * GET_LIST_STORE_OF_MERCHANT
export const GET_LIST_STORE_OF_MERCHANT = genActionTypes(
  `${TYPE}_${ACTION_KEYS.GET_LIST_STORE_OF_MERCHANT}`
);
