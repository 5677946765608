let prefix = 'orderStatus';
export const CANCEL_ORDER_REQUEST = prefix + '/CANCEL_ORDER_REQUEST';
export const CANCEL_ORDER_SUCCESS = prefix + '/CANCEL_ORDER_SUCCESS';
export const CANCEL_ORDER_FAILED = prefix + '/CANCEL_ORDER_FAILED';

export const CANCEL_PENDING_ITEM_REQUEST =
  prefix + '/CANCEL_PENDING_ITEM_REQUEST';
export const CANCEL_PENDING_ITEM_SUCCESS =
  prefix + '/CANCEL_PENDING_ITEM_SUCCESS';
export const CANCEL_PENDING_ITEM_FAILED =
  prefix + '/CANCEL_PENDING_ITEM_FAILED';

export const GET_PRODUCT_CHANGED_REQUEST =
  prefix + '/GET_PRODUCT_CHANGED_REQUEST';
export const GET_PRODUCT_CHANGED_SUCCESS =
  prefix + '/GET_PRODUCT_CHANGED_SUCCESS';
export const GET_PRODUCT_CHANGED_FAILED =
  prefix + '/GET_PRODUCT_CHANGED_FAILED';
export const SET_RECEIVED_ORDER_REQUEST =
  prefix + '/SET_RECEIVED_ORDER_REQUEST';
export const GET_ESTIMATE_DELIVERY_TIME_REQUEST =
  prefix + '/GET_ESTIMATE_DELIVERY_TIME_REQUEST';
export const GET_ESTIMATE_DELIVERY_TIME_SUCCESS =
  prefix + '/GET_ESTIMATE_DELIVERY_TIME_SUCCESS';
export const GET_ESTIMATE_DELIVERY_TIME_FAILED =
  prefix + '/GET_ESTIMATE_DELIVERY_TIME_FAILED';
export const GET_CUSTOM_CONFIG_MAP_REQUEST =
  prefix + '/GET_CUSTOM_CONFIG_MAP_REQUEST';
