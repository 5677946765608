import { put, takeLatest, fork, select } from "redux-saga/effects";
import {
  GET_LIST_NEWSFEED_DYNAMIC_REQUEST,
  GET_LIST_PRODUCT_BY_LOCATION_REQUEST,
  GET_LIST_NEWSFEED_STORE_REQUEST,
} from "./constants";
import {
  getListNewsfeedByLocationSuccess,
  getListNewsfeedByLocationFailed,
  getListNewsfeedDynamicSuccess,
  getListNewsfeedDynamicFailed,
  getListNewsfeedStoreSuccess,
  getListNewsfeedStoreFailed,
} from "./actions";
import {
  getListNewsfeedByLocationApi,
  getListNewsfeedDynamicPageApi,
  getListNewsfeedStoreApi,
} from "@shared/services/api/products";
import { listNewsfeedByLocationParser } from "./parser";
import { getLatLongSelector } from "../gps/selectors";
import { getDistance } from "@shared/helpers/geolib";
import { callApi } from "helpers/callApi";

export function* getListNewsfeedByLocationSaga(obj) {
  const { payload = {}, onSuccess } = obj;
  try {
    const res = yield callApi(getListNewsfeedByLocationApi, payload);
    let gps = yield select(getLatLongSelector);
    res?.data?.value.forEach((itemFeed) => {
      if (
        typeof itemFeed.feedType !== "undefined" &&
        itemFeed.feedType !== null &&
        itemFeed.feedType.toLowerCase() !== "product"
      ) {
        let distance = null;
        if (gps.currentLocation && gps.currentLocation.latitude) {
          if (itemFeed.location !== null) {
            distance = getDistance(
              {
                latitude: itemFeed.location.coordinates[1],
                longitude: itemFeed.location.coordinates[0],
              },
              {
                latitude: gps.currentLocation.latitude,
                longitude: gps.currentLocation.longitude,
              }
            );
            if (distance >= 1000) {
              distance =
                Math.round((distance / 1000 + Number.EPSILON) * 10) / 10;
            } else {
              distance =
                Math.round((distance / 1000 + Number.EPSILON) * 100) / 100;
            }
          }
        }
        itemFeed.distance = distance;
      }
    });
    if (typeof onSuccess === "function") {
      onSuccess(res?.data);
    }
    yield put(
      getListNewsfeedByLocationSuccess({
        total: res?.data["@odata.count"],
        skip: payload.skip || 0,
        data: listNewsfeedByLocationParser(res?.data?.value),
      })
    );
  } catch (err) {
    // yield put(getListNewsfeedByLocationFailed(err));
  }
}

export function* getListNewsfeedDynamicSaga(obj) {
  const { payload = {}, onSuccess } = obj;
  try {
    const res = yield callApi(getListNewsfeedDynamicPageApi, payload);
    let gps = yield select(getLatLongSelector);
    res?.data?.value.forEach((itemFeed) => {
      if (itemFeed.feedType && itemFeed.feedType.toLowerCase() !== "product") {
        let distance = null;
        if (gps.currentLocation && gps.currentLocation.latitude) {
          if (itemFeed.location) {
            distance = getDistance(
              {
                latitude: itemFeed.location.coordinates[1],
                longitude: itemFeed.location.coordinates[0],
              },
              {
                latitude: gps.currentLocation.latitude,
                longitude: gps.currentLocation.longitude,
              }
            );
            if (distance >= 1000) {
              distance =
                Math.round((distance / 1000 + Number.EPSILON) * 10) / 10;
            } else {
              distance =
                Math.round((distance / 1000 + Number.EPSILON) * 100) / 100;
            }
          }
        }
        itemFeed.distance = distance;
      }
    });
    if (typeof onSuccess === "function") {
      onSuccess({
        total: res?.data["@odata.count"],
        skip: payload.skip || 0,
        data: listNewsfeedByLocationParser(res?.data?.value),
      });
    }
    yield put(
      getListNewsfeedDynamicSuccess("1111", {
        total: res?.data["@odata.count"],
        skip: payload.skip || 0,
        data: listNewsfeedByLocationParser(res?.data?.value),
      })
    );
  } catch (err) {
    yield put(getListNewsfeedDynamicFailed(err));
  }
}

export function* getListNewsfeedStoreSaga(obj) {
  const { payload = {}, onSuccess } = obj;
  try {
    const res = yield callApi(getListNewsfeedStoreApi, payload);
    let gps = yield select(getLatLongSelector);
    res?.data?.value.forEach((itemFeed) => {
      if (itemFeed.feedType && itemFeed.feedType.toLowerCase() !== "product") {
        let distance = null;
        if (gps.currentLocation && gps.currentLocation.latitude) {
          if (itemFeed.location) {
            distance = getDistance(
              {
                latitude: itemFeed.location.coordinates[1],
                longitude: itemFeed.location.coordinates[0],
              },
              {
                latitude: gps.currentLocation.latitude,
                longitude: gps.currentLocation.longitude,
              }
            );
            if (distance >= 1000) {
              distance =
                Math.round((distance / 1000 + Number.EPSILON) * 10) / 10;
            } else {
              distance =
                Math.round((distance / 1000 + Number.EPSILON) * 100) / 100;
            }
          }
        }
        itemFeed.distance = distance;
      }
    });
    if (typeof onSuccess === "function") {
      onSuccess({
        total: res?.data["@odata.count"],
        skip: payload.skip || 0,
        data: listNewsfeedByLocationParser(res?.data?.value),
      });
    }
    yield put(
      getListNewsfeedStoreSuccess(payload, {
        total: res?.data["@odata.count"],
        skip: payload.skip || 0,
        data: listNewsfeedByLocationParser(res?.data?.value),
      })
    );
  } catch (err) {
    yield put(getListNewsfeedStoreFailed(err));
  }
}

function* watchProduct() {
  yield takeLatest(
    GET_LIST_PRODUCT_BY_LOCATION_REQUEST,
    getListNewsfeedByLocationSaga
  );
  yield takeLatest(
    GET_LIST_NEWSFEED_DYNAMIC_REQUEST,
    getListNewsfeedDynamicSaga
  );
  yield takeLatest(GET_LIST_NEWSFEED_STORE_REQUEST, getListNewsfeedStoreSaga);
}

export default function* rootChild() {
  yield fork(watchProduct);
}
