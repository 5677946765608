/* eslint-disable prettier/prettier */
import {
  GET_LIST_BLOCK_USER_REQUEST,
  GET_LIST_BLOCK_USER_SUCCESS,
  GET_LIST_BLOCK_USER_FAILED,
  UNBLOCK_USER_REQUEST,
  UNBLOCK_USER_SUCCESS,
  UNBLOCK_USER_FAILED,
} from './types';

export const unblockUserRequest = payload => ({
  type: UNBLOCK_USER_REQUEST,
  payload,
});

export const unblockUserSuccess = payload => ({
  type: UNBLOCK_USER_SUCCESS,
  payload,
});

export const unblockUserFailure = payload => ({
  type: UNBLOCK_USER_FAILED,
  payload,
});

export const getListBlockUserRequest = payload => ({
  type: GET_LIST_BLOCK_USER_REQUEST,
  payload,
});

export const getListBlockUserSuccess = payload => ({
  type: GET_LIST_BLOCK_USER_SUCCESS,
  payload,
});

export const getListBlockUserFailure = payload => ({
  type: GET_LIST_BLOCK_USER_FAILED,
  payload,
});
