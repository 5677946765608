/* eslint-disable no-empty */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import {
  getListMediaSummaryRequest,
  getListMediaCategoryRequest,
  getListStoryRequest,
  getAllCategoryRequest,
  clearListMediaCategory,
} from "@shared/redux/market/marketHome/actions";
import { NavigationServices, AlertMsg } from "utils";

import MarketImages from "./MarketImages.view";

const MarketImagesContainer = (props) => {
  const pageSizeCategory = 50;
  const { storeId } = NavigationServices.getParams(props);
  const dispatch = useDispatch();
  const listHistory = useSelector((state) => state.market.listStory?.data);
  const listCategory = useSelector((state) => state.market.listCategory?.data);
  const dataStory = useSelector((state) => state.market.dataStory);
  const listCurrentMediaCategorySelector = useSelector(
    (state) => state.market.listCurrentMediaCategory?.data
  );
  const skipListCurrentMediaCategory = useSelector(
    (state) => state.market.listCurrentMediaCategory?.skip
  );
  const skipListStory = useSelector((state) => state.market.listStory?.skip);
  const [listAllCategory, setListAllCategory] = useState([]);
  const [currentCategoryViewer, setCurrentCategoryViewer] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    loadData();
  }, [storeId]);

  const loadMediaCategory = (category, isLoadNew = true) => {
    const newOB = {
      skipCount: isLoadNew ? 0 : skipListCurrentMediaCategory,
      take: pageSizeCategory,
      categoryId: category.categoryId,
      storeId: storeId || "",
    };
    dispatch(getListMediaCategoryRequest(newOB));
  };

  useEffect(() => {
    if (
      !!listCurrentMediaCategorySelector &&
      listCurrentMediaCategorySelector?.length > 0
    ) {
      setCurrentCategoryViewer({
        ...currentCategoryViewer,
        mediaAssets: listCurrentMediaCategorySelector,
      });
    }
  }, [listCurrentMediaCategorySelector]);
  const onClickImageCategory = (category) => {
    dispatch(clearListMediaCategory());
    setCurrentCategoryViewer(category);
    loadMediaCategory(category, true);
  };
  const loadMoreStory = () => {
    const newOB = {
      skipCount: skipListStory,
      take: pageSizeCategory,
      categoryId: dataStory.categoryId,
      storeId: storeId || "",
    };
    dispatch(getListStoryRequest(newOB));
  };
  const loadData = () => {
    const newOB = {
      skipCount: 0,
      take: 100,
      storeId: storeId || "",
    };
    dispatch(
      getListMediaSummaryRequest(
        newOB,
        (res) => {
          setIsLoading(false);
          setListAllCategory(res.data?.filter((item) => item.count > 0));
        },
        (err) => {}
      )
    );
    dispatch(getAllCategoryRequest(newOB));
  };
  return (
    <MarketImages
      listHistory={listHistory}
      albums={listCategory}
      dataStory={dataStory}
      currentCategoryViewer={currentCategoryViewer}
      listAllCategory={listAllCategory}
      onClickImageCategory={onClickImageCategory}
      loadMoreStory={loadMoreStory}
      isLoading={isLoading}
    />
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MarketImagesContainer);
