import {END_POINTS} from '@shared/constants/apiUrl';
import {APIUtils} from 'utils';

export function countReferralApi() {
  const url = END_POINTS.URL_GET_REFERRAL_PROCESSING;
  return APIUtils.get(url);
}

export function getListRewardApi() {
  const url = END_POINTS.URL_GET_REFERRAL_REWARD;
  return APIUtils.get(url);
}

export function getListReferredApi({page = 1, limit = 10}) {
  const url = END_POINTS.URL_GET_REFERRAL_REFERRED;
  return APIUtils.get(url, {
    params: {MaxResult: limit, SkipCount: (page - 1) * limit},
  });
}
