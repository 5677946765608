// import * as TYPE from "./constants";
// import { addErrors, delErrors } from "utils/ErrorsGenerator";

const reducers = {
  // [TYPE.CREATOR.success]: (draft, { payload }) => {
  //   draft.errors = delErrors(draft.errors, TYPE.CREATOR.failure);
  //   draft.creator = payload;
  // },
  // [TYPE.CREATOR.failure]: (draft, { payload }) => {
  //   draft.errors = addErrors(draft.errors, {
  //     type: TYPE.CREATOR.failure,
  //     msg: payload,
  //   });
  // },
};

export default reducers;
