export const listTransactionParser = data => {
  if (data) {
    const newArray = data.map(item => {
      let storeImage = item.images[0].ImageUrl;
      item.images.forEach(element => {
        if (element.IsFeatured) {
          storeImage = element.ImageUrl;
        }
      });

      return {
        id: item.id,
        images: item.images ? item.images : [],
        tags: item.tags && typeof item.tags === 'object' ? item.tags : [],
        name: item.name ? item.name : '',
        address: item.address || '',
        rate: item?.rate,
        storeImage: storeImage
      };
    });
    return newArray;
  }
  return [];
};
