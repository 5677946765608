import {} from './actions'
import types from './types'
import { END_POINTS } from '@shared/constants/apiUrl'
import { call, takeLatest, fork, takeEvery, select } from 'redux-saga/effects'
import { APIUtils, LocalStorage } from 'utils'
import AppConfigs from 'configs/env'
import moment from 'moment'

async function getRatingSumApi({ sourceParentId }) {
  let requestData = `/Summary?refId=${sourceParentId}&featureId=${AppConfigs.idFeatureCommentOrder}`

  return APIUtils.get(END_POINTS.rating + requestData)
}

export function* getRatingSum(obj) {
  const { onSuccess, onError } = obj.payload
  try {
    const result = yield call(getRatingSumApi, obj.payload)
    if (result.status >= 200 && result.status < 300) {
      if (typeof onSuccess === 'function') {
        onSuccess(result)
      }
    } else {
      if (typeof onError === 'function') {
        onError(result)
      }
    }
  } catch (err) {
    if (typeof onError === 'function') {
      onError(err)
    }
  }
}

async function likeRatingApi({ ratingId, isLike }) {
  let requestData = `/${ratingId}/${isLike ? 'Like' : 'UnLike'}`

  return APIUtils.post(END_POINTS.rating + requestData)
}

export function* likeRating(obj) {
  const { onSuccess, onError } = obj.payload
  try {
    const result = yield call(likeRatingApi, obj.payload)
    if (result.status >= 200 && result.status < 300) {
      if (typeof onSuccess === 'function') {
        onSuccess(result)
      }
    } else {
      if (typeof onError === 'function') {
        onError(result)
      }
    }
  } catch (err) {
    if (typeof onError === 'function') {
      onError(err)
    }
  }
}

async function likeReplyApi({ isLike, commentId }) {
  let requestData = `/${isLike ? 'LikeComment' : 'UnLikeComment'}`

  return APIUtils.post(END_POINTS.Comment + requestData, {
    body: { commentId: commentId },
  })
}

export function* likeReply(obj) {
  const { onSuccess, onError } = obj.payload
  try {
    const result = yield call(likeReplyApi, obj.payload)
    if (result.status >= 200 && result.status < 300) {
      if (typeof onSuccess === 'function') {
        onSuccess(result)
      }
    } else {
      if (typeof onError === 'function') {
        onError(result)
      }
    }
  } catch (err) {
    if (typeof onError === 'function') {
      onError(err)
    }
  }
}

async function listCommentApi({
  storeId,
  skipCount,
  maxResult,
  haveComment = true,
  rates = [],
  sortingType = 0,
}) {
  let requestData = `?refId=${storeId}&featureId=${AppConfigs.idFeatureCommentOrder}&haveComment=${haveComment}&sortingType=${sortingType}`
  if (skipCount || maxResult || rates) {
    skipCount && (requestData = requestData + `&skip=${skipCount}`)
    maxResult && (requestData = requestData + `&take=${maxResult}`)
    if (rates) {
      rates.map((r) => {
        requestData = requestData + `&rates=${r}`
      })
    }
  }

  return APIUtils.get(END_POINTS.rating + requestData)
}

export function* listComment(obj) {
  const { onSuccess, onError } = obj.payload
  try {
    const result = yield call(listCommentApi, obj.payload)
    if (result.status >= 200 && result.status < 300) {
      if (typeof onSuccess === 'function') {
        onSuccess(result)
      }
    } else {
      if (typeof onError === 'function') {
        onError(result)
      }
    }
  } catch (err) {
    if (typeof onError === 'function') {
      onError(err)
    }
  }
}

async function createCommentApi(body) {
  return APIUtils.post(END_POINTS.Comment, { body: body })
}

export function* createComment(obj) {
  const { onSuccess, onError } = obj.payload
  try {
    const result = yield call(createCommentApi, obj.payload)
    console.log('result', result)
    if (result.status >= 200 && result.status < 300) {
      if (typeof onSuccess === 'function') {
        onSuccess(result)
      }
    } else {
      if (typeof onError === 'function') {
        onError(result)
      }
    }
  } catch (err) {
    if (typeof onError === 'function') {
      onError(err)
    }
  }
}

async function updateCommentApi(body) {
  return APIUtils.put(END_POINTS.Comment, { body: body })
}

export function* updateComment(obj) {
  const { onSuccess, onError } = obj.payload
  try {
    const result = yield call(updateCommentApi, obj.payload)
    if (result.status >= 200 && result.status < 300) {
      if (typeof onSuccess === 'function') {
        onSuccess(result)
      }
    } else {
      if (typeof onError === 'function') {
        onError(result)
      }
    }
  } catch (err) {
    if (typeof onError === 'function') {
      onError(err)
    }
  }
}

async function deleteCommentApi(payload) {
  console.log('deleteCommentApi: ', payload)
  let body = {}
  let url = ''
  if (payload.isRating) {
    url = END_POINTS.rating + `/${payload.id}`
  } else {
    body = {
      featureId: AppConfigs.idFeatureCommentOrder,
      commentId: payload.id,
    }
    url = END_POINTS.Comment
  }

  return APIUtils.delete(url, { body: body })
}

export function* deleteComment(obj) {
  const { onSuccess, onError } = obj.payload
  try {
    const result = yield call(deleteCommentApi, obj.payload)
    if (result.status >= 200 && result.status < 300) {
      if (typeof onSuccess === 'function') {
        onSuccess(result)
      }
    } else {
      if (typeof onError === 'function') {
        onError(result)
      }
    }
  } catch (err) {
    if (typeof onError === 'function') {
      onError(err)
    }
  }
}

async function getListUserLikeApi({ isRating, id, skip = 0, take = 10 }) {
  if (isRating) {
    const extendURL = `/CreatorLike?ratingId=${id}&skip=${skip}&take=${take}`
    return APIUtils.get(END_POINTS.rating + extendURL)
  } else {
    const extendURL = `/CreatorLike?commentId=${id}&skip=${skip}&take=${take}`
    return APIUtils.get(END_POINTS.Comment + extendURL)
  }
}

export function* getListUserLike(obj) {
  const { onSuccess, onError } = obj.payload
  try {
    const result = yield call(getListUserLikeApi, obj.payload)
    console.log('result', result)
    if (result.status >= 200 && result.status < 300) {
      if (typeof onSuccess === 'function') {
        onSuccess(result)
      }
    } else {
      if (typeof onError === 'function') {
        onError(result)
      }
    }
  } catch (err) {
    if (typeof onError === 'function') {
      onError(err)
    }
  }
}

async function getListReasonReportApi() {
  const extendURL = '?type=ReportReason'
  return APIUtils.get(END_POINTS.keyword + extendURL)
}

export function* getListReasonReport(obj) {
  const { onSuccess, onError } = obj.payload
  try {
    const result = yield call(getListReasonReportApi, obj.payload)
    if (result.status >= 200 && result.status < 300) {
      if (typeof onSuccess === 'function') {
        onSuccess(result)
      }
    } else {
      if (typeof onError === 'function') {
        onError(result)
      }
    }
  } catch (err) {
    if (typeof onError === 'function') {
      onError(err)
    }
  }
}

async function sendReportRatingApi(payload) {
  let body = {}
  let url = ''

  if (payload.isRating) {
    body = {
      ratingId: payload.id,
      reasons: payload.reasons,
    }
    url = END_POINTS.rating + '/Report'
  } else {
    body = {
      commentId: payload.id,
      reasons: payload.reasons,
    }
    url = END_POINTS.Comment + '/ReportComment'
  }

  return APIUtils.post(url, { body: body })
}

export function* sendReportRating(obj) {
  const { onSuccess, onError } = obj.payload
  try {
    const result = yield call(sendReportRatingApi, obj.payload)
    if (result.status >= 200 && result.status < 300) {
      if (typeof onSuccess === 'function') {
        onSuccess(result)
      }
    } else {
      if (typeof onError === 'function') {
        onError(result)
      }
    }
  } catch (err) {
    if (typeof onError === 'function') {
      onError(err)
    }
  }
}

async function getOrderNotRatingApi(payload) {
  const url =
    AppConfigs.domainUtopAppAPI +
    `Order/SuggestRating?storeId=${payload.storeId}`
  return APIUtils.get(url)
}

export function* getOrderNotRating(obj) {
  const { onSuccess, onError, storeId } = obj.payload
  try {
    const result = yield call(getOrderNotRatingApi, obj.payload)
    const account = yield select((state) => state.account)
    const dayRating = yield call(
      LocalStorage.current.getItem,
      `IS_RATING_TODAY_${account.memberCode}_${storeId}`,
    )
    const isRatingToday = `${dayRating}` === `${moment().format('DD/MM/yyyy')}`

    if (result.status >= 200 && result.status < 300) {
      if (typeof onSuccess === 'function') {
        result.isRatingToday = isRatingToday
        onSuccess(result)
      }
    } else {
      if (typeof onError === 'function') {
        onError(result)
      }
    }
  } catch (err) {
    if (typeof onError === 'function') {
      onError(err)
    }
  }
}

function* watchOrderComment() {
  yield takeEvery(types.ORDER_GET_COMMENT, listComment)
  yield takeLatest(types.ORDER_POST_COMMENT, createComment)
  yield takeLatest(types.ORDER_UPDATE_COMMENT, updateComment)
  yield takeLatest(types.LIKE_STORE_RATING, likeRating)
  yield takeLatest(types.LIKE_STORE_REPLY, likeReply)
  yield takeLatest(types.GET_RATING_SUM, getRatingSum)
  yield takeEvery(types.GET_LIST_USER_LIKE, getListUserLike)
  yield takeLatest(types.ORDER_DELETE_COMMENT, deleteComment)
  yield takeLatest(types.GET_LIST_REASON_REPORT, getListReasonReport)
  yield takeLatest(types.SEND_REPORT_RATING, sendReportRating)
  yield takeLatest(types.GET_ORDER_NOT_RATING, getOrderNotRating)
}

export default function* rootChild() {
  yield fork(watchOrderComment)
}
