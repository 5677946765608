import { call, put, takeLatest, fork } from "redux-saga/effects";
import { getJsonFromUrl } from "utils/stringFormat";
import actions from "./actions";
import * as TYPE from "./constants";
import api from "../api/api";

const sagas = {
  *[TYPE.CREATE_PAYMENT.request](action) {
    try {
      const data = yield call(api[TYPE.CREATE_PAYMENT.request], action.payload);
      if (data.status === 200) {
        if (action.payload.paymentMethod === "utop") {
          yield put(actions.TRANSACTION_PAY.request(data.data));
        }
        yield put(actions.CREATE_PAYMENT.success(data.data));
      } else {
        yield put(actions.CREATE_PAYMENT.failure(data.data));
      }
    } catch (error) {
      yield put(actions.CREATE_PAYMENT.failure(error?.data));
    }
  },
  *[TYPE.TRANSACTION_PAY.request]({ payload }) {
    const params = getJsonFromUrl(payload.url.split("?")[1]);
    try {
      const data = yield call(api[TYPE.TRANSACTION_PAY.request], {
        orderCode: params.rderCode,
        signature: payload.url.split("&Signature=")[1],
        timestamp: params.TimeStick,
        amount: params.Amount
      });
      if (data.status === 200) {
        yield put(actions.TRANSACTION_PAY.success(data.data));
      } else {
        yield put(actions.TRANSACTION_PAY.failure(data?.data));
      }
    } catch (error) {
      yield put(actions.TRANSACTION_PAY.failure(error?.data));
    }
  },
  *[TYPE.GET_LIST_COUPON_MY_VOUCHER.request](action) {
    try {
      const data = yield call(
        api[TYPE.GET_LIST_COUPON_MY_VOUCHER.request],
        action.payload
      );

      if (data.status === 200) {
        yield put(actions.GET_LIST_COUPON_MY_VOUCHER.success(data.data));
      } else {
        yield put(actions.GET_LIST_COUPON_MY_VOUCHER.failure(data?.data));
      }
    } catch (error) {
      yield put(actions.GET_LIST_COUPON_MY_VOUCHER.failure(error?.data));
    }
  },
};

function* watchedSaga() {
  yield takeLatest(
    TYPE.CREATE_PAYMENT.request,
    sagas[TYPE.CREATE_PAYMENT.request]
  );
  yield takeLatest(
    TYPE.TRANSACTION_PAY.request,
    sagas[TYPE.TRANSACTION_PAY.request]
  );
  yield takeLatest(
    TYPE.GET_LIST_COUPON_MY_VOUCHER.request,
    sagas[TYPE.GET_LIST_COUPON_MY_VOUCHER.request]
  );
}

export default function* rootChild() {
  yield fork(watchedSaga);
}
