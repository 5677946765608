import {
  GET_OTP_REQUEST,
  GET_OTP_SUCCUESS,
  GET_OTP_FAILD,
  SEND_OTP_REQUEST,
  SEND_OTP_SUCCUESS,
  SEND_OTP_FAILED,
  GET_USER_INFO,
  GET_USER_INFO_SUCCUESS,
  GET_USER_INFO_FAILED,
} from './constants';

export const getOtpRequestHandle = (data, onSuccess, onError) => ({
  type: GET_OTP_REQUEST,
  data,
  onSuccess,
  onError,
});
export const getOtpRequestSuccess = result => ({
  type: GET_OTP_SUCCUESS,
  result,
});
export const getOtpRequestFaild = error => ({
  type: GET_OTP_FAILD,
  error,
});
export const sendOtpRequestHandle = (data, onSuccess, onError) => ({
  type: SEND_OTP_REQUEST,
  data,
  onSuccess,
  onError,
});
export const sendOtpRequestSuccess = (result = {}) => ({
  type: SEND_OTP_SUCCUESS,
  result,
});
export const sendOtpRequestFaild = (error = {}) => ({
  type: SEND_OTP_FAILED,
  error,
});

export const getUserInfo = (data, onSuccess, onError) => ({
  type: GET_USER_INFO,
  data,
  onSuccess,
  onError,
});
export const getUserInfoSuccess = (result = {}) => ({
  type: GET_USER_INFO_SUCCUESS,
  result,
});
export const getUserInfoFaild = (error = {}) => ({
  type: GET_USER_INFO_FAILED,
  error,
});
