/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from "react";
import classNames from "classnames";
import Images from "constants/Images";
import { toVND } from "utils/currencyFormat";
import { FOOD_ITEM_TYPES } from "constants/types";
import Skeleton from "react-loading-skeleton";
import iconPlus from "assets/icons/iconPlus.png";
import { icons } from "assets/icons/index";
import "./foodItem.scss";

const FoodItem = (props) => {
  const {
    type = FOOD_ITEM_TYPES.DETAILS /* simple or detail */,
    id,
    code,
    title,
    currentQuantityInCart = 0,
    imgSrc,
    shortDescription,
    description,
    originalPrice,
    currentPrice,
    handleOpenMenuOrder,
    handleOpenQuantityMenuPopup,
    navigateToSmartOrderMenu,
    category,
    isSelling,
    isOutOfStock,
    isStoreClosed = false,
    saleTagUrl,
    percentDiscount,
    ...rest
  } = props;

  const [totalQty, setTotalQty] = useState(0);
  const [dataCartOfProduct, setDataCartOfProduct] = useState([]);

  useEffect(() => {
    let total = 0;
    const dataCartOfProductTemp = [];
    if (props.dataCart?.length > 0) {
      props.dataCart.forEach((element) => {
        let isPass = false;
        if (element?.dataInfoProduct?.productId === props?.data?.id) {
          total += element?.dataInfoProduct?.quantity;
          isPass = true;
          dataCartOfProductTemp.push(element);
        }
        if (!isPass && props?.sizes) {
          const arrSizes = props?.data?.sizes;
          for (let index = 0; index < arrSizes.length; index++) {
            const elmSize = arrSizes[index];
            if (elmSize.Code === element?.dataInfoProduct?.productCode) {
              total += element?.dataInfoProduct?.quantity;
              isPass = true;
              dataCartOfProductTemp.push(element);
              break;
            }
          }
        }
      });
    }
    setDataCartOfProduct(dataCartOfProductTemp);
    setTotalQty(total);
  }, [props.dataCart]);

  const handleClickItem = () => {
    if (navigateToSmartOrderMenu) {
      return navigateToSmartOrderMenu();
    }

    // if (isOutOfStock || isStoreClosed || !isSelling) {
    //   return;
    // }

    if (currentQuantityInCart > 0) {
      if (handleOpenQuantityMenuPopup) {
        return handleOpenQuantityMenuPopup(id)();
      }
    }

    if (handleOpenMenuOrder) {
      return handleOpenMenuOrder(id, totalQty, dataCartOfProduct)();
    }
  };

  return (
    <div
      {...rest}
      onClick={handleClickItem}
      className="position-relative d-flex flex-column store-detail__food-item__wrapper-market"
    >
      {(isOutOfStock || isStoreClosed || !isSelling) && (
        <div
          className="position-absolute top-0 start-0 end-0 bottom-0 bgr-white"
          style={{
            opacity: 0.6,
            zIndex: 2,
          }}
        />
      )}

      <div className="food-image__wrapper">
        <object
          data={imgSrc || Images.foodPlaceholder}
          type="image/png"
          className="food-image"
        >
          <img src={Images.foodPlaceholder} alt="" className="food-image" />
        </object>

        {percentDiscount > 0 && (
          <>
            <img
              className={classNames(
                "sale-img",
                (isOutOfStock || isStoreClosed || !isSelling) && "item-disabled"
              )}
              src={icons.iconFlag}
            />
            <div
              className={classNames(
                "text-truncate _fs-13px _fw-600",
                "txt-sale",
                (isOutOfStock || isStoreClosed || !isSelling) && "item-disabled"
              )}
            >
              <span className="color-black">-{percentDiscount}%</span>
            </div>
          </>
        )}

        <div>
          {!!saleTagUrl && <img src={saleTagUrl} className="icon-saleTagUrl" />}
          <img src={iconPlus} className="icon-plus" />
        </div>
      </div>

      <div>
        <div
          className={classNames(
            "text-truncate _fs-13px _fw-600",
            totalQty > 0 && "_fw-700"
          )}
        >
          {totalQty > 0 && (
            <span style={{ color: "rgb(250,100,0)" }}>{totalQty}x </span>
          )}
          <span className="color-black">{title}</span>
        </div>
        {!props.hideStore && (
          <div
            className="food-category text-on-1-line"
            style={{
              background: "#fee196",
              paddingInline: "5px",
              borderRadius: 5,
              color: "#000",
              fontWeight: "600",
              fontSize: "12px",
              display: "inline-block",
              maxWidth: "100%",
            }}
          >
            {category?.name?.vi}
          </div>
        )}
        <div
          className={classNames(
            "_fs-12px _fw-400 text-on-1-line",
            isOutOfStock && "color-danger",
            !isSelling && "color-danger"
          )}
        >
          {!isSelling
            ? "Ngoài giờ bán"
            : isOutOfStock
            ? "Hết món"
            : type === FOOD_ITEM_TYPES.DETAILS && description}
        </div>

        <div
          className={classNames(
            type === FOOD_ITEM_TYPES.DETAILS &&
              "d-flex flex-wrap align-items-baseline"
          )}
        >
          <div>
            <span className="_fw-600 _fs-15px color-orange mr-2">
              {toVND(currentPrice)}
            </span>
          </div>

          {originalPrice && originalPrice !== 0 ? (
            <div>
              <span className="_fw-500 _fs-12px color-silver_chalice strike-low">
                {toVND(originalPrice)}
              </span>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default React.memo(FoodItem);

export const FoodItemSkeleton = ({ ...rest }) => {
  return (
    <div
      className="position-relative d-flex flex-column store-detail__food-item__wrapper"
      {...rest}
    >
      <div className="food-image__wrapper">
        {/* <img src={imgSrc || Images.foodPlaceholder} alt="" /> */}
        <Skeleton className="food-image" />
      </div>

      <div>
        <div className={classNames("text-truncate _fs-13px _fw-600")}>
          <span className="color-black">
            {/* {title} */}
            <Skeleton width="15ch" />
          </span>
        </div>

        <div className={classNames("d-flex flex-wrap align-items-baseline")}>
          <div>
            <span className="_fw-600 _fs-15px color-orange mr-2">
              {/* {toVND(currentPrice)} */}
              <Skeleton width="7ch" />
            </span>
          </div>

          <div>
            <span className="_fw-500 _fs-12px color-silver_chalice">
              {/* {toVND(14000)} */}
              <Skeleton width="7ch" />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
