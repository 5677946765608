/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch } from "react-redux";
import AppConfigs from "configs/env";
import Skeleton from "react-loading-skeleton";

import "./Comment.scss";
import Popup from "views/common/component/Popup";
import { LoadingOverlay } from "views/common/component";
import {
  getListUserLike,
  createOrderComment,
} from "@shared/redux/orderComment/actions";
import IMAGES from "assets/img";
import SVG from "assets/svg";
import RatingStoreView from "views/pages/Market/MarketHomeView/components/Rating/components/RatingStoreView/RatingStoreView";
import { COMMENT_TYPES_OF_RATING_FEATURE } from "constants/types";
import DetailRatingPost from "./components/DetailRatingPost";
import ItemComment from "./components/ItemComment/ItemComment";

const CommentView = (props) => {
  const TAKE = 10;
  const [chosenRatingPost, setChosenRatingPost] = useState();
  const [showUserLikedList, setShowUserLikedList] = useState({});
  const initData = {
    listLike: [],
    skip: 0,
    loading: false,
  };
  const [userLikedData, setUserLikedData] = useState(initData);

  const dispatch = useDispatch();

  const handleShowDetailRatingPost = useCallback(
    (ratingPost) => () => {
      if (ratingPost) {
        setChosenRatingPost(ratingPost);
        props.openDetailRatting();
      }
    },
    [props.data]
  );

  const handleCloseDetailRatingPost = useCallback(() => {
    props.setData(chosenRatingPost);
    setChosenRatingPost(null);
    props.closeDetailRatting();
  }, [chosenRatingPost]);

  const handleCloseUserLikedList = () => {
    setShowUserLikedList({});
    setUserLikedData({
      listLike: [],
      skip: 0,
      loading: false,
    });
  };

  const handleOpenUserLikedList =
    ({ id, type, skip = 0 }) =>
    () => {
      const _isRating = type === COMMENT_TYPES_OF_RATING_FEATURE.RATING_POST;
      getListLiker(_isRating, id);
      setShowUserLikedList({ id, type });
    };

  const onLoadMore = (id, type, skip = 0) => {
    if (userLikedData.skip > 0 && userLikedData.skip % TAKE === 0) {
      const _isRating = type === COMMENT_TYPES_OF_RATING_FEATURE.RATING_POST;
      console.log("_isRating", _isRating, id);
      getListLiker(_isRating, id, true);
    }
  };

  const getListLiker = (_isRating, _id, isLoadMore = false) => {
    try {
      const dataSend = {
        isRating: _isRating,
        id: _id,
        skip: userLikedData.skip,
        onSuccess: isLoadMore ? onSuccessLoadMore : onSuccess,
        onError,
      };
      dispatch(getListUserLike(dataSend));
    } catch (error) {
      console.log("error", error);
    }
  };

  const onSuccessLoadMore = (rp) => {
    console.log("onLoadmore", rp);
    const temp = [...userLikedData.listLike, ...rp.data];

    setUserLikedData({ listLike: temp, loading: false, skip: temp.length });
  };

  const onSuccess = (rp) => {
    console.log("onSuccess: ", rp);
    const temp = [...rp.data];

    setUserLikedData({ listLike: temp, loading: false, skip: temp.length });
  };

  const onError = (rp) => {
    setUserLikedData({ ...userLikedData, loading: false });
    console.log("onError: ", rp);
  };

  const SeeMore = useCallback(() => {
    return (
      <>
        <div className="rating-content__see-more" onClick={props.gotoViewAll}>
          <p>Xem thêm</p>
          <img src={SVG.ArrowRightYellow} alt="arrow" />
        </div>
      </>
    );
  }, [props.gotoViewAll]);

  const sendComment = useCallback(({ idRatingPost, content }) => {
    const dataSend = {
      featureId: AppConfigs.idFeatureCommentOrder,
      sourceId: idRatingPost,
      content,
      onSuccess: onSuccessSend,
      onError: onErrorSend,
    };
    dispatch(createOrderComment(dataSend));
  }, []);

  const onSuccessSend = (rp) => {
    setChosenRatingPost((prvTempRating) => {
      const tempRating = { ...prvTempRating };
      const tempComment = { ...rp.data };
      tempComment.like = 0;
      tempComment.isLike = false;

      tempRating.ratingReplies = [...tempRating.ratingReplies, tempComment];
      tempRating.countComment += 1;
      return tempRating;
    });
  };

  const onErrorSend = (rp) => {
    console.log("onErrorSendCM: ", rp);
    // if (!rp.status) {
    //   refToast?.current?.show(strings('FnBRating.unknownError'), 3000);
    // } else {
    //   refToast?.current?.show(strings('FnBRating.invalidComment'), 3000);
    // }
  };

  const renderLoaderOfUserLiked = useCallback(() => {
    if (!(userLikedData.loading && userLikedData.skip === 0)) {
      return (
        <div className="people-liked-list">
          <div className="row-info">
            <Skeleton className="row-info__avatar" circle />
            <span className="row-info__name">
              <Skeleton width="23ch" />
            </span>
          </div>

          <div className="row-info">
            <Skeleton className="row-info__avatar" circle />
            <span className="row-info__name">
              <Skeleton width="23ch" />
            </span>
          </div>
        </div>
      );
    }
  }, [userLikedData]);

  return (
    <div>
      <RatingStoreView
        ratingData={props.data}
        handleSeeAllRating={props.gotoViewAll}
      />
      <div className="ratting-wrapper">
        {Object.keys(props.data).length > 0
          ? props.data?.ratings.slice(0, 2).map((item) => (
              <ItemComment
                rating={item}
                // handleUnlikeCmt={handleUnlikeCmt}
                handleLikeCmt={props.likeRating}
                handleOpenUserLikedList={handleOpenUserLikedList}
                handleLikeSubCmt={props.likeReply}
                // handleUnLikeSubCmt={handleUnLikeSubCmt}
                handleSeeAllRating={props.gotoViewAll}
                handleClickToRepliesButton={handleShowDetailRatingPost(item)}
              />
            ))
          : null}
        {props.data?.ratings?.length >= 2 && <SeeMore />}
      </div>
      <DetailRatingPost
        show={!!chosenRatingPost}
        handleClose={handleCloseDetailRatingPost}
        ratingPost={chosenRatingPost}
        // handleUnlikeCmt={() => {}}
        handleLikeCmt={props.likeRating}
        handleOpenUserLikedList={handleOpenUserLikedList}
        handleLikeSubCmt={props.likeReply}
        handleUnLikeSubCmt={() => {}}
        handleSeeAllRating={props.gotoViewAll}
        handleSubmitReply={sendComment}
      />
      <Popup
        isOpen={Object.keys(showUserLikedList).length > 0}
        handleClose={handleCloseUserLikedList}
        title={
          <div className="text-center">
            <span className="color-ucla_gold _fs-15px _fw-600">
              Danh sách lượt thích
            </span>
          </div>
        }
      >
        {/* <LoadingOverlay
          isLoading={
            (isLoading.GET_USER_LIKED_LIST_OF_RATING_POST ||
              isLoading.GET_USER_LIKED_LIST_OF_COMMENT) &&
            likedListParams.skip === 0
          }
        /> */}
        {Object.keys(showUserLikedList).length > 0 ? (
          <div
            className="people-liked-list__container"
            id="people-liked-list-popup"
          >
            <InfiniteScroll
              dataLength={userLikedData?.listLike.length}
              next={onLoadMore(showUserLikedList?.id, showUserLikedList?.type)}
              hasMore={userLikedData.loading}
              loader={renderLoaderOfUserLiked()}
              scrollableTarget="people-liked-list-popup"
            >
              <div className="people-liked-list">
                {userLikedData.listLike.map((userLiked) => (
                  <div className="row-info" key={userLiked.id}>
                    <object
                      className="row-info__avatar"
                      data={userLiked.avatarUrl || IMAGES.AvatarPlaceholder}
                      type="image/png"
                    >
                      <img
                        className="row-info__avatar"
                        src={IMAGES.AvatarPlaceholder}
                        alt="ava"
                      />
                    </object>
                    <span className="row-info__name">{userLiked.name}</span>
                  </div>
                ))}
              </div>
            </InfiniteScroll>
          </div>
        ) : null}
      </Popup>
    </div>
  );
};

export default React.memo(CommentView);
