import { useCallback, useEffect, useRef } from "react";

const useFreezeScroll = () => {
  const overflowRef = useRef();
  // const positionRef = useRef();

  useEffect(() => {
    overflowRef.current = document.body.style.overflow;
    // // positionRef.current = document.body.style.position;
  }, []);

  const freezeBodyScroll = useCallback(() => {
    overflowRef.current = document.body.style.overflow;
    // // positionRef.current = document.body.style.position;

    document.body.style.overflow = "hidden";
    // document.body.style.position = "fixed";
    // document.body.style.top = `-${window.scrollY}px`;
  }, []);

  const releaseBodyScroll = useCallback(() => {
    document.body.style.overflow = overflowRef.current;
    // // document.body.style.position = positionRef.current;
    // const scrollY = document.body.style.top;
    // window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
  }, []);

  return [freezeBodyScroll, releaseBodyScroll];
};

export default useFreezeScroll;
