/* eslint-disable no-empty */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
// import UTopQRPayResult from './UTopQRPayResult.view';
// react native
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import {
  getMarketInfoRequest,
  getListFnbTypeRequest,
  getListMediaHomeRequest,
  getListSuggestProductRequest,
  getListStoreMerchantRequest,
  getListFnbBestSeller,
} from "@shared/redux/market/marketHome/actions";
import { getLatLongSelector } from "@shared/redux/gps/selectors";
import { getStoreInfoByIdHandle } from "@shared/redux/store/actions";
// react native
// import {
//   ReactUtils,
//   insightTrackEvent,
// } from "utils";
import { NavigationServices, AlertMsg } from "utils";
import {
  getOpenTime,
  checkGuestPopup,
  checkGuest,
  updateProductCart as updateProductsCart,
} from "@shared/utils";
import {
  getDataStore,
  getListProduct,
  getListCouponSuggest,
} from "@shared/redux/smartMenu/selectors";
import {
  setDataStore,
  setIndexProductCart,
  setListCouponSuggestForStore,
  updateListCouponSuggestForStore,
  updateProductCart,
  addProductCart,
  updateListProductCart,
  deleteProductCart,
  resetProductCart,
} from "@shared/redux/smartMenu/actions";
import methodDelivery from "constants/methodDelivery";
import {
  setDeliveryAddress,
  setDeliveryAddressSelected,
} from "@shared/redux/cart/actions";
import { getListCouponSuggestHandle } from "@shared/redux/storeDetail/actions";
// react native
import { getAvailableCouponHandle } from "@shared/redux/couponForVoucher/actions";
import { getProductChangedHandle } from "@shared/redux/OrderStatus/actions";
import MarketHome from "./MarketHomeView.view";

const MarketHomeView = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingBanner, setIsLoadingBanner] = useState(true);
  const [storeDetail, setStoreDetail] = useState({});
  const [listItemCategory, setListItemCategory] = useState([]);
  const [dataBanner, setDataBanner] = useState([]);
  const [openTime, setOpenTime] = useState({});
  const [showSeeMore, setShowSeeMore] = useState(false);
  const [isReload, setIsReload] = useState(false);
  const [isShowSuggestCoupon, setIsShowSuggestCoupon] = useState(false);
  const [isProductChange, setIsProductChange] = useState(false);
  const [dataProductRecent, setDataProductRecent] = useState([]);
  const [dataListStore, setDataListStore] = useState([]);
  const [dataProduct, setDataProduct] = useState([]);
  const [alertAction, setAlertAction] = useState({});
  const [starRating, setStarRating] = useState(null);

  const { storeId, merchantId, oldDeliveryAddress, dataReOrder, isFrom } =
    NavigationServices.getParams(props);
  const { prevPathname, statePath } = NavigationServices.getCurrentRoute(props);
  const { userInfo } = useSelector((state) => state.root);

  useEffect(() => {
    // react native
    fetchData();
    const unsubscribeFocus = () => {
      if (isReload) {
        props.getMarketInfoRequest(
          storeId,
          (res) => {
            if (res.id) {
              setStoreDetail(res);
              const openTimes = res.openingTimes ? res.openingTimes : {};
              // react native
              setOpenTime(getOpenTime(openTimes));
              setIsLoading(false);
              let ListMethodDelivery = [];
              if (
                res.deliveryConfigs !== null &&
                res.deliveryConfigs?.length > 0
              ) {
                ListMethodDelivery = createListMethodDelivery(
                  res.deliveryOptions,
                  res.deliveryConfigs
                );
              } else {
                ListMethodDelivery = createListMethodDelivery(
                  res.deliveryOptions,
                  []
                );
              }

              let isExistMethodDelivery = false;
              // react native
              if (oldDeliveryAddress) {
                props.setDeliveryAddressSelected(oldDeliveryAddress || null);

                ListMethodDelivery.forEach((itemMethodDelivery) => {
                  if (
                    // react native
                    oldDeliveryAddress.code === itemMethodDelivery.code
                  ) {
                    // eslint-disable-next-line no-param-reassign
                    itemMethodDelivery.isCheck = true;
                    isExistMethodDelivery = true;
                  }
                });
              }

              if (!isExistMethodDelivery) {
                ListMethodDelivery[0].isCheck = true;
              }
              props.setDeliveryAddress(ListMethodDelivery);
            }
          },
          // eslint-disable-next-line no-unused-vars
          (_err) => {}
        );
      }
    };
    return () => {
      // unsubscribeFocus();
    };
  }, []); // react native

  // const backClicked = () => {
  //   // // react native
  //   NavigationServices.goBack();
  // };
  const refModal = useRef();
  const createListMethodDelivery = (arr = [], deliveryConfigs) => {
    const listMethodDelivery = [];
    // eslint-disable-next-line array-callback-return
    arr.map((ite) => {
      let name = "";
      switch (ite.toLowerCase()) {
        case methodDelivery.takeaway:
          name = "Nhận món mang đi";
          break;
        case methodDelivery.table:
          name = "Nhận món tại bàn";
          break;
        case methodDelivery.shop:
          name = "ĐẶT MÓN";
          break;
        case methodDelivery.home:
          name = "GIAO VỀ";
          break;
        default:
          name = "GIAO VỀ";
          break;
      }
      const conf = deliveryConfigs.filter(
        (config) => config.type.toLowerCase() === ite.toLowerCase()
      );
      listMethodDelivery.push({
        code: ite.toLowerCase(),
        isCheck: false,
        name: conf.length > 0 ? conf[0]?.label : name,
        icon: conf.length > 0 ? conf[0]?.icon : "",
      });
    });
    return listMethodDelivery;
  };
  const fetchData = () => {
    const p1 = new Promise((resolve, reject) => {
      props.getMarketInfoRequest(
        // react native
        storeId,
        (res) => {
          resolve({ ...res, type: "MARKET_INFO" });
          if (res.id) {
            setStoreDetail(res);
            const openTimes = res.openingTimes ? res.openingTimes : {};
            // react native
            setOpenTime(getOpenTime(openTimes));
            setIsLoading(false);
            let ListMethodDelivery = [];
            if (
              res.deliveryConfigs !== null &&
              res.deliveryConfigs?.length > 0
            ) {
              ListMethodDelivery = createListMethodDelivery(
                res.deliveryOptions,
                res.deliveryConfigs
              );
            } else {
              ListMethodDelivery = createListMethodDelivery(
                res.deliveryOptions,
                []
              );
            }

            let isExistMethodDelivery = false;
            // react native
            if (oldDeliveryAddress) {
              props.setDeliveryAddressSelected(oldDeliveryAddress || null);

              ListMethodDelivery.forEach((itemMethodDelivery) => {
                if (
                  // react native
                  oldDeliveryAddress.code === itemMethodDelivery.code
                ) {
                  // eslint-disable-next-line no-param-reassign
                  itemMethodDelivery.isCheck = true;
                  isExistMethodDelivery = true;
                }
              });
            }

            if (!isExistMethodDelivery) {
              ListMethodDelivery[0].isCheck = true;
            }

            props.setDeliveryAddress(ListMethodDelivery);
            // const dataTrackingAzure = {
            //   MerchantId: res?.merchantId,
            //   MerchantName: res?.merchantName,
            //   StoreId: res?.id,
            //   StoreName: res?.name,
            // };
            // react native
            // insightTrackEvent("Market_Detail", dataTrackingAzure);
          }
        },
        (err) => {
          reject(err);
          console.log("error123", err);
        }
      );
    });

    const p2 = new Promise((resolve, reject) => {
      props.getListMediaHomeRequest(
        // react native
        storeId,
        (res) => {
          resolve({ ...res, type: "MEDIA" });
          if (res.data?.length > 0) {
            setShowSeeMore(true);
          }
        },
        (err) => {
          reject(err);
        }
      );
    });
    const p3 = new Promise((resolve, reject) => {
      props.getListFnbTypeRequest(
        // react native
        merchantId,
        (res) => {
          resolve({ ...res, type: "FNB" });
          setListItemCategory(res.data);
        },
        (err) => {
          reject(err);
        }
      );
    });

    let p4 = "";
    if (
      // react native
      dataReOrder &&
      dataReOrder.length > 0
    ) {
      p4 = new Promise((resolve, reject) => {
        // react native
        // eslint-disable-next-line no-shadow
        const dataReOrders = dataReOrder;
        const orderCode = dataReOrders[0]?.orderCode;
        props.getProductChangedHandle(
          orderCode,
          (res) => {
            if (res.status === 200) {
              resolve({ ...res, type: "REORDER" });
            }
          },
          (err) => {
            console.log("err", err);
            reject(err);
          }
        );
      });
    }

    Promise.all([p1, p2, p3, p4])
      .then((values) => {
        // setIsReload(true);
        setIsLoadingBanner(false);
        const media = values.filter((item) => item.type === "MEDIA");
        const marketInfo = values.filter((item) => item.type === "MARKET_INFO");
        const reOrder = values.filter((item) => item.type === "REORDER");
        if (media.length > 0 && media[0].data.length > 0) {
          setDataBanner(media[0].data);
        } else {
          setShowSeeMore(false);
          if (marketInfo.length > 0 && marketInfo[0].imagesMerchant) {
            setDataBanner(marketInfo[0].imagesMerchant);
          } else {
            setDataBanner([]);
          }
        }
        if (
          marketInfo.length > 0 &&
          // react native
          dataReOrder &&
          dataReOrder.length > 0 &&
          !!reOrder &&
          props.router.history.action === "PUSH"
        ) {
          marketInfo[0].storeId = marketInfo[0].id;
          props.setDataStore(marketInfo[0]);

          if (
            reOrder.length > 0 &&
            props.router.history.location.state?.prevPathname ===
              "/deliveryinfomations"
          ) {
            updateCartReOrder(
              // react native
              dataReOrder,
              reOrder[0]?.data || []
            );
          } else {
            setAlertAction({
              isShow: true,
              title: "Thông báo đơn hàng",
              content:
                "Một vài sản phẩm bạn đặt đã bị thay đổi hoặc không còn phục vụ!",
              titleAccept: "Đóng",
              dataItem: {
                key: "basic",
              },
            });
            setIsProductChange(true);
          }
        }
      })
      // eslint-disable-next-line no-unused-vars
      .catch((error) => {
        try {
          if (error?.response?.data?.error?.code === "StoreIsNotFound") {
            // react native
            if (statePath === undefined) {
              setAlertAction({
                isShow: true,
                title: "Utop",
                content:
                  "Cửa hàng hiện tại đang tạm ngưng hoạt động. Vui lòng quay lại sau.",
                titleAccept: "Về trang chủ",
                dataItem: {
                  key: "draft",
                },
              });
            } else if (prevPathname === "/deliveryinfomations") {
              setAlertAction({
                isShow: true,
                title: "Utop",
                content: "Cửa hàng tạm ngưng hoạt động. Vui lòng quay lại sau.",
                titleAccept: "OK",
                dataItem: {
                  key: "draft",
                },
              });
            } else if (prevPathname === "/") {
              setAlertAction({
                isShow: true,
                title: "Utop",
                content:
                  "Cửa hàng hiện tại đang tạm ngưng hoạt động. Vui lòng quay lại sau.",
                titleAccept: "Quay lại",
                dataItem: {
                  key: "draft",
                },
              });
            } else {
              setAlertAction({
                isShow: true,
                title: "Utop",
                content:
                  "Cửa hàng hiện tại đang tạm ngưng hoạt động. Vui lòng quay lại sau.",
                titleAccept: "Quay lại",
                titleCancel: "Về trang chủ",
                dataItem: {
                  key: "draft",
                },
              });
            }
          } else if (error?.response?.data?.code === "NETWORK_REQUEST_FAILED") {
            // react native
            setAlertAction({
              isShow: true,
              title: "Utop",
              content:
                "Đã có lỗi xảy ra trong quá trình kết nối với máy chủ. Vui lòng kiểm tra kết nối mạng và thử lại sau.",
              titleAccept:
                statePath === undefined ? "Về trang chủ" : "Quay lại",
              dataItem: {
                key: "draft",
              },
            });
          } else {
            // react native
            setAlertAction({
              isShow: true,
              title: "Utop",
              content: "Kết nối bị gián đoạn, vui lòng thử lại.",
              titleAccept:
                statePath === undefined ? "Về trang chủ" : "Quay lại",
              dataItem: {
                key: "draft",
              },
            });
          }
          // eslint-disable-next-line no-shadow
        } catch (error) {
          // react native
          setAlertAction({
            isShow: true,
            title: "Utop",
            content: "Kết nối bị gián đoạn, vui lòng thử lại.",
            titleAccept: statePath === undefined ? "Về trang chủ" : "Quay lại",
            dataItem: {
              key: "draft",
            },
          });
        }
      });
    props.getListCouponSuggestHandle(
      {
        // react native
        storeId,
        merchantId,
        feature: "orderfnb",
      },
      (listCouponSuggest) => {
        // console.log("listCouponSuggestlistCouponSuggest", listCouponSuggest);
        if (
          listCouponSuggest.status === 200 &&
          listCouponSuggest.data.suggestedCouponDetails
        ) {
          props.setListCouponSuggestForStore(
            listCouponSuggest.data.suggestedCouponDetails
          );
        }
      },
      () => {}
    );
    if (userInfo) {
      props.getListSuggestProductRequest(
        {
          storeId,
          merchantId,
        },
        (res) => {
          // console.log("getListSuggestProductRequest", res);
          setDataProductRecent(res.data);
        },
        (error) => {
          console.log("getListSuggestProductRequest error", error);
        }
      );
    }
    props.getListStoreMerchantRequest(
      {
        merchantId,
        skip: 0,
        take: 6,
      },
      (res) => {
        // console.log("getListStoreMerchantRequest", res);
        setDataListStore(res.data);
      },
      (error) => {
        console.log("getListStoreMerchantRequest error", error);
      }
    );
    props.getListFnbBestSeller(
      {
        storeId,
        merchantId,
      },
      (res) => {
        if (res.data && res.data?.length > 0) {
          if (res.data.length % 2 === 0) {
            setDataProduct(res.data);
          } else {
            setDataProduct([...res.data, { id: null }]);
          }
        }
      },
      (error) => {
        console.log("getListFnbBestSeller error", error);
      }
    );
  };

  useEffect(() => {
    if (
      (JSON.stringify(props.getDataStore) !== "{}" &&
        storeDetail?.id === props.getDataStore.storeId) ||
      dataReOrder // react native
    ) {
      props.setIndexProductCart();
    }
  }, [props.getDataCart, props.getDataStore]);

  const onSubmit = () => {
    // react native
    checkGuest((isGuest) => {
      if (isGuest) {
        // react native
        checkGuestPopup();
      } else {
        storeDetail.storeId = storeDetail.id;
        props.setDataStore(storeDetail);
        props.setIndexProductCart();
        // react native
        NavigationServices.push("Cart", {
          isFromMarket: true,
        });
      }
    });
  };
  const allocateCoupon = (item) => {
    // react native
    props.getAvailableCouponHandle(
      item.code,
      (res) => {
        if (res.data.coupons.length > 0) {
          // eslint-disable-next-line no-param-reassign
          item.transactionCode = res.data.coupons[0].transactionCode;
          props.updateListCouponSuggestForStore(item);
        } else {
          // error message
          try {
            const serverErrorMsg = res.data.message;
            // react native
            setAlertAction({
              isShow: true,
              title: "Utop",
              content:
                serverErrorMsg || "Hệ thống đang xứ lý, vui lòng thử lại sau.",
              titleAccept: "OK",
              dataItem: {
                key: "basic",
              },
            });
          } catch (error) {}
        }
      },
      // eslint-disable-next-line no-unused-vars
      (err) => {
        // error message
        switch (err.response.data.message) {
          case "DuplicateTransaction":
            // react native
            setAlertAction({
              isShow: true,
              title: "Utop",
              content: "Hệ thống đang xứ lý, vui lòng thử lại sau.",
              titleAccept: "OK",
              dataItem: {
                key: "basic",
              },
            });
            break;
          case "OverMaxSet":
            // react native
            setAlertAction({
              isShow: true,
              title: "Utop",
              content: "Số lượng coupon của chương trình đã hết.",
              titleAccept: "OK",
              dataItem: {
                key: "basic",
              },
            });
            break;
          case "OverMaxSetPerUser":
            // react native
            setAlertAction({
              isShow: true,
              title: "Utop",
              content: "Bạn đã hết lượt nhận coupon.",
              titleAccept: "OK",
              dataItem: {
                key: "basic",
              },
            });
            break;
          case "NotFoundCouponCampaign":
            // react native
            setAlertAction({
              isShow: true,
              title: "Utop",
              content:
                "Mã ưu đãi không hợp lệ, vui lòng kiểm tra lại hoặc thử mã khác.",
              titleAccept: "OK",
              dataItem: {
                key: "basic",
              },
            });
            break;
          case "InvalidCouponCampaginPeriod":
            // react native
            setAlertAction({
              isShow: true,
              title: "Utop",
              content: "Đã hết thời gian nhận coupon.",
              titleAccept: "OK",
              dataItem: {
                key: "basic",
              },
            });
            break;
          default:
            // react native
            setAlertAction({
              isShow: true,
              title: "Utop",
              content: `Lỗi hệ thống, vui lòng thử lại sau.(${err.status})`,
              titleAccept: "OK",
              dataItem: {
                key: "basic",
              },
            });
            break;
        }
      }
    );
  };

  function uniqByKeepLast(a, key) {
    return [...new Map(a.map((x) => [key(x), x])).values()];
  }

  const updateCartReOrder = (dataReOrders, listProductChanged) => {
    const coOKListProduct = uniqByKeepLast(listProductChanged, (it) => it.id);

    dataReOrders.forEach((itemReOrder) => {
      coOKListProduct.forEach((itemListProduct) => {
        let quantity = 1;
        let productCode = "";
        let productId = "";
        let attributes = [];
        let toppings = [];
        let totalPrice = 0;
        const index = undefined;
        let sizeName = null;

        if (itemReOrder?.productId === itemListProduct?.id) {
          quantity = itemReOrder?.quantity;
          productCode = itemReOrder?.productCode;
          productId = itemReOrder?.productId;
          attributes = itemReOrder?.extraInfo?.attributes;
          toppings = itemReOrder?.extraInfo?.toppings;
          totalPrice = itemReOrder?.totalAmount;

          if (itemListProduct?.sizes?.length > 0) {
            itemListProduct?.sizes.forEach((itemSize) => {
              if (itemSize?.Code === itemReOrder?.productCode) {
                sizeName = `Size ${itemSize?.Name}`;
              }
            });
          }

          const listToppingTmp = [];
          if (toppings.length > 0) {
            if (itemListProduct.toppings.length > 0) {
              toppings.forEach((itemToppingReOrder) => {
                itemListProduct.toppings.forEach((itemTopping) => {
                  if (itemToppingReOrder?.code === itemTopping?.code) {
                    listToppingTmp.push(itemTopping);
                  }
                });
              });
            }
          }

          const itemCart = {
            data: itemListProduct,
            dataInfoProduct: {
              quantity,
              productCode,
              productId,
              attributes,
              toppings: listToppingTmp,
              totalPrice,
              index,
              sizeName,
            },
          };

          updateCart(itemCart);
        }
      });
    });

    if (dataReOrders.length > listProductChanged.length) {
      setIsProductChange(true);
      setAlertAction({
        isShow: true,
        title: "Thông báo đơn hàng",
        content:
          "Một vài sản phẩm bạn đặt đã bị thay đổi hoặc không còn phục vụ!",
        titleAccept: "Đóng",
        dataItem: {
          key: "basic",
        },
      });
    }
  };

  const updateCart = (itemCart) => {
    // const dataTrackingAzure = {
    //   MerchantId: storeDetail?.merchantId,
    //   MerchantName: storeDetail?.merchantName,
    //   StoreId: storeDetail?.id,
    //   StoreName: storeDetail?.name,
    //   Quantity: itemCart?.dataInfoProduct?.quantity,
    //   ProductCode: itemCart?.dataInfoProduct?.productCode,
    //   ProductId: itemCart?.dataInfoProduct?.productId,
    //   ProductName: itemCart?.data?.name?.vi,
    // };
    // react native
    // insightTrackEvent("Add_To_Cart", dataTrackingAzure);
    if (
      props?.getDataCart?.length > 0 &&
      JSON.stringify(props.getDataStore) !== "{}" &&
      storeDetail?.id !== props.getDataStore.storeId
    ) {
      setAlertAction({
        isShow: true,
        title: "Tạo giỏ hàng mới?",
        content:
          "Thêm sản phẩm này sẽ khiến các sản phẩm hiện tại trong giỏ hàng của bạn bị xoá. Bạn có muốn tiếp tục?",
        titleAccept: "Tiếp tục",
        titleCancel: "Đóng",
        dataItem: { ...itemCart },
      });
      // react native
      //   Alert.alert(
      //     strings("SmartOrderMenu.createNewCart"),
      //     strings("SmartOrderMenu.labelAddNewCart"),
      //     [
      //       {
      //         text: strings("common.labelClose"),
      //         style: "cancel",
      //       },
      //       {
      //         text: strings("Login.label5"),
      //         onPress: () => {
      //           storeDetail.storeId = storeDetail.id;
      //           props.setDataStore(storeDetail);
      //           props.resetProductCart({
      //             itemCart,
      //             isNotClearCoupon:
      //               location.state.isFrom === "ApplicableStore",
      //           });
      //         },
      //       },
      //     ],
      //     { cancelable: false }
      //   );
    } else {
      if (
        Object.keys(storeDetail).length > 0 &&
        (JSON.stringify(props.getDataStore) === "{}" ||
          storeDetail?.id !== props.getDataStore.storeId)
      ) {
        storeDetail.storeId = storeDetail.id;
        props.setDataStore(storeDetail);
      }
      updateProductsCart(props.getDataCart, itemCart, (type, payload) => {
        switch (type) {
          case "add":
            props.addProductCart(payload);
            break;

          case "update":
            props.updateListProductCart(payload);
            break;

          default:
            break;
        }
      });
    }
  };

  const onAcceptAlert = (item) => {
    if (item?.key === "draft") {
      if (statePath === undefined) {
        NavigationServices.push("/");
      } else {
        NavigationServices.goBack();
      }
      setAlertAction({});
    } else if (item?.key === "basic") {
      setAlertAction({});
    } else {
      storeDetail.storeId = storeDetail.id;
      props.setDataStore(storeDetail);
      props.resetProductCart({
        itemCart: item,
        isNotClearCoupon: isFrom === "ApplicableStore",
      });
      setAlertAction({});
    }
  };

  const onCloseAlert = (item) => {
    if (item?.key === "draft") {
      NavigationServices.navigate("/");
      setAlertAction({});
    } else {
      setAlertAction({});
    }
  };

  return (
    <MarketHome
      storeDetail={storeDetail}
      isLoading={isLoading}
      gps={props.gps}
      dataBanner={dataBanner}
      listItemCategory={listItemCategory}
      refModal={refModal}
      openingTimes={openTime}
      merchantId={merchantId} // react native
      showSeeMore={showSeeMore}
      dataCart={
        JSON.stringify(props?.getDataStore) !== "{}" &&
        storeDetail?.id === props?.getDataStore?.storeId
          ? props?.getDataCart
          : props?.route?.params?.dataReOrder || []
      }
      onSubmit={onSubmit}
      storeId={storeId} // react native
      storeInfo={storeDetail}
      isLoadingBanner={isLoadingBanner}
      listCouponSuggest={
        // eslint-disable-next-line no-nested-ternary
        props?.listCouponSuggest
          ? props.listCouponSuggest?.storeId === storeId && // react native
            props.listCouponSuggest?.merchantId === merchantId // react native
            ? props.listCouponSuggest
            : []
          : []
      }
      setIsShowSuggestCoupon={setIsShowSuggestCoupon}
      isShowSuggestCoupon={isShowSuggestCoupon}
      allocateCoupon={allocateCoupon}
      updateProductCart={props.updateProductCart}
      deleteProductCart={props.deleteProductCart}
      resetProductCart={props.resetProductCart}
      updateCart={updateCart}
      isProductChange={isProductChange}
      hideModal={setIsProductChange}
      dataProductRecent={dataProductRecent}
      dataListStore={dataListStore}
      dataProduct={dataProduct}
      dataAlertAction={alertAction}
      setDataAlertAction={() => setAlertAction({})}
      handleAlertAction={onAcceptAlert}
      handleClose={onCloseAlert}
      setStarRating={setStarRating}
      starRating={starRating}
    />
  );
};

const mapStateToProps = (state) => ({
  gps: getLatLongSelector(state),
  getDataStore: getDataStore(state),
  getDataCart: getListProduct(state),
  listCouponSuggest: getListCouponSuggest(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getMarketInfoRequest,
      getListFnbTypeRequest,
      getListMediaHomeRequest,
      getListSuggestProductRequest,
      getListStoreMerchantRequest,
      getListFnbBestSeller,
      getStoreInfoByIdHandle,
      setDataStore,
      setIndexProductCart,
      setDeliveryAddress,
      getListCouponSuggestHandle,
      setListCouponSuggestForStore,
      getAvailableCouponHandle,
      updateListCouponSuggestForStore,
      updateProductCart,
      addProductCart,
      updateListProductCart,
      deleteProductCart,
      resetProductCart,
      getProductChangedHandle,
      setDeliveryAddressSelected,
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(MarketHomeView);
