import storage from "redux-persist/lib/storage";

const setItem = async (key, value) => {
  try {
    const jsonValue = JSON.stringify(value);
    await localStorage.setItem(key, jsonValue);
  } catch (e) {
    // saving error
  }
};

const getItem = async (key, callback) => {
  try {
    const jsonValue = await localStorage.getItem(key);
    const result = jsonValue != null ? JSON.parse(jsonValue) : null;
    callback?.(null, result);
    return result;
  } catch (e) {
    // error reading value
    callback?.(e, null);
  }
};

const LocalStorage = {
  setItem,
  getItem,
  current: localStorage,
  persist: storage,
};

export default LocalStorage;
