export const convertWeight = (number) => {
  if (!number) {
    return `${0}g`;
  }

  if (number < 1000) {
    return `${number}g`;
  }

  const res = Number((number / 1000).toFixed(2)).toLocaleString("vi");

  return `${res}kg`;
};

// gặp case hiện 12.50 km khi fixed=2 -> expected: 12.5km
// export const toDistance = ({ distance, fixed = 2, localeCode = "vi" }) => {
//   if (!distance) return null;

//   const distanceAsKm = distance / 1000;
//   const res = Number(distanceAsKm.toFixed(fixed)).toLocaleString(localeCode);

//   return `${res}km`;
// };

// viết để dùng cho trường hợp digits truyền vào > 0
// nếu digits truyền vào = 0, dùng Math.round (như rule sau này (đc đơn giản hóa) ở FeedCard)
export const simplifyNumber = ({
  dividend, // so bi chia
  divisor, // so chia
  digits = 0,
  localeCode = "vi",
  endCharacter = "", // ki tu
}) => {
  // if (Number.isNaN(dividend) || Number.isNaN(divisor)) return;
  if (
    typeof dividend !== "number" ||
    typeof divisor !== "number" ||
    typeof digits !== "number"
  ) {
    return;
  }

  return `${(
    Math.round((dividend / divisor) * 10 ** digits) /
    10 ** digits
  ).toLocaleString(localeCode)}${endCharacter}`;
};
// export const simplifyNumber = ({
//   dividend, // so bi chia
//   divisor, // so chia
//   digits = 0,
//   localeCode = "vi",
//   endCharacter, // ki tu
// }) => {
//   if (Number.isNaN(dividend) || Number.isNaN(divisor)) return;

//   return `${Number(Number(dividend / divisor).toFixed(digits)).toLocaleString(
//     localeCode
//   )}${endCharacter}`;
// };
