import {END_POINTS} from '@shared/constants/apiUrl';
import {APIUtils} from 'utils';

export function getMyVoucherDetailApi(requestData) {
  let url = END_POINTS.GET_VOUCHER_DETAIL_URL + requestData;
  return APIUtils.get(url, {method: 'GET'});
}

export function getVoucherDetailByUserApi({
  productCode,
  transactionCode,
  status,
}) {
  const params = {
    productCode: productCode,
    transactionCode: transactionCode,
    status: 'Redeem',
  };
  return APIUtils.get(END_POINTS.GET_MY_VOUCHER_BY_USER_URL, {
    method: 'GET',
    params,
  });
}
