import {GET_OTP_SUCCUESS, SEND_OTP_SUCCUESS} from './constants';

const initialState = {
  utopPoint: 0,
  userInfo: {},
  sessionId: '',
  memberCode: '',
};

const loginReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_OTP_SUCCUESS: {
      return {
        ...state,
      };
    }
    case SEND_OTP_SUCCUESS: {
      return {
        ...state,
      };
    }
    default:
      return state;
  }
};

export default loginReducer;
