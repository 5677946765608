import { requestWithoutToken } from "services/request";
import { encodeQueryDataV2 } from "utils/stringFormat";
import * as TYPE from "../store/constants";

const api = {
  // * GET_LIST_FEED
  [TYPE.GET_LIST_FEED.request]: async (params) => {
    const query = encodeQueryDataV2({
      couponId: params.couponId,
      latitude: params.lat,
      longitude: params.long,
      skip: params.skip,
      take: params.take,
    });

    return requestWithoutToken(`/home/Feed/FilterByCoupon${query}`, "GET");
  },

  // * GET_LIST_STORE_OF_MERCHANT
  [TYPE.GET_LIST_STORE_OF_MERCHANT.request]: async (params) => {
    const query = encodeQueryDataV2({
      couponId: params.couponId,
      merchantId: params.merchantId,
      feedTypes: params.feedTypes, // can be an Array when use encodeQueryDataV2
      latitude: params.latitude,
      longitude: params.longitude,
      skip: params.skip,
      take: params.take,
    });

    return requestWithoutToken(`/home/Feed/FilterByCoupon${query}`, "GET");
  },
};

export default api;
