import { LOGOUT_REQUEST } from "@shared/redux/account/constants";
import { LOGIN } from "./constants";

const initialState = {
  memberCode: "",
  accessToken: "",
  schoolId: "",
  classId: "",
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN.SUCCESS: {
      return {
        ...state,
        memberCode: action.payload?.memberCode,
        accessToken: action.payload?.accessToken,
        schoolId: action.payload?.schoolId,
        classId: action.payload?.classId,
      };
    }
    case LOGOUT_REQUEST: {
      return initialState;
    }
    default:
      return state;
  }
};

export default authReducer;
