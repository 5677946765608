import APIUtils from 'utils/apiUtils';
import {END_POINTS} from '@shared/constants/apiUrl';

export function getListHistoryOrderApi({dataFilter, skipCount}) {
  let requestData = '?SkipCount=' + skipCount + '&MaxResult=10';
  if (
    JSON.stringify(dataFilter) !== '{}' &&
    dataFilter !== null &&
    typeof dataFilter !== 'undefined'
  ) {
    requestData =
      requestData +
      '&FromDateTime=' +
      dataFilter.fromDate +
      '&ToDateTime=' +
      dataFilter.toDate;
  }
  return APIUtils.get(END_POINTS.GET_LIST_HISTORY_ORDER_URL + requestData);
}
