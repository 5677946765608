import { LOGOUT_REQUEST } from "@shared/redux/account/constants";
import AppConfigs from "configs/env";
import {
  GET_LIST_BANNER_SUCCESS,
  GET_LIST_SHORTCUT_SUCCESS,
  TOGGLE_REQUEST_LOGIN,
  GET_LIST_BANNER_DYNAMIC_SUCCESS,
  GET_LIST_HOT_MERCHANT_SUCCESS,
  SET_LIST_COUPON_SUGGEST,
  UPDATE_LIST_COUPON_SUGGEST,
  GET_LIST_BANNER_BY_LOCATION_SUCCESS,
  GET_PERSONALIZE_SHORTCUT_SUCCESS,
  UPDATE_GOOGLE_MAP_KEY_API,
} from "./constants";

const initialState = {
  listBanner: [],
  listShortcut: [],
  isShowRequestLogin: false,
  listBannerDynamicPage: [],
  listHotMerchant: [],
  listCouponSuggest: [],
  googleMapApiKey: AppConfigs.keyGoogleMap,
};

const common = (state = initialState, action) => {
  const { payload } = action;

  switch (action.type) {
    case GET_LIST_BANNER_BY_LOCATION_SUCCESS:
    case GET_LIST_BANNER_SUCCESS: {
      return {
        ...state,
        listBanner: action.banners,
      };
    }
    case UPDATE_GOOGLE_MAP_KEY_API: {
      return {
        ...state,
        googleMapApiKey: action.googleMapApiKey,
      };
    }
    case GET_LIST_BANNER_DYNAMIC_SUCCESS: {
      return {
        ...state,
        listBannerDynamicPage: action.banners,
      };
    }
    case GET_LIST_HOT_MERCHANT_SUCCESS: {
      return {
        ...state,
        listHotMerchant: action.listHotMerchant,
      };
    }
    case GET_LIST_SHORTCUT_SUCCESS: {
      return {
        ...state,
        listShortcut: action.shortcuts,
      };
    }
    case TOGGLE_REQUEST_LOGIN: {
      return {
        ...state,
        isShowRequestLogin: action.payload,
      };
    }
    case LOGOUT_REQUEST: {
      return {
        ...initialState,
      };
    }
    case SET_LIST_COUPON_SUGGEST:
      return {
        ...state,
        listCouponSuggest: payload,
      };

    case GET_PERSONALIZE_SHORTCUT_SUCCESS:
      return {
        ...state,
        listShortcut: payload,
      };

    case UPDATE_LIST_COUPON_SUGGEST:
      return {
        ...state,
        listCouponSuggest:
          state?.listCouponSuggest?.length > 0
            ? state.listCouponSuggest.map((coupon, index) => {
                if (coupon.couponId === payload.couponId) {
                  coupon.isAllocated = true;
                  coupon.transactionCode = payload.transactionCode;
                  return {
                    ...coupon,
                    isAllocated: true,
                    transactionCode: payload.transactionCode,
                  };
                }
                return coupon;
              })
            : initialState.listCouponSuggest.push(payload),
      };
    default:
      return state;
  }
};

export default common;
