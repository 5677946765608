import {fork, put, takeEvery} from 'redux-saga/effects';
import {callApi} from 'helpers/callApi';
import {getSchoolInfoApi} from '@shared/services/api/school';
import {
  getListBlockOfSchoolTypeSuccess,
  getListBlockOfSchoolTypeFailed,
} from './actions';
import {GET_LIST_SCHOOL} from './constants';

export function* getListBlockOfSchool(action) {
  const {onSuccess, schoolId, onFailed} = action;
  try {
    const res = yield callApi(getSchoolInfoApi, schoolId);
    yield put(getListBlockOfSchoolTypeSuccess());
    onSuccess?.(res?.data);
  } catch (error) {
    onFailed?.();
    yield put(getListBlockOfSchoolTypeFailed(error));
  }
}

function* watchListBlockOfSchoolSaga() {
  yield takeEvery(GET_LIST_SCHOOL.HANDLER, getListBlockOfSchool);
}
export default function* rootChild() {
  yield fork(watchListBlockOfSchoolSaga);
}
