import {
  CLEAR_ADDITIONAL_DATA_SCHEMA,
  SAVE_ADDITIONAL_DATA_SCHEMA,
  SAVE_DATA_SCHEMA,
  CLEAR_DATA_SCHEMA,
  CLEAR_CAMPAIGN_DATA_SCHEMA,
  SAVE_CAMPAIGN_DATA_SCHEMA,
} from './constants';

const initialState = {
  dataScheme: {},
  additionalDataSchema: {},
  campaignDataSchema: {},
};

const scheme = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_DATA_SCHEMA: {
      return {
        ...state,
        dataScheme: action.payload,
      };
    }
    case CLEAR_DATA_SCHEMA: {
      return {
        ...state,
        dataScheme: {},
      };
    }
    case SAVE_ADDITIONAL_DATA_SCHEMA: {
      return {
        ...state,
        additionalDataSchema: action.payload,
      };
    }
    case CLEAR_ADDITIONAL_DATA_SCHEMA: {
      return {
        ...state,
        additionalDataSchema: {},
      };
    }

    case SAVE_CAMPAIGN_DATA_SCHEMA: {
      return {
        ...state,
        campaignDataSchema: action.payload,
      };
    }

    case CLEAR_CAMPAIGN_DATA_SCHEMA: {
      return {
        ...state,
        campaignDataSchema: {},
      };
    }

    default:
      return state;
  }
};

export default scheme;
