import { genActions } from "utils/actionGenerator";
import * as TYPE from "./constants";

const actions = {
  GET_VOUCHER_INFO: genActions(TYPE.GET_VOUCHER_INFO),
  GET_SUGGESTED_COUPON_LIST: genActions(TYPE.GET_SUGGESTED_COUPON_LIST),

  // CALC_COUPON_DISCOUNT: genActions(TYPE.CALC_COUPON_DISCOUNT),
  // GET_AVAILABLE_COUPONS_BY_CODE: genActions(TYPE.GET_AVAILABLE_COUPONS_BY_CODE),
  // GET_AVAILABLE_COUPONS_OF_ALLOCATED_COUPON_TICKET: genActions(
  //   TYPE.GET_AVAILABLE_COUPONS_OF_ALLOCATED_COUPON_TICKET
  // ),
  CALC_COUPON_DISCOUNT_V2: genActions(TYPE.CALC_COUPON_DISCOUNT_V2),
  CREATE_ORDER_VOUCHER: genActions(TYPE.CREATE_ORDER_VOUCHER),
  CLEAR_ORDER_VOUCHER_INFO: () => ({
    type: TYPE.CLEAR_ORDER_VOUCHER_INFO,
  }),
};

export default actions;
