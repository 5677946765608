import { genActionTypes } from "utils/actionGenerator";

export const TYPE = "VoucherCart";

export const GET_VOUCHER_INFO = genActionTypes(`${TYPE}_GET_VOUCHER_INFO`);
export const GET_SUGGESTED_COUPON_LIST = genActionTypes(
  `${TYPE}_GET_SUGGESTED_COUPON_LIST`
);

export const CALC_COUPON_DISCOUNT = genActionTypes(
  `${TYPE}_CALC_COUPON_DISCOUNT`
);
export const GET_AVAILABLE_COUPONS_BY_CODE = genActionTypes(
  `${TYPE}_GET_AVAILABLE_COUPONS_BY_CODE`
);
export const GET_AVAILABLE_COUPONS_OF_ALLOCATED_COUPON_TICKET = genActionTypes(
  `${TYPE}_GET_AVAILABLE_COUPONS_OF_ALLOCATED_COUPON_TICKET`
);
export const CALC_COUPON_DISCOUNT_V2 = genActionTypes(
  `${TYPE}_CALC_COUPON_DISCOUNT_V2`
);

export const CREATE_ORDER_VOUCHER = genActionTypes(
  `${TYPE}_CREATE_ORDER_VOUCHER`
);
export const CLEAR_ORDER_VOUCHER_INFO = `${TYPE}_CLEAR_ORDER_VOUCHER_INFO`;
