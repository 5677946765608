import {
  GET_LIST_ADDRESS_BOOK_REQUEST,
  GET_LIST_ADDRESS_BOOK_SUCCESS,
  REMOVE_ADDRESS_REQUEST,
  REMOVE_ADDRESS_SUCCESS,
  SET_DEFAULT_ADDRESS_REQUEST,
  SET_DEFAULT_ADDRESS_SUCCESS,
  GET_LIST_ADDRESS_BOOK_FAILED,
  REMOVE_ADDRESS_FAILED,
  SET_DEFAULT_ADDRESS_FAILED,
} from './constants';
//address list
export const getAddressBookListRequest = (onSuccess, onFailed) => ({
  type: GET_LIST_ADDRESS_BOOK_REQUEST,
  onSuccess,
  onFailed,
});

export const getAddressBookListSuccess = addressBook => ({
  type: GET_LIST_ADDRESS_BOOK_SUCCESS,
  addressBook,
});

export const getAddressBookListFailed = err => ({
  type: GET_LIST_ADDRESS_BOOK_FAILED,
  err,
});


// remove address
export const removeAddressRequest = (payload,onSuccess, onFailed) => ({
  type: REMOVE_ADDRESS_REQUEST,
  payload,
  onSuccess,
  onFailed,
});

export const removeAddressSuccess = addressBook => ({
  type: REMOVE_ADDRESS_SUCCESS,
  addressBook,
});

export const removeAddressFailed = err => ({
  type: REMOVE_ADDRESS_FAILED,
  err,
});


// set default address
export const setDefaultAddressRequest = (payload,onSuccess, onFailed) => ({
  type: SET_DEFAULT_ADDRESS_REQUEST,
  payload,
  onSuccess,
  onFailed,
});

export const setDefaultAddressSuccess = addressBook => ({
  type: SET_DEFAULT_ADDRESS_SUCCESS,
  addressBook,
});

export const setDefaultAddressFailed = err => ({
  type: SET_DEFAULT_ADDRESS_FAILED,
  err,
});
