import { call, put, takeLatest, fork, select } from "redux-saga/effects";
import { parseListFeed } from "helpers/parsers";
import actions from "./actions";
import * as TYPE from "./constants";
import api from "../api/api";
// import dummyData, { fakeApi } from "../dummy/listfeed";

const sagas = {
  // * GET_LIST_FEED
  *[TYPE.GET_LIST_FEED.request](action) {
    const {
      lat,
      long,
      skip,
      take,
      localeCode = "vi",
      couponId,
      type,
    } = action.payload;

    try {
      const response = yield call(api[TYPE.GET_LIST_FEED.request], {
        lat,
        long,
        skip,
        take,
        couponId,
      });

      if (response.status === 200) {
        const isInit = false;
        const nextFeeds =
          parseListFeed({ listFeed: response.data }, localeCode) || [];
        const hasMore = nextFeeds?.length === take;
        const prevListFeed = yield select(getListFeed);
        const newListFeed =
          type === "load"
            ? {
                value: [...prevListFeed.value, ...nextFeeds],
                hasMore,
                isInit,
              }
            : {
                value: [...nextFeeds],
                hasMore,
                isInit,
              };
        yield put(actions.GET_LIST_FEED.success(newListFeed));
      }
    } catch (error) {
      yield put(actions.GET_LIST_FEED.failure(error?.data));
    }
  },

  // * GET_LIST_STORE_OF_MERCHANT
  *[TYPE.GET_LIST_STORE_OF_MERCHANT.request](action) {
    const {
      couponId,
      merchantId,
      feedTypes,
      latitude,
      longitude,
      skip,
      take = 20,
      onSuccess,
    } = action.payload;

    try {
      const response = yield call(
        api[TYPE.GET_LIST_STORE_OF_MERCHANT.request],
        {
          couponId,
          merchantId,
          feedTypes,
          latitude,
          longitude,
          skip,
          take,
        }
      );

      if (response.status === 200) {
        const listStore =
          parseListFeed({ listFeed: response.data, localeCode: "vi" }) || [];
        const prevListStoreData = yield select(getListStoreOfSelectedMerchant);
        const shouldReplacePrevListStore =
          merchantId !== prevListStoreData.merchantId;
        const listStoreOfSelectedMerchant = {
          merchantId,
          listStore: shouldReplacePrevListStore
            ? listStore
            : [...prevListStoreData.listStore, ...listStore],
          hasMore: listStore.length === take,
        };

        yield put(
          actions.GET_LIST_STORE_OF_MERCHANT.success(
            listStoreOfSelectedMerchant
          )
        );

        if (onSuccess) {
          yield call(onSuccess);
        }
      } else {
        yield put(actions.GET_LIST_STORE_OF_MERCHANT.failure(response?.data));
      }
    } catch (error) {
      yield put(actions.GET_LIST_STORE_OF_MERCHANT.failure(error?.data));
    }
  },
};

function* watchedSaga() {
  // * GET_LIST_FEED
  yield takeLatest(
    TYPE.GET_LIST_FEED.request,
    sagas[TYPE.GET_LIST_FEED.request]
  );
  // * GET_LIST_STORE_OF_MERCHANT
  yield takeLatest(
    TYPE.GET_LIST_STORE_OF_MERCHANT.request,
    sagas[TYPE.GET_LIST_STORE_OF_MERCHANT.request]
  );
}

export default function* rootChild() {
  yield fork(watchedSaga);
}

// *** SELECTORS
const getListFeed = (state) => state[TYPE.TYPE].listFeed;
const getListStoreOfSelectedMerchant = (state) =>
  state[TYPE.TYPE].listStoreOfSelectedMerchant;
