import request from "services/request";
import { encodeQueryData } from "utils/stringFormat";
import * as TYPE from "../store/constants";

const api = {
  [TYPE.CREATE_PAYMENT.request]: async (params) => {
    return request(`Order/CreatePaymentUrl`, "POST", params);
  },
  [TYPE.TRANSACTION_PAY.request]: async (params) => {
    return request(`wallet/api/v2.0/Transaction/Pay`, "POST", params, {
      "Api-version": process.env.REACT_APP_PAYMENT_API_VERSION,
    });
  },
  [TYPE.GET_LIST_COUPON_MY_VOUCHER.request]: async (params) => {
    const query = encodeQueryData(params);
    return request(`/voucher/myvouchers${query}`, "GET");
  },
};

export default api;
