import { put, takeLatest, fork, call } from "redux-saga/effects";
import {
  GET_VOUCHER_DETAIL_REQUEST,
  CHECK_STOCK_VOUCHER_REQUEST,
  CHECK_DISCOUNT_COUPON_REQUEST,
  CREATE_ORDER_REQUEST,
  REFUND_VOUCHER_REQUEST,
} from "./constants";
import {
  getVoucherDetailSuccess,
  getVoucherDetailFailed,
  checkStockVoucherSuccess,
  checkStockVoucherFailed,
  checkDiscountCouponForVoucherSuccess,
  checkDiscountCouponForVoucherFailed,
  createOrderHubSuccess,
  createOrderHubFailed,
  refundVoucherSuccess,
  refundVoucherFailed,
} from "./actions";
import {
  getVoucherDetailApi,
  checkStockApi,
  checkDiscountCouponForVoucherApi,
  createOrderHubApi,
  refundVoucherApi,
} from "@shared/services/api/voucher";
import { voucherParser } from "./parser";
import { callApi } from "helpers/callApi";

export function* getVoucherDetailSaga(obj) {
  const { voucherId = {}, onSuccess } = obj;
  try {
    const res = yield callApi(getVoucherDetailApi, { voucherId });
    let dataParser = voucherParser(res?.data?.data?.findVouchersContent?.data);
    yield put(getVoucherDetailSuccess({ data: dataParser }));
    if (typeof onSuccess === "function") {
      onSuccess(dataParser);
    }
  } catch (err) {
    yield put(getVoucherDetailFailed(err));
  }
}

export function* checkStockVoucherSaga(obj) {
  const { productCode = {}, onSuccess, onError } = obj;
  try {
    const res = yield callApi(checkStockApi, productCode);
    yield put(checkStockVoucherSuccess(res));
    if (typeof onSuccess === "function") {
      onSuccess(res);
    }
  } catch (err) {
    onError(err);
    yield put(checkStockVoucherFailed(err));
  }
}

export function* checkDiscountCouponForVoucherSaga(obj) {
  const { payload = {}, onSuccess, onError } = obj;
  try {
    const res = yield callApi(checkDiscountCouponForVoucherApi, payload);
    yield put(checkDiscountCouponForVoucherSuccess(res));
    if (typeof onSuccess === "function") {
      onSuccess(res);
    }
  } catch (err) {
    yield put(checkDiscountCouponForVoucherFailed(err));
    onError(err);
  }
}

export function* createOrderHubSaga(obj) {
  const { payload = {}, onSuccess, onError } = obj;
  try {
    const res = yield callApi(createOrderHubApi, payload);
    if (typeof onSuccess === "function") {
      onSuccess(res);
    }
    yield put(createOrderHubSuccess(res));
  } catch (err) {
    yield put(createOrderHubFailed(err));
    onError(err);
  }
}

export function* refundVoucherSaga(obj) {
  const { payload = {}, onSuccess, onError } = obj;
  try {
    const res = yield callApi(refundVoucherApi, payload);
    if (typeof onSuccess === "function") {
      onSuccess(res);
    }
    yield put(refundVoucherSuccess(res));
  } catch (err) {
    yield put(refundVoucherFailed(err));
    onError(err);
  }
}

function* watchCommon() {
  yield takeLatest(GET_VOUCHER_DETAIL_REQUEST, getVoucherDetailSaga);
  yield takeLatest(CHECK_STOCK_VOUCHER_REQUEST, checkStockVoucherSaga);
  yield takeLatest(
    CHECK_DISCOUNT_COUPON_REQUEST,
    checkDiscountCouponForVoucherSaga
  );
  yield takeLatest(CREATE_ORDER_REQUEST, createOrderHubSaga);
  yield takeLatest(REFUND_VOUCHER_REQUEST, refundVoucherSaga);
}

export default function* rootChild() {
  yield fork(watchCommon);
}
