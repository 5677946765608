import { put, takeLatest, fork } from "redux-saga/effects";
import {
  GET_CITY_BY_LOCATION_REQUEST,
  GET_LIST_CITY_REQUEST,
} from "./constants";
import {
  getListCitySuccess,
  getListCityFailed,
  getCityByLocationSuccess,
  getCityByLocationFailed,
} from "./actions";
import {
  getCityByLocationApi,
  getListCityApi,
} from "@shared/services/api/address";
import { callApi } from "helpers/callApi";
import Alert from "helpers/alert";
export function* getListCitySaga(obj) {
  const { onSuccess } = obj;
  try {
    const res = yield callApi(getListCityApi);
    let listCity = res?.data;
    yield put(getListCitySuccess(listCity));
    if (typeof onSuccess === "function") {
      onSuccess(res);
    }
  } catch (err) {
    const { status } = err || {};
    if (status >= 400) {
      Alert.alert("Utop", err?.data?.message);
    }
    yield put(getListCityFailed(err));
  }
}

export function* getCityByLocationSaga(obj) {
  const { location, onSuccess, onError } = obj;
  try {
    if (location) {
      const res = yield callApi(getCityByLocationApi, {
        location: {
          lat: location.latitude,
          long: location.longitude,
        },
      });
      let city = res?.data;
      yield put(getCityByLocationSuccess(city));
      if (typeof onSuccess === "function") {
        onSuccess(city);
      }
    }
  } catch (err) {
    const { status } = err || {};
    if (status >= 400) {
      Alert.alert("Utop", err?.data?.message);
    }
    if (typeof onError === "function") {
      onError(err);
    }
    yield put(getCityByLocationFailed(err));
  }
}

function* watchAddress() {
  yield takeLatest(GET_LIST_CITY_REQUEST, getListCitySaga);
  yield takeLatest(GET_CITY_BY_LOCATION_REQUEST, getCityByLocationSaga);
}

export default function* rootChild() {
  yield fork(watchAddress);
}
