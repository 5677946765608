import { call, put, takeLatest, fork } from "redux-saga/effects";
import { uploadFileToBlob } from "utils/imageUtils";
import moment from "moment";
import rootActions from "views/common/store/actions/actions";
import actions from "./actions";
import * as TYPE from "./constants";
import api from "../api/api";

const sagas = {
  *[TYPE.UPLOAD_AVATAR.request]({ payload }) {
    try {
      const data = yield call(api[TYPE.UPLOAD_AVATAR.request]);
      if (data && data.data && data.status === 200) {
        const { file, userId } = payload;
        const { sasToken, blobEndpoint } = data.data;
        const response = yield uploadFileToBlob(file, sasToken, blobEndpoint, userId);
        if (response) {
          const photoUrl = `${response}?time=${moment().unix()}`;
          yield put(actions.UPDATE_USER_INFO.request({ photoUrl }));
          yield put(actions.UPLOAD_AVATAR.success(photoUrl));
        } else {
          yield put(actions.UPLOAD_AVATAR.failure(data?.data));
        }
      } else {
        yield put(actions.UPLOAD_AVATAR.failure(data?.data));
      }
    } catch (error) {
      yield put(actions.UPLOAD_AVATAR.failure(error?.data));
    }
  },
  *[TYPE.UPDATE_USER_INFO.request](action) {
    try {
      const data = yield call(api[TYPE.UPDATE_USER_INFO.request], action.payload);
      if (data.status === 200) {
        yield put(rootActions.GET_USER_INFO.request());
        yield put(actions.UPDATE_USER_INFO.success(data.data));
      } else {
        yield put(actions.UPDATE_USER_INFO.failure(data?.data));
      }
    } catch (error) {
      yield put(actions.UPDATE_USER_INFO.failure(error?.data));
    }
  },
  *[TYPE.GET_UTOP_INFOMATION.request](action) {
    try {
      const data = yield call(api[TYPE.GET_UTOP_INFOMATION.request], action.payload);
      if (data.status === 200) {
        yield put(actions.GET_UTOP_INFOMATION.success(data.data));
      } else {
        yield put(actions.GET_UTOP_INFOMATION.failure(data?.data));
      }
    } catch (error) {
      yield put(actions.GET_UTOP_INFOMATION.failure(error?.data));
    }
  },
};

function* watchedSaga() {
  yield takeLatest(TYPE.UPLOAD_AVATAR.request, sagas[TYPE.UPLOAD_AVATAR.request]);
  yield takeLatest(TYPE.UPDATE_USER_INFO.request, sagas[TYPE.UPDATE_USER_INFO.request]);
  yield takeLatest(TYPE.GET_UTOP_INFOMATION.request, sagas[TYPE.GET_UTOP_INFOMATION.request]);

}

export default function* rootChild() {
  yield fork(watchedSaga);
}
