import { addErrors, delErrors } from "utils/ErrorsGenerator";
import * as TYPE from "./constants";

const reducers = {
  [TYPE.GET_LIST_VOUCHER_TO_CHOOSE.request]: (draft) => {
    return {
      ...draft,
      isLoading: {
        ...draft.isLoading,
        GET_LIST_VOUCHER_TO_CHOOSE: true,
      },
    };
  },
  [TYPE.GET_LIST_VOUCHER_TO_CHOOSE.success]: (draft, { payload }) => {
    return {
      ...draft,
      errors: delErrors(draft.errors, TYPE.GET_LIST_VOUCHER_TO_CHOOSE.failure),
      voucherNotExp: payload,
      isLoading: {
        ...draft.isLoading,
        GET_LIST_VOUCHER_TO_CHOOSE: false,
      },
    };
  },

  [TYPE.GET_LIST_VOUCHER_TO_CHOOSE.failure]: (draft, { payload }) => {
    return {
      ...draft,
      errors: addErrors(draft.errors, {
        type: TYPE.GET_LIST_VOUCHER_TO_CHOOSE.failure,
        msg: payload,
      }),
      isLoading: {
        ...draft.isLoading,
        GET_LIST_VOUCHER_TO_CHOOSE: false,
      },
    };
  },

  // *SET_QUANTITY_VOUCHER

  [TYPE.SET_QUANTITY_VOUCHER]: (draft, { payload }) => {
    const { value } = draft.voucherNotExp;

    let newList = draft.voucherNotExp;

    const conditionChangeVoucher = value?.find(
      (child) => child.voucherId === payload.idVoucher
    );
    if (conditionChangeVoucher) {
      const updatedVoucher = {
        ...conditionChangeVoucher,
        quantity: payload.val,
      };
      newList = value?.map((item) =>
        item.voucherId === payload.idVoucher ? updatedVoucher : item
      );
    }

    return {
      ...draft,
      voucherNotExp: { value: newList },
    };
  },
  // *SET_CHECK_MARK

  [TYPE.SET_CHECK_MARK]: (draft, { payload }) => {
    const { value } = draft.voucherNotExp;

    let newList = draft.voucherNotExp;

    const conditionChangeVoucher = value?.find(
      (child) => child.voucherId === payload
    );
    if (conditionChangeVoucher) {
      const updatedVoucher = {
        ...conditionChangeVoucher,
        check: true,
      };
      newList = value?.map((item) =>
        item.voucherId === payload ? updatedVoucher : item
      );
    }

    return {
      ...draft,
      voucherNotExp: { value: newList, isInit: false },
    };
  },
  [TYPE.SET_UNCHECK_MARK]: (draft, { payload }) => {
    const { value } = draft.voucherNotExp;

    let newList = draft.voucherNotExp;

    const conditionChangeVoucher = value?.find(
      (child) => child.voucherId === payload
    );
    if (conditionChangeVoucher) {
      const updatedVoucher = {
        ...conditionChangeVoucher,
        check: false,
      };
      newList = value?.map((item) =>
        item.voucherId === payload ? updatedVoucher : item
      );
    }

    return {
      ...draft,
      voucherNotExp: { value: newList, isInit: false },
    };
  },

  [TYPE.CLEAR_LIST_VOUCHER_TO_CHOOSE]: (draft) => {
    return {
      ...draft,
      voucherNotExp: {
        isInit: true,
        value: [],
      },
    };
  },
};

export default reducers;
