export const VERIFY_LINK_REQUEST = 'business/VERIFY_LINK_REQUEST';
export const VERIFY_LINK_SUCCESS = 'business/VERIFY_LINK_SUCCESS';
export const VERIFY_LINK_FAILED = 'business/VERIFY_LINK_FAILED';
export const GET_USER_BUSINESS_REQUEST = 'business/GET_USER_BUSINESS_REQUEST';
export const GET_USER_BUSINESS_SUCCESS = 'business/GET_USER_BUSINESS_SUCCESS';
export const GET_USER_BUSINESS_FAILED = 'business/GET_USER_BUSINESS_FAILED';
export const CHECK_ACCOUNT_BUSINESS_REQUEST =
  'business/CHECK_ACCOUNT_BUSINESS_REQUEST';
export const CHECK_ACCOUNT_BUSINESS_SUCCESS =
  'business/CHECK_ACCOUNT_BUSINESS_SUCCESS';
export const CHECK_ACCOUNT_BUSINESS_FAILED =
  'business/CHECK_ACCOUNT_BUSINESS_FAILED';
export const GET_TRANSACTION_DETAIL_REQUEST =
  'business/GET_TRANSACTION_DETAIL_REQUEST';
export const GET_TRANSACTION_DETAIL_SUCCESS =
  'business/GET_TRANSACTION_DETAIL_SUCCESS';
export const GET_TRANSACTION_DETAIL_FAILED =
  'business/GET_TRANSACTION_DETAIL_FAILED';
export const GET_ALL_TRANSACTION_HISTORY_UB_REQUEST =
  'business/GET_ALL_TRANSACTION_HISTORY_UB_REQUEST';
export const GET_ALL_TRANSACTION_HISTORY_UB_SUCCESS =
  'business/GET_ALL_TRANSACTION_HISTORY_UB_SUCCESS';
export const GET_ALL_TRANSACTION_HISTORY_UB_FAILED =
  'business/GET_ALL_TRANSACTION_HISTORY_UB_FAILED';
export const GET_LIST_STORE_UB_REQUEST = 'business/GET_LIST_STORE_UB_REQUEST';
export const GET_LIST_STORE_UB_SUCCESS = 'business/GET_LIST_STORE_UB_SUCCESS';
export const GET_LIST_STORE_UB_FAILED = 'business/GET_LIST_STORE_UB_FAILED';
export const GET_LIST_SEARCH_STORE_UB_REQUEST =
  'business/GET_LIST_SEARCH_STORE_UB_REQUEST';
export const GET_LIST_SEARCH_STORE_UB_SUCCESS =
  'business/GET_LIST_SEARCH_STORE_UB_SUCCESS';
export const GET_LIST_SEARCH_STORE_UB_FAILED =
  'business/GET_LIST_SEARCH_STORE_UB_FAILED';
export const CLEAR_ACCOUNT_BUSINESS = 'business/CLEAR_ACCOUNT_BUSINESS';
export const UPDATE_ALL_TRANSACTION_HISTORY_UB =
  'business/UPDATE_ALL_TRANSACTION_HISTORY_UB';
export const GET_DETAIL_VERIFY_REQUEST = 'business/GET_DETAIL_VERIFY_REQUEST';
export const GET_LIST_STORE_TYPE_REQUEST =
  'business/GET_LIST_STORE_TYPE_REQUEST';
export const GET_LIST_STORE_TYPE_SUCCESS =
  'business/GET_LIST_STORE_TYPE_SUCCESS';
export const GET_LIST_STORE_TYPE_FAILED = 'business/GET_LIST_STORE_TYPE_FAILED';
export const SET_CURRENT_LOCATION = 'business/SET_CURRENT_LOCATION';
export const UPDATE_SELECTED_LOCATION = 'business/UPDATE_SELECTED_LOCATION';
export const UPDATE_CURRENT_LOCATION = 'business/UPDATE_CURRENT_LOCATION';
export const GET_LIST_CITY_REQUEST = 'business/GET_LIST_CITY_REQUEST';
export const GET_LIST_CITY_SUCCESS = 'business/GET_LIST_CITY_SUCCESS';
export const GET_LIST_CITY_FAILED = 'business/GET_LIST_CITY_FAILED';
export const GET_LIST_DISTRICT_REQUEST = 'business/GET_LIST_DISTRICT_REQUEST';
export const GET_LIST_DISTRICT_SUCCESS = 'business/GET_LIST_DISTRICT_SUCCESS';
export const GET_LIST_DISTRICT_FAILED = 'business/GET_LIST_DISTRICT_FAILED';
export const STORE_DETAIL_REQUEST = 'business/STORE_DETAIL_REQUEST';
export const CLEAR_LIST_SEARCH_STORE_UB = 'business/CLEAR_LIST_SEARCH_STORE_UB';
export const LIST_SEARCH_STORE_UB_HISTORY =
  'business/LIST_SEARCH_STORE_UB_HISTORY';
export const STORE_IMAGES_REQUEST = 'business/STORE_IMAGES_REQUEST';
export const CREATE_RESERVATION_REQUEST = 'business/CREATE_RESERVATION_REQUEST';
export const CREATE_RESERVATION_SUCCESS = 'business/CREATE_RESERVATION_SUCCESS';
export const CREATE_RESERVATION_FAILED = 'business/CREATE_RESERVATION_FAILED';
export const UPDATE_FILTER_STORE = 'business/UPDATE_FILTER_STORE';
export const UPDATE_ACCOUNT_BUSINESS = 'business/UPDATE_ACCOUNT_BUSINESS';