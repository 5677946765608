/* eslint-disable no-unused-expressions */
import React, { useState } from "react";
import { toVND } from "utils/currencyFormat";
import Popup from "views/common/component/Popup";
import { genProductMarketDescription } from "utils/genProductDescription";
import SVG, { SignSoldOut } from "assets/svg";
import EditMenu from "../../../MarketHomeView/components/EditMenu";
import "./foodList.scss";
// eslint-disable-next-line import/order
import classNames from "classnames";

const FoodItem = React.memo((props) => {
  const {
    id,
    itemId,
    name,
    quantity,
    price,
    index,
    chosenSize,
    chosenAttributes,
    chosenToppings,

    handleRemoveFood,
    handleEdit,
    itemFood,

    isSoldOut = false,
    ...rest
  } = props;

  const checkIsOutOfStock = (item) => {
    let isShow = false;
    if (props.isFromMarket) {
      item.isOutOfStock ||
      item?.data?.isOutOfStock ||
      !item?.data?.isSelling ||
      (!!item?.data?.status && item?.data?.status !== 1) ||
      item?.data?.category?.status !== 1
        ? (isShow = true)
        : (isShow = false);
    } else {
      item.isOutOfStock ? (isShow = true) : (isShow = false);
    }
    return isShow;
  };

  return (
    <div className="food-item">
      <div className={classNames("w-100 d-flex")} {...rest}>
        <div
          style={{
            minWidth: "6ch",
            opacity: checkIsOutOfStock(itemFood) ? 0.3 : 1,
          }}
        >
          <span className="_fs-16px _fw-600 color-chinese_black">{`${
            quantity || 1
          }x`}</span>
        </div>

        <div style={{ overflowX: "hidden" }}>
          <div style={{ opacity: isSoldOut ? 0.3 : 1 }}>
            <p className="mb-0 _fs-16px _fw-600 color-chinese_black only-2-lines">
              {name}
            </p>
            <p className="mb-0 text-wrap _fs-12px _fw-400 color-spanish_gray">
              {genProductMarketDescription(
                chosenSize,
                chosenAttributes,
                chosenToppings
              )}
            </p>
          </div>
        </div>

        <div
          className="ml-auto"
          style={{
            minWidth: "10ch", // limit maximum price 99.999.999
            opacity: checkIsOutOfStock(itemFood) ? 0.3 : 1,
          }}
        >
          <p className="mb-0 float-right _fs-15px _fw-600 color-black">{`${toVND(
            price
          )}`}</p>
        </div>
      </div>

      <div className="mt-2 d-flex" style={{ marginLeft: "6ch" }}>
        <div
          // type="button"
          className="button_small shadow-none outlined mr-2 button__effect"
          onClick={checkIsOutOfStock(itemFood) ? () => {} : handleEdit}
          disabled={checkIsOutOfStock(itemFood)}
          style={{ opacity: checkIsOutOfStock(itemFood) ? 0.3 : 1 }}
        >
          <img src={SVG.Pencil} alt="" style={{ marginRight: 5 }} />

          <span>Thay đổi</span>
        </div>

        <div
          // type="button"
          className="button_small shadow-none outlined button__effect"
          onClick={() => handleRemoveFood(index)}
        >
          <span className="icon-garbage_can" />
          <span>Xóa</span>
        </div>

        {checkIsOutOfStock(itemFood) && (
          <div style={{ marginLeft: 8 }}>
            <SignSoldOut />
          </div>
        )}
      </div>
    </div>
  );
});

const FoodList = React.memo((props) => {
  // const FnBCart = JSON.parse(localStorage.getItem(FNB_CART_KEY));

  const {
    productList,
    soldOutItemList,
    handleRemoveFood = () => {},
    handleUpdateItem,
  } = props;
  const [openEditMenu, setOpenEditMenu] = useState(false);
  const [itemInCartToEdit, setItemInCartToEdit] = useState();

  const handleEditFoodItem = (itemId) => () => {
    // get foot item by id from redux
    const itemToEdit = productList?.find(
      (item) => item.dataInfoProduct.index === itemId
    );

    setItemInCartToEdit(itemToEdit);

    setOpenEditMenu(true);
  };

  const handleCloseEditMenu = () => {
    setOpenEditMenu(false);

    // set time out để tránh hiện tượng giật cục khi đóng popup
    // 500ms: do hiện tại transition-duration của popup là 0.4s = 400ms
    // cần set lại chosenProduct -> undefined để OrderMenu không được render
    // -> tránh được bug cho RadioButtons & CheckboxButtons
    // các bugs này hiện tại chưa tìm hiểu được nguyên nhân
    // todo: trong tương lai, cần viết lại chung 1 popup
    setTimeout(() => {
      setItemInCartToEdit(undefined);
    }, 500);
  };

  const formatAttribute = (attributes) => {
    if (attributes.length > 0) {
      return attributes.map((item) => ({
        Name: item.Name || item.name,
        Value: item.Value || item.value,
      }));
    }
    return [];
  };

  const formatSize = (size, data) => {
    if (data.dataInfoProduct.sizeOption) {
      return data.dataInfoProduct.sizeOption;
    }
    return data.data.sizes.find((f) => f.Name === size);
  };

  const formatTopping = (topping) => {
    return topping.map((item) => ({
      ...item,
      price: item.price.default || item.price,
    }));
  };

  return (
    <div className="food-list__container">
      {productList?.length > 0 && (
        <div className="mb-2 product-list">
          {productList?.map((product) => (
            <FoodItem
              key={product?.dataInfoProduct?.index}
              id={product?.data?.id}
              itemFood={product}
              itemId={product?.dataInfoProduct?.productCode}
              name={product?.data?.name?.vi}
              index={product?.dataInfoProduct.index}
              quantity={product?.dataInfoProduct?.quantity}
              price={product?.dataInfoProduct?.totalPrice}
              chosenSize={
                product?.dataInfoProduct?.sizeName ||
                product?.dataInfoProduct?.sizeOption?.Name ||
                product?.product?.dataInfoProduct?.sizeOption?.name
              }
              chosenAttributes={product?.dataInfoProduct?.attributes}
              chosenToppings={product?.dataInfoProduct?.toppings}
              handleRemoveFood={handleRemoveFood}
              handleEdit={handleEditFoodItem(product?.dataInfoProduct.index)}
              isOutOfStock={product.isSelling === 0 || product.isOutOfStock}
              isSoldOut={soldOutItemList?.find(
                (item) => item.productId === product.productId
              )}
            />
          ))}
        </div>
      )}

      <Popup
        isOpen={openEditMenu}
        handleClose={handleCloseEditMenu}
        showButtonClose={false}
      >
        {itemInCartToEdit && (
          <EditMenu
            itemId={itemInCartToEdit?.data?.id}
            chosenAttributes={formatAttribute(
              itemInCartToEdit?.dataInfoProduct?.attributes
            )}
            chosenToppings={formatTopping(
              itemInCartToEdit?.dataInfoProduct?.toppings
            )}
            chosenSize={formatSize(
              itemInCartToEdit?.dataInfoProduct?.sizeName?.replace(
                "Size ",
                ""
              ) || "",
              itemInCartToEdit
            )}
            chosenQuantity={itemInCartToEdit?.dataInfoProduct?.quantity}
            itemPrice={itemInCartToEdit?.dataInfoProduct?.totalPrice}
            // originalData
            // originalData={itemInCartToEdit?.originalData}
            itemData={itemInCartToEdit}
            id={itemInCartToEdit?.data?.id}
            code={itemInCartToEdit?.data?.code}
            imageUrl={itemInCartToEdit?.data?.image}
            imageUrls={itemInCartToEdit?.data?.imageUrls}
            name={itemInCartToEdit?.data?.name?.vi}
            price={itemInCartToEdit?.data?.price?.default}
            listPrice={itemInCartToEdit?.data?.price?.list}
            description={itemInCartToEdit?.data?.description?.default?.vi}
            sizes={itemInCartToEdit?.data?.sizes}
            attributes={itemInCartToEdit?.data?.attributes}
            toppings={itemInCartToEdit?.data?.toppings}
            handleClose={handleCloseEditMenu}
            handleUpdateItem={handleUpdateItem}
            updateCart={handleUpdateItem}
          />
        )}
      </Popup>
    </div>
  );
});

export default FoodList;
