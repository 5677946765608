import { callApi } from "helpers/callApi";
import { fork, put, takeEvery, takeLatest } from "redux-saga/effects";
import {
  GET_LIST_BANNER_REQUEST,
  GET_LIST_SHORTCUT_REQUEST,
  SAVE_DEVICE_INFO_REQUEST,
  GET_LIST_BANNER_DYNAMIC_REQUEST,
  GET_DYNAMIC_PAGE_REQUEST,
  GET_LIST_HOT_MERCHANT_REQUEST,
  GET_LIST_BANNER_BY_LOCATION_REQUEST,
  GET_DYNAMIC_MODAL_REQUEST,
  ACTION_CAMPAIGN_PRESS_REQUEST,
  GET_SUGGEST_BANNER_REQUEST,
  SHORT_URL_REQUEST,
  GET_PERSONALIZE_SHORTCUT_REQUEST,
  GET_LOV,
} from "./constants";
import {
  getListBannerSuccess,
  getListBannerFailed,
  getListShortcutSuccess,
  getListShortcutFailed,
  saveDeviceInfoSuccess,
  saveDeviceInfoFailed,
  getListBannerDynamicSuccess,
  getListBannerDynamicFailed,
  getDynamicPageFailed,
  getListHotMerchantFailed,
  getListHotMerchantSuccess,
  getListBannerByLocationSuccess,
  getListBannerByLocationFailed,
  getDynamicModalSuccess,
  getDynamicModalFailed,
  actionCampaignPressFailure,
  actionCampaignPressSuccess,
  getSuggestBannerSuccess,
  getSuggestBannerFailure,
  getPersonalizeShortcutSuccess,
  getPersonalizeShortcutFailure,
  getLovFailure,
} from "./actions";
import {
  getDynamicModalConfigApi,
  getDynamicPageApi,
  getListBannerApi,
  getListHotMerchantApi,
  getListShortcutApi,
  saveDeviceInfoApi,
  getSuggestBannerApi,
  shortUrlApi,
  getPersonalizeShortcutApi,
  getLovApi,
} from "@shared/services/api/common";
import { getListBannerDynamicPage } from "@shared/services/api/dynamicPage";
import { APIUtils } from "utils";
import {
  listCampaignParser,
  listCommonShortcutParser,
  listPersonalizeShortcutParser,
  lovParser,
} from "./parser";
import { ERROR_CODE } from "@shared/constants/errorCode";
import { getCurrentLocale } from "@i18n";

export function* getListBannerSaga(obj) {
  const { payload = {}, onSuccess } = obj;
  try {
    const res = yield callApi(getListBannerApi, payload);

    let banners = [];
    if (res?.data?.items?.length > 0) {
      banners = res.data.items.sort(function (a, b) {
        return (
          (typeof b.data?.priority !== "undefined" ? b.data?.priority?.iv : 0) -
          (typeof a.data?.priority !== "undefined" ? a.data?.priority?.iv : 0)
        );
      });
    }

    yield put(getListBannerSuccess(banners));
    if (typeof onSuccess === "function") {
      onSuccess(res);
    }
  } catch (err) {
    const { status } = err || {};
    if (status >= 400) {
      Alert.alert("Utop", err?.data?.message);
    }
    yield put(getListBannerFailed(err));
  }
}

export function* getListBannerByLocationSaga(obj) {
  const { payload = {}, onSuccess } = obj;
  try {
    const res = yield callApi(getListBannerApi, payload);

    yield put(
      getListBannerByLocationSuccess(
        res.data?.map((banner) => ({
          data: {
            imageUrl: { iv: banner.imageUrl },
            title: { vi: banner.title },
            url: { iv: banner.actionUrl },
          },
        }))
      )
    );
    if (typeof onSuccess === "function") {
      onSuccess(
        res.data?.map((banner) => ({
          data: { imageUrl: { iv: banner.imageUrl } },
        }))
      );
    }
  } catch (err) {
    const { status } = err || {};
    if (status >= 400) {
      Alert.alert("Utop", err?.data?.message);
    }
    yield put(getListBannerByLocationFailed(err));
  }
}

export function* getListBannerDynamicSaga(obj) {
  const { payload = {}, onSuccess } = obj;
  try {
    const res = yield callApi(getListBannerDynamicPage, payload);

    let banners = [];
    if (res && res?.data?.items?.length > 0) {
      banners = res.data.items.sort(function (a, b) {
        return (
          (typeof b.data.priority !== "undefined" ? b.data.priority.iv : 0) -
          (typeof a.data.priority !== "undefined" ? a.data.priority.iv : 0)
        );
      });
    }

    yield put(getListBannerDynamicSuccess(banners));
    if (typeof onSuccess === "function") {
      onSuccess(res);
    }
  } catch (err) {
    const { status } = err || {};
    if (status >= 400) {
      Alert.alert("Utop", err?.data?.message);
    }
    yield put(getListBannerDynamicFailed(err));
  }
}

export function* getListShortcutSaga(obj) {
  const { onSuccess } = obj;
  try {
    const res = yield callApi(getListShortcutApi);
    let shortcuts = listCommonShortcutParser(res?.data?.items);
    if (shortcuts?.length > 0) {
      shortcuts = shortcuts.sort((a, b) => {
        return parseFloat(b.priority) - parseFloat(a.priority);
      });
    }
    yield put(getListShortcutSuccess(shortcuts));
    if (typeof onSuccess === "function") {
      onSuccess(res);
    }
  } catch (err) {
    const { status } = err || {};
    if (status >= 400) {
      Alert.alert("Utop", err?.data?.message);
    }
    yield put(getListShortcutFailed(err));
  }
}

export function* saveDeviceInfoSaga(obj) {
  const { deviceInfo, onSuccess, onError } = obj;
  try {
    // const sessionId = yield select(getSessionIdSelector);
    const res = yield callApi(saveDeviceInfoApi, {
      deviceInfo,
    });
    yield put(saveDeviceInfoSuccess(res));
    if (typeof onSuccess === "function") {
      onSuccess(res);
    }
  } catch (err) {
    const { status } = err || {};
    if (status >= 400) {
      Alert.alert("Utop", err?.data?.message);
    }
    if (typeof onError === "function") {
      onError(err);
    }
    yield put(saveDeviceInfoFailed(err));
  }
}

export function* getDynamicPageSaga(obj) {
  const { pageId, onSuccess } = obj;
  try {
    const pageContent = yield callApi(getDynamicPageApi, {
      pageId: pageId,
    });
    onSuccess(pageContent.data);
  } catch (error) {
    const { status } = error || {};
    if (status >= 400) {
      Alert.alert("Utop", error?.data?.message);
    }
    yield put(getDynamicPageFailed(error));
  }
}

export function* getListHotMerchantSaga(action) {
  try {
    const res = yield callApi(getListHotMerchantApi);
    yield put(getListHotMerchantSuccess(res?.data?.items));
  } catch (error) {
    const { status } = error || {};
    if (status >= 400) {
      Alert.alert("Utop", error?.data?.message);
    }
    yield put(getListHotMerchantFailed(error));
  }
}

export function* getDynamicModalConfigSaga(payload) {
  const { configPayload, onSuccess, onError } = payload;
  try {
    const responseDynamicModalConfig = yield getDynamicModalConfigApi(
      configPayload
    );
    //   configPayload,
    // });
    const parsedListCampaign = listCampaignParser(
      responseDynamicModalConfig.data
    );
    yield put(getDynamicModalSuccess({ data: parsedListCampaign }));
    onSuccess({ data: parsedListCampaign });
  } catch (error) {
    onError(error);
    yield put(getDynamicModalFailed(error));
  }
}

export function* actionCampaignPressSaga(action) {
  const { payload, onSuccess, onError } = action;
  try {
    let response = {};
    switch (payload.data.method) {
      case "GET":
        response = yield APIUtils.get(payload.data.url);
        break;
      case "PUT":
        response = yield APIUtils.put(payload.data.url);
        break;
      case "DELETE":
        response = yield APIUtils.delete(payload.data.url);
        break;
      default:
        response = yield APIUtils.post(payload.data.url, {
          body: { ...payload.data.data },
        });
    }
    // throw {message: 'Đã có lỗi xảy ra', durationTime: 5000, position: 'bottom'};
    // const res = yield getDumpData('');
    // yield delay(3000);
    yield put(actionCampaignPressSuccess());
    onSuccess(response);
  } catch (error) {
    if (error.data?.error) {
      onError({ code: error.data.error.code });
      yield put(actionCampaignPressFailure(error));
      return;
    }
    if (error.message?.indexOf("Network request failed") > -1) {
      onError({ code: ERROR_CODE.LOST_CONNECTION });
      yield put(actionCampaignPressFailure(error));
    } else {
      onError({ code: ERROR_CODE.SOMETHING_WENT_WRONG });
      yield put(actionCampaignPressFailure(error));
    }
  }
}

export function* getSuggestBannerSaga(action) {
  const { payload, onSuccess, onError } = action;
  try {
    const res = yield callApi(getSuggestBannerApi, payload);
    if (res?.data) {
      onSuccess?.(res.data);
    }
    yield put(getSuggestBannerSuccess(res?.data));
  } catch (error) {
    put(getSuggestBannerFailure(error));
    onError?.(error);
  }
}

export function* getPersonalizeShortcutSaga(action) {
  try {
    const res = yield callApi(getPersonalizeShortcutApi);
    let listShortcut = listPersonalizeShortcutParser(res?.data);
    yield put(getPersonalizeShortcutSuccess(listShortcut));
  } catch (error) {
    put(getPersonalizeShortcutFailure(error));
  }
}

export function* shortUrlSaga(action) {
  const { payload, onSuccess, onError } = action;
  try {
    const res = yield callApi(shortUrlApi, payload);
    if (res?.data) {
      onSuccess?.(res.data);
    }
  } catch (error) {
    onError?.(error);
  }
}

export function* getLovSaga(action) {
  const { payload, onSuccess, onError } = action;
  try {
    const res = yield callApi(getLovApi, payload);
    const locale = yield getCurrentLocale();
    const resLovParsed = lovParser(res.data[0], locale);
    if (res?.data) {
      onSuccess?.(resLovParsed || {});
    }
  } catch (error) {
    onError?.(error);
    yield put(getLovFailure(error));
  }
}

function* watchCommon() {
  yield takeLatest(GET_LIST_BANNER_REQUEST, getListBannerSaga);
  yield takeLatest(
    GET_LIST_BANNER_BY_LOCATION_REQUEST,
    getListBannerByLocationSaga
  );
  yield takeEvery(GET_LIST_BANNER_DYNAMIC_REQUEST, getListBannerDynamicSaga);
  yield takeLatest(GET_LIST_SHORTCUT_REQUEST, getListShortcutSaga);
  yield takeLatest(SAVE_DEVICE_INFO_REQUEST, saveDeviceInfoSaga);
  yield takeLatest(GET_DYNAMIC_PAGE_REQUEST, getDynamicPageSaga);
  yield takeLatest(GET_LIST_HOT_MERCHANT_REQUEST, getListHotMerchantSaga);
  yield takeLatest(GET_DYNAMIC_MODAL_REQUEST, getDynamicModalConfigSaga);
  yield takeLatest(ACTION_CAMPAIGN_PRESS_REQUEST, actionCampaignPressSaga);
  yield takeLatest(GET_SUGGEST_BANNER_REQUEST, getSuggestBannerSaga);
  yield takeLatest(SHORT_URL_REQUEST, shortUrlSaga);
  yield takeLatest(
    GET_PERSONALIZE_SHORTCUT_REQUEST,
    getPersonalizeShortcutSaga
  );
  yield takeLatest(GET_LOV.HANDLER, getLovSaga);
}

export default function* rootChild() {
  yield fork(watchCommon);
}
