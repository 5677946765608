import { put, takeLatest, fork, call } from 'redux-saga/effects'
import {
  GET_LIST_VOUCHER_BY_STORE_REQUEST,
  GET_MERCHANT_INFO_REQUEST,
  GET_STORE_DETAIL_REQUEST,
  GET_LIST_COUPON_SUGGEST_REQUEST,
  GET_LIST_BEST_SELLER_REQUEST,
  GET_LIST_VIDEO_STORE_REQUEST,
  GET_STORE_DETAIL_FROM_STORE_CODE_REQUEST,
} from './constants'
import {
  getListVoucherByStoreFailed,
  getListVoucherByStoreSuccess,
  getMerchantInfoFailed,
  getMerchantInfoSuccess,
  getStoreDetailFailed,
  getStoreDetailSuccess,
  getListCouponSuggestSuccess,
  getListCouponSuggestFailed,
  getListBestSellerSuccess,
  getListBestSellerFailed,
  getListVideoStoreSuccess,
  getListVideoStoreFailed,
} from './actions'
import {
  getListVoucherByStoreApi,
  getMerchantInfoApi,
  getStoreDetailApi,
  getListCouponSuggestApi,
  getListBestSellerApi,
  getStoreDetailFromStoreCodeApi,
} from '@shared/services/api/storeDetail'
import { callApi } from 'helpers/callApi'
import { APIUtils } from 'utils'
import { END_POINTS } from '@shared/constants/apiUrl'

export function* getListVoucherByStoreSaga(obj) {
  const { payload = {}, onSuccess, onFailed } = obj
  try {
    const res = yield callApi(getListVoucherByStoreApi, {
      ...payload,
    })
    if (res?.data?.items.length > 0) {
      yield put(
        getListVoucherByStoreSuccess({
          data: res,
          merchantId: payload.merchantId,
        }),
      )
    } else {
      yield put(getListVoucherByStoreFailed(null))
    }

    if (typeof onSuccess === 'function') {
      onSuccess(res)
    }
  } catch (err) {
    onFailed()
    yield put(getListVoucherByStoreFailed(err))
  }
}

export function* getMerchantInfoSaga(obj) {
  const { payload = {}, onSuccess, onFailed } = obj
  try {
    const res = yield callApi(getMerchantInfoApi, {
      payload,
    })
    yield put(
      getMerchantInfoSuccess({
        data: res,
        merchantId: payload,
      }),
    )
    if (typeof onSuccess === 'function') {
      onSuccess(res)
    }
  } catch (err) {
    if (typeof onFailed === 'function') {
      onFailed(err)
    }
    yield put(getMerchantInfoFailed(err))
  }
}

export function* getStoreDetailSaga(obj) {
  const { payload = {}, onSuccess, onFailed } = obj
  try {
    const res = yield callApi(getStoreDetailApi, {
      payload,
    })
    yield put(
      getStoreDetailSuccess({
        data: res,
      }),
    )
    if (typeof onSuccess === 'function') {
      onSuccess(res)
    }
  } catch (err) {
    if (typeof onFailed === 'function') {
      onFailed(err)
    }
    yield put(getStoreDetailFailed(err))
  }
}

export function* getListCouponSuggestSaga(obj) {
  const { payload = {}, onSuccess, onFailed } = obj
  try {
    const res = yield callApi(getListCouponSuggestApi, {
      ...payload,
    })
    if (res?.data?.suggestedCouponDetails) {
      res.data.suggestedCouponDetails.storeId = payload.storeId
      res.data.suggestedCouponDetails.merchantId = payload.merchantId
    }
    if (payload?.isFromMarket || payload?.feature === undefined) {
      yield put(
        getListCouponSuggestSuccess({
          data: res?.data?.suggestedCouponDetails || [],
        }),
      )
    }

    if (typeof onSuccess === 'function') {
      onSuccess(res)
    }
  } catch (err) {
    if (typeof onFailed === 'function') {
      onFailed()
    }
    yield put(getListCouponSuggestFailed(err))
  }
}

export function* getListBestSellerSaga(obj) {
  const { payload = {}, onSuccess, onFailed } = obj
  if (payload.isEnableFNB === false) {
    if (typeof onSuccess === 'function') {
      onSuccess({ data: {} })
    }
    return
  }
  try {
    const res = yield callApi(getListBestSellerApi, payload.merchantId)
    yield put(
      getListBestSellerSuccess({
        data: res,
        merchantId: payload.merchantId,
      }),
    )
    if (typeof onSuccess === 'function') {
      onSuccess(res)
    }
  } catch (err) {
    if (typeof onFailed === 'function') {
      onFailed()
    }
    yield put(getListBestSellerFailed(err))
  }
}

async function listVideoStoreAPI({ storeId, skip }, renewToken) {
  const header = { Authorization: 'Bearer ' + renewToken }
  const params = {
    StoreId: storeId,
    SkipCount: skip,
    MaxResult: 12,
  }
  return APIUtils.get(END_POINTS.ListVideoStore, { params, headers: header })
}

export function* getListVideoStoreSaga(obj) {
  const { onSuccess, skip } = obj.payload
  try {
    const renewToken = yield APIUtils.reauthenticate()
    const data = yield call(listVideoStoreAPI, obj.payload, renewToken)
    if (data.status === 200) {
      yield put(getListVideoStoreSuccess([...obj.payload.list, ...data.data]))
      if (typeof onSuccess === 'function') {
        onSuccess(data.data, skip)
      }
    } else {
      yield put(getListVideoStoreFailed(data))
    }
  } catch (err) {
    yield put(getListVideoStoreFailed(err))
  }
}

export function* getStoreDetailFromStoreCodeSaga(obj) {
  const { payload = {}, onSuccess, onFailed } = obj
  try {
    const res = yield callApi(getStoreDetailFromStoreCodeApi, {
      payload,
    })
    if (typeof onSuccess === 'function') {
      onSuccess(res)
    }
  } catch (err) {
    onFailed()
  }
}
function* watchCommon() {
  yield takeLatest(GET_STORE_DETAIL_REQUEST, getStoreDetailSaga)
  yield takeLatest(
    GET_STORE_DETAIL_FROM_STORE_CODE_REQUEST,
    getStoreDetailFromStoreCodeSaga,
  )

  yield takeLatest(GET_MERCHANT_INFO_REQUEST, getMerchantInfoSaga)
  yield takeLatest(GET_LIST_VOUCHER_BY_STORE_REQUEST, getListVoucherByStoreSaga)
  yield takeLatest(GET_LIST_COUPON_SUGGEST_REQUEST, getListCouponSuggestSaga)
  yield takeLatest(GET_LIST_BEST_SELLER_REQUEST, getListBestSellerSaga)
  yield takeLatest(GET_LIST_VIDEO_STORE_REQUEST, getListVideoStoreSaga)
}

export default function* rootChild() {
  yield fork(watchCommon)
}
