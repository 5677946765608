import {GET_USER_INFO_INSURANCE} from './constants';

export const createActionGetUserInfoHandle = (onSuccess, onError) => ({
  type: GET_USER_INFO_INSURANCE.HANDLER,
  onSuccess,
  onError,
});

export const createActionGetUserInfoSuccess = payload => ({
  type: GET_USER_INFO_INSURANCE.SUCCESS,
  payload,
});

export const createActionGetUserInfoFailure = error => ({
  type: GET_USER_INFO_INSURANCE.FAILURE,
  error,
});
