import { callApi } from "helpers/callApi";
import {
  getStatusOrderDetailApi,
  pushCancelOrderApi,
} from "@shared/services/api/order";
import {
  createOrderNewsApi,
  getStatusOrderDetailNewsApi,
  pushCancelOrderNewsApi,
} from "@shared/services/api/order";
import { fork, put, takeLeading, takeEvery, call } from "redux-saga/effects";
import {
  getStatusOrderDetailFailed,
  getStatusOrderDetailSuccess,
  pushCancelOrderFailed,
  pushCancelOrderSuccess,
  createOrderNewspaperNewsFailed,
  createOrderNewspaperNewsSuccess,
  getStatusOrderDetailNewsFailed,
  getStatusOrderDetailNewsSuccess,
  pushCancelOrderNewsFailed,
  pushCancelOrderNewsSuccess,
} from "./actions";
import {
  GET_STATUS_ORDER_DETAIL,
  PUSH_CANCEL_ORDER,
  CREATE_ORDER_NEWSPAPER_NEWS,
  GET_STATUS_ORDER_DETAIL_NEWS,
  PUSH_CANCEL_ORDER_NEWS,
} from "./constants";

export function* getStatusOrderDetailSaga(action) {
  const { onSuccess, orderId, onFailed } = action;
  try {
    const res = yield callApi(getStatusOrderDetailApi, { orderId });
    yield put(getStatusOrderDetailSuccess());
    onSuccess?.(res?.data);
  } catch (error) {
    onFailed?.();
    yield put(getStatusOrderDetailFailed(error));
  }
}

export function* pushCancelOrderSaga(action) {
  const { orderId, onSuccess, onFailed } = action;
  try {
    const res = yield call(pushCancelOrderApi, { orderId: orderId });
    yield put(pushCancelOrderSuccess());
    onSuccess?.(res);
  } catch (error) {
    onFailed?.();
    yield put(pushCancelOrderFailed(error));
  }
}

export function* createOrderNewspaperNewsSaga(action) {
  const { newspaperInfo, onSuccess, onFailed } = action;
  try {
    const repData = yield callApi(createOrderNewsApi, newspaperInfo);
    yield put(createOrderNewspaperNewsSuccess(repData));
    onSuccess?.(repData);
  } catch (error) {
    onFailed?.(error);
    yield put(createOrderNewspaperNewsFailed(error));
  }
}

export function* getStatusOrderDetailNewsSaga(action) {
  const { onSuccess, orderId, onFailed } = action;
  try {
    const res = yield callApi(getStatusOrderDetailNewsApi, { orderId });
    yield put(getStatusOrderDetailNewsSuccess());
    onSuccess?.(res?.data);
  } catch (error) {
    onFailed?.();
    yield put(getStatusOrderDetailNewsFailed(error));
  }
}

export function* pushCancelOrderNewsSaga(action) {
  const { orderId, onSuccess, onFailed } = action;
  try {
    const res = yield call(pushCancelOrderNewsApi, { orderId: orderId });
    yield put(pushCancelOrderNewsSuccess());
    onSuccess?.(res);
  } catch (error) {
    onFailed?.();
    yield put(pushCancelOrderNewsFailed(error));
  }
}

function* watchOrderStatusSaga() {
  yield takeEvery(GET_STATUS_ORDER_DETAIL.HANDLER, getStatusOrderDetailSaga);
  yield takeEvery(PUSH_CANCEL_ORDER.HANDLER, pushCancelOrderSaga);
  yield takeEvery(
    GET_STATUS_ORDER_DETAIL_NEWS.HANDLER,
    getStatusOrderDetailNewsSaga
  );
  yield takeLeading(PUSH_CANCEL_ORDER_NEWS.HANDLER, pushCancelOrderNewsSaga);
  yield takeLeading(
    CREATE_ORDER_NEWSPAPER_NEWS.HANDLER,
    createOrderNewspaperNewsSaga
  );
}

export default function* rootChild() {
  yield fork(watchOrderStatusSaga);
}
