import {
  SET_SHOW_GUIDE_PAYMENT,
  SET_SHOW_GUIDE_PAYMENT_UB,
  SET_SHOW_GUIDE_PAYMENT_BY_POINT,
  SET_LIST_PAYMENT_RECENTLY,
  CLEAR_LIST_PAYMENT_RECENTLY,
  UPDATE_NOTICATION_RECEIVE_PAYMENT_QR,
  CLEAR_NOTICATION_RECEIVE_PAYMENT_QR,
} from './constants';

const initialState = {
  isShowGuidePayment: true,
  isShowGuidePaymentUB: true,
  isShowGuidePaymentByPoint: true,
  listPaymentRecently: [],
  dataNotificationPayMentQR: {},
};

const qrpayReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SHOW_GUIDE_PAYMENT:
      return {
        ...state,
        isShowGuidePayment: false,
      };
    case SET_SHOW_GUIDE_PAYMENT_UB:
      return {
        ...state,
        isShowGuidePaymentUB: false,
      };
    case SET_SHOW_GUIDE_PAYMENT_BY_POINT:
      return {
        ...state,
        isShowGuidePaymentByPoint: false,
      };
    case SET_LIST_PAYMENT_RECENTLY: {
      return {
        ...state,
        listPaymentRecently: action.listPaymentRecently,
      };
    }
    case CLEAR_LIST_PAYMENT_RECENTLY: {
      return {
        ...state,
        listPaymentRecently: [],
      };
    }
    case UPDATE_NOTICATION_RECEIVE_PAYMENT_QR: {
      return {
        ...state,
        dataNotificationPayMentQR: action.payload,
      };
    }
    case CLEAR_NOTICATION_RECEIVE_PAYMENT_QR: {
      return {
        ...state,
        dataNotificationPayMentQR: {},
      };
    }
    default:
      return state;
  }
};

export default qrpayReducer;
