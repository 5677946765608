import classNames from "classnames";
import React, { useEffect, useState } from "react";
import "./SwitchButton.scss";

const SwitchButton = ({
  isChecked = false,
  onToggle = () => {},
  className,
}) => {
  const [isActive, setIsActive] = useState(false);

  const handleToggleSwitch = () => {
    setIsActive(!isActive);

    if (onToggle) {
      onToggle();
    }
  };

  useEffect(() => {
    setIsActive(isChecked);
  }, [isChecked]);

  return (
    <div className={classNames("switch-button--common", className)}>
      <label className="p-0 m-0">
        <input
          checked={isActive}
          onChange={handleToggleSwitch}
          className="switch"
          type="checkbox"
        />
        <div>
          <div />
        </div>
      </label>
    </div>
  );
};

export default SwitchButton;
