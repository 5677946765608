import {
  LIST_FAVORITE_SUCCESS,
  LIST_FAVORITE_REQUEST,
  LIST_FAVORITE_FAILED,
} from './types'

export const listFavoriteRequest = (payload) => ({
  type: LIST_FAVORITE_REQUEST,
  payload,
})

export const listFavoriteSuccess = (payload) => ({
  type: LIST_FAVORITE_SUCCESS,
  payload,
})

export const listFavoriteFailure = (payload) => ({
  type: LIST_FAVORITE_FAILED,
  payload,
})
