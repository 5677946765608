/* eslint-disable no-restricted-syntax */
export const getCategories = (items) => {
  // todo: dùng cấu trúc dữ liệu Set
  // cứ add categories vào, ko cần lo trùng
  const categoriesList = [];

  for (const item of items) {
    const alreadyExistedCategory = categoriesList?.find(
      (cat) => cat?.code === item?.data?.categories?.iv?.[0]?.flatData?.code
    );

    if (!alreadyExistedCategory) {
      categoriesList.push(item?.data?.categories?.iv?.[0]?.flatData);
    }
  }

  const categoriesListSortedByPriority = categoriesList
    .filter(Boolean)
    .sort((firstEle, secondEle) => secondEle.priority - firstEle.priority);

  return categoriesListSortedByPriority;
};
