import { put, takeLatest, fork } from "redux-saga/effects";
import {
  CHECK_NUMBER_TRANSFER,
  GET_UTOP_BALANCE,
  TRANSFER_UTOP,
  SET_PIN_CODE,
  CREATE_ORDER,
  CREATE_ORDER_UTOP_HUB,
  GET_PAYMENT_INFO,
} from "./constants";

import {
  checkNumberTransferAPI,
  getUtopBalanceAPI,
  transferUtopAPI,
  setPinCodeAPI,
  createOrderAPI,
  createOrderUtopHubAPI,
  getPaymentInfoApi,
} from "@shared/services/api/payment";
import { callApi } from "helpers/callApi";
import { getPaymentInfoFailure, getPaymentInfoSuccess } from "./actions";

export function* checkNumberTransferSaga(obj) {
  const { payload = {}, onSuccess, onError } = obj;

  try {
    const res = yield callApi(checkNumberTransferAPI, payload);
    // console.log('onSuccess');
    // console.log(res);
    if (typeof onSuccess === "function") {
      onSuccess(res);
    }
  } catch (err) {
    if (typeof onError === "function") {
      onError(err);
    }
  }
}

export function* setPinCodeSaga(obj) {
  const { payload = {}, onSuccess, onError } = obj;

  try {
    const res = yield callApi(setPinCodeAPI, payload);
    // console.log('onSuccess');
    // console.log(res);
    if (typeof onSuccess === "function") {
      onSuccess(res);
    }
  } catch (err) {
    if (typeof onError === "function") {
      onError(err);
    }
  }
}
export function* getUtopBalanceSaga(obj) {
  const { payload = {}, onSuccess, onError } = obj;

  try {
    const res = yield callApi(getUtopBalanceAPI, payload);
    console.log("onSuccess");
    console.log(res);
    if (typeof onSuccess === "function") {
      onSuccess(res);
    }
  } catch (err) {
    if (typeof onError === "function") {
      onError(err);
    }
  }
}
export function* transferUtopSaga(obj) {
  const { payload = {}, onSuccess, onError } = obj;
  try {
    const res = yield callApi(transferUtopAPI, payload);
    if (typeof onSuccess === "function") {
      onSuccess(res);
    }
  } catch (err) {
    if (typeof onError === "function") {
      onError(err);
    }
  }
}
export function* createOrderSaga(obj) {
  const { payload = {}, onSuccess, onError } = obj;

  try {
    const res = yield callApi(createOrderAPI, payload);
    if (typeof onSuccess === "function") {
      onSuccess(res);
    }
  } catch (err) {
    if (typeof onError === "function") {
      onError(err);
    }
  }
}
export function* createOrderUtopHubSaga(obj) {
  const { payload = {}, onSuccess, onError } = obj;

  try {
    const res = yield callApi(createOrderUtopHubAPI, payload);
    console.log(res);
    if (typeof onSuccess === "function") {
      onSuccess(res);
    }
  } catch (err) {
    if (typeof onError === "function") {
      onError(err);
    }
  }
}

export function* getPaymentInfoSaga(action) {
  const { payload = {}, onSuccess, onError } = action;

  try {
    const res = yield callApi(getPaymentInfoApi, payload);
    if (typeof onSuccess === "function") {
      onSuccess(res);
    }
    yield put(getPaymentInfoSuccess());
  } catch (err) {
    if (typeof onError === "function") {
      onError(err);
    }
    yield put(getPaymentInfoFailure());
  }
}

function* watchUser() {
  yield takeLatest(CHECK_NUMBER_TRANSFER, checkNumberTransferSaga);
  yield takeLatest(GET_UTOP_BALANCE, getUtopBalanceSaga);
  yield takeLatest(TRANSFER_UTOP, transferUtopSaga);
  yield takeLatest(SET_PIN_CODE, setPinCodeSaga);
  yield takeLatest(CREATE_ORDER, createOrderSaga);
  yield takeLatest(CREATE_ORDER_UTOP_HUB, createOrderUtopHubSaga);
  yield takeLatest(GET_PAYMENT_INFO.HANDLER, getPaymentInfoSaga);
}

export default function* rootChild() {
  yield fork(watchUser);
}
