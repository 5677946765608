/* eslint-disable prefer-template */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import {
  ShareSolidYellowIcon,
  CellPhoneIcon,
  // ExclamationIcon,
  // ReceiveFoodOutlineIcon,
} from "assets/svg";
import React, { useCallback, useState, useEffect, useRef } from "react";
import Skeleton from "react-loading-skeleton";

import IMAGES from "assets/img";

import { IconButton, ImageShow } from "../../../../../common/component";
import CustomSlider from "../CustomSlider";

import "./StoreInfo.scss";

function StoreInfo(props) {
  const {
    isLoading,
    images = [],
    merchantImages = [],
    name,
    description,
    phoneNumber,
    // avgRate,
    // deliveryOptions,

    // * actions
    handleShareStore,
  } = props;

  const [isShowStory, setIsShowStory] = useState(false);
  // * Open direction mode

  // *** RENDER FUNCTIONS

  useEffect(() => {
    if (isShowStory) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }
  }, [isShowStory]);

  const renderSlider = useCallback(() => {
    const items = [];

    if (Array.isArray(images) && images.length > 0) {
      items.push(...images);
    }

    const formatItems = (arr) => {
      return arr
        .map((i) => ({
          imageUrl: i.imageUrl,
          isFeatured: i.isFeatured,
        }))
        .filter((f) => !f.isFeatured);
    };

    return (
      <CustomSlider
        items={formatItems(items)}
        sliderClassName="store-info__slider"
        imgClassName="store-info__slider-img"
      />
    );
  }, [isLoading, images, merchantImages]);

  const checkImageUrl = (image) => {
    if (image !== undefined && image.length > 0) {
      const listAvatar = image.filter((item) => item.isFeatured === true);
      if (listAvatar.length > 0) {
        return listAvatar[0].imageUrl || "";
      }
      return "";
    }
    return "";
  };

  const renderSkeleton = () => {
    return (
      <div className="store-detail-header_container">
        <div className="store-banner_fnb">
          <Skeleton width="100%" height="350px" />
        </div>

        <div
          style={{
            height: 310, // *** offset để show slider phía sau, nếu dùng padding sẽ ko vuốt được
          }}
        />

        <div className="store__head__main-content">
          <div className="store-info-card__container">
            <div className="store-info-card__content-wrapper">
              <div className="store-info-card__top--over-top">
                <Skeleton width="16ch" height={30} />
              </div>

              <div className="store-info-card__body">
                <div className="name">
                  <Skeleton width="75%" />
                </div>

                <div>
                  <Skeleton width="12ch" />
                </div>

                <div>
                  <Skeleton width="40%" />
                </div>

                <div>
                  <Skeleton width="80%" />
                </div>
              </div>
            </div>
          </div>

          <div className="store-info-card__tags hide-x-scrollbar">
            <Skeleton width="15ch" height="16px" />
            <Skeleton width="10ch" height="16px" />
            <Skeleton width="7ch" height="16px" />
          </div>
        </div>
      </div>
    );
  };

  if (isLoading) {
    return renderSkeleton();
  }

  return (
    <div className="store-detail-header_container">
      <div
        id="store-detail-info-slider" // for control opacity when scroll page (handle in /storedetail)
        className="store-banner_fnb"
      >
        {renderSlider()}
      </div>

      {/* // *** offset để show slider phía sau, nếu dùng padding sẽ ko vuốt được */}
      {/* <div style={{ height: 310 }} /> */}

      <div id="store__head__main-content" className="store__head__main-content">
        <div className="store-info-card__container_1">
          <div className="store-info-card__content-wrapper">
            <div className="store-info-card__body">
              <div className="name-button__wrapper">
                {phoneNumber && (
                  <div
                    className="content-store-info-contact_icon-right"
                    style={{ marginRight: "10px" }}
                  >
                    <a href={`tel:${phoneNumber}`}>
                      <IconButton>
                        <CellPhoneIcon />
                      </IconButton>
                    </a>
                  </div>
                )}
                <IconButton
                  className="content-store-info-contact_icon-right"
                  onClick={handleShareStore}
                >
                  <ShareSolidYellowIcon />
                </IconButton>
              </div>
              <div className="avatar">
                <div
                  className="content-avatar"
                  onClick={() => setIsShowStory(true)}
                >
                  <object
                    className="avatar-img"
                    data={checkImageUrl(images) || IMAGES.LogoUtop2}
                    type="image/png"
                  >
                    <img className="avatar-img" src={IMAGES.LogoUtop2} alt="" />
                  </object>
                </div>
              </div>
              <div className="name-store">
                <p style={{ textAlign: "center" }}>
                  <b>{name}</b>
                </p>
              </div>
              <p
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: description,
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <ImageShow
        isShowStory={isShowStory}
        closeShowStory={() => setIsShowStory(false)}
        image={images}
      />
    </div>
  );
}

export default StoreInfo;
