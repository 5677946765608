import { call, put, takeLatest, fork } from 'redux-saga/effects'
import { LIST_FAVORITE_REQUEST } from './types'
import { END_POINTS } from '@shared/constants/apiUrl'
import { listFavoriteSuccess, listFavoriteFailure } from './actions'
import { APIUtils } from 'utils'

function listFavoriteVideoApi({ skipCount, maxResult }) {
  const requestData = `?SkipCount=${skipCount}&MaxResult=${maxResult}`
  return APIUtils.get(END_POINTS.FavoriteVideo + requestData)
}

export function* listFavoriteVideo(obj) {
  try {
    const data = yield call(listFavoriteVideoApi, obj.payload)
    const { onSuccess } = obj.payload
    if (Array.isArray(data)) {
      yield put(listFavoriteSuccess([...obj.payload.list, ...data]))
      if (typeof onSuccess === 'function') {
        onSuccess(data)
      }
    } else {
      yield put(listFavoriteFailure(data))
    }
  } catch (err) {
    yield put(listFavoriteFailure(err))
  }
}

function* watchListFavorite() {
  yield takeLatest(LIST_FAVORITE_REQUEST, listFavoriteVideo)
}

export default function* rootChild() {
  yield fork(watchListFavorite)
}
