/* eslint-disable no-unused-vars */
import React from "react";
import { IconRatingNoUser } from "assets/svg";
import PropTypes from "prop-types";
import { useHistory } from "react-router";
import PopupRating from "views/common/component/PopupRating";
import classNames from "classnames";

const FirstRater = ({
  title,
  buttonContent,
  href,
  titleRate,
  onClick,
  showModal,
  handleRatingStar,
  hasButton = true,
  iconFirstRater,
  addMoreClassName,
  addClassNameForDistance,
}) => {
  const history = useHistory();

  const handleClick = () => {
    if (href !== "") {
      return history.push(href);
    }
    if (onClick) {
      onClick();
    }
  };
  return (
    <div
      className={classNames("rating-content__require-login", addMoreClassName)}
    >
      {titleRate && (
        <div className="rating-content__require-login__rating-text">
          {titleRate}
        </div>
      )}

      <div
        className={classNames(
          "rating-content__require-login__rating-require-login",
          addClassNameForDistance
        )}
      >
        {iconFirstRater}
        <div className="rating-content__require-login__rating-require-login__text-login">
          {title}
        </div>
        {hasButton ? (
          <div className="rating-content__require-login__rating-require-login__button">
            <button type="button" onClick={handleClick}>
              {buttonContent}
            </button>
          </div>
        ) : null}
      </div>
      <PopupRating showModal={showModal} handleRatingStar={handleRatingStar} />
    </div>
  );
};
FirstRater.defaultProps = {
  title: " Vui lòng đăng nhập để xem đánh giá của cửa hàng",
  buttonContent: "Đăng nhập",
  href: "/",
  titleRate: "Đánh giá",
  showModal: false,
  hasButton: true,
  iconFirstRater: <IconRatingNoUser />,
  addMoreClassName: "",
  addClassNameForDistance: "",
};
FirstRater.propTypes = {
  title: PropTypes.string,
  buttonContent: PropTypes.string,
  href: PropTypes.string,
  titleRate: PropTypes.string,
  showModal: PropTypes.bool,
  hasButton: PropTypes.bool,
  iconFirstRater: PropTypes.element,
  addMoreClassName: PropTypes.string,
  addClassNameForDistance: PropTypes.string,
};
export default FirstRater;
