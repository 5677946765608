import { addErrors, delErrors } from "utils/ErrorsGenerator";
import * as TYPE from "./constants";

const reducers = {
  [TYPE.GET_LIST_COUPON_MY_VOUCHER.request]: (draft) => {
    return {
      ...draft,
      isLoading: {
        ...draft.isLoading,
        GET_LIST_COUPON_MY_VOUCHER: true,
      },
    };
  },
  [TYPE.GET_LIST_COUPON_MY_VOUCHER.success]: (draft, { payload }) => {
    if (payload?.status === "Redeem") {
      return {
        ...draft,
        errors: delErrors(
          draft.errors,
          TYPE.GET_LIST_COUPON_MY_VOUCHER.failure
        ),
        voucherNotExp: {
          isInit: false,
          value: payload?.data,
        },

        isLoading: {
          ...draft.isLoading,
          GET_LIST_COUPON_MY_VOUCHER: false,
        },
      };
    }
    if (payload?.status === "Used") {
      return {
        ...draft,
        errors: delErrors(
          draft.errors,
          TYPE.GET_LIST_COUPON_MY_VOUCHER.failure
        ),
        voucherExp: {
          isInit: false,
          value: payload?.data,
        },
        isLoading: {
          ...draft.isLoading,
          GET_LIST_COUPON_MY_VOUCHER: false,
        },
      };
    }
  },
  [TYPE.GET_LIST_COUPON_MY_VOUCHER.failure]: (draft, { payload }) => {
    return {
      ...draft,
      errors: addErrors(draft.errors, {
        type: TYPE.GET_LIST_COUPON_MY_VOUCHER.failure,
        msg: payload,
      }),
      isLoading: {
        ...draft.isLoading,
        GET_LIST_COUPON_MY_VOUCHER: false,
      },
    };
  },
};

export default reducers;
