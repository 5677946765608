import {
  GET_SUGGEST_COUPON_SUCCESS,
  GET_SUGGEST_COUPON_FAILED,
  ALLOWCATION_COUPON_FAILED,
  ALLOWCATION_COUPON_SUCCESS,
  ADD_PRODUCT_REQUEST,
  DELETE_PRODUCT_REQUEST,
  CLEAR_ERRORS,
  SET_DESCRIPTION,
  SET_DELIVERY_ADDRESS,
  SET_VOUCHER,
  SET_DISCOUNT,
  SET_NUMBER_TABLE,
  UPDATE_VOUCHER,
  CLEAR_ALLOWCATE_COUPON,
  SET_TAKE_AWAY,
  SET_DELIVERY_ADDRESS_SELECTED,
  GET_SUGGEST_DISCOUNT_SHIPPING_FAILED,
  GET_SUGGEST_DISCOUNT_SHIPPING_SUCCESS,
} from './constants';
import {CLEAR_PRODUCT_CART, RESET_PRODUCT_CART} from '../smartMenu/constants';

const initialState = {
  listSuggestCoupon: [],
  messageError: '',
  allowcationCoupon: {},
  listProduct: [],
  voucher: null,
  description: '',
  deliveryAddress: [],
  discount: 0,
  valueNumberTable: '',
  takeAway: false,
  listSuggestDiscountShipping: [],
};
const initialStateResetCard = {
  listSuggestCoupon: [],
  messageError: '',
  allowcationCoupon: {},
  listProduct: [],
  voucher: null,
  description: '',
  discount: 0,
  valueNumberTable: '',
  takeAway: false,
  deliveryAddressSelected: null,
};
const initialStateNotClearCoupon = {
  listSuggestCoupon: [],
  messageError: '',
  listProduct: [],
  description: '',
  discount: 0,
  valueNumberTable: '',
  voucher: null,
  takeAway: false,
};

const cartReducer = (state = initialState, action) => {
  const {payload, type} = action;

  switch (type) {
    case SET_NUMBER_TABLE: {
      return {
        ...state,
        valueNumberTable: payload,
      };
    }
    case SET_TAKE_AWAY: {
      return {
        ...state,
        takeAway: payload,
      };
    }
    case RESET_PRODUCT_CART: {
      if (payload.isNotClearCoupon) {
        return {...state, ...initialStateNotClearCoupon};
      }
      return {...state, ...initialStateResetCard};
    }

    case CLEAR_PRODUCT_CART: {
      return {...state, ...initialStateResetCard};
    }

    case SET_DISCOUNT: {
      return {
        ...state,
        discount: payload,
      };
    }

    case SET_VOUCHER: {
      return {
        ...state,
        voucher: payload,
      };
    }

    case UPDATE_VOUCHER: {
      return {
        ...state,
        voucher: {...state.voucher, isCouponError: payload},
      };
    }

    case SET_DESCRIPTION: {
      return {
        ...state,
        description: payload,
      };
    }

    case SET_DELIVERY_ADDRESS: {
      return {
        ...state,
        deliveryAddress: payload,
      };
    }

    case GET_SUGGEST_COUPON_SUCCESS: {
      return {
        ...state,
        listSuggestCoupon: payload,
      };
    }

    case GET_SUGGEST_COUPON_FAILED: {
      return {
        ...state,
        messageError: payload,
      };
    }
    case ALLOWCATION_COUPON_FAILED: {
      return {
        ...state,
        messageError: payload,
      };
    }
    case ALLOWCATION_COUPON_SUCCESS: {
      return {
        ...state,
        allowcationCoupon: payload,
      };
    }
    case CLEAR_ERRORS: {
      return {
        ...state,
        messageError: '',
      };
    }
    case ADD_PRODUCT_REQUEST: {
      return {
        ...state,
        listProduct: [...state.listProduct, ...payload],
      };
    }
    case DELETE_PRODUCT_REQUEST: {
      return {
        ...state,
        listProduct: state.listProduct.filter(
          (ite, idx) => ite.id + idx !== payload,
        ),
      };
    }
    case CLEAR_ALLOWCATE_COUPON: {
      return {
        ...state,
        allowcationCoupon: null,
      };
    }
    case SET_DELIVERY_ADDRESS_SELECTED: {
      return {
        ...state,
        deliveryAddressSelected: payload,
      };
    }

    case GET_SUGGEST_DISCOUNT_SHIPPING_FAILED: {
      return {
        ...state,
        listSuggestDiscountShipping: payload,
      };
    }

    case GET_SUGGEST_DISCOUNT_SHIPPING_SUCCESS: {
      return {
        ...state,
        listSuggestDiscountShipping: payload,
      };
    }

    default:
      return state;
  }
};

export default cartReducer;
