import React from "react";
import { MinusWhiteIcon, PlusWhiteIcon } from "assets/svg";
import "./inputNumber.scss";

const InputNumber = (props) => {
  const {
    value,
    minValue = 1,
    maxValue = 99,
    handleIncrease,
    handleDecrease,
    handleChange,
    disabled,
  } = props;

  return (
    <div className="d-flex align-items-center">
      <button
        type="button"
        className="_border-none button__effect button__change-quantity"
        onClick={handleDecrease}
        disabled={value <= minValue}
        style={{ position: "relative" }}
      >
        {/* <img src={SVG.MinusWhite} width={14} height={2.35} alt="" /> */}
        <div
          style={{
            lineHeight: 0,
            position: "relative",
          }}
        >
          <MinusWhiteIcon />
        </div>
      </button>

      <input
        type="number"
        value={value}
        min={minValue}
        max={maxValue}
        inputMode="numeric"
        pattern="[0-9]*"
        onChange={handleChange}
        className="text-center _fw-500 _fs-14px _border-none _outline-none"
        readOnly
      />

      <button
        type="button"
        className="_border-none button__effect button__change-quantity"
        onClick={handleIncrease}
        disabled={value === maxValue || disabled}
        style={{ position: "relative" }}
      >
        {/* <img src={SVG.PlusWhite} width={14} height={14} alt="" /> */}
        <div
          style={{
            lineHeight: 0,
            position: "relative",
          }}
        >
          <PlusWhiteIcon />
        </div>
      </button>
    </div>
  );
};

export default InputNumber;
