import { requestWithoutToken } from "services/request";
import { encodeQueryData } from "utils/stringFormat";

import * as TYPE from "../store/constants";

const api = {
  [TYPE.GET_LIST_DEAL.request]: async (params) => {
    const query = encodeQueryData({
      storeId: params.storeId,
      merchantId: params.merchantId,
      Latitude: params.Latitude,
      Longitude: params.Longitude,
      Skip: params.Skip,
      Take: params.Take,
    });
    return requestWithoutToken(`/home/feed/voucher${query}`, "GET");
  },
};

export default api;
