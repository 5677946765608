import { genActionTypes } from "utils/actionGenerator";

export const TYPE = "SetDeliveryInfo";

export const SET_LOADING = `${TYPE}_SET_LOADING`;
export const GET_SHIPPING_INFO = genActionTypes(`${TYPE}_GET_SHIPPING_INFO`);
export const GET_SUGGESTED_DELIVERY_DISCOUNT_LIST = genActionTypes(
  `${TYPE}_GET_SUGGESTED_DELIVERY_DISCOUNT_LIST`
);
export const GET_STORE_INFO = genActionTypes(`${TYPE}_GET_STORE_INFO`);
