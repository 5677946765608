import APIUtils from 'utils/apiUtils';
import {END_POINTS} from '@shared/constants/apiUrl';

export function getOrderDetailApi({data}) {
  var requestData = '?OrderCode=' + data.orderCode;

  return APIUtils.get(END_POINTS.GET_ORDER_DETAIL_URL + requestData);
}

export function getInfoStoreApi({data, isShowDraft}) {
  var apiRequest = END_POINTS.GET_INFO_STORE_URL + data.storeId;

  if (isShowDraft) {
    apiRequest += '?unpublished=true';
    return APIUtils.get(apiRequest);
  }

  return APIUtils.get(apiRequest);
}

export function getDiscountCouponApi({data}) {
  var requestData = {
    OrderCode: data.orderCode,
  };

  return APIUtils.post(END_POINTS.CHECK_COUPON_DISCOUNT_ORDER_DETAIL_URL, {
    body: requestData,
  });
}

export function getPaymentMethodApi() {
  return APIUtils.get(END_POINTS.GET_PAYMENT_METHOD_URL);
}

export function cancelOrderApi({data}) {
  var requestData = {
    OrderCode: data.orderCode,
  };

  return APIUtils.post(END_POINTS.CANCEL_ORDER_URL, {
    body: requestData,
  });
}

export function getListVoucherSumaryByUserApi() {
  var requestData = '?status=All';
  return APIUtils.get(END_POINTS.GET_MY_VOUCHER_URL + requestData);
}
