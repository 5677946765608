import {
  GET_LIST_VIDEO_DYNAMIC_SUCCESS,
  GET_LIST_VIDEO_BY_LOCATION_SUCCESS,
} from './constants';

const initialState = {
  listVideoDynamic: [],
  listVideoByLocation: [],
};

const videoReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LIST_VIDEO_DYNAMIC_SUCCESS: {
      return {
        ...state,
        listVideoDynamic: action.videos,
      };
    }
    case GET_LIST_VIDEO_BY_LOCATION_SUCCESS: {
      return {
        ...state,
        listVideoByLocation: action.videos,
      };
    }
    default:
      return state;
  }
};

export default videoReducer;
