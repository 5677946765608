import {
  CREATE_PAYMENT_URL_REQUEST,
  CREATE_PAYMENT_URL_SUCCESS,
  CREATE_PAYMENT_URL_FAILED,
  CREATE_TOKENIZATION_REQUEST,
  CREATE_TOKENIZATION_SUCCESS,
  CREATE_TOKENIZATION_FAILED,
} from './constants';

export const createPaymentUrlRequest = (payload = {}, onSuccess, onError) => ({
  type: CREATE_PAYMENT_URL_REQUEST,
  payload,
  onSuccess,
  onError,
});

export const createPaymentUrlSuccess = payload => ({
  type: CREATE_PAYMENT_URL_SUCCESS,
  payload,
});

export const createPaymentUrlFailed = payload => ({
  type: CREATE_PAYMENT_URL_FAILED,
  payload,
});

export const createTokenizationRequest = (
  payload = {},
  onSuccess,
  onError,
) => ({
  type: CREATE_TOKENIZATION_REQUEST,
  payload,
  onSuccess,
  onError,
});

export const createTokenizationSuccess = payload => ({
  type: CREATE_TOKENIZATION_SUCCESS,
  payload,
});

export const createTokenizationFailed = payload => ({
  type: CREATE_TOKENIZATION_FAILED,
  payload,
});
