import { genActions } from "utils/actionGenerator";
import * as TYPE from "../constants/constants";

const actions = {
  GET_USER_INFO: genActions(TYPE.GET_USER_INFO),
  SET_USER_INFO: (payload) => ({ type: TYPE.SET_USER_INFO, payload }),
  SIGN_IN_WITH_CUSTOM_TOKEN: genActions(TYPE.SIGN_IN_WITH_CUSTOM_TOKEN),
  UPLOAD_AVATAR: genActions(TYPE.UPLOAD_AVATAR),
  UPDATE_USER_INFO: genActions(TYPE.UPDATE_USER_INFO),
  SEARCH_LIST: genActions(TYPE.SEARCH_LIST),
  GET_SEARCH_SUGGESTION: genActions(TYPE.GET_SEARCH_SUGGESTION),

  SAVE_FILTER_DATA: (payload) => ({
    type: TYPE.SAVE_FILTER_DATA,
    payload,
  }),

  CHANGE_LAYOUT: (payload) => ({
    type: TYPE.CHANGE_LAYOUT,
    payload,
  }),

  SET_USER: (payload) => ({
    type: TYPE.SET_USER,
    payload,
  }),

  SET_SEARCHTERMS: (payload) => ({
    type: TYPE.SET_SEARCHTERMS,
    payload,
  }),

  SHOW_ELEMENTS: (payload) => ({
    type: TYPE.SHOW_ELEMENTS,
    payload,
  }),

  SET_LOADING: (payload) => ({ type: TYPE.SET_LOADING, payload }),

  CLEAR_SEARCH_RESULT: () => ({ type: TYPE.CLEAR_SEARCH_RESULT }),

  SET_OFFLINE: (payload) => ({ type: TYPE.SET_OFFLINE, payload }),
};

export default actions;
