import {
  FNB_CART_KEY,
  VOUCHER_CART_KEY,
  MARKET_CART_KEY,
} from "./localStorageKeys";

export const FOOD_ITEM_TYPES = {
  SIMPLE: "simple",
  DETAILS: "details",
};

export const FNB_OPTION_TYPES = {
  SIZE: "SIZE",
  ATTRIBUTE: "ATTRIBUTE",
  TOPPING: "TOPPING",
};

export const MAP_MODES = {
  VIEW_SELECTED_DELIVERY_ADDRESS: "VIEW_SELECTED_DELIVERY_ADDRESS",
  SELECT_DELIVERY_ADDRESS: "SELECT_DELIVERY_ADDRESS",
  DIRECTION: "DIRECTION_MODE",
};

export const ADDRESS_COMPONENT_TYPES = {
  SAVED: "SAVED",
  RECENT_USED: "RECENT_USED",
};

export const ADDRESS_GROUP_NAMES_IN_VI = {
  home: "nhà",
  company: "công ty",
  other: "khác",
};

export const DELIVERY_ADDRESS_PAGE_MODES = {
  DEFAULT: "DEFAULT",
  SEARCH: "SEARCH",
  SHOW_ALL_SAVED_ADDRESSES: "SHOW_ALL_SAVED_ADDRESSES",
};

export const FEED_TYPES = {
  FNB: {
    key: "fnb",
    BEKey: "FnB",
    regex: new RegExp("fnb", "ig"),
  },
  STORE: { key: "store", BEKey: "Store", regex: new RegExp("store", "ig") },
  MARKET: { key: "market", BEKey: "Market", regex: new RegExp("market", "ig") },
  VOUCHER: {
    key: "voucher",
    BEKey: "Voucher",
    regex: new RegExp("voucher", "ig"),
  },
  MALL: { key: "mall", BEKey: "Mall", regex: new RegExp("mall", "ig") },
  PRODUCT: {
    key: "product",
    BEKey: "Product",
    regex: new RegExp("product", "ig"),
  },
};

export const TYPES_OF_FEED_STORE = {
  FNB: { key: "fnb", BEKey: "FnB", regex: new RegExp("fnb", "ig") },
  STORE: { key: "store", BEKey: "Store", regex: new RegExp("store", "ig") },
};

export const CURRENT_HANDLED_FEED_TYPES = [
  FEED_TYPES.FNB.key,
  FEED_TYPES.STORE.key,
  FEED_TYPES.MARKET.key,
  FEED_TYPES.VOUCHER.key,
  // FEED_TYPES.MALL.key,
  // FEED_TYPES.PRODUCT.key,
]; // current allowed feed types (home, /listfeed)

export const FEED_SEARCH_PARAMS = {
  searchText: "SearchText",
  zoneId: "ZoneId",
  feedTypes: "FeedTypes",
  hasPromotion: "HasPromotion",
  businessType: "BusinessType",
  merchantId: "MerchantId",
  isGrouping: "IsGrouping",
  isFilterVisibleAtHomePage: "IsFilterVisibleAtHomePage",
  distanceLimit: "DistanceLimit",
  latitude: "Latitude",
  longitude: "Longitude",
  skip: "Skip",
  take: "Take",
};

export const WEB_ACTION_URL_PARAMS_REGEX = {
  searchText: new RegExp("searchText", "ig"),
  feedTypes: new RegExp("feedTypes", "ig"),
  zoneId: new RegExp("zoneId", "ig"),
  businessType: new RegExp("businessType", "ig"),
  hasPromotion: new RegExp("hasPromotion", "ig"),
  merchantId: new RegExp("merchantId", "ig"),
  isGrouping: new RegExp("isGrouping", "ig"),
  isFilterVisibleAtHomePage: new RegExp("isFilterVisibleAtHomePage", "ig"),
  distanceLimit: new RegExp("distanceLimit", "ig"),
  latitude: new RegExp("lat", "ig"),
  longitude: new RegExp("long", "ig"),
  //   skip: new RegExp("skip", "ig"),
  //   take: new RegExp("take", "ig"),
};

// * SHORTCUT HOME
export const SHORTCUT_ACTION_TYPES = {
  FILTER_BY_ZONE: "filterByZone",
  OPEN_CAT: "opencat",
  OPEN_LIST_STORE: "openListStore",
};

export const DELIVERY_OPTIONS = {
  HOME: "home",
  SHOP: "shop",
  TABLE: "table",
  TAKEAWAY: "takeaway",
  PICKUPTIME: "pickuptime",
};

export const DELIVERY_OPTIONS_NAME = {
  HOME: "Giao về",
  SHOP: "Nhận tại quầy",
};

export const DELIVERY_OPTIONS_CONFIGS = [
  {
    type: "shop",
    label: "ĐẶT MÓN",
    icon: "https://ucarecdn.com/d9bbcdc8-0a2a-4c9d-bb0e-2fa9db1b2605/cart2x.png",
  },
  {
    type: "home",
    label: "GIAO VỀ",
    icon: "https://ucarecdn.com/4b12417c-101f-40d8-9b8b-92f09497b3d0/scooter12x.png",
  },
];

// các dạng bình luận của tính năng rating:
// - post: cũng là 1 dạng bình luận (bình luận về cửa hàng)
// - comment: bình luận thuộc 1 post
export const COMMENT_TYPES_OF_RATING_FEATURE = {
  RATING_POST: "RATING_POST",
  COMMENT: "COMMENT",
};

// *** VOUCHER
export const VOUCHER_TYPES = {
  GEN_VOUCHER: "GenVoucher",
  GEN_VOUCHER_WITH_AMOUNT: "GenVoucherWithAmount",
  CHECK_EXIST: "CheckExist",
};

// *** ORDER TYPES
export const ORDER_TYPES = {
  OrderFNB: "OrderFNB",
  OrderGift: "OrderGift",
};

// *** COUPON DETAIL PAGE
export const COUPON_DETAIL_PAGE_MODES = {
  APPLY: "APPLY",
  ALLOCATE: "ALLOCATE",
  SHOW_LIST_STORES: "SHOW_LIST_STORES",
};

export const CART_KEYS_MAP = {
  FNB_CART: {
    path: "/order",
    LOCAL_STORAGE_KEY: FNB_CART_KEY,
  },
  VOUCHER_CART: {
    path: "/vouchercart",
    LOCAL_STORAGE_KEY: VOUCHER_CART_KEY,
  },
  MARKET_CART: {
    path: "/CartMarket",
    LOCAL_STORAGE_KEY: MARKET_CART_KEY,
  },
};

export const CURRENCY_TYPE = {
  UTOP: {
    name: "utop",
  },
  VND: {
    name: "vnd",
  },
};
