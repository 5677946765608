/* eslint global-require: 0 */
export const imagesIcon = {
  userInfo: require("./info-user.png").default,
  iconOder: require("./listIconOder.png").default,
  voucher: require("./voucher.png").default,
  logout: require("./logout.png").default,
  searchBar: require("./searchBar.png").default,
  searchIcon: require("./searchIcon.png").default,
  backIcon: require("./back.png").default,
  locationIcon: require("./locationIcon.png").default,
  nextIcon: require("./next.png").default,
  homeIcon: require("./home.png").default,
  companyIcon: require("./company.png").default,
  searchAddress: require("./vector.png").default,
  mapImg: require("./map.png").default,
  userIcon: require("./user.png").default,
  teleIcon: require("./telephone.png").default,
  clockIcon: require("./clockIcon.png").default,
  bitMap: require("./bitmap1.png").default,
  addressIcon: require("./address.png").default,
  avatar: require("./avatar.png").default,
  tpBankLogo: require("./vpbankLogo.png").default,
  gpsIcon: require("./gps.png").default,
  savedIcon: require("./saved.png").default,
  isSavedIcon: require("./isSaved.png").default,
  // subtractImg: require("./subtract.png").default,
  logoStore: require("./logoStore1.png").default,
  voucherIcon: require("./voucherIcon.png").default,
  pathIcon: require("./pathNext.png").default,
  vcDefault: require("./voucher1.png").default,
  vcCrab: require("./v-crab.png").default,
  vcCgv: require("./v-cgv.png").default,
  vcIcool: require("./v-icool.png").default,
  amountIcon: require("./amount.png").default,
  rewardIcon: require("./reward.png").default,
  vcBackground: require("./bgrvoucher1.svg").default,
  pathNext: require("./pathNextt.png").default,
  pathPrev: require("./pathPrev.png").default,
  qrCode: require("./qrCode.png").default,
  qrGs: require("./qrGs.png").default,
  returnIcon: require("./returnIcon.png").default,
  deadlineIcon: require("./deadline.png").default,
  rateImg: require("./rateImg.png").default,
  locationMap: require("./location.png").default,
  gpsMap: require("./gpsMap.png").default,
  product: require("./product1.png").default,
  moreDetails: require("./moredetails.svg").default,
  linevcr: require("./linevc.svg").default,
  locationNear: require("./locationyellow.svg").default,
  detailsMore: require("./detailsmore.svg").default,
  edituser: require("./edituser.png").default,
  barLine: require("./bar-line.svg").default,
  gpsMapIcon: require("./gpsMapIcon.svg").default,
  dotaddress: require("./dotaddress.svg").default,
  logovoucher: require("./logovoucher.png").default,
  logocoupon: require("./logocoupon.png").default,
  ratingcart: require("./ratingcart.svg").default,
  bgrvoucher: require("./bgrvoucher1.png").default,
  inputCloseBtn: require("./inputCloseBtn.png").default,
  voucherDefault: require("./voucherdefault.png").default,
  bgrcoupondetail: require("./bgrcoupondetail.png").default,
  iconcopycode: require("./icon-copy-code.png").default,
  markrefund: require("./markrefund.png").default,
  confirmmarkrefund: require("./confirmmarkrefund.png").default,
  ratestore: require("./rate-store.png").default,

  refundsuccess: require("./refundsuccess.png").default,
  refundfailure: require("./refundfailure.png").default,

  mobilepayment: require("./mobilepayment1.svg").default,
  listphonebook: require("./listphonebook.svg").default,

  stardisable: require("./star-disable.png").default,
  staractive: require("./star-active.svg").default,

  mobifone: require("./mobifone.png").default,
  mobile: require("./mobile.png").default,
  vietnammobile: require("./vietnammobile.png").default,
  viettel: require("./viettel.png").default,
  vinaphone: require("./vinaphone.png").default,
  chooseimage: require("./chooseimage.png").default,
  bgrthelienket: require("./bgrthelienket.png").default,
  logoMomo: require("./logo-momo.png").default,
  logoVNPay: require("./logo-VNPAYQR.png").default,
  logoMasterCard: require("./logo-MasterCard.png").default,
  logoVisa: require("./logo-visa.png").default,
  logoUtopNew: require("./logoUtop.png").default,
  logoPayoo: require("./logoPayoo.png").default,
  logoCreditCard: require("./logo-CreditCard.png").default,

  ratingBackground: require("./ratingBackground.png").default,
  ratingBgFailure: require("./bg-popup_failure.svg").default,
  ratingIcon: require("./ratingIcon.png").default,
  ratingPopup: require("./rating-store_popup-fail.svg").default,
  logoDownloadIOS: require("./logodownloadios.svg").default,
  logoDownloadAndroid: require("./logodownloadandroid.svg").default,

  logoBoCongThuong1: require("./bo-cong-thuong-1.png").default,
  logoBoCongThuong2: require("./bo-cong-thuong-2.png").default,

  logoUtopSmartBanner: require("./logo120.png").default,
  questionIcon: require("./question-footer.svg").default,
  IconEmail: require("./clarity_email-solid.svg").default,
  IconPhone: require("./bx_bxs-phone.svg").default,
  IconLocation: require("./subway_location.svg").default,
  CloseButton: require("./close-button.svg").default,
  BgHeaderPopupErr: require("./popup-err-bg.jpg").default,
};

export { ReactComponent as IconEmail } from "./clarity_email-solid.svg";
export { ReactComponent as IconPhone } from "./bx_bxs-phone.svg";
export { ReactComponent as IconLocation } from "./subway_location.svg";
