import { put, takeLatest, fork } from "redux-saga/effects";
import {
  GET_PRODUCTS_REQUEST,
  GET_TOPPINGS_REQUEST,
  GET_CATEGORIES_REQUEST,
  CHECK_PRODUCT_OUT_OF_STOCK_REQUEST,
  CREATE_ORDER_FNB_REQUEST,
} from "./constants";
import {
  getProductsFailed,
  getProductsSuccess,
  getToppingsFailed,
  getToppingsSuccess,
  getCategoriesFailed,
  getCategoriesSuccess,
  checkProductOutOfStockSuccess,
  checkProductOutOfStockFailed,
  createOrderFnbSuccess,
  createOrderFnbFailed,
} from "./actions";
import {
  getProductsApi,
  getToppingsApi,
  getCategoriesApi,
  checkProductOutOfStockApi,
  createOrderFnBApi,
} from "@shared/services/api/smartMenu";
import { callApi } from "helpers/callApi";
import { listProductsByMerchantIdParser } from "./parser";

export function* getProductsSaga(obj) {
  const { onSuccess, merchantId } = obj;
  try {
    const res = yield callApi(getProductsApi, { merchantId });
    yield put(
      getProductsSuccess(
        listProductsByMerchantIdParser(res, merchantId),
        res?.data?.data?.queryProductfnbContentsWithTotal?.items || []
      )
    );
    if (typeof onSuccess === "function") {
      onSuccess(
        res?.data?.data?.queryProductfnbContentsWithTotal?.items?.length > 0
          ? res?.data?.data?.queryProductfnbContentsWithTotal?.items
          : [{ isEmpty: true }]
      );
    }
  } catch (err) {
    const { status } = err || {};
    if (status >= 400) {
      Alert.alert("Utop", err?.data?.message);
    }
    yield put(getProductsFailed(err));
  }
}

export function* getCategoriesSaga(obj) {
  const { onSuccess, merchantId } = obj;
  try {
    const res = yield callApi(getCategoriesApi, { merchantId });
    yield put(getCategoriesSuccess(res));
    if (typeof onSuccess === "function") {
      onSuccess(res.data.items);
    }
  } catch (err) {
    const { status } = err || {};
    if (status >= 400) {
      Alert.alert("Utop", err?.data?.message);
    }
    yield put(getCategoriesFailed(err));
  }
}

export function* getToppingsSaga(obj) {
  const { onSuccess, merchantId } = obj;
  try {
    const res = yield callApi(getToppingsApi, { merchantId });
    yield put(getToppingsSuccess(res));
    if (typeof onSuccess === "function") {
      onSuccess(res.data.items);
    }
  } catch (err) {
    const { status } = err || {};
    if (status >= 400) {
      Alert.alert("Utop", err?.data?.message);
    }
    yield put(getToppingsFailed(err));
  }
}

export function* checkProductOutOfStockSaga(obj) {
  const { onSuccess, onFailed, storeId, merchantId } = obj;
  try {
    const res = yield callApi(checkProductOutOfStockApi, {
      storeId,
      merchantId,
    });
    yield put(
      checkProductOutOfStockSuccess(
        res?.data?.length > 0 ? res.data : [{ isEmpty: true }]
      )
    );
    if (typeof onSuccess === "function") {
      onSuccess(res?.data?.length > 0 ? res.data : [{ isEmpty: true }]);
    }
  } catch (err) {
    const { status } = err || {};
    if (status >= 400) {
      Alert.alert("Utop", err?.data?.message);
    }
    if (typeof onFailed === "function") {
      onFailed();
    }
    yield put(checkProductOutOfStockFailed(err));
  }
}

export function* createOrderFnBSaga(obj) {
  const { payload = {}, onSuccess, onFailed } = obj;
  try {
    const res = yield callApi(createOrderFnBApi, payload);

    yield put(createOrderFnbSuccess(res));
    if (typeof onSuccess === "function") {
      onSuccess(res);
    }
  } catch (err) {
    if (typeof onFailed === "function") {
      onFailed(err);
    }
    yield put(createOrderFnbFailed(err));
  }
}

function* watchSmartMenu() {
  yield takeLatest(GET_PRODUCTS_REQUEST, getProductsSaga);
  yield takeLatest(GET_TOPPINGS_REQUEST, getToppingsSaga);
  yield takeLatest(GET_CATEGORIES_REQUEST, getCategoriesSaga);
  yield takeLatest(CREATE_ORDER_FNB_REQUEST, createOrderFnBSaga);
  yield takeLatest(
    CHECK_PRODUCT_OUT_OF_STOCK_REQUEST,
    checkProductOutOfStockSaga
  );
}

export default function* rootChild() {
  yield fork(watchSmartMenu);
}
