export const voucherInfo = undefined;
export const suggestedCouponList = [];
export const shortLinkToShare = "";
export const outOfStockProductCodes = []; // danh sách các productCode (của priceOption) sau khi call check stock thì không còn available
export const isLoading = {
  GET_VOUCHER_INFO: true,
  GET_SUGGESTED_COUPON_LIST: false,
  SAVE_SUGGESTED_COUPON: false,
  GET_SHORT_LINK_TO_SHARE: false,
  CHECK_STOCK_OF_PRODUCT: false,
};
