import { SET_SHOW_SUPPORT_ORDER } from "./constants";
// import { getStateCommonNotInit } from "../../utils";

// TODO: lưu ý

// let stateCommonNotInit = {};
// try {
//   stateCommonNotInit = getStateCommonNotInit(state => {
//     if (typeof state === 'undefined') {
//       return {};
//     }
//     return state;
//   });
// } catch (error) {}

const commomNotInit = (state = {}, action) => {
  switch (action.type) {
    case SET_SHOW_SUPPORT_ORDER:
      return {
        ...state,
        isShowSupportOrder: false,
      };

    default:
      return state;
  }
};

export default commomNotInit;
