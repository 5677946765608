import {
  GET_MERCHANT_INFO_BY_ID_REQUEST,
  GET_MERCHANT_INFO_BY_ID_SUCCESS,
  GET_MERCHANT_INFO_BY_ID_FAILED,
} from './constants';

export const getMerchantInfoByIdHandle = (merchantId, onSuccess, onFailed) => ({
  type: GET_MERCHANT_INFO_BY_ID_REQUEST,
  merchantId,
  onSuccess,
  onFailed,
});

export const getMerchantInfoByIdSuccess = balance => ({
  type: GET_MERCHANT_INFO_BY_ID_SUCCESS,
  balance,
});

export const getMerchantInfoByIdFailed = error => ({
  type: GET_MERCHANT_INFO_BY_ID_FAILED,
  error,
});
