import { requestWithoutToken } from "services/request";
import { encodeQueryDataV2 } from "utils/stringFormat";
import * as TYPE from "../store/constants";

const api = {
  // [TYPE.GET_LIST_FEED__FILTER_BY_ZONE.request]: async (queryParams) => {
  //   return requestWithoutToken(
  //     `/feed/store/search${queryParams}`,
  //     "GET",
  //     null,
  //     {
  //       "X-Client": "web",
  //       "Ocp-Apim-Subscription-Key":
  //         process.env.REACT_APP_OCP_APIM_SUBSCRIPTION_KEY,
  //       "Ocp-Apim-Trace": true,
  //     }
  //   );
  // },

  // [TYPE.GET_LIST_FEED__OPEN_LIST_STORE.request]: async (queryParams) => {
  //   return requestWithoutToken(
  //     `/feed/store/search${queryParams}`,
  //     "GET",
  //     null,
  //     {
  //       "X-Client": "web",
  //       "Ocp-Apim-Subscription-Key":
  //         process.env.REACT_APP_OCP_APIM_SUBSCRIPTION_KEY,
  //       "Ocp-Apim-Trace": true,
  //     }
  //   );
  // },

  // [TYPE.GET_LIST_FEED__OPEN_CAT.request]: async (queryParams) => {
  //   return requestWithoutToken(`/feed/Search${queryParams}`, "GET", null, {
  //     "content-type": "application/json",
  //     "Access-Control-Allow-Credentials": true,
  //     Authorization: "",
  //     "Ocp-Apim-Subscription-Key":
  //       process.env.REACT_APP_OCP_APIM_SUBSCRIPTION_KEY,
  //     "X-Client": "Web",
  //   });
  // },

  // * GET_LIST_FEED
  [TYPE.GET_LIST_FEED.request]: async ({ query }) => {
    return requestWithoutToken(`/home/Feed/Search${query}`, "GET");
  },

  // * GET_LIST_STORE_OF_MERCHANT
  [TYPE.GET_LIST_STORE_OF_MERCHANT.request]: async (params) => {
    const query = encodeQueryDataV2({
      merchantId: params.merchantId,
      isGrouping: params.isGrouping,
      feedTypes: params.feedTypes, // can be an Array when use encodeQueryDataV2
      latitude: params.latitude,
      longitude: params.longitude,
      skip: params.skip,
      take: params.take,
    });

    return requestWithoutToken(`/home/Feed/Search${query}`, "GET");
  },
};

export default api;
