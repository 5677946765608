import { actionTypes } from "@shared/redux/actionTypes";

export const GET_LIST_INSURANCE_TYPE = actionTypes(
  "insurance/GET_LIST_INSURANCE_TYPE"
);

export const GET_LIST_INSURANCE_ITEM_WITH_TYPE = actionTypes(
  "insurance/GET_LIST_INSURANCE_ITEM_WITH_TYPE"
);

export const GET_INSURANCE_ITEM_DETAIL = actionTypes(
  "insuranceItemDetail/GET_INSURANCE_ITEM_DETAIL"
);
