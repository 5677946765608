/**
 *
 * @param images array of object {isFeatured: Boolean, imageUrl: String}
 * @param mode int
 * 1: lấy duy nhất 1 isFeatured = true, nếu không có sẽ là url rỗng, còn lại là  isFeatured = false, tự fill phía sau là url rỗng
 * 2: lấy tất cả isFeatured = true, nếu không có cái nào thì là 1 url rỗng, còn lại là isFeatured = false, tự fill phía sau là url rỗng
 * 3: lấy tất cả, ưu tiên cho isFeatured = true sẽ đứng trước
 * 4: lấy top 1 của isFeatured=true, lấy top 2 của isFeature=false; Nếu toàn là isFeature=true hoặc isFeature=false thì lấy theo thứ tự
 * @returns [<url1>, <url2>, <url3>]
 */
export const standardizeFeedImages = (images, mode = 4 /* UTOP PREFER */) => {
  if (!Array.isArray(images)) {
    return ["", "", ""];
  }

  const featuredUrls = [];
  const normalUrls = [];

  images.forEach((obj) => {
    // không check isFeatured có phải là "boolean" hay không
    // vì đang muốn isFeatured = null được coi là false luôn
    if (obj.isFeatured) {
      featuredUrls.push(obj.imageUrl);
    } else {
      normalUrls.push(obj.imageUrl);
    }
  });

  if (featuredUrls.length === 0 && normalUrls.length === 0) {
    return ["", "", ""];
  }

  switch (mode) {
    case 1: {
      const featuredImgUrls =
        featuredUrls.length > 0 ? featuredUrls.slice(0, 1) : [""];
      const res = [...featuredImgUrls, ...normalUrls, "", ""].slice(0, 3);
      return res;
    }
    case 2: {
      const standardizedFeaturedUrls =
        featuredUrls.length > 0 ? featuredUrls : [""];
      const res = [...standardizedFeaturedUrls, ...normalUrls, "", ""].slice(
        0,
        3
      );
      return res;
    }
    case 3: {
      const res = [...featuredUrls, ...normalUrls, "", "", ""].slice(0, 3);
      return res;
    }
    case 4: {
      if (featuredUrls.length === 0) {
        // toàn là isFeatured=false -> lấy theo thứ tự
        return [...normalUrls, "", "", ""].slice(0, 3);
      }

      if (normalUrls.length === 0) {
        // toàn là isFeatured=true -> lấy theo thứ tự
        return [...featuredUrls, "", "", ""].slice(0, 3);
      }

      // lấy top 1 của isFeatured=true, nếu không có thì lấy luôn bắt đầu từ top 1 của isFeatured=false
      const res = [
        ...featuredUrls.slice(0, 1),
        ...normalUrls,
        "",
        "",
        "",
      ].slice(0, 3);
      return res;
    }

    default: {
      console.warn("param 'mode' is missing");
    }
  }
};
