import {
  READ_NOTIFICATION,
  SET_NUMBER_NOTI,
  GET_NOTIFICATION_REQUEST,
  GET_NOTIFICATION_SUCCESS,
  GET_NOTIFICATION_FAILED,
  RESET_NOTIFICATION,
} from './constants';



export const resetNotification = payload => ({
  type: RESET_NOTIFICATION,
  payload,
});

export const getNotificationRequest = payload => ({
  type: GET_NOTIFICATION_REQUEST,
  payload,
});

export const getNotificationSuccess = payload => ({
  type: GET_NOTIFICATION_SUCCESS,
  payload,
});

export const getNotificationFailure = payload => ({
  type: GET_NOTIFICATION_FAILED,
  payload,
});

export const setNumberNoti = payload => ({
  type: SET_NUMBER_NOTI,
  payload,
});

export const readNotification = payload => ({
  type: READ_NOTIFICATION,
  payload,
});
