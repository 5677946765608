import request, { requestWithoutToken } from "services/request";
import { encodeQueryData } from "utils/stringFormat";

import * as TYPE from "../store/constants";

const api = {
  [TYPE.GET_SHIPPING_INFO.request]: async (bodyData) => {
    return request(`/shipping/Estimate`, "POST", bodyData);
  },

  [TYPE.GET_SUGGESTED_DELIVERY_DISCOUNT_LIST.request]: async (params) => {
    const query = encodeQueryData({
      merchantId: params.merchantId,
      storeId: params.storeId,
      code: params.code,
    });

    return request(`/Shipping/SuggestDiscount${query}`, "GET");
  },

  [TYPE.GET_STORE_INFO.request]: async (idStore) => {
    return requestWithoutToken(`/cms/core/stores/${idStore}`, "GET");
  },
};

export default api;
