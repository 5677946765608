export const GET_UTOP_POINT_TOTAL_REQUEST =
  'utopback/GET_UTOP_POINT_TOTAL_REQUEST';

export const GET_UTOP_POINT_TOTAL_SUCCESS =
  'utopback/GET_UTOP_POINT_TOTAL_SUCCESS';

export const GET_UTOP_POINT_TOTAL_FAILED =
  'utopback/GET_UTOP_POINT_TOTAL_FAILED';
export const GET_LIST_CAMPAIGN_REQUEST = 'utopback/GET_LIST_CAMPAIGN_REQUEST';
export const GET_LIST_CAMPAIGN_SUCCESS = 'utopback/GET_LIST_CAMPAIGN_SUCCESS';
export const GET_LIST_CAMPAIGN_FAILED = 'utopback/GET_LIST_CAMPAIGN_FAILED';
export const GET_LIST_AFF_HISTORY_REQUEST =
  'utopback/GET_LIST_AFF_HISTORY_REQUEST';
export const GET_LIST_AFF_HISTORY_SUCCESS =
  'utopback/GET_LIST_AFF_HISTORY_SUCCESS';
export const GET_LIST_AFF_HISTORY_FAILED =
  'utopback/GET_LIST_AFF_HISTORY_FAILED';
export const GET_AFF_DETAIL_REQUEST = 'utopback/GET_AFF_DETAIL_REQUEST';
export const GET_AFF_DETAIL_SUCCESS = 'utopback/GET_AFF_DETAIL_SUCCESS';
export const GET_AFF_DETAIL_FAILED = 'utopback/GET_AFF_DETAIL_FAILED';
