import {
  GET_LIST_PENDING_ORDER_REQUEST,
  GET_LIST_PENDING_ORDER_SUCCESS,
  GET_LIST_PENDING_ORDER_FAILED,
  CLEAR_LIST_PENDING_ORDER,
} from './constants';
//Action get list
export const getListOrderRequest = (payload, onSuccess, onFailed) => ({
  type: GET_LIST_PENDING_ORDER_REQUEST,
  payload,
  onSuccess,
  onFailed,
});

export const getListOrderSuccess = orderList => ({
  type: GET_LIST_PENDING_ORDER_SUCCESS,
  orderList,
});

export const getListOrderFailed = error => ({
  type: GET_LIST_PENDING_ORDER_FAILED,
  error,
});

export const clearPendingOrderList = () => ({
  type: CLEAR_LIST_PENDING_ORDER,
});
