import {
  GET_UTOP_POINT_FAILED,
  GET_UTOP_POINT_REQUEST,
  GET_UTOP_POINT_SUCCESS,
  GET_USER_INFO_REQUEST,
  GET_USER_INFO_SUCCESS,
  GET_USER_INFO_FAILED,
  LOGOUT_REQUEST,
  UPDATE_ACCOUNT_INFO_FAILED,
  UPDATE_ACCOUNT_INFO_REQUEST,
  UPDATE_USER_PROFILE_REQUEST,
  UPDATE_USER_PROFILE_SUCCESS,
  UPDATE_USER_PROFILE_FAILURE,
  UPLOAD_AVATAR_FAILURE,
  UPLOAD_AVATAR_REQUEST,
  UPLOAD_AVATAR_SUCCESS,
  CHANGE_PIN_CODE_REQUEST,
  CHANGE_PIN_CODE_SUCCESS,
  CHANGE_PIN_CODE_FAILED,
  VERIFY_PIN_CODE_FAILED,
  VERIFY_PIN_CODE_REQUEST,
  VERIFY_PIN_CODE_SUCCESS,
} from './constants';

export const getUtopPointHandle = (onSuccess, onFailed) => ({
  type: GET_UTOP_POINT_REQUEST,
  onSuccess,
  onFailed,
});

export const getUtopPointSuccess = balance => ({
  type: GET_UTOP_POINT_SUCCESS,
  balance,
});

export const getUtopPointFailed = payload => ({
  type: GET_UTOP_POINT_FAILED,
  payload,
});

export const getUserInfoHandle = (payload = {}, onSuccess, onFailed) => ({
  type: GET_USER_INFO_REQUEST,
  payload,
  onSuccess,
  onFailed,
});

export const getUserInfoSuccess = userInfo => ({
  type: GET_USER_INFO_SUCCESS,
  userInfo,
});

export const getUserInfoFailed = error => ({
  type: GET_USER_INFO_FAILED,
  error,
});

export const updateAccountInfoRequest = (
  payload = {},
  onSuccess,
  onFailed,
) => ({
  type: UPDATE_ACCOUNT_INFO_REQUEST,
  payload,
  onSuccess,
  onFailed,
});

export const updateAccountInfoFailed = payload => ({
  type: UPDATE_ACCOUNT_INFO_FAILED,
  payload,
});

export const logoutHandle = () => ({
  type: LOGOUT_REQUEST,
});

export const updateUserProfileRequest = (payload, onSuccess, onError) => ({
  type: UPDATE_USER_PROFILE_REQUEST,
  payload,
  onSuccess,
  onError,
});

export const updateUserProfileSuccess = payload => ({
  type: UPDATE_USER_PROFILE_SUCCESS,
  payload,
});

export const updateUserProfileFailure = error => ({
  type: UPDATE_USER_PROFILE_FAILURE,
  error,
});

export const uploadAvatarRequest = (payload, onSuccess, onError) => ({
  type: UPLOAD_AVATAR_REQUEST,
  payload,
  onSuccess,
  onError,
});

export const uploadAvatarSuccess = payload => ({
  type: UPLOAD_AVATAR_SUCCESS,
  payload,
});
export const uploadAvatarFailure = error => ({
  type: UPLOAD_AVATAR_FAILURE,
  error,
});
//update pin
export const changePinCodeRequest = (payload = {}, onSuccess, onFailed) => ({
  type: CHANGE_PIN_CODE_REQUEST,
  payload,
  onSuccess,
  onFailed,
});

export const changePinCodeSuccess = payload => ({
  type: CHANGE_PIN_CODE_SUCCESS,
  payload,
});

export const changePinCodeFailed = payload => ({
  type: CHANGE_PIN_CODE_FAILED,
  payload,
});

//verify pin
export const verifyPinCodeRequest = (payload = {}, onSuccess, onFailed) => ({
  type: VERIFY_PIN_CODE_REQUEST,
  payload,
  onSuccess,
  onFailed,
});

export const verifyPinCodeSuccess = payload => ({
  type: VERIFY_PIN_CODE_SUCCESS,
  payload,
});

export const verifyPinCodeFailed = payload => ({
  type: VERIFY_PIN_CODE_FAILED,
  payload,
});
