import { genActions } from "utils/actionGenerator";
import * as TYPE from "./constants";

const actions = {
  GET_LIST_VOUCHER_TO_CHOOSE: genActions(TYPE.GET_LIST_VOUCHER_TO_CHOOSE),
  CLEAR_LIST_VOUCHER_TO_CHOOSE: () => ({
    type: TYPE.CLEAR_LIST_VOUCHER_TO_CHOOSE,
  }),
  SET_QUANTITY_VOUCHER: (payload) => ({
    type: TYPE.SET_QUANTITY_VOUCHER,
    payload,
  }),
  SET_CHECK_MARK: (payload) => ({
    type: TYPE.SET_CHECK_MARK,
    payload,
  }),
  SET_UNCHECK_MARK: (payload) => ({
    type: TYPE.SET_UNCHECK_MARK,
    payload,
  }),
};

export default actions;
