import {APIUtils} from 'utils';

import {END_POINTS} from '@shared/constants/apiUrl';

export function CreatePaymentUrlAPI(payload) {
  return APIUtils.post(END_POINTS.CREATE_PAYMENT_URL, {body: payload});
}

export function CreateTokenizationAPI(payload) {
  return APIUtils.post(END_POINTS.CREATE_TOKENIZATION_URL, {body: payload});
}
