import {
  GET_LIST_INSURANCE_ITEM_WITH_TYPE,
  GET_LIST_INSURANCE_TYPE,
  GET_INSURANCE_ITEM_DETAIL,
} from './constants';

export const getListInsuranceTypeHandle = (onSuccess, onFailed) => ({
  type: GET_LIST_INSURANCE_TYPE.HANDLER,
  onSuccess,
  onFailed,
});

export const getListInsuranceTypeSuccess = payload => ({
  type: GET_LIST_INSURANCE_TYPE.SUCCESS,
  payload,
});

export const getListInsuranceTypeFailure = error => ({
  type: GET_LIST_INSURANCE_TYPE.FAILURE,
  error,
});

export const getListInsuranceItemWithTypeHandle = (
  insuranceTypeId,
  skip,
  take,
  onSuccess,
  onFailed,
) => ({
  type: GET_LIST_INSURANCE_ITEM_WITH_TYPE.HANDLER,
  insuranceTypeId,
  skip,
  take,
  onSuccess,
  onFailed,
});

export const getListInsuranceItemWithTypeSuccess = payload => ({
  type: GET_LIST_INSURANCE_ITEM_WITH_TYPE.SUCCESS,
  payload,
});

export const getListInsuranceItemWithTypeFailure = error => ({
  type: GET_LIST_INSURANCE_ITEM_WITH_TYPE.FAILURE,
  error,
});

// action for get insurance item detail
export const getActionCallInsuranceItemDetailHandle = (
  idInsuranceItem,
  onSuccess,
  onFailed,
) => ({
  type: GET_INSURANCE_ITEM_DETAIL.HANDLER,
  idInsuranceItem,
  onSuccess,
  onFailed,
});

export const getActionCallInsuranceItemDetailSuccess = payload => ({
  type: GET_INSURANCE_ITEM_DETAIL.SUCCESS,
  payload,
});

export const getActionCallInsuranceItemDetailFailure = error => ({
  type: GET_INSURANCE_ITEM_DETAIL.ERROR,
  error,
});
