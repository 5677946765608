import {
  GET_UTOP_POINT_SUCCESS,
  GET_USER_INFO_SUCCESS,
  LOGOUT_REQUEST,
  initUserInfo,
  UPDATE_ACCOUNT_INFO_REQUEST,
} from './constants'

const initialState = {
  utopPoint: 0,
  userInfo: initUserInfo,
  sessionId: '',
  memberCode: '',
}

const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_UTOP_POINT_SUCCESS: {
      return {
        ...state,
        utopPoint: action.balance,
      }
    }
    case GET_USER_INFO_SUCCESS: {
      return {
        ...state,
        userInfo: action.userInfo,
      }
    }
    case UPDATE_ACCOUNT_INFO_REQUEST: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case LOGOUT_REQUEST: {
      return {
        ...initialState,
      }
    }
    default:
      return state
  }
}

export default accountReducer
