export const getListSearchStallsSelector = state => {
  if (state.marketStore.listProductSearchStalls) {
    return state.marketStore.listProductSearchStalls;
  }
  return null;
};
export const getSkipSearchStallsSelector = state => {
  if (state.marketStore.skip) {
    return state.marketStore.skip;
  }
  return null;
};
