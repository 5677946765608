import {GET_PACKAGE_INFO} from './constants';

const initialState = {
  data: {},
  accessToken: '',
};

const getPackageInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PACKAGE_INFO.SUCCESS: {
      return {
        ...state,
        data: action.payload?.data,
        accessToken: action.payload?.accessToken,
      };
    }
    default:
      return state;
  }
};

export default getPackageInfoReducer;
