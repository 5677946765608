import { put, takeLatest, fork, call } from 'redux-saga/effects'
import { APIUtils } from 'utils'
import { markUsedVoucherFailed, markUsedVoucherSuccess } from './actions'
import { MARK_USED_VOUCHER_REQUEST } from './constants'

async function markUsedVoucherlAPI(body, renewToken) {
  const header = { Authorization: 'Bearer ' + renewToken }
  return APIUtils.post(global.apiLinks.markUsed, {
    headers: header,
    body: body,
  })
}

export function* markUsedVoucherSaga(obj) {
  const { onSuccess, onFailed } = obj.payload
  try {
    const renewToken = yield APIUtils.reauthenticate()
    const data = yield call(markUsedVoucherlAPI, obj.payload.body, renewToken)
    if (data.status >= 200 && data.status <= 299) {
      yield put(markUsedVoucherSuccess(data.data))
      if (typeof onSuccess === 'function') {
        onSuccess()
      }
    } else {
      yield put(markUsedVoucherFailed(data))
      if (typeof onFailed === 'function') {
        onFailed()
      }
    }
  } catch (err) {
    yield put(markUsedVoucherFailed(err))
    if (typeof onFailed === 'function') {
      onFailed(err)
    }
  }
}

function* watchMarkUsedVoucher() {
  yield takeLatest(MARK_USED_VOUCHER_REQUEST, markUsedVoucherSaga)
}

export default function* rootChild() {
  yield fork(watchMarkUsedVoucher)
}
