const prefix = 'cart';
export const GET_SUGGEST_COUPON_REQUEST =
  prefix + '/GET_SUGGEST_COUPON_REQUEST';
export const GET_SUGGEST_COUPON_SUCCESS =
  prefix + '/GET_SUGGEST_COUPON_SUCCESS';
export const GET_SUGGEST_COUPON_FAILED = prefix + '/GET_SUGGEST_COUPON_FAILED';
export const ALLOWCATION_COUPON_REQUEST =
  prefix + '/ALLOWCATION_COUPON_REQUEST';
export const ALLOWCATION_COUPON_SUCCESS =
  prefix + '/ALLOWCATION_COUPON_SUCCESS';
export const ALLOWCATION_COUPON_FAILED = prefix + '/ALLOWCATION_COUPON_FAILED';
export const CLEAR_ERRORS = prefix + '/CLEAR_ERRORS';
export const GET_COUPON_DETAIL_REQUEST = prefix + '/GET_COUPON_DETAIL_REQUEST';
export const GET_COUPON_DETAIL_SUCCESS = prefix + '/GET_COUPON_DETAIL_SUCCESS';
export const GET_COUPON_DETAIL_FAILED = prefix + '/GET_COUPON_DETAIL_FAILED';
export const CAL_COUPON_DISCOUNT_REQUEST =
  prefix + '/CAL_COUPON_DISCOUNT_REQUEST';
export const CAL_COUPON_DISCOUNT_SUCCESS =
  prefix + '/CAL_COUPON_DISCOUNT_SUCCESS';
export const CAL_COUPON_DISCOUNT_FAILED =
  prefix + '/CAL_COUPON_DISCOUNT_FAILED';
export const ADD_PRODUCT_REQUEST = prefix + '/ADD_PRODUCT_REQUEST';
export const DELETE_PRODUCT_REQUEST = prefix + '/DELETE_PRODUCT_REQUEST';
export const SET_DESCRIPTION = prefix + '/SET_DESCRIPTION';
export const SET_DELIVERY_ADDRESS = prefix + '/SET_DELIVERY_ADDRESS';
export const SET_VOUCHER = prefix + '/SET_VOUCHER';
export const UPDATE_VOUCHER = prefix + '/UPDATE_VOUCHER';
export const SET_DISCOUNT = prefix + '/SET_DISCOUNT';
export const SET_NUMBER_TABLE = prefix + '/SET_NUMBER_TABLE';
export const CLEAR_ALLOWCATE_COUPON = prefix + '/CLEAR_ALLOWCATE_COUPON';
export const SET_TAKE_AWAY = prefix + '/SET_TAKE_AWAY';
export const SET_DELIVERY_ADDRESS_SELECTED =
  prefix + '/SET_DELIVERY_ADDRESS_SELECTED';
export const GET_SUGGEST_DISCOUNT_SHIPPING_REQUEST =
  prefix + '/GET_SUGGEST_DISCOUNT_SHIPPING_REQUEST';
export const GET_SUGGEST_DISCOUNT_SHIPPING_SUCCESS =
  prefix + '/GET_SUGGEST_DISCOUNT_SHIPPING_SUCCESS';
export const GET_SUGGEST_DISCOUNT_SHIPPING_FAILED =
  prefix + '/GET_SUGGEST_DISCOUNT_SHIPPING_FAILED';
