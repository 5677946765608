import { callApi } from 'helpers/callApi'

import { fork, put, select, takeLatest } from 'redux-saga/effects'
import { getSupportVersionApi } from '@shared/services/api/appVersion'
import {
  compareTwoVersionApp,
  convertVersionToString,
} from '@shared/utils/arrayHandler'
import {
  getSupportedVersionFailed,
  getSupportedVersionSuccess,
  setIsMentionToUpdateFailed,
  setIsMentionToUpdateSuccess,
} from './actions'
import {
  GET_SUPPORTED_VERSION_REQUEST,
  UPDATE_IS_MENTION_TO_UPDATE_REQUEST,
} from './constants'
import Platform from 'helpers/platform'
import DeviceInfo from 'helpers/deviceInfo'

export function* getAppVersionSaga(payload) {
  const { onSuccess, onError } = payload
  try {
    const res = yield callApi(getSupportVersionApi)
    yield put(getSupportedVersionSuccess(res.data))
    yield onSuccess?.()
  } catch (error) {
    yield put(getSupportedVersionFailed(error))
    yield onError?.()
  }
}

export function* setIsMentionToUpdateSaga(obj) {
  try {
    const latestVersion = yield select(
      (state) => state.appVersion.latestVersion,
    )
    const currentAppVersion = convertVersionToString(DeviceInfo.getVersion())
    const isMention = compareTwoVersionApp(latestVersion, currentAppVersion)
    yield put(setIsMentionToUpdateSuccess(isMention))
  } catch (error) {
    yield put(setIsMentionToUpdateFailed(error))
  }
}

function* watchAppVersion() {
  yield takeLatest(GET_SUPPORTED_VERSION_REQUEST, getAppVersionSaga)
  yield takeLatest(
    UPDATE_IS_MENTION_TO_UPDATE_REQUEST,
    setIsMentionToUpdateSaga,
  )
}

export default function* rootChild() {
  yield fork(watchAppVersion)
}
