import { call, put, takeLatest, fork } from "redux-saga/effects";
import api from "../api/api";
import actions from "./actions";
import * as TYPE from "./constants";

const sagas = {
  *[TYPE.GET_LIST_CARD_TOPUP.request](action) {
    try {
      const data = yield call(
        api[TYPE.GET_LIST_CARD_TOPUP.request],
        action.payload
      );
      if (data.status === 200) {
        yield put(actions.GET_LIST_CARD_TOPUP.success(data.data));
      } else {
        yield put(actions.GET_LIST_CARD_TOPUP.failure(data?.data));
      }
    } catch (error) {
      yield put(actions.GET_LIST_CARD_TOPUP.failure(error?.data));
    }
  },

  *[TYPE.GET_LIST_BUY_CARD.request](action) {
    try {
      const data = yield call(
        api[TYPE.GET_LIST_BUY_CARD.request],
        action.payload
      );
      if (data.status === 200) {
        yield put(actions.GET_LIST_BUY_CARD.success(data.data));
      } else {
        yield put(actions.GET_LIST_BUY_CARD.failure(data?.data));
      }
    } catch (error) {
      yield put(actions.GET_LIST_BUY_CARD.failure(error?.data));
    }
  },

  *[TYPE.CREATE_ODER_TOPUP.request](action) {
    try {
      const data = yield call(
        api[TYPE.CREATE_ODER_TOPUP.request],
        action.payload
      );
      if (data.status === 200) {
        yield put(actions.CREATE_ODER_TOPUP.success(data.data));
      } else {
        yield put(actions.CREATE_ODER_TOPUP.failure(data?.data));
      }
    } catch (error) {
      yield put(actions.CREATE_ODER_TOPUP.failure(error?.data));
    }
  },
};

function* watchedSaga() {
  yield takeLatest(TYPE.GET_LIST_CARD_TOPUP.request, sagas[TYPE.GET_LIST_CARD_TOPUP.request]);
  yield takeLatest(TYPE.GET_LIST_BUY_CARD.request, sagas[TYPE.GET_LIST_BUY_CARD.request]);
  yield takeLatest(TYPE.CREATE_ODER_TOPUP.request, sagas[TYPE.CREATE_ODER_TOPUP.request]);

}

export default function* rootChild() {
  yield fork(watchedSaga);
}
