import React, { useEffect } from "react";
import { useDispatch, useSelector, connect } from "react-redux";
import { useLocation, useHistory } from "react-router";
import Skeleton from "react-loading-skeleton";
import moment from "moment";
import { Markup } from "interweave";
import { CButton } from "@coreui/react";
import { toast } from "react-toastify";
import { toastOptions } from "utils/toast";
import MESSAGES from "constants/vi";
import { COUPON_DETAIL_PAGE_MODES, CART_KEYS_MAP } from "constants/types";
import { getListProduct } from "@shared/redux/smartMenu/selectors";
import { bindActionCreators } from "redux";

import useQuery from "assets/hooks/useQuery";
import DefaultBanner from "assets/img/coupon-default-banner.png";
import { LoadingOverlay, PageHeader } from "views/common/component";
import { TYPE as ROOT_TYPE } from "views/common/store/constants/constants";

import { updateListCouponSuggest } from "@shared/redux/common/actions";
import { updateListCouponSuggestForStore } from "@shared/redux/smartMenu/actions";
import {
  allowcationCouponSuccess,
  setVoucher,
} from "@shared/redux/cart/actions";
import {
  getCouponDetailForVoucherHandle,
  getAvailableCouponHandle,
  dataCouponSelected,
} from "@shared/redux/couponForVoucher/actions";
import { setShowMessageError } from "@shared/redux/coupon/actions";
import actions from "./store/actions";
import { TYPE } from "./store/constants";

import "./CouponDetailV3.scss";

const MESSAGE_KEY = "CouponDetail"; // key của object chứa message
const UNKNOWN = "unknown";

/**
 * must pass state through location.state:
 * - mode: from COUPON_DETAIL_PAGE_MODES
 * - callbackUrl: when finish logic, redirect to this callbackUrl
 * - isAllocated: show/hide button SAVE COUPON (Lay ma)
 * - transactionCode: when transactionCode existed, it means this coupon was allocated to user before.
 * use transactionCode to pass to data of cart, so when cart page mount, it'll call respective api to calc discount from coupon
 * - prevPage: path to direct when click button back
 * example:
 *  - location.state.action: enum(COUPON_DETAIL_PAGE_MODES.APPLY, COUPON_DETAIL_PAGE_MODES.ALLOCATE) *must have*
 *  - location.state.callbackUrl: string *must have*
 *  - location.state.isAllocated: boolean *must have when mode = COUPON_DETAIL_PAGE_MODES.ALLOCATE*
 *  - location.state.transactionCode: string (optional)
 *  - location.state.prevPage: string *must have*
 * @returns CouponDetail page
 */
function CouponDetailV3(props) {
  const query = useQuery();
  const id = query.get("id");
  const location = useLocation();
  const statusMark = location.state?.status;
  localStorage.setItem("previousBackListVoucher", statusMark);
  localStorage.setItem("scrollPosition", location?.state?.trackingPosition);
  const history = useHistory();
  const dispatch = useDispatch();

  const { couponInfo, isLoading } = useSelector((state) => state[TYPE]);
  const {
    // isLoading: isRootLoading,
    userInfo,
  } = useSelector((state) => state[ROOT_TYPE]);

  const handleClickBtnMain = () => {
    if (!userInfo) {
      toast.warn("Vui lòng đăng nhập để nhận ngay ưu đãi.", {
        ...toastOptions,
        toastId: `${TYPE}_REQUIRE_LOGIN`,
        onClick: () => {
          history.push("/login");
        },
      });

      return;
    }

    if (!location.state?.mode || !location.state?.callbackUrl) {
      console.warn("missing location.state.mode or location.state.callbackUrl");
      return;
    }

    switch (location.state.mode) {
      case COUPON_DETAIL_PAGE_MODES.ALLOCATE: {
        if (
          typeof location.state?.isAllocated !== "boolean" ||
          location.state?.isAllocated === true ||
          !couponInfo?.data?.code?.iv
        ) {
          console.warn(
            "mode ALLOCATE: missing location.state.isAllocated or couponInfo.data.code"
          );
          return;
        }

        /**
         * Lấy mã và quay về trang trước
         */
        const onSuccessSaveCoupon = () => {
          toast.success("Lấy mã thành công!", {
            toastId: `${TYPE}_SAVE_SUGGESTED_COUPON_SUCCESS`,
            ...toastOptions,
          });

          // history.push(location.state.callbackUrl);
          if (location.state?.callbackUrl) {
            history.goBack();
          }
        };

        const onFailureSaveCoupon = (message) => {
          const errMsgToToast =
            MESSAGES?.[MESSAGE_KEY]?.error?.[message] ||
            MESSAGES?.[MESSAGE_KEY]?.error?.[UNKNOWN];

          toast.error(errMsgToToast, {
            toastId: `${TYPE}_SAVE_SUGGESTED_COUPON_FAILURE`,
            ...toastOptions,
          });
        };

        // call api
        dispatch(
          actions.SAVE_SUGGESTED_COUPON.request({
            onSuccessSaveCoupon,
            onFailureSaveCoupon,
            code: couponInfo.data.code.iv,
          })
        );

        break;
      }

      case COUPON_DETAIL_PAGE_MODES.APPLY: {
        /**
         * Áp dụng mã ưu đãi vào đơn hàng: Quay về trang giỏ hàng
         */
        const handleApplyCouponToOrder = () => {
          let LSKey;

          if (
            String(location.state.callbackUrl).indexOf(
              CART_KEYS_MAP.FNB_CART.path
            ) !== -1
          ) {
            LSKey = CART_KEYS_MAP.FNB_CART.LOCAL_STORAGE_KEY;
          }
          if (
            String(location.state.callbackUrl).indexOf(
              CART_KEYS_MAP.VOUCHER_CART.path
            ) !== -1
          ) {
            LSKey = CART_KEYS_MAP.VOUCHER_CART.LOCAL_STORAGE_KEY;
          }

          if (
            String(location.state.callbackUrl).indexOf(
              CART_KEYS_MAP.MARKET_CART.path
            ) !== -1
          ) {
            LSKey = CART_KEYS_MAP.FNB_CART.LOCAL_STORAGE_KEY;
          }

          if (!LSKey) return;
          if (
            !couponInfo?.data?.code?.iv &&
            !couponInfo?.data?.name?.iv &&
            !couponInfo?.data?.name?.vi &&
            !couponInfo?.data?.name?.en
          ) {
            return;
          }

          const appliedCoupon = {
            code: couponInfo.data.code.iv,
            name:
              couponInfo.data.name.iv ||
              couponInfo.data.name.vi ||
              couponInfo.data.name.en,
            transactionCode: location.state?.transactionCode,
          };

          const cartData = props.listProduct;

          if (!cartData) return;

          const couponData = {
            code: couponInfo.data.code.iv,
            couponCode: couponInfo.data.code.iv,
            couponId: couponInfo.id,
            endDateTime: couponInfo.data?.endDateTime?.iv,
            isAllocated: true,
            name:
              couponInfo.data.name.iv ||
              couponInfo.data.name.vi ||
              couponInfo.data.name.en,
            transactionCode: location.state?.transactionCode,
          };

          const newCart = {
            ...cartData,
            appliedCoupon,
          };
          localStorage.setItem(LSKey, JSON.stringify(newCart));
          props.setVoucher(couponData);
          props.getAvailableCouponHandle(
            couponData?.code,
            (res) => {
              // eslint-disable-next-line no-unused-expressions
              props.allowcationCouponSuccess({
                ...couponData,
                ...res.data.coupons[0],
              });
            },
            (err) => {
              console.log("errorAlow", err);
            }
          );
          // history.push(location.state.callbackUrl);
          if (
            ["/order", "/vouchercart", "/CartMarket"].includes(
              location.state.prevPathname
            )
          ) {
            history.goBack();
            return;
          }

          console.log("1");
          // case /order -> /promotion -> /coupondetail
          // case /vouchercart -> /promotion -> /coupondetail
          if (
            location.state.prevPathname === "/promotion" ||
            location.state.prevPathname === "/promotionV2"
          ) {
            history.go(-2);
          }
        };

        handleApplyCouponToOrder();
        break;
      }

      default: {
        console.log("8");
        console.warn("fall into unsupported mode:", location.state.mode);
        break;
      }
    }
  };

  /**
   * Hiển thị danh sách cửa hàng có thể áp dụng mã ưu đãi này
   * todo: cần làm rõ backlog
   */
  // const handleShowListStore = () => {
  //   if (!couponInfo?.data?.stores?.iv) return;
  //   if (!location.state?.prevPage) {
  //     console.log("missing location.state.prevPage");
  //     return;
  //   }

  //   history.push("/applicablestores", {
  //     stores: couponInfo.data.stores.iv,
  //     prevPage: location.state.prevPage,
  //     mode: COUPON_DETAIL_PAGE_MODES.SHOW_LIST_STORES,
  //   });
  // };

  /**
   * Back về trang trước
   */
  const handleGoBack = () => {
    if (location.state?.prevPage) {
      history.goBack();
      return;
    }

    history.push("/");
  };

  useEffect(() => {
    if (!id) return;

    let viewAsGuest = true;

    if (userInfo) {
      viewAsGuest = false;
    }

    dispatch(actions.GET_COUPON_INFO.request({ couponId: id, viewAsGuest }));
  }, [id]);

  // *** RENDER SKELETON
  if (!couponInfo && isLoading.GET_COUPON_INFO) {
    return (
      <div className="coupon-detail-page">
        <Skeleton width="100%" height="350px" />

        <div className="brief-info">
          <div className="brief-info__coupon-name only-2-lines">
            <Skeleton width="23ch" />
          </div>
          <div className="brief-info__expiration-date">
            <Skeleton width="13ch" />
          </div>
        </div>

        <div className="manual">
          <div className="manual__title only-2-lines">
            <Skeleton width="27ch" />
          </div>

          <div className="manual__content">
            <Skeleton width="100%" count={5} />
            <Skeleton width="70%" />
          </div>
        </div>

        <div className="btn--main__container">
          <Skeleton width="100%" height="40px" className="btn--main" />
        </div>
      </div>
    );
  }

  const handleShowListStore = () => {
    if (!location.state?.prevPathname === "/myvoucher") {
      console.warn("prevPage is not '/myvoucher'.");
      return;
    }

    if (!couponInfo?.data) {
      console.warn("couponInfo.data is missing.");
      return;
    }

    const couponToUse = {
      code: couponInfo.data.code?.iv || couponInfo.data.code,
      name:
        couponInfo.data.name?.vi ||
        couponInfo.data.name?.en ||
        couponInfo.data.name?.iv ||
        couponInfo.data.name ||
        "",
    };

    history.push("/applicablestores", {
      couponToUse,
      dataForAPICall: {
        couponId: couponInfo.id,
      },
    });
  };

  return (
    <div className="coupon-detail-page">
      <LoadingOverlay isLoading={isLoading.SAVE_SUGGESTED_COUPON} />

      <PageHeader title="Chi tiết coupon" onNavigate={handleGoBack} />

      <object
        data={couponInfo?.data?.imageUrl?.iv || DefaultBanner}
        type="images/png"
        className="banner"
      >
        <img
          src={couponInfo?.data?.imageUrl?.iv || DefaultBanner}
          alt="default banner"
          className="banner"
        />
      </object>

      <div className="brief-info">
        <div className="brief-info__coupon-name only-2-lines">
          {couponInfo?.data?.name?.iv ||
            couponInfo?.data?.name?.vi ||
            couponInfo?.data?.name?.en}
        </div>

        {(couponInfo?.data?.endDateTime?.iv || location?.state?.expiredDay) && (
          <div className="brief-info__expiration-date">
            <span>Hết hạn: </span>
            <span>
              {moment
                .utc(
                  location.state?.expiredDay ||
                    couponInfo?.data?.endDateTime?.iv
                )
                .local()
                .format("DD/MM/YYYY")}
            </span>
          </div>
        )}
      </div>

      {couponInfo?.data?.description?.iv && (
        <div className="manual">
          <div className="manual__title only-2-lines">
            Thể lệ và hướng dẫn sử dụng
          </div>

          <div className="manual__content">
            <Markup content={couponInfo.data.description.iv} />
          </div>
        </div>
      )}
      {/*   */}
      {/* //? ẩn feature show list applicable stores */}
      {/* {Number(couponInfo?.data?.stores?.iv?.length) > 0 &&
        statusMark === "Redeem" && (
          <div className="btn--main__container">
            <CButton
              type="button"
              className="btn--main"
              onClick={handleShowListStore}
            >
              Sử dụng
            </CButton>
          </div>
        )} */}

      {location.state?.mode === COUPON_DETAIL_PAGE_MODES.SHOW_LIST_STORES &&
        statusMark === "Redeem" && (
          <div className="btn--main__container">
            <CButton
              type="button"
              className="btn--main"
              onClick={handleShowListStore}
            >
              Sử dụng
            </CButton>
          </div>
        )}

      {location.state?.mode === COUPON_DETAIL_PAGE_MODES.APPLY && (
        <div className="btn--main__container">
          <CButton
            type="button"
            className="btn--main"
            onClick={handleClickBtnMain}
          >
            Sử dụng
          </CButton>
        </div>
      )}

      {location.state?.mode === COUPON_DETAIL_PAGE_MODES.ALLOCATE &&
        location.state?.isAllocated === false && (
          <div className="btn--main__container">
            <CButton
              type="button"
              className="btn--main"
              onClick={handleClickBtnMain}
            >
              Lấy mã
            </CButton>
          </div>
        )}
    </div>
  );
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      dataCouponSelected,
      allowcationCouponSuccess,
      updateListCouponSuggestForStore,
      updateListCouponSuggest,
      getCouponDetailForVoucherHandle,
      setShowMessageError,
      setVoucher,
      getAvailableCouponHandle,
    },
    dispatch
  );

const mapStateToProps = (state) => ({
  listProduct: getListProduct(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(CouponDetailV3);
