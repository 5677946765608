import request from "services/request";
import * as TYPE from "../store/constants";

const api = {
  [TYPE.UPLOAD_AVATAR.request]: async (data) => {
    return request(`upload/api/Upload/GetToken?type=1`, "GET", data);
  },
  [TYPE.UPDATE_USER_INFO.request]: async (data) => {
    return request(`personal/UpdateUserProfile`, "PUT", data);
  },
  [TYPE.GET_UTOP_INFOMATION.request]: async () => {
    return request(`wallet/user`, "GET");
  },
};

export default api;
