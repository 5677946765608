import { put, takeLatest, fork } from "redux-saga/effects";
import {
  GET_STORE_INFO_BY_ID_REQUEST,
  GET_PRODUCT_SUGGEST_BY_USER_REQUEST,
} from "./constants";
import {
  getStoreInfoByIdFailed,
  getStoreInfoByIdSuccess,
  getProductSuggestByUserSuccess,
  getProductSuggestByUserFailed,
} from "./actions";
import {
  getStoreInfoByIdApi,
  getProductSuggestByUserApi,
} from "@shared/services/api/store";
import { storeInfoParser } from "./parser";
import { callApi } from "helpers/callApi";
import { listSuggestProductByUserParser } from "./parser";

export function* getStoreInfoByIdSaga(obj) {
  const { onSuccess, storeId, onFailed } = obj;
  try {
    const res = yield callApi(getStoreInfoByIdApi, { storeId });
    const result = storeInfoParser(res.data);
    yield put(getStoreInfoByIdSuccess(result));
    if (typeof onSuccess === "function") {
      onSuccess(result);
    }
  } catch (err) {
    if (typeof onFailed === "function") {
      onFailed(err);
    }
    yield put(getStoreInfoByIdFailed(err));
  }
}

export function* getProductSuggestByUserSaga(obj) {
  const { onSuccess, storeId, merchantId } = obj;
  try {
    const res = yield callApi(getProductSuggestByUserApi, {
      storeId,
      merchantId,
    });
    yield put(
      getProductSuggestByUserSuccess(
        listSuggestProductByUserParser(res, merchantId)
      )
    );
    if (typeof onSuccess === "function") {
      onSuccess(res);
    }
  } catch (err) {
    const { status } = err || {};
    if (status >= 400) {
      Alert.alert("Utop", err?.data?.message);
    }
    yield put(getProductSuggestByUserFailed(err));
  }
}

function* watchStore() {
  yield takeLatest(GET_STORE_INFO_BY_ID_REQUEST, getStoreInfoByIdSaga);
  yield takeLatest(
    GET_PRODUCT_SUGGEST_BY_USER_REQUEST,
    getProductSuggestByUserSaga
  );
}

export default function* rootChild() {
  yield fork(watchStore);
}
