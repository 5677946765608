export const genActionTypes = (actionName) => ({
  request: `${actionName}_REQUEST`,
  success: `${actionName}_SUCCESS`,
  failure: `${actionName}_FAILURE`,
});

export const genActions = (actionTypes) => {
  return {
    request: (payload) => ({ type: actionTypes.request, payload }),
    success: (payload) => ({ type: actionTypes.success, payload }),
    failure: (payload) => ({ type: actionTypes.failure, payload }),
  };
};
