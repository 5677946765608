import { callApi } from "helpers/callApi";
import {
  getListInsuranceTypeApi,
  getListInsuranceWithTypeApi,
  getInsuranceItemDetailApi,
} from "@shared/services/api/insurance";
import { call, fork, put, takeEvery, takeLatest } from "redux-saga/effects";
import {
  getListInsuranceItemWithTypeFailure,
  getListInsuranceItemWithTypeSuccess,
  getListInsuranceTypeFailure,
  getListInsuranceTypeSuccess,
  getActionCallInsuranceItemDetailSuccess,
  getActionCallInsuranceItemDetailFailure,
} from "./actions";
import {
  GET_LIST_INSURANCE_TYPE,
  GET_LIST_INSURANCE_ITEM_WITH_TYPE,
  GET_INSURANCE_ITEM_DETAIL,
} from "./constants";

export function* getListInsuranceTypeSaga(action) {
  const { onSuccess, onFailed } = action;
  try {
    const res = yield callApi(getListInsuranceTypeApi);
    yield put(getListInsuranceTypeSuccess());
    onSuccess?.(res.data);
  } catch (error) {
    onFailed?.();
    yield put(getListInsuranceTypeFailure(error));
  }
}

export function* getListInsuranceItemWithTypeSaga(action) {
  const { onSuccess, skip, insuranceTypeId, take, onFailed } = action;
  try {
    const res = yield callApi(getListInsuranceWithTypeApi, {
      insuranceTypeId,
      skip,
      take,
    });
    yield put(getListInsuranceItemWithTypeSuccess());
    onSuccess?.(res.data);
  } catch (error) {
    onFailed?.();
    yield put(getListInsuranceItemWithTypeFailure(error));
  }
}

export function* getInsuranceItemDetail(action) {
  const { idInsuranceItem, onSuccess, onFailed } = action;
  try {
    const repInsuranceItemDetail = yield callApi(
      getInsuranceItemDetailApi,
      idInsuranceItem
    );
    yield call(onSuccess, repInsuranceItemDetail.data);
    yield put(
      getActionCallInsuranceItemDetailSuccess(repInsuranceItemDetail.data)
    );
  } catch (error) {
    onFailed?.();
    yield put(getActionCallInsuranceItemDetailFailure(error));
  }
}

function* watchInsurance() {
  yield takeLatest(GET_LIST_INSURANCE_TYPE.HANDLER, getListInsuranceTypeSaga);
  yield takeEvery(
    GET_LIST_INSURANCE_ITEM_WITH_TYPE.HANDLER,
    getListInsuranceItemWithTypeSaga
  );
  yield takeLatest(GET_INSURANCE_ITEM_DETAIL.HANDLER, getInsuranceItemDetail);
}

export default function* rootChild() {
  yield fork(watchInsurance);
}
