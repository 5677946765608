/* eslint-disable no-restricted-syntax */

import { CURRENCY_TYPE } from "constants/types";

/* eslint-disable no-continue */
const colors = {
  black: "#000",
  red: "#E02020",
  gray: "#939393",
  green: "#35C24B",
};

export const phoneResult = (str) => {
  if (str.slice(0, 3) === "+84") {
    return `0${str.slice(3, 14)}`.replaceAll(" ", "");
  }
  return str.replaceAll(" ", "");
};

export const encodeQueryData = (data) => {
  const values = [];
  for (const d in data) {
    if (data[d] === null || data[d] === undefined) continue;
    values.push(`${encodeURIComponent(d)}=${encodeURIComponent(data[d])}`);
  }
  return `?${values.join("&")}`;
};

/**
 * same with encodeQueryData but supports typeof Array
 * @param {object} data contains query params and their values as an Object
 * @returns
 */
export const encodeQueryDataV2 = (data) => {
  const values = [];

  for (const d in data) {
    if (data[d] === null || data[d] === undefined) continue;

    if (Array.isArray(data[d])) {
      data[d].forEach((val) =>
        values.push(`${encodeURIComponent(d)}=${encodeURIComponent(val)}`)
      );
      continue;
    }
    values.push(`${encodeURIComponent(d)}=${encodeURIComponent(data[d])}`);
  }
  return `?${values.join("&")}`;
};

export const getDeliveryListStatus = (status, currency) => {
  if (!status) return null;
  const key = status.toLowerCase();
  switch (key) {
    case "new":
      return {
        title:
          currency?.name?.toLowerCase() === CURRENCY_TYPE.UTOP.name
            ? "Chưa hoàn thành"
            : "Chưa thanh toán",
        color: "#151515",
      };
    case "payment":
      return { title: "Đặt đơn thành công", color: "#151515" };
    case "wait":
      return { title: "Chờ xác nhận", color: "#151515" };
    case "confirmed":
      return { title: "Đang chuẩn bị hàng", color: "#151515" };
    case "available":
      return { title: "Chờ nhận hàng", color: "#151515" };
    case "delivered":
      return { title: "Hoàn thành", color: "#58CA30" };
    case "cancel":
      return { title: "Đã huỷ", color: "#626262" };
    case "failed":
      return { title: "Đã huỷ", color: "#626262" };
    default:
      return { title: "", color: "#151515" };
  }
};

export const getDeliveryVoucherStatus = (status, currency) => {
  if (!status) return null;
  const key = status.toLowerCase();
  switch (key) {
    case "new":
      return {
        title:
          currency?.name?.toLowerCase() === CURRENCY_TYPE.UTOP.name
            ? "Chưa hoàn thành"
            : "Chưa thanh toán",
        color: "#028CF9",
      };
    case "payment":
      return { title: "Đặt đơn thành công", color: "#151515" };
    case "wait":
      return { title: "Chờ xác nhận", color: "#151515" };
    case "confirmed":
      return { title: "Đang chuẩn bị hàng", color: "#151515" };
    case "available":
      return { title: "Chờ nhận hàng", color: "#151515" };
    case "delivered":
      return { title: "Hoàn thành", color: "#58CA30" };
    case "cancel":
      return { title: "Đã huỷ", color: "#626262" };
    case "failed":
      return { title: "Đã huỷ", color: "#626262" };
    default:
      return { title: "", color: "#151515" };
  }
};

export const getStepByStep = (status) => {
  if (!status)
    return { count: 0, reset: 0, cancel: false, steps: [], statuses: [] };
  const key = status.toLowerCase();
  switch (key) {
    case "new":
      return {
        count: -1,
        reset: false,
        cancel: true,
        steps: [
          { name: "Đã đặt", color: colors.gray, statuses: [] },
          {
            name: "Chờ xác nhận",
            color: colors.gray,
            statuses: ["confirmed"],
          },
          {
            name: "Chờ giao hàng",
            color: colors.gray,
            statuses: ["available"],
          },
          { name: "Nhận hàng", color: colors.gray, statuses: ["delivered"] },
        ],
      };
    case "payment":
      return {
        count: 0,
        reset: false,
        cancel: true,
        steps: [
          { name: "Đã đặt", color: colors.green, statuses: ["new", "payment"] },
          {
            name: "Chờ xác nhận",
            color: colors.black,
            statuses: ["confirmed"],
          },
          {
            name: "Chờ giao hàng",
            color: colors.gray,
            statuses: ["available"],
          },
          { name: "Nhận hàng", color: colors.gray, statuses: ["delivered"] },
        ],
      };
    case "confirmed":
      return {
        count: 1,
        reset: false,
        cancel: false,
        steps: [
          { name: "Đã đặt", color: colors.green, statuses: ["new", "payment"] },
          { name: "Đã xác nhận", color: colors.green, statuses: ["confirmed"] },
          {
            name: "Chờ giao hàng",
            color: colors.black,
            statuses: ["available"],
          },
          { name: "Nhận hàng", color: colors.gray, statuses: ["delivered"] },
        ],
      };
    case "available":
      return {
        count: 2,
        reset: false,
        cancel: false,
        steps: [
          { name: "Đã đặt", color: colors.green, statuses: ["new", "payment"] },
          { name: "Đã xác nhận", color: colors.green, statuses: ["confirmed"] },
          {
            name: "Đang giao hàng",
            color: colors.green,
            statuses: ["available"],
          },
          { name: "Nhận hàng", color: colors.black, statuses: ["delivered"] },
        ],
      };
    case "delivered":
      return {
        count: 3,
        reset: true,
        cancel: false,
        steps: [
          { name: "Đã đặt", color: colors.green, statuses: ["new", "payment"] },
          { name: "Đã xác nhận", color: colors.green, statuses: ["confirmed"] },
          {
            name: "Đã giao hàng",
            color: colors.green,
            statuses: ["available"],
          },
          { name: "Nhận hàng", color: colors.green, statuses: ["delivered"] },
        ],
      };
    case "wait":
      return {
        count: 0,
        reset: false,
        cancel: true,
        steps: [
          { name: "Đã đặt", color: colors.green, statuses: ["new", "payment"] },
          {
            name: "Hết món",
            color: colors.red,
            statuses: ["wait"],
            description: "(Chờ khách xác thực)",
          },
          {
            name: "Chờ giao hàng",
            color: colors.gray,
            statuses: ["available"],
          },
          { name: "Nhận hàng", color: colors.gray, statuses: ["delivered"] },
        ],
      };
    case "cancel":
      return {
        count: 1,
        reset: true,
        cancel: false,
        steps: [
          { name: "Đã đặt", color: colors.green, statuses: ["new", "payment"] },
          { name: "Đã huỷ", color: colors.red, statuses: ["cancel"] },
        ],
      };
    case "cancel_wait":
      return {
        count: 2,
        reset: true,
        cancel: false,
        steps: [
          { name: "Đã đặt", color: colors.green, statuses: ["new", "payment"] },
          { name: "Hết món", color: colors.red, statuses: ["wait"] },
          { name: "Đã huỷ", color: colors.red, statuses: ["cancel"] },
        ],
      };
    default:
      return { count: 0, reset: false, cancel: false, steps: [], statuses: [] };
  }
};

export const getStepByStepShop = (status) => {
  if (!status)
    return { count: 0, reset: 0, cancel: false, steps: [], statuses: [] };
  const key = status.toLowerCase();
  switch (key) {
    case "new":
      return {
        count: -1,
        reset: false,
        cancel: true,
        steps: [
          { name: "Đã đặt", color: colors.gray, statuses: [] },
          {
            name: "Check-in",
            color: colors.gray,
            statuses: ["confirmed"],
          },
          {
            name: "Sẵn sàng giao",
            color: colors.gray,
            statuses: ["available"],
          },
          { name: "Nhận hàng", color: colors.gray, statuses: ["delivered"] },
        ],
      };
    case "payment":
      return {
        count: 0,
        reset: false,
        cancel: true,
        steps: [
          { name: "Đã đặt", color: colors.green, statuses: ["new", "payment"] },
          {
            name: "Check-in",
            color: colors.black,
            statuses: ["confirmed"],
          },
          {
            name: "Sẵn sàng giao",
            color: colors.gray,
            statuses: ["available"],
          },
          { name: "Nhận hàng", color: colors.gray, statuses: ["delivered"] },
        ],
      };
    case "confirmed":
      return {
        count: 1,
        reset: false,
        cancel: false,
        steps: [
          { name: "Đã đặt", color: colors.green, statuses: ["new", "payment"] },
          { name: "Check-in", color: colors.green, statuses: ["confirmed"] },
          {
            name: "Sẵn sàng giao",
            color: colors.black,
            statuses: ["available"],
          },
          { name: "Nhận hàng", color: colors.gray, statuses: ["delivered"] },
        ],
      };
    case "available":
      return {
        count: 2,
        reset: false,
        cancel: false,
        steps: [
          { name: "Đã đặt", color: colors.green, statuses: ["new", "payment"] },
          { name: "Check-in", color: colors.green, statuses: ["confirmed"] },
          {
            name: "Sẵn sàng giao",
            color: colors.green,
            statuses: ["available"],
          },
          { name: "Nhận hàng", color: colors.black, statuses: ["delivered"] },
        ],
      };
    case "delivered":
      return {
        count: 3,
        reset: true,
        cancel: false,
        steps: [
          { name: "Đã đặt", color: colors.green, statuses: ["new", "payment"] },
          { name: "Check-in", color: colors.green, statuses: ["confirmed"] },
          {
            name: "Sẵn sàng giao",
            color: colors.green,
            statuses: ["available"],
          },
          { name: "Nhận hàng", color: colors.green, statuses: ["delivered"] },
        ],
      };
    case "wait":
      return {
        count: 0,
        reset: false,
        cancel: true,
        steps: [
          { name: "Đã đặt", color: colors.green, statuses: ["new", "payment"] },
          {
            name: "Check-in",
            color: colors.red,
            statuses: ["wait"],
          },
          {
            name: "Sẵn sàng giao",
            color: colors.gray,
            statuses: ["available"],
          },
          { name: "Nhận hàng", color: colors.gray, statuses: ["delivered"] },
        ],
      };
    case "cancel":
      return {
        count: 1,
        reset: true,
        cancel: false,
        steps: [
          { name: "Đã đặt", color: colors.green, statuses: ["new", "payment"] },
          { name: "Đã huỷ", color: colors.red, statuses: ["cancel"] },
        ],
      };
    default:
      return { count: 0, reset: false, cancel: false, steps: [], statuses: [] };
  }
};

export function getJsonFromUrl(url) {
  if (!url) return {};
  const query = url.substr(1);
  const result = {};
  query.split("&").forEach((part) => {
    const item = part.split("=");
    result[item[0]] = decodeURIComponent(item[1]);
  });
  return result;
}

export function getDataFromUrl(url) {
  if (!url) return {};

  const arrThis = url.map((item) => item.replace("=", "^"));
  const currentArr = arrThis.map((item) => item.split("^"));
  const lastArray = currentArr.map((item) => item[1]);

  return lastArray;
}

// export const phoneResult = (str) => {
//   if (str.slice(0, 3) === "+84") {
//     return `0${(str).slice(3, 14)}`.replaceAll(' ', '');
//   }
//   return str.replaceAll(' ', '');
// }

export const viettelPhone =
  "(096|097|098|032|033|034|035|036|037|038|039)+([0-9]{7})$";
export const mobiPhone = "(089|090|093|070|079|077|076|078)+([0-9]{7})$";
export const vinaPhone = "(088|091|094|083|084|085|081|082|087)+([0-9]{7})$";
export const gPhone = "(099|059)+([0-9]{7})$";
export const vnPhone = "(092|056|058|052)+([0-9]{7})$";

export const REGEX = {
  viettelPhoneNum: viettelPhone,
  mobiPhoneNum: mobiPhone,
  vinaPhoneNum: vinaPhone,
  gPhoneNum: gPhone,
  vnPhoneNum: vnPhone,
};

/**
 * Transfer a random string (Vietnamese/English) to a standardized string for searching
 * @param str string to be convert to standard type for easier searching
 * @returns A normalized string, without special character of Vietnamese, and in lowercase
 */
export const standardizeStr = (str) => {
  return str
    .normalize("NFD") // chuyển về dạng tổ hợp
    .replace(/[\u0300-\u036f]/g, "") // xóa các ký tự dấu tổ hợp
    .replace(/[đĐ]/g, (m) => (m === "đ" ? "d" : "D")) // chuyển chữ đ/Đ thành d/D
    .toLowerCase();
};

export const isString = (str) => {
  if (!str || typeof str !== "string" || str.length === 0) return false;
  return true;
};

export const encodeQueryUrl = (obj) => {
  return Object.keys(obj)
    .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`)
    .join("&");
};

// export const getDataFromShortcutURL = (locationSearch) => {
//   const queryParams = new URLSearchParams(locationSearch);
//   const actionType = queryParams.get("actionType");
//   const name = queryParams.get("name"); // title của page

//   // only keep others query params for api call
//   queryParams.delete("actionType");
//   queryParams.delete("name");
//   // const queryParamsToAppend = decodeURIComponent(`?${queryParams.toString()}`);
//   const queryParamsToAppend = decodeURIComponent(
//     `?${standardizeParamFeedSearch(queryParams)}`
//   );

//   return {
//     name,
//     actionType,
//     queryParamsToAppend,
//   };
// };

export const getPathnameAndSearchFromURLString = (urlString) => {
  const url = new URL(urlString);
  return `${url.pathname}${url.search}`;
};
