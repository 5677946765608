import {
  GET_LIST_BOOKING_REQUEST,
  GET_DETAIL_BOOKING_REQUEST,
  CANCEL_BOOKING_REQUEST,
  GET_LIST_BOOKING_REQUEST_SUCCESS,
  CLEAR_LIST_BOOKING,
  IS_RELOAD_LIST_BOOKING,
} from './constants';

export const getListBookingUtopBusinessRequest = (
  payload,
  onSuccess,
  onError,
) => ({
  type: GET_LIST_BOOKING_REQUEST,
  payload,
  onSuccess,
  onError,
});
export const getDetailBookingUtopBusinessRequest = (
  payload,
  onSuccess,
  onError,
) => ({
  type: GET_DETAIL_BOOKING_REQUEST,
  payload,
  onSuccess,
  onError,
});
export const cancelBookingUtopBusinessRequest = (
  payload,
  onSuccess,
  onError,
) => ({
  type: CANCEL_BOOKING_REQUEST,
  payload,
  onSuccess,
  onError,
});
export const getListBookingUtopBusinessSuccess = listBooking => ({
  type: GET_LIST_BOOKING_REQUEST_SUCCESS,
  listBooking,
});
export const clearListBookingHistoryBusiness = () => ({
  type: CLEAR_LIST_BOOKING,
});
export const updateIsReloadingListBooking = isReload => ({
  type: IS_RELOAD_LIST_BOOKING,
  isReload,
});
