import request, { requestWithoutToken } from "services/request";
import { encodeQueryData } from "utils/stringFormat";

import * as TYPE from "../store/constants/constants";

const api = {
  [TYPE.GET_SEARCH_SUGGESTION.request]: async (params) => {
    const query = encodeQueryData({
      searchtext: params.searchText,
      lat: params.lat,
      long: params.long,
      skip: params.skip,
      take: params.take,
    });
    return requestWithoutToken(`/feed/Search${query}`, "GET", null, {
      "content-type": "application/json",
      "Access-Control-Allow-Credentials": true,
      "Ocp-Apim-Subscription-Key":
        process.env.REACT_APP_OCP_APIM_SUBSCRIPTION_KEY,
      "X-Client": "Web",
    });
  },

  // [TYPE.SEARCH_LIST.request]: async (params) => {
  //   const query = encodeQueryData({
  //     searchtext: params.searchText,
  //     lat: params.lat,
  //     long: params.long,
  //     skip: params.skip,
  //   });
  //   return requestWithoutToken(`/feed/Search${query}`, "GET", null, {
  //     "content-type": "application/json",
  //     "Access-Control-Allow-Credentials": true,
  //     "Ocp-Apim-Subscription-Key":
  //       process.env.REACT_APP_OCP_APIM_SUBSCRIPTION_KEY,
  //     "X-Client": "Web",
  //   });
  // },

  [TYPE.UPLOAD_AVATAR.request]: async (data) => {
    return request(`upload/api/Upload/GetToken?type=1`, "GET", data);
  },
  [TYPE.UPDATE_USER_INFO.request]: async (data) => {
    return request(`personal/UpdateUserProfile`, "PUT", data);
  },
};

export default api;
