/* eslint-disable no-unused-vars */
import Images from "constants/Images";
import React from "react";
import { CListGroup, CListGroupItem } from "@coreui/react";
import "./searchHistoryList.scss";
import { IllustrationHistorySearch } from "assets/svg";
import { useSelector } from "react-redux";

const SearchHistoryList = (props) => {
  const { searchHistoryList, onSearchHistory, clearHistorySearch } = props;

  const { userInfo } = useSelector((state) => state.root);

  const handleBtnClick = (item) => {
    onSearchHistory(item);
  };
  return (
    <div className="history-keypopular-group">
      <CListGroup className="search-list search-history-list">
        <div className="search-history-list__heading">
          <div className="search-history-list__label">Tìm kiếm gần đây</div>

          {userInfo && searchHistoryList?.length > 0 && (
            <div
              className="btn-delete-search-history"
              onClick={clearHistorySearch}
            >
              Xóa
            </div>
          )}
        </div>
        {userInfo && (
          <div style={{ paddingBottom: "100px" }}>
            {searchHistoryList?.map((item, index) => {
              return (
                <CListGroupItem
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  id={index}
                  className="search-item"
                  component="a"
                  href=""
                  onClick={() => handleBtnClick(item)}
                >
                  <div className="search-item__info">
                    <img src={Images.grayClockIcon} alt="" />
                    <span className="search-item-name">{item}</span>
                  </div>
                </CListGroupItem>
              );
            })}
          </div>
        )}

        <div className="bgr-illustration">
          <IllustrationHistorySearch />
        </div>
      </CListGroup>
    </div>
  );
};

export default React.memo(SearchHistoryList);
