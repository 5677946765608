import {isEmpty} from 'lodash';

export const listCampaignParser = listCampaign => {
  if (listCampaign?.length && !!listCampaign[0] && !isEmpty(listCampaign[0])) {
    const resultListCampaign = listCampaign.map((campaign, index) => {
      return {
        ...campaign,
        campaignCode: campaign.code,
      };
    });
    return resultListCampaign;
  }
  return [];
};

export const listCommonShortcutParser = listShortcut => {
  const listShortcutRes = listShortcut?.map(shortcut => {
    return {
      id: shortcut.id,
      iconUrl: shortcut?.data?.iconUrl?.iv,
      actionUrl: shortcut?.data?.url?.iv,
      name: shortcut?.data?.name?.vi,
      priority: shortcut?.data?.priority?.iv,
    };
  });
  return listShortcutRes || [];
};

export const listPersonalizeShortcutParser = listShortcut => {
  const listShortcutRes = listShortcut?.map(shortcut => {
    return {
      id: shortcut.id,
      iconUrl: shortcut.icon,
      actionUrl: shortcut?.actionUrl,
      name: shortcut?.name,
      priority: shortcut?.priority,
    };
  });
  return listShortcutRes || [];
};

export const lovParser = (lovData, locale) => {
  const lovRes = {
    ...lovData,
    data: {
      ...lovData.data,
      content: lovData.data.content[locale],
    },
  };
  console.log('lovReslovReslovRes', lovRes);
  return lovRes;
};
