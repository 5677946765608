import { addErrors, delErrors } from "utils/ErrorsGenerator";
import * as TYPE from "./constants";

const reducers = {
  // * GET_COUPON_INFO
  [TYPE.GET_COUPON_INFO.request]: (draft) => {
    return {
      ...draft,
      isLoading: {
        ...draft.isLoading,
        GET_COUPON_INFO: true,
      },
    };
  },
  [TYPE.GET_COUPON_INFO.success]: (draft, { payload }) => {
    return {
      ...draft,
      errors: delErrors(draft.errors, TYPE.GET_COUPON_INFO.failure),
      couponInfo: payload,
      isLoading: {
        ...draft.isLoading,
        GET_COUPON_INFO: false,
      },
    };
  },
  [TYPE.GET_COUPON_INFO.failure]: (draft, { payload }) => {
    return {
      ...draft,
      errors: addErrors(draft.errors, {
        type: TYPE.GET_COUPON_INFO.failure,
        msg: payload,
      }),
      isLoading: {
        ...draft.isLoading,
        GET_COUPON_INFO: false,
      },
    };
  },

  // * SAVE_SUGGESTED_COUPON
  [TYPE.SAVE_SUGGESTED_COUPON.request]: (draft) => {
    return {
      ...draft,
      isLoading: {
        ...draft.isLoading,
        SAVE_SUGGESTED_COUPON: true,
      },
    };
  },
  [TYPE.SAVE_SUGGESTED_COUPON.success]: (draft) => {
    return {
      ...draft,
      errors: delErrors(draft.errors, TYPE.SAVE_SUGGESTED_COUPON.failure),
      isLoading: {
        ...draft.isLoading,
        SAVE_SUGGESTED_COUPON: false,
      },
    };
  },
  [TYPE.SAVE_SUGGESTED_COUPON.failure]: (draft, { payload }) => {
    return {
      ...draft,
      errors: addErrors(draft.errors, {
        type: TYPE.SAVE_SUGGESTED_COUPON.failure,
        msg: payload,
      }),
      isLoading: {
        ...draft.isLoading,
        SAVE_SUGGESTED_COUPON: false,
      },
    };
  },
};

export default reducers;
