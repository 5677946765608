/* eslint-disable no-unused-vars */
import React from "react";
import { useHistory } from "react-router-dom";
// eslint-disable-next-line import/extensions
import arrowBack from "assets/icons/arrow-back.svg";
import TheInputSearch from "./TheInputSearch";

const TheHeaderHome = (props) => {
  return (
    <>
      <div className="header-bar-container">
        <div className="header-content">
          <button
            type="button"
            onClick={props?.goBack}
            className="button__effect flex-shrink-0 _border-none _outline-none"
            style={{ background: "transparent" }}
          >
            <img src={arrowBack} alt="arrow-back" width={8} height={15} />
          </button>
          <div className="header-search-bar">
            <TheInputSearch
              className="inputSearch"
              onSearchClicked={props._onSearchProduct}
              clearSearchtext={props._onClearSearchtext}
              listSearchHistory={props.listSearchHistory}
              searchtext={props.txtSearch}
              placeholder={props.placeholder}
              clearSearchHistory={props.clearSearchHistory}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default TheHeaderHome;
