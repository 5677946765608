import {LOGOUT_REQUEST} from '@shared/redux/account/constants';
import produce from 'immer';
import {
  MY_VIDEO_REQUEST,
  MY_VIDEO_SUCCESS,
  MY_VIDEO_FAILED,
  USER_INFO_SUCCESS,
  USER_INFO_FAILED,
} from './types';

const initialState = {
  errors: null,
  myVideo: null,
  isRefresh: false,
  userInfo: {},
};

const myVideoReducer = produce((draft = initialState, action) => {
  const {payload} = action;
  const refresh = action?.payload?.isRefresh;
  switch (action.type) {
    //My video
    case MY_VIDEO_REQUEST:
      return {
        ...draft,
        isRefresh: refresh,
      };

    case MY_VIDEO_SUCCESS:
      return {
        ...draft,
        isRefresh: false,
        errors: null,
        myVideo: payload,
      };

    case MY_VIDEO_FAILED:
      return {
        ...draft,
        isRefresh: false,
        errors: payload,
      };
    // user's info
    case USER_INFO_SUCCESS:
      return {
        ...draft,
        errors: null,
        userInfo: payload,
      };
    case USER_INFO_FAILED:
      return {
        ...draft,
        errors: payload,
      };
    case LOGOUT_REQUEST: {
      return {
        ...initialState,
      };
    }
    default:
      return draft;
  }
});

export default myVideoReducer;
