import SVG, {
  ArrowRightYellowIcon,
  CellPhoneIcon,
  ClockWithBackgroundIcon,
  CompassIcon,
  CouponSolidYellowIcon,
  LocationMarkerWithBackgroundIcon,
  RibbonIcon,
  ShareSolidYellowIcon,
  // ExclamationIcon,
  // ReceiveFoodOutlineIcon,
} from "assets/svg";
import classNames from "classnames";
import React, { useCallback, useMemo, useRef } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import Skeleton from "react-loading-skeleton";
import { useScrollHorizontallyWithButtons } from "hooks";
import { MAP_MODES } from "constants/types";
import { getOpeningTimeInfoOfToday } from "utils/getOpeningInfo";
import { getDistanceFromLatLonInKm } from "helpers/getDistanceFromLatLonInKm";
import { toVND } from "utils/currencyFormat";
import { CustomSlider, IconButton } from "..";
import "./StoreInfo.scss";

const OFFSET_TO_SCROLL_PER_CLICK = 300;

function StoreInfo(props) {
  const {
    idPivot, // id của element sẽ navigate tới khi chọn tab trên tab navigator
    isLoading,
    images = [],
    merchantImages = [],
    merchantClass,
    name,
    // avgRate,
    avgPrice,
    openingTimes,
    // deliveryOptions,
    address,
    phoneNumber,
    location,
    tags,
    // * selector
    suggestedCouponList,
    // * actions
    handleShareStore,
    handleSaveCoupon,
    handleOpenPopupSuggestedCouponList,
    handleGotoDetailCoupon,
  } = props;

  const history = useHistory();
  const tagsContainerRef = useRef();
  const {
    arrowButtonsState,
    scrollHorizontal,
    handleTagsContainerScroll,
    BTN_STATE,
  } = useScrollHorizontallyWithButtons({
    containerRef: tagsContainerRef,
    items: tags,
  });

  const currentOpeningData = useMemo(
    () => getOpeningTimeInfoOfToday(openingTimes),
    [openingTimes]
  );

  const distance = useMemo(() => {
    const chosenLocationAtHomePage = JSON.parse(
      localStorage.getItem("myLocation")
    );

    if (
      chosenLocationAtHomePage?.lat &&
      chosenLocationAtHomePage?.long &&
      location?.latitude &&
      location?.longitude
    ) {
      return Number(
        getDistanceFromLatLonInKm(
          chosenLocationAtHomePage.lat,
          chosenLocationAtHomePage.long,
          location.latitude,
          location.longitude
        )
      ).toFixed(1);
    }

    return null;
  }, [location?.latitude, location?.longitude]);

  // * Open direction mode
  const handleOpenDirectionMode = useCallback(() => {
    let stateToSend = {
      mode: MAP_MODES.DIRECTION,
      storeLocation: { lat: location?.latitude, lng: location?.longitude },
      storeAddress: address,
    };
    const pinnedLocationAtHomePage = JSON.parse(
      localStorage.getItem("myLocation")
    );

    if (pinnedLocationAtHomePage?.lat && pinnedLocationAtHomePage?.long) {
      stateToSend = {
        ...stateToSend,
        customDeviceLocation: {
          lat: pinnedLocationAtHomePage?.lat,
          lng: pinnedLocationAtHomePage?.long,
        },
      };
    }

    history.push("/mapdirection", stateToSend);
  }, [location?.latitude, location?.longitude, address]);

  // *** RENDER FUNCTIONS
  const renderSlider = useCallback(() => {
    const items = [];

    if (Array.isArray(images) && images.length > 0) {
      items.push(...images);
    }

    if (Array.isArray(merchantImages) && merchantImages.length > 0) {
      items.push(...merchantImages);
    }

    return (
      <CustomSlider
        items={items}
        sliderClassName="store-info__slider" // for override .carousel-dots
      />
    );
  }, [
    // isLoading,
    images,
    merchantImages,
  ]);

  const renderCouponSection = useCallback(() => {
    if (!suggestedCouponList || !suggestedCouponList?.length > 0) {
      return <></>;
    }

    const firstCoupon = suggestedCouponList[0];

    return (
      <div className="store-info-card__row pl-16px ml-n-12px suggested-coupons__container">
        <div className="store-info-card__row__left-side">
          <i>
            <CouponSolidYellowIcon />
          </i>
        </div>

        <div
          className="store-info-card__row__content suggested-coupon__info"
          onClick={handleGotoDetailCoupon({
            idCoupon: firstCoupon?.couponId,
            transactionCode: firstCoupon?.transactionCode,
          })}
        >
          {firstCoupon && (
            <div className="text-on-1-line">{firstCoupon.name}</div>
          )}

          {firstCoupon.endDateTime && (
            <span className="coupon-date">
              Hết hạn:{" "}
              <span>
                {moment
                  .utc(firstCoupon.endDateTime)
                  .local()
                  .format("DD/MM/YYYY")}
              </span>
            </span>
          )}
        </div>

        {suggestedCouponList.length === 1 ? (
          <button
            type="button"
            className="store-info-card__row__right-side button__effect button-get-coupon"
            disabled={suggestedCouponList?.[0]?.isAllocated}
            onClick={handleSaveCoupon(suggestedCouponList?.[0]?.code)}
          >
            Lấy mã
          </button>
        ) : (
          <button
            type="button"
            className="store-info-card__row__right-side button-see-more"
            onClick={handleOpenPopupSuggestedCouponList}
          >
            Xem thêm
            <i>
              <ArrowRightYellowIcon />
            </i>
          </button>
        )}
      </div>
    );
  }, [suggestedCouponList]);

  const renderSkeleton = () => {
    return (
      <div className="store__head__container">
        <div className="store-banner">
          <Skeleton width="100%" height="100vw" style={{ maxHeight: 500 }} />
        </div>

        <div className="store-info-card">
          <div className="store-info-card__content-wrapper">
            <div className="store-info-card__top--over-top">
              <Skeleton width="16ch" height={30} />
            </div>

            <div className="store-info-card__body">
              <div className="name">
                <Skeleton width="75%" height={32} />
              </div>

              <div>
                <Skeleton width="12ch" height={18} />
              </div>

              <div>
                <Skeleton width="40%" height={24} />
              </div>

              <div style={{ marginTop: 12 }}>
                <Skeleton width="100%" height={46} />
              </div>

              <div style={{ marginTop: 12 }}>
                <Skeleton width="100%" height={32} />
              </div>
            </div>
          </div>

          <div className="store-info-card__tags hide-x-scrollbar">
            <Skeleton width="15ch" height="32px" />
            <Skeleton width="10ch" height="32px" />
            <Skeleton width="17ch" height="32px" />
          </div>
        </div>
      </div>
    );
  };

  if (isLoading) {
    return renderSkeleton();
  }

  return (
    <div className="store__head__container">
      <div
        id="store-detail-info-slider" // for control opacity when scroll page (handle in /storedetail)
        className="store-banner"
      >
        {renderSlider()}
      </div>

      <div id={idPivot} className="store-info-card">
        <div className="store-info-card__content-wrapper">
          {!!merchantClass && (
            <div className="store-info-card__top--over-top">
              <div className="merchant-class">
                <i>
                  <RibbonIcon />
                </i>
                <span>{merchantClass}</span>
              </div>

              {/* // ! tạm ẩn */}
              {/* <IconButton className="button-info">
                <ExclamationIcon />
              </IconButton> */}
            </div>
          )}

          <div className="store-info-card__body">
            <div className="name-button__wrapper">
              <div className="name">{name}</div>
              <IconButton className="button-share" onClick={handleShareStore}>
                <ShareSolidYellowIcon />
              </IconButton>
            </div>

            {/* {avgRate && (
                  <IconList
                    selectedNum={Number(avgRate)}
                    containerClassName="rating-star__container"
                  />
                )} */}

            {!!avgPrice && (
              <div className="avg-price">
                <span>Trung bình: </span>
                <span className="avg-price__price">
                  {toVND(avgPrice)}/người
                </span>
              </div>
            )}

            {!!currentOpeningData && (
              <div className="store-info-card__row opening-times__container">
                <div className="store-info-card__row__left-side">
                  <i>
                    <ClockWithBackgroundIcon />
                  </i>
                </div>

                <span className="store-info-card__row__content opening-times__time">
                  <span
                    className={classNames(
                      "opening-status",
                      currentOpeningData.status === 1
                        ? "opening-status--opening"
                        : "opening-status--closed"
                    )}
                  >
                    {`Đang ${
                      currentOpeningData.status === 1 ? "mở" : "đóng"
                    } cửa`}
                  </span>

                  {currentOpeningData?.status === 1 &&
                    currentOpeningData?.currentOpeningTimeRange && (
                      <>
                        <div className="vertical-separator" />
                        <span className="current-opening-time-range">
                          {currentOpeningData.currentOpeningTimeRange}
                        </span>
                      </>
                    )}

                  {currentOpeningData?.status !== 1 &&
                    currentOpeningData?.comebackTime && (
                      <>
                        <div className="vertical-separator" />
                        <span>
                          {"Quay lại vào "}
                          <span className="comeback-time">
                            {currentOpeningData.comebackTime}
                          </span>
                        </span>
                      </>
                    )}
                </span>
              </div>
            )}

            {!!address && (
              <div className="store-info-card__row address">
                <div className="store-info-card__row__left-side">
                  <i>
                    <LocationMarkerWithBackgroundIcon />
                  </i>
                </div>

                <div className="store-info-card__row__content">
                  <div className="only-2-lines">{address}</div>

                  {distance !== null && (
                    <span className="distance">{`(${Number(
                      distance
                    ).toLocaleString("vi")} km)`}</span>
                  )}
                </div>

                <div className="store-info-card__row__right-side address__buttons">
                  <IconButton
                    onClick={handleOpenDirectionMode}
                    disabled={
                      !location?.latitude || !location?.longitude || !address
                    }
                  >
                    <CompassIcon />
                  </IconButton>

                  {!!phoneNumber && (
                    <a href={`tel:${phoneNumber}`}>
                      <IconButton>
                        <CellPhoneIcon />
                      </IconButton>
                    </a>
                  )}
                </div>
              </div>
            )}

            {/* coupon */}
            {renderCouponSection()}

            {/* //! tạm thời ẩn, dùng trong tương lai */}
            {/* <div className="divider-line" /> */}

            {/* Làm món ngay sau 30p */}
            {/* <div className="store-info-card__row pl-16px ml-n-12px ">
                  <div className="store-info-card__row__left-side">
                    <i>
                      <ReceiveFoodOutlineIcon />
                    </i>
                  </div>

                  <span className="store-info-card__row__content msg-text">Làm món ngay sau 30 phút</span>

                  <button type="button" className="store-info-card__row__right-side button-see-more">
                    Xem thêm
                    <i>
                      <ArrowRightYellowIcon />
                    </i>
                  </button>
                </div> */}
          </div>
        </div>

        {/* tags */}

        {tags?.length > 0 && (
          <div className="store-info-card__conatiner-scroll">
            {[BTN_STATE.SHOW_BUTTON_PREV, BTN_STATE.SHOW_BOTH_BUTTONS].includes(
              arrowButtonsState
            ) && (
              <img
                id="example"
                src={SVG.arrowPrev}
                alt="arrow prev"
                className="btn-prev-scroll"
                onClick={() => scrollHorizontal(-OFFSET_TO_SCROLL_PER_CLICK)}
              />
            )}
            <ul
              id="test"
              className="store-info-card__tags hide-x-scrollbar"
              ref={tagsContainerRef}
              onScroll={handleTagsContainerScroll}
            >
              {tags.map((tag, ind) => (
                <li className="tag only-2-lines" key={`${ind + 1}`}>
                  {tag}
                </li>
              ))}
            </ul>
            {[BTN_STATE.SHOW_BUTTON_NEXT, BTN_STATE.SHOW_BOTH_BUTTONS].includes(
              arrowButtonsState
            ) && (
              <img
                src={SVG.arrowNext}
                alt="arrow next"
                className="btn-next-scroll"
                onClick={() => scrollHorizontal(+OFFSET_TO_SCROLL_PER_CLICK)}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default StoreInfo;
