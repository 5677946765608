import { END_POINTS } from "@shared/constants/apiUrl";
import { APIUtils } from "utils";
import { store } from "@shared/redux/configureStore";
import moment from "moment";

export function verifyLinkBusinessApi(payload) {
  return APIUtils.post(END_POINTS.VERIFY_LINK_BUSINESS, {
    body: payload,
  });
}

export function getUserBusinessApi() {
  return APIUtils.get(END_POINTS.GET_USER_BUSINESS);
}

export async function getListStoreUBApi({
  skipCount,
  filterStore,
  userId,
  StoreName,
}) {
  let url =
    END_POINTS.GET_LIST_STORE_UB +
    "?Skip=" +
    skipCount +
    "&Take=10&Latitude=" +
    filterStore.selectedLocation.latitude +
    "&Longitude=" +
    filterStore.selectedLocation.longitude;

  if (!!filterStore.citySelected) {
    url += "&CityId=" + filterStore.citySelected.id;
  }

  if (!!filterStore.listDistrictSelected) {
    filterStore.listDistrictSelected.forEach((element) => {
      if (element.isSelected && element.id !== 0) {
        url += "&DistrictIds=" + element.id;
      }
    });
  }

  if (!!filterStore.listStoreTypeSelected) {
    filterStore.listStoreTypeSelected.forEach((element) => {
      if (element.isSelected) {
        url += "&StoreTypeIds=" + element.id;
      }
    });
  }

  if (!!filterStore.priceSelected) {
    url += "&MinAveragePrice=" + filterStore.priceSelected.min;
    if (!!filterStore.priceSelected.max) {
      url += "&MaxAveragePrice=" + filterStore.priceSelected.max;
    }
  }

  if (StoreName) {
    url += "&StoreName=" + StoreName;
  }
  // if (
  //   JSON.stringify(name) !== '{}' &&
  //   name !== null &&
  //   typeof name !== 'undefined'
  // ) {
  //   url = url + '&name=' + name;
  // }
  return APIUtils.get(url, {
    headers: {
      "X-BusinessId": userId || "",
    },
  });
}

export async function checkAccountBusinessApi({ email, userId }) {
  let url = END_POINTS.CHECK_ACCOUNT_BUSINESS + "?account=" + email;
  return APIUtils.get(url, {
    headers: {
      "X-BusinessId": userId || "",
    },
  });
}

export async function getTransactionDetailApi({ transactionId, userId }) {
  let url = END_POINTS.GET_TRANSACTION_DETAIL + transactionId;
  return APIUtils.get(url, {
    headers: {
      "X-BusinessId": userId || "",
    },
  });
}

export async function getAllTransactionHistoryUBApi({
  skipCount,
  dataFilter,
  userId,
  type,
}) {
  let url =
    END_POINTS.GET_TRANSACTION_TRANSACTION_UB +
    "?Skip=" +
    skipCount +
    "&Take=10&Genre=" +
    type;

  if (
    JSON.stringify(dataFilter) !== "{}" &&
    dataFilter !== null &&
    typeof dataFilter !== "undefined"
  ) {
    url =
      url + "&FromDate=" + dataFilter.fromDate + "&ToDate=" + dataFilter.toDate;
  }

  return APIUtils.get(url, {
    headers: {
      "X-BusinessId": userId || "",
    },
  });
}

export async function getDetailVerifyApi(payload) {
  console.log("getDetailVerifyApi", payload);
  let url =
    END_POINTS.GET_DETAIL_VERIFY +
    "?confirmationCode=" +
    payload.ConfirmationCode +
    "&organizationId=" +
    payload.OrganizationId;

  return APIUtils.get(url);
}

export async function getListStoreTypeApi({ userId }) {
  let url = END_POINTS.GET_LIST_STORE_TYPE;
  return APIUtils.get(url, {
    headers: {
      "X-BusinessId": userId || "",
    },
  });
}

export async function getListCityApi({ userId }) {
  let url = END_POINTS.GET_LIST_CITY;
  return APIUtils.get(url, {
    headers: {
      "X-BusinessId": userId || "",
    },
  });
}

export async function getListDistrictApi({ cityId, userId }) {
  let url = END_POINTS.GET_LIST_DISTRICT + "?cityId=" + cityId;
  return APIUtils.get(url, {
    headers: {
      "X-BusinessId": userId || "",
    },
  });
}
export async function storeDetailApi(payload) {
  console.log("storeDetailApi", payload);
  let url = END_POINTS.UTOP_BUSINESS_STORE_DETAIL + payload.storeId;
  return APIUtils.get(url, {
    headers: {
      "X-BusinessId": payload.userId || "",
    },
  });
}
export async function storeImageslApi(payload) {
  console.log("storeDetailApi", payload);
  let url = END_POINTS.UTOP_BUSINESS_STORE_DETAIL + payload.storeId + "/Images";
  return APIUtils.get(url, {
    headers: {
      "X-BusinessId": payload.userId || "",
    },
  });
}

export async function createReservationApi({ requestData, userId }) {
  moment;
  let dateSelected =
    requestData?.infoBooking?.daySelected +
    "T" +
    requestData?.infoBooking?.timeSelected;
  dateSelected = moment(dateSelected).utc().format("YYYY-MM-DDTHH:mm");
  let body = {
    storeId: requestData?.storeDetail?.id,
    attenderCount: parseInt(requestData?.infoBooking?.numberOfGuest),
    date: dateSelected,
    description: requestData?.infoBooking?.note,
  };
  return APIUtils.post(END_POINTS.CREATE_RESERVATION, {
    body: body,
    headers: {
      "X-BusinessId": userId || "",
    },
  });
}
