import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import moment from "moment";
import { store } from "@shared/redux/configureStore";
import apiConfig from "configs/apiConfig";
import { preprocessingPathName } from "./common";

class InsightTracking {
  appInsights = null;

  constructor() {
    const reactPlugin = new ReactPlugin();
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: apiConfig.instrumentationKey,
        extensions: [reactPlugin],
      },
    });
    this.appInsights.loadAppInsights();
  }

  logEvent = async (params) => {
    const { action, payload = {} } = params || {};
    const { userId } = store.getState()?.root?.userInfo || {};
    const { pathname, state } = store.getState()?.router?.location || "";
    const { prevPathname = "" } = state || {};
    const pinnedLocation = JSON.parse(localStorage.getItem("myLocation"));

    const data = { ...payload };
    if (userId) {
      data.memberCode = userId || "";
      data.ScreenId = preprocessingPathName(pathname) || "";
      data.PreScreenId = preprocessingPathName(prevPathname) || "";
      data.Region = pinnedLocation?.address || "";
      data.Source = "Web";
      data.Timestamp = moment.utc().format("YYYY-MM-DD HH:mm:ss");
      try {
        if (userId) {
          this.appInsights.setAuthenticatedUserContext(userId);
        } else {
          this.appInsights.clearAuthenticatedUserContext();
        }

        this.appInsights.trackEvent({
          name: action,
          properties: { ...data },
        });
      } catch (error) {
        console.log("error track insight >>>>>>>", error);
      }
    }
  };
}

const insightTracking = new InsightTracking();

export default insightTracking;
