/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import "./customCarousel.scss";
import Slider from "infinite-react-carousel";
import SVG from "assets/svg";
import IMAGES from "assets/img";
import Images from "constants/Images";

const CustomSlider = (props) => {
  const {
    images = [],
    merchantImages = [],
    imageStyles,
    imgClassName = "",
    saleTagUrl,
  } = props;
  const [errorImage, setErrorImage] = useState(false);

  const validImages = React.useMemo(() => {
    const validImagesOfStore = images?.filter((image) => image?.ImageUrl);
    const validImagesOfMerchant = merchantImages?.filter(
      (image) => image?.ImageUrl
    );

    return [...validImagesOfStore, ...validImagesOfMerchant]?.slice(0, 10);
  }, [images, merchantImages]);

  const settings = React.useMemo(
    () => ({
      autoplay: !!validImages?.length,
      dots: !!validImages?.length,
      wheel: true,
      // adaptiveHeight: true,
      // centerMode: true,
      // centerPadding: 10,
      // duration: 100,
      // virtualList: true, // uncomment will show a gap between two image
      // shift: 5,

      arrows: false, // !

      prevArrow: (
        <div>
          <img
            src={SVG.ArrowLeft}
            alt="arrow right"
            width={7.91}
            height={14.97}
          />
        </div>
      ),
      nextArrow: (
        <div>
          <img
            src={SVG.ArrowRight}
            alt="arrow right"
            width={7.91}
            height={14.97}
          />
        </div>
      ),
    }),
    [validImages?.length]
  );

  const checkErrorImage = (e) => {
    setErrorImage(e);
  };

  return (
    <>
      <div className="custom-carousel__container">
        {validImages?.length > 0 ? (
          <Slider {...settings}>
            {validImages.map((image) => (
              <div key={image?.ImageUrl} className="text-center">
                {errorImage === image?.ImageUrl ? (
                  <img
                    src={IMAGES.FoodPlaceholderX3}
                    style={{ maxWidth: "300px", borderRadius: "8px" }}
                    alt=""
                    key="BannerStore"
                  />
                ) : (
                  <img
                    src={image?.ImageUrl}
                    style={{ maxWidth: "300px", borderRadius: "8px" }}
                    onError={() => checkErrorImage(image.ImageUrl)}
                  />
                )}
                {!!saleTagUrl && (
                  <img src={saleTagUrl} className="icon-saleTagUrl" />
                )}
              </div>
            ))}
          </Slider>
        ) : (
          <div className="text-center">
            <img
              src={IMAGES.FoodPlaceholderX3}
              alt=""
              key="BannerStore"
              style={{ maxWidth: "300px", borderRadius: "8px" }}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default React.memo(CustomSlider);
