import React, { useState, useEffect } from "react";
import moment from "moment";
import "moment/locale/vi";
import SVG, { IconVerifyUser, IconReply } from "assets/svg";
import IMAGES from "assets/img";
import { IconList } from "views/common/component";
import { COMMENT_TYPES_OF_RATING_FEATURE } from "constants/types";
import "./Post.scss";
import classNames from "classnames";
import { Markup } from "interweave";

const Post = (props) => {
  const {
    rating,
    handleUnlikeCmt,
    handleLikeCmt,
    handleLikeSubCmt,
    handleUnLikeSubCmt,
    handleOpenUserLikedList,
    handleClickToRepliesButton = () => {},
    idElement,
  } = props;
  const [replyRating, setReplyRating] = useState([]);
  const [repliesToShow, setRepliesToShow] = useState([]);
  const [nextComment, setNextComment] = useState(2);
  // set up moment follow by location at VN
  moment.locale("vi");
  // convert to rule date time
  const handleChangeShowDateTime = (date) => {
    const time = moment(Date.now()).diff(date, "days");
    // < 6 days: hours
    if (time < 6) {
      return moment(date).fromNow();
    }

    // > 365 days: dd/mm/yyyy
    return moment(date).format("DD/MM/YYYY");
  };
  // handle sort ratingReplies by like > date
  const handleSortRatingReplies = (arr, createdAtOrderBy = 1) => {
    const sorted = [...arr].sort(
      (firstChild, secondChild) =>
        createdAtOrderBy *
        (new Date(firstChild.createdAt) - new Date(secondChild.createdAt))
    );

    return sorted;
  };
  const handleToggleLikeReply = (isLike, replyId, postId) => () => {
    if (isLike) {
      handleUnLikeSubCmt(replyId, postId)();
    } else {
      handleLikeSubCmt(replyId, postId)();
    }
  };

  useEffect(() => {
    if (rating?.ratingReplies) {
      const sortedReplyRating = handleSortRatingReplies(rating?.ratingReplies);
      setReplyRating(sortedReplyRating);
    }
  }, [rating?.ratingReplies]);

  useEffect(() => {
    setRepliesToShow(replyRating.slice(replyRating.length - nextComment));
  }, [replyRating, nextComment]);

  return (
    <div
      className="rating-content__comment-sub-cmt"
      key={rating.id}
      id={idElement}
      style={{ paddingLeft: "10px", paddingRight: "10px" }}
    >
      <div className="rating-content__comment-sub-cmt__header">
        <div className="rating-content__comment-sub-cmt__header--left">
          <div className="rating-content__comment-sub-cmt__header--left__avatar">
            <object
              className="avatar-img"
              data={rating.creatorAvatar || IMAGES.AvatarPlaceholder}
              type="image/png"
            >
              <img
                className="avatar-img"
                src={IMAGES.AvatarPlaceholder}
                alt="ava"
              />
            </object>
          </div>
          <div className="rating-content__comment-sub-cmt__header--left__information">
            <h2>{rating.creatorName}</h2>
            <div className="rating-content__comment-sub-cmt__header--left__information--datetime">
              <p>
                {moment(rating.createdAt).format("DD/MM/YYYY")} |
                <span>
                  <IconVerifyUser style={{ marginBottom: "2px" }} /> Người mua
                  hàng
                </span>
              </p>
            </div>
            <div className="rating-content__comment-sub-cmt__header--left__information--star">
              <IconList
                selectedNum={Number(rating.rate)}
                customId={rating.id}
                containerClassName="star-list"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="rating-content__comment-sub-cmt__body">
        <div className="rating-content__comment-sub-cmt__body__main-text">
          <p>
            <Markup content={rating.comment} />
          </p>
        </div>
        <div className="rating-content__comment-sub-cmt__body__heart-reply">
          {rating?.like > 0 ? (
            <div
              className="rating-content__comment-sub-cmt__body__heart-reply--heart-side"
              onClick={() => {
                handleOpenUserLikedList({
                  id: rating.id,
                  type: COMMENT_TYPES_OF_RATING_FEATURE.RATING_POST,
                })();
              }}
            >
              <img src={SVG.HeartFull} alt="heart" className="active" />
              <p>
                <span>{rating.like}</span> lượt thích
              </p>
            </div>
          ) : null}
          {rating?.ratingReplies.length > 0 ? (
            <div className="rating-content__comment-sub-cmt__body__heart-reply--reply-side">
              <p>{rating.ratingReplies.length} phản hồi</p>
            </div>
          ) : null}
        </div>

        <div className="rating-content__comment-sub-cmt__body__heart-reply-user">
          {rating.isLike ? (
            <div
              className="rating-content__comment-sub-cmt__body__heart-reply-user--like-side-user"
              onClick={handleUnlikeCmt(rating.id)}
            >
              <img src={SVG.HeartFull} alt="heart" className="active" />
              <span style={{ color: "#ffb600" }}>Thích</span>
            </div>
          ) : (
            <div
              className="rating-content__comment-sub-cmt__body__heart-reply-user--like-side-user"
              onClick={handleLikeCmt(rating.id)}
            >
              <img src={SVG.HeartEmty} alt="heart" />
              <span>Thích</span>
            </div>
          )}
          <div
            className="rating-content__comment-sub-cmt__body__heart-reply-user--reply-side-user"
            onClick={handleClickToRepliesButton}
          >
            <IconReply />
            <span>Phản hồi</span>
          </div>
        </div>
        <div className="rating-content__comment-sub-cmt__body__user-reply-text">
          {replyRating.length - nextComment > 0 ? (
            <div
              className="rating-content__comment-sub-cmt__body__user-reply-text--prev-text"
              onClick={() =>
                setNextComment((prevValue) =>
                  replyRating.length > prevValue + 10
                    ? prevValue + 10
                    : replyRating.length
                )
              }
            >
              <h5>Xem phản hồi trước...</h5>
            </div>
          ) : (
            <div
              className="rating-content__comment-sub-cmt__body__user-reply-text--prev-text"
              onClick={() => setNextComment(2)}
            >
              {replyRating.length > 2 ? <h5>Ẩn phản hồi...</h5> : null}
            </div>
          )}

          {repliesToShow &&
            repliesToShow?.map((reply) => (
              <div
                className="rating-content__comment-sub-cmt__body__user-reply-text__user-cmt"
                key={reply.id}
              >
                <div className="rating-content__comment-sub-cmt__body__user-reply-text__user-cmt__avatar">
                  <object
                    className="avatar-img"
                    data={reply.creatorAvatarUrl || IMAGES.AvatarPlaceholder}
                    type="image/png"
                  >
                    <img
                      className="avatar-img"
                      src={IMAGES.AvatarPlaceholder}
                      alt="ava"
                    />
                  </object>
                </div>
                <div className="rating-content__comment-sub-cmt__body__user-reply-text__user-cmt__comment">
                  <div className="rating-content__comment-sub-cmt__body__user-reply-text__user-cmt__comment--user">
                    <h5>{reply.creatorName}</h5>
                    <p>
                      <Markup content={reply.content} />
                    </p>

                    {reply.like > 0 ? (
                      <div
                        className="rating-content__comment-sub-cmt__body__user-reply-text__user-cmt__comment--user__popup-heart"
                        onClick={handleOpenUserLikedList({
                          id: reply.id,
                          type: COMMENT_TYPES_OF_RATING_FEATURE.COMMENT,
                        })}
                      >
                        <img src={SVG.HeartFullSm} alt="heart" />
                        <p>{reply.like > 99 ? `+99` : reply.like}</p>
                      </div>
                    ) : null}
                  </div>
                  <div className="rating-content__comment-sub-cmt__body__user-reply-text__user-cmt__comment__button">
                    <p className="--time">
                      {handleChangeShowDateTime(reply.createdAt)}
                    </p>
                    <span className="--bullet">&bull;</span>
                    <p
                      className={classNames({
                        "--like-active": reply.isLike,
                        "--like-btn": !reply.isLike,
                      })}
                      onClick={handleToggleLikeReply(
                        reply.isLike,
                        reply.id,
                        reply.sourceId
                      )}
                    >
                      Thích
                    </p>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default React.memo(Post);
