/* eslint-disable prefer-const */
/* eslint-disable no-param-reassign */
/* eslint-disable array-callback-return */
/* eslint-disable object-shorthand */
import React, { useRef, useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useLocation } from "react-router";
import {
  getListProductByCategoryRequest,
  getListCategoryRequest,
} from "@shared/redux/market/marketProduct/actions";
import {
  getDataStore,
  getListProduct,
} from "@shared/redux/smartMenu/selectors";
import {
  addProductCart,
  setDataStore,
  resetProductCart,
  updateListProductCart,
  updateProductCart,
  deleteProductCart,
  setIndexProductCart,
} from "@shared/redux/smartMenu/actions";
import { NavigationServices, LocalStorage } from "utils";
import {
  checkGuest,
  checkGuestPopup,
  updateProductCart as updateProductsCart,
} from "@shared/utils";
import { listCategoryParser } from "@shared/redux/market/marketProduct/parser";
import { getListFnbTypeRequest } from "@shared/redux/market/marketHome/actions";
import MarketProductView from "./MarketProduct.view";

const MarketProduct = (props) => {
  const location = useLocation();
  const { merchantId, storeId, storeInfo, type } = location.state;
  const [data, setData] = useState({});
  const [categories, setCategories] = useState([]);
  const [loadingPager, setLoadingPager] = useState();
  const [isEndListAll, setEndListAll] = useState(false);
  const [alertAction, setAlertAction] = useState({});
  const loadingPagerRef = useRef();
  const categoriesRef = useRef();
  const currentSectionRef = useRef(0);
  const listEndCategoryRef = useRef({});
  const fetchCountItem = 20;
  const isEndListAllRef = useRef(false);
  const dataRef = useRef({});
  const refTabView = useRef(null);

  const params = NavigationServices.getParams(props);

  useEffect(() => {
    isEndListAllRef.current = isEndListAll;
    if (isEndListAll) {
      dataRef.current = {};
    } else {
      dataRef.current = data;
    }
  }, [isEndListAll, data]);

  useEffect(() => {
    const getData =
      type === "all"
        ? props.getListFnbTypeRequest
        : props.getListCategoryRequest;
    getData(
      type === "all" ? params?.merchantId : { ...params },
      (resCategory) => {
        if (
          type === "all"
            ? resCategory?.data?.length > 0
            : resCategory?.length > 0
        ) {
          resCategory =
            type === "all"
              ? listCategoryParser(resCategory?.data)
              : resCategory;

          let tmp = {};
          const dataCategory = [
            {
              categoryId: "all",
              categoryName: "Tất cả",
            },
          ].concat(resCategory);

          dataCategory.map((elm, index) => {
            tmp[elm.categoryId] = {
              isLoading: false,
              name: elm.categoryName,
              index: index,
            };
          });
          setLoadingPager(tmp);
          categoriesRef.current = dataCategory;
          loadingPagerRef.current = tmp;
          setCategories(dataCategory);
          if (!dataCategory?.[1]?.categoryId) {
            setAlertAction({
              isShow: true,
              title: "Utop",
              content: "Cửa hàng đang cập nhật sản phẩm.",
              titleAccept: "OK",
              dataItem: { key: "back" },
            });
            return;
          }
          fetchDataAll(dataCategory?.[1]?.categoryId, 0, {}, false);
        } else {
          setAlertAction({
            isShow: true,
            title: "Utop",
            content: "Cửa hàng đang cập nhật sản phẩm.",
            titleAccept: "OK",
            dataItem: { key: "back" },
          });
        }
      },
      (error) => {
        if (error?.data?.code === "NETWORK_REQUEST_FAILED") return;
        setAlertAction({
          isShow: true,
          title: "Utop",
          content: "Cửa hàng đang cập nhật sản phẩm.",
          titleAccept: "OK",
          dataItem: { key: "back" },
        });
      }
    );
  }, [params.merchantId]);

  const checkEndListAll = () => {
    const obj = Object.keys(loadingPagerRef.current);
    let tmp = 0;
    obj.forEach((elm) => {
      if (loadingPagerRef.current[elm]?.endList === true) {
        tmp++;
      }
    });
    return tmp === obj?.length - 1;
  };

  const mergeDataTabChild = (dataTemp, result, id) => {
    if (!dataTemp[id]) {
      // eslint-disable-next-line no-param-reassign
      dataTemp[id] = { data: [] };
    }
    // eslint-disable-next-line no-param-reassign
    dataTemp[id] = {
      data: dataTemp[id].data.concat(result),
    };
    if (
      listEndCategoryRef.current?.[id] &&
      listEndCategoryRef.current?.[id]?.length > 0 &&
      loadingPagerRef.current[id]?.endList
    ) {
      // eslint-disable-next-line no-param-reassign
      dataTemp[id].data = dataTemp?.[id]?.data.concat(
        listEndCategoryRef.current?.[id]
      );
      listEndCategoryRef.current[id] = [];
    }
    loadingPagerRef.current[id].isLoading = false;
    setLoadingPager({ ...loadingPagerRef.current });
    setData({ ...dataTemp });
  };

  const onPageSelected = (id, masterDataTemp, result, isLoadMore) => {
    if (!isEndListAllRef.current) {
      if (checkEndListAll()) {
        setEndListAll(true);
        currentSectionRef.current =
          categoriesRef.current[categoriesRef.current?.length - 1]?.categoryId;
      }
    } else {
      return;
    }
    const dataTemp =
      masterDataTemp || (isEndListAllRef.current ? data : dataRef.current);
    try {
      if (!isLoadMore && (id === "all" || dataTemp?.[id]?.data?.length > 0)) {
        return;
      }
      getListProductByCategory(
        {
          id,
          refresh: false,
          extraSkip: result?.length,
        },
        (res) => {
          if (res?.endList) {
            loadingPagerRef.current[id].endList = true;
            setLoadingPager({ ...loadingPagerRef.current });
          }
          if (res?.data) {
            // eslint-disable-next-line no-param-reassign
            result = result.concat(res?.data);
            if (result?.length >= fetchCountItem) {
              mergeDataTabChild(dataTemp, result, id);
            } else if (loadingPagerRef.current[id]?.endList) {
              mergeDataTabChild(dataTemp, result, id);
            } else {
              onPageSelected(id, dataTemp, result, isLoadMore);
            }
          } else {
            if (
              (!listEndCategoryRef.current?.[id] ||
                listEndCategoryRef.current?.[id]?.length === 0) &&
              (!dataTemp[id]?.data || dataTemp[id]?.data?.length === 0)
            ) {
              loadingPagerRef.current[id].emptyList = true;
              setLoadingPager({ ...loadingPagerRef.current });
            }
            if (res?.endList) {
              mergeDataTabChild(dataTemp, result, id);
            } else if (res?.next) {
              mergeDataTabChild(dataTemp, result, id);
            } else {
              onPageSelected(id, dataTemp, result, isLoadMore);
            }
          }
        },
        (error) => {
          loadingPagerRef.current[id].isLoading = false;
          setLoadingPager({ ...loadingPagerRef.current });
          // eslint-disable-next-line no-useless-return
          if (error?.data?.code === "NETWORK_REQUEST_FAILED") return;
        }
      );
      // eslint-disable-next-line no-empty
    } catch (error) {}
  };
  const getListProductByCategory = (
    { id, refresh, extraSkip },
    onSuccess,
    onError
  ) => {
    const skipTemp = refresh
      ? 0
      : (dataRef.current?.[id]?.data?.length || 0) +
        (listEndCategoryRef.current?.[id]?.length || 0);
    props.getListProductByCategoryRequest(
      type === "all"
        ? {
            skip: skipTemp + extraSkip,
            top: fetchCountItem,
            merchantId,
            storeId,
            fnbType: id,
          }
        : {
            skip: skipTemp + extraSkip,
            top: fetchCountItem,
            categoryId: id,
            merchantId,
            storeId,
            fnbType: params?.id,
          },
      (res) => {
        const maxCount = res?.data?.length;
        if (maxCount < fetchCountItem) {
          if (
            !categoriesRef.current?.[
              loadingPagerRef.current?.[currentSectionRef.current]?.index + 1
            ]?.categoryId
          ) {
            setEndListAll(true);
          }
        }
        if (maxCount === 0) {
          setEndListAll(false);
          return onSuccess({
            endList: true,
            next: true,
            nextId:
              categoriesRef.current?.[
                loadingPagerRef.current[currentSectionRef.current]?.index + 1
              ]?.categoryId,
          });
        }
        if (maxCount > 0) {
          const resTemp = [];
          res?.data?.forEach((element) => {
            if (element?.isOutOfStock && element?.isSelling === 1) {
              if (!listEndCategoryRef.current?.[id]) {
                listEndCategoryRef.current[id] = [];
              }
              listEndCategoryRef.current[id].push(element);
            } else {
              resTemp.push(element);
            }
          });
          setEndListAll(false);
          onSuccess(
            resTemp?.length > 0
              ? {
                  data: resTemp,
                  endList: maxCount < fetchCountItem,
                }
              : {
                  endList: maxCount < fetchCountItem,
                  next: maxCount < fetchCountItem,
                  nextId:
                    maxCount < fetchCountItem
                      ? categoriesRef.current?.[
                          loadingPagerRef.current[currentSectionRef.current]
                            ?.index + 1
                        ]?.categoryId
                      : "",
                }
          );
        }
      },
      (error) => {
        onError(error);
      }
    );
  };

  const fetchDataAll = (id, totalCount, result, stop, jumpIndex, refresh) => {
    currentSectionRef.current = id;
    if (stop) {
      // handle data;
      let masterData;
      if (Object.keys(result)?.length === 0) {
        masterData = mergeMasterDataEndList(refresh);
        if (Object.keys(masterData)?.length === 0) {
          //   Alert.alert(
          //     strings("common.alertTitle"),
          //     strings("SmartOrderMenu.labelTheStoreIsUpdating"),
          //     [
          //       {
          //         text: strings("SmartOrderMenu.labelYes"),
          //         onPress: () => {
          //           NavigationServices.goBack();
          //         },
          //       },
          //     ],
          //     { cancelable: false }
          //   );
        }
      } else {
        masterData = mergeMasterData(result, refresh);
      }

      setData({ ...masterData });
      loadingPagerRef.current["all"].isLoading = false;
      setLoadingPager({ ...loadingPagerRef.current });
      if (
        categoriesRef.current?.length > 2 &&
        jumpIndex &&
        categoriesRef.current[jumpIndex]?.categoryId
      ) {
        const interval = setInterval(() => {
          if (Object.keys(dataRef.current)?.length > 0 && refTabView.current) {
            clearInterval(interval);
            refTabView.current.goToPage(jumpIndex);
          }
        }, 200);
      }
      return;
    }
    callBack(id, totalCount, result, jumpIndex, refresh);
  };

  const callBack = (id, totalCount, result, jumpIndex, refresh) => {
    const index = loadingPagerRef.current?.[id]?.index;
    getListProductByCategory(
      { id, refresh: false, extraSkip: result[id]?.length || 0 },
      (res) => {
        if (res?.endList) {
          loadingPagerRef.current[id].endList = true;
          setLoadingPager({ ...loadingPagerRef.current });
        }
        if (res?.data) {
          if (!result?.[id]) {
            // eslint-disable-next-line no-param-reassign
            result[id] = [];
          }
          // eslint-disable-next-line no-param-reassign
          result[id] = result[id].concat(res?.data);
          // eslint-disable-next-line no-param-reassign
          totalCount += res?.data?.length;
          if (totalCount < fetchCountItem) {
            if (
              index === categoriesRef.current?.length - 1 &&
              loadingPagerRef.current[id]?.endList
            ) {
              fetchDataAll(id, totalCount, result, true, jumpIndex, refresh);
            } else if (loadingPagerRef.current[id]?.endList) {
              fetchDataAll(
                categoriesRef.current[index + 1].categoryId,
                totalCount,
                result,
                false,
                jumpIndex,
                refresh
              );
            } else {
              fetchDataAll(id, totalCount, result, false, jumpIndex, refresh);
            }
          } else {
            fetchDataAll(id, totalCount, result, true, jumpIndex, refresh);
          }
        } else {
          if (
            (isEndListAllRef.current &&
              (!listEndCategoryRef.current?.[id] ||
                listEndCategoryRef.current?.[id]?.length === 0) &&
              !data[id]?.data) ||
            data[id]?.data?.length === 0
          ) {
            loadingPagerRef.current[id].emptyList = true;
            setLoadingPager({ ...loadingPagerRef.current });
          }
          if (
            index === categoriesRef.current?.length - 1 &&
            loadingPagerRef.current[id]?.endList
          ) {
            fetchDataAll(id, totalCount, result, true, jumpIndex, refresh);
          } else {
            fetchDataAll(
              res?.endList ? categoriesRef.current[index + 1].categoryId : id,
              totalCount,
              result,
              false,
              jumpIndex,
              refresh
            );
          }
        }
      },
      () => {
        loadingPagerRef.current["all"].isLoading = false;
        setLoadingPager({ ...loadingPagerRef.current });
      }
    );
  };

  const getNextId = () => {
    let id = currentSectionRef.current;
    while (
      loadingPagerRef.current?.[id]?.endList &&
      categoriesRef.current?.[loadingPagerRef.current?.[id]?.index + 1]
        ?.categoryId
    ) {
      id =
        categoriesRef.current[loadingPagerRef.current?.[id]?.index + 1]
          ?.categoryId;
    }
    return id;
  };

  const onLoadMore = (tabId) => {
    if (isEndListAllRef.current) {
      return;
    }
    if (!loadingPagerRef.current) {
      loadingPagerRef.current = {};
    }
    if (!loadingPagerRef.current || !loadingPagerRef.current[tabId]) {
      loadingPagerRef.current[tabId] = {};
    }
    loadingPagerRef.current[tabId].isLoading = true;
    setLoadingPager({ ...loadingPagerRef.current });
    if (tabId === "all") {
      // load more tab all
      const id = getNextId();
      fetchDataAll(id, 0, {}, false);
      return;
    }
    // load more tal child
    onPageSelected(tabId, null, [], true);
  };

  const onRefresh = (tabId) => {
    setLoadingPager({});
    setData({});
    setCategories([]);
    setEndListAll(false);
    loadingPagerRef.current = {};
    listEndCategoryRef.current = {};
    categoriesRef.current = [];

    // const getData =
    //   type === "all"
    //     ? props.getListFnbTypeRequest
    //     : props.getListCategoryRequest;

    LocalStorage.getItem(
      type === "all" ? params?.merchantId : { ...params },
      (resCategory) => {
        if (
          type === "all"
            ? resCategory?.data?.length > 0
            : resCategory?.length > 0
        ) {
          // eslint-disable-next-line no-param-reassign
          resCategory =
            type === "all"
              ? listCategoryParser(resCategory?.data)
              : resCategory;
          const tmp = {};

          const dataCategory = [
            {
              categoryId: "all",
              categoryName: "Tất cả",
            },
          ].concat(resCategory);

          // eslint-disable-next-line array-callback-return
          dataCategory.map((elm, index) => {
            tmp[elm.categoryId] = {
              isLoading: false,
              name: elm.categoryName,
              index,
            };
          });
          setLoadingPager(tmp);
          loadingPagerRef.current = tmp;
          setCategories(dataCategory);
          categoriesRef.current = dataCategory;
          fetchDataAll(
            dataCategory[1].categoryId,
            0,
            {},
            false,
            tabId === "all" ? undefined : tmp?.[tabId]?.index,
            true
          );
        }
      },
      (error) => {
        // eslint-disable-next-line no-useless-return
        if (error?.data?.code === "NETWORK_REQUEST_FAILED") return;
        // Alert.alert(
        //   strings("common.alertTitle"),
        //   strings("SmartOrderMenu.labelTheStoreIsUpdating"),
        //   [
        //     {
        //       text: strings("SmartOrderMenu.labelYes"),
        //       onPress: () => {
        //         NavigationServices.goBack();
        //       },
        //     },
        //   ],
        //   { cancelable: false }
        // );
      }
    );
  };
  const mergeMasterDataEndList = (refresh) => {
    // eslint-disable-next-line no-nested-ternary
    const masterData = refresh
      ? {}
      : Object.keys(dataRef.current)?.length > 0
      ? dataRef.current
      : data;
    const objData = Object.keys(listEndCategoryRef.current);

    if (objData?.length === 0) {
      return masterData;
    }

    for (let i = 0; i < objData?.length; i++) {
      const groupName = objData?.[i];
      if (!masterData[groupName]) {
        masterData[groupName] = {
          data: [],
        };
      }
      masterData[groupName].data = masterData[groupName].data.concat(
        listEndCategoryRef.current[groupName]
      );
    }
    listEndCategoryRef.current = {};
    return masterData;
  };

  const mergeMasterData = (json, refresh) => {
    // eslint-disable-next-line no-nested-ternary
    const masterData = refresh
      ? {}
      : Object.keys(dataRef.current)?.length > 0
      ? dataRef.current
      : data;

    const objData = Object.keys(json);

    if (objData.length === 0) {
      return masterData;
    }
    for (let i = 0; i < objData.length; i++) {
      const groupName = objData[i];

      if (groupName) {
        if (!masterData?.[groupName]) {
          masterData[groupName] = { data: [] };
        }
        masterData[groupName].data = masterData[groupName].data.concat(
          json[groupName]
        );
      }
    }
    const listEnd = Object.keys(listEndCategoryRef.current);
    if (listEnd?.length > 0) {
      listEnd.forEach((element) => {
        if (
          listEndCategoryRef.current[element]?.length > 0 &&
          loadingPagerRef.current[element]?.endList
        ) {
          if (!masterData?.[element]) {
            masterData[element] = { data: listEndCategoryRef.current[element] };
          } else {
            masterData[element].data = masterData?.[element]?.data.concat(
              listEndCategoryRef.current[element]
            );
          }
          listEndCategoryRef.current[element] = [];
        }
      });
    }

    return masterData;
  };

  const onSubmit = () => {
    storeInfo.storeId = storeInfo.id;
    props.setDataStore(storeInfo);
    props.setIndexProductCart();

    NavigationServices.navigate("CartMarket", {
      isFromMarket: true,
      storeInfo,
    });
    checkGuest(function (isGuest) {
      if (isGuest) {
        checkGuestPopup();
      } else {
        storeInfo.storeId = storeInfo.id;
        props.setDataStore(storeInfo);
        props.setIndexProductCart();

        NavigationServices.navigate("CartMarket", {
          isFromMarket: true,
          storeInfo,
        });
      }
    });
  };

  const updateCart = (itemCart) => {
    // const dataTrackingAzure = {
    //   MerchantId: storeInfo?.merchantId,
    //   MerchantName: storeInfo?.merchantName,
    //   StoreId: storeInfo?.id,
    //   StoreName: storeInfo?.name,
    //   Quantity: itemCart?.dataInfoProduct?.quantity,
    //   ProductCode: itemCart?.dataInfoProduct?.productCode,
    //   ProductId: itemCart?.dataInfoProduct?.productId,
    //   ProductName: itemCart?.data?.name?.vi,
    // };
    // insightTrackEvent("Add_To_Cart", dataTrackingAzure);
    if (
      props?.getDataCart?.length > 0 &&
      JSON.stringify(props.getDataStore) !== "{}" &&
      storeInfo?.id !== props.getDataStore.storeId
    ) {
      setAlertAction({
        isShow: true,
        title: "Tạo giỏ hàng mới?",
        content:
          "Thêm sản phẩm này sẽ khiến các sản phẩm hiện tại trong giỏ hàng của bạn bị xoá. Bạn có muốn tiếp tục?",
        titleAccept: "Tiếp tục",
        titleCancel: "Đóng",
        dataItem: { ...itemCart },
      });
      //   Alert.alert(
      //     strings("SmartOrderMenu.createNewCart"),
      //     strings("SmartOrderMenu.labelAddNewCart"),
      //     [
      //       {
      //         text: strings("common.labelClose"),
      //         style: "cancel",
      //       },
      //       {
      //         text: strings("Login.label5"),
      //         onPress: () => {
      //           storeInfo.storeId = storeInfo.id;
      //           props.setDataStore(storeInfo);
      //           props.resetProductCart({
      //             itemCart,
      //             isNotClearCoupon:
      //               props.route.params.isFrom === "ApplicableStore",
      //           });
      //         },
      //       },
      //     ],
      //     { cancelable: false }
      //   );
    } else {
      if (
        JSON.stringify(props.getDataStore) === "{}" ||
        storeInfo?.id !== props.getDataStore.storeId
      ) {
        storeInfo.storeId = storeInfo.id;
        props.setDataStore(storeInfo);
      }
      // eslint-disable-next-line no-shadow
      updateProductsCart(props.getDataCart, itemCart, (type, payload) => {
        switch (type) {
          case "add":
            props.addProductCart(payload);
            break;

          case "update":
            props.updateListProductCart(payload);
            break;

          default:
            break;
        }
      });
    }
  };

  const onAcceptAlert = (item) => {
    if (item?.key === "back") {
      NavigationServices.goBack();
      setAlertAction({});
    } else {
      storeInfo.storeId = storeInfo.id;
      props.setDataStore(storeInfo);
      props.resetProductCart({
        itemCart: item,
        isNotClearCoupon: params?.isFrom === "ApplicableStore",
      });
      setAlertAction({});
    }
  };

  return (
    <MarketProductView
      data={data}
      setData={setData}
      onLoadMore={onLoadMore}
      categories={categories}
      onPageSelected={onPageSelected}
      loadingPager={loadingPager}
      onRefresh={onRefresh}
      name={params?.name}
      loadingPagerRef={loadingPagerRef}
      currentSectionRef={currentSectionRef}
      isEndListAll={isEndListAll}
      storeInfo={storeInfo}
      dataCart={
        JSON.stringify(props?.getDataStore) !== "{}" &&
        storeInfo?.id === props?.getDataStore?.storeId
          ? props?.getDataCart
          : params?.dataReOrder || []
      }
      onSubmit={onSubmit}
      updateCart={updateCart}
      updateProductCart={props.updateProductCart}
      deleteProductCart={props.deleteProductCart}
      isCloseStore={params?.isCloseStore}
      isShowCategoryName={type === "all"}
      refTabView={refTabView}
      dataAlertAction={alertAction}
      setDataAlertAction={() => setAlertAction({})}
      handleAlertAction={onAcceptAlert}
    />
  );
};

const mapStateToProps = (state) => ({
  getDataCart: getListProduct(state),
  getDataStore: getDataStore(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getListProductByCategoryRequest,
      getListCategoryRequest,
      updateProductCart,
      deleteProductCart,
      setDataStore,
      addProductCart,
      updateListProductCart,
      resetProductCart,
      setIndexProductCart,
      getListFnbTypeRequest,
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(MarketProduct);
