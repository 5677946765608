import {
  GET_INFO_STORE_REQUEST,
  GET_DETAIL_ORDER_REQUEST,
  GET_DISCOUNT_COUPON_REQUEST,
  GET_PAYMENT_METHOD_REQUEST,
  CANCEL_ORDER_REQUEST,
  GET_LIST_SUMMARY_BY_USER_REQUEST,
} from "./constants";
import {
  getDiscountCouponFailure,
  getDetailOrderFailure,
  getDiscountCouponSuccess,
  getDetailOrderSuccess,
  getInfoStoreSuccess,
  getInfoStoreFailure,
  getPaymentMethodSuccess,
  getPaymentMethodFailure,
  cancelOrderSuccess,
  cancelOrderFailure,
  getListSummaryByUserSuccess,
  getListSummaryByUserFailure,
} from "./actions";
import { call, put, takeLatest, fork } from "redux-saga/effects";
import {
  getOrderDetailApi,
  getInfoStoreApi,
  getDiscountCouponApi,
  getPaymentMethodApi,
  cancelOrderApi,
  getListVoucherSumaryByUserApi,
} from "@shared/services/api/repaymentVoucher";
import { callApi } from "helpers/callApi";

//get detail order
export function* getOrderDetailSaga(obj) {
  const { payload = {}, onSuccess, onFailed } = obj;
  try {
    const res = yield callApi(getOrderDetailApi, {
      ...payload,
    });
    yield put(
      getDetailOrderSuccess({
        data: res,
      })
    );
    if (typeof onSuccess === "function") {
      onSuccess?.(res);
    }
  } catch (err) {
    onFailed?.();
    yield put(getDetailOrderFailure(err));
  }
}

//get info store
export function* getInfoStoreSaga(obj) {
  const { payload = {}, onSuccess, onFailed } = obj;
  try {
    const res = yield callApi(getInfoStoreApi, {
      ...payload,
    });
    yield put(
      getInfoStoreSuccess({
        data: res,
      })
    );
    if (typeof onSuccess === "function") {
      onSuccess(res);
    }
  } catch (err) {
    if (typeof onFailed === "function") {
      onFailed(err);
    }
    yield put(getInfoStoreFailure(err));
  }
}

// get discount coupon
export function* getDiscountCouponSaga(obj) {
  const { payload = {}, onSuccess, onFailed } = obj;
  try {
    const res = yield callApi(getDiscountCouponApi, {
      ...payload,
    });
    yield put(
      getDiscountCouponSuccess({
        data: res,
      })
    );
    if (typeof onSuccess === "function") {
      onSuccess(res);
    }
  } catch (err) {
    if (typeof onFailed === "function") {
      onFailed(err);
    }
    yield put(getDiscountCouponFailure(err));
  }
}

//get payment method
export function* getPaymentMethodSaga(obj) {
  const { onSuccess, onFailed } = obj;
  try {
    const res = yield callApi(getPaymentMethodApi, {});
    console.log(res);
    yield put(
      getPaymentMethodSuccess({
        data: res,
      })
    );
    if (typeof onSuccess === "function") {
      onSuccess(res);
    }
  } catch (err) {
    if (typeof onFailed === "function") {
      onFailed();
    }
    yield put(getPaymentMethodFailure(err));
  }
}

//cancel order
export function* cancelOrderSaga(obj) {
  try {
    const { payload = {}, onSuccess } = obj;
    const res = yield callApi(cancelOrderApi, {
      ...payload,
    });
    yield put(
      cancelOrderSuccess({
        data: res,
      })
    );
    if (typeof onSuccess === "function") {
      onSuccess(res);
    }
  } catch (err) {
    yield put(cancelOrderFailure(err));
  }
}

export function* getListSummaryByUserSaga(obj) {
  const { onSuccess, onFailed } = obj;
  try {
    const res = yield callApi(getListVoucherSumaryByUserApi, {});
    console.log(res);
    yield put(
      getListSummaryByUserSuccess({
        data: res,
      })
    );
    if (typeof onSuccess === "function") {
      onSuccess(res);
    }
  } catch (err) {
    if (typeof onFailed === "function") {
      onFailed();
    }
    yield put(getListSummaryByUserFailure(err));
  }
}

function* repaymentVoucherWatch() {
  yield takeLatest(GET_DETAIL_ORDER_REQUEST, getOrderDetailSaga);
  yield takeLatest(GET_INFO_STORE_REQUEST, getInfoStoreSaga);
  yield takeLatest(GET_DISCOUNT_COUPON_REQUEST, getDiscountCouponSaga);
  yield takeLatest(GET_PAYMENT_METHOD_REQUEST, getPaymentMethodSaga);
  yield takeLatest(CANCEL_ORDER_REQUEST, cancelOrderSaga);
  yield takeLatest(GET_LIST_SUMMARY_BY_USER_REQUEST, getListSummaryByUserSaga);
}

export default function* rootChild() {
  yield fork(repaymentVoucherWatch);
}
