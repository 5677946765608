import types from './types';

export const setSearchStoreMarket = payload => ({
  type: types.SET_IS_END_SEARCH_MARKET_1,
  payload,
});
export const searchStallsRequest = (payload, onSuccess, onFailed) => ({
  type: types.SEARCH_STALLS,
  payload,
  onSuccess,
  onFailed,
});

export const searchStallsRequestSuccess = listProductSearchStalls => ({
  type: types.SEARCH_STALLS_SUCCESS,
  listProductSearchStalls,
});

export const searchStallsRequestFailed = error => ({
  type: types.SEARCH_STALLS_FAILED,
  error,
});
export const clearSearchStalls = error => ({
  type: types.CLEAR_SEARCH_STALLS,
  error,
});
export const clearSearchStallsHistory = error => ({
  type: types.CLEAR_SEARCH_STALLS_HISTORY,
  error,
});
