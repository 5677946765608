import { addErrors, delErrors } from "utils/ErrorsGenerator";
import * as TYPE from "./constants";

const reducers = {
  // *** GET_LIST_DEAL
  [TYPE.GET_LIST_DEAL.request]: (draft) => {
    return {
      ...draft,
      isLoading: {
        ...draft.isLoading,
        GET_LIST_DEAL: true,
      },
    };
  },
  [TYPE.GET_LIST_DEAL.success]: (draft, { payload }) => {
    return {
      ...draft,
      listDeal: payload,
      skip: payload.skip,
      take: payload.take,
      errors: delErrors(draft.errors, TYPE.GET_LIST_DEAL.failure),
      isLoading: {
        ...draft.isLoading,
        GET_LIST_DEAL: false,
      },
    };
  },
  [TYPE.GET_LIST_DEAL.failure]: (draft, { payload }) => {
    return {
      ...draft,
      errors: addErrors(draft.errors, {
        type: TYPE.GET_LIST_DEAL.failure,
        msg: payload,
      }),
      isLoading: {
        ...draft.isLoading,
        GET_LIST_DEAL: false,
      },
    };
  },
};

export default reducers;
