export const SET_IS_CLEAR_DATA = 'budget/SET_IS_CLEAR_DATA';
export const SET_BUDGET_SELECTED = 'budget/SET_BUDGET_SELECTED';
export const UPDATE_TRANSACTION_HISTORY_UB =
  'budget/UPDATE_TRANSACTION_HISTORY_UB';
export const UPDATE_AVAILABLE_AMOUNT_BUDGET_SELECTED =
  'budget/UPDATE_AVAILABLE_AMOUNT_BUDGET_SELECTED';
export const GET_LIST_BUDGET_REQUEST = 'budget/GET_LIST_BUDGET_REQUEST';
export const GET_LIST_BUDGET_SUCCESS = 'budget/GET_LIST_BUDGET_SUCCESS';
export const GET_LIST_BUDGET_FAILED = 'budget/GET_LIST_BUDGET_FAILED';
export const GET_BUDGET_INFO_REQUEST = 'budget/GET_BUDGET_INFO_REQUEST';
export const GET_BUDGET_INFO_SUCCESS = 'budget/GET_BUDGET_INFO_SUCCESS';
export const GET_BUDGET_INFO_FAILED = 'budget/GET_BUDGET_INFO_FAILED';
export const GET_HISTORY_BUDGET_REQUEST = 'budget/GET_HISTORY_BUDGET_REQUEST';
export const GET_HISTORY_BUDGET_SUCCESS = 'budget/GET_HISTORY_BUDGET_SUCCESS';
export const GET_HISTORY_BUDGET_FAILED = 'budget/GET_HISTORY_BUDGET_FAILED';
export const CONFIRM_ADVANCE_REQUEST = 'budget/CONFIRM_ADVANCE_REQUEST';
export const CONFIRM_ADVANCE_SUCCESS = 'budget/CONFIRM_ADVANCE_SUCCESS';
export const CONFIRM_ADVANCE_FAILED = 'budget/CONFIRM_ADVANCE_FAILED';
export const GET_TRANSACTION_HISTORY_UB_REQUEST =
  'budget/GET_TRANSACTION_HISTORY_UB_REQUEST';
export const GET_TRANSACTION_HISTORY_UB_SUCCESS =
  'budget/GET_TRANSACTION_HISTORY_UB_SUCCESS';
export const GET_TRANSACTION_HISTORY_UB_FAILED =
  'budget/GET_TRANSACTION_HISTORY_UB_FAILED';
export const REVOCATION_BUDGET_REQUEST = 'budget/REVOCATION_BUDGET_REQUEST';
export const REVOCATION_BUDGET_SUCCESS = 'budget/REVOCATION_BUDGET_SUCCESS';
export const REVOCATION_BUDGET_FAILED = 'budget/REVOCATION_BUDGET_FAILED';
export const CLEAR_BUDGET = 'budget/CLEAR_BUDGET';
export const UPDATE_BUDGET_HISTORY = 'budget/UPDATE_BUDGET_HISTORY';
export const SET_LIST_ACCOUNT_BUDGET = 'budget/SET_LIST_ACCOUNT_BUDGET';
