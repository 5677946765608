const prefix = 'MARKET_STORE';
const types = {
  SET_IS_END_SEARCH_MARKET_1: prefix + '/SET_IS_END_SEARCH_MARKET_1',
  SEARCH_STALLS: prefix + '/SEARCH_STALLS',
  SEARCH_STALLS_SUCCESS: prefix + '/SEARCH_STALLS_SUCCESS',
  SEARCH_STALLS_FAILED: prefix + '/SEARCH_STALLS_FAILED',
  CLEAR_SEARCH_STALLS: prefix + '/CLEAR_SEARCH_STALLS',
  CLEAR_SEARCH_STALLS_HISTORY: prefix + '/CLEAR_SEARCH_STALLS_HISTORY',
};
export default types;
