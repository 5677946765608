import {
  cilApplicationsSettings,
  cilSpeedometer,
  cilSun,
  cilMoon,
  cilChartLine,
  cilLibrary,
  cilLockLocked,
  cibSkype,
  cibFacebook,
  cibTwitter,
  cibLinkedin,
  cibFlickr,
  cibTumblr,
  cibXing,
  cibGithub,
  cibStackoverflow,
  cibYoutube,
  cibDribbble,
  cibInstagram,
  cibPinterest,
  cibVk,
  cibYahoo,
  cibBehance,
  cibReddit,
  cibVimeo,
  cibCcMastercard,
  cibCcVisa,
  cibStripe,
  cibPaypal,
  cibGooglePay,
  cibCcAmex,
  cilDescription,
  cifUs,
  cifBr,
  cifIn,
  cifFr,
  cifEs,
  cifPl,
  cilAlignCenter,
  cilAlignLeft,
  cilAlignRight,
  cilArrowRight,
  cilArrowTop,
  cilAsterisk,
  cilBan,
  cilBasket,
  cilBell,
  cilBold,
  cilBookmark,
  cilCalculator,
  cilCalendar,
  cilCloudDownload,
  cilChartPie,
  cilCheck,
  cilChevronBottom,
  cilChevronLeft,
  cilChevronRight,
  cilChevronTop,
  cilCircle,
  cilCheckCircle,
  cilCode,
  cilCommentSquare,
  cilCreditCard,
  cilCursor,
  cilCursorMove,
  cilDrop,
  cilDollar,
  cilEnvelopeClosed,
  cilEnvelopeLetter,
  cilEnvelopeOpen,
  cilEuro,
  cilGlobeAlt,
  cilGrid,
  cilFile,
  cilFullscreen,
  cilFullscreenExit,
  cilGraph,
  cilHome,
  cilInbox,
  cilIndentDecrease,
  cilIndentIncrease,
  cilInputPower,
  cilItalic,
  cilJustifyCenter,
  cilJustifyLeft,
  cilLaptop,
  cilLayers,
  cilLightbulb,
  cilList,
  cilListNumbered,
  cilListRich,
  cilLocationPin,
  cilMagnifyingGlass,
  cilMap,
  cilNotes,
  cilOptions,
  cilPaperclip,
  cilPaperPlane,
  cilPencil,
  cilPeople,
  cilPhone,
  cilPrint,
  cilPuzzle,
  cilSave,
  cilScrubber,
  cilSettings,
  cilShare,
  cilShareAll,
  cilShareBoxed,
  cilShieldAlt,
  cilSpeech,
  cilSpreadsheet,
  cilStar,
  cilTags,
  cilTask,
  cilTrash,
  cilUnderline,
  cilUser,
  cilUserFemale,
  cilUserFollow,
  cilUserUnfollow,
  cilX,
  cilXCircle,
  cilWarning,
  cilAddressBook,
  cilPlus,
  cilSend,
  cilClock,
  cilShareAlt,
  cilMinus,
} from "@coreui/icons";
import { sygnet } from "./sygnet";
import { logo } from "./logo";
import { logoNegative } from "./logo-negative";
import iconClose from "./iconClose.png";
import iconPause from "./iconPause.png";
import iconFilter from "./iconFilter.png";
import iconCheck from "./iconCheck.png";
import iconAlbum from "./iconAlbum.png";
import iconScheduledDelivery from "./iconScheduledDelivery.png";
import iconFlag from "./icon-flag.png";
import iconCircleChecked from "./iconCircleChecked.png";
import iconTakeAway from "./iconTakeAway.png";
import iconTable from "./iconTable.png";
import iconSquareUnCheck from "./iconSquareUnCheck.png";
import iconSquareCheck from "./iconSquareCheck.png";
import iconDefaultHome from "./iconDefaultHome.png";
import iconDefaultShop from "./iconDefaultShop.png";
import iconCloseBack from "./iconCloseBack.png";



export const icons = {
  sygnet,
  logo,
  logoNegative,
  cilApplicationsSettings,
  cilSpeedometer,
  cilSun,
  cilMoon,
  cilChartLine,
  cilLibrary,
  cilLockLocked,
  cibSkype,
  cibFacebook,
  cibTwitter,
  cibLinkedin,
  cibFlickr,
  cibTumblr,
  cibXing,
  cibGithub,
  cibStackoverflow,
  cibYoutube,
  cibDribbble,
  cibInstagram,
  cibPinterest,
  cibVk,
  cibYahoo,
  cibBehance,
  cibReddit,
  cibVimeo,
  cibCcMastercard,
  cibCcVisa,
  cibStripe,
  cibPaypal,
  cibGooglePay,
  cibCcAmex,
  cilDescription,
  cifUs,
  cifBr,
  cifIn,
  cifFr,
  cifEs,
  cifPl,
  cilAlignCenter,
  cilAlignLeft,
  cilAlignRight,
  cilArrowRight,
  cilArrowTop,
  cilAsterisk,
  cilBan,
  cilBasket,
  cilBell,
  cilBold,
  cilBookmark,
  cilCalculator,
  cilCalendar,
  cilCloudDownload,
  cilChartPie,
  cilCheck,
  cilChevronBottom,
  cilChevronLeft,
  cilChevronRight,
  cilChevronTop,
  cilCircle,
  cilCheckCircle,
  cilCode,
  cilCommentSquare,
  cilCreditCard,
  cilCursor,
  cilCursorMove,
  cilDrop,
  cilDollar,
  cilEnvelopeClosed,
  cilEnvelopeLetter,
  cilEnvelopeOpen,
  cilEuro,
  cilGlobeAlt,
  cilGrid,
  cilFile,
  cilFullscreen,
  cilFullscreenExit,
  cilGraph,
  cilHome,
  cilInbox,
  cilIndentDecrease,
  cilIndentIncrease,
  cilInputPower,
  cilItalic,
  cilJustifyCenter,
  cilJustifyLeft,
  cilLaptop,
  cilLayers,
  cilLightbulb,
  cilList,
  cilListNumbered,
  cilListRich,
  cilLocationPin,
  cilMagnifyingGlass,
  cilMap,
  cilNotes,
  cilOptions,
  cilPaperclip,
  cilPaperPlane,
  cilPencil,
  cilPeople,
  cilPhone,
  cilPrint,
  cilPuzzle,
  cilSave,
  cilScrubber,
  cilSettings,
  cilShare,
  cilShareAll,
  cilShareBoxed,
  cilShieldAlt,
  cilSpeech,
  cilSpreadsheet,
  cilStar,
  cilTags,
  cilTask,
  cilTrash,
  cilUnderline,
  cilUser,
  cilUserFemale,
  cilUserFollow,
  cilUserUnfollow,
  cilX,
  cilXCircle,
  cilWarning,
  cilAddressBook,
  cilPlus,
  cilSend,
  cilClock,
  cilShareAlt,
  cilMinus,
  iconClose,
  iconPause,
  iconFilter,
  iconCheck,
  iconAlbum,
  iconScheduledDelivery,
  iconFlag,
  iconCircleChecked,
  iconTakeAway,
  iconTable,
  iconSquareUnCheck,
  iconSquareCheck,
  iconDefaultHome,
  iconDefaultShop,
  iconCloseBack
};

export { ReactComponent as Calendar20x20 } from "./shape.svg";
