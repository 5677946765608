// import {CommonActions, StackActions} from '@react-navigation/native';
import queryString from "query-string";
import { history } from "../services/stores";

// const NavigationServices = () => {
//   const history = useHistory();
//   const push = (routeName, params) => {
//     console.log(routeName);
//     history().push(routeName, params);
//   };
//   return push;
// };

let navigationState = {};

function navigate(routeName, params) {
  history.push(routeName, params);
}

// function reset(options) {
//   navigationRef.current.resetRoot(options);
// }

const goBack = () => {
  history.goBack();
};

// function replace(routeName, params, key) {
//   navigationRef.current.dispatch({
//     ...StackActions.replace(routeName, params),
//     key,
//   });
// }

function push(routeName, params) {
  history.push(routeName, params);
}

// function pop(key) {
//   navigationRef.current.dispatch({
//     ...StackActions.pop(),
//     key,
//   });
// }

// function reset(config) {
//   navigationRef.current.dispatch(CommonActions.reset(config));
// }

function setNavigationState(state) {
  navigationState = state;
}

function getNavigationState() {
  return navigationState;
}

const getCurrentRoute = (props) => {
  const prevPathname = props?.router?.location?.state?.prevPathname;
  const currentPathname = props?.router?.location?.pathname;
  const currentPath =
    props?.router?.location?.pathname + props?.router?.location?.search;
  const statePath = props?.router?.location?.state;
  return { prevPathname, currentPathname, currentPath, statePath };
};

// add other navigation functions that you need and export them
const NavigationServices = {
  navigate,
  goBack,
  // reset,
  setNavigationState,
  getNavigationState,
  // navigationRef,
  push,
  getParams,
  // replace,
  getCurrentRoute,
  // pop,
};
export default NavigationServices;

export function getParams(props) {
  const params = queryString.parse(props.router.location?.search);

  return (
    {
      ...props.router.location.state,
      ...props.router.match.params,
      ...params,
    } || {}
  );
}
