import React from "react";
import classNames from "classnames";

import "./ButtonBase.scss";

function ButtonBase(props) {
  const { children, onClick, className, style, ...rest } = props;
  return (
    <button
      type="button"
      className={classNames("button-base", className)}
      style={style}
      onClick={onClick}
      {...rest}
    >
      {children}
    </button>
  );
}

export default ButtonBase;
