/* eslint-disable radix */
import moment from 'moment'
import { getCurrentDay } from './getCurrentDay'

const checkTimePoint = (currentDay, time, isOut) => {
  let weekDay = parseInt(
    typeof time.WeekDay !== 'undefined' ? time.WeekDay : time.weekDay,
  )
  if (currentDay === 7 && weekDay < 7) {
    currentDay = 0
  }
  switch (weekDay) {
    case currentDay:
      if (isOut) {
        switch (weekDay) {
          case currentDay:
            return checkDay(weekDay)
          case currentDay + 1:
            return 'ngày mai'

          default:
            break
        }
      }
      return 'hôm nay'

    case currentDay + 1:
      return 'ngày mai'

    default:
      return checkDay(weekDay)
  }
}

const checkDay = (value) => {
  value = parseInt(value)
  let text = ''
  switch (value) {
    case 1:
      text = 'Chủ Nhật'
      break
    case 2:
      text = 'Thứ Hai'
      break
    case 3:
      text = 'Thứ Ba'
      break
    case 4:
      text = 'Thứ Tư'
      break
    case 5:
      text = 'Thứ Năm'
      break
    case 6:
      text = 'Thứ Sáu'
      break
    case 7:
      text = 'Thứ Bảy'
      break

    default:
      break
  }
  return text
}

const isInRange = (range) => {
  var currentHours = parseInt(new Date().getHours())
  var currentMinutes = parseInt(new Date().getMinutes())
  var currentTime = currentHours * 60 + currentMinutes
  let data = { openTime: '' }
  range.forEach((element, i) => {
    if (data.openTime !== '') {
      return
    }
    let From = typeof element.from !== 'undefined' ? element.from : element.From
    let To = typeof element.to !== 'undefined' ? element.to : element.To

    let arrFrom = From.split(':')
    let arrTo = To.split(':')

    let timeFrom = parseInt(arrFrom[0]) * 60 + parseInt(arrFrom[1])
    let timeTo = parseInt(arrTo[0]) * 60 + parseInt(arrTo[1])

    if (currentTime < timeFrom) {
      data.rangeTime = 'before'
      data.openTime = element
      data.openNextTime = checkAMPM(
        typeof element.From !== 'undefined' ? element.From : element.from,
      )
      return
    }

    if (currentTime >= timeFrom && currentTime <= timeTo) {
      data.rangeTime = 'in'
      data.openTime = element
      data.openNextTime = checkAMPM(
        typeof element.From !== 'undefined' ? element.From : element.from,
      )
      return
    }
    if (i === range.length - 1 && currentTime > timeTo) {
      data.rangeTime = 'after'
      return
    }
  })
  return data
}

const checkAMPM = (value) => {
  return value
  // if (value.split(':')[0] === '00' || value.split(':')[0] === '24') {
  //   return value + ' SA';
  // }
  // if (parseInt(value.split(':')[0]) >= 12) {
  //   if (parseInt(value.split(':')[0]) === 12) {
  //     return value + ' CH';
  //   }

  //   if ((parseInt(value.split(':')[0]) - 12).toString().length === 1) {
  //     return (
  //       '0' +
  //       (parseInt(value.split(':')[0]) - 12) +
  //       ':' +
  //       value.split(':')[1] +
  //       ' CH'
  //     );
  //   }

  //   return (
  //     parseInt(value.split(':')[0]) - 12 + ':' + value.split(':')[1] + ' CH'
  //   );
  // } else {
  //   return value + ' SA';
  // }
}
const getOpenTime = (arrTime) => {
  if (arrTime.length === 0) {
    return {
      rangeTime: 'all',
    }
  }
  return filterOpenTime(getCurrentDay(), arrTime, getCurrentDay())
}

const filterOpenTime = (day, arrTime, currentDay) => {
  let items = []
  arrTime.sort(function (a, b) {
    if (typeof a.WeekDay !== 'undefined') {
      return parseInt(a.WeekDay) - parseInt(b.WeekDay)
    } else {
      return parseInt(a.weekDay) - parseInt(b.weekDay)
    }
  })
  arrTime.sort(function (a, b) {
    if (typeof a.WeekDay !== 'undefined') {
      if (a.WeekDay === b.WeekDay) {
        let temp1 = moment(
          typeof a.From !== 'undefined' ? a.From : a.from,
          'HH:mm',
        )
        let temp2 = moment(
          typeof b.From !== 'undefined' ? b.From : b.from,
          'HH:mm',
        )
        if (temp1 < temp2) {
          return 1 - 2
        }
        return 2 - 1
      }
      return
    } else {
      if (a.WeekDay === b.WeekDay) {
        let temp1 = moment(
          typeof a.From !== 'undefined' ? a.From : a.from,
          'HH:mm',
        )
        let temp2 = moment(
          typeof b.From !== 'undefined' ? b.From : b.from,
          'HH:mm',
        )
        if (temp1 < temp2) {
          return 1 - 2
        }
        return 2 - 1
      }
      return
    }
  })
  items = arrTime
    .filter((i) => [day.toString()].includes(i.WeekDay || i.weekDay))
    .map((i) => {
      return i
    })

  if (items.length === 0 && day < 7) {
    return filterOpenTime(++day, arrTime, currentDay)
  }

  if (items.length === 0 && day === 7) {
    items.push(arrTime[0])
    // day = -1;
  }

  items = items.sort(function (a, b) {
    let temp1 = moment(typeof a.From !== 'undefined' ? a.From : a.from, 'HH:mm')
    let temp2 = moment(typeof b.From !== 'undefined' ? b.From : b.from, 'HH:mm')
    if (temp1 < temp2) {
      return 1 - 2
    }
    return 2 - 1
  })
  // in range current time
  if (
    (currentDay === day && parseInt(items[0].WeekDay) === day) ||
    (currentDay === day && parseInt(items[0].weekDay) === day)
  ) {
    let jsRangeTime = isInRange(items)
    switch (jsRangeTime.rangeTime) {
      case 'in':
        return jsRangeTime

      case 'before':
        jsRangeTime.timePoint = checkTimePoint(currentDay, jsRangeTime.openTime)
        return jsRangeTime

      case 'after':
        if (day === 7) {
          jsRangeTime.openTime = arrTime[0]
          jsRangeTime.openNextTime = checkAMPM(
            typeof arrTime[0].From !== 'undefined'
              ? arrTime[0].From
              : arrTime[0].from,
          )
          jsRangeTime.timePoint = checkTimePoint(
            currentDay,
            jsRangeTime.openTime,
            true,
          )
          return jsRangeTime
        } else {
          return filterOpenTime(++day, arrTime, currentDay)
        }

      default:
        break
    }
  }

  // out range current time
  return {
    openTime: items[0],
    openNextTime: checkAMPM(
      typeof items[0].From !== 'undefined' ? items[0].From : items[0].from,
    ),
    rangeTime: 'out',
    timePoint: checkTimePoint(currentDay, items[0], true),
  }
}
export { getOpenTime, checkAMPM }
