/* eslint-disable no-useless-return */
/* eslint-disable no-unused-vars */
import React, { useCallback, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import PageHeader from "views/common/component/PageHeader";
import DebounceInput from "views/common/component/DebounceInput";
import LoadingOverlay from "views/common/component/LoadingOverlay";
import { COUPON_DETAIL_PAGE_MODES } from "constants/types";
import iconLocationFNB from "assets/icons/iconLocationFNB.png";

import SVG from "assets/svg";
import IMAGES from "assets/img";
import arrowNext from "assets/icons/arrow-next.svg";
import { NavigationServices } from "utils";
import Popup from "views/common/component/Popup";
import { getDefaultAddressUserRequest } from "@shared/redux/orderAddressFood/actions";
import AlertAction from "views/common/component/AlertAction";
import CouponList from "./components/CouponList/CouponList";
import ShippingInfo from "./components/ShippingInfo";
import AppliedCoupon from "./components/AppliedCoupon";
import Delivery from "./components/DeliveryInfo/Delivery";
import LoyaltyPointSection from "./components/LoyaltyPointSection/LoyaltyPointSection";

import FoodList from "./components/FoodList/FoodList";
import {
  formatPhone,
  getTotalPrice,
  convertDistance,
  formatDistance,
  formatNumber,
} from "./utils";

import "./CartMarket.scss";
import { TYPE } from "./store/constants";
import actions from "./store/actions";
import InputTable from "./components/InputTable";

const getLoyaltyPointToUse = ({
  finalPrice,
  remainingPoint,
  exchangingToVndRate,
}) => {
  if (
    typeof finalPrice !== "number" ||
    typeof remainingPoint !== "number" ||
    typeof exchangingToVndRate !== "number"
  ) {
    return null; // ? should be 0?
  }

  /**
   * Trường hợp điểm loyalty < tổng giá trị đơn hàng
   * -> Hiển thị Tên + Toàn bộ số điểm
   */
  if (remainingPoint * exchangingToVndRate <= finalPrice) {
    return remainingPoint;
  }

  /**
   * Trường hợp Điểm loyalty > tổng giá trị đơn hàng
   * -> Hiển thị Tên + Số điểm loyalty(số điểm này = tổng giá trị đơn hàng)
   */
  const finalPriceAsLP = finalPrice / exchangingToVndRate;

  return finalPriceAsLP;
};

const CartMarketView = (props) => {
  const history = useHistory();
  const { userInfo } = useSelector((state) => state.root);
  const { orderInfo, isLoading, errors, loyaltyPointList } = useSelector(
    (state) => state[TYPE]
  );
  const [deliveryInfo, setDeliveryInfo] = useState({});
  const [loyaltyPointToUse, setLoyaltyPointToUse] = useState();
  const [willUseLoyaltyPoint, setWillUseLoyaltyPoint] = useState(true);
  const formatAmount = (amount) => {
    return `${amount?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}đ`;
  };

  const dispatch = useDispatch();

  useEffect(() => {
    scrollToTop();
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };

  useEffect(() => {
    if (props.deliveryInfo) {
      setDeliveryInfo(props.deliveryInfo);
    }
    dispatch(
      getDefaultAddressUserRequest({
        onSuccess: (response) => {
          setDeliveryInfo(response);
        },
        onFailed: () => {},
      })
    );
  }, []);

  useEffect(() => {
    if (!props.listProduct || props.listProduct.length === 0) {
      NavigationServices.goBack();
    }
  }, [props.listProduct, props?.storeInfo]);

  useEffect(() => {
    if (!userInfo) return;
    if (props?.storeId && props?.merchantId && userInfo) {
      dispatch(
        actions.GET_LOYALTY_POINT_LIST.request({
          merchantId: props?.merchantId,
          storeId: props?.storeId,
        })
      );
    }
  }, [props?.storeId, props?.merchantId, userInfo]);

  useEffect(() => {
    if (loyaltyPointList?.length > 0) {
      setLoyaltyPointToUse(loyaltyPointList[0]);
    }
  }, [loyaltyPointList]);

  // useEffect(() => {
  //   if (orderInfo?.orderCode) {
  //     // localStorage.removeItem(FNB_CART_KEY);
  //     // xóa temporary data of receiverInfoFromLS
  //     // sessionStorage.removeItem("receiverInfo");
  //     // sessionStorage.removeItem("initialReceiverInfo");
  //     props.clearProductCart();
  //     history.push(`/transactionmethods/${orderInfo.orderCode}`);
  //     // dispatch(actions.CLEAR_ORDER.request());
  //   }
  // }, [orderInfo?.orderCode]);

  const navigateToDeliveryInfoPage = () => {
    NavigationServices.navigate("/orderaddressfood", {
      prevPage: "cartmarket",
      initialReceiverInfo: userInfo || {
        name: "",
        phoneNumber: "",
        address: "",
        note: "",
      },
      // cartData: cart,
      // defaultDeliveryInfo,
    });
    // history.replace("/setdeliveryinfo", {
    //   cartData: cart,
    //   // defaultDeliveryInfo,
    // });
  };

  const renderInputType = useCallback(
    (delivery) => {
      const value = props.listMethodDelivery.filter((f) => f.isCheck);
      if (value?.[0]?.code === "home") {
        return (
          <div className="type-delivery-input">
            {delivery && delivery?.address ? (
              <div style={{ width: "100%" }} className="pr-2">
                <div className="address-wrapper">
                  <p
                    className="mb-0 _fs-15px _fw-600 text-1-line address"
                    style={{ color: "black" }}
                  >
                    {delivery?.address}
                  </p>
                  <div style={{ flexShrink: 0, marginLeft: "auto" }}>
                    <div
                      onClick={props.handleNavigateSelectMethodDelivery}
                      className="button-small shadow-none outlined ml-auto button__effect"
                    >
                      <img src={SVG.Pencil} alt="" style={{ marginRight: 5 }} />
                      <span>Thay đổi</span>
                    </div>
                  </div>
                </div>
                <p className="text-truncate mb-0 _fs-15px _fw-400 color-old_silver">
                  {delivery?.note}
                </p>
                <p className="text-truncate mb-0 _fs-14px _fw-400 color-chinese_black">
                  <span>{delivery?.userName}</span>
                  <span style={{ marginLeft: 6, marginRight: 6 }}> - </span>
                  <span>{formatPhone(delivery?.phoneNumber)}</span>
                </p>
                {props?.shippingInfo.error && <div className="line-gray" />}
                <span style={{ color: "red" }}>
                  {props?.shippingInfo.error}
                </span>
              </div>
            ) : (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                }}
                className="pr-2"
                onClick={props.handleNavigateSelectMethodDelivery}
              >
                <img
                  src={iconLocationFNB}
                  style={{
                    width: "17.6px",
                    height: "22px",
                    marginRight: "10px",
                  }}
                />
                <div
                  style={{ fontSize: "13px", color: "#000", fontWeight: "500" }}
                >
                  Chọn địa chỉ giao hàng
                </div>
              </div>
            )}
          </div>
        );
      }
      // if (value?.[0]?.code === "table") {
      //   return (
      //     <div
      //       className="type-delivery-input"
      //       onClick={() => props.setVisibleMethodDeliveryTable(true)}
      //     >
      //       <div className="type-delivery_left">
      //         <div className="icon-delivery">
      //           <img src={IMAGES.IconTable} />
      //         </div>
      //         <div className="text-table">Số bàn</div>
      //       </div>
      //       <div>{props.valueNumberTable || "Nhập số bàn"}</div>
      //     </div>
      //   );
      // }
      return;
    },
    [props.listMethodDelivery, props.shippingInfo]
  );

  const renderHeaderInfo = useCallback(
    (delivery) => {
      const renderTypeDelivery = () => {
        const value = props.listMethodDelivery.filter((f) => f.isCheck);
        if (value?.[0]?.code === "home") {
          return "Giao về";
        }
        return "Nhận tại quầy";
      };

      const renderIconType = () => {
        const value = props.listMethodDelivery.filter((f) => f.isCheck);
        if (value?.[0]?.code === "home") {
          return IMAGES.IconDeliveryHome;
        }
        return IMAGES.IconDeliveryShop;
      };

      const renderUISuggestToLogin = () => {
        return (
          <div
            className="order-page__delivery-info__wrapper bgr-white _p-12px rounded mb-2"
            style={{ cursor: "pointer" }}
          >
            <div
              onClick={navigateToLoginPage}
              className="d-flex align-items-center"
            >
              <img
                src={SVG.LocationSolidYellow15x20}
                alt="LocationSolidYellow15x20"
              />
              <span className="_ml-10px color-chinese_black _fs-13px _fw-400">
                Bạn chưa <span className="_fw-500">đăng nhập</span>! Vui lòng{" "}
                <span className="_fw-500">đăng ký/đăng nhập</span> để đặt hàng
                dễ dàng hơn.
              </span>
            </div>
          </div>
        );
      };

      if (!userInfo) {
        return renderUISuggestToLogin();
      }

      return (
        <div className="header-info_wrapper">
          <div
            className="type-delivery"
            onClick={() => props.setVisibleMethodDelivery(true)}
          >
            <div className="type-delivery_left">
              <div className="icon-delivery">
                <img src={renderIconType()} />
              </div>
              <div className="text-delivery">{renderTypeDelivery()}</div>
            </div>
            <div>
              <img src={arrowNext} alt="arrow-next" />
            </div>
          </div>
          {renderInputType(delivery)}
        </div>
      );
    },
    [
      props.listMethodDelivery,
      props.listProduct,
      props.valueNumberTable,
      props.shippingInfo,
    ]
  );

  const navigateToLoginPage = () => {
    NavigationServices.navigate("/login");
  };

  const getTotalItem = (listProduct) => {
    let tmpTotalItem = 0;

    // eslint-disable-next-line array-callback-return
    listProduct.map((ite) => {
      tmpTotalItem += ite.dataInfoProduct?.quantity || 0;
    });
    return tmpTotalItem;
  };

  const formatNumberD = (data) => {
    return `${formatNumber(data)}đ`;
  };

  const handleGoToCouponDetailPage = useCallback(
    ({ idCoupon, transactionCode }) =>
      () => {
        history.push(`/coupondetailv2?id=${idCoupon}`, {
          mode: COUPON_DETAIL_PAGE_MODES.APPLY,
          callbackUrl: `${window.location.pathname}${window.location.search}`,
          prevPage: `${window.location.pathname}${window.location.search}`,
          transactionCode,
        });
      },
    []
  );

  const handleCommitLoyaltyPointToUse = useCallback((point) => {
    setLoyaltyPointToUse(point);
  }, []);
  const handleToggleWillUseLoyaltyPoint = useCallback(() => {
    setWillUseLoyaltyPoint(!willUseLoyaltyPoint);
  }, [willUseLoyaltyPoint]);

  const goToStoreDetail = () => {
    if (props?.storeInfo.id) {
      // history.replace(
      //   `/storedetail?storeId=${cart?.storeId}&merchantId=${cart?.merchantId}`
      // );

      // NavigationServices.navigate(
      //   `/markethome?storeId=${props?.storeInfo.id}&merchantId=${props?.storeInfo?.merchantId}`
      // );
      NavigationServices.goBack();
    } else {
      NavigationServices.navigate("/");
    }
  };

  const finalPrice =
    getTotalPrice(props.listProduct) -
    props.discount +
    (props?.shippingInfo?.shippingFee || 0);

  const pointToUse = getLoyaltyPointToUse({
    finalPrice,
    remainingPoint: loyaltyPointToUse?.remainingPoint,
    exchangingToVndRate: loyaltyPointToUse?.exchangingToVndRate,
  });

  const moneyToPay = willUseLoyaltyPoint
    ? finalPrice - pointToUse * (loyaltyPointToUse?.exchangingToVndRate || 0)
    : finalPrice;

  const handleConfirmOrder = () => {
    props.handleConfirmOrder(
      willUseLoyaltyPoint,
      loyaltyPointToUse,
      pointToUse
    );
    // if (willUseLoyaltyPoint && loyaltyPointToUse && pointToUse) {
    //   props.setLoyaltyPointToUse(loyaltyPointToUse);
    //   props.setWillUseLoyaltyPoint(willUseLoyaltyPoint);
    //   props.setPointToUse(pointToUse);
    // }
  };

  return (
    <div className="order-page">
      <LoadingOverlay isLoading={props.isLoading} />

      <PageHeader
        title="Giỏ hàng"
        onNavigate={() => {
          NavigationServices.goBack();
          props.setNumberTable("");
        }}
      />

      {/* content */}
      <div className="order-wrapper">
        {renderHeaderInfo(props.deliveryInfo || deliveryInfo)}

        {/* {!hasDeliveryToHome(storeInfo?.data?.deliveryOptions?.iv) && (
          <div className="error-hint__wrapper">
            <span className="error-hint">
              Rất tiếc, cửa hàng không hỗ trợ giao về.
            </span>
          </div>
        )} */}

        <div className="section-bill">
          <div className="food-list-section__container">
            <FoodList
              productList={props.listProduct}
              handleRemoveFood={props.deleteProductCart}
              handleUpdateItem={props.updateProductCart} // todo: implement this func
              // soldOutItemList={soldOutItemList}
            />

            <button
              type="button"
              onClick={goToStoreDetail}
              className="button-add-more-food button_effect"
            >
              <p className="d-block mb-0 color-orange">Bạn có muốn thêm món</p>
              {/* <CIcon name="cilPlus" size="lg" style={{ color: COLORS.Orange }} /> */}
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.0619 5.06247H6.93704V0.937647C6.93704 0.419967 6.51732 0 5.9994 0C5.48148 0 5.06175 0.419727 5.06175 0.937647V5.06247H0.937406C0.419727 5.06247 0 5.4822 0 6.00012C0 6.51804 0.419486 6.93753 0.937406 6.93753H5.06223V11.0624C5.06223 11.58 5.48196 12 5.99988 12C6.5178 12 6.93753 11.5803 6.93753 11.0624V6.93753H11.0624C11.5803 6.93753 12 6.5178 12 6.00012C12 5.48244 11.5798 5.06247 11.0619 5.06247Z"
                  fill="#FA6400"
                />
              </svg>
            </button>
          </div>

          {/* Notes */}
          {userInfo && (
            <div>
              <div className="divider-line divider-line--expand" />

              <div
                className="note-for-store__container note-for-store__input__placeholder"
                style={{
                  marginInline: -12,
                  paddingInline: 12,
                  paddingBlock: 14,
                }}
              >
                <span className="icon-notepad" />

                <DebounceInput
                  // defaultValue={cart?.noteForRestaurant || ""}
                  type="text"
                  placeholder="Bạn có lưu ý gì với cửa hàng không?"
                  maxLength="150"
                  delayTime={100}
                  onCommitChange={props.setDescription}
                  className="_outline-none _border-none w-100"
                  showClearButton
                />
              </div>
            </div>
          )}

          <div className="divider-line divider-line--expand" />

          {/* Total price */}
          <div className="total-price">
            <span className="total-price__label">{`Thành tiền (${getTotalItem(
              props.listProduct
            )} phần)`}</span>
            <span>{formatAmount(getTotalPrice(props.listProduct))}</span>
          </div>

          {props?.shippingInfo?.shippingFee >= 0 && (
            <div className="shipping-content">
              <div />
              <div className="shipping-fee">
                <span className="shipping-fee_title">
                  {props?.shippingInfo?.provider?.toLowerCase() === "ahamove"
                    ? "Phí giao hàng dự kiến"
                    : "Phí giao hàng"}
                  {` (${formatDistance(
                    convertDistance(props?.shippingInfo?.distance)
                  )}km)`}
                </span>
                <span className="shipping-fee_title">
                  {formatAmount(props?.shippingInfo?.originalFee)}
                </span>
              </div>
              {(props?.shippingInfo?.shippingDiscount > 0 ||
                props?.shippingInfo?.shippingDiscountRange !== null) && (
                <div className="shipping-fee-note">
                  <div className="shipping-fee-note_title">
                    <span className="shipping-fee-note_title-text">
                      Giảm phí giao hàng
                    </span>
                    {props?.shippingInfo?.listSuggestShippingDiscount?.length >
                      0 && (
                      <div
                        hitSlop={{ top: 20, bottom: 20, left: 20, right: 20 }}
                        onClick={() => props.setVisibleShippingDiscount(true)}
                      >
                        {/* <Image
                          source={iconShippingInfo}
                          style={styles.iconShippingInfo}
                        /> */}
                        <img src={IMAGES.IconShippingInfo} />
                      </div>
                    )}
                  </div>
                  <span className="shipping-fee-note_title-value-text">
                    -
                    {formatAmount(
                      props?.shippingInfo?.shippingDiscount >
                        props?.shippingInfo?.originalFee
                        ? props?.shippingInfo?.originalFee
                        : props?.shippingInfo?.shippingDiscount
                    )}
                  </span>
                </div>
              )}
              {props?.shippingInfo?.shippingDiscount <
                props?.shippingInfo?.originalFee &&
              props?.shippingInfo?.shippingDiscountRange
                ?.extraPurchaseAmount ? (
                <div className="shipping-description">
                  <span className="shipping-description-text">
                    {`Mua thêm ${formatNumberD(
                      props?.shippingInfo?.shippingDiscountRange
                        ?.extraPurchaseAmount
                    )}  để được giảm phí ${formatNumberD(
                      props?.shippingInfo?.shippingDiscountRange
                        ?.shippingDiscount
                    )}`}
                  </span>
                </div>
              ) : null}
            </div>
          )}

          {userInfo && (
            <div>
              <div className="divider-line" />
              <AppliedCoupon
                isErrorCoupon={props.isErrorCoupon}
                appliedCoupon={props.voucher}
                handleRemovePromo={props.clearCoupon}
                discount={props.discount}
              />
              <div className="divider-line" />
            </div>
          )}

          {userInfo && loyaltyPointList?.length > 0 && (
            <LoyaltyPointSection
              isLoading={isLoading.GET_LOYALTY_POINT_LIST}
              pointList={loyaltyPointList}
              loyaltyPointToUse={loyaltyPointToUse}
              handleCommitLoyaltyPointToUse={handleCommitLoyaltyPointToUse}
              willUseLoyaltyPoint={willUseLoyaltyPoint}
              handleToggleWillUseLoyaltyPoint={handleToggleWillUseLoyaltyPoint}
              pointToUse={pointToUse}
            />
          )}

          {userInfo && (
            <div className="d-flex justify-content-between align-items-center mt-3 mb-1">
              <span className="_fw-600 _fs-14px color-black">
                Tổng thanh toán
              </span>
              <span className="_fw-700 _fs-14px color-orange">
                {formatAmount(moneyToPay)}
              </span>
            </div>
          )}
        </div>

        {userInfo && (
          <CouponList
            className="mr-n2"
            shippingInfo={props.shippingInfo}
            promotionList={props.listSuggestCoupon}
            appliedCoupon={props.voucher}
            handleToggleApplyPromo={props.checkAllowcationCoupon}
            handleGotoDetail={handleGoToCouponDetailPage}
            clearCoupon={props.clearCoupon}
          />
        )}
      </div>

      <div className="order-page__bottom">
        {userInfo && (
          <button
            type="button"
            onClick={handleConfirmOrder}
            className="button__effect btn-continue-payment"
            disabled={
              !userInfo
              // soldOutItemList?.length > 0 ||
              // isStillFetching ||
              // // someErrorsExisted // todo: nếu vẫn muốn cho bấm khi đã call api lỗi trước đó thì mở dòng này
              // // !hasValidReceiverInfo() ||
              // !hasDeliveryToHome(storeInfo?.data?.deliveryOptions?.iv)
            }
          >
            <span>Tiếp tục thanh toán</span>
          </button>
        )}

        {!userInfo && (
          <button
            type="button"
            onClick={navigateToLoginPage}
            className="button__effect btn-continue-payment"
          >
            <span>Đăng nhập để thanh toán</span>
          </button>
        )}
      </div>
      <AlertAction
        isOpen={props?.dataAlertAction?.isShow}
        content={props?.dataAlertAction?.content}
        title={props?.dataAlertAction?.title}
        titleAccept={props?.dataAlertAction?.titleAccept}
        titleCancel={props?.dataAlertAction?.titleCancel}
        handleClose={props?.setDataAlertAction}
        handleAccept={props?.handleAlertAction}
        dataItem={props?.dataAlertAction?.dataItem}
      />
      <Popup
        isOpen={props.isVisibleMethodDelivery}
        handleClose={props.setVisibleMethodDelivery}
        // showButtonClose={false}
      >
        <Delivery
          isShow={props.isVisibleMethodDelivery}
          setIsShowModal={props.setVisibleMethodDelivery}
          selectMethodDelivery={props.selectMethodDelivery}
          listMethodDelivery={props.listMethodDelivery}
        />
      </Popup>
      {userInfo && props?.shippingInfo?.listSuggestShippingDiscount && (
        <Popup
          isOpen={props.isVisibleShippingDiscount}
          handleClose={() => props.setVisibleShippingDiscount(false)}
          title={
            <div className="color-ucla_gold _fw-500 _fs-16px mb-2 text-center">
              Giảm phí giao hàng
            </div>
          }
        >
          <ShippingInfo
            shippingDiscountList={
              props?.shippingInfo?.listSuggestShippingDiscount
            }
            totalPrice={getTotalPrice(props.listProduct)}
          />
        </Popup>
      )}
      <Popup
        isOpen={props.isVisibleMethodDeliveryTable}
        handleClose={() => props.setVisibleMethodDeliveryTable(false)}
      >
        <InputTable
          setNumberTable={props.setNumberTable}
          valueNumberTable={props.valueNumberTable}
          setVisibleMethodDeliveryTable={props.setVisibleMethodDeliveryTable}
        />
      </Popup>
    </div>
  );
};

export default CartMarketView;

const hasDeliveryToHome = (deliveryOptions) => {
  return deliveryOptions?.find(
    (option) => String(option).toLowerCase() === "home"
  );
};
