import React from "react";

import InfiniteScroll from "react-infinite-scroll-component";

import SearchNotFound from "../../../MarketSearch/components/SearchNotFound";
import StoreItem from "../../../MarketHomeView/components/StoreList/components/StoreItem/StoreItem";

import "./searchResult.scss";

const SearchResult = (props) => {
  return (
    <div className="search-page">
      {props?.dataStalls?.length > 0 && (
        <InfiniteScroll
          dataLength={props?.dataStalls.length}
          next={props.onLoadMore}
          hasMore={props.isEndSearchMarket}
          // loader={<div class="loader" />}
          endMessage={<p style={{ textAlign: "center" }}>Đã hết cửa hàng</p>}
        >
          <div className="store-section">
            {props?.dataStalls?.length > 0 && (
              <div className="store__container product-section-child">
                <div className="store_container">
                  {props?.dataStalls.map((item) => (
                    <StoreItem
                      gotoProduct={() => props.gotoProduct(item)}
                      dataStore={item}
                      handleShareStore={props.handleShareStore}
                    />
                  ))}
                </div>
              </div>
            )}
          </div>
        </InfiniteScroll>
      )}
      {props.isShowLoading && (
        <div className="loading-wrapper">
          <div className="loader" />
        </div>
      )}

      {props.dataStalls?.length === 0 && props.dataStalls?.length >= 0 && (
        <>
          <SearchNotFound />
        </>
      )}
    </div>
  );
};

export default SearchResult;
