import APIUtils from 'utils/apiUtils';
import {END_POINTS} from '@shared/constants/apiUrl';

export function getListNewsfeedByLocationApi({
  coordinates = {},
  skip = 0,
  ...rest
}) {
  let params = {
    skip,
  };
  if (coordinates && coordinates.latitude) {
    params = {
      ...params,
      lat: coordinates.latitude,
      long: coordinates.longitude,
      ...rest,
    };
  }
  return APIUtils.get(END_POINTS.GET_PRODUCT_BASED_LOCATION_URL, {
    params,
  });
}

export function getListNewsfeedDynamicPageApi({url}) {
  return APIUtils.get(url);
}

export function getListNewsfeedStoreApi({coordinates = {}, skip = 0, ...rest}) {
  let params = {
    skip,
    ...rest,
  };
  if (coordinates && coordinates.latitude) {
    params = {
      ...params,
      lat: coordinates.latitude,
      long: coordinates.longitude,
      ...rest,
    };
  } else {
    params = {...params, ...rest};
  }
  return APIUtils.get(END_POINTS.GET_LIST_STORE_BY_ZONE_URL, {
    params,
  });
}
