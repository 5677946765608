import {GET_LIST_IMAGES_NEWS_CURRENT} from './constants';

export const getListNumberNewsCurrentTypeHandle = (
  newsPagesId,
  currentEditionId,
  onSuccess,
  onFailed,
) => {
  return {
    type: GET_LIST_IMAGES_NEWS_CURRENT.HANDLER,
    newsPagesId,
    currentEditionId,
    onSuccess,
    onFailed,
  };
};

export const getListNumberNewsCurrentTypeSuccess = payload => ({
  type: GET_LIST_IMAGES_NEWS_CURRENT.SUCCESS,
  payload,
});

export const getListNumberNewsCurrentTypeFailure = error => ({
  type: GET_LIST_IMAGES_NEWS_CURRENT.FAILURE,
  error,
});
