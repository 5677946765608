/* eslint-disable no-param-reassign */

import { addErrors, delErrors } from "utils/ErrorsGenerator";

import * as TYPE from "./constants";

const reducers = {
  //  * GET VOUCHER DETAIL
  [TYPE.VOUCHER_DETAIL.request]: (draft) => {
    return {
      ...draft,
      isLoading: {
        ...draft.isLoading,
        VOUCHER_DETAIL: true,
      },
    };
  },

  [TYPE.VOUCHER_DETAIL.success]: (draft, { payload }) => {
    return {
      ...draft,
      errors: delErrors(draft.errors, TYPE.VOUCHER_DETAIL.failure),
      voucherDetail: payload,
      isLoading: {
        ...draft.isLoading,
        VOUCHER_DETAIL: false,
      },
    };
  },

  [TYPE.VOUCHER_DETAIL.failure]: (draft, { payload }) => {
    draft.errors = addErrors(draft.errors, {
      type: TYPE.VOUCHER_DETAIL.failure,
      msg: payload,
      isLoading: {
        ...draft.isLoading,
        VOUCHER_DETAIL: false,
      },
    });
  },

  [TYPE.VOUCHER_INFOMATION.success]: (draft, { payload }) => {
    return {
      ...draft,
      errors: delErrors(draft.errors, TYPE.VOUCHER_INFOMATION.failure),
      voucherInfomation: payload,
    };
  },

  [TYPE.VOUCHER_INFOMATION.failure]: (draft, { payload }) => {
    draft.errors = addErrors(draft.errors, {
      type: TYPE.VOUCHER_INFOMATION.failure,
      msg: payload,
    });
  },
  [TYPE.VOUCHER_PRODUCT_INFORMATION.success]: (draft, { payload }) => {
    return {
      ...draft,
      errors: delErrors(draft.errors, TYPE.VOUCHER_PRODUCT_INFORMATION.failure),
      voucherProduct: payload,
    };
  },

  [TYPE.VOUCHER_PRODUCT_INFORMATION.failure]: (draft, { payload }) => {
    draft.errors = addErrors(draft.errors, {
      type: TYPE.VOUCHER_PRODUCT_INFORMATION.failure,
      msg: payload,
    });
  },

  //  * MARK USED
  [TYPE.CLICK_BY_MARK_USED.request]: (draft) => {
    return {
      ...draft,
      isLoading: {
        ...draft.isLoading,
        CLICK_BY_MARK_USED: true,
      },
    };
  },
  [TYPE.CLICK_BY_MARK_USED.success]: (draft, { payload }) => {
    const voucherId = payload.id;
    let updateChangeStatusMarkedUsed = draft.voucherDetail;
    if (voucherId) {
      const oldVoucher = draft.voucherDetail.find(
        (voucher) => voucher.id === voucherId
      );
      if (oldVoucher) {
        const newVoucher = {
          ...oldVoucher,
          status: "Used",
        };
        updateChangeStatusMarkedUsed = draft.voucherDetail.map((item) =>
          item.id === voucherId ? newVoucher : item
        );
      }
    }
    return {
      ...draft,
      voucherDetail: updateChangeStatusMarkedUsed,
      errors: delErrors(draft.errors, TYPE.CLICK_BY_MARK_USED.failure),
      isLoading: {
        ...draft.isLoading,
        CLICK_BY_MARK_USED: false,
      },
    };
  },
  [TYPE.CLICK_BY_MARK_USED.failure]: (draft, { payload }) => {
    return {
      ...draft,
      errors: addErrors(draft.errors, {
        type: TYPE.CLICK_BY_MARK_USED.failure,
        msg: payload,
      }),
      isLoading: {
        ...draft.isLoading,
        CLICK_BY_MARK_USED: false,
      },
    };
  },
};

export default reducers;
