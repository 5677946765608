export const getListBannerSelector = state => state.common.listBanner;

export const getListBannerDynamicPageSelector = state =>
  state.common.listBannerDynamicPage;

export const getShowRequestLogin = state => state.common.isShowRequestLogin;

export const getHistoryRoute = state => state.common.historyRoute;

export const getListShortcutSelector = state => state.common.listShortcut;
export const getListHotMerchantSelector = state => state.common.listHotMerchant;

export const getListCouponSuggest = state => state.common.listCouponSuggest;

export const getGoogleMapApiKeySelector = state => state.common.googleMapApiKey;
