import APIUtils from 'utils/apiUtils';

export function requestOTP({data}) {
  return APIUtils.post(global.apiLinks.RequestOTP, {
    body: data,
  });
}
export function sendOTP({data}) {
  return APIUtils.post(global.apiLinks.VerifyOTP, {
    body: data,
  });
}
export function getUserInfo() {
  return APIUtils.get(global.apiLinks.GetInfo, {method: 'GET'});
}
