import React from "react";
import { CouponIcon } from "assets/svg";
import moment from "moment";
import "./CouponItem.scss";

const CouponItem = (props) => {
  const { endDateTime, name, isAllocated, handleSave, handleOnClick, coupon } =
    props;

  return (
    <div className="suggested-coupon-item ">
      <i className="coupon-icon">
        <CouponIcon />
      </i>

      <div onClick={handleOnClick} className="content-wrapper">
        <div className="name text-on-1-line">{name}</div>
        {endDateTime && (
          <div>
            <span className="end-date-time">
              Hết hạn:{" "}
              <span>
                {moment.utc(endDateTime).local().format("DD/MM/YYYY")}
              </span>
            </span>
          </div>
        )}
      </div>

      <button
        type="button"
        onClick={() => handleSave(coupon)}
        disabled={isAllocated}
        className="button-save-coupon"
      >
        <span>{isAllocated ? "Đã lấy" : "Lấy mã"}</span>
      </button>
    </div>
  );
};

export default React.memo(CouponItem);
