export const formatNumber = (num) => {
  if (!num && typeof num !== 'number') {
    num = ''
  }
  return num
    .toString()
    .split(/(?=(?:\d{3})+(?:\.|$))/g)
    .join('.')
}

export const formatNumber2 = (num) => {
  if (!num && typeof num !== 'number') {
    num = ''
  }
  if (num < 0) {
    num = Math.abs(num)
    return (
      '-' +
      num
        .toString()
        .split(/(?=(?:\d{3})+(?:\.|$))/g)
        .join('.')
    )
  }
  return num
    .toString()
    .split(/(?=(?:\d{3})+(?:\.|$))/g)
    .join('.')
}
