import { callApi } from "helpers/callApi";
import { fork, put, select, takeLatest } from "redux-saga/effects";
// import {getSessionIdSelector} from '../account/selectors';
// import RNFetchBlob from "rn-fetch-blob";
import Alert from 'helpers/alert'
import {
  GET_UTOP_POINT_REQUEST,
  GET_USER_INFO_REQUEST,
  UPDATE_ACCOUNT_INFO_REQUEST,
  UPLOAD_AVATAR_REQUEST,
  UPDATE_USER_PROFILE_REQUEST,
  VERIFY_PIN_CODE_REQUEST,
  CHANGE_PIN_CODE_REQUEST,
} from "./constants";
import {
  getUtopPointSuccess,
  getUtopPointFailed,
  getUserInfoFailed,
  getUserInfoSuccess,
  updateAccountInfoFailed,
  updateUserProfileFailure,
  updateUserProfileSuccess,
  uploadAvatarSuccess,
} from "./actions";
import {
  getUTopCoinApi,
  getUserInfoApi,
  changePinCodeApi,
  verifyPinCodeApi,
  updateUserProfileApi,
} from "@shared/services/api/account";
import { getTokenUploadToAzure } from "@shared/services/api/azureUpload";
import { getUserInfoSelector } from "./selectors";
// import {getSessionIdSelector} from '../account/selectors';

export function* getUtopPointSaga(obj) {
  const { onSuccess, onFailed } = obj;
  try {
    const res = yield callApi(getUTopCoinApi);
    let balance = res?.data?.balance;
    yield put(getUtopPointSuccess(balance));
    // TODO: Lưu ý
    // ReactUtils.getStorage("utopInfo", function (asynData) {
    //   asynData.isCreatedPinCode = res?.data?.isCreatedPinCode;
    //   asynData.TokenAmount = balance;
    //   ReactUtils.saveStorage("utopInfo", asynData);
    // });
    console.log("UTOP, ", res);
    if (typeof onSuccess === "function") {
      onSuccess(res);
    }
  } catch (err) {
    //call back error
    if (typeof onFailed === "function") {
      onFailed(err);
    }
    const { status } = err || {};
    if (status >= 400) {
      Alert.alert("Utop", err?.data?.message);
    }
    yield put(getUtopPointFailed(err));
  }
}

export function* getUserInfoSaga(obj) {
  const { payload, onSuccess, onFailed } = obj;
  try {
    const res = yield callApi(getUserInfoApi);
    let userInfo = { ...res?.data };
    let isSuMiToMo = false;
    if (res?.data?.organization.length > 0) {
      userInfo.segment = res?.data?.organization;

      res?.data?.organization.forEach((element) => {
        if (element.toLowerCase() === "sumitomo") {
          isSuMiToMo = true;
        }
      });
      !isSuMiToMo
        ? (userInfo.isSumitomo = undefined)
        : (userInfo.isSumitomo = true);
    } else {
      userInfo.isSumitomo = undefined;
      userInfo.segment = ["utop"];
    }
    const currentUserInfo = yield select(getUserInfoSelector);
    userInfo = { ...currentUserInfo, ...userInfo };
    yield put(getUserInfoSuccess(userInfo));
    // TODO: Lưu ý
    // yield ReactUtils.getStorage("utopInfo", function (asynData) {
    //   ReactUtils.saveStorage("utopInfo", { ...asynData, ...userInfo });
    // });
    if (typeof onSuccess === "function") {
      let idBannersPersonal = "";
      if (res?.data?.banner?.length > 0) {
        for (let i = 0; i < res?.data?.banner?.length; i++) {
          if (i < res?.data?.banner?.length - 1) {
            idBannersPersonal += "'" + res?.data?.banner[i] + "',";
          } else {
            idBannersPersonal += "'" + res?.data?.banner[i] + "'";
          }
        }
      }
      onSuccess({ userId: idBannersPersonal });
    }
  } catch (err) {
    if (typeof onFailed === "function") {
      onFailed();
    }
    const { status } = err || {};
    if (status >= 400) {
      Alert.alert("Utop", err?.data?.message);
    }
    yield put(getUserInfoFailed(err));
  }
}

export function* updateAccountInfoSaga(obj) {
  const { payload } = obj;
  try {
    const userInfoToUpdate = { ...payload.userInfo, ...payload };
    delete userInfoToUpdate.userInfo;
    // TODO: Lưu ý
    // ReactUtils.getStorage("utopInfo", function (asynData) {
    //   ReactUtils.saveStorage("utopInfo", {
    //     ...asynData,
    //     ...userInfoToUpdate,
    //   });
    // });
  } catch (error) {
    const { status } = error || {};
    if (status >= 400) {
      Alert.alert("Utop", error?.data?.message);
    }
    yield put(updateAccountInfoFailed(error));
  }
}

export function* updateUserProfileSaga(obj) {
  const { payload, onSuccess, onError } = obj;
  try {
    const res = yield callApi(updateUserProfileApi, payload);
    onSuccess(res);
    yield put(updateUserProfileSuccess(res));
  } catch (error) {
    onError(error);
    yield put(updateUserProfileFailure(error));
  }
}

export function* uploadAvatarSaga(obj) {
  const { payload, onSuccess, onError } = obj;
  const userMemberCode = yield select((state) => state.account.memberCode);
  try {
    const tokenUploadToAzure = yield callApi(getTokenUploadToAzure, {});
    const url = `${tokenUploadToAzure.data.blobEndpoint}/${userMemberCode}.jpg`;
    // yield RNFetchBlob.fetch(
    //   "PUT",
    //   `${url}?${tokenUploadToAzure.data.sasToken}`,
    //   {
    //     "x-ms-blob-type": "BlockBlob",
    //     "content-type": "image/jpeg",
    //     "x-ms-blob-content-type": "image/jpeg",
    //   },
    //   RNFetchBlob.wrap(payload.url)
    // )
    //   .then((value) => {
    //     console.log("!@3123", value);
    //   })
    //   .catch((error) => {
    //     console.log("!2321312312", error);
    //   });
    // yield put(
    //   uploadAvatarSuccess({
    //     urlImage: `${tokenUploadToAzure.data.cdnFormatEndpoint}/${userMemberCode}.jpg`,
    //   })
    // );
    onSuccess({
      urlImage: `${tokenUploadToAzure.data.cdnFormatEndpoint}/${userMemberCode}.jpg`,
    });
  } catch (error) {
    onError(error);
    console.log("errorerror", error);
  }
}

export function* verifyPinCodeSaga(obj) {
  const { payload, onSuccess, onFailed } = obj;
  try {
    const res = yield callApi(verifyPinCodeApi, payload);
    if (res.status === 200 || res.status === 204) {
      // yield()
      if (typeof onSuccess === "function") {
        onSuccess(res);
      }
    }
  } catch (error) {
    if (typeof onFailed === "function") {
      onFailed(error);
    }
  }
}

export function* changePinCodeSaga(obj) {
  const { payload, onSuccess, onFailed } = obj;
  try {
    let { CurrentPinCode, NewPinCode } = payload;
    const res = yield callApi(changePinCodeApi, { CurrentPinCode, NewPinCode });
    if (res.status === 200 || res.status === 204) {
      onSuccess(res);
    } else {
      if (typeof onFailed === "function") {
        onFailed("");
      }
    }
  } catch (error) {
    if (typeof onFailed === "function") {
      onFailed(error);
    }
  }
}

function* watchUser() {
  yield takeLatest(GET_UTOP_POINT_REQUEST, getUtopPointSaga);
  yield takeLatest(GET_USER_INFO_REQUEST, getUserInfoSaga);
  yield takeLatest(UPDATE_ACCOUNT_INFO_REQUEST, updateAccountInfoSaga);
  yield takeLatest(UPDATE_USER_PROFILE_REQUEST, updateUserProfileSaga);
  yield takeLatest(UPLOAD_AVATAR_REQUEST, uploadAvatarSaga);
  yield takeLatest(VERIFY_PIN_CODE_REQUEST, verifyPinCodeSaga);
  yield takeLatest(CHANGE_PIN_CODE_REQUEST, changePinCodeSaga);
}

export default function* rootChild() {
  yield fork(watchUser);
}
