import { genActions } from "utils/actionGenerator";
import * as TYPE from "./constants";

const actions = {
  ORDER_HISTORY_DETAIL: genActions(TYPE.ORDER_HISTORY_DETAIL),
  INFOMATION_STORE: genActions(TYPE.INFOMATION_STORE),
  CANCEL_FNB: genActions(TYPE.CANCEL_FNB),
  GET_PAYMENT_METHODS: genActions(TYPE.GET_PAYMENT_METHODS),
};

export default actions;
