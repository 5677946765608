import { call, put, takeLatest, fork } from "redux-saga/effects";
import actions from "./actions";
import * as TYPE from "./constants";
import api from "../api/api";

const sagas = {
  *[TYPE.VOUCHER_DETAIL_BY_USER.request](action) {
    try {
      const response = yield call(api[TYPE.VOUCHER_DETAIL_BY_USER.request], action.payload);
      if (response && response.status === 200) {
        yield put(actions.VOUCHER_DETAIL_BY_USER.success(response.data));
      }
    } catch (error) {
      yield put(actions.VOUCHER_DETAIL_BY_USER.failure(error?.data));
    }
  },
};

function* watchedSaga() {
  yield takeLatest(
    TYPE.VOUCHER_DETAIL_BY_USER.request,
    sagas[TYPE.VOUCHER_DETAIL_BY_USER.request]
  );
}

export default function* rootChild() {
  yield fork(watchedSaga);
}
