/* eslint-disable no-param-reassign */
import { addErrors, delErrors } from "utils/ErrorsGenerator";
import * as TYPE from "./constants";

const reducers = {
  [TYPE.VOUCHER_DETAIL_BY_USER.success]: (draft, { payload }) => {
    return {
      ...draft,
      errors: delErrors(draft.errors, TYPE.VOUCHER_DETAIL_BY_USER.failure),
      voucherDetailByUser: payload,
    };
  },
  [TYPE.VOUCHER_DETAIL_BY_USER.failure]: (draft, { payload }) => {
    draft.errors = addErrors(draft.errors, {
      type: TYPE.VOUCHER_DETAIL_BY_USER.failure,
      msg: payload,
    });
  },
  [TYPE.ALLOW_MARK_REFUND.request]: (draft) => {
    draft.isLoading = true
  },
  [TYPE.ALLOW_MARK_REFUND.success]: (draft) => {
    draft.errors = delErrors(draft.errors, TYPE.ALLOW_MARK_REFUND.failure);
    draft.markRefund = 200;
    draft.isLoading = false

  },
  [TYPE.ALLOW_MARK_REFUND.failure]: (draft, { payload }) => {
    draft.errors = addErrors(draft.errors, {
      type: TYPE.ALLOW_MARK_REFUND.failure,
    });
    draft.markRefund = payload;
    draft.isLoading = false

  },
};

export default reducers;
