import { genActionTypes } from "utils/actionGenerator";

export const TYPE = "UserInfo";

export const SET_LOADING = `${TYPE}_SET_LOADING`;

export const UPLOAD_AVATAR = genActionTypes(`${TYPE}_UPLOAD_AVATAR`);
export const UPDATE_USER_INFO = genActionTypes(`${TYPE}_UPDATE_USER_INFO`);
export const GET_UTOP_INFOMATION = genActionTypes(`${TYPE}_GET_UTOP_INFOMATION`);

