/* eslint-disable no-unused-vars */
import React, { useCallback, useMemo, useState, useEffect } from "react";
import { Tab, Tabs, TabList } from "react-tabs";
import "react-tabs/style/react-tabs.scss";
import "./TabListProduct.scss";
import InfiniteScroll from "react-infinite-scroll-component";
import classNames from "classnames";
import FoodItem from "../../../MarketHomeView/components/FoodItem";

const TabListProduct = (props) => {
  const [currentTab, setCurrentTab] = useState({
    categoryId: "all",
    categoryName: "Tất cả",
  });
  const [dataLength, setDataLength] = useState(1);
  const [isLoadEndAll, setLoadEndAll] = useState(false);

  useEffect(() => {
    if (currentTab.categoryId === "all" && props.isLoading) {
      const arrayNewList = Object.entries(props?.isLoading);
      arrayNewList.shift();
      const checkEndList = arrayNewList.filter((f) => !f[1]?.endList);
      if (
        checkEndList?.length === 0 &&
        arrayNewList.length === props.categories.length - 1 &&
        Object.keys(props.isLoading).length > 1
      ) {
        setLoadEndAll(true);
      }
    }
  }, [props.isLoading]);

  const parseJsonToArray = (masterData, categories) => {
    const arrMaster = [];
    if (Object.keys(masterData)?.length > 0 && categories?.length > 0) {
      for (let i = 0; i < categories.length; i++) {
        const elmCategory = categories[i];
        Object.keys(masterData).forEach((element) => {
          if (elmCategory.categoryId === element) {
            if (masterData[element]?.data?.length > 0) {
              arrMaster.push({
                ...masterData[element],
                name: elmCategory?.categoryName?.vi,
                categoryId: elmCategory.categoryId,
                index: i,
              });
            }
          }
        });
        // if (elmCategory.categoryId === currentTab.categoryId) {
        //   return arrMaster;
        // }
      }
    }
    return arrMaster;
  };

  const calDiscount = (listPrice, price) => {
    let percentDiscount = 0;
    if (!!listPrice && !!price) {
      percentDiscount = 100 - Math.round((price / listPrice) * 100);
      if (percentDiscount === 100) {
        percentDiscount -= 1;
      }
    }
    return percentDiscount;
  };

  const renderListProduct = useCallback(() => {
    if (Object.keys(props?.data).length > 0 && props?.categories?.length > 0) {
      const array = parseJsonToArray(props.data, props.categories);
      const currentArray =
        currentTab && currentTab.categoryId !== "all"
          ? array.filter((item) => item.categoryId === currentTab.categoryId)
          : array;
      return currentArray.map((item, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div className="product-list_wrapper" key={index}>
          {currentTab && currentTab.categoryId === "all" && (
            <div className={classNames("product-list_header")}>
              <div className="label text-1-line">{item?.name}</div>
            </div>
          )}
          <div
            className="product-section"
            style={{
              paddingTop:
                currentTab && currentTab.categoryId !== "all" ? "10px" : 0,
            }}
          >
            {item?.data.length > 0 && (
              <div className="best-seller__container product-section-child">
                <div className="products__container">
                  {item?.data.map((product) => (
                    <FoodItem
                      key={product.id}
                      id={product.id}
                      code={product?.code}
                      // type={FOOD_ITEM_TYPES.DETAILS} // must have
                      title={product?.name?.vi}
                      imgSrc={product?.image}
                      description={product?.description?.default?.vi}
                      shortDescription={product?.description?.short?.vi}
                      originalPrice={product?.price?.list}
                      currentPrice={product?.price?.default}
                      category={product?.category}
                      dataCart={props.dataCart}
                      data={product}
                      isStoreClosed={props.isCloseStore}
                      isSelling={product.isSelling}
                      isOutOfStock={product.isOutOfStock}
                      // navigateToSmartOrderMenu={navigateToSmartOrderMenu}
                      handleOpenMenuOrder={() =>
                        props.handleOpenMenuOrder(product)
                      }
                      saleTagUrl={product?.saleTagUrl}
                      percentDiscount={calDiscount(
                        product?.price?.list,
                        product?.price?.default
                      )}
                    />
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      ));
    }
  }, [props?.data, props?.categories, currentTab.categoryId, props.dataCart]);

  const onChangeTab = (tab) => {
    setCurrentTab(tab);
    props?.onPageSelected(tab.categoryId, null, [], false);
  };

  const _onEndReached = useCallback(() => {
    setDataLength((prev) => prev + 1);
    if (props?.isEndListAll) {
      return;
    }
    if (!props?.isLoading?.[currentTab.categoryId]?.isLoading) {
      props?.onLoadMore(currentTab.categoryId);
    }
  }, [props?.isEndListAll, props?.isLoading]);

  const renderLoading = useMemo(() => {
    if (currentTab.categoryId === "all") {
      if (
        props?.isLoading?.[currentTab.categoryId]?.isLoading &&
        !props.isEndListAll
      ) {
        return (
          <div className="loader-wrapper">
            <div className="loader" />
          </div>
        );
      }
    } else if (props?.isLoading?.[currentTab.categoryId]?.isLoading) {
      return (
        <div className="loader-wrapper">
          <div className="loader" />
        </div>
      );
    }
  }, [props?.isLoading, props.isEndListAll]);

  return (
    <Tabs className="tab-wrapper">
      <TabList className="tab-list-wrapper">
        {props.categories && props.categories && props?.categories.length > 2
          ? props?.categories.map((category) => (
              <Tab onClick={() => onChangeTab(category)}>
                {category?.categoryName.vi &&
                category?.categoryName.vi.length > 20
                  ? `${
                      category?.categoryName.vi.substring(0, 20) ||
                      category?.categoryName.substring(0, 20)
                    }...`
                  : category?.categoryName.vi || category?.categoryName}
                {/* {category?.categoryName.vi || category?.categoryName} */}
              </Tab>
            ))
          : props.categories &&
            props.categories &&
            props?.categories.length > 0 &&
            props?.categories
              .slice(1, 2)
              .map((category) => (
                <Tab onClick={() => onChangeTab(category)}>
                  {category?.categoryName.vi &&
                  category?.categoryName.vi.length > 20
                    ? `${
                        category?.categoryName.vi.substring(0, 20) ||
                        category?.categoryName.substring(0, 20)
                      }...`
                    : category?.categoryName.vi || category?.categoryName}
                </Tab>
              ))}
      </TabList>
      {props?.data && (
        <InfiniteScroll
          dataLength={dataLength}
          next={_onEndReached}
          hasMore={
            currentTab.categoryId === "all"
              ? !isLoadEndAll
              : !props.isLoading[currentTab.categoryId]?.endList
          }
          // loader={<div className="loader" />}
          endMessage={<p style={{ textAlign: "center" }}>Đã hết sản phẩm</p>}
        >
          {renderListProduct()}
        </InfiniteScroll>
      )}
      {renderLoading}
    </Tabs>
  );
};

export default TabListProduct;
