import request from "services/request";

import * as TYPE from "../store/constants";

const api = {
  [TYPE.GET_USER_ADDRESS_BOOKS.request]: async () => {
    return request(`/personal/getuseraddressbook`, "GET");
  },

  [TYPE.DELETE_AN_USER_ADDRESS_BOOK.request]: async (idAddressBook) => {
    return request(`/personal/deleteaddressofuseraddressbook`, "POST", {
      id: idAddressBook,
    });
  },

  [TYPE.SET_DEFAULT_TO_AN_USER_ADDRESS_BOOK.request]: async (idAddressBook) => {
    return request(`/personal/setdefaultofuseraddressbook`, "POST", {
      id: idAddressBook,
    });
  },
};

export default api;
