import { LOGOUT_REQUEST } from "@shared/redux/account/constants";
import {
  GET_LIST_NEWSFEED_DYNAMIC_SUCCESS,
  GET_LIST_PRODUCT_BY_LOCATION_SUCCESS,
  GET_LIST_NEWSFEED_STORE_SUCCESS,
} from "./constants";

const initialState = {
  listNewsfeedByLocation: { data: [] },
  listNewsfeedDynamicPage: { data: [] },
};

const product = function (state = initialState, action) {
  switch (action.type) {
    case GET_LIST_PRODUCT_BY_LOCATION_SUCCESS: {
      return {
        ...state,
        listNewsfeedByLocation: {
          ...state.listNewsfeedByLocation,
          total: action.products ? action.products.total : 0,
          skip: action.products ? action.products.skip : 0,
          data:
            action.products && action.products.skip === 0
              ? action.products.data
              : [...state.listNewsfeedByLocation.data, ...action.products.data],
        },
      };
    }
    case GET_LIST_NEWSFEED_DYNAMIC_SUCCESS: {
      return {
        ...state,
        listNewsfeedDynamicPage: {
          ...state.listNewsfeedDynamicPage,
          total: action.products ? action.products.total : 0,
          skip: action.products ? action.products.skip : 0,
          data:
            action.products && action.products.skip === 0
              ? action.products.data
              : [
                  ...state.listNewsfeedDynamicPage.data,
                  ...action.products.data,
                ],
        },
      };
    }
    case GET_LIST_NEWSFEED_STORE_SUCCESS: {
      return {
        ...state,
        listNewsfeedStore: {
          ...state.listNewsfeedStore,
          total: action.products ? action.products.total : 0,
          skip: action.products ? action.products.skip : 0,
          data:
            action.products && action.products.skip === 0
              ? action.products.data
              : [...state.listNewsfeedStore.data, ...action.products.data],
        },
      };
    }
    case LOGOUT_REQUEST: {
      return {
        ...initialState,
      };
    }
    default:
      return state;
  }
};

export default product;
