import {END_POINTS} from '@shared/constants/apiUrl';
import {APIUtils} from 'utils';

export function getDynamicPageApi(screenId) {
  const list = {
    id: 'string',
    data: {
      Name: {
        iv: 'string',
      },
      Description: {
        iv: 'string',
      },
      Title: {
        iv: 'string',
      },
      Widget: {
        iv: [
          {
            WidgetType: 'NewsFeed',
            Source:
              'https://api.utopapp.net/cms/core/newsfeedv2?hasPromotion=true',
            Attributes: [
              {
                id: '1',
                title: 'Tất cả',
                skip: 0,
                source:
                  'https://api.utopapp.net/cms/core/newsfeedv2?hasPromotion=true',
              },
              {
                id: '2',
                title: 'Khuyến mãi',
                source:
                  'https://api.utopapp.net/cms/core/searchfeed?businesstype=Drink',
              },
              {
                id: '3',
                title: 'Thức uống',
                source:
                  'https://api.utopapp.net/cms/core/searchfeed?haspromotion=true',
              },
              {
                id: '4',
                title: 'Nhà hàng',
                source:
                  'https://api.utopapp.net/cms/core/newsfeedv2?hasPromotion=true',
              },
              {
                id: '5',
                title: 'Món nướng',
                source:
                  'https://api.utopapp.net/cms/core/newsfeedv2?hasPromotion=true',
              },
              {
                id: '6',
                title: 'Làm đẹp',
                source:
                  'https://api.utopapp.net/cms/core/newsfeedv2?hasPromotion=true',
              },
              {
                id: '7',
                title: 'Mua sắm',
                source:
                  'https://api.utopapp.net/cms/core/newsfeedv2?hasPromotion=true',
              },
              {
                id: '8',
                title: 'Cafe',
                source:
                  'https://api.utopapp.net/cms/core/newsfeedv2?hasPromotion=true',
              },
            ],
            isNailed: true,
          },
        ],
      },
    },
    dataDraft: {
      Name: {
        iv: 'string',
      },
      Description: {
        iv: 'string',
      },
      Title: {
        iv: 'string',
      },
      Widget: {
        iv: [
          {
            WidgetType: 'string',
            Source: 'string',
          },
        ],
      },
    },
    version: 0,
    created: '2020-12-04T08:48:26Z',
    createdBy: 'string',
    lastModified: '2020-12-04T08:48:26Z',
    lastModifiedBy: 'string',
    status: 'string',
  };
  const banner = {
    id: 'string',
    data: {
      Name: {
        iv: 'string',
      },
      Description: {
        iv: 'string',
      },
      Title: {
        iv: 'string',
      },
      Widget: {
        iv: [
          {
            WidgetType: 'Banner',
            Source: 'string',
          },
        ],
      },
    },
    dataDraft: {
      Name: {
        iv: 'string',
      },
      Description: {
        iv: 'string',
      },
      Title: {
        iv: 'string',
      },
      Widget: {
        iv: [
          {
            WidgetType: 'string',
            Source: 'string',
          },
        ],
      },
    },
    version: 0,
    created: '2020-12-04T08:48:26Z',
    createdBy: 'string',
    lastModified: '2020-12-04T08:48:26Z',
    lastModifiedBy: 'string',
    status: 'string',
  };

  const video = {
    id: 'string',
    data: {
      Name: {
        iv: 'string',
      },
      Description: {
        iv: 'string',
      },
      Title: {
        iv: 'string',
      },
      Widget: {
        iv: [
          {
            WidgetType: 'Video',
            Source: 'string',
          },
        ],
      },
    },
    dataDraft: {
      Name: {
        iv: 'string',
      },
      Description: {
        iv: 'string',
      },
      Title: {
        iv: 'string',
      },
      Widget: {
        iv: [
          {
            WidgetType: 'string',
            Source: 'string',
          },
        ],
      },
    },
    version: 0,
    created: '2020-12-04T08:48:26Z',
    createdBy: 'string',
    lastModified: '2020-12-04T08:48:26Z',
    lastModifiedBy: 'string',
    status: 'string',
  };
  const modal = {
    imageUrl:
      'https://www.adobe.com/content/dam/cc/us/en/products/creativecloud/stock/stock-riverflow1-720x522.jpg.img.jpg',
    schema:
      'utopakc://actions/opencat/?merchantBusinessType=Drink&name=Thức uống',
  };

  const listCategory = {
    id: 'string',
    data: {
      Name: {
        iv: 'string',
      },
      Description: {
        iv: 'string',
      },
      Title: {
        iv: 'string',
      },
      Widget: {
        iv: [
          {
            WidgetType: 'Category',
            Source: 'string',
            attribute: [
              {
                id: '1',
                title: 'Tất cả',
                skip: 0,
                source:
                  'https://api.utopapp.net/cms/core/newsfeedv2?hasPromotion=true',
              },
              {
                id: '2',
                title: 'Khuyến mãi',
                source:
                  'https://api.utopapp.net/cms/core/searchfeed?businesstype=Drink',
              },
              {
                id: '3',
                title: 'Thức uống',
                source:
                  'https://api.utopapp.net/cms/core/searchfeed?haspromotion=true',
              },
              {
                id: '4',
                title: 'Nhà hàng',
                source:
                  'https://api.utopapp.net/cms/core/newsfeedv2?hasPromotion=true',
              },
              {
                id: '5',
                title: 'Món nướng',
                source:
                  'https://api.utopapp.net/cms/core/newsfeedv2?hasPromotion=true',
              },
              {
                id: '6',
                title: 'Làm đẹp',
                source:
                  'https://api.utopapp.net/cms/core/newsfeedv2?hasPromotion=true',
              },
              {
                id: '7',
                title: 'Mua sắm',
                source:
                  'https://api.utopapp.net/cms/core/newsfeedv2?hasPromotion=true',
              },
              {
                id: '8',
                title: 'Cafe',
                source:
                  'https://api.utopapp.net/cms/core/newsfeedv2?hasPromotion=true',
              },
            ],
          },
        ],
      },
    },
    dataDraft: {
      Name: {
        iv: 'string',
      },
      Description: {
        iv: 'string',
      },
      Title: {
        iv: 'string',
      },
      Widget: {
        iv: [
          {
            WidgetType: 'string',
            Source: 'string',
          },
        ],
      },
    },
    version: 0,
    created: '2020-12-04T08:48:26Z',
    createdBy: 'string',
    lastModified: '2020-12-04T08:48:26Z',
    lastModifiedBy: 'string',
    status: 'string',
  };

  const shortcut = {
    id: 'string',
    data: {
      Name: {
        iv: 'string',
      },
      Description: {
        iv: 'string',
      },
      Title: {
        iv: 'string',
      },
      Widget: {
        iv: [
          {
            WidgetType: 'Shortcut',
            Content: '<i>text</i>',
          },
        ],
      },
    },
    dataDraft: {
      Name: {
        iv: 'string',
      },
      Description: {
        iv: 'string',
      },
      Title: {
        iv: 'string',
      },
      Widget: {
        iv: [
          {
            WidgetType: 'string',
            Source: 'string',
          },
        ],
      },
    },
    version: 0,
    created: '2020-12-04T08:48:26Z',
    createdBy: 'string',
    lastModified: '2020-12-04T08:48:26Z',
    lastModifiedBy: 'string',
    status: 'string',
  };

  console.log('RECEIVE', screenId);
  switch (screenId) {
    case 'Khuyến mãi':
      return {items: [shortcut, video, banner, list]};
    case 'Restaurant':
      return {items: [shortcut, video, list]};
    case 'Thức uống':
      return {items: [video, list]};
    case 'modal':
      return modal;
    default:
      return list;
  }
  //   if (screenId === 'Khuyến mãi') {
  //     return list;
  //   } else {
  //     return banner;
  //   }
  //   return APIUtils.get(END_POINTS.GET_DYNAMIC_PAGE_URL, {body: {screenId}});
}

export const getListBannerDynamicPage = payload => {
  return APIUtils.get(payload);
};
