import { genActions } from "utils/actionGenerator";
import * as TYPE from "./constants";

const actions = {
  // SET_LOADING: (payload) => ({ type: TYPE.SET_LOADING, payload }),
  GET_USER_DEFAULT_ADDRESS_BOOK: genActions(TYPE.GET_USER_DEFAULT_ADDRESS_BOOK),
  GET_SUGGESTED_COUPON_LIST: genActions(TYPE.GET_SUGGESTED_COUPON_LIST),
  GET_SHIPPING_INFO: genActions(TYPE.GET_SHIPPING_INFO),
  GET_SUGGESTED_DELIVERY_DISCOUNT_LIST: genActions(
    TYPE.GET_SUGGESTED_DELIVERY_DISCOUNT_LIST
  ),
  CALC_COUPON_DISCOUNT: genActions(TYPE.CALC_COUPON_DISCOUNT),
  GET_AVAILABLE_COUPONS_BY_CODE: genActions(TYPE.GET_AVAILABLE_COUPONS_BY_CODE),
  GET_AVAILABLE_COUPONS_OF_ALLOCATED_COUPON_TICKET: genActions(
    TYPE.GET_AVAILABLE_COUPONS_OF_ALLOCATED_COUPON_TICKET
  ),
  CALC_COUPON_DISCOUNT_V2: genActions(TYPE.CALC_COUPON_DISCOUNT_V2),
  CREATE_ORDER: genActions(TYPE.CREATE_ORDER),
  GET_PRODUCT_LIST: genActions(TYPE.GET_PRODUCT_LIST),
  GET_OUT_OF_STOCK_PRODUCT_LIST: genActions(TYPE.GET_OUT_OF_STOCK_PRODUCT_LIST),
  GET_STORE_INFO: genActions(TYPE.GET_STORE_INFO),
  GET_LOYALTY_POINT_LIST: genActions(TYPE.GET_LOYALTY_POINT_LIST),
  CLEAR_ORDER: genActions(TYPE.CLEAR_ORDER_INFO),
};

export default actions;
