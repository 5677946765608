import { genActions } from "utils/actionGenerator";
import * as TYPE from "./constants";

const actions = {
  GET_VOUCHER_INFO: genActions(TYPE.GET_VOUCHER_INFO),
  GET_SUGGESTED_COUPON_LIST: genActions(TYPE.GET_SUGGESTED_COUPON_LIST),
  SAVE_SUGGESTED_COUPON: genActions(TYPE.SAVE_SUGGESTED_COUPON),
  GET_SHORT_LINK_TO_SHARE: genActions(TYPE.GET_SHORT_LINK_TO_SHARE),
  CHECK_STOCK_OF_PRODUCT: genActions(TYPE.CHECK_STOCK_OF_PRODUCT),

  // GET_DETAIL_VOUCHER_MORE: genActions(TYPE.GET_DETAIL_VOUCHER_MORE),
};

export default actions;
