import APIUtils from 'utils/apiUtils';
import {END_POINTS} from '@shared/constants/apiUrl';
import AppConfigs from 'configs/env';

export function getTransactionByUser({skip, take}) {
  let requestData = '?skip=' + skip + '&take=' + take;

  return APIUtils.get(END_POINTS.GET_HISTORY_URL + requestData, {
    headers: {
      'Api-version': AppConfigs.transactionApiVersion,
    },
  });
}
export function getTransactionByCode(code) {
  return APIUtils.get(END_POINTS.GET_TRANSACTION_BY_CODE + code, {
    headers: {
      'Api-version': AppConfigs.transactionApiVersion,
    },
  });
}
