import { callApi } from "helpers/callApi";
import { fork, put, takeEvery } from "redux-saga/effects";
import { getEditionRenewApi } from "@shared/services/api/editionRenew";
import {
  getEditionRenewTypeFailure,
  getEditionRenewTypeSuccess,
} from "./actions";
import { GET_EDITION_RENEW } from "./constants";

export function* getEditionRenewSaga(action) {
  const { onSuccess, editionId, newspaperId, onFailed } = action;
  try {
    const res = yield callApi(getEditionRenewApi, {
      editionId,
      newspaperId,
    });
    yield put(getEditionRenewTypeSuccess());
    if (res.data.isSuccess) {
      onSuccess?.(res?.data);
    } else {
      onFailed?.();
    }
  } catch (error) {
    onFailed?.();
    yield put(getEditionRenewTypeFailure(error));
  }
}

function* watchEditionRenew() {
  yield takeEvery(GET_EDITION_RENEW.HANDLER, getEditionRenewSaga);
}

export default function* rootChild() {
  yield fork(watchEditionRenew);
}
