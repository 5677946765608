/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable no-param-reassign */
import axios from "axios";
import { onAuthStateChanged } from "firebase/auth";
import { authFirebase } from "configs/firebase";

export const getFirebaseToken = async () => {
  const { currentUser } = authFirebase;
  if (currentUser) return currentUser.getIdToken(false);

  // logged in but current user is not fetched --> wait (5s)
  return new Promise((resolve, reject) => {
    const timer = setTimeout(() => {
      reject(null);
    }, 5000);

    const unregisterAuthObserver = onAuthStateChanged(
      authFirebase,
      async (user) => {
        if (!user) return reject(null);

        const token = await user.getIdToken(false);
        resolve(token);

        clearTimeout(timer);
        unregisterAuthObserver();
      }
    );
  });
};

export const defaultHeaders = {
  "content-type": "application/json",
  "Access-Control-Allow-Credentials": true,
  "Ocp-Apim-Subscription-Key": process.env.REACT_APP_OCP_APIM_SUBSCRIPTION_KEY,
  "Api-version": process.env.REACT_APP_API_VERSION, // ! phải có, nếu không sẽ ảnh hưởng, ít nhất là trang DS đơn hàng (/transactionhistory)
};

const auth = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: defaultHeaders,
  crossDomain: true,
});

const noAuth = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    "content-type": "application/json",
    "Access-Control-Allow-Credentials": true,
    "Ocp-Apim-Subscription-Key":
      process.env.REACT_APP_OCP_APIM_SUBSCRIPTION_KEY,
    "Api-version": process.env.REACT_APP_API_VERSION, // ! phải có, nếu không sẽ ảnh hưởng, ít nhất là trang DS đơn hàng (/transactionhistory)
    // "X-Client": "Web",
    //
  },
  crossDomain: true,
});

// update token
auth.interceptors.request.use(async (configAuth) => {
  const token = await getFirebaseToken();

  if (token) {
    configAuth.headers.Authorization = `Bearer ${token}`;
  }

  return configAuth;
});

const request = (path, method, data, headers) => {
  const promise = new Promise((resolve, reject) => {
    let myData = data;
    if (data && data.file) {
      const formData = new FormData();
      formData.append("imageFile", data.file);
      formData.append("type", "image/jpeg, image/jpg, image/png");
      myData = formData;
    }
    auth(path, {
      method,
      headers,
      data: myData,
    })
      .then((response) => {
        if (!response) {
          reject("No data from response");
          return;
        }
        resolve(response);
      })
      .catch((err) => {
        reject(err?.response ? err?.response : err);
      });
  });
  return promise;
};

export const requestWithoutToken = (path, method, data, headers) => {
  const promise = new Promise((resolve, reject) => {
    let myData = data;
    if (data && data.file) {
      const formData = new FormData();
      formData.append("imageFile", data.file);
      formData.append("type", "image/jpeg, image/jpg, image/png");
      myData = formData;
    }
    noAuth(path, {
      method,
      headers,
      data: myData,
    })
      .then((response) => {
        if (!response) {
          reject("No data from response");
          return;
        }
        resolve(response);
      })
      .catch((err) => {
        reject(err?.response ? err?.response : err);
      });
  });
  return promise;
};

export default request;
