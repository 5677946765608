/* eslint-disable no-unused-vars */
import React from "react";
import { CForm } from "@coreui/react";
import SVG from "assets/svg";
import DebounceInput from "views/common/component/DebounceInput";
import arrowBack from "assets/icons/arrow-back.svg";

import classNames from "classnames";

import "./storeHeader.scss";

function StoreHeader(props) {
  const {
    idSearchInput,
    storeName,
    isSearching,
    onCommitInputSearch,
    handleChangeSearchStatus,
    goBack,
  } = props;

  return (
    <>
      <div
        className={classNames(
          "store-header",
          isSearching && "store-header--searching"
        )}
      >
        <button
          type="button"
          onClick={isSearching ? handleChangeSearchStatus(false) : goBack}
          className="button__effect flex-shrink-0 _border-none _outline-none"
          style={{ background: "transparent" }}
        >
          <img src={arrowBack} alt="arrow-back" width={8} height={15} />
        </button>

        {!isSearching ? (
          <div
            className="text-center text-truncate px-2 _fw-600 _fs-16px color-chinese_black"
            style={{ alignSelf: "flex-end" }}
          >
            Danh sách cửa hàng
          </div>
        ) : (
          <CForm className="search-box">
            <img src={SVG.LoupeBlack} alt="LoupeWhite" width={14} height={14} />

            <DebounceInput
              id={idSearchInput}
              type="text"
              className="_fw-500 _fs-12px debounce-input"
              placeholder="Nhập để tìm kiếm"
              onCommitChange={onCommitInputSearch}
              showClearButton
            />
          </CForm>
        )}

        {!isSearching && (
          <button
            type="button"
            className="button__cover-img--circle--white button__effect"
            onClick={props.goToSearch}
          >
            <img
              src={SVG.LoupeBlack}
              alt="loupe black icon"
              width={14}
              height={14}
            />
          </button>
        )}
      </div>

      <div className="store-header__bottom-line" />
    </>
  );
}

export default StoreHeader;
