/* eslint-disable no-unused-expressions */
/* eslint-disable react/button-has-type */
import React, { useEffect } from "react";
// import classNames from "classnames";
import { useFreezeScroll } from "hooks";
import {
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
} from "@coreui/react";
import "./AlertAction.scss";

const AlertAction = (props) => {
  const {
    isOpen,
    handleClose,
    handleAccept,
    // showButtonClose = false,
    title,
    // titleClassName,
    // children,
    titleCancel,
    titleAccept,
    content,
    dataItem,
  } = props;
  const [freezeBodyScroll, releaseBodyScroll] = useFreezeScroll();
  useEffect(() => {
    if (isOpen) {
      freezeBodyScroll();
    }

    return () => {
      releaseBodyScroll();
    };
  }, [isOpen]);

  if (!isOpen) {
    return <div />;
  }

  return (
    <CModal
      title={title || "UTop"}
      show={isOpen}
      onClose={() => {}}
      scrollable={false}
      centered
      closeOnBackdrop={false}
    >
      <CModalHeader className="border-0">
        <span className="_fw-700">{title || "Thông báo"}</span>
      </CModalHeader>
      <CModalBody className="py-0">{content}</CModalBody>
      <CModalFooter className="border-0">
        <CButton
          variant="ghost"
          onClick={() => {
            dataItem ? handleClose(dataItem) : handleClose;
          }}
        >
          {titleCancel}
        </CButton>
        <CButton
          variant="ghost"
          onClick={() => {
            dataItem ? handleAccept(dataItem) : handleAccept;
          }}
        >
          {titleAccept}
        </CButton>
      </CModalFooter>
    </CModal>
  );
};

export default AlertAction;
