import { genActions } from "utils/actionGenerator";
import * as TYPE from "./constants";

const actions = {
  VOUCHER_DETAIL: genActions(TYPE.VOUCHER_DETAIL),
  VOUCHER_INFOMATION: genActions(TYPE.VOUCHER_INFOMATION),
  // GET_VOUCHER_OUTSTAND: genActions(TYPE.GET_VOUCHER_OUTSTAND),
  CLICK_BY_MARK_USED: genActions(TYPE.CLICK_BY_MARK_USED),
};

export default actions;
