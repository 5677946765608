/* eslint-disable no-param-reassign */
import { addErrors, delErrors } from "utils/ErrorsGenerator";
import * as TYPE from "./constants";

const reducers = {
  // * GET_PAYMENT_METHODS
  [TYPE.GET_PAYMENT_METHODS.request]: (draft) => {
    draft.isLoading.GET_PAYMENT_METHODS = true;
  },
  [TYPE.GET_PAYMENT_METHODS.success]: (draft, { payload }) => {
    draft.errors = delErrors(draft.errors, TYPE.GET_PAYMENT_METHODS.failure);
    draft.listTransactionMethods = payload; // ***
    draft.isLoading.GET_PAYMENT_METHODS = false;
  },
  [TYPE.GET_PAYMENT_METHODS.failure]: (draft, { payload }) => {
    draft.errors = addErrors(draft.errors, {
      type: TYPE.GET_PAYMENT_METHODS.failure,
      msg: payload,
    });
    draft.isLoading.GET_PAYMENT_METHODS = false;
  },

  // * GET_PAYMENT_CARDS
  [TYPE.GET_PAYMENT_CARDS.request]: (draft) => {
    draft.isLoading.GET_PAYMENT_CARDS = true;
  },
  [TYPE.GET_PAYMENT_CARDS.success]: (draft, { payload }) => {
    draft.errors = delErrors(draft.errors, TYPE.GET_PAYMENT_CARDS.failure);
    draft.listPaymentCards = payload; // ***
    draft.isLoading.GET_PAYMENT_CARDS = false;
  },
  [TYPE.GET_PAYMENT_CARDS.failure]: (draft, { payload }) => {
    draft.errors = addErrors(draft.errors, {
      type: TYPE.GET_PAYMENT_CARDS.failure,
      msg: payload,
    });
    draft.isLoading.GET_PAYMENT_CARDS = false;
  },

  // * GET_UTOP_WALLET_INFO
  [TYPE.GET_UTOP_WALLET_INFO.request]: (draft) => {
    draft.isLoading.GET_UTOP_WALLET_INFO = true;
  },
  [TYPE.GET_UTOP_WALLET_INFO.success]: (draft, { payload }) => {
    draft.errors = delErrors(draft.errors, TYPE.GET_UTOP_WALLET_INFO.failure);
    draft.utopWalletInfo = payload; // ***
    draft.isLoading.GET_UTOP_WALLET_INFO = false;
  },
  [TYPE.GET_UTOP_WALLET_INFO.failure]: (draft, { payload }) => {
    draft.errors = addErrors(draft.errors, {
      type: TYPE.GET_UTOP_WALLET_INFO.failure,
      msg: payload,
    });
    draft.isLoading.GET_UTOP_WALLET_INFO = false;
  },

  // * CREATE_PAYMENT
  [TYPE.CREATE_PAYMENT.request]: (draft) => {
    draft.isLoading.CREATE_PAYMENT = true;
  },
  [TYPE.CREATE_PAYMENT.success]: (draft, { payload }) => {
    /**
     * sample response:
     * {
        amount: 78000
        checkPayNow: false
        orderCode: "2361024408874679"
        status: null
        transactionCode: "2361024408874679"
        url: "https://localhost:5001/?OrderCode=2361024408874679&Amount=78&TimeStick=637730159492681782&Signature=WNPDED8t/fvENjkXO3rkoRxLIiOuriZ0o4Q0KtzxJm7zoZbpkfhThpwPXsR4m9I/43g19eM1x3WFKh4CveVl18MZ1SU8GF4n/sEV9QjmLwxoaCI23FsV6sm37jTJSVZRekhY5sYRS8tIZETVbAC2i1DIE+3lOv1kV2z3co3w6BDQFOFfMUziWKqGM5lK190MUofgqrUfxw5TMCiZSLzM22xLQV90qOBKttBzt/A2cIpHz5SQiKdsBXMUNzB8B7QmDOA5Zt4cEgRwaj2fkz3CZYSv85RfeO2T9zOHC96l+kVLfi8OAcBZ59vTrax9Mlmq7HmPLSRR8so3R0eRtPlsEA=="
     * }
     */

    draft.errors = delErrors(draft.errors, TYPE.CREATE_PAYMENT.failure);
    draft.createPayment = payload; // ***
    draft.isLoading.CREATE_PAYMENT = false;
  },
  [TYPE.CREATE_PAYMENT.failure]: (draft, { payload }) => {
    draft.errors = addErrors(draft.errors, {
      type: TYPE.CREATE_PAYMENT.failure,
      msg: payload,
    });
    draft.isLoading.CREATE_PAYMENT = false;
  },

  // * TRANSACTION_PAY (UTOP WALLET) - for utop method, called in sagas.js
  [TYPE.TRANSACTION_PAY.request]: (draft) => {
    draft.isLoading.TRANSACTION_PAY = true;
    draft.isPaid = undefined;
  },
  [TYPE.TRANSACTION_PAY.success]: (draft, { payload }) => {
    draft.errors = delErrors(draft.errors, TYPE.TRANSACTION_PAY.failure);

    /**
     * sample response
     * {
        currentBalance: 18298
        orderCode: "2361024408874679"
        transactionCode: "03b5db1b-2c7f-4e6c-aab3-78d90344a922"
     * }
     */

    // update balance after pay - useless
    draft.utopWalletInfo = {
      ...draft.utopWalletInfo,
      balance: payload.currentBalance,
    };

    draft.paidTransactionInfo = payload;
    draft.isPaid = true;
    draft.isLoading.TRANSACTION_PAY = false;
  },
  [TYPE.TRANSACTION_PAY.failure]: (draft, { payload }) => {
    draft.errors = addErrors(draft.errors, {
      type: TYPE.TRANSACTION_PAY.failure,
      msg: payload,
    });
    draft.isPaid = false;
    draft.isLoading.TRANSACTION_PAY = false;
  },

  // * GET_ORDER_DETAIL
  [TYPE.GET_ORDER_DETAIL.request]: (draft) => {
    draft.isLoading.GET_ORDER_DETAIL = true;
  },
  [TYPE.GET_ORDER_DETAIL.success]: (draft, { payload }) => {
    draft.errors = delErrors(draft.errors, TYPE.GET_ORDER_DETAIL.failure);
    draft.orderDetail = payload;
    draft.isLoading.GET_ORDER_DETAIL = false;
  },
  [TYPE.GET_ORDER_DETAIL.failure]: (draft, { payload }) => {
    draft.errors = addErrors(draft.errors, {
      type: TYPE.GET_ORDER_DETAIL.failure,
      msg: payload,
    });
    draft.isLoading.GET_ORDER_DETAIL = false;
  },
  // CLEAR_PAYMENT
  [TYPE.CLEAR_PAYMENT.request]: (draft) => {
    return {
      ...draft,
      isPaid: undefined,
      createPayment: undefined,
    };
  },
};

export default reducers;
