import { actionTypes } from "@shared/redux/actionTypes";

export const GET_LIST_BANNER_REQUEST = "common/GET_LIST_BANNER_REQUEST";
export const GET_LIST_BANNER_SUCCESS = "common/GET_LIST_BANNER_SUCCESS";
export const GET_LIST_BANNER_FAILED = "common/GET_LIST_BANNER_FAILED";

export const GET_LIST_BANNER_BY_LOCATION_REQUEST =
  "common/GET_LIST_BANNER_BY_LOCATION_REQUEST";
export const GET_LIST_BANNER_BY_LOCATION_SUCCESS =
  "common/GET_LIST_BANNER_BY_LOCATION_SUCCESS";
export const GET_LIST_BANNER_BY_LOCATION_FAILED =
  "common/GET_LIST_BANNER_BY_LOCATION_FAILED";

export const GET_LIST_BANNER_DYNAMIC_REQUEST =
  "common/GET_LIST_BANNER_DYNAMIC_REQUEST";
export const GET_LIST_BANNER_DYNAMIC_SUCCESS =
  "common/GET_LIST_BANNER_DYNAMIC_SUCCESS";
export const GET_LIST_BANNER_DYNAMIC_FAILED =
  "common/GET_LIST_BANNER_DYNAMIC_FAILED";

export const GET_LIST_SHORTCUT_REQUEST = "common/GET_LIST_SHORTCUT_REQUEST";
export const GET_LIST_SHORTCUT_SUCCESS = "common/GET_LIST_SHORTCUT_SUCCESS";
export const GET_LIST_SHORTCUT_FAILED = "common/GET_LIST_SHORTCUT_FAILED";

export const SAVE_DEVICE_INFO_REQUEST = "common/SAVE_DEVICE_INFO_REQUEST";
export const SAVE_DEVICE_INFO_SUCCESS = "common/SAVE_DEVICE_INFO_SUCCESS";
export const SAVE_DEVICE_INFO_FAILED = "common/SAVE_DEVICE_INFO_FAILED";

export const TOGGLE_REQUEST_LOGIN = "common/TOGGLE_REQUEST_LOGIN";

export const GET_DYNAMIC_PAGE_REQUEST = "dynamicPage/GET_DYNAMIC_PAGE_REQUEST";
export const GET_DYNAMIC_PAGE_SUCCESS = "dynamicPage/GET_DYNAMIC_PAGE_REQUEST";
export const GET_DYNAMIC_PAGE_FAILED = "dynamicPage/GET_DYNAMIC_PAGE_FAILED";

export const GET_LIST_HOT_MERCHANT_REQUEST =
  "common/GET_LIST_HOT_MERCHANT_REQUEST";
export const GET_LIST_HOT_MERCHANT_SUCCESS =
  "common/GET_LIST_HOT_MERCHANT_SUCCESS";
export const GET_LIST_HOT_MERCHANT_FAILED =
  "common/GET_LIST_HOT_MERCHANT_FAILED";
export const SET_LIST_COUPON_SUGGEST = "smartMenu/SET_LIST_COUPON_SUGGEST";
export const UPDATE_LIST_COUPON_SUGGEST =
  "smartMenu/UPDATE_LIST_COUPON_SUGGEST";

export const GET_DYNAMIC_MODAL_REQUEST =
  "dynamicPage/GET_DYNAMIC_MODAL_REQUEST";
export const GET_DYNAMIC_MODAL_SUCCESS =
  "dynamicPage/GET_DYNAMIC_MODAL_SUCCESS";
export const GET_DYNAMIC_MODAL_FAILED = "dynamicPage/GET_DYNAMIC_MODAL_FAILED";
export const ACTION_CAMPAIGN_PRESS_REQUEST =
  "common/ACTION_CAMPAIGN_PRESS_REQUEST";
export const ACTION_CAMPAIGN_PRESS_SUCCESS =
  "common/ACTION_CAMPAIGN_PRESS_SUCCESS";
export const ACTION_CAMPAIGN_PRESS_FAILED =
  "common/ACTION_CAMPAIGN_PRESS_FAILED";

export const GET_SUGGEST_BANNER_REQUEST = "common/GET_SUGGEST_BANNER_REQUEST";

export const GET_SUGGEST_BANNER_SUCCESS = "common/GET_SUGGEST_BANNER_SUCCESS";

export const GET_SUGGEST_BANNER_FAILED = "common/GET_SUGGEST_BANNER_FAILED";

export const SHORT_URL_REQUEST = "common/SHORT_URL_REQUEST";
export const SHORT_URL_SUCCESS = "common/SHORT_URL_SUCCESS";
export const SHORT_URL_FAILED = "common/SHORT_URL_FAILED";

export const GET_PERSONALIZE_SHORTCUT_REQUEST =
  "common/GET_PERSONALIZE_SHORTCUT_REQUEST";
export const GET_PERSONALIZE_SHORTCUT_SUCCESS =
  "common/GET_PERSONALIZE_SHORTCUT_SUCCESS";
export const GET_PERSONALIZE_SHORTCUT_FAILED =
  "common/GET_PERSONALIZE_SHORTCUT_FAILED";

export const UPDATE_GOOGLE_MAP_KEY_API = "common/UPDATE_GOOGLE_MAP_KEY_API";

export const GET_LOV = actionTypes("common/GET_LOV");
