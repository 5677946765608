import {
  GET_MARKET_INFO,
  GET_LIST_FNB_TYPE,
  GET_LIST_MEDIA_HOME,
  GET_LIST_MEDIA_SUMMARY,
  GET_LIST_MEDIA_SUMMARY_SUCCESS,
  GET_ALL_CATEGORY,
  GET_LIST_MEDIA_CATEGORY,
  CLEAR_LIST_MEDIA_SUMMARY,
  GET_LIST_MEDIA_CATEGORY_SUCCESS,
  CLEAR_LIST_MEDIA_CATEGORY,
  GET_LIST_STORY,
  GET_LIST_STORY_SUCCESS,
  GET_LIST_FNB_BESTSELLER,
  GET_LIST_SUGGEST_PRODUCT,
  GET_LIST_STORE_MERCHANT,
  GET_STORE_DETAIL_MERCHANT,
  GET_LIST_CATEGORY_FNB_TYPE,
  GET_SHORT_LINK_TO_SHARE,
} from './constants'
export const getMarketInfoRequest = (storeId, onSuccess, onFailed) => ({
  type: GET_MARKET_INFO,
  storeId,
  onSuccess,
  onFailed,
})
export const getListFnbTypeRequest = (merchantId, onSuccess, onFailed) => ({
  type: GET_LIST_FNB_TYPE,
  merchantId,
  onSuccess,
  onFailed,
})
export const getListCategoryFnbTypeRequest = (
  payload,
  onSuccess,
  onFailed,
) => ({
  type: GET_LIST_CATEGORY_FNB_TYPE,
  payload,
  onSuccess,
  onFailed,
})

export const getListMediaHomeRequest = (storeId, onSuccess, onFailed) => ({
  type: GET_LIST_MEDIA_HOME,
  storeId,
  onSuccess,
  onFailed,
})
export const getListMediaSummaryRequest = (payload, onSuccess, onFailed) => ({
  type: GET_LIST_MEDIA_SUMMARY,
  payload,
  onSuccess,
  onFailed,
})

export const getListMediaSummarySuccess = (payload) => ({
  type: GET_LIST_MEDIA_SUMMARY_SUCCESS,
  payload,
})
export const getAllCategoryRequest = (payload, onSuccess, onFailed) => ({
  type: GET_ALL_CATEGORY,
  payload,
  onSuccess,
  onFailed,
})
export const clearListMediaSummary = (payload, onSuccess, onFailed) => ({
  type: CLEAR_LIST_MEDIA_SUMMARY,
  payload,
  onSuccess,
  onFailed,
})

export const getListMediaCategoryRequest = (payload, onSuccess, onFailed) => ({
  type: GET_LIST_MEDIA_CATEGORY,
  payload,
  onSuccess,
  onFailed,
})
export const getListMediaCategorySuccess = (payload) => ({
  type: GET_LIST_MEDIA_CATEGORY_SUCCESS,
  payload,
})
export const clearListMediaCategory = (payload, onSuccess, onFailed) => ({
  type: CLEAR_LIST_MEDIA_CATEGORY,
  payload,
  onSuccess,
  onFailed,
})

export const getListStoryRequest = (payload, onSuccess, onFailed) => ({
  type: GET_LIST_STORY,
  payload,
  onSuccess,
  onFailed,
})
export const getListStorySuccess = (payload) => ({
  type: GET_LIST_STORY_SUCCESS,
  payload,
})
export const getListFnbBestSeller = (payload, onSuccess, onFailed) => ({
  type: GET_LIST_FNB_BESTSELLER,
  payload,
  onSuccess,
  onFailed,
})
export const getListSuggestProductRequest = (payload, onSuccess, onFailed) => ({
  type: GET_LIST_SUGGEST_PRODUCT,
  payload,
  onSuccess,
  onFailed,
})
export const getListStoreMerchantRequest = (payload, onSuccess, onFailed) => ({
  type: GET_LIST_STORE_MERCHANT,
  payload,
  onSuccess,
  onFailed,
})
export const getStoreDetailMerchantRequest = (
  payload,
  onSuccess,
  onFailed,
) => ({
  type: GET_STORE_DETAIL_MERCHANT,
  payload,
  onSuccess,
  onFailed,
})

export const getShortLinkToShare = (payload, onSuccess, onFailed) => ({
  type: GET_SHORT_LINK_TO_SHARE,
  payload,
  onSuccess,
  onFailed,
})
