import React from "react";

function TextEndOfList(props) {
  const { paddingBottom = 0 } = props;
  return (
    <div
      style={{
        fontWeight: 400,
        fontSize: 14,
        color: "#151515",
        lineHeight: "19px",
        textAlign: "center",
        paddingBottom,
      }}
    >
      Đã hết danh sách
    </div>
  );
}

export default TextEndOfList;
