import { call, select, put, takeLatest, fork } from "redux-saga/effects";
import { parseListFeed } from "helpers/parsers";
import actions from "./actions";
import * as TYPE from "./constants";
import api from "../api/api";

const sagas = {
  *[TYPE.GET_LIST_CITY.request](action) {
    try {
      const data = yield call(api[TYPE.GET_LIST_CITY.request], action.payload);
      if (data.status === 200) {
        yield put(actions.GET_LIST_CITY.success(data.data));
      } else {
        yield put(actions.GET_LIST_CITY.failure(data?.data));
      }
    } catch (error) {
      yield put(actions.GET_LIST_CITY.failure(error?.data));
    }
  },

  // *[TYPE.GET_SEARCH_LIST.request](action) {
  //   try {
  //     const data = yield call(
  //       api[TYPE.GET_SEARCH_LIST.request],
  //       action.payload
  //     );
  //     if (data.status === 200) {
  //       yield put(actions.GET_SEARCH_LIST.success(data.data));
  //     } else {
  //       yield put(actions.GET_SEARCH_LIST.failure(data?.data));
  //     }
  //   } catch (error) {
  //     yield put(actions.GET_SEARCH_LIST.failure(error?.data));
  //   }
  // },

  *[TYPE.FILTER_VOUCHER_STORE.request](action) {
    try {
      const data = yield call(
        api[TYPE.FILTER_VOUCHER_STORE.request],
        action.payload
      );
      if (data.status === 200) {
        yield put(actions.FILTER_VOUCHER_STORE.success(data.data));
      } else {
        yield put(actions.FILTER_VOUCHER_STORE.failure(data?.data));
      }
    } catch (error) {
      yield put(actions.FILTER_VOUCHER_STORE.failure(error?.data));
    }
  },

  // ! deprecated
  *[TYPE.GET_LIST_VOUCHER.request](action) {
    const { lat, long, skip, take } = action.payload;

    try {
      const response = yield call(api[TYPE.GET_LIST_VOUCHER.request], {
        lat,
        long,
        skip,
        take,
      });

      if (response.status === 200) {
        const listVoucher = {
          value: response.data.value || [],
          total: response.data?.["@odata.count"] || 0,
        };
        yield put(actions.GET_LIST_VOUCHER.success(listVoucher));
      } else {
        yield put(actions.GET_LIST_VOUCHER.failure(response?.data));
      }
    } catch (error) {
      yield put(actions.GET_LIST_VOUCHER.failure(error?.data));
    }
  },

  // * GET_LIST_FEED
  *[TYPE.GET_LIST_FEED.request](action) {
    const { lat, long, skip, take, localeCode = "vi" } = action.payload;

    try {
      const response = yield call(api[TYPE.GET_LIST_FEED.request], {
        lat,
        long,
        skip,
        take,
      });

      if (response.status === 200) {
        const isInit = false;
        const nextFeeds =
          parseListFeed({ listFeed: response.data }, localeCode) || [];
        const hasMore = nextFeeds?.length === take;
        const prevListFeed = yield select(getListFeed);
        const newListFeed = {
          value: [...prevListFeed.value, ...nextFeeds],
          hasMore,
          isInit,
        };

        yield put(actions.GET_LIST_FEED.success(newListFeed));
      } else {
        yield put(actions.GET_LIST_FEED.failure(response?.data));
      }
    } catch (error) {
      yield put(actions.GET_LIST_FEED.failure(error?.data));
    }
  },

  // * GET_LIST_STORE_OF_MERCHANT
  *[TYPE.GET_LIST_STORE_OF_MERCHANT.request](action) {
    const {
      latitude,
      longitude,
      feedTypes,
      merchantId,
      skip,
      take = 20,
      onSuccess,
    } = action.payload;

    try {
      const response = yield call(
        api[TYPE.GET_LIST_STORE_OF_MERCHANT.request],
        {
          merchantId,
          feedTypes,
          latitude,
          longitude,
          skip,
          take,
        }
      );

      if (response.status === 200) {
        const listStore =
          parseListFeed({ listFeed: response.data, localeCode: "vi" }) || [];
        const prevListStoreData = yield select(getListStoreOfSelectedMerchant);
        const shouldReplacePrevListStore =
          merchantId !== prevListStoreData.merchantId;
        const listStoreOfSelectedMerchant = {
          merchantId,
          listStore: shouldReplacePrevListStore
            ? listStore
            : [...prevListStoreData.listStore, ...listStore],
          hasMore: listStore.length === take,
        };

        yield put(
          actions.GET_LIST_STORE_OF_MERCHANT.success(
            listStoreOfSelectedMerchant
          )
        );

        if (onSuccess) {
          yield call(onSuccess);
        }
      } else {
        yield put(actions.GET_LIST_STORE_OF_MERCHANT.failure(response?.data));
      }
    } catch (error) {
      yield put(actions.GET_LIST_STORE_OF_MERCHANT.failure(error?.data));
    }
  },

  *[TYPE.GET_SHORTCUTS.request](action) {
    try {
      const data = yield call(api[TYPE.GET_SHORTCUTS.request], action.payload);

      if (data.status === 200) {
        yield put(actions.GET_SHORTCUTS.success(data.data));
      } else {
        yield put(actions.GET_SHORTCUTS.failure(data?.data));
      }
    } catch (error) {
      yield put(actions.GET_SHORTCUTS.failure(error?.data));
    }
  },

  *[TYPE.GET_SHORTCUTS_PERSONALIZED.request](action) {
    try {
      const response = yield call(
        api[TYPE.GET_SHORTCUTS_PERSONALIZED.request],
        action.payload
      );

      if (response.status === 200) {
        yield put(actions.GET_SHORTCUTS_PERSONALIZED.success(response.data));
      } else {
        yield put(actions.GET_SHORTCUTS_PERSONALIZED.failure(response?.data));
      }
    } catch (error) {
      yield put(actions.GET_SHORTCUTS_PERSONALIZED.failure(error?.data));
    }
  },
};

function* watchedSaga() {
  yield takeLatest(
    TYPE.GET_LIST_CITY.request,
    sagas[TYPE.GET_LIST_CITY.request]
  );
  yield takeLatest(
    TYPE.FILTER_VOUCHER_STORE.request,
    sagas[TYPE.FILTER_VOUCHER_STORE.request]
  );

  // ! deprecated
  yield takeLatest(
    TYPE.GET_LIST_VOUCHER.request,
    sagas[TYPE.GET_LIST_VOUCHER.request]
  );

  // * GET_LIST_FEED
  yield takeLatest(
    TYPE.GET_LIST_FEED.request,
    sagas[TYPE.GET_LIST_FEED.request]
  );

  // * GET_LIST_STORE_OF_MERCHANT
  yield takeLatest(
    TYPE.GET_LIST_STORE_OF_MERCHANT.request,
    sagas[TYPE.GET_LIST_STORE_OF_MERCHANT.request]
  );

  yield takeLatest(
    TYPE.GET_SHORTCUTS.request,
    sagas[TYPE.GET_SHORTCUTS.request]
  );
  yield takeLatest(
    TYPE.GET_SHORTCUTS_PERSONALIZED.request,
    sagas[TYPE.GET_SHORTCUTS_PERSONALIZED.request]
  );
}

export default function* rootChild() {
  yield fork(watchedSaga);
}

// *** SELECTORS
const getListFeed = (state) => state[TYPE.TYPE].listFeed;
const getListStoreOfSelectedMerchant = (state) =>
  state[TYPE.TYPE].listStoreOfSelectedMerchant;
