import {END_POINTS} from '@shared/constants/apiUrl';
import {APIUtils} from 'utils';

export function getTokenUploadToAzure({type = 1}) {
  return new Promise((resolve, reject) => {
    const url = END_POINTS.GET_TOKEN_UPLOAD_URL + `?type=${type}`;
    return APIUtils.get(url)
      .then(result => {
        resolve(result);
      })
      .catch(error => {
        console.log('errorerror', error);
        reject(error);
      });
  });
}
