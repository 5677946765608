import { genActionTypes } from "utils/actionGenerator";
// import { TYPE as ROOT } from "views/common/store/constants";

export const TYPE = "StoreDetail";

// export const SET_LOADING = `${TYPE}_SET_LOADING`;
export const GET_MERCHANT_INFO = genActionTypes(`${TYPE}_GET_MERCHANT_INFO`);
export const GET_STORE_INFO = genActionTypes(`${TYPE}_GET_STORE_INFO`);
export const GET_BEST_SELLER_LIST = genActionTypes(
  `${TYPE}_GET_BEST_SELLER_LIST`
);
export const GET_PRODUCT_LIST = genActionTypes(`${TYPE}_GET_PRODUCT_LIST`);
export const GET_RECENT_ORDERED_PRODUCT_CODE_LIST = genActionTypes(
  `${TYPE}_GET_RECENT_ORDERED_PRODUCT_CODE_LIST`
);
export const GET_SUGGESTED_COUPON_LIST = genActionTypes(
  `${TYPE}_GET_SUGGESTED_COUPON_LIST`
);

export const SAVE_SUGGESTED_COUPON = genActionTypes(
  `${TYPE}_SAVE_SUGGESTED_COUPON`
);
export const GET_OUT_OF_STOCK_PRODUCT_LIST = genActionTypes(
  `${TYPE}_GET_OUT_OF_STOCK_PRODUCT_LIST`
);
export const GET_SHORT_LINK_TO_SHARE = genActionTypes(
  `${TYPE}_GET_SHORT_LINK_TO_SHARE`
);
export const GET_STORE_INFO_V2 = genActionTypes(`${TYPE}_GET_STORE_INFO_V2`);
