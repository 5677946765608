import React from "react";
import "./TipsDetails.scss";

function TipsDetails(props) {
  const { tipDetails } = props;

  return (
    <div className="tip-info">
      <div className="tip-info__title">Phí áp dụng</div>
      <div className="tip-info__content">
        {tipDetails?.map((tipDetail, idx) => (
          <div key={`tip-detail__${idx + 0}__${tipDetail}`}>{tipDetail}</div>
        ))}
      </div>
    </div>
  );
}

export default TipsDetails;
