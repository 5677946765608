/* eslint-disable array-callback-return */
/* eslint-disable no-param-reassign */
import { addErrors, delErrors } from "utils/ErrorsGenerator";
import * as TYPE from "./constants";

const reducers = {
  [TYPE.CREATE_PAYMENT.success]: (draft) => {
    draft.errors = delErrors(draft.errors, TYPE.CREATE_PAYMENT.failure);
  },
  [TYPE.CREATE_PAYMENT.failure]: (draft, { payload }) => {
    draft.errors = addErrors(draft.errors, {
      type: TYPE.CREATE_PAYMENT.failure,
      msg: payload,
    });
  },
  [TYPE.TRANSACTION_PAY.success]: (draft, { payload }) => {
    draft.errors = delErrors(draft.errors, TYPE.TRANSACTION_PAY.failure);
    draft.paymentStatus = payload;
  },
  [TYPE.TRANSACTION_PAY.failure]: (draft, { payload }) => {
    draft.errors = addErrors(draft.errors, {
      type: TYPE.TRANSACTION_PAY.failure,
      msg: payload,
    });
  },
};

export default reducers;
