import APIUtils from 'utils/apiUtils';
import {END_POINTS} from '@shared/constants/apiUrl';

export function allowcationCouponApi({code}) {
  return APIUtils.post(END_POINTS.ALLOWCATION_COUPON, {
    body: {code},
  });
}

export function getSuggestCouponApi({}) {
  let requestData = '?Feature=orderfnb';

  return APIUtils.get(END_POINTS.GET_SUGGEST_COUPON + requestData);
}

export function getUtopBalanceApi({}) {
  let requestData = '';

  return APIUtils.get(END_POINTS.GET_UTOP_BALANCE_URL + requestData);
}

export function getCouponDetailApi({code, transactionCode}) {
  var requestData =
    '?productcode=' +
    code +
    '&status=Redeem&transactioncode=' +
    transactionCode;

  return APIUtils.get(END_POINTS.GET_VOUCHER_DETAIL_URL + requestData);
}

export function calCouponDiscountApi(requestData) {
  return APIUtils.post(END_POINTS.CHECK_COUPON_DISCOUNT_URL, {
    body: requestData,
  });
}

export function getSuggestDiscountShippingApi(bodyRequest) {
  return APIUtils.get(
    END_POINTS.GET_SUGGEST_DISCOUNT_SHIPPING +
      `?merchantId=${bodyRequest.merchantId}&storeId=${bodyRequest.storeId}&code=${bodyRequest.code}`,
  );
}
