import React, { useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  useLoadScript,
  GoogleMap,
  Marker,
  OverlayView,
} from "@react-google-maps/api";

import { MAP_MODES } from "constants/types";
import { GOOGLE_API_LIBRARIES } from "configs/placesAPIConfig";

import SVG from "assets/svg";
import "./map.scss";
import { getDistanceFromLatLonInKm } from "helpers/getDistanceFromLatLonInKm";
import Geocode from "react-geocode";

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_API_KEY);
// Geocode.enableDebug();

const BinhThanhLocation = {
  lat: 10.810583,
  lng: 106.709145,
};

function MapAddress(props) {
  const {
    mode /* SELECT_DELIVERY_ADDRESS || DIRECTION */,
    storeLocation,

    deliveryLocation,
    deliveryAddress,

    deviceLocation,
    // getMarkedLocation,

    mapContainerStyle,

    initCenter,

    // zoom = 15,

    mapOptions,

    // sub-info
    storeAddress,

    customDeviceLocation, // prop này có độ ưu tiên cao hơn deviceLocation, được thêm vào bởi requirement mới: mode DIRECTION khi click từ storeview/storedetail sẽ show dẫn đường từ điểm đã pinned ở homepage tới store (không care location của device hiện tại)

    handlePickAddress,
    // ...rest
  } = props;

  const history = useHistory();

  const mapRef = useRef();
  const centerChangeTimeoutRef = useRef();
  const [center, setCenter] = useState(BinhThanhLocation);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries: GOOGLE_API_LIBRARIES, // ! must have, declare this in order to avoid bug | https://github.com/ErrorPro/react-google-autocomplete/issues/89#:~:text=somewhere%20in%20the%20code%20you%20already%20require%20google%20map%20API(any%20map%20library%20or%20directly)%20without%20places%20API%20in%20the%20URL
  });

  useEffect(() => {
    const initialCenterLocation =
      initCenter || storeLocation || deliveryLocation;

    if (initialCenterLocation) {
      setCenter(initialCenterLocation);
    }

    return () => {
      setCenter(BinhThanhLocation);
    };
  }, [initCenter, storeLocation, deliveryLocation]);

  const [currentMarkedAddress, setCurrentMarkedAddress] = useState();

  const handleMapOnLoad = (map) => {
    mapRef.current = map;
  };

  const handleCenterChanged = () => {
    if (centerChangeTimeoutRef.current) {
      clearTimeout(centerChangeTimeoutRef.current);
    }

    centerChangeTimeoutRef.current = setTimeout(() => {
      const currentPosition = mapRef.current.getCenter().toJSON();

      Geocode.fromLatLng(currentPosition.lat, currentPosition.lng).then(
        (response) => {
          const address = response.results[0].formatted_address;

          setCurrentMarkedAddress(address);

          if (handlePickAddress && address) {
            handlePickAddress({
              address,
              lat: currentPosition.lat,
              lng: currentPosition.lng,
            });
          }
        },
        (error) => {
          console.error(error);
        }
      );
    }, [500]);
  };

  const handlePanToCurrentLocation = () => {
    if (
      customDeviceLocation &&
      customDeviceLocation?.lat &&
      customDeviceLocation?.lng
    ) {
      mapRef.current.panTo({
        lat: customDeviceLocation.lat,
        lng: customDeviceLocation.lng,
      });

      return;
    }

    // get geolocation from browser
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          // infoWindow.setPosition(pos);
          // infoWindow.setContent("Location found.");
          // infoWindow.open(map);
          // console.log("pos: ", pos);
          // mapRef.current.setCenter(pos);
          mapRef.current.panTo(pos);
          // setTimeout(() => {
          //   setCenter(pos);
          // }, 1500);
        },
        () => {
          // handleLocationError(true, infoWindow, map.getCenter());
          console.warn(
            "User block location of browser or geolocation is not supported"
          );
        }
      );
    } else {
      // Browser doesn't support Geolocation
      // handleLocationError(false, infoWindow, map.getCenter());
      console.warn(`Browser doesn't support Geolocation`);
    }
  };

  const handlePanToStoreLocation = () => {
    mapRef.current.panTo(storeLocation);

    // todo: pass data to outside
  };

  const handleOpenGoogleMap = () => {
    if (
      /* if we're on iOS, open in Apple Maps */
      navigator.userAgent.indexOf("iPhone") !== -1 ||
      navigator.userAgent.indexOf("iPad") !== -1
      // navigator.platform.indexOf("iPod") !== -1
    ) {
      // window.open(`maps://maps.google.com/maps?daddr=<lat>,<long>&amp;ll=`); // original sample
      // window.open(
      //   `maps://maps.google.com/maps/dir/${deviceLocation.lat},${deviceLocation.lng}/${storeLocation.lat},${storeLocation.lng}`
      // );
      //
      // window.open(
      //   `maps://www.google.com/maps/dir/?api=1&destination=${storeLocation.lat},${storeLocation.lng}`
      // );
      //
      // window.open(
      //   `maps://www.google.com/maps?daddr=${storeLocation.lat},${storeLocation.lng}`
      // );
      if (customDeviceLocation) {
        window.open(
          `http://maps.apple.com/?saddr=${customDeviceLocation.lat},${customDeviceLocation.lng}&daddr=${storeLocation.lat},${storeLocation.lng}`
        );
      } else {
        window.open(
          `http://maps.apple.com/?&daddr=${storeLocation.lat},${storeLocation.lng}`
        );
      }
      return;
    }

    if (customDeviceLocation) {
      window.open(
        `https://www.google.com/maps/dir/?api=1&origin=${customDeviceLocation.lat},${customDeviceLocation.lng}&destination=${storeLocation.lat},${storeLocation.lng}`
      );
    } else {
      window.open(
        `https://www.google.com/maps/dir/?api=1&destination=${storeLocation.lat},${storeLocation.lng}`
      );
    }
  };

  const handleDragStart = () => {
    if (mode !== MAP_MODES.SELECT_DELIVERY_ADDRESS) {
      return;
    }

    const LocationMarker = document.getElementById("location-marker");
    LocationMarker.style.opacity = 0.5;
    setCurrentMarkedAddress("...");
  };

  const handleDragEnd = () => {
    if (mode !== MAP_MODES.SELECT_DELIVERY_ADDRESS) {
      return;
    }

    document.getElementById("location-marker").style.opacity = 1;

    setCenter(mapRef.current.getCenter().toJSON());

    // todo: pass data to outside
  };

  const handleOpenDirectionMode = () => {
    if (mode === MAP_MODES.VIEW_SELECTED_DELIVERY_ADDRESS) {
      history.push("/markposition", {
        mode: MAP_MODES.SELECT_DELIVERY_ADDRESS,
        deliveryLocation,
        deliveryAddress,
      });
    }
  };

  const distance = useMemo(() => {
    if (!storeLocation) {
      return null;
    }

    const pivotLocation = customDeviceLocation || deviceLocation; // ưu tiên customDeviceLocation

    if (pivotLocation?.lat && pivotLocation?.lng) {
      return (
        pivotLocation &&
        storeLocation &&
        `(${Number(
          getDistanceFromLatLonInKm(
            pivotLocation.lat,
            pivotLocation.lng,
            storeLocation.lat,
            storeLocation.lng
          ).toFixed(1)
        ).toLocaleString("vi")}km)`
      );
    }

    return null;
  }, [storeLocation, customDeviceLocation, deviceLocation]);

  const renderMap = () => {
    return (
      <div style={{ position: "relative" }}>
        <GoogleMap
          onLoad={handleMapOnLoad} // Store a reference to the google map instance in state
          onCenterChanged={
            mode === MAP_MODES.SELECT_DELIVERY_ADDRESS && handleCenterChanged
          } // Save the current center position in state
          // onClick={(e) => setClickedLatLng(e.latLng.toJSON())} // Save the user's map click position
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
          center={center}
          // defaultCenter={CueichaLocation}
          zoom={15}
          mapContainerStyle={
            mapContainerStyle || {
              height: "100vh",
              width: "100%",
            }
          }
          options={{
            zoomControl: !!mapOptions?.zoomControl,
            zoomControlOptions: {
              position: window.google.maps.ControlPosition.LEFT_CENTER,
            },

            mapTypeControl: !!mapOptions?.mapTypeControl,
            mapTypeControlOptions: {
              style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
              position: window.google.maps.ControlPosition.TOP_CENTER,
            },

            scaleControl: !!mapOptions?.scaleControl,

            streetViewControl: !!mapOptions?.streetViewControl,
            streetViewControlOptions: {
              position: window.google.maps.ControlPosition.LEFT_TOP,
            },

            rotateControl: !!mapOptions?.rotateControl,
            fullscreenControl: !!mapOptions?.fullscreenControl,
            gestureHandling: "none",
            disableDefaultUI: true,
          }}
        >
          {/* Nếu không có customDeviceLocation thì mới hiện deviceLocation như bình thường 
          customDeviceLocation là địa chỉ được pinned từ homepage
          */}
          {!customDeviceLocation && deviceLocation && (
            <Marker
              position={deviceLocation}
              icon={SVG.LocationCircleBlue16x16}
            >
              <OverlayView mapPaneName="markerLayer" position={deviceLocation}>
                <div className="info-window info-window__device">
                  <div className="info-window__title">Vị trí của bạn</div>
                </div>
              </OverlayView>
            </Marker>
          )}

          {customDeviceLocation && (
            <Marker
              position={customDeviceLocation}
              icon={SVG.LocationCircleBlue16x16}
            >
              <OverlayView
                mapPaneName="markerLayer"
                position={customDeviceLocation}
              >
                <div className="info-window info-window__device">
                  <div className="info-window__title">Vị trí giao hàng</div>
                </div>
              </OverlayView>
            </Marker>
          )}

          {storeLocation && (
            <Marker
              position={storeLocation}
              icon={SVG.LocationMarkerStore36x36}
            >
              <OverlayView mapPaneName="markerLayer" position={storeLocation}>
                <div className="info-window info-window__store">
                  <div className="info-window__title">Vị trí cửa hàng</div>

                  <div className="info-window__content">{storeAddress}</div>
                </div>
              </OverlayView>
            </Marker>
          )}

          {deliveryLocation?.lat &&
            deliveryLocation?.lng &&
            mode !== MAP_MODES.SELECT_DELIVERY_ADDRESS && (
              <Marker
                position={deliveryLocation}
                icon={SVG.LocationSolidRed24x24}
              >
                <OverlayView
                  mapPaneName="markerLayer"
                  position={deliveryLocation}
                >
                  <div className="info-window info-window__delivery">
                    <div className="info-window__title">Địa chỉ giao hàng</div>

                    {mode !== MAP_MODES.VIEW_SELECTED_DELIVERY_ADDRESS && (
                      <div className="info-window__content">
                        {deliveryAddress}
                      </div>
                    )}
                  </div>
                </OverlayView>
              </Marker>
            )}
        </GoogleMap>

        {mode === MAP_MODES.SELECT_DELIVERY_ADDRESS && (
          <>
            <img
              id="location-marker"
              src={SVG.LocationSolidRed24x24}
              alt="LocationSolidRed24x24"
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%,-100%)",
              }}
            />

            {/* !! implementing */}
            <div className="info-window info-window__delivery bubble-address">
              <div className="info-window__title">Địa chỉ giao hàng</div>
              <div className="info-window__content">{currentMarkedAddress}</div>
            </div>
          </>
        )}

        {mode === MAP_MODES.SELECT_DELIVERY_ADDRESS && (
          // showPanToCurrentLocationBtn &&
          <div
            onClick={handlePanToCurrentLocation}
            style={{
              position: "absolute",
              bottom: 24,
              right: 24,
            }}
          >
            <img src={SVG.BtnPanToCurrentLocation} alt="current location" />
          </div>
        )}

        {mode === MAP_MODES.VIEW_SELECTED_DELIVERY_ADDRESS && (
          <img
            className="button__effect"
            src={SVG.BtnOpenDirectionMode}
            atl="BtnOpenDirectionMode"
            onClick={handleOpenDirectionMode}
            style={{
              position: "absolute",
              bottom: 12,
              right: 12,
            }}
          />
        )}

        {mode === MAP_MODES.DIRECTION && (
          // showBottomActionBar &&
          <div className="bottom-bar">
            <img
              src={SVG.BtnYourLocation}
              alt="BtnYourLocation"
              className="button__effect"
              onClick={handlePanToCurrentLocation}
            />
            <img
              src={SVG.BtnStoreLocation}
              alt="BtnYourLocation"
              className="button__effect"
              onClick={handlePanToStoreLocation}
            />

            <button
              type="button"
              className="bottom-bar__direction-btn button__effect"
              onClick={handleOpenGoogleMap}
            >
              <span>
                Chỉ đường
                {!!distance && ` ${distance}`}
              </span>
            </button>
          </div>
        )}
      </div>
    );
  };

  return isLoaded ? (
    <>{renderMap()}</>
  ) : (
    <div>
      Bản đồ đang tải. Bạn có thể thử tải lại trang nếu bản đồ chưa xuất hiện
      sau khoảng 1 phút.
    </div>
  );
}

export default React.memo(MapAddress);
