import React from "react";
import { toVND } from "utils/currencyFormat";
import { FNB_OPTION_TYPES } from "constants/types";

const CheckBoxOption = (props) => {
  const {
    type = FNB_OPTION_TYPES.TOPPING,
    id,
    code,
    name,
    price,
    listPrice,
    checked = false,
    handleCheckOption,
  } = props;

  return (
    <div className="d-flex align-items-center">
      <label
        className="form-check-label d-flex justify-content-between w-100"
        htmlFor={code}
      >
        <div>
          <span className="_fs-14px _fw-400">{name}</span>
        </div>
        <div className="text-end">
          <div>
            <span className="_fw-600 _fs-14px">{toVND(Number(price))}</span>
          </div>
          {Number(listPrice) > 0 && (
            <div className="mt-n2">
              <span className="mt-n2 text-decoration-line-through _fw-400 _fs-10px">
                {toVND(Number(listPrice))}
              </span>
            </div>
          )}
        </div>
      </label>
      <div className="form-check mb-0 ml-3">
        <input
          className="form-check-input"
          type="checkbox"
          id={code}
          checked={checked}
          onChange={handleCheckOption(type, {
            id,
            code,
            quantity: 1, //! hard code, not know reason why topping has quantity
            name,
            price,
          })}
        />
      </div>
    </div>
  );
};
export default CheckBoxOption;
