export const CHECK_UTOP_BUSINESS = 'qrpay/CHECK_UTOP_BUSINESS';
export const PAY_BY_TOKEN_V1 = 'qrpay/PAY_BY_TOKEN_V1';
export const PAY_BY_TOKEN_V2 = 'qrpay/PAY_BY_TOKEN_V2';
export const SET_SHOW_GUIDE_PAYMENT = 'qrpay/SET_SHOW_GUIDE_PAYMENT';
export const SET_SHOW_GUIDE_PAYMENT_UB = 'qrpay/SET_SHOW_GUIDE_PAYMENT_UB';
export const SET_SHOW_GUIDE_PAYMENT_BY_POINT =
  'qrpay/SET_SHOW_GUIDE_PAYMENT_BY_POINT';
export const PAY_BY_UTOP_BUSINESS = 'qrpay/PAY_BY_UTOP_BUSINESS';
export const GET_LIST_BUDGET_SOUCRE = 'qrpay/GET_LIST_BUDGET_SOUCRE';
export const GET_LIST_LEGAL = 'qrpay/GET_LIST_LEGAL';
export const CHECK_QR = 'qrpay/CHECK_QR';
export const LOAD_DATA_QR = 'qrpay/LOAD_DATA_QR';
export const UPLOAD_IMAGE_UTOP_BUSINESS = 'qrpay/UPLOAD_IMAGE_UTOP_BUSINESS';
export const SET_LIST_PAYMENT_RECENTLY = 'qrpay/SET_LIST_PAYMENT_RECENTLY';
export const CLEAR_LIST_PAYMENT_RECENTLY = 'qrpay/CLEAR_LIST_PAYMENT_RECENTLY';
export const UPDATE_NOTICATION_RECEIVE_PAYMENT_QR =
  'qrpay/UPDATE_NOTICATION_RECEIVE_PAYMENT_QR';
export const CLEAR_NOTICATION_RECEIVE_PAYMENT_QR =
  'qrpay/CLEAR_NOTICATION_RECEIVE_PAYMENT_QR';
