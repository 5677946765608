import React, { useRef } from "react";
import classNames from "classnames";
import moment from "moment";
import SVG from "assets/svg";
import { useScrollHorizontallyWithButtons } from "hooks";

import "./CouponList.scss";

export const CouponItem = React.memo((props) => {
  const {
    id,
    transactionCode,
    name,
    code,
    couponCode,
    expiryDate,
    isApplied,
    handleToggleApplyPromo,
    handleGotoDetail,
    coupon,
    isAllocated,
    clearCoupon,
    ...rest
  } = props;

  return (
    // promotion item wrapper
    <div {...rest}>
      <div
        className={classNames(
          "promotion-item d-flex",
          isApplied && "promotion-item-applied"
        )}
      >
        <div
          className="pr-2"
          style={{
            minWidth: 0,
            flexGrow: 1,
            borderRight: "1px dashed #dedede",
          }}
          onClick={handleGotoDetail}
        >
          {!name && <div style={{ height: "21px" }} />}
          <div className="_fw-500 _fs-14px color-black text-truncate">
            {name}
          </div>
          <div className="_fw-700 _fs-12px color-ucla_gold text-on-1-line">
            {couponCode}
          </div>
          <div
            className="_fw-500 _fs-10px color-granite_gray"
            style={{ color: !expiryDate && "transparent" }}
          >
            {`HSD: ${moment(expiryDate).format("DD/MM/YYYY")}`}
          </div>
        </div>

        <div className="d-flex">
          <button
            onClick={() =>
              isApplied ? clearCoupon() : handleToggleApplyPromo(coupon)
            }
            className="button-apply _outline-none"
            type="button"
          >
            <span className="color-ucla_gold _fw-600 _fs-14px">
              {isApplied ? "Hủy" : "Chọn"}
            </span>
          </button>
        </div>

        {/* serration ticket border */}
        <div className="dotted-right-border" />
        <div className="dotted-right-border dotted-right-border-cover" />
        <div className="redundant-dotted-cover" />
      </div>
    </div>
  );
});

const OFFSET_TO_SCROLL_PER_CLICK = 300;

const CouponHorizontalList = (props) => {
  const {
    promotionList,
    handleToggleApplyPromo,
    appliedCoupon,
    handleGotoDetail,
  } = props;
  const tagsContainerRef = useRef();
  const {
    arrowButtonsState,
    scrollHorizontal,
    handleTagsContainerScroll,
    BTN_STATE,
  } = useScrollHorizontallyWithButtons({
    containerRef: tagsContainerRef,
    items: promotionList,
  });

  return (
    <div style={{ position: "relative" }}>
      <div
        id="test"
        className="store-info-card__tags hide-x-scrollbar"
        ref={tagsContainerRef}
        onScroll={handleTagsContainerScroll}
      >
        {promotionList?.map((coupon) => (
          <CouponItem
            key={coupon?.couponId}
            id={coupon?.couponId}
            coupon={coupon}
            transactionCode={coupon?.transactionCode}
            name={coupon?.name}
            code={coupon?.code}
            couponCode={coupon?.couponCode}
            expiryDate={coupon?.endDateTime}
            isApplied={appliedCoupon?.code === coupon?.code}
            handleToggleApplyPromo={handleToggleApplyPromo}
            isAllocated={coupon?.isAllocated}
            handleGotoDetail={handleGotoDetail({
              idCoupon: coupon?.couponId,
              transactionCode: coupon?.transactionCode,
            })}
            clearCoupon={props.clearCoupon}
          />
        ))}
        {[BTN_STATE.SHOW_BUTTON_PREV, BTN_STATE.SHOW_BOTH_BUTTONS].includes(
          arrowButtonsState
        ) && (
          <img
            // id="example"
            src={SVG.arrowPrev}
            alt="arrow prev"
            className="btn-prev-scroll"
            onClick={() => scrollHorizontal(-OFFSET_TO_SCROLL_PER_CLICK)}
          />
        )}
        {[BTN_STATE.SHOW_BUTTON_NEXT, BTN_STATE.SHOW_BOTH_BUTTONS].includes(
          arrowButtonsState
        ) && (
          <img
            src={SVG.arrowNext}
            alt="arrow next"
            className="btn-next-scroll"
            onClick={() => scrollHorizontal(+OFFSET_TO_SCROLL_PER_CLICK)}
          />
        )}
      </div>
    </div>
  );
};

export default React.memo(CouponHorizontalList);
