/* eslint-disable no-unused-vars */
import { call, put, takeLatest, fork } from "redux-saga/effects";
import actions from "./actions";
import * as TYPE from "./constants";
import api from "../api/api";

const sagas = {
  // *[TYPE.GET_USER_ADDRESS_BOOKS.request](action) {
  //   try {
  //     const data = yield call(
  //       api[TYPE.GET_USER_ADDRESS_BOOKS.request],
  //       action.payload
  //     );
  //     if (data.status === 200) {
  //       yield put(actions.GET_USER_ADDRESS_BOOKS.success(data.data));
  //     } else {
  //       yield put(actions.GET_USER_ADDRESS_BOOKS.failure(data?.data));
  //     }
  //   } catch (error) {
  //     yield put(actions.GET_USER_ADDRESS_BOOKS.failure(error?.data));
  //   }
  // },
  // *[TYPE.DELETE_A_USER_ADDRESS_BOOK.request](action) {
  //   try {
  //     const data = yield call(
  //       api[TYPE.DELETE_A_USER_ADDRESS_BOOK.request],
  //       action.payload
  //     );
  //     if (data.status === 200) {
  //       yield put(actions.DELETE_A_USER_ADDRESS_BOOK.success(data.data));
  //     } else {
  //       yield put(actions.DELETE_A_USER_ADDRESS_BOOK.failure(data?.data));
  //     }
  //   } catch (error) {
  //     yield put(actions.DELETE_A_USER_ADDRESS_BOOK.failure(error?.data));
  //   }
  // },
};

function* watchedSaga() {
  // yield takeLatest(
  //   TYPE.GET_USER_ADDRESS_BOOKS.request,
  //   sagas[TYPE.GET_USER_ADDRESS_BOOKS.request]
  // );
  // yield takeLatest(
  //   TYPE.DELETE_A_USER_ADDRESS_BOOK.request,
  //   sagas[TYPE.DELETE_A_USER_ADDRESS_BOOK.request]
  // );
}

export default function* rootChild() {
  yield fork(watchedSaga);
}
