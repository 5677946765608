import {findIndex} from 'lodash';
import {
  GET_INFO_STORE_SUCCESS,
  GET_LIST_VOUCHER_BY_STORE_SUCCESS,
  GET_MERCHANT_INFO_SUCCESS,
  GET_STORE_DETAIL_SUCCESS,
  GET_LIST_COUPON_SUGGEST_SUCCESS,
  GET_LIST_BEST_SELLER_SUCCESS,
  UPDATE_LIST_SUGGEST_COUPON,
  GET_LIST_VIDEO_STORE_SUCCESS,
  GET_LIST_VIDEO_STORE_FAILED,
  SET_LIST_COUPON_SUGGEST_FOR_STORE,
  UPDATE_LIST_COUPON_SUGGEST_FOR_STORE,
  GET_LIST_VOUCHER_BY_STORE_FAILED,
} from './constants';

const initialState = {
  storeDetail: {},
  infoStore: {},
  listVoucherByStore: [],
  merchantInfo: {},
  listCouponSuggest: [],
  listBestSeller: [],
  listVideoStore: [],
  error: null,
};

const storeDetail = (state = initialState, action) => {
  switch (action.type) {
    case GET_STORE_DETAIL_SUCCESS: {
      return {
        ...state,
        storeDetail: action.payload.data.data,
      };
    }
    case GET_INFO_STORE_SUCCESS: {
      return {
        ...state,
        infoStore: action.payload.data.data,
      };
    }
    case GET_LIST_VOUCHER_BY_STORE_SUCCESS: {
      return {
        ...state,
        listVoucherByStore: {
          ...action.payload.data.data,
          merchantId: action?.payload?.merchantId || '',
        },
      };
    }

    case GET_LIST_VOUCHER_BY_STORE_FAILED: {
      return {
        ...state,
        listVoucherByStore : []
      };
    }

    case GET_MERCHANT_INFO_SUCCESS: {
      return {
        ...state,
        merchantInfo: {
          ...action.payload.data.data,
          merchantId: action?.payload?.merchantId || '',
        },
      };
    }

    case GET_LIST_COUPON_SUGGEST_SUCCESS: {
      return {
        ...state,
        listCouponSuggest: action.payload.data,
      };
    }

    case GET_LIST_BEST_SELLER_SUCCESS: {
      return {
        ...state,
        listBestSeller: {
          ...action.payload.data.data,
          merchantId: action?.payload?.merchantId || '',
        },
      };
    }

    case UPDATE_LIST_SUGGEST_COUPON: {
      let listSuggestCouponTmp = state.listCouponSuggest.suggestedCouponDetails;
      listSuggestCouponTmp.forEach(item => {
        if (item.couponId === action.payload) {
          item.taken = true;
        }
      });
      return {
        ...state,
        listCouponSuggest: listSuggestCouponTmp,
      };
    }
    case GET_LIST_VIDEO_STORE_SUCCESS: {
      return {
        ...state,
        listVideoStore: action.payload,
      };
    }
    case GET_LIST_VIDEO_STORE_FAILED: {
      return {
        ...state,
        error: action.payload,
      };
    }

    case SET_LIST_COUPON_SUGGEST_FOR_STORE:
      var storeId =
          action.payload?.storeId || state?.listCouponSuggest?.storeId || '',
        merchantId =
          action.payload?.merchantId ||
          state?.listCouponSuggest?.merchantId ||
          '';
      var arrMaster = action.payload;
      arrMaster.storeId = storeId;
      arrMaster.merchantId = merchantId;
      return {
        ...state,
        listCouponSuggest: arrMaster,
      };

    case UPDATE_LIST_COUPON_SUGGEST_FOR_STORE:
      var storeId = state?.listCouponSuggest?.storeId || '',
        merchantId = state?.listCouponSuggest?.merchantId || '';
      var arrMaster = state.listCouponSuggest.map((coupon, index) => {
        if (coupon.couponId === action.payload.couponId) {
          coupon.isAllocated = true;
          coupon.transactionCode = action.payload.transactionCode;
        }
        return coupon;
      });
      arrMaster.storeId = storeId;
      arrMaster.merchantId = merchantId;
      return {
        ...state,
        listCouponSuggest: arrMaster,
      };
    default:
      return state;
  }
};

export default storeDetail;
