import { put, takeLatest, fork } from "redux-saga/effects";
import { GET_MERCHANT_INFO_BY_ID_REQUEST } from "./constants";
import {
  getMerchantInfoByIdFailed,
  getMerchantInfoByIdSuccess,
} from "./actions";
import { getMerchantInfoByIdApi } from "@shared/services/api/merchant";
import { merchantInfoParser } from "./parser";
import { callApi } from "helpers/callApi";

export function* getMerchantInfoByIdSaga(obj) {
  console.log("obj obj", obj);
  const { onSuccess, merchantId, onFailed } = obj;
  try {
    const res = yield callApi(getMerchantInfoByIdApi, { merchantId });
    let result;
    result = merchantInfoParser(res.data);
    yield put(getMerchantInfoByIdSuccess(result));
    if (typeof onSuccess === "function") {
      onSuccess(result);
    }
  } catch (err) {
    if (typeof onFailed === "function") {
      onFailed(err);
    }
    yield put(getMerchantInfoByIdFailed(err));
  }
}

function* watchStore() {
  yield takeLatest(GET_MERCHANT_INFO_BY_ID_REQUEST, getMerchantInfoByIdSaga);
}

export default function* rootChild() {
  yield fork(watchStore);
}
