import { put, select, takeLatest } from "redux-saga/effects";
import {
  COUNT_REFERRAL,
  GET_REFERRAL_REWARD,
  GET_REFERRAL_REFERRED,
} from "./constants";
import { callApi } from "helpers/callApi";
import {
  countReferralApi,
  getListReferredApi,
  getListRewardApi,
} from "@shared/services/api/referral";
import {
  countReferralFailed,
  countReferralSuccess,
  getReferralRewardSuccess,
  getReferralRewardFailed,
  getListReferredSuccess,
  getListReferredFailed,
} from "./actions";
import { parseProcessing, parseListReward, parseListReferred } from "./parser";
import { getListReferredSelector } from "./selectors";
import { pushPagingData } from "helpers/pagings";

export function* countReferralSaga(obj) {
  const { payload = {} } = obj;
  try {
    const res = yield callApi(countReferralApi, payload);
    console.log(res);
    const dataParse = parseProcessing(res.data);
    yield put(countReferralSuccess(dataParse));
  } catch (err) {
    yield put(countReferralFailed(err));
  }
}

export function* getReferralRewardSaga() {
  try {
    const res = yield callApi(getListRewardApi);
    const dataParse = parseListReward(res.data);
    yield put(getReferralRewardSuccess(dataParse));
  } catch (err) {
    yield put(getReferralRewardFailed(err));
  }
}

export function* getListReferredSaga({ payload }) {
  const { page = 1, limit = 10 } = payload || {};
  try {
    const res = yield callApi(getListReferredApi, { page, limit });
    const dataParse = parseListReferred(res.data, { page });

    let newList = dataParse;
    if (dataParse.pagination.currentPage > 1) {
      const dataSource = yield select((state) =>
        getListReferredSelector(state)
      );
      newList = pushPagingData(dataSource, dataParse);
    }
    yield put(getListReferredSuccess(newList));
  } catch (err) {
    yield put(getListReferredFailed(err));
  }
}

export default function* rootChild() {
  yield takeLatest(COUNT_REFERRAL.REQUEST, countReferralSaga);
  yield takeLatest(GET_REFERRAL_REWARD.REQUEST, getReferralRewardSaga);
  yield takeLatest(GET_REFERRAL_REFERRED.REQUEST, getListReferredSaga);
}
