import {store} from '@shared/redux/configureStore';
import {toggleRequestLogin} from '@shared/redux/common/actions';

const checkGuestPopup = () => {
  if (store.getState().account.sessionId === '') {
    store.dispatch(toggleRequestLogin(true));
  }
};
const checkGuest = onComplete => {
  if (store.getState().account.sessionId === '') {
    return onComplete(true);
  }
  return onComplete(false);
};
export {checkGuestPopup, checkGuest};
