import { callApi } from "helpers/callApi";
import { takeLatest, fork, put } from "redux-saga/effects";
import types from "./types";
import { searchStallsRequestApi } from "@shared/services/api/market";
import {
  searchStallsRequestSuccess,
  searchStallsRequestFailed,
} from "./actions";
export function* searchStallsRequestSaga(obj) {
  const { payload, onSuccess, onFailed } = obj;
  try {
    const res = yield callApi(searchStallsRequestApi, payload);
    yield put(
      searchStallsRequestSuccess({
        skip: payload.skip + payload.take || 0,
        data: res.data,
        merchantId: payload.merchantId,
        storeId: payload.storeId,
        valueSearch: payload.valueSearch,
        isLoadNew: payload.skip === 0 ? true : false,
      })
    );
    onSuccess?.(res);
  } catch (err) {
    yield put(searchStallsRequestFailed(err));
    onFailed?.(err);
  }
}
function* watchCommon() {
  yield takeLatest(types.SEARCH_STALLS, searchStallsRequestSaga);
}
export default function* rootChild() {
  yield fork(watchCommon);
}
