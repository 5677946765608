/* eslint-disable no-param-reassign */
import { addErrors, delErrors } from "utils/ErrorsGenerator";
import * as TYPE from "../constants/constants";

const reducers = {
  [TYPE.SET_LOADING]: (draft, { payload }) => {
    return {
      ...draft,
      isLoading: payload,
    };
  },
  [TYPE.SET_OFFLINE]: (draft, { payload }) => {
    return {
      ...draft,
      isOffline: payload,
    };
  },

  [TYPE.GET_USER_INFO.success]: (draft, { payload }) => {
    draft.errors = delErrors(draft.errors, TYPE.GET_USER_INFO.failure);
    draft.userInfo = {
      displayName: payload.displayName,
      email: payload.email,
      phoneNumber: payload.phoneNumber,
      photoURL: payload.photoURL,
      userId: payload.uid,
      providerData: payload.providerData,
    };
  },

  [TYPE.GET_USER_INFO.failure]: (draft, { payload }) => {
    draft.errors = addErrors(draft.errors, {
      type: TYPE.GET_USER_INFO.failure,
      msg: payload,
    });
  },

  [TYPE.SET_USER_INFO]: (draft, { payload }) => {
    draft.userInfo = {
      displayName: payload.displayName,
      email: payload.email,
      phoneNumber: payload.phoneNumber,
      photoURL: payload.photoURL,
      userId: payload.uid,
      providerData: payload.providerData,
    };
  },

  [TYPE.SIGN_IN_WITH_CUSTOM_TOKEN.success]: (draft) => {
    draft.errors = delErrors(
      draft.errors,
      TYPE.SIGN_IN_WITH_CUSTOM_TOKEN.failure
    );
  },

  [TYPE.SIGN_IN_WITH_CUSTOM_TOKEN.failure]: (draft, { payload }) => {
    draft.errors = addErrors(draft.errors, {
      type: TYPE.SIGN_IN_WITH_CUSTOM_TOKEN.failure,
      msg: payload,
    });
  },

  [TYPE.UPLOAD_AVATAR.success]: (draft, { payload }) => {
    draft.errors = delErrors(draft.errors, TYPE.UPLOAD_AVATAR.failure);
    draft.userInfo = {
      ...draft.userInfo,
      photoURL: payload,
    };
  },
  [TYPE.UPLOAD_AVATAR.failure]: (draft, { payload }) => {
    draft.errors = addErrors(draft.errors, {
      type: TYPE.UPLOAD_AVATAR.failure,
      msg: payload,
    });
  },

  [TYPE.UPDATE_USER_INFO.success]: (draft, { payload }) => {
    draft.errors = delErrors(draft.errors, TYPE.UPDATE_USER_INFO.failure);
    if (payload.displayName) {
      draft.userInfo = {
        ...draft.userInfo,
        displayName: payload.displayName,
      };
    }
  },
  [TYPE.UPDATE_USER_INFO.failure]: (draft, { payload }) => {
    draft.errors = addErrors(draft.errors, {
      type: TYPE.UPDATE_USER_INFO.failure,
      msg: payload,
    });
  },

  [TYPE.CHANGE_LAYOUT]: (draft, { payload }) => {
    return {
      ...draft,
      ...payload,
    };
  },

  [TYPE.SET_USER]: (draft, { payload }) => {
    return {
      ...draft,
      user: payload,
    };
  },

  [TYPE.SEARCH_LIST.success]: (draft, { payload }) => {
    return {
      ...draft,
      errors: delErrors(draft.errors, TYPE.SEARCH_LIST.failure),
      listFeed: {
        value: [...draft.listFeed.value, ...payload.value],
        total: payload?.[`@odata.count`],
      },
    };
  },
  [TYPE.SEARCH_LIST.failure]: (draft, { payload }) => {
    draft.errors = addErrors(draft.errors, {
      type: TYPE.SEARCH_LIST.failure,
      msg: payload,
    });
  },

  // * SUGGESTION
  [TYPE.GET_SEARCH_SUGGESTION.success]: (draft, { payload }) => {
    return {
      ...draft,
      errors: delErrors(draft.errors, TYPE.GET_SEARCH_SUGGESTION.failure),
      listSearchSuggestion: {
        value: payload.value,
        total: payload?.[`@odata.count`],
      },
    };
  },
  [TYPE.GET_SEARCH_SUGGESTION.failure]: (draft, { payload }) => {
    draft.errors = addErrors(draft.errors, {
      type: TYPE.GET_SEARCH_SUGGESTION.failure,
      msg: payload,
    });
  },

  [TYPE.SAVE_FILTER_DATA]: (draft, { payload }) => {
    return {
      ...draft,
      chosenFilterData: payload,
    };
  },

  [TYPE.SET_SEARCHTERMS]: (draft, { payload }) => {
    return {
      ...draft,
      searchTerms: payload,
    };
  },

  [TYPE.SHOW_ELEMENTS]: (draft, { payload }) => {
    return {
      ...draft,
      displayElements: payload,
    };
  },

  [TYPE.CLEAR_SEARCH_RESULT]: (draft) => {
    return {
      ...draft,
      listFeed: {
        value: [],
        total: 0,
      },
    };
  },
};

export default reducers;
