import {
  GET_LIST_CATEGORY_REQUEST,
  GET_LIST_CATEGORY_FAILED,
  GET_LIST_CATEGORY_SUCCESS,
  GET_LIST_PRODUCT_BY_CATEGORY_REQUEST,
  GET_LIST_PRODUCT_BY_CATEGORY_FAILED,
  GET_LIST_PRODUCT_BY_CATEGORY_SUCCESS,
} from './constants';

export const getListProductByCategoryRequest = (
  payload,
  onSuccess,
  onError,
) => ({
  type: GET_LIST_PRODUCT_BY_CATEGORY_REQUEST,
  payload,
  onSuccess,
  onError,
});

export const getListProductByCategorySuccess = payload => ({
  type: GET_LIST_PRODUCT_BY_CATEGORY_SUCCESS,
  payload,
});

export const getListProductByCategoryFailed = error => ({
  type: GET_LIST_PRODUCT_BY_CATEGORY_FAILED,
  error,
});

export const getListCategoryRequest = (payload, onSuccess, onError) => ({
  type: GET_LIST_CATEGORY_REQUEST,
  payload,
  onSuccess,
  onError,
});

export const getListCategorySuccess = payload => ({
  type: GET_LIST_CATEGORY_SUCCESS,
  payload,
});

export const getListCategoryFailed = error => ({
  type: GET_LIST_CATEGORY_FAILED,
  error,
});
