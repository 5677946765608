import { put, takeLatest, fork, call } from "redux-saga/effects";
import { APIUtils } from "utils";
import { myVoucherCodeFailed, myVoucherCodeSuccess } from "./actions";
import { getMyVoucherDetailApi } from "@shared/services/api/myVoucher";
import { MY_VOUCHER_CODE_REQUEST } from "./constants";

export function* myVoucherCodeSaga(obj) {
  const { payload = {}, onSuccess } = obj;
  try {
    const renewToken = yield APIUtils.reauthenticate();
    const data = yield call(getMyVoucherDetailApi, payload, renewToken);
    if (data.status === 200) {
      yield put(myVoucherCodeSuccess(data?.data));
      if (typeof onSuccess === "function") {
        onSuccess(data?.data);
      }
    } else {
      yield put(myVoucherCodeFailed(data));
    }
  } catch (err) {
    yield put(myVoucherCodeFailed(err));
  }
}

function* watchMyVoucherDetail() {
  yield takeLatest(MY_VOUCHER_CODE_REQUEST, myVoucherCodeSaga);
}

export default function* rootChild() {
  yield fork(watchMyVoucherDetail);
}
