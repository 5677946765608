import { put, takeLatest, fork, call, select } from "redux-saga/effects";
import {
  GET_LIST_COUPON_FOR_VOUCHER_REQUEST,
  GET_COUPON_DETAIL_FOR_VOUCHER_REQUEST,
  SEARCH_COUPON_DETAIL_FOR_VOUCHER_REQUEST,
  GET_AVAILABLE_COUPON_REQUEST,
} from "./constants";
import {
  getListCouponForVoucherSuccess,
  getListCouponForVoucherFailed,
  getCouponDetailForVoucherSuccess,
  getCouponDetailForVoucherFailed,
  searchCouponDetailForVoucherSuccess,
  searchCouponDetailForVoucherFailed,
  getAvailableCouponSuccess,
  getAvailableCouponFailed,
} from "./actions";
import {
  getListCouponForVoucherApi,
  getCouponDetailForVoucherApi,
  searchCouponDetailForVoucherApi,
  getAvailableCouponApi,
} from "@shared/services/api/couponForVoucher";
import {
  listCouponForVoucherParser,
  couponDetailForVoucherParser,
  searchCouponDetailForVoucherParser,
  getAvailableCouponParser,
} from "./parser";
import { callApi } from "helpers/callApi";

export function* getListCouponForVoucherSaga(obj) {
  const { payload = {}, onSuccess } = obj;
  try {
    const res = yield callApi(getListCouponForVoucherApi, payload);
    if (res.data.length > 0) {
      yield put(
        getListCouponForVoucherSuccess({
          data: listCouponForVoucherParser(res),
        })
      );
    } else {
      yield put(
        getListCouponForVoucherSuccess({
          data: { noResult: true },
        })
      );
    }

    if (typeof onSuccess === "function") {
      onSuccess(res);
    }
  } catch (err) {
    yield put(getListCouponForVoucherFailed(err));
  }
}

export function* getCouponDetailForVoucherSaga(obj) {
  const { payload = {}, onSuccess, onFailed } = obj;
  try {
    const res = yield callApi(getCouponDetailForVoucherApi, {
      ...payload,
    });
    if (res.data.length > 0) {
      yield put(
        getCouponDetailForVoucherSuccess({
          data: couponDetailForVoucherParser(res),
        })
      );
    } else {
      yield put(
        getCouponDetailForVoucherSuccess({
          data: { noResult: true },
        })
      );
    }

    if (typeof onSuccess === "function") {
      onSuccess(res);
    }
  } catch (err) {
    onFailed(err);
    yield put(getCouponDetailForVoucherFailed(err));
  }
}

export function* searchCouponDetailForVoucherSaga(obj) {
  const { payload = {}, onSuccess } = obj;
  try {
    const res = yield callApi(searchCouponDetailForVoucherApi, {
      ...payload,
    });

    yield put(
      searchCouponDetailForVoucherSuccess({
        data: searchCouponDetailForVoucherParser(res),
      })
    );
    if (typeof onSuccess === "function") {
      onSuccess(res);
    }
  } catch (err) {
    yield put(searchCouponDetailForVoucherFailed(err));
  }
}

export function* getAvailableCouponSaga(obj) {
  const { payload = {}, onSuccess, onFailed } = obj;
  try {
    const res = yield callApi(getAvailableCouponApi, payload);

    yield put(
      getAvailableCouponSuccess({
        data: getAvailableCouponParser(res),
      })
    );
    if (typeof onSuccess === "function") {
      onSuccess(res);
    }
  } catch (err) {
    onFailed(err);
    yield put(getAvailableCouponFailed(err));
  }
}

function* watchProduct() {
  yield takeLatest(
    GET_LIST_COUPON_FOR_VOUCHER_REQUEST,
    getListCouponForVoucherSaga
  );
  yield takeLatest(
    GET_COUPON_DETAIL_FOR_VOUCHER_REQUEST,
    getCouponDetailForVoucherSaga
  );
  yield takeLatest(
    SEARCH_COUPON_DETAIL_FOR_VOUCHER_REQUEST,
    searchCouponDetailForVoucherSaga
  );
  yield takeLatest(GET_AVAILABLE_COUPON_REQUEST, getAvailableCouponSaga);
}

export default function* rootChild() {
  yield fork(watchProduct);
}
