// Add Errors
/**
 * Add Error
 * @param {null | Array<Object>} errors List errors
 * @param {Object} itemErr Error itemErr
 * @return {Array<Object>}
 */
export const addErrors = (errors = null, itemErr = {}) => {
  if (errors === null) {
    return [itemErr];
  }

  const errorExists = errors.findIndex(err => err.type === itemErr.type);

  let newErrors = errors;
  if (errorExists !== -1) {
    newErrors = errors.filter((err) => err.type !== itemErr.type);
  }

  return [...newErrors, itemErr];
};

/**
 * Remove Error
 * @param {null | Array<Object>} errors List errors
 * @param {String} errType Error Type
 * @return {null | Array<Object>}
 */
export const delErrors = (errors = null, errType = "") => {
  if (errors === null) {
    return null;
  }

  if(Array.isArray(errType)) {
    return errors.find((err) => !errType.includes(err.type));
  }

  return errors.filter((err) => err.type !== errType);
};

/**
 * Get error
 * @param {null | Array<Object>} errors List errors
 * @param {String | Array<String>} errType Error Type
 * @return {undefined | Object} If found is return Object, else undefined
 */
export const getError = (errors = null, errType = "") => {
  if (errors === null) {
    return null;
  }

  if (Array.isArray(errType)) {
    return errors.find((err) => errType.includes(err.type));
  }

  return errors.find((err) => err.type === errType);
};
