import { put, takeLatest, fork } from "redux-saga/effects";
import {
  GET_MOBILE_CARD_LIST_REQUEST,
  GET_BUY_CARD_LIST_REQUEST,
  CREATE_ORDER_REQUEST,
  PAYMENT_REQUEST,
} from "./constants";
import {
  getMobileCardListSuccess,
  getMobileCardListFailed,
  getBuyCardListSuccess,
  getBuyCardListFailed,
  createOrderSuccess,
  createOrderFailed,
  paymentSuccess,
  paymentFailed,
} from "./actions";
import {
  getMobileCardListApi,
  createOrderApi,
  paymentApi,
} from "@shared/services/api/mobileCard";

import { callApi } from "helpers/callApi";

export function* getMobileCardListSaga(obj) {
  const { payload, onSuccess, onFailed } = obj;
  try {
    const res = yield callApi(getMobileCardListApi, payload);
    yield put(getMobileCardListSuccess(res));
    if (typeof onSuccess === "function") {
      onSuccess(res);
    }
  } catch (err) {
    if (typeof onFailed === "function") {
      onFailed(err);
    }
    yield put(getMobileCardListFailed(err));
  }
}

export function* getBuyCardListSaga(obj) {
  const { payload, onSuccess, onFailed } = obj;
  try {
    const res = yield callApi(getMobileCardListApi, payload);
    yield put(getBuyCardListSuccess(res));
    if (typeof onSuccess === "function") {
      onSuccess(res);
    }
  } catch (err) {
    if (typeof onFailed === "function") {
      onFailed(err);
    }
    yield put(getBuyCardListFailed(err));
  }
}

export function* createOrderSaga(obj) {
  const { payload, onSuccess, onFailed } = obj;
  try {
    const res = yield callApi(createOrderApi, payload);
    yield put(createOrderSuccess(res));
    if (typeof onSuccess === "function") {
      onSuccess(res);
    }
  } catch (err) {
    if (typeof onFailed === "function") {
      onFailed(err);
    }
    yield put(createOrderFailed(err));
  }
}

export function* paymentSaga(obj) {
  const { payload, onSuccess, onFailed } = obj;
  try {
    const res = yield callApi(paymentApi, payload);
    yield put(paymentSuccess(res));
    if (typeof onSuccess === "function") {
      onSuccess(res);
    }
  } catch (err) {
    if (typeof onFailed === "function") {
      onFailed(err);
    }
    yield put(paymentFailed(err));
  }
}

function* watchProduct() {
  yield takeLatest(GET_MOBILE_CARD_LIST_REQUEST, getMobileCardListSaga);
  yield takeLatest(GET_BUY_CARD_LIST_REQUEST, getBuyCardListSaga);
  yield takeLatest(CREATE_ORDER_REQUEST, createOrderSaga);
  yield takeLatest(PAYMENT_REQUEST, paymentSaga);
}

export default function* rootChild() {
  yield fork(watchProduct);
}
