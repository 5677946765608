import React, { useState } from "react";
import { NavigationServices } from "utils";
import Popup from "views/common/component/Popup";
import AlertAction from "views/common/component/AlertAction";
import TheHeaderHome from "./components/TheHeaderSearch/TheHeaderHome";
import SearchResult from "./components/SearchResult/SearchResult";
import OrderMenu from "../MarketHomeView/components/OrderMenu";
import "./MarketSearch.scss";
import CartBottomBar from "../MarketHomeView/components/CartBottomBar";
import QuantityMenu from "../MarketHomeView/components/QuantityMenu";
import EditMenu from "../MarketHomeView/components/EditMenu";

const MarketSearchView = (props) => {
  const [showOrderMenu, setShowOrderMenu] = useState(false);
  const [chosenProduct, setChosenProduct] = useState();
  const [showQuantityMenu, setShowQuantityMenu] = useState(false);
  const [showEditMenu, setShowEditMenu] = useState(false);
  const [itemInCartToEdit, setItemInCartToEdit] = useState();

  const goBack = () => {
    NavigationServices.goBack();
  };

  const handleOpenMenuOrder = (product, type) => () => {
    if (
      props.dataCart &&
      props.dataCart.length > 0 &&
      props.dataCart.filter((f) => f.dataInfoProduct?.productId === product?.id)
        .length > 0 &&
      !type
    ) {
      setChosenProduct(
        props.dataCart.filter(
          (f) => f.dataInfoProduct?.productId === product?.id
        )
      );
      setShowQuantityMenu(true);
    } else {
      setChosenProduct((prevChosenProduct) => {
        if (prevChosenProduct?.id === product.id) {
          return prevChosenProduct;
        }
        const found = product;

        return found;
      });
      setShowOrderMenu(true);
    }
  };

  const handleCloseMenuOrder = () => {
    setShowOrderMenu(false);
    setTimeout(() => {
      setChosenProduct(undefined);
    }, 500);
  };

  const handleCloseQuantityMenu = () => {
    setShowQuantityMenu(false);

    // set time out để tránh hiện tượng giật cục khi đóng popup
    // chú thích ở hàm handleCloseMenuOrder
    setTimeout(() => {
      // setItemsToUpdateQuantity([]);
    }, 500);
  };

  const handleOpenEditMenu = (itemData) => () => {
    // setShowQuantityMenu(false);

    // bên trong hàm này có set lại itemsToUpdateQuantity về []
    // tránh được case:
    // đang mở QuantityMenu -> set quantity về 0 -> ấn nút "Thay đổi" để vào EditMenu -> tắt EditMenu -> mở lại QuantityMenu thì còn các items cũ (state cũ là 0)
    handleCloseQuantityMenu();

    const itemToEdit = props?.dataCart.find(
      (item) => item.dataInfoProduct?.index === itemData?.dataInfoProduct?.index
    );

    // const originalDataOfItemToEdit = genOriginalDataOfProductToStoreInCart(
    //   productList?.find((product) => product.id === itemToEdit.productId)
    // );

    setItemInCartToEdit({
      ...itemToEdit,
    });
    setShowEditMenu(true);
  };

  const handleCloseEditMenu = () => {
    if (showQuantityMenu) {
      setShowQuantityMenu(false);
    }
    setShowEditMenu(false);

    // set time out để tránh hiện tượng giật cục khi đóng popup
    // chú thích ở hàm handleCloseMenuOrder
    setTimeout(() => {
      setItemInCartToEdit(undefined); // must have if not want to get bug
    }, 400);
  };

  const calDiscount = (listPrice, price) => {
    let percentDiscount = 0;
    if (!!listPrice && !!price) {
      percentDiscount = 100 - Math.round((price / listPrice) * 100);
      if (percentDiscount === 100) {
        percentDiscount -= 1;
      }
    }
    return percentDiscount;
  };

  return (
    <div className="market-search-wrapper">
      <TheHeaderHome
        onSearchPage
        goBack={goBack}
        _onSearchProduct={props._onSearchProduct}
        _onClearSearchtext={props._onClearSearchtext}
        listSearchHistory={props.listSearchHistory}
        txtSearch={props.txtSearch}
        placeholder={props.placeholder}
        clearSearchHistory={props.clearSearchHistory}
      />
      <SearchResult
        dataProducts={props.dataProducts}
        loadMore={props.loadMore}
        updateCart={props.updateCart}
        updateProductCart={props.updateProductCart}
        dataCart={props.dataCart}
        onSubmit={props.onSubmit}
        deleteProductCart={props.deleteProductCart}
        isShowLoading={props.isShowLoading}
        storeInfo={props.storeInfo}
        isEndSearchMarket={props.isEndSearchMarket}
        isCloseStore={props.isCloseStore}
        flatRef={props.flatRef}
        dataStoreMerchant={props.dataStoreMerchant}
        handleOpenMenuOrder={handleOpenMenuOrder}
      />
      <div className="cart-bottom">
        <CartBottomBar
          // to="/order"
          eventHandle={() =>
            NavigationServices.navigate("CartMarket", {
              isFromMarket: true,
              storeInfo: props.storeInfo,
            })
          }
          totalPrice={
            props.dataCart.length > 0
              ? props.dataCart
                  .map((i) => i.dataInfoProduct.totalPrice)
                  .reduce((a, b) => a + b)
              : 0
          }
          itemsCount={
            props.dataCart.length > 0
              ? props.dataCart
                  .map((i) => i.dataInfoProduct.quantity)
                  .reduce((a, b) => a + b)
              : 0
          }
          className="store-detail-page__cart-bottom-bar"
          text="Xem giỏ hàng"
          disabled={props.dataCart.length > 0 && !props.isCloseStore ? 0 : 1}
          // todo: check thêm khi ấn nút này, có thể bị phá khi mở devtool
        />
      </div>
      <Popup
        isOpen={showOrderMenu}
        handleClose={handleCloseMenuOrder}
        // showButtonClose={false}
      >
        {chosenProduct && (
          <OrderMenu
            showOrderMenu={showOrderMenu}
            storeId={props.storeInfo?.storeId}
            // merchantId={storeInfo?.data?.merchant?.iv?.[0]}
            id={chosenProduct?.id}
            // code={chosenProduct?.data?.code?.iv}
            imageUrl={chosenProduct?.image}
            imageUrls={chosenProduct?.imageUrls}
            name={chosenProduct?.name?.vi}
            price={chosenProduct?.price?.default}
            listPrice={chosenProduct?.price?.list}
            description={chosenProduct?.description?.default?.vi}
            attributes={chosenProduct.attributes}
            sizes={chosenProduct?.sizes}
            toppings={chosenProduct?.toppings}
            rawData={chosenProduct}
            // functions
            handleClose={handleCloseMenuOrder}
            updateCart={props.updateCart}
            isStoreClosed={props.isCloseStore}
            itemCart={
              props?.dataCart.length > 0
                ? props?.dataCart[props?.dataCart.length - 1].dataInfoProduct
                    .index
                : 0
            }
            // handleTempSaveInfoOfItemToAdd={handleTempSaveInfoOfItemToAdd}
            // handleToggleModal={handleToggleModal}
            saleTagUrl={chosenProduct?.saleTagUrl}
            percentDiscount={calDiscount(
              chosenProduct?.price?.list,
              chosenProduct?.price?.default
            )}
          />
        )}
      </Popup>
      <Popup
        isOpen={showQuantityMenu}
        handleClose={handleCloseQuantityMenu}
        // showButtonClose={false}
      >
        {showQuantityMenu && chosenProduct && (
          <QuantityMenu
            // productId={itemsToUpdateQuantity?.[0].productId}
            items={chosenProduct}
            // * handle popups
            handleOpenMenuOrder={handleOpenMenuOrder}
            handleOpenEditMenu={handleOpenEditMenu}
            handleCloseQuantityMenu={handleCloseQuantityMenu}
            // * handle actions
            handleUpdateQuantityOfItem={props.updateProductCart}
            handleRemoveItemInCart={props.deleteProductCart}
          />
        )}
      </Popup>
      <Popup
        isOpen={showEditMenu}
        handleClose={handleCloseEditMenu}
        // showButtonClose={false}
      >
        {itemInCartToEdit && (
          <EditMenu
            showEditMenu={showEditMenu}
            itemId={itemInCartToEdit?.dataInfoProduct?.productId}
            chosenAttributes={itemInCartToEdit?.dataInfoProduct?.attributes}
            chosenToppings={itemInCartToEdit?.dataInfoProduct?.toppings}
            chosenSize={itemInCartToEdit?.dataInfoProduct?.sizeOption}
            chosenQuantity={itemInCartToEdit?.dataInfoProduct?.quantity}
            itemPrice={itemInCartToEdit?.data?.price?.default}
            // originalData
            itemData={itemInCartToEdit}
            id={itemInCartToEdit?.data?.id}
            code={itemInCartToEdit?.data?.code}
            imageUrl={itemInCartToEdit?.data?.image}
            imageUrls={itemInCartToEdit?.data?.imageUrls}
            name={itemInCartToEdit?.data?.name?.vi}
            price={itemInCartToEdit?.data?.price?.default}
            listPrice={itemInCartToEdit?.data?.price?.list}
            description={itemInCartToEdit?.data?.description?.default}
            sizes={itemInCartToEdit?.data?.sizes}
            attributes={itemInCartToEdit?.data?.attributes}
            toppings={itemInCartToEdit?.data?.toppings}
            handleClose={handleCloseEditMenu}
            handleCloseQuantityMenu={handleCloseQuantityMenu}
            handleUpdateItem={props.updateCart}
            updateCart={props.updateProductCart}
          />
        )}
      </Popup>
      <AlertAction
        isOpen={props?.dataAlertAction?.isShow}
        content={props?.dataAlertAction?.content}
        title={props?.dataAlertAction?.title}
        titleAccept={props?.dataAlertAction?.titleAccept}
        titleCancel={props?.dataAlertAction?.titleCancel}
        handleClose={props?.setDataAlertAction}
        handleAccept={props?.handleAlertAction}
        dataItem={props?.dataAlertAction?.dataItem}
      />
    </div>
  );
};

export default MarketSearchView;
