import types from './types';

const initialState = {
  listProductSearchStalls: null,
  listSearchHistory: [],
  skip: 0,
  isEndSearchStalls: false,
};
function uniq(a, key) {
  let seen = new Set();
  return a.filter(item => {
    let k = key(item).toLowerCase();
    return seen.has(k) ? false : seen.add(k);
  });
}
const cook = (dataOld, valueSearch) => {
  let listSearchHistory = dataOld;
  if (!!valueSearch) {
    listSearchHistory.unshift(valueSearch);
  }
  listSearchHistory = uniq(listSearchHistory, x => x);
  listSearchHistory = listSearchHistory.slice(0, 10);

  return listSearchHistory;
};
const marketStore = (state = initialState, action) => {
  const {payload, type} = action;

  switch (type) {
    case types.SET_IS_END_SEARCH_MARKET_1: {
      return {
        ...state,
        listSearchHistory: cook(state.listSearchHistory, action.payload),
      };
    }
    case types.CLEAR_SEARCH_STALLS: {
      return {
        ...state,
        listProductSearchStalls: null,
        skip: 0,
        isEndSearchStalls: false,
      };
    }
    case types.CLEAR_SEARCH_STALLS_HISTORY: {
      return {
        ...state,
        listSearchHistory: [],
      };
    }
    case types.SEARCH_STALLS_SUCCESS: {
      return {
        ...state,
        listProductSearchStalls: action.listProductSearchStalls.isLoadNew
          ? action.listProductSearchStalls.data
          : state.listProductSearchStalls !== null
          ? [
              ...state.listProductSearchStalls,
              ...action.listProductSearchStalls.data,
            ]
          : action.listProductSearchStalls.data,
        skip: action.listProductSearchStalls.skip,
        listSearchHistory: cook(
          state.listSearchHistory,
          action.listProductSearchStalls.valueSearch,
        ),
        isEndSearchStalls: false,
      };
    }
    default:
      return state;
  }
};

export default marketStore;
