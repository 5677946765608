import { genActionTypes } from "utils/actionGenerator";

export const TYPE = "DeliveryAddress";

export const SET_LOADING = `${TYPE}_SET_LOADING`;
export const GET_USER_ADDRESS_BOOKS = genActionTypes(
  `${TYPE}_GET_USER_ADDRESS_BOOKS`
);
export const DELETE_AN_USER_ADDRESS_BOOK = genActionTypes(
  `${TYPE}_DELETE_AN_USER_ADDRESS_BOOK`
);
export const SET_DEFAULT_TO_AN_USER_ADDRESS_BOOK = genActionTypes(
  `${TYPE}_SET_DEFAULT_TO_AN_USER_ADDRESS_BOOK`
);
