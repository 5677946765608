import {END_POINTS} from '@shared/constants/apiUrl';

import {APIUtils} from 'utils';

export function getListInsuranceTypeApi() {
  return APIUtils.get(END_POINTS.GET_LIST_INSURANCE_TYPE_URL);
}

export function getListInsuranceWithTypeApi(payload) {
  return APIUtils.get(
    END_POINTS.GET_LIST_INSURANCE_ITEM_WITH_TYPE_URL +
      `filter?insuranceTypeId=${payload.insuranceTypeId}&skip=${payload.skip}&take=${payload.take}`,
  );
}

export function getInsuranceItemDetailApi(idInsuranceItem) {
  return APIUtils.get(
    END_POINTS.GET_INSURANCE_DETAIL_BY_ID + `/App/${idInsuranceItem}`,
  );
}
