export const GET_LIST_VIDEO_REQUEST = 'userInfo/GET_LIST_VIDEO_REQUEST';
export const GET_LIST_VIDEO_SUCCESS = 'userInfo/GET_LIST_VIDEO_SUCCESS';
export const GET_LIST_VIDEO_FAILED = 'userInfo/GET_LIST_VIDEO_FAILED';

export const GET_USER_INFO_REQUEST = 'userInfo/GET_USER_INFO_REQUEST';
export const GET_USER_INFO_SUCCESS = 'userInfo/GET_USER_INFO_SUCCESS';
export const GET_USER_INFO_FAILED = 'userInfo/GET_USER_INFO_FAILED';

export const FOLLOW_USER_REQUEST = 'userInfo/FOLLOW_USER_REQUEST';
export const FOLLOW_USER_SUCCESS = 'userInfo/FOLLOW_USER_SUCCESS';
export const FOLLOW_USER_FAILED = 'userInfo/FOLLOW_USER_FAILED';

export const UN_FOLLOW_USER_REQUEST = 'userInfo/UN_FOLLOW_USER_REQUEST';
export const UN_FOLLOW_USER_SUCCESS = 'userInfo/UN_FOLLOW_USER_SUCCESS';
export const UN_FOLLOW_USER_FAILED = 'userInfo/UN_FOLLOW_USER_FAILED';
