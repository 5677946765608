import {GET_LIST_IMAGES_NEWS} from './constants';

export const getListImagesTypeHandle = (
  skip,
  take,
  editionId,
  onSuccess,
  onFailed,
) => {
  return {
    type: GET_LIST_IMAGES_NEWS.HANDLER,
    skip,
    take,
    editionId,
    onSuccess,
    onFailed,
  };
};

export const getListImagesTypeSuccess = payload => ({
  type: GET_LIST_IMAGES_NEWS.SUCCESS,
  payload,
});

export const getListImagesTypeFailure = error => ({
  type: GET_LIST_IMAGES_NEWS.FAILURE,
  error,
});
