import { genActions } from "utils/actionGenerator";
import * as TYPE from "./constants";

const actions = {
  VOUCHER_DETAIL: genActions(TYPE.VOUCHER_DETAIL),
  VOUCHER_INFOMATION: genActions(TYPE.VOUCHER_INFOMATION),
  CLICK_BY_MARK_USED: genActions(TYPE.CLICK_BY_MARK_USED),
  VOUCHER_PRODUCT_INFORMATION: genActions(TYPE.VOUCHER_PRODUCT_INFORMATION),
};

export default actions;
