/* eslint-disable no-param-reassign */
import { toast } from "react-toastify";
import { addErrors, delErrors } from "utils/ErrorsGenerator";
import { toastOptions } from "utils/toast";
import * as TYPE from "./constants";

const reducers = {
  // * GET_LIST_CANCEL_REASON
  [TYPE.GET_LIST_CANCEL_REASON.request]: (draft) => {
    draft.isLoading.GET_LIST_CANCEL_REASON = true;
  },
  [TYPE.GET_LIST_CANCEL_REASON.success]: (draft, { payload }) => {
    draft.errors = delErrors(draft.errors, TYPE.GET_LIST_CANCEL_REASON.failure);
    draft.listCancelReason = payload;
    draft.isLoading.GET_LIST_CANCEL_REASON = false;
  },
  [TYPE.GET_LIST_CANCEL_REASON.failure]: (draft, { payload }) => {
    draft.errors = addErrors(draft.errors, {
      type: TYPE.GET_LIST_CANCEL_REASON.failure,
      msg: payload,
    });
    draft.isLoading.GET_LIST_CANCEL_REASON = false;
  },

  // * CANCEL_FNB
  [TYPE.CANCEL_FNB.request]: (draft) => {
    draft.isLoading.CANCEL_FNB = true;
  },
  [TYPE.CANCEL_FNB.success]: (draft) => {
    toast.success("Hủy đơn hàng thành công!", toastOptions);
    draft.errors = delErrors(draft.errors, TYPE.CANCEL_FNB.failure);
    draft.isLoading.CANCEL_FNB = false;
  },
  [TYPE.CANCEL_FNB.failure]: (draft, { payload }) => {
    toast.error("Hủy đơn hàng thất bại!", toastOptions);
    draft.errors = addErrors(draft.errors, {
      type: TYPE.CANCEL_FNB.failure,
      msg: payload,
    });
    draft.isLoading.CANCEL_FNB = false;
  },
};

export default reducers;
