import {GET_EDITION_RENEW} from './constants';

export const getEditionRenewTypeHandle = (
  editionId,
  newspaperId,
  onSuccess,
  onFailed,
) => {
  return {
    type: GET_EDITION_RENEW.HANDLER,
    editionId,
    newspaperId,
    onSuccess,
    onFailed,
  };
};

export const getEditionRenewTypeSuccess = payload => ({
  type: GET_EDITION_RENEW.SUCCESS,
  payload,
});

export const getEditionRenewTypeFailure = error => ({
  type: GET_EDITION_RENEW.FAILURE,
  error,
});
