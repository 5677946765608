import { imagesIcon } from "img";
import React, { useCallback } from "react";
import DebounceInput from "views/common/component/DebounceInput";
import "./receiverInfo.scss";

export default function ReceiverInfo(props) {
  const renderHint = useCallback((hintMsg) => {
    return <div className="hint">{hintMsg}</div>;
  }, []);

  return (
    <div className="receiver-info__wrapper">
      <div className="receiver-info__heading">Thông tin người nhận</div>

      <div className="receiver-info__input-box">
        <img src={imagesIcon.userIcon} alt="userIcon" className="icon-left" />
        <DebounceInput
          type="text"
          placeholder="Họ và tên người nhận"
          defaultValue={props.valueUserName}
          onCommitChange={props.setValueUserName}
          delayTime={50}
          maxLength={60}
        />
        <div onClick={() => props.setValueUserName("")}>
          <img src={imagesIcon.CloseButton} className="icon-delete" />
        </div>
      </div>
      {!props?.valueUserName?.trim()?.length > 0 &&
        renderHint("Tên người nhận không được để trống")}
      <div className="receiver-info__input-box" style={{ marginTop: "10px" }}>
        <img src={imagesIcon.teleIcon} alt="teleIcon" className="icon-left" />
        <DebounceInput
          type="tel"
          placeholder="Số điện thoại người nhận"
          defaultValue={props.valueNumberPhone}
          onCommitChange={props.setValueNumberPhone}
          delayTime={50}
          maxLength={12}
        />
        <div onClick={() => props.setValueNumberPhone("")}>
          <img src={imagesIcon.CloseButton} className="icon-delete" />
        </div>
      </div>
      {(!props.validPhoneNumber(props?.valueNumberPhone) ||
        !Number.isInteger(props?.valueNumberPhone * 1)) &&
        renderHint("Số điện thoại không hợp lệ")}
    </div>
  );
}
