import {
  GET_LIST_BOUGHT_PACKAGE,
  GET_LIST_DATA_RECOMMEND_WITH_LEVEL_TYPE_NEWS,
  GET_LIST_RECOMMEND_LEVEL_TYPE_NEWS,
  GET_LIST_NEWS_BOUGHT,
} from './constants';

export const getListBoughtPackageTypeHandle = (
  isPurchased,
  skip,
  take,
  onSuccess,
  onFailed,
) => ({
  type: GET_LIST_BOUGHT_PACKAGE.HANDLER,
  isPurchased,
  skip,
  take,
  onSuccess,
  onFailed,
});

export const getListBoughtPackageTypeSuccess = payload => ({
  type: GET_LIST_BOUGHT_PACKAGE.SUCCESS,
  payload,
});

export const getListBoughtPackageTypeFailure = error => ({
  type: GET_LIST_BOUGHT_PACKAGE.FAILURE,
  error,
});

export const getListUnBoughtWithTypeHandle = (status, onSuccess, onFailed) => ({
  type: GET_LIST_RECOMMEND_LEVEL_TYPE_NEWS.HANDLER,
  status,
  onSuccess,
  onFailed,
});

export const getListUnBoughtWithTypeSuccess = payload => ({
  type: GET_LIST_RECOMMEND_LEVEL_TYPE_NEWS.SUCCESS,
  payload,
});

export const getListUnBoughtWithTypeHandleFailed = error => ({
  type: GET_LIST_RECOMMEND_LEVEL_TYPE_NEWS.FAILURE,
  error,
});

export const getListUnBoughtDataWithTypeHandle = (
  newspaperTypeId,
  skip,
  take,
  isPurchased,
  onSuccess,
  onFailed,
) => ({
  type: GET_LIST_DATA_RECOMMEND_WITH_LEVEL_TYPE_NEWS.HANDLER,
  newspaperTypeId,
  skip,
  take,
  isPurchased,
  onSuccess,
  onFailed,
});

export const getListUnBoughtDataWithTypeSuccess = payload => ({
  type: GET_LIST_DATA_RECOMMEND_WITH_LEVEL_TYPE_NEWS.SUCCESS,
  payload,
});

export const getListUnBoughtDataWithTypeFailed = error => ({
  type: GET_LIST_DATA_RECOMMEND_WITH_LEVEL_TYPE_NEWS.FAILURE,
  error,
});
export const getListNewsBoughtWithTypeAdd = payload => ({
  type: GET_LIST_NEWS_BOUGHT.ADD,
  payload,
});
