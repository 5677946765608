import React from "react";
import { BackgroundSearch } from "assets/svg";
import "./searchNotFound.scss";

const SearchNotFound = () => {
  return (
    <div className="search-notfound-wrapper">
      <div className="search-notfound">
        <div className="not-found-content">
          <div className="disabled-image">
            <BackgroundSearch />
          </div>
          <div className="text-notfound">Không tìm thấy kết quả phù hợp</div>
        </div>
      </div>
      {/* <div style={{ position: "fixed", bottom: 0, left: 0, right: 0 }}> */}
      {/* </div> */}
    </div>
  );
};

export default SearchNotFound;
