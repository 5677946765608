import { store } from '@shared/redux/configureStore'

export const handleResponseVideoApi = (data) => {
  if (data.message) {
    if (data.message === 'Network Error') {
      return { errors: [{ Error: 'Kết nối Internet.' }] }
    } else if (data.message === 'Request failed with status code 500') {
      return { errors: [{ Error: 'Không xác định từ server.' }] }
    } else if (data.message === 'Request failed with status code 502') {
      return { errors: [{ Error: 'Không xác định từ server.' }] }
    } else if (data.message.indexOf('timeout') !== -1) {
      return { errors: [{ Error: 'Kết nối Server.' }] }
    }
  }
  return data
}

export const checkIsGuest = (serverErrorMsg) => {
  if (store.getState().account.sessionId === '') {
    store.dispatch(toggleRequestLogin(true))
  } else {
    serverErrorMsg()
  }
}
