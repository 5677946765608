export const suggestedCouponList = [];
export const discountAmountFromAppliedCoupon = 0;
export const voucherCodeOfAppliedCoupon = undefined; // after succeed to apply coupon, we will have a voucherCode, use it to create order
export const orderInfo = undefined;

export const isLoading = {
  GET_SUGGESTED_COUPON_LIST: false,

  // CALC_COUPON_DISCOUNT: false,
  // GET_AVAILABLE_COUPONS_BY_CODE: false,
  // GET_AVAILABLE_COUPONS_OF_ALLOCATED_COUPON_TICKET: false,
  CALC_COUPON_DISCOUNT_V2: false,

  CREATE_ORDER_VOUCHER: false,
};
