import { call, put, takeLatest, fork, select } from "redux-saga/effects";
import { parseListFeed } from "helpers/parsers";
import actions from "./actions";
import * as TYPE from "./constants";
import api from "../api/api";

const sagas = {
  // *[TYPE.SEARCH_LIST.request](action) {
  //   try {
  //     const data = yield call(api[TYPE.SEARCH_LIST.request], action.payload);
  //     if (data.status === 200) {
  //       yield put(actions.SEARCH_LIST.success(data?.data));
  //     } else {
  //       yield put(actions.SEARCH_LIST.failure(data?.data));
  //     }
  //   } catch (error) {
  //     yield put(actions.SEARCH_LIST.failure(error?.data));
  //   }
  // },

  // * GET_LIST_FEED
  *[TYPE.GET_LIST_FEED.request](action) {
    const {
      searchText,
      lat,
      long,
      skip,
      take,
      localeCode = "vi",
      refresh,
    } = action.payload;

    try {
      const response = yield call(api[TYPE.GET_LIST_FEED.request], {
        searchText,
        lat,
        long,
        skip,
        take,
        refresh,
        isGrouping: true, // search thì luôn grouping, k có trường hợp nào có param merchantId nên sẽ hard code ở đây
        isFilterVisibleAtHomePage: true, // default BE là true, nhưng vẫn truyền trường hợp BE thay đổi. Rule này theo Mobile
      });

      if (response.status === 200) {
        const isInit = false;
        const nextFeeds =
          parseListFeed({ listFeed: response.data }, localeCode) || [];
        const hasMore = nextFeeds?.length === take;
        if (refresh) {
          const newListFeed = {
            value: nextFeeds,
            hasMore,
            isInit,
          };
          yield put(actions.GET_LIST_FEED.success(newListFeed));
        } else {
          const prevListFeed = yield select(getListFeed);
          const newListFeed = {
            value: [...prevListFeed?.value, ...nextFeeds],
            hasMore,
            isInit,
          };
          yield put(actions.GET_LIST_FEED.success(newListFeed));
        }
      }
    } catch (error) {
      yield put(actions.GET_LIST_FEED.failure(error?.data));
    }
  },

  // * GET_LIST_STORE_OF_MERCHANT
  *[TYPE.GET_LIST_STORE_OF_MERCHANT.request](action) {
    const {
      latitude,
      longitude,
      feedTypes,
      merchantId,
      skip,
      take = 20,
      onSuccess,
    } = action.payload;

    try {
      const response = yield call(
        api[TYPE.GET_LIST_STORE_OF_MERCHANT.request],
        {
          merchantId,
          feedTypes,
          latitude,
          longitude,
          skip,
          take,
          isGrouping: false, // get list store của merchant thì không grouping theo merchant
        }
      );

      if (response.status === 200) {
        const listStore =
          parseListFeed({ listFeed: response.data, localeCode: "vi" }) || [];
        const prevListStoreData = yield select(getListStoreOfSelectedMerchant);
        const shouldReplacePrevListStore =
          merchantId !== prevListStoreData.merchantId;
        const listStoreOfSelectedMerchant = {
          merchantId,
          listStore: shouldReplacePrevListStore
            ? listStore
            : [...prevListStoreData.listStore, ...listStore],
          hasMore: listStore.length === take,
        };

        yield put(
          actions.GET_LIST_STORE_OF_MERCHANT.success(
            listStoreOfSelectedMerchant
          )
        );

        if (onSuccess) {
          yield call(onSuccess);
        }
      } else {
        yield put(actions.GET_LIST_STORE_OF_MERCHANT.failure(response?.data));
      }
    } catch (error) {
      yield put(actions.GET_LIST_STORE_OF_MERCHANT.failure(error?.data));
    }
  },
};

function* watchedSaga() {
  // yield takeLatest(TYPE.SEARCH_LIST.request, sagas[TYPE.SEARCH_LIST.request]);

  // * GET_LIST_FEED
  yield takeLatest(
    TYPE.GET_LIST_FEED.request,
    sagas[TYPE.GET_LIST_FEED.request]
  );
  // * GET_LIST_STORE_OF_MERCHANT
  yield takeLatest(
    TYPE.GET_LIST_STORE_OF_MERCHANT.request,
    sagas[TYPE.GET_LIST_STORE_OF_MERCHANT.request]
  );
}

export default function* rootChild() {
  yield fork(watchedSaga);
}

// *** SELECTORS
const getListFeed = (state) => state[TYPE.TYPE].listFeed;
const getListStoreOfSelectedMerchant = (state) =>
  state[TYPE.TYPE].listStoreOfSelectedMerchant;
