// import React from "react";
// import arrowBack from "assets/icons/arrow-back.svg";

// import "./Header.scss";

// function StoreHeader(props) {
//   const { storeName, goBack, onRightButtonClick } = props;
//   return (
//     <div className="store-header store-header_store_detail">
//       <button
//         type="button"
//         onClick={goBack}
//         className="button__effect flex-shrink-0 _border-none _outline-none"
//         style={{ background: "transparent" }}
//       >
//         <img src={arrowBack} alt="arrow-back" width={8} height={15} />
//       </button>

//       <div
//         className="text-center text-truncate px-2 _fw-600 _fs-16px color-chinese_black"
//         style={{ alignSelf: "flex-end" }}
//       >
//         {storeName}
//       </div>
//       <div className="search-button" onClick={onRightButtonClick}>
//         {!!props.rightButton && props.rightButton()}
//       </div>
//     </div>
//   );
// }

// export default StoreHeader;
/* eslint-disable no-unused-vars */
import React from "react";
import { CForm } from "@coreui/react";
import SVG from "assets/svg";
import DebounceInput from "views/common/component/DebounceInput";
import arrowBack from "assets/icons/arrow-back.svg";
import classNames from "classnames";
import "./Header.scss";

function StoreHeader(props) {
  const { storeName, goBack, onRightButtonClick } = props;

  return (
    <div className={classNames("store-header")}>
      <button
        type="button"
        onClick={goBack}
        className="button__effect flex-shrink-0 _border-none _outline-none"
        style={{ background: "transparent" }}
      >
        <img src={arrowBack} alt="arrow-back" width={8} height={15} />
      </button>
      <div className="text-center text-truncate px-2 _fw-600 _fs-16px color-chinese_black">
        {storeName}
      </div>
      <div className="filter-button" onClick={onRightButtonClick}>
        {!!props.rightButton && props.rightButton()}
      </div>
    </div>
  );
}

export default StoreHeader;
