import { call, put, takeLatest, fork } from "redux-saga/effects";
import actions from "./actions";
import * as TYPE from "./constants";
import api from "../api/api";

const sagas = {
  *[TYPE.GET_USER_DEFAULT_ADDRESS_BOOK.request](action) {
    try {
      const data = yield call(
        api[TYPE.GET_USER_DEFAULT_ADDRESS_BOOK.request],
        action.payload
      );
      if (data.status === 200) {
        yield put(actions.GET_USER_DEFAULT_ADDRESS_BOOK.success(data.data));
      } else {
        yield put(actions.GET_USER_DEFAULT_ADDRESS_BOOK.failure(data?.data));
      }
    } catch (error) {
      yield put(actions.GET_USER_DEFAULT_ADDRESS_BOOK.failure(error?.data));
    }
  },

  *[TYPE.GET_SUGGESTED_COUPON_LIST.request](action) {
    try {
      const response = yield call(
        api[TYPE.GET_SUGGESTED_COUPON_LIST.request],
        action.payload
      );

      if (response.status === 200) {
        yield put(actions.GET_SUGGESTED_COUPON_LIST.success(response.data));
      } else {
        yield put(actions.GET_SUGGESTED_COUPON_LIST.failure(response?.data));
      }
    } catch (error) {
      yield put(actions.GET_SUGGESTED_COUPON_LIST.failure(error?.data));
    }
  },

  *[TYPE.GET_SHIPPING_INFO.request](action) {
    try {
      const response = yield call(
        api[TYPE.GET_SHIPPING_INFO.request],
        action.payload
      );

      if (response.status === 200) {
        yield put(actions.GET_SHIPPING_INFO.success(response.data));
      } else {
        yield put(actions.GET_SHIPPING_INFO.failure(response?.data));
      }
    } catch (error) {
      yield put(actions.GET_SHIPPING_INFO.failure(error?.data));
    }
  },

  *[TYPE.GET_SUGGESTED_DELIVERY_DISCOUNT_LIST.request](action) {
    try {
      const response = yield call(
        api[TYPE.GET_SUGGESTED_DELIVERY_DISCOUNT_LIST.request],
        action.payload
      );

      if (response.status === 200) {
        yield put(
          actions.GET_SUGGESTED_DELIVERY_DISCOUNT_LIST.success(response.data)
        );
      } else {
        yield put(
          actions.GET_SUGGESTED_DELIVERY_DISCOUNT_LIST.failure(response?.data)
        );
      }
    } catch (error) {
      yield put(
        actions.GET_SUGGESTED_DELIVERY_DISCOUNT_LIST.failure(error?.data)
      );
    }
  },

  *[TYPE.CALC_COUPON_DISCOUNT.request](action) {
    try {
      const response = yield call(
        api[TYPE.CALC_COUPON_DISCOUNT.request],
        action.payload
      );

      if (response.status === 200) {
        yield put(
          actions.CALC_COUPON_DISCOUNT.success(
            response.data?.discountAmount || 0
          )
        );
      } else {
        yield put(actions.CALC_COUPON_DISCOUNT.failure(response?.data));
      }
    } catch (error) {
      yield put(actions.CALC_COUPON_DISCOUNT.failure(error?.data));
    }
  },

  *[TYPE.GET_AVAILABLE_COUPONS_BY_CODE.request](action) {
    try {
      const response = yield call(
        api[TYPE.GET_AVAILABLE_COUPONS_BY_CODE.request],
        action.payload
      );

      if (response.status === 200) {
        yield put(
          actions.GET_AVAILABLE_COUPONS_BY_CODE.success(
            response.data?.coupons?.[0]?.voucherCode
          )
        );
      } else {
        yield put(
          actions.GET_AVAILABLE_COUPONS_BY_CODE.failure(response?.data)
        );
      }
    } catch (error) {
      yield put(actions.GET_AVAILABLE_COUPONS_BY_CODE.failure(error?.data));
    }
  },

  *[TYPE.GET_AVAILABLE_COUPONS_OF_ALLOCATED_COUPON_TICKET.request](action) {
    try {
      const response = yield call(
        api[TYPE.GET_AVAILABLE_COUPONS_OF_ALLOCATED_COUPON_TICKET.request],
        action.payload
      );

      if (response.status === 200) {
        yield put(
          actions.GET_AVAILABLE_COUPONS_OF_ALLOCATED_COUPON_TICKET.success(
            response.data
          )
        );
      } else {
        yield put(
          actions.GET_AVAILABLE_COUPONS_OF_ALLOCATED_COUPON_TICKET.failure(
            response?.data
          )
        );
      }
    } catch (error) {
      yield put(
        actions.GET_AVAILABLE_COUPONS_OF_ALLOCATED_COUPON_TICKET.failure(
          error?.data
        )
      );
    }
  },

  *[TYPE.CREATE_ORDER.request](action) {
    try {
      const response = yield call(
        api[TYPE.CREATE_ORDER.request],
        action.payload
      );

      if (response.status === 200) {
        yield put(actions.CREATE_ORDER.success(response.data));
      } else {
        yield put(actions.CREATE_ORDER.failure(response?.data));
      }
    } catch (error) {
      yield put(actions.CREATE_ORDER.failure(error?.data));
    }
  },

  *[TYPE.CALC_COUPON_DISCOUNT_V2.request](action) {
    const params = action.payload;
    let voucherCode;

    try {
      // if (params?.couponTransactionCode) {
      //   // call API 045
      //   const response = yield call(
      //     api[TYPE.GET_AVAILABLE_COUPONS_OF_ALLOCATED_COUPON_TICKET.request],
      //     {
      //       couponProductCode: params?.couponProductCode,
      //       couponTransactionCode: params?.couponTransactionCode,
      //     }
      //   );

      //   if (response.status === 200) {
      //     voucherCode = response.data?.[0]?.voucherCode;
      //   } else {
      //     yield put(actions.CALC_COUPON_DISCOUNT_V2.failure(response?.data));
      //   }
      // } else {
      //   // call API 012
      //   const response = yield call(
      //     api[TYPE.GET_AVAILABLE_COUPONS_BY_CODE.request],
      //     params?.couponProductCode
      //   );

      //   if (response.status === 200) {
      //     voucherCode = response.data?.coupons?.[0]?.voucherCode;
      //   } else {
      //     yield put(actions.CALC_COUPON_DISCOUNT_V2.failure(response?.data));
      //   }
      // }

      // do có trường hợp GET_AVAILABLE_COUPONS_OF_ALLOCATED_COUPON_TICKET trả ra mảng rỗng
      // -> lấy voucherCode bị null -> CALC_COUPON_DISCOUNT bị NotFoundCoupon  (do truyền coupon: [null])
      // do GET_AVAILABLE_COUPONS_BY_CODE đều call được dù coupon đó đã đc allocated hay chưa
      // -> luôn call GET_AVAILABLE_COUPONS_BY_CODE để bắt được lỗi tốt hơn
      const getAvailableCouponsByCodeResponse = yield call(
        api[TYPE.GET_AVAILABLE_COUPONS_BY_CODE.request],
        params?.couponProductCode
      );

      if (getAvailableCouponsByCodeResponse.status === 200) {
        voucherCode =
          getAvailableCouponsByCodeResponse.data?.coupons?.[0]?.voucherCode;
      } else {
        yield put(
          actions.CALC_COUPON_DISCOUNT_V2.failure(
            getAvailableCouponsByCodeResponse?.data
          )
        );
      }

      // call API 046
      const response = yield call(api[TYPE.CALC_COUPON_DISCOUNT.request], {
        merchantId: params?.merchantId,
        storeId: params?.storeId,
        products: params?.products,
        coupons: [voucherCode],
      });

      if (response.status === 200) {
        yield put(
          actions.CALC_COUPON_DISCOUNT_V2.success({
            voucherCode,
            discountAmount: response.data?.discountAmount,
          })
        );
      } else {
        yield put(actions.CALC_COUPON_DISCOUNT_V2.failure(response?.data));
      }
    } catch (error) {
      yield put(actions.CALC_COUPON_DISCOUNT_V2.failure(error?.data));
    }
  },

  *[TYPE.GET_OUT_OF_STOCK_PRODUCT_LIST.request](action) {
    try {
      const response = yield call(
        api[TYPE.GET_OUT_OF_STOCK_PRODUCT_LIST.request],
        action.payload
      );

      if (response.status === 200) {
        yield put(actions.GET_OUT_OF_STOCK_PRODUCT_LIST.success(response.data));
      } else {
        yield put(
          actions.GET_OUT_OF_STOCK_PRODUCT_LIST.failure(response?.data)
        );
      }
    } catch (error) {
      yield put(actions.GET_OUT_OF_STOCK_PRODUCT_LIST.failure(error?.data));
    }
  },

  *[TYPE.GET_PRODUCT_LIST.request](action) {
    try {
      const response = yield call(
        api[TYPE.GET_PRODUCT_LIST.request],
        action.payload
      );

      if (response.status === 200) {
        yield put(
          actions.GET_PRODUCT_LIST.success(
            response.data?.data?.queryProductfnbContentsWithTotal?.items
          )
        );
      } else {
        yield put(actions.GET_PRODUCT_LIST.failure(response?.data));
      }
    } catch (error) {
      yield put(actions.GET_PRODUCT_LIST.failure(error?.data));
    }
  },

  *[TYPE.GET_STORE_INFO.request](action) {
    try {
      const data = yield call(api[TYPE.GET_STORE_INFO.request], action.payload);

      if (data.status === 200) {
        yield put(actions.GET_STORE_INFO.success(data.data));
      } else {
        yield put(actions.GET_STORE_INFO.failure(data?.data));
      }
    } catch (error) {
      yield put(actions.GET_STORE_INFO.failure(error?.data));
    }
  },

  *[TYPE.GET_LOYALTY_POINT_LIST.request](action) {
    try {
      const response = yield call(
        api[TYPE.GET_LOYALTY_POINT_LIST.request],
        action.payload
      );

      if (response.status === 200) {
        yield put(actions.GET_LOYALTY_POINT_LIST.success(response.data));
      } else {
        yield put(actions.GET_LOYALTY_POINT_LIST.failure(response?.data));
      }
    } catch (error) {
      yield put(actions.GET_LOYALTY_POINT_LIST.failure(error?.data));
    }
  },
};

function* watchedSaga() {
  yield takeLatest(
    TYPE.GET_USER_DEFAULT_ADDRESS_BOOK.request,
    sagas[TYPE.GET_USER_DEFAULT_ADDRESS_BOOK.request]
  );
  yield takeLatest(
    TYPE.GET_SUGGESTED_COUPON_LIST.request,
    sagas[TYPE.GET_SUGGESTED_COUPON_LIST.request]
  );
  yield takeLatest(
    TYPE.GET_SHIPPING_INFO.request,
    sagas[TYPE.GET_SHIPPING_INFO.request]
  );
  yield takeLatest(
    TYPE.GET_SUGGESTED_DELIVERY_DISCOUNT_LIST.request,
    sagas[TYPE.GET_SUGGESTED_DELIVERY_DISCOUNT_LIST.request]
  );
  yield takeLatest(
    TYPE.CALC_COUPON_DISCOUNT.request,
    sagas[TYPE.CALC_COUPON_DISCOUNT.request]
  );
  yield takeLatest(
    TYPE.GET_AVAILABLE_COUPONS_BY_CODE.request,
    sagas[TYPE.GET_AVAILABLE_COUPONS_BY_CODE.request]
  );
  yield takeLatest(
    TYPE.GET_AVAILABLE_COUPONS_OF_ALLOCATED_COUPON_TICKET.request,
    sagas[TYPE.GET_AVAILABLE_COUPONS_OF_ALLOCATED_COUPON_TICKET.request]
  );
  yield takeLatest(TYPE.CREATE_ORDER.request, sagas[TYPE.CREATE_ORDER.request]);
  yield takeLatest(
    TYPE.CALC_COUPON_DISCOUNT_V2.request,
    sagas[TYPE.CALC_COUPON_DISCOUNT_V2.request]
  );
  yield takeLatest(
    TYPE.GET_OUT_OF_STOCK_PRODUCT_LIST.request,
    sagas[TYPE.GET_OUT_OF_STOCK_PRODUCT_LIST.request]
  );
  yield takeLatest(
    TYPE.GET_PRODUCT_LIST.request,
    sagas[TYPE.GET_PRODUCT_LIST.request]
  );
  yield takeLatest(
    TYPE.GET_STORE_INFO.request,
    sagas[TYPE.GET_STORE_INFO.request]
  );
  yield takeLatest(
    TYPE.GET_LOYALTY_POINT_LIST.request,
    sagas[TYPE.GET_LOYALTY_POINT_LIST.request]
  );
}

export default function* rootChild() {
  yield fork(watchedSaga);
}
