/*
  func Helper gen actionType
*/
export const asyncTypes = action => ({
  ORIGIN: action,
  REQUEST: `${action}_REQUEST`,
  SUCCESS: `${action}_SUCCESS`,
  FAILED: `${action}_FAILED`,
  CLEAR: `${action}_CLEAR`,
});
