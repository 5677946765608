import React from "react";
import classNames from "classnames";
import { useSelector } from "react-redux";
import TheContent from "./TheContent";

const TheLayout = () => {
  const { darkMode } = useSelector((state) => ({
    darkMode: state.root.darkMode,
    user: state.root.user,
  }));
  const classes = classNames(
    "c-app c-default-layout",
    darkMode && "c-dark-theme"
  );

  return (
    <div className={classes}>
       <div class="checkQA hide">Bạn đang ở môi trường thử nghiệm</div>
      <div className="c-wrapper">
        <div className="c-body">
          <TheContent />
        </div>
      </div>
    </div>
  );
};

export default TheLayout;
